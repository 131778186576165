export enum AdvancedSearchNumberInput {
  LENGTH = 'height',
  WIDTH = 'width',
  PICK_DENSITY = 'pick_density',
  REED_DENSITY = 'reed_density',
  NR_COLUMNS_FOR_WEFT_SELECTION = 'nrColumnsForWeftSelection',
  SIZE_IN_KB = 'sizeInKB',
  WIDTH_IN_DENTS = 'widthInDents',
  HEIGHT_IN_PICKS = 'heightInPicks'
}
