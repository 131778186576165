import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {ListDrawingJute} from '@presentation/components/drawing-list/list-drawing-jute';
import {ListDrawingOrderLine} from '@presentation/components/drawing-list/list-drawing-order-line';
import {find} from 'lodash-es';

export function getListDrawingFromProductionScheduleItem(productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup, productionSchedule: ProductionSchedule): ListDrawing {
  let listDrawing: ListDrawing;

  if (productionScheduleItemInPathGroup.hasOrderLine()) {
    listDrawing = new ListDrawingOrderLine(find(productionSchedule.orderLines, {id: productionScheduleItemInPathGroup.orderLineId}));
  } else if (productionScheduleItemInPathGroup.scheduleItemType === DrawingType.MANUAL_NON_PRODUCTION_ITEM) {
    listDrawing = new ListDrawingJute(productionScheduleItemInPathGroup.drawing);
  } else {
    listDrawing = new ListDrawingConfiguration(productionScheduleItemInPathGroup.drawing, 0);
  }

  return listDrawing;
}
