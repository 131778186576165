import {PlanningItemForecast} from './planning-item-forecast';
import {PlanningLine} from './planning-line';

export class PlanningForecast {
  private _planningLines: PlanningLine[];
  private _forecasts: PlanningItemForecast[];

  public constructor(planningLines: PlanningLine[], forecasts: PlanningItemForecast[]) {
    this._planningLines = planningLines;
    this._forecasts = forecasts;
  }

  public get planningLines(): PlanningLine[] {
    return this._planningLines;
  }

  public set planningLines(value: PlanningLine[]) {
    this._planningLines = value;
  }

  public get forecasts(): PlanningItemForecast[] {
    return this._forecasts;
  }

  public set forecasts(value: PlanningItemForecast[]) {
    this._forecasts = value;
  }

  public static fromJSON(json: any): PlanningForecast {
    return new PlanningForecast(json.planningLines.map(PlanningLine.fromJSON), json.forecasts.map(PlanningItemForecast.fromJSON));
  }
}
