import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class ExtrusionMachine extends Equipment {
  private _winderSerialNumbers: string[];
  private _bobbinHolderShaftNumbers: string[];

  public constructor(id: number, name: string, orderId: string, winderSerialNumbers: string[], bobbinHolderShaftNumbers: string[]) {
    super(id, MachineType.EXTRUSION, name, orderId);

    this._bobbinHolderShaftNumbers = bobbinHolderShaftNumbers;
    this._winderSerialNumbers = winderSerialNumbers;
  }

  public get winderSerialNumbers(): string[] {
    return this._winderSerialNumbers;
  }

  public set winderSerialNumbers(winderSerialNumbers: string[]) {
    this._winderSerialNumbers = winderSerialNumbers;
  }

  public get bobbinHolderShaftNumbers(): string[] {
    return this._bobbinHolderShaftNumbers;
  }

  public set bobbinHolderShaftNumbers(bobbinHolderShaftNumbers: string[]) {
    this._bobbinHolderShaftNumbers = bobbinHolderShaftNumbers;
  }

  public static fromJSON(extrusionMachineJSON: any): ExtrusionMachine {
    return new ExtrusionMachine(
      extrusionMachineJSON.id,
      extrusionMachineJSON.name,
      extrusionMachineJSON.orderId,
      extrusionMachineJSON.winderSerialNumbers,
      extrusionMachineJSON.bobbinHolderShaftNumbers
    );
  }

  public toJSON(): JSON {
    return {
      machineType: `${MachineType[this.machineType]}`,
      name: this.name,
      orderId: this.orderId,
      winderSerialNumbers: this._winderSerialNumbers,
      bobbinHolderShaftNumbers: this._bobbinHolderShaftNumbers
    } as any as JSON;
  }
}
