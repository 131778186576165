import {VelvetMachineDrawingIn} from './velvet-machine-drawing-in';
import {VelvetMachineDrawingInType} from './velvet-machine-drawing-in-type.enum';

export class VelvetMachineGroundDrawingIn extends VelvetMachineDrawingIn {
  private _bottomCloth: boolean;

  public constructor(number: number, bottomCloth: boolean, position: number) {
    super(number, VelvetMachineDrawingInType.GROUND, position);

    this._bottomCloth = bottomCloth;
  }

  public get bottomCloth(): boolean {
    return this._bottomCloth;
  }

  public toJSON(): any {
    return {
      number: this.number,
      type: VelvetMachineDrawingInType[this.type],
      bottomCloth: this._bottomCloth,
      position: this.position
    };
  }

  public static fromJSON(velvetMachineGroundDrawingInJSON: any): VelvetMachineGroundDrawingIn {
    return new VelvetMachineGroundDrawingIn(velvetMachineGroundDrawingInJSON.number, velvetMachineGroundDrawingInJSON.bottomCloth, velvetMachineGroundDrawingInJSON.position);
  }
}
