import {Observable, Observer} from 'rxjs';

export class DOMUtils {
  public static observeResize(target: Element): Observable<ResizeObserverEntry[]> {
    return new Observable((observer: Observer<ResizeObserverEntry[]>) => {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        observer.next(entries);
      });

      resizeObserver.observe(target);

      return function unsubscribe(): void {
        resizeObserver.unobserve(target);
        resizeObserver.disconnect();
      };
    });
  }

  public static observeMutations(target: Node, options: MutationObserverInit): Observable<MutationRecord[]> {
    return new Observable((observer: Observer<MutationRecord[]>) => {
      const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
        observer.next(mutations);
      });

      mutationObserver.observe(target, options);

      return function unsubscribe(): void {
        mutationObserver.disconnect();
      };
    });
  }

  public static observeWindowEvents<K extends keyof WindowEventMap>(window: Window, type: K): Observable<WindowEventMap[K]> {
    return new Observable((observer: Observer<WindowEventMap[K]>) => {
      const handler = (event: WindowEventMap[K]): void => observer.next(event);

      window.addEventListener(type, handler);

      return function unsubscribe(): void {
        window.removeEventListener(type, handler);
      };
    });
  }
}
