import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {Subscription} from '@domain/profile/subscription';
import {AuthenticationToken} from '@infrastructure/http/authentication/authentication-token';
import {CompanySubscriptions} from '@infrastructure/http/profile/company-subscriptions';
import {PROFILE, Profile} from '@infrastructure/http/profile/profile';
import {AuthenticationError, LocalStorageService, UuidUtils, WINDOW} from '@vdw/angular-component-library';
import {find, isNil} from 'lodash-es';
import {BehaviorSubject, filter, first, map, Observable, of, Subject, switchMap, tap, throwError} from 'rxjs';
import {Authentication} from './authentication';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthenticationService implements Authentication {
  public static readonly subscriptionStorageKey = 'subscription';
  protected currentSubscriptionSubject: Subject<Subscription> = new Subject<Subscription>();
  private readonly LOGIN_URI: string;
  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  private readonly storageConstants = {
    STATE: 'state',
    NONCE: 'nonce',
    EMAIL: 'email',
    SUBSCRIPTION: HttpAuthenticationService.subscriptionStorageKey,
    REDIRECT: 'redirect'
  };

  public constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration,
    @Inject(PROFILE) private readonly profile: Profile,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly router: Router,
    @Inject(WINDOW) window: Window
  ) {
    this.LOGIN_URI = `${window.location.origin}/${RouteUtils.paths.login.path}`;
    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isAuthenticatedAndHasSubscription());
  }

  public login(email: string): void {
    this.setRequestStateAndNonce();

    this.msalService.loginRedirect({
      loginHint: email,
      scopes: this.backendConfiguration.getAuthenticationScopes(),
      nonce: this.localStorageService.get(this.storageConstants.NONCE),
      state: this.localStorageService.get(this.storageConstants.STATE)
    });
  }

  public onAuthentication(): Observable<AuthenticationToken> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter(({eventType}: EventMessage) => {
        return eventType === EventType.LOGIN_SUCCESS || eventType === EventType.ACQUIRE_TOKEN_SUCCESS || eventType === EventType.LOGIN_FAILURE || eventType === EventType.ACQUIRE_TOKEN_FAILURE;
      }),
      first(),
      switchMap(({eventType, payload, error}: EventMessage) => {
        return eventType === EventType.ACQUIRE_TOKEN_FAILURE || eventType === EventType.LOGIN_FAILURE
          ? throwError(() => new AuthenticationError(error.message))
          : of(AuthenticationToken.fromJSON(payload));
      }),
      switchMap((authenticationToken: AuthenticationToken) => {
        const currentNonce: string = this.localStorageService.get(this.storageConstants.NONCE);
        const currentState: string = this.localStorageService.get(this.storageConstants.STATE);
        const currentAudience = this.backendConfiguration.getAuthenticationClientId();

        return authenticationToken.isValid(currentState, currentNonce, currentAudience) ? of(authenticationToken) : throwError(() => new AuthenticationError());
      }),
      tap((authenticationToken: AuthenticationToken) => {
        this.localStorageService.set(this.storageConstants.EMAIL, authenticationToken.email);
        this.isAuthenticatedSubject.next(this.isAuthenticatedAndHasSubscription());
      })
    );
  }

  public logout(): void {
    this.httpClient
      .post(`${this.backendConfiguration.getAggregatorEndpoint()}cache/logout`, null)
      .pipe(
        switchMap(() =>
          this.msalService.logoutRedirect({
            onRedirectNavigate: () => this.resetLocalStorageToDefaults()
          })
        )
      )
      .subscribe();
  }

  public isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.getValue();
  }

  public currentAuthenticatedChanges(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  public getToken(): Observable<string> {
    return this.msalService.initialize().pipe(
      switchMap(() => {
        return this.msalService.acquireTokenSilent({
          scopes: this.backendConfiguration.getAuthenticationScopes(),
          forceRefresh: false,
          account: this.msalService.instance.getAllAccounts()[0]
        });
      }),
      map((authenticationResult: AuthenticationResult) => authenticationResult.accessToken)
    );
  }

  public validateCurrentSubscription(): void {
    try {
      const subscription: Subscription = this.getCurrentSubscription();

      if (!isNil(subscription)) {
        if (subscription.isValid()) {
          this.refreshSubscription(subscription);
        } else {
          this.handleInvalidSubscription();
        }
      }
    } catch (e) {
      this.handleInvalidSubscription();
    }
  }

  public getCurrentSubscription(): Subscription {
    const storedSubscriptionJSONString: string = this.localStorageService.get(this.storageConstants.SUBSCRIPTION);
    let result: Subscription = null;

    if (!isNil(storedSubscriptionJSONString)) {
      result = Subscription.fromLocalStorage(storedSubscriptionJSONString);
    }

    return result;
  }

  public setCurrentSubscription(subscription: Subscription): void {
    this.currentSubscriptionSubject.next(subscription);
    this.localStorageService.set(this.storageConstants.SUBSCRIPTION, subscription.toJSON());
    this.isAuthenticatedSubject.next(this.isAuthenticatedAndHasSubscription());
  }

  public currentSubscriptionChanges(): Observable<Subscription> {
    return this.currentSubscriptionSubject;
  }

  public getCurrentEmail(): string {
    const storedEmail = this.localStorageService.get(this.storageConstants.EMAIL);
    return isNil(storedEmail) ? null : storedEmail;
  }

  public getCurrentUserId(): string {
    const currentSubscription: Subscription = this.getCurrentSubscription();
    return currentSubscription?.userIdentity.userId ?? '';
  }

  public getRedirectAfterAuthentication(): string {
    return this.localStorageService.get(this.storageConstants.REDIRECT);
  }

  public setRedirectAfterAuthentication(url: string): void {
    if (isNil(url)) {
      this.localStorageService.remove(this.storageConstants.REDIRECT);
    } else {
      this.localStorageService.set(this.storageConstants.REDIRECT, url);
    }
  }

  public resetLocalStorageToDefaults(): void {
    this.localStorageService.remove(...Object.keys(this.storageConstants).map((storageConstantKey: string) => this.storageConstants[storageConstantKey]));
    this.backendConfiguration.clearLocalStorage();
  }

  public clearSubscription(): void {
    this.currentSubscriptionSubject.next(null);
    this.localStorageService.remove(this.storageConstants.SUBSCRIPTION);
  }

  public isAuthenticationInProgress(): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      first(),
      map((status: InteractionStatus) => {
        return status === InteractionStatus.Login || status === InteractionStatus.HandleRedirect;
      })
    );
  }

  public handleInvalidSubscription(): void {
    this.router.navigate([RouteUtils.paths.login.absolutePath], {queryParams: {'session-expired': true}});
    this.resetLocalStorageToDefaults();
    this.isAuthenticatedSubject.next(false);
  }

  private setRequestStateAndNonce(): void {
    const requestState = `${this.LOGIN_URI}?${UuidUtils.generateV4Uuid()}`;
    const requestNonce = UuidUtils.generateV4Uuid();

    this.localStorageService.set(this.storageConstants.STATE, requestState);
    this.localStorageService.set(this.storageConstants.NONCE, requestNonce);
  }

  private refreshSubscription(subscriptionToRefresh: Subscription): void {
    this.profile.getSubscriptions(false).subscribe((companySubscriptions: CompanySubscriptions) => {
      this.setCurrentSubscription(find(companySubscriptions.subscriptions, {id: subscriptionToRefresh.id}));
      this.backendConfiguration.setApplicationMaintenance(companySubscriptions.applicationMaintenance);
    });
  }

  private isAuthenticatedAndHasSubscription(): boolean {
    return !isNil(this.localStorageService.get(this.storageConstants.EMAIL)) && !isNil(this.localStorageService.get(this.storageConstants.SUBSCRIPTION));
  }
}
