import {ReportItemTemplateField} from '@domain/report-data-set/report-item-template-field.enum';
import {OverviewMachine} from '@presentation/pages/machine-overview/overview-machine';
import {ReportItem} from '@presentation/pages/machine-overview/report-item';
import {AssertionUtils, ColorConstants} from '@vdw/angular-component-library';
import {isNil, toLower} from 'lodash-es';

export enum MachineStatus {
  RUNNING = 'RUNNING',
  STOPPED_MANUAL = 'STOPPED_MANUAL',
  STOPPED_CRITICAL = 'STOPPED_CRITICAL',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTED = 'CONNECTED'
}

export namespace MachineStatus {
  export function getMachineStatusByLabel(label: string): MachineStatus {
    return !isNil(label) && !isNil(MachineStatus[`${label}`]) ? MachineStatus[`${label}`] : null;
  }

  export function getMachineStatusClass(machineStatus: MachineStatus, canSeeStatus: boolean = true): string {
    if (canSeeStatus) {
      return toLower(`${MachineStatus[machineStatus]}`).replace('_', '-');
    }
    const status = machineStatus !== MachineStatus.DISCONNECTED ? MachineStatus.CONNECTED : machineStatus;
    return toLower(`${status}`).replace('_', '-');
  }

  export function getMachineStatusColor(machine: OverviewMachine, canSeeStatus: boolean, canSeeDeclarationMessages: boolean, isStatusBar: boolean = true): string {
    if (!canSeeStatus) {
      return '';
    }

    const isDisconnected = !AssertionUtils.isNullOrUndefined(machine.status) && machine.status === MachineStatus.DISCONNECTED;
    const reportItemStopReasonColor = machine.reportItems.find((reportItem: ReportItem) => reportItem.field === ReportItemTemplateField.DECLARATION_COLOR);

    if (reportItemStopReasonColor?.value && canSeeDeclarationMessages) {
      return reportItemStopReasonColor.value;
    }

    switch (machine.status) {
      case MachineStatus.STOPPED_CRITICAL:
        return ColorConstants.FUNCTIONAL_COLOR_RED;
      case MachineStatus.STOPPED_MANUAL:
        return ColorConstants.PRIMARY_COLOR_VDW_BLUE;
      case MachineStatus.RUNNING:
        return isStatusBar && !isDisconnected ? ColorConstants.FUNCTIONAL_COLOR_GREEN : '';
      default:
        return '';
    }
  }
}
