import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {GetAllWeaveProductsParameters, WEAVE_PRODUCT, WeaveProducts} from '@infrastructure/http/weave-product/weave-products.interface';
import {NavigationMachineOverviewData} from '@presentation/pages/machine-overview/navigation-machine-overview-data.interface';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {
  AgGridUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-weave-product.component.html'
})
export class SelectWeaveProductComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('weaveProductsGrid') public weaveProductsGrid: AgGridAngular;
  public listOfWeaveProducts: OverviewListWeaveProduct[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private readonly machineId: number;

  public constructor(
    @Inject(WEAVE_PRODUCT) private weaveProducts: WeaveProducts,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<SelectWeaveProductComponent>,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectWeaveProductComponent>>
  ) {
    super();

    this.machineId = data?.machineId;
  }

  public get gridOptionsForListOfWeaveProducts(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.getWeaveProducts();
    this.initializeGridOptionsForListOfWeaveProducts();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.weaveProductsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<DialogComponentData<typeof SelectWeaveProductComponent>>({dialogComponent: SelectWeaveProductComponent});
  }

  public selectWeaveProduct(): void {
    this.dialogRef.close(this.weaveProductsGrid.api.getSelectedRows()[0]);
  }

  public canSelectWeaveProduct(): boolean {
    return this.weaveProductsGrid?.api && this.weaveProductsGrid.api.getSelectedRows().length > 0;
  }

  public showOnlyLatestVersionChanged(showOnlyLatestVersion: boolean): void {
    this.getWeaveProducts(showOnlyLatestVersion);
  }

  public navigateToAddWeaveProduct(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationMachineOverviewData>(
      {equipmentId: this.machineId ?? null},
      RouteUtils.paths.texStyle.weaveProduct.addWeaveProduct.absolutePath
    );
  }

  private initializeGridOptionsForListOfWeaveProducts(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfWeaveProducts(), GridIdentifier.SELECT_WEAVE_PRODUCT)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnCellDoubleClicked(() => this.selectWeaveProduct())
        .build()
    ];
  }

  private getWeaveProducts(showOnlyLatestVersion: boolean = true): void {
    const parameters: Partial<GetAllWeaveProductsParameters> = {showOnlyLatestVersion};
    const request = this.machineId == null ? this.weaveProducts.getAll(parameters) : this.weaveProducts.getAllForMachineId(this.machineId, parameters);

    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((listOfWeaveProducts: OverviewListWeaveProduct[]) => {
      this.listOfWeaveProducts = listOfWeaveProducts;
    });
  }

  private getColumnDefsForListOfWeaveProducts(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('nameWithVersion', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('widthInCm')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.CENTIMETER))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('pickDensityInPicksPerCm')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.PICK_DENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.PICKS_PER_CENTIMETER))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('standardSpeedInRotationsPerMinute')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_SPEED')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.ROTATIONS_PER_MINUTE))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('standardEfficiencyInPercentage')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_EFFICIENCY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => `${params.value} %`)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withField('numberOfFeeders').withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_FEEDERS').withRightAlignment().build(),
      this.colDefBuilderFactoryService.getBuilder().withField('numberOfShafts').withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_SHAFTS').withRightAlignment().build()
    ];
  }

  private buildAgGridCellTextWithUnit(value: number, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService);
  }
}
