<section class="flex-column bms-theme gap-8">
  <article class="dialog-content flex-column gap-16">
    <div class="new-updates-head">
      <h2>{{ 'NEW_UPDATE_DIALOG.DIALOG_BAR_TITLE' | bmsTranslateAsync: {version: changelogs[0]?.version} | async }}</h2>
      <p class="font-weight-regular">{{ changelogs[0].changes?.date | date: 'MMMM d, yyyy' }}</p>
    </div>
    <div class="new-updates-body flex-column gap-8">
      @for (changelog of changelogs; track $index) {
        <div class="flex-column gap-4">
          <div class="feature-version font-weight-semibold">{{ 'NEW_UPDATE_DIALOG.NEW_FEATURES_LIST_TITLE' | bmsTranslateAsync: {version: changelog.version} | async }}</div>

          <div class="features-list flex-1 flex-column flex-start-start gap-8">
            @for (type of getChanges(changelog.version); track $index) {
              <div class="changes-type flex-column flex-start-start gap-4">
                <p class="types font-weight-semibold">{{ 'CLIENT_VERSION.CHANGES_TYPE.' + (type | uppercase) | bmsTranslateAsync | async }}</p>
                <ul class="list">
                  @for (change of getChangesOfType(changelog.version, type); track $index) {
                    <li>
                      <p>{{ change }}</p>
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </article>

  <vdw-footer chooseEventName="NEW_UPDATE_DIALOG.UPDATE_BUTTON" [centered]="true" [withCancelButton]="true" (chooseEvent)="updateApp()"></vdw-footer>
</section>
