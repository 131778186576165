import {HorizontalPositionOfCreelPosition} from '@domain/textile-data/creel/horizontal-position-of-creel-position';
import {HorizontalPositionOfCreelPositionType} from '@domain/textile-data/creel/horizontal-position-of-creel-position-type.enum';
import {Color} from '@vdw/angular-component-library';
import {fill} from 'lodash-es';

export class HorizontalPositionOfColorCreelPosition extends HorizontalPositionOfCreelPosition {
  private readonly _color: Color;

  public constructor(amount: number, color: Color, isSelected: boolean = false) {
    super(HorizontalPositionOfCreelPositionType.COLOR, amount, isSelected);

    this._color = color;
  }

  public get color(): Color {
    return this._color;
  }

  public static fromJSON(colorCreelPositionJSON: any): HorizontalPositionOfColorCreelPosition {
    return new HorizontalPositionOfColorCreelPosition(colorCreelPositionJSON.amount, Color.fromJSON(colorCreelPositionJSON.single));
  }

  public toJSON(): JSON {
    return {
      type: HorizontalPositionOfCreelPositionType[this.type],
      id: this.color.id,
      amount: this.amount
    } as any as JSON;
  }

  public getAmountOfDentsInHorizontalPositionOfCreelPosition(): number {
    return this.amount;
  }

  public getPatternForHorizontalPositionOfCreelPosition(): Color[] {
    return fill(new Array(this.amount), this.color);
  }
}
