import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AgGridModule} from 'ag-grid-angular';
import {FooterModule} from '../custom-components/footer/footer.module';
import {HeaderModule} from '../custom-components/header/header.module';
import {MessageContainerModule} from '../custom-components/message-container/message-container.module';
import {CanShowErrorForFormControlModule} from '../custom-directives/can-show-error-for-context/can-show-error-for-form-control.module';
import {TranslationModule} from '../translation/translation.module';
import {WINDOW_PROVIDERS} from '../window/window.service';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {ConflictsDialogComponent} from './conflicts-dialog/conflicts-dialog.component';
import {DialogBuilderFactoryService} from './dialog-builder/dialog-builder-factory.service';
import {DialogOpenerService} from './dialog-opener/dialog-opener.service';
import {RepositionWatchDialogFactoryService} from './dialog-reposition/reposition-watch-dialog-factory.service';
import {HoverDialogBuilderService} from './hover-dialog/hover-dialog-builder.service';
import {SelectObjectsDialogComponent} from './select-objects-dialog/select-objects-dialog.component';

@NgModule({
  declarations: [AlertDialogComponent, SelectObjectsDialogComponent, ConflictsDialogComponent],
  imports: [
    AgGridModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule,
    CommonModule,
    MatSelectModule,
    FooterModule,
    HeaderModule,
    CanShowErrorForFormControlModule,
    MessageContainerModule
  ],
  providers: [DialogBuilderFactoryService, HoverDialogBuilderService, DialogOpenerService, RepositionWatchDialogFactoryService, WINDOW_PROVIDERS],
  exports: [AlertDialogComponent, SelectObjectsDialogComponent]
})
export class DialogModule {}
