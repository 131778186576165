import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';

export class NonProductionItemDrawing extends Drawing {
  public constructor(id: number, name: string, dimensionsInPixels: DimensionsInPx, shape: string, used: boolean, repeated: boolean, shapeId?: number) {
    super(id, name, DrawingType.NON_PRODUCTION_ITEM, null, dimensionsInPixels, shape, used, repeated, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, shapeId);
  }

  public static fromJSON(drawingJSON: any): NonProductionItemDrawing {
    return new NonProductionItemDrawing(
      drawingJSON.id,
      drawingJSON.name,
      {
        widthInPx: drawingJSON.dimensionsInPx.widthInPx,
        heightInPx: drawingJSON.dimensionsInPx.heightInPx
      },
      drawingJSON.shape,
      false,
      drawingJSON.repeated,
      drawingJSON.shapeId
    );
  }
}
