import {AbstractControl} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {AssertionUtils} from '../../common/utils/assertion-utils';

export class FormValueHelper {
  public static syncValuesOfFormFieldsByFactor(
    firstFormField: AbstractControl,
    secondFormField: AbstractControl,
    factorForConversionFromFirstFormFieldToSecondFormField: number,
    factorForConversionFromSecondFormFieldToFirstFormField: number,
    divisibleBy?: number
  ): Observable<void> {
    const firstFormFieldChanges = firstFormField.valueChanges.pipe(
      filter((value: number) => !AssertionUtils.isNullOrUndefined(value)),
      tap((value: number) => {
        const convertedValue = this.getConvertedValue(value, factorForConversionFromFirstFormFieldToSecondFormField, divisibleBy);
        secondFormField.patchValue(Math.max(divisibleBy, convertedValue), {emitEvent: false});
      })
    );

    const secondFormFieldChanges = secondFormField.valueChanges.pipe(
      filter((value: number) => !AssertionUtils.isNullOrUndefined(value)),
      tap((value: number) => {
        const convertedValue = this.getConvertedValue(value, factorForConversionFromSecondFormFieldToFirstFormField, divisibleBy);
        firstFormField.patchValue(Math.max(divisibleBy, convertedValue), {emitEvent: false});
      })
    );

    return combineLatest([firstFormFieldChanges, secondFormFieldChanges]).pipe(map(() => null));
  }

  private static getConvertedValue(value: number, factorForConversion: number, divisibleBy?: number): number {
    if (!AssertionUtils.isNullOrUndefined(divisibleBy) && divisibleBy !== 0) {
      return Math.round((value * factorForConversion) / divisibleBy) * divisibleBy;
    } else {
      return Math.round(value * factorForConversion);
    }
  }
}
