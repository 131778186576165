import {ArticleForSalesOrderLine} from '@domain/sales-order/article-for-sales-order-line';

export class OrderLineForSalesOrder {
  private _id: number;
  private _article: ArticleForSalesOrderLine;
  private _amount: number;
  private _amountPlanned: number;
  private _amountLeft: number;
  private _amountProduced: number;
  private _commercialLengthInMM: number;

  public constructor(id: number, article: ArticleForSalesOrderLine, amount: number, amountPlanned: number, amountLeft: number, amountProduced: number, commercialLengthInMM?: number) {
    this._id = id;
    this._article = article;
    this._amount = amount;
    this._amountPlanned = amountPlanned;
    this._amountLeft = amountLeft;
    this._amountProduced = amountProduced;
    this._commercialLengthInMM = commercialLengthInMM;
  }

  public get id(): number {
    return this._id;
  }

  public get article(): ArticleForSalesOrderLine {
    return this._article;
  }

  public get amount(): number {
    return this._amount;
  }

  public get amountPlanned(): number {
    return this._amountPlanned;
  }

  public get amountLeft(): number {
    return this._amountLeft;
  }

  public get amountProduced(): number {
    return this._amountProduced;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public static fromJSON(orderLineJSON: any): OrderLineForSalesOrder {
    return new OrderLineForSalesOrder(
      orderLineJSON.id,
      ArticleForSalesOrderLine.fromJSON(orderLineJSON.article),
      orderLineJSON.amount,
      orderLineJSON.amountPlanned,
      orderLineJSON.amountLeft,
      orderLineJSON.amountProduced,
      orderLineJSON.commercialLengthInMM
    );
  }

  public toJSON(): JSON {
    const orderLineJSON = {
      articleId: this._article.id,
      amount: this._amount
    } as any as JSON;

    if (this._article.design.repeated) {
      orderLineJSON['commercialLengthInMM'] = this._commercialLengthInMM;
    }
    return orderLineJSON;
  }
}
