import {convertRGBToHexadecimalColorCode, convertRGBToShadeName} from '../converters/convert-color-representation';
import {ColorRGBRepresentation} from '../interfaces/color-rgb-representation';

export class Color {
  private _id: number;
  private _name: string;
  private _rgb: ColorRGBRepresentation;
  private _used: boolean;
  private _shade: string;
  private _description: string;

  public constructor(id: number, name: string, rgb: ColorRGBRepresentation, used: boolean, shade?: string, description: string = null) {
    this._id = id;
    this._name = name;
    this._rgb = rgb;
    this._used = used;
    this._shade = shade;
    this._description = description;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get rgb(): ColorRGBRepresentation {
    return this._rgb;
  }

  public set rgb(rgbRepresentation: ColorRGBRepresentation) {
    this._rgb = {
      red: Color.inRangeRGBComponent(rgbRepresentation.red),
      green: Color.inRangeRGBComponent(rgbRepresentation.green),
      blue: Color.inRangeRGBComponent(rgbRepresentation.blue)
    };
  }

  public get hexadecimalColorCode(): string {
    return convertRGBToHexadecimalColorCode(this._rgb);
  }

  public get used(): boolean {
    return this._used;
  }

  public set used(used: boolean) {
    this._used = used;
  }

  public get shade(): string {
    return this._shade;
  }

  public set shade(shade: string) {
    this._shade = shade;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  public static fromJSON(colorJSON: any): Color {
    return new Color(
      colorJSON.id,
      colorJSON.name,
      {
        red: colorJSON.rgb.red,
        green: colorJSON.rgb.green,
        blue: colorJSON.rgb.blue
      },
      colorJSON.used,
      convertRGBToShadeName(colorJSON.rgb.red, colorJSON.rgb.green, colorJSON.rgb.blue),
      colorJSON.description
    );
  }

  public static createEmptyColor(): Color {
    return new Color(
      null,
      null,
      {
        red: 0,
        green: 0,
        blue: 0
      },
      null,
      null,
      null
    );
  }

  public static inRangeRGBComponent(decimal: number): number {
    return Math.max(0, Math.min(255, Math.round(decimal)));
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      rgb: {
        red: this._rgb.red,
        green: this._rgb.green,
        blue: this._rgb.blue
      },
      description: this._description
    } as any as JSON;
  }

  public getRgbAsCommaSeparatedValue(): string {
    return Object.values(this._rgb).join(', ');
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
