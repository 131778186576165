export enum AdvancedSearchStringInput {
  NAME = 'name',
  DRAWING_CODE = 'code',
  DRAWING_SHAPE = 'shape',
  DRAWING_NAME = 'drawing_name',
  MACHINE_PRODUCTION_ORDER = 'production',
  MACHINE_ORDER_ID = 'orderId',
  ORDER_REFERENCE = 'orderReference',
  CUSTOMER_REFERENCE = 'customerReference',
  DATE_MODIFIED = 'dateModified'
}
