import {MachineType} from '@domain/machine/machine-type.enum';
import {TimezoneUtils} from '@vdw/angular-component-library';
import {isEqual, isNil, map} from 'lodash-es';
import {PlanningMachineRun} from '../planning-machine-run';
import {PlanningOrder} from '../planning-order';
import {PlanningCreel} from './planning-creel';
import {PlanningMachineQuality} from './planning-machine-quality';

export class PlanningMachineCarpetRun extends PlanningMachineRun {
  private _machineQuality: PlanningMachineQuality;
  private _creel: PlanningCreel;

  public constructor(id: number, machineQuality: PlanningMachineQuality, creel: PlanningCreel, planningOrders: PlanningOrder[], startDate: Date, endDate: Date, compatibleWithMachine: boolean) {
    super(id, planningOrders, startDate, endDate, compatibleWithMachine);

    this._machineQuality = machineQuality;
    this._creel = creel;
  }

  public static fromJSON(planningMachineRunJSON: any, timezoneCode: string): PlanningMachineCarpetRun {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.endDate, timezoneCode);

    const planningOrders = map(planningMachineRunJSON.planningOrders, (planningOrderJSON: any) => {
      return PlanningOrder.fromCarpetJSON(planningOrderJSON, timezoneCode);
    });

    return new PlanningMachineCarpetRun(
      planningMachineRunJSON.id,
      PlanningMachineQuality.fromJSON(planningMachineRunJSON.machineQuality),
      PlanningCreel.fromJSON(planningMachineRunJSON.creel),
      planningOrders,
      startDate,
      endDate,
      planningMachineRunJSON.compatibleWithMachine
    );
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      ...super.toJSON(timezoneCode),
      machineQuality: !isNil(this._machineQuality) ? this._machineQuality.toJSON() : null,
      creel: !isNil(this._creel) ? this._creel.toJSON() : null
    } as any as JSON;
  }

  public get machineQuality(): PlanningMachineQuality {
    return this._machineQuality;
  }

  public get creel(): PlanningCreel {
    return this._creel;
  }

  public isCompatibleWithMachineType(machineType: MachineType): boolean {
    return !isEqual(machineType, MachineType.WEAVING_MACHINE);
  }
}
