import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {BackgroundJobGlobalStatusUpdate} from '@domain/background-jobs/background-job-global-status-update';
import {RealtimeBackgroundJobs} from '@infrastructure/signalr/jobs/realtime-background-jobs';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class SignalrBackgroundJobService implements RealtimeBackgroundJobs {
  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public getJobGlobalStatusChanged(): Observable<BackgroundJobGlobalStatusUpdate> {
    return this.signalrHubConnectionFactoryService
      .getSignalrHubEvents(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnJobGlobalStatusChanged')
      .pipe(map((updateForBackgroundJobGlobalStatus: any) => BackgroundJobGlobalStatusUpdate.fromJSON(updateForBackgroundJobGlobalStatus)));
  }
}
