import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {FixedSchedulePlaceholder} from '@domain/planning-prototype/carpet/fixed-schedule-placeholder';
import {OrderCarpet} from '@domain/planning-prototype/carpet/order-carpet';
import {RunCarpet} from '@domain/planning-prototype/carpet/run-carpet';
import {GenericOrder, PlanningOrderLite} from '@domain/planning-prototype/generic-order.interface';
import {GenericRun} from '@domain/planning-prototype/generic-run.interface';
import {MaintenanceType} from '@domain/planning-prototype/maintenance-type';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {PlanningItemForecast} from '@domain/planning-prototype/planning-item-forecast';
import {PlanningItemType} from '@domain/planning-prototype/planning-item-type';
import {OrderPlastic} from '@domain/planning-prototype/plastic/order-plastic';
import {RunPlastic} from '@domain/planning-prototype/plastic/run-plastic';
import {CompatibleMachine} from '@domain/planning-prototype/to-plan/compatible-machine';
import {FixedScheduleCompatibility} from '@domain/planning-prototype/to-plan/fixed-schedule-compatibility';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {OrderTufting} from '@domain/planning-prototype/tufting/order-tufting';
import {RunTufting} from '@domain/planning-prototype/tufting/run-tufting';
import {OrderWeaving} from '@domain/planning-prototype/weaving/order-weaving';
import {RunWeaving} from '@domain/planning-prototype/weaving/run-weaving';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';

export class PlanningItemHelper {
  public static isDifferentConfig(previous: PlanningItem, next: PlanningItem): boolean {
    if (previous instanceof RunCarpet && next instanceof RunCarpet) {
      return previous.creel?.id !== next.creel?.id || previous.quality?.id !== next.quality?.id || previous.creelMappingFormula !== next.creelMappingFormula;
    }
    if (previous instanceof RunWeaving && next instanceof RunWeaving) {
      return previous.weaveProduct.id !== next.weaveProduct.id;
    }
    if (previous instanceof RunTufting && next instanceof RunTufting) {
      return previous.tuftProduct.id !== next.tuftProduct.id;
    }
    if (previous instanceof RunPlastic && next instanceof RunPlastic) {
      return previous.tool?.id !== next.tool?.id;
    }
    return false;
  }

  public static getTimelineStartDate(item: PlanningItem, forecast?: PlanningItemForecast): Date {
    return item.actualStartDate ?? forecast?.estimatedStart;
  }

  public static getTimelineEndDate(item: PlanningItem, forecast?: PlanningItemForecast): Date {
    return item.actualEndDate ?? forecast?.estimatedEnd ?? item.estimatedEndDate;
  }

  public static getItemName(item: PlanningItem): string {
    if (PlanningItemHelper.isOrderItem(item)) {
      return item.productionOrder?.name ?? item.name;
    }
    if (item instanceof RunWeaving) {
      return item.weaveProduct?.name;
    }
    if (item instanceof RunTufting) {
      return item.tuftProduct?.name;
    }
    if (item instanceof RunPlastic) {
      return item.tool?.name;
    }
    if (item instanceof FixedSchedulePlaceholder) {
      return item.fixedSchedule.name;
    }

    return item.name;
  }

  public static getItemStatus(item: PlanningItem): ProductionScheduleStatus {
    if (!PlanningItemHelper.isOrderItem(item)) {
      return null;
    }

    if (!AssertionUtils.isNullOrUndefined(item.productionOrder)) {
      return item.productionOrder.status;
    }

    return AssertionUtils.isNullOrUndefined(item.actualEndDate) ? ProductionScheduleStatus.EXECUTING : ProductionScheduleStatus.EXECUTED;
  }

  public static getMaintenanceTypesForMachineType(machineType: MachineType): MaintenanceType[] {
    const result = [MaintenanceType.MAINTENANCE, MaintenanceType.MACHINE_UNAVAILABLE];
    if ([MachineType.CARPET_LOOM, MachineType.CARPET_LOOM_NO_JQ, MachineType.VELVET, MachineType.VELVET_NO_JQ].includes(machineType)) {
      result.push(MaintenanceType.CREEL_CHANGE);
    }
    if ([MachineType.WEAVING_MACHINE, MachineType.TUFT].includes(machineType)) {
      result.push(MaintenanceType.WARP_CHANGE);
    }

    return result;
  }

  public static getMachineTypesForItem(item: PlanningItem): MachineType[] {
    if (item instanceof RunCarpet || item instanceof OrderCarpet || item instanceof FixedSchedulePlaceholder) {
      return [MachineType.CARPET_LOOM, MachineType.CARPET_LOOM_NO_JQ, MachineType.VELVET, MachineType.VELVET_NO_JQ];
    }

    if (item instanceof RunWeaving || item instanceof OrderWeaving) {
      return [MachineType.WEAVING_MACHINE];
    }

    if (item instanceof RunTufting || item instanceof OrderTufting) {
      return [MachineType.TUFT];
    }

    if (item instanceof RunPlastic || item instanceof OrderPlastic) {
      return [MachineType.PLASTIC_MACHINE];
    }

    return [];
  }

  public static isOrderItem(item: PlanningItem): item is GenericOrder {
    return item instanceof OrderCarpet || PlanningItemHelper.isOrderLiteItem(item);
  }

  public static canBeInRun(item: PlanningItem): item is GenericOrder | FixedSchedulePlaceholder {
    return PlanningItemHelper.isOrderItem(item) || item instanceof FixedSchedulePlaceholder;
  }

  public static isOrderLiteItem(item: PlanningItem): item is PlanningOrderLite {
    return item instanceof OrderWeaving || item instanceof OrderTufting || item instanceof OrderPlastic;
  }

  public static isRunItem(item: PlanningItem): item is GenericRun {
    return item instanceof RunCarpet || item instanceof RunWeaving || item instanceof RunTufting || item instanceof RunPlastic;
  }

  public static getEstimatedProductionTime(toPlan: ProductionOrderToPlan, machine: PlanningEquipment): string {
    return toPlan.compatibleMachines.find((compatibleMachine: CompatibleMachine) => compatibleMachine.machine.id === machine?.id)?.estimatedProductionTime ?? '04:00:00';
  }

  public static isTargetItemCompatible(item: GenericOrder, targetItem: PlanningItem): boolean {
    if (!targetItem) {
      return true;
    }

    if (item instanceof OrderCarpet && targetItem instanceof RunCarpet) {
      return item.productionOrder?.creel.id === targetItem.creel?.id && item.productionOrder?.quality.id === targetItem.quality?.id && item.creelMappingFormula === targetItem.creelMappingFormula;
    }

    if (item instanceof OrderWeaving && targetItem instanceof RunWeaving) {
      return item.productionOrder.weaveProduct.id === targetItem.weaveProduct.id;
    }

    if (item instanceof OrderTufting && targetItem instanceof RunTufting) {
      return item.productionOrder.tuftProduct.id === targetItem.tuftProduct.id;
    }

    if (item instanceof OrderPlastic && targetItem instanceof RunPlastic) {
      return item.productionOrder.tool?.id === targetItem.tool?.id;
    }

    return false;
  }

  public static childTypeForRunType(runType: PlanningItemType): PlanningItemType {
    switch (runType) {
      case PlanningItemType.RUN_CARPET:
        return PlanningItemType.ORDER_CARPET;
      case PlanningItemType.RUN_WEAVING:
        return PlanningItemType.ORDER_WEAVING;
      case PlanningItemType.RUN_TUFTING:
        return PlanningItemType.ORDER_TUFTING;
      case PlanningItemType.RUN_PLASTIC:
        return PlanningItemType.ORDER_PLASTIC;
      default:
        throw new Error(`Unable to get child type for ${runType}`);
    }
  }

  public static isFixedScheduleCompatible(fixedSchedule: FixedScheduleToPlan, machine: PlanningEquipment, creel?: IdName, machineQuality?: IdName): boolean {
    const machineCompatibility = fixedSchedule.compatibility.find((compatibility: FixedScheduleCompatibility) => compatibility.machine.id === machine.id);
    if (AssertionUtils.isNullOrUndefined(machineCompatibility)) {
      return false;
    }
    if (AssertionUtils.isNullOrUndefined(creel) || AssertionUtils.isNullOrUndefined(machineQuality)) {
      return true;
    }
    return (
      machineCompatibility.creels.some((compatibleCreel: IdName) => compatibleCreel.id === creel.id) &&
      machineCompatibility.qualities.some((compatibleQuality: IdName) => compatibleQuality.id === machineQuality.id)
    );
  }
}
