import {CommercialUnitConversionError} from '../error/commercial-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToCommercialUnitSquareMeter(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.SQUARE_MILLIMETER:
      result = unitValue.value / 1000000;
      break;
    case Unit.SQUARE_CENTIMETER:
      result = unitValue.value / 10000;
      break;
    case Unit.SQUARE_METER:
      result = unitValue.value;
      break;
    default:
      throw new CommercialUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.METER}`);
  }

  return result;
}
