import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from './route-leaf';

export class WeftColoredYarnSetRoute extends RouteBranch {
  private _addWeftColoredYarnSet: RouteLeaf;
  private _duplicateWeftColoredYarnSet: RouteLeaf;
  private _editWeftColoredYarnSet: RouteLeaf;

  public get addWeftColoredYarnSet(): RouteLeaf {
    return this._addWeftColoredYarnSet;
  }

  public set addWeftColoredYarnSet(value: RouteLeaf) {
    this.replace(this._addWeftColoredYarnSet, value);
    this._addWeftColoredYarnSet = value;
  }

  public get duplicateWeftColoredYarnSet(): RouteLeaf {
    return this._duplicateWeftColoredYarnSet;
  }

  public set duplicateWeftColoredYarnSet(value: RouteLeaf) {
    this.replace(this._duplicateWeftColoredYarnSet, value);
    this._duplicateWeftColoredYarnSet = value;
  }

  public get editWeftColoredYarnSet(): RouteLeaf {
    return this._editWeftColoredYarnSet;
  }

  public set editWeftColoredYarnSet(value: RouteLeaf) {
    this.replace(this._editWeftColoredYarnSet, value);
    this._editWeftColoredYarnSet = value;
  }
}
