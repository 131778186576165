import {animate, AnimationTriggerMetadata, query, style, transition, trigger} from '@angular/animations';

export function skeletonViewAnimation(querySelector: string): AnimationTriggerMetadata {
  return trigger('fadeIn', [
    transition('true => false', [
      query(':leave', [style({display: 'none'})], {optional: true}),
      query(`${querySelector}, :enter`, [style({opacity: 0})], {optional: true}),
      query(`${querySelector}, :enter`, [animate('300ms ease-in', style({opacity: 1}))], {
        optional: true
      })
    ])
  ]);
}
