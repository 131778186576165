import {NameGenerator} from './name-generator';

export class CustomSettings {
  private _id: number;
  private _nameGenerator: NameGenerator;

  public constructor(id: number, nameGenerator: NameGenerator) {
    this.id = id;
    this.nameGenerator = nameGenerator;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get nameGenerator(): NameGenerator {
    return this._nameGenerator;
  }

  public set nameGenerator(value: NameGenerator) {
    this._nameGenerator = value;
  }

  public toJSON(): JSON {
    return {
      nameGenerator: this.nameGenerator.toJSON()
    } as any as JSON;
  }

  public static fromJSON(json: any): CustomSettings {
    return new CustomSettings(json.id, NameGenerator.fromJSON(json.nameGenerator));
  }
}
