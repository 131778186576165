<mat-select [formControl]="ngControl.control" [(value)]="value" [compareWith]="compareColors" disableOptionCentering>
  <mat-select-trigger>
    <div class="container">
      <div class="color-preview" [style.background-color]="getPreviewColorCode(ngControl.value)"></div>
      <h4 class="color-name">{{ ngControl.value?.name }}</h4>
    </div>
  </mat-select-trigger>
  <mat-option *ngFor="let color of colors" [attr.value]="color" [value]="color">
    <div class="container">
      <div class="color-preview" [style.background-color]="getPreviewColorCode(color)"></div>
      <h4 class="color-name">{{ color?.name }}</h4>
    </div>
  </mat-option>
</mat-select>
