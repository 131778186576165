import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class ProductionOrderRoute extends RouteBranch {
  private _addProductionOrder: RouteLeaf;
  private _addProductionOrderNewView: RouteLeaf;
  private _duplicateProductionOrder: RouteLeaf;
  private _duplicateProductionOrderNewView: RouteLeaf;
  private _addProductionOrderForMachine: RouteLeaf;
  private _addProductionOrderNewViewForMachine: RouteLeaf;
  private _addProductionOrderForRun: RouteLeaf;
  private _addProductionOrderNewViewForRun: RouteLeaf;
  private _addProductionOrderForProductConfiguration: RouteLeaf;
  private _addProductionOrderNewViewForProductConfiguration: RouteLeaf;
  private _editProductionOrder: RouteLeaf;
  private _editProductionOrderNewView: RouteLeaf;
  private _settings: RouteLeaf;
  private _details: RouteLeaf;

  public get addProductionOrder(): RouteLeaf {
    return this._addProductionOrder;
  }

  public set addProductionOrder(value: RouteLeaf) {
    this.replace(this._addProductionOrder, value);
    this._addProductionOrder = value;
  }

  public get addProductionOrderNewView(): RouteLeaf {
    return this._addProductionOrderNewView;
  }

  public set addProductionOrderNewView(value: RouteLeaf) {
    this.replace(this._addProductionOrderNewView, value);
    this._addProductionOrderNewView = value;
  }

  public get duplicateProductionOrder(): RouteLeaf {
    return this._duplicateProductionOrder;
  }

  public set duplicateProductionOrder(value: RouteLeaf) {
    this.replace(this._duplicateProductionOrder, value);
    this._duplicateProductionOrder = value;
  }

  public get duplicateProductionOrderNewView(): RouteLeaf {
    return this._duplicateProductionOrderNewView;
  }

  public set duplicateProductionOrderNewView(value: RouteLeaf) {
    this.replace(this._duplicateProductionOrderNewView, value);
    this._duplicateProductionOrderNewView = value;
  }

  public get addProductionOrderForMachine(): RouteLeaf {
    return this._addProductionOrderForMachine;
  }

  public set addProductionOrderForMachine(value: RouteLeaf) {
    this.replace(this._addProductionOrderForMachine, value);
    this._addProductionOrderForMachine = value;
  }

  public get addProductionOrderNewViewForMachine(): RouteLeaf {
    return this._addProductionOrderNewViewForMachine;
  }

  public set addProductionOrderNewViewForMachine(value: RouteLeaf) {
    this.replace(this._addProductionOrderNewViewForMachine, value);
    this._addProductionOrderNewViewForMachine = value;
  }

  public get addProductionOrderForRun(): RouteLeaf {
    return this._addProductionOrderForRun;
  }

  public set addProductionOrderForRun(value: RouteLeaf) {
    this.replace(this._addProductionOrderForRun, value);
    this._addProductionOrderForRun = value;
  }

  public get addProductionOrderNewViewForRun(): RouteLeaf {
    return this._addProductionOrderNewViewForRun;
  }

  public set addProductionOrderNewViewForRun(value: RouteLeaf) {
    this.replace(this._addProductionOrderNewViewForRun, value);
    this._addProductionOrderNewViewForRun = value;
  }

  public get addProductionOrderForProductConfiguration(): RouteLeaf {
    return this._addProductionOrderForProductConfiguration;
  }

  public set addProductionOrderForProductConfiguration(value: RouteLeaf) {
    this.replace(this._addProductionOrderForProductConfiguration, value);
    this._addProductionOrderForProductConfiguration = value;
  }

  public get addProductionOrderNewViewForProductConfiguration(): RouteLeaf {
    return this._addProductionOrderNewViewForProductConfiguration;
  }

  public set addProductionOrderNewViewForProductConfiguration(value: RouteLeaf) {
    this.replace(this._addProductionOrderNewViewForProductConfiguration, value);
    this._addProductionOrderNewViewForProductConfiguration = value;
  }

  public get editProductionOrder(): RouteLeaf {
    return this._editProductionOrder;
  }

  public set editProductionOrder(value: RouteLeaf) {
    this.replace(this._editProductionOrder, value);
    this._editProductionOrder = value;
  }

  public get editProductionOrderNewView(): RouteLeaf {
    return this._editProductionOrderNewView;
  }

  public set editProductionOrderNewView(value: RouteLeaf) {
    this.replace(this._editProductionOrderNewView, value);
    this._editProductionOrderNewView = value;
  }

  public get settings(): RouteLeaf {
    return this._settings;
  }

  public set settings(value: RouteLeaf) {
    this.replace(this._settings, value);
    this._settings = value;
  }

  public get details(): RouteLeaf {
    return this._details;
  }

  public set details(value: RouteLeaf) {
    this.replace(this._details, value);
    this._details = value;
  }
}
