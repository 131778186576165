import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class WeaveProductRoute extends RouteBranch {
  private _addWeaveProduct: RouteLeaf;
  private _duplicateWeaveProduct: RouteLeaf;
  private _editWeaveProduct: RouteLeaf;

  public get addWeaveProduct(): RouteLeaf {
    return this._addWeaveProduct;
  }

  public set addWeaveProduct(value: RouteLeaf) {
    this.replace(this._addWeaveProduct, value);
    this._addWeaveProduct = value;
  }

  public get duplicateWeaveProduct(): RouteLeaf {
    return this._duplicateWeaveProduct;
  }

  public set duplicateWeaveProduct(value: RouteLeaf) {
    this.replace(this._duplicateWeaveProduct, value);
    this._duplicateWeaveProduct = value;
  }

  public get editWeaveProduct(): RouteLeaf {
    return this._editWeaveProduct;
  }

  public set editWeaveProduct(value: RouteLeaf) {
    this.replace(this._editWeaveProduct, value);
    this._editWeaveProduct = value;
  }
}
