import {PatternVisualisation} from './pattern-visualisation';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';

export class SvgPatternVisualisation extends PatternVisualisation {
  private _svg: string;

  public constructor(svg: string) {
    super(PatternVisualisationType.PATTERN);

    this._svg = svg;
  }

  public get svg(): string {
    return this._svg;
  }

  public static fromJSON(patternVisualisationJSON: any): SvgPatternVisualisation {
    return new SvgPatternVisualisation(patternVisualisationJSON.pattern);
  }

  public toJSON(): JSON {
    return {
      pattern: this._svg,
      patternType: PatternVisualisationType[this.type]
    } as any as JSON;
  }
}
