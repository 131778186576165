import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteLeaf} from '@application/helper/routing/route-leaf';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AlertDialogResult, AssertionUtils, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {Observable, Observer} from 'rxjs';
import {AddProductionSchedulePageComponent} from '../add/add-production-schedule-page.component';

export const canDeactivateProductionScheduleInitialPreparationsGuard = (
  component: AddProductionSchedulePageComponent,
  _: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Observable<boolean> => {
  const navigationHelperService = inject(NavigationHelperService);
  const dialogBuilderFactoryService = inject(DialogBuilderFactoryService);

  return new Observable((observer: Observer<boolean>) => {
    if (component?.isDirty()) {
      if (!AssertionUtils.isNullOrUndefined(component.initialPreparationsComponent?.initialPreparationsAdditionalOptionsComponent)) {
        component.initialPreparationsComponent.getProductionSchedule();
      }

      if (
        !AssertionUtils.isNullOrUndefined(currentState) &&
        !AssertionUtils.isNullOrUndefined(nextState) &&
        navigationHelperService.isNextRouteRelatedToCurrent(getCurrentRouteLeaf(currentState.url), nextState.url)
      ) {
        confirm(observer, component, true);
      } else {
        showDiscardConfirmation(observer, component, dialogBuilderFactoryService);
      }
    } else {
      confirm(observer, component);
    }
  });
};

const showDiscardConfirmation = (observer: Observer<boolean>, component: AddProductionSchedulePageComponent, dialogBuilderFactoryService: DialogBuilderFactoryService): void => {
  dialogBuilderFactoryService
    .getBuilder()
    .openAlertDialog({
      type: DialogType.DISCARD
    })
    .subscribe((result: AlertDialogResult) => {
      if (result === AlertDialogResult.DISCARD) {
        confirm(observer, component);
      } else if (result === AlertDialogResult.CANCEL) {
        cancel(observer);
      }
    });
};

const confirm = (observer: Observer<boolean>, component: AddProductionSchedulePageComponent, navigationThroughNavigationHelper: boolean = false): void => {
  observer.next(true);
  observer.complete();

  component.notifyChangesToForm(navigationThroughNavigationHelper);
};

const cancel = (observer: Observer<boolean>): void => {
  observer.next(false);
  observer.complete();
};

const getCurrentRouteLeaf = (currentRoute: string): RouteLeaf => {
  const possibleCurrentRoutes = [
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForProductConfiguration,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForMachine,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForRun,
    RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView
  ];

  return possibleCurrentRoutes.find((routeLeaf: RouteLeaf) => RouteUtils.isRoutePathMatching(currentRoute, routeLeaf.absolutePath));
};
