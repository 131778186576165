import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddPlasticProductionOrderPageComponent} from './add/add-plastic-production-order-page.component';
import {PlasticProductionOrderOverviewPageComponent} from './overview/plastic-production-order-overview-page.component';

const shared = {parentId: NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE};

export const plasticProductionOrderRoutes = [
  Factory.createPrototypeComponentRoute(PlasticProductionOrderOverviewPageComponent, {
    route: '',
    requiredPermission: Permission.TEXFAB_PLASTIC_ORDER_VIEW,
    reuse: true,
    id: NavigationId.PLASTIC_ORDER,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddPlasticProductionOrderPageComponent, {
    route: 'add',
    requiredPermission: Permission.TEXFAB_PLASTIC_ORDER_EDIT,
    reuse: true,
    id: NavigationId.ADD_PLASTIC_ORDER,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddPlasticProductionOrderPageComponent, {
    route: 'edit/:id',
    requiredPermission: Permission.TEXFAB_PLASTIC_ORDER_VIEW,
    id: NavigationId.EDIT_PLASTIC_ORDER,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddPlasticProductionOrderPageComponent, {
    route: 'add/:id',
    requiredPermission: Permission.TEXFAB_PLASTIC_ORDER_EDIT,
    id: NavigationId.DUPLICATE_PLASTIC_ORDER,
    ...shared
  })
];

export const plasticProductionOrderRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(plasticProductionOrderRoutes);
