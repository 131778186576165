export class FoldingCorrectionProperties {
  private _useFoldingCorrection: boolean;

  public constructor(useFoldingCorrection: boolean) {
    this._useFoldingCorrection = useFoldingCorrection;
  }

  public get useFoldingCorrection(): boolean {
    return this._useFoldingCorrection;
  }

  public static fromJSON(foldingCorrectionPropertiesJSON: any): FoldingCorrectionProperties {
    return new FoldingCorrectionProperties(foldingCorrectionPropertiesJSON?.useFoldingCorrection ?? false);
  }

  public toJSON(): JSON {
    return {useFoldingCorrection: this._useFoldingCorrection} as any as JSON;
  }
}
