<div class="mobile-filter">
  <mat-form-field class="full-width">
    <input
      #inputElement
      matInput
      appDataCy="filter"
      autocomplete="off"
      [ngModel]="filteredText"
      (ngModelChange)="filterChanged($event)"
      [disabled]="disabled"
      [placeholder]="placeholder | bmsTranslate"
    />
    <button appDataCy="filter-button" mat-icon-button matSuffix *ngIf="!isIconDisabled()" class="mat-icon-action-button-sm-without-border">
      <mat-icon #iconElement [svgIcon]="icon"></mat-icon>
    </button>
  </mat-form-field>
</div>
