import {IdName} from '@domain/id-name';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {MaterialTypeOverviewList, OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {Color} from '@vdw/angular-component-library';
import {flatMap, map, uniqBy} from 'lodash-es';

export class OverviewListColorSet {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _creelPositions: OverviewListCreelPosition[];
  private readonly _alternatives: IdName[];
  private readonly _technicalCode: string;

  public constructor(id: number, name: string, creelPositions: OverviewListCreelPosition[], alternatives?: IdName[], technicalCode?: string) {
    this._id = id;
    this._name = name;
    this._creelPositions = creelPositions;
    this._alternatives = alternatives;
    this._technicalCode = technicalCode;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get creelPositions(): OverviewListCreelPosition[] {
    return this._creelPositions;
  }

  public get alternatives(): IdName[] {
    return this._alternatives;
  }

  public get technicalCode(): string {
    return this._technicalCode;
  }

  public static fromJSON(colorSetOverviewListJSON: any): OverviewListColorSet {
    return new OverviewListColorSet(
      colorSetOverviewListJSON.id,
      colorSetOverviewListJSON.name,
      map(colorSetOverviewListJSON.creelPositions, (creelPositionJSON: any) => {
        return new OverviewListCreelPosition(
          map(creelPositionJSON.creel, (itemJSON: any) => {
            return Color.fromJSON(itemJSON);
          }),
          creelPositionJSON.creelPosition,
          creelPositionJSON.percentageOfColorsInDrawingImage,
          creelPositionJSON.needsToBeFromSameBath
        );
      }),
      colorSetOverviewListJSON.alternativeColorSets?.map((alternativeColorSetJSON: any) => IdName.fromJSON(alternativeColorSetJSON)),
      colorSetOverviewListJSON.technicalCode
    );
  }

  public static fromColorSet(colorSet: ColorSet): OverviewListColorSet {
    return new OverviewListColorSet(
      colorSet.id,
      colorSet.name,
      map(colorSet.creelPositions, (creelPosition: CreelPosition, index: number) => {
        return OverviewListCreelPosition.fromCreelPosition(creelPosition, index + 1);
      })
    );
  }

  public getUniqueColors(): Color[] {
    return uniqBy(
      flatMap(this.creelPositions, (creelPosition: OverviewListCreelPosition) => {
        return creelPosition.items;
      }) as Color[],
      'id'
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      creelPositions: map(this.creelPositions, (creelPosition: OverviewListCreelPosition) => {
        return {
          creelPosition: creelPosition.position,
          creel: map(creelPosition.items, (materialType: MaterialTypeOverviewList) => materialType.toJSON()),
          needsToBeFromSameBath: creelPosition.needsToBeFromSameBath,
          percentageOfColorsInDrawingImage: creelPosition.percentageOfColorsInDrawingImage
        } as any as JSON;
      })
    } as any as JSON;
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
