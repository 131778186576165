import {Equipment} from '@domain/machine/equipment';
import {AssertionUtils, TimeUtils} from '@vdw/angular-component-library';
import {MachineGroup} from './machine-group';
import {MachineType} from './machine-type.enum';
import {PlasticsMachineType} from './plastics-machine-type';
import {StandardSpeed} from './standard-speed.enum';

export class PlasticsMachine extends Equipment {
  private _machineGroup: MachineGroup;
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _plasticsMachineType: PlasticsMachineType;

  public constructor(id: number, name: string, orderId: string, machineGroup: MachineGroup, standardSpeed: number, standardEfficiency: number, plasticsMachineType: PlasticsMachineType) {
    super(id, MachineType.PLASTIC_MACHINE, name, orderId);
    this._machineGroup = machineGroup;
    this._standardSpeed = standardSpeed;
    this._standardEfficiency = standardEfficiency;
    this._plasticsMachineType = plasticsMachineType;
  }

  public get machineGroup(): MachineGroup {
    return this._machineGroup;
  }

  public set machineGroup(machineGroup: MachineGroup) {
    this._machineGroup = machineGroup;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public set standardSpeed(standardSpeed: number) {
    this._standardSpeed = standardSpeed;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public set standardEfficiency(standardEfficiency: number) {
    this._standardEfficiency = standardEfficiency;
  }

  public get plasticsMachineType(): PlasticsMachineType {
    return this._plasticsMachineType;
  }

  public set plasticsMachineType(plasticsMachineType: PlasticsMachineType) {
    this._plasticsMachineType = plasticsMachineType;
  }

  public static fromJSON(plasticsMachineJSON: any): PlasticsMachine {
    if (plasticsMachineJSON.plasticMachineType.speedKind === StandardSpeed.SPEED) {
      plasticsMachineJSON.standardSpeedInCycleTime = TimeUtils.SECONDS_IN_A_MINUTE / plasticsMachineJSON.standardSpeedInCycleTime;
    }

    return new PlasticsMachine(
      plasticsMachineJSON.id,
      plasticsMachineJSON.name,
      plasticsMachineJSON.orderId,
      MachineGroup.fromJSON(plasticsMachineJSON.machineGroup),
      plasticsMachineJSON.standardSpeedInCycleTime,
      plasticsMachineJSON.standardEfficiency,
      AssertionUtils.isNullOrUndefined(plasticsMachineJSON.plasticMachineType) ? null : PlasticsMachineType.fromJSON(plasticsMachineJSON.plasticMachineType)
    );
  }

  public toJSON(): JSON {
    if (this.plasticsMachineType.standardSpeed === StandardSpeed.SPEED) {
      this.standardSpeed = TimeUtils.SECONDS_IN_A_MINUTE / this.standardSpeed;
    }

    const plasticsMachineJSON = super.toJSON();
    plasticsMachineJSON['standardSpeedInCycleTime'] = this.standardSpeed;
    plasticsMachineJSON['standardEfficiency'] = this.standardEfficiency;
    plasticsMachineJSON['machineGroupId'] = this.machineGroup?.id;
    plasticsMachineJSON['machineGroup'] = this.machineGroup?.toJSON();
    plasticsMachineJSON['plasticMachineTypeId'] = this.plasticsMachineType?.id;

    return plasticsMachineJSON;
  }
}
