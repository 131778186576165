import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-grid-cutting-line-component',
  templateUrl: './grid-cutting-line.component.html'
})
export class GridCuttingLineComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public hasCuttingLine(): boolean {
    return !isNil(this.params.getValue());
  }
}
