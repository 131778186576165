import {NgComponentOutlet} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {DialogModule} from '../../dialogs/dialog.module';
import {HoverDialogBuilderService} from '../../dialogs/hover-dialog/hover-dialog-builder.service';
import {ApplicationLayoutService} from './application-layout.service';

@NgModule({
  imports: [NgComponentOutlet, MatSidenavModule, MatButtonModule, MatSidenavModule, MatMenuModule, MatIconModule, DialogModule],
  providers: [ApplicationLayoutService, HoverDialogBuilderService]
})
export class ApplicationLayoutModule {}
