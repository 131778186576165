<header>
  <div class="header-content">
    <div class="icon-bar">
      <button mat-icon-button *ngIf="showBackButton" fxFlexAlign="center" [routerLink]="previousRouterLink" (click)="backClicked.emit()">
        <mat-icon svgIcon="back"></mat-icon>
      </button>
    </div>
    <div class="header-text">
      <h3><vdw-ellipsis-label [text]="title" [tooltipDisabled]="true"></vdw-ellipsis-label></h3>
      <vdw-tag *ngIf="tagValue && showTag" [value]="tagValue" [size]="tagSize"></vdw-tag>
    </div>

    <div class="icon-bar" fxLayoutAlign="end">
      <button mat-icon-button type="button" *ngIf="icon" fxFlexAlign="center" (click)="iconClicked.emit()">
        <mat-icon [svgIcon]="icon"></mat-icon>
      </button>
    </div>
  </div>
</header>
