import {ZoneErrors} from '@domain/production-schedule/zone-errors';
import {ZoneWarnings} from '@domain/production-schedule/zone-warnings';
import {isNil} from 'lodash-es';

export class RestZone {
  private _technicalWidthInDents: number;
  private _startDent: number;
  private _coloredYarnSetPathNr: number;
  private _errors: ZoneErrors;
  private _warnings: ZoneWarnings;

  public constructor(technicalWidthInDents: number, startDent: number, coloredYarnSetPathNr: number, errors?: ZoneErrors, warnings?: ZoneWarnings) {
    this._technicalWidthInDents = technicalWidthInDents;
    this._startDent = startDent;
    this._coloredYarnSetPathNr = coloredYarnSetPathNr;
    this._errors = errors;
    this._warnings = warnings;
  }

  public get technicalWidthInDents(): number {
    return this._technicalWidthInDents;
  }

  public set technicalWidthInDents(value: number) {
    this._technicalWidthInDents = value;
  }

  public get startDent(): number {
    return this._startDent;
  }

  public set startDent(value: number) {
    this._startDent = value;
  }

  public get coloredYarnSetPathNr(): number {
    return this._coloredYarnSetPathNr;
  }

  public set coloredYarnSetPathNr(value: number) {
    this._coloredYarnSetPathNr = value;
  }

  public get errors(): ZoneErrors {
    return this._errors;
  }

  public set errors(value: ZoneErrors) {
    this._errors = value;
  }

  public get warnings(): ZoneWarnings {
    return this._warnings;
  }

  public set warnings(value: ZoneWarnings) {
    this._warnings = value;
  }

  public static fromJSON(restZoneJSON: any): RestZone {
    return new RestZone(restZoneJSON.technicalWidthInDents, restZoneJSON.startDent, restZoneJSON.coloredYarnSetPathNr, restZoneJSON.errors, restZoneJSON.warnings);
  }

  public hasErrors(): boolean {
    return !isNil(this.errors);
  }

  public toJSON(): JSON {
    return {
      technicalWidthInDents: this._technicalWidthInDents,
      startDent: this._startDent,
      startPick: 0,
      coloredYarnSetPathNr: this._coloredYarnSetPathNr
    } as any as JSON;
  }
}
