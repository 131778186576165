import {getItemsForDateRange} from '@application/helper/planning/get-items-for-date-range';
import {DateRange} from '@domain/date-range';
import {CalendarItem} from '@domain/planning/calendar-item';
import {Subscription} from '@domain/profile/subscription';
import {MachineEvent} from '@presentation/pages/texbi/machine-event-board/machine-event';
import {AssertionUtils, TimezoneUtils} from '@vdw/angular-component-library';
import {has} from 'lodash-es';
import {MachineEventDeclaration} from './machine-event-declaration';

export class MachineEventGroup extends CalendarItem {
  private _name: string;
  private _events: MachineEvent[];
  private _declarations: MachineEventDeclaration[];

  public constructor(id: number, name: string, startDate: Date, endDate: Date, events: MachineEvent[], declarations: MachineEventDeclaration[]) {
    super(id, startDate, endDate);

    this._name = name;
    this._events = events;
    this._declarations = declarations;
  }

  public get name(): string {
    return this._name;
  }

  public get events(): MachineEvent[] {
    return this._events;
  }

  public get declarations(): MachineEventDeclaration[] {
    return this._declarations;
  }

  public static fromJSON(machineEventGroupJSON: any, subscription: Subscription): MachineEventGroup {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(machineEventGroupJSON.startDate, subscription.timeZone.code);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(machineEventGroupJSON.endDate, subscription.timeZone.code);

    return new MachineEventGroup(
      machineEventGroupJSON.id,
      machineEventGroupJSON.name,
      startDate,
      endDate,
      machineEventGroupJSON.events.map((machineEventJSON: any) => MachineEvent.fromJSON(machineEventJSON, subscription)),
      machineEventGroupJSON.declarations.map((machineEventJSON: any) => MachineEventDeclaration.fromJSON(machineEventJSON, subscription))
    );
  }

  public static canDeSerializeJSON(calendarItemJSON: any): boolean {
    return has(calendarItemJSON, 'events') || has(calendarItemJSON, 'declarations');
  }

  public hasMachineEvent(id: number): boolean {
    return !AssertionUtils.isNullOrUndefined(this._events.find((event: MachineEvent) => event.id === id));
  }

  public getEventsForDateRange(dateRange: DateRange): MachineEvent[] {
    return getItemsForDateRange<MachineEvent>(this._events, dateRange);
  }

  public getDeclarationsForDateRange(dateRange: DateRange): MachineEventDeclaration[] {
    return getItemsForDateRange<MachineEventDeclaration>(this._declarations, dateRange);
  }
}
