import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CardInfoMessage} from '@presentation/components/card-info/card-info-message.interface';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent {
  @Input() public message: CardInfoMessage;
  @Input() public information: string;
  @Input() public button: string = null;
  @Input() public progressButton: string = null;
  @Input() public inProgress = false;
  @Input() public authorized = true;
  @Input() public showAsColumn = true;
  @Input() public showWarningIcon = false;
  @Input() public showWarningButton = false;
  @Output() public clickButtonEvent: EventEmitter<void> = new EventEmitter<void>();

  public hasButton(): boolean {
    return !isNil(this.button);
  }

  public hasProgressButton(): boolean {
    return !isNil(this.progressButton);
  }

  public getIcon(): string {
    return this.showWarningIcon ? 'warning-full-52px' : 'info-52px';
  }

  public clickButton(): void {
    this.clickButtonEvent.emit();
  }
}
