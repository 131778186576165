import {Component, Input} from '@angular/core';
import {Maintenance} from '@domain/planning-prototype/maintenance';
import {MaintenanceType} from '@domain/planning-prototype/maintenance-type';
import {StringUtils} from '@vdw/angular-component-library';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  @Input() public maintenance: Maintenance;

  public getIconName(): string {
    return StringUtils.kebabCase(MaintenanceType[this.maintenance.maintenanceType]);
  }
}
