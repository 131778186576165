import {FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {L10nIntlService} from 'angular-l10n';
import isNumber from 'lodash-es/isNumber';
import {LocaleUtils} from '../../common/utils/locale-utils';

export class MinMaxLimitValidator {
  public static createValidator(l10nIntlService: L10nIntlService): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      let result = null;

      const minimumControl = this.getFormControlWithNameContaining(formGroup, 'min');
      const maximumControl = this.getFormControlWithNameContaining(formGroup, 'max');

      const minimumValue: number = LocaleUtils.parseNumber(minimumControl.value, l10nIntlService);
      const maximumValue: number = LocaleUtils.parseNumber(maximumControl.value, l10nIntlService);

      if (isNumber(minimumValue) && isNumber(maximumValue) && minimumValue >= maximumValue) {
        minimumControl.markAsTouched();
        maximumControl.markAsTouched();

        result = {nomatch: true};
      }

      return result;
    };
  }

  public static getFormControlWithNameContaining(formGroup: FormGroup, name: string): FormControl {
    const controlName = Object.keys(formGroup.controls).find((control: string) => control.includes(name));
    return formGroup.controls[controlName] as FormControl;
  }
}
