import {CommercialUnitConversionError} from '../error/commercial-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToCommercialUnitMeter(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.MILLIMETER:
      result = unitValue.value / 1000;
      break;
    case Unit.CENTIMETER:
      result = unitValue.value / 100;
      break;
    case Unit.DECIMETER:
      result = unitValue.value / 10;
      break;
    case Unit.METER:
      result = unitValue.value;
      break;
    default:
      throw new CommercialUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.METER}`);
  }

  return result;
}
