import {IdNameVersion} from '@domain/id-name-version';

export class IdNameVersionPosition extends IdNameVersion {
  private readonly _numberOfCreelPositions: number;

  public constructor(id: number, name: string, version: number, numberOfCreelPositions: number) {
    super(id, name, version);
    this._numberOfCreelPositions = numberOfCreelPositions;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public static fromJSON(object: any): IdNameVersionPosition {
    return new IdNameVersionPosition(object.id, object.name, object.version, object.numberOfCreelPositions);
  }
}
