import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {PaginatorComponent} from './paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [PaginatorComponent]
})
export class PaginatorModule {}
