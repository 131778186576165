import {Inject, Injectable} from '@angular/core';
import {Command} from '@application/command/command';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {PATTERNS, Patterns} from '@infrastructure/http/patterns/patterns';
import {Observable, Subject, tap} from 'rxjs';

export interface GetPatternVisualisationByPatternIdArguments {
  weaveStructureId: number;
  weavePatternId: number;
  type: PatternVisualisationType;
}

@Injectable()
export class GetPatternVisualisationByPatternId implements Command<{weaveStructureId: number; weavePatternId: number; type: PatternVisualisationType}, Observable<PatternVisualisation>> {
  private patternVisualisationLoaded = new Subject<PatternVisualisation>();

  public constructor(@Inject(PATTERNS) private readonly patterns: Patterns) {}

  public execute(getPatternVisualisationByPatternIdArgs: GetPatternVisualisationByPatternIdArguments): Observable<PatternVisualisation> {
    return this.patterns
      .getPatternVisualisationByPatternId(getPatternVisualisationByPatternIdArgs.weaveStructureId, getPatternVisualisationByPatternIdArgs.weavePatternId, getPatternVisualisationByPatternIdArgs.type)
      .pipe(tap((patternVisualisation: PatternVisualisation) => this.patternVisualisationLoaded.next(patternVisualisation)));
  }

  public patternVisualisationLoadedObservable(): Observable<PatternVisualisation> {
    return this.patternVisualisationLoaded.asObservable();
  }
}
