export class MappingForDesign {
  private _from: number;
  private _to: number;

  public constructor(from: number, to: number) {
    this._from = from;
    this._to = to;
  }

  public get from(): number {
    return this._from;
  }

  public get to(): number {
    return this._to;
  }

  public static fromJSON(mappingForDesignJSON: any): MappingForDesign {
    return new MappingForDesign(mappingForDesignJSON.from, mappingForDesignJSON.to);
  }

  public toJSON(): JSON {
    return {
      from: this._from,
      to: this._to
    } as any as JSON;
  }
}
