<mat-form-field class="full-width" floatLabel="always">
  <mat-label>{{ label }}</mat-label>
  <input matInput type="text" [formControl]="ngControl.control" [placeholder]="placeholder" />
  <button class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="reset()" *ngIf="ngControl?.control.value">
    <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
  </button>
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  <mat-hint>
    <ng-content select="[hint]"></ng-content>
  </mat-hint>
</mat-form-field>
