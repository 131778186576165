import {Inject, Injectable} from '@angular/core';
import {PlanningOrderStubFactory} from '@application/helper/planning/planning-order-stub-factory';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningItemType} from '@domain/planning/planning-item-type.enum';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {PlanningGridComponent, TimezoneUtils} from '@vdw/angular-component-library';
import moment from 'moment';
import {PLANNING_SCHEDULER, PlanningScheduler} from '../../planning/planning-scheduler/planning-scheduler';

@Injectable()
export class PlanningContextService {
  public selectedPlanningOrderId: number;
  public selectedMachine: PlanningMachine;
  public selectedPlanningItem: PlanningItem;
  public selectedPlaceholder: PlanningItem;

  public machines: PlanningMachine[] = [];
  public machinesForSelectedPlanningGroups: PlanningMachine[] = [];
  public groupedCalendarItems: CalendarItemGroup[] = [];

  public unplannedOrderItem: PlanningOrder;
  public planningGridDisplay: PlanningGridComponent;

  public isOnboardingRunning = false;
  public isDragAndDropActive = false;

  private currentSubscription: Subscription;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(PLANNING_SCHEDULER) private readonly planningScheduler: PlanningScheduler
  ) {
    this.unplannedOrderItem = PlanningOrderStubFactory.getRandomPlanningOrder();
    this.unplannedOrderItem.endDate = moment(new Date()).add(12, 'h').toDate();
    this.currentSubscription = this.authentication.getCurrentSubscription();
  }

  public resetSelectedVariables(): void {
    this.selectedMachine = null;
    this.selectedPlanningItem = null;
    this.selectedPlanningOrderId = null;
  }

  public canEditPlanningItem(planningItem: PlanningItem): boolean {
    return this.canEdit() && !planningItem.isNew() && this.canReschedulePlanningItem(planningItem);
  }

  public canEdit(): boolean {
    return !this.isOnboardingRunning && !this.planningScheduler.isUpdating;
  }

  public canEditPlanningOrder(planningOrder: PlanningOrder): boolean {
    if (!this.hasPermissionToEditOrder(planningOrder)) {
      return false;
    }

    return (
      this.canEdit() &&
      planningOrder.productionOrder.status !== ProductionScheduleStatus.EXECUTED &&
      planningOrder.productionOrder.status !== ProductionScheduleStatus.EXECUTING &&
      planningOrder.productionOrder.status !== ProductionScheduleStatus.EXECUTED_PARTIAL &&
      planningOrder.productionOrder.status !== ProductionScheduleStatus.EXECUTED_COMPLETE
    );
  }

  public canEditMachineRun(machineRun: PlanningMachineRun): boolean {
    return !machineRun.planningOrders?.some((order: PlanningOrder) => !this.canEditPlanningOrder(order));
  }

  public getCurrentDateForSubscriptionTimezone(): Date {
    return TimezoneUtils.convertToDateWithCurrentOffset(new Date().toISOString(), this.authentication.getCurrentSubscription().timeZone.code);
  }

  public hasMachinePlanningItemPlaceholder(machineId: number): boolean {
    return this.selectedMachine?.id === machineId && this.selectedPlaceholder != null && !(this.selectedPlaceholder instanceof PlanningMachineRun);
  }

  public hasMachineMachineRunPlaceholder(machineId: number): boolean {
    return this.selectedMachine?.id === machineId && this.selectedPlaceholder instanceof PlanningMachineRun;
  }

  private canReschedulePlanningItem(planningItem: PlanningItem): boolean {
    if (planningItem instanceof PlanningMachineRun) {
      return this.canEditMachineRun(planningItem);
    }

    const currentDate = this.getCurrentDateForSubscriptionTimezone();
    const isPlanningItemExecuting = moment(planningItem.startDate).isSameOrBefore(currentDate);
    const isMaintenance = planningItem.type === PlanningItemType.CREEL_CHANGE || planningItem.type === PlanningItemType.WARP_CHANGE || planningItem.type === PlanningItemType.MACHINE_UNAVAILABLE;

    return isMaintenance || !isPlanningItemExecuting;
  }

  private hasPermissionToEditOrder(planningOrder: PlanningOrder): boolean {
    return (
      (planningOrder.isCarpetOrder && this.currentSubscription?.hasPermission(Permission.TEXFAB_CARPET_QUEUE)) ||
      (planningOrder.isWeavingOrder && this.currentSubscription?.hasPermission(Permission.TEXFAB_WEAVE_QUEUE)) ||
      (planningOrder.isTuftingOrder && this.currentSubscription?.hasPermission(Permission.TEXFAB_TUFT_QUEUE))
    );
  }
}
