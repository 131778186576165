import {MaterialSet} from '@domain/textile-data/colored-yarn-set/material-set';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {map, size} from 'lodash-es';

export class YarnSet extends MaterialSet {
  public constructor(id: number, name: string, creelPositions: CreelPosition[], used: boolean) {
    super(id, name, creelPositions, used);
  }

  public static fromJSON(yarnSetJSON: any): YarnSet {
    return new YarnSet(
      yarnSetJSON.id,
      yarnSetJSON.name,
      map(yarnSetJSON.creelPositions, (creelPositionJSON: any) => {
        return CreelPosition.fromJSON(creelPositionJSON);
      }),
      yarnSetJSON.used
    );
  }

  public static createEmptyYarnSet(): YarnSet {
    return new YarnSet(null, null, [], null);
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      creelPositions: map(this.creelPositions, (creelPosition: CreelPosition, indexOfCreelPosition: number) => {
        return {
          creelPosition: indexOfCreelPosition + 1,
          creel: creelPosition.toJSON()
        };
      }),
      numberOfYarns: this.creelPositions.flatMap((creelPosition: CreelPosition) => creelPosition.horizontalPositionsOfCreelPosition).length
    } as any as JSON;
  }

  public getSizeOfCreelPositions(): number {
    return size(this.creelPositions);
  }

  public isColorSet(): boolean {
    return false;
  }

  public isYarnSet(): boolean {
    return true;
  }
}
