import {Injectable, Renderer2} from '@angular/core';
import {DraggingService} from '@application/services/dragging/dragging.service';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {AssertionUtils, BaseComponent, convertHeightInMeterToPicks, convertPixelsToPicks, Unit} from '@vdw/angular-component-library';
import {CellEditingStoppedEvent, EditableCallbackParams, RowNode, RowSelectedEvent} from 'ag-grid-community';

@Injectable()
export class DrawingLibraryHelper extends BaseComponent {
  public displayTechnicalUnit: boolean;
  public commercialUnit: Unit;
  public savedFilterModel: any;

  public constructor() {
    super();
  }

  public onRowDrag(params: {rowNode: RowNode; dragEvent: DragEvent}, productionScheduleItemMultiplier: number, draggingService: DraggingService, renderer: Renderer2): void {
    const listDrawing = new ListDrawingConfiguration(params.rowNode.data.drawing, params.rowNode.data.configurationIndex, params.rowNode.data.amount);
    listDrawing.amount = Math.floor(listDrawing.amount / productionScheduleItemMultiplier);

    draggingService.setData(listDrawing);

    const dragPreview = renderer.createElement('img');
    renderer.setAttribute(dragPreview, 'src', '/assets/images/image-placeholder.svg');

    params.dragEvent.dataTransfer.setDragImage(dragPreview, 0, 0);
  }

  public getQuantitySuggestionForPath(
    drawing: ListDrawingConfiguration,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number,
    shouldMakeSuggestion: boolean = false,
    productionScheduleMultiplier: number = 2
  ): number {
    if (!shouldMakeSuggestion) {
      drawing.amount = AssertionUtils.isNullOrUndefined(drawing.amount) ? productionScheduleMultiplier : drawing.amount;
      return drawing.amount;
    } else if (!AssertionUtils.isNullOrUndefined(drawing)) {
      drawing.amount = this.makeQuantitySuggestionCalculation(drawing, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks);
      return drawing.amount;
    }
  }

  public getWidthFilterForPath(productionSchedule: ProductionSchedule, selectedPath: ProductionSchedulePath, selectedRestPathColoredYarnSet: OverviewListColoredYarnSetWithStartDent): number {
    let widthFilter: number;

    if (AssertionUtils.isNullOrUndefined(selectedRestPathColoredYarnSet)) {
      const nrColumnsForWeftSelection = productionSchedule?.machineQuality?.numberOfColumnsToDropForWeftSelection ?? 0;

      widthFilter = selectedPath?.technicalWidthInDents + nrColumnsForWeftSelection;
    } else {
      let totalWidthOfPathsOfColoredYarnSetInDents = 0;

      const pathsOfSelectedRestPath = productionSchedule?.productionSchedulePathsOfColoredYarnSets?.filter(
        (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
          productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent === selectedRestPathColoredYarnSet.startDent
      );

      pathsOfSelectedRestPath[0]?.productionSchedulePaths?.forEach((productionSchedulePath: ProductionSchedulePath) => {
        totalWidthOfPathsOfColoredYarnSetInDents += productionSchedulePath?.technicalWidthInDents;
      });

      const nrColumnsForWeftSelection = productionSchedule?.machineQuality?.numberOfColumnsToDropForWeftSelection;
      widthFilter = pathsOfSelectedRestPath[0]?.productionScheduleColoredYarnSet?.technicalWidthInDents - totalWidthOfPathsOfColoredYarnSetInDents;

      if (widthFilter === 0) {
        widthFilter = pathsOfSelectedRestPath[0].productionScheduleColoredYarnSet.technicalWidthInDents;
      }

      widthFilter += nrColumnsForWeftSelection;
    }

    return widthFilter;
  }

  public isQuantityEditable(params: EditableCallbackParams<ListDrawingConfiguration>): boolean {
    return !params.data.drawing.repeated;
  }

  public recalculateQuantitySuggestionWhenRollLengthIsChanged(
    event: CellEditingStoppedEvent,
    rollLengthColumnId: string,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    buggyLengthMaxInPicks: number,
    availableSpaceInBuggyInPicks: number
  ): void {
    if (event.column.getColId() === rollLengthColumnId) {
      const value = this.getQuantitySuggestionForPath(event.node.data, productionSchedule, selectedPath, buggyLengthMaxInPicks, availableSpaceInBuggyInPicks, true);
      event.node.setDataValue('quantity', value);
    }
  }

  public setQuantitySuggestionForPath(
    event: RowSelectedEvent,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number
  ): void {
    const quantity = this.makeQuantitySuggestionCalculation(event.data, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks);

    if (!isNaN(quantity) || quantity < 0) {
      event.node.setDataValue('quantity', quantity);
    } else {
      event.node.setDataValue('quantity', 0);
    }
  }

  public getColorSetIdOfSelectedPath(productionSchedule: ProductionSchedule, selectedPath: ProductionSchedulePath): number {
    let colorSetId: number;

    productionSchedule.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
        if (productionSchedulePath.pathNumber === selectedPath.pathNumber) {
          colorSetId = productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.coloredYarnSet.overviewListColorSet.id;
        }
      });
    });

    return colorSetId;
  }

  public getInitializationFilterModel(
    showOnlyPreSelectedDrawings: boolean,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    selectedRestPathColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    preselectedDesignIds: number[],
    preselectedConfigurationIds: number[],
    isPreselection: boolean
  ): any {
    let filterModel: any;

    const widthFilter = this.getWidthFilterForPath(productionSchedule, selectedPath, selectedRestPathColoredYarnSet);
    const filterType = AssertionUtils.isNullOrUndefined(selectedPath) ? 'lessThanOrEqual' : 'equals';

    const designIds = preselectedDesignIds?.map((designId: number) => `${designId}`);
    const configurationIds = preselectedConfigurationIds?.map((configurationId: number) => `${configurationId}`);

    if (!AssertionUtils.isNullOrUndefined(this.savedFilterModel)) {
      filterModel = this.savedFilterModel;
    }

    const standardFilterModel = {
      missingDesign: {
        filterModels: [null, {values: ['false'], filterType: 'set'}],
        filterType: 'multi'
      },
      drawingType: {
        filterModels: [null, {values: ['BMP'], filterType: 'set'}],
        filterType: 'multi'
      }
    };

    filterModel = {...filterModel, ...standardFilterModel};

    if (!isPreselection) {
      const widthFilterModel = {
        widthInPx: {
          filterModels: [{filter: widthFilter, filterType: 'number', type: filterType}, null],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...widthFilterModel};
    } else {
      delete filterModel.widthInPx;
    }

    if (showOnlyPreSelectedDrawings && !AssertionUtils.isEmpty(designIds)) {
      let preselectionFilterModel = {
        id: {
          filterModels: [null, {values: designIds, filterType: 'set'}],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...preselectionFilterModel};
    } else {
      delete filterModel.id;
    }

    if (showOnlyPreSelectedDrawings && !AssertionUtils.isEmpty(configurationIds)) {
      const configurationFilterModel = {
        configurationId: {
          filterModels: [null, {values: configurationIds, filterType: 'set'}],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...configurationFilterModel};
    } else {
      delete filterModel.configurationId;
    }

    return filterModel;
  }

  public getPreselectedDrawingAndConfigurationIdsFilterModel(preselectedDesignIds: number[], preselectedConfigurationIds: number[]): any {
    const designIds = preselectedDesignIds?.map((designId: number) => `${designId}`);
    const configurationIds = preselectedConfigurationIds?.map((configurationId: number) => `${configurationId}`);

    if (AssertionUtils.isEmpty(designIds)) {
      return null;
    }

    let filterModel = {
      id: {
        filterModels: [null, {values: designIds, filterType: 'set'}],
        filterType: 'multi'
      }
    };

    if (!AssertionUtils.isNullOrUndefined(preselectedConfigurationIds) && !AssertionUtils.isEmpty(preselectedConfigurationIds)) {
      const configurationFilterModel = {
        configurationId: {
          filterModels: [null, {values: configurationIds, filterType: 'set'}],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...configurationFilterModel};
    }

    return filterModel;
  }

  public makeQuantitySuggestionCalculation(
    drawing: ListDrawingConfiguration,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number
  ): number {
    let availableSpace: number;
    let numberOfCloths = productionSchedule?.machine?.jacquard?.isSisal ? 1 : productionSchedule?.machineQuality?.weaveStructure?.numberOfCloths ?? 2;
    let drawingHeightInPicks = convertPixelsToPicks(drawing?.drawing?.dimensionsInPixels?.heightInPx, productionSchedule?.machineQuality?.weaveStructure?.picksPerColorLine);

    // For repeated designs we need to take into account the roll Length instead of taking the height of the drawing.
    if (drawing?.drawing?.repeated && !AssertionUtils.isNullOrUndefined(drawing.rollLengthInMeter)) {
      drawingHeightInPicks = convertHeightInMeterToPicks(drawing?.rollLengthInMeter, productionSchedule?.machineQuality.pickDensity * 1000);
    }

    if (AssertionUtils.isNullOrUndefined(numberOfCloths) || numberOfCloths === 0) {
      numberOfCloths = 2;
    }

    if (AssertionUtils.isNullOrUndefined(selectedPath)) {
      availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, availableSpaceInBuggyInPicks ?? buggyLengthMaxInPicks));
    } else {
      let longestPathHeight = 0;

      productionSchedule.productionSchedulePathsOfColoredYarnSets?.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet?.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          if (productionSchedulePath?.totalLengthInPicks > longestPathHeight) {
            longestPathHeight = productionSchedulePath?.totalLengthInPicks;
          }
        });
      });

      const isLargestPath = longestPathHeight <= selectedPath?.totalLengthInPicks;

      if (isLargestPath) {
        if (!AssertionUtils.isNullOrUndefined(availableSpaceInBuggyInPicks)) {
          availableSpace = availableSpaceInBuggyInPicks ?? buggyLengthMaxInPicks - selectedPath.totalLengthInPicks;
        } else {
          availableSpace = buggyLengthMaxInPicks - selectedPath.totalLengthInPicks;
        }
      } else {
        if (!AssertionUtils.isNullOrUndefined(availableSpaceInBuggyInPicks)) {
          availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, availableSpaceInBuggyInPicks));
        } else {
          availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, buggyLengthMaxInPicks)) - selectedPath.totalLengthInPicks;
        }
      }
    }

    const totalHeightInPicks = this.getTotalCutbarLengthInPicks(productionSchedule) + drawingHeightInPicks;

    let quantity = Math.floor((availableSpace * numberOfCloths) / totalHeightInPicks);

    quantity -= quantity % numberOfCloths;

    return quantity > 0 ? quantity : productionSchedule?.getProductionScheduleItemMultiplier();
  }

  private getMaxBuggyLengthOrLongestPath(productionSchedule: ProductionSchedule, buggyLengthMaxInPicks: number): number {
    let longestPathLengthInPicks = this.getLongestPathLengthInPicks(productionSchedule);

    return longestPathLengthInPicks !== 0 ? longestPathLengthInPicks : buggyLengthMaxInPicks;
  }

  private getLongestPathLengthInPicks(productionSchedule: ProductionSchedule): number {
    const productionSchedulePathLenths =
      productionSchedule?.productionSchedulePathsOfColoredYarnSets
        .flatMap((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePathsOfColoredYarnSet?.productionSchedulePaths ?? [])
        .map((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath?.totalLengthInPicks ?? 0) ?? [];

    return Math.max(0, ...productionSchedulePathLenths);
  }

  private getTotalCutbarLengthInPicks(productionSchedule: ProductionSchedule): number {
    let finishing = productionSchedule?.finishing;

    let totalCutbarLengthInPicks = 0;

    if (!AssertionUtils.isNullOrUndefined(finishing)) {
      const cutbarBeforeLengthInPicks = isNaN(finishing?.cutbarsInformation?.cutbarBeforeProperties?.technicalLengthInPicks)
        ? 0
        : finishing?.cutbarsInformation?.cutbarBeforeProperties?.technicalLengthInPicks;
      const cutbarAfterLengthInPicks = isNaN(finishing?.cutbarsInformation?.cutbarAfterProperties?.technicalLengthInPicks)
        ? 0
        : finishing?.cutbarsInformation?.cutbarAfterProperties?.technicalLengthInPicks;
      totalCutbarLengthInPicks = cutbarBeforeLengthInPicks + cutbarAfterLengthInPicks;
    }

    return totalCutbarLengthInPicks;
  }
}
