import {EnumUtils, TranslateService} from '@vdw/angular-component-library';

export enum FixedShape {
  RECTANGULAR,
  OVAL,
  ECLIPSE,
  CIRCLE,
  RUNNER,
  WALL_TO_WALL
}

export namespace FixedShape {
  export function getTranslationKeyForShape(drawingShape: string, translate: TranslateService): string {
    return EnumUtils.getEnumNames(FixedShape).includes(drawingShape) ? translate.instant(`DESIGN_LIBRARY.DETAILS.SHAPES.${drawingShape}`) : drawingShape;
  }
}
