import {Directive, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {Authentication} from '@infrastructure/http/authentication/authentication';
import {ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {AssertionUtils, BaseComponent, canShowErrorForErrorCodeAndFormControlForFormGroup, moment} from '@vdw/angular-component-library';
import {finalize, takeUntil} from 'rxjs/operators';

@Directive()
export abstract class ConfirmProductionScheduleBaseComponent extends BaseComponent implements OnInit {
  @Input() public productionSchedule: ProductionSchedule;
  public maximumLossOfProductionScheduleInPercent: number;
  public confirmationForm: UntypedFormGroup;
  public completingAndPlanning = false;
  public completingAndQueuing = false;
  public completingAndProcess = false;
  public processing = false;
  public readonly TEXFAB_QUEUE_PERMISSION = Permission.TEXFAB_CARPET_QUEUE;
  public readonly PERMISSION_TO_PLAN_PRODUCTION_SCHEDULE = Permission.TEXFAB_PLANNINGBOARD;
  public readonly PERMISSION_TO_PLAN_PRODUCTION_SCHEDULE_NEW = Permission.TEXFAB_PLANNINGBOARD_NEW;
  public readonly SCHEDULE_DUE_DATE_PERMISSION = Permission.SCHEDULE_DUE_DATE;
  protected authentication: Authentication;
  protected readonly productionSchedules: ProductionSchedules;
  private currentSubscription: ProfileSubscription;
  private readonly formBuilder: UntypedFormBuilder;

  public constructor(formBuilder: UntypedFormBuilder, productionSchedules: ProductionSchedules, authentication: Authentication) {
    super();
    this.formBuilder = formBuilder;
    this.productionSchedules = productionSchedules;
    this.authentication = authentication;
  }

  public datePickerFilter = (date: Date | null): boolean => {
    let result = moment().startOf('day').isSameOrBefore(date);

    if (!result) {
      const dueDate: Date = this.confirmationForm.get('dueDate').value;
      if (!AssertionUtils.isNullOrUndefined(dueDate) && !AssertionUtils.isNullOrUndefined(this.productionSchedule.dueDate)) {
        const dueDateIsUnchanged = this.confirmationForm.get('dueDate').pristine;
        if (dueDateIsUnchanged) {
          result = date.getFullYear() === dueDate.getFullYear() && date.getMonth() === dueDate.getMonth() && date.getDate() === dueDate.getDate();
        }
      }
    }

    return result;
  };

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.setFormFields();
  }

  public canShowConfirmationForm(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.confirmationForm);
  }

  public canShowErrorForFormControl(errorCode: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndFormControlForFormGroup(errorCode, formControlName, this.confirmationForm);
  }

  public canShowWarningsForProductionSchedule(): boolean {
    let result = !AssertionUtils.isNullOrUndefined(this.productionSchedule.warnings);

    if (result) {
      const {totalLossInPercent, maxBuggyLengthExceeded, minBuggyLengthReached} = this.productionSchedule.warnings;
      const currentLossInPercentExceedsMaximumLoss = totalLossInPercent > this.maximumLossOfProductionScheduleInPercent;

      result = currentLossInPercentExceedsMaximumLoss || maxBuggyLengthExceeded || !minBuggyLengthReached;
    }

    return result;
  }

  public getEarliestPossibleDueDate(): Date {
    return this.productionSchedule.dueDate ?? new Date();
  }

  public canPlanAndQueue(): boolean {
    return this.canComplete() && this.productionSchedule.machine.isMachinePremium() && !this.isUserBasic() && this.productionSchedule.addToPlanboard && !this.productionSchedule.machine.remote;
  }

  public canShowProcessAndSendButton(): boolean {
    return this.canComplete() && (this.isUserBasic() || !this.productionSchedule.addToPlanboard) && this.productionSchedule.machine.isMachinePremium() && !this.productionSchedule.machine.remote;
  }

  public canProcess(): boolean {
    return this.canComplete() && (this.isUserBasic() || !this.productionSchedule.addToPlanboard || !this.productionSchedule.machine.isMachinePremium() || this.productionSchedule.machine.remote);
  }

  public isProcessAndSendRaisedButton(): boolean {
    return this.productionSchedule.machine.isMachinePremium() && (this.isUserBasic() || !this.productionSchedule.addToPlanboard);
  }

  public process(): void {
    if (!this.completingAndQueuing && !this.completingAndPlanning && !this.completingAndProcess) {
      this.adjustProductionOrderLabelWithLatestChanges();
      this.processing = true;

      this.productionSchedules
        .process(this.productionSchedule)
        .pipe(
          takeUntil(this.unSubscribeOnViewDestroy),
          finalize(() => (this.processing = false))
        )
        .subscribe(() => this.onProductionScheduleCompleted());
    }
  }

  public completeAndPlan(): void {
    if (!this.completingAndQueuing && !this.completingAndProcess && !this.processing) {
      this.adjustProductionOrderLabelWithLatestChanges();
      this.completingAndPlanning = true;

      this.productionSchedules
        .completeAndPlan(this.productionSchedule)
        .pipe(
          takeUntil(this.unSubscribeOnViewDestroy),
          finalize(() => (this.completingAndPlanning = false))
        )
        .subscribe(() => this.onProductionScheduleCompleted());
    }
  }

  public completeAndQueue(): void {
    if (!this.completingAndPlanning && !this.completingAndProcess && !this.processing) {
      this.adjustProductionOrderLabelWithLatestChanges();
      this.completingAndQueuing = true;

      this.productionSchedules
        .completeAndQueue(this.productionSchedule)
        .pipe(
          takeUntil(this.unSubscribeOnViewDestroy),
          finalize(() => (this.completingAndQueuing = false))
        )
        .subscribe(() => this.onProductionScheduleCompleted());
    }
  }

  public hasScheduleDueDatePermission(): boolean {
    return this.currentSubscription?.hasPermission(this.SCHEDULE_DUE_DATE_PERMISSION);
  }

  private hasTexfabQueuePermission(): boolean {
    return this.currentSubscription?.hasPermission(this.TEXFAB_QUEUE_PERMISSION);
  }

  private hasPlanningPermission(): boolean {
    return this.currentSubscription.hasAtLeastOneOfThePermissions([this.PERMISSION_TO_PLAN_PRODUCTION_SCHEDULE, this.PERMISSION_TO_PLAN_PRODUCTION_SCHEDULE_NEW]);
  }

  private adjustProductionOrderLabelWithLatestChanges(): void {
    this.productionSchedule.name = this.confirmationForm.get('name').value;
    this.productionSchedule.dueDate = this.confirmationForm.get('dueDate').value;
    this.productionSchedule.comment = this.confirmationForm.get('comment').value;
  }

  private setFormFields(): void {
    this.confirmationForm = this.formBuilder.group({
      name: [this.productionSchedule.name, Validators.required, AsyncUniqueValidator.createValidator(this.productionSchedules, this.productionSchedule.name)],
      dueDate: {value: this.productionSchedule.dueDate, disabled: !this.hasScheduleDueDatePermission()},
      comment: this.productionSchedule.comment
    });
  }

  private canComplete(): boolean {
    return this.confirmationForm.valid;
  }

  private isUserBasic(): boolean {
    return !this.hasTexfabQueuePermission() || !this.hasPlanningPermission();
  }

  protected abstract onProductionScheduleCompleted(): void;

  protected abstract onProductionScheduleCompleted(): void;
}
