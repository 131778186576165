import {PlanningItemData} from './planning-item-data';

export class PlanningGroupData extends PlanningItemData {
  public get items(): readonly PlanningItemData[] {
    return this._items;
  }

  private _items: PlanningItemData[] = [];

  public addItem(item: PlanningItemData): void {
    if (this._items.includes(item)) {
      return;
    }
    this._items.push(item);
  }

  public removeItem(item: PlanningItemData): void {
    const itemIndex = this._items.indexOf(item);
    if (itemIndex < 0) {
      return;
    }
    this._items.splice(itemIndex, 1);
  }

  public sortItems(): void {
    this._items.sort((a: PlanningItemData, b: PlanningItemData) => a.startDate?.getTime() - b.startDate?.getTime());
  }
}
