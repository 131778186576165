import {isNil, max} from 'lodash-es';

export class CutbarPropertiesForMachineQuality {
  private _commercialLengthInMM: number;
  private _deficitLengthOfTopCarpetInPicks: number;
  private _deficitLengthOfBottomCarpetInPicks: number;

  public constructor(commercialLengthInMM: number, deficitLengthOfTopCarpetInPicks: number, deficitLengthOfBottomCarpetInPicks: number) {
    this._commercialLengthInMM = commercialLengthInMM;
    this._deficitLengthOfTopCarpetInPicks = deficitLengthOfTopCarpetInPicks;
    this._deficitLengthOfBottomCarpetInPicks = deficitLengthOfBottomCarpetInPicks;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public get deficitLengthOfTopCarpetInPicks(): number {
    return this._deficitLengthOfTopCarpetInPicks;
  }

  public get deficitLengthOfBottomCarpetInPicks(): number {
    return this._deficitLengthOfBottomCarpetInPicks;
  }

  public static fromJSON(cutbarPropertiesForCompatibilityWithMachineQualityJSON: any): CutbarPropertiesForMachineQuality {
    return !isNil(cutbarPropertiesForCompatibilityWithMachineQualityJSON)
      ? new CutbarPropertiesForMachineQuality(
          cutbarPropertiesForCompatibilityWithMachineQualityJSON.commercialLengthInMM,
          cutbarPropertiesForCompatibilityWithMachineQualityJSON.deficitLengthOfTopCarpetInPicks,
          cutbarPropertiesForCompatibilityWithMachineQualityJSON.deficitLengthOfBottomCarpetInPicks
        )
      : null;
  }

  public hasDeficitLength(): boolean {
    return !isNil(this.deficitLengthOfBottomCarpetInPicks) || !isNil(this.deficitLengthOfTopCarpetInPicks);
  }

  public getGreatestDeficitLength(): number {
    return this.hasDeficitLength() ? max([this.deficitLengthOfTopCarpetInPicks, this.deficitLengthOfBottomCarpetInPicks]) : 0;
  }

  public getNumberOfIncompatibilities(): number {
    let result = 0;

    if (this.hasDeficitLength()) {
      if (!isNil(this.deficitLengthOfTopCarpetInPicks)) {
        result++;
      }

      if (!isNil(this.deficitLengthOfBottomCarpetInPicks)) {
        result++;
      }
    }

    return result;
  }
}
