import {UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isEqual} from 'lodash-es';

export function isMultipleOfValidator(multiple: number): ValidatorFn {
  return (formControl: UntypedFormControl): ValidationErrors => {
    let result = null;

    if (!isMultipleOf(formControl.value, multiple)) {
      result = {multiple: true};
    }

    return result;
  };
}

export function isMultipleOf(value: number, multiple: number): boolean {
  return isEqual(value % multiple, 0);
}
