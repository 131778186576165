import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {FooterConstants} from './footer.constants';

@Component({
  selector: 'vdw-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent {
  @Input() public locked = false;
  @Input() public centered = false;
  @Input() public chooseEventName = 'ANGULAR_COMPONENT_LIBRARY.FOOTER.CONFIRM_SELECTION';
  @Input() public withChooseButton = true;
  @Input() public chooseDialogCloseValue;
  @Input() public chooseEventInProgress = false;
  @Input() public cancelEventName = 'ANGULAR_COMPONENT_LIBRARY.FOOTER.CANCEL';
  @Input() public withCancelButton = true;
  @Input() public withSaveButton = false;
  @Input() public canShowBMSTheme = false;
  @Output() public cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCloseEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCreateNewEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public chooseEvent: EventEmitter<void> = new EventEmitter<void>();
  public saveTimedOutCloser: NodeJS.Timeout;
  public readonly CHOOSE_BUTTON_CLASS_NAME = FooterConstants.CHOOSE_BUTTON_CLASS_NAME;

  public constructor() {
    super();
  }

  public cancelButtonClicked(): void {
    this.cancelEvent.emit();
  }

  public saveButtonClicked(): void {
    this.saveEvent.emit();
  }

  public saveAndCloseButtonClicked(): void {
    this.saveAndCloseEvent.emit();
  }

  public saveAndCreateNewButtonClicked(): void {
    this.saveAndCreateNewEvent.emit();
  }

  public chooseButtonClicked(): void {
    this.chooseEvent.emit();
  }

  public hasSaveAndCloseButton(): boolean {
    return this.saveAndCloseEvent.observed;
  }

  public hasSaveAndCreateNewButton(): boolean {
    return this.saveAndCreateNewEvent.observed;
  }

  public hasSaveMenu(): boolean {
    return this.hasSaveAndCloseButton() || this.hasSaveAndCreateNewButton();
  }

  public hasDefaultCancelDialogButton(): boolean {
    return this.centered && !this.cancelEvent.observed;
  }

  public hasDefaultChooseDialogButton(): boolean {
    return !this.chooseEvent.observed;
  }

  public hasHintsElement(): boolean {
    return !AssertionUtils.isNullOrUndefined(document.querySelector('[hints]'));
  }
}
