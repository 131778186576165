import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ToastrModule} from 'ngx-toastr';
import {TranslationModule} from '../../translation/translation.module';
import {ToastComponent} from './toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, TranslationModule, MatIconModule, FlexLayoutModule, MatButtonModule, ToastrModule.forRoot({toastComponent: ToastComponent})],
  exports: [ToastComponent]
})
export class ToastModule {}
