import {CommercialDimensions} from '@domain/commercial-dimensions';
import {CutbarsLength} from '@domain/production-schedule/cutbars-length';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {EpDrawing} from '@domain/production-schedule/ep-drawing';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {NotImplementedError} from '@vdw/angular-component-library';
import {isUndefined} from 'lodash-es';
import {TechnicalDimensions} from '../technical-dimensions';
import {ProductionScheduleItemInPathGroupStatus} from './production-schedule-item-in-path-group-status';
import {ProductionScheduleItemRecolorInformation} from './production-schedule-item-recolor-information';

export class EpProductionScheduleItemInPathGroup extends ProductionScheduleItemInPathGroup {
  private _cutbarsLength: CutbarsLength;

  public constructor(
    drawing: EpDrawing,
    quantity: number,
    commercialDimensionsInMM: CommercialDimensions,
    technicalDimensions: TechnicalDimensions,
    cutbarsLength: CutbarsLength,
    isProductionItem: boolean,
    mainColorPosition: number,
    borderColorPosition: number,
    guid: string,
    amountProduced: number,
    status: ProductionScheduleItemInPathGroupStatus,
    orderLineId: number = null,
    id: number = null,
    epId: number = null,
    totalWovenHeightInPicks: number = null,
    totalWovenHeightInMM: number = null,
    amountIncomplete: number = null
  ) {
    super(
      drawing,
      quantity,
      commercialDimensionsInMM,
      technicalDimensions,
      isProductionItem,
      mainColorPosition,
      borderColorPosition,
      guid,
      amountProduced,
      status,
      DrawingType.EP,
      orderLineId,
      id,
      epId,
      totalWovenHeightInPicks,
      totalWovenHeightInMM,
      amountIncomplete
    );

    this._cutbarsLength = cutbarsLength;
  }

  public get cutbarsLength(): CutbarsLength {
    return this._cutbarsLength;
  }

  public static fromJSON(productionScheduleItemInPathGroup: any): EpProductionScheduleItemInPathGroup {
    throw new NotImplementedError();
  }

  public getCommercialCutbarsLengthInMM(): number {
    return this.cutbarsLength.cutbarBefore.commercialLengthInMM + this.cutbarsLength.cutbarAfter.commercialLengthInMM;
  }

  public getTechnicalCutbarsLengthInNumberOfPicks(): number {
    return this.cutbarsLength.cutbarBefore.technicalLengthInNumberOfPicks + this.cutbarsLength.cutbarAfter.technicalLengthInNumberOfPicks;
  }

  public getCutbarInformation(): CutbarsLength {
    return this.cutbarsLength;
  }

  public canBeRecolored(): boolean {
    return true;
  }

  public hasCutbarBefore(): boolean {
    return !isUndefined(this.cutbarsLength.cutbarBefore);
  }

  public hasCutbarAfter(): boolean {
    return !isUndefined(this.cutbarsLength.cutbarAfter);
  }

  public toJSON(): JSON {
    const result = super.toJSON();

    result['cutbarBefore'] = {
      commercialLengthInMM: this.cutbarsLength.cutbarBefore.commercialLengthInMM,
      technicalLengthInPicks: this.cutbarsLength.cutbarBefore.technicalLengthInNumberOfPicks
    };
    result['cutbarAfter'] = {
      commercialLengthInMM: this.cutbarsLength.cutbarAfter.commercialLengthInMM,
      technicalLengthInPicks: this.cutbarsLength.cutbarAfter.technicalLengthInNumberOfPicks
    };

    return result;
  }

  public recolorDrawing(recolorInformation: ProductionScheduleItemRecolorInformation): void {
    this.drawing.image.data = recolorInformation.imageData;
    this.commercialDimensionsInMM = recolorInformation.commercialDimensionsInMM;
    this.mainColorPosition = recolorInformation.mainColorPosition;
    this.borderColorPosition = recolorInformation.borderColorPosition;
    this.isRecolored = true;
  }
}
