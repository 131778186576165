import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PathLayoutTemplatePath} from '@domain/path-layout-template/path-layout-template-path';
import {PageUrls} from '@domain/textile-data/page-urls';
import {HttpPathLayoutTemplatesService} from '@infrastructure/http/path-layout-template/http-path-layout-templates.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {AgGridUtils, BaseComponent, ColDefBuilderFactoryService, convertToCommercialUnitCentimeter, LinkIconRendererComponent, StringUtils, Unit} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ITooltipParams, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {takeUntil} from 'rxjs';

@Component({
  templateUrl: './path-layout-template-overview-page.component.html'
})
export class PathLayoutTemplateOverviewPageComponent extends BaseComponent implements OnInit {
  public readonly GRID_IDENTIFIER = GridIdentifier.PATH_LAYOUT_TEMPLATE_OVERVIEW;
  public readonly textileDataType = TextileDataType;

  public listOfPathLayoutTemplates: PathLayoutTemplate[];

  private pageUrls: PageUrls;

  public constructor(
    public readonly pathLayoutTemplates: HttpPathLayoutTemplatesService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getPathLayoutTemplates();
    this.pageUrls = LastModifiedCardUtils.getPageUrls('pathLayoutTemplate');
  }

  public getColumnDefsForListOfPathLayoutTemplates(): ColDef[] {
    return [
      this.colDefBuilderFactoryService

        .getBuilder()
        .withField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withField('loomGroup', true).withHeaderName('PATH_LAYOUT_TEMPLATE.LOOM_TYPE').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('paths')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.PATHS')
        .withValueGetter((params: ValueGetterParams) => (params.data as PathLayoutTemplate).pathLayoutTemplatePaths.length, true)
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .withNumericMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('widths')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.OPTIONAL_SETTINGS.PATH_WIDTHS')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(this.getPathsWidth(params), Unit.CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(this.getPathsWidth(params), Unit.CENTIMETER))
        .withFilterValueGetter((params: ValueGetterParams) => this.getPathsWidth(params))
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('totalWidth')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.TOTAL_WIDTH')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(this.getTotalWidth(params), Unit.CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(this.getTotalWidth(params), Unit.CENTIMETER))
        .withFilterValueGetter((params: ValueGetterParams) => this.getTotalWidth(params))
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .withNumericMultiFilter()
        .build()
    ];
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(this.pageUrls.settings);
  }

  private getTotalWidth(params: ICellRendererParams | ITooltipParams | ValueGetterParams): string {
    const totalWidthInMM = (params.data as PathLayoutTemplate).pathLayoutTemplatePaths.reduce((width: number, pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      return width + pathLayoutTemplatePath.widthInMM;
    }, 0);

    const totalWidthInCM = convertToCommercialUnitCentimeter({
      unit: Unit.MILLIMETER,
      value: totalWidthInMM
    });

    return totalWidthInCM.toFixed(2).toString();
  }

  private getPathsWidth(params: ICellRendererParams | ITooltipParams | ValueGetterParams): string {
    return (params.data as PathLayoutTemplate).pathLayoutTemplatePaths
      .map((pathLayoutTemplatePath: PathLayoutTemplatePath) =>
        convertToCommercialUnitCentimeter({
          unit: Unit.MILLIMETER,
          value: pathLayoutTemplatePath.widthInMM
        })
      )
      .join(', ');
  }

  private buildAgGridCellTextWithUnit(value: string, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService);
  }

  private buildAgGridCellTooltipWithUnit(value: string, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTooltipWithUnit(value, defaultUnit, this.l10nIntlService);
  }

  private getPathLayoutTemplates(): void {
    this.pathLayoutTemplates
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((pathLayoutTemplates: PathLayoutTemplate[]) => (this.listOfPathLayoutTemplates = pathLayoutTemplates));
  }
}
