import {InjectionToken} from '@angular/core';
import {ProfileInfo} from '@domain/profile/profile-info';
import {Versionable} from '@domain/profile/versionable';
import {Observable} from 'rxjs';
import {CompanySubscriptions} from './company-subscriptions';

export const PROFILE = new InjectionToken<Profile>('Profile');

export interface Profile {
  getCreditsAmount(): Observable<number>;

  getProfileInfo(): Observable<ProfileInfo>;

  getSubscriptions(shouldCancelRequest?: boolean): Observable<CompanySubscriptions>;

  getVersionables(): Observable<Versionable[]>;

  getExternalApplicationKey(): Observable<string>;

  signTermsAndConditions(): Observable<void>;
}
