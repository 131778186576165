import {MachineRoleCode} from '@domain/machine/machine-role-code';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineSummaryJacquard} from '@domain/production-schedule/machine-summary-jacquard';
import {isEqual, isNil} from 'lodash-es';

export class MachineSummary {
  private _id: number;
  private _name: string;
  private _dentsForFabric: number;
  private _jacquard: MachineSummaryJacquard;
  private _roleCode: MachineRoleCode;
  private _type: MachineType;
  private _hmiVersion: string;
  private _remote: boolean;

  public constructor(id: number, name: string, dentsForFabric: number, jacquard: MachineSummaryJacquard, roleCode: MachineRoleCode, type: MachineType, hmiVersion?: string, remote?: boolean) {
    this._id = id;
    this._name = name;
    this._dentsForFabric = dentsForFabric;
    this._jacquard = jacquard;
    this._roleCode = roleCode;
    this._type = type;
    this._hmiVersion = hmiVersion;
    this._remote = remote ?? false;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get dentsForFabric(): number {
    return this._dentsForFabric;
  }

  public set dentsForFabric(value: number) {
    this._dentsForFabric = value;
  }

  public get jacquard(): MachineSummaryJacquard {
    return this._jacquard;
  }

  public get roleCode(): MachineRoleCode {
    return this._roleCode;
  }

  public set roleCode(roleCode: MachineRoleCode) {
    this._roleCode = roleCode;
  }

  public get type(): MachineType {
    return this._type;
  }

  public get hmiVersion(): string {
    return this._hmiVersion;
  }

  public get remote(): boolean {
    return this._remote;
  }

  public static fromJSON(machineSummaryJSON: any, fromProductionSchedule: boolean = false): MachineSummary {
    return new MachineSummary(
      machineSummaryJSON.id,
      machineSummaryJSON.name,
      machineSummaryJSON.dentsForFabric,
      !isNil(machineSummaryJSON.jacquard) ? MachineSummaryJacquard.fromJSON(machineSummaryJSON.jacquard) : null,
      MachineRoleCode[`${machineSummaryJSON.roleCode}`],
      MachineType[`${fromProductionSchedule ? machineSummaryJSON.type : machineSummaryJSON.machineType}`],
      machineSummaryJSON.hmiVersion,
      machineSummaryJSON.remoteMachine
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      dentsForFabric: this._dentsForFabric,
      jacquard: !isNil(this._jacquard) ? this._jacquard.toJSON() : null,
      roleCode: !isNil(this._roleCode) ? MachineRoleCode[this._roleCode] : null,
      type: MachineType[this._type],
      remoteMachine: this._remote
    } as any as JSON;
  }

  public getTotalWidthInDents(): number {
    return this.dentsForFabric;
  }

  public allowsEmptySpaceInPaths(): boolean {
    return isNil(this._jacquard) || this._jacquard.allowEmptySpaceInPath;
  }

  public isMachinePremium(): boolean {
    return !isNil(this._roleCode) && isEqual(this._roleCode, MachineRoleCode['M-P']);
  }

  public canHaveRestAndFreeZonesAndPathLabels(): boolean {
    return this._type === MachineType.CARPET_LOOM || this._type === MachineType.VELVET;
  }
}
