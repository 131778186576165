<mat-card class="full-height">
  <mat-card-header fxLayout="row" fxLayoutAlign="start center">
    <div mat-card-avatar>
      <mat-icon [svgIcon]="getTextileDataIcon()"></mat-icon>
    </div>
    <mat-card-title fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex fxLayout="column" class="header-title">
        <span class="title">{{ getTextileDataName() }}</span>
        <span *ngIf="canShowTextileDataAmount()" class="amount">{{ getTextileDataAmount() }}</span>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="form-scrollable-content">
    <article>
      <div fxFlex fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxFlex fxLayoutAlign="start center" *ngIf="creelMap">
          <mat-button-toggle-group [value]="!showActiveCreelMap" (change)="onCreelMapViewModeChange($event)">
            <mat-button-toggle [value]="true">{{ 'MACHINE.DETAILS.THEORETICAL' | bmsTranslate }}</mat-button-toggle>
            <mat-button-toggle [value]="false">{{ 'MACHINE.DETAILS.ACTIVE' | bmsTranslate }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div fxLayout="row">
          <ag-grid-angular #creelPositionsGrid fxFlex class="ag-theme-vdw" domLayout="autoHeight" [gridOptions]="gridOptionsForListOfCreelPositions" [rowData]="listOfCreelPositions"></ag-grid-angular>
        </div>
      </div>
    </article>
  </mat-card-content>
</mat-card>
