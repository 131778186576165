import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectionListItem} from './selection-list-item.interface';

@Component({
  selector: 'vdw-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list-component.scss']
})
export class SelectionListComponent {
  @Input() public options: SelectionListItem[];
  @Output() public optionsChanged = new EventEmitter<SelectionListItem[]>();
  public value: {[key: string]: boolean} = {something: true};

  public onSelectionChanged(optionKey: string, event: any): void {
    this.options.find((option: SelectionListItem) => option.name === optionKey).value = event.checked;
    this.optionsChanged.emit(this.options);
  }
}
