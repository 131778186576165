import {Injectable} from '@angular/core';
import {Command} from '@application/command/command';
import {AlertDialogResult, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {Observable, of as observableOf} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {ConfirmProductionOrderDeletionParameters} from './confirm-production-order-deletion-parameters';

@Injectable()
export class ConfirmProductionOrderDeletionCommand implements Command<ConfirmProductionOrderDeletionParameters, void> {
  public constructor(private readonly dialogBuilderFactoryService: DialogBuilderFactoryService) {}

  public execute({title, message}: ConfirmProductionOrderDeletionParameters): Observable<void> {
    return this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: title,
        messageText: message,
        type: DialogType.DELETE
      })
      .pipe(
        filter((result: AlertDialogResult) => {
          return isEqual(result, AlertDialogResult.CONFIRM);
        }),
        switchMap(() => observableOf(void 0))
      );
  }
}
