import {getItemsForDateRange} from '@application/helper/planning/get-items-for-date-range';
import {DateRange} from '@domain/date-range';
import {moment, TimeUtils, TimezoneUtils} from '@vdw/angular-component-library';
import {each, every, isEmpty, isEqual, last, map, some, sumBy} from 'lodash-es';
import {Moment} from 'moment';
import {PlanningItem} from './planning-item';
import {PlanningItemType} from './planning-item-type.enum';
import {PlanningOrder} from './planning-order';

export abstract class PlanningMachineRun extends PlanningItem {
  private _planningOrders: PlanningOrder[];
  private _compatibleWithMachine: boolean;

  public constructor(id: number, planningOrders: PlanningOrder[], startDate: Date, endDate: Date, compatibleWithMachine: boolean) {
    super(id, startDate, endDate, PlanningItemType.RUN);

    this._planningOrders = planningOrders;
    this._compatibleWithMachine = compatibleWithMachine;
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      id: this.id,
      startDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._startDate, timezoneCode),
      endDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._endDate, timezoneCode),
      type: PlanningItemType[this.type],
      compatibleWithMachine: this._compatibleWithMachine,
      planningOrders: map(this.planningOrders, (planningOrder: PlanningOrder) => {
        return planningOrder.toJSON(timezoneCode);
      })
    } as any as JSON;
  }

  public get planningOrders(): PlanningOrder[] {
    return this._planningOrders;
  }

  public set planningOrders(planningOrders: PlanningOrder[]) {
    this._planningOrders = planningOrders;
  }

  public get hasDuePlanningOrders(): boolean {
    return some(this.planningOrders, (planningOrder: PlanningOrder) => {
      return planningOrder.isProductionOrderDueDateBeforeEndDate && !planningOrder.productionOrder.completed;
    });
  }

  public get completed(): boolean {
    return !isEmpty(this._planningOrders) && every(this.planningOrders, ['productionOrder.completed', true]);
  }

  public get executing(): boolean {
    return some(this.planningOrders, ['productionOrder.executing', true]) || (some(this._planningOrders, ['productionOrder.completed', true]) && !this.completed);
  }

  public get compatibleWithMachine(): boolean {
    return this._compatibleWithMachine;
  }

  public get uniqueId(): string {
    return `run-${this._id}`;
  }

  public get hasPlanningOrders(): boolean {
    return !isEmpty(this._planningOrders);
  }

  public getOrdersForDateRange(dateRange: DateRange): PlanningOrder[] {
    return getItemsForDateRange<PlanningOrder>(this._planningOrders, dateRange);
  }

  public reschedule(startDate: Date, endDate: Date): void {
    const momentStartDate: Moment = moment(startDate);
    each(this.planningOrders, (planningOrder: PlanningOrder) => {
      const duration = planningOrder.totalRunningTimeInMs;
      planningOrder.startDate = momentStartDate.toDate();
      planningOrder.endDate = momentStartDate.add(duration).toDate();
    });

    super.reschedule(startDate, endDate);
  }

  public canBeRescheduled(): boolean {
    return !this.completed && !this.executing;
  }

  public rescheduleBasedOnPlanningOrders(): void {
    const startDateWithPlanningOrdersTotalRunningTime = moment(this.startDate).add(this.getPlanningOrdersTotalRunningTimeInMs(), TimeUtils.MILLISECONDS_UNIT).toDate();

    if (!moment(this.endDate).isSameOrAfter(startDateWithPlanningOrdersTotalRunningTime)) {
      this.reschedule(this.startDate, startDateWithPlanningOrdersTotalRunningTime);
    } else {
      this.reschedule(this.startDate, this.endDate);
    }
  }

  public updateDuration(newDurationInMilliseconds: number): void {
    const oldRunDurationInMilliseconds = moment(this._endDate).diff(this._startDate, TimeUtils.MILLISECONDS_UNIT);

    if (!isEqual(newDurationInMilliseconds, oldRunDurationInMilliseconds)) {
      const momentStartDate = moment(this._startDate);
      const emptySpaceDurationInMs = moment(this._endDate).diff(last(this._planningOrders).endDate, TimeUtils.MILLISECONDS_UNIT);

      each(this._planningOrders, (planningOrder: PlanningOrder) => {
        const planningOrderNewDurationInMilliseconds =
          (planningOrder.totalRunningTimeInMs * (newDurationInMilliseconds - emptySpaceDurationInMs)) / (oldRunDurationInMilliseconds - emptySpaceDurationInMs);

        planningOrder.startDate = momentStartDate.toDate();
        planningOrder.endDate = momentStartDate.add(planningOrderNewDurationInMilliseconds).toDate();
      });

      this._endDate = moment(this._startDate).add(newDurationInMilliseconds, TimeUtils.MILLISECONDS_UNIT).toDate();

      this.updateUuid();
    }
  }

  public fitOrders(): void {
    this._planningOrders.sort((a: PlanningOrder, b: PlanningOrder) => a.startDate.getTime() - b.startDate.getTime());
    for (let i = 0; i < this._planningOrders.length; i++) {
      const element = this._planningOrders[i];
      if (i === 0) {
        element.updateStartWhilePreservingDuration(this.startDate);
        continue;
      }
      const prevElement = this._planningOrders[i - 1];
      element.updateStartWhilePreservingDuration(prevElement.endDate);
    }
  }

  private getPlanningOrdersTotalRunningTimeInMs(): number {
    return sumBy(this.planningOrders, 'totalRunningTimeInMs');
  }
}
