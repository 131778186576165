import {Component} from '@angular/core';
import {FilterComponent} from '../filter.component';

@Component({
  selector: 'vdw-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss']
})
export class MobileFilterComponent extends FilterComponent {
  public filterChanged(newValue: string): void {
    this.filteredText = newValue;
    this.filteredTextChanged.emit(this.filteredText);
  }
}
