import {IdName} from '@domain/id-name';
import {ObjectUtils} from '@vdw/angular-component-library';

export class GroupedDrawing {
  private _quality: IdName;
  private _colorSet: IdName;
  private _commercialWidthInCM: number;
  private _technicalWidthInPx: number;
  private _mainColor: IdName;
  private _borderColor: IdName;
  private _shape: IdName;
  private _drawingIds: number[];
  private _configurationIds: number[];

  public constructor(
    quality: IdName,
    colorSet: IdName,
    commercialWidthInCM: number,
    technicalWidthInPx: number,
    mainColor: IdName,
    borderColor: IdName,
    shape: IdName,
    drawingIds: number[],
    configurationIds: number[]
  ) {
    this._quality = quality;
    this._colorSet = colorSet;
    this._commercialWidthInCM = commercialWidthInCM;
    this._technicalWidthInPx = technicalWidthInPx;
    this._mainColor = mainColor;
    this._borderColor = borderColor;
    this._shape = shape;
    this._drawingIds = drawingIds;
    this._configurationIds = configurationIds;
  }

  public static fromJSON(groupedDrawingJSON: any): GroupedDrawing {
    return new GroupedDrawing(
      ObjectUtils.tryGetValue(groupedDrawingJSON.quality, new IdName(null, '')),
      ObjectUtils.tryGetValue(groupedDrawingJSON.colorSet, new IdName(null, '')),
      ObjectUtils.tryGetValue(groupedDrawingJSON.commercialWidthInCM?.value, ''),
      ObjectUtils.tryGetValue(groupedDrawingJSON.technicalWidthInPx?.value, ''),
      ObjectUtils.tryGetValue(groupedDrawingJSON.mainColor, new IdName(null, '')),
      ObjectUtils.tryGetValue(groupedDrawingJSON.borderColor, new IdName(null, '')),
      ObjectUtils.tryGetValue(groupedDrawingJSON.shape, new IdName(null, '')),
      groupedDrawingJSON.drawings?.map((drawing: any) => parseInt(drawing.id)),
      groupedDrawingJSON.configurations?.map(Number)
    );
  }

  public get quality(): IdName {
    return this._quality;
  }

  public set quality(value: IdName) {
    this._quality = value;
  }

  public get colorSet(): IdName {
    return this._colorSet;
  }

  public set colorSet(value: IdName) {
    this._colorSet = value;
  }

  public get commercialWidthInCM(): number {
    return this._commercialWidthInCM;
  }

  public set commercialWidthInCM(value: number) {
    this._commercialWidthInCM = value;
  }

  public get technicalWidthInPx(): number {
    return this._technicalWidthInPx;
  }

  public set technicalWidthInPx(value: number) {
    this._technicalWidthInPx = value;
  }

  public get mainColor(): IdName {
    return this._mainColor;
  }

  public set mainColor(value: IdName) {
    this._mainColor = value;
  }

  public get borderColor(): IdName {
    return this._borderColor;
  }

  public set borderColor(value: IdName) {
    this._borderColor = value;
  }

  public get shape(): IdName {
    return this._shape;
  }

  public set shape(value: IdName) {
    this._shape = value;
  }

  public get drawingIds(): number[] {
    return this._drawingIds;
  }

  public set drawingIds(value: number[]) {
    this._drawingIds = value;
  }

  public get configurationIds(): number[] {
    return this._configurationIds;
  }

  public set configurationIds(value: number[]) {
    this._configurationIds = value;
  }
}
