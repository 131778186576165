import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputL10nDecimalDirective} from './input-l10n-decimal.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InputL10nDecimalDirective],
  exports: [InputL10nDecimalDirective]
})
export class InputL10nDecimalModule {}
