import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {PlanningOrderData} from '@domain/planning/planning-order-data';

@Component({
  selector: 'app-unplanned-order-card',
  templateUrl: './unplanned-order-card.component.html',
  styleUrls: ['./unplanned-order-card.component.scss']
})
export class UnplannedOrderCardComponent {
  @Input() public unplannedOrder: PlanningOrderData;
  @Output() public deleteUnplannedOrder: EventEmitter<void> = new EventEmitter<void>();
  @Output() public unplannedOrderDragStart: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
  @Output() public unplannedOrderDrag: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
  @Output() public unplannedOrderDragEnd: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.is-being-dragged') public isBeingDragged = false;

  public delete(): void {
    this.deleteUnplannedOrder.emit();
  }

  public onDragStart(event: DragEvent): void {
    this.isBeingDragged = true;
    this.unplannedOrderDragStart.emit(event);
  }

  public onDrag(event: DragEvent): void {
    this.unplannedOrderDrag.emit(event);
  }

  public onDragEnd(): void {
    this.isBeingDragged = false;
    this.unplannedOrderDragEnd.emit();
  }
}
