import {PlanningEquipment} from './planning-equipment';
import {PlanningItem} from './planning-item';
import {PlanningItemFactory} from './planning-item-factory';

export class PlanningLine {
  private _id: number;
  private _name: string;
  private _parentEquipment: PlanningEquipment;
  private _planningItems: PlanningItem[];

  public constructor(id: number, name: string, parentEquipment: PlanningEquipment, planningItems: PlanningItem[]) {
    this._id = id;
    this._name = name;
    this._parentEquipment = parentEquipment;
    this._planningItems = planningItems;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get parentEquipment(): PlanningEquipment {
    return this._parentEquipment;
  }

  public get planningItems(): PlanningItem[] {
    return this._planningItems;
  }

  public set planningItems(value: PlanningItem[]) {
    this._planningItems = value;
  }

  public static fromJSON(json: any): PlanningLine {
    return new PlanningLine(json.id, json.name, PlanningEquipment.fromJSON(json.parentEquipment), json.planningItems?.map(PlanningItemFactory.fromJSON));
  }

  public toJSON(clearCompletedItems: boolean = false): JSON {
    const planningItems = [...(this.planningItems ?? [])];
    planningItems.sort(PlanningItem.compareBySequenceNumber);
    return {
      id: this.id,
      equipmentId: this.parentEquipment.id,
      planningItems: planningItems.filter((item: PlanningItem) => !clearCompletedItems || !item.isCompleted).map((item: PlanningItem) => item.toJSON(clearCompletedItems))
    } as any as JSON;
  }
}
