<section fxLayout="column" fxFlex>
  <vdw-header *ngIf="!isMobile()" [title]="'ORDERBOOK.CONFLICT_ORDERLINE_TITLE' | bmsTranslate" svgIcon="error-full-52px" [canShowClose]="true"></vdw-header>
  <vdw-mobile-page-header *ngIf="isMobile()" [title]="'ORDERBOOK.CONFLICT_ORDERLINE_TITLE' | bmsTranslate" (backClicked)="cancel()"></vdw-mobile-page-header>
  <article fxFlex class="dialog-content" fxLayout="column" fxLayoutGap="24px">
    <p class="b1 hint" [class.info]="isMobile()">{{ 'ORDERBOOK.CONFLICT_ORDERLINE_HINT' | bmsTranslate }}</p>
    <ag-grid-angular
      #conflictsWithOrderLinesGrid
      class="ag-theme-vdw"
      [gridOptions]="gridOptionsForConflictsWithOrderLines"
      [rowData]="conflictedOrderLines"
      [class.ag-grid-mobile]="isMobile()"
    ></ag-grid-angular>
  </article>

  <vdw-footer
    cancelEventName="GENERAL.ACTIONS.IGNORE"
    [chooseEventName]="getChooseEventName()"
    [centered]="true"
    [withCancelButton]="!isMobile()"
    (cancelEvent)="dismiss()"
    (chooseEvent)="chooseEvent()"
  ></vdw-footer>
</section>
