import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DelayedClassDirective} from './delayed-class.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DelayedClassDirective],
  exports: [DelayedClassDirective]
})
export class DelayedClassModule {}
