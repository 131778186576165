import {NameGeneratorPartType} from './enums/name-generator-part-type.enum';
import {ParametersMap} from './parameters/parameters-map.interface';

export class NameGeneratorPart<T extends NameGeneratorPartType> {
  private _type: NameGeneratorPartType;
  private _parameters: ParametersMap[T];

  public constructor(type: T, parameters: ParametersMap[T]) {
    this._type = type;
    this._parameters = parameters;
  }

  public get type(): NameGeneratorPartType {
    return this._type;
  }

  public get parameters(): ParametersMap[T] {
    return this._parameters;
  }

  public toJSON(): JSON {
    return {
      type: this._type,
      parameters: this._parameters
    } as any as JSON;
  }
}
