import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {PlaceholderResponse} from '@domain/textile-data/finishing-and-finishing-template/placeholder-response';
import {PlaceholderPartsComponent} from '@presentation/components/placeholder-parts/placeholder-parts.component';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {cloneDeep} from 'lodash-es';

@Component({
  selector: 'app-change-placeholder-parts-dialog',
  templateUrl: './change-placeholder-parts-dialog.component.html',
  styleUrls: ['./change-placeholder-parts-dialog.component.scss']
})
export class ChangePlaceholderPartsDialogComponent {
  @ViewChild('placeholderPartsComponent', {static: true}) public placeholderPartsComponent: PlaceholderPartsComponent;
  public placeholderParts: PlaceholderComponent[];
  public filteredPlaceholderParts: PlaceholderComponent[];
  public listOfPlaceholderCategoriesWithComponents: PlaceholderCategory[];
  public separatorForTextPlaceholder: string;
  public shouldDisableChooseButton = false;
  public headerTitle: string;
  public specifyTitle: string;
  public columnTitle: string;
  public requiredMaxLength: boolean;
  public isSerialNumberAllowedFreePosition: boolean;
  private readonly serialNumberTypes = [
    {
      name: 'numeric serial number',
      translationKey: 'GENERAL.PLACEHOLDER.NUMERIC_SERIAL_NUMBER',
      regex: '^\\d+$'
    },
    {
      name: 'alphabetic serial number',
      translationKey: 'GENERAL.PLACEHOLDER.ALPHABETIC_SERIAL_NUMBER',
      regex: '^[A-Z]+$'
    },
    {
      name: 'alphanumeric serial number',
      translationKey: 'GENERAL.PLACEHOLDER.ALPHANUMERIC_SERIAL_NUMBER',
      regex: '^[A-Z0-9]+$'
    }
  ];

  public constructor(
    private dialogRef: MatDialogRef<ChangePlaceholderPartsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.placeholderParts = cloneDeep(data.placeholderParts);
    this.listOfPlaceholderCategoriesWithComponents = data.listOfPlaceholderCategoriesWithComponents;
    this.separatorForTextPlaceholder = data.separatorForTextPlaceholder;
    this.headerTitle = data.headerTitle;
    this.specifyTitle = data.specifyTitle;
    this.columnTitle = data.columnTitle;
    this.filteredPlaceholderParts = data.filteredPlaceholderParts;
    this.requiredMaxLength = !!data?.requiredMaxLength;
    this.isSerialNumberAllowedFreePosition = data.isSerialNumberAllowedFreePosition;
  }

  public save(): void {
    this.dialogRef.close(new PlaceholderResponse(this.placeholderPartsComponent.editedPlaceholderParts, this.placeholderPartsComponent.filteredEditedPlaceholderParts));
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public getTitle(): string {
    return this.headerTitle ?? 'GENERAL.PLACEHOLDER.PLACEHOLDER_PARTS';
  }

  public canDisableChooseButton(): boolean {
    let result = false;

    const nonCustomPlaceholderParts = this.placeholderPartsComponent.editedPlaceholderParts.filter((placeholderPart: PlaceholderComponent) => {
      return (
        placeholderPart.id &&
        this.listOfPlaceholderCategoriesWithComponents.some((placeholderCategory: PlaceholderCategory) => {
          return placeholderCategory.placeholderComponents.some((placeholderPartInPlaceholderCategory: PlaceholderComponent) => placeholderPartInPlaceholderCategory.id === placeholderPart.id);
        })
      );
    });

    if (nonCustomPlaceholderParts.length > 0) {
      result = nonCustomPlaceholderParts.some((placeholderPart: PlaceholderComponent) => {
        return (
          (placeholderPart.configurableValue && (placeholderPart.value === '' || !placeholderPart.value)) ||
          (this.requiredMaxLength && !placeholderPart.maxLength) ||
          this.canShowSerialNumberValueErrorMessage(nonCustomPlaceholderParts)
        );
      });
    }

    return result || this.placeholderPartsComponent.canShowErrorMessage();
  }

  private canShowSerialNumberValueErrorMessage(placeholderParts: PlaceholderComponent[]): boolean {
    let result = false;
    this.serialNumberTypes.forEach((type: any) => {
      const serialNumberParts = placeholderParts.filter((placeholderComponent: PlaceholderComponent) => placeholderComponent.categoryName === type.name);
      if (serialNumberParts.length > 0) {
        const startValue = serialNumberParts[0].value;
        const maxValue = serialNumberParts[1].value;
        if (
          !startValue ||
          !maxValue ||
          startValue?.toString().length > Number(maxValue) ||
          (type.name === 'numeric serial number' && Number(startValue) > BackendLimitsConstants.INT32_MAX) ||
          Number(maxValue) > BackendLimitsConstants.INT32_MAX ||
          !new RegExp(type.regex).test(startValue?.toString())
        ) {
          result = true;
        }
      }
    });

    return result;
  }
}
