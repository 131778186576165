import {isPlatformBrowser} from '@angular/common';
import {ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID} from '@angular/core';
import {BrowserWindowRef} from './browser-window-ref';
import {WindowRef} from './window-ref';

export const WINDOW = new InjectionToken('WindowToken');

export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Record<string, unknown>): Window | Record<string, unknown> {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return {};
}

const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef
};

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID]
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
