import {ArticleRoute} from '@application/helper/routing/article-route';
import {RouteBranch} from '@application/helper/routing/route-branch';
import {SalesOrderRoute} from '@application/helper/routing/sales-order-route';
import {CustomerRoute} from './customer-route';
import {OrderbookRoute} from './orderbook-route';
import {PathLayoutTemplateRoute} from './path-layout-template.route';
import {PlanningPrototypeRoute} from './planning-prototype-route';
import {PlanningRoute} from './planning-route';
import {ProductionOrderRoute} from './production-order.route';
import {TuftingRoute} from './tufting-route';
import {WeavingRoute} from './weaving.route';
import {PlasticRoute} from './plastic-route';

export class TexFabRoute extends RouteBranch {
  private _productionOrder: ProductionOrderRoute;
  private _customer: CustomerRoute;
  private _planning: PlanningRoute;
  private _planningPrototype: PlanningPrototypeRoute;
  private _article: ArticleRoute;
  private _salesOrder: SalesOrderRoute;
  private _orderbook: OrderbookRoute;
  private _weaving: WeavingRoute;
  private _tufting: TuftingRoute;
  private _plastic: PlasticRoute;
  private _pathLayoutTemplate: PathLayoutTemplateRoute;

  public get productionOrder(): ProductionOrderRoute {
    return this._productionOrder;
  }

  public set productionOrder(value: ProductionOrderRoute) {
    this.replace(this._productionOrder, value);
    this._productionOrder = value;
  }

  public get customer(): CustomerRoute {
    return this._customer;
  }

  public set customer(value: CustomerRoute) {
    this.replace(this._customer, value);
    this._customer = value;
  }

  public get planning(): PlanningRoute {
    return this._planning;
  }

  public set planning(value: PlanningRoute) {
    this.replace(this._planning, value);
    this._planning = value;
  }

  public get planningPrototype(): PlanningPrototypeRoute {
    return this._planningPrototype;
  }

  public set planningPrototype(value: PlanningPrototypeRoute) {
    this.replace(this._planningPrototype, value);
    this._planningPrototype = value;
  }

  public get article(): ArticleRoute {
    return this._article;
  }

  public set article(value: ArticleRoute) {
    this.replace(this._article, value);
    this._article = value;
  }

  public get salesOrder(): SalesOrderRoute {
    return this._salesOrder;
  }

  public set salesOrder(value: SalesOrderRoute) {
    this.replace(this._salesOrder, value);
    this._salesOrder = value;
  }

  public get orderbook(): OrderbookRoute {
    return this._orderbook;
  }

  public set orderbook(value: OrderbookRoute) {
    this.replace(this._orderbook, value);
    this._orderbook = value;
  }

  public get weaving(): WeavingRoute {
    return this._weaving;
  }

  public set weaving(value: WeavingRoute) {
    this.replace(this._weaving, value);
    this._weaving = value;
  }

  public get tufting(): TuftingRoute {
    return this._tufting;
  }

  public set tufting(value: TuftingRoute) {
    this.replace(this._tufting, value);
    this._tufting = value;
  }

  public get plastic(): PlasticRoute {
    return this._plastic;
  }

  public set plastic(value: PlasticRoute) {
    this.replace(this._plastic, value);
    this._plastic = value;
  }

  public get pathLayoutTemplate(): PathLayoutTemplateRoute {
    return this._pathLayoutTemplate;
  }

  public set pathLayoutTemplate(value: PathLayoutTemplateRoute) {
    this.replace(this._pathLayoutTemplate, value);
    this._pathLayoutTemplate = value;
  }
}
