import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {MachineForOverviewListProductionSchedule} from '../machine-for-overview-list-production-schedule';

@Component({
  selector: 'app-production-schedule-overview-machine',
  templateUrl: './production-schedule-overview-machine.component.html',
  styleUrls: ['./production-schedule-overview-machine.component.scss']
})
export class ProductionScheduleOverviewMachineComponent implements ICellRendererAngularComp {
  public machine: MachineForOverviewListProductionSchedule;

  public agInit(params: ICellRendererParams): void {
    this.machine = params.value;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
