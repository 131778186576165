import {InjectionToken} from '@angular/core';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const CREELS = new InjectionToken<Creels>('Creels');

export interface Creels
  extends Repository<OverviewListCreel, OverviewListCreel>,
    UniqueIdentifierObjectRepository,
    ConflictsRepository,
    CustomSettingsObjectRepository,
    NameGenerationRepository<OverviewListCreel> {
  getAllWithNumberOfCreelPositionsAndNumberOfDents(numberOfCreelPositions: number, numberOfDentsForFabric: number): Observable<OverviewListCreel[]>;

  getActiveCreelForMachine(id: number): Observable<OverviewListCreel>;

  getActiveCreelMappingForMachine(creelId: number, machineId: number): Observable<CreelMap[]>;

  getPossibleCreelsForMachine(id: number): Observable<OverviewListCreel[]>;
}
