import {inject} from '@angular/core';
import {CanDeactivateFn} from '@angular/router';
import {AlertDialogResult, AssertionUtils, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {map, Observable, of} from 'rxjs';
import {PlanningPrototypeComponent} from '../planning-prototype.component';

export const canDeactivatePlanningPrototypeGuard: CanDeactivateFn<PlanningPrototypeComponent> = (component: PlanningPrototypeComponent): Observable<boolean> => {
  if (AssertionUtils.isEmpty(component.updatedPlanningLines)) {
    return of(true);
  }
  const dialogBuilderFactoryService = inject(DialogBuilderFactoryService);
  return dialogBuilderFactoryService
    .getBuilder()
    .openAlertDialog({type: DialogType.DISCARD})
    .pipe(map((result: AlertDialogResult) => result === AlertDialogResult.DISCARD));
};
