<ng-template>
  <div class="row-indicator" (click)="clickable && selected.emit($event)" [class.clickable]="clickable">
    <div class="background white"></div>
    <div class="background opacity" [style.background-color]="color"></div>
    <div class="row-indicator-border" [style.border-color]="color"></div>
    <div class="row-indicator-content">
      <mat-icon class="mat-icon" [svgIcon]="icon"></mat-icon>
      <vdw-ellipsis-label ng-disabled="false" [text]="indicatorText" class="b2 font-weight-semibold" matTooltipPosition="above"></vdw-ellipsis-label>
    </div>
  </div>
</ng-template>
