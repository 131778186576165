import {Component, Input} from '@angular/core';
import {MaterialType} from '@domain/textile-data/creel/creel-position';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Color} from '@vdw/angular-component-library';
import {defaultTo, flatMap, join, size, takeRight} from 'lodash-es';

@Component({
  selector: 'app-more-items-available',
  templateUrl: './more-items-available.component.html',
  styleUrls: ['./more-items-available.component.scss']
})
export class MoreItemsAvailableComponent {
  @Input() public items: OverviewListCreelPosition[] | MaterialType[];
  @Input() public maximumItemsToShow: number;

  public getNamesOfOtherItems(): string {
    let result = '';

    const firstItem: OverviewListCreelPosition | MaterialType = this.items[0];
    if (firstItem instanceof OverviewListCreelPosition) {
      result = join(
        flatMap(
          flatMap(takeRight(this.items as OverviewListCreelPosition[], this.getAmountOfMoreItemsAvailable()), (creelPosition: OverviewListCreelPosition) => creelPosition.getUniqueItems()),
          (materialType: MaterialType) => materialType.name
        ),
        ', '
      );
    } else if (firstItem instanceof OverviewListYarnType || firstItem instanceof Color) {
      result = join(
        flatMap(takeRight(this.items as MaterialType[], this.getAmountOfMoreItemsAvailable()), (materialType: MaterialType) => materialType.name),
        ', '
      );
    }

    return result;
  }

  public getAmountOfMoreItemsAvailable(): number {
    return size(this.items) - this.getAmountOfItemsToShow();
  }

  private getAmountOfItemsToShow(): number {
    return defaultTo(this.maximumItemsToShow, size(this.items));
  }
}
