import {Component, Inject} from '@angular/core';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './grid-colored-yarn-set-preview.component.html',
  styleUrls: ['./grid-colored-yarn-set-preview.component.scss']
})
export class GridColoredYarnSetPreviewComponent implements ICellRendererAngularComp {
  public coloredYarnSet: OverviewListColoredYarnSet;
  public canShowDetailColor: boolean;

  public constructor(@Inject(RESPONSIVENESS_VIEW_MODE) private responsivenessViewMode: ResponsivenessViewMode) {}

  public agInit(params: ICellRendererParams): void {
    this.coloredYarnSet = params.value;
    this.canShowDetailColor = !this.isMobile();
  }

  public refresh(params: any): boolean {
    return false;
  }

  private isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }
}
