import {MaterialSet} from '@domain/textile-data/colored-yarn-set/material-set';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {Color} from '@vdw/angular-component-library';
import {flatMap, map, size, uniqBy} from 'lodash-es';

export class ColorSet extends MaterialSet {
  public constructor(id: number, name: string, creelPositions: CreelPosition[], used: boolean, technicalCode: string, alternativeColorSetIds?: number[]) {
    super(id, name, creelPositions, used, technicalCode, alternativeColorSetIds);
  }

  public static createEmptyColorSet(): ColorSet {
    return new ColorSet(null, null, [], null, null, null);
  }

  public static fromJSON(colorSetJSON: any): ColorSet {
    return new ColorSet(
      colorSetJSON.id,
      colorSetJSON.name,
      map(colorSetJSON.creelPositions, (creelPositionJSON: any) => {
        return CreelPosition.fromJSON(creelPositionJSON);
      }),
      colorSetJSON.used,
      colorSetJSON.technicalCode ?? null,
      colorSetJSON.alternativeColorSetIds ?? null
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      creelPositions: map(this.creelPositions, (creelPosition: CreelPosition, indexOfCreelPosition: number) => {
        return {
          creelPosition: indexOfCreelPosition + 1,
          creel: creelPosition.toJSON()
        };
      }),
      technicalCode: this.technicalCode,
      alternativeColorSetIds: this.alternativeColorSetIds ?? []
    } as any as JSON;
  }

  public getColors(): Color[] {
    return uniqBy(flatMap(this.creelPositions, (creelPosition: CreelPosition) => creelPosition.getUniqueItems()) as Color[], 'id');
  }

  public getSizeOfCreelPositions(): number {
    return size(this.creelPositions);
  }

  public isColorSet(): boolean {
    return true;
  }

  public isYarnSet(): boolean {
    return false;
  }
}
