import {isEqual, isNil, size, sumBy} from 'lodash-es';
import {BitmapMetadata} from './bitmap-metadata.model';

// This class is based on the Bitmap format documentation located at
// https://en.wikipedia.org/wiki/BMP_file_format

export class BitmapMetadataReader {
  private static INDEX_FIELD_BITS_PER_PIXEL = 28;
  private static SIZE_FIELD_BITS_PER_PIXEL = 2;

  private static INDEX_FIELD_DIB_HEADER_BYTES_COUNT = 14;
  private static SIZE_FIELD_DIB_HEADER_BYTES_COUNT = 4;

  public static readMetadataFromBase64EncodedString(base64decodedString: string): BitmapMetadata {
    if (isNil(base64decodedString)) {
      return new BitmapMetadata();
    }

    const byteCharacters = atob(base64decodedString);
    const bytes: number[] = [];

    let maxNumberOfBytesToDecode = this.INDEX_FIELD_DIB_HEADER_BYTES_COUNT + this.SIZE_FIELD_DIB_HEADER_BYTES_COUNT;

    for (let i = 0; i < byteCharacters.length && i < maxNumberOfBytesToDecode; i++) {
      bytes.push(byteCharacters.charCodeAt(i));

      const isDIBHeaderValueRead = isEqual(i, this.INDEX_FIELD_DIB_HEADER_BYTES_COUNT + this.SIZE_FIELD_DIB_HEADER_BYTES_COUNT - 1);
      if (isDIBHeaderValueRead) {
        maxNumberOfBytesToDecode = this.INDEX_FIELD_DIB_HEADER_BYTES_COUNT + this.getDIBHeaderBytesCount(bytes);
      }
    }
    return this.readMetadata(bytes);
  }

  public static readMetadata(bytes: number[]): BitmapMetadata {
    const metadata = new BitmapMetadata();

    metadata.bitsPerPixel = this.getBitsPerPixel(bytes);

    return metadata;
  }

  private static getDIBHeaderBytesCount(bytes: number[]): number {
    return this.getNumericValue(bytes, this.INDEX_FIELD_DIB_HEADER_BYTES_COUNT, this.SIZE_FIELD_DIB_HEADER_BYTES_COUNT);
  }

  private static getBitsPerPixel(bytes: number[]): number {
    return this.getNumericValue(bytes, this.INDEX_FIELD_BITS_PER_PIXEL, this.SIZE_FIELD_BITS_PER_PIXEL);
  }

  private static getNumericValue(bytes: number[], start: number, numberOfBytes: number): number {
    if (size(bytes) < start + numberOfBytes) {
      return 0;
    }

    const valueBytes = bytes.slice(start, start + numberOfBytes);
    let power = 0;
    return sumBy(valueBytes, (byte: number) => byte * Math.pow(256, power++));
  }
}
