import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class PathLayoutTemplateRoute extends RouteBranch {
  private _addPathLayoutTemplate: RouteLeaf;
  private _editPathLayoutTemplate: RouteLeaf;
  private _duplicatePathLayoutTemplate: RouteLeaf;
  private _pathLayoutTemplateSettings: RouteLeaf;

  public get addPathLayoutTemplate(): RouteLeaf {
    return this._addPathLayoutTemplate;
  }

  public set addPathLayoutTemplate(value: RouteLeaf) {
    this.replace(this._addPathLayoutTemplate, value);
    this._addPathLayoutTemplate = value;
  }

  public get editPathLayoutTemplate(): RouteLeaf {
    return this._editPathLayoutTemplate;
  }

  public set editPathLayoutTemplate(value: RouteLeaf) {
    this.replace(this._editPathLayoutTemplate, value);
    this._editPathLayoutTemplate = value;
  }

  public get duplicatePathLayoutTemplate(): RouteLeaf {
    return this._duplicatePathLayoutTemplate;
  }

  public set duplicatePathLayoutTemplate(value: RouteLeaf) {
    this.replace(this._duplicatePathLayoutTemplate, value);
    this._duplicatePathLayoutTemplate = value;
  }

  public get pathLayoutTemplateSettings(): RouteLeaf {
    return this._pathLayoutTemplateSettings;
  }

  public set pathLayoutTemplateSettings(value: RouteLeaf) {
    this.replace(this._pathLayoutTemplateSettings, value);
    this._pathLayoutTemplateSettings = value;
  }
}
