<div fxLayout="row">
  <div fxFlex="240px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'LOGGER.SELECT_LOGGER_LEVEL' | bmsTranslate }}</mat-label>
      <mat-select disableOptionCentering [(value)]="levelSelected">
        <mat-option *ngFor="let level of loggerLevels" [value]="level">
          {{ level }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row">
  <vdw-progress-button (progressClick)="setNewLogLevel()"></vdw-progress-button>
</div>
