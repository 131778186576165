import {AssertionUtils} from '@vdw/angular-component-library';

export class RunningMachineEventInfo {
  private _yield: number;
  private _rotationsPerMinute: number;

  public constructor(yieldOfMachine: number, rotationsPerMinute: number) {
    this._yield = yieldOfMachine;
    this._rotationsPerMinute = rotationsPerMinute;
  }

  public get yield(): number {
    return this._yield;
  }

  public get rotationsPerMinute(): number {
    return this._rotationsPerMinute;
  }

  public static fromJSON(runningMachineEventInfoJSON: any): RunningMachineEventInfo {
    return new RunningMachineEventInfo(runningMachineEventInfoJSON.yield, runningMachineEventInfoJSON.rotationsPerMinute);
  }

  public hasMachineSpeed(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.rotationsPerMinute) && this.rotationsPerMinute !== 0;
  }

  public hasMachineYield(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.yield);
  }
}
