import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {map} from 'lodash-es';

export class PlaceholderCategory {
  private _name: string;
  private _placeholderComponents: PlaceholderComponent[];

  public constructor(name: string, placeholderComponents: PlaceholderComponent[]) {
    this._name = name;
    this._placeholderComponents = placeholderComponents;
  }

  public get name(): string {
    return this._name;
  }

  public get placeholderComponents(): PlaceholderComponent[] {
    return this._placeholderComponents;
  }

  public static fromJSON(placeholderCategoryJSON: any): PlaceholderCategory {
    return new PlaceholderCategory(
      placeholderCategoryJSON.name,
      map(placeholderCategoryJSON.components, (placeholderComponentJSON: any) => {
        return PlaceholderComponent.fromJSON(placeholderComponentJSON);
      })
    );
  }
}
