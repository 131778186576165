import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {AssertionUtils} from '@vdw/angular-component-library';

export class PlanningCreel {
  private _id: number;
  private _name: string;
  private _mappingForCurrentPositionOnMachine: CreelMap[];

  public constructor(id: number, name: string, mappingForCurrentPositionOnMachine: CreelMap[] = null) {
    this._id = id;
    this._name = name;
    this._mappingForCurrentPositionOnMachine = mappingForCurrentPositionOnMachine;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get mappingForCurrentPositionOnMachine(): CreelMap[] {
    return this._mappingForCurrentPositionOnMachine;
  }

  public static fromJSON(creelJSON: any): PlanningCreel {
    return AssertionUtils.isNullOrUndefined(creelJSON) ? null : new PlanningCreel(creelJSON.id, creelJSON.name, creelJSON.mappingForCurrentPositionOnMachine);
  }

  public static fromOverviewListCreel(overviewListCreel: OverviewListCreel): PlanningCreel {
    return new PlanningCreel(overviewListCreel.id, overviewListCreel.name, overviewListCreel.mappingForCurrentPositionOnMachine);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      mappingForCurrentPositionOnMachine: this._mappingForCurrentPositionOnMachine
    } as any as JSON;
  }
}
