import {Component, ElementRef, computed, inject, input} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FixedSchedulePlaceholder} from '@domain/planning-prototype/carpet/fixed-schedule-placeholder';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {DOMUtils} from '@vdw/angular-component-library';
import {map} from 'rxjs';

@Component({
  selector: 'app-fixed-schedule-placeholder',
  templateUrl: './fixed-schedule-placeholder.component.html',
  styleUrls: ['./fixed-schedule-placeholder.component.scss']
})
export class FixedSchedulePlaceholderComponent {
  private readonly SMALL_ITEM_THRESHOLD = 72;
  private readonly element = inject(ElementRef<HTMLElement>);

  public readonly fixedSchedule = input.required<FixedSchedulePlaceholder | FixedScheduleToPlan>();
  public readonly name = computed(() => this.fixedSchedule().fixedSchedule.name);
  public readonly shouldShowSmallInfo = toSignal(
    DOMUtils.observeResize(this.element.nativeElement).pipe(map((entries: ResizeObserverEntry[]) => entries[0].contentRect.width < this.SMALL_ITEM_THRESHOLD)),
    {initialValue: false}
  );
}
