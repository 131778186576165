import {Inject, Injectable} from '@angular/core';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils, BaseComponent, CrudOverviewButtonConfig} from '@vdw/angular-component-library';
import {CrudActions} from './crud-actions.interface';

@Injectable({providedIn: 'root'})
export class GridActionButtonsHelper extends BaseComponent {
  private deleteButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DELETE', icon: 'solid-delete', withTooltip: true, onlyEnabledOnSingleRowSelection: true};
  private editButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EDIT', icon: 'solid-edit', onlyEnabledOnSingleRowSelection: true, withTooltip: true};
  private duplicateButton: CrudOverviewButtonConfig = {
    title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DUPLICATE',
    icon: 'solid-file-copy',
    onlyEnabledOnSingleRowSelection: true,
    withTooltip: true
  };

  private currentSubscription: ProfileSubscription;

  public constructor(@Inject(AUTHENTICATION) private authentication: Authentication) {
    super();
  }

  public getActionButtonsForGridSelection(crudActions: CrudActions): CrudOverviewButtonConfig[] {
    let actionButtons: CrudOverviewButtonConfig[] = [];
    if (this.hasPermissionForAction(crudActions?.requiredPermissionToEdit)) {
      if (!AssertionUtils.isNullOrUndefined(crudActions?.withEdit)) {
        this.editButton.onClick = crudActions.withEdit;
        actionButtons.push(this.editButton);
      }

      if (!AssertionUtils.isNullOrUndefined(crudActions?.withDuplicate)) {
        this.duplicateButton.onClick = crudActions.withDuplicate;
        actionButtons.push(this.duplicateButton);
      }

      if (!AssertionUtils.isNullOrUndefined(crudActions?.withDelete)) {
        this.deleteButton.onClick = crudActions.withDelete;
        if (crudActions.allowDeleteMultiple) {
          this.deleteButton.onlyEnabledOnSingleRowSelection = false;
        }
        actionButtons.push(this.deleteButton);
      }

      return actionButtons;
    } else {
      return actionButtons;
    }
  }

  private hasPermissionForAction(permission: Permission): boolean {
    this.currentSubscription = this.authentication.getCurrentSubscription();

    return this.currentSubscription?.hasPermission(permission);
  }
}
