import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {PRODUCTION_ORDERS} from '@infrastructure/http/production-order/production-orders';
import {isEqual} from 'lodash-es';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

// Don't remove unused childRoute: ActivatedRouteSnapshot or state: RouterStateSnapshot is seen as childRoute: ActivatedRouteSnapshot.
export const canActivateProductionScheduleGuard = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const router = inject(Router);
  const productionOrders = inject(PRODUCTION_ORDERS);

  if (!isEqual(state.url, NavigationUtils.getAbsolutePath(NavigationId.CARPET_PRODUCTION_SCHEDULE_SETTINGS))) {
    return productionOrders.hasAlreadyAdjustedCustomSettings().pipe(
      map((hasAlreadyAdjustedCustomSettings: boolean) => {
        let result = false;

        if (hasAlreadyAdjustedCustomSettings) {
          result = true;
        } else {
          router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.CARPET_PRODUCTION_SCHEDULE_SETTINGS));
        }

        return result;
      })
    );
  } else {
    return of(true);
  }
};
