import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-drawing-library-image-preview',
  templateUrl: './drawing-library-image-preview.component.html',
  styleUrls: ['./drawing-library-image-preview.component.scss']
})
export class DrawingLibraryImagePreviewComponent {
  public static PREVIEW_TOOLTIP_HEIGHT_FROM_BOTTOM_IN_PX = 30;
  public readonly MISSING_DATA_IMAGE = 'assets/images/missing-design.svg';

  @ViewChild('preview') public preview: ElementRef<HTMLElement>;
  @Input() public drawingImage: DrawingImage;
  @Input() public qualityId: number;
  @Input() public colorSetId: number;
  @Input() public drawingDimensionsInPx: DimensionsInPx;
  @Input() public areImagesUploading: boolean;
  @Input() public shouldDownloadImage = false;
  @Input() public hasMissingDesign = false;
  @Input() public showAmountSpecifier: boolean;
  @Output() public imageDownloaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() public hideTooltip: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('mouseleave', ['$event']) public onMouseLeave(event: MouseEvent): void {
    const previewWidth = this.preview.nativeElement.offsetWidth;
    const previewHeight = this.preview.nativeElement.offsetHeight;
    const mouseOffsetY = event.offsetY;
    const mouseOffsetX = event.offsetX;

    const isMouseOutsidePreview = 0 >= mouseOffsetY || mouseOffsetY >= previewHeight || 0 >= mouseOffsetX || mouseOffsetX >= previewWidth;
    const isMouseOnTooltip = mouseOffsetY >= previewHeight - DrawingLibraryImagePreviewComponent.PREVIEW_TOOLTIP_HEIGHT_FROM_BOTTOM_IN_PX;

    if ((isMouseOutsidePreview && !isMouseOnTooltip) || this.showAmountSpecifier) {
      this.hideTooltip.emit();
    }
  }

  public isImageDownloading(): boolean {
    return isNil(this.areImagesUploading);
  }

  public canShowImageLoader(): boolean {
    return this.areImagesUploading;
  }

  public hasDrawingImageData(): boolean {
    return !isNil(this.drawingImage?.data);
  }

  public onImageDownloaded(image: string): void {
    this.imageDownloaded.emit(image);
  }
}
