import {InjectionToken} from '@angular/core';
import {DateRange} from '@domain/date-range';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {NeedsAttentionItem} from '@domain/planning/needs-attention-item';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningOrderDetails} from '@domain/planning/planning-order-details';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {Observable} from 'rxjs';
import {MachineDetails} from './machine-details';

export const PLANNING = new InjectionToken<Planning>('Planning');

export interface Planning {
  getGroupedRunsForPeriod(dateRange: DateRange): Observable<CalendarItemGroup[]>;

  getMachines(): Observable<PlanningMachine[]>;

  getMachineDetails(machineId: number): Observable<MachineDetails>;

  updateGroupedItems(groupedItems: CalendarItemGroup[]): Observable<void>;

  getPlanningOrderDetails(id: number): Observable<PlanningOrderDetails>;

  deletePlanningItem(planningItem: PlanningItem): Observable<void>;

  processProductionOrder(id: number): Observable<void>;

  queueProductionOrder(id: number): Observable<void>;

  processAndQueueProductionOrder(id: number): Observable<void>;

  getCreelForRun(runId: number): Observable<OverviewListCreel>;

  checkRunCompatibilityWithMachine(runId: number, machineId: number): Observable<number>;

  deletePlanningOrder(productionOrderId: number): Observable<void>;

  deletePlanningOrderLite(productionOrderLiteId: number): Observable<void>;

  getCreelMapForRun(runId: number): Observable<CreelMap[]>;

  getOrdersThatRequireAttention(): Observable<NeedsAttentionItem[]>;
}
