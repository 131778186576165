export class Company {
  private readonly _id: string;
  private readonly _name: string;

  public constructor(id: string, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(companyJSON: any): Company {
    return new Company(companyJSON.id, companyJSON.name);
  }
}
