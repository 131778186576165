import {IdName} from '@domain/id-name';
import {UnitOfMeasurement} from '@domain/textile-data/plastic-product/unit-of-measurement.enum';

export class IdNameUnitOfMeasurement extends IdName {
  private _unitOfMeasurement: UnitOfMeasurement;

  public constructor(id: number, name: string, unitOfMeasurement: UnitOfMeasurement) {
    super(id, name);

    this._unitOfMeasurement = unitOfMeasurement;
  }

  public get unitOfMeasurement(): UnitOfMeasurement {
    return this._unitOfMeasurement;
  }

  public set unitOfMeasurement(value: UnitOfMeasurement) {
    this._unitOfMeasurement = value;
  }

  public static fromJSON(object: any): IdNameUnitOfMeasurement {
    return new IdNameUnitOfMeasurement(object.id, object.name, object.unitOfMeasurement);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      unitOfMeasurement: this.unitOfMeasurement
    } as any as JSON;
  }
}
