import {DensityUnitConversionError} from '../error/density-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToDensityUnitPicksPerCentimeter(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.PICKS_PER_MILLIMETER:
      result = unitValue.value * 10;
      break;
    case Unit.PICKS_PER_CENTIMETER:
      result = unitValue.value;
      break;
    case Unit.PICKS_PER_DECIMETER:
      result = unitValue.value / 10;
      break;
    case Unit.PICKS_PER_METER:
      result = unitValue.value / 100;
      break;
    default:
      throw new DensityUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.PICKS_PER_CENTIMETER}`);
  }

  return result;
}
