export class StatusBar {
  private readonly _totalRows: number;
  private _totalGroupedRows: number;
  private readonly _filteredRows: number;
  private readonly _currentRowIds: number[];

  public constructor(totalRows: number, filteredRows: number, currentRowIds: number[], totalGroupedRows?: number) {
    this._totalRows = totalRows;
    this._filteredRows = filteredRows;
    this._currentRowIds = currentRowIds;
    this._totalGroupedRows = totalGroupedRows;
  }

  public get totalRows(): number {
    return this._totalRows;
  }

  public get totalGroupedRows(): number {
    return this._totalGroupedRows;
  }

  public set totalGroupedRows(value: number) {
    this._totalGroupedRows = value;
  }

  public get filteredRows(): number {
    return this._filteredRows;
  }

  public get currentRowIds(): number[] {
    return this._currentRowIds;
  }

  public static fromJSON(statusBarJSON: any): StatusBar {
    return new StatusBar(statusBarJSON.totalRows, statusBarJSON.filteredRows, statusBarJSON.currentRowIds, statusBarJSON.totalGroupedRows ?? null);
  }
}
