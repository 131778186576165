import {HorizontalPositionOfColorCreelPosition} from '@domain/textile-data/color-set/horizontal-position-of-color-creel-position';
import {HorizontalPositionOfCreelPosition} from '@domain/textile-data/creel/horizontal-position-of-creel-position';
import {HorizontalPositionOfCreelPositionType} from '@domain/textile-data/creel/horizontal-position-of-creel-position-type.enum';
import {HorizontalPositionOfYarnTypeCreelPosition} from '@domain/textile-data/yarn-set/horizontal-position-of-yarn-type-creel-position';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Color} from '@vdw/angular-component-library';
import {fill, flatMap, map, sumBy} from 'lodash-es';

export class HorizontalPositionOfGroupCreelPosition extends HorizontalPositionOfCreelPosition {
  private readonly _group: HorizontalPositionOfCreelPosition[];

  public constructor(amount: number, group: HorizontalPositionOfCreelPosition[], isSelected: boolean = false) {
    super(HorizontalPositionOfCreelPositionType.GROUP, amount, isSelected);

    this._group = group;
  }

  public get group(): HorizontalPositionOfCreelPosition[] {
    return this._group;
  }

  public static fromJSON(groupCreelPositionJSON: any): HorizontalPositionOfGroupCreelPosition {
    return new HorizontalPositionOfGroupCreelPosition(
      groupCreelPositionJSON.amount,
      map(groupCreelPositionJSON.group, (groupJSON: any) => {
        let position: HorizontalPositionOfCreelPosition;
        const type: HorizontalPositionOfCreelPositionType = groupJSON.type as HorizontalPositionOfCreelPositionType;
        const valueOfType: HorizontalPositionOfCreelPositionType = HorizontalPositionOfCreelPositionType[type] as any as HorizontalPositionOfCreelPositionType;

        switch (valueOfType) {
          case HorizontalPositionOfCreelPositionType.COLOR:
            position = HorizontalPositionOfColorCreelPosition.fromJSON(groupJSON);
            break;
          case HorizontalPositionOfCreelPositionType.GROUP:
            position = HorizontalPositionOfGroupCreelPosition.fromJSON(groupJSON);
            break;
          case HorizontalPositionOfCreelPositionType.YARN_TYPE:
            position = HorizontalPositionOfYarnTypeCreelPosition.fromJSON(groupJSON);
            break;
        }
        return position;
      })
    );
  }

  public toJSON(): JSON {
    return {
      type: HorizontalPositionOfCreelPositionType[this.type],
      group: map(this.group, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
        let result: JSON;

        switch (horizontalPositionOfCreelPosition.type) {
          case HorizontalPositionOfCreelPositionType.COLOR:
            result = (horizontalPositionOfCreelPosition as HorizontalPositionOfColorCreelPosition).toJSON();
            break;
          case HorizontalPositionOfCreelPositionType.GROUP:
            result = (horizontalPositionOfCreelPosition as HorizontalPositionOfGroupCreelPosition).toJSON();
            break;
          case HorizontalPositionOfCreelPositionType.YARN_TYPE:
            result = (horizontalPositionOfCreelPosition as HorizontalPositionOfYarnTypeCreelPosition).toJSON();
            break;
        }

        return result;
      }),
      amount: this.amount
    } as any as JSON;
  }

  public getItems(): YarnType[] | Color[] {
    return flatMap(this.group, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
      let result;

      switch (horizontalPositionOfCreelPosition.type) {
        case HorizontalPositionOfCreelPositionType.COLOR:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfColorCreelPosition).color;
          break;
        case HorizontalPositionOfCreelPositionType.GROUP:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfGroupCreelPosition).getItems();
          break;
        case HorizontalPositionOfCreelPositionType.YARN_TYPE:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfYarnTypeCreelPosition).yarnType;
          break;
        default:
          result = [];
      }

      return result;
    });
  }

  public getAmountOfDentsInHorizontalPositionOfCreelPosition(): number {
    const result = sumBy(this.group, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
      return horizontalPositionOfCreelPosition.getAmountOfDentsInHorizontalPositionOfCreelPosition();
    });

    return result * this.amount;
  }

  public getPatternForHorizontalPositionOfCreelPosition(): Color[] | OverviewListYarnType[] {
    return flatMap(
      fill(
        Array(this.amount),
        flatMap(this.group, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
          return horizontalPositionOfCreelPosition.getPatternForHorizontalPositionOfCreelPosition();
        })
      )
    ) as any as Color[] | OverviewListYarnType[];
  }
}
