import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MACHINES, Machines} from '@infrastructure/http/machine/machines.interface';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {AgGridUtils, AssertionUtils, BaseComponent, ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, SelectGridDialog, Unit} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {combineLatest, map, Observable, takeUntil} from 'rxjs';

@Component({
  templateUrl: './select-machine.component.html',
  styleUrls: ['./select-machine.component.scss']
})
export class SelectMachineComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('machinesGrid') public machinesGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfMachines: MachineOverviewList[];
  private readonly allowedMachineTypes: MachineType[];
  private readonly machineQualityId: number;
  private readonly weaveProductId: number;
  private readonly tuftProductId: number;
  private readonly manufacturerName: string = 'manufacturer.name';

  private readonly nameColDef = this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').build();
  private readonly manufacturerColDef = this.colDefBuilderFactoryService.getBuilder().withField(this.manufacturerName, true).withHeaderName('MACHINE.MANUFACTURER').build();

  public constructor(
    @Inject(MACHINES) private readonly machines: Machines,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectMachineComponent>,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly navigationHelperService: NavigationHelperService<{dialogComponent: SelectMachineComponent}>
  ) {
    super();

    this.allowedMachineTypes = data?.allowedMachineTypes ?? this.allowedMachineTypes;
    this.machineQualityId = data?.machineQualityId;
    this.weaveProductId = data?.weaveProductId;
    this.tuftProductId = data?.tuftProductId;
  }

  public get gridOptionsForListOfMachines(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.instantiateGridOptionsForListOfMachines();
    this.getListOfMachines();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.machinesGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState({dialogComponent: SelectMachineComponent});
  }

  public selectMachine(): void {
    this.dialogRef.close(this.machinesGrid.api.getSelectedRows()[0]);
  }

  public canSelectMachine(): boolean {
    let result = false;
    if (!AssertionUtils.isNullOrUndefined(this.machinesGrid?.api)) {
      result = this.machinesGrid.api.getSelectedRows()?.length > 0 === true;
    }
    return result;
  }

  private getListOfMachines(): void {
    const machinesRequests: Observable<MachineOverviewList[]>[] = [];

    if (AssertionUtils.isNullOrUndefined(this.machineQualityId) && AssertionUtils.isNullOrUndefined(this.weaveProductId) && AssertionUtils.isNullOrUndefined(this.tuftProductId)) {
      machinesRequests.push(this.machines.getAll());
    } else {
      if (!AssertionUtils.isNullOrUndefined(this.machineQualityId)) {
        machinesRequests.push(this.machines.getAllForMachineQualityId(this.machineQualityId));
      }
      if (!AssertionUtils.isNullOrUndefined(this.weaveProductId)) {
        machinesRequests.push(this.machines.getAllForWeaveProductId(this.weaveProductId));
      }
      if (!AssertionUtils.isNullOrUndefined(this.tuftProductId)) {
        machinesRequests.push(this.machines.getAllForTuftProductId(this.tuftProductId));
      }
    }

    combineLatest(machinesRequests)
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        map((results: MachineOverviewList[][]) => this.flattenAndOnlySubset(results))
      )
      .subscribe((listOfMachines: MachineOverviewList[]) => {
        if (this.allowedMachineTypes?.length > 0) {
          this.listOfMachines = listOfMachines.filter((machine: MachineOverviewList) => this.allowedMachineTypes.includes(machine.machineType));
        } else {
          this.listOfMachines = listOfMachines;
        }
      });
  }

  private flattenAndOnlySubset(listOfMachines: MachineOverviewList[][]): MachineOverviewList[] {
    const flattenedMap = listOfMachines.flatMap((values: MachineOverviewList[]) => [...values]);

    if (listOfMachines.length <= 1) {
      return flattenedMap;
    }

    return flattenedMap.filter(
      (value: MachineOverviewList, index: number, array: MachineOverviewList[]) =>
        array.filter((firstFilterElement: MachineOverviewList) => firstFilterElement.id === value.id).length === listOfMachines.length &&
        array.findIndex((secondFilterElement: MachineOverviewList) => secondFilterElement.id === value.id) === index
    );
  }

  private instantiateGridOptionsForListOfMachines(): void {
    const isWeavingMachineAllowed = this.allowedMachineTypes?.includes(MachineType.WEAVING_MACHINE);
    const isTuftingMachineAllowed = this.allowedMachineTypes?.includes(MachineType.TUFT);

    let columns: ColDef[];
    let gridIdentifier: GridIdentifier;
    if (isWeavingMachineAllowed) {
      columns = this.getColumnDefsForListOfWeavingMachines();
      gridIdentifier = GridIdentifier.SELECT_WEAVING_MACHINE;
    } else if (isTuftingMachineAllowed) {
      columns = this.getColumnDefsForListOfTuftingMachines();
      gridIdentifier = GridIdentifier.SELECT_TUFT_MACHINE;
    } else {
      columns = this.getColumnDefsForListOfMachines();
      gridIdentifier = GridIdentifier.SELECT_MACHINE;
    }

    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(columns, gridIdentifier)
        .withOnCellDoubleClicked(() => this.selectMachine())
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'MACHINE.MACHINE',
          hideDescription: true
        })
        .withLoadingOverlay({
          scale: 0.7
        })
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColumnDefsForListOfMachines(): ColDef[] {
    return [
      this.nameColDef,
      this.manufacturerColDef,
      this.colDefBuilderFactoryService.getBuilder().withField('type', true).withHeaderName('GENERAL.TYPE').build(),
      this.colDefBuilderFactoryService.getBuilder().withField('machineGroup.name', true).withHeaderName('SUPPORT.MACHINES_LIST.TABLE_HEADER.GROUPS').build(),
      this.colDefBuilderFactoryService.getBuilder().withField('machineType', true).withHeaderName('MACHINE.DETAILS.MACHINE_TYPE').build(),
      this.colDefBuilderFactoryService.getBuilder().withField('dentsForFabric', true).withHeaderName('MACHINE.DETAILS.NR_DENTS', 1, true).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('numberOfCreelPositions', true).withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.CREEL_POSITIONS', 1, true).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('orderId', true).withHeaderName('SUPPORT.MACHINES_LIST.TABLE_HEADER.ORDER_ID').build()
    ];
  }

  private getColumnDefsForListOfLiteMachines(): ColDef[] {
    return [
      this.nameColDef,
      this.manufacturerColDef,
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('widthInCm')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.value, Unit.CENTIMETER, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('standardSpeed')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_SPEED')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.value, Unit.ROTATIONS_PER_MINUTE, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('standardEfficiency')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_EFFICIENCY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => `${params.value} %`)
        .build()
    ];
  }

  private getColumnDefsForListOfWeavingMachines(): ColDef[] {
    return [
      ...this.getColumnDefsForListOfLiteMachines(),
      this.colDefBuilderFactoryService.getBuilder().withField('numberOfFeeders', true).withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_FEEDERS').withRightAlignment().build(),
      this.colDefBuilderFactoryService.getBuilder().withField('numberOfShafts', true).withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_SHAFTS').withRightAlignment().build()
    ];
  }

  private getColumnDefsForListOfTuftingMachines(): ColDef[] {
    return [
      ...this.getColumnDefsForListOfLiteMachines(),
      this.colDefBuilderFactoryService.getBuilder().withField('gauge.name').withHeaderName('GENERAL.DIMENSIONS.GAUGE').withRightAlignment().build()
    ];
  }
}
