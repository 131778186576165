import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {DateRange} from '@domain/date-range';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineType} from '@domain/machine/machine-type.enum';
import {Regime} from '@domain/machine/regime';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineMaintenance} from '@domain/planning/planning-machine-maintenance';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {MACHINE_OVERVIEW, MachineOverview} from '@infrastructure/http/machine-overview/machine-overview';
import {PLANNING, Planning} from '@infrastructure/http/planning/planning';
import {DeletedPlanningItem} from '@infrastructure/signalr/planning/deleted-planning-item';
import {REALTIME_PLANNING, RealtimePlanning} from '@infrastructure/signalr/planning/realtime-planning';
import {ProductionOrderLiteStatusChange} from '@infrastructure/signalr/production-order-lite/production-order-lite-status-change';
import {REALTIME_PRODUCTION_ORDER_LITE, RealtimeProductionOrderLite} from '@infrastructure/signalr/production-order-lite/realtime-production-order-lite';
import {ProductionOrderStatusChange} from '@infrastructure/signalr/production-order/production-order-status-change';
import {REALTIME_PRODUCTION_ORDER, RealtimeProductionOrder} from '@infrastructure/signalr/production-order/realtime-production-order';
import {ArrayUtils, AssertionUtils, BaseComponent, LocalStorageService, moment, SelectionListItem, TranslateService} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {takeUntil} from 'rxjs';
import {MachineFilterSettingsComponent} from './machine-filter-settings/machine-filter-settings.component';
import {NavigationPlanningData} from './navigation-planning-data.type';
import {PlanningDateRangeData} from './planning-date-rage-data.interface';
import {PLANNING_SCHEDULER, PlanningScheduler} from './planning-scheduler/planning-scheduler';
import {PlanningContextService} from './planning-services/planning-context.service';
import {PlanningOnboardingService} from './planning-services/planning-onboarding.service';
import {PlanningRealtimeChangesService} from './planning-services/planning-realtime-changes.service';
import {PlanningSidebarManager} from './sidebars/planning-sidebar-manager';
import {PlanningSidebarsComponent} from './sidebars/planning-sidebars.component';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent extends BaseComponent implements OnInit {
  @ViewChild(PlanningSidebarsComponent) public planningSideBars: PlanningSidebarsComponent;

  public regimes: Regime[];
  public loadingMachines = true;
  public fallbackMachineGroupId: number;
  public planningSidebarManager = new PlanningSidebarManager();
  public selectedDateRange: DateRange = [moment().startOf('day').toDate(), moment().endOf('day').add(4, 'days').toDate()];
  public readonly machineFilterSettingsComponent = MachineFilterSettingsComponent;
  private planningGroupFilter: SelectionListItem[] = [];
  private currentSubscription: Subscription;
  private readonly MACHINES_FILTER_KEY = 'planning.machineFilter';
  private readonly CARPET_QUEUE_PERMISSION = Permission.TEXFAB_CARPET_QUEUE;
  private readonly WEAVE_QUEUE_PERMISSION = Permission.TEXFAB_WEAVE_QUEUE;
  private readonly TUFT_QUEUE_PERMISSION = Permission.TEXFAB_TUFT_QUEUE;

  public constructor(
    private readonly router: Router,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(PLANNING) private readonly planning: Planning,
    public readonly planningContext: PlanningContextService,
    private readonly planningOnboarding: PlanningOnboardingService,
    private readonly realtimeChanges: PlanningRealtimeChangesService,
    private readonly localStorage: LocalStorageService,
    private readonly translate: TranslateService,
    @Inject(MACHINE_OVERVIEW) private readonly machineOverview: MachineOverview,
    @Inject(REALTIME_PLANNING) private readonly realtimePlanning: RealtimePlanning,
    @Inject(PLANNING_SCHEDULER) private readonly planningScheduler: PlanningScheduler,
    @Inject(REALTIME_PRODUCTION_ORDER) private readonly realtimeProductionOrder: RealtimeProductionOrder,
    @Inject(REALTIME_PRODUCTION_ORDER_LITE) private readonly realtimeProductionOrderLite: RealtimeProductionOrderLite,
    private readonly navigationHelperService: NavigationHelperService<NavigationPlanningData>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.getRegimes();
    this.getMachines();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<PlanningDateRangeData>({
      dateRange: this.selectedDateRange
    });
  }

  public canShowNoDataOverlay(): boolean {
    return !this.loadingMachines && this.planningContext.machines.length === 0;
  }

  public onMachineFilterChanged(selectedGroups: SelectionListItem[]): void {
    this.storePlanningGroupFilter(selectedGroups);
    this.planningGroupFilter = selectedGroups;
    this.updateMachinesForSelectedPlanningGroupsList();
  }

  public getMachineFilterDialogData(): {options: SelectionListItem[]} {
    return {options: this.planningGroupFilter};
  }

  public showMachineFilterButton(): boolean {
    return this.planningGroupFilter?.length > 1;
  }

  public canShowOpenNeedsAttentionOrders(): boolean {
    const permissions = [this.CARPET_QUEUE_PERMISSION, this.WEAVE_QUEUE_PERMISSION, this.TUFT_QUEUE_PERMISSION];
    return this.currentSubscription.hasAtLeastOneOfThePermissions(permissions);
  }

  private getMachines(): void {
    this.planning
      .getMachines()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (machines: PlanningMachine[]) => {
          if (AssertionUtils.isEmpty(machines)) {
            this.planningGroupFilter = [];
            this.planningContext.machines = machines;
            this.loadingMachines = false;
            this.updatePlanningGroupFilter();
            return;
          }

          this.planningContext.machines = machines;

          this.subscribeToPlanningChanges();
          this.planningScheduler.getPlanning([this.selectedDateRange[0], this.selectedDateRange[1]]);

          const reverseMachines: PlanningMachine[] = [].concat(this.planningContext.machines).reverse();
          const lastMachineWithGroup = reverseMachines.find((machine: PlanningMachine) => !AssertionUtils.isNullOrUndefined(machine.group));
          this.fallbackMachineGroupId = lastMachineWithGroup != null ? lastMachineWithGroup.group.id + 1 : 0;

          this.planningContext.machines.sort(PlanningMachine.compare);
          this.loadingMachines = false;
          this.updatePlanningGroupFilter();
        },
        error: () => (this.loadingMachines = false)
      });
  }

  private subscribeToPlanningChanges(): void {
    let initialized = false;
    let onboardingStarted = false;

    this.planningScheduler
      .onPlanningChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((groupedCalendarItems: CalendarItemGroup[]) => {
        if (AssertionUtils.isEmpty(groupedCalendarItems)) {
          return;
        }

        this.planningContext.groupedCalendarItems = groupedCalendarItems;
        const planningGridDisplayParentXPosition = this.planningContext.planningGridDisplay.bodyContainer.nativeElement.parentElement.getBoundingClientRect().x;

        if (!initialized) {
          this.initializeRealtimeChangesService();

          if (planningGridDisplayParentXPosition > 0) {
            this.initializeAndStartOnboarding();
            onboardingStarted = true;
          }

          initialized = true;
        }

        if (planningGridDisplayParentXPosition > 0 && !onboardingStarted) {
          this.initializeAndStartOnboarding();
          onboardingStarted = true;
        }
      });
  }

  private initializeAndStartOnboarding(): void {
    this.planningOnboarding.initializeOnboarding(this.planningContext.planningGridDisplay, this.planningSideBars);
    this.planningOnboarding.startOnboarding();
  }

  private getRegimes(): void {
    this.machineOverview
      .getListOfCustomSettings()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((machineOverviewSettings: PropertyValue[]) => {
        const regimesValue = machineOverviewSettings.find((propertyValue: PropertyValue) => propertyValue.propertyName === 'regimes');
        this.regimes = regimesValue.propertyValue;
      });
  }

  public navigateToTexFab(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.absolutePath);
  }

  public selectedDateRangeChanged(dateRange: DateRange): void {
    if (dateRange[0].getTime() !== this.selectedDateRange[0].getTime() || dateRange[1].getTime() !== this.selectedDateRange[1].getTime()) {
      this.selectedDateRange = dateRange;
      this.realtimeChanges.selectedDateRange = dateRange;
      this.planningScheduler.getPlanning(this.selectedDateRange);
    }
  }

  public addPlaceholder({event, rowIndex, date}: {event: PointerEvent; rowIndex: number; date: Date}): void {
    const selectedMachine = this.planningContext.machinesForSelectedPlanningGroups[rowIndex];
    const canAddItem = selectedMachine != null && !this.planningSidebarManager.opened && date > this.planningContext.getCurrentDateForSubscriptionTimezone();

    if (canAddItem && this.canAddItemForMachineType(selectedMachine.machineType)) {
      this.planningContext.selectedPlaceholder = new PlanningMachineMaintenance(null, date, moment(date).add(1, 'h').toDate());
      this.planningSideBars.openPlanningItemDetails(selectedMachine, this.planningContext.selectedPlaceholder);
      event?.stopImmediatePropagation();
    }
  }

  private canAddItemForMachineType(machineType: MachineType): boolean {
    switch (machineType) {
      case MachineType.CARPET_LOOM:
      case MachineType.VELVET:
        return this.currentSubscription?.hasPermission(this.CARPET_QUEUE_PERMISSION);
      case MachineType.TUFT:
        return this.currentSubscription?.hasPermission(this.TUFT_QUEUE_PERMISSION);
      case MachineType.WEAVING_MACHINE:
        return this.currentSubscription?.hasPermission(this.WEAVE_QUEUE_PERMISSION);
      default:
        return true;
    }
  }

  private initializeRealtimeChangesService(): void {
    this.realtimeChanges.initialize(this.selectedDateRange, this.planningSidebarManager, this.planningSideBars);

    this.subscribeToRealtimePlanningChanges();
    this.subscribeToRealtimeDeletedPlanningItems();
    this.subscribeToRealtimeProductionOrderStatusChanges();
    this.subscribeToRealtimeProductionOrderLiteStatusChanges();
  }

  private subscribeToRealtimePlanningChanges(): void {
    this.realtimePlanning
      .getPlanningChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((updatedGroupedCalendarItems: CalendarItemGroup[]) => {
        if (!this.planningContext.isOnboardingRunning) {
          this.realtimeChanges.updateGroupedCalendarItems(updatedGroupedCalendarItems);
        }
      });
  }

  private subscribeToRealtimeDeletedPlanningItems(): void {
    this.realtimePlanning
      .getDeletedPlanningItem()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((deletedPlanningItem: DeletedPlanningItem) => {
        if (!this.planningContext.isDragAndDropActive || deletedPlanningItem.id !== this.planningContext.selectedPlanningItem?.id) {
          this.realtimeChanges.removePlanningItemFromGroupedCalendarItems(deletedPlanningItem);
          this.realtimeChanges.showToastForDeletedPlanningItem(deletedPlanningItem.type);
        }
      });
  }

  private subscribeToRealtimeProductionOrderStatusChanges(): void {
    this.realtimeProductionOrder
      .getProductionOrdersStatusChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionOrderStatusChange: ProductionOrderStatusChange) => {
        this.realtimeChanges.updatePlanningOrderStatus(productionOrderStatusChange.id, productionOrderStatusChange.status, false);
      });
  }

  private subscribeToRealtimeProductionOrderLiteStatusChanges(): void {
    this.realtimeProductionOrderLite
      .getProductionOrdersLiteStatusChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionOrderLiteStatusChange: ProductionOrderLiteStatusChange) =>
        this.realtimeChanges.updatePlanningOrderStatus(productionOrderLiteStatusChange.id, productionOrderLiteStatusChange.status, true)
      );
  }

  private updatePlanningGroupFilter(): void {
    const storedFilter = this.getStoredPlanningGroupFilter();
    let allGroups = ArrayUtils.distinctBy(
      this.planningContext.machines.map((machine: PlanningMachine) => machine.group),
      (group: MachineGroup) => group?.id
    );
    allGroups.sort(MachineGroup.compare);
    const undefinedIndex = allGroups.findIndex((group: MachineGroup) => AssertionUtils.isNullOrUndefined(group));
    if (undefinedIndex > -1) {
      allGroups[undefinedIndex] = new MachineGroup(this.fallbackMachineGroupId, this.translate.instant('PLANNING.NO_PLANNING_GROUP'));
    }

    allGroups = allGroups.filter((group: MachineGroup) => !AssertionUtils.isNullOrUndefined(group.name));

    this.planningGroupFilter = allGroups.map((group: MachineGroup) => ({
      name: group.name,
      value: storedFilter?.find((selectionItem: SelectionListItem) => selectionItem.name === group.name)?.value ?? false
    }));
    this.updateMachinesForSelectedPlanningGroupsList();
  }

  private updateMachinesForSelectedPlanningGroupsList(): void {
    const enabledGroups = this.planningGroupFilter?.filter((item: SelectionListItem) => item.value).map((item: SelectionListItem) => item.name);
    if (enabledGroups?.length <= 0) {
      this.planningContext.machinesForSelectedPlanningGroups = this.planningContext.machines;

      this.getNavigationData();
      return;
    }

    const fallbackGroupName = this.translate.instant('PLANNING.NO_PLANNING_GROUP');
    this.planningContext.machinesForSelectedPlanningGroups = this.planningContext.machines?.filter((machine: PlanningMachine) => enabledGroups.includes(machine.group?.name ?? fallbackGroupName));
  }

  private getStoredPlanningGroupFilter(): SelectionListItem[] {
    return this.localStorage.get<SelectionListItem[]>(this.MACHINES_FILTER_KEY);
  }

  private storePlanningGroupFilter(value: SelectionListItem[]): void {
    this.localStorage.set(this.MACHINES_FILTER_KEY, value);
  }

  private getNavigationData(): void {
    const emptyDateRangeState = {dateRange: null} as PlanningDateRangeData;
    const dateRangeState = this.navigationHelperService.getPartialState<PlanningDateRangeData>(Object.keys(emptyDateRangeState));

    if (dateRangeState && !isEqual(emptyDateRangeState, dateRangeState)) {
      this.selectedDateRangeChanged(dateRangeState.dateRange);
    }
  }
}
