import {Component} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {BaseComponent} from '../../base-component';
import {StringCellEditorParams} from './string-cell-editor-params.interface';

@Component({
  templateUrl: './string-cell-editor.component.html',
  styleUrls: ['./string-cell-editor.component.scss']
})
export class StringCellEditorComponent extends BaseComponent implements ICellEditorAngularComp {
  public maxlength: number;
  public value: string;
  public colId: string;

  public agInit(params: StringCellEditorParams): void {
    this.maxlength = params.max;
    this.value = params.value;
    this.colId = params.colDef?.colId;
  }

  public getValue(): string {
    return this.value;
  }
}
