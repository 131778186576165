import {StringUtils} from '@application/helper/string-utils';
import {IdName} from '@domain/id-name';
import {Equipment} from '@domain/machine/equipment';
import {CommercialMachineQuality} from '@domain/textile-data/machine-quality/commercial-machine-quality';
import {IncorporationFactorException} from '@domain/textile-data/machine-quality/incorporation-factor-exception';
import {TuftLengthException} from '@domain/textile-data/machine-quality/tuft-Length-exception';
import {DoubleWidthWeaveStructure} from '@domain/textile-data/weave-structure/double-width-weave-structure';
import {SingleWidthWeaveStructure} from '@domain/textile-data/weave-structure/single-width-weave-structure';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {MachineOverviewListHelper} from '@presentation/pages/machine-overview/machine-overview-list-helper';
import {WeaveStructureWidth} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-general/weave-structure-width.enum';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {isNil, map} from 'lodash-es';
import {Finishing} from '../finishing-and-finishing-template/finishing/finishing';
import {OverviewListDoubleWidthWeaveStructure} from '../weave-structure/overview-list-double-width-weave-structure';
import {OverviewListSingleWidthWeaveStructure} from '../weave-structure/overview-list-single-width-weave-structure';
import {GeneralJuteSettings} from './general-jute-settings';
import {Jute} from './jute';
import {PathWidth} from './path-width';
import {WeftSelection} from './weft-selection';

export class WeaveQuality {
  private _id: number;
  private _technicalName: string;
  private readonly _version: number;
  private _type: WeaveQualityType;
  private readonly _weaveStructureWidth: WeaveStructureWidth;
  private _singleWidth: SingleWidthWeaveStructure;
  private readonly _doubleWidth: DoubleWidthWeaveStructure;
  private readonly _reedDensityInDentsPerMM: number;
  private readonly _pickDensityInPicksPerMM: number;
  private readonly _weftDensityInDentsPerMM: number;
  private readonly _technicalCode: string;
  private readonly _practicalPickDensityInPicksPerMM: number;
  private readonly _commercialMachineQualities: CommercialMachineQuality[];
  private readonly _pathWidths: PathWidth[];
  private _applicableMachines: MachineOverviewList[];
  private _machinesWithSpecificPickDensity: {machine: MachineOverviewList; specificPickDensity: number}[];
  private readonly _pileHeightInMM: number;
  private readonly _buggyLengthMinInMM: number;
  private readonly _buggyLengthMaxInMM: number;
  private readonly _tuftLengthInMM: number;
  private readonly _incorporationFactor: number;
  private readonly _tolerance: number;
  private readonly _picksPerHourMin: number;
  private readonly _tuftLengthPatternTypeExceptions: TuftLengthException[];
  private readonly _tuftLengthYarnTypeExceptions: TuftLengthException[];
  private readonly _incorporationFactorPatternTypeExceptions: IncorporationFactorException[];
  private readonly _incorporationFactorYarnTypeExceptions: IncorporationFactorException[];
  private _used: boolean;
  private _numberOfColumnsToDropForWeftSelection: number;
  private _jute: Jute[];
  private _finishings: Finishing[];
  private _juteGeneralSettings: GeneralJuteSettings;
  private readonly _weftSelections: WeftSelection[];
  private _yarnSet: IdName;
  private _canAddJute: boolean;
  private _missingDesignMarginInDents: number;

  public constructor(
    id: number,
    technicalName: string,
    version: number,
    type: WeaveQualityType,
    weaveStructureWidth: WeaveStructureWidth,
    singleWidth: SingleWidthWeaveStructure,
    doubleWidth: DoubleWidthWeaveStructure,
    reedDensityInDentsPerMM: number,
    pickDensityInPicksPerMM: number,
    weftDensityInDentsPerMM: number,
    technicalCode: string,
    practicalPickDensityInPicksPerMM: number,
    commercialMachineQualities: CommercialMachineQuality[],
    pathWidths: PathWidth[],
    applicableMachines: MachineOverviewList[],
    machinesWithSpecificPickDensity: {machine: MachineOverviewList; specificPickDensity: number}[],
    pileHeightInMM: number,
    buggyLengthMinInMM: number,
    buggyLengthMaxInMM: number,
    tuftLengthInMM: number,
    incorporationFactor: number,
    tolerance: number,
    picksPerHourMin: number,
    tuftLengthPatternTypeExceptions: TuftLengthException[],
    tuftLengthYarnTypeExceptions: TuftLengthException[],
    incorporationFactorPatternTypeExceptions: IncorporationFactorException[],
    incorporationFactorYarnTypeExceptions: IncorporationFactorException[],
    used: boolean,
    numberOfColumnsToDropForWeftSelection: number,
    jute: Jute[],
    weftSelections: WeftSelection[],
    juteGeneralSettings: GeneralJuteSettings,
    yarnSet: IdName,
    missingDesignMarginInDents: number,
    finishings: Finishing[] = [],
    canAddJute?: boolean
  ) {
    this._id = id;
    this._technicalName = technicalName;
    this._version = version;
    this._type = type;
    this._weaveStructureWidth = weaveStructureWidth;
    this._singleWidth = singleWidth;
    this._doubleWidth = doubleWidth;
    this._reedDensityInDentsPerMM = reedDensityInDentsPerMM;
    this._pickDensityInPicksPerMM = pickDensityInPicksPerMM;
    this._weftDensityInDentsPerMM = weftDensityInDentsPerMM;
    this._technicalCode = technicalCode;
    this._practicalPickDensityInPicksPerMM = practicalPickDensityInPicksPerMM;
    this._commercialMachineQualities = commercialMachineQualities;
    this._pathWidths = pathWidths;
    this._applicableMachines = applicableMachines;
    this._machinesWithSpecificPickDensity = machinesWithSpecificPickDensity;
    this._pileHeightInMM = pileHeightInMM;
    this._buggyLengthMinInMM = buggyLengthMinInMM;
    this._buggyLengthMaxInMM = buggyLengthMaxInMM;
    this._tuftLengthInMM = tuftLengthInMM;
    this._incorporationFactor = incorporationFactor;
    this._tolerance = tolerance;
    this._picksPerHourMin = picksPerHourMin;
    this._tuftLengthPatternTypeExceptions = tuftLengthPatternTypeExceptions;
    this._tuftLengthYarnTypeExceptions = tuftLengthYarnTypeExceptions;
    this._incorporationFactorPatternTypeExceptions = incorporationFactorPatternTypeExceptions;
    this._incorporationFactorYarnTypeExceptions = incorporationFactorYarnTypeExceptions;
    this._used = used;
    this._numberOfColumnsToDropForWeftSelection = numberOfColumnsToDropForWeftSelection;
    this._jute = jute;
    this._finishings = finishings;
    this._weftSelections = weftSelections;
    this._juteGeneralSettings = juteGeneralSettings;
    this._yarnSet = yarnSet;
    this._missingDesignMarginInDents = missingDesignMarginInDents;
    this._canAddJute = canAddJute;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get technicalName(): string {
    return this._technicalName;
  }

  public set technicalName(technicalName: string) {
    this._technicalName = technicalName;
  }

  public get version(): number {
    return this._version;
  }

  public get technicalNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.technicalName, this.version);
  }

  public get type(): WeaveQualityType {
    return this._type;
  }

  public set type(type: WeaveQualityType) {
    this._type = type;
  }

  public get weaveStructureWidth(): WeaveStructureWidth {
    return this._weaveStructureWidth;
  }

  public get weaveStructureId(): number {
    return this._weaveStructureWidth === WeaveStructureWidth.DOUBLE_WIDTH ? this._doubleWidth.id ?? this._doubleWidth.topWeaveStructure.id : this._singleWidth.weaveStructure.id;
  }

  public get singleWidth(): SingleWidthWeaveStructure {
    return this._singleWidth;
  }

  public set singleWidth(value: SingleWidthWeaveStructure) {
    this._singleWidth = value;
  }

  public get doubleWidth(): DoubleWidthWeaveStructure {
    return this._doubleWidth;
  }

  public get reedDensityInDentsPerMM(): number {
    return this._reedDensityInDentsPerMM;
  }

  public get pickDensityInPicksPerMM(): number {
    return this._pickDensityInPicksPerMM;
  }

  public get weftDensityInDentsPerMM(): number {
    return this._weftDensityInDentsPerMM;
  }

  public get technicalCode(): string {
    return this._technicalCode;
  }

  public get practicalPickDensityInPicksPerMM(): number {
    return this._practicalPickDensityInPicksPerMM;
  }

  public get commercialMachineQualities(): CommercialMachineQuality[] {
    return this._commercialMachineQualities;
  }

  public get pathWidths(): PathWidth[] {
    return this._pathWidths;
  }

  public get applicableMachines(): MachineOverviewList[] {
    return this._applicableMachines;
  }

  public set applicableMachines(applicableMachines: MachineOverviewList[]) {
    this._applicableMachines = applicableMachines;
  }

  public get machinesWithSpecificPickDensity(): {machine: MachineOverviewList; specificPickDensity: number}[] {
    return this._machinesWithSpecificPickDensity;
  }

  public set machinesWithSpecificPickDensity(machinesWithSpecificPickDensity: {machine: MachineOverviewList; specificPickDensity: number}[]) {
    this._machinesWithSpecificPickDensity = machinesWithSpecificPickDensity;
  }

  public get pileHeightInMM(): number {
    return this._pileHeightInMM;
  }

  public get buggyLengthMinInMM(): number {
    return this._buggyLengthMinInMM;
  }

  public get buggyLengthMaxInMM(): number {
    return this._buggyLengthMaxInMM;
  }

  public get tuftLengthInMM(): number {
    return this._tuftLengthInMM;
  }

  public get incorporationFactor(): number {
    return this._incorporationFactor;
  }

  public get tolerance(): number {
    return this._tolerance;
  }

  public get picksPerHourMin(): number {
    return this._picksPerHourMin;
  }

  public get tuftLengthPatternTypeExceptions(): TuftLengthException[] {
    return this._tuftLengthPatternTypeExceptions;
  }

  public get tuftLengthYarnTypeExceptions(): TuftLengthException[] {
    return this._tuftLengthYarnTypeExceptions;
  }

  public get incorporationFactorPatternTypeExceptions(): IncorporationFactorException[] {
    return this._incorporationFactorPatternTypeExceptions;
  }

  public get incorporationFactorYarnTypeExceptions(): IncorporationFactorException[] {
    return this._incorporationFactorYarnTypeExceptions;
  }

  public get used(): boolean {
    return this._used;
  }

  public set used(value: boolean) {
    this._used = value;
  }

  public get numberOfColumnsToDropForWeftSelection(): number {
    return this._numberOfColumnsToDropForWeftSelection;
  }

  public set numberOfColumnsToDropForWeftSelection(numberOfColumnsToDropForWeftSelection: number) {
    this._numberOfColumnsToDropForWeftSelection = numberOfColumnsToDropForWeftSelection;
  }

  public get jute(): Jute[] {
    return this._jute;
  }

  public set jute(value: Jute[]) {
    this._jute = value;
  }

  public get finishings(): Finishing[] {
    return this._finishings;
  }

  public set finishings(value: Finishing[]) {
    this._finishings = value;
  }

  public get yarnSet(): IdName {
    return this._yarnSet;
  }

  public set yarnSet(value: IdName) {
    this._yarnSet = value;
  }

  public get missingDesignMarginInDents(): number {
    return this._missingDesignMarginInDents;
  }

  public set missingDesignMarginInDents(value: number) {
    this._missingDesignMarginInDents = value;
  }

  public get weftSelections(): WeftSelection[] {
    return this._weftSelections;
  }

  public static createEmptyWeaveQuality(): WeaveQuality {
    return new WeaveQuality(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      null,
      null,
      [],
      [],
      null,
      null,
      0
    );
  }

  public get juteGeneralSettings(): GeneralJuteSettings {
    return this._juteGeneralSettings;
  }

  public set juteGeneralSettings(value: GeneralJuteSettings) {
    this._juteGeneralSettings = value;
  }

  public get canAddJute(): boolean {
    return this._canAddJute;
  }

  public set canAddJute(value: boolean) {
    this._canAddJute = value;
  }

  public static fromJSON(weaveQualityJSON: any): WeaveQuality {
    let result: WeaveQuality = null;

    if (!isNil(weaveQualityJSON)) {
      result = new WeaveQuality(
        weaveQualityJSON.id,
        weaveQualityJSON.technicalName,
        weaveQualityJSON.version,
        weaveQualityJSON.type,
        weaveQualityJSON.weaveStructureWidthType ? weaveQualityJSON.weaveStructureWidthType : null,
        WeaveQuality.getBackwardsSingleWidthWeaveStructure(weaveQualityJSON),
        DoubleWidthWeaveStructure.fromJSON(weaveQualityJSON),
        weaveQualityJSON.reedDensityInDentsPerMM,
        weaveQualityJSON.theoreticalPickDensityInPicksPerMM,
        weaveQualityJSON.weftDensityInDentsPerMM,
        weaveQualityJSON.technicalCode,
        isNil(weaveQualityJSON.specificPickDensity) ? weaveQualityJSON.practicalPickDensityInPicksPerMM : weaveQualityJSON.specificPickDensity,
        map(weaveQualityJSON.commercialMachineQualities, (commercialMachineQualityJSON: any) => {
          return CommercialMachineQuality.fromJSON(commercialMachineQualityJSON);
        }),
        weaveQualityJSON.pathWidths?.map((pathWidthJSON: any) => {
          return PathWidth.fromJSON(pathWidthJSON);
        }) ?? [],
        map(weaveQualityJSON.applicableMachines, (applicableMachineJSON: any) => {
          return MachineOverviewListHelper.fromJSON(applicableMachineJSON);
        }),
        map(weaveQualityJSON.machinesWithSpecificPickDensity, (machineWithSpecificPickDensityJSON: any) => {
          return {
            machine: MachineOverviewListHelper.fromJSON(machineWithSpecificPickDensityJSON.machine),
            specificPickDensity: machineWithSpecificPickDensityJSON.specificPickDensity
          };
        }),
        weaveQualityJSON.pileHeightInMM,
        weaveQualityJSON.buggyLengthMinInMM,
        weaveQualityJSON.buggyLengthMaxInMM,
        weaveQualityJSON.tuftLengthInMM,
        weaveQualityJSON.incorporationFactor,
        weaveQualityJSON.tolerance,
        weaveQualityJSON.picksPerHourMin,
        WeaveQuality.getTuftLengthExceptions(weaveQualityJSON.tuftLengthPatternTypeExceptions),
        WeaveQuality.getTuftLengthExceptions(weaveQualityJSON.tuftLengthYarnTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(weaveQualityJSON.incorporationFactorPatternTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(weaveQualityJSON.incorporationFactorYarnTypeExceptions),
        weaveQualityJSON.used,
        weaveQualityJSON.nrColumnsToDropForWeftSelection,
        weaveQualityJSON.jute?.map((jute: any) => {
          return Jute.fromJSON(jute);
        }) ?? [],
        weaveQualityJSON.weftSelections
          ?.map((weftSelectionJSON: any) => WeftSelection.fromJSON(weftSelectionJSON))
          ?.sort((currentWeftSelection: WeftSelection, nextWeftSelection: WeftSelection) => {
            return currentWeftSelection.position < nextWeftSelection.position ? -1 : 1;
          }) ?? [],
        weaveQualityJSON.juteGeneralSettings ? GeneralJuteSettings.fromJSON(weaveQualityJSON.juteGeneralSettings) : null,
        weaveQualityJSON.yarnSet ? IdName.fromJSON(weaveQualityJSON.yarnSet) : null,
        weaveQualityJSON.missingDesignMarginInDents,
        weaveQualityJSON.finishings?.map((finishing: any) => Finishing.fromJSON(finishing)),
        weaveQualityJSON.canAddJute
      );
    }

    return result;
  }

  public static getBackwardsSingleWidthWeaveStructure(weaveQualityJSON: any): SingleWidthWeaveStructure {
    if (!weaveQualityJSON.singleWidth && !weaveQualityJSON.doubleWidth) {
      return weaveQualityJSON.weaveStructure ? SingleWidthWeaveStructure.fromJSON(weaveQualityJSON.weaveStructure) : null;
    } else if (!weaveQualityJSON.singleWidth && weaveQualityJSON.doubleWidth) {
      return null;
    } else {
      return SingleWidthWeaveStructure.fromJSON(weaveQualityJSON.singleWidth.weaveStructure);
    }
  }

  public static getBackwardsOverviewListSingleWidthWeaveStructure(weaveQualityJSON: any): OverviewListSingleWidthWeaveStructure {
    if (!weaveQualityJSON.singleWidth && !weaveQualityJSON.doubleWidth) {
      return OverviewListSingleWidthWeaveStructure.fromOldJSON(weaveQualityJSON);
    } else if (!weaveQualityJSON.singleWidth && weaveQualityJSON.doubleWidth) {
      return null;
    } else {
      return OverviewListSingleWidthWeaveStructure.fromJSON(weaveQualityJSON);
    }
  }

  public static fromOverviewListMachineQuality(overviewListMachineQuality: OverviewListMachineQuality): WeaveQuality {
    return new WeaveQuality(
      overviewListMachineQuality.id,
      overviewListMachineQuality.technicalName,
      overviewListMachineQuality.version,
      overviewListMachineQuality.type,
      overviewListMachineQuality.weaveStructureWidth,
      overviewListMachineQuality.singleWidth ? SingleWidthWeaveStructure.fromOverviewListSingleWidthWeaveStructure(overviewListMachineQuality.singleWidth) : null,
      overviewListMachineQuality.doubleWidth ? DoubleWidthWeaveStructure.fromOverviewListDoubleWidthWeaveStructure(overviewListMachineQuality.doubleWidth) : null,
      overviewListMachineQuality.reedDensityInDentsPerMM,
      overviewListMachineQuality.pickDensityInPicksPerMM,
      overviewListMachineQuality.weftDensityInDentsPerMM,
      'technicalcode',
      overviewListMachineQuality.practicalPickDensityInPicksPerMM,
      [],
      [new PathWidth(1, 2, 3)],
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      null,
      null,
      [],
      [],
      null,
      null,
      0,
      [],
      false
    );
  }

  public static toOverviewListMachineQuality(weaveQuality: WeaveQuality): OverviewListMachineQuality {
    return new OverviewListMachineQuality(
      weaveQuality.id,
      weaveQuality.technicalName,
      weaveQuality.version,
      weaveQuality.type,
      weaveQuality.weaveStructureWidth ? weaveQuality.weaveStructureWidth : null,
      WeaveQuality.getBackwardsOverviewListSingleWidthWeaveStructure(weaveQuality),
      weaveQuality.doubleWidth ? OverviewListDoubleWidthWeaveStructure.fromJSON(weaveQuality) : null,
      weaveQuality.reedDensityInDentsPerMM,
      weaveQuality.pickDensityInPicksPerMM,
      weaveQuality.weftDensityInDentsPerMM,
      weaveQuality.practicalPickDensityInPicksPerMM,
      weaveQuality.pileHeightInMM,
      weaveQuality.applicableMachines,
      null,
      weaveQuality.used
    );
  }

  public static getTuftLengthExceptions(tuftLengthExceptions: any): TuftLengthException[] {
    return !isNil(tuftLengthExceptions)
      ? map(tuftLengthExceptions, (tuftLengthExceptionJSON: any) => {
          return TuftLengthException.fromJSON(tuftLengthExceptionJSON);
        })
      : null;
  }

  public static getIncorporationFactorExceptions(incorporationFactorExceptions: any): IncorporationFactorException[] {
    return !isNil(incorporationFactorExceptions)
      ? map(incorporationFactorExceptions, (incorporationFactorExceptionJSON: any) => {
          return IncorporationFactorException.fromJSON(incorporationFactorExceptionJSON);
        })
      : null;
  }

  public toJSON(): JSON {
    const machinesWithSpecificPickDensity = map(this._machinesWithSpecificPickDensity, (machineWithSpecificPickDensity: {machine: Equipment; specificPickDensity: number}) => {
      return {
        machineId: machineWithSpecificPickDensity.machine.id,
        specificPickDensity: machineWithSpecificPickDensity.specificPickDensity
      };
    });

    return {
      technicalName: this._technicalName,
      version: this._version,
      type: this._type,
      weaveStructureWidthType: this._weaveStructureWidth,
      singleWidth: this._singleWidth ? {weaveStructureId: this._singleWidth.weaveStructure.id} : null,
      weaveStructureId: this._singleWidth ? this._singleWidth.weaveStructure.id : null,
      weaveStructureName: this._singleWidth ? this._singleWidth.weaveStructure.name : this._doubleWidth.bottomWeaveStructure.name.slice(0, -1),
      doubleWidth: this._doubleWidth
        ? {
            topWeaveStructureId: this._doubleWidth.topWeaveStructure.id,
            bottomWeaveStructureId: this._doubleWidth.bottomWeaveStructure.id,
            backgroundWeaveStructureId: this._doubleWidth.backgroundWeaveStructure.id
          }
        : null,
      reedDensityInDentsPerMM: this._reedDensityInDentsPerMM ?? 0,
      theoreticalPickDensityInPicksPerMM: this._pickDensityInPicksPerMM ?? 0,
      weftDensityInDentsPerMM: this._weftDensityInDentsPerMM ?? 0,
      technicalCode: this._technicalCode,
      practicalPickDensityInPicksPerMM: this._practicalPickDensityInPicksPerMM ?? 0,
      commercialMachineQualities: map(this._commercialMachineQualities, (commercialMachineQuality: CommercialMachineQuality) => {
        return commercialMachineQuality.toJSON();
      }),
      pathWidths: this._pathWidths.map((pathWidth: PathWidth) => {
        return pathWidth.toJSON();
      }),
      applicableMachineIds: map(this._applicableMachines, 'id'),
      machinesWithSpecificPickDensity,
      pileHeightInMM: this._pileHeightInMM,
      buggyLengthMinInMM: this._buggyLengthMinInMM,
      buggyLengthMaxInMM: this._buggyLengthMaxInMM,
      tuftLengthInMM: this._tuftLengthInMM,
      incorporationFactor: this._incorporationFactor,
      tolerance: this._tolerance,
      picksPerHourMin: this._picksPerHourMin,
      tuftLengthPatternTypeExceptions: map(this._tuftLengthPatternTypeExceptions, (tuftLengthPatternTypeException: TuftLengthException) => {
        return tuftLengthPatternTypeException.toJSON();
      }),
      tuftLengthYarnTypeExceptions: map(this._tuftLengthYarnTypeExceptions, (tuftLengthYarnTypeException: TuftLengthException) => {
        return tuftLengthYarnTypeException.toJSON();
      }),
      incorporationFactorPatternTypeExceptions: map(this._incorporationFactorPatternTypeExceptions, (incorporationFactorPatternTypeException: IncorporationFactorException) => {
        return incorporationFactorPatternTypeException.toJSON();
      }),
      incorporationFactorYarnTypeExceptions: map(this._incorporationFactorYarnTypeExceptions, (incorporationFactorYarnTypeException: IncorporationFactorException) => {
        return incorporationFactorYarnTypeException.toJSON();
      }),
      used: this._used,
      nrColumnsToDropForWeftSelection: this._numberOfColumnsToDropForWeftSelection,
      jute: map(this.jute, (jute: Jute) => {
        return jute.toJSON();
      }),
      finishings: this.finishings.map((finishing: Finishing) => finishing.toJSON()),
      weftSelections: this.weftSelections.map((weftSelection: WeftSelection) => {
        return weftSelection.toJSON();
      }),
      juteGeneralSettings: this._juteGeneralSettings.toJSON(),
      yarnSetId: this.yarnSet ? this._yarnSet.id : null,
      yarnSetName: this.yarnSet ? this._yarnSet.name : null,
      missingDesignMarginInDents: this._missingDesignMarginInDents
    } as any as JSON;
  }
}
