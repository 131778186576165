export enum BackgroundStatus {
  NOT_STARTED,
  STARTED,
  COMPLETED,
  FAILED,
  CANCELLED
}

const backgroundStatusIconMap: Map<BackgroundStatus, string> = new Map<BackgroundStatus, string>([
  [BackgroundStatus.NOT_STARTED, 'draft'],
  [BackgroundStatus.STARTED, 'execute'],
  [BackgroundStatus.COMPLETED, 'completed'],
  [BackgroundStatus.FAILED, 'failed'],
  [BackgroundStatus.CANCELLED, 'cancelled']
]);

export namespace BackgroundStatus {
  export function getStatusIconName(status: BackgroundStatus): string {
    return backgroundStatusIconMap.get(status);
  }
}
