<div
  class="input-chip full-max-width"
  [ngClass]="{
    disabled: disabled,
    error: error && !disabled,
    url: route,
    'bms-theme': canShowBMSTheme,
    'padding-with-close-icon': canShowCloseIcon() || canShowCloseIconForBMSTheme(),
    'padding-without-close-icon': !canShowCloseIcon() || canShowCloseIconForBMSTheme()
  }"
>
  <mat-icon *ngIf="canMoveChips" class="drag-handle icon-12" svgIcon="solid-drag-indicator"></mat-icon>
  <span matPrefix *ngIf="color" class="color-preview" [style.background-color]="color"></span>
  <a *ngIf="route; else chipValue" [routerLink]="route" class="overflow-hidden" [tabindex]="getTabIndex()" (click)="$event.stopPropagation()">
    <ng-container *ngTemplateOutlet="chipValue"></ng-container>
  </a>
  <ng-template #chipValue>
    <p class="full-width" [class.b1]="!canShowBMSTheme" [class.b2]="canShowBMSTheme">
      <vdw-ellipsis-label [text]="value" matTooltipPosition="above" />
    </p>
  </ng-template>
  <mat-icon *ngIf="canShowCloseIcon()" class="icon-24 cursor-pointer close-icon" [class.mat-icon-error]="error" svgIcon="close-small" (click)="onRemoveClick($event)"></mat-icon>
  <button *ngIf="canShowCloseIconForBMSTheme()" mat-icon-button class="close-button" (click)="onRemoveClick($event)">
    <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
  </button>
</div>
