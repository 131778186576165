import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'hexValue'})
export class HexValuePipe implements PipeTransform {
  public transform(value: string, length: number = 4): string {
    if (value && length) {
      const hexValue = this.binToHex(value);
      return '0x' + this.addLeadingZeros(length - hexValue.length) + hexValue;
    }
    if (value) {
      return '0x' + this.binToHex(value);
    }
    return '0x' + this.addLeadingZeros(length);
  }

  private binToHex(value: string): string {
    return parseInt(value, 2).toString(16).toUpperCase();
  }

  private addLeadingZeros(numberOfZeros: number): string {
    return ''.padStart(numberOfZeros, '0');
  }
}
