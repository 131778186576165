import {UUID} from 'crypto';

export class PlanningItemForecast {
  private _draftId: UUID;
  private _estimatedStart: Date;
  private _estimatedEnd: Date;

  public constructor(draftId: UUID, estimatedStart: Date, estimatedEnd: Date) {
    this._draftId = draftId;
    this._estimatedStart = estimatedStart;
    this._estimatedEnd = estimatedEnd;
  }

  public get draftId(): UUID {
    return this._draftId;
  }

  public get estimatedStart(): Date {
    return this._estimatedStart;
  }

  public get estimatedEnd(): Date {
    return this._estimatedEnd;
  }

  public static fromJSON(json: any): PlanningItemForecast {
    return new PlanningItemForecast(json.draftId, new Date(json.estimatedStart), new Date(json.estimatedEnd));
  }
}
