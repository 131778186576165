import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './disabled-cell-renderer.component.html',
  styleUrls: ['./disabled-cell-renderer.component.scss']
})
export class DisabledCellRendererComponent implements ICellRendererAngularComp {
  public value: number;
  public indentClass: string;

  public agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.indentClass = params.node.level === 1 ? 'ag-row-group-indent-2' : 'ag-row-group-indent-1';
  }

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
