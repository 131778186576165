import {YarnConsumption} from '@domain/production-schedule/yarn-consumption';
import {Unit} from '@vdw/angular-component-library';
import {first, size} from 'lodash-es';

export class OverviewListYarnType {
  private _id: number;
  private _name: string;
  private _yarnCount: number;
  private _yarnCountInNm: number;
  private _yarnCountUnit: Unit;
  private _threads: {rawMaterial: string}[];
  private _yarnConsumption: YarnConsumption;
  private _description: string;
  private _groupName: string;
  private _comment: string;

  public constructor(
    id: number,
    name: string,
    yarnCount: number,
    yarnCountUnit: Unit,
    threads: {rawMaterial: string}[],
    description?: string,
    groupName?: string,
    comment?: string,
    yarnConsumption?: YarnConsumption,
    yarnCountInNm?: number
  ) {
    this._id = id;
    this._name = name;
    this._yarnCount = yarnCount;
    this._yarnCountUnit = yarnCountUnit;
    this._threads = threads;
    this._yarnConsumption = yarnConsumption;
    this._description = description;
    this._groupName = groupName;
    this._comment = comment;
    this._yarnCountInNm = yarnCountInNm;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get yarnCount(): number {
    return this._yarnCount;
  }

  public get yarnCountUnit(): Unit {
    return this._yarnCountUnit;
  }

  public get threads(): {rawMaterial: string}[] {
    return this._threads;
  }

  public get yarnConsumption(): YarnConsumption {
    return this._yarnConsumption;
  }

  public set yarnConsumption(yarnConsumption: YarnConsumption) {
    this._yarnConsumption = yarnConsumption;
  }

  public get description(): string {
    return this._description;
  }

  public get groupName(): string {
    return this._groupName;
  }

  public get comment(): string {
    return this._comment;
  }

  public get yarnCountInNm(): number {
    return this._yarnCountInNm;
  }

  public set yarnCountInNm(value: number) {
    this._yarnCountInNm = value;
  }

  public static fromJSON(yarnTypeJSON: any): OverviewListYarnType {
    return new OverviewListYarnType(
      yarnTypeJSON.id,
      yarnTypeJSON.name,
      yarnTypeJSON.yarnCount,
      Unit[yarnTypeJSON.yarnCountUnit] as Unit,
      yarnTypeJSON.threads.map((threadJSON: any) => {
        return {rawMaterial: threadJSON.rawMaterialView.name};
      }),
      yarnTypeJSON.description,
      yarnTypeJSON.groupName,
      yarnTypeJSON.comment,
      undefined,
      yarnTypeJSON.yarnCountInNm
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      yarnCount: this._yarnCount,
      yarnCountUnit: this._yarnCountUnit,
      threads: this._threads
    } as any as JSON;
  }

  public getFirstRawMaterial(): string {
    return size(this.threads) > 0 ? first(this.threads).rawMaterial : '';
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
