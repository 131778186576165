import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {WeaveProduct} from './weave-product';

export class RunWeaving extends PlanningItem {
  private _weaveProduct: WeaveProduct;

  public constructor(itemProperties: PlanningItemProperties, weaveProduct: WeaveProduct, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.RUN_WEAVING, ...planningItems);
    this._weaveProduct = weaveProduct;
  }

  public get weaveProduct(): WeaveProduct {
    return this._weaveProduct;
  }

  public set weaveProduct(value: WeaveProduct) {
    this._weaveProduct = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      weaveProductId: this.weaveProduct.id
    } as any as JSON;
  }
}
