import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {AssertionUtils, CheckName} from '@vdw/angular-component-library';
import {Observable, Observer, of as observableOf} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';

export class AsyncUniqueValidator {
  public static createValidator(repository: UniqueIdentifierObjectRepository, originalIdentifier: string | number, version: number = 1): AsyncValidatorFn {
    return this.createValidatorForFunc((value: string) => repository.isIdentifierAvailable(value, version), originalIdentifier);
  }

  public static createValidatorForFunc(isIdentifierAvailable: (value: any) => Observable<boolean | CheckName>, originalIdentifier: string | number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return new Observable((observer: Observer<string>) => observer.next(control.value)).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        take(1),
        switchMap((value: any) => {
          if (!control.dirty && !control.touched) {
            return observableOf(null);
          }

          let result;
          value = AssertionUtils.isNullOrWhitespace(value.toString()) ? null : value.toString().trim().toLowerCase();

          if (AssertionUtils.isNullOrUndefined(value) || (!AssertionUtils.isNullOrUndefined(originalIdentifier) && originalIdentifier.toString().trim().toLowerCase() === value)) {
            result = observableOf(null);
          } else {
            result = isIdentifierAvailable(value).pipe(map(this.processCheckName()));
          }

          return result;
        })
      );
    };
  }

  private static processCheckName(): any {
    return (res: boolean | CheckName) => {
      if (typeof res === 'boolean') {
        return res ? null : {identifierTaken: true};
      } else {
        const checkName: CheckName = res;
        return checkName.isAvailable ? null : {identifierTaken: true, entityId: checkName.id};
      }
    };
  }
}
