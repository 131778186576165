import {isEqual} from 'lodash-es';

export class AlertDialogData {
  private static readonly exclamationInfo: string = 'info-blue';
  private static readonly exclamationWarning: string = 'warning-blue';
  private static readonly exclamationWarningFull: string = 'warning-full-36px';
  private static readonly exclamationError: string = 'error-full-36px';
  private static readonly cancelText: string = 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.CANCEL';
  private readonly _titleIcon: string;
  private readonly _titleText: string;
  private readonly _messageText: string;
  private readonly _extraInformationText: string;
  private readonly _cancelButtonText: string;
  private readonly _confirmationTypes: AlertDialogConfirmationType[];
  private readonly _confirmButtonText: string;
  private readonly _informationUrl: string;
  private readonly _entities: any[];
  private readonly _saveButton: boolean;

  public constructor(
    titleIcon: string,
    titleText: string,
    messageText: string,
    cancelButtonText: string,
    confirmationTypes: AlertDialogConfirmationType[],
    confirmButtonText?: string,
    extraInformationText?: string,
    informationUrl?: string,
    entities?: any[],
    saveButton: boolean = false
  ) {
    this._titleIcon = titleIcon;
    this._titleText = titleText;
    this._messageText = messageText;
    this._extraInformationText = extraInformationText;
    this._cancelButtonText = cancelButtonText;
    this._confirmationTypes = confirmationTypes;
    this._confirmButtonText = confirmButtonText;
    this._informationUrl = informationUrl;
    this._entities = entities;
    this._saveButton = saveButton;
  }

  public get titleIcon(): string {
    return this._titleIcon;
  }

  public get titleText(): string {
    return this._titleText;
  }

  public get messageText(): string {
    return this._messageText;
  }

  public get extraInformationText(): string {
    return this._extraInformationText;
  }

  public get cancelButtonText(): string {
    return this._cancelButtonText;
  }

  public get confirmationTypes(): AlertDialogConfirmationType[] {
    return this._confirmationTypes;
  }

  public get confirmButtonText(): string {
    return this._confirmButtonText;
  }

  public get informationUrl(): string {
    return this._informationUrl;
  }

  public get entities(): any[] {
    return this._entities;
  }

  public get saveButton(): boolean {
    return this._saveButton;
  }

  public static createConfirmationData(titleIcon: string, titleText: string, messageText: string, confirmationType: AlertDialogConfirmationType): AlertDialogData {
    return new AlertDialogData(isEqual(confirmationType, AlertDialogConfirmationType.DELETE) ? AlertDialogData.exclamationWarning : titleIcon, titleText, messageText, AlertDialogData.cancelText, [
      confirmationType
    ]);
  }

  public static createReportData(titleText: string, messageText: string, extraInformationText: string, confirmButtonText: string, informationUrl: string): AlertDialogData {
    return new AlertDialogData(
      AlertDialogData.exclamationInfo,
      titleText,
      messageText,
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.CLOSE',
      [AlertDialogConfirmationType.REPORT],
      confirmButtonText,
      extraInformationText,
      informationUrl
    );
  }

  public static createErrorData(titleText: string, messageText: string, extraInformationText?: string): AlertDialogData {
    return new AlertDialogData(AlertDialogData.exclamationError, titleText, messageText, '', [AlertDialogConfirmationType.REPORT], null, extraInformationText);
  }

  public static createInformativeData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(AlertDialogData.exclamationWarning, titleText, messageText, 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.OK', null);
  }

  public static createConfirmData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(AlertDialogData.exclamationWarning, titleText, messageText, AlertDialogData.cancelText, [AlertDialogConfirmationType.CONFIRM]);
  }

  public static createDiscardData(): AlertDialogData {
    return new AlertDialogData(
      AlertDialogData.exclamationError,
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.TITLE',
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.MESSAGE',
      AlertDialogData.cancelText,
      [AlertDialogConfirmationType.DISCARD],
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.ACTION'
    );
  }

  public static createDiscardAndSaveData(): AlertDialogData {
    return new AlertDialogData(
      AlertDialogData.exclamationError,
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.TITLE',
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.MESSAGE',
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD.ACTION',
      [AlertDialogConfirmationType.DISCARD],
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.SAVE_CHANGES',
      undefined,
      undefined,
      undefined,
      true
    );
  }

  public static createConfirmationDeleteData(titleText: string, messageText: string, extraInformationText: string, entities: any[] = null, buttonText: string = null): AlertDialogData {
    return new AlertDialogData(
      AlertDialogData.exclamationError,
      titleText,
      messageText,
      AlertDialogData.cancelText,
      [AlertDialogConfirmationType.CONFIRM_DELETE],
      buttonText ?? 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DELETE_FOREVER',
      extraInformationText,
      null,
      entities
    );
  }

  public static createOverrideData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(
      AlertDialogData.exclamationWarningFull,
      titleText,
      messageText,
      AlertDialogData.cancelText,
      [AlertDialogConfirmationType.OVERRIDE],
      'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.IMPORT_TEMPLATE'
    );
  }
}

export enum AlertDialogConfirmationType {
  CONFIRM,
  DELETE,
  DISCARD,
  REPORT,
  CONFIRM_DELETE,
  OVERRIDE
}

export enum AlertDialogResult {
  CANCEL,
  CONFIRM,
  DISCARD,
  SAVE
}
