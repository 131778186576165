import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {ObjectUtils, UuidUtils} from '@vdw/angular-component-library';
import {UUID} from 'crypto';
import {FixedSchedule} from './carpet/fixed-schedule';
import {FixedSchedulePlaceholder} from './carpet/fixed-schedule-placeholder';
import {OrderCarpet} from './carpet/order-carpet';
import {ProductionOrder} from './carpet/production-order';
import {RunCarpet} from './carpet/run-carpet';
import {GenericOrder} from './generic-order.interface';
import {GenericRun} from './generic-run.interface';
import {Maintenance} from './maintenance';
import {PlanningEquipment} from './planning-equipment';
import {PlanningItem} from './planning-item';
import {PlanningItemProperties} from './planning-item-properties';
import {PlanningItemType} from './planning-item-type';
import {OrderPlastic} from './plastic/order-plastic';
import {ProductionOrderPlastic} from './plastic/production-order-plastic';
import {RunPlastic} from './plastic/run-plastic';
import {Tool} from './plastic/tool';
import {FixedScheduleCompatibility} from './to-plan/fixed-schedule-compatibility';
import {FixedScheduleToPlan} from './to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from './to-plan/production-order-to-plan';
import {OrderTufting} from './tufting/order-tufting';
import {ProductionOrderTuft} from './tufting/production-order-tuft';
import {RunTufting} from './tufting/run-tufting';
import {TuftProduct} from './tufting/tuft-product';
import {OrderWeaving} from './weaving/order-weaving';
import {ProductionOrderWeaving} from './weaving/production-order-weaving';
import {RunWeaving} from './weaving/run-weaving';
import {WeaveProduct} from './weaving/weave-product';

export class PlanningItemFactory {
  public static fromJSON(json: any): PlanningItem {
    const commonProperties = PlanningItemFactory.parseCommonProperties(json);
    const planningItems = json.planningItems?.map(PlanningItemFactory.fromJSON) ?? [];
    const type = json.type as PlanningItemType;
    switch (type) {
      case PlanningItemType.MAINTENANCE:
        return new Maintenance(commonProperties, json.maintenanceType, ...planningItems);
      case PlanningItemType.ORDER_CARPET:
        return new OrderCarpet(
          commonProperties,
          json.estimatedProductionTime,
          ObjectUtils.parseNullable(json.productionOrder, ProductionOrder.fromJSON),
          json.producedQuantityInPicks,
          json.creelMappingFormula,
          ...planningItems
        );
      case PlanningItemType.RUN_CARPET:
        return new RunCarpet(
          commonProperties,
          ObjectUtils.parseNullable(json.quality, IdName.fromJSON),
          ObjectUtils.parseNullable(json.creel, IdName.fromJSON),
          json.creelMappingFormula,
          ...planningItems
        );
      case PlanningItemType.ORDER_WEAVING:
        return new OrderWeaving(
          commonProperties,
          json.estimatedProductionTime,
          ObjectUtils.parseNullable(json.productionOrder, ProductionOrderWeaving.fromJSON),
          json.producedQuantityInMeters,
          ...planningItems
        );
      case PlanningItemType.RUN_WEAVING:
        return new RunWeaving(commonProperties, ObjectUtils.parseNullable(json.weaveProduct, WeaveProduct.fromJSON), ...planningItems);
      case PlanningItemType.ORDER_TUFTING:
        return new OrderTufting(
          commonProperties,
          json.estimatedProductionTime,
          ObjectUtils.parseNullable(json.productionOrder, ProductionOrderTuft.fromJSON),
          json.producedQuantityInMeters,
          ...planningItems
        );
      case PlanningItemType.RUN_TUFTING:
        return new RunTufting(commonProperties, ObjectUtils.parseNullable(json.tuftProduct, TuftProduct.fromJSON), ...planningItems);
      case PlanningItemType.ORDER_PLASTIC:
        return new OrderPlastic(
          commonProperties,
          json.estimatedProductionTime,
          ObjectUtils.parseNullable(json.productionOrder, ProductionOrderPlastic.fromJSON),
          json.producedQuantity,
          ...planningItems
        );
      case PlanningItemType.RUN_PLASTIC:
        return new RunPlastic(commonProperties, ObjectUtils.parseNullable(json.tool, Tool.fromJSON), ...planningItems);
      case PlanningItemType.FIXED_SCHEDULE_PLACEHOLDER:
        return new FixedSchedulePlaceholder(commonProperties, FixedSchedule.fromJSON(json.fixedSchedule));
      default:
        return new PlanningItem(commonProperties, type);
    }
  }

  public static createRunForPlanningItem(item: GenericOrder | FixedSchedulePlaceholder): GenericRun {
    const commonProperties: PlanningItemProperties = {
      draftId: UuidUtils.generateV4Uuid() as UUID,
      sequenceNumber: 1
    };

    if (item instanceof OrderCarpet) {
      return new RunCarpet(commonProperties, item.productionOrder?.quality, item.productionOrder?.creel, item.creelMappingFormula);
    }

    if (item instanceof OrderWeaving) {
      return new RunWeaving(commonProperties, item.productionOrder.weaveProduct);
    }

    if (item instanceof OrderTufting) {
      return new RunTufting(commonProperties, item.productionOrder.tuftProduct);
    }

    if (item instanceof OrderPlastic) {
      return new RunPlastic(commonProperties, item.productionOrder.tool);
    }

    return null;
  }

  public static createRunForFixedSchedulePlaceholder(item: FixedSchedulePlaceholder, toPlan: FixedScheduleToPlan, machineId: number): RunCarpet {
    const commonProperties: PlanningItemProperties = {
      draftId: UuidUtils.generateV4Uuid() as UUID,
      sequenceNumber: 1
    };
    const compatibleMachine = toPlan.compatibility.find((compatibility: FixedScheduleCompatibility) => compatibility.machine.id === machineId);
    const quality = compatibleMachine.qualities[0] ?? null;
    const creel = compatibleMachine?.creels[0];

    if (item instanceof FixedSchedulePlaceholder) {
      return new RunCarpet(commonProperties, quality, creel);
    }
  }

  public static createOrderForToPlan(toPlan: ProductionOrderToPlan, machine: PlanningEquipment = null, sequenceNumber: number = 1): GenericOrder {
    const commonProperties: PlanningItemProperties = {
      draftId: UuidUtils.generateV4Uuid() as UUID,
      sequenceNumber,
      name: toPlan.productionOrder.name
    };
    const estimatedProductionTime = PlanningItemHelper.getEstimatedProductionTime(toPlan, machine);

    if (toPlan.productionOrder instanceof ProductionOrder) {
      return new OrderCarpet(commonProperties, estimatedProductionTime, toPlan.productionOrder, 0);
    }

    if (toPlan.productionOrder instanceof ProductionOrderWeaving) {
      return new OrderWeaving(commonProperties, estimatedProductionTime, toPlan.productionOrder, 0);
    }

    if (toPlan.productionOrder instanceof ProductionOrderTuft) {
      return new OrderTufting(commonProperties, estimatedProductionTime, toPlan.productionOrder, 0);
    }

    if (toPlan.productionOrder instanceof ProductionOrderPlastic) {
      return new OrderPlastic(commonProperties, estimatedProductionTime, toPlan.productionOrder, 0);
    }
    return null;
  }

  public static createPlaceholderForFixedSchedule(toPlan: FixedSchedule, sequenceNumber: number = 1): PlanningItem {
    const commonProperties: PlanningItemProperties = {
      draftId: UuidUtils.generateV4Uuid() as UUID,
      sequenceNumber,
      name: toPlan.name,
      minimumDuration: '05:00:00'
    };
    return new FixedSchedulePlaceholder(commonProperties, toPlan);
  }

  public static createRunForMachine(commonProperties: PlanningItemProperties, machine: PlanningEquipment): GenericRun {
    const carpetTypes = [MachineType.CARPET_LOOM, MachineType.CARPET_LOOM_NO_JQ, MachineType.VELVET, MachineType.VELVET_NO_JQ];
    if (carpetTypes.includes(machine.equipmentKind)) {
      return new RunCarpet(commonProperties);
    }

    if (machine.equipmentKind === MachineType.WEAVING_MACHINE) {
      return new RunWeaving(commonProperties, null);
    }

    if (machine.equipmentKind === MachineType.TUFT) {
      return new RunTufting(commonProperties, null);
    }

    if (machine.equipmentKind === MachineType.PLASTIC_MACHINE) {
      return new RunPlastic(commonProperties, null);
    }
  }

  private static parseCommonProperties(json: any): PlanningItemProperties {
    return {
      actualEndDate: ObjectUtils.parseNullable(json.actualEndDate, (date: any) => new Date(date)),
      actualStartDate: ObjectUtils.parseNullable(json.actualStartDate, (date: any) => new Date(date)),
      draftId: json.draftId,
      earliestStartDate: ObjectUtils.parseNullable(json.earliestStartDate, (date: any) => new Date(date)),
      estimatedEndDate: ObjectUtils.parseNullable(json.estimatedEndDate, (date: any) => new Date(date)),
      id: json.id,
      minimumDuration: json.minimumDuration,
      name: json.name,
      sequenceNumber: json.sequenceNumber
    };
  }
}
