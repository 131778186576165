import {Component, Inject, OnInit} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {StringUtils} from '@application/helper/string-utils';
import {PageUrls} from '@domain/textile-data/page-urls';
import {ARTICLES, Articles} from '@infrastructure/http/article/articles';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';
import {GridColoredYarnSetPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/overview/grid-colored-yarn-set-preview/grid-colored-yarn-set-preview.component';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BaseComponent, ColDefBuilderFactoryService, LinkIconRendererComponent} from '@vdw/angular-component-library';
import {ColDef, GridApi, ICellRendererParams} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './article-overview-page.component.html'
})
export class ArticleOverviewPageComponent extends BaseComponent implements OnInit {
  public listOfArticles: OverviewListArticle[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.ARTICLE_OVERVIEW;

  private pageUrls: PageUrls;

  public constructor(
    @Inject(ARTICLES) public readonly articles: Articles,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadArticles();
    this.pageUrls = LastModifiedCardUtils.getPageUrls('article');
  }

  public getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('name')
        .withField('name')
        .withHeaderName('CUSTOMERS.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColId('design').withField('design.name').withHeaderName('DESIGN_LIBRARY.DESIGN').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('quality')
        .withField('machineQuality.nameWithVersion')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('coloredYarnSet')
        .withValueGetter('data.coloredYarnSet')
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET')
        .withCellRenderer(GridColoredYarnSetPreviewComponent)
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  public exportGridForArticles(gridApi: GridApi): void {
    gridApi.exportDataAsCsv({
      columnKeys: ['name', 'design', 'quality']
    });
  }

  private loadArticles(): void {
    this.articles
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfArticles: OverviewListArticle[]) => {
        this.listOfArticles = listOfArticles;
      });
  }
}
