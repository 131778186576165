import {Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {isClickOutsideGivenElements} from '@application/helper/is-click-outside-given-elements';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {OrderLinesTarget} from '@domain/order-lines-target.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {ORDERBOOK, Orderbook} from '@infrastructure/http/orderbook/orderbook';
import {OrderLinesGridComponent} from '@presentation/components/order-lines-grid/order-lines-grid.component';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {OverviewListOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-order-line';
import {
  AlertDialogResult,
  BaseComponent,
  DialogBuilderFactoryService,
  DialogType,
  EnumUtils,
  FilterComponent,
  NoDataOverlayComponentParams,
  Priority,
  ToastService,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {find} from 'lodash-es';
import {EMPTY, forkJoin, Subject} from 'rxjs';
import {debounceTime, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './orderbook-overview-page.component.html',
  styleUrls: ['./orderbook-overview-page.component.scss']
})
export class OrderbookOverviewPageComponent extends BaseComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('filterComponent') public filterComponent: FilterComponent;
  @ViewChild('searchFilters') public searchFilters: SearchFiltersComponent;
  @ViewChild('orderLinesGrid') public orderLinesGrid: OrderLinesGridComponent;

  public selectedOrderLines: OverviewListOrderLine[] = [];
  public advancedSearchFilters: PropertyValue[] = [];
  public loadingForTheFirstTime = true;
  public noRowsOverlayComponentParams: Partial<NoDataOverlayComponentParams>;
  public isExporting = false;
  public readonly permissionToDeleteOrderLines = Permission.TEXFAB_ORDERBOOK;
  public readonly commercialUnit = Unit.CENTIMETER;
  public readonly orderbookIconName = 'orderbook';
  public readonly orderLinesGridTarget = OrderLinesTarget.ORDER_BOOK;

  public readonly PERMISSION_TO_ACCESS_CUSTOM_SETTINGS = RouteUtils.paths.texFab.orderbook.settings.requiredPermission;

  private currentSubscription: Subscription;
  private readonly filterChangeSubject = new Subject<string>();
  private readonly listOfClassesThatShouldNotCloseSidebarOnClick = ['orderline-detail-container', 'cdk-overlay-container', 'toast-container', 'ag-row', 'remove-button'];
  private readonly orderLineTranslationKey = 'SALES_ORDERS.ORDER_LINES.ORDER_LINE';

  public constructor(
    @Inject(AUTHENTICATION) private authentication: Authentication,
    @Inject(ORDERBOOK) private readonly orderbook: Orderbook,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  @HostListener('document:click', ['$event'])
  public onOutsideDetailClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (this.sidenav.opened && isClickOutsideGivenElements(targetElement, this.listOfClassesThatShouldNotCloseSidebarOnClick)) {
      this.closeSelectedOrderLineDetails();
    }
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.initialiseAdvancedSearchFilters();
    this.setNoRowsOverlayComponentParams();
    this.subscribeToFilterChanges();
  }

  public isOrderLineSelected(): boolean {
    return this.selectedOrderLines?.length > 0;
  }

  public getSelectedOrderLineId(): number {
    const [selectedOrderLine] = this.selectedOrderLines;

    return selectedOrderLine.id;
  }

  public closeSelectedOrderLineDetails(): void {
    this.selectedOrderLines = [];
  }

  public filter(filterText: string): void {
    this.filterChangeSubject.next(filterText);
  }

  public exportComplete(): void {
    this.isExporting = false;
  }

  public deleteOrderLine(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('alert-dialog')
      .openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: this.translate.instant(this.orderLineTranslationKey, {count: this.selectedOrderLines.length}).toLowerCase()}),
        messageText: this.translate.instant('GENERAL.ACTIONS.CONFIRM_DELETE_DESCRIPTION'),
        type: DialogType.CONFIRM_DELETE
      })
      .pipe(
        switchMap((result: AlertDialogResult) =>
          result === AlertDialogResult.CONFIRM ? forkJoin(this.selectedOrderLines.map((selectedOrderLine: OverviewListOrderLine) => this.orderbook.delete(selectedOrderLine.id))) : EMPTY
        ),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe({
        next: () => {
          this.toastService.showInfo({
            tapToDismiss: false,
            timeOut: 2000,
            message: this.translate.instant('GENERAL.ACTIONS.DELETED_OBJECT', {object: this.translate.instant(this.orderLineTranslationKey, {count: this.selectedOrderLines.length})})
          });

          this.selectedOrderLines = [];

          this.orderLinesGrid.reloadOrderLines();
        },
        error: () => {
          this.handleBackendError();
        }
      });
  }

  public hasCustomSettingsPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.PERMISSION_TO_ACCESS_CUSTOM_SETTINGS);
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.orderbook.settings.absolutePath);
  }

  private initialiseAdvancedSearchFilters(): void {
    this.advancedSearchFilters.push(
      {propertyName: AdvancedSearchInput.ORDER_REFERENCE},
      {propertyName: AdvancedSearchInput.CUSTOMER_REFERENCE},
      {propertyName: AdvancedSearchInput.ARTICLE},
      {
        propertyName: AdvancedSearchInput.PRIORITY,
        possiblePropertyValues: EnumUtils.getEnumValues(Priority)
      },
      {propertyName: AdvancedSearchInput.MACHINE_QUALITY},
      {propertyName: AdvancedSearchInput.COLORED_YARN_SET},
      {propertyName: AdvancedSearchInput.MAIN_COLOR},
      {propertyName: AdvancedSearchInput.BORDER_COLOR},
      {propertyName: AdvancedSearchInput.WIDTH}
    );
  }

  private subscribeToFilterChanges(): void {
    this.filterChangeSubject
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((filterText: string) => {
        find(this.advancedSearchFilters, ['propertyName', AdvancedSearchInput.ORDER_REFERENCE]).propertyValue = filterText;

        this.searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();

        this.orderLinesGrid.reloadOrderLines();
      });
  }

  private handleBackendError(): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: this.translate.instant(this.orderLineTranslationKey, {count: 1}).toLowerCase()}),
      messageText: this.translate.instant('CONFLICTS.IN_USE', {object: this.translate.instant(this.orderLineTranslationKey, {count: 1})}),
      type: DialogType.INFORMATION
    });
  }

  private setNoRowsOverlayComponentParams(): void {
    this.noRowsOverlayComponentParams = {
      titleParam: this.orderLineTranslationKey,
      hideDescription: true
    };
  }
}
