import {Component} from '@angular/core';
import {ProductionScheduleGlobalStatus} from '@domain/production-schedule/production-schedule-global-status.enum';
import {ProductionScheduleState} from '@domain/production-schedule/production-schedule-state';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './production-schedule-overview-status.component.html',
  styleUrls: ['./production-schedule-overview-status.component.scss']
})
export class ProductionScheduleOverviewStatusComponent implements ICellRendererAngularComp {
  private productionScheduleStatus: ProductionScheduleStatus;
  private productionScheduleState: ProductionScheduleState;

  public getIconNameForProductionScheduleStatus(): string {
    return this.productionScheduleState
      ? ProductionScheduleGlobalStatus.getStatusIconName(this.productionScheduleState.globalStatus)
      : ProductionScheduleStatus.getStatusIconName(this.productionScheduleStatus);
  }

  public getIconColorNameForProductionScheduleStatus(): string {
    return this.productionScheduleState ? this.productionScheduleState.statusColor.toLowerCase() : '';
  }

  public agInit(params: ICellRendererParams): void {
    this.productionScheduleStatus = params.value;
    this.productionScheduleState = params.data?.state;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
