import {InjectionToken} from '@angular/core';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingInfo} from '@domain/production-schedule/drawing-info.interface';
import {DrawingRecolorInformation} from '@domain/production-schedule/drawing-recolor-information';
import {PropertyValue} from '@domain/property-value';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {ColoredYarnSetPosition} from '@domain/textile-data/creel/colored-yarn-set-position';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {ConflictsRepository} from '@infrastructure/http/conflicts-repository';
import {GroupedDrawing} from '@presentation/pages/texfab/production-schedule/add/drawing-library/views/drawings-grouped-view/grouped-drawing';
import {GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

export const DRAWINGS = new InjectionToken<Drawings>('Drawings');

export interface Drawings extends Repository<Drawing, Drawing>, UniqueIdentifierObjectRepository, CustomSettingsObjectRepository, ConflictsRepository {
  getAllWithoutImageData(target: TargetForListOfDrawingsEnum, filters?: PropertyValue[]): Observable<Drawing[]>;

  getAllWithoutImageDataFromGridModel(target: TargetForListOfDrawingsEnum, gridModel: GridModel, loadConfigurations?: boolean, viewMode?: string, exclude?: string): Observable<Drawing[]>;

  getSlice(
    startIndex: number,
    count: number,
    viewMode: string,
    loadConfiguration: boolean,
    target: TargetForListOfDrawingsEnum,
    filters?: PropertyValue[],
    sort?: {sort: string; colId: string}
  ): Observable<Drawing[]>;

  getDrawing(drawingImageId: string, drawingSignature: string): Observable<Blob>;

  deleteMultiple(drawingIds: number[]): Observable<number[]>;

  getDrawingShapes(): Observable<string[]>;

  addImageToUploadQueue(drawingImage: DrawingImage): void;

  getUploadQueue(): DrawingImage[];

  removeDrawingImagesFromUploadQueue(namesOfDrawingImagesToRemove: string[]): DrawingImage[];

  clearUploadQueue(): void;

  uploadDrawingImages(drawingImages: DrawingImage[]): Observable<DrawingImage[]>;

  deleteDrawingImages(drawingImagesIds: string[]): Observable<void>;

  getRecoloredImageConfiguration(
    imageId: string,
    machineQualityId: number,
    colorSetId: number,
    coloredYarnSetId: number,
    mappingForCurrentPositionOnMachine: CreelMap,
    target: TargetForListOfDrawingsEnum,
    dimensions: {
      input: DimensionsInPx;
      output?: DimensionsInPx;
    },
    orderLineId?: number,
    position?: ColoredYarnSetPosition,
    finishingId?: number,
    numberOfWeftSelectionColumns?: number,
    threadByThread?: boolean
  ): Observable<DrawingRecolorInformation>;

  getRecoloredImage(imageId: string, machineQualityId: number, colorSetId: number, dimensionsInPx: DimensionsInPx): Observable<Blob>;

  getRecoloredPathLabelImage(
    imageId: string,
    machineQualityId: number,
    colorSetId: number,
    mappingForCurrentPositionOnMachine: CreelMap,
    dimensionsInPx: DimensionsInPx,
    position: ColoredYarnSetPosition,
    creelName: string,
    machineId: number,
    freePatternId: number
  ): Observable<string>;

  saveAll(drawings: Drawing[], overwriteExistingDrawings: boolean): Observable<number[]>;

  processDrawingConfigurationIntoEpDrawing(
    imageId: string,
    drawingName: string,
    machineQualityId: number,
    colorSetId: number,
    dimensionsInPx: DimensionsInPx,
    finishingId: number,
    machineId: number
  ): Observable<void>;

  downloadFile(imageId: string): Observable<ArrayBuffer>;

  downloadPVDFile(yarnConsumptionId: number): Observable<ArrayBuffer>;

  getCategoriesWithComponents(): Observable<PlaceholderCategory[]>;

  getDrawingInfoByName(dimensionsInPx: DimensionsInPx, id: string, drawingName: string): Observable<DrawingInfo>;

  getPossibleValues(columnIdentifier: string): Observable<string[]>;

  getGroupedDrawings(gridModel: GridModel, groupedBy: string[]): Observable<GroupedDrawing[]>;
}
