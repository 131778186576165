import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class SuperbadyeMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.SUPERBADYE, name, orderId);
  }

  public static fromJSON(superbadyeMachineJSON: any): SuperbadyeMachine {
    return new SuperbadyeMachine(superbadyeMachineJSON.id, superbadyeMachineJSON.name, superbadyeMachineJSON.orderId);
  }
}
