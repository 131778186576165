import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';

export function getRowHeightForCreelPreview(coloredYarnSets: OverviewListColoredYarnSetWithStartDent[]): number {
  let rowHeight = 32;

  if (coloredYarnSets.length > 0) {
    const amountOfColoredYarnSets = coloredYarnSets.length;
    const heightOfSingleColoredYarnSetPreview = 16;
    const marginBetweenColoredYarnSetPreviewsInPx = 4;
    const totalVerticalPaddingInPx = 32;

    rowHeight = heightOfSingleColoredYarnSetPreview * amountOfColoredYarnSets + totalVerticalPaddingInPx + (amountOfColoredYarnSets - 1) * marginBetweenColoredYarnSetPreviewsInPx;
  }

  return rowHeight;
}
