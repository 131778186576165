import {isEqual, min} from 'lodash-es';

export function leastCommonMultiple(firstValue: number, secondValue: number): number {
  let minVal = min([firstValue, secondValue]);

  while (!isEqual(minVal % firstValue, 0) || !isEqual(minVal % secondValue, 0)) {
    minVal++;
  }
  return minVal;
}
