import {NameGeneratorPart} from '../name-generator-part';
import {NameGeneratorPartType} from '../name-generator-part-type.enum';

export class CustomTextPart extends NameGeneratorPart {
  private _customText: string;

  public constructor(customText: string) {
    super();
    this.type = NameGeneratorPartType.CUSTOM_TEXT;
    this.customText = customText;
  }

  public get customText(): string {
    return this._customText;
  }

  public set customText(value: string) {
    this._customText = value;
  }

  public toJSON(): JSON {
    return {
      type: this.type,
      customText: this.customText
    } as any as JSON;
  }
}
