import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class GroupedWeaveStructureRoute extends RouteBranch {
  private _overviewGroupedWeaveStructure: RouteLeaf;
  private _addGroupedWeaveStructure: RouteLeaf;
  private _editGroupedWeaveStructure: RouteLeaf;
  private _duplicateGroupedWeaveStructure: RouteLeaf;

  public get overviewGroupedWeaveStructure(): RouteLeaf {
    return this._overviewGroupedWeaveStructure;
  }

  public set overviewGroupedWeaveStructure(value: RouteLeaf) {
    this.replace(this._overviewGroupedWeaveStructure, value);
    this._overviewGroupedWeaveStructure = value;
  }

  public get addGroupedWeaveStructure(): RouteLeaf {
    return this._addGroupedWeaveStructure;
  }

  public set addGroupedWeaveStructure(value: RouteLeaf) {
    this.replace(this._addGroupedWeaveStructure, value);
    this._addGroupedWeaveStructure = value;
  }

  public get editGroupedWeaveStructure(): RouteLeaf {
    return this._editGroupedWeaveStructure;
  }

  public set editGroupedWeaveStructure(value: RouteLeaf) {
    this.replace(this._editGroupedWeaveStructure, value);
    this._editGroupedWeaveStructure = value;
  }

  public get duplicateGroupedWeaveStructure(): RouteLeaf {
    return this._duplicateGroupedWeaveStructure;
  }

  public set duplicateGroupedWeaveStructure(value: RouteLeaf) {
    this.replace(this._duplicateGroupedWeaveStructure, value);
    this._duplicateGroupedWeaveStructure = value;
  }
}
