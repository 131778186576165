import {Component, Inject} from '@angular/core';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';

@Component({
  templateUrl: './grid-color-set-preview.component.html',
  styleUrls: ['./grid-color-set-preview.component.scss']
})
export class GridColorSetPreviewComponent {
  public colorSet: OverviewListColorSet;
  public showDetailColor: boolean;

  public constructor(@Inject(RESPONSIVENESS_VIEW_MODE) private responsivenessViewMode: ResponsivenessViewMode) {}

  public agInit(params: {data: OverviewListColorSet}): void {
    this.colorSet = params.data;
    this.showDetailColor = !this.isMobile();
  }

  private isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }
}
