import {AssertionUtils} from '../../common/utils/assertion-utils';
import {FilterModelType} from './filter-model-type.enum';
import {FilterType} from './filter-type.enum';

export class FilterModelBuilder {
  private filterType: FilterModelType;
  private filter: any;
  private values: any;
  private type: FilterType;
  private filterModels?: FilterModelBuilder[] = [];

  public constructor(filterType: FilterModelType) {
    this.filterType = filterType;
  }

  public setType(type: FilterType): FilterModelBuilder {
    this.type = type;
    return this;
  }

  public setFilter(filter: any): FilterModelBuilder {
    this.filter = filter;
    return this;
  }

  public setValues(values: any): FilterModelBuilder {
    this.values = values;
    return this;
  }

  public newFilter(newFilter: FilterModelBuilder): FilterModelBuilder {
    this.filterModels.push(newFilter.build());
    return this;
  }

  public build(): any {
    let filterModel: any = {
      filterType: FilterModelType[this.filterType]
    };

    if (this.filter) {
      filterModel = {
        ...filterModel,
        filter: this.filter
      };
    }

    if (this.values) {
      filterModel = {
        ...filterModel,
        values: this.values
      };
    }

    if (!AssertionUtils.isNullOrUndefined(this.type)) {
      filterModel = {
        ...filterModel,
        type: FilterType[this.type]
      };
    }

    if (this.filterModels.length > 0) {
      filterModel = {
        ...filterModel,
        filterModels: this.filterModels
      };
    }

    return filterModel;
  }
}
