import {Component, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatFormFieldControlHelper} from '../helper/form-field-control-helper';

@Component({
  selector: 'vdw-string-input',
  templateUrl: './string-input.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: StringInputComponent}]
})
export class StringInputComponent extends MatFormFieldControlHelper<any> implements OnInit, OnDestroy {
  @Input() public label: string = '';
  @Input() public set placeholder(value: string) {
    super.placeholder = value;
  }

  public constructor(@Optional() @Self() public readonly ngControl: NgControl) {
    super(ngControl);
  }

  public ngOnInit(): void {
    super.init();
  }

  public get placeholder(): string {
    return super.placeholder;
  }

  public reset(): void {
    this.ngControl.control.reset();
  }

  public ngOnDestroy(): void {
    super.destroy();
  }
}
