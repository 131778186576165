import {IdNameVersion} from '@domain/id-name-version';

export class OverviewListDoubleWidthWeaveStructure {
  private readonly _topWeaveStructure: IdNameVersion;
  private readonly _bottomWeaveStructure: IdNameVersion;
  private readonly _backgroundWeaveStructure: IdNameVersion;

  public constructor(topWeaveStructure: IdNameVersion, bottomWeaveStructure: IdNameVersion, backgroundWeaveStructure: IdNameVersion) {
    this._topWeaveStructure = topWeaveStructure;
    this._bottomWeaveStructure = bottomWeaveStructure;
    this._backgroundWeaveStructure = backgroundWeaveStructure;
  }

  public get topWeaveStructure(): IdNameVersion {
    return this._topWeaveStructure;
  }

  public get bottomWeaveStructure(): IdNameVersion {
    return this._bottomWeaveStructure;
  }

  public get backgroundWeaveStructure(): IdNameVersion {
    return this._backgroundWeaveStructure;
  }

  public static fromJSON(doubleWidthWeaveStructureJSON: any): OverviewListDoubleWidthWeaveStructure {
    return new OverviewListDoubleWidthWeaveStructure(
      IdNameVersion.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.topWeaveStructure),
      IdNameVersion.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.bottomWeaveStructure),
      IdNameVersion.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.backgroundWeaveStructure)
    );
  }
}
