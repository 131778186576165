export class IncorporationFactorException {
  private _id: number;
  private _name: string;
  private _incorporationFactor: number;

  public constructor(id: number, name: string, incorporationFactor: number) {
    this._id = id;
    this._name = name;
    this._incorporationFactor = incorporationFactor;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get incorporationFactor(): number {
    return this._incorporationFactor;
  }

  public static fromJSON(incorporationFactorJSON: any): IncorporationFactorException {
    return new IncorporationFactorException(incorporationFactorJSON.id, incorporationFactorJSON.name, incorporationFactorJSON.incorporationFactor);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      incorporationFactor: this._incorporationFactor
    } as any as JSON;
  }
}
