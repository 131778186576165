import {CuttingLineProperties} from '@domain/textile-data/finishing-and-finishing-template/cutting-line-properties';
import {AssertionUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {FoldingCorrectionProperties} from '../folding-correction-properties';
import {FinishingTemplateCutbarProperties} from './finishing-template-cutbar-properties';

export class FinishingTemplateCutbarsInformation {
  private _cutbarBeforeProperties: FinishingTemplateCutbarProperties;
  private _cutbarAfterProperties: FinishingTemplateCutbarProperties;
  private _cuttingLineProperties: CuttingLineProperties;
  private _foldingCorrectionProperties: FoldingCorrectionProperties;

  public constructor(
    cutbarBeforeProperties: FinishingTemplateCutbarProperties,
    cutbarAfterProperties: FinishingTemplateCutbarProperties,
    cuttingLineProperties: CuttingLineProperties,
    foldingCorrectionProperties: FoldingCorrectionProperties
  ) {
    this._cutbarBeforeProperties = cutbarBeforeProperties;
    this._cutbarAfterProperties = cutbarAfterProperties;
    this._cuttingLineProperties = cuttingLineProperties;
    this._foldingCorrectionProperties = foldingCorrectionProperties;
  }

  public get cutbarBeforeProperties(): FinishingTemplateCutbarProperties {
    return this._cutbarBeforeProperties;
  }

  public get cutbarAfterProperties(): FinishingTemplateCutbarProperties {
    return this._cutbarAfterProperties;
  }

  public get cuttingLineProperties(): CuttingLineProperties {
    return this._cuttingLineProperties;
  }

  public get foldingCorrectionProperties(): FoldingCorrectionProperties {
    return this._foldingCorrectionProperties;
  }

  public static fromJSON(cutbarsInformationJSON: any): FinishingTemplateCutbarsInformation {
    return isNil(cutbarsInformationJSON)
      ? null
      : new FinishingTemplateCutbarsInformation(
          FinishingTemplateCutbarProperties.fromJSON(cutbarsInformationJSON.cutbarBeforeProperties),
          FinishingTemplateCutbarProperties.fromJSON(cutbarsInformationJSON.cutbarAfterProperties),
          CuttingLineProperties.fromJSON(cutbarsInformationJSON.cuttingLineProperties),
          FoldingCorrectionProperties.fromJSON(cutbarsInformationJSON.foldingCorrectionProperties)
        );
  }

  public hasAddedCutbarBefore(): boolean {
    return !isNil(this.cutbarBeforeProperties);
  }

  public getLengthOfCutbarBeforeInMillimeters(): number {
    return this.hasAddedCutbarBefore() ? this.cutbarBeforeProperties.commercialLengthInMM : 0;
  }

  public hasAddedCutbarAfter(): boolean {
    return !isNil(this.cutbarAfterProperties);
  }

  public getLengthOfCutbarAfterInMillimeters(): number {
    return this.hasAddedCutbarAfter() ? this.cutbarAfterProperties.commercialLengthInMM : 0;
  }

  public hasAddedCuttingLine(): boolean {
    return !isNil(this.cuttingLineProperties);
  }

  public toJSON(): JSON {
    return {
      cutbarBeforeProperties: this._cutbarBeforeProperties.toJSON(),
      cutbarAfterProperties: this._cutbarAfterProperties.toJSON(),
      cuttingLineProperties: this.hasAddedCuttingLine() ? this._cuttingLineProperties.toJSON() : null,
      foldingCorrectionProperties: this.hasAddedFoldingCorrection() ? this._foldingCorrectionProperties.toJSON() : null
    } as any as JSON;
  }

  private hasAddedFoldingCorrection(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.foldingCorrectionProperties);
  }
}
