import {EventEmitter, Inject, Injectable} from '@angular/core';
import {PriorityLevel} from '@domain/event/priority-level.enum';
import {Notification} from '@domain/notifications/notification';
import {Permission} from '@domain/profile/permission.enum';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {HttpNotificationsService} from '@infrastructure/http/notifications/http-notifications.service';
import {OnNotificationMarkedAsReadInterface} from '@infrastructure/signalr/notifications/on-notification-marked-as-read.interface';
import {SignalrNotificationsService} from '@infrastructure/signalr/notifications/signalr-notifications.service';
import {map, merge, Observable, of, shareReplay, startWith, switchMap, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly PAGE_SIZE = 20;
  private readonly notificationAdded = this.realtimeNotifications.newNotifications.pipe(tap((addedNotification: Notification) => this.onNotificationAdded.emit(addedNotification)));

  private readonly readUpdates = this.realtimeNotifications.notificationsMarkedAsRead.pipe(
    tap((notificationMarkedAsRead: OnNotificationMarkedAsReadInterface) => this.onNotificationMarkedAsRead.emit(notificationMarkedAsRead)),
    map((notificationMarkedAsRead: OnNotificationMarkedAsReadInterface) => notificationMarkedAsRead.unreadCount)
  );

  public onNotificationAdded = new EventEmitter<Notification>();
  public onNotificationMarkedAsRead = new EventEmitter<OnNotificationMarkedAsReadInterface>();

  private readonly _initialCountForCurrentSubscription = this.authentication.currentAuthenticatedChanges().pipe(
    startWith(undefined),
    switchMap(() => (this.authentication.getCurrentSubscription().hasPermission(Permission.ALERT_VIEW_NOTIFICATIONS) ? this.notifications.getUnreadCount() : of(0)))
  );

  private readonly _unreadCount = merge(this._initialCountForCurrentSubscription, this.readUpdates).pipe(
    switchMap((count: number) =>
      this.notificationAdded.pipe(
        startWith(count),
        map(() => count++)
      )
    ),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public get unreadCount(): Observable<number> {
    return this._unreadCount;
  }

  public constructor(
    private readonly notifications: HttpNotificationsService,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly realtimeNotifications: SignalrNotificationsService
  ) {}

  public markAsRead(notifications: Notification[], notificationId: number): Observable<void> {
    this.markNotificationsAsRead(notifications, [notificationId]);

    return this.notifications.markAsRead(notificationId);
  }

  public markAllAsRead(notifications: Notification[]): Observable<void> {
    notifications.forEach((notification: Notification) => (notification.read = true));

    return this.notifications.markAsRead();
  }

  public getNotificationsSlice(priorities: PriorityLevel[], currentIndex: number, equipmentId?: number, groupId?: number): Observable<Notification[]> {
    return this.authentication.currentSubscriptionChanges().pipe(
      startWith(this.authentication.getCurrentSubscription()),
      switchMap(() => this.notifications.getSlice(currentIndex, this.PAGE_SIZE, priorities, groupId, equipmentId))
    );
  }

  public markNotificationsAsRead(notifications: Notification[], readNotificationIds: number[]): void {
    const readNotifications = notifications.filter((notification: Notification) => readNotificationIds.includes(notification.id));
    readNotifications.forEach((notification: Notification) => (notification.read = true));
  }
}
