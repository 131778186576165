import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DrawingPatternVisualisation} from '@domain/drawing-pattern-visualisation';

@Component({
  templateUrl: './design-pattern-preview-dialog.component.html',
  styleUrls: ['./design-pattern-preview-dialog.component.scss']
})
export class DesignPatternPreviewDialogComponent {
  public drawingPatternVisualisation: DrawingPatternVisualisation;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.design) {
      this.drawingPatternVisualisation = new DrawingPatternVisualisation(data.design);
    }
  }
}
