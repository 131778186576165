<mat-card class="full-width">
  <vdw-header
    [title]="title"
    [svgIcon]="icon"
    [action]="action"
    [extraInformationTitle]="extraInformationTitle"
    [amount]="amount"
    [locked]="locked"
    [isInUse]="isInUse"
    [hasPermissionToEdit]="hasPermissionToEdit"
    [invalidFormMessageError]="invalidFormMessageError"
    [isLoading]="isLoading"
    [isCreateNewPage]="isCreateNewPage"
    [headerButtons]="headerButtons"
    [headerTabIndex]="headerTabIndex"
    [headerActions]="headerActions"
    [showCustomErrorMessage]="showCustomErrorMessage"
    [customErrorMessage]="customErrorMessage"
    [saving]="saving"
    [identifier]="identifier"
    [settingsTooltip]="settingsTooltip"
    [actionText]="actionText"
    (settingsEvent)="settingsButtonClicked()"
    (backEvent)="backButtonClicked()"
    (saveEvent)="saveButtonClicked()"
    (cancelEvent)="cancelButtonClicked()"
    (deleteEvent)="deleteButtonClicked()"
    (duplicateEvent)="duplicateButtonClicked()"
    (saveAndCloseEvent)="saveAndCloseButtonClicked()"
    (saveAndCreateNewEvent)="saveAndCreateNewButtonClicked()"
    (inUseEvent)="inUseButtonClicked()"
  >
    <ng-content></ng-content>
  </vdw-header>
  <mat-card-content *ngIf="menuItems?.length > 0">
    <mat-divider></mat-divider>
    <vdw-tabs-menu [menuItems]="menuItems" [selectedMenuItem]="selectedMenuItem" (selectedMenuItemChange)="selectMenuItem($event)"></vdw-tabs-menu>
  </mat-card-content>
</mat-card>
