import {Directive, Input} from '@angular/core';
import {getMappedColoredYarnSetWithStartDent} from '@application/helper/textile-data/colored-yarn-set/get-mapped-colored-yarn-set-with-start-dent';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {PreviewType} from '@presentation/pages/textile-data/creel/add/preview-type';
import {BaseComponent} from '@vdw/angular-component-library';
import {cloneDeep} from 'lodash-es';

@Directive()
export abstract class BaseCreelTableComponent extends BaseComponent {
  public static readonly colorLineHeightInPx = 20;
  public static readonly cellPaddingInPx = 28;

  @Input() public previewType: PreviewType = PreviewType.COLOR;
  public cellHeightInPx = 0;

  public isOfPreviewTypeColor(): boolean {
    return this.previewType === PreviewType.COLOR;
  }

  public togglePreviewType(ofPreviewTypeYarn: boolean): void {
    this.previewType = ofPreviewTypeYarn ? PreviewType.YARN_TYPE : PreviewType.COLOR;
  }

  protected getMappedColoredYarnSetWithStartDent(coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent, coloredYarnSetCreelMap: CreelMap): OverviewListColoredYarnSetWithStartDent {
    return getMappedColoredYarnSetWithStartDent(coloredYarnSetWithStartDent, coloredYarnSetCreelMap);
  }

  protected reverseColoredYarnSetWithStartDentCreelPositions(coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent): OverviewListColoredYarnSetWithStartDent {
    const reversedColoredYarnSetWithStartDent = cloneDeep(coloredYarnSetWithStartDent);
    reversedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions.reverse();
    reversedColoredYarnSetWithStartDent.coloredYarnSet.overviewListYarnSet.creelPositions.reverse();

    return reversedColoredYarnSetWithStartDent;
  }

  protected getMaximumAmountOfColorsInColoredYarnSet(coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent): number {
    return Math.max(1, ...coloredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions.map((creelPosition: OverviewListCreelPosition) => creelPosition.getUniqueItems().length));
  }

  protected getMaximumAmountOfYarnSetsInColoredYarnSet(coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent): number {
    return Math.max(1, ...coloredYarnSetWithStartDent.coloredYarnSet.overviewListYarnSet.creelPositions.map((creelPosition: OverviewListCreelPosition) => creelPosition.getUniqueItems().length));
  }
}
