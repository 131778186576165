import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Color} from '@vdw/angular-component-library';
import {first, join, map, size, uniqBy} from 'lodash-es';

export class OverviewListCreelPosition {
  private _items: MaterialTypeOverviewList[];
  private _position: number;
  private _percentageOfColorsInDrawingImage: number;
  private _needsToBeFromSameBath: boolean;

  public constructor(items: MaterialTypeOverviewList[], position?: number, percentageOfColorsInDrawingImage?: number, needsToBeFromSameBath: boolean = false) {
    this._items = items;
    this._position = position;
    this._percentageOfColorsInDrawingImage = percentageOfColorsInDrawingImage;
    this._needsToBeFromSameBath = needsToBeFromSameBath;
  }

  public get items(): MaterialTypeOverviewList[] {
    return this._items;
  }

  public get position(): number {
    return this._position;
  }

  public get percentageOfColorsInDrawingImage(): number {
    return this._percentageOfColorsInDrawingImage;
  }

  public get needsToBeFromSameBath(): boolean {
    return this._needsToBeFromSameBath;
  }

  public set needsToBeFromSameBath(needsToBeFromSameBath: boolean) {
    this._needsToBeFromSameBath = needsToBeFromSameBath;
  }

  public static fromCreelPosition(creelPosition: CreelPosition, position: number): OverviewListCreelPosition {
    return new OverviewListCreelPosition(creelPosition.getUniqueItems(), position);
  }

  public getUniqueItems(): MaterialTypeOverviewList[] {
    return uniqBy(this._items, 'id');
  }

  public hasMultipleItems(): boolean {
    return size(this.getUniqueItems()) > 1;
  }

  public getItemNames(): string {
    return join(
      map(this.items, (materialType: MaterialTypeOverviewList) => materialType.name),
      ', '
    );
  }

  public getFirstItem(): Color {
    return first(this.items as Color[]);
  }
}

export type MaterialTypeOverviewList = OverviewListYarnType | Color;
