import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {StringCountCellRendererParams} from './string-count-cell-renderer-params';

@Component({
  templateUrl: './string-count-cell-renderer.component.html',
  styleUrls: ['./string-count-cell-renderer.component.scss']
})
export class StringCountCellRendererComponent implements ICellRendererAngularComp {
  public text: string;
  public count: number;

  public agInit(params: StringCountCellRendererParams): void {
    this.text = params.value.text;
    this.count = params.value.count;
  }

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
