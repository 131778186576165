import {Equipment} from '@domain/machine/equipment';
import {MachineType} from '@domain/machine/machine-type.enum';
import {WinderMachineType} from '@domain/machine/winder-machine-type';

export class WinderMachine extends Equipment {
  private readonly _maxNumberOfSpindles: number;
  private readonly _winderMachineType: WinderMachineType;

  public constructor(id: number, name: string, orderId: string, maxNumberOfSpindles: number, winderMachineType: WinderMachineType) {
    super(id, MachineType.WINDER, name, orderId);
    this._maxNumberOfSpindles = maxNumberOfSpindles;
    this._winderMachineType = winderMachineType;
  }

  public get maxNumberOfSpindles(): number {
    return this._maxNumberOfSpindles;
  }

  public get winderMachineType(): WinderMachineType {
    return this._winderMachineType;
  }

  public static fromJSON(winderMachineJSON: any): WinderMachine {
    return new WinderMachine(
      winderMachineJSON.id,
      winderMachineJSON.name,
      winderMachineJSON.orderId,
      winderMachineJSON.maxNumberOfSpindles,
      winderMachineJSON.winderMachineType === undefined ? null : WinderMachineType.fromJSON(winderMachineJSON.winderMachineType)
    );
  }

  public toJSON(): JSON {
    const winderMachineJSON = super.toJSON();
    winderMachineJSON['winderMachineTypeId'] = this.winderMachineType?.id;
    winderMachineJSON['maxNumberOfSpindles'] = this.maxNumberOfSpindles;

    return winderMachineJSON;
  }
}
