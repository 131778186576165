import {ArticleForOrderLine} from '@domain/orderbook/article-for-order-line';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {moment, Priority} from '@vdw/angular-component-library';
import {isEmpty, map} from 'lodash-es';
import {OrderLineAdditionalInfo} from './order-line-additional-info';
import {ProductionScheduleForOrderLine} from './production-schedule-for-order-line';

export class OrderLine {
  private _id: number;
  private _salesOrderLineReference: string;
  private _salesOrderReference: string;
  private _customerReference: string;
  private _priority: Priority;
  private _status: OrderLineStatus;
  private _commercialLengthInMM: number;
  private _amount: number;
  private _amountProduced: number;
  private _amountPlanned: number;
  private _article: ArticleForOrderLine;
  private _desiredDate: Date;
  private _productionSchedules: ProductionScheduleForOrderLine[];
  private _additionalInfo: OrderLineAdditionalInfo[];

  public constructor(
    id: number,
    salesOrderLineReference: string,
    salesOrderReference: string,
    customerReference: string,
    priority: Priority,
    status: OrderLineStatus,
    commercialLengthInMM: number,
    amount: number,
    amountProduced: number,
    amountPlanned: number,
    article: ArticleForOrderLine,
    desiredDate: Date,
    productionSchedules: ProductionScheduleForOrderLine[],
    additionalInfo: OrderLineAdditionalInfo[]
  ) {
    this._id = id;
    this._salesOrderLineReference = salesOrderLineReference;
    this._salesOrderReference = salesOrderReference;
    this._customerReference = customerReference;
    this._priority = priority;
    this._status = status;
    this._commercialLengthInMM = commercialLengthInMM;
    this._amount = amount;
    this._amountProduced = amountProduced;
    this._amountPlanned = amountPlanned;
    this._article = article;
    this._desiredDate = desiredDate;
    this._productionSchedules = productionSchedules;
    this._additionalInfo = additionalInfo;
  }

  public get id(): number {
    return this._id;
  }

  public get salesOrderLineReference(): string {
    return this._salesOrderLineReference;
  }

  public get salesOrderReference(): string {
    return this._salesOrderReference;
  }

  public get customerReference(): string {
    return this._customerReference;
  }

  public get priority(): Priority {
    return this._priority;
  }

  public get status(): OrderLineStatus {
    return this._status;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public get amount(): number {
    return this._amount;
  }

  public get amountProduced(): number {
    return this._amountProduced;
  }

  public get amountPlanned(): number {
    return this._amountPlanned;
  }

  public get article(): ArticleForOrderLine {
    return this._article;
  }

  public get desiredDate(): Date {
    return this._desiredDate;
  }

  public get productionSchedules(): ProductionScheduleForOrderLine[] {
    return this._productionSchedules;
  }

  public get additionalInfo(): OrderLineAdditionalInfo[] {
    return this._additionalInfo;
  }

  public static createEmptyOrderLine(): OrderLine {
    return new OrderLine(null, null, null, null, null, null, null, null, null, null, null, null, [], []);
  }

  public static fromJSON(orderLineJSON: any, isTargetOrderBook: boolean): OrderLine {
    return new OrderLine(
      orderLineJSON.id,
      orderLineJSON.salesOrderLineReference,
      orderLineJSON.salesOrderReference,
      orderLineJSON.customerReference,
      orderLineJSON.priority,
      OrderLineStatus[`${orderLineJSON.status}`],
      orderLineJSON.commercialLengthInMM,
      orderLineJSON.amount,
      orderLineJSON.amountProduced,
      orderLineJSON.amountPlanned,
      ArticleForOrderLine.fromJSON(orderLineJSON.article, isTargetOrderBook),
      moment(orderLineJSON.desiredDate).add(new Date().getTimezoneOffset(), 'minutes').toDate(),
      !isEmpty(orderLineJSON.productionSchedules) ? map(orderLineJSON.productionSchedules, ProductionScheduleForOrderLine.fromJSON) : [],
      Object.keys(orderLineJSON).reduce((additionalInfo: OrderLineAdditionalInfo[], key: string) => {
        if (key.startsWith('additionalInfo')) {
          additionalInfo.push(new OrderLineAdditionalInfo(key, orderLineJSON[key]));
        }
        return additionalInfo;
      }, [])
    );
  }

  public hasProductionSchedules(): boolean {
    return !isEmpty(this._productionSchedules);
  }
}
