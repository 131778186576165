import {ComponentRef, EventEmitter, Injectable, NgZone, ViewContainerRef} from '@angular/core';
import {take} from 'rxjs';
import {Point} from '../../common/interfaces/point';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {PlanningGridComponent} from '../planning-display/planning-grid/planning-grid.component';
import {PlanningGroupData} from '../planning-items/planning-item-base/planning-group-data';
import {PlanningItemData} from '../planning-items/planning-item-base/planning-item-data';
import {PlanningRowData} from '../planning-items/planning-item-base/planning-row-data';
import {DragDropData} from './planning-drag-drop-data';
import {PlanningDragIndicatorComponent} from './planning-drag-indicator/planning-drag-indicator.component';
import {PlanningHourIndicatorComponent} from './planning-hour-indicator/planning-hour-indicator.component';

@Injectable()
export class PlanningDragDropService {
  private dragIndicatorRef: ComponentRef<PlanningDragIndicatorComponent>;
  private dragIndicator: PlanningDragIndicatorComponent;
  private hourIndicator: PlanningHourIndicatorComponent;
  private emptyDragImage: Element;
  private dragDropData: DragDropData;
  private readonly DRAGGING_INSIDE_GRID = 'dragging-inside-grid';

  public dragEnd = new EventEmitter<DragDropData>();
  public drag = new EventEmitter<DragDropData>();
  public get dragActive(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.dragIndicatorRef);
  }

  public constructor(private readonly ngZone: NgZone) {}

  public onDragStart(
    event: DragEvent,
    item: PlanningItemData,
    sourceGroup: PlanningGroupData,
    sourceRow: PlanningRowData,
    indicatorWidthPx: number,
    startMousePositionInContainer: Point,
    newGroupText: string,
    targetGrid: PlanningGridComponent,
    viewContainer: ViewContainerRef = null
  ): DragDropData {
    event.stopPropagation();

    if (event.dataTransfer != null) {
      event.dataTransfer.effectAllowed = 'linkMove';
    }

    targetGrid.calculateRowWidth();
    targetGrid.onDestroy.pipe(take(1)).subscribe(() => this.reset());

    this.dragDropData = new DragDropData();

    this.dragDropData.draggedItem = item;
    this.dragDropData.sourceRow = sourceRow;
    this.dragDropData.targetGrid = targetGrid;
    this.dragDropData.sourceGroup = sourceGroup;
    this.dragDropData.indicatorWidthPx = indicatorWidthPx;
    this.dragDropData.startMousePositionInContainer = startMousePositionInContainer;

    this.dragDropData.mouseOffsetFromDragIndicator = {x: event.offsetX, y: event.offsetY};
    this.dragDropData.dragIndicatorPositionOnScreen = {x: event.clientX - this.dragDropData.mouseOffsetFromDragIndicator.x, y: event.clientY - this.dragDropData.mouseOffsetFromDragIndicator.y};

    this.dragDropData.mousePositionInContainer = {x: this.dragDropData.startMousePositionInContainer.x, y: this.dragDropData.startMousePositionInContainer.y};
    this.dragIndicatorRef = (viewContainer ?? this.dragDropData.targetGrid.dragContainer).createComponent(PlanningDragIndicatorComponent);
    this.dragIndicator = this.dragIndicatorRef.instance;
    this.dragIndicator.init(this.dragDropData, newGroupText);

    this.hourIndicator ??= this.dragDropData.targetGrid.dragContainer.createComponent(PlanningHourIndicatorComponent).instance;
    this.hourIndicator.init(this.dragDropData);

    this.emptyDragImage ??= document.createElement('div');
    event.dataTransfer?.setDragImage(this.emptyDragImage, 0, 0);

    this.addGridEventListeners();

    return this.dragDropData;
  }

  private onDrag = (event: DragEvent): void => {
    event.preventDefault();
    this.dragDropData.shiftKey = event.shiftKey;
    this.dragDropData.dragIndicatorPositionOnScreen = {
      x: event.clientX - this.dragDropData.mouseOffsetFromDragIndicator.x,
      y: event.clientY - this.dragDropData.mouseOffsetFromDragIndicator.y
    };
    this.dragDropData.mousePositionInContainer = {
      x: this.dragDropData.startMousePositionInContainer.x + event.offsetX - this.dragDropData.mouseOffsetFromDragIndicator.x,
      y: this.dragDropData.startMousePositionInContainer.y + event.offsetY - this.dragDropData.mouseOffsetFromDragIndicator.y
    };
    this.dragDropData.dropAllowed = this.dragDropData.isInsideGrid && this.mouseIsRightOfCurrentTime();

    this.changeHourHeaderRow();
    this.drag.emit(this.dragDropData);
    this.dragIndicator.updateIndicator(this.dragDropData);
    this.hourIndicator.calculateNewPosition(this.dragDropData);
  };

  private changeHourHeaderRow(): void {
    if (this.dragDropData.dropAllowed) {
      this.dragDropData.targetGrid.bodyContainer.nativeElement.classList.add(this.DRAGGING_INSIDE_GRID);
    } else {
      this.dragDropData.targetGrid.bodyContainer.nativeElement.classList.remove(this.DRAGGING_INSIDE_GRID);
    }
  }

  private mouseIsRightOfCurrentTime(): boolean {
    const grid = this.dragDropData.targetGrid;
    if (!grid.currentTime) {
      return true;
    }

    const currentTimeLeft = (grid.getLeftPercentForDate(grid.currentTime) / 100) * grid.rowWidth + grid.ROW_INDICATOR_WIDTH;
    return this.dragDropData.mousePositionInContainer.x >= currentTimeLeft;
  }

  private onGridDragEnter = (event: DragEvent): void => {
    event.preventDefault();
    const targets = event.composedPath();
    const currentRow = targets.find((target: EventTarget) => target instanceof HTMLElement && target.classList.contains('planning-item-row') && target.hasAttribute('data-rowIndex')) as HTMLElement;
    const targetRowIndex = Number(currentRow?.getAttribute('data-rowIndex'));

    this.dragDropData.targetRow = null;
    this.dragDropData.targetItem = null;
    this.dragDropData.isInsideGrid = false;
    this.dragDropData.targetChildItem = null;

    if (isNaN(targetRowIndex)) {
      return;
    }

    const targetRow = this.dragDropData.targetGrid.rowIndicators[targetRowIndex];
    this.dragDropData.targetRow = targetRow?.data;
    this.dragDropData.isInsideGrid = targetRow !== undefined && targets.includes(this.dragDropData.targetGrid.bodyContainer.nativeElement);

    const targetItem = targetRow?.data.items.find((item: PlanningItemData) => targets.includes(item.displayElement.nativeElement));
    this.dragDropData.targetItem = targetItem;

    if (!(targetItem instanceof PlanningGroupData)) {
      return;
    }

    const itemInGroup = targetItem.items.find((item: PlanningItemData) => targets.includes(item.displayElement.nativeElement));
    this.dragDropData.targetChildItem = itemInGroup;
  };

  private onGridDragOver = (event: DragEvent): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = this.dragDropData.dropEffect;
  };

  private onDragEnd = (event: DragEvent): void => {
    event.preventDefault();
    this.removeGridEventListeners();
    this.dragIndicator.template = null;
    this.hourIndicator.hide();
    this.dragEnd.emit(this.dragDropData);
    this.dragIndicatorRef.destroy();
    this.dragIndicatorRef = this.dragIndicator = null;
    this.dragDropData.targetGrid.bodyContainer.nativeElement.classList.remove(this.DRAGGING_INSIDE_GRID);
  };

  private preventDefault = (event: DragEvent): void => {
    event.preventDefault();
  };

  private addGridEventListeners(): void {
    window.addEventListener('dragend', this.onDragEnd);
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('drag', this.onDrag);
      window.addEventListener('dragover', this.onGridDragOver);
      window.addEventListener('dragleave', this.preventDefault);
      window.addEventListener('dragenter', this.onGridDragEnter);
    });
  }

  private removeGridEventListeners(): void {
    window.removeEventListener('dragend', this.onDragEnd);
    this.ngZone.runOutsideAngular(() => {
      window.removeEventListener('drag', this.onDrag);
      window.removeEventListener('dragover', this.onGridDragOver);
      window.removeEventListener('dragleave', this.preventDefault);
      window.removeEventListener('dragenter', this.onGridDragEnter);
    });
  }

  private reset(): void {
    this.dragIndicatorRef = null;
    this.dragIndicator = null;
    this.hourIndicator = null;
    this.dragDropData = null;
    this.emptyDragImage = null;
  }
}
