import {Component, Input} from '@angular/core';

@Component({
  selector: 'vdw-planning-display',
  templateUrl: './planning-display.component.html',
  styleUrls: ['./planning-display.component.scss']
})
export class PlanningDisplayComponent {
  @Input()
  public planningHeight = 0;

  public getPlanningHeight(): string {
    return this.planningHeight === 0 ? '100%' : `${this.planningHeight}px`;
  }
}
