import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class TexEditRoute extends RouteBranch {
  private _dataSheets: RouteLeaf;
  private _dataSheetDetails: RouteLeaf;
  private _designLibrary: RouteLeaf;
  private _designLibrarySettings: RouteLeaf;
  private _addDesign: RouteLeaf;
  private _productConfiguration: RouteLeaf;
  private _addProductConfiguration: RouteLeaf;
  private _editProductConfiguration: RouteLeaf;
  private _duplicateProductConfiguration: RouteLeaf;
  private _productConfigurationSettings: RouteLeaf;
  private _tuftLink: RouteLeaf;

  public get dataSheets(): RouteLeaf {
    return this._dataSheets;
  }

  public set dataSheets(value: RouteLeaf) {
    this.replace(this._dataSheets, value);
    this._dataSheets = value;
  }

  public get dataSheetDetails(): RouteLeaf {
    return this._dataSheetDetails;
  }

  public set dataSheetDetails(value: RouteLeaf) {
    this.replace(this._dataSheetDetails, value);
    this._dataSheetDetails = value;
  }

  public get designLibrary(): RouteLeaf {
    return this._designLibrary;
  }

  public set designLibrary(value: RouteLeaf) {
    this.replace(this._designLibrary, value);
    this._designLibrary = value;
  }

  public get designLibrarySettings(): RouteLeaf {
    return this._designLibrarySettings;
  }

  public set designLibrarySettings(value: RouteLeaf) {
    this.replace(this._designLibrarySettings, value);
    this._designLibrarySettings = value;
  }

  public get addDesign(): RouteLeaf {
    return this._addDesign;
  }

  public set addDesign(value: RouteLeaf) {
    this.replace(this._addDesign, value);
    this._addDesign = value;
  }

  public get productConfiguration(): RouteLeaf {
    return this._productConfiguration;
  }

  public set productConfiguration(value: RouteLeaf) {
    this.replace(this._productConfiguration, value);
    this._productConfiguration = value;
  }

  public get addProductConfiguration(): RouteLeaf {
    return this._addProductConfiguration;
  }

  public set addProductConfiguration(value: RouteLeaf) {
    this.replace(this._addProductConfiguration, value);
    this._addProductConfiguration = value;
  }

  public get editProductConfiguration(): RouteLeaf {
    return this._editProductConfiguration;
  }

  public set editProductConfiguration(value: RouteLeaf) {
    this.replace(this._editProductConfiguration, value);
    this._editProductConfiguration = value;
  }

  public get duplicateProductConfiguration(): RouteLeaf {
    return this._duplicateProductConfiguration;
  }

  public set duplicateProductConfiguration(value: RouteLeaf) {
    this.replace(this._duplicateProductConfiguration, value);
    this._duplicateProductConfiguration = value;
  }

  public get productConfigurationSettings(): RouteLeaf {
    return this._productConfigurationSettings;
  }

  public set productConfigurationSettings(value: RouteLeaf) {
    this.replace(this._productConfigurationSettings, value);
    this._productConfigurationSettings = value;
  }

  public get tuftLink(): RouteLeaf {
    return this._tuftLink;
  }

  public set tuftLink(value: RouteLeaf) {
    this.replace(this._tuftLink, value);
    this._tuftLink = value;
  }
}
