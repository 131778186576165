import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {EventExecutedSuccessfullyUpdate} from '@domain/event-executed-successfully-update';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {SignalrEvent} from './signalr-event.interface';

@Injectable()
export class SignalrEventService implements SignalrEvent {
  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public getEventExecutedSuccessfullyUpdates(): Observable<EventExecutedSuccessfullyUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<EventExecutedSuccessfullyUpdate>(
      `${this.backendConfiguration.getMachineOverviewHubEndPoint()}machineoverview`,
      'OnEventExecutedSuccessfully'
    );
  }
}
