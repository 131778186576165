export class OverviewListMachineWithCompany {
  private _id: string;
  private _companyId: string;

  public constructor(id: string, companyId: string) {
    this._id = id;
    this._companyId = companyId;
  }

  public get id(): string {
    return this._id;
  }

  public get companyId(): string {
    return this._companyId;
  }

  public static fromJSON(machineCompanyJSON: any): OverviewListMachineWithCompany {
    return new OverviewListMachineWithCompany(machineCompanyJSON.machineOrderId, machineCompanyJSON.companyId);
  }
}
