export enum BackendErrorCodeEnum {
  NOT_FOUND,
  LINKED_ENTITIES,
  DUPLICATE_NAME,
  DUPLICATE_CONFIGURATION,
  OVERLAP_REGIMES_OR_SHIFTS,
  RECOLOR_MISMATCH,
  MISSING_COLORS,
  ALTERNATIVES,
  LOOM_MAP_CHORE_MISMATCH,
  EXCEPTIONS_LIST,
  INVALID_NAME,
  INVALID_VALUE,
  OVERLAPPING_ENTITIES,
  MISSING_PROPERTY,
  MISMATCH_PROPERTY,
  DESIGN_MISSING_COLORS,
  DESIGN_INVALID_WEFT_SELECTION_CODES
}
