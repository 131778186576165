import {DOCUMENT} from '@angular/common';
import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../window/window.service';
import {RepositionWatchDialog} from './reposition-watch-dialog.interface';
import {RepositionWatchDialogService} from './reposition-watch-dialog.service';

@Injectable()
export class RepositionWatchDialogFactoryService {
  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly applicationRef: ApplicationRef
  ) {}

  public getService(): RepositionWatchDialog {
    return new RepositionWatchDialogService(this.window, this.document, this.applicationRef);
  }
}
