import {Injectable} from '@angular/core';
import {L10nIntlService} from 'angular-l10n';
import {TranslateService} from '../translation/translate.service';
import {ColDefBuilder} from './col-def-builder';

@Injectable({providedIn: 'root'})
export class ColDefBuilderFactoryService {
  public constructor(
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService
  ) {}

  public getBuilder(): ColDefBuilder {
    return new ColDefBuilder(this.l10nIntlService, this.translate);
  }
}
