<div class="full-height" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
  <mat-icon
    svgIcon="drag"
    class="drag-handle"
    [draggable]="true"
    [appRunEventsOutsideAngular]="['drag']"
    (dragstart)="onDragStart($event)"
    (dragend)="onDragEnd()"
    (outSideAngularEvent)="onDrag($event)"
  ></mat-icon>
  <div fxLayout="column" fxFlex="calc(100% - 30px)">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
      <app-order-info fxLayout="column" fxFlex [orderData]="unplannedOrder"></app-order-info>
      <p class="b2 font-weight-semibold to-plan text-align-center">
        {{ 'PLANNING.NEEDS_ATTENTION.TO_PLAN' | bmsTranslate | uppercase }}
      </p>
      <button mat-icon-button class="mat-icon-action-button-lg-with-border" (click)="delete()">
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </div>
</div>
