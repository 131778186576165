import {Injectable} from '@angular/core';
import {NavigationState} from '@domain/navigation/navigation-state.enum';
import {AssertionUtils, LocalStorageService} from '@vdw/angular-component-library';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavigationStateService {
  public onNavigationStateChanged: Subject<NavigationState> = new Subject<NavigationState>();
  private sideNavState: NavigationState;
  private localStorageService: LocalStorageService;
  private sideNavStateStorageKey = 'sideNavState';

  public constructor(localStorageService: LocalStorageService) {
    this.localStorageService = localStorageService;

    this.initializeSideNavState();
  }

  public changeNavigationStateToOpen(): void {
    this.changeSideNavState(NavigationState.OPEN);
  }

  public changeNavigationStateToClosed(): void {
    this.changeSideNavState(NavigationState.CLOSED);
  }

  public getCurrentNavigationState(): NavigationState {
    return this.sideNavState;
  }

  private changeSideNavState(navigationState: NavigationState): void {
    this.sideNavState = navigationState;
    this.localStorageService.set(this.sideNavStateStorageKey, NavigationState[navigationState]);
    this.onNavigationStateChanged.next(navigationState);
  }

  private initializeSideNavState(): void {
    const storedSideNavState = this.localStorageService.get(this.sideNavStateStorageKey);
    if (AssertionUtils.isNullOrUndefined(storedSideNavState) || AssertionUtils.isNullOrUndefined(NavigationState[storedSideNavState])) {
      this.changeNavigationStateToOpen();
    } else {
      this.sideNavState = NavigationState[storedSideNavState];
    }
  }
}
