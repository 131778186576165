import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {TabsMenuItem} from '../tabs-menu/tabs-menu-item';
import {TabsMenuModule} from '../tabs-menu/tabs-menu.module';

@Component({
  selector: 'vdw-tabs-and-actions-header',
  templateUrl: 'tabs-and-actions-header.component.html',
  styleUrl: 'tabs-and-actions-header.component.scss',
  standalone: true,
  imports: [TabsMenuModule]
})
export class TabsAndActionsHeaderComponent implements OnInit {
  @Input() public menuItems: TabsMenuItem[];
  @Input() public selectedMenuItem: TabsMenuItem;
  @Output() public selectedMenuItemChange: EventEmitter<TabsMenuItem> = new EventEmitter();

  protected showMenuItems: boolean;

  public ngOnInit(): void {
    this.showMenuItems = !AssertionUtils.isEmpty(this.menuItems);
  }
}
