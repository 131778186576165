import {IdName} from '@domain/id-name';

export class ControllerType extends IdName {
  public constructor(id: number, name: string = null) {
    super(id, name);
  }

  public static fromJSON(object: any): ControllerType {
    return new ControllerType(object.id, object.name);
  }
}
