import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DataUnitSetupCatalogRoute extends RouteBranch {
  private _customSetting: RouteLeaf;
  private _addDataUnitSetupCatalog: RouteLeaf;
  private _editDataUnitSetupCatalog: RouteLeaf;
  private _duplicateDataUnitSetupCatalog: RouteLeaf;

  public get customSetting(): RouteLeaf {
    return this._customSetting;
  }

  public set customSetting(value: RouteLeaf) {
    this.replace(this._customSetting, value);
    this._customSetting = value;
  }

  public get addDataUnitSetupCatalog(): RouteLeaf {
    return this._addDataUnitSetupCatalog;
  }

  public set addDataUnitSetupCatalog(value: RouteLeaf) {
    this.replace(this._addDataUnitSetupCatalog, value);
    this._addDataUnitSetupCatalog = value;
  }

  public get editDataUnitSetupCatalog(): RouteLeaf {
    return this._editDataUnitSetupCatalog;
  }

  public set editDataUnitSetupCatalog(value: RouteLeaf) {
    this.replace(this._editDataUnitSetupCatalog, value);
    this._editDataUnitSetupCatalog = value;
  }

  public get duplicateDataUnitSetupCatalog(): RouteLeaf {
    return this._duplicateDataUnitSetupCatalog;
  }

  public set duplicateDataUnitSetupCatalog(value: RouteLeaf) {
    this.replace(this.duplicateDataUnitSetupCatalog, value);
    this._duplicateDataUnitSetupCatalog = value;
  }
}
