import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {DialogModule} from '../../dialogs/dialog.module';
import {DialogButtonModule} from '../dialog-button/dialog-button.module';
import {ColorPickerFormInputComponent} from './color-picker-form-input.component';
import {ColorPresetPickerMenuComponent} from './color-preset-picker-menu/color-preset-picker-menu.component';

@NgModule({
  declarations: [ColorPickerFormInputComponent, ColorPresetPickerMenuComponent],
  imports: [CommonModule, MatIconModule, DialogModule, DialogButtonModule],
  exports: [ColorPickerFormInputComponent]
})
export class ColorPickerFormInputModule {}
