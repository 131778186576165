import {HttpClient, HubConnection} from '@microsoft/signalr';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import {finalize, shareReplay, switchMap, tap} from 'rxjs/operators';
import {SignalrHubConnectionBuilder} from './signalr-hub-connection-builder';
import {SignalrLogger} from './signalr-logger';

export class SignalrHubConnectionFactoryService {
  private readonly disconnectAllSubject = new Subject<void>();
  private readonly connectAllSubject = new Subject<void>();
  private readonly connectedSubject = new BehaviorSubject<HubConnection>(null);
  private readonly signalrHubConnectionsByUrl = new Map<string, Observable<HubConnection>>();
  private readonly signalrHubConnectionsByUrlAndEvent = new Map<string, Map<string, Observable<any>>>();

  public constructor(
    private readonly signalrLogger: SignalrLogger,
    private readonly httpClient: HttpClient,
    private readonly accessTokenFactory: () => Promise<string>,
    private readonly queryFactory: (url: string) => string
  ) {}

  public getSignalrHubConnection(url: string): Observable<HubConnection> {
    let signalrHubConnection = this.signalrHubConnectionsByUrl.get(url);
    if (!signalrHubConnection) {
      signalrHubConnection = this.createSignalrHubConnection(url, this.connectAllSubject.asObservable(), this.disconnectAllSubject.asObservable());
      this.signalrHubConnectionsByUrl.set(url, signalrHubConnection);
    }
    return signalrHubConnection;
  }

  public getSignalrHubEvents<T = any>(url: string, method: string): Observable<T> {
    let signalrHubConnectionMap = this.signalrHubConnectionsByUrlAndEvent.get(url);
    if (!signalrHubConnectionMap) {
      signalrHubConnectionMap = new Map<string, Observable<HubConnection>>();
      this.signalrHubConnectionsByUrlAndEvent.set(url, signalrHubConnectionMap);
    }

    let signalrHubConnection = signalrHubConnectionMap.get(method);
    if (!signalrHubConnection) {
      signalrHubConnection = this.createSignalrHubEventsConnection<T>(url, method);
      signalrHubConnectionMap.set(method, signalrHubConnection);
    }
    return signalrHubConnection;
  }

  public getConnectedHubConnections(): Observable<HubConnection> {
    return this.connectedSubject.asObservable();
  }

  public getConnectionId(): string {
    return this.connectedSubject?.value?.connectionId ?? '';
  }

  public connectAll(): void {
    this.connectAllSubject.next();
  }

  public disconnectAll(): void {
    this.disconnectAllSubject.next();
  }

  private createSignalrHubEventsConnection<T = any>(url: string, method: string): Observable<T> {
    return this.getSignalrHubConnection(url).pipe(
      switchMap(
        (hubConnection: HubConnection) =>
          new Observable<T>((subscriber: Subscriber<T>) => {
            const handler = (event: any): void => subscriber.next(event);
            hubConnection.on(method, handler);

            return () => hubConnection.off(method, handler);
          })
      ),
      finalize(() => this.signalrHubConnectionsByUrlAndEvent.get(url).delete(method)),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  private createSignalrHubConnection(url: string, connect: Observable<void>, disconnect: Observable<void>): Observable<HubConnection> {
    return new SignalrHubConnectionBuilder(this.signalrLogger, this.httpClient, this.accessTokenFactory).build(this.queryFactory(url), connect, disconnect).pipe(
      tap((hubConnection: HubConnection) => this.connectedSubject.next(hubConnection)),
      finalize(() => {
        this.signalrHubConnectionsByUrl.delete(url);
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }
}
