import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class PlasticProductRoute extends RouteBranch {
  private _addPlasticProduct: RouteLeaf;
  private _editPlasticProduct: RouteLeaf;
  private _duplicatePlasticProduct: RouteLeaf;

  public get addPlasticProduct(): RouteLeaf {
    return this._addPlasticProduct;
  }

  public set addPlasticProduct(value: RouteLeaf) {
    this.replace(this._addPlasticProduct, value);
    this._addPlasticProduct = value;
  }

  public get editPlasticProduct(): RouteLeaf {
    return this._editPlasticProduct;
  }

  public set editPlasticProduct(value: RouteLeaf) {
    this.replace(this._editPlasticProduct, value);
    this._editPlasticProduct = value;
  }

  public get duplicatePlasticProduct(): RouteLeaf {
    return this._duplicatePlasticProduct;
  }

  public set duplicatePlasticProduct(value: RouteLeaf) {
    this.replace(this._duplicatePlasticProduct, value);
    this._duplicatePlasticProduct = value;
  }
}
