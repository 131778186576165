import {MachineType} from '@domain/machine/machine-type.enum';
import {TimezoneUtils} from '@vdw/angular-component-library';
import {PlanningItem} from './planning-item';
import {PlanningItemType} from './planning-item-type.enum';

export class PlanningMachineUnavailability extends PlanningItem {
  public constructor(id: number, startDate: Date, endDate: Date) {
    super(id, startDate, endDate, PlanningItemType.MACHINE_UNAVAILABLE);
  }

  public static fromJSON(planningMachineUnavailabilityJSON: any, timezoneCode: string): PlanningMachineUnavailability {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineUnavailabilityJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineUnavailabilityJSON.endDate, timezoneCode);

    return new PlanningMachineUnavailability(planningMachineUnavailabilityJSON.id, startDate, endDate);
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      id: this.id,
      startDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._startDate, timezoneCode),
      endDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._endDate, timezoneCode),
      type: PlanningItemType[this.type]
    } as any as JSON;
  }

  public isCompatibleWithMachineType(_: MachineType): boolean {
    return true;
  }
}
