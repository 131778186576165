export class CreelPositionForArticle {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(creelPositionForArticleJSON: any): CreelPositionForArticle {
    return new CreelPositionForArticle(creelPositionForArticleJSON.id, creelPositionForArticleJSON.name);
  }
}
