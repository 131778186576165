import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BreadcrumbItem} from './breadcrumb-item.interface';

@Component({
  selector: 'vdw-breadcrumb',
  styleUrls: ['./breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Output() public itemsChanged = new EventEmitter<BreadcrumbItem[]>();
  @Input() public items: BreadcrumbItem[];
  @Input() public disablePredicate: (breadcrumb: any) => boolean = (): boolean => false;

  public navigateTo(level: number): void {
    if (!this.disablePredicate(this.items[level])) {
      this.items.splice(level + 1, this.items.length - level - 1);
      this.itemsChanged.emit(this.items);
    }
  }
}
