export class MachineEventInfo {
  private _id: number;
  private _name: string;
  private _type: string;
  private _group: string;

  public constructor(id: number, name: string, type: string, group: string) {
    this._id = id;
    this._name = name;
    this._type = type;
    this._group = group;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get type(): string {
    return this._type;
  }

  public get group(): string {
    return this._group;
  }

  public static fromJSON(machineEventInfoJSON: any): MachineEventInfo {
    return new MachineEventInfo(machineEventInfoJSON.id, machineEventInfoJSON.name, machineEventInfoJSON.type, machineEventInfoJSON.group);
  }
}
