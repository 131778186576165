import {PaymentStatus} from '@domain/sales-order/payment-status';
import {SalesOrderStatus} from '@domain/sales-order/sales-order-status';
import {Priority} from '@vdw/angular-component-library';

export class OverviewListSalesOrder {
  private _id: number;
  private _orderNumber: number;
  private _customer: {
    name: string;
  };

  private _desiredDate: Date;
  private _priority: Priority;
  private _paymentStatus: PaymentStatus;
  private _status: SalesOrderStatus;
  private _addedToOrderBook: boolean;

  public constructor(
    id: number,
    orderNumber: number,
    customer: {name: string},
    desiredDate: Date,
    priority: Priority,
    paymentStatus: PaymentStatus,
    status: SalesOrderStatus,
    addedToOrderBook: boolean
  ) {
    this._id = id;
    this._orderNumber = orderNumber;
    this._customer = customer;
    this._desiredDate = desiredDate;
    this._priority = priority;
    this._paymentStatus = paymentStatus;
    this._status = status;
    this._addedToOrderBook = addedToOrderBook;
  }

  public get id(): number {
    return this._id;
  }

  public get orderNumber(): number {
    return this._orderNumber;
  }

  public get customer(): {name: string} {
    return this._customer;
  }

  public get desiredDate(): Date {
    return this._desiredDate;
  }

  public get priority(): Priority {
    return this._priority;
  }

  public get paymentStatus(): PaymentStatus {
    return this._paymentStatus;
  }

  public get status(): SalesOrderStatus {
    return this._status;
  }

  public get addedToOrderBook(): boolean {
    return this._addedToOrderBook;
  }

  public static fromJSON(overviewListSalesOrderJSON: any): OverviewListSalesOrder {
    return new OverviewListSalesOrder(
      overviewListSalesOrderJSON.id,
      overviewListSalesOrderJSON.orderNumber,
      {name: overviewListSalesOrderJSON.customer.name},
      new Date(overviewListSalesOrderJSON.desiredDate),
      overviewListSalesOrderJSON.priority,
      overviewListSalesOrderJSON.paymentStatus,
      overviewListSalesOrderJSON.status,
      overviewListSalesOrderJSON.addedToOrderBook
    );
  }

  public canDuplicate(): boolean {
    return true;
  }
}
