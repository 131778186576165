import {InjectionToken} from '@angular/core';
import {ProductionOrderWeaving} from '@domain/production-order-lite/production-order-weaving/production-order-weaving';
import {Repository} from '@infrastructure/http/repository';
import {OverviewListProductionOrderWeaving} from '@presentation/pages/texfab/production-order-lite/overview/overview-list-production-order-weaving';
import {Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const PRODUCTION_ORDERS_WEAVING = new InjectionToken<ProductionOrdersWeaving>('ProductionOrdersWeaving');

export interface ProductionOrdersWeaving
  extends Repository<ProductionOrderWeaving, OverviewListProductionOrderWeaving>,
    CustomSettingsObjectRepository,
    NameGenerationRepository<ProductionOrderWeaving> {
  checkCompatibilityWithMachine(productionOrderLiteId: number, machineId: number): Observable<number>;
  saveAndPlan(productionOrderWeaving: ProductionOrderWeaving, runId: number): Observable<number>;
  updateAndPlan(productionOrderWeaving: ProductionOrderWeaving, runId: number): Observable<void>;
}
