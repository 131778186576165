import {isNil} from 'lodash-es';

const placeholderSeparators = {
  POINT: '.',
  COMMA: ',',
  DASH: '-',
  UNDERSCORE: '_',
  SPACE: ' ',
  VERTICAL_BAR: '|',
  NO_SEPARATOR: '',
  TAB: '  '
};

export function getDisplayValueForPlaceholderSeparator(placeholderSeparator: string): string {
  return !isNil(placeholderSeparators[placeholderSeparator]) ? placeholderSeparators[placeholderSeparator] : placeholderSeparator;
}
