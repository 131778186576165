import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RadioToggleButtonDirective} from './radio-toggle-button.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RadioToggleButtonDirective],
  exports: [RadioToggleButtonDirective]
})
export class RadioToggleButtonModule {}
