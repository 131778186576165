import {CdkDropList} from '@angular/cdk/drag-drop';
import {Injectable} from '@angular/core';
import {combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, Subject, tap} from 'rxjs';
import {AssertionUtils} from '../../../../common/utils/assertion-utils';
import {CdkDraggableDropListEntry} from '../cdk-drop-list-interfaces/cdk-draggable-drop-list-entry';
import {CdkDraggableListEntryDraggedData} from '../cdk-drop-list-interfaces/cdk-draggable-list-entry-dragged-data';
import {DraggableDropListComparator} from './../../shared-logic/draggable-drop-list-comparator.enum';

@Injectable()
export class CdkDragDropHelperService {
  public maxNestedDepth: number = null;
  public draggedElement: CdkDraggableListEntryDraggedData;

  public readonly DEFAULT_MAX_NESTED_DEPTH = 6;

  private generalDropLists: CdkDropList[] = [];
  private cachedEntries: CdkDraggableDropListEntry<any>[];
  private dropListById: {[id: number]: CdkDropList} = {};
  private _cachedDropList: CdkDropList[] = [];

  private readonly dropListsChangedSubject = new Subject<void>();

  public get cachedDropList(): CdkDropList[] {
    return this._cachedDropList;
  }

  public register(id: number, dropList: CdkDropList): void {
    if (dropList) {
      this.dropListById[id] = dropList;
      this.dropListsChangedSubject.next(null);
    }
  }

  public registerGeneralList(dropList: CdkDropList): void {
    if (dropList) {
      this.generalDropLists.push(dropList);
      this.dropListsChangedSubject.next(null);
    }
  }

  public getNestedChildEntries<Data>(entry: CdkDraggableDropListEntry<Data>): CdkDraggableDropListEntry<Data>[] {
    const nestedEntries: CdkDraggableDropListEntry<Data>[] = [];

    nestedEntries.push(entry);

    if (!AssertionUtils.isEmpty(entry.childEntries)) {
      nestedEntries.push(...entry.childEntries.flatMap((nestedEntry: CdkDraggableDropListEntry<Data>) => this.getNestedChildEntries<Data>(nestedEntry)));
    }

    return nestedEntries;
  }

  public findParentEntry<Data>(group: CdkDraggableDropListEntry<Data>, comparator: DraggableDropListComparator = DraggableDropListComparator.ID): CdkDraggableDropListEntry<Data> {
    return this.cachedEntries
      ?.map((groupEntry: CdkDraggableDropListEntry<Data>) => this.getParentOfChildEntry<Data>(groupEntry, group, comparator))
      ?.find((groupEntry: CdkDraggableDropListEntry<Data>) => !AssertionUtils.isNullOrUndefined(groupEntry));
  }

  public moveEntryToRoot<Data>(group: CdkDraggableDropListEntry<Data>, comparator: DraggableDropListComparator = DraggableDropListComparator.ID): void {
    const parentGroup = this.findParentEntry(group, comparator);

    if (AssertionUtils.isNullOrUndefined(parentGroup)) {
      return;
    }

    parentGroup.childEntries = parentGroup.childEntries.filter((groupEntry: CdkDraggableDropListEntry<Data>) => groupEntry.id !== group.id);
    this.cachedEntries.push(group);
  }

  public getLinkedDropLists(entries: CdkDraggableDropListEntry<any>[]): Observable<CdkDropList[]> {
    if (!AssertionUtils.isNullOrUndefined(entries)) {
      this.cachedEntries = entries;
    } else {
      entries = this.cachedEntries;
    }

    const maxNestedDepth = this.maxNestedDepth ?? this.DEFAULT_MAX_NESTED_DEPTH;

    return combineLatest([this.dropListsChangedSubject.asObservable(), of(entries)]).pipe(
      debounceTime(20),
      map(([, rootEntries]: [void, CdkDraggableDropListEntry<any>[]]) => rootEntries),
      map((rootEntry: CdkDraggableDropListEntry<any>[]) => this.levelOrderFlattenEntries(rootEntry).reverse()),
      map((flattenedEntries: CdkDraggableDropListEntry<any>[]) => [
        ...(flattenedEntries.map((flattenedEntry: CdkDraggableDropListEntry<any>) => this.dropListById[flattenedEntry.id]) || []).flat(maxNestedDepth),
        ...(this.generalDropLists || [])
      ]),
      distinctUntilChanged(),
      tap((droplist: CdkDropList[]) => (this._cachedDropList = droplist))
    );
  }

  public getNestedDepth(entry: CdkDraggableDropListEntry<any>): number {
    if (AssertionUtils.isEmpty(this.cachedEntries)) {
      return null;
    }

    const root = {id: -1, childEntries: this.cachedEntries} as CdkDraggableDropListEntry<any>;

    let depthStep = 0;
    let currentEntries = [root];

    while (currentEntries?.length ?? false) {
      const childEntries = currentEntries.flatMap((currentEntry: CdkDraggableDropListEntry<any>) => currentEntry.childEntries);
      const foundMatch = childEntries.find((childEntry: CdkDraggableDropListEntry<any>) => childEntry.id === entry?.id);

      if (!AssertionUtils.isNullOrUndefined(foundMatch)) {
        return depthStep;
      }

      depthStep++;
      currentEntries = childEntries?.flatMap((currentEntry: CdkDraggableDropListEntry<any>) => currentEntry) ?? [];
    }
  }

  private levelOrderFlattenEntries(entries: CdkDraggableDropListEntry<any>[], parentGroup?: CdkDraggableDropListEntry<any>): CdkDraggableDropListEntry<any>[] {
    const levelOrderGroups = [];
    const root = AssertionUtils.isNullOrUndefined(parentGroup) ? ({id: -1, childEntries: entries} as CdkDraggableDropListEntry<any>) : parentGroup;

    const queue = [root];
    while (queue.length) {
      const group = queue.shift();
      levelOrderGroups.push(group);

      queue.push(...group.childEntries);
    }

    return levelOrderGroups.filter((group: CdkDraggableDropListEntry<any>) => group.id !== -1);
  }

  private getParentOfChildEntry<Data>(
    startGroup: CdkDraggableDropListEntry<Data>,
    childGroup: CdkDraggableDropListEntry<Data>,
    comparator: DraggableDropListComparator
  ): CdkDraggableDropListEntry<Data> {
    if (startGroup.childEntries?.find((group: CdkDraggableDropListEntry<Data>) => this.getPredicate(group, childGroup, comparator))) {
      return startGroup;
    }

    return startGroup.childEntries
      ?.map((group: CdkDraggableDropListEntry<Data>) => this.getParentOfChildEntry<Data>(group, childGroup, comparator))
      ?.find((group: CdkDraggableDropListEntry<Data>) => !AssertionUtils.isNullOrUndefined(group));
  }

  private getPredicate<Data>(group: CdkDraggableDropListEntry<Data>, childGroup: CdkDraggableDropListEntry<Data>, comparator: DraggableDropListComparator): boolean {
    switch (comparator) {
      case DraggableDropListComparator.ID:
        return childGroup.id === group.id;
      case DraggableDropListComparator.NAME:
        return childGroup.name === group.name;
      default:
        return childGroup.id === group.id;
    }
  }
}
