import {NameGeneratorPart} from '../name-generator-part';
import {NameGeneratorPartType} from '../name-generator-part-type.enum';

export class SerialNumberPart extends NameGeneratorPart {
  private _maxLength: number;
  private _startValue: string;
  private _stepSize: number;

  public constructor(type: NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER | NameGeneratorPartType.NUMERIC_SERIAL_NUMBER, maxLength: number, startValue: string, stepSize: number) {
    super();
    this.type = type;
    this.maxLength = maxLength;
    this.startValue = startValue;
    this.stepSize = stepSize;
  }

  public get maxLength(): number {
    return this._maxLength;
  }

  public set maxLength(value: number) {
    this._maxLength = value;
  }

  public get startValue(): string {
    return this._startValue;
  }

  public set startValue(value: string) {
    this._startValue = value;
  }

  public get stepSize(): number {
    return this._stepSize;
  }

  public set stepSize(value: number) {
    this._stepSize = value;
  }

  public toJSON(): JSON {
    return {
      type: this.type,
      maxLength: this.maxLength,
      startValue: this.startValue,
      stepSize: this.stepSize
    } as any as JSON;
  }
}
