import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {orderbookSettingsUniqueFieldsValidator} from '@application/validators/orderbook-settings-unique-fields-validator';
import {PropertyValue} from '@domain/property-value';
import {Orderbook, ORDERBOOK} from '@infrastructure/http/orderbook/orderbook';
import {BaseComponent, FormValidationHelper, skeletonViewAnimation, ToastService, TranslateService} from '@vdw/angular-component-library';
import {finalize, takeUntil} from 'rxjs';

@Component({
  selector: 'app-orderbook-custom-settings',
  templateUrl: './orderbook-custom-settings.component.html',
  styleUrls: ['./orderbook-custom-settings.component.scss'],
  animations: [skeletonViewAnimation('.mat-card-header, .form-field-skeleton-wrapper, .text-skeleton-wrapper')]
})
export class OrderbookCustomSettingsComponent extends BaseComponent implements OnInit {
  public settingsForm: FormArray;
  public loading = false;

  private saveButtonTouched = false;
  private readonly URL_TO_ORDERBOOK_OVERVIEW = RouteUtils.paths.texFab.orderbook.absolutePath;

  public constructor(
    @Inject(ORDERBOOK) private readonly orderbook: Orderbook,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.settingsForm = this.formBuilder.array(
      Array.from(Array(10)).map(() => {
        return this.formBuilder.group({label: this.formBuilder.control(null, [Validators.required])});
      }),
      orderbookSettingsUniqueFieldsValidator()
    );

    this.loading = true;

    this.orderbook
      .getListOfCustomSettings()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (settings: PropertyValue[]) => {
          settings.forEach(({propertyValue}: PropertyValue, index: number) => {
            this.settingsForm.get(`${index}.label`).patchValue(propertyValue);
          });

          this.loading = false;
        },
        error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.URL_TO_ORDERBOOK_OVERVIEW)
      });
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = this.settingsForm.controls.some((settingsControl: FormControl) => {
      return settingsControl.invalid && settingsControl.touched;
    });

    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public save(): void {
    this.saveButtonTouched = true;

    if (new FormValidationHelper().checkForms(this.settingsForm.controls as FormGroup[], this.document)) {
      this.saving = true;

      const settings: PropertyValue[] = this.settingsForm.controls.map((settingsControl: FormControl, index: number) => {
        return {
          propertyName: `additionalInfo${index + 1}`,
          propertyValue: settingsControl.get('label').value
        };
      });

      this.orderbook
        .saveCustomSettings(settings)
        .pipe(finalize(this.finalizeSaving()), takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => {
          this.navigateToOrderbookOverview();
          this.toastService.showInfo({
            tapToDismiss: false,
            timeOut: 2000,
            message: this.translate.instant('GENERAL.ACTIONS.SAVED_OBJECT', {
              object: this.translate.instant(this.translate.instant('GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS').toLowerCase())
            })
          });
        });
    }
  }

  public navigateToOrderbookOverview(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.orderbook.absolutePath);
  }
}
