import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {CheckName, GridModel} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductsService implements Repository<PlasticProduct, PlasticProduct>, UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<PlasticProduct[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}plastic-products`)
      .pipe(map((plasticProductsJSON: any) => plasticProductsJSON.map((plasticProductJSON: any) => PlasticProduct.fromJSON(plasticProductJSON))));
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}plastic-products/${id}`);
  }

  public deleteMulti(ids: number[]): Observable<MultiDeleteResponse[]> {
    return this.httpClient
      .delete(`${this.backendConfiguration.getEndpoint()}plastic-products/multi-delete`, {body: ids})
      .pipe(map((reponsesJSON: any) => reponsesJSON.map((reponseJSON: any) => MultiDeleteResponse.fromJSON(reponseJSON))));
  }

  public getById(id: number): Observable<PlasticProduct> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-products/${id}`).pipe(map((plasticProductJSON: any) => PlasticProduct.fromJSON(plasticProductJSON)));
  }

  public save(item: PlasticProduct): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}plastic-products`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: PlasticProduct): Observable<void | number> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}plastic-products/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(identifier: string): Observable<CheckName> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-products/check-name?name=${identifier}`).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getPossibleValues(columnIdentifier: string): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-products/${columnIdentifier}/possible-values`).pipe(map((response: string[]) => response));
  }

  public getSlice(gridModel: GridModel): Observable<PlasticProduct[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}plastic-products/get`, gridModel)
      .pipe(map((plasticProductsJSON: any) => plasticProductsJSON.map((plasticProductJSON: any) => PlasticProduct.fromJSON(plasticProductJSON))));
  }

  public getAllForMachineId(machineId: number): Observable<PlasticProduct[]> {
    throw new Error('Method not implemented.');
  }
}
