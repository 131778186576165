import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CanShowErrorForFormControlDirective} from './can-show-error-for-form-control.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CanShowErrorForFormControlDirective],
  exports: [CanShowErrorForFormControlDirective]
})
export class CanShowErrorForFormControlModule {}
