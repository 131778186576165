import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagSize} from '../tag/tag-size.enum';

@Component({
  selector: 'vdw-mobile-page-header',
  templateUrl: './mobile-page-header.component.html',
  styleUrls: ['./mobile-page-header.component.scss']
})
export class MobilePageHeaderComponent {
  @Input() public title: string;
  @Input() public previousRouterLink: string;
  @Input() public icon: string;
  @Input() public showBackButton = true;
  @Input() public tagValue: string;
  @Input() public showTag = false;
  @Output() public backClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public iconClicked: EventEmitter<void> = new EventEmitter<void>();

  public tagSize = TagSize.MEDIUM;
}
