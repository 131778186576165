import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'vdw-split-button',
  templateUrl: './split-button.component.html'
})
export class SplitButtonComponent {
  @Input() public label: string;
  @Input() public stroked = false;
  @Input() public disabled = false;
  @Input() public compact = false;
  @Input() public inProgress = false;
  @Output() public buttonClicked = new EventEmitter<void>();

  public onButonClicked(): void {
    if (!this.inProgress) {
      this.buttonClicked.emit();
    }
  }
}
