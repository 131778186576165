<section class="full-height flex-column">
  <vdw-tabs-menu-card
    [icon]="isEditingSalesOrder() ? 'details-blue' : 'add-blue'"
    [action]="getActionText()"
    [title]="addSalesOrderForm.controls.orderNumber.value"
    [isInUse]="isSalesOrderBeingUsed()"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [isLoading]="isLoadingSalesOrder"
    [hasPermissionToEdit]="hasEditPermission()"
    [isCreateNewPage]="!isEditingSalesOrder()"
    [saving]="saving"
    [headerActions]="headerActions"
    [identifier]="HEADER_IDENTIFIER"
    (backEvent)="cancel()"
    (cancelEvent)="cancel()"
    (saveEvent)="saveSalesOrder(SAVE_TYPE.SAVE)"
    (saveAndCloseEvent)="saveSalesOrder(SAVE_TYPE.SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="saveSalesOrder(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (deleteEvent)="deleteSalesOrder()"
    (duplicateEvent)="duplicateSalesOrder()"
    (inUseEvent)="openConflictsDialog()"
  >
  </vdw-tabs-menu-card>

  <form [formGroup]="addSalesOrderForm" class="flex-row gap-16 full-height full-max-height-with-toolbar" vdwBp.lt-sm="flex-column" appBreadcrumbFormLinker="orderNumber">
    <mat-card class="skeleton-wrapper no-margin overflow-auto one-third-width full-width-mobile" [class.skeleton]="isLoadingSalesOrder" [@fadeIn]="isLoadingSalesOrder">
      <mat-card-content fxLayout="column">
        <article fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row">
            <div class="text-skeleton-wrapper">
              <h4 class="font-weight-semibold title">{{ 'GENERAL.ORDER_DETAILS' | bmsTranslate }}</h4>
            </div>
          </div>
          <fieldset class="full-width" [disabled]="!hasEditPermission()">
            <div class="flex-column">
              <div class="flex-row gap-24" vdwBp.lt-sm="flex-column">
                <div class="full-width form-field-skeleton-wrapper">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'SALES_ORDERS.ORDER_NUMBER' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal type="number" formControlName="orderNumber" />
                    <mat-error *vdwCanShowErrorForFormControl="'orderNumber'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'orderNumber'; error: 'identifierTaken'">
                      {{ 'GENERAL.ERRORS.NUMBER_DUPLICATE' | bmsTranslate }}
                    </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'orderNumber'; error: 'max'">
                      {{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: int32MaximumValue} }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="full-width form-field-skeleton-wrapper">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} }}</mat-label>
                    <vdw-object-selection
                      formControlName="customer"
                      [objectName]="'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} | lowercase"
                      [baseRouterLink]="CUSTOMER_BASE_ROUTER_LINK"
                      (selectClicked)="selectCustomer()"
                    ></vdw-object-selection>
                    <mat-error *vdwCanShowErrorForFormControl="'customer'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex-row gap-24" vdwBp.lt-sm="flex-column">
                <div class="full-width form-field-skeleton-wrapper">
                  <vdw-datepicker-form-input
                    class="full-width"
                    [inputLabel]="'SALES_ORDERS.DESIRED_DATE' | bmsTranslate"
                    [form]="addSalesOrderForm"
                    formControlNameValue="desiredDate"
                    [minValue]="getEarliestPossibleDueDate()"
                  ></vdw-datepicker-form-input>
                </div>
                <div class="full-width form-field-skeleton-wrapper">
                  <mat-form-field class="full-width">
                    <mat-label>
                      <span>{{ 'SALES_ORDERS.PRIORITY.PRIORITY' | bmsTranslate }}</span>
                    </mat-label>
                    <mat-select disableOptionCentering formControlName="priority">
                      <mat-option *ngFor="let priority of priorities" [attr.value]="priority" [value]="priority">
                        {{ 'SALES_ORDERS.PRIORITY.' + priority | bmsTranslate }}
                      </mat-option>
                    </mat-select>
                    <mat-error *vdwCanShowErrorForFormControl="'priority'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="isEditingSalesOrder()">
                <div class="flex-row gap-24" vdwBp.lt-sm="flex-column">
                  <div class="full-width form-field-skeleton-wrapper">
                    <mat-form-field class="full-width">
                      <mat-label>
                        <span>{{ 'SALES_ORDERS.PAYMENT_STATUS.PAYMENT_STATUS' | bmsTranslate }}</span>
                      </mat-label>
                      <mat-select disableOptionCentering formControlName="paymentStatus">
                        <mat-option *ngFor="let paymentStatus of paymentStatuses" [attr.value]="paymentStatus" [value]="paymentStatus">
                          {{ 'SALES_ORDERS.PAYMENT_STATUS.' + paymentStatus | bmsTranslate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <app-disabled-form-input class="full-width" [label]="'ORDERBOOK.REQUESTED_AMOUNT' | bmsTranslate" [value]="salesOrderToSave.getTotalAmountRequested()"></app-disabled-form-input>
                </div>
                <app-disabled-form-input class="full-width" [label]="'ORDERBOOK.PRODUCED' | bmsTranslate" [value]="salesOrderToSave.getTotalAmountProduced()"></app-disabled-form-input>
              </div>
            </div>
          </fieldset>
        </article>
      </mat-card-content>
    </mat-card>
    <div class="order-lines">
      <mat-card class="skeleton-wrapper full-height no-margin overflow-auto" [class.skeleton]="isLoadingSalesOrder" [@fadeIn]="isLoadingSalesOrder">
        <mat-card-header class="flex-row gap-24">
          <mat-card-title class="antialiased">
            <h3 class="font-weight-bold">{{ 'SALES_ORDERS.ORDER_LINES.ORDER_LINE' | bmsTranslate: {count: 2} }}</h3>
          </mat-card-title>
          <div class="actions">
            <div class="button-skeleton-wrapper raised-btn">
              <button
                mat-icon-button
                class="mat-icon-action-button-lg-with-border"
                [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
                (click)="addNewOrderLine()"
                [disabled]="isSalesOrderBeingUsed() || !hasEditPermission()"
              >
                <mat-icon svgIcon="add"></mat-icon>
              </button>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content fxLayout="column">
          <article fxLayout="column" fxLayoutGap="16px">
            <fieldset fxLayout="column" [disabled]="!hasEditPermission()">
              <div formArrayName="orderLines" *ngFor="let orderLine of getOrderLines(); let orderLineIndex = index">
                <div fxLayout="row" fxLayoutGap="24px" [formGroupName]="orderLineIndex">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'ARTICLES.ARTICLE' | bmsTranslate: {count: 1} }}</mat-label>
                    <vdw-object-selection
                      formControlName="article"
                      [objectName]="'ARTICLES.ARTICLE' | bmsTranslate: {count: 1} | lowercase"
                      [baseRouterLink]="ARTICLE_BASE_ROUTER_LINK"
                      (selectClicked)="selectArticleForOrderLine(orderLine, orderLineIndex)"
                    ></vdw-object-selection>
                    <mat-error *ngIf="canShowErrorForFormControl('required', 'article', orderLine)">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                  <div class="form-field-skeleton-wrapper">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'ORDERBOOK.REQUESTED' | bmsTranslate }}</mat-label>
                      <input matInput vdwL10nDecimal type="number" formControlName="amount" />
                      <mat-error *ngIf="canShowErrorForFormControl('required', 'amount', orderLine)">
                        {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                      </mat-error>
                      <mat-error *ngIf="canShowErrorForFormControl('min', 'amount', orderLine)">
                        {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <ng-container *ngIf="isEditingSalesOrder()">
                    <div class="form-field-skeleton-wrapper">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'ORDERBOOK.PLANNED' | bmsTranslate }}</mat-label>
                        <input matInput vdwL10nDecimal type="number" formControlName="amountPlanned" />
                      </mat-form-field>
                    </div>
                    <div class="form-field-skeleton-wrapper">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'ORDERBOOK.TO_PLAN' | bmsTranslate }}</mat-label>
                        <input matInput vdwL10nDecimal type="number" formControlName="amountLeft" />
                      </mat-form-field>
                    </div>
                    <div class="form-field-skeleton-wrapper">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'ORDERBOOK.PRODUCED' | bmsTranslate }}</mat-label>
                        <input matInput vdwL10nDecimal type="number" formControlName="amountProduced" />
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <div fxLayout="column" fxLayoutAlign="center">
                    <button
                      *ngIf="hasMoreThanOneOrderLine()"
                      mat-icon-button
                      class="mat-icon-action-button-lg-with-border"
                      [matTooltip]="'GENERAL.ACTIONS.DELETE' | bmsTranslate"
                      (click)="removeOrderLine(orderLineIndex)"
                      [disabled]="!hasEditPermission()"
                    >
                      <mat-icon svgIcon="close"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </article>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</section>
