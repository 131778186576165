<section class="full-height flex-column">
  <vdw-tabs-menu-card
    [icon]="isEditingTuftProductionOrder() ? 'details-blue' : 'add-blue'"
    [action]="getActionText()"
    [title]="getTitle()"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [hasPermissionToEdit]="hasPermissionToEditTuftProductionOrder()"
    [isCreateNewPage]="!isEditingTuftProductionOrder()"
    [saving]="saving"
    [locked]="canDisableForm()"
    [identifier]="HEADER_IDENTIFIER"
    (settingsEvent)="navigateToCustomSettings()"
    (backEvent)="cancel()"
    (cancelEvent)="cancel()"
    (deleteEvent)="deleteTuftProductionOrder()"
    (duplicateEvent)="duplicateTuftProductionOrder()"
    (saveEvent)="saveTuftProductionOrder(SAVE)"
    (saveAndCloseEvent)="saveTuftProductionOrder(SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="saveTuftProductionOrder(SAVE_AND_CREATE_NEW)"
  ></vdw-tabs-menu-card>
  <mat-card class="skeleton-wrapper overflow-auto one-third-width full-height no-margin">
    <mat-card-content class="form-scrollable-content">
      <article>
        <form [formGroup]="addTuftProductionOrderForm" class="flex-column" appBreadcrumbFormLinker>
          <div class="form-row">
            <div class="form-field-skeleton-wrapper flex-item">
              <mat-form-field class="full-width">
                <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
                <input matInput type="text" formControlName="name" />
                <mat-hint *ngIf="canShowSuggestionForTuftProductionOrderName()" class="flex-row">
                  <vdw-ellipsis-label class="overflow-hidden" [text]="'GENERAL.SUGGESTION' | bmsTranslate: {value: suggestedTuftProductionOrderName}" matTooltipPosition="below"></vdw-ellipsis-label>
                  <a class="url" (mousedown)="applySuggestionForTuftProductionOrderName()">{{ 'GENERAL.ACTIONS.APPLY' | bmsTranslate }}</a>
                </mat-hint>
                <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
                  {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                </mat-error>
                <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
                  {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-field-skeleton-wrapper flex-item">
              <mat-form-field class="full-width">
                <mat-label
                  ><span>{{ 'MACHINE.MACHINE' | bmsTranslate: {count: 1} }}</span
                  ><span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span></mat-label
                >
                <vdw-object-selection
                  formControlName="machine"
                  [objectName]="'MACHINE.MACHINE' | bmsTranslate: {count: 1} | lowercase"
                  [baseRouterLink]="MACHINE_BASE_ROUTER_LINK"
                  (selectClicked)="selectMachine()"
                ></vdw-object-selection>
              </mat-form-field>
            </div>
          </div>

          <div class="form-row">
            <div class="form-field-skeleton-wrapper flex-item">
              <mat-form-field class="full-width">
                <mat-label>{{ 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT' | bmsTranslate: {count: 1} }}</mat-label>
                <vdw-object-selection
                  formControlName="tuftProduct"
                  [objectName]="'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT' | bmsTranslate: {count: 1} | lowercase"
                  [baseRouterLink]="TUFT_PRODUCT_BASE_ROUTER_LINK"
                  (selectClicked)="selectTuftProduct()"
                ></vdw-object-selection>
                <mat-error *vdwCanShowErrorForFormControl="'tuftProduct'; error: 'required'">
                  {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-field-skeleton-wrapper flex-item"></div>
          </div>

          <div class="form-row">
            <div class="form-field-skeleton-wrapper flex-item">
              <mat-form-field class="full-width">
                <mat-label>{{ 'TUFT_PRODUCTION_ORDER.PLANNED_LENGTH' | bmsTranslate }}</mat-label>
                <input formControlName="plannedQuantityInMeters" matInput vdwL10nDecimal min="1" type="number" />
                <span matSuffix>
                  {{ 'GENERAL.UNIT.METER' | bmsTranslate }}
                </span>
                <mat-error *vdwCanShowErrorForFormControl="'plannedQuantityInMeters'; error: 'required'">
                  {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                </mat-error>
                <mat-error *vdwCanShowErrorForFormControl="'plannedQuantityInMeters'; error: 'min'">
                  {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-field-skeleton-wrapper flex-item">
              <vdw-datepicker-form-input
                [inputLabel]="'GENERAL.DATETIME.DUE_DATE' | bmsTranslate"
                [form]="addTuftProductionOrderForm"
                formControlNameValue="dueDate"
                [minValue]="getEarliestPossibleDueDate()"
                [optional]="true"
              ></vdw-datepicker-form-input>
            </div>
          </div>

          <mat-form-field class="full-width">
            <mat-label
              ><span>{{ 'TUFT_PRODUCTION_ORDER.NOTES' | bmsTranslate }}</span
              ><span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
            </mat-label>
            <input matInput type="text" formControlName="tuftingNotes" />
          </mat-form-field>
        </form>
      </article>
    </mat-card-content>
  </mat-card>
</section>
