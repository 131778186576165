import {InjectionToken} from '@angular/core';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const COLOR_SETS = new InjectionToken<ColorSets>('ColorSets');

export interface ColorSets
  extends Repository<ColorSet, OverviewListColorSet>,
    UniqueIdentifierObjectRepository,
    ConflictsRepository,
    CustomSettingsObjectRepository,
    NameGenerationRepository<ColorSet> {
  getAllByMachineQuality(qualityId: number): Observable<OverviewListColorSet[]>;
}
