import {Pipe, PipeTransform} from '@angular/core';
import {AssertionUtils, TranslateService} from '@vdw/angular-component-library';

@Pipe({
  name: 'notificationMobileOverviewHeader'
})
export class NotificationMobileOverviewHeaderPipe implements PipeTransform {
  public constructor(private readonly translate: TranslateService) {}

  public transform(headerKey: string, unreadCount: number): string {
    let translatedHeader: string;
    if (headerKey) {
      translatedHeader = this.translate.instant(headerKey);
    } else {
      translatedHeader = '';
    }

    return `${translatedHeader}${unreadCount === 0 || AssertionUtils.isNullOrUndefined(unreadCount) ? '' : ` (${unreadCount?.toString(10)})`}`;
  }
}
