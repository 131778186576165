import {Component, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ButtonSize} from './button-size.enum';

@Component({
  selector: 'vdw-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent {
  @Input() public inProgress = false;
  @Input() public label = 'ANGULAR_COMPONENT_LIBRARY.PROGRESS_BUTTON.SAVE';
  @Input() public class = '';
  @Input() public disabled = false;
  @Input() public raisedButton = true;
  @Input() public labelReplaceableValues = {};
  @Input() public tabIndex = 0;
  @Input() public fullWidth = false;
  @Input() public invalid = false;
  @Input() public hasIcon = false;
  @Input() public iconName: string;
  @Input() public buttonSize: ButtonSize = ButtonSize.LARGE;
  @Input() public canShowBMSTheme = false;
  @Output() public progressClick: Subject<void> = new Subject<void>();

  public isInProgress(): boolean {
    return this.inProgress;
  }

  public isDisabled(): boolean {
    return this.disabled;
  }

  public getButtonWidth(): string {
    return this.fullWidth ? '100%' : 'auto';
  }

  public startProgress(): void {
    if (!this.isInProgress()) {
      this.progressClick.next();
    }
  }

  public isRaisedButton(): boolean {
    return this.raisedButton;
  }

  public getSpinnerDiameter(): number {
    if (!this.canShowBMSTheme) {
      return 44;
    }
    return this.class.includes('compact') ? 12 : 14;
  }
}
