import {Point} from '../../common/interfaces/point';
import {PlanningGridComponent} from '../planning-display/planning-grid/planning-grid.component';
import {PlanningGroupData} from '../planning-items/planning-item-base/planning-group-data';
import {PlanningItemData} from '../planning-items/planning-item-base/planning-item-data';
import {PlanningRowData} from '../planning-items/planning-item-base/planning-row-data';

export class DragDropData {
  public mouseOffsetFromDragIndicator: Point;
  public startMousePositionInContainer: Point;
  public dragIndicatorPositionOnScreen: Point;
  public mousePositionInContainer: Point;

  public draggedItem: PlanningItemData;
  public indicatorWidthPx: number;

  public sourceRow: PlanningRowData;
  public sourceGroup: PlanningGroupData;

  public targetGrid: PlanningGridComponent;
  public isInsideGrid: boolean;
  public targetRow: PlanningRowData;
  public targetItem: PlanningItemData;
  public targetChildItem: PlanningItemData;
  public targetTime: Date;
  public shiftKey: boolean;

  public dropEffect: 'none' | 'copy' | 'link' | 'move' = 'move';

  public get dropAllowed(): boolean {
    return this.dropEffect === 'move' || this.dropEffect === 'link';
  }

  public set dropAllowed(value: boolean) {
    this.dropEffect = value ? 'move' : 'none';
  }
}
