import {InjectionToken} from '@angular/core';
import {Pattern} from '@domain/pattern';
import {CutbarPattern} from '@domain/textile-data/finishing-and-finishing-template/finishing/cutbar-pattern';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const FINISHINGS = new InjectionToken<Finishings>('Finishings');

export interface Finishings
  extends Repository<Finishing, OverviewListFinishing>,
    UniqueIdentifierObjectRepository,
    ConflictsRepository,
    CustomSettingsObjectRepository,
    NameGenerationRepository<Finishing> {
  getAll(parameters?: Partial<GetAllFinishingsParameters>): Observable<OverviewListFinishing[]>;

  getPatternsForCutbarForMachineQuality(parameters: Partial<GetAllFinishingsParameters>): Observable<CutbarPattern[]>;

  getFinishingsForMachineQualityId(parameters?: Partial<GetAllFinishingsParameters>): Observable<OverviewListFinishing[]>;

  generateFinishingForFinishingTemplateAndMachineQuality(finishingTemplateId: number, machineQualityId: number): Observable<Finishing>;

  generateFinishingForFinishingTemplateAndQuality(finishingTemplateId: number, weaveStructureId: number, pickDensity: number): Observable<Finishing>;

  getOverviewListFinishingById(id: number): Observable<OverviewListFinishing>;

  getMinNumberOfPicks(foldingTypeName: string, machineQualityId: number): Observable<number>;

  getMinNumberOfCutbarPicks(foldingTypeName: string, weaveStructureId: number): Observable<number>;

  getFoldingTypes(weaveStructureId: number): Observable<Pattern[]>;
}

export interface GetAllFinishingsParameters {
  showOnlyLatestVersion: boolean;
  machineQualityId: number;
  weaveStructureId: number;
  onlyRecommended: boolean;
}
