export class DrawingShapeAbbreviation {
  private _id: number;
  private _code: string;
  private _shape: string;
  private _repeated: boolean;

  public constructor(id: number, code: string, shape: string, repeated: boolean) {
    this._id = id;
    this._code = code;
    this._shape = shape;
    this._repeated = repeated;
  }

  public get id(): number {
    return this._id;
  }

  public get code(): string {
    return this._code;
  }

  public set code(code: string) {
    this._code = code;
  }

  public get shape(): string {
    return this._shape;
  }

  public set shape(shape: string) {
    this._shape = shape;
  }

  public get repeated(): boolean {
    return this._repeated;
  }

  public set repeated(repeated: boolean) {
    this._repeated = repeated;
  }

  public static fromJSON(drawingShapeJSON: any): DrawingShapeAbbreviation {
    return new DrawingShapeAbbreviation(drawingShapeJSON?.shapeId, drawingShapeJSON?.code, drawingShapeJSON?.shape, drawingShapeJSON?.repeated);
  }

  public toJSON(): JSON {
    return {
      code: this._code,
      shape: this._shape,
      repeated: this._repeated
    } as any as JSON;
  }
}
