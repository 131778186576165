import {Component, Input} from '@angular/core';
import {ProductionScheduleWarnings} from '@domain/production-schedule/production-schedule-warnings';
import {Message, MessageType, TranslateService} from '@vdw/angular-component-library';

@Component({
  selector: 'app-warnings-for-production-schedule-plan',
  templateUrl: './warnings-for-production-schedule-plan.component.html'
})
export class WarningsForProductionSchedulePlanComponent {
  @Input() public productionScheduleWarnings: ProductionScheduleWarnings;
  @Input() public maximumLossOfProductionScheduleInPercent: number;
  private translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public canShowWarningForMaximumLossInProductionSchedule(): boolean {
    return this.getWarningTotalLossInPercentValue() > this.maximumLossOfProductionScheduleInPercent;
  }

  public getWarningTotalLossInPercentValue(): number {
    return this.productionScheduleWarnings.totalLossInPercent;
  }

  public getMessages(): Message[] {
    const result = [];
    if (this.canShowWarningForMaximumLossInProductionSchedule()) {
      const description = this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.CONFIRMATION.WARNINGS.MAXIMUM_LOSS_EXCEEDED', {
        currentLossInPercent: this.getWarningTotalLossInPercentValue(),
        maximumLossInPercent: this.maximumLossOfProductionScheduleInPercent
      });
      result.push(new Message(null, description, MessageType.WARNING, null));
    }

    if (!this.productionScheduleWarnings.minBuggyLengthReached) {
      const description = this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.CONFIRMATION.WARNINGS.MINIMUM_BUGGY_LENGTH_NOT_REACHED');
      result.push(new Message(null, description, MessageType.WARNING, null));
    }

    if (this.productionScheduleWarnings.maxBuggyLengthExceeded) {
      const description = this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.CONFIRMATION.WARNINGS.MAXIMUM_BUGGY_LENGTH_EXCEEDED');
      result.push(new Message(null, description, MessageType.WARNING, null));
    }

    return result;
  }
}
