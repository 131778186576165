import {Component} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {CheckboxCellEditorParams} from './checkbox-cell-editor-params.interface';

@Component({
  templateUrl: './checkbox-cell-editor.component.html',
  styleUrls: ['./checkbox-cell-editor.component.scss']
})
export class CheckboxCellEditorComponent implements ICellRendererAngularComp {
  public params: CheckboxCellEditorParams;

  public agInit(params: CheckboxCellEditorParams): void {
    this.params = params;
  }

  public valueChanged(event: MatCheckboxChange): void {
    this.params.setValue(event.checked);
  }

  public refresh(_: ICellRendererParams<any, any>): boolean {
    return true;
  }
}
