import {InjectionToken} from '@angular/core';
import {WeaveProduct} from '@domain/textile-data/weave-product/weave-product';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';

export const WEAVE_PRODUCT = new InjectionToken<WeaveProducts>('WeaveProducts');

export interface WeaveProducts extends Repository<WeaveProduct, OverviewListWeaveProduct>, UniqueIdentifierObjectRepository, ConflictsRepository {
  getAll(parameters?: Partial<GetAllWeaveProductsParameters>): Observable<OverviewListWeaveProduct[]>;
  getAllForMachineId(machineId: number, parameters?: Partial<GetAllWeaveProductsParameters>): Observable<OverviewListWeaveProduct[]>;
}

export interface GetAllWeaveProductsParameters {
  showOnlyLatestVersion: boolean;
}
