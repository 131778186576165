<section class="flex-column">
  <article class="dialog-content flex-column gap-8">
    <mat-icon *ngIf="hasReportButton()" [class]="getIconClass()" [svgIcon]="getIcon()"></mat-icon>
    <h2 class="font-weight-semibold" [innerHTML]="dialogData.titleText | bmsTranslate"></h2>
    <p class="b1 font-weight-regular multi-line" *ngIf="hasMessageText()" [innerHTML]="dialogData.messageText | bmsTranslate"></p>
    <p class="b1 font-weight-regular multi-line" *ngIf="hasExtraInformationText()" [innerHTML]="dialogData.extraInformationText | bmsTranslate"></p>
    <p class="b1 font-weight-regular multi-line" *ngIf="canShowReportIssueText() && !hasConfirmButtonText()" [innerHTML]="'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.REPORT_ISSUE' | bmsTranslate"></p>
    <ul *ngIf="data.entities" class="flex-column no-margin pl-24">
      <li *ngFor="let entity of data.entities">
        <a *ngIf="entity.route; else noRoute" class="b1 url" (click)="navigateToUrl(entity.route)" target="_blank">{{ entity.name }}</a>
        <ng-template #noRoute>
          <a>{{ entity.name }}</a>
        </ng-template>
      </li>
    </ul>
  </article>
  <vdw-footer
    [cancelEventName]="dialogData.cancelButtonText"
    [chooseEventName]="getChooseEventName()"
    [centered]="true"
    [withCancelButton]="hasCancelButton()"
    [withChooseButton]="canShowChooseButton()"
    [withSaveButton]="hasSaveButton()"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (chooseEvent)="chooseEvent()"
  >
    <button *ngIf="hasDiscardButton() && !hasSaveButton()" mat-flat-button (click)="discard()">
      {{ getConfirmButtonText() | bmsTranslate }}
    </button>
    <button *ngIf="hasDeleteButton()" mat-flat-button class="error" (click)="confirm()">
      {{ getConfirmButtonText() | bmsTranslate }}
    </button>
    <button *ngIf="hasOverrideButton()" mat-flat-button (click)="confirm()">
      {{ getConfirmButtonText() | bmsTranslate }}
    </button>
  </vdw-footer>
</section>
