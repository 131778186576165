import {IdName} from '@domain/id-name';

export class EquipmentManufacturer extends IdName {
  private readonly _models: string[];
  private readonly _technologyIds: number[];

  public constructor(id: number, name: string = null, models: string[] = [], technologyIds: number[] = []) {
    super(id, name);
    this._models = models;
    this._technologyIds = technologyIds;
  }

  public get models(): string[] {
    return this._models;
  }

  public get technologyIds(): number[] {
    return this._technologyIds;
  }

  public static fromJSON(equipmentManufacturerJSON: any): EquipmentManufacturer {
    return new EquipmentManufacturer(equipmentManufacturerJSON.id, equipmentManufacturerJSON.name, equipmentManufacturerJSON.models, equipmentManufacturerJSON.technologyIds);
  }
}
