import {Component} from '@angular/core';
import {CutbarPropertiesForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/cutbar-properties-for-machine-quality';
import {FinishingTemplateForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/finishing-template-for-machine-quality';
import {TranslationsForIncompatibility} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/grid-compatible-with-machine-quality/translations-for-incompatibility';
import {BaseComponent, TranslateService} from '@vdw/angular-component-library';
import {AgRendererComponent} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {isNil, lowerCase} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './grid-compatible-with-machine-quality.component.html',
  styleUrls: ['./grid-compatible-with-machine-quality.component.scss']
})
export class GridCompatibleWithMachineQualityComponent extends BaseComponent implements AgRendererComponent {
  public reasonForIncompatibility: string;
  private finishingTemplate: FinishingTemplateForMachineQuality;
  private translate: TranslateService;
  private readonly labelForCutbarBefore = 'TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_BEFORE';
  private readonly labelForCutbarAfter = 'TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_AFTER';
  private readonly labelForCarpetTop = 'TEXTILE_DATA.FINISHING_TEMPLATE.LABEL.CARPET_TOP';
  private readonly labelForCarpetBottom = 'TEXTILE_DATA.FINISHING_TEMPLATE.LABEL.CARPET_BOTTOM';

  public constructor(translate: TranslateService) {
    super();

    this.translate = translate;
  }

  public agInit(params: ICellRendererParams): void {
    this.finishingTemplate = params.data;
    if (!this.isCompatibleWithMachineQuality()) {
      this.setReasonForIncompatibility();
    }
  }

  public refresh(params: any): boolean {
    return false;
  }

  public isCompatibleWithMachineQuality(): boolean {
    return isNil(this.finishingTemplate.cutbarsInformation) ? true : !isNil(this.finishingTemplate.cutbarsInformation) && !this.finishingTemplate.cutbarsInformation.hasDeficitLength();
  }

  public setReasonForIncompatibility(): void {
    this.reasonForIncompatibility = '';

    this.translate
      .get([this.labelForCutbarBefore, this.labelForCutbarAfter, this.labelForCarpetTop, this.labelForCarpetBottom])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((translations: TranslationsForIncompatibility) => {
        this.addReasonsForIncompatibility(translations);
      });
  }

  private addReasonsForIncompatibility(translations: TranslationsForIncompatibility): void {
    const cutbarBeforeProperties: CutbarPropertiesForMachineQuality = this.finishingTemplate.cutbarsInformation.cutbarBeforeProperties;

    const cutbarAfterProperties: CutbarPropertiesForMachineQuality = this.finishingTemplate.cutbarsInformation.cutbarAfterProperties;

    if (cutbarBeforeProperties.hasDeficitLength()) {
      if (!isNil(cutbarBeforeProperties.deficitLengthOfTopCarpetInPicks)) {
        this.addTranslatedReasonForIncompatibility(cutbarBeforeProperties.deficitLengthOfTopCarpetInPicks, translations[this.labelForCarpetTop], translations[this.labelForCutbarBefore]);
      }
      if (!isNil(cutbarBeforeProperties.deficitLengthOfBottomCarpetInPicks)) {
        this.addTranslatedReasonForIncompatibility(cutbarBeforeProperties.deficitLengthOfBottomCarpetInPicks, translations[this.labelForCarpetBottom], translations[this.labelForCutbarBefore]);
      }
    }
    if (cutbarAfterProperties.hasDeficitLength()) {
      if (!isNil(cutbarAfterProperties.deficitLengthOfTopCarpetInPicks)) {
        this.addTranslatedReasonForIncompatibility(cutbarAfterProperties.deficitLengthOfTopCarpetInPicks, translations[this.labelForCarpetTop], translations[this.labelForCutbarAfter]);
      }
      if (!isNil(cutbarAfterProperties.deficitLengthOfBottomCarpetInPicks)) {
        this.addTranslatedReasonForIncompatibility(cutbarAfterProperties.deficitLengthOfTopCarpetInPicks, translations[this.labelForCarpetBottom], translations[this.labelForCutbarAfter]);
      }
    }
  }

  private addTranslatedReasonForIncompatibility(deficitLength: number, carpetPlace: string, cutbarPlace: string): void {
    this.translate
      .get('TEXTILE_DATA.FINISHING.SELECTION_OF_FINISHING_TEMPLATE.DEFICIT_LENGTH_IN_POSITION', {
        length: deficitLength,
        carpetPlace: lowerCase(carpetPlace),
        cutbarPlace
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((translation: string) => {
        this.reasonForIncompatibility += `${translation} \n`;
      });
  }
}
