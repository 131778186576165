import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class MachineDataSourceRoute extends RouteBranch {
  private _addMachineDataSource: RouteLeaf;
  private _editMachineDataSource: RouteLeaf;
  private _settings: RouteLeaf;

  public get addMachineDataSource(): RouteLeaf {
    return this._addMachineDataSource;
  }

  public set addMachineDataSource(value: RouteLeaf) {
    this.replace(this._addMachineDataSource, value);
    this._addMachineDataSource = value;
  }

  public get editMachineDataSource(): RouteLeaf {
    return this._editMachineDataSource;
  }

  public set editMachineDataSource(value: RouteLeaf) {
    this.replace(this._editMachineDataSource, value);
    this._editMachineDataSource = value;
  }

  public get settings(): RouteLeaf {
    return this._settings;
  }

  public set settings(value: RouteLeaf) {
    this.replace(this._settings, value);
    this._settings = value;
  }
}
