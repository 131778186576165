import {intersection, isEmpty, isEqual, isNil} from 'lodash-es';

export function isClickOutsideGivenElements(element: HTMLElement, elementsClasses: string[] = []): boolean {
  let result = true;
  while (!isEqual(element.tagName, 'BODY')) {
    if (!isEmpty(intersection(element.classList, elementsClasses))) {
      result = false;
      break;
    }
    element = element.parentElement;

    if (isNil(element)) {
      result = false;
      break;
    }
  }
  return result;
}
