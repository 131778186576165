import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class SalesOrderRoute extends RouteBranch {
  private _addSalesOrder: RouteLeaf;
  private _editSalesOrder: RouteLeaf;
  private _duplicateSalesOrder: RouteLeaf;

  public get addSalesOrder(): RouteLeaf {
    return this._addSalesOrder;
  }

  public set addSalesOrder(value: RouteLeaf) {
    this.replace(this._addSalesOrder, value);
    this._addSalesOrder = value;
  }

  public get editSalesOrder(): RouteLeaf {
    return this._editSalesOrder;
  }

  public set editSalesOrder(value: RouteLeaf) {
    this.replace(this._editSalesOrder, value);
    this._editSalesOrder = value;
  }

  public get duplicateSalesOrder(): RouteLeaf {
    return this._duplicateSalesOrder;
  }

  public set duplicateSalesOrder(value: RouteLeaf) {
    this.replace(this._duplicateSalesOrder, value);
    this._duplicateSalesOrder = value;
  }
}
