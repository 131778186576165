import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {GridDateTimeRendererParams} from './grid-date-time-params.interface';

@Component({
  templateUrl: './grid-date-time.component.html'
})
export class GridDateTimeComponent implements ICellRendererAngularComp {
  public date: Date;
  public dateTimeFormat: string;

  public agInit(params: GridDateTimeRendererParams): void {
    this.date = params.value;
    this.dateTimeFormat = params.hideTime ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm';
  }

  public refresh(params: any): boolean {
    return false;
  }
}
