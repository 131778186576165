import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import SvgPanZoom from 'svg-pan-zoom';

@Component({
  selector: 'app-pannable-and-zoomable-pattern-preview',
  templateUrl: './pannable-and-zoomable-pattern-preview.component.html',
  styleUrls: ['./pannable-and-zoomable-pattern-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PannableAndZoomablePatternPreviewComponent implements AfterViewInit, OnDestroy {
  @Input() public svg: string;
  @ViewChild('pannableAndZoomableContainer') public pannableAndZoomableContainer: ElementRef<HTMLElement>;

  private svgPanZoomInstance: typeof SvgPanZoom;

  public ngAfterViewInit(): void {
    this.svgPanZoomInstance = SvgPanZoom(this.pannableAndZoomableContainer.nativeElement.firstElementChild as SVGElement, {
      minZoom: 0.25,
      maxZoom: 10,
      zoomScaleSensitivity: 0.6,
      beforePan: (previousPosition: {x: number; y: number}, {x, y}: {x: number; y: number}) => {
        this.svgPanZoomInstance.updateBBox();
        const sizes = this.svgPanZoomInstance.getSizes() as any;

        const width = sizes.viewBox.width * sizes.realZoom;
        const height = sizes.viewBox.height * sizes.realZoom;
        const offsetX = -sizes.viewBox.x * sizes.realZoom;
        const offsetY = -sizes.viewBox.y * sizes.realZoom;
        const viewportWidthBiggerThanViewBoxWidth = sizes.width > width;
        const viewportHeightBiggerThanViewBoxHeight = sizes.height > height;

        const minX = viewportWidthBiggerThanViewBoxWidth ? offsetX : sizes.width - width + offsetX;
        const maxX = viewportWidthBiggerThanViewBoxWidth ? sizes.width - width + offsetX : offsetX;

        const minY = viewportHeightBiggerThanViewBoxHeight ? offsetY : sizes.height - height + offsetY;
        const maxY = viewportHeightBiggerThanViewBoxHeight ? sizes.height - height + offsetY : offsetY;

        return {
          x: Math.max(minX, Math.min(x, maxX)),
          y: Math.max(minY, Math.min(y, maxY))
        };
      }
    });
  }

  public resetPanAndZoom(): void {
    this.svgPanZoomInstance.reset();
  }

  public ngOnDestroy(): void {
    this.svgPanZoomInstance.destroy();
  }
}
