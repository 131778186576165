import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddProductionSchedulePageComponent} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule-page.component';
import {ProductionScheduleCustomSettingsComponent} from '@presentation/pages/texfab/production-schedule/custom-settings/production-schedule-custom-settings.component';
import {ProductionScheduleOverviewPageComponent} from '@presentation/pages/texfab/production-schedule/overview/production-schedule-overview-page.component';
import {canDeactivateProductionScheduleInitialPreparationsGuard} from '@presentation/pages/texfab/production-schedule/security/can-deactivate-production-schedule-initial-preparations-guard.service';
import {canDeactivateProductionScheduleGuard} from '@presentation/pages/texfab/production-schedule/security/can-deactivate-production-schedule.guard';
import {AddProductionScheduleNewPageComponent} from './add/add-production-schedule-new-page.component';
import {canDeactivateProductionScheduleGuardNew} from './security/can-deactivate-production-schedule-new.guard';

const shared = {parentId: NavigationId.PRODUCTION_SCHEDULE_MODULE};

export const productionScheduleRoutes = [
  Factory.createPrototypeComponentRoute(ProductionScheduleOverviewPageComponent, {route: '', requiredPermission: Permission.TEXFAB_SCHEDULE, id: NavigationId.CARPET_PRODUCTION_ORDER, ...shared}),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'add', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionScheduleNewPageComponent, {route: 'add/new', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_NEW, ...shared}, null, [
    canDeactivateProductionScheduleGuardNew,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'add/machine/:id', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_MACHINE, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionScheduleNewPageComponent, {route: 'add/machine/new/:id', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_MACHINE_NEW, ...shared}, null, [
    canDeactivateProductionScheduleGuardNew,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'add/run', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_RUN, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionScheduleNewPageComponent, {route: 'add/run/new', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_RUN_NEW, ...shared}, null, [
    canDeactivateProductionScheduleGuardNew,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'add/product-configuration/:id', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_CONFIGURATION, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(
    AddProductionScheduleNewPageComponent,
    {route: 'add/product-configuration/:id/new', id: NavigationId.ADD_CARPET_PRODUCTION_SCHEDULE_CONFIGURATION_NEW, ...shared},
    null,
    [canDeactivateProductionScheduleGuardNew, canDeactivateProductionScheduleInitialPreparationsGuard]
  ),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'edit/:id', id: NavigationId.EDIT_CARPET_PRODUCTION_SCHEDULE, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionSchedulePageComponent, {route: 'add/:id', id: NavigationId.DUPLICATE_CARPET_PRODUCTION_SCHEDULE, ...shared}, null, [
    canDeactivateProductionScheduleGuard,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionScheduleNewPageComponent, {route: 'edit/:id/new', id: NavigationId.EDIT_CARPET_PRODUCTION_SCHEDULE_NEW, ...shared}, null, [
    canDeactivateProductionScheduleGuardNew,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(AddProductionScheduleNewPageComponent, {route: 'add/:id/new', id: NavigationId.DUPLICATE_CARPET_PRODUCTION_SCHEDULE_NEW, ...shared}, null, [
    canDeactivateProductionScheduleGuardNew,
    canDeactivateProductionScheduleInitialPreparationsGuard
  ]),
  Factory.createPrototypeComponentRoute(ProductionScheduleCustomSettingsComponent, {
    route: 'settings',
    id: NavigationId.CARPET_PRODUCTION_SCHEDULE_SETTINGS,
    ...shared
  })
];

export const productionScheduleDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(productionScheduleRoutes);
