import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {AsyncValidatorFn, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {some} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {BaseComponent} from '../../../base-component';
import {convertHSLToRGB, convertHSVToRGB, convertRGBToHexadecimalColorCode, convertRGBToHSL, convertRGBToHSV} from '../../../common/converters/convert-color-representation';
import {Color} from '../../../common/data/color';
import {ColorHSLRepresentation} from '../../../common/interfaces/color-hsl-representation';
import {ColorHSVRepresentation} from '../../../common/interfaces/color-hsv-representation';
import {ColorRGBRepresentation} from '../../../common/interfaces/color-rgb-representation';
import {Point} from '../../../common/interfaces/point';
import {FormValidationHelper} from '../../../forms/form-validation-helper';
import {notEmptyValidator} from '../../../validators/not-empty-validator';

@Component({
  selector: 'vdw-add-color',
  templateUrl: './add-color.component.html',
  styleUrls: ['./add-color.component.scss']
})
export class AddColorComponent extends BaseComponent implements OnChanges, OnDestroy {
  private static readonly colorPickerHeightInPx = 275;
  private static readonly colorPickerIndicatorSizeInPx = 20;
  private static readonly colorPickerWidthInPx = 275;
  @Input() public colorToSave: Color = Color.createEmptyColor();
  @Input() public canEdit = true;
  @Input() public validatorFunction: () => AsyncValidatorFn;
  @Input() public loadLink: string;
  @Input() public suggestedColorName: string;
  @Output() public save: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() public nameChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public loadClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() public getSuggestedColorName: EventEmitter<Color> = new EventEmitter<Color>();
  public addColorForm: UntypedFormGroup;
  public previewHSL: ColorHSLRepresentation;
  private readonly previewHSLChange: Subject<ColorHSLRepresentation> = new Subject<ColorHSLRepresentation>();
  private saveButtonTouched = false;

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  private static calculateHSLForPositionInRectangle(hue: number, position: Point): ColorHSLRepresentation {
    const horizontalOffset: number = position.x / AddColorComponent.colorPickerWidthInPx;
    const verticalOffset: number = 1 - position.y / AddColorComponent.colorPickerHeightInPx;
    const saturation: number = 100 * horizontalOffset;
    const value: number = 100 * verticalOffset;
    let result = convertRGBToHSL(convertHSVToRGB({hue, saturation, value}));
    result = {
      hue: Math.round(result.hue),
      saturation: Math.round(result.saturation),
      luminance: Math.round(result.luminance)
    };
    return result;
  }

  private static calculatePositionInRectangleForHSL(hsl: ColorHSLRepresentation): Point {
    const hsv: ColorHSVRepresentation = convertRGBToHSV(convertHSLToRGB(hsl));
    const horizontalOffset: number = hsv.saturation / 100;
    const verticalOffset: number = hsv.value / 100;
    return {
      x: horizontalOffset * AddColorComponent.colorPickerWidthInPx,
      y: (1 - verticalOffset) * AddColorComponent.colorPickerHeightInPx
    };
  }

  private static getHSLWithLuminance(hsl: ColorHSLRepresentation, luminance: number): ColorHSLRepresentation {
    return {hue: hsl.hue, saturation: hsl.saturation, luminance};
  }

  private static getHSLWithSaturation(hsl: ColorHSLRepresentation, saturation: number): ColorHSLRepresentation {
    return {hue: hsl.hue, saturation, luminance: hsl.luminance};
  }

  private static getRoundedHSL(hsl: ColorHSLRepresentation): ColorHSLRepresentation {
    return {hue: Math.round(hsl.hue), saturation: Math.round(hsl.saturation), luminance: Math.round(hsl.luminance)};
  }

  private static getRoundedRGB(rgb: ColorRGBRepresentation): ColorRGBRepresentation {
    return {red: Math.round(rgb.red), green: Math.round(rgb.green), blue: Math.round(rgb.blue)};
  }

  private static getInRangeHSL(hsl: ColorHSLRepresentation): ColorHSLRepresentation {
    return {
      hue: AddColorComponent.getInRangeValue(hsl.hue, 0, 360),
      saturation: AddColorComponent.getInRangeValue(hsl.saturation, 0, 100),
      luminance: AddColorComponent.getInRangeValue(hsl.luminance, 0, 100)
    };
  }

  private static getInRangeRGB(rgb: ColorRGBRepresentation): ColorRGBRepresentation {
    return {
      red: AddColorComponent.getInRangeValue(rgb.red, 0, 255),
      blue: AddColorComponent.getInRangeValue(rgb.blue, 0, 255),
      green: AddColorComponent.getInRangeValue(rgb.green, 0, 255)
    };
  }

  private static getInRangeValue(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, Math.round(value)));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorToSave) {
      if (this.addColorForm) {
        this.setFormFieldValues();
        this.getSuggestedColorName.emit(this.colorToSave);
      } else {
        this.setFormFields();
      }
    }
  }

  public canShowSuggestedColorName(): boolean {
    return this.suggestedColorName?.length > 0 && this.addColorForm.get('name').value !== this.suggestedColorName;
  }

  public applySuggestionForColorName(): void {
    this.addColorForm.get('name').patchValue(this.suggestedColorName);
  }

  public getStyleForColorPickerContainer(): any {
    return {
      width: AddColorComponent.colorPickerWidthInPx + 2 * AddColorComponent.colorPickerIndicatorSizeInPx + 'px',
      height: AddColorComponent.colorPickerHeightInPx + 2 * AddColorComponent.colorPickerIndicatorSizeInPx + 'px',
      position: 'relative',
      left: `${-AddColorComponent.colorPickerIndicatorSizeInPx}px`,
      top: `${-AddColorComponent.colorPickerIndicatorSizeInPx}px`
    };
  }

  public getStyleForColorPicker(): any {
    const colorPickerTopRightColorCode: string = convertRGBToHexadecimalColorCode(
      convertHSVToRGB({
        hue: this.previewHSL.hue,
        saturation: 100,
        value: 100
      })
    );

    return {
      background: `linear-gradient(to top, black, transparent), linear-gradient(to right, white, ${colorPickerTopRightColorCode})`,
      width: `${AddColorComponent.colorPickerWidthInPx}px`,
      height: `${AddColorComponent.colorPickerHeightInPx}px`,
      position: 'relative',
      left: `${AddColorComponent.colorPickerIndicatorSizeInPx}px`,
      top: `${AddColorComponent.colorPickerIndicatorSizeInPx}px`
    };
  }

  public onColorPickerClick(event: MouseEvent): void {
    if (this.canEditColor()) {
      const eventTarget = event.target as Element;
      const position: Point = {x: 0, y: 0};

      switch (eventTarget.id) {
        case 'colorPickerContainer':
          position.x = Math.max(0, Math.min(AddColorComponent.colorPickerWidthInPx, event.offsetX - AddColorComponent.colorPickerIndicatorSizeInPx));
          position.y = Math.max(0, Math.min(AddColorComponent.colorPickerHeightInPx, event.offsetY - AddColorComponent.colorPickerIndicatorSizeInPx));
          break;
        case 'colorPicker':
          position.x = event.offsetX;
          position.y = event.offsetY;
          break;
        default:
          return;
      }
      this.previewHSLChange.next(AddColorComponent.calculateHSLForPositionInRectangle(this.previewHSL.hue, position));
    }
  }

  public getStyleForColorPickerIndicator(): any {
    const position: Point = AddColorComponent.calculatePositionInRectangleForHSL(this.previewHSL);
    const translateX: number = position.x - AddColorComponent.colorPickerIndicatorSizeInPx / 2;
    const translateY: number = position.y - AddColorComponent.colorPickerIndicatorSizeInPx / 2;
    return {
      background: this.getPreviewColorCode(),
      width: `${AddColorComponent.colorPickerIndicatorSizeInPx}px`,
      height: `${AddColorComponent.colorPickerIndicatorSizeInPx}px`,
      transform: `translateX(${translateX}px) translateY(${translateY}px)`
    };
  }

  public getPreviewColorCode(): string {
    return convertRGBToHexadecimalColorCode(convertHSLToRGB(this.previewHSL));
  }

  public getTrackBackgroundForHueSlider(): string {
    return 'linear-gradient(90deg, red, yellow, lime, cyan, blue, magenta, red)';
  }

  public onHueChange(newValue: number): void {
    this.previewHSLChange.next({
      hue: newValue,
      saturation: this.previewHSL.saturation,
      luminance: this.previewHSL.luminance
    });
  }

  public getTrackBackgroundForSaturationSlider(): string {
    const colorCodeForGradientStart: string = this.getColorCodeForPreviewHSLWithSaturation(0);
    const colorCodeForGradientEnd: string = this.getColorCodeForPreviewHSLWithSaturation(100);
    return `linear-gradient(90deg, ${colorCodeForGradientStart}, ${colorCodeForGradientEnd})`;
  }

  public onSaturationChange(newValue: number): void {
    this.previewHSLChange.next({
      hue: this.previewHSL.hue,
      saturation: newValue,
      luminance: this.previewHSL.luminance
    });
  }

  public getTrackBackgroundForLuminanceSlider(): string {
    const colorCodeForGradientStart: string = this.getColorCodeForPreviewHSLWithLuminance(0);
    const colorCodeForGradientMiddle: string = this.getColorCodeForPreviewHSLWithLuminance(50);
    const colorCodeForGradientEnd: string = this.getColorCodeForPreviewHSLWithLuminance(100);
    return `linear-gradient(90deg, ${colorCodeForGradientStart}, ${colorCodeForGradientMiddle}, ${colorCodeForGradientEnd})`;
  }

  public onLuminanceChange(newValue: number): void {
    this.previewHSLChange.next({
      hue: this.previewHSL.hue,
      saturation: this.previewHSL.saturation,
      luminance: newValue
    });
  }

  public saveColor(): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForm(this.addColorForm, this.document);
    if (isValid) {
      this.save.emit(this.getCurrentColor());
    }
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = this.addColorForm ? some(this.addColorForm.controls, (control: UntypedFormControl) => control.invalid && control.touched) : false;
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public canEditColor(): boolean {
    return this.canEdit && !this.colorToSave.used;
  }

  public getLoadLink(): string {
    const id = this.addColorForm.controls['name'].errors.entityId;
    return this.loadLink?.replace(':id', id.toString());
  }

  public onLoadClick(event: MouseEvent): void {
    event?.preventDefault();
    this.loadClicked.emit(this.getLoadLink());
  }

  public getCurrentColor(): Color {
    return new Color(this.colorToSave.id, this.addColorForm.get('name').value, this.getFormRGB(), this.colorToSave.used, undefined, this.addColorForm.get('description').value);
  }

  private setFormFields(): void {
    this.previewHSL = AddColorComponent.getRoundedHSL(convertRGBToHSL(this.colorToSave.rgb));

    this.addColorForm = this.formBuilder.group({
      name: [this.colorToSave.name, [Validators.required, notEmptyValidator()], this.validatorFunction ? this.validatorFunction() : null],
      description: [this.colorToSave.description],
      rgb: this.formBuilder.group({
        red: [this.colorToSave.rgb.red, [Validators.required, Validators.min(0), Validators.max(255)]],
        green: [this.colorToSave.rgb.green, [Validators.required, Validators.min(0), Validators.max(255)]],
        blue: [this.colorToSave.rgb.blue, [Validators.required, Validators.min(0), Validators.max(255)]]
      }),
      hsl: this.formBuilder.group({
        hue: [this.previewHSL.hue, [Validators.required, Validators.min(0), Validators.max(360)]],
        saturation: [this.previewHSL.saturation, [Validators.required, Validators.min(0), Validators.max(100)]],
        luminance: [this.previewHSL.luminance, [Validators.required, Validators.min(0), Validators.max(100)]]
      })
    });

    this.addColorForm
      .get('name')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((value: string) => setTimeout(() => this.nameChanged.emit(value), 0));

    this.subscribeToPreviewHSL();
  }

  private subscribeToPreviewHSL(): void {
    const rgbFormGroup = this.addColorForm.get('rgb');
    const hslFormGroup = this.addColorForm.get('hsl');
    this.previewHSLChange
      .pipe(
        tap((hsl: ColorHSLRepresentation) => {
          this.previewHSL = hsl;
          rgbFormGroup.patchValue(AddColorComponent.getRoundedRGB(convertHSLToRGB(this.previewHSL)), {emitEvent: false});
          hslFormGroup.patchValue(AddColorComponent.getRoundedHSL(this.previewHSL), {emitEvent: false});
        }),
        debounceTime(500),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.getSuggestedColorName.emit(this.getCurrentColor());
      });
    rgbFormGroup.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      if (rgbFormGroup.valid) {
        this.previewHSLChange.next(convertRGBToHSL(this.getFormRGB()));
      } else {
        rgbFormGroup.patchValue(AddColorComponent.getInRangeRGB(this.getFormRGB()));
      }
    });
    hslFormGroup.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      if (hslFormGroup.valid) {
        this.previewHSLChange.next(this.getFormHSL());
      } else {
        hslFormGroup.patchValue(AddColorComponent.getInRangeHSL(this.getFormHSL()));
      }
    });
  }

  private getColorCodeForPreviewHSLWithLuminance(luminance: number): string {
    const colorHSLRepresentation: ColorHSLRepresentation = AddColorComponent.getHSLWithLuminance(this.previewHSL, luminance);
    return convertRGBToHexadecimalColorCode(convertHSLToRGB(colorHSLRepresentation));
  }

  private getColorCodeForPreviewHSLWithSaturation(saturation: number): string {
    const colorHSLRepresentation: ColorHSLRepresentation = AddColorComponent.getHSLWithSaturation(this.previewHSL, saturation);
    return convertRGBToHexadecimalColorCode(convertHSLToRGB(colorHSLRepresentation));
  }

  private getFormHSL(): ColorHSLRepresentation {
    return {
      hue: this.addColorForm.get('hsl.hue').value,
      saturation: this.addColorForm.get('hsl.saturation').value,
      luminance: this.addColorForm.get('hsl.luminance').value
    };
  }

  private getFormRGB(): ColorRGBRepresentation {
    return {
      red: this.addColorForm.get('rgb.red').value,
      green: this.addColorForm.get('rgb.green').value,
      blue: this.addColorForm.get('rgb.blue').value
    };
  }

  private setFormFieldValues(): void {
    this.previewHSL = AddColorComponent.getRoundedHSL(convertRGBToHSL(this.colorToSave.rgb));

    this.addColorForm.get('name').setAsyncValidators(this.validatorFunction ? this.validatorFunction() : null);

    this.addColorForm.setValue({
      name: this.colorToSave.name,
      description: this.colorToSave.description,
      rgb: {
        red: this.colorToSave.rgb.red,
        green: this.colorToSave.rgb.green,
        blue: this.colorToSave.rgb.blue
      },
      hsl: {
        hue: this.previewHSL.hue,
        saturation: this.previewHSL.saturation,
        luminance: this.previewHSL.luminance
      }
    });
  }
}
