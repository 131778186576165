import {AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {AssertionUtils, BaseComponent, WINDOW} from '@vdw/angular-component-library';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {RepositionDialogData} from './reposition-dialog-data.interface';

@Component({
  selector: 'app-reposition-dialog',
  templateUrl: './reposition-dialog.component.html',
  styleUrls: ['./reposition-dialog.component.scss']
})
export class RepositionDialogComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly ARROW_SIZE = 24;
  private static readonly ARROW_MARGIN = 16;
  private static readonly ARROW_PADDING = 4;
  private static readonly DEFAULT_ARROW_OFFSET = 22;
  private static readonly DEFAULT_LEFT_ARROW_OFFSET = 18;
  private static readonly DIALOG_MARGIN = 16;

  public arrowPositionTop: number;
  public arrowPositionLeft: number;
  public componentRef: ComponentRef<any>;

  private startLeft: number;
  private startTop: number;

  @ViewChild('rootElement', {static: true}) private rootElement: ElementRef;
  @ViewChild('container', {read: ViewContainerRef, static: true}) private container: ViewContainerRef;

  private readonly component: any;
  private dialogElementRef: HTMLElement;
  private readonly sourceElement: Element;
  private positionOfDialog: PositionOfDialog = PositionOfDialog.LEFT;
  private readonly windowResizeSubject = new Subject<void>();
  private windowDimensions: DimensionsInPx;
  private hasResizedDialog: boolean;
  private showArrow = true;
  private moveWithCursor = false;
  private readonly verticalOffset: number;
  private readonly horizontalOffset: number;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: RepositionDialogData<any>,
    private readonly dialogRef: MatDialogRef<RepositionDialogComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: Window,
    private readonly ngZone: NgZone
  ) {
    super();
    this.component = data.component;
    this.sourceElement = data.sourceElement;

    if (!AssertionUtils.isNullOrUndefined(data.positionOfDialog)) {
      this.positionOfDialog = data.positionOfDialog;
    }

    this.showArrow = data.showArrow ?? this.showArrow;
    this.moveWithCursor = data.moveWithCursor ?? this.moveWithCursor;

    if (this.moveWithCursor) {
      this.ngZone.runOutsideAngular(() => this.sourceElement?.addEventListener('mousemove', this.mouseMoved));
      this.ngZone.runOutsideAngular(() => this.sourceElement?.addEventListener('mouseleave', this.close));
    }

    const showArrow = !AssertionUtils.isNullOrUndefined(data?.showArrow) && !data.showArrow;

    this.verticalOffset = data.verticalOffset ?? RepositionDialogComponent.ARROW_MARGIN;
    this.horizontalOffset = showArrow ? 8 + (data?.horizontalOffset ?? 0) : RepositionDialogComponent.DEFAULT_LEFT_ARROW_OFFSET + (data?.horizontalOffset ?? 0);

    this.dialogRef = dialogRef;
  }

  private static isDialogOverflowingLeftEdgeOfWindow(dialogLeft: number): boolean {
    return dialogLeft < RepositionDialogComponent.getMinimumDialogPosition();
  }

  private static getMinimumDialogPosition(): number {
    return RepositionDialogComponent.DIALOG_MARGIN;
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.windowDimensions = {widthInPx: this.window.innerWidth, heightInPx: this.window.innerHeight};
    this.windowResizeSubject.next();
  }

  public ngOnInit(): void {
    this.addComponent();

    if (!this.moveWithCursor) {
      this.initializeDialogPositioning();
      return;
    }

    if (!AssertionUtils.isNullOrUndefined(this.data.event)) {
      this.dialogRef.updatePosition({left: `${this.data.event.x + this.horizontalOffset / 2}px`, top: `${this.data.event.y + this.verticalOffset / 2}px`});
    }
  }

  public ngAfterViewInit(): void {
    if (!this.moveWithCursor) {
      this.repositionDialog();
      return;
    }

    this.dialogElementRef = this.rootElement.nativeElement;

    const sourceRect = this.sourceElement.getBoundingClientRect();
    const dialogRect = this.dialogElementRef.getBoundingClientRect();

    this.dialogElementRef.style.position = 'absolute';
    this.dialogElementRef.style.pointerEvents = 'none';

    this.startLeft = sourceRect.left - dialogRect.left + this.horizontalOffset;
    this.startTop = sourceRect.top - dialogRect.top + this.verticalOffset;

    if (!AssertionUtils.isNullOrUndefined(this.data.event)) {
      this.dialogElementRef.style.top = `${this.startTop + this.data.event.offsetY}px`;
      this.dialogElementRef.style.left = `${this.startLeft + this.data.event.offsetX}px`;
    }
  }

  public ngOnDestroy(): void {
    if (!AssertionUtils.isEmpty(this.sourceElement.eventListeners('mousemove'))) {
      this.ngZone.runOutsideAngular(() => this.sourceElement?.removeEventListener('mousemove', this.mouseMoved));
    }

    if (!AssertionUtils.isEmpty(this.sourceElement.eventListeners('mouseleave'))) {
      this.ngZone.runOutsideAngular(() => this.sourceElement?.removeEventListener('mouseleave', this.close));
    }
  }

  public canShowArrow(): boolean {
    if (!this.showArrow) {
      return this.showArrow;
    }

    if (this.positionOfDialog === PositionOfDialog.LEFT || this.positionOfDialog === PositionOfDialog.RIGHT) {
      return this.arrowPositionTop + RepositionDialogComponent.ARROW_SIZE < this.rootElement.nativeElement.clientTop + this.rootElement.nativeElement.clientHeight;
    }
    if (this.positionOfDialog === PositionOfDialog.TOP || this.positionOfDialog === PositionOfDialog.BOTTOM) {
      return this.arrowPositionLeft + RepositionDialogComponent.ARROW_SIZE < this.rootElement.nativeElement.clientLeft + this.rootElement.nativeElement.clientWidth;
    }

    return true;
  }

  public isArrowPositionedAtTop(): boolean {
    return this.positionOfDialog === PositionOfDialog.BOTTOM;
  }

  public isArrowPositionedAtBottom(): boolean {
    return this.positionOfDialog === PositionOfDialog.TOP;
  }

  public isArrowPositionedAtRight(): boolean {
    return this.positionOfDialog === PositionOfDialog.LEFT;
  }

  public isArrowPositionedAtLeft(): boolean {
    return this.positionOfDialog === PositionOfDialog.RIGHT;
  }

  public emitRepositionDialogEvent(): void {
    this.windowResizeSubject.next();
  }

  public repositionDialog(): void {
    let dialogWidth = parseInt(this.rootElement.nativeElement.clientWidth, 10);
    let dialogHeight: number = this.rootElement.nativeElement.clientHeight;

    const sourceRect = this.sourceElement.getBoundingClientRect();
    let dialogLeft = this.calculateDialogPositionLeft(dialogWidth, sourceRect);
    let dialogTop = this.calculateDialogPositionTop(dialogHeight, sourceRect);

    if (this.isDialogOverflowing(dialogLeft, dialogTop, dialogWidth, dialogHeight)) {
      [dialogLeft, dialogWidth] = this.repositionDialogHorizontally(dialogLeft, dialogWidth);
      [dialogTop, dialogHeight] = this.repositionDialogVertically(dialogTop, dialogHeight);
    } else if (this.hasResizedDialog) {
      this.dialogRef.updateSize(`${dialogWidth}px`, `${dialogHeight}px`);
    }

    this.arrowPositionTop = this.calculateArrowPositionTop(dialogHeight, dialogTop, sourceRect);
    this.arrowPositionLeft = this.calculateArrowPositionLeft(dialogWidth, dialogLeft, sourceRect);

    this.dialogRef.updatePosition({left: `${dialogLeft}px`, top: `${dialogTop}px`});

    this.changeDetectorRef.detectChanges();
  }

  public mouseMoved = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    if (AssertionUtils.isNullOrUndefined(this.dialogElementRef) || AssertionUtils.isNullOrUndefined(this.rootElement)) {
      return;
    }

    this.positionOfDialog = event.clientX + this.horizontalOffset + parseInt(this.rootElement.nativeElement.clientWidth, 10) >= this.window.innerWidth ? PositionOfDialog.LEFT : PositionOfDialog.RIGHT;

    this.dialogElementRef.style.top = `${this.startTop + event.offsetY}px`;

    if (this.positionOfDialog === PositionOfDialog.RIGHT) {
      this.dialogElementRef.style.left = `${this.startLeft + event.offsetX}px`;
    } else {
      this.dialogElementRef.style.left = `${this.startLeft + event.offsetX - parseInt(this.rootElement.nativeElement.clientWidth, 10) - this.horizontalOffset}px`;
    }
  };

  private addComponent(): void {
    this.componentRef = this.container.createComponent(this.component);
  }

  private initializeDialogPositioning(): void {
    this.windowDimensions = {widthInPx: this.window.innerWidth, heightInPx: this.window.innerHeight};
    this.windowResizeSubject
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.repositionDialog());

    this.repositionDialog();
  }

  private calculateDialogPositionLeft(dialogWidth: number, sourceRect: DOMRect): number {
    let dialogLeft: number;
    switch (this.positionOfDialog) {
      case PositionOfDialog.TOP:
      case PositionOfDialog.BOTTOM:
        const sourceElementHorizontalCenter = sourceRect.left + sourceRect.width / 2;
        const arrowPositionRight = sourceElementHorizontalCenter + RepositionDialogComponent.ARROW_SIZE / 2;
        dialogLeft = arrowPositionRight - dialogWidth + this.horizontalOffset;
        break;
      case PositionOfDialog.LEFT:
        dialogLeft = sourceRect.left - dialogWidth - this.horizontalOffset;
        if (RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft) || this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth)) {
          this.positionOfDialog = PositionOfDialog.RIGHT;
          dialogLeft = sourceRect.right + this.verticalOffset;
          if (RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft) || this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth)) {
            this.positionOfDialog = PositionOfDialog.LEFT;
            dialogLeft = sourceRect.left - dialogWidth - this.horizontalOffset;
          }
        }
        break;
      case PositionOfDialog.RIGHT:
        dialogLeft = sourceRect.right + this.horizontalOffset;
        if (RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft) || this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth)) {
          this.positionOfDialog = PositionOfDialog.LEFT;
          dialogLeft = sourceRect.left - dialogWidth - this.horizontalOffset;
        }
        break;
    }
    return dialogLeft;
  }

  private canShiftDialogHorizontally(): boolean {
    return this.positionOfDialog === PositionOfDialog.TOP || this.positionOfDialog === PositionOfDialog.BOTTOM;
  }

  private canShiftDialogVertically(): boolean {
    return this.positionOfDialog === PositionOfDialog.LEFT || this.positionOfDialog === PositionOfDialog.RIGHT;
  }

  private isDialogOverflowingRightEdgeOfWindow(dialogLeft: number, dialogWidth: number): boolean {
    return dialogLeft + dialogWidth > this.getMaximumDialogPositionRight();
  }

  private getMaximumDialogPositionRight(): number {
    return this.windowDimensions.widthInPx - RepositionDialogComponent.DIALOG_MARGIN;
  }

  private calculateDialogPositionTop(dialogHeight: number, sourceRect: DOMRect): number {
    let dialogTop: number;
    switch (this.positionOfDialog) {
      case PositionOfDialog.TOP:
        dialogTop = sourceRect.top - dialogHeight - this.verticalOffset;
        if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight) || this.isDialogOverflowingTopEdgeOfWindow(dialogTop)) {
          this.positionOfDialog = PositionOfDialog.BOTTOM;
          dialogTop = sourceRect.bottom + this.verticalOffset;
          if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight) || this.isDialogOverflowingTopEdgeOfWindow(dialogTop)) {
            this.positionOfDialog = PositionOfDialog.TOP;
            dialogTop = sourceRect.top - dialogHeight - this.verticalOffset;
          }
        }
        break;
      case PositionOfDialog.BOTTOM:
        dialogTop = sourceRect.bottom + this.verticalOffset;
        if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight) || this.isDialogOverflowingTopEdgeOfWindow(dialogTop)) {
          this.positionOfDialog = PositionOfDialog.TOP;
          dialogTop = sourceRect.top - dialogHeight - this.verticalOffset;
        }
        break;
      case PositionOfDialog.LEFT:
      case PositionOfDialog.RIGHT:
        const sourceElementVerticalCenter = sourceRect.top + sourceRect.height / 2;
        const arrowPositionTop = sourceElementVerticalCenter - RepositionDialogComponent.ARROW_SIZE / 2;
        dialogTop = arrowPositionTop - RepositionDialogComponent.DEFAULT_ARROW_OFFSET;
        if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight)) {
          dialogTop = this.getMaximumDialogPositionBottom() - dialogHeight;
        }

        break;
    }

    return dialogTop;
  }

  private isDialogOverflowingBottomEdgeOfWindow(dialogTop: number, dialogHeight: number): boolean {
    return dialogTop + dialogHeight > this.getMaximumDialogPositionBottom();
  }

  private getMaximumDialogPositionBottom(): number {
    return this.windowDimensions.heightInPx - RepositionDialogComponent.DIALOG_MARGIN;
  }

  private calculateArrowPositionTop(dialogHeight: number, dialogTop: number, sourceRect: DOMRect): number {
    let arrowTop: number;
    switch (this.positionOfDialog) {
      case PositionOfDialog.TOP:
        arrowTop = dialogHeight - RepositionDialogComponent.ARROW_PADDING;
        break;
      case PositionOfDialog.BOTTOM:
        arrowTop = -this.verticalOffset + RepositionDialogComponent.ARROW_PADDING;
        break;
      case PositionOfDialog.LEFT:
      case PositionOfDialog.RIGHT:
        arrowTop = sourceRect.top + sourceRect.height / 2 - dialogTop - RepositionDialogComponent.ARROW_SIZE / 2;
        break;
    }
    return arrowTop;
  }

  private calculateArrowPositionLeft(dialogWidth: number, dialogLeft: number, sourceRect: DOMRect): number {
    let arrowLeft: number;
    switch (this.positionOfDialog) {
      case PositionOfDialog.TOP:
      case PositionOfDialog.BOTTOM:
        arrowLeft = sourceRect.left + sourceRect.width / 2 - dialogLeft - RepositionDialogComponent.ARROW_SIZE / 2;
        break;
      case PositionOfDialog.LEFT:
        arrowLeft = dialogWidth - RepositionDialogComponent.ARROW_PADDING;
        break;
      case PositionOfDialog.RIGHT:
        arrowLeft = -this.verticalOffset + RepositionDialogComponent.ARROW_PADDING;
        break;
    }
    return arrowLeft;
  }

  private isDialogOverflowingTopEdgeOfWindow(dialogTop: number): boolean {
    return dialogTop < RepositionDialogComponent.getMinimumDialogPosition();
  }

  private isDialogOverflowing(dialogLeft: number, dialogTop: number, dialogWidth: number, dialogHeight: number): boolean {
    return (
      RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft) ||
      this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth) ||
      this.isDialogOverflowingTopEdgeOfWindow(dialogTop) ||
      this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight)
    );
  }

  private repositionDialogHorizontally(dialogLeft: number, dialogWidth: number): [number, number] {
    if (RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft)) {
      if (this.canShiftDialogHorizontally()) {
        dialogWidth = Math.min(dialogWidth, this.getMaximumDialogPositionRight() - dialogLeft);
      } else {
        dialogWidth -= RepositionDialogComponent.getMinimumDialogPosition() - dialogLeft;
      }
      dialogLeft = RepositionDialogComponent.getMinimumDialogPosition();
      this.hasResizedDialog = true;
    } else if (this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth)) {
      if (this.canShiftDialogHorizontally()) {
        dialogLeft = Math.max(RepositionDialogComponent.getMinimumDialogPosition(), this.getMaximumDialogPositionRight() - dialogWidth);
      }
      dialogWidth = this.getMaximumDialogPositionRight() - dialogLeft;
      this.hasResizedDialog = true;
    }

    return [dialogLeft, dialogWidth];
  }

  private repositionDialogVertically(dialogTop: number, dialogHeight: number): [number, number] {
    if (this.isDialogOverflowingTopEdgeOfWindow(dialogTop)) {
      if (this.canShiftDialogVertically()) {
        dialogHeight = Math.min(dialogHeight, this.getMaximumDialogPositionBottom() - dialogTop);
      } else {
        dialogHeight -= RepositionDialogComponent.getMinimumDialogPosition() - dialogTop;
      }

      dialogTop = RepositionDialogComponent.getMinimumDialogPosition();
      this.hasResizedDialog = true;
    } else if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight)) {
      if (this.canShiftDialogVertically()) {
        dialogTop = Math.max(RepositionDialogComponent.getMinimumDialogPosition(), this.getMaximumDialogPositionBottom() - dialogTop);
      }
      dialogHeight = this.getMaximumDialogPositionBottom() - dialogTop;
      this.hasResizedDialog = true;
    }

    return [dialogTop, dialogHeight];
  }

  private close = (): void => {
    this.dialogRef.close();
  };
}
