import {AnimationTriggerMetadata, state, style, trigger} from '@angular/animations';

export function animateToast(): AnimationTriggerMetadata {
  return trigger('flyInOut', [
    state(
      'inactive',
      style({
        display: 'none',
        opacity: 0
      })
    ),
    state('active', style({})),
    state('removed', style({}))
  ]);
}
