import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslationModule} from '../../translation/translation.module';
import {MobileDropdownModule} from '../mobile-dropdown/mobile-dropdown.module';
import {MobilePositionIndicatorModule} from '../mobile-position-indicator/mobile-position-indicator.module';
import {MobileBottomSelectorComponent} from './mobile-bottom-selector.component';

@NgModule({
  declarations: [MobileBottomSelectorComponent],
  imports: [CommonModule, MatIconModule, MobileDropdownModule, MobilePositionIndicatorModule, TranslationModule],
  exports: [MobileBottomSelectorComponent]
})
export class MobileBottomSelectorModule {}
