import {RouteNode} from '@application/helper/routing/route-node';
import {Permission} from '@domain/profile/permission.enum';
import {AssertionUtils} from '@vdw/angular-component-library';

export class RouteBranch extends RouteNode {
  private _children: RouteNode[] = [];

  public constructor(path: string, requiredPermission?: Permission, isMobileFriendly: boolean = false) {
    super(path, requiredPermission, isMobileFriendly);
  }

  public get children(): RouteNode[] {
    return this._children;
  }

  public add(node: RouteNode): void {
    this._children.push(node);
    node.parent = this;
  }

  public remove(node: RouteNode): void {
    const componentIndex = this._children.indexOf(node);
    if (componentIndex >= 0) {
      this._children.splice(componentIndex, 1);
      node.parent = null;
    }
  }

  public replace(node: RouteNode, replacement: RouteNode): void {
    if (node !== replacement) {
      if (!AssertionUtils.isNullOrUndefined(node)) {
        this.remove(node);
      }
      this.add(replacement);
    }
  }

  public isKnownPath(pathSegments: string[]): boolean {
    let result = false;
    const firstPathSegment = pathSegments[0];
    result = this.matchesPath(firstPathSegment);
    if (result && pathSegments?.length > 1) {
      pathSegments.splice(0, this.hasParameter ? this.amountOfRouteParameters : 1);
      result = this._children?.some((routeNode: RouteNode) => routeNode.isKnownPath(pathSegments));
    }
    return result;
  }
}
