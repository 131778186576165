import {AfterContentInit, Component, EventEmitter, Inject, Output} from '@angular/core';
import {DateRange} from 'moment-range';
import {distinctUntilChanged, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {moment} from '../../../common/moment';
import {InterfaceOf} from '../../../common/types/interface-of';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {TimeViewMode} from '../../time-view-mode.enum';
import {PlanningDisplayHelper} from '../helpers/planning-display-helper';
import type {PlanningGridComponent} from '../planning-grid/planning-grid.component';
import {PLANNING_GRID_TOKEN} from '../planning-grid/planning-grid.token';

@Component({
  selector: 'vdw-hour-header-row',
  templateUrl: './hour-header-row.component.html',
  styleUrls: ['./hour-header-row.component.scss']
})
export class HourHeaderRowComponent extends BaseComponent implements AfterContentInit {
  private _selectedDate: Date;
  private _selectedHour: DateRange;
  public hoursForDate: DateRange[] = [];

  @Output() public selectedHourChanged = new EventEmitter<DateRange>();

  public constructor(@Inject(PLANNING_GRID_TOKEN) public readonly grid: InterfaceOf<PlanningGridComponent>) {
    super();
  }

  public ngAfterContentInit(): void {
    this.updateHoursToRender();
    this._selectedHour = this.hoursForDate.find((hour: DateRange) => hour.start.isSameOrBefore(this.grid.currentTime) && hour.end.isSameOrAfter(this.grid.currentTime)) ?? this.hoursForDate[0];

    this.grid.setTimeToHour(this._selectedHour.start.toDate());

    this.grid.onChanges
      .asObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.updateHoursToRender());
  }

  public onHourClicked(duration: DateRange): void {
    if (this.isHourSelected(duration)) {
      return;
    }

    this._selectedHour = duration;
    this.selectedHourChanged.emit(this._selectedHour);

    this.grid.setTimeToHour(this._selectedHour.start.toDate());
  }

  public isHourSelected(hour: DateRange): boolean {
    return this._selectedHour.start.hours() === hour.start.hours() && this._selectedHour.end.hours() === hour.end.hours();
  }

  private updateHoursToRender(): void {
    if (AssertionUtils.isEmpty(this.grid.dayCellData)) {
      return;
    }

    const isDifferentDate = this._selectedDate != null && !PlanningDisplayHelper.isSameDate(this._selectedDate, this.grid.dayCellData[0].date);

    this._selectedDate = moment(this.grid.dayCellData[0].date).set('h', 0).toDate();
    this.hoursForDate = PlanningDisplayHelper.split(moment.range(this._selectedDate, PlanningDisplayHelper.getNextDate(this._selectedDate)), 'hours', 1, TimeViewMode.HOUR);

    if (isDifferentDate) {
      this._selectedHour = this.hoursForDate[0];
    }
  }
}
