import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';

export class TuftProduct {
  private _id: number;
  private _name: string;
  private _gaugeId: number;
  private _widthInCm: number;

  public constructor(id: number, name: string, gaugeId: number, widthInCm: number) {
    this._id = id;
    this._name = name;
    this._gaugeId = gaugeId;
    this._widthInCm = widthInCm;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get gaugeId(): number {
    return this._gaugeId;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public static fromJSON(json: any): TuftProduct {
    return new TuftProduct(json.id, json.name, json.gaugeId, json.widthInCm);
  }

  public static fromOverviewListTuftProduct(tuftProduct: OverviewListTuftProduct): TuftProduct {
    return new TuftProduct(tuftProduct.id, tuftProduct.name, tuftProduct.gauge.id, tuftProduct.widthInCm);
  }
}
