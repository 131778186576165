import {Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2} from '@angular/core';
import {each} from 'lodash-es';

@Directive({
  selector: '[appRunEventsOutsideAngular]'
})
export class RunEventsOutsideAngularDirective implements OnInit {
  @Input('appRunEventsOutsideAngular') public listOfEventsToRunOutsideAngular: string[];
  @Output() public outSideAngularEvent: EventEmitter<any> = new EventEmitter();

  private elementRef: ElementRef;
  private zone: NgZone;
  private renderer: Renderer2;

  public constructor(elementRef: ElementRef, zone: NgZone, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.zone = zone;
    this.renderer = renderer;
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      each(this.listOfEventsToRunOutsideAngular, (eventToRunOutsideAngular: string) => {
        this.renderer.listen(this.elementRef.nativeElement, eventToRunOutsideAngular, this.onEvent.bind(this));
      });
    });
  }

  private onEvent(event: any): void {
    this.outSideAngularEvent.emit(event);
  }
}
