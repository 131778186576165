import {StringUtils} from '@application/helper/string-utils';
import {FinishingCutbarsInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-cutbars-information';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {FinishingAndFinishingTemplateLabelOverviewList} from '@presentation/pages/textile-data/finishing-template/overview/finishing-and-finishing-template-label-overview-list';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {isNil, map} from 'lodash-es';

export class FinishingForProductionSchedule {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;
  private readonly _cutbarsInformation: FinishingCutbarsInformation;
  private readonly _labels: FinishingAndFinishingTemplateLabelOverviewList[];
  private readonly _finishingType: FinishingType;

  public constructor(
    id: number,
    name: string,
    version: number,
    cutbarsInformation: FinishingCutbarsInformation,
    labels: FinishingAndFinishingTemplateLabelOverviewList[] = null,
    finishingType: FinishingType = null
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._cutbarsInformation = cutbarsInformation;
    this._labels = labels;
    this._finishingType = finishingType;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._name, this._version);
  }

  public get cutbarsInformation(): FinishingCutbarsInformation {
    return this._cutbarsInformation;
  }

  public get labels(): FinishingAndFinishingTemplateLabelOverviewList[] {
    return this._labels;
  }

  public get finishingType(): FinishingType {
    return this._finishingType;
  }

  public static fromJSON(finishingJSON: any): FinishingForProductionSchedule {
    return !isNil(finishingJSON)
      ? new FinishingForProductionSchedule(
          finishingJSON.id,
          finishingJSON.name,
          finishingJSON.version,
          FinishingCutbarsInformation.fromJSON(finishingJSON.cutbarsInformation),
          map(finishingJSON.labels, (label: any) => FinishingAndFinishingTemplateLabelOverviewList.fromJSON(label)),
          finishingJSON.finishingType
        )
      : null;
  }

  public static fromOverviewListFinishing(overviewListFinishing: OverviewListFinishing): FinishingForProductionSchedule {
    return new FinishingForProductionSchedule(
      overviewListFinishing.id,
      overviewListFinishing.name,
      overviewListFinishing.version,
      overviewListFinishing.cutbarsInformation,
      overviewListFinishing.labels,
      overviewListFinishing.finishingType
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version,
      cutbarsInformation: !isNil(this._cutbarsInformation) ? this._cutbarsInformation.toJSON() : null
    } as any as JSON;
  }
}
