export class FontSettings {
  private _fontFamily: string;
  private _fontWeight: string;
  private _fontStyle: string;

  public constructor(fontFamily: string, fontWeight: string, fontStyle: string) {
    this._fontFamily = fontFamily;
    this._fontWeight = fontWeight;
    this._fontStyle = fontStyle;
  }

  public get fontFamily(): string {
    return this._fontFamily;
  }

  public get fontWeight(): string {
    return this._fontWeight;
  }

  public get fontStyle(): string {
    return this._fontStyle;
  }

  public static fromJSON(fontSettingsJSON: any): FontSettings {
    return new FontSettings(fontSettingsJSON.fontFamily, fontSettingsJSON.fontWeight, fontSettingsJSON.fontStyle);
  }

  public static fromPathLabelInformationJSON(pathLabelInformationJSON: any): FontSettings {
    return new FontSettings(pathLabelInformationJSON.labelFontFamily, pathLabelInformationJSON.labelFontWeight, pathLabelInformationJSON.labelFontStyle);
  }

  public toJSON(): JSON {
    return {
      labelFontFamily: this._fontFamily,
      labelFontWeight: this._fontWeight,
      labelFontStyle: this._fontStyle
    } as any as JSON;
  }
}
