import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {isNil} from 'lodash-es';

export class PathLabelDrawing extends Drawing {
  public constructor(id: number, name: string, image: DrawingImage, dimensionsInPixels: DimensionsInPx, shape: string, used: boolean, repeated: boolean, shapeId?: number) {
    super(id, name, DrawingType.PATHLABEL, image, dimensionsInPixels, shape, used, repeated, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, shapeId);
  }

  public static fromJSON(drawingJSON: any): PathLabelDrawing {
    const image: DrawingImage = isNil(drawingJSON.image)
      ? {}
      : {
          id: drawingJSON.image.id || null,
          data: drawingJSON.image.data || null
        };

    return new PathLabelDrawing(
      drawingJSON.id,
      drawingJSON.name,
      image,
      {
        widthInPx: drawingJSON.dimensionsInPx.widthInPx,
        heightInPx: drawingJSON.dimensionsInPx.heightInPx
      },
      drawingJSON.shape,
      false,
      drawingJSON.repeated,
      drawingJSON.shapeId
    );
  }
}
