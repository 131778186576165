import {DOCUMENT} from '@angular/common';
import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NameGeneratorPartService} from '@application/helper/name-generator/name-generator-part.service';
import {NameGeneratorPartType} from '@domain/name-generator/enums/name-generator-part-type.enum';
import {NameGenerationProperty} from '@domain/name-generator/name-generation-property';
import {NameGeneratorPart} from '@domain/name-generator/name-generator-part';
import {CustomTextParameters} from '@domain/name-generator/parameters/custom-text-parameters.interface';
import {EntityPropertyParameters} from '@domain/name-generator/parameters/entity-property-parameters.interface';
import {SerialNumberParameters} from '@domain/name-generator/parameters/serial-number-parameters.interface';
import {SerialNumberType} from '@domain/name-generator/serial-number-type';
import {AssertionUtils, BaseComponent, FormValidationHelper, OverlayActionsService, OverlayComponentParamsAction, TranslateService} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {CutFrom} from '../../../../domain/name-generator/enums/cut-from.enum';
import {NameGenerationPatternForm} from './name-generation-pattern-form';

@Component({
  templateUrl: './select-name-generation-pattern.component.html',
  styleUrls: ['./select-name-generation-pattern.component.scss']
})
export class SelectNameGenerationPatternComponent extends BaseComponent implements OnInit {
  @ViewChild('container') public patternsContainer: ElementRef<HTMLElement>;

  public nameGenerationPatternForms: FormArray<NameGenerationPatternForm>;
  public nameGenerationPatternPreviewText = '';
  public nameGenerationProperties: string[];
  public nameGeneratorPartType = NameGeneratorPartType;
  public cutFroms = CutFrom;
  public dragging = false;
  public readonly noDataOverlayActions: OverlayComponentParamsAction[];

  private nameGeneratorParts: NameGeneratorPart<NameGeneratorPartType>[];
  private namePlaceholderSeparator: string;
  private draggedIndex = -1;
  private dropIndicator: HTMLElement;
  private isOnBottomHalfOfRow = false;
  private dragPreviewElementDOMRect: DOMRect;
  private baseElementForDragPreview: HTMLElement;
  private dragPreviewElement: HTMLElement;
  private readonly CLASS_NAME_FOR_DROP_INDICATOR = 'drop-indicator';
  private readonly CLASS_NAME_FOR_PATTERN_ITEM = 'pattern-item';
  private readonly CLASS_NAME_FOR_DRAGGED_ELEMENT = 'dragging';
  private readonly ADD_NAME_PATTERN_ACTION_KEY = 'ADD_NAME_PATTERN';
  private readonly DRAG_PREVIEW_ELEMENT_WIDTH = 248;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialogRef: MatDialogRef<SelectNameGenerationPatternComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly nameGeneratorPartService: NameGeneratorPartService,
    private readonly overlayActionsService: OverlayActionsService,
    private readonly renderer: Renderer2,
    private readonly translate: TranslateService
  ) {
    super();

    this.nameGenerationProperties = data.nameGenerationProperties ? data.nameGenerationProperties.filter((property: string) => NameGenerationProperty.getTranslationKey(property)) : [];
    this.namePlaceholderSeparator = data.namePlaceholderSeparator;
    this.nameGeneratorParts = data.nameGeneratorParts ?? [];
    this.initialiseNameGenerationPatternForms();

    this.noDataOverlayActions = [
      {
        key: this.ADD_NAME_PATTERN_ACTION_KEY,
        titleKey: translate.instant('GENERAL.ACTIONS.ADD_OBJECT', {object: translate.instant('GENERAL.PLACEHOLDER.NAME_PATTERN', {count: 1})}),
        isPrimary: true
      }
    ];
  }

  public ngOnInit(): void {
    this.subscribeToOverlayActions();
  }

  public addPattern(): void {
    this.nameGenerationPatternForms.push(this.initialiseNameGenerationPatternForm());
    this.updatePropertyValueAndValidity();
  }

  public deletePattern(index: number): void {
    this.nameGenerationPatternForms.removeAt(index);
    this.updatePropertyValueAndValidity();
  }

  public getPatternName(nameGenerationProperty: string): string {
    const propertyTranslationKey = NameGenerationProperty.getTranslationKey(nameGenerationProperty);
    return propertyTranslationKey ? this.translate.instant(propertyTranslationKey, {count: 1}) : nameGenerationProperty;
  }

  public selectNameGenerationPattern(): void {
    const isValid = new FormValidationHelper().checkForms([...this.nameGenerationPatternForms.controls], this.document);

    if (isValid) {
      this.dialogRef.close(this.getCurrentNameGeneratorParts());
    }
  }

  public onDragOver(event: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('pattern-item-container')) {
      return;
    }
    event.preventDefault();

    const draggedItemDifferentFromTargetItem = index !== this.draggedIndex;
    const isMouseOnBottomHalfOfTarget = this.isMouseOnBottomHalfOfRow(target.getBoundingClientRect(), event.y);
    const isMouseOnBottomHalfOfPreviousItem = isMouseOnBottomHalfOfTarget && index === this.draggedIndex - 1;
    const isMouseOnTopHalfOfNextItem = !isMouseOnBottomHalfOfTarget && index === this.draggedIndex + 1;

    if (draggedItemDifferentFromTargetItem && !isMouseOnBottomHalfOfPreviousItem && !isMouseOnTopHalfOfNextItem) {
      this.addDropIndicator(target, event.y);
    }
  }

  public onDraggedPatternLeavesPatternItem(event: any): void {
    event.preventDefault();

    if (AssertionUtils.isNullOrUndefined(event.relatedTarget) || !(event.relatedTarget as HTMLElement).classList.contains(this.CLASS_NAME_FOR_PATTERN_ITEM)) {
      this.removeDropIndicator();
    }
  }

  public onDraggedPatternDroppedOnPatternItem(event: any, index: number): void {
    event.preventDefault();

    const currentNameGenerationPatternForm = this.nameGenerationPatternForms.at(this.draggedIndex);
    this.nameGenerationPatternForms.removeAt(this.draggedIndex);

    if (this.draggedIndex < index && !this.isOnBottomHalfOfRow) {
      index -= 1;
    }

    this.nameGenerationPatternForms.insert(index, currentNameGenerationPatternForm);
    this.updatePropertyValueAndValidity();
  }

  public onDragStart(event: DragEvent, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, index: number): void {
    this.dragPreviewElementDOMRect = this.createDragPreviewElement(event, baseElementForDragPreview, containerElement, index);
    this.baseElementForDragPreview = baseElementForDragPreview;

    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.removeStyle(this.dragPreviewElement, 'max-width');
    this.renderer.removeStyle(this.dragPreviewElement, 'min-width');
    this.renderer.setStyle(baseElementForDragPreview, 'opacity', 0.5);
    this.renderer.setStyle(this.dragPreviewElement, 'width', `${this.DRAG_PREVIEW_ELEMENT_WIDTH}px`);
    this.renderer.appendChild(document.body, this.dragPreviewElement);
    this.draggedIndex = index;

    event.stopPropagation();
  }

  public onDragEnd(): void {
    this.removeDragPreviewElement();
    this.removeDropIndicator();
    this.renderer.setStyle(this.baseElementForDragPreview, 'opacity', 1);
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.dragging = false;
  }

  public onDrag(event: DragEvent): void {
    this.dragging = true;
    if (event.screenX !== 0 && event.screenY !== 0) {
      this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${event.x + 5}px, ${event.y - this.dragPreviewElementDOMRect.height / 2}px, 0)`);
    }
  }

  private isMouseOnBottomHalfOfRow(patternItemElementBoundingDOMRect: DOMRect, mouseYPosition: number): boolean {
    return mouseYPosition > patternItemElementBoundingDOMRect.top + patternItemElementBoundingDOMRect.height / 2;
  }

  private addDropIndicator(element: HTMLElement, eventY: number): void {
    if (AssertionUtils.isNullOrUndefined(this.dropIndicator)) {
      this.dropIndicator = this.renderer.createElement('hr');
      this.renderer.addClass(this.dropIndicator, this.CLASS_NAME_FOR_DROP_INDICATOR);
      this.renderer.appendChild(this.patternsContainer.nativeElement, this.dropIndicator);
    }

    const elementBoundingDOMRect = element.getBoundingClientRect();
    this.isOnBottomHalfOfRow = this.isMouseOnBottomHalfOfRow(elementBoundingDOMRect, eventY);

    const width = elementBoundingDOMRect.width;
    const yPosition = this.isOnBottomHalfOfRow ? elementBoundingDOMRect.y + elementBoundingDOMRect.height : elementBoundingDOMRect.y;

    this.renderer.setStyle(this.dropIndicator, 'width', `${width}px`);
    this.renderer.setStyle(this.dropIndicator, 'transform', `translate3d(${elementBoundingDOMRect.x}px, ${yPosition}px, 0)`);
  }

  private removeDropIndicator(): void {
    if (this.dropIndicator != null) {
      this.renderer.removeChild(document.body, this.dropIndicator);
      this.dropIndicator = null;
    }
  }

  private createDragPreviewElement(event: any, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, index: number): DOMRect {
    const dragPreviewElementDOMRect = baseElementForDragPreview.getBoundingClientRect();
    this.dragPreviewElement = baseElementForDragPreview.cloneNode(true) as HTMLElement;

    const dragIcon = this.dragPreviewElement.querySelector('mat-icon');
    this.dragPreviewElement.replaceChildren(dragIcon);

    this.renderer.addClass(this.dragPreviewElement, 'pattern-drag-preview');
    this.renderer.addClass(containerElement, this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
    this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${dragPreviewElementDOMRect.left}px, -${dragPreviewElementDOMRect.height}px, 0)`);

    const dragPreviewTextElement = this.document.createElement('div');

    dragPreviewTextElement.style.paddingLeft = '12px';
    this.renderer.addClass(this.dragPreviewElement, 'b1');
    dragPreviewTextElement.textContent = this.getPatternName(this.nameGenerationPatternForms.controls[index].value.property);
    this.dragPreviewElement.appendChild(dragPreviewTextElement);

    const dummyPreview = document.createElement('div');
    this.renderer.setStyle(dummyPreview, 'display', `none`);
    event.dataTransfer.setDragImage(dummyPreview, 0, 0);

    return dragPreviewElementDOMRect;
  }

  private removeDragPreviewElement(): void {
    if (this.dragPreviewElement != null) {
      this.renderer.removeChild(this.dragPreviewElement.parentElement, this.dragPreviewElement);
      this.renderer.removeClass(document.querySelector(`.${this.CLASS_NAME_FOR_DRAGGED_ELEMENT}`), this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
      this.dragPreviewElement = null;
    }
  }

  private initialiseNameGenerationPatternForms(): void {
    this.nameGenerationPatternPreviewText = this.nameGeneratorPartService.getPreviewText(this.nameGeneratorParts, this.namePlaceholderSeparator);
    this.nameGenerationPatternForms = this.formBuilder.array([
      ...this.nameGeneratorParts.map((nameGeneratorPart: NameGeneratorPart<NameGeneratorPartType>) => this.initialiseNameGenerationPatternForm(nameGeneratorPart))
    ]);
    this.nameGenerationPatternForms.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.nameGenerationPatternPreviewText = this.nameGeneratorPartService.getPreviewText(this.getCurrentNameGeneratorParts(), this.namePlaceholderSeparator);
    });
  }

  private subscribeToOverlayActions(): void {
    this.overlayActionsService.actionTriggeredEmitter.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((actionKey: string) => {
      if (actionKey === this.noDataOverlayActions[0].key) {
        this.addPattern();
      }
    });
  }

  private initialiseNameGenerationPatternForm(nameGeneratorPart?: NameGeneratorPart<NameGeneratorPartType>): NameGenerationPatternForm {
    const nameGenerationPatternForm = this.formBuilder.group({
      property: new FormControl(NameGeneratorPartType.CUSTOM_TEXT),
      entityProperty: this.formBuilder.group({
        cutFrom: new FormControl(CutFrom.NONE),
        startCharacter: new FormControl(null, Validators.min(1)),
        length: new FormControl(null, [Validators.min(1), Validators.max(20)])
      }),
      customText: this.formBuilder.group({
        value: new FormControl(null, [Validators.required, Validators.maxLength(20)])
      }),
      serialNumber: this.formBuilder.group({
        maxLength: new FormControl(null, [Validators.min(1), Validators.max(5)]),
        startValue: new FormControl(null, Validators.maxLength(5)),
        stepSize: new FormControl(null, Validators.min(1))
      })
    }) as NameGenerationPatternForm;

    if (nameGeneratorPart) {
      if (nameGeneratorPart.type === NameGeneratorPartType.ENTITY_PROPERTY) {
        const parameters = nameGeneratorPart.parameters as EntityPropertyParameters;
        nameGenerationPatternForm.patchValue({property: parameters.value, entityProperty: {cutFrom: parameters.cutFrom, startCharacter: parameters.startCharacter, length: parameters.length}});
      } else if (nameGeneratorPart.type === NameGeneratorPartType.CUSTOM_TEXT) {
        nameGenerationPatternForm.controls.customText.patchValue({value: (nameGeneratorPart.parameters as CustomTextParameters).value});
      } else {
        const parameters = nameGeneratorPart.parameters as SerialNumberParameters;
        nameGenerationPatternForm.patchValue({property: nameGeneratorPart.type, serialNumber: {maxLength: parameters.length, startValue: parameters.startValue, stepSize: parameters.stepSize}});
        nameGenerationPatternForm.controls.serialNumber.controls.startValue.setValidators(Validators.maxLength(parameters.length ?? 5));
        nameGenerationPatternForm.controls.serialNumber.controls.startValue.updateValueAndValidity({emitEvent: false});
      }
    }

    nameGenerationPatternForm.controls.property.addValidators(this.serialNumberNotInMiddleValidator(nameGenerationPatternForm));
    nameGenerationPatternForm.controls.property.updateValueAndValidity({emitEvent: false});

    this.subscribeToValueChanges(nameGenerationPatternForm);
    this.updateFormControlDisabledState(nameGenerationPatternForm, nameGenerationPatternForm.value.property);

    return nameGenerationPatternForm;
  }

  private subscribeToValueChanges(nameGenerationPatternForm: NameGenerationPatternForm): void {
    nameGenerationPatternForm.controls.property.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((property: string) => {
      nameGenerationPatternForm.reset({property: property, entityProperty: {cutFrom: CutFrom.NONE}}, {emitEvent: false});
      this.updateFormControlDisabledState(nameGenerationPatternForm, property);
    });

    nameGenerationPatternForm.controls.entityProperty.controls.cutFrom.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((cutFrom: CutFrom) => {
      if (cutFrom === CutFrom.NONE) {
        nameGenerationPatternForm.controls.entityProperty.controls.startCharacter.disable({emitEvent: false});
        nameGenerationPatternForm.controls.entityProperty.controls.length.disable({emitEvent: false});
      } else {
        nameGenerationPatternForm.controls.entityProperty.controls.startCharacter.enable({emitEvent: false});
        nameGenerationPatternForm.controls.entityProperty.controls.length.enable({emitEvent: false});
      }
    });

    nameGenerationPatternForm.controls.serialNumber.controls.maxLength.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((maxLength: number) => {
      nameGenerationPatternForm.controls.serialNumber.controls.startValue.setValidators(Validators.maxLength(maxLength ?? 5));
      nameGenerationPatternForm.controls.serialNumber.controls.startValue.updateValueAndValidity({emitEvent: false});
    });

    nameGenerationPatternForm.controls.serialNumber.controls.startValue.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: string) => {
      const searchValue = nameGenerationPatternForm.value.property === NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER ? /[^A-Za-z]/g : /\D/g;
      nameGenerationPatternForm.controls.serialNumber.controls.startValue.patchValue(value.replace(searchValue, '').toUpperCase(), {emitEvent: false});
    });
  }

  private updateFormControlDisabledState(nameGenerationPatternForm: NameGenerationPatternForm, property: string): void {
    if (NameGeneratorPartType[property] === undefined) {
      nameGenerationPatternForm.controls.entityProperty.enable({emitEvent: false});
      nameGenerationPatternForm.controls.customText.disable({emitEvent: false});
      nameGenerationPatternForm.controls.serialNumber.disable({emitEvent: false});
    } else if (NameGeneratorPartType[property] === NameGeneratorPartType.CUSTOM_TEXT) {
      nameGenerationPatternForm.controls.entityProperty.disable({emitEvent: false});
      nameGenerationPatternForm.controls.customText.enable({emitEvent: false});
      nameGenerationPatternForm.controls.serialNumber.disable({emitEvent: false});
    } else {
      nameGenerationPatternForm.controls.entityProperty.disable({emitEvent: false});
      nameGenerationPatternForm.controls.customText.disable({emitEvent: false});
      nameGenerationPatternForm.controls.serialNumber.enable({emitEvent: false});
    }
  }

  private serialNumberNotInMiddleValidator(nameGenerationPatternForm: NameGenerationPatternForm): ValidatorFn {
    return (property: FormControl<string>): ValidationErrors | null => {
      if (property.value !== NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER && property.value !== NameGeneratorPartType.NUMERIC_SERIAL_NUMBER) {
        return null;
      }
      const index = this.nameGenerationPatternForms?.controls.findIndex((form: NameGenerationPatternForm) => form === nameGenerationPatternForm);
      return index !== 0 && index !== this.nameGenerationPatternForms?.length - 1 ? {serialNumberInMiddle: true} : null;
    };
  }

  private updatePropertyValueAndValidity(): void {
    this.nameGenerationPatternForms.controls.forEach((nameGenerationPatternForm: NameGenerationPatternForm) => {
      nameGenerationPatternForm.controls.property.updateValueAndValidity({emitEvent: false});
    });
  }

  private getCurrentNameGeneratorParts(): NameGeneratorPart<NameGeneratorPartType>[] {
    return this.nameGenerationPatternForms.controls.map((patternForm: NameGenerationPatternForm) => {
      if (NameGeneratorPartType[patternForm.value.property] === undefined) {
        return new NameGeneratorPart(NameGeneratorPartType.ENTITY_PROPERTY, {
          value: patternForm.value.property,
          cutFrom: patternForm.value.entityProperty.cutFrom,
          startCharacter: patternForm.value.entityProperty.startCharacter,
          length: patternForm.value.entityProperty.length
        });
      } else if (NameGeneratorPartType[patternForm.value.property] === NameGeneratorPartType.CUSTOM_TEXT) {
        return new NameGeneratorPart(NameGeneratorPartType.CUSTOM_TEXT, {value: patternForm.value.customText.value});
      } else {
        return new NameGeneratorPart<SerialNumberType>(NameGeneratorPartType[patternForm.value.property], {
          length: patternForm.value.serialNumber.maxLength,
          startValue: patternForm.value.serialNumber.startValue,
          stepSize: patternForm.value.serialNumber.stepSize
        });
      }
    });
  }
}
