import {Injectable} from '@angular/core';
import {ApplicationLayoutService} from '@vdw/angular-component-library';
import {NavigationHeaderComponent} from '../navigation-header/navigation-header.component';
import {NavigationComponent} from '../navigation-component/navigation.component';

@Injectable({
  providedIn: 'root'
})
export class ApplicationLayoutRegisterService {
  public constructor(private readonly applicationLayoutService: ApplicationLayoutService) {}

  public registerElements(): void {
    this.applicationLayoutService.sidebarType = NavigationComponent;
    this.applicationLayoutService.navigationHeaderType = NavigationHeaderComponent;
  }
}
