import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ElementPressedDirective} from './element-pressed.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ElementPressedDirective],
  exports: [ElementPressedDirective]
})
export class ElementPressedModule {}
