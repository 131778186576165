import {ProductionScheduleGlobalStatus} from './production-schedule-global-status.enum';
import {ProductionScheduleStatusColorDescription} from './production-schedule-status-color-description.enum';
import {ProductionScheduleStatusColor} from './production-schedule-status-color.enum';
import {ProductionScheduleStatus} from './production-schedule-status.enum';

export class ProductionScheduleState {
  private _globalStatus: ProductionScheduleGlobalStatus;
  private _statusColor: ProductionScheduleStatusColor;
  private _statusColorDescription: ProductionScheduleStatusColorDescription;
  private _subStatus: ProductionScheduleStatus;

  public constructor(
    globalStatus: ProductionScheduleGlobalStatus,
    statusColor: ProductionScheduleStatusColor,
    statusColorDescription: ProductionScheduleStatusColorDescription,
    subStatus: ProductionScheduleStatus
  ) {
    this._globalStatus = globalStatus;
    this._statusColor = statusColor;
    this._statusColorDescription = statusColorDescription;
    this._subStatus = subStatus;
  }

  public get globalStatus(): ProductionScheduleGlobalStatus {
    return this._globalStatus;
  }

  public get statusColor(): ProductionScheduleStatusColor {
    return this._statusColor;
  }

  public get statusColorDescription(): ProductionScheduleStatusColorDescription {
    return this._statusColorDescription;
  }

  public get subStatus(): ProductionScheduleStatus {
    return this._subStatus;
  }

  public static fromJSON(productionScheduleStateJSON: any): ProductionScheduleState {
    return new ProductionScheduleState(
      productionScheduleStateJSON.globalStatus,
      productionScheduleStateJSON.statusColor,
      productionScheduleStateJSON.statusColorDescription,
      productionScheduleStateJSON.subStatus
    );
  }
}
