export class FilterModelsUtils {
  public static setFilterModelsForColumnId(combinedValues: {[key: string]: string}, filterModel: {[key: string]: any}, key: string): void {
    const setFilterModelIndex = filterModel[key]?.filterModels?.findIndex((model: any) => model?.filterType === 'set');
    if (setFilterModelIndex !== -1) {
      const setFilterModel = filterModel[key].filterModels[setFilterModelIndex];
      const setFilterValues = setFilterModel.values;

      const mappedValues = setFilterValues
        .map((value: string) => {
          const entry = Object.entries(combinedValues).find(([_, translatedValue]: [string, string]) => translatedValue === value);
          return entry ? entry[0] : undefined;
        })
        .filter((mappedValue: string | undefined) => mappedValue !== undefined);

      setFilterModel.values = mappedValues as string[];
    }

    const textFilterModelIndex = filterModel[key]?.filterModels?.findIndex((model: any) => model?.filterType === 'text');
    if (textFilterModelIndex !== -1) {
      const textFilterModel = filterModel[key].filterModels[textFilterModelIndex];
      const searchText = textFilterModel.filter.toLowerCase();

      const matchingKeys = Object.entries(combinedValues)
        .filter(([_, translatedValue]: [string, string]) => translatedValue.toLowerCase().includes(searchText))
        .map(([originalKey, _]: [string, string]) => originalKey);

      if (matchingKeys.length > 0) {
        this.setFilterModelValues(filterModel, key, matchingKeys);
      } else {
        filterModel[key] = {
          filterType: 'set',
          values: ['']
        };
      }

      if (filterModel[key]?.filterModels) {
        filterModel[key].filterModels.splice(textFilterModelIndex, 1);
      }
    }
  }

  private static setFilterModelValues(filterModel: {[key: string]: any}, key: string, values: string[]): void {
    if (!filterModel[key]) {
      filterModel[key] = {
        filterType: 'set',
        values: values
      };
    } else {
      if (filterModel[key].filterModels) {
        const setFilterModel = filterModel[key].filterModels.find((model: any) => model?.filterType === 'set');
        if (setFilterModel) {
          setFilterModel.values = [...new Set([...setFilterModel.values, ...values])];
        } else {
          filterModel[key].filterModels.push({
            filterType: 'set',
            values: values
          });
        }
      } else {
        filterModel[key].filterModels = [
          {
            filterType: 'set',
            values: values
          }
        ];
      }
    }
  }
}
