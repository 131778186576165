<div class="flex-row gap-16">
  <mat-icon class="message-container__icon flex-column" class="icon-36" [svgIcon]="messages[0].type"></mat-icon>
  <div class="message-container__container flex-column flex-center-start gap-4">
    <div *ngIf="showTitle" class="full-width flex-row flex-space-between gap-16">
      <div class="flex-1 overflow-hidden">
        <h4 *ngIf="title; else firstMessageTitle" class="message-container__title font-weight-semibold flex-1" vdw-ellipsis-label [text]="title" matTooltipPosition="above"></h4>

        <ng-template #firstMessageTitle>
          <h4 class="message-container__title font-weight-semibold flex-1" vdw-ellipsis-label [text]="messages[0].title" matTooltipPosition="above"></h4>
        </ng-template>
      </div>
      <p class="b2" *ngIf="showTimestamp">{{ getMomentCurrentDateForSubscriptionTimezone(messages[0].timestamp) }}</p>
    </div>
    <ng-container *ngIf="!onlyShowTitle">
      <ul *ngIf="canShowMessageList(); else singleMessage" class="message-list no-margin full-width flex-column gap-4">
        <li *ngFor="let message of messages">
          <div class="message-list-item flex-row gap-16">
            <p class="b1 flex-1">{{ message.description }}</p>
            <p class="b1" *ngIf="canShowTimestampForEachMessage()">{{ getMomentCurrentDateForSubscriptionTimezone(message.timestamp) }}</p>
          </div>
        </li>
      </ul>
      <ng-template #singleMessage>
        <div class="message-list-item full-width flex-row gap-16">
          <p class="b1 flex-1">{{ messages[0].description }}</p>
          <p class="b1" *ngIf="canShowTimestampForEachMessage()">{{ getMomentCurrentDateForSubscriptionTimezone(messages[0].timestamp) }}</p>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
