import {CarpetWeaveQuality} from '@domain/textile-data/machine-quality/carpet-weave-quality';
import {VelvetWeaveQuality} from '@domain/textile-data/machine-quality/velvet-weave-quality';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';

const weaveQualityClassFromQualityTypeMap = new Map<WeaveQualityType, any>([
  [WeaveQualityType.CARPET, CarpetWeaveQuality],
  [WeaveQualityType.VELVET, VelvetWeaveQuality]
]);

export function getWeaveQualityClassFromQualityType(qualityType: WeaveQualityType): any {
  return weaveQualityClassFromQualityTypeMap.get(qualityType);
}
