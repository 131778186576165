import {SidebarSize} from '@application/sidebar/sidebar-size.enum';
import {isEqual} from 'lodash-es';
import {PlanningSidebarType} from './planning-sidebar-type.enum';

export class PlanningSidebarManager {
  private _opened: boolean;
  private _type: PlanningSidebarType;
  private _size: SidebarSize;

  public constructor() {
    this.initialize();
  }

  public get opened(): boolean {
    return this._opened;
  }

  public get type(): PlanningSidebarType {
    return this._type;
  }

  public get size(): SidebarSize {
    return this._size;
  }

  public open(type: PlanningSidebarType, size: SidebarSize = SidebarSize.SMALL): void {
    this._opened = true;
    this._type = type;

    if (isEqual(size, SidebarSize.SMALL)) {
      this.setSidebarSizeAsSmall();
    } else {
      this.setSidebarSizeAsLarge();
    }
  }

  public close(): void {
    this.initialize();
  }

  public setSidebarSizeAsSmall(): void {
    this._size = SidebarSize.SMALL;
  }

  public setSidebarSizeAsLarge(): void {
    this._size = SidebarSize.LARGE;
  }

  public isSidebarTypeMachine(): boolean {
    return isEqual(this._type, PlanningSidebarType.MACHINE);
  }

  public isSidebarTypePlanningItem(): boolean {
    return isEqual(this._type, PlanningSidebarType.PLANNING_ITEM);
  }

  public isSidebarTypePlanningOrder(): boolean {
    return isEqual(this._type, PlanningSidebarType.PLANNING_ORDER);
  }

  public isSidebarTypeNeedsAttention(): boolean {
    return isEqual(this._type, PlanningSidebarType.NEEDS_ATTENTION);
  }

  private initialize(): void {
    this._opened = false;
    this._type = null;
    this._size = SidebarSize.SMALL;
  }
}
