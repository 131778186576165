import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Permission} from '@domain/profile/permission.enum';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils} from '@vdw/angular-component-library';

@Directive({
  selector: '[appHasPermission],[appHasNotPermission]'
})
export class HasPermissionDirective {
  @Input() public appHasPermissionElse?: TemplateRef<any>;
  @Input() public appHasNotPermissionElse?: TemplateRef<any>;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  @Input()
  public set appHasPermission(permission: Permission) {
    const currentSubscription = this.authentication.getCurrentSubscription();

    if (!AssertionUtils.isNullOrUndefined(currentSubscription) && currentSubscription.hasPermission(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.clearAndCreateView(this.appHasPermissionElse);
    }
  }

  @Input()
  public set appHasNotPermission(permission: Permission) {
    const currentSubscription = this.authentication.getCurrentSubscription();

    if (AssertionUtils.isNullOrUndefined(currentSubscription) || !currentSubscription?.hasPermission(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.clearAndCreateView(this.appHasNotPermissionElse);
    }
  }

  private clearAndCreateView(templateRef: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (!AssertionUtils.isNullOrUndefined(templateRef)) {
      this.viewContainer.createEmbeddedView(templateRef);
    }
  }
}
