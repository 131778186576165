<section class="skeleton-wrapper flex-column full-height" [class.skeleton]="loading" [@fadeIn]="loading">
  <vdw-tabs-menu-card
    icon="language-settings-blue"
    [title]="'GENERAL.CUSTOM_SETTINGS.SETTINGS_FOR_OBJECT' | bmsTranslate: {object: 'ORDERBOOK.ORDERBOOK' | bmsTranslate: {count: 1}}"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [saving]="saving"
    (backEvent)="navigateToOrderbookOverview()"
    (cancelEvent)="navigateToOrderbookOverview()"
    (saveEvent)="save()"
  ></vdw-tabs-menu-card>

  <mat-card class="one-third-width flex-grow no-margin">
    <mat-card-content>
      <article fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row">
          <p class="text-skeleton-wrapper b1 hint">{{ 'ORDERBOOK.SETTINGS.HINT' | bmsTranslate }}</p>
        </div>
        <form>
          <div class="flex-row-wrap gap-16">
            <div class="form-field-skeleton-wrapper" *ngFor="let additionalInfoFormControl of settingsForm.controls; let index = index" [formGroup]="additionalInfoFormControl">
              <mat-form-field class="full-width">
                <mat-label>{{ 'ORDERBOOK.SETTINGS.ADDITIONAL_INFO_NUMBER' | bmsTranslate: {number: index + 1} }}</mat-label>
                <input matInput type="text" formControlName="label" />
                <mat-error *vdwCanShowErrorForFormControl="'label'; error: 'required'">
                  {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                </mat-error>
                <mat-error *vdwCanShowErrorForFormControl="'label'; error: 'unique'">
                  {{ 'GENERAL.ERRORS.UNIQUE' | bmsTranslate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </article>
    </mat-card-content>
  </mat-card>
</section>
