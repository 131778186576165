import {MachineType} from '@domain/machine/machine-type.enum';
import {moment} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {duration} from 'moment';
import {CalendarItem} from './calendar-item';
import {PlanningItemType} from './planning-item-type.enum';

export abstract class PlanningItem extends CalendarItem {
  private _type: PlanningItemType;

  public constructor(id: number, startDate: Date, endDate: Date, type: PlanningItemType) {
    super(id, startDate, endDate);
    this._type = type;
  }

  public get type(): PlanningItemType {
    return this._type;
  }

  public get totalRunningTimeInMs(): number {
    return moment(this._endDate).diff(this._startDate);
  }

  public get uniqueId(): string {
    return `planning-item-${this._id}`;
  }

  public reschedule(startDate: Date, endDate: Date): void {
    this._startDate = startDate;
    this._endDate = endDate;

    this.updateUuid();
  }

  public updateStartWhilePreservingDuration(startDate: Date): void {
    const originalDuration = duration(moment(this._endDate).diff(this.startDate));
    this._startDate = startDate;
    this._endDate = moment(startDate).add(originalDuration).toDate();
  }

  public isNew(): boolean {
    return isNil(this._id);
  }

  public abstract toJSON(timezoneCode: string);
  public abstract isCompatibleWithMachineType(machineType: MachineType): boolean;
}
