<div fxLayout="column" fxFlex="100" fxLayoutAlign="center end">
  <div fxLayout="row">
    <span class="b1 amount text-align-right" fxFlex="100">{{ amount.requested }}</span>
    <span #requested class="b1 font-weight-semibold">{{ 'ORDERBOOK.REQUESTED' | bmsTranslate }}</span>
  </div>
  <div fxLayout="row">
    <span class="b1 amount text-align-right" fxFlex="100">{{ amount.inPlan }}</span>
    <span #currently class="b1 font-weight-semibold">{{ 'ORDERBOOK.CURRENTLY' | bmsTranslate }}</span>
  </div>
  <div fxLayout="row">
    <span class="b1 amount text-align-right" fxFlex="100">{{ amount.planned }}</span>
    <span #planned class="b1 font-weight-semibold">{{ 'ORDERBOOK.PLANNED' | bmsTranslate }}</span>
  </div>
  <div fxLayout="row">
    <span class="b1 amount text-align-right" fxFlex="100">{{ amount.tooMuch }}</span>
    <span #tooMuch class="b1 font-weight-semibold">{{ 'ORDERBOOK.TOO_MUCH_MOBILE' | bmsTranslate }}</span>
  </div>
</div>
