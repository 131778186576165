import {NgModule} from '@angular/core';
import {BmsTranslateAsyncPipe} from './pipes/bms-translate-async.pipe';
import {BmsTranslatePipe} from './pipes/bms-translate.pipe';
import {TranslateService} from './translate.service';

@NgModule({
  declarations: [BmsTranslatePipe, BmsTranslateAsyncPipe],
  providers: [TranslateService],
  exports: [BmsTranslatePipe, BmsTranslateAsyncPipe]
})
export class TranslationModule {}
