import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {MobileNavigationService} from '@application/services/navigation/mobile-navigation.service';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';

export const canActivateUnauthorizedPathGuard = (): boolean => {
  const router = inject(Router);
  const authentication = inject(AUTHENTICATION);
  const navigationService = inject(NavigationService);
  const responsivenessViewMode = inject(RESPONSIVENESS_VIEW_MODE);
  const mobileNavigationService = inject(MobileNavigationService);

  const subscription = authentication.getCurrentSubscription();

  if (AssertionUtils.isNullOrUndefined(subscription)) {
    router.navigateByUrl(RouteUtils.paths.login.absolutePath);
    return false;
  }

  const path = responsivenessViewMode.isPhone ? mobileNavigationService.getDefaultMobileRoute(subscription) : navigationService.getDefaultRoute(subscription);

  if (path) {
    router.navigateByUrl(path);
    return false;
  }

  return true;
};
