import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {TuftProduct} from './tuft-product';

export class RunTufting extends PlanningItem {
  private _tuftProduct: TuftProduct;

  public constructor(itemProperties: PlanningItemProperties, tuftProduct: TuftProduct, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.RUN_TUFTING, ...planningItems);
    this._tuftProduct = tuftProduct;
  }

  public get tuftProduct(): TuftProduct {
    return this._tuftProduct;
  }

  public set tuftProduct(value: TuftProduct) {
    this._tuftProduct = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      tuftProductId: this.tuftProduct.id
    } as any as JSON;
  }
}
