import {InjectionToken} from '@angular/core';
import {IdName} from '@domain/id-name';
import {Equipment} from '@domain/machine/equipment';
import {EquipmentManufacturer} from '@domain/machine/equipment-manufacturer';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineManufacturer} from '@domain/machine/machine-manufacturer';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {WinderVibrationFormValues} from '@domain/texbi/winder-vibration-form-values';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {Repository} from '@infrastructure/http/repository';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {OverviewListMachineWithCompany} from '@presentation/pages/textile-data/company-weave-structure/overview/overview-list-machine-company';
import {Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

export const MACHINES = new InjectionToken<Machines>('Machines');

export interface Machines extends Repository<Equipment, MachineOverviewList>, UniqueIdentifierObjectRepository, CustomSettingsObjectRepository, NameGenerationRepository<Equipment> {
  getActiveColoredYarnSetsWithWidthsForMachineId(id: number): Observable<OverviewListColoredYarnSetWithStartDent[]>;

  getMachineManufacturers(): Observable<MachineManufacturer[]>;

  getEquipmentManufacturers(machineType: MachineType): Observable<EquipmentManufacturer[]>;

  getTuftingMachineGauges(): Observable<IdName[]>;

  getMachineGroups(): Observable<MachineGroup[]>;

  getSummaryForMachine(id: number): Observable<MachineSummary>;

  getAllMachinesWithCompanies(): Observable<OverviewListMachineWithCompany[]>;

  getAllForWeaveStructureId(weaveStructureId: number): Observable<MachineOverviewList[]>;

  getAllForWeaveProductId(weaveProductId: number): Observable<MachineOverviewList[]>;

  getAllForTuftProductId(tuftProductId: number): Observable<MachineOverviewList[]>;

  getAllForPlasticProductId(plasticProductId: number): Observable<MachineOverviewList[]>;

  getAllForMachineQualityId(machineQualityId: number): Observable<MachineOverviewList[]>;

  getAllWinderSerialNumbers(): Observable<string[]>;

  getAllBobbinHolderShaftNumbers(): Observable<string[]>;

  saveWinderVibrationFormValues(winderVibrationFormValues: WinderVibrationFormValues): Observable<number>;

  getAllForAmountOfCreelPositions(amountOfCreelPositions: number): Observable<MachineOverviewList[]>;
}
