import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {ApplicationLayoutService} from '../../application-layout/application-layout.service';
import {MenuTreeViewContextService} from '../menu-tree-view-context.service';
import {MenuItemEntry} from './menu-item-entry.interface';

@Component({
  selector: 'vdw-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends BaseComponent implements OnInit {
  @Input() public iconWidthInPx = 24;
  @Input() public isFavorites = false;
  @Input() public entry: MenuItemEntry;
  @Output() public menuItemClicked = new EventEmitter<PointerEvent>();

  public isActive = false;

  public constructor(
    public readonly menuTreeViewContext: MenuTreeViewContextService,
    private readonly applicationLayout: ApplicationLayoutService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isActive = this.isItemActive();
    this.menuTreeViewContext.activeEntryUpdated.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => (this.isActive = this.isItemActive()));
  }

  public hasChildEntries(): boolean {
    return !AssertionUtils.isEmpty(this.entry.childEntries);
  }

  public hasLink(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.entry.route);
  }

  public hasIcon(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.entry.icon) && this.entry.icon !== '' && !this.isFavorites;
  }

  public favoriteClicked(event: MouseEvent): void {
    event.stopPropagation();

    this.entry.isFavorited = !this.entry.isFavorited;
    this.menuTreeViewContext.itemFavorited.emit(this.entry);
  }

  public isItemActive(): boolean {
    if (this.menuTreeViewContext.isActiveEntry(this.entry)) {
      return true;
    }

    const childEntries = this.applicationLayout.flattenMenuItems(this.entry);
    return childEntries.some((child: MenuItemEntry) => this.menuTreeViewContext.isActiveEntry(child));
  }
}
