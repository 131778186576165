import {InjectionToken} from '@angular/core';
import {InitialOptionsForProductionOrder} from '@domain/production-schedule/initial-options-for-production-order';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {CustomSettingsObjectRepository} from '@infrastructure/http/custom-settings-object-repository';
import {Repository} from '@infrastructure/http/repository';
import {OverviewListProductionSchedule} from '@presentation/pages/texfab/production-schedule/overview/overview-list-production-schedule';
import {ReasonForFailureContext} from '@presentation/pages/texfab/production-schedule/overview/reason-for-failure-context';
import {GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {NameGenerationRepository} from '../name-generation-repository';

export const PRODUCTION_ORDERS = new InjectionToken<ProductionOrders>('ProductionOrders');

export interface ProductionOrders extends CustomSettingsObjectRepository, Repository<ProductionSchedule, OverviewListProductionSchedule>, NameGenerationRepository<ProductionSchedule> {
  getSlice(gridModel: GridModel): Observable<OverviewListProductionSchedule[]>;

  getInitialOptionsForMachine(machineId: number): Observable<InitialOptionsForProductionOrder>;

  getInitialOptionsForMachineAndMachineQuality(machineId: number, machineQualityId: number, reedDensity: number): Observable<InitialOptionsForProductionOrder>;

  getCategoriesWithComponentsForProductionOrderLabel(): Observable<PlaceholderCategory[]>;

  getCategoriesWithComponentsForPathLabel(): Observable<PlaceholderCategory[]>;

  getCategoriesWithComponentsForProductionOrderName(): Observable<PlaceholderCategory[]>;

  getWeavingOrderXMLForProductionOrder(productionOrderId: number): Observable<string>;

  getWeavingOrderXSLTForProductionOrder(productionOrderId: number): Observable<string>;

  getReasonForFailure(productionOrderId: number): Observable<ReasonForFailureContext>;

  getReadyToQueue(productionOrderId: number): Observable<boolean>;

  downloadEpFiles(productionOrderId: string): Observable<ArrayBuffer>;

  removeMachineFiles(productionOrderId: string | number, machineFromProductionOrderId: number): Observable<void>;

  checkCompatibilityWithMachine(productionOrderId: number, machineId: number): Observable<number>;

  completeProductionOrder(productionOrderId: number | string, schedule: ProductionSchedule | ProductionScheduleStatus): Observable<void>;

  updateProductionOrder(
    productionScheduleId: string,
    productionSchedulePathsOfColoredYarnSets: ProductionSchedulePathsOfColoredYarnSet[],
    factorForConversionFromMillimetersToDents: number,
    productionScheduleStatus: ProductionScheduleStatus
  ): Observable<void>;

  getPossibleValues(columnIdentifier: string): Observable<string[] | number[]>;
}
