import {FinishingLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label';
import {FinishingPositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-position-in-carpet';
import {FontSettings} from '@domain/textile-data/finishing-and-finishing-template/finishing/font-settings';
import {LabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-place.enum';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {map} from 'lodash-es';
import isNil from 'lodash-es/isNil';
import {FinishingLabelType} from './finishing-label-type.enum';

export class FinishingTextLabel extends FinishingLabel {
  protected _label: string;
  private _commercialLabelHeightInMM: number;
  private _technicalLabelHeightInPicks: number;
  private _text: string;
  private _placeholderParts: PlaceholderComponent[];
  private _fontSettings: FontSettings;
  private _qrCode: boolean;

  public constructor(
    id: number,
    place: LabelPlace,
    text: string,
    placeholderParts: PlaceholderComponent[],
    fontSettings: FontSettings,
    commercialLabelHeightInMM: number,
    technicalLabelHeightInNumberOfPicks: number,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingPositionInCarpet,
    positionInBottomCarpet: FinishingPositionInCarpet,
    qrCode: boolean = false
  ) {
    super(id, place, rotation, mirrored, positionInTopCarpet, positionInBottomCarpet, FinishingLabelType.TEXT);

    this._text = text;
    this._placeholderParts = placeholderParts;
    this._commercialLabelHeightInMM = commercialLabelHeightInMM;
    this._technicalLabelHeightInPicks = technicalLabelHeightInNumberOfPicks;
    this._fontSettings = fontSettings;
    this._qrCode = qrCode;
    this._label = JSON.stringify(this.getLabel());
  }

  public get commercialLabelHeightInMM(): number {
    return this._commercialLabelHeightInMM;
  }

  public get technicalLabelHeightInPicks(): number {
    return this._technicalLabelHeightInPicks;
  }

  public get text(): string {
    return this._text;
  }

  public get placeholderParts(): PlaceholderComponent[] {
    return this._placeholderParts;
  }

  public get fontSettings(): FontSettings {
    return this._fontSettings;
  }

  public get qrCode(): boolean {
    return this._qrCode;
  }

  public static fromJSON(finishingTextLabelJSON: any): FinishingTextLabel {
    return new FinishingTextLabel(
      finishingTextLabelJSON.id,
      finishingTextLabelJSON.place as LabelPlace,
      !isNil(finishingTextLabelJSON.text) ? finishingTextLabelJSON.text : null,
      !isNil(finishingTextLabelJSON.placeholderParts)
        ? map(finishingTextLabelJSON.placeholderParts, (placeholderPartJSON: any) => {
            return PlaceholderComponent.fromJSON(placeholderPartJSON);
          })
        : null,
      new FontSettings(finishingTextLabelJSON.fontFamily, finishingTextLabelJSON.fontWeight, finishingTextLabelJSON.fontStyle),
      finishingTextLabelJSON.commercialLabelHeightInMM,
      finishingTextLabelJSON.technicalLabelHeightInPicks,
      finishingTextLabelJSON.rotation,
      finishingTextLabelJSON.mirrored,
      FinishingPositionInCarpet.fromJSON(finishingTextLabelJSON.topCarpet),
      FinishingPositionInCarpet.fromJSON(finishingTextLabelJSON.bottomCarpet),
      !isNil(finishingTextLabelJSON.qrCode) ? finishingTextLabelJSON.qrCode : false
    );
  }

  public isPlaceholder(): boolean {
    return !isNil(this.placeholderParts);
  }

  public toJSON(): JSON {
    return {id: this.id, ...this.getLabel()} as any as JSON;
  }

  private getLabel(): object {
    const result = {
      finishingLabelType: this.finishingLabelType,
      place: this.place,
      rotation: this.rotation,
      mirrored: this.mirrored,
      commercialLabelHeightInMM: this.commercialLabelHeightInMM,
      technicalLabelHeightInPicks: this.technicalLabelHeightInPicks,
      fontFamily: this.fontSettings.fontFamily,
      fontWeight: this.fontSettings.fontWeight,
      fontStyle: this.fontSettings.fontStyle,
      topCarpet: this.positionInTopCarpet.toJSON(),
      bottomCarpet: this.positionInBottomCarpet.toJSON(),
      qrCode: this.qrCode
    };

    if (this.isPlaceholder()) {
      result['placeholderParts'] = map(this.placeholderParts, (placeholderPart: PlaceholderComponent) => {
        return placeholderPart.toJSON();
      });
    } else {
      result['text'] = this.text;
    }

    return result;
  }
}
