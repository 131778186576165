export class Tool {
  private _id: number;
  private _name: string;
  private _mountTime: string;
  private _dismountTime: string;

  public constructor(id: number, name: string, mountTime: string, dismountTime: string) {
    this._id = id;
    this._name = name;
    this._mountTime = mountTime;
    this._dismountTime = dismountTime;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get mountTime(): string {
    return this._mountTime;
  }

  public get dismountTime(): string {
    return this._dismountTime;
  }

  public static fromJSON(json: any): Tool {
    return new Tool(json.id, json.name, json.mountTime, json.dismountTime);
  }
}
