import {IdToken} from '@infrastructure/http/authentication/id-token';
import {Token} from '@infrastructure/http/authentication/token';
import {jwtDecode} from 'jwt-decode';
import {isEmpty, isEqual, isNil} from 'lodash-es';

export class AuthenticationToken {
  private _name: string;
  private _email: string;
  private _expiration: number;
  private _requestNonce: string;
  private _audience: string;
  private readonly _requestState: string;
  private readonly _encodedAccessToken: string;
  private readonly _encodedIdToken: string;

  public constructor(encodedAccessToken: string, encodedIdToken: string, requestState: string) {
    this._encodedAccessToken = encodedAccessToken;
    this._encodedIdToken = encodedIdToken;
    this._requestState = requestState;

    this.decodeAccessToken(encodedAccessToken);
    this.decodeIdToken(encodedIdToken);
  }

  public get expiration(): number {
    return this._expiration;
  }

  public get encodedAccessToken(): string {
    return this._encodedAccessToken;
  }

  public get requestState(): string {
    return this._requestState;
  }

  public get email(): string {
    return this._email;
  }

  private get hasEncodedAccessToken(): boolean {
    return !isEmpty(this._encodedAccessToken);
  }

  private get hasEncodedIdToken(): boolean {
    return !isEmpty(this._encodedIdToken);
  }

  private get isNotExpired(): boolean {
    return Date.now() < this._expiration * 1000;
  }

  public static fromJSON(authenticationTokenJSON: any): AuthenticationToken {
    return new AuthenticationToken(authenticationTokenJSON.accessToken, authenticationTokenJSON.idToken, authenticationTokenJSON.state);
  }

  public isValid(state: string, nonce: string, audience: string): boolean {
    const stateMatches = isEqual(state, this._requestState);
    const nonceMatches = isEqual(nonce, this._requestNonce);
    const audienceMatches = isEqual(audience, this._audience);

    return this.hasEncodedAccessToken && this.hasEncodedIdToken && this.isNotExpired && stateMatches && nonceMatches && audienceMatches;
  }

  private decodeIdToken(encodedIdToken: string): void {
    if (!isEmpty(encodedIdToken)) {
      const decodedIdToken: IdToken = jwtDecode(encodedIdToken);

      this._requestNonce = decodedIdToken.nonce;
      this._audience = decodedIdToken.aud;
    }
  }

  private decodeAccessToken(encodedAccessToken: string): void {
    if (!isEmpty(encodedAccessToken)) {
      const decodedToken: Token = jwtDecode(encodedAccessToken);

      this._name = decodedToken.name;
      this._expiration = decodedToken.exp;

      const isVersionOneAccessToken = !isNil(decodedToken.email);
      if (isVersionOneAccessToken) {
        this._email = decodedToken.email;
      } else {
        this._email = !isNil(decodedToken.preferred_username) ? decodedToken.preferred_username : decodedToken.name;
      }
    }
  }
}
