import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EllipsisLabelComponent} from './ellipsis-label.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  exports: [EllipsisLabelComponent],
  declarations: [EllipsisLabelComponent]
})
export class LabelModule {}
