import {OrderLineAdditionalInfo} from '@domain/orderbook/order-line-additional-info';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {OverviewListArticleForOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-article-for-order-line';
import {moment, Priority} from '@vdw/angular-component-library';

export class OverviewListOrderLine {
  private _id: number;
  private _salesOrderReference: string;
  private _salesOrderLineReference: string;
  private _customerReference: string;
  private _desiredDate: Date;
  private _priority: Priority;
  private _article: OverviewListArticleForOrderLine;
  private _amount: number;
  private _amountLeft: number;
  private _amountProduced: number;
  private _amountPlanned: number;
  private _isSelectable: boolean;
  private _status: OrderLineStatus;
  private _canBePlanned: boolean;
  private _commercialLengthInMM: number;
  private _orderType: string;
  private _missingDesign: boolean;
  private _additionalInfo: OrderLineAdditionalInfo[];

  public constructor(
    id: number,
    salesOrderReference: string,
    salesOrderLineReference: string,
    customerReference: string,
    desiredDate: Date,
    priority: Priority,
    article: OverviewListArticleForOrderLine,
    amount: number,
    amountLeft: number,
    amountProduced: number,
    amountPlanned: number,
    isSelectable: boolean,
    status: OrderLineStatus,
    orderType: string,
    canBePlanned: boolean,
    missingDesign: boolean,
    additionalInfo: OrderLineAdditionalInfo[],
    commercialLengthInMM: number = null
  ) {
    this._id = id;
    this._salesOrderReference = salesOrderReference;
    this._salesOrderLineReference = salesOrderLineReference;
    this._customerReference = customerReference;
    this._desiredDate = desiredDate;
    this._priority = priority;
    this._article = article;
    this._amount = amount;
    this._amountLeft = amountLeft;
    this._amountProduced = amountProduced;
    this._amountPlanned = amountPlanned;
    this._isSelectable = isSelectable;
    this._status = status;
    this._canBePlanned = canBePlanned;
    this._commercialLengthInMM = commercialLengthInMM;
    this._orderType = orderType;
    this._missingDesign = missingDesign;
    this._additionalInfo = additionalInfo;
  }

  public get id(): number {
    return this._id;
  }

  public get salesOrderReference(): string {
    return this._salesOrderReference;
  }

  public get salesOrderLineReference(): string {
    return this._salesOrderLineReference;
  }

  public get customerReference(): string {
    return this._customerReference;
  }

  public get desiredDate(): Date {
    return this._desiredDate;
  }

  public get priority(): Priority {
    return this._priority;
  }

  public get article(): OverviewListArticleForOrderLine {
    return this._article;
  }

  public get amount(): number {
    return this._amount;
  }

  public get amountLeft(): number {
    return this._amountLeft;
  }

  public set amountLeft(value: number) {
    this._amountLeft = value;
  }

  public get amountProduced(): number {
    return this._amountProduced;
  }

  public get amountPlanned(): number {
    return this._amountPlanned;
  }

  public get isSelectable(): boolean {
    return this._isSelectable;
  }

  public set isSelectable(isSelectable: boolean) {
    this._isSelectable = isSelectable;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public get status(): OrderLineStatus {
    return this._status;
  }

  public get canBePlanned(): boolean {
    return this._canBePlanned;
  }

  public get orderType(): string {
    return this._orderType;
  }

  public get missingDesign(): boolean {
    return this._missingDesign;
  }

  public get additionalInfo(): OrderLineAdditionalInfo[] {
    return this._additionalInfo;
  }

  public static fromJSON(overviewListOrderLineJSON: any): OverviewListOrderLine {
    return new OverviewListOrderLine(
      overviewListOrderLineJSON.id,
      overviewListOrderLineJSON.salesOrderReference,
      overviewListOrderLineJSON.salesOrderLineReference,
      overviewListOrderLineJSON.customerReference,
      moment(overviewListOrderLineJSON.desiredDate).add(new Date().getTimezoneOffset(), 'minutes').toDate(),
      Priority[overviewListOrderLineJSON.priority as string],
      OverviewListArticleForOrderLine.fromJSON(overviewListOrderLineJSON.article),
      overviewListOrderLineJSON.amount,
      overviewListOrderLineJSON.amountLeft,
      overviewListOrderLineJSON.amountProduced,
      overviewListOrderLineJSON.amountPlanned,
      true,
      OrderLineStatus[`${overviewListOrderLineJSON.status}`],
      overviewListOrderLineJSON.orderType,
      overviewListOrderLineJSON.canBePlanned ?? true,
      !!overviewListOrderLineJSON.missingDesign,
      Object.keys(overviewListOrderLineJSON).reduce((additionalInfo: OrderLineAdditionalInfo[], key: string) => {
        if (key.startsWith('additionalInfo')) {
          additionalInfo.push(new OrderLineAdditionalInfo(key, overviewListOrderLineJSON[key]));
        }
        return additionalInfo;
      }, []),
      overviewListOrderLineJSON.commercialLengthInMM
    );
  }

  public canDuplicate(): boolean {
    return false;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
