import {InjectionToken} from '@angular/core';
import {CreelForProductionSchedule} from '@domain/production-schedule/creel-for-production-schedule';
import {Drawing} from '@domain/production-schedule/drawing';
import {MachineQualityForProductionSchedule} from '@domain/production-schedule/machine-quality-for-production-schedule';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ProductionSchedulePhase} from '@domain/production-schedule/production-schedule-phase.enum';
import {ProductionScheduleProductConfiguration} from '@domain/production-schedule/production-schedule-product-configuration';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {ZoneType} from '@domain/zone-type.enum';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {ParametersForPreselection} from '@presentation/pages/texfab/production-schedule/add/plan/parameters-for-preselection';
import {ConflictedOrderLine} from '@presentation/pages/texfab/production-schedule/confirm/conflicted-order-line';
import {Conflict, GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';

export const PRODUCTION_SCHEDULES = new InjectionToken<ProductionSchedules>('ProductionSchedules');

export interface ProductionSchedules extends Repository<ProductionSchedule, ProductionSchedule>, UniqueIdentifierObjectRepository {
  createProductionSchedule(
    machineSummary: MachineSummary,
    machineQuality: MachineQualityForProductionSchedule,
    practicalPickDensityInPicksPerMM: number,
    coloredYarnSets: OverviewListColoredYarnSetWithStartDent[],
    creel: CreelForProductionSchedule,
    runId: number,
    mappingForCurrentPositionOnMachine: CreelMap[],
    productConfiguration: ProductionScheduleProductConfiguration,
    isSample: boolean,
    canAddJute: boolean,
    hmiVersion: string,
    addToPlanboard: boolean,
    preselectionId: number
  ): Observable<ProductionSchedule>;

  decreateProductionOrder(decreatedProductionOrder: ProductionSchedule): Observable<void>;

  updateWithMachineQualityChanged(productionSchedule: ProductionSchedule, isMachineQualityChanged?: boolean): Observable<void>;
  getConflicts(id: number): Observable<Conflict[]>;

  setProductionSchedulePlan(
    productionScheduleId: string,
    productionSchedulePathsOfColoredYarnSets: ProductionSchedulePathsOfColoredYarnSet[],
    reedDensityInDentsPerMeter: number
  ): Observable<ProductionSchedule>;

  verifyProductionSchedule(id: string, manualSave?: boolean, productionSchedulePhase?: ProductionSchedulePhase): Observable<ProductionSchedule>;

  cancelTemporarySavedChanges(id: string): Observable<void>;

  checkVerifyProductionSchedule(productionScheduleId: string, type: ZoneType, startDent: number, value: number, coloredYarnSetPathNr: number): Observable<any>;

  completeAndPlan(productionSchedule: ProductionSchedule): Observable<void>;

  completeAndQueue(productionSchedule: ProductionSchedule): Observable<void>;

  process(productionSchedule: ProductionSchedule): Observable<void>;

  saveDesignsAndOrderLines(id: number | string, designs: Drawing[], orderLines: ProductionScheduleOrderLine[], scheduleName: string): Observable<void>;

  getDesignsAndOrderLines(id: number | string, status: ProductionScheduleStatus): Observable<{designs: Drawing[]; orderLines: ProductionScheduleOrderLine[]}>;

  getPreselectionListById(id: number | string): Observable<ParametersForPreselection>;

  savePreselectionList(id: number | string, designIds: number[], orderLinesIds: number[], gridModel: GridModel, configurationIds?: number[]): Observable<number>;

  updatePreselectionList(id: number | string, designIds: number[], orderLinesIds: number[], gridModel: GridModel, configurationIds?: number[]): Observable<void>;

  checkPossibleConflictsWithOrderLines(id: string): Observable<ConflictedOrderLine[]>;

  getDuplicatedProductionOrder(id: number | string): Observable<ProductionSchedule>;

  duplicateProductionOrder(productionOrderToDuplicate: ProductionSchedule): Observable<ProductionSchedule>;

  getGeneratedProductionOrderName(machineId: number, qualityId: number, creelId: number): Observable<string>;
}
