import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class CustomerRoute extends RouteBranch {
  private _addCustomer: RouteLeaf;
  private _editCustomer: RouteLeaf;

  public get addCustomer(): RouteLeaf {
    return this._addCustomer;
  }

  public set addCustomer(value: RouteLeaf) {
    this.replace(this._addCustomer, value);
    this._addCustomer = value;
  }

  public get editCustomer(): RouteLeaf {
    return this._editCustomer;
  }

  public set editCustomer(value: RouteLeaf) {
    this.replace(this._editCustomer, value);
    this._editCustomer = value;
  }
}
