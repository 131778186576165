import {InjectionToken} from '@angular/core';
import {YarnSet} from '@domain/textile-data/yarn-set/yarn-set';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {ConflictsRepository} from '../conflicts-repository';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const YARN_SETS = new InjectionToken<YarnSets>('YarnSets');

export interface YarnSets extends Repository<YarnSet, OverviewListYarnSet>, UniqueIdentifierObjectRepository, ConflictsRepository, CustomSettingsObjectRepository, NameGenerationRepository<YarnSet> {}
