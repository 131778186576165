import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {OverviewListWeavingMachine} from '@presentation/pages/texfab/production-order-lite/overview/overview-list-weaving-machine';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import moment from 'moment';
import {ProductionOrderLite} from '../production-order-lite';

export class ProductionOrderWeaving extends ProductionOrderLite {
  private _weaveProduct: OverviewListWeaveProduct;

  public constructor(
    id: number,
    name: string,
    weaveProduct: OverviewListWeaveProduct,
    machine: OverviewListWeavingMachine,
    plannedLength: number,
    dateCreated: Date,
    dueDate: Date,
    status: ProductionScheduleStatus
  ) {
    super(id, name, machine, plannedLength, dateCreated, dueDate, status);
    this._weaveProduct = weaveProduct;
  }

  public get weaveProduct(): OverviewListWeaveProduct {
    return this._weaveProduct;
  }

  public set weaveProduct(value: OverviewListWeaveProduct) {
    this._weaveProduct = value;
  }

  public static fromJSON(overviewListWeaveProductionOrderJSON: any): ProductionOrderWeaving {
    return new ProductionOrderWeaving(
      overviewListWeaveProductionOrderJSON.id,
      overviewListWeaveProductionOrderJSON.name,
      OverviewListWeaveProduct.fromJSON(overviewListWeaveProductionOrderJSON.weaveProduct),
      overviewListWeaveProductionOrderJSON.machine ? OverviewListWeavingMachine.fromJSON(overviewListWeaveProductionOrderJSON.machine) : null,
      overviewListWeaveProductionOrderJSON.plannedQuantity,
      new Date(overviewListWeaveProductionOrderJSON.creationDate),
      overviewListWeaveProductionOrderJSON.dueDate ? new Date(overviewListWeaveProductionOrderJSON.dueDate) : null,
      overviewListWeaveProductionOrderJSON.status
    );
  }

  public toJSON(): JSON {
    return {
      id: this?.id,
      name: this.name,
      weaveProductId: this._weaveProduct?.id,
      weaveProductName: this._weaveProduct?.name,
      weaveProductPickDensityInPicksPerCm: this._weaveProduct?.pickDensityInPicksPerCm,
      machineId: this.machine?.id,
      machineName: this.machine?.name,
      plannedQuantity: this.plannedLength ?? 0,
      creationDate: this.dateCreated ? this.dateCreated.toISOString() : new Date().toISOString(),
      dueDate: moment(this.dueDate).startOf('day').subtract(new Date().getTimezoneOffset(), 'minutes').toISOString()
    } as any as JSON;
  }
}
