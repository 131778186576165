import {WeavePattern} from '@domain/textile-data/weave-structure/weave-pattern/weave-pattern';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {OverviewListWeaveStructure} from '@presentation/pages/textile-data/weave-structure/overview/overview-list-weave-structure';
import {WeaveStructureDetails} from './weave-structure-details';

export class WeaveStructure extends OverviewListWeaveStructure {
  public used: boolean;
  public details: WeaveStructureDetails;
  public qualityType: WeaveQualityType;

  public constructor(
    id: number,
    name: string,
    version: number,
    type: string,
    description: string,
    fileName: string,
    releaseNotes: string,
    used: boolean,
    fromVandewiele: boolean,
    patterns: WeavePattern[],
    details: WeaveStructureDetails,
    qualityType: WeaveQualityType
  ) {
    super(id, name, version, type, description, fileName, fromVandewiele, qualityType, releaseNotes, patterns);
    this.used = used;
    this.details = details;
  }

  public static createEmptyWeaveStructure(): WeaveStructure {
    return new WeaveStructure(null, null, null, null, null, null, null, null, true, [], WeaveStructureDetails.createEmptyWeaveStructureDetails(), null);
  }

  public static fromJSON(weaveStructureJSON: any): WeaveStructure {
    return new WeaveStructure(
      weaveStructureJSON.id,
      weaveStructureJSON.name,
      weaveStructureJSON.version,
      weaveStructureJSON.type,
      weaveStructureJSON.description,
      weaveStructureJSON.fileName,
      weaveStructureJSON.releaseNotes,
      weaveStructureJSON.used,
      weaveStructureJSON.fromVandewiele,
      weaveStructureJSON.patterns ? weaveStructureJSON.patterns.map((weavePattern: WeavePattern) => WeavePattern.fromJSON(weavePattern)) : null,
      weaveStructureJSON.details ? WeaveStructureDetails.fromJSON(weaveStructureJSON.details) : null,
      weaveStructureJSON.qualityType
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      version: this.version,
      type: this.type,
      description: this.description,
      fileName: this.fileName,
      releaseNotes: this.releaseNotes,
      used: this.used,
      fromVandewiele: this.fromVandewiele,
      patterns: this.patterns.map((weavePattern: WeavePattern) => weavePattern.toJSON()),
      details: this.details.toJSON(),
      qualityType: this.qualityType
    } as any as JSON;
  }
}
