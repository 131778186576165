import {Injectable, Injector} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Route, Routes} from '@angular/router';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {PrototypeRouteUtils} from '../../common/utils/prototype-route-utils';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogService<T = unknown> {
  public activeRoute: Route;
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;
  public navigationHistory: {route: Route; inputs: Record<string, any>}[] = [];

  private routes: Routes = [];
  private activeRouteData: T;
  private inputs: Record<string, unknown>;
  private routeData: Record<string, T> = {};

  public addRouting(routes: Routes): void {
    this.routes.push(...routes);
  }

  public reset(): void {
    this.dialog = null;
    this.routeData = {};
    this.activeRouteData = null;
    this.navigationHistory = [];
  }

  public getInputs(): Record<string, unknown> {
    return this.inputs;
  }

  public getRouteData(route: Route): T {
    return this.routeData[route.path];
  }

  public resetRouteData(route: Route): void {
    this.routeData[route.path] = null;
  }

  public getActiveRouteData(): T {
    return this.activeRoute ? this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, this.activeRoute.data?.id)] : null;
  }

  public navigateBack(data?: T): void {
    const previous = this.navigationHistory.pop();

    if (!AssertionUtils.isNullOrUndefined(data)) {
      this.setActiveRouteData(data);
    }

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      this.inputs = previous.inputs;
      this.activeRoute = previous.route;
    } else {
      this.dialog.close();
    }
  }

  public navigateForward(route: Route, data?: T, newInputs?: Record<string, unknown>): void {
    if (!AssertionUtils.isNullOrUndefined(this.dialog)) {
      this.navigationHistory.push({route: this.activeRoute, inputs: this.inputs});
    }

    if (!AssertionUtils.isNullOrUndefined(data)) {
      this.setActiveRouteData(data);
    }

    this.activeRoute = route;

    if (!AssertionUtils.isNullOrUndefined(newInputs)) {
      this.inputs = newInputs;
    }
  }

  private setActiveRouteData(routeData: T): void {
    this.activeRouteData = routeData;
    this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, this.activeRoute.data?.id)] = this.activeRouteData;
  }
}
