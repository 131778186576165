import {CommercialUnitConversionError} from '../error/commercial-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToCommercialUnitDecimeter(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.MILLIMETER:
      result = unitValue.value * 0.01;
      break;
    case Unit.CENTIMETER:
      result = unitValue.value * 0.1;
      break;
    case Unit.DECIMETER:
      result = unitValue.value;
      break;
    case Unit.METER:
      result = unitValue.value * 10;
      break;
    default:
      throw new CommercialUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.DECIMETER}`);
  }

  return result;
}
