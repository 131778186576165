import {Component, HostListener, Inject, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {isClickOutsideGivenElements} from '@application/helper/is-click-outside-given-elements';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {SidebarSize} from '@application/sidebar/sidebar-size.enum';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningItemType} from '@domain/planning/planning-item-type.enum';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {PLANNING, Planning} from '@infrastructure/http/planning/planning';
import {AssertionUtils, BaseComponent, DialogBuilderFactoryService, DialogType, ObjectUtils, TranslateService} from '@vdw/angular-component-library';
import moment from 'moment';
import {takeUntil} from 'rxjs';
import {NavigationPlanningData} from '../navigation-planning-data.type';
import {PLANNING_SCHEDULER, PlanningScheduler} from '../planning-scheduler/planning-scheduler';
import {RemovePlanningOrderCommand} from '../planning-scheduler/remove-planning-order/remove-planning-order-command';
import {PlanningContextService} from '../planning-services/planning-context.service';
import {PlanningDragDropChangesService} from '../planning-services/planning-drag-drop-changes.service';
import {PlanningSidebarData} from '../planning-sidebar-data.interface';
import {NeedsAttentionSidebarComponent} from './needs-attention-sidebar/needs-attention-sidebar.component';
import {PlanningSidebarManager} from './planning-sidebar-manager';
import {PlanningSidebarType} from './planning-sidebar-type.enum';

@Component({
  selector: 'app-planning-sidebars',
  templateUrl: './planning-sidebars.component.html'
})
export class PlanningSidebarsComponent extends BaseComponent implements OnInit {
  @Input() public sidebarManager: PlanningSidebarManager;

  @ViewChild(NeedsAttentionSidebarComponent)
  public set needsAttentionSidebar(sidebar: NeedsAttentionSidebarComponent) {
    this.planningDragDropChanges.needsAttentionSidebar = sidebar;
  }

  private readonly removePlanningOrderCommand = new RemovePlanningOrderCommand();

  private readonly classesThatShouldNotCloseSidebar = [
    'row-indicator',
    'sidebar',
    'planning-group',
    'needs-attention',
    'inactivity-card',
    'machine-run-card',
    'cdk-overlay-container',
    'planning-item-container'
  ];

  public constructor(
    @Inject(PLANNING) private readonly planning: Planning,
    @Inject(PLANNING_SCHEDULER) private readonly planningScheduler: PlanningScheduler,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    public readonly planningContext: PlanningContextService,
    public readonly planningDragDropChanges: PlanningDragDropChangesService,
    public readonly viewContainerRef: ViewContainerRef,
    private readonly navigationHelperService: NavigationHelperService<NavigationPlanningData>
  ) {
    super();
  }

  public ngOnInit(): void {
    const emptyState = {sidebarType: null, sidebarPlanningItem: null, sidebarPlanningMachine: null, sidebarPlanningOrderId: null} as PlanningSidebarData;
    const previousState = this.navigationHelperService.getPartialState<PlanningSidebarData>(Object.keys(emptyState));
    if (previousState && !ObjectUtils.isDeepEqual(emptyState, previousState)) {
      if (previousState.sidebarType === PlanningSidebarType.PLANNING_ITEM) {
        setTimeout(() => this.openPlanningItemDetails(previousState.sidebarPlanningMachine, previousState.sidebarPlanningItem));
      } else if (previousState.sidebarType === PlanningSidebarType.MACHINE) {
        setTimeout(() => this.openMachineDetails(previousState.sidebarPlanningMachine));
      } else if (previousState.sidebarType === PlanningSidebarType.PLANNING_ORDER) {
        setTimeout(() => this.openPlanningOrderDetails(previousState.sidebarPlanningOrderId));
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public onOutsideSidebarClick(event: MouseEvent): void {
    if (
      !AssertionUtils.isNullOrUndefined(this.sidebarManager) &&
      this.sidebarManager.opened &&
      !this.planningContext.isOnboardingRunning &&
      isClickOutsideGivenElements(event.target as HTMLElement, this.classesThatShouldNotCloseSidebar)
    ) {
      this.closeSidebar(this.sidebarManager.isSidebarTypePlanningItem());
    }
  }

  public onSidebarOpenedChange(closed: boolean): void {
    if (closed) {
      this.closeSidebar(this.sidebarManager.isSidebarTypePlanningItem());
    }
  }

  public closeSidebar(resetPlanning: boolean = false): void {
    if (resetPlanning) {
      this.planningScheduler.resetPlanning();
    }

    this.planningContext.selectedPlaceholder = null;
    this.planningContext.resetSelectedVariables();

    this.sidebarManager.close();
  }

  public openNeedsAttentionOrders(): void {
    this.resetSidebar();
    this.sidebarManager.open(PlanningSidebarType.NEEDS_ATTENTION, SidebarSize.SMALL);
  }

  public openMachineDetails(machine: PlanningMachine): void {
    this.resetSidebar();
    this.planningContext.selectedMachine = machine;
    this.sidebarManager.open(PlanningSidebarType.MACHINE, SidebarSize.SMALL);
  }

  public openPlanningItemDetails(machine: PlanningMachine, planningItem: PlanningItem): void {
    if (this.sidebarManager.isSidebarTypePlanningItem() && planningItem.id !== this.planningContext.selectedPlanningItem.id) {
      this.planningScheduler.resetPlanning();
    }

    this.planningContext.resetSelectedVariables();
    if (planningItem !== this.planningContext.selectedPlaceholder) {
      this.planningContext.selectedPlaceholder = null;
    }

    this.planningContext.selectedMachine = machine;
    this.planningContext.selectedPlanningItem = planningItem;

    this.sidebarManager.open(PlanningSidebarType.PLANNING_ITEM, this.sidebarShouldBeLarge(planningItem) ? SidebarSize.LARGE : SidebarSize.SMALL);
  }

  public openPlanningOrderDetails(planningOrderId: number): void {
    if (planningOrderId == null) {
      return;
    }

    this.resetSidebar();
    this.planningContext.selectedPlanningOrderId = planningOrderId;
    this.sidebarManager.open(PlanningSidebarType.PLANNING_ORDER, SidebarSize.SMALL);
  }

  public onPlanningItemChange(planningItem: PlanningItem): void {
    this.sidebarShouldBeLarge(planningItem) ? this.sidebarManager.setSidebarSizeAsLarge() : this.sidebarManager.setSidebarSizeAsSmall();

    if (planningItem.startDate <= this.planningContext.getCurrentDateForSubscriptionTimezone() && (!(planningItem instanceof PlanningMachineRun) || planningItem.canBeRescheduled())) {
      planningItem.startDate = moment(this.planningContext.getCurrentDateForSubscriptionTimezone()).add(1, 'm').toDate();
    }

    if (planningItem.isNew()) {
      this.planningContext.selectedPlaceholder = planningItem;
    } else {
      const groupedCalendarItem = this.planningContext.groupedCalendarItems.find((itemGroup: CalendarItemGroup) => itemGroup.machineId === this.planningContext.selectedMachine.id);
      this.replacePlanningItemInGroupedCalendarItem(planningItem, groupedCalendarItem);

      this.planningContext.selectedPlanningItem = planningItem;
    }
  }

  public deletePlanningItem(): void {
    this.planning
      .deletePlanningItem(this.planningContext.selectedPlanningItem)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: () => {
          this.closeSidebar();
        },
        error: () => {
          this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
            titleText: this.translate.instant('GENERAL.ERRORS.SOMETHING_WRONG'),
            messageText: this.translate.instant('PLANNING.ERRORS.DELETE_PLANNING_ITEM', {planningItemType: PlanningItemType[this.planningContext.selectedPlanningItem.type].toLowerCase()}),
            type: DialogType.ERROR
          });
        }
      });
  }

  public savePlanningItem(planningItem: PlanningItem): void {
    const groupedCalendarItem = this.planningScheduler.getCachedGroupedCalendarItem(this.planningContext.selectedMachine.id);

    if (planningItem.isNew()) {
      this.planningScheduler.addNewPlanningItem(planningItem, groupedCalendarItem);
    } else {
      this.replacePlanningItemInGroupedCalendarItem(planningItem, groupedCalendarItem);
      this.planningScheduler.rescheduleItems(planningItem, this.planningContext.selectedMachine, groupedCalendarItem, this.planningContext.selectedMachine);
    }

    this.closeSidebar(false);
    this.planningScheduler.setGroupedCalendarItems(this.planningContext.groupedCalendarItems);
  }

  public deletePlanningOrder(): void {
    const foundOrder = this.findSelectedPlanningOrder();

    if (foundOrder?.order != null) {
      this.planningContext.groupedCalendarItems = this.removePlanningOrderCommand.execute({
        run: foundOrder.machineRun,
        adjustRunRunningTime: false,
        planningOrder: foundOrder.order,
        groupedCalendarItems: this.planningContext.groupedCalendarItems,
        sourceGroupedCalendarItem: foundOrder.groupedCalendarItem
      });

      this.planningScheduler.setGroupedCalendarItems(this.planningContext.groupedCalendarItems);
    }

    this.closeSidebar(false);
  }

  private resetSidebar(): void {
    if (this.sidebarManager.isSidebarTypePlanningItem()) {
      this.planningScheduler.resetPlanning();
    }

    this.planningContext.resetSelectedVariables();
    this.planningContext.selectedPlaceholder = null;
  }

  private sidebarShouldBeLarge(planningItem: PlanningItem): boolean {
    return planningItem instanceof PlanningMachineRun && (planningItem.canBeRescheduled() || planningItem.executing);
  }

  private replacePlanningItemInGroupedCalendarItem(updatedPlanningItem: PlanningItem, groupedCalendarItem: CalendarItemGroup): void {
    if (groupedCalendarItem != null) {
      const index = groupedCalendarItem.items.findIndex((planningItem: PlanningItem) => planningItem.uniqueId === updatedPlanningItem.uniqueId);
      groupedCalendarItem.items.splice(index, 1, updatedPlanningItem);
    }
  }

  private findSelectedPlanningOrder(): {order: PlanningOrder; machineRun: PlanningMachineRun; groupedCalendarItem: CalendarItemGroup} {
    for (const groupedItem of this.planningContext.groupedCalendarItems) {
      for (const planningItem of groupedItem.items) {
        if (planningItem instanceof PlanningMachineRun) {
          const planningOrder = planningItem.planningOrders.find((order: PlanningOrder) => order.id === this.planningContext.selectedPlanningOrderId);

          if (planningOrder != null) {
            return {order: planningOrder, machineRun: planningItem, groupedCalendarItem: groupedItem};
          }
        }
      }
    }

    return {order: undefined, machineRun: undefined, groupedCalendarItem: undefined};
  }
}
