import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {getMachineClassFromMachineType} from '@application/helper/machine/get-machine-class-from-machine-type';
import {ColoredYarnSetStubFactory} from '@application/helper/textile-data/colored-yarn-set/colored-yarn-set-stub-factory';
import {Equipment} from '@domain/machine/equipment';
import {EquipmentManufacturer} from '@domain/machine/equipment-manufacturer';
import {Gauge} from '@domain/machine/gauge';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineManufacturer} from '@domain/machine/machine-manufacturer';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {PropertyValue} from '@domain/property-value';
import {WinderVibrationFormValues} from '@domain/texbi/winder-vibration-form-values';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {Machines} from '@infrastructure/http/machine/machines.interface';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {MachineOverviewListHelper} from '@presentation/pages/machine-overview/machine-overview-list-helper';
import {OverviewListMachineWithCompany} from '@presentation/pages/textile-data/company-weave-structure/overview/overview-list-machine-company';
import {NotImplementedError} from '@vdw/angular-component-library';
import {Observable, of as observableOf, throwError} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpMachinesService implements Machines {
  private readonly MACHINE_TYPE_TO_EQUIPMENT_MANUFACTURERS_URL_MAP = new Map<MachineType, string>([
    [MachineType.WEAVING_MACHINE, 'weaving-machine-manufacturers'],
    [MachineType.TUFT, 'tufting-machine-manufacturers'],
    [MachineType.WINDER, 'winder-machine-manufacturers'],
    [MachineType.DYEING, 'dyeing-machine-manufacturers'],
    [MachineType.PLASTIC_MACHINE, 'plastic-machine-manufacturers']
  ]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public delete(id: number): Observable<void> {
    return throwError(() => new NotImplementedError());
  }

  public getAll(): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getById(id: number): Observable<Equipment> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/${id}`).pipe(
      rxjsMap((machineJSON: any) => {
        return getMachineClassFromMachineType(MachineType[`${machineJSON['machineType']}`]).fromJSON(machineJSON);
      })
    );
  }

  public getActiveColoredYarnSetsWithWidthsForMachineId(id: number): Observable<OverviewListColoredYarnSetWithStartDent[]> {
    const productionOrderColoredYarnSet: OverviewListColoredYarnSetWithStartDent[] = [
      new OverviewListColoredYarnSetWithStartDent(ColoredYarnSetStubFactory.getRandomColoredYarnSetWithId(1), 1, 200),
      new OverviewListColoredYarnSetWithStartDent(ColoredYarnSetStubFactory.getRandomColoredYarnSetWithId(2), 200, 300)
    ];

    if (id === 1) {
      productionOrderColoredYarnSet.push(new OverviewListColoredYarnSetWithStartDent(ColoredYarnSetStubFactory.getRandomColoredYarnSetWithId(2), 500, 500));
    }

    return observableOf(productionOrderColoredYarnSet);
  }

  public getCurrentWeaveQualityForMachineId(id: number): Observable<WeaveQuality> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/${id}/machine-quality`).pipe(rxjsMap((machineQualityJSON: any) => WeaveQuality.fromJSON(machineQualityJSON)));
  }

  public save(machine: Equipment): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getMachineEndpoint()}machines`, machine.toJSON()).pipe(rxjsMap((response: number) => response));
  }

  public update(machine: Equipment): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getMachineEndpoint()}machines/${machine.id}`, machine.toJSON());
  }

  public getMachineManufacturers(): Observable<MachineManufacturer[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/machine-manufacturers`).pipe(
      rxjsMap((machineManufacturersJSON: any) => {
        return machineManufacturersJSON.map((machineManufacturerJSON: any) => MachineManufacturer.fromJSON(machineManufacturerJSON));
      })
    );
  }

  public getEquipmentManufacturers(equipmentType: MachineType): Observable<EquipmentManufacturer[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/${this.MACHINE_TYPE_TO_EQUIPMENT_MANUFACTURERS_URL_MAP.get(equipmentType)}`).pipe(
      rxjsMap((equipmentManufacturersJSON: any) => {
        return equipmentManufacturersJSON.map((equipmentManufacturerJSON: any) => EquipmentManufacturer.fromJSON(equipmentManufacturerJSON));
      })
    );
  }

  public getTuftingMachineGauges(): Observable<Gauge[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/tufting-machine-gauges`).pipe(
      rxjsMap((tuftingMachineGaugesJSON: any) => {
        return tuftingMachineGaugesJSON.map((tuftingMachineGaugeJSON: any) => Gauge.fromJSON(tuftingMachineGaugeJSON));
      })
    );
  }

  public getMachineGroups(): Observable<MachineGroup[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/machine-groups`).pipe(
      rxjsMap((machineGroupsJSON: any) => {
        return machineGroupsJSON.map((machineGroupJSON: any) => MachineGroup.fromJSON(machineGroupJSON));
      })
    );
  }

  public getSummaryForMachine(id: number): Observable<MachineSummary> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/${id}/summary`).pipe(rxjsMap((machineSummaryJSON: any) => MachineSummary.fromJSON(machineSummaryJSON)));
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/check-name`, {params}).pipe(
      rxjsMap((response: {machineNameAvailable: boolean}) => {
        return response.machineNameAvailable;
      })
    );
  }

  public getAllMachinesWithCompanies(): Observable<OverviewListMachineWithCompany[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getMachineEndpoint()}machines/company`)
      .pipe(rxjsMap((machineCompaniesJSON: any) => machineCompaniesJSON.map((machineCompanyJSON: any) => OverviewListMachineWithCompany.fromJSON(machineCompanyJSON))));
  }

  public getAllForWeaveStructureId(weaveStructureId: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/weavestructure/${weaveStructureId}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getAllForWeaveProductId(weaveProductId: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/weave-product/${weaveProductId}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getAllForTuftProductId(tuftProductId: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/tuft-product/${tuftProductId}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getAllForPlasticProductId(plasticProductId: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/plastic-product/${plasticProductId}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getAllForMachineQualityId(machineQualityId: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machines/machine-quality/${machineQualityId}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  public getAllBobbinHolderShaftNumbers(): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/bobbin-holder-shaft-numbers`).pipe(
      rxjsMap((bobbinHolderShaftNumbers: string[]) => {
        return bobbinHolderShaftNumbers;
      })
    );
  }

  public getAllWinderSerialNumbers(): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/winder-serial-numbers`).pipe(
      rxjsMap((winderSerialNumbers: string[]) => {
        return winderSerialNumbers;
      })
    );
  }

  public saveWinderVibrationFormValues(winderVibrationFormValues: WinderVibrationFormValues): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getWorkerEndpoint()}extrusion/winder-vibration-form`, winderVibrationFormValues.toJSON())
      .pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public getAllForAmountOfCreelPositions(amountOfCreelPositions: number): Observable<MachineOverviewList[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/creel-positions/${amountOfCreelPositions}/compatible`).pipe(rxjsMap(this.mapMachinesJSONToList));
  }

  private mapMachinesJSONToList = (machinesJSON: any): MachineOverviewList[] => {
    return machinesJSON.map((machineJSON: any): MachineOverviewList => {
      return MachineOverviewListHelper.fromJSON(machineJSON);
    });
  };

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get<PropertyValue[]>(`${this.backendConfiguration.getMachineEndpoint()}machines/custom-settings`);
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machines/custom-settings/already-adjusted`).pipe(
      rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getMachineEndpoint()}machines/custom-settings`, chosenSettings);
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getMachineEndpoint()}machines/custom-settings/name-generation-properties`);
  }

  public generateName(item: Equipment): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getMachineEndpoint()}machines/custom-settings/generate-name`, item.toJSON());
  }
}
