import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LabelModule} from '../ellipsis-label/label.module';
import {ProgressBarComponent} from './progress-bar.component';

@NgModule({
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
  imports: [CommonModule, LabelModule, MatTooltipModule]
})
export class ProgressBarModule {}
