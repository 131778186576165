import {Directive, DoCheck, Host, Input, Optional, SkipSelf, TemplateRef, ViewContainerRef} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {canShowErrorForErrorCodeAndFormControlForFormGroup} from '../../common/validators/can-show-error-for-error-code-and-form-control-for-form-group';

@Directive({
  selector: '[vdwCanShowErrorForFormControl]'
})
export class CanShowErrorForFormControlDirective implements DoCheck {
  @Input('vdwCanShowErrorForFormControl') public formControlName: string;
  @Input('vdwCanShowErrorForFormControlError') public error: string;

  private templateRef: TemplateRef<any>;
  private viewContainer: ViewContainerRef;
  private isHidden = true;

  public constructor(
    @Optional() @Host() @SkipSelf() private parent: FormGroupDirective,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef
  ) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
  }

  public ngDoCheck(): void {
    if (AssertionUtils.isNullOrUndefined(this.parent) || AssertionUtils.isNullOrUndefined(this.error) || AssertionUtils.isNullOrUndefined(this.formControlName)) {
      // skip evaluation
      return;
    }
    if (canShowErrorForErrorCodeAndFormControlForFormGroup(this.error, this.formControlName, this.parent.control)) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }
}
