import {getTotalRunningTimeInMsForDateRange} from '@application/helper/planning/get-total-running-time-in-milliseconds-for-date-range';
import {DateRange} from '@domain/date-range';
import {Subscription} from '@domain/profile/subscription';
import {Color, moment, Pattern, TimezoneUtils} from '@vdw/angular-component-library';

export class MachineEventDeclaration {
  private _id: number;
  private _abbreviation: string;
  private _startDate: Date;
  private _endDate: Date;
  private _color: Color;
  private _pattern: Pattern;

  public constructor(id: number, abbreviation: string, startDate: Date, endDate: Date, color: Color, pattern: Pattern) {
    this._id = id;
    this._abbreviation = abbreviation;
    this._startDate = startDate;
    this._endDate = endDate;
    this._color = color;
    this._pattern = pattern;
  }

  public get id(): number {
    return this._id;
  }

  public get abbreviation(): string {
    return this._abbreviation;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public get color(): Color {
    return this._color;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public static fromJSON(declarationJSON: any, subscription: Subscription): MachineEventDeclaration {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(declarationJSON.startDate, subscription.timeZone.code);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(declarationJSON.endDate, subscription.timeZone.code);

    return new MachineEventDeclaration(declarationJSON.id, declarationJSON.abbreviation, startDate, endDate, Color.fromJSON(declarationJSON.color), declarationJSON.patternId as number);
  }

  public getDuration(): number {
    return moment.range(this.startDate, this.endDate).diff();
  }

  public getTotalRunningTimeInMsForDateRange(dateRange: DateRange): number {
    return getTotalRunningTimeInMsForDateRange<MachineEventDeclaration>(this, dateRange);
  }
}
