export interface PropertyValue {
  propertyName: string;
  possiblePropertyValues?: any[];
  propertyValue?: any;
  headerName?: string;
}

export function getProperty(listOfCustomSettings: PropertyValue[], propertyName: string): PropertyValue {
  return listOfCustomSettings.find((propertyValue: PropertyValue) => propertyValue.propertyName === propertyName);
}

export function getPropertyValue(listOfCustomSettings: PropertyValue[], propertyName: string): any {
  return listOfCustomSettings.find((customSetting: PropertyValue) => customSetting.propertyName === propertyName)?.propertyValue;
}

export function getPossiblePropertyValues(listOfCustomSettings: PropertyValue[], propertyName: string): any {
  return listOfCustomSettings.find((customSetting: PropertyValue) => customSetting.propertyName === propertyName)?.possiblePropertyValues;
}
