import {animate, AnimationTriggerMetadata, sequence, state, style, transition, trigger} from '@angular/animations';

export enum ToastAnimationState {
  DEFAULT = 'default',
  CLOSING = 'closing'
}

export function slideDownUpAnimation(): AnimationTriggerMetadata {
  const marginTop = (pixels: number): any => {
    return {'margin-top': `${pixels}px`};
  };
  const timings = '250ms ease';
  return trigger('slideDownUpAnimation', [
    state(ToastAnimationState.DEFAULT, style(marginTop(8))),
    transition('void => *', [style(marginTop(-58)), sequence([animate(timings, style(marginTop(16))), animate(timings, style(marginTop(8)))])]),
    transition(`${ToastAnimationState.DEFAULT} => ${ToastAnimationState.CLOSING}`, [style(marginTop(8)), animate(timings, style(marginTop(-58)))])
  ]);
}
