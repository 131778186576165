export enum DialogType {
  INFORMATION = 'INFORMATION',
  ERROR = 'ERROR',
  CONFIRM = 'CONFIRM',
  DISCARD = 'DISCARD',
  DISCARD_AND_SAVE = 'DISCARD_AND_SAVE',
  DELETE = 'DELETE',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  REPORT = 'REPORT',
  OVERRIDE = 'OVERRIDE'
}
