import {MaintenanceType} from './maintenance-type';
import {PlanningItem} from './planning-item';
import {PlanningItemProperties} from './planning-item-properties';
import {PlanningItemType} from './planning-item-type';

export class Maintenance extends PlanningItem {
  private _maintenanceType: MaintenanceType;

  public constructor(itemProperties: PlanningItemProperties, maintenanceType: MaintenanceType, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.MAINTENANCE, ...planningItems);
    this._maintenanceType = maintenanceType;
  }

  public get maintenanceType(): MaintenanceType {
    return this._maintenanceType;
  }

  public set maintenanceType(value: MaintenanceType) {
    this._maintenanceType = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      maintenanceType: this.maintenanceType
    } as any as JSON;
  }
}
