import {LabelPositionInCarpet} from '../finishing/label-position-in-carpet.enum';
import {LabelPositionInLabelPlace} from '../label-position-in-label-place.enum';

export class FinishingTemplatePositionInCarpet {
  private _positioningInCarpet: LabelPositionInCarpet;
  private _positioningInLabelPlace: LabelPositionInLabelPlace;
  private _commercialHorizontalOffsetInMM: number;
  private _commercialVerticalOffsetInMM: number;

  public constructor(positioningInCarpet: LabelPositionInCarpet, positioningInLabelPlace: LabelPositionInLabelPlace, commercialHorizontalOffsetInMM: number, commercialVerticalOffsetInMm: number) {
    this._positioningInCarpet = positioningInCarpet;
    this._positioningInLabelPlace = positioningInLabelPlace;
    this._commercialHorizontalOffsetInMM = commercialHorizontalOffsetInMM;
    this._commercialVerticalOffsetInMM = commercialVerticalOffsetInMm;
  }

  public get positioningInCarpet(): LabelPositionInCarpet {
    return this._positioningInCarpet;
  }

  public get positioningInLabelPlace(): LabelPositionInLabelPlace {
    return this._positioningInLabelPlace;
  }

  public get commercialHorizontalOffsetInMM(): number {
    return this._commercialHorizontalOffsetInMM;
  }

  public get commercialVerticalOffsetInMM(): number {
    return this._commercialVerticalOffsetInMM;
  }

  public static fromJSON(finishingPositionInCarpetJSON: any): FinishingTemplatePositionInCarpet {
    return new FinishingTemplatePositionInCarpet(
      finishingPositionInCarpetJSON.positioningInCarpet as LabelPositionInCarpet,
      finishingPositionInCarpetJSON.positioningInLabelPlace as LabelPositionInLabelPlace,
      finishingPositionInCarpetJSON.commercialHorizontalOffsetInMM,
      finishingPositionInCarpetJSON.commercialVerticalOffsetInMM
    );
  }

  public toJSON(): JSON {
    return {
      positioningInCarpet: this.positioningInCarpet,
      positioningInLabelPlace: this.positioningInLabelPlace,
      commercialHorizontalOffsetInMM: this.commercialHorizontalOffsetInMM,
      commercialVerticalOffsetInMM: this.commercialVerticalOffsetInMM
    } as any as JSON;
  }
}
