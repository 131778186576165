import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {YarnSet} from '@domain/textile-data/yarn-set/yarn-set';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {isNil} from 'lodash-es';

export class ColoredYarnSet extends OverviewListColoredYarnSet {
  private readonly _colorSet: ColorSet;
  private readonly _yarnSet: YarnSet;
  private _alternativeColoredYarnSetIds: number[];
  private _technicalCode: string;

  public constructor(id: number, name: string, colorSet: ColorSet, yarnSet: YarnSet, used: boolean, technicalCode: string, alternativeColoredYarnSetIds: number[] = []) {
    const overviewListColorSet: OverviewListColorSet = !isNil(colorSet) ? OverviewListColorSet.fromColorSet(colorSet) : null;
    const overviewListYarnSet: OverviewListYarnSet = !isNil(yarnSet) ? OverviewListYarnSet.fromYarnSet(yarnSet) : null;

    super(id, name, overviewListColorSet, overviewListYarnSet, used);
    this._colorSet = colorSet;
    this._yarnSet = yarnSet;

    this._alternativeColoredYarnSetIds = alternativeColoredYarnSetIds;
    this._technicalCode = technicalCode;
  }

  public get colorSet(): ColorSet {
    return this._colorSet;
  }

  public get yarnSet(): YarnSet {
    return this._yarnSet;
  }

  public get alternativeColoredYarnSetIds(): number[] {
    return this._alternativeColoredYarnSetIds;
  }

  public set alternativeColoredYarnSetIds(value: number[]) {
    this._alternativeColoredYarnSetIds = value;
  }

  public get technicalCode(): string {
    return this._technicalCode;
  }

  public static createEmptyColoredYarnSet(): ColoredYarnSet {
    return new ColoredYarnSet(null, null, null, null, null, null, []);
  }

  public static fromJSON(coloredYarnSetJSON: any): ColoredYarnSet {
    return new ColoredYarnSet(
      coloredYarnSetJSON.id,
      coloredYarnSetJSON.name,
      ColorSet.fromJSON(coloredYarnSetJSON.colorSet),
      YarnSet.fromJSON(coloredYarnSetJSON.yarnSet),
      coloredYarnSetJSON.used,
      coloredYarnSetJSON.technicalCode,
      coloredYarnSetJSON.alternativeColoredYarnSetIds
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      colorSetId: this.colorSet.id,
      yarnSetId: this.yarnSet.id,
      alternativeColoredYarnSetIds: this._alternativeColoredYarnSetIds,
      technicalCode: this._technicalCode
    } as any as JSON;
  }
}
