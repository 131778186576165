import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {LabelModule} from '../ellipsis-label/label.module';
import {MessageContainerComponent} from './message-container.component';

@NgModule({
  declarations: [MessageContainerComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule, LabelModule],
  exports: [MessageContainerComponent]
})
export class MessageContainerModule {}
