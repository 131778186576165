import {Component, OnInit} from '@angular/core';
import {TranslateService} from '../../../translation/translate.service';
import {BaseOverlayComponent} from '../base-overlay.component';
import {OverlayActionsService} from '../overlay-actions.service';

@Component({
  selector: 'vdw-overlay',
  templateUrl: '../base-overlay.component.html',
  styleUrls: ['../base-overlay.component.scss']
})
export class OverlayComponent extends BaseOverlayComponent implements OnInit {
  public constructor(translate: TranslateService, actionsService: OverlayActionsService) {
    super(actionsService, translate);
  }

  public ngOnInit(): void {
    this.image = '/assets/icons/illustrations/unauthorized.svg';
    this.initializeOverlayComponent(this.createParamsObject());
  }
}
