// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export class ColorConstants {
    public static readonly NEUTRAL_BLACK: string = '#1b1d22';
    public static readonly NEUTRAL_200: string = '#454a54';
    public static readonly NEUTRAL_300: string = '#585f6d';
    public static readonly NEUTRAL_400: string = '#666f7d';
    public static readonly NEUTRAL_500: string = '#7d8494';
    public static readonly NEUTRAL_600: string = '#959ca9';
    public static readonly NEUTRAL_700: string = '#b5b9c2';
    public static readonly NEUTRAL_800: string = '#d7d9de';
    public static readonly NEUTRAL_850: string = '#ebecf0';
    public static readonly NEUTRAL_900: string = '#f5f6f8';
    public static readonly NEUTRAL_950: string = '#fbfbfc';
    public static readonly NEUTRAL_WHITE: string = '#ffffff';
    public static readonly TINT_NEUTRAL_BLACK_15_TRANSPARENT: string = 'rgba(27, 29, 34, 0.15)';
    public static readonly TINT_NEUTRAL_BLACK_20_TRANSPARENT: string = 'rgba(27, 29, 34, 0.2)';
    public static readonly TINT_NEUTRAL_BLACK_25_TRANSPARENT: string = 'rgba(27, 29, 34, 0.25)';
    public static readonly TINT_NEUTRAL_BLACK_30_TRANSPARENT: string = 'rgba(27, 29, 34, 0.3)';
    public static readonly TINT_NEUTRAL_950_80_TRANSPARENT: string = 'rgba(251, 251, 252, 0.8)';
    public static readonly PRIMARY_300: string = '#0055cc';
    public static readonly PRIMARY_400: string = '#0c66e4';
    public static readonly PRIMARY_500: string = '#1d7afc';
    public static readonly PRIMARY_600: string = '#579dff';
    public static readonly PRIMARY_700: string = '#9ac4ff';
    public static readonly PRIMARY_800: string = '#cce0ff';
    public static readonly PRIMARY_850: string = '#e7f0ff';
    public static readonly PRIMARY_900: string = '#f2f7ff';
    public static readonly PRIMARY_AQUA_200: string = '#206a83';
    public static readonly PRIMARY_AQUA_400: string = '#2898bd';
    public static readonly PRIMARY_AQUA_500: string = '#42b2d7';
    public static readonly PRIMARY_AQUA_600: string = '#6cc3e0';
    public static readonly PRIMARY_AQUA_800: string = '#c6edfb';
    public static readonly PRIMARY_AQUA_900: string = '#edfbff';
    public static readonly PRIMARY_TEAL_200: string = '#216e4e';
    public static readonly PRIMARY_TEAL_400: string = '#22a06b';
    public static readonly PRIMARY_TEAL_500: string = '#2abb7f';
    public static readonly PRIMARY_TEAL_600: string = '#4bce97';
    public static readonly PRIMARY_TEAL_800: string = '#baf3db';
    public static readonly PRIMARY_TEAL_900: string = '#e0fff3';
    public static readonly PRIMARY_YELLOW_200: string = '#a16207';
    public static readonly PRIMARY_YELLOW_400: string = '#dca908';
    public static readonly PRIMARY_YELLOW_500: string = '#f0c82b';
    public static readonly PRIMARY_YELLOW_600: string = '#fde047';
    public static readonly PRIMARY_YELLOW_700: string = '#fef08a';
    public static readonly PRIMARY_YELLOW_800: string = '#fef9c3';
    public static readonly PRIMARY_YELLOW_850: string = '#fefbd9';
    public static readonly PRIMARY_YELLOW_900: string = '#fffded';
    public static readonly PRIMARY_PINK_200: string = '#943d73';
    public static readonly PRIMARY_PINK_400: string = '#cf5aa2';
    public static readonly PRIMARY_PINK_500: string = '#da62ac';
    public static readonly PRIMARY_PINK_600: string = '#e774bb';
    public static readonly PRIMARY_PINK_800: string = '#fdd0ec';
    public static readonly PRIMARY_PINK_900: string = '#fff2fa';
    public static readonly PRIMARY_PURPLE_200: string = '#6f4db2';
    public static readonly PRIMARY_PURPLE_400: string = '#9470db';
    public static readonly PRIMARY_PURPLE_500: string = '#a17ee7';
    public static readonly PRIMARY_PURPLE_600: string = '#af8fef';
    public static readonly PRIMARY_PURPLE_800: string = '#e5d8fd';
    public static readonly PRIMARY_PURPLE_900: string = '#f8f5ff';
    public static readonly PRIMARY_VIOLET_200: string = '#4d5eb2';
    public static readonly PRIMARY_VIOLET_400: string = '#7082db';
    public static readonly PRIMARY_VIOLET_500: string = '#7f90e7';
    public static readonly PRIMARY_VIOLET_600: string = '#8f9fef';
    public static readonly PRIMARY_VIOLET_800: string = '#d8defd';
    public static readonly PRIMARY_VIOLET_900: string = '#f5f6ff';
    public static readonly SEMANTIC_ERROR_200: string = '#ae2424';
    public static readonly SEMANTIC_ERROR_300: string = '#c92c2c';
    public static readonly SEMANTIC_ERROR_400: string = '#db3b3b';
    public static readonly SEMANTIC_ERROR_500: string = '#f15050';
    public static readonly SEMANTIC_ERROR_600: string = '#f86868';
    public static readonly SEMANTIC_ERROR_700: string = '#fd9191';
    public static readonly SEMANTIC_ERROR_800: string = '#ffd2d2';
    public static readonly SEMANTIC_ERROR_850: string = '#ffe4e4';
    public static readonly SEMANTIC_ERROR_900: string = '#fff5f5';
    public static readonly SEMANTIC_WARNING_200: string = '#a55300';
    public static readonly SEMANTIC_WARNING_400: string = '#da740f';
    public static readonly SEMANTIC_WARNING_500: string = '#f3993f';
    public static readonly SEMANTIC_WARNING_600: string = '#feb062';
    public static readonly SEMANTIC_WARNING_800: string = '#fee2c8';
    public static readonly SEMANTIC_WARNING_900: string = '#fff6ed';
    public static readonly SEMANTIC_SUCCESS_200: string = '#4c6b1f';
    public static readonly SEMANTIC_SUCCESS_400: string = '#6a9a23';
    public static readonly SEMANTIC_SUCCESS_500: string = '#82b536';
    public static readonly SEMANTIC_SUCCESS_600: string = '#94c748';
    public static readonly SEMANTIC_SUCCESS_800: string = '#d3f1a7';
    public static readonly SEMANTIC_SUCCESS_900: string = '#effcd9';
    public static readonly PRIMARY_COLOR_DARK_BLUE: string = '#13296d';
    public static readonly PRIMARY_COLOR_VDW_BLUE: string = '#2b4ec0';
    public static readonly PRIMARY_COLOR_HIGHLIGHT_BLUE: string = '#4269e2';
    public static readonly PRIMARY_COLOR_LIGHTBLUE: string = '#9dc1ff';
    public static readonly PRIMARY_COLOR_VDW_PINK: string = '#e0669e';
    public static readonly PRIMARY_COLOR_GREY: string = '#737b92';
    public static readonly PRIMARY_COLOR_WHITE: string = '#ffffff';
    public static readonly PRIMARY_COLOR_BLACK: string = '#101a43';
    public static readonly PRIMARY_COLOR_JUTE: string = '#dc895b';
    public static readonly PRIMARY_COLOR_JUTE_NEW: string = '#c26100';
    public static readonly FUNCTIONAL_COLOR_RED: string = '#fa3d3d';
    public static readonly FUNCTIONAL_COLOR_ORANGE: string = '#fa8b3d';
    public static readonly FUNCTIONAL_COLOR_GREEN: string = '#0fba4c';
    public static readonly FUNCTIONAL_COLOR_SAT_PURPLE: string = '#7506e5';
    public static readonly FUNCTIONAL_COLOR_SAT_PINK: string = '#f07';
    public static readonly FUNCTIONAL_COLOR_SAT_TURQUOISE: string = '#019db8';
    public static readonly FUNCTIONAL_COLOR_SAT_BROWN: string = '#86492c';
    public static readonly PRIMARY_COLOR_VDW_GRADIENT: string = 'linear-gradient(221.58deg, #2b4ec0 14.25%, #294ab7 25.87%, #1e388c 85.4%)';
    public static readonly TINT_DARKBLUE_70: string = '#5a6a9a';
    public static readonly TINT_DARKBLUE_70_TRANSPARENT: string = 'rgba(19, 41, 109, 0.7)';
    public static readonly TINT_DARKBLUE_60: string = '#717fa7';
    public static readonly TINT_DARKBLUE_50: string = '#8a94b7';
    public static readonly TINT_DARKBLUE_50_TRANSPARENT: string = 'rgba(19, 41, 109, 0.5)';
    public static readonly TINT_DARKBLUE_30: string = '#b9bfd4';
    public static readonly TINT_DARKBLUE_30_TRANSPARENT: string = 'rgba(19, 41, 109, 0.3)';
    public static readonly TINT_DARKBLUE_20: string = '#d0d4e2';
    public static readonly TINT_DARKBLUE_20_TRANSPARENT: string = 'rgba(19, 41, 109, 0.2)';
    public static readonly TINT_DARKBLUE_13_TRANSPARENT: string = 'rgba(19, 41, 109, 0.13)';
    public static readonly TINT_DARKBLUE_10_TRANSPARENT: string = 'rgba(19, 41, 109, 0.1)';
    public static readonly TINT_DARKBLUE_10: string = '#e8eaf1';
    public static readonly TINT_DARKBLUE_6: string = '#f1f2f6';
    public static readonly TINT_DARKBLUE_6_TRANSPARENT: string = 'rgba(19, 41, 109, 0.06)';
    public static readonly TINT_DARKBLUE_2: string = '#fafbfc';
    public static readonly TINT_DARKBLUE_2_TRANSPARENT: string = 'rgba(19, 41, 109, 0.02)';
    public static readonly TINT_VDW_BLUE_HOVER: string = '#223e9a';
    public static readonly TINT_VDW_BLUE_70: string = '#6b84d3';
    public static readonly TINT_VDW_BLUE_70_TRANSPARENT: string = 'rgba(43, 78, 192, 0.7)';
    public static readonly TINT_VDW_BLUE_60: string = '#8095d9';
    public static readonly TINT_VDW_BLUE_50: string = '#95a7e0';
    public static readonly TINT_VDW_BLUE_40_TRANSPARENT: string = 'rgba(43, 78, 192, 0.4)';
    public static readonly TINT_VDW_BLUE_30: string = '#c0caed';
    public static readonly TINT_VDW_BLUE_30_TRANSPARENT: string = 'rgba(43, 78, 192, 0.3)';
    public static readonly TINT_VDW_BLUE_20: string = '#d5dcf2';
    public static readonly TINT_VDW_BLUE_20_TRANSPARENT: string = 'rgba(43, 78, 192, 0.2)';
    public static readonly TINT_VDW_BLUE_10: string = '#eaeef9';
    public static readonly TINT_VDW_BLUE_10_TRANSPARENT: string = 'rgba(43, 78, 192, 0.1)';
    public static readonly TINT_VDW_BLUE_6: string = '#f3f5fc';
    public static readonly TINT_VDW_BLUE_6_TRANSPARENT: string = 'rgba(43, 78, 192, 0.06)';
    public static readonly TINT_BLACK_50: string = '#888da1';
    public static readonly TINT_BLACK_50_TRANSPARENT: string = 'rgba(16, 26, 67, 0.5)';
    public static readonly TINT_BLACK_40_TRANSPARENT: string = 'rgba(16, 26, 67, 0.4)';
    public static readonly TINT_BLACK_30_TRANSPARENT: string = 'rgba(16, 26, 67, 0.3)';
    public static readonly TINT_BLACK_20: string = '#cfd1d9';
    public static readonly TINT_BLACK_20_TRANSPARENT: string = 'rgba(16, 26, 67, 0.2)';
    public static readonly TINT_BLACK_10_TRANSPARENT: string = 'rgba(16, 26, 67, 0.1)';
    public static readonly TINT_LIGHTBLUE_70: string = '#bbd4ff';
    public static readonly TINT_LIGHTBLUE_40_TRANSPARENT: string = 'rgba(157, 193, 255, 0.4)';
    public static readonly TINT_LIGHTBLUE_30: string = '#e2edff';
    public static readonly TINT_LIGHTBLUE_30_TRANSPARENT: string = 'rgba(157, 193, 255, 0.3)';
    public static readonly TINT_LIGHTBLUE_20: string = '#ebf3ff';
    public static readonly TINT_LIGHTBLUE_20_TRANSPARENT: string = 'rgba(157, 193, 255, 0.2)';
    public static readonly TINT_LIGHTBLUE_10: string = '#f6f9ff';
    public static readonly TINT_LIGHTBLUE_10_TRANSPARENT: string = 'rgba(157, 193, 255, 0.1)';
    public static readonly TINT_LIGHTBLUE_6: string = '#f9fcff';
    public static readonly TINT_RED_70: string = '#fc7777';
    public static readonly TINT_RED_70_TRANSPARENT: string = 'rgba(250, 61, 61, 0.7)';
    public static readonly TINT_RED_50: string = '#fd9e9e';
    public static readonly TINT_RED_50_TRANSPARENT: string = 'rgba(250, 61, 61, 0.5)';
    public static readonly TINT_RED_30: string = '#fec5c5';
    public static readonly TINT_RED_30_TRANSPARENT: string = 'rgba(250, 61, 61, 0.3)';
    public static readonly TINT_RED_20: string = '#fed8d8';
    public static readonly TINT_RED_20_TRANSPARENT: string = 'rgba(250, 61, 61, 0.2)';
    public static readonly TINT_RED_10: string = '#ffecec';
    public static readonly TINT_RED_10_TRANSPARENT: string = 'rgba(250, 61, 61, 0.1)';
    public static readonly TINT_RED_6: string = '#fff4f4';
    public static readonly TINT_RED_6_TRANSPARENT: string = 'rgba(250, 61, 61, 0.06)';
    public static readonly TINT_ORANGE_70: string = '#fcae78';
    public static readonly TINT_ORANGE_50: string = '#fdc59e';
    public static readonly TINT_ORANGE_20: string = '#fee8d8';
    public static readonly TINT_ORANGE_20_TRANSPARENT: string = 'rgba(250, 139, 61, 0.2)';
    public static readonly TINT_ORANGE_10: string = '#fff4ec';
    public static readonly TINT_ORANGE_10_TRANSPARENT: string = 'rgba(250, 139, 61, 0.1)';
    public static readonly TINT_ORANGE_6: string = '#fff8f4';
    public static readonly TINT_GREEN_70: string = '#57cf85';
    public static readonly TINT_GREEN_50: string = '#87dda6';
    public static readonly TINT_GREEN_20: string = '#cff1db';
    public static readonly TINT_GREEN_20_TRANSPARENT: string = 'rgba(15, 186, 76, 0.2)';
    public static readonly TINT_GREEN_10: string = '#e7f9ee';
    public static readonly TINT_GREEN_6: string = '#f1fbf4';
    public static readonly TINT_PINK_70: string = '#ea94bc';
    public static readonly TINT_PINK_50: string = '#f0b3cf';
    public static readonly TINT_PINK_20: string = '#f9e0ec';
    public static readonly TINT_PINK_10: string = '#fcf0f6';
    public static readonly TINT_PINK_6: string = '#fdf6f9';
    public static readonly PLANNING_SOFT_ORANGE: string = '#ffcab9';
    public static readonly PLANNING_SOFT_YELLOW: string = '#fcecc5';
    public static readonly PLANNING_SKY_BLUE: string = '#9ae1ff';
    public static readonly PLANNING_BRIGHT_SKY_BLUE: string = '#b2f4f8';
    public static readonly PLANNING_BABY_BLUE: string = '#e2f5ff';
    public static readonly PLANNING_BRIGHT_PINK: string = '#f2aefe';
    public static readonly PLANNING_SOFT_PINK: string = '#fcceec';
    public static readonly PLANNING_SOFT_PURPLE: string = '#ded7f3';
    public static readonly PLANNING_SOFT_BLUE: string = '#c4cded';
    public static readonly PLANNING_SOFT_GREY: string = '#e7e6ee';
    public static readonly TINT_WHITE_90_TRANSPARENT: string = 'rgba(255, 255, 255, 0.9)';
    public static readonly TINT_WHITE_7_TRANSPARENT: string = 'rgba(255, 255, 255, 0.07)';
    public static readonly TINT_JUTE_20_TRANSPARENT: string = 'rgba(220, 137, 91, 0.2)';
}
