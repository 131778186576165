<div fxLayout="row" fxFlex fxLayoutGap="16px" fxLayoutAlign="start center">
  <div fxLayout="column">
    <mat-icon class="info-icon" [svgIcon]="getIcon()"></mat-icon>
  </div>
  <div class="flex-column flex-double" *ngIf="information && showAsColumn">
    <p class="b1 font-weight-semibold">{{ message.main }}</p>
    <p class="b1">{{ information }}</p>
  </div>
  <div class="flex full-width" *ngIf="information && !showAsColumn">
    <p class="b1 font-weight-semibold">
      {{ message.main }}
      <span class="b1 font-weight-regular">{{ information }}</span>
    </p>
  </div>
  <div class="same-row" fxFlex fxLayout="column" *ngIf="!information">
    <h4 class="font-weight-semibold same-row">
      {{ message.main | bmsTranslate }} <span *ngIf="message.sub" class="font-weight-regular">{{ message.sub | bmsTranslate }}</span>
    </h4>
  </div>
  <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="end end">
    <vdw-progress-button
      [raisedButton]="false"
      [disabled]="!authorized"
      *ngIf="hasProgressButton()"
      [label]="progressButton"
      [inProgress]="inProgress"
      (progressClick)="clickButton()"
    ></vdw-progress-button>
    <button *ngIf="hasButton()" [class.mat-warning]="showWarningButton" [disabled]="!authorized" mat-stroked-button type="button" (click)="clickButton()">{{ button }}</button>
    <p *ngIf="!authorized" class="b1 hint">({{ 'GENERAL.HINTS.UNAUTHORIZED' | bmsTranslate }})</p>
  </div>
</div>
