import {JuteInformationForPart} from '@domain/production-schedule/jute-information-for-part';
import {isNil} from 'lodash-es';

export class JuteInformation {
  private _juteBefore: JuteInformationForPart;
  private _juteAfter: JuteInformationForPart;

  public constructor(juteBefore: JuteInformationForPart, juteAfter: JuteInformationForPart) {
    this._juteBefore = juteBefore;
    this._juteAfter = juteAfter;
  }

  public get juteBefore(): JuteInformationForPart {
    return this._juteBefore;
  }

  public set juteBefore(value: JuteInformationForPart) {
    this._juteBefore = value;
  }

  public get juteAfter(): JuteInformationForPart {
    return this._juteAfter;
  }

  public set juteAfter(value: JuteInformationForPart) {
    this._juteAfter = value;
  }

  public static fromJSON(juteInformationJSON: any): JuteInformation {
    let juteBeforeInformationPart: JuteInformationForPart = null;
    let juteAfterInformationPart: JuteInformationForPart = null;

    if (!isNil(juteInformationJSON)) {
      const juteBefore = juteInformationJSON.juteBefore;
      const juteAfter = juteInformationJSON.juteAfter;

      if (!isNil(juteBefore)) {
        juteBeforeInformationPart = JuteInformationForPart.fromJSON(juteBefore);
      }

      if (!isNil(juteAfter)) {
        juteAfterInformationPart = JuteInformationForPart.fromJSON(juteAfter);
      }
    }

    return new JuteInformation(juteBeforeInformationPart, juteAfterInformationPart);
  }

  public toJSON(): JSON {
    return {
      juteBefore: !isNil(this.juteBefore) ? this.juteBefore.toJSON() : null,
      juteAfter: !isNil(this.juteAfter) ? this.juteAfter.toJSON() : null
    } as any as JSON;
  }
}
