<vdw-tabs-menu-card icon="planning-blue" [title]="'PLANNING.PLANNING' | bmsTranslate" (saveEvent)="saveChanges()" [saving]="saving">
  <vdw-date-range-picker (dateRangeChanged)="dateRangeChanged($event)"></vdw-date-range-picker>
  <vdw-dialog-button #machineFilterSettingsButton [componentType]="FILTER_SETTINGS_COMPONENT">
    <button
      type="button"
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      (click)="machineFilterSettingsButton.toggleDialog()"
      [disabled]="updatedPlanningLines.length > 0"
      [matTooltip]="'AGGRID.FILTEROOO' | bmsTranslate"
    >
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </vdw-dialog-button>

  <button type="button" mat-icon-button class="mat-icon-action-button-lg-with-border needs-attention" (click)="openToPlan()" [matTooltip]="'PLANNING.NEEDS_ATTENTION.TITLE' | bmsTranslate">
    <mat-icon svgIcon="warning"></mat-icon>
  </button>
  @if (canShowFixedSchedules()) {
    <button
      type="button"
      mat-icon-button
      class="mat-icon-action-button-lg-with-border fixed-schedules"
      (click)="openFixedSchedules()"
      [matTooltip]="'TEXTILE_DATA.FIXED_ORDER.FIXED_ORDER' | bmsTranslate: {count: 2}"
    >
      <mat-icon svgIcon="schedule-24px"></mat-icon>
    </button>
  }
</vdw-tabs-menu-card>

<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav mode="over" position="end" [autoFocus]="false">
    <app-planning-prototype-sidebar></app-planning-prototype-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card class="planning-container">
      @if (loading) {
        <vdw-loading-overlay [hideDescription]="false" [scale]="1"></vdw-loading-overlay>
      }
      <vdw-planning-display>
        <vdw-planning-grid [startDate]="startDate" [endDate]="endDate" [rowTitle]="'MACHINE.MACHINE' | bmsTranslate: {count: 2}" (gridSelected)="openNewItemForm($event)">
          @for (regime of regimes; track $index) {
            <vdw-shift-schedule [days]="regime.days">
              @for (shift of regime.shifts; track $index) {
                <vdw-shift [name]="getShiftName(shift, regime)" [start]="shift.shiftStart" [end]="shift.shiftEnd"></vdw-shift>
              }
            </vdw-shift-schedule>
          }
          @for (machine of machines; track machine.id) {
            <vdw-planning-row [indicatorText]="machine.name" [icon]="getIconForMachineType(machine.equipmentKind)" (selected)="selectMachine(machine)" [dataTransfer]="machine" [clickable]="true">
              @for (item of getPlanningItemsForMachine(machine); track item.draftId) {
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
              }

              @for (item of getHistoryForMachine(machine); track item.draftId) {
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
              }

              @if (getItemCreationPlaceholder(machine); as item) {
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
              }

              <ng-template #itemTemplate let-item>
                @if (getPlanningItemIsGroup(item)) {
                  <vdw-planning-group
                    vdwPlanningDragDrop
                    class="run"
                    [startDate]="getTimelineStartDate(item)"
                    [endDate]="getTimelineEndDate(item)"
                    [applyCustomBorder]="true"
                    [groupText]="getPlanningItemDisplayName(item)"
                    [class.different-config]="getIsDifferentConfig(item)"
                    [clickable]="true"
                    [dataTransfer]="item"
                    [isItemDraggable]="item.isPlanned"
                    (selected)="selectItem(item)"
                    (dragStart)="onDragStart()"
                    (dragEnd)="onDragEnd($event)"
                    (dragMove)="onDragMove($event)"
                  >
                    @for (child of item.planningItems; track $index) {
                      <ng-template
                        vdwPlanningItem
                        vdwPlanningDragDrop
                        [class]="'order'"
                        [startDate]="getTimelineStartDate(child)"
                        [endDate]="getTimelineEndDate(child)"
                        [clickable]="true"
                        [dataTransfer]="child"
                        [isItemDraggable]="child.isPlanned"
                        [newGroupText]="NEW_RUN_TEXT"
                        (dragStart)="onDragStart()"
                        (dragEnd)="onDragEnd($event)"
                        (dragMove)="onDragMove($event)"
                        (selected)="selectItem(child)"
                      >
                        @if (planningItemIsFixedSchedulePlaceholder(child)) {
                          <app-fixed-schedule-placeholder [fixedSchedule]="child"></app-fixed-schedule-placeholder>
                        } @else {
                          <app-planning-order
                            [order]="child"
                            [class.past-due]="getOrderIsPastDue(child)"
                            [class.completed]="child.isCompleted"
                            [class.active]="child.isActive"
                            [class.planned]="child.isPlanned"
                          ></app-planning-order>
                        }
                      </ng-template>
                    }
                  </vdw-planning-group>
                } @else {
                  <ng-template
                    vdwPlanningItem
                    vdwPlanningDragDrop
                    [class]="'maintenance'"
                    [startDate]="getTimelineStartDate(item)"
                    [endDate]="getTimelineEndDate(item)"
                    [applyCustomBorder]="true"
                    [clickable]="true"
                    [dataTransfer]="item"
                    [isItemDraggable]="item.isPlanned"
                    (selected)="selectItem(item)"
                    (dragStart)="onDragStart()"
                    (dragEnd)="onDragEnd($event)"
                    (dragMove)="onDragMove($event)"
                  >
                    <app-maintenance [maintenance]="item"></app-maintenance>
                  </ng-template>
                }
              </ng-template>
            </vdw-planning-row>
          }
        </vdw-planning-grid>
      </vdw-planning-display>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
