import {Permission} from '@domain/profile/permission.enum';
import {isEqual, map, snakeCase, some, toUpper} from 'lodash-es';

export class RoleInfo {
  private readonly _role: string;
  private readonly _permissions: Permission[];

  public constructor(role: string, permissions: Permission[]) {
    this._role = role;
    this._permissions = permissions;
  }

  public get role(): string {
    return this._role;
  }

  public get permissions(): Permission[] {
    return this._permissions;
  }

  public static fromJSON(roleInfoJSON: any): RoleInfo {
    return new RoleInfo(
      roleInfoJSON.role,
      map(roleInfoJSON.permissions, (permission: any) => Permission[toUpper(snakeCase(permission as string))])
    );
  }

  public toJSON(): JSON {
    return {
      role: this._role,
      permissions: map(this._permissions, (permission: any) => Permission[permission])
    } as any as JSON;
  }

  public hasPermission(permissionToCheck: Permission): boolean {
    return some(this._permissions, (permission: Permission) => isEqual(permissionToCheck, permission));
  }
}
