import {Injectable} from '@angular/core';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {BackendErrorContext} from '@application/helper/backend-error-context';
import {Entity} from '@application/helper/entity.enum';
import {AssertionUtils, TranslateService} from '@vdw/angular-component-library';
import {defaultTo, join, lowerCase, size, startCase} from 'lodash-es';
import {forkJoin, Observable, throwError} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class ErrorMessageHelper {
  private translate: TranslateService;

  private static readonly ENTITY_TRANSLATION_KEYS: Record<Entity, string> = {
    MACHINE_QUALITY: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY',
    COLOR_SET: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
    COLOR: 'TEXTILE_DATA.COLOR.COLOR',
    YARN_SET: 'TEXTILE_DATA.YARN_SET.YARN_SET',
    YARN_TYPE: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE',
    COLORED_YARN_SET: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET',
    CREEL: 'TEXTILE_DATA.CREEL.CREEL',
    FINISHING: 'TEXTILE_DATA.FINISHING.FINISHING',
    FINISHING_TEMPLATE: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE',
    MACHINE: 'MACHINE.MACHINE',
    DESIGN: 'DESIGN_LIBRARY.DESIGN',
    CUSTOM_SETTING: 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS',
    WEAVE_STRUCTURE: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
    COMPANY: 'GENERAL.COMPANY',
    PRODUCTION_ORDER: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
    PRODUCTION_ORDER_LITE: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
    RUN: 'PLANNING.ADD_ITEM.TYPES.RUN',
    STOP_REASON_TEXT: 'BMSCONFIG.STOP_REASON_CATALOG',
    DRAWING_SHAPE: 'DESIGN_LIBRARY.SETTINGS.DESIGN_SHAPES',
    WEFT_COLOR: 'TEXTILE_DATA.WEFT_COLOR',
    ORDER_LINE: 'SALES_ORDERS.ORDER_LINES.ORDER_LINE',
    ARTICLE: 'ARTICLES.ARTICLE',
    DATA_UNIT_SETUP: 'BMSCONFIG.DATA_UNIT_SETUP.DATA_UNIT_SETUP',
    DATA_SOURCE: 'BMSCONFIG.MACHINE_DATA_SOURCES.MACHINE_DATA_SOURCES',
    CHORE: 'TEXTILE_DATA.COLOR.COLOR',
    CUSTOMER: 'CUSTOMERS.CUSTOMER',
    SALES_ORDER: 'SALES_ORDERS.SALES_ORDER',
    WEAVE_PRODUCT: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT',
    AUTOMATIC_STOP_GROUPS: 'BMSCONFIG.AUTOMATIC_STOP_GROUPS.CARD_TITLE',
    OPERATOR: 'MACHINE.OPERATORS.OPERATOR',
    OPERATOR_TYPE: 'MACHINE.OPERATOR_TYPES.OPERATOR_TYPE',
    PHYSICAL_QUANTITY: 'BMSCONFIG.PHYSICAL_QUANTITIES.PHYSICAL_QUANTITY',
    RAW_MATERIAL: 'TEXTILE_DATA.YARN_TYPE.RAW_MATERIAL',
    NO_COMPANY_OWNED_WEAVE_STRUCTURE: 'TEXTILE_DATA.WEAVE_STRUCTURE.NO_COMPANY_OWNED_WEAVE_STRUCTURE',
    PRODUCTION_SCHEDULE_PATH: 'PRODUCTION_ORDER.PRODUCTION_SCHEDULE_PATHS',
    START_DENT: 'PRODUCTION_ORDER.START_DENT',
    WIDTH_IN_DENTS: 'PRODUCTION_ORDER.WIDTH_IN_DENTS',
    REED_DENSITY: 'GENERAL.ADVANCED_SEARCH.REED_DENSITY',
    PICK_DENSITY: 'GENERAL.ADVANCED_SEARCH.PICK_DENSITY',
    JACQUARD_TYPE: 'MACHINE.DETAILS.TYPE_JACQUARD',
    NUM_CREEL_POSITIONS: 'MACHINE.DETAILS.NR_CREEL_POSITIONS',
    MACHINE_WIDTH: 'GENERAL.WIDTH_IN_DENTS',
    WEFT_SELECTION: 'TEXTILE_DATA.MACHINE_QUALITY.WEFT_SELECTION.TITLE',
    WEFT_SELECTION_COLUMNS: 'TEXTILE_DATA.MACHINE_QUALITY.WEFT_SELECTION.TITLE',
    HEIGHT_IN_PICKS: 'GENERAL.DIMENSIONS.HEIGHT',
    IMAGE_PROCESSING: 'GENERAL.IMAGE_PROCESSING',
    TUFT_PRODUCT: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT',
    PRODUCTION_SCHEDULE: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
    SORT_COLUMN: 'AGGRID.SORT_COLUMN'
  };

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public getErrorMessageFromBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    let result: Observable<string>;

    switch (Number(BackendErrorCodeEnum[backendErrorContext.errorCode])) {
      case BackendErrorCodeEnum.NOT_FOUND:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.NOT_FOUND', backendErrorContext);
        break;
      case BackendErrorCodeEnum.DUPLICATE_CONFIGURATION:
        result = this.getErrorMessageForDuplicateConfigurationBackendError(backendErrorContext);
        break;
      case BackendErrorCodeEnum.DUPLICATE_NAME:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.DUPLICATE_NAME', backendErrorContext);
        break;
      case BackendErrorCodeEnum.LINKED_ENTITIES:
        result = this.getErrorMessageForLinkedEntitiesBackendError(backendErrorContext);
        break;
      case BackendErrorCodeEnum.OVERLAP_REGIMES_OR_SHIFTS:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.OVERLAP_REGIMES_OR_SHIFTS', backendErrorContext);
        break;
      case BackendErrorCodeEnum.RECOLOR_MISMATCH:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.RECOLOR_MISMATCH', backendErrorContext);
        break;
      case BackendErrorCodeEnum.MISSING_COLORS:
        result = this.getErrorMessageForMissingColorsBackendError(backendErrorContext);
        break;
      case BackendErrorCodeEnum.ALTERNATIVES:
        result = this.translate.get('GENERAL.ERRORS.BACKEND.ALTERNATIVES');
        break;
      case BackendErrorCodeEnum.LOOM_MAP_CHORE_MISMATCH:
        result = this.getErrorMessageForLoomMapWithMissingPatterns(backendErrorContext);
        break;
      case BackendErrorCodeEnum.INVALID_NAME:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.INVALID_NAME', backendErrorContext);
        break;
      case BackendErrorCodeEnum.INVALID_VALUE:
        result = this.getErrorMessageForInvalidValue(backendErrorContext);
        break;
      case BackendErrorCodeEnum.OVERLAPPING_ENTITIES:
        result = this.getErrorMessageForOverlappingEntities(backendErrorContext);
        break;
      case BackendErrorCodeEnum.MISSING_PROPERTY:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.MISSING_PROPERTY', backendErrorContext);
        break;
      case BackendErrorCodeEnum.MISMATCH_PROPERTY:
        result = this.getErrorMessageForMisMatchProperty(backendErrorContext);
        break;
      case BackendErrorCodeEnum.DESIGN_MISSING_COLORS:
        result = this.getErrorMessageForMissingColorsOnDesign(backendErrorContext);
        break;
      case BackendErrorCodeEnum.DESIGN_INVALID_WEFT_SELECTION_CODES:
        result = this.getErrorMessageForInvalidWeftSelectionCodesOnDesign(backendErrorContext);
        break;
      case BackendErrorCodeEnum.EXCEPTIONS_LIST:
        result = forkJoin(backendErrorContext.list.map((backendErrorSubContext: BackendErrorContext) => this.getErrorMessageFromBackendError(backendErrorSubContext))).pipe(
          map((results: string[]) => '<p>' + results.join('</p><p>') + '</p>')
        );
        break;
      default:
        result = throwError(() => backendErrorContext);
    }
    return result;
  }

  private getErrorMessageForBackendErrorAndTranslationKey(translationKey: string, backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(this.getTranslationKeyForEntity(backendErrorContext.entity), {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get(translationKey, {
            entity: startCase(translatedEntity),
            entityName: backendErrorContext.entityName,
            entityNames: join(backendErrorContext.entityNames ?? backendErrorContext.linkedEntityNames, ', ')
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForLoomMapWithMissingPatterns(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate
      .get('GENERAL.ERRORS.BACKEND.LOOM_MAP_MISSING_PATTERN', {
        patternId: backendErrorContext.entityNames[0],
        patternType: backendErrorContext.entityNames[1],
        originalChores: backendErrorContext.entityNames[2],
        targetChores: backendErrorContext.entityNames[3]
      })
      .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
  }

  private getErrorMessageForInvalidValue(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(this.getTranslationKeyForEntity(backendErrorContext.linkedEntity), {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.INVALID_VALUE', {
            entityName: startCase(translatedEntity),
            givenValue: backendErrorContext.linkedEntityNames[0],
            range: `${backendErrorContext.linkedEntityNames[1]}, ${backendErrorContext.linkedEntityNames[2]}`
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForOverlappingEntities(backendErrorContext: BackendErrorContext): Observable<string> {
    const entityTranslationKey = this.getTranslationKeyForEntity(backendErrorContext.entity);
    const linkedEntityTranslationKey = defaultTo(this.getTranslationKeyForEntity(backendErrorContext.linkedEntity), '');

    return this.translate.get([entityTranslationKey, linkedEntityTranslationKey], {count: 1}).pipe(
      mergeMap((translations: Record<string, string>) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.OVERLAPPING_ENTITIES', {
            entity: startCase(translations[entityTranslationKey]),
            linkedEntity: lowerCase(translations[linkedEntityTranslationKey]),
            linkedEntityNames: join(backendErrorContext.linkedEntityNames, ', ')
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForDuplicateConfigurationBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(`TEXTILE_DATA.${backendErrorContext.entity}.${backendErrorContext.entity}`, {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.DUPLICATE_CONFIGURATION', {
            entity: startCase(translatedEntity),
            similarEntityName: backendErrorContext.similarEntityName
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForLinkedEntitiesBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    const entityTranslationKey = this.getTranslationKeyForEntity(backendErrorContext.entity);
    const linkedEntityTranslationKey = defaultTo(this.getTranslationKeyForEntity(backendErrorContext.linkedEntity), '');

    return this.translate.get([entityTranslationKey, linkedEntityTranslationKey], {count: 1}).pipe(
      mergeMap((translations: Record<string, string>) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.LINKED_ENTITIES', {
            entity: startCase(translations[entityTranslationKey]),
            entityName: backendErrorContext.entityName,
            linkedEntity: lowerCase(translations[linkedEntityTranslationKey]),
            linkedEntityNames: join(backendErrorContext.linkedEntityNames, ', ')
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForMisMatchProperty(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(this.getTranslationKeyForEntity(backendErrorContext.linkedEntity), {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.MISMATCH_PROPERTY', {
            entityName: startCase(translatedEntity),
            givenValue: backendErrorContext.linkedEntityNames[0],
            expectedValue: backendErrorContext.linkedEntityNames[1]
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForMissingColorsOnDesign(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate
      .get('GENERAL.ERRORS.BACKEND.DESIGN_MISSING_COLORS', {
        design: backendErrorContext.linkedEntityNames[0],
        patterns: backendErrorContext.linkedEntityNames[1]
      })
      .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
  }

  private getErrorMessageForInvalidWeftSelectionCodesOnDesign(backendErrorContext: BackendErrorContext): Observable<string> {
    if (AssertionUtils.isNullOrUndefined(backendErrorContext.linkedEntityNames)) {
      return this.translate
        .get('GENERAL.ERRORS.BACKEND.INVALID_WEFT_SELECTION_CODES', {
          codes: join(backendErrorContext.entityNames, ', ')
        })
        .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
    } else {
      return this.translate
        .get('GENERAL.ERRORS.BACKEND.DESIGN_INVALID_WEFT_SELECTION_CODES', {
          design: backendErrorContext.linkedEntityNames[0],
          codes: backendErrorContext.linkedEntityNames[1]
        })
        .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
    }
  }

  private getErrorMessageForMissingColorsBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate
      .get('GENERAL.ERRORS.BACKEND.MISSING_COLORS', {
        count: size(backendErrorContext.entityNames),
        colorNumbers: backendErrorContext.entityNames
      })
      .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
  }

  private getTranslationKeyForEntity(entity: Entity): string {
    const translationKey = ErrorMessageHelper.ENTITY_TRANSLATION_KEYS[entity];
    if (!translationKey) {
      throw new Error(`No translation key found for entity ${entity}`);
    }
    return translationKey;
  }
}
