<div
  #resizer
  vdwResizeElement
  class="resizer"
  [class.no-interaction]="isSideNavCollapsed()"
  [minResizeWidth]="SIDEBAR_MIN_WIDTH"
  [maxResizeWidth]="SIDEBAR_MAX_WIDTH"
  (resizeElement)="onResize($event)"
  (resizeElementEnd)="onResizeEnd()"
  (mouseenter)="resizeEntered()"
  (mouseleave)="resizeLeft()"
></div>
