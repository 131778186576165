import {FinishingTemplateLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-label';
import {FinishingTemplatePositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-position-in-carpet';
import {FontSettings} from '@domain/textile-data/finishing-and-finishing-template/finishing/font-settings';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {AssertionUtils} from '@vdw/angular-component-library';
import {has, isNil, map} from 'lodash-es';
import {LabelPlace} from '../label-place.enum';

export class FinishingTemplateTextLabel extends FinishingTemplateLabel {
  protected _label: string;
  private _commercialLabelHeightInMM: number;
  private _text: string;
  private _placeholderParts: PlaceholderComponent[];
  private _fontSettings: FontSettings;
  private _qrCode: boolean;

  public constructor(
    id: number,
    place: LabelPlace,
    text: string,
    placeholderParts: PlaceholderComponent[],
    fontSettings: FontSettings,
    labelHeightInMM: number,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingTemplatePositionInCarpet,
    positionInBottomCarpet: FinishingTemplatePositionInCarpet,
    qrCode: boolean = null
  ) {
    super(id, place, rotation, mirrored, positionInTopCarpet, positionInBottomCarpet);

    this._commercialLabelHeightInMM = labelHeightInMM;
    this._text = text;
    this._placeholderParts = placeholderParts;
    this._fontSettings = fontSettings;
    this._qrCode = qrCode;
    this._label = JSON.stringify(this.getLabel());
  }

  public get commercialLabelHeightInMM(): number {
    return this._commercialLabelHeightInMM;
  }

  public get text(): string {
    return this._text;
  }

  public get placeholderParts(): PlaceholderComponent[] {
    return this._placeholderParts;
  }

  public get fontSettings(): FontSettings {
    return this._fontSettings;
  }

  public get qrCode(): boolean {
    return this._qrCode;
  }

  public static fromJSON(finishingTemplateTextLabelJSON: any): FinishingTemplateTextLabel {
    return new FinishingTemplateTextLabel(
      finishingTemplateTextLabelJSON.id,
      finishingTemplateTextLabelJSON.place as LabelPlace,
      !isNil(finishingTemplateTextLabelJSON.text) ? finishingTemplateTextLabelJSON.text : null,
      !isNil(finishingTemplateTextLabelJSON.placeholderParts)
        ? map(finishingTemplateTextLabelJSON.placeholderParts, (placeholderPartJSON: any) => {
            return new PlaceholderComponent(has(placeholderPartJSON, 'id') ? placeholderPartJSON.id : null, placeholderPartJSON.name, placeholderPartJSON.maxLength);
          })
        : null,
      new FontSettings(finishingTemplateTextLabelJSON.fontFamily, finishingTemplateTextLabelJSON.fontWeight, finishingTemplateTextLabelJSON.fontStyle),
      finishingTemplateTextLabelJSON.commercialLabelHeightInMM,
      finishingTemplateTextLabelJSON.rotation,
      finishingTemplateTextLabelJSON.mirrored,
      FinishingTemplatePositionInCarpet.fromJSON(finishingTemplateTextLabelJSON.topCarpet),
      FinishingTemplatePositionInCarpet.fromJSON(finishingTemplateTextLabelJSON.bottomCarpet),
      !isNil(finishingTemplateTextLabelJSON.qrCode) ? finishingTemplateTextLabelJSON.qrCode : false
    );
  }

  public isPlaceholder(): boolean {
    return !isNil(this.placeholderParts);
  }

  public toJSON(): JSON {
    const result = this.getLabel();

    if (!AssertionUtils.isNullOrUndefined(this.id)) {
      result['id'] = this.id;
    }

    return result as any as JSON;
  }

  private getLabel(): object {
    const result = {
      finishingLabelType: 'TEXT',
      place: this.place,
      rotation: this.rotation,
      mirrored: this.mirrored,
      commercialLabelHeightInMM: this.commercialLabelHeightInMM,
      fontFamily: this.fontSettings.fontFamily,
      fontWeight: this.fontSettings.fontWeight,
      fontStyle: this.fontSettings.fontStyle,
      topCarpet: this.positionInTopCarpet.toJSON(),
      bottomCarpet: this.positionInBottomCarpet.toJSON(),
      qrCode: this.qrCode
    };

    if (this.isPlaceholder()) {
      result['placeholderParts'] = this.placeholderParts.map((placeholderPart: PlaceholderComponent) => placeholderPart.toJSON());
    } else {
      result['text'] = this.text;
    }

    return result;
  }
}
