import {IdName} from '@domain/id-name';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import moment from 'moment';
import {IdNameUnitOfMeasurement} from './id-name-unit-of-measurement';

export class PlasticProductionOrder {
  private _id: number;
  private _name: string;
  private _status: ProductionScheduleStatus;
  private _dueDate: Date;
  private _plasticProduct: IdNameUnitOfMeasurement;
  private _plannedQuantity: number;
  private _machine: IdName;
  private _tool: IdName;

  public constructor(id: number, name: string, status: ProductionScheduleStatus, dueDate: Date, plasticProduct: IdNameUnitOfMeasurement, plannedQuantity: number, machine: IdName, tool: IdName) {
    this._id = id;
    this._name = name;
    this._status = status;
    this._dueDate = dueDate;
    this._plasticProduct = plasticProduct;
    this._plannedQuantity = plannedQuantity;
    this._machine = machine;
    this._tool = tool;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public set dueDate(value: Date) {
    this._dueDate = value;
  }

  public get plasticProduct(): IdNameUnitOfMeasurement {
    return this._plasticProduct;
  }

  public set plasticProduct(value: IdNameUnitOfMeasurement) {
    this._plasticProduct = value;
  }

  public get plannedQuantity(): number {
    return this._plannedQuantity;
  }

  public set plannedQuantity(value: number) {
    this._plannedQuantity = value;
  }

  public get machine(): IdName {
    return this._machine;
  }

  public set machine(value: IdName) {
    this._machine = value;
  }

  public get tool(): IdName {
    return this._tool;
  }

  public set tool(value: IdName) {
    this._tool = value;
  }

  public static fromJSON(plasticProductionOrderJSON: any): PlasticProductionOrder {
    return new PlasticProductionOrder(
      plasticProductionOrderJSON.id,
      plasticProductionOrderJSON.name,
      plasticProductionOrderJSON.status,
      plasticProductionOrderJSON.dueDate ? new Date(plasticProductionOrderJSON.dueDate) : null,
      plasticProductionOrderJSON.plasticProduct ? IdNameUnitOfMeasurement.fromJSON(plasticProductionOrderJSON.plasticProduct) : null,
      plasticProductionOrderJSON.plannedQuantity,
      plasticProductionOrderJSON.machine ? IdName.fromJSON(plasticProductionOrderJSON.machine) : null,
      plasticProductionOrderJSON.tool ? IdName.fromJSON(plasticProductionOrderJSON.tool) : null
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      dueDate: this._dueDate ? moment(this._dueDate).startOf('day').subtract(this._dueDate.getTimezoneOffset(), 'minutes').toISOString() : null,
      plasticProduct: this._plasticProduct ? this._plasticProduct.toJSON() : null,
      plannedQuantity: this._plannedQuantity,
      machineId: this._machine?.id,
      machineName: this._machine?.name,
      tool: this._tool ? this._tool.toJSON() : null
    } as any as JSON;
  }
}
