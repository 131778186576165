import {BmpProductionScheduleItemInPathGroup} from '@domain/production-schedule/bmp-production-schedule-item-in-path-group';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {EpProductionScheduleItemInPathGroup} from '@domain/production-schedule/ep-production-schedule-item-in-path-group';
import {JuteProductionScheduleItemInPathGroup} from '@domain/production-schedule/jute-production-schedule-item-in-path-group';
import {PathLabelDrawing} from '@domain/production-schedule/path-label-drawing';
import {PathLabelProductionScheduleItemInPathGroup} from '@domain/production-schedule/path-label-production-schedule-item-in-path-group';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {ListDrawingOrderLine} from '@presentation/components/drawing-list/list-drawing-order-line';
import {AssertionUtils} from '@vdw/angular-component-library';
import {filter, isEqual, map, reduce, remove, uniqBy} from 'lodash-es';
import {NonProductionItemInPathGroup} from './non-production-item-path-group';

export class ProductionSchedulePath {
  private _name: string;
  private _pathNumber: number;
  private _commercialWidthInMM: number;
  private _technicalWidthInDents: number;
  private _pathGroups: ProductionScheduleItemInPathGroup[];
  private _startDent: number;
  private _totalLengthInMM: number;
  private _totalLengthInPicks: number;

  public constructor(
    name: string,
    pathNumber: number,
    commercialWidthInMM: number,
    technicalWidthInDents: number,
    pathGroups: ProductionScheduleItemInPathGroup[],
    startDent: number,
    totalLengthInMM: number,
    totalLengthInPicks: number
  ) {
    this._name = name;
    this._pathNumber = pathNumber;
    this._commercialWidthInMM = commercialWidthInMM;
    this._technicalWidthInDents = technicalWidthInDents;
    this._pathGroups = pathGroups;
    this._startDent = startDent;
    this._totalLengthInMM = totalLengthInMM;
    this._totalLengthInPicks = totalLengthInPicks;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get pathNumber(): number {
    return this._pathNumber;
  }

  public set pathNumber(value: number) {
    this._pathNumber = value;
  }

  public get commercialWidthInMM(): number {
    return this._commercialWidthInMM;
  }

  public set commercialWidthInMM(value: number) {
    this._commercialWidthInMM = value;
  }

  public get technicalWidthInDents(): number {
    return this._technicalWidthInDents;
  }

  public set technicalWidthInDents(value: number) {
    this._technicalWidthInDents = value;
  }

  public get pathGroups(): ProductionScheduleItemInPathGroup[] {
    return this._pathGroups;
  }

  public set pathGroups(value: ProductionScheduleItemInPathGroup[]) {
    this._pathGroups = value;
  }

  public get startDent(): number {
    return this._startDent;
  }

  public set startDent(value: number) {
    this._startDent = value;
  }

  public get totalLengthInMM(): number {
    return this._totalLengthInMM;
  }

  public set totalLengthInMM(value: number) {
    this._totalLengthInMM = value;
  }

  public get totalLengthInPicks(): number {
    return this._totalLengthInPicks;
  }

  public set totalLengthInPicks(value: number) {
    this._totalLengthInPicks = value;
  }

  public static fromJSON(productionSchedulePathJSON: any): ProductionSchedulePath {
    return new ProductionSchedulePath(
      productionSchedulePathJSON.name,
      productionSchedulePathJSON.pathNumber,
      productionSchedulePathJSON.commercialWidthInMM,
      productionSchedulePathJSON.technicalWidthInDents,
      map(productionSchedulePathJSON.scheduleItems, (pathGroup: any) => {
        let result;

        switch (pathGroup.design.drawingType) {
          case DrawingType.BMP:
            result = BmpProductionScheduleItemInPathGroup.fromJSON(pathGroup);
            break;
          case DrawingType.EP:
            result = EpProductionScheduleItemInPathGroup.fromJSON(pathGroup);
            break;
          case DrawingType.PATHLABEL:
            result = PathLabelProductionScheduleItemInPathGroup.fromJSON(pathGroup);
            break;
          case DrawingType.NON_PRODUCTION_ITEM:
            result = NonProductionItemInPathGroup.fromJSON(pathGroup);
            break;
          case DrawingType.MANUAL_NON_PRODUCTION_ITEM:
            result = JuteProductionScheduleItemInPathGroup.fromJSON(pathGroup);
            break;
        }

        return result;
      }),
      productionSchedulePathJSON.startDent,
      productionSchedulePathJSON.totalLengthInMM,
      productionSchedulePathJSON.totalLengthInPicks
    );
  }

  public setProductionScheduleItemInPathGroupAt(index: number, productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup): void {
    if (!AssertionUtils.isNullOrUndefined(this.getProductionScheduleItemInPathGroupAt(index))) {
      this.pathGroups[index] = productionScheduleItemInPathGroup;
    }
  }

  public hasProductionScheduleItem(productionScheduleItem: ProductionScheduleItemInPathGroup): boolean {
    return this.pathGroups.some((pathGroup: ProductionScheduleItemInPathGroup) => pathGroup.uuid === productionScheduleItem.uuid);
  }

  public getUniqueProductionScheduleItems(): ProductionScheduleItemInPathGroup[] {
    return uniqBy(
      filter(
        this.pathGroups,
        (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) =>
          productionScheduleItemInPathGroup instanceof BmpProductionScheduleItemInPathGroup || productionScheduleItemInPathGroup instanceof EpProductionScheduleItemInPathGroup
      ),
      (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => {
        return `${productionScheduleItemInPathGroup.drawing.image.id}_${productionScheduleItemInPathGroup.orderLineId}`;
      }
    );
  }

  public getNonRecoloredProductionScheduleItems(): ProductionScheduleItemInPathGroup[] {
    return filter(
      this.pathGroups,
      (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) =>
        (productionScheduleItemInPathGroup instanceof BmpProductionScheduleItemInPathGroup || productionScheduleItemInPathGroup instanceof EpProductionScheduleItemInPathGroup) &&
        !productionScheduleItemInPathGroup.isRecolored
    );
  }

  public getUniquePathLabelDrawings(): PathLabelDrawing[] {
    return uniqBy(
      map(
        filter(this.pathGroups, (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => isEqual(productionScheduleItemInPathGroup.scheduleItemType, DrawingType.PATHLABEL)),
        (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => productionScheduleItemInPathGroup.drawing
      ),
      'image.id'
    );
  }

  public equals(other: ProductionSchedulePath): boolean {
    return this.name === other.name;
  }

  public toJSON(): JSON {
    const productionSchedulePathJSON = {
      name: this._name,
      pathNumber: this._pathNumber,
      startDent: this._startDent,
      commercialWidthInMM: this._commercialWidthInMM,
      technicalWidthInDents: this._technicalWidthInDents,
      scheduleItems: map(this._pathGroups, (pathGroup: ProductionScheduleItemInPathGroup) => pathGroup.toJSON())
    } as any as JSON;

    if (!AssertionUtils.isNullOrUndefined(this._totalLengthInPicks)) {
      productionSchedulePathJSON['totalLengthInPicks'] = this._totalLengthInPicks;
    }

    return productionSchedulePathJSON;
  }

  public getProductionScheduleItemInPathGroup(uuid: string): ProductionScheduleItemInPathGroup {
    return this.pathGroups.find((pathGroup: ProductionScheduleItemInPathGroup) => pathGroup.uuid === uuid);
  }

  public getOrderLineQuantityInProductionScheduleItems(orderLineId: number): number {
    return reduce(
      this.pathGroups,
      (quantity: number, productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => {
        if (productionScheduleItemInPathGroup.hasOrderLine() && isEqual(productionScheduleItemInPathGroup.orderLineId, orderLineId)) {
          quantity += productionScheduleItemInPathGroup.quantity;
        }

        return quantity;
      },
      0
    );
  }

  public getOrderLineHeightInMMInProductionScheduleItems(orderLineId: number): number {
    return this.pathGroups.reduce((heightInMM: number, productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => {
      if (productionScheduleItemInPathGroup.hasOrderLine() && productionScheduleItemInPathGroup.orderLineId === orderLineId) {
        heightInMM += productionScheduleItemInPathGroup.commercialDimensionsInMM?.heightInMM * productionScheduleItemInPathGroup.quantity;
      }

      return heightInMM;
    }, 0);
  }

  public getProductionScheduleItemsForListDrawing(listDrawing: ListDrawing): ProductionScheduleItemInPathGroup[] {
    return reduce(
      this._pathGroups,
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionScheduleItem: ProductionScheduleItemInPathGroup) => {
        if (
          (listDrawing instanceof ListDrawingOrderLine && isEqual(productionScheduleItem.orderLineId, listDrawing.orderLine.id)) ||
          (listDrawing instanceof ListDrawingConfiguration && !productionScheduleItem.hasOrderLine() && isEqual(productionScheduleItem.drawing.id, listDrawing.drawing.id))
        ) {
          productionScheduleItems.push(productionScheduleItem);
        }

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionScheduleItemsForOrderLine(orderLineId: number): ProductionScheduleItemInPathGroup[] {
    return filter(this._pathGroups, {orderLineId});
  }

  public updatePathLength(productionSchedule: ProductionSchedule): void {
    const otherProductionSchedulePath = productionSchedule.getProductionSchedulePath(this.pathNumber);

    this.totalLengthInMM = otherProductionSchedulePath.totalLengthInMM;
    this.totalLengthInPicks = otherProductionSchedulePath.totalLengthInPicks;
  }

  public removePathLabelsAndNonProductionItemsFromPathGroups(): void {
    remove(this._pathGroups, (pathGroup: ProductionScheduleItemInPathGroup) => {
      return pathGroup instanceof PathLabelProductionScheduleItemInPathGroup || pathGroup instanceof NonProductionItemInPathGroup;
    });
  }

  private getProductionScheduleItemInPathGroupAt(index: number): ProductionScheduleItemInPathGroup {
    return this.pathGroups.length > index ? this.pathGroups[index] : null;
  }
}
