<section class="select-design-container" fxLayout="column" fxFlex *ngIf="!canShowAdvancedSearch">
  <vdw-header [title]="'GENERAL.ACTIONS.CHOOSE_OBJECT' | bmsTranslate: {object: designTranslationKey | lowercase}" svgIcon="select-blue">
    <vdw-filter
      *ngIf="canShowFilterInput()"
      icon="filter"
      fxLayoutAlign="center"
      [filteredText]="getNameFilterValue()"
      (filterIconClicked)="showAdvancedSearch()"
      (filteredTextChanged)="onNameFilterChange($event)"
    ></vdw-filter>
  </vdw-header>
  <article appDataCy="grid" fxFlex="0 0 calc(100% - 146px)" fxLayout="row" class="dialog-content">
    <div fxLayout="column" fxFlex="100">
      <app-search-filters
        #searchFilters
        [searchFilters]="advancedSearchFilters"
        [commercialUnit]="defaultUnit"
        (showAdvancedSearch)="showAdvancedSearch()"
        (searchFiltersChanged)="updateAdvancedSearchFilters()"
      ></app-search-filters>
      <button mat-icon-button class="mat-icon-action-button-lg-with-border ml-24" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="drawingImageInput.click()">
        <mat-icon svgIcon="add"></mat-icon>
      </button>
      <input
        #drawingImageInput
        class="hidden"
        multiple
        type="file"
        [attr.accept]="ALLOWED_FILE_EXTENSION_FOR_FILES"
        (change)="onDrawingFileChange($event.target.files); drawingImageInput.value = ''"
      />
      <ng-container *ngIf="!loadingDrawings">
        <ng-container *ngIf="!canShowNoResultOverlay()">
          <app-drawing-list
            *ngIf="hasDrawings()"
            class="mt-24"
            [listOfDrawings]="listOfDrawings"
            [allowSelectMultipleDrawings]="allowSelectMultipleDrawings"
            [selectedDrawings]="selectedDrawings"
            [allowDoubleClick]="!allowSelectMultipleDrawings"
            (drawingDoubleClicked)="onDrawingDoubleClicked($event)"
            (drawingSelected)="onDrawingSelected($event)"
            (virtualScrollerEnd)="onVirtualScrollerEnd()"
          ></app-drawing-list>

          <vdw-nodata-overlay *ngIf="!hasDrawings()" fxFlex [shouldShowFilterNoResult]="false" [hideDescription]="true" [titleParam]="'DESIGN_LIBRARY.DESIGN' | bmsTranslate: {count: 2}" scale="0.9">
          </vdw-nodata-overlay>
        </ng-container>
        <vdw-nodata-overlay *ngIf="canShowNoResultOverlay()" fxFlex [shouldShowFilterNoResult]="true" [hideDescription]="true" scale="0.9"> </vdw-nodata-overlay>
      </ng-container>

      <app-progress-spinner fxLayoutAlign="center center" *ngIf="loadingDrawings" [text]="'GENERAL.LOADING.LOADING' | bmsTranslate"></app-progress-spinner>
    </div>
  </article>
  <vdw-footer [locked]="!canSelectDesign()" [centered]="true" (chooseEvent)="selectDesign()"></vdw-footer>
</section>

<app-advanced-search
  *ngIf="canShowAdvancedSearch"
  [advancedSearchFilters]="advancedSearchFilters"
  [defaultUnit]="defaultUnit"
  (advancedSearchClose)="closeAdvancedSearch()"
  (update)="updateAdvancedSearchFilters($event)"
></app-advanced-search>
