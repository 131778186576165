import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertFileSizeUnitByte(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.KILOBYTE:
      result = unitValue.value * 1000;
      break;
    case Unit.BYTE:
      result = unitValue.value;
      break;
    default:
      throw new TextileUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.BYTE}`);
  }

  return result;
}
