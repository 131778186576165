import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {CreelMappingTableBaseComponent} from '@presentation/components/creel-tables/creel-mapping-table/creel-mapping-table-base.component';
import {AssertionUtils} from '@vdw/angular-component-library';
import {cloneDeep} from 'lodash-es';

@Component({
  selector: 'app-creel-mapping-table',
  templateUrl: './creel-mapping-table.component.html',
  styleUrls: ['../base-creel-table/base-creel-table.component.scss']
})
export class CreelMappingTableComponent extends CreelMappingTableBaseComponent implements OnChanges {
  @Input() public readOnly = false;
  @Output() public creelMapUpdate: EventEmitter<CreelMap> = new EventEmitter<CreelMap>();
  private draggedCreelPosition: OverviewListCreelPosition;
  private sourceIndex: number;

  public onDrop(mappedCreelPositionToSwitch: OverviewListCreelPosition, destinationIndex: number): void {
    if (!AssertionUtils.isNullOrUndefined(this.draggedCreelPosition) && !AssertionUtils.isNullOrUndefined(this.sourceIndex)) {
      const creelPositionInCreelMap = this.activeColoredYarnSetCreelMap.creelPositions.find(
        (value: {creelPosition: number; currentPositionOnMachine: number}) => value.creelPosition === this.draggedCreelPosition.position
      );

      const creelPositionToSwitch = this.activeColoredYarnSetCreelMap.creelPositions.find(
        (value: {creelPosition: number; currentPositionOnMachine: number}) => value.creelPosition === mappedCreelPositionToSwitch.position
      );

      const creelPositionInCreelMapCurrentPositionOnMachine = creelPositionInCreelMap.currentPositionOnMachine;
      creelPositionInCreelMap.currentPositionOnMachine = creelPositionToSwitch.currentPositionOnMachine;
      creelPositionToSwitch.currentPositionOnMachine = creelPositionInCreelMapCurrentPositionOnMachine;

      const sourceCreelPositionClone = cloneDeep(this.activeMappedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions[this.sourceIndex]);

      this.activeMappedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions[this.sourceIndex] =
        this.activeMappedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions[destinationIndex];
      this.activeMappedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions[destinationIndex] = sourceCreelPositionClone;

      this.creelMapUpdate.emit(this.activeColoredYarnSetCreelMap);

      this.draggedCreelPosition = null;
      this.sourceIndex = null;
    }
  }

  public onDragOver(event: any): void {
    if (AssertionUtils.isNullOrUndefined(this.draggedCreelPosition) || AssertionUtils.isNullOrUndefined(this.sourceIndex)) {
      event.dataTransfer.dropEffect = 'move';
    }

    event.preventDefault();
  }

  public setDraggedCreelPosition(mappedCreelPosition: OverviewListCreelPosition, sourceIndex: number): void {
    this.draggedCreelPosition = mappedCreelPosition;
    this.sourceIndex = sourceIndex;
  }
}
