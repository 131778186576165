/* eslint-disable brace-style */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PropertyValue} from '@domain/property-value';
import {TuftProductionOrder} from '@domain/texfab/tuft-production-order/tuft-production-order';
import {OverviewListTuftProductionOrder} from '@presentation/pages/texfab/tuft-production-order/overview/overview-list-tuft-production-order';
import {map, Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';
import {Repository} from '../repository';

@Injectable({providedIn: 'root'})
export class HttpTuftProductionOrdersService
  implements Repository<TuftProductionOrder, OverviewListTuftProductionOrder>, CustomSettingsObjectRepository, NameGenerationRepository<TuftProductionOrder>
{
  public error = 'Method not implemented.';

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<OverviewListTuftProductionOrder[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft`)
      .pipe(map((tuftProductionOrdersJSON: any) => tuftProductionOrdersJSON.map((tuftProductionOrderJSON: any) => OverviewListTuftProductionOrder.fromJSON(tuftProductionOrderJSON))));
  }

  public getById(id: number): Observable<TuftProductionOrder> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/${id}`)
      .pipe(map((tuftProductionOrderJSON: any) => TuftProductionOrder.fromJSON(tuftProductionOrderJSON)));
  }

  public save(item: TuftProductionOrder): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public saveAndPlan(item: TuftProductionOrder, runId: number): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getAggregatorEndpoint()}productionorders/tuft/save-and-plan`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: TuftProductionOrder): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/${item.id}`, item.toJSON());
  }

  public updateAndPlan(item: TuftProductionOrder, runId: number): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getAggregatorEndpoint()}productionorders/tuft/${item.id}/save-and-plan`, item.toJSON());
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/${id}`);
  }

  public checkCompatibilityWithMachine(tuftProductionOrderId: number, machineId: number): Observable<number> {
    return this.httpClient.get<number>(`${this.backendConfiguration.getAggregatorEndpoint()}productionorders/tuft/${tuftProductionOrderId}/is-compatible-machine/${machineId}`);
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get<PropertyValue[]>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/custom-settings`);
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/custom-settings/already-adjusted`)
      .pipe(map((response: {alreadyAdjustedCustomSettings: boolean}) => response.alreadyAdjustedCustomSettings));
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/custom-settings`, chosenSettings);
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/custom-settings/name-generation-properties`);
  }

  public generateName(item: TuftProductionOrder): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/tuft/custom-settings/generate-name`, item.toJSON());
  }
}
