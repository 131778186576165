import {Component, Inject, ViewContainerRef} from '@angular/core';
import {BACKGROUND_JOBS, BackgroundJobs} from '@infrastructure/http/background-jobs/background-jobs';
import {REALTIME_BACKGROUND_JOBS, RealtimeBackgroundJobs} from '@infrastructure/signalr/jobs/realtime-background-jobs';
import {DialogBuilderFactoryService, HoverDialogBuilderService, TranslateService} from '@vdw/angular-component-library';
import {BaseBackgroundJobsComponent} from './base-background-jobs.component';

@Component({
  templateUrl: './background-jobs-dialog.component.html',
  styleUrls: ['./background-jobs-dialog.component.scss']
})
export class BackgroundJobsDialogComponent extends BaseBackgroundJobsComponent {
  public constructor(
    @Inject(BACKGROUND_JOBS) backgroundJobs: BackgroundJobs,
    @Inject(REALTIME_BACKGROUND_JOBS) realtimeBackgroundJobs: RealtimeBackgroundJobs,
    dialogBuilderFactoryService: DialogBuilderFactoryService,
    translate: TranslateService,
    viewContainerRef: ViewContainerRef,
    hoverDialogService: HoverDialogBuilderService
  ) {
    super(backgroundJobs, realtimeBackgroundJobs, dialogBuilderFactoryService, translate);

    hoverDialogService.registerElement(viewContainerRef.element.nativeElement, false);
  }
}
