import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ColorRoute extends RouteBranch {
  private _addColor: RouteLeaf;
  private _duplicateColor: RouteLeaf;
  private _editColor: RouteLeaf;
  private _colorSettings: RouteLeaf;

  public get addColor(): RouteLeaf {
    return this._addColor;
  }

  public set addColor(value: RouteLeaf) {
    this.replace(this._addColor, value);
    this._addColor = value;
  }

  public get duplicateColor(): RouteLeaf {
    return this._duplicateColor;
  }

  public set duplicateColor(value: RouteLeaf) {
    this.replace(this._duplicateColor, value);
    this._duplicateColor = value;
  }

  public get editColor(): RouteLeaf {
    return this._editColor;
  }

  public set editColor(value: RouteLeaf) {
    this.replace(this._editColor, value);
    this._editColor = value;
  }

  public get colorSettings(): RouteLeaf {
    return this._colorSettings;
  }

  public set colorSettings(value: RouteLeaf) {
    this.replace(this._colorSettings, value);
    this._colorSettings = value;
  }
}
