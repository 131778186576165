import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Data, Router} from '@angular/router';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';

interface CanActivatePermissionRedirectGuardDataCandidateRoute {
  route: string;
  requiredPermission: Permission;
  isMobileFriendly: boolean;
}

interface CanActivatePermissionRedirectGuardData {
  permissionsToCheck: Permission[];
  redirectCandidateRoutes: CanActivatePermissionRedirectGuardDataCandidateRoute[];
}

export const canActivatePermissionRedirectGuard = (route: ActivatedRouteSnapshot): boolean => {
  return canActivateBase(route, true);
};

const canActivateBase = (route: ActivatedRouteSnapshot, withNavigation: boolean): boolean => {
  const authentication = inject(AUTHENTICATION);
  const router = inject(Router);
  const guardData: CanActivatePermissionRedirectGuardData = extractGuardData(route.data);
  const currentSubscription = authentication.getCurrentSubscription();
  const validPermissionsCount = getValidPermissionsCount(guardData, currentSubscription);

  let canActivate = false;

  if (validPermissionsCount > 1) {
    canActivate = true;
  } else {
    const permittedRoutes: string[] = [];

    guardData.redirectCandidateRoutes.forEach((candidateRoute: CanActivatePermissionRedirectGuardDataCandidateRoute) => {
      if (isRouteAllowed(candidateRoute, currentSubscription)) {
        permittedRoutes.push(candidateRoute.route);
      }
    });

    canActivate = permittedRoutes.length !== 1;

    if (!canActivate && withNavigation) {
      router.navigate([permittedRoutes[0]], {
        state: {
          isRedirect: true
        }
      });
    }
  }

  return canActivate;
};

const getValidPermissionsCount = (guardData: CanActivatePermissionRedirectGuardData, currentSubscription: Subscription): number => {
  const responsivenessViewMode = inject(RESPONSIVENESS_VIEW_MODE);
  let validPermissionsCount = 0;

  guardData.permissionsToCheck.forEach((requiredPermission: Permission, index: number) => {
    let hasRequiredPermission = false;

    hasRequiredPermission = currentSubscription.hasPermission(requiredPermission);

    if (hasRequiredPermission && (responsivenessViewMode.isPhone ? guardData.redirectCandidateRoutes[index]?.isMobileFriendly : true)) {
      validPermissionsCount++;
    }
  });

  return validPermissionsCount;
};

const isRouteAllowed = (candidateRoute: CanActivatePermissionRedirectGuardDataCandidateRoute, currentSubscription: Subscription): boolean => {
  const responsivenessViewMode = inject(RESPONSIVENESS_VIEW_MODE);
  let hasRequiredPermission = false;

  if (AssertionUtils.isNullOrUndefined(candidateRoute.requiredPermission)) {
    hasRequiredPermission = true;
  } else {
    hasRequiredPermission = currentSubscription.hasPermission(candidateRoute.requiredPermission);
  }

  return hasRequiredPermission && (responsivenessViewMode.isPhone ? candidateRoute.isMobileFriendly : true);
};

const extractGuardData = (data: Data): CanActivatePermissionRedirectGuardData => {
  const guardData: CanActivatePermissionRedirectGuardData = {permissionsToCheck: [], redirectCandidateRoutes: []};
  guardData.permissionsToCheck = data.permissionsToCheck;
  guardData.redirectCandidateRoutes = data.redirectCandidateRoutes;
  return guardData;
};
