import {Component, Inject} from '@angular/core';
import {WINDOW} from '../../window/window.service';

@Component({
  templateUrl: './reload-required.component.html',
  styleUrls: ['./reload-required.component.scss']
})
export class ReloadRequiredComponent {
  public constructor(@Inject(WINDOW) private readonly window: Window) {}

  public reloadApp(): void {
    this.window.location.reload();
  }
}
