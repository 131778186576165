import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class FixedScheduleRoute extends RouteBranch {
  private _addFixedSchedule: RouteLeaf;
  private _editFixedSchedule: RouteLeaf;
  private _duplicateFixedSchedule: RouteLeaf;

  public get addFixedSchedule(): RouteLeaf {
    return this._addFixedSchedule;
  }

  public set addFixedSchedule(value: RouteLeaf) {
    this.replace(this._addFixedSchedule, value);
    this._addFixedSchedule = value;
  }

  public get editFixedSchedule(): RouteLeaf {
    return this._editFixedSchedule;
  }

  public set editFixedSchedule(value: RouteLeaf) {
    this.replace(this._editFixedSchedule, value);
    this._editFixedSchedule = value;
  }

  public get duplicateFixedSchedule(): RouteLeaf {
    return this._duplicateFixedSchedule;
  }

  public set duplicateFixedSchedule(value: RouteLeaf) {
    this.replace(this._duplicateFixedSchedule, value);
    this._duplicateFixedSchedule = value;
  }
}
