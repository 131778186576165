import {Component, Input} from '@angular/core';
import {isEmpty, isEqual, isNil, isString, lowerCase} from 'lodash-es';
import {TranslateService} from '../../translation/translate.service';
import {OverlayActionsService} from './overlay-actions.service';
import {OverlayComponentParams} from './overlay-component-params';
import {OverlayComponentParamsAction} from './overlay-component-params-actions';

@Component({
  template: ''
})
export abstract class BaseOverlayComponent {
  @Input() public smallTableMode: boolean;
  @Input() public titleKey: string;
  @Input() public titleParam: string | Record<string, unknown>;
  @Input() public descriptionKey: string;
  @Input() public image: string;
  @Input() public scale = 1;
  @Input() public hideDescription = false;
  @Input() public actionsParam: OverlayComponentParamsAction[];
  @Input() public hideActions = false;

  public title: string;
  public description: string;
  public heightImage = 188;
  public titleFontSize = 22;
  public descriptionFontSize = 16;
  public actions: OverlayComponentParamsAction[];
  protected translate: TranslateService;
  private overlayActionsService: OverlayActionsService;
  private minimumFontSize = 13;

  public constructor(overlayActionsService: OverlayActionsService, translate: TranslateService) {
    this.overlayActionsService = overlayActionsService;
    this.translate = translate;
  }

  public get shouldDisplayTitle(): boolean {
    return !isNil(this.title) && !this.smallTableMode;
  }

  public get shouldDisplayDescription(): boolean {
    return !isNil(this.description) && !this.smallTableMode;
  }

  public get shouldDisplayImage(): boolean {
    return !isNil(this.image) && !this.smallTableMode;
  }

  public get shouldDisplayActions(): boolean {
    return !isEmpty(this.actions) && !this.smallTableMode;
  }

  public getActionClass(action: OverlayComponentParamsAction): boolean {
    return !isNil(action) && action.isPrimary;
  }

  public onActionClicked(actionKey: string): void {
    this.overlayActionsService.notifyActionTriggered(actionKey);
  }

  public scaled(value: number): number {
    return this.scale * value < this.minimumFontSize ? this.minimumFontSize : this.scale * value;
  }

  public isActionDisabled(disabled: boolean | (() => boolean)): boolean {
    if (disabled === undefined) {
      return false;
    }
    return typeof disabled === 'boolean' ? disabled : disabled();
  }

  protected getTranslation(key: string, objectParam: Record<string, unknown> | string = null): string {
    let result = null;

    if (!isNil(key)) {
      const replaceableValues: Record<string, unknown> = isString(objectParam) ? {object: lowerCase(this.translate.instant(objectParam, {count: 0}))} : objectParam;
      result = this.translate.instant(key, replaceableValues);
    }
    return result;
  }

  protected createParamsObject(): OverlayComponentParams {
    return {
      api: null,
      context: null,
      smallTableMode: this.smallTableMode,
      titleKey: this.titleKey,
      titleParam: this.titleParam,
      descriptionKey: this.descriptionKey,
      image: this.image,
      scale: this.scale,
      hideDescription: this.hideDescription,
      hideActions: this.hideActions,
      actions: this.getActions()
    };
  }

  private getActions(): OverlayComponentParamsAction[] {
    if (!this.hideActions) {
      if (!isNil(this.titleKey)) {
        return [{key: 'default action', titleKey: this.titleKey, isPrimary: true}];
      } else if (this.actionsParam) {
        return this.actionsParam;
      }
    }

    return [];
  }

  protected initializeOverlayComponent(params: OverlayComponentParams): void {
    this.smallTableMode = params.smallTableMode;
    this.hideDescription = params.hideDescription;
    this.hideActions = params.hideActions;
    this.titleKey = params.titleKey;
    this.titleParam = params.titleParam;
    this.title = this.getTranslation(params.titleKey, params.titleParam);
    this.description = params.hideDescription ? null : this.getTranslation(params.descriptionKey);
    this.image = params.image;

    if (isNil(params.scale)) {
      this.scale = isEqual(this.smallTableMode, true) ? 0.7 : 1;
    } else {
      this.scale = params.scale;
    }

    this.actions = this.hideActions ? [] : params.actions;
  }
}
