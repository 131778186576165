import {StringUtils} from '@application/helper/string-utils';

export class OverviewListWeaveProduct {
  private _id: number;
  private _name: string;
  private _description: string;
  private _version: number;
  private _widthInCm: number;
  private _pickDensityInPicksPerCm: number;
  private _standardSpeedInRotationsPerMinute: number;
  private _standardEfficiencyInPercentage: number;
  private _numberOfFeeders: number;
  private _numberOfShafts: number;

  public constructor(
    id: number,
    name: string,
    description: string,
    version: number,
    widthInCm: number,
    pickDensityInPicksPerCm: number,
    standardSpeedInRotationsPerMinute: number,
    standardEfficiencyInPercentage: number,
    numberOfFeeders: number,
    numberOfShafts: number
  ) {
    this._id = id;
    this._name = name;
    this._description = description;
    this._version = version;
    this._widthInCm = widthInCm;
    this._pickDensityInPicksPerCm = pickDensityInPicksPerCm;
    this._standardSpeedInRotationsPerMinute = standardSpeedInRotationsPerMinute;
    this._standardEfficiencyInPercentage = standardEfficiencyInPercentage;
    this._numberOfFeeders = numberOfFeeders;
    this._numberOfShafts = numberOfShafts;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get description(): string {
    return this._description;
  }

  public get version(): number {
    return this._version;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public get pickDensityInPicksPerCm(): number {
    return this._pickDensityInPicksPerCm;
  }

  public get standardSpeedInRotationsPerMinute(): number {
    return this._standardSpeedInRotationsPerMinute;
  }

  public get standardEfficiencyInPercentage(): number {
    return this._standardEfficiencyInPercentage;
  }

  public get numberOfFeeders(): number {
    return this._numberOfFeeders;
  }

  public get numberOfShafts(): number {
    return this._numberOfShafts;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._name, this._version);
  }

  public static fromJSON(weaveProductJSON: any): OverviewListWeaveProduct {
    return new OverviewListWeaveProduct(
      weaveProductJSON.id,
      weaveProductJSON.name,
      weaveProductJSON.description,
      weaveProductJSON.version,
      weaveProductJSON.widthInCm,
      weaveProductJSON.pickDensityInPicksPerCm,
      weaveProductJSON.standardSpeedInRotationsPerMinute,
      weaveProductJSON.standardEfficiencyInPercentage,
      weaveProductJSON.numberOfFeeders,
      weaveProductJSON.numberOfShafts
    );
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
