import {ElementRef, EventEmitter, TemplateRef} from '@angular/core';
import moment, {Duration, duration} from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {AssertionUtils} from '../../../common/utils/assertion-utils';

export class PlanningItemData {
  public get startDate(): Date {
    return this._startDate;
  }

  public set startDate(value: Date) {
    if (!AssertionUtils.datesAreEqual(this._startDate, value)) {
      this._startDate = value;
      this.onPeriodChanged();
    }
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public set endDate(value: Date) {
    if (!AssertionUtils.datesAreEqual(this._endDate, value)) {
      this._endDate = value;
      this.onPeriodChanged();
    }
  }

  public get applyCustomBorder(): boolean {
    return this._applyCustomBorder;
  }

  public set applyCustomBorder(value: boolean) {
    this._applyCustomBorder = value;
  }

  public get applyBackground(): boolean {
    return this._applyBackground;
  }

  public set applyBackground(value: boolean) {
    this._applyBackground = value;
  }

  public get duration(): Duration {
    return this._duration;
  }

  public get displayElement(): ElementRef<HTMLElement> {
    return this._displayElement.value;
  }

  public set displayElement(value: ElementRef<HTMLElement>) {
    if (this._displayElement.value !== value) {
      this._displayElement.next(value);
    }
  }

  public get displayElementChanges(): Observable<ElementRef<HTMLElement>> {
    return this._displayElement.asObservable();
  }

  public dataTransfer: any;
  public template: TemplateRef<any>;
  public periodChanged = new EventEmitter();
  public selected = new EventEmitter<MouseEvent>();
  public class = '';

  public clickable = false;
  public draggable = false;

  private _endDate: Date;
  private _startDate: Date;
  private _duration: Duration;
  private _applyCustomBorder = false;
  private _applyBackground = true;

  private _displayElement = new BehaviorSubject<ElementRef<HTMLElement>>(undefined);

  protected onPeriodChanged(): void {
    this._duration = duration(moment(this._endDate).diff(this._startDate));
    this.periodChanged.emit();
  }
}
