import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class YarnSetRoute extends RouteBranch {
  private _addYarnSet: RouteLeaf;
  private _duplicateYarnSet: RouteLeaf;
  private _editYarnSet: RouteLeaf;
  private _yarnSetSettings: RouteLeaf;

  public get addYarnSet(): RouteLeaf {
    return this._addYarnSet;
  }

  public set addYarnSet(value: RouteLeaf) {
    this.replace(this._addYarnSet, value);
    this._addYarnSet = value;
  }

  public get duplicateYarnSet(): RouteLeaf {
    return this._duplicateYarnSet;
  }

  public set duplicateYarnSet(value: RouteLeaf) {
    this.replace(this._duplicateYarnSet, value);
    this._duplicateYarnSet = value;
  }

  public get editYarnSet(): RouteLeaf {
    return this._editYarnSet;
  }

  public set editYarnSet(value: RouteLeaf) {
    this.replace(this._editYarnSet, value);
    this._editYarnSet = value;
  }

  public get yarnSetSettings(): RouteLeaf {
    return this._yarnSetSettings;
  }

  public set yarnSetSettings(value: RouteLeaf) {
    this.replace(this._yarnSetSettings, value);
    this._yarnSetSettings = value;
  }
}
