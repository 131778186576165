import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class TexBiRoute extends RouteBranch {
  private _reports: RouteLeaf;
  private _bmsreports: RouteLeaf;
  private _windervibrationreport: RouteLeaf;
  private _qmreports: RouteLeaf;
  private _wmreports: RouteLeaf;
  private _emreports: RouteLeaf;
  private _machineEventBoard: RouteLeaf;
  private _winderVibrationForm: RouteLeaf;

  public get reports(): RouteLeaf {
    return this._reports;
  }

  public set reports(value: RouteLeaf) {
    this.replace(this._reports, value);
    this._reports = value;
  }

  public get windervibrationreport(): RouteLeaf {
    return this._windervibrationreport;
  }

  public set windervibrationreport(value: RouteLeaf) {
    this.replace(this._windervibrationreport, value);
    this._windervibrationreport = value;
  }

  public get bmsreports(): RouteLeaf {
    return this._bmsreports;
  }

  public set bmsreports(value: RouteLeaf) {
    this.replace(this._bmsreports, value);
    this._bmsreports = value;
  }

  public get qmreports(): RouteLeaf {
    return this._qmreports;
  }

  public set qmreports(value: RouteLeaf) {
    this.replace(this._qmreports, value);
    this._qmreports = value;
  }

  public get wmreports(): RouteLeaf {
    return this._wmreports;
  }

  public set wmreports(value: RouteLeaf) {
    this.replace(this._wmreports, value);
    this._wmreports = value;
  }

  public get emreports(): RouteLeaf {
    return this._emreports;
  }

  public set emreports(value: RouteLeaf) {
    this.replace(this._emreports, value);
    this._emreports = value;
  }

  public get machineEventBoard(): RouteLeaf {
    return this._machineEventBoard;
  }

  public set machineEventBoard(value: RouteLeaf) {
    this.replace(this._machineEventBoard, value);
    this._machineEventBoard = value;
  }

  public get winderVibrationForm(): RouteLeaf {
    return this._winderVibrationForm;
  }

  public set winderVibrationForm(value: RouteLeaf) {
    this.replace(this._winderVibrationForm, value);
    this._winderVibrationForm = value;
  }
}
