<app-textile-data-overview
  #textileDataOverview
  iconForCard="production-order-blue"
  [listOfTextileData]="listOfTuftProductionOrders"
  [columnDefs]="getColumnDefsForListOfTuftProductionOrders()"
  [textileDataService]="tuftProductionOrders"
  [textileDataType]="textileDataType.TUFT_PRODUCTION_ORDER"
  [gridIdentifier]="GRID_IDENTIFIER"
  (exportGridWithComplexColumns)="exportGridForTuftProductionOrders($event)"
  [showSearchBar]="false"
  (customSettingsSetup)="navigateToCustomSettings()"
>
</app-textile-data-overview>
