<div class="p-24 full-height bms-theme">
  <app-custom-settings-dialog-header [menuItems]="[generalTab]" entityTranslationKey="PLASTIC_PRODUCTION_ORDER.PLASTIC_PRODUCTION_ORDER" (cancel)="closeDialog()" (save)="save()" />

  <form class="bms-theme p-48" [formGroup]="settingsForm">
    <app-name-generation-pattern-new
      class="half-width"
      [nameGenerator]="customSettings?.nameGenerator"
      [nameGenerationProperties]="nameGenerationProperties"
      (nameGeneratorChange)="updateNameGenerator($event)"
    />
  </form>
</div>
