import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Injectable({providedIn: 'root'})
export class AppInsightsLoggingService {
  private appInsights: ApplicationInsights;
  private keys: {[key: string]: any} = [];

  public init(appInsightsKey: string): void {
    if (!AssertionUtils.isEmpty(appInsightsKey)) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsKey,
          enableAutoRouteTracking: false,
          disableTelemetry: false, //if true, this one disables all data sent to insights, even our exception logging
          enableDebug: false,
          disableFetchTracking: true,
          disableAjaxTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  public setTag(key: string, value: string): void {
    this.keys[key] = value;
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights?.trackException({exception: exception, severityLevel: severityLevel}, this.keys);
  }
}
