import {DefaultHttpClient, HttpRequest, HttpResponse, MessageHeaders} from '@microsoft/signalr';
import {SignalrLogger} from '@vdw/angular-component-library';

export class CustomSignalrHttpClient extends DefaultHttpClient {
  public constructor(
    private readonly configureHeaders: (headers: MessageHeaders) => void,
    logger: SignalrLogger
  ) {
    super(logger);
  }

  public send(request: HttpRequest): Promise<HttpResponse> {
    this.configureHeaders(request.headers);
    return super.send(request);
  }
}
