import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslationModule} from '../../../translation/translation.module';
import {LabelModule} from '../../ellipsis-label/label.module';
import {PatternModule} from '../../pattern/pattern.module';
import {SelectPatternsComponent} from './select-patterns.component';

@NgModule({
  imports: [CommonModule, MatSelectModule, TranslationModule, ReactiveFormsModule, LabelModule, FormsModule, MatInputModule, PatternModule],
  exports: [SelectPatternsComponent],
  declarations: [SelectPatternsComponent]
})
export class SelectPatternsModule {}
