import {InjectionToken} from '@angular/core';
import {PropertyValue} from '@domain/property-value';
import {WeaveStructure} from '@domain/textile-data/weave-structure/weave-structure';
import {WeaveStructureDetails} from '@domain/textile-data/weave-structure/weave-structure-details';
import {WeaveStructureForMachineQuality} from '@domain/textile-data/weave-structure/weave-structure-for-machine-quality';
import {GridModelRepository} from '@infrastructure/http/grid-model-repository';
import {Repository} from '@infrastructure/http/repository';
import {WeaveStructureNameCheck} from '@infrastructure/http/weave-structure/weave-structure-name-check.interface';
import {OverviewListWeaveStructure} from '@presentation/pages/textile-data/weave-structure/overview/overview-list-weave-structure';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {WeftSelectionSuggestions} from './weft-selection-suggestions.interface';

export const WEAVE_STRUCTURES = new InjectionToken<WeaveStructures>('WeaveStructures');

export interface WeaveStructures extends Repository<WeaveStructureForMachineQuality, WeaveStructureForMachineQuality>, ConflictsRepository, GridModelRepository<WeaveStructureForMachineQuality> {
  getAll(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<WeaveStructureForMachineQuality[]>;
  getAllOverviewListWeaveStructures(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<OverviewListWeaveStructure[]>;
  manageGetAllOverviewListWeaveStructures(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<OverviewListWeaveStructure[]>;
  manageSaveOrUpdateSimple(item: WeaveStructure, wvsxFile: File): Observable<number>;
  getByIdExtended(id: number): Observable<WeaveStructure>;
  manageGetByIdExtended(id: number): Observable<WeaveStructure>;
  parseDescription(name: string, description: string): Observable<WeaveStructureDetails>;
  getFactorForConversionFromPicksToWefts(weaveStructureId: number): Observable<number>;
  getWeftSelectionSuggestions(weaveStructureId: number): Observable<WeftSelectionSuggestions>;
  duplicateWeaveStructure(weaveStructureId: number, name: string): Observable<number>;
  isNameUnavailableOrReserved(name: string): Observable<WeaveStructureNameCheck>;
  manageDelete(id: number): Observable<void>;
  getCustomSettings(): Observable<PropertyValue[]>;
  saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void>;
}

export interface GetAllWeaveStructuresParameters {
  showOnlyLatestVersion: boolean;
  numberOfCreelPositions: number;
  isActive: boolean;
}
