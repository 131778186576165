import {isEqual, isFinite, max, round} from 'lodash-es';
import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {convertToTextileUnitDecitex} from './convert-to-textile-unit-decitex';
import {convertToTextileUnitDenier} from './convert-to-textile-unit-denier';
import {convertToTextileUnitEnglishCottonCount} from './convert-to-textile-unit-english-cotton-count';
import {convertToTextileUnitLibraPounds} from './convert-to-textile-unit-libra-pounds';
import {convertToTextileUnitLinencount} from './convert-to-textile-unit-linencount';
import {convertToTextileUnitNumberMetric} from './convert-to-textile-unit-number-metric';
import {convertToTextileUnitTex} from './convert-to-textile-unit-tex';
import {UnitConversionParameters} from './unit-conversion-parameters';

export const minValueForTexUnit = 1;
export const minValueForOtherTextileUnits = 0.01;

export function convertTextileUnit(unitConversionParameters: UnitConversionParameters): number {
  let result: number;

  if (unitConversionParameters.from.value === undefined) {
    return undefined;
  } else if (unitConversionParameters.from.value === null) {
    return null;
  }

  const isToUnitTex = isEqual(unitConversionParameters.to, Unit.TEX);
  const minValue = isToUnitTex ? minValueForTexUnit : minValueForOtherTextileUnits;

  switch (unitConversionParameters.to) {
    case Unit.NUMBER_METRIC:
      result = convertToTextileUnitNumberMetric(unitConversionParameters.from);
      break;
    case Unit.DENIER:
      result = convertToTextileUnitDenier(unitConversionParameters.from);
      break;
    case Unit.DECITEX:
      result = convertToTextileUnitDecitex(unitConversionParameters.from);
      break;
    case Unit.TEX:
      result = convertToTextileUnitTex(unitConversionParameters.from);
      break;
    case Unit.LINENCOUNT:
      result = convertToTextileUnitLinencount(unitConversionParameters.from);
      break;
    case Unit.LIBRA_POUNDS:
      result = convertToTextileUnitLibraPounds(unitConversionParameters.from);
      break;
    case Unit.ENGLISH_COTTON_COUNT:
      result = convertToTextileUnitEnglishCottonCount(unitConversionParameters.from);
      break;
    default:
      throw new TextileUnitConversionError(`No conversion found from ${unitConversionParameters.from.unit} to ${unitConversionParameters.to}`);
  }

  if (isFinite(result)) {
    result = max([round(result, isToUnitTex ? 0 : 2), minValue]);
  } else {
    result = minValue;
  }

  return result;
}
