import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class OperatorRoute extends RouteBranch {
  private _addOperator: RouteLeaf;
  private _editOperator: RouteLeaf;
  private _duplicateOperator: RouteLeaf;
  private _addOperatorType: RouteLeaf;
  private _editOperatorType: RouteLeaf;

  public get addOperator(): RouteLeaf {
    return this._addOperator;
  }

  public set addOperator(value: RouteLeaf) {
    this.replace(this._addOperator, value);
    this._addOperator = value;
  }

  public get editOperator(): RouteLeaf {
    return this._editOperator;
  }

  public set editOperator(value: RouteLeaf) {
    this.replace(this._editOperator, value);
    this._editOperator = value;
  }

  public get duplicateOperator(): RouteLeaf {
    return this._duplicateOperator;
  }

  public set duplicateOperator(value: RouteLeaf) {
    this.replace(this._duplicateOperator, value);
    this._duplicateOperator = value;
  }

  public get addOperatorType(): RouteLeaf {
    return this._addOperatorType;
  }

  public set addOperatorType(value: RouteLeaf) {
    this.replace(this._addOperatorType, value);
    this._addOperatorType = value;
  }

  public get editOperatorType(): RouteLeaf {
    return this._editOperatorType;
  }

  public set editOperatorType(value: RouteLeaf) {
    this.replace(this.editOperatorType, value);
    this._editOperatorType = value;
  }
}
