import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {IdName} from '@domain/id-name';
import {Tool} from '@domain/utilities/tool/tool';
import {CheckName, GridModel} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpToolsService implements Repository<Tool, Tool>, UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<Tool[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tools`).pipe(map((toolsJSON: any) => toolsJSON.map((toolJSON: any) => Tool.fromJSON(toolJSON))));
  }

  public delete(id: number | string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}tools/${id}`);
  }

  public getById(id: number): Observable<Tool> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tools/${id}`).pipe(map((toolJSON: any) => Tool.fromJSON(toolJSON)));
  }

  public save(item: Tool): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}tools`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: Tool): Observable<void | number> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}tools/${item.id}`, item.toJSON());
  }

  public getStockLocations(): Observable<IdName[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}stock-locations`)
      .pipe(map((stockLocationsJSON: any) => stockLocationsJSON.map((stockLocationJSON: any) => IdName.fromJSON(stockLocationJSON))));
  }

  public getPossibleValues(columnIdentifier: string): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tools/${columnIdentifier}/possible-values`).pipe(map((response: string[]) => response));
  }

  public isIdentifierAvailable(identifier: string): Observable<CheckName> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tools/check-name?name=${identifier}`).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getSlice(gridModel: GridModel): Observable<Tool[]> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}tools/get`, gridModel).pipe(map((toolsJSON: any) => toolsJSON.map((toolJSON: any) => Tool.fromJSON(toolJSON))));
  }
}
