import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {WeaveProduct} from '@domain/textile-data/weave-product/weave-product';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ExtendedHttpParams} from '../extended-http-params';
import {GetAllWeaveProductsParameters, WeaveProducts} from './weave-products.interface';

@Injectable()
export class HttpWeaveProductsService implements WeaveProducts {
  private readonly VARIABLE_NAMES_TO_HTTP_PARAMS_MAP = new Map<string, string>([['showOnlyLatestVersion', 'only-latest-versions']]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}weave-products/${id}`);
  }

  public getById(id: string | number): Observable<WeaveProduct> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weave-products/${id}`).pipe(map((weaveProductJSON: any) => WeaveProduct.fromJSON(weaveProductJSON)));
  }

  public save(item: WeaveProduct): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}weave-products`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: WeaveProduct): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}weave-products/${item.id}`, item.toJSON());
  }

  public getAll(parameters?: Partial<GetAllWeaveProductsParameters>): Observable<OverviewListWeaveProduct[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}weave-products`, {params})
      .pipe(map((weaveProductsJSON: any) => weaveProductsJSON.map((weaveProductJSON: any) => OverviewListWeaveProduct.fromJSON(weaveProductJSON))));
  }

  public getAllForMachineId(machineId: number, parameters?: Partial<GetAllWeaveProductsParameters>): Observable<OverviewListWeaveProduct[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}weave-products/machine-id/${machineId}/compatible`, {params})
      .pipe(map((weaveProductsJSON: any) => weaveProductsJSON.map((weaveProductJSON: any) => OverviewListWeaveProduct.fromJSON(weaveProductJSON))));
  }

  public getConflicts(id: string | number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}weave-products/${id}/conflicts`)
      .pipe(map((conflictsJSON: any) => conflictsJSON.map((conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}weave-products/check-name`, {params}).pipe(map((response: any) => CheckName.fromJSON(response)));
  }
}
