import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {PlanningItemData} from '../../planning-items/planning-item-base/planning-item-data';

@Component({
  selector: 'vdw-planning-item-display',
  templateUrl: './planning-item-display.component.html',
  styleUrls: ['./planning-item-display.component.scss']
})
export class PlanningItemDisplayComponent implements OnInit {
  @Input()
  public planningItem: PlanningItemData;

  @HostBinding('class.border')
  public get applyCustomBorder(): boolean {
    return this.planningItem?.applyCustomBorder;
  }

  @HostBinding('class.background')
  public get applyBackground(): boolean {
    return this.planningItem?.applyBackground;
  }

  @HostBinding('class.cursor-pointer')
  public get clickable(): boolean {
    return this.planningItem?.clickable;
  }

  @HostBinding('class.cursor-grab')
  public get draggable(): boolean {
    return this.planningItem?.draggable;
  }

  public constructor(private readonly element: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    if (this.planningItem != null) {
      this.planningItem.displayElement = this.element;
    }
  }
}
