import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {DrawingImageUploadHelper} from '@application/helper/drawing/drawing-image-upload-helper';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {PropertyValue} from '@domain/property-value';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {Drawings, DRAWINGS} from '@infrastructure/http/drawing/drawings';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {BaseComponent, DesignFileType, DialogComponentData, TranslateService, Unit} from '@vdw/angular-component-library';
import {find, isEmpty, isEqual, isNil, map, size, some} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-design.component.html',
  styleUrls: ['./select-design.component.scss']
})
export class SelectDesignComponent extends BaseComponent implements OnInit {
  @ViewChild('searchFilters') public searchFilters: SearchFiltersComponent;
  public listOfDrawings: ListDrawingConfiguration[] = [];
  public selectedDrawings: ListDrawingConfiguration[] = [];
  public canShowAdvancedSearch = false;
  public advancedSearchFilters: PropertyValue[] = [];
  public defaultUnit = Unit.CENTIMETER;
  public loadingDrawings = false;
  public designTranslationKey: string;
  public allowSelectMultipleDrawings: boolean;
  public readonly ALLOWED_FILE_EXTENSION_FOR_FILES = [DesignFileType.BMP, DesignFileType.EP, DesignFileType.TFT, DesignFileType.PVD].join();

  private hasFilterActive = false;
  private canGetMoreDrawings = false;

  private readonly bmpDrawingsOnly: PropertyValue[] = [{propertyName: AdvancedSearchInput.DRAWING_TYPE, propertyValue: DrawingType.BMP}];
  private readonly SLICE_LENGTH = 50;
  private readonly nameFilterChangeSubject: Subject<string> = new Subject<string>();
  private readonly dialogRef: MatDialogRef<SelectDesignComponent>;
  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private data: {translationKey: string; allowSelectMultipleDrawings?: boolean; selected?: Drawing[]},
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    dialogRef: MatDialogRef<SelectDesignComponent>,
    changeDetectorRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly drawingImageUploadHelper: DrawingImageUploadHelper
  ) {
    super();

    this.dialogRef = dialogRef;
    this.changeDetectorRef = changeDetectorRef;
    this.designTranslationKey = data?.translationKey
      ? data.translationKey
      : this.translate.instant('DESIGN_LIBRARY.DESIGN', {
          object: 1
        });
    this.allowSelectMultipleDrawings = data?.allowSelectMultipleDrawings ?? false;
    if (data?.selected) {
      this.selectedDrawings.push(...map(data.selected, (drawing: Drawing) => new ListDrawingConfiguration(drawing, 0)));
    }
    this.drawingImageUploadHelper.unSubscribeOnViewDestroy = this.unSubscribeOnViewDestroy;
  }

  public ngOnInit(): void {
    this.getFirstSliceOfDesigns();
    this.initializeAdvancedSearchFilters();
    this.subscribeToNameFilterChanges();
  }

  public hasDrawings(): boolean {
    return !isEmpty(this.listOfDrawings);
  }

  public onDrawingSelected(drawingConfigurations: ListDrawingConfiguration[]): void {
    this.selectedDrawings = drawingConfigurations;
  }

  public canSelectDesign(): boolean {
    return !isEmpty(this.selectedDrawings);
  }

  public selectDesign(): void {
    this.dialogRef.close(map(this.selectedDrawings, (drawingConfiguration: ListDrawingConfiguration) => drawingConfiguration.drawing));
  }

  public onDrawingDoubleClicked(drawingConfiguration: ListDrawingConfiguration): void {
    this.onDrawingSelected([drawingConfiguration]);
    this.selectDesign();
  }

  public showAdvancedSearch(): void {
    this.canShowAdvancedSearch = true;
  }

  public closeAdvancedSearch(): void {
    this.canShowAdvancedSearch = false;
  }

  public updateAdvancedSearchFilters(updatedAdvancedSearchFilters?: PropertyValue[]): void {
    if (!isNil(updatedAdvancedSearchFilters)) {
      this.advancedSearchFilters = updatedAdvancedSearchFilters;
      this.closeAdvancedSearch();
      this.changeDetectorRef.detectChanges();
      this.searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();
    }
    this.checkIfHasFilterActive();
    this.getFirstSliceOfDesigns();
  }

  public onNameFilterChange(name: string): void {
    this.nameFilterChangeSubject.next(name);
  }

  public getNameFilterValue(): string {
    return find(this.advancedSearchFilters, {propertyName: AdvancedSearchInput.NAME}).propertyValue;
  }

  public canShowNoResultOverlay(): boolean {
    return this.hasFilterActive && !this.hasDrawings();
  }

  public canShowFilterInput(): boolean {
    return this.loadingDrawings || this.hasFilterActive || this.hasDrawings();
  }

  public onVirtualScrollerEnd(): void {
    if (this.canGetMoreDrawings) {
      this.canGetMoreDrawings = false;
      this.getDesigns();
    }
  }

  public onDrawingFileChange(files: File[]): void {
    this.drawingImageUploadHelper.uploadFiles(files, {dialogComponent: SelectDesignComponent} as DialogComponentData<typeof SelectDesignComponent>);
  }

  private getDesigns(): void {
    this.drawings
      .getSlice(size(this.listOfDrawings), this.SLICE_LENGTH, 'thumbs', false, TargetForListOfDrawingsEnum.PRODUCTION_ORDER, this.getAdvancedSearchFiltersWithRestrictions())
      .pipe(
        finalize(() => (this.loadingDrawings = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((drawings: Drawing[]) => {
        this.listOfDrawings.push(...map(drawings, (drawing: Drawing) => new ListDrawingConfiguration(drawing, 0)));

        this.canGetMoreDrawings = isEqual(size(drawings), this.SLICE_LENGTH);
      });
  }

  private initializeAdvancedSearchFilters(): void {
    const shapeFilter: PropertyValue = {propertyName: AdvancedSearchInput.DRAWING_SHAPE};
    this.advancedSearchFilters.push(
      {propertyName: AdvancedSearchInput.NAME},
      {propertyName: AdvancedSearchInput.DRAWING_CODE},
      {propertyName: AdvancedSearchInput.LENGTH},
      {propertyName: AdvancedSearchInput.WIDTH},
      shapeFilter,
      {propertyName: AdvancedSearchInput.MACHINE_QUALITY},
      {propertyName: AdvancedSearchInput.COLOR_SET},
      {propertyName: AdvancedSearchInput.MAIN_COLOR},
      {propertyName: AdvancedSearchInput.BORDER_COLOR}
    );
    this.drawings
      .getDrawingShapes()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((drawingShapes: string[]) => {
        if (!isNil(drawingShapes)) {
          shapeFilter.possiblePropertyValues = drawingShapes;
        }
      });
  }

  private subscribeToNameFilterChanges(): void {
    this.nameFilterChangeSubject.pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy)).subscribe((name: string) => {
      find(this.advancedSearchFilters, {propertyName: AdvancedSearchInput.NAME}).propertyValue = name;

      this.checkIfHasFilterActive();

      this.searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();
      this.getFirstSliceOfDesigns();
    });
  }

  private checkIfHasFilterActive(): void {
    this.hasFilterActive = some(this.advancedSearchFilters, (advancedSearchFilter: PropertyValue) => AdvancedSearchUtils.hasValue(advancedSearchFilter));
  }

  private getFirstSliceOfDesigns(): void {
    this.listOfDrawings = [];
    this.loadingDrawings = true;
    this.getDesigns();
  }

  private getAdvancedSearchFiltersWithRestrictions(): PropertyValue[] {
    return [...this.advancedSearchFilters, ...this.bmpDrawingsOnly];
  }
}
