import {BaseMachineType} from '@domain/machine/base-machine-type';
import {EquipmentManufacturer} from '@domain/machine/equipment-manufacturer';

export class WinderMachineType extends BaseMachineType {
  public constructor(id: number, name: string, model: string, manufacturer: EquipmentManufacturer) {
    super(id, name, manufacturer, model);
  }

  public static fromJSON(winderMachineTypeJSON: any): WinderMachineType {
    return new WinderMachineType(
      winderMachineTypeJSON.id,
      winderMachineTypeJSON.name,
      winderMachineTypeJSON.model,
      new EquipmentManufacturer(winderMachineTypeJSON.manufacturerId, winderMachineTypeJSON.manufacturerName)
    );
  }
}
