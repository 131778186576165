import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {MachineOverviewListHelper} from '@presentation/pages/machine-overview/machine-overview-list-helper';
import {ArrayUtils, AssertionUtils} from '@vdw/angular-component-library';

export class OverviewListCreel {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _coloredYarnSetsWithStartDent: OverviewListColoredYarnSetWithStartDent[];
  private readonly _used: boolean;
  private readonly _usedInPo: boolean;
  private readonly _isColoredYarnSplitPositionedCorrectly: boolean;
  private _mappingForCurrentPositionOnMachine: CreelMap[];
  private _machines: MachineOverviewList[];

  public constructor(
    id: number,
    name: string,
    used: boolean,
    usedInPo: boolean,
    isColoredYarnSplitPositionedCorrectly: boolean,
    coloredYarnSetsWithStartDent: OverviewListColoredYarnSetWithStartDent[] = null,
    mappingForCurrentPositionOnMachine: CreelMap[] = null,
    machines: MachineOverviewList[] = []
  ) {
    this._id = id;
    this._name = name;
    this._coloredYarnSetsWithStartDent = coloredYarnSetsWithStartDent;
    this._mappingForCurrentPositionOnMachine = mappingForCurrentPositionOnMachine;
    this._used = used;
    this._usedInPo = usedInPo;
    this._isColoredYarnSplitPositionedCorrectly = isColoredYarnSplitPositionedCorrectly;
    this._machines = machines;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get mappingForCurrentPositionOnMachine(): CreelMap[] {
    return this._mappingForCurrentPositionOnMachine;
  }

  public set mappingForCurrentPositionOnMachine(mappingForCurrentPositionOnMachine: CreelMap[]) {
    this._mappingForCurrentPositionOnMachine = mappingForCurrentPositionOnMachine;
  }

  public get coloredYarnSetsWithStartDent(): OverviewListColoredYarnSetWithStartDent[] {
    return this._coloredYarnSetsWithStartDent;
  }

  public get used(): boolean {
    return this._used;
  }

  public get usedInPo(): boolean {
    return this._usedInPo;
  }

  public get isColoredYarnSplitPositionedCorrectly(): boolean {
    return this._isColoredYarnSplitPositionedCorrectly;
  }

  public get machines(): MachineOverviewList[] {
    return this._machines;
  }

  public set machines(machines: MachineOverviewList[]) {
    this._machines = machines;
  }

  public static fromJSON(creelJSON: any): OverviewListCreel {
    if (AssertionUtils.isNullOrUndefined(creelJSON)) {
      return null;
    }
    return new OverviewListCreel(
      creelJSON.id,
      creelJSON.name,
      creelJSON.used,
      creelJSON.usedInPo,
      creelJSON.isColoredYarnSplitPositionedCorrectly,
      ArrayUtils.toSortedBy(creelJSON.coloredYarnSetsWithStartDents.map(OverviewListColoredYarnSetWithStartDent.fromJSON), (yarnSet: OverviewListColoredYarnSetWithStartDent) => yarnSet.startDent),
      creelJSON.mappingForCurrentPositionOnMachine,
      creelJSON.machines?.map(MachineOverviewListHelper.fromJSON)
    );
  }

  public static createEmptyCreel(): OverviewListCreel {
    return new OverviewListCreel(null, '', null, false, null, [], null);
  }

  public getColoredYarnSetsName(): string {
    return this._coloredYarnSetsWithStartDent.map((coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) => coloredYarnSetWithStartDent.coloredYarnSet.name).join(', ');
  }

  public toJSON(): JSON {
    return {
      name: this._name,
      coloredYarnSetsWithStartDents: this.coloredYarnSetsWithStartDent.map((coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) => {
        return {
          id: coloredYarnSetWithStartDent.coloredYarnSet.id,
          startDent: coloredYarnSetWithStartDent.startDent ?? 0
        };
      }),
      mappingForCurrentPositionOnMachine: this._mappingForCurrentPositionOnMachine,
      machines: this.machines.map((machine: MachineOverviewList) => machine.id)
    } as any as JSON;
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }

  public getOriginalMappingForCurrentPositionOnMachine(): CreelMap[] {
    return this._coloredYarnSetsWithStartDent.map((coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) =>
      coloredYarnSetWithStartDent.getOriginalMappingForCurrentPositionOnMachine()
    );
  }
}
