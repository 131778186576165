import {PlanningCreel} from '@domain/planning/carpet/planning-creel';
import {PlanningMachineQuality} from '@domain/planning/carpet/planning-machine-quality';
import {ProductionOrderCarpetForPlanningOrder} from '@domain/planning/carpet/production-order-carpet-for-planning-order';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';

export class ProductionOrderCarpetForPlanningOrderStubFactory {
  public static getRandomProductionOrder(): ProductionOrderCarpetForPlanningOrder {
    const productionOrder = new ProductionOrderCarpetForPlanningOrder(1, 'ProductionOrder_1', new Date(), ProductionScheduleStatus.ARRIVED);

    productionOrder.machineQuality = new PlanningMachineQuality(1, 'MQ 01', 1);
    productionOrder.creel = new PlanningCreel(1, 'Creel 01');
    return productionOrder;
  }

  public static getRandomWithIdAndDueDate(id: number, dueDate: Date): ProductionOrderCarpetForPlanningOrder {
    return new ProductionOrderCarpetForPlanningOrder(id, `ProductionOrder_${id}`, dueDate, ProductionScheduleStatus.ARRIVED);
  }

  public static getRandomProductionOrderWithStatusValue(status: ProductionScheduleStatus): ProductionOrderCarpetForPlanningOrder {
    return new ProductionOrderCarpetForPlanningOrder(1, 'ProductionOrder_1', new Date(), status);
  }

  public static getRandomProductionOrderWithIdAndStatusValue(id: number, status: ProductionScheduleStatus): ProductionOrderCarpetForPlanningOrder {
    return new ProductionOrderCarpetForPlanningOrder(id, `ProductionOrder_${id}`, new Date(), status);
  }

  public static getRandomProductionOrderWithIdMachineQualityIdAndCreelId(id: number, machineQualityId: number, creelId: number): ProductionOrderCarpetForPlanningOrder {
    const productionOrder = new ProductionOrderCarpetForPlanningOrder(id, `ProductionOrder_${id}`, new Date(), ProductionScheduleStatus.TO_PLAN);

    productionOrder.machineQuality = new PlanningMachineQuality(machineQualityId, `MQ ${id}`, 1);
    productionOrder.creel = new PlanningCreel(creelId, `Creel ${id}`);

    return productionOrder;
  }

  public static getRandomWithIdAndName(id: number, name: string): ProductionOrderCarpetForPlanningOrder {
    return new ProductionOrderCarpetForPlanningOrder(id, name, new Date(), ProductionScheduleStatus.ARRIVED);
  }
}
