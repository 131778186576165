import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {isUndefined} from 'lodash-es';

@Component({
  selector: 'app-yarn-type-grid-preview',
  templateUrl: './yarn-type-grid-preview.component.html',
  styleUrls: ['./yarn-type-grid-preview.component.scss']
})
export class YarnTypeGridPreviewComponent implements AfterViewChecked {
  @Input() public yarnTypes: YarnType[];
  @Input() public showFirstRawMaterial = true;
  @Input() public tooltipDisabled = false;
  @ViewChild('name') public name: ElementRef;
  public showTooltip = false;
  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.name) && this.name.nativeElement.offsetWidth < this.name.nativeElement.scrollWidth && !this.tooltipDisabled;
  }
}
