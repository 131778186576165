import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DataUnitSetupRoute extends RouteBranch {
  private _addDataUnitSetupFromCatalog: RouteLeaf;
  private _addDataUnitSetup: RouteLeaf;
  private _editDataUnitSetup: RouteLeaf;
  private _customSetting: RouteLeaf;

  public get addDataUnitSetupFromCatalog(): RouteLeaf {
    return this._addDataUnitSetupFromCatalog;
  }

  public set addDataUnitSetupFromCatalog(value: RouteLeaf) {
    this.replace(this._addDataUnitSetupFromCatalog, value);
    this._addDataUnitSetupFromCatalog = value;
  }

  public get addDataUnitSetup(): RouteLeaf {
    return this._addDataUnitSetup;
  }

  public set addDataUnitSetup(value: RouteLeaf) {
    this.replace(this._addDataUnitSetup, value);
    this._addDataUnitSetup = value;
  }

  public get editDataUnitSetup(): RouteLeaf {
    return this._editDataUnitSetup;
  }

  public set editDataUnitSetup(value: RouteLeaf) {
    this.replace(this._editDataUnitSetup, value);
    this._editDataUnitSetup = value;
  }

  public get customSetting(): RouteLeaf {
    return this._customSetting;
  }

  public set customSetting(value: RouteLeaf) {
    this.replace(this._customSetting, value);
    this._customSetting = value;
  }
}
