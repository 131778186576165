<app-textile-data-overview
  #textileDataOverview
  iconForCard="production-order-blue"
  [listOfTextileData]="listOfProductionOrdersWeaving"
  [columnDefs]="getColumnDefsForListOfProductionOrdersWeaving()"
  [textileDataService]="productionOrdersWeaving"
  [textileDataType]="textileDataType.PRODUCTION_ORDER_WEAVING"
  [gridIdentifier]="GRID_IDENTIFIER"
  [showSearchBar]="false"
  (customSettingsSetup)="navigateToCustomSettings()"
>
</app-textile-data-overview>
