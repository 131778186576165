import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ReportItemRoute extends RouteBranch {
  private _editReportItem: RouteLeaf;

  public get editReportItem(): RouteLeaf {
    return this._editReportItem;
  }

  public set editReportItem(value: RouteLeaf) {
    this.replace(this._editReportItem, value);
    this._editReportItem = value;
  }
}
