import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {StringUtils} from '@application/helper/string-utils';
import {ConflictedOrderLine} from '@presentation/pages/texfab/production-schedule/confirm/conflicted-order-line';
import {ConflictWithOrderlinesDialogResult} from '@presentation/pages/texfab/production-schedule/confirm/conflicts-with-order-lines/conflict-with-orderlines-dialog-result';
import {ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE, SelectGridDialog, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ValueGetterParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {ConflictsWithOrderLinesAmountComponent} from './amount/conflicts-with-order-lines-amount.component';

@Component({
  selector: 'app-conflicts-with-order-lines',
  templateUrl: './conflicts-with-order-lines.component.html',
  styleUrls: ['./conflicts-with-order-lines.component.scss']
})
export class ConflictsWithOrderLinesComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('conflictsWithOrderLinesGrid') public conflictsWithOrderLinesGrid: AgGridAngular;
  @Input() public conflictedOrderLines: ConflictedOrderLine[];
  @Output() public closeConflicts: EventEmitter<ConflictWithOrderlinesDialogResult> = new EventEmitter();
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  public constructor(
    @Inject(RESPONSIVENESS_VIEW_MODE) private responsivenessViewMode: ResponsivenessViewMode,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private readonly dialogRef: MatDialogRef<ConflictsWithOrderLinesComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    if (!isNil(data)) {
      this.conflictedOrderLines = data.conflictedOrderLines;
    }
  }

  public get gridOptionsForConflictsWithOrderLines(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.initialiseGridOptionsForConflictsWithOrderLines();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.conflictsWithOrderLinesGrid?.api];
  }

  public cancel(): void {
    this.closeConflicts.emit(null);
  }

  public getChooseEventName(): string {
    const adjustOrderPlanTranslationKey = this.translate.instant('GENERAL.ACTIONS.ADJUST_OBJECT', {object: this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.WEAVE_PLAN').toLowerCase()});
    return this.isMobile() ? 'GENERAL.ACTIONS.IGNORE' : adjustOrderPlanTranslationKey;
  }

  public chooseEvent(): void {
    this.isMobile() ? this.dismiss() : this.goToPlan();
  }

  public dismiss(): void {
    if (!this.isMobile()) {
      this.dialogRef.close(ConflictWithOrderlinesDialogResult.DISMISS);
    } else {
      this.closeConflicts.emit(ConflictWithOrderlinesDialogResult.DISMISS);
    }
  }

  public isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }

  private goToPlan(): void {
    if (!this.isMobile()) {
      this.dialogRef.close(ConflictWithOrderlinesDialogResult.ADJUST_ORDER_PLAN);
    } else {
      this.closeConflicts.emit(ConflictWithOrderlinesDialogResult.ADJUST_ORDER_PLAN);
    }
  }

  private initialiseGridOptionsForConflictsWithOrderLines(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForConflictsWithOrderLine(), GridIdentifier.ORDER_LINES_CONFLICTS)
        .withRowHeight(this.isMobile() ? 115 : 32)
        .withoutGetRowId()
        .withoutSorting()
        .withSuppressRowClickSelection()
        .build()
    ];
  }

  private getColumnDefsForConflictsWithOrderLine(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('ORDERBOOK.ORDERBOOK_REFERENCE')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getValueForOrderNumber(params.data), true)
        .build(),
      ...(this.isMobile()
        ? [
            this.colDefBuilderFactoryService
              .getBuilder()
              .withHeaderName('GENERAL.AMOUNT')
              .withValueGetter((params: ValueGetterParams) => {
                return [params.data.requestedAmount, params.data.amountInSchedule, params.data.amountAlreadyPlanned, params.data.amountTooMuchPlanned];
              })
              .withCellRenderer(ConflictsWithOrderLinesAmountComponent)
              .withRightAlignment()
              .build()
          ]
        : [
            this.colDefBuilderFactoryService.getBuilder().withField('requestedAmount').withHeaderName('ORDERBOOK.REQUESTED').withRightAlignment().build(),
            this.colDefBuilderFactoryService.getBuilder().withField('amountInSchedule').withHeaderName('ORDERBOOK.CURRENTLY_IN_PLAN').withRightAlignment().build(),
            this.colDefBuilderFactoryService.getBuilder().withField('amountInSchedule').withHeaderName('ORDERBOOK.ALREADY_PLANNED').withRightAlignment().build(),
            this.colDefBuilderFactoryService.getBuilder().withField('amountTooMuchPlanned').withHeaderName('ORDERBOOK.TOO_MUCH').withRightAlignment().build()
          ])
    ];
  }

  private getValueForOrderNumber(params: ConflictedOrderLine): string {
    return `${params.salesOrderReference}, ${params.salesOrderLineReference}`;
  }
}
