import {Injectable} from '@angular/core';
import {DialogOpenerService} from '../dialog-opener/dialog-opener.service';
import {RepositionWatchDialogFactoryService} from '../dialog-reposition/reposition-watch-dialog-factory.service';
import {DialogBuilder} from './dialog-builder';

@Injectable()
export class DialogBuilderFactoryService {
  public constructor(
    private readonly repositionDialogWatchFactoryService: RepositionWatchDialogFactoryService,
    private readonly dialogOpener: DialogOpenerService
  ) {}

  public getBuilder(): DialogBuilder {
    return new DialogBuilder(this.repositionDialogWatchFactoryService.getService(), this.dialogOpener);
  }
}
