import {InjectionToken} from '@angular/core';
import {ProductionOrderCreated} from '@infrastructure/signalr/production-order/production-order-created';
import {Observable} from 'rxjs';
import {ProductionOrderStatusChange} from './production-order-status-change';

export const REALTIME_PRODUCTION_ORDER = new InjectionToken<RealtimeProductionOrder>('RealtimeProductionOrder');

export interface RealtimeProductionOrder {
  getProductionOrdersStatusChanges(): Observable<ProductionOrderStatusChange>;

  getProductionOrderCreated(): Observable<ProductionOrderCreated>;
}
