import {AssertionUtils} from '@vdw/angular-component-library';
import {PlanningMachineCarpetRun} from './carpet/planning-machine-carpet-run';
import {PlanningMachineRun} from './planning-machine-run';
import {PlanningMachineTuftingRun} from './tufting/planning-machine-tufting-run';
import {PlanningMachineWeavingRun} from './weaving/planning-machine-weaving-run';

export class PlanningMachineRunFactory {
  public static fromJSON(planningMachineRunJSON: any, timezoneCode: string): PlanningMachineRun {
    if (!AssertionUtils.isNullOrUndefined(planningMachineRunJSON.weaveProduct)) {
      return PlanningMachineWeavingRun.fromJSON(planningMachineRunJSON, timezoneCode);
    } else if (!AssertionUtils.isNullOrUndefined(planningMachineRunJSON.tuftProduct)) {
      return PlanningMachineTuftingRun.fromJSON(planningMachineRunJSON, timezoneCode);
    } else if (this.checkForInvalidProductionOrderLite(planningMachineRunJSON)) {
      return null;
    }

    return PlanningMachineCarpetRun.fromJSON(planningMachineRunJSON, timezoneCode);
  }

  private static checkForInvalidProductionOrderLite(planningMachineRunJSON: any): boolean {
    if (AssertionUtils.isNullOrUndefined(planningMachineRunJSON.planningOrders)) {
      return false;
    }
    return planningMachineRunJSON.planningOrders.some((planningOrderJson: any) => !AssertionUtils.isNullOrUndefined(planningOrderJson.productionOrderLite));
  }
}
