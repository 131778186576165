import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {ProductionOrderPlastic} from './production-order-plastic';

export class OrderPlastic extends PlanningItem {
  private _productionOrder: ProductionOrderPlastic;
  private _producedQuantity: number;
  private _estimatedProductionTime: string;

  public constructor(itemProperties: PlanningItemProperties, estimatedProductionTime: string, productionOrder: ProductionOrderPlastic, producedQuantity: number = 0, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.ORDER_PLASTIC, ...planningItems);
    this._productionOrder = productionOrder;
    this._producedQuantity = producedQuantity;
    this._estimatedProductionTime = estimatedProductionTime;
  }

  public get productionOrder(): ProductionOrderPlastic {
    return this._productionOrder;
  }

  public get producedQuantity(): number {
    return this._producedQuantity;
  }

  public get estimatedProductionTime(): string {
    return this._estimatedProductionTime;
  }

  public set estimatedProductionTime(value: string) {
    this._estimatedProductionTime = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      productionOrderLiteId: this.productionOrder.id,
      estimatedProductionTime: this.estimatedProductionTime,
      toolId: this.productionOrder.tool?.id,
      mountTime: this.productionOrder.tool?.mountTime,
      dismountTime: this.productionOrder.tool?.dismountTime
    } as any as JSON;
  }
}
