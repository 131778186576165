import {Component} from '@angular/core';
import {TranslateService} from '@vdw/angular-component-library';

@Component({
  selector: 'app-loading-file',
  templateUrl: './loading-file.component.html',
  styleUrls: ['./loading-file.component.scss']
})
export class LoadingFileComponent {
  private translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public getTitle(): string {
    return this.translate.instant('MACHINE_DOCUMENTATION.LOADING_FILE', null, this.translate.getLocale().language);
  }

  public getText(): string {
    return this.translate.instant('MACHINE_DOCUMENTATION.PLEASE_WAIT', null, this.translate.getLocale().language);
  }
}
