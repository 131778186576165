import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function notEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value || value.trim() === '') {
      return {required: true};
    }

    return null;
  };
}
