<div class="wrapper flex-row flex-space-between gap-16">
  <div class="flex-row flex-end-center gap-16 overflow-hidden header-title-wrapper">
    @if (hasBackButton()) {
      <button mat-icon-button class="back" (click)="backButtonClicked()">
        <mat-icon svgIcon="back"></mat-icon>
      </button>
    }
    @if (svgIcon) {
      <div class="avatar">
        <mat-icon [svgIcon]="svgIcon"></mat-icon>
      </div>
    }
    <div class="flex-column flex-1 overflow-hidden">
      <h3 class="flex-row">
        @if (action) {
          <span class="font-weight-semibold no-wrap">{{ action + '&nbsp;' }}</span>
        }
        <vdw-ellipsis-label [text]="title" matTooltipPosition="below" class="overflow-hidden"></vdw-ellipsis-label>
      </h3>
      @if (extraInformationTitle) {
        <span class="b1 font-weight-semibold">{{ extraInformationTitle }}</span>
      }
      @if (canShowAmount()) {
        <p class="b1 font-weight-semibold">({{ amount }})</p>
      }
    </div>
  </div>

  <div class="skeleton-wrapper" [class.skeleton]="isLoading" [@fadeIn]="isLoading">
    <div class="flex-row flex-end-center gap-16">
      @if (invalidFormMessageError) {
        <vdw-invalid-form-message class="no-wrap overflow-hidden"></vdw-invalid-form-message>
      } @else if (showCustomErrorMessage) {
        <vdw-invalid-form-message class="no-wrap overflow-hidden" [message]="customErrorMessage | bmsTranslate"></vdw-invalid-form-message>
      }
      @if (canShowSettingsButton()) {
        <div class="button-skeleton-wrapper standard-btn">
          <button
            mat-icon-button
            class="mat-icon-action-button-lg-with-border disable-focus"
            [tabindex]="headerTabIndex.tabIndexCustomSettings"
            [matTooltip]="settingsTooltip ?? '' | bmsTranslate"
            [matTooltipDisabled]="!settingsTooltip"
            (click)="settingsButtonClicked()"
          >
            <mat-icon svgIcon="settings"></mat-icon>
          </button>
        </div>
      }
      <ng-content></ng-content>
      @if (canShowDivider()) {
        <mat-divider vertical class="vertical-divider"></mat-divider>
      }

      @if (canShowMoreButton()) {
        <div class="button-skeleton-wrapper standard-btn">
          <button
            mat-icon-button
            class="mat-icon-action-button-lg-with-border disable-focus more-button"
            #optionMenuTrigger
            [matMenuTriggerFor]="optionsMenu"
            (mouseenter)="mouseEnterOptionButton()"
            (mouseleave)="mouseLeaveOptionButton()"
          >
            <mat-icon svgIcon="more"></mat-icon>
          </button>
        </div>
      }
      <mat-menu #optionsMenu="matMenu" yPosition="below" class="mat-elevation-z0" [hasBackdrop]="false">
        <div (mouseenter)="mouseEnterOptionButton()" (mouseleave)="mouseLeaveOptionButton()">
          @if (hasDuplicateButton()) {
            <button mat-menu-item (click)="duplicateButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DUPLICATE' | bmsTranslate }}</button>
          }
          @if (hasDeleteButton()) {
            <button mat-menu-item [disabled]="isInUse" (click)="deleteButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DELETE' | bmsTranslate }}</button>
          }
          @for (headerAction of headerActions; track $index) {
            <button mat-menu-item [disabled]="headerAction.disabled" (click)="headerActionClicked($event, headerAction)">{{ headerAction.label }}</button>
          }
        </div>
      </mat-menu>
      @if (canShowCancelButton()) {
        <div class="button-skeleton-wrapper standard-btn">
          <button mat-stroked-button (click)="cancelButtonClicked()" [tabindex]="headerTabIndex.tabIndexCancel">
            {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.CANCEL' | bmsTranslate }}
          </button>
        </div>
      }
      @if (canShowSaveButton() || canShowInUseButton()) {
        <div class="button-skeleton-wrapper raised-btn">
          @if (canShowSaveButton()) {
            <vdw-progress-button
              [tabIndex]="headerTabIndex.tabIndexSave"
              [disabled]="locked"
              [inProgress]="saving"
              (progressClick)="save(defaultSaveType)"
              [hasIcon]="hasSaveMenu()"
              [label]="getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate}"
            >
              @if (hasSaveMenu()) {
                <div
                  #saveMenuTrigger
                  class="full-height flex-center save-menu-trigger"
                  [ngClass]="{'display-save-menu': locked, 'hide-icon': saving}"
                  [matMenuTriggerFor]="showSaveMenu()"
                  (click)="$event.stopPropagation()"
                  (mouseenter)="mouseEnterSaveButton()"
                  (mouseleave)="mouseLeaveSaveButton()"
                >
                  <mat-icon svgIcon="chevron-down"></mat-icon>
                </div>
              }
            </vdw-progress-button>
          }
          @if (canShowInUseButton()) {
            <button mat-stroked-button color="warn" (click)="inUseButtonClicked()">
              <mat-icon svgIcon="error"></mat-icon>
              {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ALREADY_USED' | bmsTranslate }}
            </button>
          }
        </div>
      }
      <mat-menu #saveMenu="matMenu" yPosition="below" class="mat-elevation-z0" [hasBackdrop]="false">
        <div (mouseenter)="mouseEnterSaveButton()" (mouseleave)="mouseLeaveSaveButton()">
          <button *ngIf="hasSaveMenuButton()" mat-menu-item (click)="save(SAVE)">{{ actionText | bmsTranslate }}</button>
          <button *ngIf="hasSaveAndCloseMenuButton()" mat-menu-item (click)="save(SAVE_AND_CLOSE)">
            {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CLOSE' | bmsTranslate: {action: actionText | bmsTranslate} }}
          </button>
          <button *ngIf="hasSaveAndCreateNewMenuButton()" mat-menu-item (click)="save(SAVE_AND_CREATE_NEW)">
            {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CREATE_NEW' | bmsTranslate: {action: actionText | bmsTranslate} }}
          </button>
        </div>
      </mat-menu>

      <div *ngIf="canShowClose">
        <button *ngIf="!closeEvent.observed" mat-icon-button [mat-dialog-close] class="mat-icon-action-button-sm-without-border">
          <mat-icon svgIcon="close"></mat-icon>
        </button>

        <button *ngIf="closeEvent.observed" mat-icon-button class="mat-icon-action-button-sm-without-border" (click)="closeClicked()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
