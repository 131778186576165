<div class="flex-column full-height">
  <vdw-tabs-menu-card
    icon="builder-blue"
    [title]="'GENERAL.CUSTOM_SETTINGS.SETTINGS_FOR_OBJECT' | bmsTranslate: {object: 'PRODUCTION_ORDER.PRODUCTION_ORDER' | bmsTranslate: {count: 1}}"
    [saving]="saving"
    (backEvent)="navigateBack()"
    (cancelEvent)="navigateBack()"
    (saveEvent)="saveCustomSettings()"
  ></vdw-tabs-menu-card>
  <mat-card class="one-third-width full-height no-margin">
    <mat-card-content>
      <article>
        <form [formGroup]="tuftProductionOrderCustomSettingsForm">
          <app-name-generation-pattern
            [nameGenerationProperties]="nameGenerationProperties"
            [namePlaceholderSeparator]="tuftProductionOrderCustomSettingsForm.value.namePlaceholderSeparator"
            [nameGeneratorParts]="generateNameConfiguration?.parts"
            (nameGeneratorPartsChange)="generateNameConfiguration.parts = $event"
          ></app-name-generation-pattern>
          <mat-form-field class="full-width">
            <mat-label>{{ 'GENERAL.PLACEHOLDER.NAME_PLACEHOLDER_SEPARATOR' | bmsTranslate }}</mat-label>
            <mat-select formControlName="namePlaceholderSeparator" disableOptionCentering>
              <mat-option *ngFor="let nameGeneratorSeparator of getKeysOfNameGeneratorSeparator()" [value]="nameGeneratorSeparator">
                {{ 'GENERAL.PLACEHOLDER.SEPARATOR.' + nameGeneratorSeparator | bmsTranslate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </article>
    </mat-card-content>
  </mat-card>
</div>
