import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class SuperbawinderMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.SUPERBAWINDER, name, orderId);
  }

  public static fromJSON(superbawinderMachineJSON: any): SuperbawinderMachine {
    return new SuperbawinderMachine(superbawinderMachineJSON.id, superbawinderMachineJSON.name, superbawinderMachineJSON.orderId);
  }
}
