import {Inject, Injectable} from '@angular/core';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {HttpBuggyService} from '@infrastructure/http/buggy/http-buggy.service';
import {PRODUCTION_ORDERS, ProductionOrders} from '@infrastructure/http/production-order/production-orders';
import {Observable} from 'rxjs';

@Injectable()
export class ProductionOrdersPlaceholderService {
  public constructor(
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    private readonly buggies: HttpBuggyService
  ) {}

  public getPlaceholderCategories(isNameGeneration: boolean, isBuggySelection: boolean): Observable<PlaceholderCategory[]>[] {
    const requests: Observable<PlaceholderCategory[]>[] = [];
    requests.push(this.productionOrders.getCategoriesWithComponentsForProductionOrderLabel());

    if (isNameGeneration) {
      requests.push(this.productionOrders.getCategoriesWithComponentsForProductionOrderName());
    }
    if (isBuggySelection) {
      requests.push(this.buggies.getCategoriesWithComponentsForBuggyName());
    }

    return requests;
  }
}
