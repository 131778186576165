import {Permission} from '@domain/profile/permission.enum';
import {AssertionUtils} from '@vdw/angular-component-library';

export abstract class RouteNode {
  private readonly _path: string;
  private readonly _requiredPermission: Permission;
  private readonly _isMobileFriendly: boolean;
  private readonly _isMobileOnly: boolean;
  private _parent: RouteNode;

  protected constructor(path: string, requiredPermission: Permission, isMobileFriendly: boolean = false, isMobileOnly: boolean = false) {
    this._path = path;
    this._requiredPermission = requiredPermission;
    this._isMobileFriendly = isMobileFriendly;
    this._isMobileOnly = isMobileOnly;
  }

  public get parent(): RouteNode {
    return this._parent;
  }

  public set parent(parent: RouteNode) {
    this._parent = parent;
  }

  public get path(): string {
    return this._path;
  }

  public get requiredPermission(): Permission {
    return this._requiredPermission;
  }

  public get isMobileFriendly(): boolean {
    return this._isMobileFriendly;
  }

  public get isMobileOnly(): boolean {
    return this._isMobileOnly;
  }

  public get hasParameter(): boolean {
    return this._path.includes('/:');
  }

  public get amountOfRouteParameters(): number {
    return this.hasParameter ? this._path.match(/\/:/g).length : 0;
  }

  public get absolutePath(): string {
    return RouteNode.getAbsolutePath(this);
  }

  private static getAbsolutePath(node: RouteNode, accumulatedPath: string = ''): string {
    let result = node.path + accumulatedPath;

    if (!AssertionUtils.isEmpty(node.path)) {
      result = '/' + result;
    }

    if (node.parent) {
      result = RouteNode.getAbsolutePath(node.parent, result);
    }

    return result;
  }

  public matchesPath(path: string): boolean {
    return this.hasParameter ? this.path.startsWith(path) : this.path === path;
  }

  public getRoutes(): RouteNode[] {
    const mappedKeys = Object.keys(this).map((key: string) => this[key]);

    return mappedKeys.filter((property: RouteNode[] | RouteNode | Record<string, unknown>) => typeof property === 'object' && !(property instanceof Array));
  }

  public abstract isKnownPath(pathSegments: string[]): boolean;
}
