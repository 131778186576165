import {Observable, Observer} from 'rxjs';

export function observeIntersection(target: HTMLElement, threshold: number = 0.1): Observable<boolean> {
  return new Observable((observer: Observer<boolean>) => {
    const intersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        observer.next(entries[entries.length - 1].isIntersecting);
      },
      {
        threshold
      }
    );

    intersectionObserver.observe(target);

    return function unsubscribe(): void {
      intersectionObserver.unobserve(target);
      intersectionObserver.disconnect();
    };
  });
}
