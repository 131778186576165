import {CommercialDimensions} from '@domain/commercial-dimensions';
import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {CutbarsLength} from '@domain/production-schedule/cutbars-length';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {AssertionUtils} from '@vdw/angular-component-library';
import {isEqual, isNil} from 'lodash-es';
import {TechnicalDimensions} from '../technical-dimensions';
import {MachineQualityForProductionSchedule} from './machine-quality-for-production-schedule';
import {ProductionScheduleItemInPathGroupStatus} from './production-schedule-item-in-path-group-status';
import {ProductionScheduleItemRecolorInformation} from './production-schedule-item-recolor-information';

export class BmpProductionScheduleItemInPathGroup extends ProductionScheduleItemInPathGroup {
  private _finishing: FinishingForProductionSchedule;

  public constructor(
    drawing: BmpDrawing,
    quantity: number,
    commercialDimensionsInMM: CommercialDimensions,
    technicalDimensions: TechnicalDimensions,
    finishing: FinishingForProductionSchedule,
    isProductionItem: boolean,
    mainColorPosition: number,
    borderColorPosition: number,
    guid: string,
    amountProduced: number,
    status: ProductionScheduleItemInPathGroupStatus,
    orderLineId: number = null,
    id: number = null,
    epId: number = null,
    totalWovenHeightInPicks: number = null,
    totalWovenHeightInMM: number = null,
    amountIncomplete: number = null,
    machineQuality: MachineQualityForProductionSchedule = null
  ) {
    super(
      drawing,
      quantity,
      commercialDimensionsInMM,
      technicalDimensions,
      isProductionItem,
      mainColorPosition,
      borderColorPosition,
      guid,
      amountProduced,
      status,
      DrawingType.BMP,
      orderLineId,
      id,
      epId,
      totalWovenHeightInPicks,
      totalWovenHeightInMM,
      amountIncomplete,
      machineQuality
    );

    this._finishing = finishing;
  }

  public get finishing(): FinishingForProductionSchedule {
    return this._finishing;
  }

  public static fromJSON(productionScheduleItemInPathGroup: any): BmpProductionScheduleItemInPathGroup {
    return new BmpProductionScheduleItemInPathGroup(
      BmpDrawing.fromJSON(productionScheduleItemInPathGroup.design),
      productionScheduleItemInPathGroup.quantity,
      productionScheduleItemInPathGroup.commercialDimensions,
      productionScheduleItemInPathGroup.technicalDimensions,
      !AssertionUtils.isNullOrUndefined(productionScheduleItemInPathGroup.finishing) ? FinishingForProductionSchedule.fromJSON(productionScheduleItemInPathGroup.finishing) : null,
      productionScheduleItemInPathGroup.production,
      productionScheduleItemInPathGroup.mainColorPosition,
      productionScheduleItemInPathGroup.borderColorPosition,
      null,
      productionScheduleItemInPathGroup.amountProduced,
      AssertionUtils.isNullOrUndefined(productionScheduleItemInPathGroup.status) ? null : ProductionScheduleItemInPathGroupStatus[`${productionScheduleItemInPathGroup.status}`],
      productionScheduleItemInPathGroup.orderLineId,
      productionScheduleItemInPathGroup.id,
      productionScheduleItemInPathGroup.epId,
      productionScheduleItemInPathGroup.nrWovenInPicks,
      productionScheduleItemInPathGroup.nrWovenInMM,
      productionScheduleItemInPathGroup.amountIncomplete,
      !AssertionUtils.isNullOrUndefined(productionScheduleItemInPathGroup.machineQuality) ? MachineQualityForProductionSchedule.fromJSON(productionScheduleItemInPathGroup.machineQuality) : null
    );
  }

  public changeFinishing(finishing: FinishingForProductionSchedule): void {
    this._finishing = finishing;
  }

  public getCommercialCutbarsLengthInMM(): number {
    let commercialCutbarsLengthInMM = 0;

    if (!AssertionUtils.isNullOrUndefined(this.finishing) && !AssertionUtils.isNullOrUndefined(this.finishing.cutbarsInformation)) {
      if (this.finishing.cutbarsInformation.hasAddedCutbarBefore()) {
        commercialCutbarsLengthInMM += this.finishing.cutbarsInformation.cutbarBeforeProperties.commercialLengthInMM;
      }
      if (this.finishing.cutbarsInformation.hasAddedCutbarAfter()) {
        commercialCutbarsLengthInMM += this.finishing.cutbarsInformation.cutbarAfterProperties.commercialLengthInMM;
      }
    }

    return commercialCutbarsLengthInMM;
  }

  public getTechnicalCutbarsLengthInNumberOfPicks(): number {
    let technicalCutbarsLengthInPicks = 0;

    if (!AssertionUtils.isNullOrUndefined(this.finishing) && !AssertionUtils.isNullOrUndefined(this.finishing.cutbarsInformation)) {
      if (this.finishing.cutbarsInformation.hasAddedCutbarBefore()) {
        technicalCutbarsLengthInPicks += this.finishing.cutbarsInformation.cutbarBeforeProperties.technicalLengthInPicks;
      }
      if (this.finishing.cutbarsInformation.hasAddedCutbarAfter()) {
        technicalCutbarsLengthInPicks += this.finishing.cutbarsInformation.cutbarAfterProperties.technicalLengthInPicks;
      }
    }

    return technicalCutbarsLengthInPicks;
  }

  public getCutbarInformation(): CutbarsLength {
    const cutbarInformation = !isNil(this.finishing) ? this.finishing.cutbarsInformation : null;
    let cutbarBeforeCommercialLengthInMM = 0;
    let cutbarBeforeTechnicalLengthInNumberOfPicks = 0;
    let cutbarAfterCommercialLengthInMM = 0;
    let cutbarAfterTechnicalLengthInNumberOfPicks = 0;

    if (!isNil(cutbarInformation)) {
      cutbarBeforeCommercialLengthInMM = cutbarInformation.getLengthOfCutbarBeforeInMillimeters();
      cutbarBeforeTechnicalLengthInNumberOfPicks = cutbarInformation.getLengthOfCutbarBeforeInNumberOfPicks();
      cutbarAfterCommercialLengthInMM = cutbarInformation.getLengthOfCutbarAfterInMillimeters();
      cutbarAfterTechnicalLengthInNumberOfPicks = cutbarInformation.getLengthOfCutbarAfterInNumberOfPicks();
    }

    return {
      cutbarBefore: {
        commercialLengthInMM: cutbarBeforeCommercialLengthInMM,
        technicalLengthInNumberOfPicks: cutbarBeforeTechnicalLengthInNumberOfPicks
      },
      cutbarAfter: {
        commercialLengthInMM: cutbarAfterCommercialLengthInMM,
        technicalLengthInNumberOfPicks: cutbarAfterTechnicalLengthInNumberOfPicks
      }
    };
  }

  public canBeRecolored(): boolean {
    return true;
  }

  public toJSON(): JSON {
    const result = super.toJSON();

    result['finishing'] = !isNil(this._finishing) ? this._finishing.toJSON() : null;

    return result;
  }

  public updateFinishing(finishing: FinishingForProductionSchedule): void {
    if (isEqual(this._finishing?.id, finishing.id)) {
      this._finishing = finishing;
    }
  }

  public recolorDrawing(recolorInformation: ProductionScheduleItemRecolorInformation): void {
    this.drawing.image.data = recolorInformation.imageData;
    this.mainColorPosition = recolorInformation.mainColorPosition;
    this.borderColorPosition = recolorInformation.borderColorPosition;
    this.drawing.mappingForDesign = recolorInformation.mappingForDesign;

    this.commercialDimensionsInMM ??= recolorInformation.commercialDimensionsInMM;

    if (!isNil(recolorInformation.finishing)) {
      this.changeFinishing(recolorInformation.finishing);
    }

    this.isRecolored = true;
  }
}
