import {Equipment} from './equipment';
import {MachineType} from './machine-type.enum';

export class UnconfiguredMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.UNDEFINED, name, orderId);
  }

  public static fromJSON(machineJSON: any): UnconfiguredMachine {
    return new UnconfiguredMachine(machineJSON.id, machineJSON.name, machineJSON.orderId);
  }
}
