import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';

export class WeaveStructureForMachineQuality {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;
  private readonly _type: string;
  private readonly _description: string;
  private readonly _sendRepeat: number;
  private _qualityType: WeaveQualityType;
  private readonly _fromVandewiele: boolean;
  private readonly _releaseNotes: string;
  private readonly _dateModified: Date;
  private readonly _picksPerColorLine: number;
  private readonly _nrSolenoidsPerPile: number;
  private readonly _groundYarns: number;
  private readonly _pileYarns: number;
  private readonly _dentRepeat: number;
  private readonly _numberOfCreelPositions: number;
  private readonly _numberOfCloths: number;
  private readonly _numberOfGroundYarns: number;

  public constructor(
    id: number,
    name: string,
    version: number,
    type: string,
    description: string,
    sendRepeat: number,
    qualityType: WeaveQualityType,
    fromVandewiele: boolean,
    releaseNotes: string = null,
    dateModified: Date = null,
    picksPerColorLine: number = null,
    numberOfCreelPositions: number = null,
    nrSolenoidsPerPile: number = 1,
    groundYarns: number = 1,
    pileYarns: number = 1,
    dentReport: number = 1,
    numberOfCloths: number = 2,
    numberOfGroundYarns: number = 0
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._type = type;
    this._description = description;
    this._sendRepeat = sendRepeat;
    this._qualityType = qualityType;
    this._fromVandewiele = fromVandewiele;
    this._releaseNotes = releaseNotes;
    this._dateModified = dateModified;
    this._picksPerColorLine = picksPerColorLine;
    this._nrSolenoidsPerPile = nrSolenoidsPerPile;
    this._groundYarns = groundYarns;
    this._pileYarns = pileYarns;
    this._dentRepeat = dentReport;
    this._numberOfCreelPositions = numberOfCreelPositions;
    this._numberOfCloths = numberOfCloths;
    this._numberOfGroundYarns = numberOfGroundYarns;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get type(): string {
    return this._type;
  }

  public get description(): string {
    return this._description;
  }

  public get fromVandewiele(): boolean {
    return this._fromVandewiele;
  }

  public get releaseNotes(): string {
    return this._releaseNotes;
  }

  public get dateModified(): Date {
    return this._dateModified;
  }

  public get picksPerColorLine(): number {
    return this._picksPerColorLine;
  }

  public get nrSolenoidsPerPile(): number {
    return this._nrSolenoidsPerPile;
  }

  public get groundYarns(): number {
    return this._groundYarns;
  }

  public get pileYarns(): number {
    return this._pileYarns;
  }

  public get dentRepeat(): number {
    return this._dentRepeat;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public get sendRepeat(): number {
    return this._sendRepeat;
  }

  public get qualityType(): WeaveQualityType {
    return this._qualityType;
  }

  public set qualityType(value: WeaveQualityType) {
    this._qualityType = value;
  }

  public get numberOfCloths(): number {
    return this._numberOfCloths;
  }

  public get numberOfGroundYarns(): number {
    return this._numberOfGroundYarns;
  }

  public static fromShortJSON(weaveStructureJSON: any): WeaveStructureForMachineQuality {
    return new WeaveStructureForMachineQuality(
      weaveStructureJSON.id,
      weaveStructureJSON.name,
      weaveStructureJSON.version,
      weaveStructureJSON.type,
      weaveStructureJSON.description,
      weaveStructureJSON.sendRepeat,
      weaveStructureJSON.qualityType,
      weaveStructureJSON.fromVandewiele,
      weaveStructureJSON.releaseNotes,
      weaveStructureJSON.dateModified,
      weaveStructureJSON.picksPerColorLine,
      weaveStructureJSON.numberOfCreelPositions ?? null
    );
  }

  public static fromJSON(weaveStructureJSON: any): WeaveStructureForMachineQuality {
    return new WeaveStructureForMachineQuality(
      weaveStructureJSON.id,
      weaveStructureJSON.name,
      weaveStructureJSON.version,
      weaveStructureJSON.type ?? null,
      weaveStructureJSON.description ?? null,
      weaveStructureJSON.sendRepeat ?? null,
      weaveStructureJSON.qualityType ?? null,
      weaveStructureJSON.fromVandewiele ?? false,
      weaveStructureJSON.releaseNotes ?? null,
      weaveStructureJSON.dateModified ?? null,
      weaveStructureJSON.picksPerColorLine ?? null,
      weaveStructureJSON.numberOfCreelPositions ?? null,
      weaveStructureJSON.nrSolenoidsPerPile ?? 1,
      weaveStructureJSON.groundYarns ?? 1,
      weaveStructureJSON.pileYarns ?? 1,
      weaveStructureJSON.dentRepeat ?? 1,
      weaveStructureJSON.numberOfCloths ?? 2,
      weaveStructureJSON.numberOfGroundYarns ?? 0
    );
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
