import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ColoredYarnSetRoute extends RouteBranch {
  private _addColoredYarnSet: RouteLeaf;
  private _duplicateColoredYarnSet: RouteLeaf;
  private _editColoredYarnSet: RouteLeaf;

  public get addColoredYarnSet(): RouteLeaf {
    return this._addColoredYarnSet;
  }

  public set addColoredYarnSet(value: RouteLeaf) {
    this.replace(this._addColoredYarnSet, value);
    this._addColoredYarnSet = value;
  }

  public get duplicateColoredYarnSet(): RouteLeaf {
    return this._duplicateColoredYarnSet;
  }

  public set duplicateColoredYarnSet(value: RouteLeaf) {
    this.replace(this._duplicateColoredYarnSet, value);
    this._duplicateColoredYarnSet = value;
  }

  public get editColoredYarnSet(): RouteLeaf {
    return this._editColoredYarnSet;
  }

  public set editColoredYarnSet(value: RouteLeaf) {
    this.replace(this._editColoredYarnSet, value);
    this._editColoredYarnSet = value;
  }
}
