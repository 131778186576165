import {ColDef, GridApi} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {isNil, isNumber} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {convertUnit} from '../../common/converters/convert-unit';
import {Color} from '../../common/data/color';
import {Unit} from '../../common/unit.enum';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {LocaleUtils} from '../../common/utils/locale-utils';
import {StringUtils} from '../../common/utils/string-utils';

export class AgGridUtils {
  public static buildAgGridCellTextWithUnit(text: string | number, unit: string, l10nIntlService: L10nIntlService, decimals?: number): string {
    let result: string;

    if (!isNil(text)) {
      result = `${isNumber(text) ? LocaleUtils.formatNumber(text, l10nIntlService, decimals) : text}<span class="font-weight-semibold density">${unit}</span>`;
    }

    return result;
  }

  public static buildAgGridCellTextWithUnitConversion(text: string | number, fromUnit: Unit, toUnit: Unit, l10nIntlService: L10nIntlService, decimals?: number): string {
    let result: string;

    if (isNumber(text)) {
      const convertedValue = convertUnit({
        from: {
          value: text,
          unit: fromUnit
        },
        to: toUnit
      });

      result = decimals !== undefined ? parseFloat(convertedValue.toFixed(decimals)).toString() : convertedValue.toString();

      result = this.buildAgGridCellTextWithUnit(result, toUnit, l10nIntlService);
    } else {
      result = this.buildAgGridCellTextWithUnit(text, toUnit, l10nIntlService);
    }

    return result;
  }

  public static buildAgGridCellTooltipWithUnit(text: string | number, unit: string, l10nIntlService: L10nIntlService, withUnit: boolean = true, decimals?: number): string {
    let result: string;

    if (!isNil(text)) {
      result = `${isNumber(text) ? LocaleUtils.formatNumber(text, l10nIntlService, decimals) : text} ${withUnit ? unit : ''}`;
    }

    return result;
  }

  public static buildAgGridCellTooltipWithUnitConversion(text: string | number, fromUnit: Unit, toUnit: Unit, l10nIntlService: L10nIntlService, withUnit: boolean = true, decimals?: number): string {
    let result: string;

    if (isNumber(text)) {
      const convertedValue = convertUnit({
        from: {
          value: text,
          unit: fromUnit
        },
        to: toUnit
      });

      result = decimals !== undefined ? parseFloat(convertedValue.toFixed(decimals)).toString() : convertedValue.toString();

      result = this.buildAgGridCellTooltipWithUnit(result, toUnit, l10nIntlService, withUnit);
    } else {
      result = this.buildAgGridCellTooltipWithUnit(text, toUnit, l10nIntlService, withUnit);
    }

    return result;
  }

  public static buildAgGridCellTextWithoutUnit(value: number, fromUnit: Unit, toUnit: Unit, l10nIntlService: L10nIntlService, decimals?: number): string {
    const conversionResult = convertUnit({
      from: {
        value,
        unit: fromUnit
      },
      to: toUnit
    });

    return LocaleUtils.formatNumber(conversionResult, l10nIntlService, decimals);
  }

  public static buildAgGridCellListWithoutUnit(value: number[], fromUnit: Unit, toUnit: Unit): string {
    const conversionResult = [];

    value.forEach((item: number) => {
      const convertedUnit = convertUnit({
        from: {
          value: item,
          unit: fromUnit
        },
        to: toUnit
      });
      if (!AssertionUtils.isNullOrUndefined(convertedUnit)) {
        conversionResult.push(convertedUnit);
      }
    });
    const stringResult = conversionResult.toString();

    return stringResult.split(',').join(', ');
  }

  public static buildAgGridCellDateAsDDMMYYYY(date: Date): string {
    if (AssertionUtils.isNullOrUndefined(date)) {
      return '';
    }

    date = new Date(date);
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  }

  public static buildAgGridCellProductionOrderStatus(status: number): string {
    return String(status);
  }

  public static buildAgGridCellColorRGB(color: Color, showColorName: boolean = false): string {
    let result: string;

    if (!isNil(color) && !isNil(color.rgb)) {
      const colorName = showColorName ? color.name : '';
      result = `<div class="color-square" style="background: rgb(${color.rgb.red}, ${color.rgb.green}, ${color.rgb.blue}); box-shadow: 0 3px 6px -2px ${color.hexadecimalColorCode}"  ></div>${colorName}`;
    }

    return result;
  }

  public static createColumnString(fieldName: string, tooltipField: string, minWidth: number, headerNameKey: string, textAlign: string): ColDef {
    return {
      field: fieldName,
      sortable: true,
      minWidth,
      headerName: headerNameKey,
      comparator: StringUtils.stringComparator,
      tooltipField,
      cellStyle: {
        'text-align': textAlign
      }
    } as ColDef;
  }

  public static getSkeletonView(): string {
    return `<div class="ag-skeleton">
      <div class="ag-skeleton-body">
        <div class="ag-skeleton-row"></div>
        <div class="ag-skeleton-row"></div>
        <div class="ag-skeleton-row"></div>
      </div>
    </div>`;
  }

  public static onRowDataUpdated(gridApi: GridApi): void {
    if (!AssertionUtils.isNullOrUndefined(gridApi)) {
      if (gridApi.getDisplayedRowCount() < 1) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }

  public static initSimpleSearchSubject(gridApi: GridApi, searchSubject: Subject<string>, unsubSubject: Subject<boolean>): void {
    searchSubject.pipe(takeUntil(unsubSubject), debounceTime(500)).subscribe((searchValue: string) => {
      if (searchValue?.length > 0) {
        const filterModel = {
          name: {
            filterType: 'multi',
            filterModels: [
              {
                filterType: 'text',
                type: 'contains',
                filter: searchValue
              }
            ]
          }
        };
        gridApi?.setFilterModel(filterModel);
      } else {
        gridApi?.setFilterModel(null);
      }
    });
  }
}
