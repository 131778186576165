import {IdName} from '@domain/id-name';
import {PlanningEquipment} from '../planning-equipment';

export class FixedScheduleCompatibility {
  private _machine: PlanningEquipment;
  private _creels: IdName[];
  private _qualities: IdName[];

  public constructor(machine: PlanningEquipment, creels: IdName[], qualities: IdName[]) {
    this._machine = machine;
    this._creels = creels;
    this._qualities = qualities;
  }

  public get machine(): PlanningEquipment {
    return this._machine;
  }

  public get creels(): IdName[] {
    return this._creels;
  }

  public get qualities(): IdName[] {
    return this._qualities;
  }

  public static fromJSON(json: any): FixedScheduleCompatibility {
    return new FixedScheduleCompatibility(PlanningEquipment.fromJSON(json.machine), json.creels.map(IdName.fromJSON), json.qualities.map(IdName.fromJSON));
  }
}
