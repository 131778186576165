import {Component, Input} from '@angular/core';
import {UUID} from 'crypto';
import {ColorConstants} from '../../common/colors-variables.generated';

@Component({
  selector: 'vdw-account-switch',
  templateUrl: './account-switch.component.html',
  styleUrls: ['./account-switch.component.scss']
})
export class AccountSwitchComponent {
  @Input() public showExpanded = false;

  @Input() public set name(name: string) {
    if (this._name !== name) {
      this._name = name;
      this.firstCharacter = name.charAt(0).toUpperCase();
    }
  }

  public get name(): string {
    return this._name;
  }

  @Input() public set id(id: UUID) {
    if (this._id !== id) {
      this._id = id;
      this.backgroundColor = this.colorPalette[Number.parseInt(id.charAt(0), 16) % this.colorPalette.length];
    }
  }

  public firstCharacter: string;
  public backgroundColor: string;

  private _id: string;
  public _name: string;
  private colorPalette = [ColorConstants.PRIMARY_AQUA_400, ColorConstants.PRIMARY_TEAL_400, ColorConstants.PRIMARY_YELLOW_400, ColorConstants.PRIMARY_PINK_400, ColorConstants.PRIMARY_PURPLE_400];
}
