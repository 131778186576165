export class MachineQualityForOverviewListProductionSchedule {
  private _id: number;
  private _technicalName: string;
  private _reedDensity: number;
  private _pickDensity: number;
  private _weftDensity: number;

  public constructor(id: number, technicalName: string, reedDensity: number, pickDensity: number, weftDensity: number) {
    this._id = id;
    this._technicalName = technicalName;
    this._reedDensity = reedDensity;
    this._pickDensity = pickDensity;
    this._weftDensity = weftDensity;
  }

  public get id(): number {
    return this._id;
  }

  public get technicalName(): string {
    return this._technicalName;
  }

  public get reedDensity(): number {
    return this._reedDensity;
  }

  public get pickDensity(): number {
    return this._pickDensity;
  }

  public get weftDensity(): number {
    return this._weftDensity;
  }

  public static fromJSON(machineQualityForOverviewListProductionScheduleJSON: any): MachineQualityForOverviewListProductionSchedule {
    return new MachineQualityForOverviewListProductionSchedule(
      machineQualityForOverviewListProductionScheduleJSON.id,
      machineQualityForOverviewListProductionScheduleJSON.technicalName,
      machineQualityForOverviewListProductionScheduleJSON.reedDensity,
      machineQualityForOverviewListProductionScheduleJSON.pickDensity,
      machineQualityForOverviewListProductionScheduleJSON.weftDensity
    );
  }
}
