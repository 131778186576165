<ng-container>
  <section class="full-height flex-column">
    <vdw-tabs-menu-card
      [icon]="isEditingCustomer() ? 'details-blue' : 'add-blue'"
      [action]="getActionText()"
      [title]="addCustomerForm.value.name"
      [isInUse]="isCustomerBeingUsed()"
      [invalidFormMessageError]="canShowInvalidFormMessageError()"
      [isLoading]="canShowSkeletonView()"
      [hasPermissionToEdit]="hasEditPermission()"
      [isCreateNewPage]="!isEditingCustomer()"
      [saving]="saving"
      [identifier]="HEADER_IDENTIFIER"
      (backEvent)="cancel()"
      (cancelEvent)="cancel()"
      (saveEvent)="saveCustomer(SAVE_TYPE.SAVE)"
      (saveAndCloseEvent)="saveCustomer(SAVE_TYPE.SAVE_AND_CLOSE)"
      (saveAndCreateNewEvent)="saveCustomer(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
      (deleteEvent)="deleteCustomer()"
      (inUseEvent)="openConflictsDialog()"
    >
    </vdw-tabs-menu-card>

    <mat-card
      appearance="outlined"
      class="skeleton-wrapper full-height no-margin overflow-auto half-width full-width-mobile"
      vdwBp.gt-md="one-third-width"
      [class.skeleton]="canShowSkeletonView()"
      [@fadeIn]="canShowSkeletonView()"
    >
      <mat-card-content>
        <article [class.no-padding]="isMobile()">
          <div class="text-skeleton-wrapper">
            <h4 class="customer-details-title">{{ 'CUSTOMERS.DETAILS' | bmsTranslate }}</h4>
          </div>
          <form [formGroup]="addCustomerForm" appBreadcrumbFormLinker>
            <div fxLayout="column">
              <div fxFlex fxLayoutGap.gt-xs="16px" fxLayout.lt-sm="column">
                <div fxFlex class="form-field-skeleton-wrapper">
                  <mat-form-field fxFlex>
                    <mat-label>{{ 'CUSTOMERS.NUMBER' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal type="number" formControlName="number" />
                    <mat-error *ngIf="canShowErrorForFormControl('required', 'number')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                    <mat-error *ngIf="canShowErrorForFormControl('identifierTaken', 'number')">
                      {{ 'GENERAL.ERRORS.NUMBER_DUPLICATE' | bmsTranslate }}
                    </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'number'; error: 'max'"> {{ 'GENERAL.VALIDATION.MAXIMUM' | bmsTranslate }}: {{ '9,223,372,036,854,775,807' }} </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex class="form-field-skeleton-wrapper">
                  <mat-form-field fxFlex>
                    <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
                    <input matInput type="text" formControlName="name" />
                    <mat-error *ngIf="canShowErrorForFormControl('required', 'name')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <ng-container formGroupName="addressDetails">
                <div fxFlex class="form-field-skeleton-wrapper">
                  <mat-form-field fxFlex>
                    <mat-label>
                      {{ 'CUSTOMERS.STREET_NAME' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                    </mat-label>
                    <input matInput type="text" formControlName="streetName" />
                  </mat-form-field>
                </div>
                <div fxFlex fxLayoutGap.gt-xs="16px" fxLayout.lt-sm="column">
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.COUNTRY' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput type="text" formControlName="country" />
                    </mat-form-field>
                  </div>
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.STREET_NUMBER' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput vdwL10nDecimal type="number" formControlName="streetNumber" />
                    </mat-form-field>
                  </div>
                </div>
                <div fxFlex fxLayoutGap.gt-xs="16px" fxLayout.lt-sm="column">
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.POSTAL_CODE' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput type="text" formControlName="postalCode" />
                    </mat-form-field>
                  </div>
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.CITY' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput type="text" formControlName="city" />
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
              <ng-container formGroupName="contactDetails">
                <div fxFlex fxLayoutGap.gt-xs="16px" fxLayout.lt-sm="column">
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.TELEPHONE' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput type="text" formControlName="telephone" />
                    </mat-form-field>
                  </div>
                  <div fxFlex class="form-field-skeleton-wrapper">
                    <mat-form-field fxFlex>
                      <mat-label>
                        {{ 'CUSTOMERS.FAX' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                      </mat-label>
                      <input matInput type="text" formControlName="fax" />
                    </mat-form-field>
                  </div>
                </div>
                <div fxFlex class="form-field-skeleton-wrapper">
                  <mat-form-field fxFlex>
                    <mat-label>
                      {{ 'CUSTOMERS.EMAIL' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                    </mat-label>
                    <input matInput type="text" formControlName="email" />
                    <mat-error *ngIf="canShowErrorForFormControl('email', 'contactDetails.email')">
                      {{ 'GENERAL.ERRORS.INVALID_EMAIL' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <div fxFlex class="form-field-skeleton-wrapper">
                <mat-form-field fxFlex>
                  <mat-label>
                    {{ 'CUSTOMERS.COMMENTS' | bmsTranslate }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                  </mat-label>
                  <textarea matInput type="text" formControlName="comments"></textarea>
                </mat-form-field>
              </div>
              <div class="checkbox-skeleton" *ngIf="!isEditingCustomer()">
                <mat-checkbox class="checkbox" formControlName="confirm">
                  {{ 'CUSTOMERS.CONFIRM' | bmsTranslate }}
                </mat-checkbox>
              </div>
            </div>
          </form>
        </article>
      </mat-card-content>
    </mat-card>
  </section>
</ng-container>
