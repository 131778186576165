import {inject} from '@angular/core';
import {Navigation, Params, Route, Router, UrlSegment} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils} from '@vdw/angular-component-library';

export const canLoadViaAuthGuard = (_: Route, segments: UrlSegment[]): boolean => {
  const router = inject(Router);
  const authentication = inject(AUTHENTICATION);

  let result = false;

  if (authentication.isAuthenticated()) {
    result = true;
  } else {
    let queryParams: Params = [];
    const navigation: Navigation = router.getCurrentNavigation();
    if (!AssertionUtils.isNullOrUndefined(navigation) && !AssertionUtils.isNullOrUndefined(navigation.extractedUrl)) {
      queryParams = navigation.extractedUrl.queryParams;
    }
    router.navigate([RouteUtils.paths.login.absolutePath], {queryParams: {redirect: RouteUtils.buildPathFromSegmentsAndQueryParams(segments, queryParams)}});
  }
  return result;
};
