import {Pipe, PipeTransform} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {StringUtils} from '../../common/utils/string-utils';

@Pipe({
  name: 'htmlWithVariablesToText'
})
export class HtmlWithVariablesToTextPipe implements PipeTransform {
  public transform(html: string, listOfVariables?: {id: number; name: string}[]): string {
    if (AssertionUtils.isNullOrUndefined(html)) {
      return '';
    }

    const splitedHtml = html.split(/(%\{-?\d+\}%)/g)?.filter((value: string) => !AssertionUtils.isEmpty(value));

    const textWithVariables = splitedHtml
      ?.map((value: string) => (!AssertionUtils.isNullOrUndefined(listOfVariables) && value.startsWith('%{') ? `*${this.getVariableNameByValue(value, listOfVariables)}*` : ` ${value} `))
      .join('');

    return StringUtils.htmlToText(textWithVariables ?? '');
  }

  private getVariableNameByValue(value: string, listOfVariables: {id: number; name: string}[]): string {
    const match = /%\{(-?\d+)\}%/.exec(value);
    return match?.length >= 2 ? listOfVariables.find((variable: {id: number; name: string}) => variable.id === parseInt(match[1], 10))?.name : value;
  }
}
