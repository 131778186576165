import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {LabelModule} from '../ellipsis-label/label.module';
import {InputChipModule} from '../input-chip/input-chip.module';
import {MultiLineDragModule} from '../multi-line-drag/multi-line-drag.module';
import {FilterChipsComponent} from './filter-chips.component';

@NgModule({
  declarations: [FilterChipsComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatIconModule, MatMenuModule, LabelModule, MultiLineDragModule, InputChipModule],
  exports: [FilterChipsComponent]
})
export class FilterChipsModule {}
