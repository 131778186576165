export class ImageForFinishingImageLabel {
  private _id: number;
  private _name: string;
  private _imageId: string;
  private _file: File;

  public constructor(id: number, name: string, imageId: string, file?: File) {
    this._id = id;
    this._name = name;
    this._imageId = imageId;
    this._file = file;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public get imageId(): string {
    return this._imageId;
  }

  public set imageId(imageId: string) {
    this._imageId = imageId;
  }

  public get file(): File {
    return this._file;
  }

  public static fromJSON(imageForFinishingImageLabelJSON: any): ImageForFinishingImageLabel {
    return new ImageForFinishingImageLabel(imageForFinishingImageLabelJSON.id, imageForFinishingImageLabelJSON.name, imageForFinishingImageLabelJSON.imageId);
  }
}
