import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';

export class ProductionScheduleItemInPathGroupWithIdentification {
  private _productionSchedulePath: ProductionSchedulePath;
  private _productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup;
  private _productionScheduleItemInPathGroupIndex: number;
  private _productionSchedulePathIndex: number;
  private _startDentOfColoredYarnSet: number;
  private _productionScheduleOrderLine: ProductionScheduleOrderLine;

  public constructor(
    productionSchedulePath: ProductionSchedulePath,
    productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup,
    productionScheduleItemInPathGroupIndex: number,
    productionSchedulePathIndex: number,
    startDentOfColoredYarnSet: number,
    productionScheduleOrderLine: ProductionScheduleOrderLine
  ) {
    this._productionSchedulePath = productionSchedulePath;
    this._productionScheduleItemInPathGroup = productionScheduleItemInPathGroup;
    this._productionScheduleItemInPathGroupIndex = productionScheduleItemInPathGroupIndex;
    this._productionSchedulePathIndex = productionSchedulePathIndex;
    this._startDentOfColoredYarnSet = startDentOfColoredYarnSet;
    this._productionScheduleOrderLine = productionScheduleOrderLine;
  }

  public get productionSchedulePath(): ProductionSchedulePath {
    return this._productionSchedulePath;
  }

  public get productionScheduleItemInPathGroup(): ProductionScheduleItemInPathGroup {
    return this._productionScheduleItemInPathGroup;
  }

  public get productionScheduleItemInPathGroupIndex(): number {
    return this._productionScheduleItemInPathGroupIndex;
  }

  public get productionSchedulePathIndex(): number {
    return this._productionSchedulePathIndex;
  }

  public get startDentOfColoredYarnSet(): number {
    return this._startDentOfColoredYarnSet;
  }

  public get productionScheduleOrderLine(): ProductionScheduleOrderLine {
    return this._productionScheduleOrderLine;
  }
}
