import {ModuleWithProviders, NgModule} from '@angular/core';
import {LocalStorageConfig, LOCALSTORAGE_CONFIG} from './local-storage-config';
import {LocalStorageService} from './local-storage.service';

@NgModule()
export class LocalStorageModule {
  public static forRoot(config: LocalStorageConfig): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [LocalStorageService, {provide: LOCALSTORAGE_CONFIG, useValue: config}]
    };
  }
}
