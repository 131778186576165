import {Component, Inject} from '@angular/core';
import {BackgroundStatus} from '@domain/background-jobs/background-status';
import {BackgroundTaskWithJob} from '@presentation/components/background-jobs/background-task-with-job';
import {ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {first} from 'lodash-es';

@Component({
  templateUrl: './background-jobs-overview-status.component.html',
  styleUrls: ['./background-jobs-overview-status.component.scss']
})
export class BackgroundJobsOverviewStatusComponent implements ICellRendererAngularComp {
  public backgroundStatusIcon: string;

  public constructor(@Inject(RESPONSIVENESS_VIEW_MODE) public readonly responsivenessViewMode: ResponsivenessViewMode) {}

  public agInit(params: ICellRendererParams): void {
    const backgroundTaskWithJob: BackgroundTaskWithJob = params.node.group ? first(params.node.allLeafChildren).data : params.data;
    const status: BackgroundStatus = params.node.group || this.responsivenessViewMode.isPhone ? backgroundTaskWithJob.backgroundJob.status : backgroundTaskWithJob.backgroundTask.status;

    this.backgroundStatusIcon = BackgroundStatus.getStatusIconName(status);
  }

  public refresh(params: any): boolean {
    return false;
  }
}
