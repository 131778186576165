import {toNumber} from 'lodash-es';
import {CommercialUnitConversionError} from '../error/commercial-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToCommercialUnitCentimeter(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.MILLIMETER:
      result = toNumber(unitValue.value) / 10;
      break;
    case Unit.CENTIMETER:
      result = unitValue.value;
      break;
    case Unit.DECIMETER:
      result = unitValue.value * 10;
      break;
    case Unit.METER:
      result = unitValue.value * 100;
      break;
    default:
      throw new CommercialUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.CENTIMETER}`);
  }

  return result;
}
