import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PlasticProductionOrder} from '@domain/texfab/plastic-production-order/plastic-production-order';
import {CheckName, GridModel} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductionOrdersService implements Repository<PlasticProductionOrder, PlasticProductionOrder>, UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<PlasticProductionOrder[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic`)
      .pipe(map((plasticProductionOrdersJSON: any) => plasticProductionOrdersJSON.map((plasticProductionOrderJSON: any) => PlasticProductionOrder.fromJSON(plasticProductionOrderJSON))));
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic/${id}`);
  }

  public getById(id: number): Observable<PlasticProductionOrder> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic/${id}`)
      .pipe(map((plasticProductionOrderJSON: any) => PlasticProductionOrder.fromJSON(plasticProductionOrderJSON)));
  }

  public save(item: PlasticProductionOrder): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: PlasticProductionOrder): Observable<void | number> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(identifier: string): Observable<CheckName> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-products/check-name?name=${identifier}`).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getPossibleValues(columnIdentifier: string): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-products/${columnIdentifier}/possible-values`).pipe(map((response: string[]) => response));
  }

  public getSlice(gridModel: GridModel): Observable<PlasticProductionOrder[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}plastic-products/get`, gridModel)
      .pipe(map((plasticProductionOrdersJSON: any) => plasticProductionOrdersJSON.map((plasticProductionOrderJSON: any) => PlasticProductionOrder.fromJSON(plasticProductionOrderJSON))));
  }
}
