import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RowNode} from 'ag-grid-community';
import {of, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {TranslateService} from '../../../translation/translate.service';
import {FooterConstants} from '../../footer/footer.constants';
import {CrudGridContextService} from '../crud-grid-context-service/crud-grid.context.service';
import {CrudOverviewDataConfig} from '../interfaces/crud-overview-data-config.interface';

@Component({
  templateUrl: './crud-object-selection-dialog.component.html',
  styleUrls: ['./crud-object-selection-dialog.component.scss']
})
export class CrudObjectSelectionDialogComponent extends BaseComponent implements OnInit {
  @Input()
  protected config: CrudOverviewDataConfig<unknown> = {};

  protected showSelected: string;
  protected selectedItemCount = 0;
  protected readonly CHOOSE_BUTTON_CLASS_NAME = FooterConstants.CHOOSE_BUTTON_CLASS_NAME;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly translate: TranslateService,
    private readonly changeDetector: ChangeDetectorRef,
    protected readonly gridContext: CrudGridContextService,
    private readonly dialogRef: MatDialogRef<CrudObjectSelectionDialogComponent>
  ) {
    super();

    if (!AssertionUtils.isNullOrUndefined(data.config)) {
      this.config = data.config;
    }
  }

  public ngOnInit(): void {
    this.config.gridOptions.popupParent = document.getElementById(this.dialogRef.id);
    this.showSelected = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.ONLY_SHOW_SELECTED') + ':';

    this.config.dataObservable?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((data: any[]) => {
      this.config.rowData = data;
      this.changeDetector.detectChanges();
    });

    const gridObservable = AssertionUtils.isNullOrUndefined(this.gridContext.grid) ? this.gridContext.gridReady.asObservable() : of(null);

    gridObservable.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.gridContext.grid.api.forEachNode((node: RowNode) => node.setSelected(this.config.selectedObjects?.some((selectedObject: any) => selectedObject?.id === node.data?.id)));

      this.gridContext.grid.rowSelected?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
        this.selectedItemCount = this.gridContext.grid.api.getSelectedRows()?.length ?? 0;

        if (this.config.onlyShowSelected) {
          this.toggleShowOnlySelected(this.selectedItemCount > 0);
        }
      });
    });
  }

  public get getGridSelectedRows(): number {
    return this.gridContext?.grid?.api?.getSelectedRows()?.length ?? 0;
  }

  protected closeDialog(): void {
    this.dialogRef.close();
  }

  public back(event: MouseEvent): void {
    AssertionUtils.isNullOrUndefined(this.config.withBackClicked) ? this.dialogRef?.close() : this.config.withBackClicked(event);
  }

  protected select(): void {
    const data = this.gridContext.grid?.api?.getSelectedRows() ?? [];

    AssertionUtils.isNullOrUndefined(this.config.withConfirmClicked) ? this.dialogRef?.close(data) : this.config.withConfirmClicked(data);
  }

  protected toggleShowOnlySelected(checked: boolean): void {
    this.config.onlyShowSelected = checked;

    if (this.config.gridOptions.rowModelType === 'serverSide') {
      this.gridContext.grid.api.onFilterChanged();
    } else {
      checked ? this.gridContext.grid.api.setGridOption('rowData', this.gridContext.grid.api?.getSelectedRows()) : this.gridContext.grid.api.setGridOption('rowData', this.config.rowData);
    }
  }
}
