export enum PlanningItemType {
  MAINTENANCE = 'MAINTENANCE',

  ORDER_CARPET = 'ORDER_CARPET',
  ORDER_WEAVING = 'ORDER_WEAVING',
  ORDER_TUFTING = 'ORDER_TUFTING',
  ORDER_PLASTIC = 'ORDER_PLASTIC',

  RUN_CARPET = 'RUN_CARPET',
  RUN_WEAVING = 'RUN_WEAVING',
  RUN_TUFTING = 'RUN_TUFTING',
  RUN_PLASTIC = 'RUN_PLASTIC',

  FIXED_SCHEDULE_PLACEHOLDER = 'FIXED_SCHEDULE_PLACEHOLDER'
}
