export class WebNotificationSubscription {
  private readonly _id: number;
  private readonly _auth: string;
  private readonly _p256DH: string;
  private readonly _endpoint: string;

  public constructor(id: number, p256DH: string, endpoint: string, auth: string) {
    this._id = id;
    this._auth = auth;
    this._p256DH = p256DH;
    this._endpoint = endpoint;
  }

  public get id(): number {
    return this._id;
  }

  public get auth(): string {
    return this._auth;
  }

  public get p256DH(): string {
    return this._p256DH;
  }

  public get endpoint(): string {
    return this._endpoint;
  }

  public toJSON(): JSON {
    return {
      endpoint: this.endpoint,
      p256DH: this.p256DH,
      auth: this._auth
    } as any as JSON;
  }
}
