export enum ProductionScheduleStatus {
  DRAFT = 'DRAFT',
  VERIFIED_WITH_ERRORS = 'VERIFIED_WITH_ERRORS',
  VERIFIED_WITH_WARNINGS = 'VERIFIED_WITH_WARNINGS',
  VERIFIED = 'VERIFIED',
  COMPLETED = 'COMPLETED',
  CREATING = 'CREATING',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FINALIZING = 'FINALIZING',
  FINALIZED = 'FINALIZED',
  TRANSLATING = 'TRANSLATING',
  TRANSLATED = 'TRANSLATED',
  SENDING = 'SENDING',
  ARRIVED = 'ARRIVED',
  EXECUTING = 'EXECUTING',
  EXECUTED = 'EXECUTED',
  EXECUTED_COMPLETE = 'EXECUTED_COMPLETE',
  EXECUTED_PARTIAL = 'EXECUTED_PARTIAL',
  CANCELLED = 'CANCELLED',
  QUEUING = 'QUEUING',
  QUEUED = 'QUEUED',
  FAILURE = 'FAILURE',
  TO_PLAN = 'TO_PLAN',
  TO_RECONFIRM = 'TO_RECONFIRM',
  SENT = 'SENT',
  PLANNING = 'PLANNING',
  PLANNED = 'PLANNED',
  REPLANNING = 'REPLANNING',
  REPLANNED = 'REPLANNED',
  REPLANNED_WITH_WARNINGS = 'REPLANNED_WITH_WARNINGS',
  PRODUCING = 'PRODUCING',
  PRODUCED_COMPLETELY = 'PRODUCED_COMPLETELY',
  PRODUCED_PARTIALLY = 'PRODUCED_PARTIALLY',
  NO_PRODUCTION_INFORMATION_RECEIVED = 'NO_PRODUCTION_INFORMATION_RECEIVED'
}

const draftIconName = 'draft';
const verifiedIconName = 'verified';
const verifiedWithErrorsIconName = 'verified-error';
const verifiedWithWarningsIconName = 'verified-with-warnings';
const completedIconName = 'confirm';
const processingIconName = 'processing';
const processingGreenIconName = 'processing-green';
const sendingIconName = 'send';
const arrivedIconName = 'arrived';
const executingIconName = 'execute';
const executedPartialIconName = 'executed-partial';
const executedCompleteIconName = 'executed-complete';
const cancelledIconName = 'cancelled';
const queuedIconName = 'hourglass-green';
const queuingIconName = 'hourglass';
const failedIconName = 'failed';
const toReconfirmIconName = 'reconfirm';

const productionScheduleStatusIconMap: Map<ProductionScheduleStatus, string> = new Map<ProductionScheduleStatus, string>([
  [ProductionScheduleStatus.DRAFT, draftIconName],
  [ProductionScheduleStatus.VERIFIED, verifiedIconName],
  [ProductionScheduleStatus.VERIFIED_WITH_ERRORS, verifiedWithErrorsIconName],
  [ProductionScheduleStatus.VERIFIED_WITH_WARNINGS, verifiedWithWarningsIconName],
  [ProductionScheduleStatus.COMPLETED, completedIconName],
  [ProductionScheduleStatus.CREATING, processingIconName],
  [ProductionScheduleStatus.CREATED, processingIconName],
  [ProductionScheduleStatus.PROCESSING, processingIconName],
  [ProductionScheduleStatus.PROCESSED, processingGreenIconName],
  [ProductionScheduleStatus.FINALIZING, processingGreenIconName],
  [ProductionScheduleStatus.FINALIZED, processingGreenIconName],
  [ProductionScheduleStatus.TRANSLATING, processingGreenIconName],
  [ProductionScheduleStatus.TRANSLATED, processingGreenIconName],
  [ProductionScheduleStatus.SENDING, sendingIconName],
  [ProductionScheduleStatus.ARRIVED, arrivedIconName],
  [ProductionScheduleStatus.EXECUTING, executingIconName],
  [ProductionScheduleStatus.EXECUTED, executingIconName],
  [ProductionScheduleStatus.EXECUTED_COMPLETE, executedCompleteIconName],
  [ProductionScheduleStatus.EXECUTED_PARTIAL, executedPartialIconName],
  [ProductionScheduleStatus.CANCELLED, cancelledIconName],
  [ProductionScheduleStatus.QUEUING, queuingIconName],
  [ProductionScheduleStatus.QUEUED, queuedIconName],
  [ProductionScheduleStatus.FAILURE, failedIconName],
  [ProductionScheduleStatus.TO_PLAN, verifiedIconName],
  [ProductionScheduleStatus.TO_RECONFIRM, toReconfirmIconName]
]);

export namespace ProductionScheduleStatus {
  export function getStatusIconName(status: ProductionScheduleStatus): string {
    return productionScheduleStatusIconMap.get(status);
  }
}
