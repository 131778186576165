export class CheckName {
  private readonly _isAvailable: boolean;
  private readonly _id: number;

  public constructor(isAvailable: boolean, id: number) {
    this._isAvailable = isAvailable;
    this._id = id;
  }

  public get id(): number {
    return this._id;
  }

  public get isAvailable(): boolean {
    return this._isAvailable;
  }

  public static fromJSON(checkName: any): CheckName {
    return new CheckName(checkName.isAvailable, checkName.id);
  }
}
