export class CustomerAddressDetails {
  private _streetName: string;
  private _country: string;
  private _streetNumber: number;
  private _postalCode: string;
  private _city: string;

  public constructor(streetName: string, country: string, streetNumber: number, postalCode: string, city: string) {
    this._streetName = streetName;
    this._country = country;
    this._streetNumber = streetNumber;
    this._postalCode = postalCode;
    this._city = city;
  }

  public static fromJSON(customerAddressJSON: any): CustomerAddressDetails {
    return new CustomerAddressDetails(customerAddressJSON.streetName, customerAddressJSON.country, customerAddressJSON.streetNumber, customerAddressJSON.postalCode, customerAddressJSON.city);
  }

  public toJSON(): JSON {
    return {
      streetName: this._streetName,
      country: this._country,
      streetNumber: this._streetNumber,
      postalCode: this._postalCode,
      city: this._city
    } as any as JSON;
  }

  public get streetName(): string {
    return this._streetName;
  }

  public get country(): string {
    return this._country;
  }

  public get streetNumber(): number {
    return this._streetNumber;
  }

  public get postalCode(): string {
    return this._postalCode;
  }

  public get city(): string {
    return this._city;
  }
}
