<section class="flex-column full-height overflow-hidden">
  <vdw-tabs-menu-card
    [title]="addPathLayoutTemplateForm?.value?.name"
    icon="add-blue"
    [action]="getActionText()"
    [saving]="saving"
    [identifier]="HEADER_IDENTIFIER"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [isLoading]="isLoadingPathLayoutTemplate"
    [isCreateNewPage]="!isEditingPathLayoutTemplate()"
    [hasPermissionToEdit]="hasEditPermission()"
    (backEvent)="navigateBack()"
    (cancelEvent)="navigateBack()"
    (saveEvent)="savePathLayoutTemplate(SAVE_TYPE.SAVE)"
    (saveAndCloseEvent)="savePathLayoutTemplate(SAVE_TYPE.SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="savePathLayoutTemplate(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (duplicateEvent)="duplicatePathLayoutTemplate()"
    (deleteEvent)="deletePathLayoutTemplate()"
    (settingsEvent)="navigateToCustomSettings()"
  ></vdw-tabs-menu-card>
  <div class="flex-row gap-16 full-height min-height-0">
    <mat-card class="full-width full-height">
      <mat-card-content class="full-height full-min-height">
        <article class="flex-column gap-16 full-height" [formGroup]="addPathLayoutTemplateForm" appBreadcrumbFormLinker>
          <h4 class="font-weight-semibold title">{{ 'GENERAL.GENERAL' | bmsTranslate }}</h4>
          <div class="flex-row gap-24">
            <mat-form-field class="full-width">
              <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
              <input matInput type="text" formControlName="name" />
              <mat-hint *ngIf="canShowSuggestionForPathLayoutTemplateName()" class="flex-row">
                <vdw-ellipsis-label class="overflow-hidden" [text]="'GENERAL.SUGGESTION' | bmsTranslate: {value: suggestedPathLayoutTemplateName}" matTooltipPosition="below"></vdw-ellipsis-label>
                <a class="url" (mousedown)="applySuggestionForPathLayoutTemplateName()">{{ 'GENERAL.ACTIONS.APPLY' | bmsTranslate }}</a>
              </mat-hint>
              <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
              <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
                {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'PATH_LAYOUT_TEMPLATE.LOOM_TYPE' | bmsTranslate }}</mat-label>
              <input matInput type="text" formControlName="loomGroup" />
              <mat-error *vdwCanShowErrorForFormControl="'loomGroup'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
            </mat-form-field>
          </div>
          <mat-card class="paths full-height min-height-0">
            <vdw-header class="header" [title]="'PATH_LAYOUT_TEMPLATE.PATHS' | bmsTranslate" [class.invalid]="isPathWidthsTitleInvalid()">
              <button mat-icon-button type="button" class="mat-icon-action-button-lg-with-border" [disabled]="!hasEditPermission()" (click)="addPathWidth()">
                <mat-icon svgIcon="add"></mat-icon>
              </button>
            </vdw-header>

            <mat-card-content #container class="form-scrollable-content">
              <div class="titles">
                <h4 class="full-width font-weight-semibold">{{ 'GENERAL.DIMENSIONS.WIDTH' | bmsTranslate }} ({{ 'GENERAL.UNIT.CENTIMETER' | bmsTranslate | lowercase }})</h4>
                <div class="end-empty-space"></div>
              </div>
              <div
                *ngFor="let path of getPathWidthFormArray().controls; let index = index"
                [appRunEventsOutsideAngular]="['dragover']"
                (outSideAngularEvent)="onPathWidthDragOver($event, index)"
                (dragleave)="onDraggedPathWidthLeavesPathWidthItem($event)"
                (drop)="onDraggedPathWidthDroppedOnPathWidthItem($event, index)"
              >
                <ng-container [formGroup]="path">
                  <div
                    #element
                    class="path-width-wrapper flex-row"
                    draggable="true"
                    [appRunEventsOutsideAngular]="['drag']"
                    (dragstart)="onDragStart($event, element, container, index)"
                    (dragend)="onDragEnd()"
                    (outSideAngularEvent)="onPathWidthDrag($event)"
                  >
                    <mat-icon class="ml-16 mr-8" [class.drag-handle]="hasEditPermission()" svgIcon="drag-handle" [class.prevent-drop]="dragging"></mat-icon>
                    <mat-form-field class="full-width no-subscript pr-24" [class.prevent-drop]="dragging">
                      <input matInput vdwL10nDecimal type="number" [min]="PATH_WIDTH_MIN_VALUE" [max]="PATH_WIDTH_MAX_VALUE" formControlName="width" />
                      <mat-error *vdwCanShowErrorForFormControl="'width'; error: 'required'">
                        {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                      </mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'width'; error: 'min'">
                        {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: PATH_WIDTH_MIN_VALUE} }}
                      </mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'width'; error: 'max'">
                        {{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: PATH_WIDTH_MAX_VALUE} }}
                      </mat-error>
                    </mat-form-field>
                    <button
                      mat-icon-button
                      class="mat-icon-action-button-lg-with-border ml-24 mr-24"
                      [class.prevent-drop]="dragging"
                      type="button"
                      [disabled]="!hasEditPermission()"
                      (click)="removePathWidth(index)"
                    >
                      <mat-icon svgIcon="trash"></mat-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
        </article>
      </mat-card-content>
    </mat-card>
    <div class="full-width"></div>
  </div>
</section>
