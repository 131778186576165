import {CommercialDimensions} from '@domain/commercial-dimensions';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {TechnicalDimensions} from '@domain/technical-dimensions';
import {isNil} from 'lodash-es';

export class DesignForOrderLine {
  private _id: number;
  private _image: DrawingImage;
  private _name: string;
  private _shape: string;
  private _commercialDimensions: CommercialDimensions;
  private _technicalDimensions: TechnicalDimensions;
  private _dimensionsInPixels: DimensionsInPx;

  public constructor(
    id: number,
    image: DrawingImage,
    name: string,
    shape: string,
    commercialDimensions: CommercialDimensions,
    technicalDimensions: TechnicalDimensions,
    dimensionsInPixels: DimensionsInPx
  ) {
    this._id = id;
    this._image = image;
    this._name = name;
    this._shape = shape;
    this._commercialDimensions = commercialDimensions;
    this._technicalDimensions = technicalDimensions;
    this._dimensionsInPixels = dimensionsInPixels;
  }

  public get id(): number {
    return this._id;
  }

  public get image(): DrawingImage {
    return this._image;
  }

  public get name(): string {
    return this._name;
  }

  public get shape(): string {
    return this._shape;
  }

  public get commercialDimensions(): CommercialDimensions {
    return this._commercialDimensions;
  }

  public get technicalDimensions(): TechnicalDimensions {
    return this._technicalDimensions;
  }

  public get dimensionsInPixels(): DimensionsInPx {
    return this._dimensionsInPixels;
  }

  public static fromJSON(designForOrderLineJSON: any): DesignForOrderLine {
    const image: DrawingImage = isNil(designForOrderLineJSON.image)
      ? {}
      : {
          id: designForOrderLineJSON.image.id || null,
          data: designForOrderLineJSON.image.data || null
        };

    return new DesignForOrderLine(
      designForOrderLineJSON.id,
      image,
      designForOrderLineJSON.name,
      designForOrderLineJSON.shape,
      designForOrderLineJSON.commercialDimensions,
      designForOrderLineJSON.technicalDimensions,
      designForOrderLineJSON.dimensionsInPx
    );
  }
}
