import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DeclarationRoute extends RouteBranch {
  private _addDeclaration: RouteLeaf;
  private _editDeclaration: RouteLeaf;

  public get addDeclaration(): RouteLeaf {
    return this._addDeclaration;
  }

  public set addDeclaration(value: RouteLeaf) {
    this.replace(this._addDeclaration, value);
    this._addDeclaration = value;
  }

  public get editDeclaration(): RouteLeaf {
    return this._editDeclaration;
  }

  public set editDeclaration(value: RouteLeaf) {
    this.replace(this._editDeclaration, value);
    this._editDeclaration = value;
  }
}
