import {inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteLeaf} from '@application/helper/routing/route-leaf';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PRODUCTION_SCHEDULE_PLAN} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {AlertDialogResult, AssertionUtils, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {Observable, Observer} from 'rxjs';
import {AddProductionScheduleNewPageComponent} from '../add/add-production-schedule-new-page.component';

export const canDeactivateProductionScheduleGuardNew = (
  component: AddProductionScheduleNewPageComponent,
  _: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Observable<boolean> => {
  const productionSchedulePlan = inject(PRODUCTION_SCHEDULE_PLAN);
  const dialogBuilderFactoryService = inject(DialogBuilderFactoryService);
  const navigationHelperService = inject(NavigationHelperService);
  const productionScheduleId = component?.productionScheduleToAdd?.id;

  return new Observable((observer: Observer<boolean>) => {
    if (
      (productionSchedulePlan.hasUnsavedChanges(true) ||
        (!AssertionUtils.isNullOrUndefined(productionScheduleId) && productionSchedulePlan.hasNoPaths(component?.productionScheduleToAdd) && !component?.justInitialized)) &&
      !component?.saveButtonClicked
    ) {
      dialogBuilderFactoryService
        .getBuilder()
        .openAlertDialog({
          type: DialogType.DISCARD_AND_SAVE
        })
        .subscribe((result: AlertDialogResult) => {
          if (result === AlertDialogResult.DISCARD) {
            productionSchedulePlan.discardUnsavedChanges();
            productionSchedulePlan.discardTemporarySavedChanges();
            observer.next(true);
            observer.complete();
            if (
              !component.productionScheduleToAdd.hasSavedManually &&
              !AssertionUtils.isNullOrUndefined(navigationHelperService?.routingHistory[navigationHelperService?.routingHistory?.length - 1]?.route)
            ) {
              navigationHelperService.routingHistory[navigationHelperService.routingHistory.length - 1].route = '/texfab/production-order';
            }
          } else if (result === AlertDialogResult.SAVE) {
            // Save the production schedule builder changes
            productionSchedulePlan.manualSaveProductionSchedule(component?.productionScheduleToAdd?.id?.toString(), component.currentProductionSchedulePhase);

            // Save the changes in the configuration phase if there are any
            if (component?.productionScheduleInitialPreparationsForm?.touched) {
              component?.saveConfigurationPhaseFromLeaveDialog().subscribe({
                next: (succesfullSave: boolean) => {
                  observer.next(succesfullSave);
                  observer.complete();
                },
                error: () => {
                  observer.next(false);
                  observer.complete();
                }
              });
            } else {
              observer.next(true);
              observer.complete();
            }
          }
        });
    } else if (
      (AssertionUtils.isNullOrUndefined(productionScheduleId) &&
        (!isFormEmpty(component?.productionScheduleInitialPreparationsForm) || component?.productionScheduleInitialPreparationsForm.touched) &&
        !navigationHelperService.isNextRouteRelatedToCurrent(getCurrentRouteLeaf(currentState?.url), nextState?.url)) ||
      (component.isDuplicatingProductionOrder() && !component?.saveButtonClicked)
    ) {
      dialogBuilderFactoryService
        .getBuilder()
        .openAlertDialog({
          type: DialogType.DISCARD_AND_SAVE
        })
        .subscribe((result: AlertDialogResult) => {
          if (result === AlertDialogResult.DISCARD) {
            observer.next(true);
            observer.complete();
          } else if (result === AlertDialogResult.SAVE) {
            component?.saveConfigurationPhaseFromLeaveDialog().subscribe({
              next: (succesfullSave: boolean) => {
                observer.next(succesfullSave);
                observer.complete();
              },
              error: () => {
                observer.next(false);
                observer.complete();
              }
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
    } else {
      observer.next(true);
      observer.complete();
      component.justInitialized = false;
    }
  });
};

const isFormEmpty = (form: FormGroup): boolean => {
  return Object.values(form?.controls).every((control: FormControl) => AssertionUtils.isNullOrUndefined(control?.value));
};

const getCurrentRouteLeaf = (currentRoute: string): RouteLeaf => {
  const possibleCurrentRoutes = [
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForProductConfiguration,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForMachine,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForRun,
    RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView
  ];

  return possibleCurrentRoutes.find((routeLeaf: RouteLeaf) => RouteUtils.isRoutePathMatching(currentRoute, routeLeaf.absolutePath));
};
