import {Injectable} from '@angular/core';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {ToolStatus} from '@domain/machine/tool-status.enum';
import {
  ColDefBuilderFactoryService,
  GridOptionsBuilder,
  GridTagComponent,
  LinkIconRendererComponent,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  StringUtils,
  TagColor,
  TagSize,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ITooltipParams, ValueGetterParams} from 'ag-grid-community';

@Injectable()
export class ToolOverviewService {
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('tool');

  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(withLinkIcon: boolean = true): ColDef[] {
    let nameColDef = this.colDefBuilderFactoryService.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator);

    if (withLinkIcon) {
      nameColDef = nameColDef.withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
        return {
          params,
          editPageUrl: this.pageUrls.edit
        };
      });
    }

    return [
      nameColDef.build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('toolTypeId')
        .withField('toolType')
        .withHeaderName('UTILITIES.TOOL.TOOL_TYPE.TOOL_TYPE')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`UTILITIES.TOOL.TOOL_TYPE.${params.data.toolType}`), true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('weightInKg', true).withHeaderName('UTILITIES.TOOL.WEIGHT', 1, false, Unit.KILOGRAM).withRightAlignment().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('description', true).withHeaderName('GENERAL.DESCRIPTION').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('toolStatusId')
        .withField('status', true)
        .withHeaderName('GENERAL.STATUS')
        .withCellRenderer(GridTagComponent, {
          translationKey: 'UTILITIES.TOOL.TOOL_STATUS.',
          size: TagSize.MEDIUM,
          colorGetter: {
            [ToolStatus.ASSEMBLING]: TagColor.VIOLET,
            [ToolStatus.FREE]: TagColor.TEAL,
            [ToolStatus.IN_MAINTENANCE]: TagColor.YELLOW,
            [ToolStatus.IN_USE]: TagColor.AQUA,
            [ToolStatus.NOT_AVAILABLE]: TagColor.ERROR
          }
        })
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`'UTILITIES.TOOL.TOOL_STATUS.' + ${params.value}`))
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('stockLocation.name', true).withHeaderName('UTILITIES.TOOL.STOCK_LOCATION').build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('pieceCycles').withHeaderName('UTILITIES.TOOL.CAVITIES.PIECE_CYCLES').withRightAlignment().build(),
      this.colDefBuilderFactoryService.getBuilder().withColId('originalCavities').withField('cavities').withHeaderName('UTILITIES.TOOL.CAVITIES.CAVITIES').withRightAlignment().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('mountTimeInMinutes').withHeaderName('UTILITIES.TOOL.SETUP.MOUNT_TIME').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('dismountTimeInMinutes').withHeaderName('UTILITIES.TOOL.SETUP.DISMOUNT_TIME').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('usedCavities').withHeaderName('UTILITIES.TOOL.CAVITIES.USED_CAVITIES').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('speed').withHeaderName('UTILITIES.TOOL.SPEED').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('speedUnit')
        .withHeaderName('UTILITIES.TOOL.SPEED_UNIT.SPEED_UNIT')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`UTILITIES.TOOL.SPEED_UNIT.${params.data.speedUnit}`), true)
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('numberingMode')
        .withHeaderName('UTILITIES.TOOL.CAVITIES.NUMBERING_MODE.NUMBERING_MODE')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`UTILITIES.TOOL.CAVITIES.NUMBERING_MODE.${params.data.numberingMode}`), true)
        .withHide()
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('cavitiesAcross').withHeaderName('UTILITIES.TOOL.CAVITIES.CAVITIES_ACROSS').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('cavitiesDown').withHeaderName('UTILITIES.TOOL.CAVITIES.CAVITIES_DOWN').withRightAlignment().withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('waiveCycles').withHeaderName('UTILITIES.TOOL.SETUP.WAIVE_CYCLES').withRightAlignment().withHide().build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('UTILITIES.TOOL.TOOL', {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
