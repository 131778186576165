<section fxLayout="column" fxFlex>
  <vdw-header [title]="'GENERAL.ACTIONS.CHOOSE_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT' | bmsTranslate: {count: 1} | lowercase}" svgIcon="select-blue">
    <vdw-slide-toggle (checkedChange)="showOnlyLatestVersionChanged($event)"></vdw-slide-toggle>
  </vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddWeaveProduct()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #weaveProductsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfWeaveProducts" [rowData]="listOfWeaveProducts"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectWeaveProduct()" [centered]="true" (chooseEvent)="selectWeaveProduct()"></vdw-footer>
</section>
