import {getTotalRunningTimeInMsForDateRange} from '@application/helper/planning/get-total-running-time-in-milliseconds-for-date-range';
import {DateRange} from '@domain/date-range';
import {MachineStatus} from '@domain/machine/machine-status.enum';
import {Subscription} from '@domain/profile/subscription';
import {MachineEventInfo} from '@presentation/pages/texbi/machine-event-board/machine-event-info';
import {RunningMachineEventInfo} from '@presentation/pages/texbi/machine-event-board/running-machine-event-info';
import {moment, TimezoneUtils} from '@vdw/angular-component-library';

export class MachineEvent {
  private _id: number;
  private _machineEventInfo: MachineEventInfo | RunningMachineEventInfo;
  private _status: MachineStatus;
  private _startDate: Date;
  private _endDate: Date;

  public constructor(id: number, machineEventInfo: MachineEventInfo | RunningMachineEventInfo, status: MachineStatus, startDate: Date, endDate: Date) {
    this._id = id;
    this._machineEventInfo = machineEventInfo;
    this._status = status;
    this._startDate = startDate;
    this._endDate = endDate;
  }

  public get id(): number {
    return this._id;
  }

  public get machineEventInfo(): MachineEventInfo | RunningMachineEventInfo {
    return this._machineEventInfo;
  }

  public get status(): MachineStatus {
    return this._status;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public static fromJSON(machineEventJSON: any, subscription: Subscription): MachineEvent {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(machineEventJSON.startDate, subscription.timeZone.code);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(machineEventJSON.endDate, subscription.timeZone.code);

    return new MachineEvent(
      machineEventJSON.id,
      machineEventJSON.status === MachineStatus[MachineStatus.RUNNING] ? RunningMachineEventInfo.fromJSON(machineEventJSON) : MachineEventInfo.fromJSON(machineEventJSON),
      MachineStatus.getMachineStatusByLabel(machineEventJSON.status),
      startDate,
      endDate
    );
  }

  public replaceMachineEventInfo(machineEventInfo: MachineEventInfo | RunningMachineEventInfo): void {
    this._machineEventInfo = machineEventInfo;
  }

  public getTotalRunningTimeInMsForDateRange(dateRange: DateRange): number {
    return getTotalRunningTimeInMsForDateRange<MachineEvent>(this, dateRange);
  }

  public getDuration(): number {
    return moment.range(this.startDate, this.endDate).diff();
  }

  public isMachineStatusRunning(): boolean {
    return this.isSameAsCurrentMachineStatus(MachineStatus.RUNNING);
  }

  public isMachineStatusStoppedManual(): boolean {
    return this.isSameAsCurrentMachineStatus(MachineStatus.STOPPED_MANUAL);
  }

  public isMachineStatusStoppedCritical(): boolean {
    return this.isSameAsCurrentMachineStatus(MachineStatus.STOPPED_CRITICAL);
  }

  public hasRelevantEndTime(): boolean {
    return this.machineEventInfo instanceof MachineEventInfo || (this.machineEventInfo instanceof RunningMachineEventInfo && this.machineEventInfo.hasMachineYield());
  }

  private isSameAsCurrentMachineStatus(machineStatus: MachineStatus): boolean {
    return this.status === machineStatus;
  }
}
