import {Component, Inject, Input} from '@angular/core';
import {AsyncValidatorFn} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BaseComponent} from '../../../base-component';
import {Color} from '../../../common/data/color';

@Component({
  selector: 'vdw-color-picker-popup',
  templateUrl: './color-picker-popup.component.html'
})
export class ColorPickerPopupComponent extends BaseComponent {
  @Input()
  public get color(): Color {
    return this.selectedColor;
  }

  public set color(value: Color) {
    this.selectedColor = value;
  }

  public selectedColor: Color;
  public okButtonText: string;
  public cancelButtonText: string;
  public canEdit: boolean;
  public validatorFunction: () => AsyncValidatorFn;

  public constructor(
    private readonly matDialogRef: MatDialogRef<ColorPickerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.okButtonText = data.okButtonText || 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.CREATE';
    this.cancelButtonText = data.cancelButtonText || 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.CANCEL';
    this.color = data.color || Color.createEmptyColor();
    this.canEdit = data.canEdit ?? true;
    this.validatorFunction = data.validatorFunction;
  }

  public colorCreated(color: Color): void {
    this.selectedColor = color;
    this.matDialogRef.close(this.selectedColor);
  }

  public cancel(): void {
    this.matDialogRef.close();
  }
}
