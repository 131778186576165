<vdw-multi-line-drag
  #chipsContainer
  class="full-width"
  [ngClass]="compact ? 'chips-container-small' : 'chips-container'"
  [class.no-wrap]="!canMoveChips"
  [items]="chips"
  (itemsChange)="drop()"
  [draggable]="canMoveChips && !disabled"
>
  <ng-template let-chip="item">
    <div #visibleChips class="dnd-item flex-row chips-standard" [class.chips-small]="compact">
      <vdw-input-chip
        class="chip-content full-width"
        [value]="labelGetter(chip)"
        [disabled]="disabled"
        [error]="errorPredicate(chip)"
        [multiSelect]="canDeleteChips"
        [canShowBMSTheme]="true"
        [canMoveChips]="canMoveChips"
        (removeClick)="delete(chip)"
      ></vdw-input-chip>
    </div>
  </ng-template>
  <div
    [style.margin-left]="-overflowGap + 'px'"
    *ngIf="overflowAmount > 0"
    [class.error-quantity]="canShowErrorQuantity()"
    [class.menu-openned]="overflowChipTrigger.menuOpen"
    class="quantity-dropdown cursor-pointer"
    #overflowChipTrigger="matMenuTrigger"
    [matMenuTriggerFor]="overflowChips"
  >
    <p class="b2">+{{ overflowAmount }}</p>
  </div>
</vdw-multi-line-drag>

<mat-menu class="mat-elevation-z0 overflow-chips" #overflowChips="matMenu">
  <div class="chips no-wrap p-8" [ngClass]="compact ? 'chips-container-small' : 'chips-container'">
    <div *ngFor="let chip of overFlowChipLabels" class="dnd-item chips-standard" [class.chips-small]="compact">
      <vdw-input-chip
        class="chip-content full-width"
        [value]="chip"
        [disabled]="disabled"
        [error]="errorPredicate(chip)"
        [multiSelect]="canDeleteChips"
        [canShowBMSTheme]="true"
        (removeClick)="delete(chip)"
      ></vdw-input-chip>
    </div>
  </div>
</mat-menu>
