import {AssertionUtils} from './assertion-utils';

export class ObjectUtils {
  public static isDeepEqual(objectA: any, objectB: any): boolean {
    if ((objectA === null && objectB === null) || (objectA === undefined && objectB === undefined)) {
      return true;
    } else if (AssertionUtils.isNullOrUndefined(objectA) || AssertionUtils.isNullOrUndefined(objectB)) {
      return false;
    }

    const keysA = Object.keys(objectA);
    const keysB = Object.keys(objectB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const keyA of keysA) {
      const valueA = objectA[keyA];
      const valueB = objectB[keyA];
      const areObjects = ObjectUtils.isObject(valueA) && ObjectUtils.isObject(valueB);

      if ((areObjects && !ObjectUtils.isDeepEqual(valueA, valueB)) || (!areObjects && valueA !== valueB)) {
        return false;
      }
    }

    return true;
  }

  public static isObject(object: any): boolean {
    return !AssertionUtils.isNullOrUndefined(object) && typeof object === 'object';
  }

  public static parseNullable<T>(json: any, parse: (json: any) => T): T {
    return AssertionUtils.isNullOrUndefined(json) ? null : parse(json);
  }

  public static tryGetValue<T1, T2>(object: T1, replacement: T2): T1 | T2 {
    return AssertionUtils.isNullOrUndefined(object) ? replacement : object;
  }
}
