import {KeyValue} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {map, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpGridSettingsService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public get(key: string): Observable<KeyValue<string, string>> {
    return this.httpClient.get(`${this.backendConfiguration.getSettingsEndpoint(2)}ag-grid-local-storages/${key}`).pipe(map((gridSettingJSON: any) => JSON.parse(gridSettingJSON.value)));
  }

  public save(gridSetting: KeyValue<string, string>): Observable<string> {
    return this.httpClient.put(`${this.backendConfiguration.getSettingsEndpoint(2)}ag-grid-local-storages`, gridSetting).pipe(map((response: {key: string}) => response.key));
  }
}
