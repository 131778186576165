import {IdName} from '@domain/id-name';

export class Gauge extends IdName {
  private readonly _fractionalValueAsString: string;

  public constructor(id: number, name: string) {
    super(id, name);
    this._fractionalValueAsString = name.split(' ')?.[0];
  }

  public static fromJSON(object: any): Gauge {
    return new Gauge(object.id, object.name);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      fractionalValueAsString: this._fractionalValueAsString
    } as any as JSON;
  }

  public get fractionalValueAsString(): string {
    return this._fractionalValueAsString;
  }

  public get fractionalValue(): number {
    const parts = this._fractionalValueAsString.split('/');
    const numerator = parseFloat(parts[0]);
    const denominator = parseFloat(parts[1]);

    return numerator / denominator;
  }
}
