<section class="full-height flex-column">
  <vdw-tabs-menu-card
    [icon]="isEditingProductionOrderWeaving() ? 'details-blue' : 'add-blue'"
    [action]="getTitle()"
    [title]="addProductionOrderWeavingForm.value.name"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [hasPermissionToEdit]="hasPermissionToEditProductionOrderWeaving()"
    [isCreateNewPage]="!isEditingProductionOrderWeaving()"
    [saving]="saving"
    [locked]="canDisableForm()"
    [canShowDelete]="canShowDelete()"
    [identifier]="HEADER_IDENTIFIER"
    (settingsEvent)="navigateToCustomSettings()"
    (backEvent)="cancel()"
    (cancelEvent)="cancel()"
    (deleteEvent)="deleteProductionOrderWeaving()"
    (duplicateEvent)="duplicateProductionOrderWeaving()"
    (saveEvent)="saveProductionOrderWeaving(SAVE)"
    (saveAndCloseEvent)="saveProductionOrderWeaving(SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="saveProductionOrderWeaving(SAVE_AND_CREATE_NEW)"
  >
  </vdw-tabs-menu-card>

  <mat-card class="skeleton-wrapper full-height no-margin overflow-auto half-width full-width-mobile" vdwBp.gt-md="one-third-width">
    <mat-card-content>
      <article>
        <form [formGroup]="addProductionOrderWeavingForm" class="flex-column" appBreadcrumbFormLinker>
          <div class="flex-row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
              <input matInput type="text" formControlName="name" />
              <mat-hint *ngIf="canShowSuggestionForProductionOrderWeavingName()" class="flex-row">
                <vdw-ellipsis-label class="overflow-hidden" [text]="'GENERAL.SUGGESTION' | bmsTranslate: {value: suggestedProductionOrderWeavingName}" matTooltipPosition="below"></vdw-ellipsis-label>
                <a class="url" (mousedown)="applySuggestionForProductionOrderWeavingName()">{{ 'GENERAL.ACTIONS.APPLY' | bmsTranslate }}</a>
              </mat-hint>
              <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
              <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
                {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
              </mat-error>
              <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'maxlength'">
                {{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: '24'} }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>
                {{ 'MACHINE.MACHINE' | bmsTranslate: {count: 1} }} <span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
              </mat-label>
              <vdw-object-selection
                formControlName="machine"
                [objectName]="'MACHINE.MACHINE' | bmsTranslate: {count: 1} | lowercase"
                [baseRouterLink]="MACHINE_BASE_ROUTER_LINK"
                (selectClicked)="selectMachine()"
              ></vdw-object-selection>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT' | bmsTranslate: {count: 1} }}</mat-label>
              <vdw-object-selection
                formControlName="weaveProduct"
                [objectName]="'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT' | bmsTranslate: {count: 1} | lowercase"
                [baseRouterLink]="WEAVE_PRODUCT_BASE_ROUTER_LINK"
                (selectClicked)="selectWeaveProduct()"
              ></vdw-object-selection>
              <mat-error *vdwCanShowErrorForFormControl="'weaveProduct'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
            </mat-form-field>
            <div class="full-width"></div>
          </div>
          <div class="flex-row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'PRODUCTION_ORDER_LITE.PLANNED_LENGTH' | bmsTranslate }}</mat-label>
              <input formControlName="plannedLength" matInput vdwL10nDecimal min="1" type="number" />
              <span matSuffix>
                {{ 'GENERAL.UNIT.METER' | bmsTranslate }}
              </span>
              <mat-error *vdwCanShowErrorForFormControl="'plannedLength'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
              <mat-error *vdwCanShowErrorForFormControl="'plannedLength'; error: 'min'">
                {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}
              </mat-error>
            </mat-form-field>
            <vdw-datepicker-form-input
              class="full-width"
              [inputLabel]="'GENERAL.DATETIME.DUE_DATE' | bmsTranslate"
              [form]="addProductionOrderWeavingForm"
              formControlNameValue="dueDate"
              [minValue]="getEarliestPossibleDueDate()"
              [optional]="true"
            ></vdw-datepicker-form-input>
          </div>
        </form>
      </article>
    </mat-card-content>
  </mat-card>
</section>
