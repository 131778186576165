import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {FINISHINGS, Finishings, GetAllFinishingsParameters} from '@infrastructure/http/finishing/finishings';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {AssertionUtils, BaseComponent, GridOptionsBuilderFactoryService, NoDataOverlayComponentParams, OverlayComponentParams, SelectGridDialog} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';
import {OverviewListFinishingAndFinishingTemplateService} from '../finishing-and-finishing-template/overview/overview-list-finishing-and-finishing-template.service';

@Component({
  templateUrl: './select-finishing.component.html'
})
export class SelectFinishingComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('finishingsGrid') public finishingsGrid: AgGridAngular;
  public finishingsForMachineQuality: OverviewListFinishing[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private readonly machineQualityId: number;

  public constructor(
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    private readonly dialogRef: MatDialogRef<SelectFinishingComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly overviewListFinishingAndFinishingTemplateService: OverviewListFinishingAndFinishingTemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly navigationHelperService: NavigationHelperService<NavigationSelectFinishingData>
  ) {
    super();

    this.machineQualityId = data?.machineQualityId ?? null;
  }

  public get gridOptionsForListOfFinishings(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfFinishings();
    this.getFinishings();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.finishingsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationSelectFinishingData>({dialogComponent: SelectFinishingComponent});
  }

  public canSelectFinishing(): boolean {
    return !AssertionUtils.isEmpty(this.finishingsGrid?.api?.getSelectedRows());
  }

  public selectFinishing(): void {
    this.dialogRef.close(this.getSelectedFinishing());
  }

  public showOnlyLatestVersionChanged(change: boolean): void {
    this.getFinishings(change);
  }

  private setGridOptionsForListOfFinishings(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfFinishings(), GridIdentifier.SELECT_FINISHING)
        .withOnCellDoubleClicked(() => this.selectFinishing())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'TEXTILE_DATA.FINISHING.FINISHING',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColumnDefsForListOfFinishings(): ColDef[] {
    return this.overviewListFinishingAndFinishingTemplateService.getColumnDefs(false, true);
  }

  private getSelectedFinishing(): OverviewListFinishing {
    return this.finishingsGrid.api.getSelectedRows()[0];
  }

  private getFinishings(showOnlyLatestVersion: boolean = true): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineQualityId)) {
      const parameters: Partial<GetAllFinishingsParameters> = {machineQualityId: this.machineQualityId, showOnlyLatestVersion};

      this.finishings
        .getFinishingsForMachineQualityId(parameters)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((listOfFinishings: OverviewListFinishing[]) => {
          this.finishingsForMachineQuality = listOfFinishings;
        });
    } else {
      const parameters: Partial<GetAllFinishingsParameters> = {showOnlyLatestVersion};

      this.finishings
        .getAll(parameters)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((listOfFinishings: OverviewListFinishing[]) => {
          this.finishingsForMachineQuality = listOfFinishings;
        });
    }
  }
}

interface NavigationSelectFinishingData {
  dialogComponent: typeof SelectFinishingComponent;
}
