export class MultiDeleteResponse {
  private _id: number;
  private _statusCode: number;

  public constructor(id: number, statusCode: number) {
    this._id = id;
    this._statusCode = statusCode;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get statusCode(): number {
    return this._statusCode;
  }

  public set statusCode(statusCode: number) {
    this._statusCode = statusCode;
  }

  public static fromJSON(multiDeleteResponseJSON: any): MultiDeleteResponse {
    return new MultiDeleteResponse(multiDeleteResponseJSON.id, multiDeleteResponseJSON.statusCode);
  }
}
