export enum JacquardMachineManufacturerType {
  PTR_PTX_SINGLE_WIDTH = 'PTR/PTX - single width',
  PTR_PTX_DOUBLE_WIDTH = 'PTR/PTX - double width (sisal)',
  HMI_SINGLE_WIDTH = 'HMI - single width',
  HMI_DOUBLE_WIDTH = 'HMI - double width (sisal)',
  HMI_AXMINSTER = 'HMI Axminster',
  S500 = 'S500',
  S600_NO_EXF = 'S600 (no EXF)',
  S600 = 'S600',
  S800 = 'S800',
  S200_S250 = 'S200_S250',
  CI = 'Ci',
  SPLIT_14 = 'Split: 1.4Mb',
  SPLIT = 'Split: none',
  FLAT_SPLIT_14 = 'Flat Split: 1.4Mb',
  FLAT_SPLIT = 'Flat Split: none',
  CARPET = 'Carpet',
  FLAT_WEAVE = 'Flat weave',
  CARPET_ALPHA = 'Carpet (Alpha 400+)',
  OLD_SPLIT_14 = 'Old -Split: 1.4Mb',
  OLD_SPLIT = 'Old -Split: none',
  NEW_SPLIT_14 = 'New -Split: 1.4Mb',
  NEW_SPLIT = 'New -Split: none',
  FLAT_OLD_SPLIT_14 = 'Flat Old-Split: 1.4Mb',
  FLAT_OLD_SPLIT = 'Flat Old-Split: none',
  FLAT_NEW_SPLIT_14 = 'Flat New-Split: 1.4Mb',
  FLAT_NEW_SPLIT = 'Flat New-Split: none',
  HMI_PLUS_SINGLE_WIDTH = 'HMI+ - single width',
  HMI_PLUS_DOUBLE_WIDTH = 'HMI+ - double width (sisal)',
  HMI_PLUS_AXMINSTER = 'HMI+ Axminster'
}
