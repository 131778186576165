<app-textile-data-overview
  #textileDataOverview
  iconForCard="production-order-blue"
  [columnDefs]="getColumnDefsForListOfProductionSchedules()"
  [textileDataService]="productionSchedules"
  [rowHeight]="getRowHeight.bind(this)"
  (customSettingsSetup)="navigateToCustomSettings()"
  [textileDataType]="getTextileDataType()"
  [infiniteGrid]="true"
  [cacheBlockSize]="quantityOfRowsToRequestPerSlice"
  [advancedSearchFilters]="advancedSearchFilters"
  [class.loading]="loadingForTheFirstTime"
  [pickDensityUnit]="pickDensityUnit"
  [reedDensityUnit]="reedDensityUnit"
  [showSearchBar]="false"
  [dataSource]="dataSource"
  [gridIdentifier]="GRID_IDENTIFIER"
  (reloadData)="reloadProductionSchedules($event)"
  (rowsSelected)="productionSchedulesSelected($event)"
  (getAllExportData)="exportAllData($event)"
  [isExporting]="isExporting"
>
  <ng-container extra-actions>
    <button
      mat-icon-button
      class="mat-icon-action-button-lg-with-border disable-focus actions-button"
      [class.open]="isActionsMenuOpen"
      [matMenuTriggerFor]="actionsMenu"
      [disabled]="!hasSelectedProductionSchedule()"
      (menuOpened)="openActionsMenu()"
      (menuClosed)="closeActionsMenu()"
    >
      <mat-icon svgIcon="more"></mat-icon>
    </button>

    <mat-menu class="mat-elevation-z0 actions-menu" #actionsMenu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item [disabled]="!canPrintSelectedProductionScheduleWeavingOrder()" (click)="printWeavingOrderForSelectedProductionSchedule()">
          <span class="mat-menu-item-label">{{ 'GENERAL.ACTIONS.PRINT' | bmsTranslate }}</span>
          <span *ngIf="!hasPrintWeaveOrderPermission()" class="mat-menu-item-label"> ({{ 'GENERAL.HINTS.UNAUTHORIZED' | bmsTranslate }})</span>
        </button>
        <button mat-menu-item [disabled]="!canShowFailureReasonForSelectedProductionSchedule()" (click)="showFailureReasonForSelectedProductionSchedule()">
          <span class="mat-menu-item-label">{{ 'GENERAL.ERRORS.ERROR_DETAILS' | bmsTranslate }}</span>
        </button>
        <button mat-menu-item [disabled]="!canConfirmSelectedProductionSchedule()" (click)="confirmSelectedProductionSchedule()">
          <span class="mat-menu-item-label">{{ 'GENERAL.ACTIONS.CONFIRM' | bmsTranslate }}</span>
        </button>
        <ng-container *appHasPermission="permissionToDownloadEpFiles">
          <button mat-menu-item [disabled]="!canDownloadEpFilesForSelectedProductionSchedule()" (click)="downloadEpFilesForSelectedProductionSchedule()">
            <span class="mat-menu-item-label">{{ 'PRODUCTION_ORDER.OVERVIEW.DOWNLOAD_EPS' | bmsTranslate }}</span>
          </button>
          <button mat-menu-item [disabled]="!canRemoveMachineFilesForSelectedProductionSchedule()" (click)="removeMachineFilesForSelectedProductionSchedule()">
            <span class="mat-menu-item-label">{{ 'PRODUCTION_ORDER.OVERVIEW.REMOVE_FILES' | bmsTranslate }}</span>
          </button>
        </ng-container>
        <button mat-menu-item [disabled]="!canCompleteProductionSchedules()" (click)="completeProductionSchedules()">
          <span class="mat-menu-item-label">{{ 'PRODUCTION_ORDER.OVERVIEW.COMPLETE' | bmsTranslate }}</span>
        </button>
        <button mat-menu-item [disabled]="!canCompletePartiallyProductionSchedules()" (click)="completePartiallyProductionSchedules()">
          <span class="mat-menu-item-label">{{ 'PRODUCTION_ORDER.OVERVIEW.COMPLETE_PARTIALLY' | bmsTranslate }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</app-textile-data-overview>

<a #downloadAnchor fxHide></a>
<ag-grid-angular #exportProductionSchedulesGrid [gridOptions]="exportGridOptions" [rowData]="exportData" fxHide></ag-grid-angular>
