import {Permission} from '@domain/profile/permission.enum';
import {RoleInfo} from '@domain/profile/role-info';
import {UserIdentity} from '@domain/profile/user-identity';
import {flatMap, isEmpty, isNil, map, some, uniq} from 'lodash-es';
import {Company} from './company';
import {TimeZone} from './time-zone';

export class Subscription {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _roles: RoleInfo[];
  private readonly _timeZone: TimeZone;
  private _company: Company;
  private readonly _userIdentity: UserIdentity;
  private _acceptedTermsAndConditions: boolean;
  private _locked: boolean;

  public constructor(id: string, name: string, roles: RoleInfo[], timeZone: TimeZone, company: Company, userIdentity: UserIdentity, acceptedTermsAndConditions: boolean, locked: boolean) {
    this._id = id;
    this._name = name;
    this._roles = roles;
    this._timeZone = timeZone;
    this._company = company;
    this._userIdentity = userIdentity;
    this._acceptedTermsAndConditions = acceptedTermsAndConditions;
    this._locked = locked;
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get roles(): RoleInfo[] {
    return this._roles;
  }

  public get company(): Company {
    return this._company;
  }

  public set company(company: Company) {
    this._company = company;
  }

  public get timeZone(): TimeZone {
    return this._timeZone;
  }

  public get userIdentity(): UserIdentity {
    return this._userIdentity;
  }

  public get acceptedTermsAndConditions(): boolean {
    return this._acceptedTermsAndConditions;
  }

  public get locked(): boolean {
    return this._locked;
  }

  public static fromJSON(subscriptionJSON: any): Subscription {
    const subscriptionTimeZone = isNil(subscriptionJSON.subscriptionTimeZone) ? null : subscriptionJSON.subscriptionTimeZone;
    const identity = isNil(subscriptionJSON.identityUser) ? null : UserIdentity.fromJSON(subscriptionJSON.identityUser);

    return new Subscription(
      subscriptionJSON.id,
      subscriptionJSON.name,
      map(subscriptionJSON.roles, (roleJSON: any) => RoleInfo.fromJSON(roleJSON)),
      subscriptionTimeZone,
      null,
      identity,
      subscriptionJSON.acceptedTermsAndConditions,
      subscriptionJSON.isLocked
    );
  }

  public static fromLocalStorage(subscriptionJSONString: any): Subscription {
    return Subscription.fromJSON(subscriptionJSONString);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      roles: map(this._roles, (role: RoleInfo) => role.toJSON()),
      subscriptionTimeZone: this.timeZone,
      identityUser: this.userIdentity.toJSON()
    } as any as JSON;
  }

  public getAllPermissions(): Permission[] {
    return uniq(flatMap(this._roles, (role: RoleInfo) => role.permissions));
  }

  public hasPermission(permission: Permission): boolean {
    return some(this._roles, (role: RoleInfo) => role.hasPermission(permission));
  }

  public isValid(): boolean {
    return !(some(this.roles, (roleInfo: RoleInfo) => isEmpty(roleInfo.role)) || isNil(this.timeZone) || isNil(this.userIdentity));
  }

  public hasAtLeastOneOfThePermissions(permissions: Permission[]): boolean {
    return some(permissions, (permission: Permission) => this.hasPermission(permission));
  }

  public hasAllPermissions(...permissions: Permission[]): boolean {
    return permissions.every((permission: Permission) => this.hasPermission(permission));
  }
}
