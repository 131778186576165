import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class MyriadMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.MYRIAD, name, orderId);
  }

  public static fromJSON(myriadMachineJSON: any): MyriadMachine {
    return new MyriadMachine(myriadMachineJSON.id, myriadMachineJSON.name, myriadMachineJSON.orderId);
  }
}
