<section *ngIf="!canShowSelectObject()">
  <vdw-header [title]="'GENERAL.SEARCH' | bmsTranslate" svgIcon="select-blue" [canShowClose]="true" (closeEvent)="closeAdvancedSearch()"></vdw-header>
  <article class="dialog-content">
    <form class="flex-row filters" [formGroup]="advancedSearchForm">
      <div
        *ngFor="let advancedSearchFilter of advancedSearchFilters"
        [ngClass]="getPropertyClass(advancedSearchFilter.propertyName)"
        class="filter"
        [class.big-width]="isBigWidth(advancedSearchFilter)"
        [class.small-width]="isSmallWidth(advancedSearchFilter)"
      >
        <mat-form-field *ngIf="canShowTextField(advancedSearchFilter)">
          <mat-label>{{ getInputLabel(advancedSearchFilter.propertyName) }}</mat-label>
          <input
            [appDataCy]="advancedSearchFilter.propertyName"
            matInput
            type="text"
            [class.url]="isInputOfTypeObject(advancedSearchFilter)"
            [formControlName]="advancedSearchFilter.propertyName"
            (click)="selectObject(advancedSearchFilter)"
            [readonly]="isInputOfTypeObject(advancedSearchFilter)"
          />
        </mat-form-field>

        <mat-form-field *ngIf="canShowNumberField(advancedSearchFilter)">
          <mat-label>{{ getInputLabel(advancedSearchFilter.propertyName) }}</mat-label>
          <input
            [appDataCy]="advancedSearchFilter.propertyName"
            matInput
            vdwL10nDecimal
            type="number"
            [class.url]="isInputOfTypeObject(advancedSearchFilter)"
            [formControlName]="advancedSearchFilter.propertyName"
            (click)="selectObject(advancedSearchFilter)"
            [maximumFractionDigits]="maximumFractionDigits"
            [decimals]="allowDecimalCases"
          />
          <span matSuffix class="suffix-unit" *ngIf="canShowInputFieldSuffix(advancedSearchFilter)">{{ getSuffixUnit(advancedSearchFilter) }}</span>
          <ng-container *ngIf="allowDecimalCases">
            <mat-error *vdwCanShowErrorForFormControl="'advancedSearchFilter.propertyName'; error: 'badInput'">
              {{ 'GENERAL.ERRORS.INVALID_FORMAT.INVALID_FORMAT' | bmsTranslate }}
            </mat-error>
          </ng-container>
        </mat-form-field>

        <mat-form-field *ngIf="canShowSelectField(advancedSearchFilter)">
          <mat-label>{{ getInputLabel(advancedSearchFilter.propertyName) }}</mat-label>
          <mat-select [appDataCy]="advancedSearchFilter.propertyName" disableOptionCentering [formControlName]="advancedSearchFilter.propertyName">
            <mat-option *ngFor="let option of advancedSearchFilter.possiblePropertyValues" [value]="option" [attr.value]="option">
              {{ getOptionText(option, advancedSearchFilter.propertyName) }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="isInputOfTypeObject(advancedSearchFilter)">
          <mat-label>{{ getInputLabel(advancedSearchFilter.propertyName) }}</mat-label>
          <vdw-object-selection
            [formControlName]="advancedSearchFilter.propertyName"
            [objectName]="getInputLabel(advancedSearchFilter.propertyName) | lowercase"
            [chipValueKey]="getChipValueKey(advancedSearchFilter.propertyName)"
            [baseRouterLink]="getBaseRouterLink(advancedSearchFilter.propertyName)"
            (selectClicked)="selectObject(advancedSearchFilter)"
            (clearClicked)="clearValue(advancedSearchFilter)"
          ></vdw-object-selection>
        </mat-form-field>

        <vdw-datepicker-form-input
          *ngIf="canShowDateField(advancedSearchFilter)"
          [inputLabel]="getDateInputLabel(advancedSearchFilter.propertyName)"
          [form]="advancedSearchForm"
          [formControlNameValue]="advancedSearchFilter.propertyName"
          [minValue]="getMinDate(advancedSearchFilter.propertyName)"
          [maxValue]="getMaxDate(advancedSearchFilter.propertyName)"
          [placeholder]="getPlaceholderForAdvancedSearchFilter(advancedSearchFilter.propertyName)"
        ></vdw-datepicker-form-input>

        <div *ngIf="canShowCheckboxField(advancedSearchFilter)">
          <mat-checkbox type="checkbox" [formControlName]="advancedSearchFilter.propertyName" (click)="selectObject(advancedSearchFilter)" class="checkbox">{{
            'GENERAL.ADVANCED_SEARCH.NOT_IN_GROUP' | bmsTranslate
          }}</mat-checkbox>
        </div>
      </div>
    </form>
  </article>
  <vdw-footer
    cancelEventName="GENERAL.ACTIONS.CLEAR"
    chooseEventName="GENERAL.ACTIONS.APPLY"
    [centered]="true"
    (cancelEvent)="clearAdvancedSearchFilters()"
    (chooseEvent)="applyAdvancedSearchFilters()"
  >
  </vdw-footer>
</section>

<app-advanced-search-select-object
  appDataCy="advanced-search-select-object"
  *ngIf="canShowSelectObject()"
  [objectInput]="selectObjectInput"
  [selectedObject]="getSelectedObject()"
  [showSearchBar]="false"
  (objectSelected)="onObjectSelected($event)"
  (cancel)="cancelSelectObject()"
>
</app-advanced-search-select-object>
