<section class="flex-column full-height">
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate | lowercase}" svgIcon="select-blue">
    <button mat-icon-button mat-dialog-close class="mat-icon-action-button-sm-without-border">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </vdw-header>
  <article class="dialog-content full-height flex-column gap-24 overflow-hidden">
    <mat-card class="full-height overflow-hidden">
      <mat-card-header class="header flex-start-center">
        <mat-card-title class="antialiased">
          <h3 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate }}</h3>
        </mat-card-title>
        <div class="flex-end-center flex-grow">
          <button mat-icon-button class="mat-icon-action-button-lg-with-border" (click)="addPattern()">
            <mat-icon svgIcon="add"></mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content class="full-height overflow-auto">
        <ng-container *ngIf="nameGenerationPatternForms?.length; else noNamePatterns">
          <div class="properties-header">
            <h4 class="font-weight-semibold">{{ 'GENERAL.PLACEHOLDER.PATTERN_NAME' | bmsTranslate }}</h4>
          </div>
          <div
            *ngFor="let pattern of nameGenerationPatternForms.controls; let index = index"
            #container
            class="pattern-item"
            [formGroup]="pattern"
            [appRunEventsOutsideAngular]="['dragover']"
            (outSideAngularEvent)="onDragOver($event, index)"
            (dragleave)="onDraggedPatternLeavesPatternItem($event)"
            (drop)="onDraggedPatternDroppedOnPatternItem($event, index)"
          >
            <div
              #element
              class="pattern-item-container"
              [class.prevent-drop]="dragging"
              draggable="true"
              [appRunEventsOutsideAngular]="['drag']"
              (dragstart)="onDragStart($event, element, container, index)"
              (dragend)="onDragEnd()"
              (outSideAngularEvent)="onDrag($event)"
            >
              <div class="pattern-name-with-drag-handle">
                <mat-icon class="cursor-grab" svgIcon="drag-handle"></mat-icon>
                <mat-form-field class="no-subscript flex-grow">
                  <mat-select formControlName="property" disableOptionCentering>
                    <mat-option *ngFor="let nameGenerationProperty of nameGenerationProperties" [attr.value]="nameGenerationProperty" [value]="nameGenerationProperty">
                      {{ getPatternName(nameGenerationProperty) }}
                    </mat-option>
                  </mat-select>
                  <mat-error *vdwCanShowErrorForFormControl="'property'; error: 'serialNumberInMiddle'">
                    <vdw-ellipsis-label [text]="'GENERAL.PLACEHOLDER.SERIAL_NUMBER_SEQUENCE_ERROR' | bmsTranslate" matTooltipPosition="below"></vdw-ellipsis-label>
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-container [ngSwitch]="nameGeneratorPartType[pattern.value.property]">
                <ng-container *ngSwitchCase="undefined" formGroupName="entityProperty">
                  <mat-form-field class="flex-1">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.LABEL' | bmsTranslate }}</mat-label>
                    <mat-select formControlName="cutFrom" disableOptionCentering>
                      <mat-option *ngFor="let cutFrom of cutFroms | keyvalue" [attr.value]="cutFrom.key" [value]="cutFrom.key">
                        {{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.' + cutFrom.value | bmsTranslate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="pattern.value.entityProperty.cutFrom !== cutFroms.NONE" class="cut-from-left-or-right">
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_CHARACTER' | bmsTranslate }}</mat-label>
                      <input formControlName="startCharacter" matInput vdwL10nDecimal min="0" type="number" />
                      <mat-error *vdwCanShowErrorForFormControl="'entityProperty.startCharacter'; error: 'min'"> {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }} </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.LENGTH' | bmsTranslate }}</mat-label>
                      <input formControlName="length" matInput vdwL10nDecimal min="0" type="number" />
                      <mat-error *vdwCanShowErrorForFormControl="'entityProperty.length'; error: 'min'"> {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }} </mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'entityProperty.length'; error: 'max'"> {{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '20'} }} </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="nameGeneratorPartType.CUSTOM_TEXT" formGroupName="customText">
                  <mat-form-field class="flex-1">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.VALUE' | bmsTranslate }}</mat-label>
                    <input formControlName="value" matInput type="text" />
                    <mat-error *vdwCanShowErrorForFormControl="'customText.value'; error: 'required'"> {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }} </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'customText.value'; error: 'maxlength'">{{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: '20'} }}</mat-error>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngSwitchDefault formGroupName="serialNumber">
                  <mat-form-field class="flex-1">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.MAX_LENGTH' | bmsTranslate }}</mat-label>
                    <input formControlName="maxLength" matInput vdwL10nDecimal min="0" type="number" />
                    <mat-error *vdwCanShowErrorForFormControl="'serialNumber.maxLength'; error: 'min'"> {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }} </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'serialNumber.maxLength'; error: 'max'"> {{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '5'} }} </mat-error>
                  </mat-form-field>
                  <mat-form-field class="flex-1">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_VALUE' | bmsTranslate }}</mat-label>
                    <input formControlName="startValue" matInput type="text" />
                    <mat-error *vdwCanShowErrorForFormControl="'serialNumber.startValue'; error: 'maxlength'">
                      {{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: pattern.value.serialNumber.maxLength ?? '5'} }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="flex-1">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.STEP_SIZE' | bmsTranslate }}</mat-label>
                    <input formControlName="stepSize" matInput vdwL10nDecimal min="0" type="number" />
                    <mat-error *vdwCanShowErrorForFormControl="'serialNumber.stepSize'; error: 'min'"> {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }} </mat-error>
                  </mat-form-field>
                </ng-container>
              </ng-container>

              <div class="flex-start-center">
                <button mat-icon-button class="mat-icon-action-button-lg-with-border" type="button" (click)="deletePattern(index)">
                  <mat-icon svgIcon="trash"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noNamePatterns>
          <vdw-nodata-overlay
            class="no-data-overlay"
            titleParam="GENERAL.PLACEHOLDER.NAME_PATTERN"
            [shouldShowFilterNoResult]="false"
            [hideDescription]="true"
            [scale]="0.6"
            [actionsParam]="noDataOverlayActions"
          ></vdw-nodata-overlay>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <div *ngIf="nameGenerationPatternPreviewText.length" class="flex-column gap-8">
      <h4 class="font-weight-semibold">{{ 'GENERAL.PREVIEW' | bmsTranslate }}</h4>
      <h4 class="font-weight-regular">{{ nameGenerationPatternPreviewText }}</h4>
    </div>
  </article>
  <vdw-footer [centered]="true" (chooseEvent)="selectNameGenerationPattern()"></vdw-footer>
</section>
