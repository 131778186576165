<section class="flex-column flex-0">
  <vdw-header [action]="getActionText()" [title]="getTitle()" [svgIcon]="getSvgIcon()">
    <button mat-icon-button class="mat-icon-action-button-sm-without-border" (click)="close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </vdw-header>
  <article *ngIf="canShowSelectObject()" class="dialog-content flex-row gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" (click)="goToAddObjectContent()" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <button
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      *ngIf="hasEditMethod()"
      (click)="onEdit()"
      [disabled]="!isObjectSelected() || isMultiSelection()"
      [matTooltip]="'GENERAL.ACTIONS.EDIT' | bmsTranslate"
    >
      <mat-icon svgIcon="edit"></mat-icon>
    </button>
    <button
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      *ngIf="hasDeleteMethod()"
      (click)="deleteSelectedObject()"
      [disabled]="!isObjectSelected()"
      [matTooltip]="'GENERAL.ACTIONS.DELETE' | bmsTranslate"
    >
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
  </article>
  <article *ngIf="canShowSelectObject()" class="dialog-content flex-0">
    <ag-grid-angular #optionsForListOfObjectsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfObjects" [rowData]="listOfObjects"></ag-grid-angular>
  </article>
  <article *ngIf="canShowAddObject()" class="add-object-dialog-content flex-column gap-16 flex-0">
    <vdw-message-container *ngIf="addNewObjectForm.disabled" [messages]="objectInUseWarningMessage"></vdw-message-container>
    <form [formGroup]="addNewObjectForm" class="full-width">
      <div class="form-field-skeleton-wrapper full-width">
        <mat-form-field class="full-width">
          <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
            {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
          </mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
            {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </article>
  <vdw-footer *ngIf="canShowSelectObject()" [locked]="!isObjectSelected() || isMultiSelection()" [centered]="true" (chooseEvent)="selectObject()"></vdw-footer>
  <vdw-footer
    *ngIf="canShowAddObject()"
    [centered]="true"
    [withChooseButton]="false"
    (cancelEvent)="goToSelectObjectContent()"
    [withSaveButton]="true"
    (saveEvent)="save(SAVE)"
    (saveAndCreateNewEvent)="save(SAVE_AND_CREATE_NEW)"
    [locked]="addNewObjectForm.disabled"
  ></vdw-footer>
</section>
