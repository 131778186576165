import {InjectionToken} from '@angular/core';
import {ApplicationEnvironment} from '@application/configuration/application-environment';

export const RUNTIME_APPLICATION_ENVIRONMENT = new InjectionToken<RuntimeApplicationEnvironment>('RuntimeApplicationEnvironment');

export interface RuntimeApplicationEnvironment {
  loadEnvironment(): Promise<void>;

  getEnvironment(): ApplicationEnvironment;
}
