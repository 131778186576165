import {Inject, Injectable, Optional} from '@angular/core';
import {LocalStorageConfig, LOCALSTORAGE_CONFIG} from './local-storage-config';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly prefix: string;

  public constructor(@Optional() @Inject(LOCALSTORAGE_CONFIG) config: LocalStorageConfig) {
    this.prefix = config?.prefix;
    Object.keys(localStorage)
      .filter((key: string) => key.startsWith(this.prefix))
      .forEach((key: string) => {
        let value = localStorage.getItem(key);
        if (value.startsWith('"[') || value.startsWith('"{\\')) {
          localStorage.setItem(key, JSON.parse(value));
        }
        if (value === '"null"') {
          localStorage.removeItem(key);
        }
      });
  }

  public get<T = string>(key: string): T {
    return JSON.parse(localStorage.getItem(`${this.prefix}.${key}`));
  }

  public set(key: string, value: any): void {
    localStorage.setItem(`${this.prefix}.${key}`, JSON.stringify(value));
  }

  public remove(...keys: string[]): void {
    keys.forEach((key: string) => {
      localStorage.removeItem(`${this.prefix}.${key}`);
    });
  }
}
