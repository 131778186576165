import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData, SelectionListContainerData, SelectionListItem} from '@vdw/angular-component-library';
import {Observable, Subject} from 'rxjs';

@Component({
  templateUrl: './machine-filter-settings.component.html',
  styleUrls: ['./machine-filter-settings.component.scss']
})
export class MachineFilterSettingsComponent implements DialogData<SelectionListItem[]>, OnDestroy {
  private readonly filterOptionsChanges = new Subject<SelectionListItem[]>();
  public readonly selectListOptions: SelectionListItem[];
  public containerTitle: string;
  public maxHeight: string;

  public constructor(@Inject(MAT_DIALOG_DATA) data: SelectionListContainerData) {
    this.selectListOptions = data.options;
    this.containerTitle = data.containerTitle ?? 'PLANNING.SHOW_PLANNING_GROUPS';
    this.maxHeight = data.maxHeight ?? '300px';
  }

  public ngOnDestroy(): void {
    this.filterOptionsChanges.complete();
  }

  public getValue(): Observable<SelectionListItem[]> {
    return this.filterOptionsChanges.asObservable();
  }

  public onFilterOptionChanged(update: SelectionListItem[]): void {
    this.filterOptionsChanges.next(update);
  }
}
