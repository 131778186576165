import {PlanningOrder} from '@domain/planning/planning-order';
import {PlanningOrderData} from '@domain/planning/planning-order-data';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ProductionOrderCarpetForPlanningOrderStubFactory} from './production-order-carpet-for-planning-order-stub-factory';
import {ProductionOrderTuftingForPlanningOrderStubFactory} from './production-order-tufting-for-planning-order-stub-factory';
import {ProductionOrderWeavingForPlanningOrderStubFactory} from './production-order-weaving-for-planning-order-stub-factory';

export class PlanningOrderStubFactory {
  public static getRandomPlanningOrder(): PlanningOrder {
    return this.getRandomPlanningOrderWithId(1);
  }

  public static getRandomPlanningOrderWithId(id: number): PlanningOrder {
    return new PlanningOrder(id, new Date(), new Date(), null, ProductionOrderCarpetForPlanningOrderStubFactory.getRandomProductionOrder());
  }

  public static getRandomWithIdStartDateEndDateAndProductionOrderWithDueDate(id: number, startDate: Date, endDate: Date, dueDate: Date): PlanningOrder {
    return new PlanningOrder(id, startDate, endDate, null, ProductionOrderCarpetForPlanningOrderStubFactory.getRandomWithIdAndDueDate(id, dueDate));
  }

  public static getRandomWeavingWithIdStartDateEndDateAndProductionOrderWithDueDate(id: number, startDate: Date, endDate: Date, dueDate: Date): PlanningOrder {
    return new PlanningOrder(id, startDate, endDate, null, ProductionOrderWeavingForPlanningOrderStubFactory.getRandomProductionOrderLiteWithIdAndDueDate(id, dueDate));
  }

  public static getRandomTuft(): PlanningOrder {
    return new PlanningOrder(1, new Date(), new Date(), null, ProductionOrderTuftingForPlanningOrderStubFactory.getRandomProductionOrderTuft());
  }

  public static getRandomTuftingWithIdStartDateEndDateAndProductionOrderWithDueDate(id: number, startDate: Date, endDate: Date, dueDate: Date): PlanningOrder {
    return new PlanningOrder(id, startDate, endDate, null, ProductionOrderTuftingForPlanningOrderStubFactory.getRandomProductionOrderTuftWithIdAndDueDate(id, dueDate));
  }

  public static getRandomPlanningOrderThatContainsProductionOrderWithStatusValue(productionOrderStatus: ProductionScheduleStatus): PlanningOrder {
    return new PlanningOrder(1, new Date(), new Date(), null, ProductionOrderCarpetForPlanningOrderStubFactory.getRandomProductionOrderWithStatusValue(productionOrderStatus));
  }

  public static getRandomPlanningOrderThatContainsProductionOrderLiteWithStatusValue(productionOrderStatus: ProductionScheduleStatus): PlanningOrder {
    return new PlanningOrder(1, new Date(), new Date(), null, ProductionOrderWeavingForPlanningOrderStubFactory.getRandomProductionOrderLiteWithStatusValue(productionOrderStatus));
  }

  public static getRandomPlanningOrderWithEfficiency(efficiency: number): PlanningOrder {
    return new PlanningOrder(1, new Date(), new Date(), efficiency, ProductionOrderCarpetForPlanningOrderStubFactory.getRandomProductionOrder());
  }

  public static getRandomPlanningOrderWithProductionOrder(productionOrder: PlanningOrderData): PlanningOrder {
    return this.getRandomPlanningOrderWithIdAndProductionOrder(1, productionOrder);
  }

  public static getRandomPlanningOrderWithIdAndProductionOrder(id: number, productionOrder: PlanningOrderData): PlanningOrder {
    return new PlanningOrder(id, new Date(), new Date(), null, productionOrder);
  }
}
