import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {intComparator} from '@application/helper/int-comparator';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {Customer} from '@domain/customer/customer';
import {Customers, CUSTOMERS} from '@infrastructure/http/customer/customers';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-customer.component.html'
})
export class SelectCustomerComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('customersGrid') public customersGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfCustomers: Customer[];

  public constructor(
    @Inject(CUSTOMERS) private readonly customers: Customers,
    private readonly dialogRef: MatDialogRef<SelectCustomerComponent>,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectCustomerComponent>>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public get gridOptionsForListOfCustomers(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfCustomers();
    this.getCustomers();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.customersGrid?.api];
  }

  public canSelectCustomer(): boolean {
    return this.listOfCustomers?.length && !AssertionUtils.isEmpty(this.customersGrid?.api?.getSelectedRows());
  }

  public selectCustomer(): void {
    this.dialogRef.close(this.customersGrid.api.getSelectedRows()[0]);
  }

  public navigateToAddCustomer(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState({dialogComponent: SelectCustomerComponent}, RouteUtils.paths.texFab.customer.addCustomer.absolutePath);
  }

  private setGridOptionsForListOfCustomers(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfCustomers(), GridIdentifier.SELECT_CUSTOMER)
        .withOnRowDoubleClicked((row: RowDoubleClickedEvent) => {
          if (!row.node.isSelected()) {
            row.node.setSelected(true);
          }
          this.selectCustomer();
        })
        .withNoRowsOverlay({
          titleParam: 'CUSTOMERS.CUSTOMER',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .build()
    ];
  }

  private getCustomers(): void {
    this.customers
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((customers: Customer[]) => {
        this.listOfCustomers = customers;
      });
  }

  private getColumnDefsForListOfCustomers(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('number', true).withHeaderName('CUSTOMERS.NUMBER').withComparator(intComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('CUSTOMERS.NAME').withComparator(StringUtils.stringComparator).build()
    ];
  }
}
