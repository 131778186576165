import {Directive, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AdvancedSearchInputType, AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PropertyValue} from '@domain/property-value';
import {AssertionUtils, BaseComponent, convertCommercialUnit, DatepickerHeaderComponent, EnumUtils, StringUtils, TranslateService, Unit} from '@vdw/angular-component-library';
import {AdvancedSearchInput} from './advanced-search-input.enum';
import {AdvancedSearchObjectInput} from './advanced-search-object-input.enum';
import {SelectObjectType} from './advanced-search-select-object/select-object-type';

@Directive()
export abstract class BaseAdvancedSearchComponent extends BaseComponent implements OnInit {
  public advancedSearchFilters: PropertyValue[];
  public advancedSearchForm: UntypedFormGroup;
  public allowDecimalCases = false;
  public maximumFractionDigits: number;
  public selectObjectInput: AdvancedSearchObjectInput = null;
  public defaultUnit: Unit = Unit.CENTIMETER;
  public datepickerHeader = DatepickerHeaderComponent;

  protected pickDensityUnit: Unit;
  protected reedDensityUnit: Unit;

  protected constructor(
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setFormFields();
  }

  public isBigWidth(propertyValue: PropertyValue): boolean {
    return propertyValue.propertyName === AdvancedSearchInput.NAME || this.isInputOfTypeObject(propertyValue) || this.isInputOfTypeDate(propertyValue);
  }

  public isSmallWidth(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.NUMBER;
  }

  public canShowTextField(propertyValue: PropertyValue): boolean {
    const inputType: AdvancedSearchInputType = AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName);
    return inputType === AdvancedSearchInputType.STRING && !this.canShowSelectField(propertyValue);
  }

  public canShowNumberField(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.NUMBER && !this.canShowSelectField(propertyValue);
  }

  public canShowDateField(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.DATE && !this.canShowSelectField(propertyValue);
  }

  public canShowInputFieldSuffix(propertyValue: PropertyValue): boolean {
    return (
      propertyValue.propertyName === AdvancedSearchInput.LENGTH ||
      propertyValue.propertyName === AdvancedSearchInput.WIDTH ||
      propertyValue.propertyName === AdvancedSearchInput.PICK_DENSITY ||
      propertyValue.propertyName === AdvancedSearchInput.REED_DENSITY ||
      propertyValue.propertyName === AdvancedSearchInput.WIDTH_IN_DENTS ||
      propertyValue.propertyName === AdvancedSearchInput.HEIGHT_IN_PICKS
    );
  }

  public canShowSelectField(propertyValue: PropertyValue): boolean {
    return !AssertionUtils.isEmpty(propertyValue.possiblePropertyValues);
  }

  public canShowCheckboxField(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.BOOL;
  }

  public getOptionText(option: any, propertyName: string): string {
    return AdvancedSearchUtils.getDisplayValueForAdvancedSearchFilter(propertyName, option, this.translate, this.defaultUnit);
  }

  public canShowObjectPreview(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.hasValue(propertyValue) && this.isInputOfTypeObject(propertyValue);
  }

  public getPreviewObjectName(propertyValue: PropertyValue): string {
    return AdvancedSearchUtils.getDisplayValueForAdvancedSearchFilter(propertyValue.propertyName, propertyValue.propertyValue, this.translate, this.defaultUnit);
  }

  public clearValue(propertyValue: PropertyValue): void {
    propertyValue.propertyValue = null;
    this.advancedSearchForm?.get(propertyValue.propertyName).setValue(null);
  }

  public isInputOfTypeObject(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.OBJECT;
  }

  public isInputOfTypeDate(propertyValue: PropertyValue): boolean {
    return AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName) === AdvancedSearchInputType.DATE;
  }

  public canShowSelectObject(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.selectObjectInput);
  }

  public selectObject(propertyValue: PropertyValue): void {
    if (this.isInputOfTypeObject(propertyValue)) {
      this.selectObjectInput = propertyValue.propertyName as AdvancedSearchObjectInput;
    }
  }

  public getInputLabel(propertyName: string): string {
    return this.translate.instant(`GENERAL.ADVANCED_SEARCH.${EnumUtils.getKeyFromValue(AdvancedSearchInput, propertyName)}`, {count: 1});
  }

  public cancelSelectObject(): void {
    this.selectObjectInput = null;
  }

  public onObjectSelected(object: SelectObjectType): void {
    this.getAdvancedSearchFilter(this.selectObjectInput).propertyValue = object;
    this.advancedSearchForm?.get(this.getAdvancedSearchFilter(this.selectObjectInput).propertyName).setValue(object);
    this.cancelSelectObject();
  }

  public getSelectedObject(): SelectObjectType {
    return this.canShowSelectObject() ? this.getAdvancedSearchFilter(this.selectObjectInput).propertyValue : null;
  }

  public getSuffixUnit(propertyValue: PropertyValue): string {
    let unit = this.defaultUnit;
    if (propertyValue.propertyName === AdvancedSearchInput.PICK_DENSITY) {
      unit = this.pickDensityUnit;
    } else if (propertyValue.propertyName === AdvancedSearchInput.REED_DENSITY) {
      unit = this.reedDensityUnit;
    }

    return unit;
  }

  public getPropertyClass(propertyName: string): string {
    return StringUtils.kebabCase(propertyName);
  }

  public getDateInputLabel(propertyName: string): string {
    let dateInputLabel: string;

    if (propertyName === AdvancedSearchInput.COMPLETION_DATE_START) {
      dateInputLabel = this.translate.instant(`GENERAL.ADVANCED_SEARCH.COMPLETION_DATE`);
    } else if (propertyName === AdvancedSearchInput.COMPLETION_DATE_END) {
      dateInputLabel = '';
    } else {
      dateInputLabel = this.getInputLabel(propertyName);
    }
    return dateInputLabel;
  }

  public getMinDate(propertyName: string): string {
    let minDate = null;
    if (propertyName === AdvancedSearchInput.COMPLETION_DATE_END && !AssertionUtils.isNullOrUndefined(this.advancedSearchForm.get(AdvancedSearchInput.COMPLETION_DATE_START).value)) {
      minDate = this.advancedSearchForm.get(AdvancedSearchInput.COMPLETION_DATE_START).value;
    }

    return minDate;
  }

  public getMaxDate(propertyName: string): string {
    let maxDate = null;
    if (propertyName === AdvancedSearchInput.COMPLETION_DATE_START && !AssertionUtils.isNullOrUndefined(this.advancedSearchForm.get(AdvancedSearchInput.COMPLETION_DATE_END).value)) {
      maxDate = this.advancedSearchForm.get(AdvancedSearchInput.COMPLETION_DATE_END).value;
    }

    return maxDate;
  }

  public getPlaceholderForAdvancedSearchFilter(propertyName: string): string {
    return this.translate.instant(`GENERAL.ADVANCED_SEARCH.${propertyName.toUpperCase()}_PLACEHOLDER`);
  }

  protected getAdvancedSearchFilter(propertyName: string): PropertyValue {
    return this.advancedSearchFilters?.find((filter: PropertyValue) => filter.propertyName === propertyName);
  }

  protected updateAdvancedSearchFilterValues(): void {
    for (const key in this.advancedSearchForm.controls) {
      if ((key === AdvancedSearchInput.LENGTH || key === AdvancedSearchInput.WIDTH) && this.advancedSearchForm.get(key).value !== null) {
        this.getAdvancedSearchFilter(key).propertyValue = convertCommercialUnit({
          from: {
            unit: this.defaultUnit,
            value: this.advancedSearchForm.get(key).value
          },
          to: Unit.MILLIMETER
        });
      } else if (AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(key) === AdvancedSearchInputType.BOOL) {
        this.getAdvancedSearchFilter(key).propertyValue = this.advancedSearchForm.get(key).value === true ? true : null;
      } else if (AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(key) !== AdvancedSearchInputType.OBJECT) {
        this.getAdvancedSearchFilter(key).propertyValue = this.advancedSearchForm.get(key).value;
      }
    }
  }

  protected clearAdvancedSearchFilterValues(): void {
    for (const propertyValue of this.advancedSearchFilters) {
      AdvancedSearchUtils.clearValue(propertyValue);
    }
  }

  private setFormFields(): void {
    const controlConfigs = {};
    for (const advancedSearchFilter of this.advancedSearchFilters) {
      controlConfigs[advancedSearchFilter.propertyName] = advancedSearchFilter.propertyValue;
    }

    this.advancedSearchForm = this.formBuilder.group(controlConfigs);
  }

  public getChipValueKey(propertyName: string): string {
    return propertyName === AdvancedSearchInput.MACHINE_QUALITY ? '%(technicalNameWithVersion)' : null;
  }

  public getBaseRouterLink(propertyName: string): string {
    switch (propertyName) {
      case AdvancedSearchInput.MACHINE_QUALITY:
        return RouteUtils.paths.texStyle.machineQuality.editMachineQuality.absolutePath;
      case AdvancedSearchInput.COLORED_YARN_SET:
        return RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet.absolutePath;
      case AdvancedSearchInput.MACHINE:
        return RouteUtils.paths.texState.editMachine.absolutePath;
      case AdvancedSearchInput.COLOR_SET:
        return RouteUtils.paths.texStyle.colorSet.editColorSet.absolutePath;
      case AdvancedSearchInput.MAIN_COLOR:
        return RouteUtils.paths.texStyle.color.editColor.absolutePath;
      case AdvancedSearchInput.BORDER_COLOR:
        return RouteUtils.paths.texStyle.color.editColor.absolutePath;
      case AdvancedSearchInput.ARTICLE:
        return NavigationUtils.getAbsolutePath(NavigationId.EDIT_ARTICLE);
      case AdvancedSearchInput.CREEL:
        return RouteUtils.paths.texStyle.creel.editCreel.absolutePath;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        return RouteUtils.paths.texStyle.weaveStructure.editWeaveStructure.absolutePath;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        return RouteUtils.paths.texStyle.weftColoredYarnSet.editWeftColoredYarnSet.absolutePath;
      case AdvancedSearchObjectInput.WEAVE_PRODUCT:
        return RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct.absolutePath;
      case AdvancedSearchObjectInput.PLASTIC_PRODUCT:
        return RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.absolutePath;
      case AdvancedSearchObjectInput.TOOL:
        return RouteUtils.paths.utilities.tool.editTool.absolutePath;
    }
  }

  public abstract clearAdvancedSearchFilters(): void;

  public abstract applyAdvancedSearchFilters(): void;

  public abstract closeAdvancedSearch(): void;
}
