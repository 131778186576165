import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {convertCommercialUnit} from './convert-commercial-unit';
import {convertDensityUnit} from './convert-density-unit';
import {convertFileSizeUnit} from './convert-file-size-unit';
import {convertTextileUnit} from './convert-textile-unit';
import {UnitConversionParameters} from './unit-conversion-parameters';

export function convertUnit(unitConversionParameters: UnitConversionParameters): number {
  let result: number;

  if (Unit.isConvertibleToCommercialUnit(unitConversionParameters.from.unit)) {
    result = convertCommercialUnit(unitConversionParameters);
  } else if (Unit.isConvertibleToDensityUnit(unitConversionParameters.from.unit)) {
    result = convertDensityUnit(unitConversionParameters);
  } else if (Unit.isConvertibleToTextileUnit(unitConversionParameters.from.unit)) {
    result = convertTextileUnit(unitConversionParameters);
  } else if (Unit.isConvertibleToFileSizeUnit(unitConversionParameters.from.unit)) {
    result = convertFileSizeUnit(unitConversionParameters);
  } else {
    throw new TextileUnitConversionError(`No conversion found from ${unitConversionParameters.from.unit} to ${unitConversionParameters.to}`);
  }

  return result;
}
