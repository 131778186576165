<div #preview class="image" [ngClass]="isImageDownloading() ? 'download' : 'upload'" fxLayoutAlign="center">
  <img *ngIf="hasMissingDesign; else designIsPresent" class="full-width full-height" alt="{{ 'DESIGN_LIBRARY.LIBRARY.DESIGN_ALT' | bmsTranslate }}" [src]="MISSING_DATA_IMAGE" draggable="false" />

  <ng-template #designIsPresent>
    <img
      appLoadDrawingImage
      alt="{{ 'DESIGN_LIBRARY.LIBRARY.DESIGN_ALT' | bmsTranslate }}"
      [imageId]="drawingImage?.id"
      [imageSignature]="drawingImage?.signature"
      [qualityId]="qualityId"
      [colorSetId]="colorSetId"
      [drawingDimensionsInPx]="drawingDimensionsInPx"
      [shouldDownloadImage]="shouldDownloadImage"
      (imageDownloaded)="onImageDownloaded($event)"
      width="100%"
      height="100%"
      draggable="false"
      *ngIf="!hasDrawingImageData() && !hasMissingDesign"
    />
    <img class="full-width full-height" alt="{{ 'DESIGN_LIBRARY.LIBRARY.DESIGN_ALT' | bmsTranslate }}" [src]="drawingImage.data" draggable="false" *ngIf="hasDrawingImageData() && !hasMissingDesign" />
    <div *ngIf="canShowImageLoader()" class="loading" fxLayout="row" fxLayoutAlign="center end">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>
  </ng-template>
</div>
