import {FixedSchedule} from '../carpet/fixed-schedule';
import {FixedScheduleCompatibility} from './fixed-schedule-compatibility';

export class FixedScheduleToPlan {
  private _fixedSchedule: FixedSchedule;
  private _compatibility: FixedScheduleCompatibility[];

  public constructor(fixedSchedule: FixedSchedule, compatibility: FixedScheduleCompatibility[]) {
    this._fixedSchedule = fixedSchedule;
    this._compatibility = compatibility;
  }

  public get fixedSchedule(): FixedSchedule {
    return this._fixedSchedule;
  }

  public get name(): string {
    return this.fixedSchedule.name;
  }

  public get compatibility(): FixedScheduleCompatibility[] {
    return this._compatibility;
  }

  public static fromJSON(json: any): FixedScheduleToPlan {
    return new FixedScheduleToPlan(FixedSchedule.fromJSON(json.fixedSchedule), json.compatibility.map(FixedScheduleCompatibility.fromJSON));
  }
}
