import {ProductionOrder} from './production-order';

export class FixedSchedule {
  private _id: number;
  private _name: string;
  private _reedDensityInDentsPerMeter: number;
  private _nrDentsForFabric: number;
  private _nrSolenoidsPerPile: number;
  private _nrCreelPositions: number;
  private _productionOrder: ProductionOrder;

  public constructor(id: number, name: string, reedDensityInDentsPerMeter: number, nrDentsForFabric: number, nrSolenoidsPerPile: number, nrCreelPositions: number, productionOrder: ProductionOrder) {
    this._id = id;
    this._name = name;
    this._reedDensityInDentsPerMeter = reedDensityInDentsPerMeter;
    this._nrDentsForFabric = nrDentsForFabric;
    this._nrSolenoidsPerPile = nrSolenoidsPerPile;
    this._nrCreelPositions = nrCreelPositions;
    this._productionOrder = productionOrder;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get reedDensityInDentsPerMeter(): number {
    return this._reedDensityInDentsPerMeter;
  }

  public get nrDentsForFabric(): number {
    return this._nrDentsForFabric;
  }

  public get nrSolenoidsPerPile(): number {
    return this._nrSolenoidsPerPile;
  }

  public get nrCreelPositions(): number {
    return this._nrCreelPositions;
  }

  public get productionOrder(): ProductionOrder {
    return this._productionOrder;
  }

  public static fromJSON(json: any): FixedSchedule {
    return new FixedSchedule(
      json.id,
      json.name,
      json.reedDensityInDentsPerMeter,
      json.nrDentsForFabric,
      json.nrSolenoidsPerPile,
      json.nrCreelPositions,
      ProductionOrder.fromJSON(json.productionOrder)
    );
  }
}
