import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {lowerCase} from 'lodash-es';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {TranslateService} from '../../translation/translate.service';
import {LinkIconRendererParams} from './link-icon-renderer-params.interface';

@Component({
  templateUrl: './link-icon-renderer.component.html',
  styleUrls: ['./link-icon-renderer.component.scss']
})
export class LinkIconRendererComponent implements ICellRendererAngularComp {
  public name: string;
  public canClick: boolean | (() => boolean);
  public hasNoName: boolean;
  public editPageUrl: string;
  private isRowGroupParent: boolean;

  public constructor(private readonly translate: TranslateService) {}

  public agInit(params: LinkIconRendererParams): void {
    this.name = this.getName(params.value);
    this.canClick = params.canClick ?? true;

    this.isRowGroupParent = AssertionUtils.isNullOrUndefined(params.node?.data);

    this.editPageUrl = params.node?.data?.machineType
      ? params.editPageUrl.replace(':machineType', params.node.data.machineType.toLowerCase().replace('_', '-')).replace(':id', params?.id ?? params?.data?.id?.toString())
      : params.editPageUrl.replace(':id', params?.id ?? params?.data?.id?.toString());
  }

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }

  public iconIsVisible(): boolean {
    return (typeof this.canClick === 'boolean' ? this.canClick : this.canClick()) && !this.isRowGroupParent;
  }

  private getName(value: string): string {
    if (!value) {
      this.hasNoName = true;
      return this.translate.instant('GENERAL.NO_DATA', {object: lowerCase(this.translate.instant('GENERAL.NAME'))});
    }
    this.hasNoName = false;
    return value;
  }
}
