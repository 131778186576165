import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ElementPressedModule} from '../../custom-directives/element-pressed/element-pressed.module';
import {HoverDialogBuilderService} from '../../dialogs/hover-dialog/hover-dialog-builder.service';
import {TranslationModule} from '../../translation/translation.module';
import {ApplicationLayoutModule} from '../application-layout/application-layout.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {MenuItemComponent} from './menu-item/menu-item.component';
import {MenuTreeViewContextService} from './menu-tree-view-context.service';
import {MenuTreeViewComponent} from './menu-tree-view.component';

@NgModule({
  declarations: [MenuItemComponent, MenuTreeViewComponent],
  providers: [MenuTreeViewContextService, HoverDialogBuilderService],
  imports: [CommonModule, MatIconModule, MatButtonModule, LabelModule, ElementPressedModule, TranslationModule, ApplicationLayoutModule],
  exports: [MenuItemComponent, MenuTreeViewComponent]
})
export class MenuTreeViewModule {}
