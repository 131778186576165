import {Equipment} from '@domain/machine/equipment';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {isNil, map, size} from 'lodash-es';
import {BuilderType} from './builder-type.enum';
import {JacquardMachineLoomLayoutZone} from './jacquard-machine-loom-layout-zone';
import {MachineGroup} from './machine-group';
import {MachineManufacturer} from './machine-manufacturer';
import {MachineType} from './machine-type.enum';

export class CarpetLoomMachine extends Equipment {
  private _machineGroup: MachineGroup;
  private _remote: boolean;
  private _manufacturer: MachineManufacturer;
  private _jacquardType: string;
  private _minNumberOfPicks: number;
  private _pickDensityInSchedule: boolean;
  private _possibleQualities: OverviewListMachineQuality[];
  private _possibleCreels: OverviewListCreel[];
  private _numberOfCreelPositions: number;
  private _dentsForFabric: number;
  private _sendReport: number;
  private _builderType: BuilderType;
  private _loomLayoutZones: JacquardMachineLoomLayoutZone[];

  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.CARPET_LOOM, name, orderId);

    this._machineGroup = null;
    this._remote = false;
    this._loomLayoutZones = [];
  }

  public static fromJSON(carpetLoomMachineJSON: any): CarpetLoomMachine {
    const machineQualities: OverviewListMachineQuality[] =
      size(carpetLoomMachineJSON.possibleMachineQualities) > 0
        ? map(carpetLoomMachineJSON.possibleMachineQualities, (possibleMachineQuality: any) => {
            return OverviewListMachineQuality.fromJSON(possibleMachineQuality);
          })
        : null;

    const creels: OverviewListCreel[] =
      size(carpetLoomMachineJSON.possibleCreels) > 0
        ? map(carpetLoomMachineJSON.possibleCreels, (possibleCreel: any) => {
            return OverviewListCreel.fromJSON(possibleCreel);
          })
        : null;

    const machineGroup: MachineGroup = isNil(carpetLoomMachineJSON.machineGroup) ? null : MachineGroup.fromJSON(carpetLoomMachineJSON.machineGroup);

    const carpetLoomMachine = new CarpetLoomMachine(carpetLoomMachineJSON.id, carpetLoomMachineJSON.name, carpetLoomMachineJSON.orderId);
    carpetLoomMachine.manufacturer = isNil(carpetLoomMachineJSON.manufacturerId) ? null : new MachineManufacturer(carpetLoomMachineJSON.manufacturerId);
    carpetLoomMachine.jacquardType = carpetLoomMachineJSON.jacquardType;
    carpetLoomMachine.minNumberOfPicks = carpetLoomMachineJSON.minNumberOfPicks;
    carpetLoomMachine.pickDensityInSchedule = carpetLoomMachineJSON.pickDensityInSchedule;
    carpetLoomMachine.possibleQualities = machineQualities;
    carpetLoomMachine.possibleCreels = creels;
    carpetLoomMachine.numberOfCreelPositions = carpetLoomMachineJSON.numberOfCreelPositions;
    carpetLoomMachine.dentsForFabric = carpetLoomMachineJSON.dentsForFabric;
    carpetLoomMachine.sendReport = carpetLoomMachineJSON.sendReport;
    carpetLoomMachine.builderType = carpetLoomMachineJSON.builderType ? BuilderType[carpetLoomMachineJSON.builderType as string] : null;
    carpetLoomMachine.remote = carpetLoomMachineJSON.remoteMachine;
    carpetLoomMachine.machineGroup = machineGroup;
    carpetLoomMachine.loomLayoutZones = carpetLoomMachineJSON.loomLayoutZones
      ? carpetLoomMachineJSON.loomLayoutZones
          .map(JacquardMachineLoomLayoutZone.fromJSON)
          .sort((firstLoomLayoutZone: JacquardMachineLoomLayoutZone, secondLoomLayoutZone: JacquardMachineLoomLayoutZone) => firstLoomLayoutZone.position - secondLoomLayoutZone.position)
      : [];

    return carpetLoomMachine;
  }

  public get manufacturer(): MachineManufacturer {
    return this._manufacturer;
  }

  public set manufacturer(manufacturer: MachineManufacturer) {
    this._manufacturer = manufacturer;
  }

  public get jacquardType(): string {
    return this._jacquardType;
  }

  public set jacquardType(jacquardType: string) {
    this._jacquardType = jacquardType;
  }

  public get minNumberOfPicks(): number {
    return this._minNumberOfPicks;
  }

  public set minNumberOfPicks(minNumberOfPicks: number) {
    this._minNumberOfPicks = minNumberOfPicks;
  }

  public get pickDensityInSchedule(): boolean {
    return this._pickDensityInSchedule;
  }

  public set pickDensityInSchedule(pickDensityInSchedule: boolean) {
    this._pickDensityInSchedule = pickDensityInSchedule;
  }

  public get possibleQualities(): OverviewListMachineQuality[] {
    return this._possibleQualities;
  }

  public set possibleQualities(possibleQualities: OverviewListMachineQuality[]) {
    this._possibleQualities = possibleQualities;
  }

  public get possibleCreels(): OverviewListCreel[] {
    return this._possibleCreels;
  }

  public set possibleCreels(possibleCreels: OverviewListCreel[]) {
    this._possibleCreels = possibleCreels;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public set numberOfCreelPositions(numberOfCreelPositions: number) {
    this._numberOfCreelPositions = numberOfCreelPositions;
  }

  public get dentsForFabric(): number {
    return this._dentsForFabric;
  }

  public set dentsForFabric(dentsForFabric: number) {
    this._dentsForFabric = dentsForFabric;
  }

  public get sendReport(): number {
    return this._sendReport;
  }

  public set sendReport(sendReport: number) {
    this._sendReport = sendReport;
  }

  public get machineGroup(): MachineGroup {
    return this._machineGroup;
  }

  public set machineGroup(machineGroup: MachineGroup) {
    this._machineGroup = machineGroup;
  }

  public get remote(): boolean {
    return this._remote;
  }

  public set remote(remote: boolean) {
    this._remote = remote;
  }

  public get builderType(): BuilderType {
    return this._builderType;
  }

  public set builderType(builderType: BuilderType) {
    this._builderType = builderType;
  }

  public get loomLayoutZones(): JacquardMachineLoomLayoutZone[] {
    return this._loomLayoutZones;
  }

  public set loomLayoutZones(loomLayoutZones: JacquardMachineLoomLayoutZone[]) {
    this._loomLayoutZones = loomLayoutZones;
  }

  public toJSON(): JSON {
    const carpetLoomMachineJSON = super.toJSON();

    carpetLoomMachineJSON['jacquardType'] = this.jacquardType;
    carpetLoomMachineJSON['manufacturerId'] = this.manufacturer ? this.manufacturer.id : null;
    carpetLoomMachineJSON['minNumberOfPicks'] = this.minNumberOfPicks;
    carpetLoomMachineJSON['numberOfCreelPositions'] = this.numberOfCreelPositions;
    carpetLoomMachineJSON['pickDensityInSchedule'] = this.pickDensityInSchedule;
    carpetLoomMachineJSON['possibleCreelIds'] = map(this.possibleCreels, (possibleCreel: OverviewListCreel) => possibleCreel.id);
    carpetLoomMachineJSON['possibleMachineQualityIds'] = map(this.possibleQualities, (possibleQuality: OverviewListMachineQuality) => possibleQuality.id);
    carpetLoomMachineJSON['dentsForFabric'] = this.dentsForFabric;
    carpetLoomMachineJSON['sendReport'] = this.sendReport;
    carpetLoomMachineJSON['machineGroupId'] = this.machineGroup?.id;
    carpetLoomMachineJSON['machineGroup'] = this.machineGroup?.toJSON();
    carpetLoomMachineJSON['remoteMachine'] = this.remote;
    carpetLoomMachineJSON['builderType'] = BuilderType[this.builderType];
    carpetLoomMachineJSON['loomLayoutZones'] = this._loomLayoutZones.map((loomLayoutZone: JacquardMachineLoomLayoutZone) => loomLayoutZone.toJSON());

    return carpetLoomMachineJSON;
  }
}
