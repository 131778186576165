import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ColorPickerMode} from './color-picker-mode.enum';
import {ColorPresetPickerMenuComponent} from './color-preset-picker-menu/color-preset-picker-menu.component';

@Component({
  selector: 'vdw-color-picker-form-input',
  templateUrl: './color-picker-form-input.component.html',
  styleUrls: ['./color-picker-form-input.component.scss']
})
export class ColorPickerFormInputComponent {
  @Input() public mode = ColorPickerMode.NATIVE;
  @Input() public selectedColor: string;
  @Output() public selectedColorChange = new EventEmitter<string>();

  public readonly COLOR_PICKER_MODE = ColorPickerMode;
  public readonly COLOR_PRESET_PICKER_MENU_COMPONENT = ColorPresetPickerMenuComponent;

  public getDialogData(): {limited: boolean; selectedColor: string} {
    return {limited: this.mode === ColorPickerMode.LIMITED, selectedColor: this.selectedColor};
  }

  public onSelectedColorChanged(color: string): void {
    this.selectedColorChange.emit(color);
  }
}
