import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {PatternComponent} from './pattern.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [PatternComponent],
  declarations: [PatternComponent]
})
export class PatternModule {}
