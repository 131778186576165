import {DataUnitSetupCatalogRoute} from '@application/helper/routing/data-unit-setup-catalog-route';
import {DataUnitSetupRoute} from '@application/helper/routing/data-unit-setup-route';
import {MachineDataSourceRoute} from '@application/helper/routing/machine-data-sources.route';
import {AlertRoute} from './alert-route';
import {DeclarationRoute} from './declaration-route';
import {DeviceTemplateCatalogRoute} from './device-template-catalog-route';
import {DeviceTemplateRoute} from './device-template-route';
import {EventRoute} from './event-route';
import {OperatorRoute} from './operator-route';
import {PhysicalQuantityRoute} from './physical-quantity-route';
import {ReportItemRoute} from './report-item-route';
import {ResourceTypeRoute} from './resource-type-route';
import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';
import {RejectReasonRoute} from './reject-reason-route';

export class SettingsRoute extends RouteBranch {
  private _machineDataSources: MachineDataSourceRoute;
  private _dataUnitSetup: DataUnitSetupRoute;
  private _dataUnitSetupCatalog: DataUnitSetupCatalogRoute;
  private _resourceType: ResourceTypeRoute;
  private _physicalQuantity: PhysicalQuantityRoute;
  private _declaration: DeclarationRoute;
  private _deviceTemplate: DeviceTemplateRoute;
  private _deviceTemplateCatalog: DeviceTemplateCatalogRoute;
  private _reportItem: ReportItemRoute;
  private _event: EventRoute;
  private _alert: AlertRoute;
  private _automaticStopGroup: RouteLeaf;
  private _operator: OperatorRoute;
  private _rejectReason: RejectReasonRoute;

  public get machineDataSources(): MachineDataSourceRoute {
    return this._machineDataSources;
  }

  public set machineDataSources(value: MachineDataSourceRoute) {
    this.replace(this._machineDataSources, value);
    this._machineDataSources = value;
  }

  public get dataUnitSetup(): DataUnitSetupRoute {
    return this._dataUnitSetup;
  }

  public set dataUnitSetup(value: DataUnitSetupRoute) {
    this.replace(this._dataUnitSetup, value);
    this._dataUnitSetup = value;
  }

  public get dataUnitSetupCatalog(): DataUnitSetupCatalogRoute {
    return this._dataUnitSetupCatalog;
  }

  public set dataUnitSetupCatalog(value: DataUnitSetupCatalogRoute) {
    this.replace(this._dataUnitSetupCatalog, value);
    this._dataUnitSetupCatalog = value;
  }

  public get resourceType(): ResourceTypeRoute {
    return this._resourceType;
  }

  public set resourceType(value: ResourceTypeRoute) {
    this.replace(this._resourceType, value);
    this._resourceType = value;
  }

  public get declaration(): DeclarationRoute {
    return this._declaration;
  }

  public set declaration(value: DeclarationRoute) {
    this.replace(this._declaration, value);
    this._declaration = value;
  }

  public get physicalQuantity(): PhysicalQuantityRoute {
    return this._physicalQuantity;
  }

  public set physicalQuantity(value: PhysicalQuantityRoute) {
    this.replace(this._physicalQuantity, value);
    this._physicalQuantity = value;
  }

  public get deviceTemplate(): DeviceTemplateRoute {
    return this._deviceTemplate;
  }

  public set deviceTemplate(value: DeviceTemplateRoute) {
    this.replace(this._deviceTemplate, value);
    this._deviceTemplate = value;
  }

  public get deviceTemplateCatalog(): DeviceTemplateCatalogRoute {
    return this._deviceTemplateCatalog;
  }

  public set deviceTemplateCatalog(value: DeviceTemplateCatalogRoute) {
    this.replace(this._deviceTemplateCatalog, value);
    this._deviceTemplateCatalog = value;
  }

  public get reportItem(): ReportItemRoute {
    return this._reportItem;
  }

  public set reportItem(value: ReportItemRoute) {
    this.replace(this._reportItem, value);
    this._reportItem = value;
  }

  public get event(): EventRoute {
    return this._event;
  }

  public set event(value: EventRoute) {
    this.replace(this._event, value);
    this._event = value;
  }

  public get alert(): AlertRoute {
    return this._alert;
  }

  public set alert(value: AlertRoute) {
    this.replace(this._alert, value);
    this._alert = value;
  }

  public get automaticStopGroup(): RouteLeaf {
    return this._automaticStopGroup;
  }

  public set automaticStopGroup(value: RouteLeaf) {
    this.replace(this._automaticStopGroup, value);
    this._automaticStopGroup = value;
  }

  public get operator(): OperatorRoute {
    return this._operator;
  }

  public set operator(value: OperatorRoute) {
    this.replace(this._operator, value);
    this._operator = value;
  }

  public get rejectReason(): RejectReasonRoute {
    return this._rejectReason;
  }

  public set rejectReason(value: RejectReasonRoute) {
    this.replace(this._rejectReason, value);
    this._rejectReason = value;
  }
}
