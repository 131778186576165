import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslationModule} from '../../translation/translation.module';
import {StringInputComponent} from './string-input.component';

@NgModule({
  declarations: [StringInputComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslationModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule],
  exports: [StringInputComponent]
})
export class StringInputModule {}
