import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MobileSelectorDialogDataInterface} from './mobile-selector-dialog-data.interface';
import {MobileSelectorOption} from './mobile-selector-option.interface';

@Component({
  templateUrl: './mobile-selector.component.html',
  styleUrls: ['./mobile-selector.component.scss']
})
export class MobileSelectorComponent {
  public selectedOption: MobileSelectorOption;
  public options: MobileSelectorOption[];
  public title: string;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: MobileSelectorDialogDataInterface,
    private readonly dialogRef: MatDialogRef<MobileSelectorComponent>
  ) {
    this.selectedOption = data.selectedOption;
    this.options = data.options;
    this.title = data.title;
  }

  public closeDialog(option: MobileSelectorOption): void {
    this.dialogRef.close(option);
  }
}
