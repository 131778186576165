import {getTotalRunningTimeInMsForDateRange} from '@application/helper/planning/get-total-running-time-in-milliseconds-for-date-range';
import {DateRange} from '@domain/date-range';
import {MachineType} from '@domain/machine/machine-type.enum';
import {AssertionUtils, moment, TimeUtils, TimezoneUtils} from '@vdw/angular-component-library';
import {duration} from 'moment';
import {ProductionOrderCarpetForPlanningOrder} from './carpet/production-order-carpet-for-planning-order';
import {PlanningOrderData} from './planning-order-data';
import {ProductionOrderTuftingForPlanningOrder} from './tufting/production-order-tufting-for-planning-order';
import {ProductionOrderWeavingForPlanningOrder} from './weaving/production-order-weaving-for-planning-order';

export class PlanningOrder {
  private _id: number;
  private _startDate: Date;
  private _endDate: Date;
  private _efficiency: number;
  private _productionOrder: PlanningOrderData;

  public constructor(id: number, startDate: Date, endDate: Date, efficiency: number, productionOrder: PlanningOrderData) {
    this._id = id;
    this._startDate = startDate;
    this._endDate = endDate;
    this._efficiency = efficiency;
    this._productionOrder = productionOrder;
  }

  public static fromJSON(planningOrderJSON: any, timezoneCode: string, orderData: PlanningOrderData): PlanningOrder {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningOrderJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningOrderJSON.endDate, timezoneCode);
    return new PlanningOrder(planningOrderJSON.id, startDate, endDate, planningOrderJSON.efficiency, orderData);
  }

  public static fromWeavingJSON(planningOrderJSON: any, timezoneCode: string): PlanningOrder {
    const orderData = ProductionOrderWeavingForPlanningOrder.fromJSON(planningOrderJSON.productionOrderLite);
    return PlanningOrder.fromJSON(planningOrderJSON, timezoneCode, orderData);
  }

  public static fromTuftingJSON(planningOrderJSON: any, timezoneCode: string): PlanningOrder {
    const orderData = ProductionOrderTuftingForPlanningOrder.fromJSON(planningOrderJSON.productionOrderLite);
    return PlanningOrder.fromJSON(planningOrderJSON, timezoneCode, orderData);
  }

  public static fromCarpetJSON(planningOrderJSON: any, timezoneCode: string): PlanningOrder {
    const orderData = ProductionOrderCarpetForPlanningOrder.fromJSON(planningOrderJSON.productionOrder);
    return PlanningOrder.fromJSON(planningOrderJSON, timezoneCode, orderData);
  }

  public toJSON(timezoneCode: string): JSON {
    const data = {
      id: this._id,
      startDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._startDate, timezoneCode),
      endDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._endDate, timezoneCode)
    } as any;

    if (this.productionOrder instanceof ProductionOrderCarpetForPlanningOrder) {
      data.productionOrder = this._productionOrder.toJSON();
    } else if (this.productionOrder instanceof ProductionOrderWeavingForPlanningOrder) {
      data.productionOrderLite = this._productionOrder.toJSON();
    } else if (this.productionOrder instanceof ProductionOrderTuftingForPlanningOrder) {
      data.productionOrderLite = this._productionOrder.toJSON();
    }

    return data as JSON;
  }

  public get id(): number {
    return this._id;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public set startDate(startDate: Date) {
    this._startDate = startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public set endDate(endDate: Date) {
    this._endDate = endDate;
  }

  public get efficiency(): number {
    return this._efficiency;
  }

  public get productionOrder(): PlanningOrderData {
    return this._productionOrder;
  }

  public get isProductionOrderDueDateBeforeEndDate(): boolean {
    return AssertionUtils.isNullOrUndefined(this._productionOrder.dueDate) ? false : moment(this._productionOrder.dueDate).endOf('day').isBefore(this._endDate);
  }

  public get totalRunningTimeInMs(): number {
    return moment(this._endDate).diff(this._startDate, TimeUtils.MILLISECONDS_UNIT);
  }

  public get isCarpetOrder(): boolean {
    return this._productionOrder instanceof ProductionOrderCarpetForPlanningOrder;
  }

  public get isWeavingOrder(): boolean {
    return this._productionOrder instanceof ProductionOrderWeavingForPlanningOrder;
  }

  public get isTuftingOrder(): boolean {
    return this._productionOrder instanceof ProductionOrderTuftingForPlanningOrder;
  }

  public getTotalRunningTimeInMsForDateRange(dateRange: DateRange): number {
    return getTotalRunningTimeInMsForDateRange<PlanningOrder>(this, dateRange);
  }

  public canBeRescheduled(): boolean {
    return !this.productionOrder.completed && !this.productionOrder.executing;
  }

  public isCompatibleWithMachineType(machineType: MachineType): boolean {
    if (this.isWeavingOrder) {
      return machineType === MachineType.WEAVING_MACHINE;
    }
    if (this.isTuftingOrder) {
      return machineType === MachineType.TUFT;
    }
    return ![MachineType.WEAVING_MACHINE, MachineType.TUFT].includes(machineType);
  }

  public updateStartWhilePreservingDuration(startDate: Date): void {
    const originalDuration = duration(moment(this._endDate).diff(this.startDate));
    this._startDate = startDate;
    this._endDate = moment(startDate).add(originalDuration).toDate();
  }
}
