import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Command} from '@application/command/command';
import {WINDOW} from '@vdw/angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class PrintDocument implements Command<[string, string], void> {
  private readonly renderer: Renderer2;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public execute([xml, xslt]: [string, string]): void {
    const printableElement: HTMLElement = this.renderer.createElement('div');
    this.renderer.addClass(printableElement, 'printable');

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const xsltDoc = parser.parseFromString(xslt, 'text/xml');
    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);

    const result: DocumentFragment = xsltProcessor.transformToFragment(xmlDoc, this.document);
    this.renderer.appendChild(printableElement, result);
    this.renderer.appendChild(this.document.body, printableElement);

    this.window.onafterprint = (): void => this.renderer.removeChild(this.document.body, printableElement);

    this.window.print();
  }
}
