import {PriorityLevel} from '@domain/event/priority-level.enum';
import {Notification} from './notification';

export class HighestSeverityNotification {
  private readonly _equipmentId?: number;
  private readonly _groupId?: number;
  private _alertLogId: number;
  private _highestPriorityLevel: PriorityLevel;

  public constructor(equipmentId?: number, groupId?: number, alertLogId?: number, highestPriorityLevel?: PriorityLevel) {
    this._equipmentId = equipmentId;
    this._groupId = groupId;
    this._alertLogId = alertLogId;
    this._highestPriorityLevel = highestPriorityLevel;
  }

  public get equipmentId(): number {
    return this._equipmentId;
  }

  public get groupId(): number {
    return this._groupId;
  }

  public get alertLogId(): number {
    return this._alertLogId;
  }

  public get highestPriorityLevel(): PriorityLevel {
    return this._highestPriorityLevel;
  }

  public static fromJSON(notificationJSON: any): HighestSeverityNotification {
    return new HighestSeverityNotification(notificationJSON.equipmentId, notificationJSON.groupId, notificationJSON.alertLogId, PriorityLevel[notificationJSON.highestPriorityLevel]);
  }

  public updateWithNotification(notification: Notification): void {
    if (this.getPriorityLevelSeveritiy(notification.priority) > this.getPriorityLevelSeveritiy(this.highestPriorityLevel)) {
      this._alertLogId = notification.id;
      this._highestPriorityLevel = notification.priority;
    }
  }

  public getCurrentPriorityLevelSeveritiy(): number {
    return this.getPriorityLevelSeveritiy(this.highestPriorityLevel);
  }

  private getPriorityLevelSeveritiy(prioritylevel: PriorityLevel): number {
    switch (prioritylevel) {
      case PriorityLevel.CRITICAL:
        return 3;
      case PriorityLevel.ALARM:
        return 2;
      case PriorityLevel.WARNING:
        return 1;
      case PriorityLevel.INFORMATION:
        return 0;
    }
  }
}
