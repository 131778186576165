import {InjectionToken} from '@angular/core';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {ConflictsRepository} from '../conflicts-repository';

export const COLORED_YARN_SETS = new InjectionToken<ColoredYarnSets>('ColoredYarnSets');

export interface ColoredYarnSets extends Repository<ColoredYarnSet, OverviewListColoredYarnSet>, UniqueIdentifierObjectRepository, ConflictsRepository {}
