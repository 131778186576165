import {Component, Inject, OnInit} from '@angular/core';
import {debounceTime, map, switchMap, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {InterfaceOf} from '../../../common/types/interface-of';
import {ShiftInstance} from '../../shift-schedule/shift-instance';
import {ShiftScheduleComponent} from '../../shift-schedule/shift-schedule.component';
import type {PlanningGridComponent} from '../planning-grid/planning-grid.component';
import {PLANNING_GRID_TOKEN} from '../planning-grid/planning-grid.token';

@Component({
  selector: 'vdw-regimes-indicator',
  templateUrl: './regimes-indicator.component.html',
  styleUrls: ['./regimes-indicator.component.scss']
})
export class RegimesIndicatorComponent extends BaseComponent implements OnInit {
  public shifts: ShiftInstance[];

  public constructor(@Inject(PLANNING_GRID_TOKEN) private readonly grid: InterfaceOf<PlanningGridComponent>) {
    super();
  }

  public ngOnInit(): void {
    this.grid.shiftSchedules
      .pipe(
        switchMap((shiftSchedules: ShiftScheduleComponent[]) => this.grid.onChanges.pipe(map(() => shiftSchedules))),
        debounceTime(20),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((shiftSchedules: ShiftScheduleComponent[]) => this.getShiftsForSchedule(shiftSchedules[0]));
  }

  private getShiftsForSchedule(schedule: ShiftScheduleComponent): void {
    this.shifts = schedule?.getShiftsForPeriod(this.grid.startDate, this.grid.endDate) ?? [];
    let x = 0;
    for (const shift of this.shifts) {
      shift.alternate = shift.name && ++x % 2 === 0;
    }
  }
}
