import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HeaderAction} from '../header/header-action';
import {HeaderButtons} from '../header/header-button';
import {HeaderTabIndex} from '../header/header-tab-index';
import {TabsMenuItem} from '../tabs-menu/tabs-menu-item';

@Component({
  selector: 'vdw-tabs-menu-card',
  templateUrl: './tabs-menu-card.component.html',
  styleUrls: ['./tabs-menu-card.component.scss']
})
export class TabsMenuCardComponent implements OnInit, OnChanges {
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public action: string;
  @Input() public extraInformationTitle: string;
  @Input() public amount: number;
  @Input() public locked: boolean;
  @Input() public isInUse: boolean;
  @Input() public menuItems: TabsMenuItem[];
  @Input() public selectedMenuItem: TabsMenuItem;
  @Input() public invalidFormMessageError: string;
  @Input() public isLoading: boolean;
  @Input() public hasPermissionToEdit = true;
  @Input() public canShowCustomSettings = true;
  @Input() public canShowCancel = true;
  @Input() public canShowDelete = true;
  @Input() public canShowDuplicate = true;
  @Input() public canShowSave = true;
  @Input() public canShowSaveAndCreateNew = true;
  @Input() public canShowBackButton = true;
  @Input() public headerTabIndex: HeaderTabIndex = {tabIndexSave: 0, tabIndexCancel: 0, tabIndexCustomSettings: 0};
  @Input() public headerActions: HeaderAction[];
  @Input() public isCreateNewPage: boolean;
  @Input() public showCustomErrorMessage = false;
  @Input() public customErrorMessage: string;
  @Input() public saving: string;
  @Input() public identifier: string;
  @Input() public settingsTooltip: string;
  @Input() public actionText = 'ANGULAR_COMPONENT_LIBRARY.HEADER.SAVE';
  @Output() public selectedMenuItemChange: EventEmitter<TabsMenuItem> = new EventEmitter<TabsMenuItem>();
  @Output() public backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCloseEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCreateNewEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deleteEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public duplicateEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public settingsEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public inUseEvent: EventEmitter<void> = new EventEmitter<void>();
  public headerButtons: HeaderButtons;

  public ngOnInit(): void {
    this.headerButtons = new HeaderButtons();
    this.headerButtons.back = this.canShowBackButton && this.backEvent.observed;
    this.headerButtons.cancel = this.canShowCancel && this.cancelEvent.observed;
    this.headerButtons.delete = this.canShowDelete && this.deleteEvent.observed;
    this.headerButtons.duplicate = this.canShowDuplicate && this.duplicateEvent.observed;
    this.headerButtons.save = this.canShowSave && this.saveEvent.observed;
    this.headerButtons.saveAndClose = this.canShowSave && this.saveAndCloseEvent.observed;
    this.headerButtons.saveAndCreateNew = this.canShowSaveAndCreateNew && this.saveAndCreateNewEvent.observed;
    this.headerButtons.settings = this.canShowCustomSettings && this.settingsEvent.observed;
    this.headerButtons.inUse = this.inUseEvent.observed;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.canShowCancel && !changes.canShowCancel.isFirstChange()) {
      this.headerButtons.cancel = changes.canShowCancel.currentValue && this.cancelEvent.observed;
    }

    if (changes.canShowDelete && !changes.canShowDelete.isFirstChange()) {
      this.headerButtons.delete = changes.canShowDelete.currentValue && this.deleteEvent.observed;
    }

    if (changes.canShowSave && !changes.canShowSave.isFirstChange()) {
      this.headerButtons.save = changes.canShowSave.currentValue && this.saveEvent.observed;
    }
  }

  public selectMenuItem(menuItem: TabsMenuItem): void {
    this.selectedMenuItemChange.emit(menuItem);
  }

  public cancelButtonClicked(): void {
    this.cancelEvent.emit();
  }

  public deleteButtonClicked(): void {
    this.deleteEvent.emit();
  }

  public backButtonClicked(): void {
    this.backEvent.emit();
  }

  public duplicateButtonClicked(): void {
    this.duplicateEvent.emit();
  }

  public saveButtonClicked(): void {
    this.saveEvent.emit();
  }

  public saveAndCloseButtonClicked(): void {
    this.saveAndCloseEvent.emit();
  }

  public saveAndCreateNewButtonClicked(): void {
    this.saveAndCreateNewEvent.emit();
  }

  public settingsButtonClicked(): void {
    this.settingsEvent.emit();
  }

  public inUseButtonClicked(): void {
    this.inUseEvent.emit();
  }
}
