import {IdName} from '@domain/id-name';
import {CommercialMachineQuality} from '@domain/textile-data/machine-quality/commercial-machine-quality';
import {IncorporationFactorException} from '@domain/textile-data/machine-quality/incorporation-factor-exception';
import {TuftLengthException} from '@domain/textile-data/machine-quality/tuft-Length-exception';
import {DoubleWidthWeaveStructure} from '@domain/textile-data/weave-structure/double-width-weave-structure';
import {SingleWidthWeaveStructure} from '@domain/textile-data/weave-structure/single-width-weave-structure';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {MachineOverviewListHelper} from '@presentation/pages/machine-overview/machine-overview-list-helper';
import {WeaveStructureWidth} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-general/weave-structure-width.enum';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {isNil, map} from 'lodash-es';
import {Finishing} from '../finishing-and-finishing-template/finishing/finishing';
import {GeneralJuteSettings} from './general-jute-settings';
import {Jute} from './jute';
import {PathWidth} from './path-width';
import {PostProcessing} from './post-processing';
import {Selvedges} from './selvedges';
import {WeaveQuality} from './weave-quality';
import {WeftSelection} from './weft-selection';

export class VelvetWeaveQuality extends WeaveQuality {
  public postProcessing: PostProcessing;
  public selvedges: Selvedges;

  public constructor(
    id: number,
    technicalName: string,
    version: number,
    type: WeaveQualityType,
    weaveStructureWidth: WeaveStructureWidth,
    singleWidth: SingleWidthWeaveStructure,
    doubleWidth: DoubleWidthWeaveStructure,
    reedDensityInDentsPerMM: number,
    pickDensityInPicksPerMM: number,
    weftDensityInDentsPerMM: number,
    technicalCode: string,
    practicalPickDensityInPicksPerMM: number,
    commercialMachineQualities: CommercialMachineQuality[],
    pathWidths: PathWidth[],
    applicableMachines: MachineOverviewList[],
    machinesWithSpecificPickDensity: {machine: MachineOverviewList; specificPickDensity: number}[],
    pileHeightInMM: number,
    buggyLengthMinInMM: number,
    buggyLengthMaxInMM: number,
    tuftLengthInMM: number,
    incorporationFactor: number,
    tolerance: number,
    picksPerHourMin: number,
    tuftLengthPatternTypeExceptions: TuftLengthException[],
    tuftLengthYarnTypeExceptions: TuftLengthException[],
    incorporationFactorPatternTypeExceptions: IncorporationFactorException[],
    incorporationFactorYarnTypeExceptions: IncorporationFactorException[],
    used: boolean,
    numberOfColumnsToDropForWeftSelection: number,
    jute: Jute[],
    finishings: Finishing[],
    postProcessing: PostProcessing,
    selvedges: Selvedges,
    weftSelections: WeftSelection[],
    juteGeneralSettings: GeneralJuteSettings,
    yarnSet: IdName,
    missingDesignMarginInDents: number,
    canAddJute?: boolean
  ) {
    super(
      id,
      technicalName,
      version,
      type,
      weaveStructureWidth,
      singleWidth,
      doubleWidth,
      reedDensityInDentsPerMM,
      pickDensityInPicksPerMM,
      weftDensityInDentsPerMM,
      technicalCode,
      practicalPickDensityInPicksPerMM,
      commercialMachineQualities,
      pathWidths,
      applicableMachines,
      machinesWithSpecificPickDensity,
      pileHeightInMM,
      buggyLengthMinInMM,
      buggyLengthMaxInMM,
      tuftLengthInMM,
      incorporationFactor,
      tolerance,
      picksPerHourMin,
      tuftLengthPatternTypeExceptions,
      tuftLengthYarnTypeExceptions,
      incorporationFactorPatternTypeExceptions,
      incorporationFactorYarnTypeExceptions,
      used,
      numberOfColumnsToDropForWeftSelection,
      jute,
      weftSelections,
      juteGeneralSettings,
      yarnSet,
      missingDesignMarginInDents,
      finishings,
      canAddJute
    );
    this.postProcessing = postProcessing;
    this.selvedges = selvedges;
  }

  public static createEmptyVelvetWeaveQuality(): VelvetWeaveQuality {
    return new VelvetWeaveQuality(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      null,
      null,
      [],
      null,
      null,
      0,
      null
    );
  }

  public static fromJSON(velvetWeaveQualityJSON: any): VelvetWeaveQuality {
    let result: VelvetWeaveQuality = null;

    if (!isNil(velvetWeaveQualityJSON)) {
      result = new VelvetWeaveQuality(
        velvetWeaveQualityJSON.id,
        velvetWeaveQualityJSON.technicalName,
        velvetWeaveQualityJSON.version,
        WeaveQualityType.VELVET,
        velvetWeaveQualityJSON.weaveStructureWidthType ? velvetWeaveQualityJSON.weaveStructureWidthType : null,
        WeaveQuality.getBackwardsSingleWidthWeaveStructure(velvetWeaveQualityJSON),
        DoubleWidthWeaveStructure.fromJSON(velvetWeaveQualityJSON),
        velvetWeaveQualityJSON.reedDensityInDentsPerMM,
        velvetWeaveQualityJSON.theoreticalPickDensityInPicksPerMM,
        velvetWeaveQualityJSON.weftDensityInDentsPerMM,
        velvetWeaveQualityJSON.technicalCode,
        isNil(velvetWeaveQualityJSON.specificPickDensity) ? velvetWeaveQualityJSON.practicalPickDensityInPicksPerMM : velvetWeaveQualityJSON.specificPickDensity,
        map(velvetWeaveQualityJSON.commercialMachineQualities, (commercialMachineQualityJSON: any) => {
          return CommercialMachineQuality.fromJSON(commercialMachineQualityJSON);
        }),
        velvetWeaveQualityJSON.pathWidths?.map((pathWidthJSON: any) => {
          return PathWidth.fromJSON(pathWidthJSON);
        }) ?? [],
        map(velvetWeaveQualityJSON.applicableMachines, (applicableMachineJSON: any) => {
          return MachineOverviewListHelper.fromJSON(applicableMachineJSON);
        }),
        map(velvetWeaveQualityJSON.machinesWithSpecificPickDensity, (machineWithSpecificPickDensityJSON: any) => {
          return {
            machine: MachineOverviewListHelper.fromJSON(machineWithSpecificPickDensityJSON.machine),
            specificPickDensity: machineWithSpecificPickDensityJSON.specificPickDensity
          };
        }),
        velvetWeaveQualityJSON.pileHeightInMM,
        velvetWeaveQualityJSON.buggyLengthMinInMM,
        velvetWeaveQualityJSON.buggyLengthMaxInMM,
        velvetWeaveQualityJSON.tuftLengthInMM,
        velvetWeaveQualityJSON.incorporationFactor,
        velvetWeaveQualityJSON.tolerance,
        velvetWeaveQualityJSON.picksPerHourMin,
        WeaveQuality.getTuftLengthExceptions(velvetWeaveQualityJSON.tuftLengthPatternTypeExceptions),
        WeaveQuality.getTuftLengthExceptions(velvetWeaveQualityJSON.tuftLengthYarnTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(velvetWeaveQualityJSON.incorporationFactorPatternTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(velvetWeaveQualityJSON.incorporationFactorYarnTypeExceptions),
        velvetWeaveQualityJSON.used,
        velvetWeaveQualityJSON.nrColumnsToDropForWeftSelection,
        velvetWeaveQualityJSON.jute?.map((jute: any) => {
          return Jute.fromJSON(jute);
        }) ?? [],
        velvetWeaveQualityJSON.finishings?.map((finishing: any) => Finishing.fromJSON(finishing)),
        PostProcessing.fromJSON(velvetWeaveQualityJSON.postProcessing),
        Selvedges.fromJSON(velvetWeaveQualityJSON.selvedges),
        velvetWeaveQualityJSON.weftSelections?.map((weftSelectionJSON: any) => WeftSelection.fromJSON(weftSelectionJSON)) ?? [],
        velvetWeaveQualityJSON.juteGeneralSettings ? GeneralJuteSettings.fromJSON(velvetWeaveQualityJSON.juteGeneralSettings) : null,
        velvetWeaveQualityJSON.yarnSet ? IdName.fromJSON(velvetWeaveQualityJSON.yarnSet) : null,
        velvetWeaveQualityJSON.missingDesignMarginInDents,
        velvetWeaveQualityJSON.canAddJute ?? false
      );
    }

    return result;
  }

  public toJSON(): JSON {
    const velvetWeaveQuality: any = super.toJSON();
    velvetWeaveQuality.postProcessing = this.postProcessing.toJSON();
    velvetWeaveQuality.selvedges = this.selvedges === null ? null : this.selvedges.toJSON();

    return velvetWeaveQuality as JSON;
  }
}
