import {Component, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatFormFieldControlHelper} from '../../../custom-components/helper/form-field-control-helper';

@Component({
  selector: 'vdw-bit-pattern-editor-form-field',
  templateUrl: './bit-pattern-editor-form-field.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: BitPatternEditorFormFieldComponent}]
})
export class BitPatternEditorFormFieldComponent extends MatFormFieldControlHelper<number> {
  @Input() public disabled: boolean;

  public static nextId = 0;

  public id = `vdw-bit-pattern-editor-form-field-${BitPatternEditorFormFieldComponent.nextId++}`;

  public constructor(@Optional() @Self() public readonly ngControl: NgControl) {
    super(ngControl);
  }

  public onBitPatternChanged(value: number): void {
    this.onChange(value);
  }
}
