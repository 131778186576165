import {Directive, ElementRef, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import moment, {Duration, duration} from 'moment';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {PlanningItemData} from '../../planning-items/planning-item-base/planning-item-data';

@Directive({
  selector: '[vdwTimelinePosition]'
})
export class TimelinePositionDirective extends BaseComponent implements OnInit {
  @Input()
  public set parentStartDate(value: Date) {
    if (AssertionUtils.datesAreEqual(this._parentStartDate, value)) {
      return;
    }
    this._parentStartDate = value;
    this.calculatePosition();
  }

  public get parentStartDate(): Date {
    return this._parentStartDate;
  }

  @Input()
  public set parentEndDate(value: Date) {
    if (AssertionUtils.datesAreEqual(this._parentEndDate, value)) {
      return;
    }
    this._parentEndDate = value;
    this.calculatePosition();
  }

  public get parentEndDate(): Date {
    return this._parentEndDate;
  }

  public get endDate(): Date {
    return this._data?.endDate;
  }

  @Input()
  public set data(value: PlanningItemData) {
    if (this._data === value) {
      return;
    }
    this._data = value;
    this._data.displayElement ??= this.element;
    this.calculatePosition();
  }

  public get data(): PlanningItemData {
    return this._data;
  }

  @HostBinding('style.width.%')
  public widthPercent = 0;

  @HostBinding('style.max-width.%')
  public maxWidthPercent = 0;

  @HostBinding('style.left.%')
  public leftPercent = 0;

  @HostBinding('class.hidden')
  public isHidden = true;

  @HostBinding('class.clipped-right')
  public isClippedRight = false;

  @HostBinding('class.clipped-left')
  public isClippedLeft = false;

  private _parentStartDate: Date;
  private _parentEndDate: Date;
  private _parentDuration: Duration;
  private _data: PlanningItemData;

  public constructor(private readonly element: ElementRef<HTMLElement>) {
    super();
  }

  public ngOnInit(): void {
    this.calculatePosition();
    this._data?.periodChanged.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => this.calculatePosition());
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this._data?.clickable) {
      event.stopPropagation();
      this._data.selected.emit(event);
    }
  }

  private calculatePosition(): void {
    if (this._parentStartDate == null || this._parentEndDate == null || this._data == null) {
      this.isHidden = true;
      return;
    }
    this._parentDuration = duration(moment(this.parentEndDate).diff(this.parentStartDate));
    this.leftPercent = Math.max(this.getLeftPercentForDate(this.data.startDate), 0);
    this.maxWidthPercent = 100 - this.leftPercent;
    this.widthPercent = this.getLeftPercentForDate(this.endDate) - this.leftPercent;
    this.isClippedLeft = this.data.startDate <= this.parentStartDate;
    this.isClippedRight = this.data.endDate >= this.parentEndDate;
    this.isHidden = this.data.startDate > this.parentEndDate || this.endDate < this.parentStartDate;
  }

  private getLeftPercentForDate(date: Date): number {
    return (duration(moment(date).diff(this.parentStartDate)).asMinutes() / this._parentDuration.asMinutes()) * 100;
  }
}
