<app-textile-data-overview
  iconForCard="builder-blue"
  [columnDefs]="getColumnDefsForListOfPathLayoutTemplates()"
  [listOfTextileData]="listOfPathLayoutTemplates"
  [textileDataService]="pathLayoutTemplates"
  [textileDataType]="textileDataType.PATH_LAYOUT_TEMPLATE"
  [gridIdentifier]="GRID_IDENTIFIER"
  [canExportGrid]="false"
  [showSearchBar]="false"
  [multiSelection]="true"
  (customSettingsSetup)="navigateToCustomSettings()"
></app-textile-data-overview>
