export class CreelForProductionSchedule {
  private _id: number;
  private _name: string;
  private _isColoredYarnSplitPositionedCorrectly: boolean;

  public constructor(id: number, name: string, isColoredYarnSplitPositionedCorrectly: boolean) {
    this._id = id;
    this._name = name;
    this._isColoredYarnSplitPositionedCorrectly = isColoredYarnSplitPositionedCorrectly;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get isColoredYarnSplitPositionedCorrectly(): boolean {
    return this._isColoredYarnSplitPositionedCorrectly;
  }

  public set isColoredYarnSplitPositionedCorrectly(value: boolean) {
    this._isColoredYarnSplitPositionedCorrectly = value;
  }

  public static fromJSON(creelForProductionScheduleJSON: any): CreelForProductionSchedule {
    return new CreelForProductionSchedule(creelForProductionScheduleJSON.id, creelForProductionScheduleJSON.name, creelForProductionScheduleJSON.isColoredYarnSplitPositionedCorrectly);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      isColoredYarnSplitPositionedCorrectly: this._isColoredYarnSplitPositionedCorrectly
    } as any as JSON;
  }
}
