export class WeaveStructureDetails {
  public factorM2: number;
  public dentRepeat: number;
  public sendRepeat: number;
  public picksPerColorLine: number;

  public rapier: number;
  public extraInfoType: string;
  public creelPositions: number;
  public jacquardPositions: number;
  public hpLp: string;
  public ratio: number;
  public incFloatPoint: string;
  public bb: string;
  public bt: string;
  public tb: string;
  public tt: string;

  public constructor(
    factorM2: number,
    dentRepeat: number,
    sendRepeat: number,
    picksPerColorLine: number,
    rapier: number,
    extraInfoType: string,
    creelPositions: number,
    jacquardPositions: number,
    hpLp: string,
    ratio: number,
    incFloatPoint: string,
    bb: string,
    bt: string,
    tb: string,
    tt: string
  ) {
    this.factorM2 = factorM2;
    this.dentRepeat = dentRepeat;
    this.sendRepeat = sendRepeat;
    this.picksPerColorLine = picksPerColorLine;
    this.rapier = rapier;
    this.extraInfoType = extraInfoType;
    this.creelPositions = creelPositions;
    this.jacquardPositions = jacquardPositions;
    this.hpLp = hpLp;
    this.ratio = ratio;
    this.incFloatPoint = incFloatPoint;
    this.bb = bb;
    this.bt = bt;
    this.tb = tb;
    this.tt = tt;
  }

  public static createEmptyWeaveStructureDetails(): WeaveStructureDetails {
    return new WeaveStructureDetails(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  public static fromJSON(weaveStructureDetailsJSON: any): WeaveStructureDetails {
    return new WeaveStructureDetails(
      weaveStructureDetailsJSON.factorM2,
      weaveStructureDetailsJSON.dentRepeat,
      weaveStructureDetailsJSON.sendRepeat,
      weaveStructureDetailsJSON.picksPerColorLine,
      weaveStructureDetailsJSON.rapier,
      weaveStructureDetailsJSON.extraInfoType,
      weaveStructureDetailsJSON.creelPositions,
      weaveStructureDetailsJSON.jacquardPositions,
      weaveStructureDetailsJSON.hpLp,
      weaveStructureDetailsJSON.ratio,
      weaveStructureDetailsJSON.incFloatPoint,
      weaveStructureDetailsJSON.bb,
      weaveStructureDetailsJSON.bt,
      weaveStructureDetailsJSON.tb,
      weaveStructureDetailsJSON.tt
    );
  }

  public toJSON(): JSON {
    return {
      factorM2: this.factorM2,
      dentRepeat: this.dentRepeat,
      sendRepeat: this.sendRepeat,
      picksPerColorLine: this.picksPerColorLine,
      rapier: this.rapier,
      extraInfoType: this.extraInfoType,
      creelPositions: this.creelPositions,
      jacquardPositions: this.jacquardPositions,
      hpLp: this.hpLp,
      ratio: this.ratio,
      incFloatPoint: this.incFloatPoint,
      bb: this.bb,
      bt: this.bt,
      tb: this.tb,
      tt: this.tt
    } as any as JSON;
  }
}
