import {Component} from '@angular/core';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {BaseComponent, convertCommercialUnit, Unit} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {round} from 'lodash-es';
import {PathLengthCellRendererParams} from './path-length-cell-renderer-params';

@Component({
  templateUrl: './path-length.component.html'
})
export class PathLengthComponent extends BaseComponent implements ICellRendererAngularComp {
  public pathLengthInCommercialUnit = 0;
  public pathLengthInTechnicalUnit = 0;
  public commercialUnit: Unit;
  private productionSchedulePath: ProductionSchedulePath;

  public agInit(params: PathLengthCellRendererParams): void {
    this.productionSchedulePath = params.data;
    this.commercialUnit = params.commercialUnit;

    this.calculatePathLengthInCommercialUnit();
  }

  public refresh(params: any): boolean {
    return false;
  }

  private calculatePathLengthInCommercialUnit(): void {
    const pathLengthInMillimeters: number = this.productionSchedulePath.totalLengthInMM;

    this.pathLengthInCommercialUnit = round(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLengthInMillimeters}, to: this.commercialUnit}), 2);

    this.pathLengthInTechnicalUnit = this.productionSchedulePath.totalLengthInPicks;
  }
}
