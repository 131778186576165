import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[vdwSliderTrackBackground]'
})
export class SliderTrackBackgroundDirective implements AfterViewInit, OnChanges {
  @Input() public sliderTrackBackground: string;

  private readonly QUERY_NAME_FOR_SLIDER_TRACK = 'div.mdc-slider__track--inactive';
  private elementReference: ElementRef;
  private sliderTrackBackgroundElement: HTMLDivElement;

  public constructor(elementRef: ElementRef) {
    this.elementReference = elementRef;
  }

  public ngAfterViewInit(): void {
    this.sliderTrackBackgroundElement = this.elementReference.nativeElement.querySelector(this.QUERY_NAME_FOR_SLIDER_TRACK);
    this.updateSliderTrackBackground();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateSliderTrackBackground();
  }

  private updateSliderTrackBackground(): void {
    if (this.sliderTrackBackgroundElement) {
      this.sliderTrackBackgroundElement.style.background = this.sliderTrackBackground;
    }
  }
}
