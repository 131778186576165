import {OverviewListSingleWidthWeaveStructure} from './overview-list-single-width-weave-structure';
import {WeaveStructureForMachineQuality} from './weave-structure-for-machine-quality';

export class SingleWidthWeaveStructure {
  private _weaveStructure: WeaveStructureForMachineQuality;

  public constructor(weaveStructure: WeaveStructureForMachineQuality) {
    this._weaveStructure = weaveStructure;
  }

  public get weaveStructure(): WeaveStructureForMachineQuality {
    return this._weaveStructure;
  }

  public set weaveStructure(value: WeaveStructureForMachineQuality) {
    this._weaveStructure = value;
  }

  public static fromJSON(singleWidthWeaveStructureJSON: any): SingleWidthWeaveStructure {
    return new SingleWidthWeaveStructure(WeaveStructureForMachineQuality.fromJSON(singleWidthWeaveStructureJSON));
  }

  public static fromOverviewListSingleWidthWeaveStructure(overviewListSingleWidthWeaveStructure: OverviewListSingleWidthWeaveStructure): SingleWidthWeaveStructure {
    return new SingleWidthWeaveStructure(WeaveStructureForMachineQuality.fromJSON(overviewListSingleWidthWeaveStructure.weaveStructure));
  }
}
