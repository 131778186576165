import {Pattern} from '@domain/pattern';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {LabelType} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-selvedges/label-type.enum';
import {has, map} from 'lodash-es';
import {ImageForFinishingImageLabel} from '../finishing-and-finishing-template/image-for-finishing-image-label';
import {PlaceholderComponent} from '../finishing-and-finishing-template/placeholder-component';

export class Label {
  private _type: LabelType;
  private _offsetLeftInDents: number;
  private _offsetBottomInPicks: number;
  private _rotate: boolean;
  private _image: ImageForFinishingImageLabel;
  private _text: string;
  private _height: number;
  private _fontFamily: string;
  private _fontWeight: string;
  private _fontStyle: string;
  private _textPattern: Pattern;
  private _imagePattern: Pattern;
  private _placeholderParts: PlaceholderComponent[];

  public constructor(
    type: LabelType,
    offsetLeftInDents: number,
    offsetBottomInPicks: number,
    rotate: boolean,
    image: ImageForFinishingImageLabel = null,
    text: string = null,
    height: number = null,
    fontFamily: string = null,
    fontWeight: string = null,
    fontStyle: string = null,
    pattern: Pattern = null,
    placeholderParts: PlaceholderComponent[] = null
  ) {
    this._type = type;
    this._offsetLeftInDents = offsetLeftInDents;
    this._offsetBottomInPicks = offsetBottomInPicks;
    this._rotate = rotate;
    this._image = image;
    this._text = type === LabelType.IMAGE ? null : text;
    this._height = height;
    this._fontFamily = type === LabelType.IMAGE ? null : fontFamily;
    this._fontWeight = type === LabelType.IMAGE ? null : fontWeight;
    this._fontStyle = type === LabelType.IMAGE ? null : fontStyle;
    this._textPattern = type === LabelType.TEXT || type === LabelType.PLACEHOLDER ? pattern : null;
    this._imagePattern = type === LabelType.IMAGE ? pattern : null;
    this._placeholderParts = type === LabelType.PLACEHOLDER ? placeholderParts : null;
  }

  public static createEmptyLabel(): Label {
    return new Label(LabelType.IMAGE, null, null, false);
  }

  public get type(): LabelType {
    return this._type;
  }

  public get offsetLeftInDents(): number {
    return this._offsetLeftInDents;
  }

  public get offsetBottomInPicks(): number {
    return this._offsetBottomInPicks;
  }

  public get rotate(): boolean {
    return this._rotate;
  }

  public get image(): ImageForFinishingImageLabel {
    return this._image;
  }

  public get text(): string {
    return this._text;
  }

  public get height(): number {
    return this._height;
  }

  public get fontFamily(): string {
    return this._fontFamily;
  }

  public get fontWeight(): string {
    return this._fontWeight;
  }

  public get fontStyle(): string {
    return this._fontStyle;
  }

  public get textPattern(): Pattern {
    return this._textPattern;
  }

  public get imagePattern(): Pattern {
    return this._imagePattern;
  }

  public get placeholderParts(): PlaceholderComponent[] {
    return this._placeholderParts;
  }

  public static fromJSON(labelJSON: any): Label {
    let pattern = labelJSON.textPattern === null ? null : Pattern.fromJSON(labelJSON.textPattern);
    if (labelJSON.imagePattern !== null) {
      pattern = Pattern.fromJSON(labelJSON.imagePattern);
    }
    return new Label(
      labelJSON.type,
      labelJSON.offsetWidth,
      labelJSON.offsetHeight,
      labelJSON.rotate,
      labelJSON.image === null ? null : ImageForFinishingImageLabel.fromJSON(labelJSON.image),
      labelJSON.text,
      labelJSON.height,
      labelJSON.fontFamily,
      labelJSON.fontWeight,
      labelJSON.fontStyle,
      pattern,
      labelJSON.placeholderParts === null
        ? null
        : map(labelJSON.placeholderParts, (placeholderPartJSON: any) => {
            return new PlaceholderComponent(has(placeholderPartJSON, 'id') ? placeholderPartJSON.id : null, placeholderPartJSON.name, placeholderPartJSON.maxLength);
          })
    );
  }

  public toJSON(): JSON {
    return {
      type: this._type,
      offsetWidth: this._offsetLeftInDents,
      offsetHeight: this._offsetBottomInPicks,
      rotate: this._rotate,
      imageId: this._image === null ? null : this._image.id,
      text: this._text,
      height: this._height,
      fontFamily: this._fontFamily,
      fontWeight: this._fontWeight,
      fontStyle: this._fontStyle,
      textWeaveStructurePatternIndex: this._textPattern?.visualisations[0]?.isWeavePatternVisualisation() ? this._textPattern.id : null,
      imageWeaveStructurePatternIndex: this._imagePattern?.visualisations[0]?.isWeavePatternVisualisation() ? this._imagePattern.id : null,
      textPatternImageId: this._textPattern?.visualisations[0]?.type === PatternVisualisationType.DRAWING ? this._textPattern.id : null,
      imagePatternImageId: this._imagePattern?.visualisations[0]?.type === PatternVisualisationType.DRAWING ? this._imagePattern.id : null,
      placeholderParts:
        this._placeholderParts === null
          ? null
          : map(this._placeholderParts, (placeholderPart: PlaceholderComponent) => {
              return placeholderPart.toJSON();
            })
    } as any as JSON;
  }
}
