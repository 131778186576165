import {PatternVisualisation} from './pattern-visualisation';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';

export class ImagePatternVisualisation extends PatternVisualisation {
  private _image: string;

  public constructor(image: string) {
    super(PatternVisualisationType.IMAGE);

    this._image = image;
  }

  public get image(): string {
    return this._image;
  }

  public static fromJSON(patternVisualisationJSON: any): ImagePatternVisualisation {
    return new ImagePatternVisualisation(patternVisualisationJSON.pattern);
  }

  public toJSON(): JSON {
    return {
      pattern: this._image,
      patternType: PatternVisualisationType[this.type]
    } as any as JSON;
  }
}
