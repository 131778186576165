import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SwUpdate} from '@angular/service-worker';
import {LocalStorageService, WINDOW} from '@vdw/angular-component-library';
import {forEach, isEmpty, isEqual, isNil} from 'lodash-es';
import {ChangeLog} from './changelog';

@Component({
  templateUrl: './new-update-available.component.html',
  styleUrls: ['./new-update-available.component.scss']
})
export class NewUpdateAvailableComponent {
  public readonly changelogs: ChangeLog[] = [];

  private readonly swUpdate: SwUpdate;
  private readonly localStorageService: LocalStorageService;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeLog[],
    swUpdate: SwUpdate,
    localStorageService: LocalStorageService,
    @Inject(WINDOW) private readonly window: Window
  ) {
    this.swUpdate = swUpdate;
    this.localStorageService = localStorageService;
    this.changelogs = data;
    this.window = window;
  }

  public getChangesOfType(version: string, type: string): string[] {
    let changesOfType: string[] = [];
    forEach(this.changelogs, (changelog: ChangeLog) => {
      if (isEqual(version, changelog.version)) {
        changesOfType = isNil(changelog) || isNil(changelog.changes) ? [] : changelog.changes[type];
      }
    });
    return changesOfType;
  }

  public updateApp(): void {
    this.localStorageService.set('new-update-activation-timestamp', `${new Date().getTime()}`);

    this.swUpdate.activateUpdate().then(() => this.window.location.reload());
  }

  public getChanges(version: string): string[] {
    const types = ['added', 'changed', 'deprecated', 'removed', 'fixed', 'security'];
    const changesTypes: string[] = [];
    forEach(this.changelogs, (changelog: ChangeLog) => {
      forEach(types, (type: string) => {
        if (isEqual(version, changelog.version) && !isNil(changelog) && !isNil(changelog.changes) && !isEmpty(changelog.changes[type])) {
          changesTypes.push(type);
        }
      });
    });
    return changesTypes;
  }
}
