import {CommercialDimensions} from '@domain/commercial-dimensions';
import {DrawingConfiguration} from '@domain/production-schedule/drawing-configuration';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {ObjectUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {CommercialDimensionsCM} from './commercial-dimensions-cm';
import {DrawingConfigurationWeaveQuality} from './drawing-configuration-weave-quality';

export class DrawingConfigurationWithColoredYarnSet extends DrawingConfiguration {
  private _coloredYarnSet: OverviewListColoredYarnSet;

  public constructor(
    image: DrawingImage,
    mainColorsCreelPosition: OverviewListCreelPosition,
    borderColorsCreelPosition: OverviewListCreelPosition,
    quality: DrawingConfigurationWeaveQuality,
    commercialDimensionsInMm: CommercialDimensions,
    coloredYarnSet: OverviewListColoredYarnSet,
    commercialDimensionsInCm?: CommercialDimensionsCM,
    id?: number
  ) {
    super(image, mainColorsCreelPosition, borderColorsCreelPosition, quality, commercialDimensionsInMm, commercialDimensionsInCm, id);

    this._coloredYarnSet = coloredYarnSet;
  }

  public get coloredYarnSet(): OverviewListColoredYarnSet {
    return this._coloredYarnSet;
  }

  public static fromJSON(drawingConfigurationJSON: any): DrawingConfigurationWithColoredYarnSet {
    const coloredYarnSet = OverviewListColoredYarnSet.fromJSON(drawingConfigurationJSON.coloredYarnSet);
    const quality = DrawingConfigurationWeaveQuality.fromJSON(drawingConfigurationJSON.machineQuality);

    const commercialDimensionsInMm = isNil(drawingConfigurationJSON.commercialDimensions)
      ? null
      : {
          widthInMM: drawingConfigurationJSON.commercialDimensions.widthInMM,
          heightInMM: drawingConfigurationJSON.commercialDimensions.heightInMM
        };

    const commercialDimensionsInCm = ObjectUtils.tryGetValue(drawingConfigurationJSON.commercialDimensionsCM, null) as CommercialDimensionsCM;

    const mainColorsCreelPosition = coloredYarnSet.overviewListColorSet.creelPositions.find(
      (creelPosition: OverviewListCreelPosition) => creelPosition.position === drawingConfigurationJSON.mainColorPosition
    );

    const borderColorsCreelPosition = coloredYarnSet.overviewListColorSet.creelPositions.find(
      (creelPosition: OverviewListCreelPosition) => creelPosition.position === drawingConfigurationJSON.borderColorPosition
    );

    const image: DrawingImage = {
      id: null,
      data: drawingConfigurationJSON.image ?? null
    };

    return new DrawingConfigurationWithColoredYarnSet(
      image,
      mainColorsCreelPosition,
      borderColorsCreelPosition,
      quality,
      commercialDimensionsInMm,
      coloredYarnSet,
      commercialDimensionsInCm,
      drawingConfigurationJSON.id
    );
  }

  public toJSON(): JSON {
    return {
      qualityId: this.quality.id,
      mainColorPosition: this.mainColorsCreelPosition.position,
      borderColorPosition: this.borderColorsCreelPosition.position,
      commercialDimensions: {
        widthInMM: this.commercialDimensionsInMm.widthInMM,
        heightInMM: this.commercialDimensionsInMm.heightInMM
      },
      colorSet: this.coloredYarnSet?.overviewListColorSet?.toJSON(),
      coloredYarnSet: this.coloredYarnSet?.toJSON()
    } as any as JSON;
  }
}
