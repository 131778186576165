import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {PriorityLevel} from '@domain/event/priority-level.enum';
import {Notification} from '@domain/notifications/notification';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {NotificationsComponent} from '../notifications.component';
import {NotificationsService} from '../notifications.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends BaseComponent {
  @Input() public notificationList: Notification[];
  @Input() public notificationClickable = true;
  @Output() public listScrolled: EventEmitter<void> = new EventEmitter<void>();

  private previousScrollEvent: IPageInfo;

  public constructor(
    private readonly notificationsService: NotificationsService,
    private readonly router: Router,
    private readonly navigationHistory: NavigationHistoryService,
    @Optional() private readonly dialogRef: MatDialogRef<NotificationsComponent>
  ) {
    super();
  }

  public getPriorityIconName(priority: PriorityLevel): string {
    return `${priority.toLowerCase()}-circle`;
  }

  public markAsRead(notificationId: number, event: PointerEvent): void {
    event?.stopPropagation();
    this.notificationsService.markAsRead(this.notificationList, notificationId).pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe();
  }

  public loadMore(event: IPageInfo): void {
    if (AssertionUtils.isEmpty(this.notificationList) || event.endIndex !== this.notificationList.length - 1 || event === this.previousScrollEvent) {
      return;
    }

    this.listScrolled.emit();
    this.previousScrollEvent = event;
  }

  public async openMachineDetailSidebar(notification: Notification): Promise<void> {
    await this.router.navigate([RouteUtils.paths.texState.absolutePath], {state: {equipmentId: notification.equipment.id}});
    this.navigationHistory.replaceState(RouteUtils.paths.texState.absolutePath, undefined, {equipmentId: notification.equipment.id});

    if (!AssertionUtils.isNullOrUndefined(this.dialogRef?.componentInstance)) {
      this.dialogRef.close();
    }
  }
}
