import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-bit-pattern-editor',
  styleUrls: ['./bit-pattern-editor.component.scss'],
  templateUrl: './bit-pattern-editor.component.html'
})
export class BitPatternEditorComponent {
  @Input() public bitPattern = 0;
  @Input() public enabledBits = 1111111111111111;
  @Input() public showHexValue = true;
  @Input() public disabled = false;
  @Output() public bitPatternChange = new EventEmitter<number>();

  public readonly checkboxList = ['I16', 'I15', 'I14', 'I13', 'I12', 'I11', 'I10', 'I9', 'I8', 'I7', 'I6', 'I5', 'I4', 'I3', 'I2', 'I1'];

  public isDisabled(index: number): boolean {
    return !+this.getStringBits(this.enabledBits)[index] || this.disabled;
  }

  public isChecked(index: number): boolean {
    const checked = !!+this.getStringBits(this.bitPattern)[index];
    if (checked && this.isDisabled(index) && !this.disabled) {
      this.checkedChanged(index, false);
      return false;
    }
    return checked;
  }

  public checkedChanged(index: number, checked: boolean): void {
    const stringBitPattern = this.getStringBits(this.bitPattern);
    this.bitPattern = +(stringBitPattern.substring(0, index) + +checked + stringBitPattern.substring(index + 1));
    this.bitPatternChange.emit(this.bitPattern);
  }

  private getStringBits(bits: number): string {
    return AssertionUtils.isNullOrUndefined(bits) ? '0000000000000000' : bits.toString().padStart(16, '0');
  }
}
