import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import moment from 'moment';
import {PlanningGroupData} from '../../planning-items/planning-item-base/planning-group-data';
import {DragDropData} from '../planning-drag-drop-data';

@Component({
  templateUrl: './planning-hour-indicator.component.html',
  styleUrls: ['./planning-hour-indicator.component.scss']
})
export class PlanningHourIndicatorComponent implements AfterViewInit {
  @ViewChild('container')
  public container: ElementRef<HTMLDivElement>;

  @ViewChild('indicator')
  public indicator: ElementRef<HTMLDivElement>;

  @ViewChild('dateText')
  public dateText: ElementRef<HTMLParagraphElement>;

  @ViewChild('dropIndicator')
  public dropIndicator: ElementRef<HTMLDivElement>;

  public date: Date;
  public showDropIndicator = false;
  public position: {
    top: number;
    left: number;
    height: number;
  };

  private isNestedItem = false;

  public ngAfterViewInit(): void {
    if (!this.position) {
      return;
    }
    this.updatePosition();
  }

  public show(): void {
    if (!this.container) {
      return;
    }
    this.container.nativeElement.style.opacity = '1';
  }

  public hide(): void {
    if (!this.container) {
      return;
    }
    this.container.nativeElement.style.opacity = '0';
  }

  public init(data: DragDropData): void {
    this.isNestedItem = data.sourceGroup && data.draggedItem !== data.sourceGroup;
    this.calculateNewPosition(data);
  }

  public calculateNewPosition(data: DragDropData): void {
    this.show();
    data.targetTime = null;
    const topOffset = data.targetGrid.COLUMN_HEADER_HEIGHT / 2;
    this.position = {
      left: data.mousePositionInContainer.x - data.mouseOffsetFromDragIndicator.x,
      top: topOffset,
      height: data.dragIndicatorPositionOnScreen.y - data.targetGrid.bodyContainerOffset.y - topOffset
    };
    this.showDropIndicator = false;
    const grid = data.targetGrid;

    if (data.mousePositionInContainer.x < grid.ROW_INDICATOR_WIDTH) {
      data.dropAllowed = false;
    }

    this.collideWithDropTarget(data);

    this.collideWithCurrentTime(data);

    data.targetTime ??= grid.getDateForHorizontalPosition(this.position.left - grid.ROW_INDICATOR_WIDTH);
    this.date = data.targetTime;
    this.updatePosition();
  }

  private collideWithDropTarget(data: DragDropData): void {
    if (!data.dropAllowed || !this.isNestedItem) {
      return;
    }

    let dropTarget = data.targetChildItem;
    if (data.targetItem instanceof PlanningGroupData && dropTarget == null) {
      if (data.targetItem.items.length === 0) {
        this.position.left = data.targetItem.displayElement.nativeElement.offsetLeft + data.targetGrid.ROW_INDICATOR_WIDTH;
        data.targetTime = data.targetItem.startDate;
        this.showDropIndicator = true;

        this.position.height =
          data.targetItem.displayElement.nativeElement.getBoundingClientRect().y +
          data.targetItem.displayElement.nativeElement.clientHeight -
          data.targetGrid.bodyContainerOffset.y -
          this.position.top;
      } else {
        dropTarget = data.targetItem.items[data.targetItem.items.length - 1];
      }
    }

    if (!dropTarget || data.draggedItem instanceof PlanningGroupData) {
      return;
    }

    if (dropTarget === data.draggedItem) {
      this.hide();
      return;
    }

    const targetStart = dropTarget.displayElement.nativeElement.offsetLeft + data.targetItem.displayElement.nativeElement.offsetLeft + data.targetGrid.ROW_INDICATOR_WIDTH;
    const targetEnd = targetStart + dropTarget.displayElement.nativeElement.clientWidth;
    if (this.position.left - targetStart < targetEnd - this.position.left) {
      this.position.left = targetStart;
      data.targetTime = dropTarget.startDate;
    } else {
      this.position.left = targetEnd;
      data.targetTime = dropTarget.endDate;
    }
    this.showDropIndicator = true;
    this.position.height =
      data.targetItem.displayElement.nativeElement.getBoundingClientRect().y + data.targetItem.displayElement.nativeElement.clientHeight - data.targetGrid.bodyContainerOffset.y - this.position.top;
  }

  private collideWithCurrentTime(data: DragDropData): void {
    if (!data.targetGrid.currentTime) {
      return;
    }
    const currentTimeLeft = (data.targetGrid.getLeftPercentForDate(data.targetGrid.currentTime) / 100) * data.targetGrid.rowWidth + data.targetGrid.ROW_INDICATOR_WIDTH;
    if (this.position.left < currentTimeLeft) {
      this.position.left = currentTimeLeft;
      data.targetTime = data.targetGrid.currentTime;
    }
  }

  private updatePosition(): void {
    if (!this.indicator || !this.container) {
      return;
    }
    this.indicator.nativeElement.style.top = `${this.position.top}px`;
    this.container.nativeElement.style.left = `${this.position.left}px`;
    this.indicator.nativeElement.style.height = `${this.position.height}px`;
    this.dateText.nativeElement.innerText = moment(this.date).format('HH:mm');
    this.dropIndicator.nativeElement.style.opacity = this.showDropIndicator ? '1' : '0';
  }
}
