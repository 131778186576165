import {Component, Inject, OnInit} from '@angular/core';
import {BackgroundJob} from '@domain/background-jobs/background-job';
import {BackgroundJobs, BACKGROUND_JOBS} from '@infrastructure/http/background-jobs/background-jobs';
import {RealtimeBackgroundJobs, REALTIME_BACKGROUND_JOBS} from '@infrastructure/signalr/jobs/realtime-background-jobs';
import {AlertDialogResult, BaseComponent, DialogBuilderFactoryService, DialogType, TranslateService} from '@vdw/angular-component-library';
import {isEqual, noop} from 'lodash-es';
import {Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class BaseBackgroundJobsComponent extends BaseComponent implements OnInit {
  public listOfBackgroundJobs: BackgroundJob[];

  private backgroundJobsSubscription: Subscription;

  protected constructor(
    @Inject(BACKGROUND_JOBS) protected readonly backgroundJobs: BackgroundJobs,
    @Inject(REALTIME_BACKGROUND_JOBS) protected readonly realtimeBackgroundJobs: RealtimeBackgroundJobs,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadListOfBackgroundJobs();
    this.subscribeToRealtimeJobGlobalStatusChanges();
  }

  public retryBackgroundJob(backgroundJob: BackgroundJob): void {
    this.backgroundJobs
      .retry(backgroundJob)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.loadListOfBackgroundJobs();
      });
  }

  public cancelBackgroundJob(backgroundJob: BackgroundJob): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: this.translate.instant('BACKGROUND_JOBS.CANCEL_JOB'),
        messageText: this.translate.instant('BACKGROUND_JOBS.CANCEL_JOB_CONFIRMATION'),
        type: DialogType.DELETE
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((result: AlertDialogResult) => {
        if (isEqual(result, AlertDialogResult.CONFIRM)) {
          this.backgroundJobs.cancelJob(backgroundJob.id).pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe();
        }
      });
  }

  protected onBackgroundJobsLoaded(): void {
    noop();
  }

  private loadListOfBackgroundJobs(): void {
    this.backgroundJobsSubscription?.unsubscribe();
    this.backgroundJobsSubscription = this.backgroundJobs
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfBackgroundJobs: BackgroundJob[]) => {
        this.listOfBackgroundJobs = listOfBackgroundJobs;

        this.onBackgroundJobsLoaded();
      });
  }

  private subscribeToRealtimeJobGlobalStatusChanges(): void {
    this.realtimeBackgroundJobs
      .getJobGlobalStatusChanged()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.loadListOfBackgroundJobs());
  }
}
