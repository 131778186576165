import {Injectable} from '@angular/core';
import {includes, isEmpty, map} from 'lodash-es';
import {moment} from '../../common/moment';
import {EnumUtils} from '../../common/utils/enum-utils';
import {LocalStorageService} from '../../custom-services/local-storage.service/local-storage.service';
import {TranslateService} from '../translate.service';
import {Language} from './language.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static readonly AVAILABLE_LANGUAGES: string[] = EnumUtils.getEnumNames(Language);
  public static readonly LOCAL_STORAGE_KEY = 'language';
  public static readonly DEFAULT_LANGUAGE: string = EnumUtils.getKeyFromValue(Language, Language.en);
  private static readonly EXPERIMENTAL_LANGUAGES = [EnumUtils.getKeyFromValue(Language, Language.tr), EnumUtils.getKeyFromValue(Language, Language.zh)];

  public constructor(
    private readonly translate: TranslateService,
    private readonly localStorage: LocalStorageService
  ) {}

  public initialize(): Promise<void> {
    let storedLanguage: string = this.localStorage.get(LanguageService.LOCAL_STORAGE_KEY);

    if (isEmpty(storedLanguage)) {
      storedLanguage = LanguageService.DEFAULT_LANGUAGE;
      this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, storedLanguage);
    }

    moment.locale(storedLanguage);

    return this.setLanguage(storedLanguage);
  }

  public changeLanguage(language: string): Promise<void> {
    this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, language);
    moment.locale(language);

    return this.setLanguage(language);
  }

  public getActiveLanguage(): string {
    return this.translate.getLocale().language;
  }

  public getAvailableLanguages(): {key: string; label: string}[] {
    return map(LanguageService.AVAILABLE_LANGUAGES, (availableLanguageKey: string) => {
      return {
        key: availableLanguageKey,
        label: Language[availableLanguageKey]
      };
    });
  }

  public isExperimentalLanguage(language: string): boolean {
    return includes(LanguageService.EXPERIMENTAL_LANGUAGES, language);
  }

  private setLanguage(language: string): Promise<void> {
    return this.translate.setLocale({language});
  }
}
