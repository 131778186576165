import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NameGeneratorPartService} from '@application/helper/name-generator/name-generator-part.service';
import {NameGeneratorPartType} from '@domain/name-generator/enums/name-generator-part-type.enum';
import {NameGeneratorPart} from '@domain/name-generator/name-generator-part';
import {BaseComponent, DialogBuilderFactoryService} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {SelectNameGenerationPatternComponent} from './select-name-generation-pattern/select-name-generation-pattern.component';

@Component({
  selector: 'app-name-generation-pattern',
  templateUrl: './name-generation-pattern.component.html'
})
export class NameGenerationPatternComponent extends BaseComponent implements OnChanges {
  @Input() public nameGenerationProperties: string[];
  @Input() public namePlaceholderSeparator: string;
  @Input() public nameGeneratorParts: NameGeneratorPart<NameGeneratorPartType>[];
  @Output() public nameGeneratorPartsChange = new EventEmitter<NameGeneratorPart<NameGeneratorPartType>[]>();

  public nameGenerationPattern: FormControl<string>;

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly nameGeneratorPartService: NameGeneratorPartService
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('namePlaceholderSeparator' in changes || 'nameGeneratorParts' in changes) {
      if (this.nameGenerationPattern) {
        this.nameGenerationPattern.patchValue(this.nameGeneratorPartService.getPreviewText(this.nameGeneratorParts, this.namePlaceholderSeparator));
      } else {
        this.nameGenerationPattern = new FormControl(this.nameGeneratorPartService.getPreviewText(this.nameGeneratorParts, this.namePlaceholderSeparator));
      }
    }
  }

  public selectNameGenerationPattern(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('change-placeholder-parts')
      .withWidth('1200px')
      .withHeight('90vh')
      .withMaxHeight('688px')
      .openDialog(SelectNameGenerationPatternComponent, {
        nameGenerationProperties: this.nameGenerationProperties,
        namePlaceholderSeparator: this.namePlaceholderSeparator,
        nameGeneratorParts: this.nameGeneratorParts
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((nameGeneratorParts: NameGeneratorPart<NameGeneratorPartType>[]) => {
        if (nameGeneratorParts) {
          this.nameGenerationPattern.patchValue(this.nameGeneratorPartService.getPreviewText(nameGeneratorParts, this.namePlaceholderSeparator));
          this.nameGeneratorPartsChange.emit(nameGeneratorParts);
        }
      });
  }

  public clearNameGenerationPattern(): void {
    this.nameGenerationPattern.patchValue(null);
    this.nameGeneratorPartsChange.emit([]);
  }
}
