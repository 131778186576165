import {HttpParams} from '@angular/common/http';
import {GetAllFinishingsParameters} from '@infrastructure/http/finishing/finishings';
import {GetAllMachineQualitiesParameters} from '@infrastructure/http/machine-quality/machine-qualities';
import {GetAllWeaveStructuresParameters} from '@infrastructure/http/weave-structure/weave-structures';
import {AssertionUtils} from '@vdw/angular-component-library';

export class ExtendedHttpParams extends HttpParams {
  public setHttpParamsFromObject(parameters: Partial<HttpParamsObject>, httpParamsMap: Map<string, string>): HttpParams {
    let httpParams = new HttpParams();

    if (!parameters) {
      return httpParams;
    } else {
      for (const key in parameters) {
        if (!AssertionUtils.isNullOrUndefined(parameters[key]) && httpParamsMap.has(key)) {
          httpParams = httpParams.set(httpParamsMap.get(key), parameters[key]);
        }
      }
      return httpParams;
    }
  }
}

type HttpParamsObject = GetAllWeaveStructuresParameters | GetAllMachineQualitiesParameters | GetAllFinishingsParameters;
