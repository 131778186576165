import {Router} from '@angular/router';
import {
  AgGridEvent,
  BodyScrollEvent,
  CellDoubleClickedEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellMouseOverEvent,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  ColumnMenuTab,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  DisplayedColumnsChangedEvent,
  DndSourceCallbackParams,
  DndSourceOnRowDragParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GetLocaleTextParams,
  GetMainMenuItemsParams,
  GetRowIdFunc,
  GetRowIdParams,
  GetServerSideGroupKey,
  GridApi,
  GridOptions,
  GridPreDestroyedEvent,
  GridReadyEvent,
  GridSizeChangedEvent,
  IDetailCellRendererParams,
  IRowDragItem,
  IServerSideDatasource,
  IsServerSideGroup,
  MenuItemDef,
  NewColumnsLoadedEvent,
  RowClassParams,
  RowClassRules,
  RowClickedEvent,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  RowDragCallbackParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowDragLeaveEvent,
  RowDropZoneParams,
  RowEditingStoppedEvent,
  RowGroupingDisplayType,
  RowGroupOpenedEvent,
  RowHeightParams,
  RowNode,
  RowSelectedEvent,
  RowStyle,
  RowValueChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
  TabToNextCellParams
} from 'ag-grid-community';
import {debounce} from 'lodash-es';
import {catchError, Observable, of, ReplaySubject, Subject, takeUntil} from 'rxjs';
import {ColorConstants} from '../common/colors-variables.generated';
import {AssertionUtils} from '../common/utils/assertion-utils';
import {CrudOverviewButtonConfig} from '../custom-components/crud-overview-data/interfaces/crud-overview-button-config.interface';
import {LocalStorageService} from '../custom-services/local-storage.service/local-storage.service';
import {TranslateService} from '../translation/translate.service';
import {AgGridRowSelection} from './ag-grid-row-selection.enum';
import {CellButtonParams} from './cell-button/cell-button-params.interface';
import {CellButtonComponent} from './cell-button/cell-button.component';
import {GridColumnSettings} from './grid-column-settings.interface';
import {ExternalGridDropZone} from './grid-drop-zone.interface';
import {GridState} from './grid-state.interface';
import {GridTooltipComponent} from './grid-tooltip/grid-tooltip.component';
import {HeaderClass} from './header-class';
import {AgGridUtils} from './helper/ag-grid-utils';
import {LoadingOverlayComponent} from './overlay/loading-overlay/loading-overlay.component';
import {NoDataOverlayComponent} from './overlay/no-data-overlay/no-data-overlay.component';

export class GridOptionsBuilder {
  private gridOptions: GridOptions = {
    headerHeight: 32,
    getRowId: (params: GetRowIdParams) => `${params.data.id}`,
    defaultColDef: {
      sortable: true,
      suppressHeaderMenuButton: true,
      tooltipComponent: GridTooltipComponent,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {buttons: ['reset']}
          },
          {
            filter: 'agSetColumnFilter',
            filterParams: {buttons: ['reset']}
          }
        ]
      },
      floatingFilter: true
    },
    suppressMovableColumns: false,
    suppressDragLeaveHidesColumns: true,
    tabIndex: -1,
    onFilterChanged: (event: FilterChangedEvent) => {
      this.addPlaceholderToFloatingFilter();
      this.resizeGrid(event.api);
    },
    onDisplayedColumnsChanged: () => this.addPlaceholderToFloatingFilter(),
    onFirstDataRendered: (event: FirstDataRenderedEvent) => this.onFirstDataRendered(event),
    onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
    onGridPreDestroyed: (event: GridPreDestroyedEvent) => this.onGridPreDestroyed(event),
    getMainMenuItems: (params: GetMainMenuItemsParams) => {
      return params.defaultItems.filter((item: string) => item !== 'rowUnGroup');
    },
    animateRows: true,
    loadingOverlayComponent: LoadingOverlayComponent,
    rowSelection: AgGridRowSelection.SINGLE,
    context: {
      rowSelection: AgGridRowSelection.SINGLE,
      gridIdentifier: 'undefined'
    },
    rowClass: 'normal',
    stopEditingWhenCellsLoseFocus: true,
    suppressCellFocus: true,
    tooltipShowDelay: 500,
    multiSortKey: 'ctrl',
    suppressContextMenu: true,
    columnMenu: 'new'
  } as GridOptions;

  private gridApi: GridApi;
  private gridColumnSettings = new ReplaySubject<GridColumnSettings>(1);
  private SHOW_HIDE_SEARCH_TRANSLATION_KEY = 'AGGRID.SHOWHIDESEARCH';
  private EXPAND_ALL_TRANSLATION_KEY = 'AGGRID.EXPANDALL';
  private COLLAPSE_ALL_TRANSLATION_KEY = 'AGGRID.COLLAPSEALL';

  private readonly unSubscribeOnGridDestroy = new Subject<boolean>();

  public constructor(
    private readonly translateService: TranslateService,
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly saveGridSettings: (identifier: any, columnState: Record<string, any>) => Observable<any>,
    private readonly getGridSettings: (identifier: any) => Observable<unknown>
  ) {
    if (AssertionUtils.isNullOrUndefined(this.saveGridSettings)) {
      this.saveGridSettings = (): Observable<any> => of(null);
    }

    if (AssertionUtils.isNullOrUndefined(this.getGridSettings)) {
      this.getGridSettings = (): Observable<unknown> => of(null);
    }
  }

  public build(): GridOptions {
    return this.gridOptions;
  }

  public getDefaultGridOptions(columnDefs: ColDef[], gridIdentifier: string, rowData?: any, resizable: boolean = false): GridOptionsBuilder {
    this.gridOptions.maintainColumnOrder = true;

    if (rowData) {
      this.setOption('rowData', rowData);
    }

    if (columnDefs.length > 0 && columnDefs.every((columnDef: ColDef) => !columnDef.lockVisible)) {
      columnDefs[0].lockVisible = true;
    }

    const groupColDefIndex = columnDefs.findIndex((columnDef: ColDef) => columnDef.rowGroup && columnDef.hide);

    if (groupColDefIndex !== -1) {
      columnDefs[groupColDefIndex].suppressColumnsToolPanel = true;
    }

    if (columnDefs.length === 1) {
      this.withoutMovableColumns();
    }

    this.extendOption('columnDefs', columnDefs);

    this.withColumnMenu();

    this.withOnBodyScroll(() => {
      this.addPlaceholderToFloatingFilter();
      this.addCompactClassToFloatingFilter();
    });

    this.withGridIdentifier(gridIdentifier, resizable);

    this.withMainMenuItems(true, ['hideFloatingFilter']);

    this.getGridSettings(this.gridOptions.context.gridIdentifier)
      .pipe(
        catchError(() => of(null)),
        takeUntil(this.unSubscribeOnGridDestroy)
      )
      .subscribe((settings: GridColumnSettings) => this.gridColumnSettings.next(settings));

    return this;
  }

  public getDefaultCrudGridOptions(columnDefs: ColDef[], gridIdentifier: string, rowData: any, rowActionButtons: CrudOverviewButtonConfig[], resizable: boolean = false): GridOptionsBuilder {
    this.gridOptions.maintainColumnOrder = true;

    if (rowData) {
      this.setOption('rowData', rowData);
    }

    if (columnDefs.length > 0 && columnDefs.every((columnDef: ColDef) => !columnDef.lockVisible)) {
      columnDefs[0].lockVisible = true;
    }

    const groupColDefIndex = columnDefs.findIndex((columnDef: ColDef) => columnDef.rowGroup && columnDef.hide);

    if (groupColDefIndex !== -1) {
      columnDefs[groupColDefIndex].suppressColumnsToolPanel = true;
    }

    if (columnDefs.length === 1) {
      this.withoutMovableColumns();
    }

    this.extendOption('columnDefs', columnDefs);
    this.setOption('popupParent', document.body);

    this.withColumnMenu();

    if (!AssertionUtils.isEmpty(rowActionButtons)) {
      this.withRowActions({buttons: rowActionButtons} as CellButtonParams);
    }

    this.withOnBodyScroll(() => {
      this.addPlaceholderToFloatingFilter();
      this.addCompactClassToFloatingFilter();
    });

    this.withGridIdentifier(gridIdentifier, resizable);

    this.withMainMenuItems(true, ['hideFloatingFilter']);

    this.getGridSettings(this.gridOptions.context.gridIdentifier)
      .pipe(
        catchError(() => of(null)),
        takeUntil(this.unSubscribeOnGridDestroy)
      )
      .subscribe((settings: GridColumnSettings) => this.gridColumnSettings.next(settings));

    return this;
  }

  public fromExistingGridApi(gridApi: GridApi): GridOptionsBuilder {
    this.gridApi = gridApi;

    return this;
  }

  // General
  public withServerSideDataSource(cacheBlockSize: number, dataSource: IServerSideDatasource): GridOptionsBuilder {
    this.setOption('maxConcurrentDatasourceRequests', 1);
    this.setOption('blockLoadDebounceMillis', 1);
    this.setOption('cacheBlockSize', cacheBlockSize);
    this.setOption('rowModelType', 'serverSide');
    this.setOption('loadingOverlayComponent', LoadingOverlayComponent);
    this.setOption('serverSideDatasource', dataSource);

    this.withOnGridReady(() => {
      this.setOption('loadingCellRendererParams', {
        loadingForTheFirstTime: true
      });
    });

    return this;
  }

  public withTreeData(isServerSideGroup: IsServerSideGroup = null, getServerSideGroupKey: GetServerSideGroupKey = null): GridOptionsBuilder {
    this.setOption('treeData', true);
    this.setOption('isServerSideGroup', isServerSideGroup);
    this.setOption('getServerSideGroupKey', getServerSideGroupKey);
    return this;
  }

  // Add properties
  public withHeaderHeight(height: number): GridOptionsBuilder {
    this.setOption('headerHeight', height);
    return this;
  }

  /**
   *
   * @deprecated Please use the predefined css classes to set the row height of a grid.
   * Row height is 32px by default, use css class='ag-grid-compact-view' for 24px and css class='ag-grid-large-view' for 48px.
   */
  public withRowHeight(height: number): GridOptionsBuilder {
    this.setOption('rowHeight', height);
    return this;
  }

  public withLoadingCellRenderer(loadingCellRenderer: string): GridOptionsBuilder {
    this.setOption('loadingCellRenderer', loadingCellRenderer);
    return this;
  }

  public withComponents(components: any): GridOptionsBuilder {
    this.setOption('components', components);
    return this;
  }

  public withFloatingFiltersHeight(height: number): GridOptionsBuilder {
    this.setOption('floatingFiltersHeight', height);
    return this;
  }

  public withRowClass(rowClass: string | string[]): GridOptionsBuilder {
    this.setOption('rowClass', rowClass);
    return this;
  }

  public withRowClassRules(rules: RowClassRules): GridOptionsBuilder {
    this.setOption('rowClassRules', rules);
    return this;
  }

  public withCompactView(): GridOptionsBuilder {
    if (this.gridOptions.autoGroupColumnDef) {
      this.setOption('autoGroupColumnDef', {
        ...this.gridOptions.autoGroupColumnDef,
        headerClass: Array.isArray(this.gridOptions.autoGroupColumnDef.headerClass) ? this.gridOptions.autoGroupColumnDef.headerClass.join(' ') : this.gridOptions.autoGroupColumnDef.headerClass
      });
      this.setOption('autoGroupColumnDef', {...this.gridOptions.autoGroupColumnDef, headerClass: `${this.gridOptions.autoGroupColumnDef.headerClass ?? ''} ${HeaderClass.COMPACT}`});
    }

    for (const colDef of this.gridOptions.columnDefs) {
      colDef.headerClass = Array.isArray(colDef.headerClass) ? colDef.headerClass.join(' ') : colDef.headerClass;
      colDef.headerClass = `${colDef.headerClass ?? ''} ${HeaderClass.COMPACT}`;
    }

    this.setOption('rowClass', Array.isArray(this.gridOptions.rowClass) ? this.gridOptions.rowClass.join(' ') : this.gridOptions.rowClass);
    this.setOption('rowClass', `${this.gridOptions.rowClass ?? ''} ${HeaderClass.COMPACT}`);
    return this;
  }

  public withGetRowId(getRowIdFunction: GetRowIdFunc): GridOptionsBuilder {
    this.setOption('getRowId', getRowIdFunction);
    return this;
  }

  public withoutGetRowId(): GridOptionsBuilder {
    this.setOption('getRowId', undefined);
    return this;
  }

  public withAutoHeight(): GridOptionsBuilder {
    this.setOption('autoHeight', true);
    return this;
  }

  public withRowSelection(
    allowMultipleSelection: boolean,
    rowMultiSelectWithClick: boolean = true,
    selectWithCheckbox: boolean = false,
    headerWithCheckbox: boolean = true,
    groupSelectsChildren: boolean = false
  ): GridOptionsBuilder {
    if (allowMultipleSelection) {
      this.setOption('rowSelection', AgGridRowSelection.MULTIPLE);
      this.setOption('rowMultiSelectWithClick', rowMultiSelectWithClick);

      this.setOption('context', {...this.gridOptions.context, ...{rowSelection: AgGridRowSelection.MULTIPLE}});

      this.withOnRowSelected((event: RowSelectedEvent) => {
        event.api.refreshHeader();
        this.addPlaceholderToFloatingFilter();
        this.addCompactClassToFloatingFilter();

        const defaultColDef = this.getOption(event.api, 'defaultColDef');

        if (!AssertionUtils.isNullOrUndefined(defaultColDef?.resizable) && !defaultColDef.resizable) {
          event.api.sizeColumnsToFit();
        }
      });
    }

    if (selectWithCheckbox) {
      this.extendOption('columnDefs', [
        {
          colId: 'checkbox',
          headerClass: 'ag-cell-flex-center',
          cellClass: 'ag-cell-flex-center',
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          resizable: false,
          checkboxSelection: true,
          suppressHeaderMenuButton: true,
          headerCheckboxSelection: headerWithCheckbox,
          floatingFilter: false,
          lockVisible: true,
          suppressColumnsToolPanel: true,
          lockPosition: 'left',
          pinned: 'left',
          lockPinned: true,
          suppressMovable: true,
          sortable: false
        }
      ]);
    }

    this.setOption('groupSelectsChildren', groupSelectsChildren);

    return this;
  }

  private getDragHandleColDef(
    dragHandleText: (params: IRowDragItem, dragItemCount: number) => string = () => '',
    rowDrag: boolean | ((params: RowDragCallbackParams) => boolean) = undefined,
    dndSource: boolean | ((params: DndSourceCallbackParams<any>) => boolean) = undefined,
    dndOnRowDrag: (params: DndSourceOnRowDragParams) => void = undefined
  ): ColDef {
    return {
      colId: 'dragHandle',
      cellClass: 'ag-cell-flex-center',
      rowDrag: rowDrag,
      rowDragText: dragHandleText,
      width: 35,
      minWidth: 35,
      maxWidth: 35,
      resizable: false,
      suppressHeaderMenuButton: true,
      filter: false,
      floatingFilter: false,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      lockPosition: 'left',
      pinned: 'left',
      lockPinned: true,
      suppressMovable: true,
      sortable: false,
      dndSource: dndSource,
      dndSourceOnRowDrag: dndOnRowDrag
    };
  }

  public withRowDraggingToExternalDropZones(
    dragHandleText: (params: IRowDragItem, dragItemCount: number) => string,
    externalDropZones: ExternalGridDropZone[],
    draggingInsideGrid: boolean = false
  ): this {
    this.extendOption('columnDefs', [this.getDragHandleColDef(dragHandleText, true)]);
    this.setOption('rowDragManaged', draggingInsideGrid);

    this.setOption('onRowDragEnter', (rowDragStartParams: RowDragEnterEvent) => {
      externalDropZones.forEach((externalDropZone: ExternalGridDropZone) => {
        document.querySelectorAll(externalDropZone.querySelector).forEach((dropTarget: HTMLElement) => {
          let dropZoneParams: RowDropZoneParams = {
            getContainer: () => dropTarget,
            onDragEnter: (params: RowDragEnterEvent) => {
              document.querySelector('.ag-dnd-ghost').classList.add('pointer-events-none');
              externalDropZone.setData(params);
            },
            onDragLeave: (params: RowDragLeaveEvent) => {
              document.querySelector('.ag-dnd-ghost').classList.remove('pointer-events-none');
              externalDropZone.clearData(params);
            },
            onDragStop: externalDropZone.clearData
          };

          rowDragStartParams.api.removeRowDropZone({getContainer: () => dropTarget});
          rowDragStartParams.api.addRowDropZone(dropZoneParams);
        });
      });
    });

    return this;
  }

  public withRowDraggingOutsideGrid(
    onRowDrag: (params: Partial<DndSourceOnRowDragParams>) => void,
    dndSource: (params: DndSourceCallbackParams<any>) => boolean = null,
    columnIndex?: number
  ): GridOptionsBuilder {
    this.extendOption('columnDefs', [this.getDragHandleColDef(undefined, undefined, dndSource ?? true, onRowDrag)]);

    return this;
  }

  public withRowDraggingInsideGrid(dragHandleText: (params: IRowDragItem, dragItemCount: number) => string = undefined, rowDrag: boolean | ((params: RowDragCallbackParams) => boolean) = true): this {
    this.extendOption('columnDefs', [this.getDragHandleColDef(dragHandleText, rowDrag)]);
    this.setOption('rowDragManaged', true);

    return this;
  }

  public withNoRowsOverlay(params?: any): GridOptionsBuilder {
    this.setOption('noRowsOverlayComponent', NoDataOverlayComponent);
    this.setOption('noRowsOverlayComponentParams', params);

    this.withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => AgGridUtils.onRowDataUpdated(api));
    this.withOnFilterChanged(({api}: FilterChangedEvent) => AgGridUtils.onRowDataUpdated(api));

    return this;
  }

  public withLoadingOverlay(params?: any): GridOptionsBuilder {
    this.setOption('loadingOverlayComponent', LoadingOverlayComponent);
    this.setOption('loadingOverlayComponentParams', params);
    return this;
  }

  public withOverlayLoadingTemplate(overlayLoadingTemplate: string): GridOptionsBuilder {
    this.setOption('overlayLoadingTemplate', overlayLoadingTemplate);
    this.setOption('loadingOverlayComponent', null);
    this.setOption('loadingOverlayComponentParams', null);

    return this;
  }

  public withoutSorting(): GridOptionsBuilder {
    this.setOption('defaultColDef', {...this.gridOptions.defaultColDef, sortable: false});
    return this;
  }

  public withLockPinnedColumns(): GridOptionsBuilder {
    this.setOption('defaultColDef', {...this.gridOptions.defaultColDef, lockPinned: true});
    return this;
  }

  public withoutMovableColumns(): GridOptionsBuilder {
    this.setOption('suppressMovableColumns', true);
    return this;
  }

  public withDefaultColDef(defaultColDef: any): GridOptionsBuilder {
    this.setOption('defaultColDef', defaultColDef);
    return this;
  }

  public withGroupDisplayType(groupDisplayType: RowGroupingDisplayType): GridOptionsBuilder {
    this.setOption('groupDisplayType', groupDisplayType);
    return this;
  }

  public withSuppressRowClickSelection(value: boolean = true): GridOptionsBuilder {
    this.setOption('suppressRowClickSelection', value);
    return this;
  }

  public withAutoGroupColumnDef(autoGroupColumnDef: ColDef, pinAndLockColumn: boolean = true): GridOptionsBuilder {
    if (pinAndLockColumn) {
      autoGroupColumnDef.pinned = true;
      autoGroupColumnDef.lockPinned = true;
    }

    this.setOption('autoGroupColumnDef', autoGroupColumnDef);
    return this;
  }

  public withSingleClickEdit(): GridOptionsBuilder {
    this.setOption('singleClickEdit', true);
    return this;
  }

  public withEditType(): GridOptionsBuilder {
    this.setOption('editType', 'fullRow');
    return this;
  }

  public withStopEditingWhenCellsLoseFocus(): GridOptionsBuilder {
    this.setOption('stopEditingWhenCellsLoseFocus', true);
    return this;
  }

  public withGroupRowRendererParams(groupRowRendererParams: any): GridOptionsBuilder {
    this.setOption('groupRowRendererParams', groupRowRendererParams);
    return this;
  }

  public withRowDragManaged(): GridOptionsBuilder {
    this.setOption('rowDragManaged', true);
    return this;
  }

  public withPinnedBottomRowData(data: any): GridOptionsBuilder {
    this.setOption('pinnedBottomRowData', data);
    return this;
  }

  public withSuppressNoRowsOverlay(): GridOptionsBuilder {
    this.setOption('suppressNoRowsOverlay', true);
    return this;
  }

  public withGroupEdit(): GridOptionsBuilder {
    this.setOption('enableGroupEdit', true);
    return this;
  }

  public withMasterDetailGrid(detailCellRenderer: any, getDetailCellRendererParams: (dcrParams: any) => IDetailCellRendererParams): GridOptionsBuilder {
    this.setOption('masterDetail', true);
    this.setOption('detailCellRenderer', detailCellRenderer);
    this.setOption('detailCellRendererParams', getDetailCellRendererParams);
    return this;
  }

  public withDetailRowHeight(height: number): GridOptionsBuilder {
    this.setOption('height', height);
    return this;
  }

  public withMasterDetail(
    detailCellRenderer: any,
    withExpandAndCollapseButton: boolean = false,
    getDetailCellRendererParams?: (dcrParams: any) => IDetailCellRendererParams,
    detailAutoRowHeight: boolean = true
  ): GridOptionsBuilder {
    this.setOption('masterDetail', true);
    this.setOption('detailCellRenderer', detailCellRenderer);
    this.setOption('detailRowAutoHeight', detailAutoRowHeight);

    if (!AssertionUtils.isNullOrUndefined(getDetailCellRendererParams)) {
      this.setOption('detailCellRendererParams', getDetailCellRendererParams);
    }

    if (withExpandAndCollapseButton) {
      this.withMainMenuItems(true, ['hideFloatingFilter', 'expandButton', 'collapseButton']);
    }

    return this;
  }

  public withGetRowClass(getRowClass: (params: RowClassParams) => string | string[]): GridOptionsBuilder {
    this.setOption('getRowClass', getRowClass);
    return this;
  }

  public withSuppressRowHoverHighlight(): GridOptionsBuilder {
    this.setOption('suppressRowHoverHighlight', true);
    return this;
  }

  public withTooltipShowDelay(tooltipShowDelay: number): GridOptionsBuilder {
    this.setOption('tooltipShowDelay', tooltipShowDelay);
    return this;
  }

  public withTooltipHideDelay(tooltipHideDelay: number): GridOptionsBuilder {
    this.setOption('tooltipHideDelay', tooltipHideDelay);
    return this;
  }

  // Add functions
  public withIsRowMaster(isRowMaster: (dataItem: any) => boolean): GridOptionsBuilder {
    this.setOption('isRowMaster', isRowMaster);
    return this;
  }

  public withIsRowSelectable(isRowSelectable: (dataItem: RowNode) => boolean): GridOptionsBuilder {
    this.gridOptions.isRowSelectable = isRowSelectable;
    this.gridOptions.getRowStyle = (params: RowClassParams): RowStyle => (params.node.selectable ? undefined : {color: ColorConstants.TINT_DARKBLUE_50});

    return this;
  }

  public withIsExternalFilterPresent(isExternalFilterPresent: () => boolean): GridOptionsBuilder {
    this.setOption('isExternalFilterPresent', isExternalFilterPresent);
    return this;
  }

  public withRowActions(cellButtonParams?: CellButtonParams): GridOptionsBuilder {
    this.extendOption('columnDefs', [
      {
        colId: 'actions',
        cellRendererSelector: (params: CellButtonParams): any => {
          return {
            component: CellButtonComponent,
            params: cellButtonParams
          };
        },
        width: 32,
        minWidth: 32,
        maxWidth: 32,
        resizable: false,
        suppressMenu: true,
        filter: false,
        suppressHeaderMenuButton: true,
        floatingFilter: false,
        lockVisible: true,
        suppressColumnsToolPanel: true,
        lockPosition: 'right',
        pinned: 'right',
        lockPinned: true,
        suppressMovable: true,
        sortable: false
      }
    ]);

    return this;
  }

  public withDoesExternalFilterPass(doesExternalFilterPass: (node: RowNode) => boolean): GridOptionsBuilder {
    this.setOption('doesExternalFilterPass', doesExternalFilterPass);
    return this;
  }

  public withTabToNextCell(): GridOptionsBuilder {
    this.setOption('tabToNextCell', (params: TabToNextCellParams): CellPosition => {
      const row = params.api.getDisplayedRowAtIndex(params.nextCellPosition.rowIndex);
      if (row.isSelected()) {
        return params.nextCellPosition;
      }
    });

    return this;
  }

  /**
   *
   * @deprecated Please use the predefined css classes to set the row height of a grid.
   * Row height is 32px by default, use css class='ag-grid-compact-view' for 24px and css class='ag-grid-large-view' for 48px.
   */
  public withGetRowHeight(getRowHeightFunction: (params: RowHeightParams) => number): GridOptionsBuilder {
    this.setOption('getRowHeight', getRowHeightFunction);
    return this;
  }

  public withColumnMenu(menuTabs: ColumnMenuTab[] = ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']): this {
    let defaultColDef = this.gridOptions.defaultColDef;
    defaultColDef.menuTabs = menuTabs;
    defaultColDef.suppressHeaderMenuButton = menuTabs.length <= 0;

    this.setOption('defaultColDef', defaultColDef);

    return this;
  }

  public withoutColumnMenu(): this {
    return this.withColumnMenu([]);
  }

  public withPopupParent(htmlElement: HTMLElement): GridOptionsBuilder {
    this.setOption('popupParent', htmlElement);
    return this;
  }

  public withContext(context: Record<string, any>): GridOptionsBuilder {
    this.updateGridContext(context);

    return this;
  }

  public withRememberStateForNavigationHelper(): GridOptionsBuilder {
    const gridIdentifier = this.gridOptions.context.gridIdentifier;

    this.withOnBodyScroll((event: BodyScrollEvent) => {
      if (event.top > -1 && gridIdentifier) {
        sessionStorage.setItem(`gridState.${this.router.url}${gridIdentifier}`, JSON.stringify({...this.getNavigationHelperGridState(), top: event.top} as GridState));
      }
    });
    this.withOnFilterChanged((event: FilterChangedEvent) => {
      sessionStorage.setItem(
        `gridState.${this.router.url}${gridIdentifier}`,
        JSON.stringify({
          ...this.getNavigationHelperGridState(),
          quickFilter: event.api.getQuickFilter(),
          filterModel: event.api.getFilterModel()
        })
      );
    });

    return this;
  }

  private getNavigationHelperGridState(): GridState {
    return JSON.parse(sessionStorage.getItem(`gridState.${this.router.url}${this.gridOptions.context.gridIdentifier}`)) as GridState;
  }

  public withAutomaticRowSelection(): GridOptionsBuilder {
    this.withOnFilterChanged(({api}: FilterChangedEvent) => this.automaticFilterChangedSelection(api));
    this.withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => this.automaticFirstDataRenderedSelection(api));
    this.withOnGridReady(({api}: GridReadyEvent) => this.automaticFirstDataRenderedSelection(api));

    return this;
  }

  public withAutomaticButtonFocusOnRowSelection(buttonSelector: string): GridOptionsBuilder {
    this.withOnRowSelected((event: RowSelectedEvent) => {
      if (event.api.getSelectedNodes().length > 0) {
        setTimeout(() => {
          const button = document.querySelector<HTMLButtonElement>(buttonSelector);
          button?.focus();
        }, 500);
      }
    });

    return this;
  }

  public withMainMenuItems(useDefaultMenuItems: boolean, customItems: string[]): this {
    this.setOption('getMainMenuItems', (params: GetContextMenuItemsParams) => {
      let menuItems: (string | MenuItemDef)[] = [];

      if (useDefaultMenuItems) {
        if (params.column.isSortable()) {
          menuItems.push(...params.defaultItems.slice(0, 3));
        }

        menuItems.push({
          name: this.translateService.instant('AGGRID.AUTOSIZETHISCOLUMN'),
          icon: '<span class="ag-icon ag-icon-width"></span>',
          action: (): void => params.api.autoSizeColumns([params.column])
        });

        if (!params.column.getColDef().lockPinned) {
          menuItems.push('pinSubMenu');
        }

        menuItems.push(...['separator', 'autoSizeAll', 'columnChooser', 'resetColumns']);
      }

      if (customItems.includes('hideFloatingFilter')) {
        let defaultColdef = this.getOption(this.gridApi, 'defaultColDef');
        menuItems.push(
          ...[
            'separator',
            {
              name: this.translateService.instant(this.SHOW_HIDE_SEARCH_TRANSLATION_KEY),
              icon: `<span class="ag-icon ${defaultColdef.floatingFilter ? 'ag-icon-visibility' : 'ag-icon-visibility-off'}"></span>`,
              action: (): void => {
                defaultColdef.floatingFilter = !defaultColdef.floatingFilter;
                this.setOption('defaultColDef', defaultColdef);
                this.gridApi.refreshHeader();

                if (defaultColdef.floatingFilter) {
                  this.addPlaceholderToFloatingFilter();
                }
              }
            }
          ]
        );
      }
      if (customItems.includes('expandButton')) {
        menuItems.push({
          name: this.translateService.instant(this.EXPAND_ALL_TRANSLATION_KEY),
          action: (): void => {
            params.api.forEachNode((node: RowNode) => node.setExpanded(true));
            params.api.hidePopupMenu();
          }
        });
      }
      if (customItems.includes('collapseButton')) {
        menuItems.push({
          name: this.translateService.instant(this.COLLAPSE_ALL_TRANSLATION_KEY),
          action: (): void => {
            params.api.forEachNode((node: RowNode) => node.setExpanded(false));
            params.api.hidePopupMenu();
          }
        });
      }

      return menuItems;
    });

    return this;
  }

  // Event handlers
  public withOnColumnPinned(onColumnPinnedFunction: (event: ColumnPinnedEvent) => void): GridOptionsBuilder {
    this.setOption('onColumnPinned', onColumnPinnedFunction);
    return this;
  }

  public withOnColumnMoved(onColumnMovedFunction: (event: ColumnMovedEvent) => void): GridOptionsBuilder {
    this.withOnEvent('onColumnMoved', onColumnMovedFunction);
    return this;
  }

  public withOnSortChanged(onSortChangedFunction: (event: SortChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onSortChanged', onSortChangedFunction);
    return this;
  }

  public withOnRowSelected(onRowSelectedFunction: (event: RowSelectedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowSelected', onRowSelectedFunction);
    return this;
  }

  public withOnRowDoubleClicked(onRowDoubleClickedFunction: (event: RowDoubleClickedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowDoubleClicked', onRowDoubleClickedFunction);
    return this;
  }

  public withOnFilterChanged(onFilterChangedFunction: (event: FilterChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onFilterChanged', onFilterChangedFunction);
    return this;
  }

  public withOnFirstDataRendered(onFirstDataRenderedFunction: (event: FirstDataRenderedEvent) => void): GridOptionsBuilder {
    this.setOption('onFirstDataRendered', onFirstDataRenderedFunction);
    return this;
  }

  public withOnBodyScroll(onBodyScrollFunction: (event: BodyScrollEvent) => void): GridOptionsBuilder {
    this.setOption('onBodyScroll', onBodyScrollFunction);
    return this;
  }

  public withOnCellDoubleClicked(onCellDoubleClickedFunction: (event: CellDoubleClickedEvent) => void): GridOptionsBuilder {
    this.setOption('onCellDoubleClicked', onCellDoubleClickedFunction);
    return this;
  }

  public withOnGridReady(gridReadyFunction: (event: GridReadyEvent) => void): GridOptionsBuilder {
    this.withOnEvent('onGridReady', gridReadyFunction);
    return this;
  }

  public withOnGridSizeChanged(gridSizeChangedFunction: (event: GridSizeChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onGridSizeChanged', gridSizeChangedFunction);
    return this;
  }

  public withOnColumnVisible(columnVisibleFunction: (event: ColumnVisibleEvent) => void): GridOptionsBuilder {
    this.setOption('onColumnVisible', columnVisibleFunction);
    return this;
  }

  public withOnRowClicked(rowClickedFunction: (event: RowClickedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowClicked', rowClickedFunction);
    return this;
  }

  public withOnCellMouseOver(cellMouseOverFunction: (event: CellMouseOverEvent) => void): GridOptionsBuilder {
    this.setOption('onCellMouseOver', cellMouseOverFunction);
    return this;
  }

  public withOnRowDataUpdated(rowDataUpdatedFunction: (event: RowDataUpdatedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowDataUpdated', rowDataUpdatedFunction);
    return this;
  }

  public withOnRowValueChanged(rowValueChangedFunction: (event: RowValueChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowValueChanged', rowValueChangedFunction);
    return this;
  }

  public withOnRowEditingStopped(rowEditingStoppedFunction: (event: RowEditingStoppedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowEditingStopped', rowEditingStoppedFunction);
    return this;
  }

  public withOnCellValueChanged(cellValueChangedFunction: (event: CellValueChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onCellValueChanged', cellValueChangedFunction);
    return this;
  }

  public withOnCellEditingStopped(cellEditingStoppedFunction: (event: CellEditingStoppedEvent) => void = null): GridOptionsBuilder {
    this.setOption('onCellEditingStopped', cellEditingStoppedFunction);
    return this;
  }

  public withOnCellEditingStarted(cellEditingStartedFunction: (event: CellEditingStartedEvent) => void): GridOptionsBuilder {
    this.setOption('onCellEditingStarted', cellEditingStartedFunction);
    return this;
  }

  public withOnNewColumnsLoaded(withOnNewColumnsLoaded: (event: NewColumnsLoadedEvent) => void): GridOptionsBuilder {
    this.setOption('onNewColumnsLoaded', withOnNewColumnsLoaded);
    return this;
  }

  public withOnRowGroupOpened(rowGroupOpened: (event: RowGroupOpenedEvent) => void): GridOptionsBuilder {
    this.setOption('onRowGroupOpened', rowGroupOpened);
    return this;
  }

  public withOnColumnResized(columnResizedFunction: (event: ColumnResizedEvent) => void): GridOptionsBuilder {
    this.setOption('onColumnResized', columnResizedFunction);
    return this;
  }

  public withOnDisplayedColumnsChanged(onDisplayedColumnsChangedFunction: (event: DisplayedColumnsChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onDisplayedColumnsChanged', onDisplayedColumnsChangedFunction);
    return this;
  }

  public withOnSelectionChanged(selectionChanged: (event: SelectionChangedEvent) => void): GridOptionsBuilder {
    this.setOption('onSelectionChanged', selectionChanged);
    return this;
  }

  public withOnRowDragEnd(onRowDragEnd: (event: RowDragEndEvent) => void): GridOptionsBuilder {
    this.setOption('onRowDragEnd', onRowDragEnd);
    return this;
  }

  public withSuppressMoveWhenRowDragging(): GridOptionsBuilder {
    this.setOption('suppressMoveWhenRowDragging', true);
    return this;
  }

  private withGridIdentifier(gridIdentifier: string, resizable: boolean = false): GridOptionsBuilder {
    this.setOption('context', {
      ...this.gridOptions.defaultColDef,
      gridIdentifier: gridIdentifier,
      resizable
    });

    this.setOption('suppressMovableColumns', false);

    const debouncedSaveColumnStateFn = debounce((columnState: Record<string, any>) => this.saveColumnState(columnState), 1000, {maxWait: 2000});

    this.withOnColumnResized((event: ColumnResizedEvent) => debouncedSaveColumnStateFn(event.api?.getColumnState()));
    this.withOnColumnPinned((event: ColumnPinnedEvent) => debouncedSaveColumnStateFn(event.api?.getColumnState()));
    this.withOnColumnMoved((event: ColumnMovedEvent) => debouncedSaveColumnStateFn(event.api?.getColumnState()));
    this.withOnColumnVisible((event: ColumnVisibleEvent) => debouncedSaveColumnStateFn(event.api?.getColumnState()));
    this.withOnSortChanged((event: SortChangedEvent) => debouncedSaveColumnStateFn(event.api?.getColumnState()));
    this.withOnDisplayedColumnsChanged((event: DisplayedColumnsChangedEvent) => {
      if (event.source === 'contextMenu') {
        event.api.sizeColumnsToFit();
        this.sizeColumnsToMoreSuitedWidth(event.api);
      }
    });

    this.setOption('getLocaleText', (params: GetLocaleTextParams): string => this.translateGridText(params.key));
    return this;
  }

  private translateGridText(key: string): string {
    return this.translateService.instant('AGGRID.' + key.toUpperCase());
  }

  private withOnEvent(eventHandlerName: string, onEventFunction: (event: AgGridEvent<any>) => void): GridOptionsBuilder {
    const onEvent = this.gridOptions[eventHandlerName];

    this.gridOptions[eventHandlerName] = (event: AgGridEvent<any>): void => {
      if (onEvent) {
        onEvent(event);
      }
      onEventFunction(event);
    };

    return this;
  }

  // Default callbacks for gridoptions
  private resizeGrid(gridApi: GridApi): void {
    const context = this.getOption(gridApi, 'context');

    if (!context.gridIdentifier) {
      gridApi?.sizeColumnsToFit();
    }
  }

  private getGridColumnSettings(api: GridApi): GridColumnSettings {
    const defaultColDef = (({floatingFilter}: ColDef): Partial<ColDef> => ({floatingFilter}))(api?.getGridOption('defaultColDef'));
    const columnState = api?.getColumnState();

    return {defaultColDef, columnState};
  }

  private onGridPreDestroyed(event: GridPreDestroyedEvent): void {
    this.saveGridSettings(this.gridOptions.context.gridIdentifier, this.getGridColumnSettings(event.api))
      .pipe(takeUntil(this.unSubscribeOnGridDestroy))
      .subscribe(() => {
        this.unSubscribeOnGridDestroy.next(true);
        this.unSubscribeOnGridDestroy.complete();
      });
  }

  private onGridReady(event: GridReadyEvent): void {
    this.gridColumnSettings.subscribe((gridSettings: GridColumnSettings) => {
      if (AssertionUtils.isNullOrUndefined(gridSettings)) {
        event.api?.sizeColumnsToFit();
        this.sizeColumnsToMoreSuitedWidth(event.api);
        this.updateGridContext({forceColumnAutosizeOnFirstDataRenderedEvent: true});
      } else if (!event.api?.isDestroyed()) {
        if (gridSettings.columnState) {
          this.applyColumnState(event.api, gridSettings.columnState);
        }
        if (gridSettings.defaultColDef) {
          this.applyDefaultColDef(event.api, gridSettings.defaultColDef);
        }
      }

      this.gridApi = event.api;

      this.withMainMenuItems(true, ['hideFloatingFilter']);
      this.addPlaceholderToFloatingFilter();
      this.addCompactClassToFloatingFilter();
    });
  }

  private onFirstDataRendered(event: FirstDataRenderedEvent): void {
    const context = this.getOption(event.api, 'context');

    if (!context.gridIdentifier) {
      event.api.sizeColumnsToFit();
    } else if (context.forceColumnAutosizeOnFirstDataRenderedEvent) {
      this.sizeColumnsToMoreSuitedWidth(event.api);

      this.updateGridContext({forceColumnAutosizeOnFirstDataRenderedEvent: true});
    }
    this.scrollToSavedPosition(event.api);
  }

  private saveColumnState(columnState: Record<string, any>): void {
    if (columnState) {
      this.localStorage.set('columnState.' + this.gridOptions.context.gridIdentifier, columnState);
    }
  }

  private applyDefaultColDef(gridApi: GridApi, defaultColDef: Partial<ColDef>): void {
    let actualDefaultColDef = this.getOption(gridApi, 'defaultColDef');

    for (const [key, value] of Object.entries(defaultColDef)) {
      actualDefaultColDef[key] = value;
    }

    gridApi.setGridOption('defaultColDef', actualDefaultColDef);
  }

  private applyColumnState(gridApi: GridApi, columnState: any): void {
    gridApi.applyColumnState({state: columnState, applyOrder: true});
  }

  private autoSizeAllColumns(gridApi: GridApi): void {
    gridApi.expandAll();
    gridApi.autoSizeAllColumns();
    gridApi.collapseAll();
  }

  private getTotalColumnsWidth(gridApi: GridApi): number {
    return gridApi.getColumnState().reduce((totalWidth: number, columnState: ColumnState) => {
      if (!columnState.hide) {
        totalWidth += columnState.width;
      }

      return totalWidth;
    }, 0);
  }

  private sizeColumnsToMoreSuitedWidth(gridApi: GridApi): void {
    const widthToFitGrid = this.getTotalColumnsWidth(gridApi);

    this.autoSizeAllColumns(gridApi);

    const widthWithAutoSizedColumns = this.getTotalColumnsWidth(gridApi);

    if (widthToFitGrid > widthWithAutoSizedColumns) {
      gridApi.sizeColumnsToFit({
        columnLimits: gridApi.getColumnState().map(({colId, width}: ColumnState) => {
          return {key: colId, minWidth: width};
        })
      });
    }
  }

  private addPlaceholderToFloatingFilter(): void {
    Array.from(document.querySelectorAll('.ag-floating-filter-input .ag-input-field-input')).forEach((obj: any) => {
      if (obj.attributes['disabled']) {
        return;
      }
      obj.setAttribute('placeholder', 'Search');
    });
  }

  private addCompactClassToFloatingFilter(): void {
    document.querySelectorAll(`.ag-header-row:has(.${HeaderClass.COMPACT}) + .ag-header-row .ag-floating-filter:not(.compact)`).forEach((floatingFilterElement: HTMLElement) => {
      if (floatingFilterElement.attributes['disabled']) {
        return;
      }

      if (floatingFilterElement.clientHeight < 35) {
        floatingFilterElement.classList.add(HeaderClass.COMPACT);
      }
    });
  }

  private scrollToSavedPosition(gridApi: GridApi): void {
    const savedScrollState = this.getNavigationHelperGridState();

    if (savedScrollState) {
      if (savedScrollState.quickFilter) {
        gridApi.setGridOption('quickFilterText', savedScrollState.quickFilter);
      }
      if (savedScrollState.filterModel) {
        gridApi.setFilterModel(savedScrollState.filterModel);
      }

      const gridBodyElement = document?.querySelector('.mat-mdc-dialog-container')?.querySelector('.ag-body-viewport');
      if (gridBodyElement) {
        gridBodyElement.scrollTop = savedScrollState.top;
      }
    }
  }

  private updateGridContext(gridContext: Record<string, any>): void {
    this.setOption('context', {
      ...this.gridOptions.context,
      ...gridContext
    });
  }

  private automaticFilterChangedSelection(gridApi: GridApi): void {
    if (!this.getOption(gridApi, 'suppressRowClickSelection')) {
      const isMultipleSelectionAllowed = this.getOption(gridApi, 'rowSelection') === AgGridRowSelection.MULTIPLE;
      const renderedNodes = gridApi.getRenderedNodes().filter((node: RowNode) => node.level === 0 && node.displayed);

      if (renderedNodes.length === 1) {
        const node = renderedNodes[0];

        if (node.group && node.childrenAfterFilter.length === 1) {
          node.childrenAfterFilter[0].setSelected(true, !isMultipleSelectionAllowed);
        } else if (!node.group) {
          node.setSelected(true, !isMultipleSelectionAllowed);
        } else if (!isMultipleSelectionAllowed) {
          gridApi.deselectAll();
        }
      } else if (!isMultipleSelectionAllowed) {
        gridApi.deselectAll();
      }
    }
  }

  private automaticFirstDataRenderedSelection(gridApi: GridApi): void {
    if (!this.getOption(gridApi, 'suppressRowClickSelection')) {
      const renderedNodes = gridApi.getRenderedNodes().filter((node: RowNode) => node.level === 0);

      if (renderedNodes.length === 1) {
        const node = renderedNodes[0];

        if (node.group && node.allLeafChildren.length === 1) {
          node.allLeafChildren[0].setSelected(true);
        } else if (!node.group) {
          node.setSelected(true);
        }
      }
    }
  }

  private getOption(api: GridApi, key: keyof GridOptions): any {
    return api?.getGridOption(key) ?? this.gridOptions[key];
  }

  private extendOption(key: any, value: any[]): void {
    const option: any[] = this.getOption(this.gridApi, key);
    if (!AssertionUtils.isNullOrUndefined(option)) {
      value.push(...option);
    }

    this.setOption(key, value);
  }

  private setOption(key: any, value: any): void {
    const listenersThatCantBeOverwritten = [
      'onColumnResized',
      'onColumnPinned',
      'onColumnMoved',
      'onColumnVisible',
      'onSortChanged',
      'onRowSelected',
      'onRowDataUpdated',
      'onFilterChanged',
      'onFirstDataRendered',
      'onBodyScroll',
      'onGridReady',
      'onColumnEverythingChanged'
    ];

    const isListener = listenersThatCantBeOverwritten.includes(key);

    if (isListener) {
      const existingFunction = this.gridApi ? this.gridApi.getGridOption(key) : this.gridOptions[key];

      const combinedFunction = (arg1: any, arg2: any): void => {
        if (existingFunction) {
          existingFunction(arg1, arg2);
        }
        value(arg1, arg2);
      };

      if (this.gridApi) {
        this.gridApi.setGridOption(key, combinedFunction);
      } else {
        this.gridOptions[key] = combinedFunction;
      }
    } else {
      if (this.gridApi && !this.gridApi.isDestroyed()) {
        this.gridApi.setGridOption(key, value);
      } else {
        this.gridOptions[key] = value;
      }
    }
  }
}
