import {HorizontalPositionOfCreelPositionType} from '@domain/textile-data/creel/horizontal-position-of-creel-position-type.enum';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Color} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';

export abstract class HorizontalPositionOfCreelPosition {
  private readonly _type: HorizontalPositionOfCreelPositionType;
  private _amount: number;
  private _isSelected: boolean;
  private _id: string;

  protected constructor(type: HorizontalPositionOfCreelPositionType, amount: number, isSelected: boolean = false) {
    this._type = type;
    this._amount = amount;
    this._isSelected = isSelected;
  }

  public get type(): HorizontalPositionOfCreelPositionType {
    return this._type;
  }

  public get amount(): number {
    return this._amount;
  }

  public set amount(value: number) {
    this._amount = value;
  }

  public get isSelected(): boolean {
    return this._isSelected;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public set isSelected(value: boolean) {
    this._isSelected = value;
  }

  public isColor(): boolean {
    return isEqual(this.type, HorizontalPositionOfCreelPositionType.COLOR);
  }

  public isGroup(): boolean {
    return isEqual(this.type, HorizontalPositionOfCreelPositionType.GROUP);
  }

  public isYarnType(): boolean {
    return isEqual(this.type, HorizontalPositionOfCreelPositionType.YARN_TYPE);
  }

  public abstract getAmountOfDentsInHorizontalPositionOfCreelPosition(): number;

  public abstract getPatternForHorizontalPositionOfCreelPosition(): Color[] | OverviewListYarnType[];
}
