import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class JacquardMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.JACQUARD, name, orderId);
  }

  public static fromJSON(jacquardMachineJSON: any): JacquardMachine {
    return new JacquardMachine(jacquardMachineJSON.id, jacquardMachineJSON.name, jacquardMachineJSON.orderId);
  }
}
