import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {L10nProvider, L10nTranslationLoader} from 'angular-l10n';
import {Observable} from 'rxjs';

@Injectable()
export class TranslationLoader implements L10nTranslationLoader {
  private static readonly headers = new HttpHeaders({'Content-Type': 'application/json'});
  private readonly httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public get(language: string, provider: L10nProvider): Observable<{[key: string]: any}> {
    const url = `${provider.asset}/${language}.json`;
    const options = {
      headers: TranslationLoader.headers,
      params: new HttpParams()
    };
    return this.httpClient.get(url, options);
  }
}
