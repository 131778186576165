import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';

export class ColoredYarnSetForArticle {
  private _id: number;
  private _name: string;
  private _colorSet: ColorSet;

  public constructor(id: number, name: string, colorSet: ColorSet) {
    this._id = id;
    this._name = name;
    this._colorSet = colorSet;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get colorSet(): ColorSet {
    return this._colorSet;
  }

  public static fromJSON(coloredYarnSetForArticleJSON: any): ColoredYarnSetForArticle {
    return new ColoredYarnSetForArticle(coloredYarnSetForArticleJSON.id, coloredYarnSetForArticleJSON.name, coloredYarnSetForArticleJSON.colorSet ?? null);
  }

  public static fromOverviewListColoredYarnSet(overviewListColoredYarnSet: OverviewListColoredYarnSet): ColoredYarnSetForArticle {
    return new ColoredYarnSetForArticle(overviewListColoredYarnSet.id, overviewListColoredYarnSet.name, null);
  }
}
