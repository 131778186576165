import {AlertDialogConfirmationType} from './alert-dialog-data';

export class AlertDialogUtils {
  private static readonly CONFIRMATION_TYPE_TEXTS = new Map<AlertDialogConfirmationType, string>([
    [AlertDialogConfirmationType.CONFIRM, 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.CONFIRM'],
    [AlertDialogConfirmationType.DELETE, 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DELETE_FOREVER'],
    [AlertDialogConfirmationType.DELETE, 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.DISCARD']
  ]);

  public static getButtonTextForConfirmationType(confirmationType: AlertDialogConfirmationType): string {
    return this.CONFIRMATION_TYPE_TEXTS.get(confirmationType);
  }
}
