import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent {
  @Input() public checked = true;
  @Input() public disabled = false;
  @Input() public isInteractable = true;
  @Input() public label = 'ANGULAR_COMPONENT_LIBRARY.SLIDE_TOGGLE.SHOW_LATEST_VERSION';

  @Output() public checkedChange = new EventEmitter<boolean>();
  @Output() public afterCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public toggleButtonClicked(change: MatSlideToggleChange): void {
    this.checkedChange.emit(change.checked);

    setTimeout(() => this.afterCheckedChange.emit(change.checked), 0);
  }

  public hasLabel(): boolean {
    return !AssertionUtils.isEmpty(this.label);
  }
}
