import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class OrderbookRoute extends RouteBranch {
  private _settings: RouteLeaf;

  public get settings(): RouteLeaf {
    return this._settings;
  }

  public set settings(value: RouteLeaf) {
    this.replace(this._settings, value);
    this._settings = value;
  }
}
