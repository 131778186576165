import {Component} from '@angular/core';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {BaseComponent, convertCommercialUnit, Unit} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {round} from 'lodash-es';
import {PathWidthCellRendererParams} from './path-width-cell-renderer-params';

@Component({
  templateUrl: './path-width.component.html'
})
export class PathWidthComponent extends BaseComponent implements ICellRendererAngularComp {
  public commercialWidth = 0;
  public commercialUnit: Unit;
  public productionSchedulePath: ProductionSchedulePath;

  public agInit(params: PathWidthCellRendererParams): void {
    this.productionSchedulePath = params.data;
    this.commercialUnit = params.commercialUnit;

    this.calculateCommercialWidth();
  }

  public refresh(params: any): boolean {
    return false;
  }

  private calculateCommercialWidth(): void {
    const commercialWidthOfPath: number = convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: this.productionSchedulePath.commercialWidthInMM}, to: this.commercialUnit});
    this.commercialWidth = round(commercialWidthOfPath, 2);
  }
}
