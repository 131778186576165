import {Pipe, PipeTransform} from '@angular/core';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';

@Pipe({name: 'weave-structure-column-pipe'})
export class WeaveStructureColumnPipe implements PipeTransform {
  public transform(machineQuality: OverviewListMachineQuality): string {
    if (machineQuality.singleWidth) {
      return `${machineQuality.singleWidth.weaveStructure.name} (v${machineQuality.singleWidth.weaveStructure.version})`;
    } else {
      return `bg: ${machineQuality.doubleWidth.backgroundWeaveStructure.name} (v${machineQuality.doubleWidth.backgroundWeaveStructure.version}), t: ${machineQuality.doubleWidth.topWeaveStructure.name} (v${machineQuality.doubleWidth.topWeaveStructure.version}), b: ${machineQuality.doubleWidth.bottomWeaveStructure.name} (v${machineQuality.doubleWidth.bottomWeaveStructure.version})`;
    }
  }
}
