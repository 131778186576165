import {StringUtils} from '@application/helper/string-utils';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';

export class FinishingForArticle {
  private _id: number;
  private _name: string;
  private _version: number;

  public constructor(id: number, name: string, version: number) {
    this._id = id;
    this._name = name;
    this._version = version;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._name, this._version);
  }

  public static fromJSON(finishingForArticleJSON: any): FinishingForArticle {
    return new FinishingForArticle(finishingForArticleJSON.id, finishingForArticleJSON.name, finishingForArticleJSON.version);
  }

  public static fromOverviewListFinishing(overviewListFinishing: OverviewListFinishing): FinishingForArticle {
    return new FinishingForArticle(overviewListFinishing.id, overviewListFinishing.name, overviewListFinishing.version);
  }
}
