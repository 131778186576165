import {OverviewListYarnTypeStubFactory} from '@application/helper/textile-data/yarn-type/overview-list-yarn-type-stub-factory';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {times} from 'lodash-es';

export class OverviewListYarnSetStubFactory {
  private static readonly numberOfYarnTypes: number = 5;

  public static getRandomYarnSetOverviewListWithId(id: number): OverviewListYarnSet {
    return this.getRandomYarnSetOverviewListWithIdAndNumberOfCreelPositions(id, 4);
  }

  public static getRandomYarnSetOverviewListWithIdAndNumberOfCreelPositions(id: number, numberOfCreelPositions: number): OverviewListYarnSet {
    return new OverviewListYarnSet(
      id,
      `Yarn set ${id}`,
      times(numberOfCreelPositions, (creelPositionIndex: number) => {
        return new OverviewListCreelPosition(
          times(OverviewListYarnSetStubFactory.numberOfYarnTypes, (yarnTypeIndex: number) => {
            return OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(OverviewListYarnSetStubFactory.numberOfYarnTypes * creelPositionIndex + yarnTypeIndex + 1);
          }),
          creelPositionIndex + 1
        );
      })
    );
  }
}
