import {Location} from '@angular/common';
import {Injectable, OnDestroy} from '@angular/core';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {AssertionUtils} from '@vdw/angular-component-library';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {NavigationHistory} from './navigation-history.interface';

@Injectable({providedIn: 'root'})
export class NavigationHistoryService implements NavigationHistory, OnDestroy {
  private previousUrl: string;
  private currentUrl: string;
  private _stateUpdates = new BehaviorSubject<unknown>(this.getState());

  private readonly router: Router;

  public get stateUpdates(): Observable<unknown> {
    return this._stateUpdates.asObservable();
  }

  public constructor(
    router: Router,
    private readonly location: Location
  ) {
    this.router = router;
    this.location = location;
  }

  public subscribeToRouterEvents(): void {
    this.router.events.pipe(filter((event: RouterEvent | Event): boolean => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this._stateUpdates.next(this.getState());
    });
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public replaceState(path: string, query?: string, state?: any, navigate: boolean = true): void {
    this.currentUrl = path;

    if (navigate) {
      const navigateExtras = AssertionUtils.isNullOrUndefined(state) ? {replaceUrl: true} : {replaceUrl: true, ...state};
      this.router.navigate([path], navigateExtras);
    }
    this.location.replaceState(path, query, state);

    this._stateUpdates.next(this.getState());
  }

  public getState(): unknown {
    return this.location.getState();
  }

  public ngOnDestroy(): void {
    this._stateUpdates.complete();
  }
}
