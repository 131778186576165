import {Color} from './color';

export class ColorStubFactory {
  public static getRandomColor(): Color {
    return this.getRandomColorWithId(1);
  }

  public static getRandomColorWithId(id: number): Color {
    return this.getRandomColorWithIdAndColorCode(id, '#000000');
  }

  public static getRandomColorWithIdAndName(id: number, name: string): Color {
    return this.getRandomColorWithIdNameAndColorCode(id, name, '#000000');
  }

  public static getRandomColorWithIdAndColorCode(id: number, colorCode: string): Color {
    return this.getRandomColorWithIdNameAndColorCode(id, `COLOR_${id}`, colorCode);
  }

  public static getRandomColorWithIdNameAndColorCode(id: number, name: string, colorCode: string): Color {
    return new Color(
      id,
      name,
      {
        red: parseInt(colorCode.substring(1, 3), 16),
        green: parseInt(colorCode.substring(3, 5), 16),
        blue: parseInt(colorCode.substring(5, 7), 16)
      },
      false,
      'tomato',
      'a tomato description'
    );
  }
}
