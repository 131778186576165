import {IdName} from '@domain/id-name';
import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';

export class RunCarpet extends PlanningItem {
  private _quality?: IdName;
  private _creel?: IdName;
  private _creelMappingFormula?: string;

  public constructor(itemProperties: PlanningItemProperties, quality?: IdName, creel?: IdName, creelMappingFormula: string = null, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.RUN_CARPET, ...planningItems);
    this._quality = quality;
    this._creel = creel;
    this._creelMappingFormula = creelMappingFormula;
  }

  public get quality(): IdName {
    return this._quality;
  }

  public set quality(value: IdName) {
    this._quality = value;
  }

  public get creel(): IdName {
    return this._creel;
  }

  public set creel(value: IdName) {
    this._creel = value;
  }

  public get creelMappingFormula(): string {
    return this._creelMappingFormula;
  }

  public set creelMappingFormula(value: string) {
    this._creelMappingFormula = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      qualityId: this.quality?.id,
      creelId: this.creel?.id,
      creelMappingFormula: this.creelMappingFormula
    } as any as JSON;
  }
}
