import {ProductionOrder} from '../carpet/production-order';
import {ProductionOrderPlastic} from '../plastic/production-order-plastic';
import {ProductionOrderTuft} from '../tufting/production-order-tuft';
import {ProductionOrderWeaving} from '../weaving/production-order-weaving';
import {CompatibleMachine} from './compatible-machine';
import {GenericProductionOrder} from './generic-production-order.interface';
import {ProductionOrderType} from './production-order-type';

export class ProductionOrderToPlan {
  private _productionOrder: GenericProductionOrder;
  private _compatibleMachines: CompatibleMachine[];
  private _type: ProductionOrderType;

  public constructor(productionOrder: GenericProductionOrder, compatibleMachines: CompatibleMachine[]) {
    this._productionOrder = productionOrder;
    this._compatibleMachines = compatibleMachines;
    this._type = ProductionOrderToPlan.getType(productionOrder);
  }

  public get productionOrder(): GenericProductionOrder {
    return this._productionOrder;
  }

  public get compatibleMachines(): readonly CompatibleMachine[] {
    return this._compatibleMachines;
  }

  public get type(): ProductionOrderType {
    return this._type;
  }

  public static fromJSON(json: any): ProductionOrderToPlan {
    const type = json.type as ProductionOrderType;
    let productionOrder: GenericProductionOrder;

    switch (type) {
      case ProductionOrderType.CARPET:
        productionOrder = ProductionOrder.fromJSON(json.productionOrder);
        break;
      case ProductionOrderType.WEAVING:
        productionOrder = ProductionOrderWeaving.fromJSON(json.productionOrder);
        break;
      case ProductionOrderType.TUFTING:
        productionOrder = ProductionOrderTuft.fromJSON(json.productionOrder);
        break;
      case ProductionOrderType.PLASTIC:
        productionOrder = ProductionOrderPlastic.fromJSON(json.productionOrder);
        break;
      default:
        break;
    }

    return new ProductionOrderToPlan(productionOrder, json.compatibleMachines.map(CompatibleMachine.fromJSON));
  }

  public static getType(productionOrder: GenericProductionOrder): ProductionOrderType {
    if (productionOrder instanceof ProductionOrder) {
      return ProductionOrderType.CARPET;
    } else if (productionOrder instanceof ProductionOrderWeaving) {
      return ProductionOrderType.WEAVING;
    } else if (productionOrder instanceof ProductionOrderTuft) {
      return ProductionOrderType.TUFTING;
    } else if (productionOrder instanceof ProductionOrderPlastic) {
      return ProductionOrderType.PLASTIC;
    } else {
      throw new Error('Invalid production order type');
    }
  }
}
