import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ObjectUtils} from '@vdw/angular-component-library';
import {WeaveProduct} from './weave-product';

export class ProductionOrderWeaving {
  private _id: number;
  private _name: string;
  private _weaveProduct: WeaveProduct;
  private _status: ProductionScheduleStatus;
  private _plannedQuantityInMeters: number;
  private _dueDate?: Date;

  public constructor(id: number, name: string, weaveProduct: WeaveProduct, status: ProductionScheduleStatus, plannedQuantityInMeters: number, dueDate?: Date) {
    this._id = id;
    this._name = name;
    this._weaveProduct = weaveProduct;
    this._status = status;
    this._plannedQuantityInMeters = plannedQuantityInMeters;
    this._dueDate = dueDate;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get weaveProduct(): WeaveProduct {
    return this._weaveProduct;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public get plannedQuantityInMeters(): number {
    return this._plannedQuantityInMeters;
  }

  public static fromJSON(json: any): ProductionOrderWeaving {
    return new ProductionOrderWeaving(
      json.id,
      json.name,
      ObjectUtils.parseNullable(json.weaveProduct, WeaveProduct.fromJSON),
      json.status,
      json.plannedQuantityInMeters,
      ObjectUtils.parseNullable(json.dueDate, (dueDate: any) => new Date(dueDate))
    );
  }
}
