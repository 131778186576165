import {CreelPosition} from '@domain/textile-data/creel/creel-position';

export abstract class MaterialSet {
  private _id: number;
  private _name: string;
  private _creelPositions: CreelPosition[];
  private _used: boolean;
  private _technicalCode: string;
  private _alternativeColorSetIds: number[];

  protected constructor(id: number, name: string, creelPositions: CreelPosition[], used: boolean, technicalCode: string = '', alternativeColorSetIds?: number[]) {
    this._id = id;
    this._name = name;
    this._creelPositions = creelPositions;
    this._used = used;
    this._technicalCode = technicalCode;
    this._alternativeColorSetIds = alternativeColorSetIds;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get creelPositions(): CreelPosition[] {
    return this._creelPositions;
  }

  public set creelPositions(value: CreelPosition[]) {
    this._creelPositions = value;
  }

  public get used(): boolean {
    return this._used;
  }

  public get technicalCode(): string {
    return this._technicalCode;
  }

  public set technicalCode(value: string) {
    this._technicalCode = value;
  }

  public get alternativeColorSetIds(): number[] {
    return this._alternativeColorSetIds;
  }

  public set alternativeColorSetIds(value: number[]) {
    this._alternativeColorSetIds = value;
  }

  public abstract isColorSet(): boolean;

  public abstract isYarnSet(): boolean;
}
