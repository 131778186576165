import {InjectionToken} from '@angular/core';
import {CommercialMachineQuality} from '@domain/textile-data/machine-quality/commercial-machine-quality';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {CustomSettingsObjectRepository} from '@infrastructure/http/custom-settings-object-repository';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {GetSliceRepository} from '../get-slice-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const MACHINE_QUALITIES = new InjectionToken<MachineQualities>('MachineQualities');

export interface MachineQualities
  extends Repository<WeaveQuality, OverviewListMachineQuality>,
    UniqueIdentifierObjectRepository,
    CustomSettingsObjectRepository,
    ConflictsRepository,
    NameGenerationRepository<WeaveQuality>,
    GetSliceRepository<OverviewListMachineQuality> {
  getSlice(gridModel: GridModel, parameters?: Partial<GetAllMachineQualitiesParameters>): Observable<OverviewListMachineQuality[]>;

  getAll(parameters?: Partial<GetAllMachineQualitiesParameters>): Observable<OverviewListMachineQuality[]>;

  getSuggestionForWeftDensity(weaveStructureId: number, pickDensity: number): Observable<number>;

  getFactorForConversionFromMillimetersToPicks(machineQualityId: number): Observable<number>;

  getFactorForConversionFromPicksToMillimeters(machineQualityId: number): Observable<number>;

  getFactorForConversionFromMillimetersToDents(machineQualityId: number): Observable<number>;

  getFactorForConversionFromDentsToMillimeters(machineQualityId: number): Observable<number>;

  getPathWidths(machineQualityId: number): Observable<PathWidth[]>;

  getListOfCommercialMachineQualities(): Observable<CommercialMachineQuality[]>;

  getActiveMachineQualityForMachine(id: number): Observable<WeaveQuality>;

  getPossibleMachineQualitiesForMachine(id: number, parameters?: Partial<GetAllMachineQualitiesParameters>): Observable<WeaveQuality[]>;

  getListOfConditionPatterns(): Observable<string[]>;

  getListOfPatternFields(): Observable<string[]>;

  getPossibleValues(columnIdentifier: string): Observable<string[] | number[]>;

  updateOrCreateNewVersion(weaveQuality: WeaveQuality): Observable<number>;
}

export interface GetAllMachineQualitiesParameters {
  showOnlyLatestVersion: boolean;
  numberOfCreelPositions: number;
}
