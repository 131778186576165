import {FinishingTemplateLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-label';
import {FinishingTemplatePositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-position-in-carpet';
import {LabelPositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing/label-position-in-carpet.enum';
import {LabelPositionInLabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-position-in-label-place.enum';
import {ImageForFinishingImageLabel} from '../image-for-finishing-image-label';
import {LabelPlace} from '../label-place.enum';

export class FinishingTemplateImageLabel extends FinishingTemplateLabel {
  protected _label: string;
  private _image: ImageForFinishingImageLabel;

  public constructor(
    id: number,
    place: LabelPlace,
    image: ImageForFinishingImageLabel,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingTemplatePositionInCarpet,
    positionInBottomCarpet: FinishingTemplatePositionInCarpet
  ) {
    super(id, place, rotation, mirrored, positionInTopCarpet, positionInBottomCarpet);

    this._image = image;
    this._label = JSON.stringify(this.getLabel());
  }

  public get image(): ImageForFinishingImageLabel {
    return this._image;
  }

  public static fromJSON(finishingTemplateImageLabelJSON: any): FinishingTemplateImageLabel {
    return new FinishingTemplateImageLabel(
      finishingTemplateImageLabelJSON.id,
      finishingTemplateImageLabelJSON.place as LabelPlace,
      ImageForFinishingImageLabel.fromJSON(finishingTemplateImageLabelJSON.image),
      finishingTemplateImageLabelJSON.rotation,
      finishingTemplateImageLabelJSON.mirrored,
      new FinishingTemplatePositionInCarpet(
        finishingTemplateImageLabelJSON.topCarpet.positioningInCarpet as LabelPositionInCarpet,
        finishingTemplateImageLabelJSON.topCarpet.positioningInLabelPlace as LabelPositionInLabelPlace,
        finishingTemplateImageLabelJSON.topCarpet.commercialHorizontalOffsetInMM,
        finishingTemplateImageLabelJSON.topCarpet.commercialVerticalOffsetInMM
      ),
      new FinishingTemplatePositionInCarpet(
        finishingTemplateImageLabelJSON.bottomCarpet.positioningInCarpet as LabelPositionInCarpet,
        finishingTemplateImageLabelJSON.bottomCarpet.positioningInLabelPlace as LabelPositionInLabelPlace,
        finishingTemplateImageLabelJSON.bottomCarpet.commercialHorizontalOffsetInMM,
        finishingTemplateImageLabelJSON.bottomCarpet.commercialVerticalOffsetInMM
      )
    );
  }

  public toJSON(): JSON {
    return this.getLabel() as any as JSON;
  }

  private getLabel(): object {
    return {
      finishingLabelType: 'IMAGE',
      place: this.place,
      imageId: this.image.id,
      rotation: this.rotation,
      mirrored: this.mirrored,
      topCarpet: this.positionInTopCarpet.toJSON(),
      bottomCarpet: this.positionInBottomCarpet.toJSON()
    };
  }
}
