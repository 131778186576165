import {StringUtils} from '@application/helper/string-utils';
import {AbstractFinishing} from '@domain/textile-data/finishing-and-finishing-template/abstract-finishing';
import {BackcoatingInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/backcoating-information';
import {FinishingImageLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-image-label';
import {FinishingLabelType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label-type.enum';
import {FinishingTextLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-text-label';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {MachineQualityForFinishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/machine-quality-for-finishing';
import {AssertionUtils} from '@vdw/angular-component-library';
import {isEmpty, isEqual, isNil, map} from 'lodash-es';
import {FinishingCutbarProperties} from './finishing-cutbar-properties';
import {FinishingCutbarsInformation} from './finishing-cutbars-information';
import {FinishingLabel} from './finishing-label';

export class Finishing extends AbstractFinishing {
  private _version: number;
  private _machineQuality: MachineQualityForFinishing;
  private _finishingTemplateId: number;
  private _cutbarsInformation: FinishingCutbarsInformation;
  private _labels: FinishingLabel[];
  private _finishingTemplateName: string;
  private _finishing: string;

  public constructor(
    id: number | string,
    name: string,
    version: number,
    finishingType: FinishingType,
    machineQuality: MachineQualityForFinishing,
    finishingTemplateId: number,
    cutbarsInformation: FinishingCutbarsInformation,
    labels: FinishingLabel[],
    backcoatingInformation: BackcoatingInformation,
    used: boolean,
    finishingTemplateName?: string
  ) {
    super(id, name, finishingType, backcoatingInformation, used);
    this._version = version;
    this._machineQuality = machineQuality;
    this._finishingTemplateId = finishingTemplateId;
    this._cutbarsInformation = cutbarsInformation;
    this._labels = labels;
    this._finishingTemplateName = finishingTemplateName;
    this._finishing = JSON.stringify({
      finishingType: this.finishingType,
      cutbarsInformation: this.cutbarsInformation ? this.cutbarsInformation.toJSON() : null,
      weightInGramsPerSquareMeter: this.backcoatingInformation?.weight ?? null
    });
  }

  public get version(): number {
    return this._version;
  }

  public set version(version: number) {
    this._version = version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.name, this.version);
  }

  public get machineQuality(): MachineQualityForFinishing {
    return this._machineQuality;
  }

  public set machineQuality(value: MachineQualityForFinishing) {
    this._machineQuality = value;
  }

  public get finishingTemplateId(): number {
    return this._finishingTemplateId;
  }

  public get cutbarsInformation(): FinishingCutbarsInformation {
    return this._cutbarsInformation;
  }

  public set cutbarsInformation(cutbarsInformation: FinishingCutbarsInformation) {
    this._cutbarsInformation = cutbarsInformation;
  }

  public get labels(): FinishingLabel[] {
    return this._labels;
  }

  public get finishingTemplateName(): string {
    return this._finishingTemplateName;
  }

  public static createEmptyFinishing(): Finishing {
    return new Finishing(null, null, null, null, null, null, null, [], null, null, null);
  }

  public static fromJSON(finishingJSON: any): Finishing {
    return new Finishing(
      finishingJSON.id,
      finishingJSON.name,
      finishingJSON.version,
      finishingJSON.finishingType,
      MachineQualityForFinishing.fromJSON(finishingJSON.machineQuality),
      finishingJSON.finishingTemplateId,
      FinishingCutbarsInformation.fromJSON(finishingJSON.cutbarsInformation),
      map(finishingJSON.labels, (finishingLabelJSON: any) => {
        return isEqual(finishingLabelJSON.finishingLabelType, FinishingLabelType.TEXT) ? FinishingTextLabel.fromJSON(finishingLabelJSON) : FinishingImageLabel.fromJSON(finishingLabelJSON);
      }),
      BackcoatingInformation.fromJSON(finishingJSON.backcoatingInformation),
      finishingJSON.used,
      finishingJSON.finishingTemplateName ?? null
    );
  }

  public hasAddedCutbarsInformation(): boolean {
    return !isNil(this._cutbarsInformation);
  }

  public hasAddedCutbarBefore(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCutbarBefore();
  }

  public hasAddedCutbarAfter(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCutbarAfter();
  }

  public hasAddedLabels(): boolean {
    return !isEmpty(this.labels);
  }

  public hasAddedCuttingLine(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCuttingLine();
  }

  public canDuplicate(): boolean {
    return true;
  }

  public toJSON(nullableCutbarProperties: boolean = true): JSON {
    if (!nullableCutbarProperties && (AssertionUtils.isNullOrUndefined(this.cutbarsInformation) || !this.cutbarsInformation.hasAddedCutbarBefore() || !this.cutbarsInformation.hasAddedCutbarAfter())) {
      const cutbarBeforeProperties = this.cutbarsInformation?.hasAddedCutbarBefore() ? this.cutbarsInformation.cutbarBeforeProperties : new FinishingCutbarProperties(null, null, null);
      const cutbarAfterProperties = this.cutbarsInformation?.hasAddedCutbarAfter() ? this.cutbarsInformation.cutbarAfterProperties : new FinishingCutbarProperties(null, null, null);
      this.cutbarsInformation = new FinishingCutbarsInformation(
        cutbarBeforeProperties,
        cutbarAfterProperties,
        this.cutbarsInformation?.cuttingLineProperties ?? null,
        this.cutbarsInformation?.foldingType ?? null
      );
    }

    return {
      id: typeof this.id === 'number' ? this.id : undefined,
      name: this.name,
      version: this.version ?? 1,
      finishingType: this.finishingType,
      machineQualityId: this.machineQuality?.id,
      finishingTemplateId: this.finishingTemplateId ?? 0,
      finishingTemplateName: this.finishingTemplateName,
      cutbarsInformation: isNil(this.cutbarsInformation) ? this.cutbarsInformation : this.cutbarsInformation.toJSON(),
      labels: map(this.labels, (label: FinishingLabel) => {
        return label.toJSON();
      }),
      backcoatingInformation: {
        useBackcoating: this.backcoatingInformation.useBackcoating,
        weightInGramsPerSquareMeter: this.backcoatingInformation.useBackcoating ? this.backcoatingInformation.weight : null
      },
      used: this.used
    } as any as JSON;
  }

  public static isEqual(finishing1: Finishing, finishing2: Finishing): boolean {
    if (finishing1._finishing !== finishing2._finishing || finishing1._labels.length !== finishing2._labels.length) {
      return false;
    }

    let areLabelsEqual = true;
    if (finishing1._labels.length > 0) {
      finishing1._labels.forEach((currentFinishingLabel: FinishingLabel) => {
        if (finishing2._labels.findIndex((finishingLabel: FinishingLabel) => finishingLabel.label === currentFinishingLabel.label) === -1) {
          areLabelsEqual = false;
        }
      });
    }

    return areLabelsEqual;
  }
}
