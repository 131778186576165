import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {OverviewListWeavingMachine} from '@presentation/pages/texfab/production-order-lite/overview/overview-list-weaving-machine';
import moment from 'moment';

export class ProductionOrderLite {
  private _id: number;
  private _name: string;
  private _machine: OverviewListWeavingMachine;
  private _plannedLength: number;
  private _dateCreated: Date;
  private _dueDate: Date;
  private _status: ProductionScheduleStatus;

  public constructor(id: number, name: string, machine: OverviewListWeavingMachine, plannedLength: number, dateCreated: Date, dueDate: Date, status: ProductionScheduleStatus) {
    this._id = id;
    this._name = name;
    this._machine = machine;
    this._plannedLength = plannedLength;
    this._dateCreated = dateCreated;
    this._dueDate = dueDate;
    this._status = status;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get machine(): OverviewListWeavingMachine {
    return this._machine;
  }

  public set machine(value: OverviewListWeavingMachine) {
    this._machine = value;
  }

  public get plannedLength(): number {
    return this._plannedLength;
  }

  public set plannedLength(value: number) {
    this._plannedLength = value;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public set dateCreated(value: Date) {
    this._dateCreated = value;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public set dueDate(value: Date) {
    this._dueDate = value;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public static fromJSON(overviewListWeaveProductionOrderJSON: any): ProductionOrderLite {
    return new ProductionOrderLite(
      overviewListWeaveProductionOrderJSON.id,
      overviewListWeaveProductionOrderJSON.name,
      overviewListWeaveProductionOrderJSON.machine ? OverviewListWeavingMachine.fromJSON(overviewListWeaveProductionOrderJSON.machine) : null,
      overviewListWeaveProductionOrderJSON.plannedQuantity,
      new Date(overviewListWeaveProductionOrderJSON.creationDate),
      overviewListWeaveProductionOrderJSON.dueDate ? new Date(overviewListWeaveProductionOrderJSON.dueDate) : null,
      overviewListWeaveProductionOrderJSON.status
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      machineId: this._machine?.id,
      machineName: this._machine?.name,
      plannedQuantity: this._plannedLength,
      creationDate: this._dateCreated ? this._dateCreated.toISOString() : new Date().toISOString(),
      dueDate: moment(this._dueDate).startOf('day').subtract(new Date().getTimezoneOffset(), 'minutes').toISOString()
    } as any as JSON;
  }
}
