import {Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[appSvgLoader]'
})
export class SvgLoaderDirective implements OnChanges {
  @Input() public appSvgLoader: string;
  private element: ElementRef;

  public constructor(element: ElementRef) {
    this.element = element;
  }

  public ngOnChanges(values: any): void {
    if (values.appSvgLoader.currentValue) {
      this.element.nativeElement.innerHTML = values.appSvgLoader.currentValue;
    }
  }
}
