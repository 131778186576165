import {BaseMachineType} from '@domain/machine/base-machine-type';
import {Gauge} from '@domain/machine/gauge';
import {EquipmentManufacturer} from './equipment-manufacturer';

export class TuftingMachineType extends BaseMachineType {
  private _width: number;
  private _gauge: Gauge;

  public constructor(id: number, name: string, width: number, gauge: Gauge, manufacturer: EquipmentManufacturer, model: string) {
    super(id, name, manufacturer, model);
    this._width = width;
    this._gauge = gauge;
  }

  public static fromJSON(tuftingMachineTypeJSON: any): TuftingMachineType {
    return new TuftingMachineType(
      tuftingMachineTypeJSON.id,
      tuftingMachineTypeJSON.name,
      tuftingMachineTypeJSON.widthInCm,
      new Gauge(tuftingMachineTypeJSON.gaugeId, tuftingMachineTypeJSON.gaugeName),
      new EquipmentManufacturer(tuftingMachineTypeJSON.manufacturerId, tuftingMachineTypeJSON.manufacturerName, []),
      tuftingMachineTypeJSON.model
    );
  }

  public toJSON(): JSON {
    const machineTypeJSON = super.toJSON();
    machineTypeJSON['widthInCm'] = this.width;
    machineTypeJSON['gaugeId'] = this.gauge?.id;
    return machineTypeJSON;
  }

  public get width(): number {
    return this._width;
  }

  public set width(width: number) {
    this._width = width;
  }

  public get gauge(): Gauge {
    return this._gauge;
  }

  public set gauge(gauge: Gauge) {
    this._gauge = gauge;
  }
}
