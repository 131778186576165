import {Pattern} from '@domain/pattern';
import {PathLabelInformationForPart} from '@domain/production-schedule/path-label-information-for-part';
import {FontSettings} from '@domain/textile-data/finishing-and-finishing-template/finishing/font-settings';
import {isNil} from 'lodash-es';

export class PathLabelInformation {
  private _pathLabelBefore: PathLabelInformationForPart;
  private _pathLabelAfter: PathLabelInformationForPart;
  private _fontSettings: FontSettings;
  private _labelHeightInMM: number;
  private _labelHeightInPicks: number;
  private _labelFontSizeInMM: number;
  private _pattern: Pattern;

  public constructor(
    pathLabelBefore: PathLabelInformationForPart,
    pathLabelAfter: PathLabelInformationForPart,
    fontSettings: FontSettings,
    labelHeightInMM: number,
    labelHeightInPicks: number,
    labelFontSizeInMM: number,
    pattern: Pattern
  ) {
    this._pathLabelBefore = pathLabelBefore;
    this._pathLabelAfter = pathLabelAfter;
    this._fontSettings = fontSettings;
    this._labelHeightInPicks = labelHeightInPicks;
    this._labelHeightInMM = labelHeightInMM;
    this._labelFontSizeInMM = labelFontSizeInMM;
    this._pattern = pattern;
  }

  public get pathLabelBefore(): PathLabelInformationForPart {
    return this._pathLabelBefore;
  }

  public set pathLabelBefore(value: PathLabelInformationForPart) {
    this._pathLabelBefore = value;
  }

  public get pathLabelAfter(): PathLabelInformationForPart {
    return this._pathLabelAfter;
  }

  public set pathLabelAfter(value: PathLabelInformationForPart) {
    this._pathLabelAfter = value;
  }

  public get fontSettings(): FontSettings {
    return this._fontSettings;
  }

  public set fontSettings(fontSettings: FontSettings) {
    this._fontSettings = fontSettings;
  }

  public get labelHeightInMM(): number {
    return this._labelHeightInMM;
  }

  public set labelHeightInMM(labelHeightInMM: number) {
    this._labelHeightInMM = labelHeightInMM;
  }

  public get labelHeightInPicks(): number {
    return this._labelHeightInPicks;
  }

  public set labelHeightInPicks(labelHeightInPicks: number) {
    this._labelHeightInPicks = labelHeightInPicks;
  }

  public get labelFontSizeInMM(): number {
    return this._labelFontSizeInMM;
  }

  public set labelFontSizeInMM(labelFontSizeInMM: number) {
    this._labelFontSizeInMM = labelFontSizeInMM;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public static fromJSON(pathLabelInformationJSON: any): PathLabelInformation {
    return isNil(pathLabelInformationJSON)
      ? null
      : new PathLabelInformation(
          PathLabelInformationForPart.fromJSON(pathLabelInformationJSON.pathLabelBefore),
          PathLabelInformationForPart.fromJSON(pathLabelInformationJSON.pathLabelAfter),
          FontSettings.fromPathLabelInformationJSON(pathLabelInformationJSON),
          pathLabelInformationJSON.commercialLabelHeightInMM,
          pathLabelInformationJSON.technicalLabelHeightInPicks,
          pathLabelInformationJSON.commercialLabelFontSizeInMM,
          Pattern.fromJSON(pathLabelInformationJSON.pattern)
        );
  }

  public hasAddedPathLabelBefore(): boolean {
    return !isNil(this.pathLabelBefore);
  }

  public hasAddedPathLabelAfter(): boolean {
    return !isNil(this.pathLabelAfter);
  }

  public toJSON(): JSON {
    let result = null;

    if (this.hasAddedPathLabelBefore() || this.hasAddedPathLabelAfter()) {
      result = {
        pathLabelBefore: this.hasAddedPathLabelBefore() ? this._pathLabelBefore.toJSON() : null,
        pathLabelAfter: this.hasAddedPathLabelAfter() ? this._pathLabelAfter.toJSON() : null,
        commercialLabelFontSizeInMM: this._labelFontSizeInMM,
        technicalLabelHeightInPicks: this._labelHeightInPicks,
        commercialLabelHeightInMM: this._labelHeightInMM,
        pattern: this._pattern?.toJSON() ?? null,
        ...this._fontSettings.toJSON()
      } as any as JSON;
    }

    return result;
  }
}
