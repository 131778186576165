import {Pattern} from '@domain/pattern';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';

export class GeneralJuteSettings {
  private _addJuteSoPathsHaveEqualHeight: boolean;
  private _pattern: Pattern;
  private _priorityPerBetweenCondition: boolean;
  private _borderColorIndex: Pattern;
  private _borderIndexColorToggle: boolean;
  private _repeatLastDesignToEndOfPath: boolean;
  private _addConditionalDesigns: boolean;

  public constructor(
    addJuteSoPathsHaveEqualHeight: boolean,
    pattern: Pattern,
    priorityPerBetweenCondition: boolean,
    borderColorIndex: Pattern,
    borderIndexColorToggle: boolean,
    repeatLastDesignToEndOfPath: boolean,
    addConditionalDesigns: boolean
  ) {
    this._addJuteSoPathsHaveEqualHeight = addJuteSoPathsHaveEqualHeight;
    this._pattern = pattern;
    this._priorityPerBetweenCondition = priorityPerBetweenCondition;
    this._borderColorIndex = borderColorIndex;
    this._borderIndexColorToggle = borderIndexColorToggle;
    this._repeatLastDesignToEndOfPath = repeatLastDesignToEndOfPath;
    this._addConditionalDesigns = addConditionalDesigns;
  }

  public get addJuteSoPathsHaveEqualHeight(): boolean {
    return this._addJuteSoPathsHaveEqualHeight;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public get priorityPerBetweenCondition(): boolean {
    return this._priorityPerBetweenCondition;
  }

  public get borderColorIndex(): number | Pattern {
    return this._borderColorIndex;
  }

  public get borderIndexColorToggle(): boolean {
    return this._borderIndexColorToggle;
  }

  public get repeatLastDesignToEndOfPath(): boolean {
    return this._repeatLastDesignToEndOfPath;
  }

  public get addConditionalDesigns(): boolean {
    return this._addConditionalDesigns;
  }

  public static fromJSON(generalJuteSettings: any): GeneralJuteSettings {
    return new GeneralJuteSettings(
      generalJuteSettings.addJuteSoPathsHaveEqualHeight,
      Pattern.fromJSON(generalJuteSettings.pattern),
      generalJuteSettings.priorityPerBetweenCondition,
      generalJuteSettings.borderColorIndex ? Pattern.fromJSON(generalJuteSettings.borderColorIndex) : null,
      generalJuteSettings.borderIndexColorToggle,
      generalJuteSettings.repeatLastDesignToEndOfPath,
      generalJuteSettings.addConditionalDesigns
    );
  }

  public toJSON(): JSON {
    return {
      addJuteSoPathsHaveEqualHeight: this._addJuteSoPathsHaveEqualHeight,
      pattern: this._pattern
        ? {
            id: this._pattern.id,
            type: this._pattern.visualisations.length > 0 ? PatternVisualisationType[this._pattern.visualisations[0].type] : null
          }
        : null,
      priorityPerBetweenCondition: this._priorityPerBetweenCondition,
      borderColorIndex: this._borderColorIndex?.id ?? null,
      borderIndexColorToggle: this._borderIndexColorToggle,
      repeatLastDesignToEndOfPath: this._repeatLastDesignToEndOfPath,
      addConditionalDesigns: this._addConditionalDesigns
    } as any as JSON;
  }
}
