import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';

export class PlanningTuftProduct {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(jsonData: any): PlanningTuftProduct {
    return new PlanningTuftProduct(jsonData.id, jsonData.name);
  }

  public static fromOverviewListTuftProduct(tuftProduct: OverviewListTuftProduct): PlanningTuftProduct {
    return new PlanningTuftProduct(tuftProduct.id, tuftProduct.name);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name
    } as any as JSON;
  }
}
