export class BidirectionalMap<K, V> {
  private forwardMap = new Map<K, V>();
  private reverseMap = new Map<V, K>();

  public constructor(entries: [K, V][]) {
    for (const [key, value] of entries) {
      this.setMaps(key, value);
    }
  }

  public getValue(key: K): V | undefined {
    return this.forwardMap.get(key);
  }

  public getKey(value: V): K | undefined {
    return this.reverseMap.get(value);
  }

  private setMaps(key: K, value: V): void {
    this.forwardMap.set(key, value);
    this.reverseMap.set(value, key);
  }
}
