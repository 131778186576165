import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class TexBotMachineDocumentationRoute extends RouteBranch {
  private _details: RouteLeaf;

  public get details(): RouteLeaf {
    return this._details;
  }

  public set details(value: RouteLeaf) {
    this.replace(this._details, value);
    this._details = value;
  }
}
