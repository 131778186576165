/* eslint-disable brace-style */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PropertyValue} from '@domain/property-value';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {map, Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpPathLayoutTemplatesService
  implements Repository<PathLayoutTemplate, PathLayoutTemplate>, UniqueIdentifierObjectRepository, CustomSettingsObjectRepository, NameGenerationRepository<PathLayoutTemplate>
{
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<PathLayoutTemplate[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates`)
      .pipe(map((pathLayoutTemplatesJSON: any) => pathLayoutTemplatesJSON.map((pathLayoutTemplateJSON: any) => PathLayoutTemplate.fromJSON(pathLayoutTemplateJSON))));
  }

  public delete(id: number | string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/${id}`);
  }

  public deleteMulti(ids: number[]): Observable<MultiDeleteResponse[]> {
    return this.httpClient.delete(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/multi-delete`, {body: ids}).pipe(
      map((reponsesJSON: any) => {
        return reponsesJSON.map((reponseJSON: any) => {
          return MultiDeleteResponse.fromJSON(reponseJSON);
        });
      })
    );
  }

  public getById(id: number): Observable<PathLayoutTemplate> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/${id}`).pipe(map((response: any) => PathLayoutTemplate.fromJSON(response)));
  }

  public save(item: PathLayoutTemplate): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: PathLayoutTemplate): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(identifier: string): Observable<boolean> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/check-name?name=${identifier}`)
      .pipe(map((response: {isAvailable: boolean}) => response.isAvailable));
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get<PropertyValue[]>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/custom-settings`);
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/custom-settings/already-adjusted`).pipe(
      map((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/custom-settings`, chosenSettings);
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/custom-settings/name-generation-properties`);
  }

  public generateName(item: PathLayoutTemplate): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getProductionOrderEndpoint()}path-layout-templates/custom-settings/generate-name`, item.toJSON());
  }
}
