import {AssertionUtils} from '@vdw/angular-component-library';
import {ColorPatternVisualisation} from './color-pattern-visualisation';
import {DrawingPatternVisualisation} from './drawing-pattern-visualisation';
import {ImagePatternVisualisation} from './image-pattern-visualisation';
import {PatternVisualisation} from './pattern-visualisation';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';
import {SvgManchonVisualisation} from './svg-manchon-visualisation';
import {SvgPatternVisualisation} from './svg-pattern-visualisation';

export class Pattern {
  private readonly _id: number;
  private _name: string;
  private _visualisations: PatternVisualisation[];
  private readonly _type: string;
  private readonly _comment: string;
  private readonly _heightInPixels: number;
  private readonly _widthInPixels: number;
  private readonly _sizeInBytes: number;
  private readonly _dateCreated: Date;
  private readonly _xSizeInDents: number;
  private readonly _xSizeInFrames: number;
  private readonly _ySizeInColorlines: number;
  private readonly _ySizeInPicks: number;
  private readonly _weavingChores: number[];

  public constructor(
    id: number,
    name: string,
    visualisations: PatternVisualisation[],
    type?: string,
    comment?: string,
    heightInPixels?: number,
    widthInPixels?: number,
    sizeInBytes?: number,
    dateCreated?: Date,
    xSizeInDents?: number,
    xSizeInFrames?: number,
    ySizeInColorlines?: number,
    ySizeInPicks?: number,
    weavingChores?: number[]
  ) {
    this._id = id;
    this._name = name;
    this._visualisations = visualisations;
    this._type = type;
    this._comment = comment;
    this._heightInPixels = heightInPixels;
    this._widthInPixels = widthInPixels;
    this._sizeInBytes = sizeInBytes;
    this._dateCreated = dateCreated;
    this._xSizeInDents = xSizeInDents;
    this._xSizeInFrames = xSizeInFrames;
    this._ySizeInColorlines = ySizeInColorlines;
    this._ySizeInPicks = ySizeInPicks;
    this._weavingChores = weavingChores;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get visualisations(): PatternVisualisation[] {
    return this._visualisations;
  }

  public set visualisations(visualisations: PatternVisualisation[]) {
    this._visualisations = visualisations;
  }

  public get type(): string {
    return this._type;
  }

  public get comment(): string {
    return this._comment;
  }

  public get heightInPixels(): number {
    return this._heightInPixels;
  }

  public get widthInPixels(): number {
    return this._widthInPixels;
  }

  public get sizeInBytes(): number {
    return this._sizeInBytes;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public get xSizeInDents(): number {
    return this._xSizeInDents;
  }

  public get xSizeInFrames(): number {
    return this._xSizeInFrames;
  }

  public get ySizeInColorlines(): number {
    return this._ySizeInColorlines;
  }

  public get ySizeInPicks(): number {
    return this._ySizeInPicks;
  }

  public get weavingChores(): number[] {
    return this._weavingChores;
  }

  public clearName(): void {
    this._name = null;
  }

  public static fromJSON(patternJSON: any): Pattern {
    return !AssertionUtils.isNullOrUndefined(patternJSON)
      ? new Pattern(
          patternJSON.id,
          patternJSON.name,
          patternJSON.patternVisualisations?.map((patternVisualisationJSON: any) => {
            return Pattern.fromPatternVisualisationJSON(patternVisualisationJSON);
          }) ?? [],
          patternJSON.weavePatternType ?? undefined,
          patternJSON.comment ?? undefined,
          patternJSON.heightInPixels ?? undefined,
          patternJSON.widthInPixels ?? undefined,
          patternJSON.sizeInBytes ?? undefined,
          patternJSON.dateCreated ?? undefined,
          patternJSON.xSizeInDents ?? undefined,
          patternJSON.xSizeInFrames ?? undefined,
          patternJSON.ySizeInColorlines ?? undefined,
          patternJSON.ySizeInPicks ?? undefined,
          patternJSON.weavingChores ?? undefined
        )
      : null;
  }

  public static fromPatternVisualisationJSON(patternVisualisationJSON: any): PatternVisualisation {
    if (PatternVisualisationType[patternVisualisationJSON?.patternType as string] === PatternVisualisationType.COLOR) {
      return ColorPatternVisualisation.fromJSON(patternVisualisationJSON);
    } else if (PatternVisualisationType[patternVisualisationJSON?.patternType as string] === PatternVisualisationType.IMAGE) {
      return ImagePatternVisualisation.fromJSON(patternVisualisationJSON);
    } else if (PatternVisualisationType[patternVisualisationJSON?.patternType as string] === PatternVisualisationType.PATTERN) {
      return SvgPatternVisualisation.fromJSON(patternVisualisationJSON);
    } else if (PatternVisualisationType[patternVisualisationJSON?.patternType as string] === PatternVisualisationType.DRAWING) {
      return DrawingPatternVisualisation.fromJSON(patternVisualisationJSON);
    } else if (PatternVisualisationType[patternVisualisationJSON?.patternType as string] === PatternVisualisationType.MANCHON) {
      return SvgManchonVisualisation.fromJSON(patternVisualisationJSON);
    }
  }

  public static fromFoldingTypeJSON(foldingTypeJSON: any): Pattern {
    let result: Pattern = null;

    if (!AssertionUtils.isNullOrUndefined(foldingTypeJSON)) {
      const visualisations = AssertionUtils.isNullOrUndefined(foldingTypeJSON.patternVisualisations) ? [] : [SvgPatternVisualisation.fromJSON(foldingTypeJSON.patternVisualisations[0])];

      result = new Pattern(foldingTypeJSON.id ?? null, foldingTypeJSON.name, visualisations, foldingTypeJSON.weavePatternType, foldingTypeJSON.comment);
    }

    return result;
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      patternVisualisations: this._visualisations.map((visualisation: PatternVisualisation) => visualisation?.toJSON())
    } as any as JSON;
  }
}
