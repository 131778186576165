import {LongFloatStitchingMethod} from './long-float-stitching-method.enum';
import {LongFloatThreadType} from './long-float-thread-type.enum';

export class LongFloatCorrectionRule {
  private _threadType: LongFloatThreadType;
  private _clothType: string[];
  private _side: string[];
  private _stitchFrom: LongFloatStitchingMethod;
  private _maxLength: number;

  public constructor(threadType: LongFloatThreadType, clothType: string[], side: string[], stitchFrom: LongFloatStitchingMethod, maxLength: number) {
    this._threadType = threadType;
    this._clothType = clothType;
    this._side = side;
    this._stitchFrom = stitchFrom;
    this._maxLength = maxLength;
  }

  public static createEmptyLongFloatCorrectionRule(): LongFloatCorrectionRule {
    return new LongFloatCorrectionRule(LongFloatThreadType.WARP, [], [], LongFloatStitchingMethod.MIDDLE, null);
  }

  public get threadType(): LongFloatThreadType {
    return this._threadType;
  }

  public get clothType(): string[] {
    return this._clothType;
  }

  public get side(): string[] {
    return this._side;
  }

  public get stitchFrom(): LongFloatStitchingMethod {
    return this._stitchFrom;
  }

  public get maxLength(): number {
    return this._maxLength;
  }

  public static fromJSON(longFloatCorrectionRuleJSON: any): LongFloatCorrectionRule {
    return new LongFloatCorrectionRule(
      longFloatCorrectionRuleJSON.threadType,
      longFloatCorrectionRuleJSON.clothType,
      longFloatCorrectionRuleJSON.side,
      longFloatCorrectionRuleJSON.stitchFrom,
      longFloatCorrectionRuleJSON.maxLength
    );
  }

  public toJSON(): JSON {
    return {
      threadType: this._threadType,
      clothType: this._clothType,
      side: this._side,
      stitchFrom: this._stitchFrom,
      maxLength: this._maxLength
    } as any as JSON;
  }
}
