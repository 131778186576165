export class BlobUtils {
  public static blobToImageData(blob: Blob): Promise<string> {
    return new Promise((resolve: (string) => void) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent): void => resolve((event.target as FileReader).result as string);
      reader.readAsDataURL(blob);
    });
  }

  public static blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve: (string) => void) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent): void => resolve((event.target as FileReader).result as ArrayBuffer);
      reader.readAsArrayBuffer(blob);
    });
  }
}
