import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {isNil} from 'lodash-es';

export class OverviewListColoredYarnSet {
  private _id: number;
  private readonly _name: string;
  private _overviewListColorSet: OverviewListColorSet;
  private _overviewListYarnSet: OverviewListYarnSet;
  private readonly _used: boolean;

  public constructor(id: number, name: string, colorSet: OverviewListColorSet, yarnSet: OverviewListYarnSet, used: boolean) {
    this._id = id;
    this._name = name;
    this._overviewListColorSet = colorSet;
    this._overviewListYarnSet = yarnSet;
    this._used = used;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public get overviewListColorSet(): OverviewListColorSet {
    return this._overviewListColorSet;
  }

  public set overviewListColorSet(overviewListColorSet: OverviewListColorSet) {
    this._overviewListColorSet = overviewListColorSet;
  }

  public get overviewListYarnSet(): OverviewListYarnSet {
    return this._overviewListYarnSet;
  }

  public set overviewListYarnSet(overviewListYarnSet: OverviewListYarnSet) {
    this._overviewListYarnSet = overviewListYarnSet;
  }

  public get used(): boolean {
    return this._used;
  }

  public static fromJSON(coloredYarnSetOverviewListJSON: any): OverviewListColoredYarnSet {
    return new OverviewListColoredYarnSet(
      coloredYarnSetOverviewListJSON.id,
      coloredYarnSetOverviewListJSON.name,
      isNil(coloredYarnSetOverviewListJSON.colorSet) ? null : OverviewListColorSet.fromJSON(coloredYarnSetOverviewListJSON.colorSet),
      isNil(coloredYarnSetOverviewListJSON.yarnSet) ? null : OverviewListYarnSet.fromJSON(coloredYarnSetOverviewListJSON.yarnSet),
      coloredYarnSetOverviewListJSON.used
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      colorSet: this._overviewListColorSet.toJSON(),
      yarnSet: isNil(this._overviewListYarnSet) ? null : this._overviewListYarnSet.toJSON(),
      used: this._used
    } as any as JSON;
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
