import {Component} from '@angular/core';
import {ICellRenderer} from 'ag-grid-community';
import {LoadingCellOverlayComponentParams} from './loading-cell-overlay-component-params';

@Component({
  selector: 'vdw-loading-cell-overlay',
  templateUrl: './loading-cell-overlay.component.html',
  styleUrls: ['./loading-cell-overlay.component.scss']
})
export class LoadingCellOverlayComponent implements ICellRenderer {
  public loadingForTheFirstTime: boolean;

  public agInit(params: LoadingCellOverlayComponentParams): void {
    this.loadingForTheFirstTime = params.loadingForTheFirstTime;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
