import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {OrderLinesGridService} from '@application/helper/orderbook/order-lines-grid.service';
import {OrderLinesTarget} from '@domain/order-lines-target.enum';
import {PropertyValue} from '@domain/property-value';
import {ORDERBOOK, Orderbook} from '@infrastructure/http/orderbook/orderbook';
import {OverviewListOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-order-line';
import {
  AgGridRowSelection,
  AssertionUtils,
  BaseComponent,
  DialogBuilder,
  DialogBuilderFactoryService,
  DialogType,
  GridModel,
  GridOptionsBuilderFactoryService,
  LoadingCellOverlayComponent,
  LocalStorageService,
  NoDataOverlayComponentParams,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {
  ColDef,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
  SortModelItem
} from 'ag-grid-community';
import {each, isEmpty, isEqual, isNil, some} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-order-lines-grid',
  templateUrl: './order-lines-grid.component.html',
  styleUrls: ['./order-lines-grid.component.scss']
})
export class OrderLinesGridComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('orderLinesGrid') public orderLinesGrid: AgGridAngular;
  @ViewChild('exportOrderLinesGrid') public exportOrderLinesGrid: AgGridAngular;

  @Input() public allowMultipleSelection: boolean;
  @Input() public advancedSearchFilters: PropertyValue[];
  @Input() public selectedOrderLines: OverviewListOrderLine[];
  @Input() public noRowsOverlayComponentParams: Partial<NoDataOverlayComponentParams>;
  @Input() public target: OrderLinesTarget;
  @Input() public unit: Unit = Unit.CENTIMETER;
  @Input() public isExporting = false;

  @Output() public selectedOrderLinesChange: EventEmitter<OverviewListOrderLine[]> = new EventEmitter<OverviewListOrderLine[]>();
  @Output() public advancedSearchFiltersChange: EventEmitter<PropertyValue[]> = new EventEmitter<PropertyValue[]>();
  @Output() public exportComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  public gridOptions: GridOptions;
  public loadingForTheFirstTime = true;
  public exportGridOptions: GridOptions = {};
  public exportData: OverviewListOrderLine[] = [];
  private colDefs: ColDef[] = [];
  private readonly quantityOfOrderLinesToRequestPerSlice = 30;
  private readonly localStorageFilterModelKey = 'filterModel.Orderbook';
  private dialogBuilder: DialogBuilder;

  public constructor(
    @Inject(ORDERBOOK) private readonly orderbook: Orderbook,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly orderLinesGridService: OrderLinesGridService,
    private readonly localStorage: LocalStorageService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initialiseGridOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('allowMultipleSelection' in changes && !changes.allowMultipleSelection.isFirstChange()) {
      this.handleMultipleSelectionChange();
    }

    if ('selectedOrderLines' in changes && !changes.selectedOrderLines.isFirstChange()) {
      this.handleSelectedOrderLinesChange();
    }

    if ('isExporting' in changes && this.isExporting === true) {
      this.exportAllData();
    }
  }

  public reloadOrderLines(): void {
    this.orderLinesGrid.api.hideOverlay();
    this.orderLinesGrid.api.refreshServerSide({purge: true});
  }

  public exportAllData(): void {
    this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();
    this.dialogBuilder.openAlertDialog({
      titleText: 'AGGRID.EXPORT',
      messageText: 'AGGRID.EXPORTMESSAGE',
      type: DialogType.INFORMATION
    });

    this.exportOrderLinesGrid.api.setGridOption('columnDefs', this?.colDefs);
    const sortModel: SortModelItem[] = [];
    let startRow: number = 0;
    let endRow: number = 30;
    const filterModel = this.orderLinesGrid.api.getFilterModel();
    const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);
    this.fetchExportData(gridModel);
  }

  private fetchExportData(gridModel: GridModel): void {
    this.orderLinesGrid.api.setGridOption('suppressRowClickSelection', true);

    this.orderbook
      .getSlice(this.target, gridModel)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfOrderlines: OverviewListOrderLine[]) => {
        listOfOrderlines.forEach((orderline: OverviewListOrderLine) => {
          this.exportData.push(orderline);
        });

        if (listOfOrderlines.length < 29) {
          this.exportOrderLinesGrid.api.setGridOption('rowData', this.exportData);
          this.exportGridOptions.cacheBlockSize = this.exportData.length;
          this.orderLinesGrid.api.setGridOption('suppressRowClickSelection', false);
          this.exportOrderLinesGrid.api.exportDataAsCsv();
          this.isExporting = false;
          this.exportData = [];
          this.onExportComplete();
          this.dialogBuilder.close();
        } else {
          gridModel.startRow += 30;
          gridModel.endRow += 30;
          this.fetchExportData(gridModel);
        }
      });
  }

  private initialiseGridOptions(): void {
    this.orderLinesGridService
      .getColumnDefs(this.unit)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((colDefs: ColDef[]) => {
        this.colDefs = colDefs;
        this.gridOptions = this.gridOptionsBuilderFactoryService
          .getBuilder(colDefs, this.isGridTargetOrderbook() ? GridIdentifier.ORDERBOOK : GridIdentifier.ORDER_ITEMS, undefined, true)
          .withHeaderHeight(32)
          .withFloatingFiltersHeight(32)
          .withLoadingCellRenderer('customLoadingCellRenderer')
          .withServerSideDataSource(this.quantityOfOrderLinesToRequestPerSlice, this.getServerSideDatasource())
          .withRowSelection(this.allowMultipleSelection, true, this.allowMultipleSelection, false)
          .withOnRowClicked((rowClickedEvent: RowClickedEvent<OverviewListOrderLine>) => this.onRowClicked(rowClickedEvent.node))
          .withOnRowSelected(({api}: RowSelectedEvent) => this.onRowSelected(api))
          .withComponents({
            customLoadingCellRenderer: LoadingCellOverlayComponent
          })
          .withNoRowsOverlay({
            ...this.noRowsOverlayComponentParams,
            isAnyFilterPresent: () => this.hasFilterActive()
          } as NoDataOverlayComponentParams)
          .withCompactView()
          .withOnGridReady(({api}: GridReadyEvent) => this.applyFilterModel(api))
          .withOnFilterChanged(({api}: FilterChangedEvent) => this.saveFilterModel(api))
          .build();
      });
  }

  private hasFilterActive(): boolean {
    return Object.values(this.orderLinesGrid.api.getFilterModel()).length > 0;
  }

  private onRowClicked(rowNode: IRowNode<OverviewListOrderLine>): void {
    if (!this.allowMultipleSelection && rowNode.isSelected() && this.selectedOrderLines[0]?.id === rowNode.data.id) {
      rowNode.setSelected(false);
    }
  }

  private onExportComplete(): void {
    this.exportComplete.emit(true);
  }

  private onRowSelected(gridApi: GridApi): void {
    this.selectedOrderLinesChange.emit(gridApi.getSelectedNodes().map((rowNode: RowNode<OverviewListOrderLine>) => rowNode.data));
  }

  private getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams): void => {
        const sortModel: SortModelItem[] = params.request.sortModel;
        const startRow: number = params.request.startRow;
        const endRow: number = params.request.endRow;
        const filterModel = params.request.filterModel;

        const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);

        this.orderbook
          .getSlice(this.target, gridModel)
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe({
            next: (listOfOrderLines: OverviewListOrderLine[]) => {
              let endRowOrderbook: number;

              if (listOfOrderLines.length < this.quantityOfOrderLinesToRequestPerSlice) {
                endRowOrderbook = startRow + listOfOrderLines.length;
              }

              if (startRow === 0) {
                if (listOfOrderLines.length === 0) {
                  params.api.showNoRowsOverlay();
                } else {
                  params.api.hideOverlay();
                }
              }

              this.loadingForTheFirstTime = false;
              this.gridOptions.loadingCellRendererParams = {
                loadingForTheFirstTime: this.loadingForTheFirstTime
              };

              if (this.allowMultipleSelection && this.hasSelectedOrderLines()) {
                const [selectedOrderLine] = this.selectedOrderLines;

                each(listOfOrderLines, (orderline: OverviewListOrderLine) => {
                  orderline.isSelectable = !this.isGridTargetOrderbook() || isEqual(selectedOrderLine.article.machineQuality.id, orderline.article.machineQuality.id);
                });
              }

              params.success({
                rowData: listOfOrderLines,
                rowCount: endRowOrderbook
              });

              this.setSelectedOrderLines(params.api);
            },
            error: (error: any) => {
              params.fail();
              params.api.showNoRowsOverlay();
              throw error;
            }
          });
      }
    };
  }

  private isGridTargetOrderbook(): boolean {
    return this.target === OrderLinesTarget.ORDER_BOOK;
  }

  private handleMultipleSelectionChange(): void {
    this.orderLinesGrid.api.updateGridOptions({
      rowSelection: this.allowMultipleSelection ? AgGridRowSelection.MULTIPLE : AgGridRowSelection.SINGLE,
      rowMultiSelectWithClick: this.allowMultipleSelection,
      context: {
        ...this.gridOptions.context,
        rowSelection: this.gridOptions.rowSelection
      },
      columnDefs: this.orderLinesGrid.api.getColumnDefs().map((colDef: ColDef) => {
        if (colDef.colId === 'salesOrderReference' || colDef.colId === 'checkbox') {
          colDef.checkboxSelection = this.allowMultipleSelection;
        }
        return colDef;
      })
    });

    this.orderLinesGrid.api.refreshCells();
    this.orderLinesGrid.api.deselectAll();
    this.orderLinesGrid.api.refreshHeader();
  }

  private handleSelectedOrderLinesChange(): void {
    if (this.gridOptions.rowSelection === AgGridRowSelection.MULTIPLE) {
      this.orderLinesGrid.api.refreshHeader();
    }
    if (!this.hasSelectedOrderLines()) {
      this.orderLinesGrid.api.deselectAll();
    }
  }

  private hasSelectedOrderLines(): boolean {
    return !isEmpty(this.selectedOrderLines);
  }

  private saveFilterModel(gridApi: GridApi): void {
    const filterModel = gridApi.getFilterModel();
    this.localStorage.set(this.localStorageFilterModelKey, JSON.stringify(filterModel));
  }

  private applyFilterModel(gridApi: GridApi): void {
    const filterModel = this.localStorage.get<any>(this.localStorageFilterModelKey);
    if (AssertionUtils.isNullOrUndefined(filterModel)) {
      gridApi.setFilterModel(null);
    } else {
      gridApi.setFilterModel(filterModel);
    }

    this.orderLinesGrid?.api?.setGridOption('serverSideDatasource', this.getServerSideDatasource());
  }

  private setSelectedOrderLines(gridApi: GridApi): void {
    if (!this.isGridTargetOrderbook() && this.hasSelectedOrderLines()) {
      gridApi.forEachNode((node: RowNode) => {
        if (!isNil(node.data)) {
          node.setSelected(some(this.selectedOrderLines, {id: node.data.id}));
        }
      });
    }
  }
}
