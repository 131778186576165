import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class SuperbaheatMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.SUPERBAHEAT, name, orderId);
  }

  public static fromJSON(superbaheatMachineJSON: any): SuperbaheatMachine {
    return new SuperbaheatMachine(superbaheatMachineJSON.id, superbaheatMachineJSON.name, superbaheatMachineJSON.orderId);
  }
}
