import {FinishingLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label';
import {FinishingPositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-position-in-carpet';
import {ImageForFinishingImageLabel} from '@domain/textile-data/finishing-and-finishing-template/image-for-finishing-image-label';
import {LabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-place.enum';
import {FinishingLabelType} from './finishing-label-type.enum';

export class FinishingImageLabel extends FinishingLabel {
  protected _label: string;
  private _image: ImageForFinishingImageLabel;

  public constructor(
    id: number,
    place: LabelPlace,
    image: ImageForFinishingImageLabel,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingPositionInCarpet,
    positionInBottomCarpet: FinishingPositionInCarpet
  ) {
    super(id, place, rotation, mirrored, positionInTopCarpet, positionInBottomCarpet, FinishingLabelType.IMAGE);

    this._image = image;
    this._label = JSON.stringify(this.getLabel());
  }

  public get image(): ImageForFinishingImageLabel {
    return this._image;
  }

  public static fromJSON(finishingImageLabelJSON: any): FinishingImageLabel {
    return new FinishingImageLabel(
      finishingImageLabelJSON.id,
      finishingImageLabelJSON.place as LabelPlace,
      ImageForFinishingImageLabel.fromJSON(finishingImageLabelJSON.image),
      finishingImageLabelJSON.rotation,
      finishingImageLabelJSON.mirrored,
      FinishingPositionInCarpet.fromJSON(finishingImageLabelJSON.topCarpet),
      FinishingPositionInCarpet.fromJSON(finishingImageLabelJSON.bottomCarpet)
    );
  }

  public toJSON(): JSON {
    return {id: this.id, ...this.getLabel()} as any as JSON;
  }

  private getLabel(): object {
    return {
      finishingLabelType: this.finishingLabelType,
      place: this.place,
      imageId: this.image.id,
      rotation: this.rotation,
      mirrored: this.mirrored,
      topCarpet: this.positionInTopCarpet.toJSON(),
      bottomCarpet: this.positionInBottomCarpet.toJSON()
    };
  }
}
