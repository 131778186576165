import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export const CONNECTIVITY_TOAST = new InjectionToken<ConnectivityToast>('ConnectivityToast');

export interface ConnectivityToast {
  didRequestEnd: Observable<void>;

  show(): void;

  hide(): void;

  requestEnded(): void;
}
