import {ChangeDetectorRef, Component, Inject, QueryList, ViewChildren} from '@angular/core';
import {getMappedColoredYarnSetWithStartDent} from '@application/helper/textile-data/colored-yarn-set/get-mapped-colored-yarn-set-with-start-dent';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {ColorListPreviewComponent} from '@presentation/components/color-list-preview/color-list-preview.component';
import {RepositionDialogData} from '@presentation/components/reposition-dialog/reposition-dialog-data.interface';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {GridCreelPreviewComponent} from '@presentation/pages/textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component';
import {TextileDataDetailDialogData} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail-dialog-data.interface';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {DialogBuilderFactoryService, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE, TranslateService} from '@vdw/angular-component-library';
import {ICellEditorParams} from 'ag-grid-community';
import {cloneDeep} from 'lodash-es';

@Component({
  selector: 'app-production-schedule-overview-creel-preview',
  templateUrl: './production-schedule-overview-creel-preview.component.html',
  styleUrls: ['../../../../textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component.scss']
})
export class ProductionScheduleOverviewCreelPreviewComponent extends GridCreelPreviewComponent {
  @ViewChildren('colorListPreview') public colorListPreviewElements: QueryList<ColorListPreviewComponent>;
  public creelMaps: CreelMap[];
  private theoreticalValue: OverviewListColoredYarnSetWithStartDent[];

  public constructor(
    dialogBuilderFactoryService: DialogBuilderFactoryService,
    translate: TranslateService,
    @Inject(RESPONSIVENESS_VIEW_MODE) protected responsivenessViewMode: ResponsivenessViewMode,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogBuilderFactoryService, translate, responsivenessViewMode, changeDetectorRef);
  }

  public agInit(params: ICellEditorParams): void {
    params.value = params.data.coloredYarnSets;
    this.theoreticalValue = cloneDeep(params.value);
    this.creelMaps = params.data.mappingForCurrentPositionOnMachine;

    if (this.creelMaps) {
      params.value = params.value.map((coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) => {
        return getMappedColoredYarnSetWithStartDent(
          coloredYarnSetWithStartDent,
          this.creelMaps.find((creelMap: CreelMap) => creelMap.coloredYarnSetId === coloredYarnSetWithStartDent.coloredYarnSet.id),
          'asc'
        );
      });

      super.agInit(params);

      setTimeout(() => {
        this.colorListPreviewElements.forEach((colorListPreviewElement: ColorListPreviewComponent) => {
          colorListPreviewElement.calculateCreelPositionsToShow();
        });
      });
    } else {
      super.agInit(params);
    }
  }

  protected getDialogDataForColoredYarnSetDetailsDialog(
    data: {colorList: OverviewListColoredYarnSet; event: MouseEvent},
    index: number
  ): RepositionDialogData<TextileDataDetailComponent> & TextileDataDetailDialogData {
    return {
      ...super.getDialogDataForColoredYarnSetDetailsDialog(data, index),
      ...(this.creelMaps
        ? {
            creelMap: this.creelMaps.find((creelMap: CreelMap) => creelMap.coloredYarnSetId === data.colorList.id),
            textileData: this.theoreticalValue[index].coloredYarnSet
          }
        : {})
    };
  }
}
