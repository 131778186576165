import {Pattern} from '@domain/pattern';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {BetweenRule} from './between-rule.interface';

export class Jute {
  private _type: string;
  private _commercialHeightInMM: number;
  private _technicalHeightInPicks: number;
  private _pattern: Pattern;
  private _rules: string[] | BetweenRule[];
  private _borderColorIndex: number;
  private _priority: number;

  public constructor(type: string, commercialHeightInMM: number, technicalHeightInPicks: number, pattern: Pattern, rules: string[] | BetweenRule[], borderColorIndex: number, priority: number) {
    this._type = type;
    this._commercialHeightInMM = commercialHeightInMM;
    this._technicalHeightInPicks = technicalHeightInPicks;
    this._pattern = pattern;
    this._rules = rules;
    this._borderColorIndex = borderColorIndex;
    this._priority = priority;
  }

  public get type(): string {
    return this._type;
  }

  public get commercialHeightInMM(): number {
    return this._commercialHeightInMM;
  }

  public get technicalHeightInPicks(): number {
    return this._technicalHeightInPicks;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public get rules(): string[] | BetweenRule[] {
    return this._rules;
  }

  public get borderColorIndex(): number {
    return this._borderColorIndex;
  }

  public get priority(): number {
    return this._priority;
  }

  public static fromJSON(juteConditionJSON: any): Jute {
    return new Jute(
      juteConditionJSON.type,
      juteConditionJSON.commercialHeightInMM,
      juteConditionJSON.technicalHeightInPicks,
      Pattern.fromJSON(juteConditionJSON.pattern),
      juteConditionJSON.rules,
      juteConditionJSON.borderColorIndex ? juteConditionJSON.borderColorIndex.id : null,
      juteConditionJSON.priority
    );
  }

  public toJSON(): JSON {
    const jute = {
      type: this._type,
      commercialHeightInMM: this._commercialHeightInMM,
      technicalHeightInPicks: this._technicalHeightInPicks,
      pattern: {
        id: this._pattern?.id,
        type: PatternVisualisationType[this._pattern?.visualisations[0].type]
      },
      rules: this._rules,
      borderColorIndex: this._borderColorIndex
    };

    if (this._priority) {
      jute['priority'] = this._priority;
    }

    return jute as any as JSON;
  }
}
