<div class="color-picker-form-input" [style.background-color]="selectedColor">
  @if (mode === COLOR_PICKER_MODE.NATIVE) {
    <input type="color" (input)="selectedColorChange.emit($event.target.value)" />
  } @else {
    <vdw-dialog-button
      #colorPicker
      [componentType]="COLOR_PRESET_PICKER_MENU_COMPONENT"
      [dialogData]="getDialogData()"
      [dialogClass]="['bms-theme', 'no-border', 'no-padding']"
      [verticaloffset]="2"
      [align]="'left'"
      (dialogDataChanged)="onSelectedColorChanged($event)"
    >
      <input [class.open]="colorPicker.open" (click)="colorPicker.toggleDialog()" />
    </vdw-dialog-button>
  }
  <mat-icon svgIcon="solid-colorize"></mat-icon>
</div>
