<div class="flex-column">
  <div class="datepicker-header flex-row">
    <div class="flex-column">
      <button mat-button (click)="currentPeriodClicked()">
        <div class="flex-start-center">
          <h3 class="title flex-1 font-weight-semibold">{{ periodButtonText }}</h3>
          <mat-icon svgIcon="chevron-up"></mat-icon>
        </div>
      </button>
    </div>
    <div class="flex-column controls">
      <div class="flex-row">
        <button mat-icon-button type="button" class="mat-icon-action-button-sm-without-border" [disabled]="!previousEnabled()" (click)="previousClicked()" [attr.aria-label]="prevButtonLabel">
          <mat-icon class="ag-icon ag-icon-asc"></mat-icon>
        </button>
        <button mat-icon-button type="button" class="mat-icon-action-button-sm-without-border" [disabled]="!nextEnabled()" (click)="nextClicked()" [attr.aria-label]="nextButtonLabel">
          <mat-icon class="ag-icon ag-icon-desc"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
