<section class="flex-column">
  <article class="dialog-content flex-column gap-8">
    <ng-container *ngIf="hasMultipleConflicts(); else singleConflict">
      <ng-container *ngFor="let entityName of data.entityName; let conflictIndex = index">
        <h2 class="font-weight-semibold" [innerHTML]="data.titleKey | bmsTranslate: {object: data.entityType | bmsTranslate: {count: getTitleCount()}}"></h2>
        <p class="b1 font-weight-regular multi-line">{{ data.descriptionKey | bmsTranslate: {entityName: entityName} }}</p>
        <ul class="flex-column no-margin pl-24">
          <li *ngFor="let conflict of data.conflicts[conflictIndex]">
            <a class="b1 url" (click)="navigateToConflictUrl(conflict)" target="_blank">{{ conflict.name }} ({{ getConflictTypeTranslationKey(conflict.type) | bmsTranslate: {count: 1} }})</a>
          </li>
        </ul>
      </ng-container>
    </ng-container>
    <ng-template #singleConflict>
      <h2 class="font-weight-semibold" [innerHTML]="data.titleKey | bmsTranslate: {object: data.entityType | bmsTranslate: {count: getTitleCount()}}"></h2>
      <p class="b1 font-weight-regular multi-line">{{ data.descriptionKey | bmsTranslate: {entityName: data.entityName} }}</p>
      <ul class="flex-column no-margin pl-24">
        <li *ngFor="let conflict of data.conflicts">
          <a class="b1 url" (click)="navigateToConflictUrl(conflict)" target="_blank">{{ conflict.name }} ({{ getConflictTypeTranslationKey(conflict.type) | bmsTranslate: {count: 1} }})</a>
        </li>
      </ul>
    </ng-template>
    <p *ngIf="data.allowUnlock" class="b1 font-weight-regular multi-line">{{ 'CONFLICTS.CONFIRM_UNLOCK' | bmsTranslate }}</p>
  </article>
  <vdw-footer [chooseEventName]="getButtonText()" [centered]="true" [withCancelButton]="data.allowUnlock" [chooseDialogCloseValue]="getChooseDialogCloseValue()"></vdw-footer>
</section>
