import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Gauge} from '@domain/machine/gauge';
import {HttpTuftProductsService} from '@infrastructure/http/tuft-product/http-tuft-products.service';
import {GetAllTuftProductsParameters} from '@infrastructure/http/tuft-product/tuft-products.interface';
import {NavigationMachineOverviewData} from '@presentation/pages/machine-overview/navigation-machine-overview-data.interface';
import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';
import {
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  StringUtils,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams, ITooltipParams, RowNode} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-tuft-product.component.html'
})
export class SelectTuftProductComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('tuftProductsGrid') public tuftProductsGrid: AgGridAngular;
  public listOfTuftProducts: OverviewListTuftProduct[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private readonly machineId: number;

  public constructor(
    private readonly dialogRef: MatDialogRef<SelectTuftProductComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly tuftProducts: HttpTuftProductsService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectTuftProductComponent>>
  ) {
    super();

    this.machineId = data?.machineId;
  }

  public get gridOptionsForListOfTuftProducts(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.getTuftProducts();
    this.initializeGridOptionsForListOfTuftProducts();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.tuftProductsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<DialogComponentData<typeof SelectTuftProductComponent>>({dialogComponent: SelectTuftProductComponent});
  }

  public selectTuftProduct(): void {
    this.dialogRef.close(this.tuftProductsGrid.api.getSelectedRows()[0]);
  }

  public canSelectTuftProduct(): boolean {
    return !!this.tuftProductsGrid?.api && this.tuftProductsGrid.api.getSelectedRows()?.length > 0;
  }

  public showOnlyLatestVersionChanged(): void {
    this.getTuftProducts();
  }

  public navigateToAddTuftProduct(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationMachineOverviewData>(
      {equipmentId: this.machineId ?? null},
      RouteUtils.paths.texStyle.tuftProduct.addTuftProduct.absolutePath
    );
  }

  private initializeGridOptionsForListOfTuftProducts(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfTuftProducts(), GridIdentifier.SELECT_TUFT_PRODUCT)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnCellDoubleClicked(() => this.selectTuftProduct())
        .build()
    ];
  }

  private getTuftProducts(showOnlyLatestVersion: boolean = true): void {
    const parameters: Partial<GetAllTuftProductsParameters> = {showOnlyLatestVersion};
    const request = AssertionUtils.isNullOrUndefined(this.machineId) ? this.tuftProducts.getAll(parameters) : this.tuftProducts.getAllForMachineId(this.machineId, parameters);

    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((listOfTuftProducts: OverviewListTuftProduct[]) => {
      this.listOfTuftProducts = listOfTuftProducts;
    });
  }

  private getColumnDefsForListOfTuftProducts(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('version', true)
        .withHeaderName('GENERAL.VERSION')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('widthInCm')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.CENTIMETER, this.l10nIntlService))
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('blend', true)
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.BLEND')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('left')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('color', true)
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.COLOR')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('left')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('gauge')
        .withHeaderName('GENERAL.DIMENSIONS.GAUGE')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue().fractionalValueAsString, Unit.INCH))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value.fractionalValueAsString, Unit.INCH, this.l10nIntlService))
        .withCellClass('right')
        .withComparator(this.gaugeComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('stitchRateInStitchesPerCm')
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.STITCH_RATE')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.PER_CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.PER_CENTIMETER, this.l10nIntlService))
        .withCellClass('right')
        .build()
    ];
  }

  private buildAgGridCellTextWithUnit(value: number, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService);
  }

  private gaugeComparator(valueA: Gauge, valueB: Gauge, _nodeA: RowNode<Gauge>, _nodeB: RowNode<Gauge>, _isInverted: boolean): number {
    const comparison = valueA.fractionalValue > valueB.fractionalValue ? 1 : -1;
    return valueA.fractionalValue === valueB.fractionalValue ? 0 : comparison;
  }
}
