import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DatepickerHeaderComponent} from '../datepicker/datepicker-header/datepicker-header.component';
import moment, {Moment} from 'moment';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {MatButton} from '@angular/material/button';
import {canShowErrorForErrorCodeAndFormControlForFormGroup} from '../../common/validators/can-show-error-for-error-code-and-form-control-for-form-group';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-datepicker-form-input',
  templateUrl: './datepicker-form-input.component.html'
})
export class DatepickerFormInputComponent extends BaseComponent implements AfterViewInit {
  @Input() public inputLabel: string;
  @Input() public form: UntypedFormGroup;
  @Input() public formControlNameValue: string;
  @Input() public minValue: Date = null;
  @Input() public maxValue: Date = null;
  @Input() public value: Date = new Date();
  @Input() public showHint = false;
  @Input() public hint: string;
  @Input() public placeholder: string;
  @Input() public optional = false;
  @Input() public canShowBMSTheme = false;
  @Output() public dateChange: EventEmitter<MatDatepickerInputEvent<Date>> = new EventEmitter<MatDatepickerInputEvent<Date>>();

  public readonly datepickerHeader = DatepickerHeaderComponent;

  @ViewChild('datePicker')
  public datePicker: MatDatepicker<Moment>;

  @ViewChild('applyButton')
  public applyButton: MatButton;

  private dayClickListeners: (() => void)[] = [];
  private monthNavClickListeners: (() => void)[] = [];

  public ngAfterViewInit(): void {
    this.datePicker?.openedStream?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.addDayClickedEvents();
      this.addMonthNavigationEvents();
    });
  }

  public onMonthSelected(): void {
    this.addDayClickedEvents();
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.dateChange.emit(event);
    this.removeDayClickListeners();
    this.removeMonthNavClickListeners();
  }

  public clear(): void {
    this.form.get(this.formControlNameValue).setValue(null);
    this.datePicker.close();
  }

  public today(): void {
    this.form.get(this.formControlNameValue).setValue(moment().startOf('day').toDate());
    this.datePicker.close();
  }

  public canShowRequiredError(): boolean {
    return (
      canShowErrorForErrorCodeAndFormControlForFormGroup('required', this.formControlNameValue, this.form) &&
      !canShowErrorForErrorCodeAndFormControlForFormGroup('matDatepickerParse', this.formControlNameValue, this.form)
    );
  }

  public canShowClearInputButton(): boolean {
    return (
      this.canShowBMSTheme &&
      !this.form.get(this.formControlNameValue).disabled &&
      (!AssertionUtils.isNullOrUndefined(this.form.get(this.formControlNameValue).value) ||
        canShowErrorForErrorCodeAndFormControlForFormGroup('matDatepickerParse', this.formControlNameValue, this.form))
    );
  }

  private addDayClickedEvents(): void {
    setTimeout(() => {
      const cells = document.querySelectorAll<HTMLElement>('.mat-calendar-body-cell');
      cells.forEach((cell: HTMLElement) => {
        const listener = this.onDateCellClick.bind(this);
        cell.addEventListener('click', listener);
        this.dayClickListeners.push(() => cell.removeEventListener('click', listener));
      });
    }, 100);
  }

  private onDateCellClick(): void {
    this.applyButton._elementRef.nativeElement.click();
    this.removeDayClickListeners();
    this.removeMonthNavClickListeners();
  }

  private addMonthNavigationEvents(): void {
    setTimeout(() => {
      const monthNavigationButtons = document.getElementsByClassName('mdc-icon-button');
      const monthNavigationButtonsArray = Array.from(monthNavigationButtons) as HTMLElement[];
      monthNavigationButtonsArray.forEach((button: HTMLElement) => {
        const listener = this.onMonthNavigationClick.bind(this);
        button.addEventListener('click', listener);
        this.monthNavClickListeners.push(() => button.removeEventListener('click', listener));
      });
    });
  }

  private onMonthNavigationClick(): void {
    this.addDayClickedEvents();
  }

  private removeDayClickListeners(): void {
    this.dayClickListeners.forEach((removeListener: () => void) => removeListener());
    this.dayClickListeners = [];
  }

  private removeMonthNavClickListeners(): void {
    this.monthNavClickListeners.forEach((removeListener: () => void) => removeListener());
    this.monthNavClickListeners = [];
  }
}
