import {GlobalPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';
import {ConnectivityToast} from '@presentation/components/connectivity-toast/connectivity-toast';
import {ConnectivityToastRef} from '@presentation/components/connectivity-toast/connectivity-toast-ref';
import {ConnectivityToastComponent} from '@presentation/components/connectivity-toast/connectivity-toast.component';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ConnectivityToastService implements ConnectivityToast {
  public didRequestEnd: Observable<void>;

  private connectivityToastRef: ConnectivityToastRef;
  private readonly overlay: Overlay;
  private readonly parentInjector: Injector;
  private readonly didRequestEndSubject = new Subject<void>();

  public constructor(overlay: Overlay, parentInjector: Injector) {
    this.overlay = overlay;
    this.parentInjector = parentInjector;
    this.didRequestEnd = this.didRequestEndSubject.asObservable();
  }

  public show(): void {
    if (!this.connectivityToastRef?.isVisible()) {
      const positionStrategy: GlobalPositionStrategy = this.overlay.position().global().top('0px').centerHorizontally();

      const overlayRef: OverlayRef = this.overlay.create({positionStrategy});
      this.connectivityToastRef = new ConnectivityToastRef(overlayRef);

      const injector = Injector.create({providers: [], parent: this.parentInjector});
      const componentPortal: ComponentPortal<ConnectivityToastComponent> = new ComponentPortal(ConnectivityToastComponent, null, injector);

      overlayRef.attach(componentPortal);
    }
  }

  public hide(): void {
    if (this.connectivityToastRef?.isVisible()) {
      this.connectivityToastRef.close();
    }
  }

  public requestEnded(): void {
    this.didRequestEndSubject.next();
  }
}
