import {ColorRGBRepresentation} from '@vdw/angular-component-library';
import {PatternVisualisation} from './pattern-visualisation';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';

export class ColorPatternVisualisation extends PatternVisualisation {
  private _rgb: ColorRGBRepresentation;

  public constructor(rgb: ColorRGBRepresentation) {
    super(PatternVisualisationType.COLOR);

    this._rgb = rgb;
  }

  public get rgb(): ColorRGBRepresentation {
    return this._rgb;
  }

  public static fromJSON(patternVisualisationJSON: any): ColorPatternVisualisation {
    return new ColorPatternVisualisation(patternVisualisationJSON.pattern);
  }

  public toJSON(): JSON {
    return {
      pattern: this._rgb,
      patternType: PatternVisualisationType[this.type]
    } as any as JSON;
  }
}
