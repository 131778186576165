export class MathUtils {
  public static divide(numerator: number, denominator: number): number {
    const result = denominator !== 0 ? numerator / denominator : 0;
    return isFinite(result) && !isNaN(result) ? result : 0;
  }

  public static round(value: number, precision: number = 0): number {
    const modifier = 10 ** precision;
    return Math.round(value * modifier) / modifier;
  }
}
