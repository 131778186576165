import {FinishingTemplateCutbarsInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-cutbars-information';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {FinishingAndFinishingTemplateLabelOverviewList} from '@presentation/pages/textile-data/finishing-template/overview/finishing-and-finishing-template-label-overview-list';
import {map} from 'lodash-es';

export class OverviewListFinishingTemplate {
  private _id: number;
  private _name: string;
  private _finishingType: FinishingType;
  private _cutbarsInformation: FinishingTemplateCutbarsInformation;
  private _labels: FinishingAndFinishingTemplateLabelOverviewList[];

  public constructor(id: number, name: string, finishingType: FinishingType, cutbarsInformation: FinishingTemplateCutbarsInformation, labels: FinishingAndFinishingTemplateLabelOverviewList[]) {
    this._id = id;
    this._name = name;
    this._finishingType = finishingType;
    this._cutbarsInformation = cutbarsInformation;
    this._labels = labels;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get finishingType(): FinishingType {
    return this._finishingType;
  }

  public get cutbarsInformation(): FinishingTemplateCutbarsInformation {
    return this._cutbarsInformation;
  }

  public get labels(): FinishingAndFinishingTemplateLabelOverviewList[] {
    return this._labels;
  }

  public static fromJSON(finishingTemplateJSON: any): OverviewListFinishingTemplate {
    return new OverviewListFinishingTemplate(
      finishingTemplateJSON.id,
      finishingTemplateJSON.name,
      finishingTemplateJSON.finishingType,
      FinishingTemplateCutbarsInformation.fromJSON(finishingTemplateJSON.cutbarsInformation),
      map(finishingTemplateJSON.labels, (finishingTemplateLabelJSON: any) => FinishingAndFinishingTemplateLabelOverviewList.fromJSON(finishingTemplateLabelJSON))
    );
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
