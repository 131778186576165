import {Component, EventEmitter, Input, Output} from '@angular/core';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {DialogBuilderFactoryService} from '../../dialogs/dialog-builder/dialog-builder-factory.service';
import {MobileSelectorDialogDataInterface} from './mobile-selector/mobile-selector-dialog-data.interface';
import {MobileSelectorOption} from './mobile-selector/mobile-selector-option.interface';
import {MobileSelectorComponent} from './mobile-selector/mobile-selector.component';

@Component({
  selector: 'vdw-mobile-dropdown',
  templateUrl: './mobile-dropdown.component.html',
  styleUrls: ['./mobile-dropdown.component.scss']
})
export class MobileDropdownComponent extends BaseComponent {
  @Input() public selectedOption: MobileSelectorOption;
  @Input() public options: MobileSelectorOption[];
  @Input() public dialogTitleKey: string;
  @Output() public selectorChange = new EventEmitter<MobileSelectorOption>();

  public constructor(private readonly dialogBuilderFactoryService: DialogBuilderFactoryService) {
    super();
  }

  public openDialog(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withWidth('100%')
      .withMaxWidth('100vw')
      .withHeight('375px')
      .withPosition({bottom: '0px'})
      .withClass('transparent')
      .openDialog<MobileSelectorComponent, MobileSelectorDialogDataInterface, MobileSelectorOption>(MobileSelectorComponent, {
        title: this.dialogTitleKey,
        options: this.options,
        selectedOption: this.selectedOption
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((selectedOption: MobileSelectorOption | undefined) => {
        if (selectedOption) {
          this.selectedOption = selectedOption;
          this.selectorChange.emit(selectedOption);
        }
      });
  }
}
