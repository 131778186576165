import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShowOverlayForVirtualScrollerDirective} from './show-overlay-for-virtual-scroller.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowOverlayForVirtualScrollerDirective],
  exports: [ShowOverlayForVirtualScrollerDirective]
})
export class ShowOverlayForVirtualScrollerModule {}
