import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {BaseComponent} from '@vdw/angular-component-library';

@Directive()
export abstract class LoadImageDirective extends BaseComponent implements OnInit, OnChanges {
  @Input() public imageId: string;

  protected elementRef: ElementRef<HTMLElement>;
  private renderer: Renderer2;

  protected constructor(renderer: Renderer2, elementRef: ElementRef<HTMLElement>) {
    super();
    this.renderer = renderer;
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {
    this.setImageFromData('/assets/images/image-placeholder.svg');
  }

  protected setImageFromData(image: string): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'src', image);
  }

  public abstract ngOnChanges(): void;

  protected abstract downloadAndSetImage(...args: [string, string, number, number, DimensionsInPx]): void;

  protected abstract setDownloadedImage(blob: Blob): void;
}
