<mat-select [formControl]="ngControl.control" [(value)]="value" disableOptionCentering>
  <mat-select-trigger>
    <div class="container">
      <vdw-pattern *ngIf="ngControl.value" [pattern]="ngControl.value"></vdw-pattern>
      <h4 class="pattern-name">{{ 'ANGULAR_COMPONENT_LIBRARY.SELECT_PATTERN.' + patternEnum[ngControl?.value] | bmsTranslate }}</h4>
    </div>
  </mat-select-trigger>
  <mat-option *ngFor="let pattern of patterns" [attr.value]="pattern" [value]="pattern">
    <div class="container">
      <vdw-pattern *ngIf="pattern" [pattern]="pattern"></vdw-pattern>
      <h4 class="pattern-name">{{ 'ANGULAR_COMPONENT_LIBRARY.SELECT_PATTERN.' + patternEnum[pattern] | bmsTranslate }}</h4>
    </div>
  </mat-option>
</mat-select>
