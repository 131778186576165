import {isNil} from 'lodash-es';
import {BaseError} from './base-error';

export enum BackendErrorSeverity {
  FATAL,
  ERROR,
  WARNING,
  INFO
}

export class BackendError extends BaseError {
  public readonly severity: BackendErrorSeverity;
  public readonly errorContext: any;

  public constructor(severity: BackendErrorSeverity = BackendErrorSeverity.FATAL, message?: string, errorContext?: any) {
    super(message);
    this.severity = isNil(severity) ? BackendErrorSeverity.FATAL : severity;
    this.errorContext = errorContext;
  }
}
