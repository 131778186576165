<div class="full-height" fxLayout="column">
  <div fxLayout="row" fxFlex fxLayoutGap="16px">
    <div fxLayout="column" fxFlex>
      <vdw-tabs-menu-card
        [icon]="orderbookIconName"
        [title]="'ORDERBOOK.ORDERBOOK' | bmsTranslate"
        [hasPermissionToEdit]="hasCustomSettingsPermission()"
        (settingsEvent)="navigateToCustomSettings()"
      ></vdw-tabs-menu-card>
      <mat-sidenav-container class="full-height sidenav-border" [hasBackdrop]="false">
        <mat-sidenav #sidenav [opened]="isOrderLineSelected()" class="sidebar sidebar-large orderline-detail-container" mode="over" position="end" [fixedInViewport]="false" [autoFocus]="false">
          <app-orderline-details-sidebar *ngIf="isOrderLineSelected()" [orderLineId]="getSelectedOrderLineId()" (orderlineDetailsSidebarClose)="closeSelectedOrderLineDetails()">
          </app-orderline-details-sidebar>
        </mat-sidenav>
        <mat-sidenav-content class="overflow-auto" fxFlex fxLayout="column">
          <mat-card appearance="raised" fxLayout="column" class="orderbook-mat-card full-height" fxFlex>
            <mat-card-header class="flex-start-center">
              <div class="flex gap-16">
                <button
                  *appHasPermission="permissionToDeleteOrderLines"
                  mat-icon-button
                  class="mat-icon-action-button-lg-with-border remove-button"
                  [disabled]="!isOrderLineSelected()"
                  [matTooltip]="'GENERAL.ACTIONS.DELETE' | bmsTranslate"
                  (click)="deleteOrderLine()"
                >
                  <mat-icon svgIcon="trash"></mat-icon>
                </button>
                <button mat-icon-button class="mat-icon-action-button-lg-with-border margin-left" (click)="isExporting = true" [matTooltip]="'AGGRID.EXPORT' | bmsTranslate" [disabled]="isExporting">
                  <mat-icon svgIcon="export"></mat-icon>
                </button>
              </div>
            </mat-card-header>
            <mat-card-content fxFlex fxLayout="row">
              <article class="content" fxFlex fxLayout="column" fxLayoutGap="16px">
                <div fxFlex>
                  <app-order-lines-grid
                    #orderLinesGrid
                    [(selectedOrderLines)]="selectedOrderLines"
                    [target]="orderLinesGridTarget"
                    [advancedSearchFilters]="advancedSearchFilters"
                    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
                    [isExporting]="isExporting"
                    (exportComplete)="exportComplete()"
                  ></app-order-lines-grid>
                </div>
              </article>
            </mat-card-content>
          </mat-card>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
</div>
