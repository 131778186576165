<section class="full-width flex-column">
  <vdw-header [title]="getTitle() | bmsTranslate" svgIcon="select-blue" [canShowClose]="true"></vdw-header>
  <app-placeholder-parts
    class="dialog-content"
    #placeholderPartsComponent
    [placeholderParts]="placeholderParts"
    [listOfPlaceholderCategoriesWithComponents]="listOfPlaceholderCategoriesWithComponents"
    [separatorForTextPlaceholder]="separatorForTextPlaceholder"
    [headerTitle]="headerTitle"
    [specifyTitle]="specifyTitle"
    [columnTitle]="columnTitle"
    [filteredPlaceholderParts]="filteredPlaceholderParts"
    [requiredMaxLength]="requiredMaxLength"
    [isSerialNumberAllowedFreePosition]="isSerialNumberAllowedFreePosition"
  >
  </app-placeholder-parts>
  <vdw-footer [locked]="canDisableChooseButton()" [centered]="true" (chooseEvent)="save()"></vdw-footer>
</section>
