import {isEmpty, isEqual, size, truncate} from 'lodash-es';
import {AssertionUtils} from './assertion-utils';

export class StringUtils {
  public static slugifyString(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/&/g, '-and-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-');
  }

  public static stringEndingWithNumberComparator(valueA: string, valueB: string): number {
    if (!valueA && !valueB) {
      return 0;
    }

    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    if (StringUtils.endsWithNumber(valueA) && StringUtils.endsWithNumber(valueB) && valueA.split(' ')[0] === valueB.split(' ')[0]) {
      const numberA = Number(valueA.split(' ')[1]);
      const numberB = Number(valueB.split(' ')[1]);
      const numberComparison = numberA > numberB ? 1 : -1;
      return numberA === numberB ? 0 : numberComparison;
    }

    const upperValueA = valueA.toUpperCase();
    const upperValueB = valueB.toUpperCase();
    const comparison = upperValueA > upperValueB ? 1 : -1;
    return upperValueA === upperValueB ? 0 : comparison;
  }

  public static stringComparator(valueA: string, valueB: string): number {
    if (!valueA && !valueB) {
      return 0;
    }

    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    const upperValueA = valueA.toUpperCase();
    const upperValueB = valueB.toUpperCase();
    const comparison = upperValueA > upperValueB ? 1 : -1;
    return isEqual(upperValueA, upperValueB) ? 0 : comparison;
  }

  public static truncateString(text: string, length: number): string {
    return truncate(text, {length});
  }

  public static hasStringExceededGivenMaxSize(text: string, maxSize: number): boolean {
    return size(text) > maxSize;
  }

  public static isEqualIgnoreCase(text1: string, text2: string): boolean {
    return isEqual(text1.toLocaleLowerCase(), text2.toLocaleLowerCase());
  }

  public static createNameWithVersion(name: string, version: number): string {
    let result = '';

    if (!isEmpty(name)) {
      result = name;
      if (version) {
        result += ` (v${version})`;
      }
    }

    return result;
  }

  public static endsWithNumber(string: string): boolean {
    return !isNaN(parseInt(string.slice(-1)[0], 10));
  }

  public static htmlToText(html: string): string {
    let htmlText = html?.split('<br>').join('\n');
    htmlText = htmlText?.split('&nbsp;').join(' ');

    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlText;

    return tempElement.innerText === 'undefined' ? undefined : tempElement.innerText;
  }

  public static textToHtml(text: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerText = text;

    return tempElement.innerHTML === 'undefined' ? undefined : tempElement.innerHTML;
  }

  public static kebabCase(string: string): string {
    const kebabRegex = /(\d+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g;
    if (AssertionUtils.isNullOrUndefined(string)) {
      return string;
    }
    return (string.match(kebabRegex) ?? []).map((section: string) => section.toLocaleLowerCase()).join('-');
  }

  public static dotLargeNumber(number: number): string {
    // conversion eg. 10000.1 -> 10.000,1
    // not used anywhere yet
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }
}
