export enum TagColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  AQUA = 'aqua',
  TEAL = 'teal',
  YELLOW = 'yellow',
  PINK = 'pink',
  PURPLE = 'purple',
  VIOLET = 'violet'
}
