<mat-icon
  class="arrow"
  svgIcon="atoms-arrow"
  [style.left.px]="arrowLeftPositionInPx"
  [style.top.px]="arrowTopPositionInPx"
  [class.blue-arrow]="canShowBlueArrow"
  [class.top]="isArrowPositionedAtTop()"
></mat-icon>
<section class="dialog-container" fxFlex fxLayout="column">
  <header #dialogHeader class="dialog-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon class="title-icon" svgIcon="info-52px"></mat-icon>
      <h3 fxFlex class="dialog-title">{{ getTitleKey() | bmsTranslate }}</h3>
      <mat-icon *ngIf="!hasOnboardingSteps()" appDataCy="onboarding-finish-button" class="close-icon" svgIcon="close" (click)="finishTour()"></mat-icon>
    </div>
  </header>
  <ng-container *ngIf="hasOnboardingSteps()">
    <article class="dialog-content" fxLayout="row">
      <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutGap="8px">
            <h3>{{ getStepTitleKey() | bmsTranslate }}</h3>
            <p class="b1 hint">{{ getStepDescriptionKey() | bmsTranslate }}</p>
          </div>
        </div>
        <div fxLayout="row" class="features" *ngIf="canShowFeatures()">
          <div fxLayout="column" fxLayoutGap="16px">
            <h4>{{ 'ONBOARDING.FEATURES_UNDER_TAB' | bmsTranslate }}</h4>
            <hr class="separator" />
            <div fxLayout="row">
              <ul class="list" fxLayout="column" fxLayoutGap="16px">
                <li class="feature" *ngFor="let feature of onboardingStepFeatures">{{ feature | bmsTranslate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </article>
    <vdw-footer
      cancelEventName="ONBOARDING.SKIP_TIPS"
      [chooseEventName]="getChooseEventName()"
      [centered]="true"
      [withCancelButton]="canGoToNextStep()"
      (cancelEvent)="finishTour()"
      (chooseEvent)="chooseEvent()"
    >
    </vdw-footer>
  </ng-container>
</section>
