import {Drawing} from '@domain/production-schedule/drawing';
import {ListDrawing} from './list-drawing';

export class ListDrawingJute implements ListDrawing {
  private _drawing: Drawing;
  private _amount: number;

  public constructor(drawing: Drawing) {
    this._drawing = drawing;
    this._amount = 1;
  }

  public get id(): string {
    return '';
  }

  public get drawing(): Drawing {
    return this._drawing;
  }

  public set drawing(drawing: Drawing) {
    this._drawing = drawing;
  }

  public get amount(): number {
    return this._amount;
  }
}
