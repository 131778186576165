import {isMultipleOf} from '@application/validators/is-multiple-of-validator';
import {Pattern} from '@domain/pattern';
import {JutePosition} from '@domain/production-schedule/jute-position.enum';
import {isNil} from 'lodash-es';

export class JuteInformationForPart {
  private _position: JutePosition;
  private _commercialHeightInMM: number;
  private _technicalHeightInPicks: number;
  private _pattern: Pattern;

  public constructor(position: JutePosition, commercialHeightInMM: number, technicalHeightInPicks: number, pattern: Pattern) {
    this._position = position;
    this._commercialHeightInMM = commercialHeightInMM;
    this._technicalHeightInPicks = technicalHeightInPicks;
    this._pattern = pattern;
  }

  public get position(): JutePosition {
    return this._position;
  }

  public set position(value: JutePosition) {
    this._position = value;
  }

  public get commercialHeightInMM(): number {
    return this._commercialHeightInMM;
  }

  public set commercialHeightInMM(value: number) {
    this._commercialHeightInMM = value;
  }

  public get technicalHeightInPicks(): number {
    return this._technicalHeightInPicks;
  }

  public set technicalHeightInPicks(value: number) {
    this._technicalHeightInPicks = value;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public set pattern(value: Pattern) {
    this._pattern = value;
  }

  public static fromJSON(juteInformationJSON: any): JuteInformationForPart {
    return new JuteInformationForPart(
      juteInformationJSON.position,
      juteInformationJSON.commercialHeightInMM,
      juteInformationJSON.technicalHeightInPicks,
      Pattern.fromJSON(juteInformationJSON.pattern)
    );
  }

  public static isValid(juteInformationForPart: JuteInformationForPart, multiple: number): boolean {
    return (
      !isNil(juteInformationForPart.position) &&
      juteInformationForPart.technicalHeightInPicks > 0 &&
      isMultipleOf(juteInformationForPart.technicalHeightInPicks, multiple) &&
      !isNil(juteInformationForPart.commercialHeightInMM) &&
      juteInformationForPart.commercialHeightInMM > 0 &&
      !isNil(juteInformationForPart.pattern)
    );
  }

  public toJSON(): JSON {
    return {
      position: this.position,
      commercialHeightInMM: this.commercialHeightInMM,
      technicalHeightInPicks: this.technicalHeightInPicks,
      pattern: this.pattern?.toJSON() ?? null
    } as any as JSON;
  }
}
