import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCardModule, MAT_CARD_CONFIG} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslationModule} from '../../translation/translation.module';
import {HeaderModule} from '../header/header.module';
import {TabsMenuModule} from '../tabs-menu/tabs-menu.module';
import {TabsMenuCardComponent} from './tabs-menu-card.component';

@NgModule({
  declarations: [TabsMenuCardComponent],
  imports: [CommonModule, HeaderModule, TabsMenuModule, TranslationModule, MatCardModule, MatIconModule, MatTooltipModule, MatDividerModule],
  exports: [TabsMenuCardComponent],
  providers: [
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined'
      }
    }
  ]
})
export class TabsMenuCardModule {}
