import {CarpetLoomMachine} from '@domain/machine/carpet-loom-machine';
import {CarpetLoomNoJacquardMachine} from '@domain/machine/carpet-loom-no-jacquard-machine';
import {ColortecMachine} from '@domain/machine/colortec-machine';
import {ColorteclplusMachine} from '@domain/machine/colorteclplus-machine';
import {DyeingMachine} from '@domain/machine/dyeing-machine';
import {ExtrusionMachine} from '@domain/machine/extrusion-machine';
import {FinishingMachine} from '@domain/machine/finishing-machine';
import {JacquardMachine} from '@domain/machine/jacquard-machine';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MyriadMachine} from '@domain/machine/myriad-machine';
import {PlasticsMachine} from '@domain/machine/plastics-machine';
import {SuperbadyeMachine} from '@domain/machine/superbadye-machine';
import {SuperbaheatMachine} from '@domain/machine/superbaheat-machine';
import {SuperbawinderMachine} from '@domain/machine/superbawinder-machine';
import {TuftMachine} from '@domain/machine/tuft-machine';
import {UnconfiguredMachine} from '@domain/machine/unconfigured-machine';
import {VelvetMachine} from '@domain/machine/velvet-machine';
import {VelvetNoJacquardMachine} from '@domain/machine/velvet-no-jacquard-machine';
import {WeavingMachine} from '@domain/machine/weaving-machine';
import {WinderMachine} from '@domain/machine/winder-machine';

const machineClassFromMachineTypeMap = new Map<MachineType, any>([
  [MachineType.CARPET_LOOM, CarpetLoomMachine],
  [MachineType.CARPET_LOOM_NO_JQ, CarpetLoomNoJacquardMachine],
  [MachineType.WEAVING_MACHINE, WeavingMachine],
  [MachineType.COLORTEC, ColortecMachine],
  [MachineType.COLORTECLPLUS, ColorteclplusMachine],
  [MachineType.TUFT, TuftMachine],
  [MachineType.MYRIAD, MyriadMachine],
  [MachineType.JACQUARD, JacquardMachine],
  [MachineType.EXTRUSION, ExtrusionMachine],
  [MachineType.FINISHING, FinishingMachine],
  [MachineType.DYEING, DyeingMachine],
  [MachineType.SUPERBADYE, SuperbadyeMachine],
  [MachineType.SUPERBAHEAT, SuperbaheatMachine],
  [MachineType.SUPERBAWINDER, SuperbawinderMachine],
  [MachineType.UNDEFINED, UnconfiguredMachine],
  [MachineType.VELVET, VelvetMachine],
  [MachineType.VELVET_NO_JQ, VelvetNoJacquardMachine],
  [MachineType.WINDER, WinderMachine],
  [MachineType.PLASTIC_MACHINE, PlasticsMachine]
]);

export function getMachineClassFromMachineType(machineType: MachineType): any {
  return machineClassFromMachineTypeMap.get(machineType);
}
