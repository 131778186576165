import {moment} from '../moment';
import {AssertionUtils} from '../utils/assertion-utils';

export class TimezoneUtils {
  public static convertToDateWithCurrentOffset(dateTimeString: string, timezoneCode: string): Date {
    let date: Date = null;

    if (!AssertionUtils.isNullOrUndefined(dateTimeString)) {
      const momentDate = moment(dateTimeString);
      const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();
      date = momentDate.add(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toDate();
    }

    return date;
  }

  public static convertToISOStringWithoutCurrentOffset(date: Date, timezoneCode: string): string {
    const momentDate = moment(date);
    const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();

    return momentDate.subtract(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toISOString();
  }
}
