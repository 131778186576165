import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';

export class ArticleForSalesOrderLine {
  private _id: number;
  private _name: string;
  private _design: {
    shape: string;
    repeated: boolean;
  };

  public constructor(id: number, name: string, design: {shape: string; repeated: boolean}) {
    this._id = id;
    this._name = name;
    this._design = design;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get design(): {shape: string; repeated: boolean} {
    return this._design;
  }

  public static fromJSON(articleJSON: any): ArticleForSalesOrderLine {
    return new ArticleForSalesOrderLine(articleJSON.id, articleJSON.name, {
      shape: articleJSON.design.shape,
      repeated: articleJSON.design.repeated
    });
  }

  public static fromOverviewListArticle(article: OverviewListArticle): ArticleForSalesOrderLine {
    return new ArticleForSalesOrderLine(article.id, article.name, {
      shape: article.design.shape,
      repeated: article.design.repeated
    });
  }
}
