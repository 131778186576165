<div #container class="padding-container full-height bms-theme flex-column">
  <div class="header">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border back-button" tabindex="-1" (click)="back($event)" [matTooltip]="'back'" matTooltipPosition="above" matTooltipClass="bms-theme">
      <mat-icon class="back-icon" svgIcon="solid-arrow-back"></mat-icon>
    </button>
    <h2>{{ config.headerTitle }}</h2>
  </div>

  <vdw-crud-grid-with-actions class="padding-container" [rowData]="config.rowData" [withNewClicked]="config.withNewClicked" [gridOptions]="config.gridOptions"></vdw-crud-grid-with-actions>

  <div class="footer">
    @if (config.gridOptions?.rowSelection === 'multiple' && (selectedItemCount !== 0 || config.onlyShowSelected)) {
      <div class="footer-start">
        <vdw-slide-toggle class="toggle" [label]="showSelected" [checked]="config.onlyShowSelected" (checkedChange)="toggleShowOnlySelected($event)"></vdw-slide-toggle>
        <div class="selected-item-count font-weight-semibold">{{ selectedItemCount }} {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.OF' | bmsTranslate }} {{ config.rowData?.length }}</div>
      </div>
    }

    <div class="footer-end">
      <button mat-button (click)="closeDialog()" tabindex="-1">{{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CANCEL' | bmsTranslate }}</button>
      <button [class]="'action-button ' + CHOOSE_BUTTON_CLASS_NAME" mat-flat-button (click)="select()" [disabled]="getGridSelectedRows === 0">
        {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CONFIRM_SELECTION' | bmsTranslate }}
      </button>
    </div>
  </div>
</div>
