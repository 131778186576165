import {Component, Inject, OnInit} from '@angular/core';
import {distinctUntilChanged, interval, merge, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {InterfaceOf} from '../../../common/types/interface-of';
import type {PlanningGridComponent} from '../planning-grid/planning-grid.component';
import {PLANNING_GRID_TOKEN} from '../planning-grid/planning-grid.token';

@Component({
  selector: 'vdw-current-time-indicator',
  templateUrl: './current-time-indicator.component.html',
  styleUrls: ['./current-time-indicator.component.scss']
})
export class CurrentTimeIndicatorComponent extends BaseComponent implements OnInit {
  private _width: number;
  private _shouldIndicatorBeRendered: boolean;

  public get width(): number {
    return this._width;
  }

  public get shouldIndicatorBeRendered(): boolean {
    return this._shouldIndicatorBeRendered;
  }

  public constructor(@Inject(PLANNING_GRID_TOKEN) private readonly grid: InterfaceOf<PlanningGridComponent>) {
    super();
  }

  public ngOnInit(): void {
    merge(this.grid.onChanges.pipe(distinctUntilChanged()), interval(this.grid.MILLISECONDS_IN_MINUTE))
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.updateCurrentDate());
  }

  public updateCurrentDate(): void {
    this._width = this.grid.getLeftPercentForDate(this.grid.currentTime);
    this._shouldIndicatorBeRendered = this._width > 0;
  }
}
