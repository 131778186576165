<div
  class="overflow-auto container"
  cdkDrag
  cdkDropList
  cdkDropListSortingDisabled
  [cdkDragData]="dropListEntry"
  [cdkDropListData]="dropListEntry"
  (cdkDragStarted)="dragStarted()"
  (cdkDropListEntered)="dragEntered()"
  (cdkDropListDropped)="dropped($event)"
  [cdkDropListConnectedTo]="linkedDropLists"
  [cdkDragDisabled]="!dropListEntry?.dragAllowed"
  [class.drop-not-allowed]="!dropListEntry?.dropAllowed || !calculatedDropAllowed"
>
  <div class="template-container">
    <ng-container [ngTemplateOutlet]="dropListContent" [ngTemplateOutletContext]="{data: dropListEntry}"> </ng-container>
  </div>

  <mat-expansion-panel
    *ngIf="!isEmpty"
    class="full-width expansion-panel"
    hideToggle="true"
    disabled="true"
    [expanded]="dropListEntry.isExpanded"
    [class.empty-expansion-panel-body]="!dropListEntry.isExpanded"
  >
    <ng-template matExpansionPanelContent class="flex-column">
      <div class="flex-column child-container">
        <vdw-cdk-draggable-drop-list
          *ngFor="let childEntry of dropListEntry.childEntries"
          (entryDropped)="childEntryDropped($event)"
          [dropListEntry]="childEntry"
          [dropListContent]="dropListContent"
          [linkedDropLists]="linkedDropLists"
          [dragPreviewContent]="dragPreviewContent"
        ></vdw-cdk-draggable-drop-list>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <div *cdkDragPreview>
    <ng-container [ngTemplateOutlet]="dragPreviewContent" [ngTemplateOutletContext]="{data: dropListEntry}"></ng-container>
  </div>
</div>
