import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PropertyValue} from '@domain/property-value';
import {WeftSelection} from '@domain/textile-data/machine-quality/weft-selection';
import {WeaveStructure} from '@domain/textile-data/weave-structure/weave-structure';
import {WeaveStructureDetails} from '@domain/textile-data/weave-structure/weave-structure-details';
import {WeaveStructureForMachineQuality} from '@domain/textile-data/weave-structure/weave-structure-for-machine-quality';
import {ExtendedHttpParams} from '@infrastructure/http/extended-http-params';
import {WeaveStructureNameCheck} from '@infrastructure/http/weave-structure/weave-structure-name-check.interface';
import {GetAllWeaveStructuresParameters, WeaveStructures} from '@infrastructure/http/weave-structure/weave-structures';
import {OverviewListWeaveStructure} from '@presentation/pages/textile-data/weave-structure/overview/overview-list-weave-structure';
import {Conflict, GridModel} from '@vdw/angular-component-library';
import {isNil, map, orderBy} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {WeftSelectionSuggestions} from './weft-selection-suggestions.interface';

@Injectable()
export class HttpWeaveStructuresService implements WeaveStructures {
  private readonly VARIABLE_NAMES_TO_HTTP_PARAMS_MAP = new Map<string, string>([
    ['showOnlyLatestVersion', 'only-latest-versions'],
    ['numberOfCreelPositions', 'creel-positions'],
    ['isActive', 'active']
  ]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<WeaveStructureForMachineQuality[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}weavestructures`, {params})
      .pipe(rxjsMap((weaveStructuresJSON: any) => weaveStructuresJSON.map((weaveStructureJSON: any) => WeaveStructureForMachineQuality.fromShortJSON(weaveStructureJSON))));
  }

  public get(gridModel: GridModel, additionalParams: any): Observable<WeaveStructureForMachineQuality[]> {
    const parameters: Partial<GetAllWeaveStructuresParameters> = additionalParams;
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}weavestructures/get`, gridModel.toJSON(), {params})
      .pipe(rxjsMap((weaveStructuresJSON: any) => weaveStructuresJSON.map((weaveStructureJSON: any) => WeaveStructureForMachineQuality.fromShortJSON(weaveStructureJSON))));
  }

  public getAllOverviewListWeaveStructures(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<OverviewListWeaveStructure[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}weavestructures`, {params})
      .pipe(rxjsMap((weaveStructuresJSON: any) => weaveStructuresJSON.map((weaveStructureJSON: any) => OverviewListWeaveStructure.fromJSON(weaveStructureJSON))));
  }

  public getById(id: number): Observable<WeaveStructureForMachineQuality> {
    return undefined;
  }

  public manageDelete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}weavestructures/manage/${id}`);
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}weavestructures/${id}`);
  }

  public save(item: WeaveStructureForMachineQuality): Observable<number> {
    return undefined;
  }

  public update(item: WeaveStructureForMachineQuality): Observable<void> {
    return undefined;
  }

  public getCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/custom-settings`).pipe(rxjsMap((response: PropertyValue[]) => response));
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}weavestructures/custom-settings`, chosenSettings);
  }

  public manageSaveOrUpdateSimple(item: WeaveStructure, wvsxFile: File): Observable<number> {
    const formDataToUpload = new FormData();
    formDataToUpload.append('data', JSON.stringify(item.toJSON()));
    if (!isNil(wvsxFile)) {
      formDataToUpload.append('file', wvsxFile, wvsxFile.name);
    }

    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}weavestructures/manage`, formDataToUpload).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public manageGetAllOverviewListWeaveStructures(parameters?: Partial<GetAllWeaveStructuresParameters>): Observable<OverviewListWeaveStructure[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/manage`, {params}).pipe(
      rxjsMap((weaveStructuresJSON: any) => {
        return orderBy(
          map(weaveStructuresJSON, (weaveStructureJSON: any) => OverviewListWeaveStructure.fromJSON(weaveStructureJSON)),
          ['name', 'version'],
          ['asc', 'desc']
        );
      })
    );
  }

  public getByIdExtended(id: number): Observable<WeaveStructure> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/${id}`).pipe(rxjsMap((weaveStructureJSON: any) => WeaveStructure.fromJSON(weaveStructureJSON)));
  }

  public manageGetByIdExtended(id: number): Observable<WeaveStructure> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/manage/${id}`).pipe(rxjsMap((weaveStructureJSON: any) => WeaveStructure.fromJSON(weaveStructureJSON)));
  }

  public parseDescription(name: string, description: string): Observable<WeaveStructureDetails> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/manage/parse-description?name=${name}&description=${encodeURIComponent(description)}`).pipe(
      rxjsMap((weaveStructureDetailsJSON: any) => {
        return WeaveStructureDetails.fromJSON(weaveStructureDetailsJSON);
      })
    );
  }

  public getFactorForConversionFromPicksToWefts(weaveStructureId: number): Observable<number> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}weavestructures/${weaveStructureId}/factor-for-conversion-from-picks-to-wefts`)
      .pipe(rxjsMap((result: {factorForConversionFromPicksToWefts: number}) => result.factorForConversionFromPicksToWefts));
  }

  public getWeftSelectionSuggestions(weaveStructureId: number): Observable<WeftSelectionSuggestions> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/${weaveStructureId}/cols-for-weft-selection-suggestion`).pipe(
      rxjsMap((result: {nrColumnsToDropForWeftSelection: number; weftSelections: any}) => {
        return {
          numberColumnsToDropForWeftSelectionSuggestion: result.nrColumnsToDropForWeftSelection,
          weftSelections: result.weftSelections?.map((weftSelectionJSON: any) => WeftSelection.fromJSON(weftSelectionJSON)) ?? []
        };
      })
    );
  }

  public duplicateWeaveStructure(weaveStructureId: number, name: string): Observable<number> {
    const requestBody = {
      name,
      duplicatedFromWeaveStructureId: weaveStructureId,
      version: '1'
    };
    return this.httpClient.post<{id: number}>(`${this.backendConfiguration.getEndpoint()}weavestructures/duplicate`, requestBody).pipe(rxjsMap((result: {id: number}) => result.id));
  }

  public isNameUnavailableOrReserved(name: string): Observable<WeaveStructureNameCheck> {
    const params = new HttpParams().set('name', name);
    return this.httpClient.get<WeaveStructureNameCheck>(`${this.backendConfiguration.getEndpoint()}weavestructures/check-name`, {params});
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weavestructures/${id}/conflicts`).pipe(
      rxjsMap((conflictsJSON: any) => {
        return conflictsJSON.map((conflictJSON: any) => {
          return Conflict.fromJSON(conflictJSON);
        });
      })
    );
  }
}
