import {PositionOfDialog} from '@domain/position-of-dialog';
import {includes, isEqual, isNil} from 'lodash-es';

export class SourceElementUtils {
  public static findSourceElementForDialog(event: MouseEvent, className: string, positionOfDialog: PositionOfDialog = null): Element {
    let source: Element = event.target as Element;
    while (!includes(source.classList, className) && !isNil(source.parentElement)) {
      source = source.parentElement;
    }
    return source.children.item(!isNil(positionOfDialog) && isEqual(positionOfDialog, PositionOfDialog.LEFT) ? 0 : source.childElementCount - 1);
  }
}
