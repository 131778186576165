import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {BackgroundJob} from '@domain/background-jobs/background-job';
import {BackgroundJobGlobalStatusUpdate} from '@domain/background-jobs/background-job-global-status-update';
import {Subscription} from '@domain/profile/subscription';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {BackgroundJobs} from '@infrastructure/http/background-jobs/background-jobs';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpBackgroundJobsService implements BackgroundJobs {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(
    @Inject(AUTHENTICATION) private authentication: Authentication,
    httpClient: HttpClient,
    backendConfiguration: BackendConfiguration
  ) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public getAll(): Observable<BackgroundJob[]> {
    return this.httpClient.get(`${this.backendConfiguration.getWorkerEndpoint()}jobs/recent-and-active`).pipe(
      rxjsMap((backgroundJobsJSON: any) => {
        const currentSubscription: Subscription = this.authentication.getCurrentSubscription();
        return map(backgroundJobsJSON, (backgroundJobJSON: any) => {
          return BackgroundJob.fromJSON(backgroundJobJSON, currentSubscription);
        });
      })
    );
  }

  public retry(backgroundJob: BackgroundJob): Observable<void> {
    return this.httpClient.post<void>(`${this.backendConfiguration.getWorkerEndpoint()}jobs/${backgroundJob.id}/retry`, {});
  }

  public getGlobalStatus(): Observable<BackgroundJobGlobalStatusUpdate> {
    return this.httpClient.get(`${this.backendConfiguration.getWorkerEndpoint()}jobs/status`).pipe(
      rxjsMap((backgroundJobGlobalStatusUpdate: any) => {
        return BackgroundJobGlobalStatusUpdate.fromJSON(backgroundJobGlobalStatusUpdate);
      })
    );
  }

  public cancelJob(jobId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.backendConfiguration.getWorkerEndpoint()}jobs/${jobId}/cancel`, {});
  }
}
