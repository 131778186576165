import {MachineType} from '@domain/machine/machine-type.enum';
import {TimezoneUtils} from '@vdw/angular-component-library';
import {PlanningItem} from '../planning-item';
import {PlanningItemType} from '../planning-item-type.enum';

export class PlanningMachineWarpChange extends PlanningItem {
  public constructor(id: number, startDate: Date, endDate: Date) {
    super(id, startDate, endDate, PlanningItemType.WARP_CHANGE);
  }

  public static fromJSON(planningMachineCreelChangeJSON: any, timezoneCode: string): PlanningMachineWarpChange {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineCreelChangeJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineCreelChangeJSON.endDate, timezoneCode);

    return new PlanningMachineWarpChange(planningMachineCreelChangeJSON.id, startDate, endDate);
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      id: this.id,
      startDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._startDate, timezoneCode),
      endDate: TimezoneUtils.convertToISOStringWithoutCurrentOffset(this._endDate, timezoneCode),
      type: PlanningItemType[this.type]
    } as any as JSON;
  }

  public isCompatibleWithMachineType(machineType: MachineType): boolean {
    return machineType === MachineType.WEAVING_MACHINE;
  }
}
