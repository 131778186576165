export class DrawingForArticle {
  private _id: number;
  private _name: string;
  private _shape: string;
  private _repeated: boolean;

  public constructor(id: number, name: string, shape: string, repeated: boolean) {
    this._id = id;
    this._name = name;
    this._shape = shape;
    this._repeated = repeated;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get shape(): string {
    return this._shape;
  }

  public get repeated(): boolean {
    return this._repeated;
  }

  public static fromJSON(drawingForArticleJSON: any): DrawingForArticle {
    return new DrawingForArticle(drawingForArticleJSON.id, drawingForArticleJSON.name, drawingForArticleJSON.shape, drawingForArticleJSON.repeated);
  }
}
