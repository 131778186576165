<article class="content flex-column gap-24 full-width">
  <div class="grid-placeholder-parts" fxLayout="column">
    <ag-grid-angular #placeholderPartsGrid class="ag-theme-vdw" fxFill [gridOptions]="gridOptionsForPlaceholderParts"></ag-grid-angular>
  </div>

  <vdw-message-container *ngIf="canShowSerialNumberValueErrorMessage()" [messages]="serialNumberValueErrorMessage"> </vdw-message-container>

  <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px" class="add-custom-text">
    <mat-form-field class="no-subscript">
      <mat-label>{{ 'GENERAL.PLACEHOLDER.ADD_CUSTOM_PLACEHOLDER_NAME' | bmsTranslate }}</mat-label>
      <input matInput appDataCy="custom-text" [(ngModel)]="customTextValue" />
    </mat-form-field>
    <button mat-icon-button appDataCy="add-custom-text" class="mat-icon-action-button-lg-with-border" [disabled]="!canAddCustomText()" (click)="addCustomText()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </div>

  <div fxLayout="column" fxLayoutGap="8px" *ngIf="hasPlaceholderPartsSpecified()">
    <h4 class="font-weight-semibold">{{ 'GENERAL.PLACEHOLDER.PLACEHOLDER_PARTS_ARRANGE' | bmsTranslate }}</h4>

    <vdw-message-container *ngIf="canShowErrorMessage()" [messages]="errorMessage"> </vdw-message-container>

    <vdw-filter-chips
      [chips]="getFilteredEditedPlaceholderParts()"
      [labelGetter]="placeholderPartLabelGetter"
      [errorPredicate]="placeholderPartErrorPredicate"
      (droppedEvent)="dropPlaceholderComponent()"
      (deletedEvent)="removePlaceholderPart($event)"
    ></vdw-filter-chips>
  </div>

  <div fxLayout="column" fxLayoutGap="8px" *ngIf="hasPlaceholderPartsSpecified()">
    <h4 class="font-weight-semibold">{{ 'TEXTILE_DATA.FINISHING_TEMPLATE.LABEL.PREVIEW' | bmsTranslate }}</h4>

    <p class="b1 preview-of-placeholder-parts">{{ labelFromFilteredEditedPlaceholderComponents }}</p>
  </div>
</article>
