import {BackgroundStatus} from '@domain/background-jobs/background-status';
import {BackgroundTaskAction} from '@domain/background-jobs/background-task-action';
import {Subscription} from '@domain/profile/subscription';
import {moment, TimeUtils, TimezoneUtils} from '@vdw/angular-component-library';

export class BackgroundTask {
  private _id: string;
  private _action: BackgroundTaskAction;
  private _startTime: Date;
  private _endTime: Date;
  private _status: BackgroundStatus;

  public constructor(id: string, action: BackgroundTaskAction, startTime: Date, endTime: Date, status: BackgroundStatus) {
    this._id = id;
    this._action = action;
    this._startTime = startTime;
    this._endTime = endTime;
    this._status = status;
  }

  public get id(): string {
    return this._id;
  }

  public get action(): BackgroundTaskAction {
    return this._action;
  }

  public get startTime(): Date {
    return this._startTime;
  }

  public get endTime(): Date {
    return this._endTime;
  }

  public get durationInMs(): number {
    return moment(this._endTime).diff(this._startTime, TimeUtils.MILLISECONDS_UNIT);
  }

  public get status(): BackgroundStatus {
    return this._status;
  }

  public static fromJSON(backgroundTaskJSON: any, subscription: Subscription): BackgroundTask {
    const timezoneCode: string = subscription.timeZone.code;
    const startTime = TimezoneUtils.convertToDateWithCurrentOffset(backgroundTaskJSON.startTime, timezoneCode);
    const endTime = TimezoneUtils.convertToDateWithCurrentOffset(backgroundTaskJSON.endTime, timezoneCode);

    return new BackgroundTask(backgroundTaskJSON.id, BackgroundTaskAction[`${backgroundTaskJSON.action}`], startTime, endTime, BackgroundStatus[`${backgroundTaskJSON.status}`]);
  }
}
