import {Equipment} from '@domain/machine/equipment';
import {TuftingMachineType} from '@domain/machine/tufting-machine-type';
import {MachineType} from './machine-type.enum';

export class TuftMachine extends Equipment {
  private readonly _standardSpeed: number;
  private readonly _standardEfficiency: number;
  private readonly _tuftingMachineType: TuftingMachineType;

  public constructor(id: number, name: string, orderId: string, standardSpeed: number, standardEfficiency: number, tuftingMachineType: TuftingMachineType) {
    super(id, MachineType.TUFT, name, orderId);
    this._standardEfficiency = standardEfficiency;
    this._standardSpeed = standardSpeed;
    this._tuftingMachineType = tuftingMachineType;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public get tuftingMachineType(): TuftingMachineType {
    return this._tuftingMachineType;
  }

  public static fromJSON(tuftingMachineJSON: any): TuftMachine {
    return new TuftMachine(
      tuftingMachineJSON.id,
      tuftingMachineJSON.name,
      tuftingMachineJSON.orderId,
      tuftingMachineJSON.standardSpeed,
      tuftingMachineJSON.standardEfficiency,
      tuftingMachineJSON.tuftingMachineType === undefined ? null : TuftingMachineType.fromJSON(tuftingMachineJSON.tuftingMachineType)
    );
  }

  public toJSON(): JSON {
    const tuftingMachineJSON = super.toJSON();
    tuftingMachineJSON['standardSpeed'] = this._standardSpeed;
    tuftingMachineJSON['standardEfficiency'] = this._standardEfficiency;
    tuftingMachineJSON['tuftingMachineTypeId'] = this._tuftingMachineType?.id;
    return tuftingMachineJSON;
  }
}
