import {IdName} from './id-name';

export class IdNameVersion extends IdName {
  private readonly _version: number;

  public constructor(id: number, name: string, version: number) {
    super(id, name);
    this._version = version;
  }

  public get version(): number {
    return this._version;
  }

  public static fromJSON(object: any): IdNameVersion {
    return new IdNameVersion(object.id, object.name, object.version);
  }

  public static fromMachineQuality(object: any): IdNameVersion {
    return new IdNameVersion(object.id, object.technicalName, object.version);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      version: this.version
    } as any as JSON;
  }
}
