import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslationModule} from '../../translation/translation.module';
import {FooterModule} from '../footer/footer.module';
import {HeaderModule} from '../header/header.module';
import {ReloadRequiredComponent} from './reload-required.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatDialogModule, TranslationModule, FooterModule, HeaderModule],
  exports: [ReloadRequiredComponent],
  declarations: [ReloadRequiredComponent]
})
export class ReloadRequiredModule {}
