import {Component, Inject, OnInit} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {intComparator} from '@application/helper/int-comparator';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {StringUtils} from '@application/helper/string-utils';
import {Customer} from '@domain/customer/customer';
import {PageUrls} from '@domain/textile-data/page-urls';
import {Customers, CUSTOMERS} from '@infrastructure/http/customer/customers';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BaseComponent, ColDefBuilderFactoryService, LinkIconRendererComponent} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './customer-overview-page.component.html'
})
export class CustomerOverviewPageComponent extends BaseComponent implements OnInit {
  public listOfCustomers: Customer[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.CUSTOMER_OVERVIEW;

  private pageUrls: PageUrls;

  public constructor(
    @Inject(CUSTOMERS) public readonly customers: Customers,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.customers
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((customers: Customer[]) => {
        this.listOfCustomers = customers;
      });

    this.pageUrls = LastModifiedCardUtils.getPageUrls('customer');
  }

  public getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('number', true).withHeaderName('CUSTOMERS.NUMBER').withComparator(intComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('name', true)
        .withHeaderName('CUSTOMERS.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withField('addressDetails.streetName', true).withHeaderName('CUSTOMERS.STREET_NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('contactDetails.email', true).withHeaderName('CUSTOMERS.EMAIL').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('contactDetails.telephone', true).withHeaderName('CUSTOMERS.TELEPHONE').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('comments', true).withHeaderName('CUSTOMERS.COMMENTS').withComparator(StringUtils.stringComparator).build()
    ];
  }
}
