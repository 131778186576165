import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {ProductionOrderCarpetForPlanningOrder} from '@domain/planning/carpet/production-order-carpet-for-planning-order';
import {PropertyValue} from '@domain/property-value';
import {ProductionOrders, PRODUCTION_ORDERS} from '@infrastructure/http/production-order/production-orders';
import {ConfirmProductionScheduleOnDesktopCommand} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-command';
import {ConfirmProductionScheduleOnDesktopResult} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-result';
import {BaseComponent} from '@vdw/angular-component-library';
import {isEmpty, isEqual} from 'lodash-es';
import {finalize, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-planned-order-card',
  templateUrl: './planned-order-card.component.html',
  styleUrls: ['./planned-order-card.component.scss']
})
export class PlannedOrderCardComponent extends BaseComponent {
  @Input() public plannedOrder: ProductionOrderCarpetForPlanningOrder;
  @Output() public plannedOrderReconfirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public viewPlannedOrderDetails: EventEmitter<void> = new EventEmitter<void>();

  private listOfProductionOrdersCustomSettings: PropertyValue[] = [];
  private reconfirming = false;

  private readonly confirmProductionScheduleOnDesktopCommand: ConfirmProductionScheduleOnDesktopCommand;

  public constructor(
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    confirmProductionScheduleOnDesktopCommand: ConfirmProductionScheduleOnDesktopCommand
  ) {
    super();

    this.confirmProductionScheduleOnDesktopCommand = confirmProductionScheduleOnDesktopCommand;
  }

  public reconfirm(): void {
    if (!this.reconfirming) {
      this.reconfirming = true;
      if (isEmpty(this.listOfProductionOrdersCustomSettings)) {
        this.productionOrders
          .getListOfCustomSettings()
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe((listOfProductionOrdersCustomSettings: PropertyValue[]) => {
            this.listOfProductionOrdersCustomSettings = listOfProductionOrdersCustomSettings;
            this.getAndReconfirmProductionOrder();
          });
      } else {
        this.getAndReconfirmProductionOrder();
      }
    }
  }

  public viewDetails(): void {
    this.viewPlannedOrderDetails.emit();
  }

  private getAndReconfirmProductionOrder(): void {
    this.confirmProductionScheduleOnDesktopCommand
      .getAndConfirmProductionSchedule(this.plannedOrder.id, this.listOfProductionOrdersCustomSettings)
      .pipe(
        finalize(() => (this.reconfirming = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((confirmProductionScheduleOnDesktopResult: ConfirmProductionScheduleOnDesktopResult) => {
        if (isEqual(confirmProductionScheduleOnDesktopResult, ConfirmProductionScheduleOnDesktopResult.CONFIRMED)) {
          this.plannedOrderReconfirmed.emit();
        }
      });
  }
}
