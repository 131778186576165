import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToTextileUnitLinencount(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.NUMBER_METRIC:
      result = unitValue.value / 600;
      break;
    case Unit.DENIER:
      result = 15 / unitValue.value;
      break;
    case Unit.TEX:
      result = 1 / (0.6 * unitValue.value);
      break;
    case Unit.DECITEX:
      result = 1 / (0.06 * unitValue.value);
      break;
    case Unit.LINENCOUNT:
      result = unitValue.value;
      break;
    case Unit.LIBRA_POUNDS:
      result = 48 / unitValue.value;
      break;
    case Unit.ENGLISH_COTTON_COUNT:
      result = unitValue.value / 354.6;
      break;
    default:
      throw new TextileUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.LINENCOUNT}`);
  }

  return result;
}
