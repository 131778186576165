<div *ngIf="canShowColorPreview()" [class.rule-condition-color]="isRuleCondition" [class.multiple-selection]="hasMultipleSelection" class="container">
  <div class="color-preview no-flex-shrink" [style.background-color]="color.hexadecimalColorCode"></div>

  <div class="name-wrapper">
    <span class="link-icon-cell-name">{{ showRGBValue ? color.getRgbAsCommaSeparatedValue() : color.name }}</span>
    <div class="no-flex-shrink bms-theme">
      <a [routerLink]="editPageUrl">
        <button *ngIf="enableClickableName" mat-icon-button matPrefix class="mat-icon-action-button-sm-with-border ag-icon-button" type="button">
          <mat-icon svgIcon="solid-open-in-full"></mat-icon>
        </button>
      </a>
    </div>
  </div>
</div>
