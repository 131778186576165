import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {MaterialTypeOverviewList, OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {YarnSet} from '@domain/textile-data/yarn-set/yarn-set';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {flatMap, map, uniqBy} from 'lodash-es';

export class OverviewListYarnSet {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _creelPositions: OverviewListCreelPosition[];

  public constructor(id: number, name: string, creelPositions: OverviewListCreelPosition[]) {
    this._id = id;
    this._name = name;
    this._creelPositions = creelPositions;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get creelPositions(): OverviewListCreelPosition[] {
    return this._creelPositions;
  }

  public static fromJSON(yarnSetOverviewListJSON: any): OverviewListYarnSet {
    return new OverviewListYarnSet(
      yarnSetOverviewListJSON.id,
      yarnSetOverviewListJSON.name,
      map(yarnSetOverviewListJSON.creelPositions, (creelPositionJSON: any) => {
        return new OverviewListCreelPosition(
          map(creelPositionJSON.creel, (itemJSON: any) => {
            return new OverviewListYarnType(
              itemJSON.id,
              itemJSON.name,
              null,
              null,
              map(itemJSON.threads, (threadJSON: any) => {
                return {rawMaterial: threadJSON.rawMaterial};
              })
            );
          }),
          creelPositionJSON.creelPosition,
          null,
          null
        );
      })
    );
  }

  public static fromYarnSet(yarnSet: YarnSet): OverviewListYarnSet {
    return new OverviewListYarnSet(
      yarnSet.id,
      yarnSet.name,
      map(yarnSet.creelPositions, (creelPosition: CreelPosition, index: number) => {
        return OverviewListCreelPosition.fromCreelPosition(creelPosition, index + 1);
      })
    );
  }

  public getUniqueYarnTypes(): YarnType[] {
    return uniqBy(
      flatMap(this.creelPositions, (creelPosition: OverviewListCreelPosition) => {
        return creelPosition.items as any as YarnType[];
      }),
      'id'
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      creelPositions: map(this._creelPositions, (creelPosition: OverviewListCreelPosition) => {
        return {
          creelPosition: creelPosition.position,
          creel: map(creelPosition.items, (yarnType: MaterialTypeOverviewList) => yarnType.toJSON())
        } as any as JSON;
      })
    } as any as JSON;
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
