<div class="cell-wrapper full-width">
  <vdw-progress-button
    class="button"
    [ngClass]="{'button-small': isSmallView, 'button-large': !isSmallView}"
    color="primary"
    [inProgress]="isSaving"
    [label]="params.label"
    [iconName]="'add-small'"
    [buttonSize]="'SMALL'"
    [fullWidth]="true"
    (click)="valueChanged($event)"
    [disabled]="isButtonDisabled()"
  ></vdw-progress-button>
</div>
