<app-textile-data-overview
  iconForCard="sales-order"
  [listOfTextileData]="listOfSalesOrders"
  [columnDefs]="getColumnDefsForListOfSalesOrders()"
  [textileDataService]="salesOrders"
  [gridIdentifier]="GRID_IDENTIFIER"
  [textileDataType]="textileDataType.SALES_ORDER"
  (exportGridWithComplexColumns)="exportGridForSalesOrders($event)"
  [showSearchBar]="false"
></app-textile-data-overview>
