import {OverviewListArticleStubFactory} from '@application/helper/articles/overview-list-article-stub-factory';
import {ArticleForSalesOrderLine} from '@domain/sales-order/article-for-sales-order-line';
import {OrderLineForSalesOrder} from '@domain/sales-order/order-line-for-sales-order';

export class OrderLineForSalesOrderStubFactory {
  public static getRandomOrderLine(): OrderLineForSalesOrder {
    return this.getRandomOrderLineWithId(1);
  }

  public static getRandomOrderLineWithId(id: number): OrderLineForSalesOrder {
    return new OrderLineForSalesOrder(id, ArticleForSalesOrderLine.fromOverviewListArticle(OverviewListArticleStubFactory.getRandomArticleWithId(1)), 200, 0, 200, 0);
  }
}
