import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, RowNode} from 'ag-grid-community';
import {forkJoin, Observable} from 'rxjs';
import {filter, finalize, switchMap, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../base-component';
import {Conflict} from '../../common/data/conflict';
import {Message} from '../../common/data/message';
import {MessageType} from '../../common/data/message-type.enum';
import {ObjectActionType} from '../../common/object-action-type.enum';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {SaveType} from '../../custom-components/header/save-type.enum';
import {ToastHelperService} from '../../custom-components/toast/helper/toast-helper.service';
import {BackendError} from '../../error/backend-error';
import {FormValidationHelper} from '../../forms/form-validation-helper';
import {ColDefBuilderFactoryService} from '../../grids/col-def-builder-factory.service';
import {GridOptionsBuilderFactoryService} from '../../grids/grid-options-builder-factory.service';
import {NoDataOverlayComponentParams} from '../../grids/overlay/no-data-overlay/no-data-overlay-component-params';
import {OverlayComponentParams} from '../../grids/overlay/overlay-component-params';
import {TranslateService} from '../../translation/translate.service';
import {AlertDialogResult} from '../alert-dialog/alert-dialog-data';
import {ConflictsDialogData} from '../conflicts-dialog/conflicts-dialog-data';
import {ConflictsDialogComponent} from '../conflicts-dialog/conflicts-dialog.component';
import {DialogBuilderFactoryService} from '../dialog-builder/dialog-builder-factory.service';
import {DialogType} from '../dialog-type';
import {SelectObjectsDialogComponentMatDialogConfigDataInterface} from './select-objects-dialog-component-mat-dialog-config.interface';
import {VisibleContentForSelectAddObject} from './visible-content-for-select-add-object.enum';

@Component({
  selector: 'vdw-select-objects-dialog-dialog',
  templateUrl: './select-objects-dialog.component.html',
  styleUrls: ['./select-objects-dialog.component.scss']
})
export class SelectObjectsDialogComponent<T> extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('optionsForListOfObjectsGrid') public optionsForListOfObjectsGrid: AgGridAngular;

  public readonly SAVE = SaveType.SAVE;
  public readonly SAVE_AND_CREATE_NEW = SaveType.SAVE_AND_CREATE_NEW;
  public listOfObjects: T[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public objectInUseWarningMessage: Message[];
  public addNewObjectForm: FormGroup<{
    name: FormControl<string>;
  }>;

  public gridIdentifier: string;
  public objectTranslationKey: string;
  public selectedObject: T;
  public visibleContent: VisibleContentForSelectAddObject = VisibleContentForSelectAddObject.SELECT_OBJECT;

  private isEditing = false;
  private itemToEdit: T;
  private previousCheckboxSelection = false;
  private selectedObjectId: number;
  private readonly getAllObjects: Observable<T[]>;
  private readonly saveNewObject: (item: string) => Observable<number>;
  private readonly deleteObject: (id: number) => Observable<void>;
  private readonly updateObject: (item: T) => Observable<void>;
  private readonly deleteMultipleObjects: (ids: number[]) => Observable<any[]>;
  private readonly asyncUniqueValidator: (name: string) => AsyncValidatorFn;
  private readonly getConflicts: (id: number) => Observable<any[]>;
  private readonly conflictType: any;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: SelectObjectsDialogComponentMatDialogConfigDataInterface<T>,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialogRef: MatDialogRef<SelectObjectsDialogComponent<T>>,
    private readonly formBuilder: FormBuilder,
    private readonly toastHelperService: ToastHelperService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();

    this.gridIdentifier = data.gridIdentifier;
    this.objectTranslationKey = data.objectTranslationKey;
    this.selectedObject = data?.selectedObject;
    this.getAllObjects = data.getAllObjects;
    this.saveNewObject = data.saveNewObject;
    this.deleteObject = data?.deleteObject ?? undefined;
    this.deleteMultipleObjects = data?.deleteMultipleObjects ?? undefined;
    this.asyncUniqueValidator = data.asyncUniqueValidator;
    this.updateObject = data.updateObject ?? undefined;
    this.getConflicts = data.getConflicts;
    this.conflictType = data.conflictType;
  }

  public get gridOptionsForListOfObjects(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.objectInUseWarningMessage = [
      new Message(null, this.translate.instant('CONFLICTS.IN_USE', {object: this.translate.instant(this.objectTranslationKey, {count: 1})}), MessageType.WARNING, null)
    ];
    if (this.selectedObject) {
      this.selectedObjectId = this.selectedObject['id'];
    }

    this.loadObjects();
    this.setGridOptionsForListOfObjects();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.optionsForListOfObjectsGrid?.api];
  }

  public canShowSelectObject(): boolean {
    return this.visibleContent === VisibleContentForSelectAddObject.SELECT_OBJECT;
  }

  public canShowAddObject(): boolean {
    return this.visibleContent === VisibleContentForSelectAddObject.ADD_OBJECT;
  }

  public isObjectSelected(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.optionsForListOfObjectsGrid?.api) && this.optionsForListOfObjectsGrid?.api.getSelectedRows().length > 0;
  }

  public isMultiSelection(): boolean {
    return this.optionsForListOfObjectsGrid?.api?.getSelectedRows().length > 1;
  }

  public selectObject(): void {
    this.dialogRef.close(this.optionsForListOfObjectsGrid.api.getSelectedRows());
  }

  public close(): void {
    this.canShowAddObject() ? this.goToSelectObjectContent() : this.dialogRef.close();
  }

  public getActionText(): string {
    let actionKey: string;

    if (this.canShowSelectObject()) {
      actionKey = 'GENERAL.ACTIONS.SELECT_OBJECT';
    } else {
      actionKey = this.isEditing ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    }

    return this.translate.instant(actionKey, {
      object: this.translate.instant(this.objectTranslationKey, {count: 1}).toLowerCase()
    });
  }

  public getTitle(): string {
    return this.canShowAddObject() ? this.addNewObjectForm.value.name : null;
  }

  public getSvgIcon(): string {
    if (this.canShowSelectObject()) {
      return 'select-blue';
    }
    return this.isEditing ? 'details-blue' : 'add-blue';
  }

  public goToSelectObjectContent(): void {
    this.setVisibleContent(VisibleContentForSelectAddObject.SELECT_OBJECT);
    this.loadObjects();
    this.isEditing = false;
  }

  public goToAddObjectContent(name: string = null): void {
    this.setVisibleContent(VisibleContentForSelectAddObject.ADD_OBJECT);
    this.setFormFields(name);
  }

  public save(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForms([this.addNewObjectForm], this.document);
    if (isValid) {
      this.saving = true;

      if (this.isEditing) {
        this.itemToEdit['name'] = this.addNewObjectForm.value.name;
      }
      const request: Observable<void | number> = this.isEditing ? this.updateObject(this.itemToEdit) : this.saveNewObject(this.addNewObjectForm.value.name);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => this.showToastAndProceed(this.isEditing ? this.itemToEdit['id'] : id, saveType),
        error: (errorMessage: BackendError) =>
          this.showErrorDialogForBackendError(this.getActionTranslation(this.isEditing ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT'), errorMessage.message)
      });
    }
  }

  public onEdit(): void {
    this.isEditing = true;
    this.itemToEdit = this.optionsForListOfObjectsGrid?.api?.getSelectedRows()[0];

    this.goToAddObjectContent(this.itemToEdit['name']);

    if (this.itemToEdit['used']) {
      this.addNewObjectForm.disable();
    }
  }

  public hasDeleteMethod(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.deleteObject);
  }

  public hasEditMethod(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.updateObject);
  }

  public deleteSelectedObject(): void {
    const selectedRows = this.optionsForListOfObjectsGrid?.api?.getSelectedRows();
    const object = this.translate.instant(this.objectTranslationKey, {count: this.isMultiSelection() ? 2 : 1}).toLowerCase();

    this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {
          object: this.isMultiSelection() ? this.getMultiDeleteConfirmationMessage(selectedRows, this.objectTranslationKey) : selectedRows[0].name
        }),
        messageText: this.translate.instant('GENERAL.ACTIONS.CONFIRM_DELETE_DESCRIPTION'),
        entities: this.isMultiSelection()
          ? selectedRows.map((data: T) => ({
              name: data['name']
            }))
          : null,
        type: DialogType.CONFIRM_DELETE
      })
      .pipe(
        filter((result: AlertDialogResult) => result === AlertDialogResult.CONFIRM),
        switchMap(() => (this.isMultiSelection() ? this.deleteMultipleObjects(selectedRows.map((value: T) => value['id']) as number[]) : this.deleteObject(selectedRows[0].id))),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe({
        next: (multiDeleteResponses: any[]) => {
          if (this.isMultiSelection()) {
            this.handleMultiDeletionResponse(multiDeleteResponses, selectedRows);
          } else {
            this.showToastAndDeselect();
          }
        },
        error: (errorMessage: BackendError) => this.handleErrorResponse(errorMessage, selectedRows[0].id, object)
      });
  }

  private handleMultiDeletionResponse(multiDeleteResponses: any[], selectedRows: any[]): void {
    const succesfullDeletedIds = multiDeleteResponses.filter((multiDeleteResponse: any) => multiDeleteResponse.statusCode === 200).map((multiDeleteResponse: any) => multiDeleteResponse.id);

    if (succesfullDeletedIds.length !== selectedRows.length) {
      this.handleConflicts(multiDeleteResponses, succesfullDeletedIds, selectedRows);
    } else {
      this.showToastAndDeselect();
    }
  }

  private handleConflicts(multiDeleteResponses: any[], succesfullDeletedIds: number[], selectedRows: any[]): void {
    const unsuccesfullDeletedIds = multiDeleteResponses.filter((multiDeleteResponse: any) => !succesfullDeletedIds.includes(multiDeleteResponse.id));

    forkJoin(unsuccesfullDeletedIds.map((multiDeleteResponse: any) => this.getConflicts(multiDeleteResponse.id)))
      .pipe(
        switchMap((conflicts: Conflict[][]) => {
          const entities = selectedRows.filter((row: any) => !succesfullDeletedIds.includes(row.id)).map((row: any) => row.name);

          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('alert-dialog')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createCannotDeleteData(this.objectTranslationKey, entities, conflicts, this.conflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();

    this.showToastAndDeselect(succesfullDeletedIds.length > 0, succesfullDeletedIds.length > 1);
  }

  private handleErrorResponse(errorMessage: BackendError, selectemItemId: number, object: string): void {
    if (errorMessage.errorContext.errorCode === 'LINKED_ENTITIES') {
      this.showConflictsDialog(selectemItemId, errorMessage.errorContext.entityName);
    } else {
      this.showErrorDialogForBackendError(this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object}), errorMessage.message);
    }
  }

  private showConflictsDialog(id: number, entityName: string): void {
    this.getConflicts(id)
      .pipe(
        switchMap((conflicts: Conflict[]) => {
          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('alert-dialog')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createCannotDeleteData(this.objectTranslationKey, entityName, conflicts, this.conflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  private showToastAndDeselect(showToast: boolean = true, isMultiDelete: boolean = this.isMultiSelection()): void {
    if (showToast) {
      this.toastHelperService.showToastForObjectAction(ObjectActionType.DELETE, this.objectTranslationKey, null, isMultiDelete);
    }
    this.optionsForListOfObjectsGrid.api.deselectAll();
    this.showCheckboxIfNeeded();
    this.loadObjects();
  }

  private showToastAndProceed(id: number, saveType: SaveType): void {
    if (saveType === SaveType.SAVE) {
      this.toastHelperService.showToastForObjectAction(this.isEditing ? ObjectActionType.EDIT : ObjectActionType.CREATE, this.objectTranslationKey, this.addNewObjectForm.value.name);
      this.selectedObjectId = id;
      this.goToSelectObjectContent();
    } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
      this.toastHelperService.showToastForObjectAction(this.isEditing ? ObjectActionType.EDIT : ObjectActionType.CREATE, this.objectTranslationKey, this.addNewObjectForm.value.name);
      this.setFormFields();
      this.isEditing = false;
    }
  }

  private setVisibleContent(content: VisibleContentForSelectAddObject): void {
    this.visibleContent = content;
  }

  private showErrorDialogForBackendError(title: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: title,
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  private getActionTranslation(actionKey: string): string {
    return this.translate.instant(actionKey, {
      object: this.translate.instant(this.objectTranslationKey, {
        count: 1
      })
    });
  }

  private loadObjects(): void {
    this.getAllObjects.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((listOfObjects: T[]) => {
      this.listOfObjects = listOfObjects;
    });
  }

  private setGridOptionsForListOfObjects(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForObjects(), this.gridIdentifier)
        .withoutColumnMenu()
        .withOnCellDoubleClicked(() => this.selectObject())
        .withOnRowDataUpdated(() => this.setSelectedObject())
        .withRowSelection(this.hasMultiDeleteMethod(), false)
        .withOnRowClicked(() => this.showCheckboxIfNeeded())
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: this.objectTranslationKey,
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withRememberStateForNavigationHelper()
        .withOnFirstDataRendered(() => this.setSelectedObject())
        .build()
    ];
  }

  private hasMultiDeleteMethod(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.deleteMultipleObjects);
  }

  private setSelectedObject(): void {
    if (!AssertionUtils.isNullOrUndefined(this.selectedObjectId)) {
      this.optionsForListOfObjectsGrid.api.forEachNode((node: RowNode) => node.setSelected(this.selectedObjectId === node.data.id));
    }
  }

  private getColumnDefsForObjects(): ColDef[] {
    return [this.colDefBuilderFactoryService.getBuilder().withField('name').withHeaderName('GENERAL.NAME').build()];
  }

  private setFormFields(name: string = null): void {
    this.addNewObjectForm = this.formBuilder.group({
      name: this.formBuilder.control(name, Validators.required, this.asyncUniqueValidator(name))
    });
  }

  private getMultiDeleteConfirmationMessage(selectedRows: T[], objectTranslationKey: string): string {
    return `${selectedRows.length} ${this.translate.instant(objectTranslationKey, {count: 2}).toLowerCase()}`;
  }

  private showCheckboxIfNeeded(): void {
    let newCheckboxSelection = null;
    const columnDefs = this.optionsForListOfObjectsGrid.api.getColumnDefs();

    columnDefs.forEach((colDef: ColDef, index: number) => {
      if (index === 0) {
        colDef.checkboxSelection = this.isMultiSelection();
        newCheckboxSelection = colDef.checkboxSelection;
      }
    });

    if (this.previousCheckboxSelection !== newCheckboxSelection) {
      this.optionsForListOfObjectsGrid.api.setGridOption('columnDefs', columnDefs);
      this.optionsForListOfObjectsGrid.api.refreshCells();
    }

    this.previousCheckboxSelection = newCheckboxSelection;
  }
}
