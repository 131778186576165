import {Inject, Injectable} from '@angular/core';
import {SwPush} from '@angular/service-worker';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';
import {WebNotificationSubscription} from '@domain/notifications/web-notification-subscription';
import {HttpNotificationsService} from '@infrastructure/http/notifications/http-notifications.service';
import {AssertionUtils} from '@vdw/angular-component-library';
import {catchError, from, Observable, ObservableInput, switchMap, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private _isSubscribed?: boolean = undefined;

  public get isSubscribed(): boolean {
    return this._isSubscribed ?? false;
  }

  public constructor(
    private readonly swPush: SwPush,
    private readonly notificationService: HttpNotificationsService,
    @Inject(RUNTIME_APPLICATION_ENVIRONMENT) private runtimeEnvironment: RuntimeApplicationEnvironment
  ) {}

  public subscribeToPushNotifications(): Observable<void> {
    return this.requestSubscription().pipe(
      switchMap((subscription: PushSubscription) => {
        const subscriptionJSON = subscription.toJSON();
        const auth = (subscriptionJSON.keys && subscriptionJSON.keys['auth']) ?? '';
        const p256dh = (subscriptionJSON.keys && subscriptionJSON.keys['p256dh']) ?? '';

        const webNotificationSubscription = new WebNotificationSubscription(null, p256dh, subscriptionJSON.endpoint ?? '', auth);

        return this.notificationService.addWebNotificationSubscription(webNotificationSubscription).pipe(
          tap(() => (this._isSubscribed = true)),
          catchError((): ObservableInput<any> => from(subscription.unsubscribe()))
        );
      })
    );
  }

  public unsubscribeToPushNotifications(): Observable<void> {
    return this.requestSubscription().pipe(
      switchMap((subscription: PushSubscription) => {
        subscription.unsubscribe();
        return this.notificationService.deleteWebNotificationSubscription(subscription.endpoint).pipe(tap(() => (this._isSubscribed = false)));
      })
    );
  }

  public subscriptionEnabledInBrowser(): Observable<PushSubscription> {
    return this.swPush.subscription.pipe(tap((subscription: PushSubscription) => (this._isSubscribed = !AssertionUtils.isNullOrUndefined(subscription))));
  }

  public isBrowserSupported(): boolean {
    return this.swPush.isEnabled && !AssertionUtils.isNullOrUndefined(this._isSubscribed);
  }

  private requestSubscription(): Observable<PushSubscription> {
    const applicationServerKey = this.runtimeEnvironment.getEnvironment().publicVapidKeyOfServer;
    return from(this.swPush.requestSubscription({serverPublicKey: applicationServerKey}));
  }
}
