import {DimensionsInPx} from '@domain/dimensions-in-px';

export enum MachineOverviewViewMode {
  DETAILVIEW = 'DETAILVIEW',
  OVERVIEW = 'OVERVIEW',
  LISTVIEW = 'LISTVIEW',
  GRIDVIEW = 'GRIDVIEW',
  PHYSICALVIEW = 'PHYSICALVIEW'
}

export namespace MachineOverviewViewMode {
  export function getMachineDimensionsForViewMode(mode: MachineOverviewViewMode): DimensionsInPx {
    let result: DimensionsInPx = null;

    switch (mode) {
      case MachineOverviewViewMode.OVERVIEW:
        result = {
          widthInPx: 64,
          heightInPx: 96
        };
        break;
      case MachineOverviewViewMode.DETAILVIEW:
        result = {
          widthInPx: 182,
          heightInPx: 165
        };
        break;
    }
    return result;
  }
}
