import {Component, Input} from '@angular/core';

@Component({
  selector: 'vdw-invalid-form-message',
  templateUrl: './invalid-form-message.component.html',
  styleUrls: ['./invalid-form-message.component.scss']
})
export class InvalidFormMessageComponent {
  @Input() public message = 'ANGULAR_COMPONENT_LIBRARY.FORM_MESSAGE.CHECK_INVALID_FIELDS';
  @Input() public compact = false;
  @Input() public link = null;
  @Input() public linkTranslationKey = 'GENERAL.ACTIONS.LOAD';
}
