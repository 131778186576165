import {InjectionToken} from '@angular/core';
import {Floorplan} from '@domain/machine/floorplan';
import {MachineFile} from '@domain/machine/machine-file';
import {CustomSettingsObjectRepository} from '@infrastructure/http/custom-settings-object-repository';
import {OverviewMachine} from '@presentation/pages/machine-overview/overview-machine';
import {OverviewMachineGroup} from '@presentation/pages/machine-overview/overview-machine-group';
import {MachineOverviewMessageFilter} from '@presentation/pages/machine-overview/sidebar/machine-overview-message-filter';
import {Message} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';

export const MACHINE_OVERVIEW = new InjectionToken<MachineOverview>('MachineOverview');

export interface MachineOverview extends CustomSettingsObjectRepository {
  getFloorplan(): Observable<Floorplan>;

  updateMachinePosition(machine: OverviewMachine): Observable<void>;

  addMachineToFloorplan(machine: OverviewMachine): Observable<void>;

  getMessages(start: number, count: number, filter: Partial<MachineOverviewMessageFilter>): Observable<Message[]>;

  saveMachineFiles(machineId: number, files: MachineFile[]): Observable<void>;

  deleteMachineFiles(machineId: number, files: MachineFile[]): Observable<void>;

  getRemoteAccessUrl(machineId: number): Observable<string>;

  getRootGroup(): Observable<OverviewMachineGroup>;
}
