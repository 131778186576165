import {Gauge} from '@domain/machine/gauge';
import {WeaveProduct} from '../weave-product/weave-product';

export class TuftProduct {
  private _id: number;
  private _name: string;
  private _version: number;
  private _description: string;
  private _color: string;
  private _blend: string;
  private _widthInCm: number;
  private _gauge: Gauge;
  private _stitchRateInStitchesPerCm: number;
  private _pileHeightInCm: number;
  private _weightInGramsPerMeter: number;
  private _primaryBacking: WeaveProduct;
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _tuftingNotes: string;
  private _backingNotes: string;
  private _used: boolean;

  public constructor(
    id: number,
    name: string,
    version: number,
    description: string,
    color: string,
    blend: string,
    widthInCm: number,
    gauge: Gauge,
    stitchRateInStitchesPerCm: number,
    pileHeightInCm: number,
    weightInGramsPerMeter: number,
    primaryBacking: WeaveProduct,
    standardSpeed: number,
    standardEfficiency: number,
    tuftingNotes: string,
    backingNotes: string,
    used: boolean
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._description = description;
    this._color = color;
    this._blend = blend;
    this._widthInCm = widthInCm;
    this._gauge = gauge;
    this._stitchRateInStitchesPerCm = stitchRateInStitchesPerCm;
    this._pileHeightInCm = pileHeightInCm;
    this._weightInGramsPerMeter = weightInGramsPerMeter;
    this._primaryBacking = primaryBacking;
    this._standardSpeed = standardSpeed;
    this._standardEfficiency = standardEfficiency;
    this._tuftingNotes = tuftingNotes;
    this._backingNotes = backingNotes;
    this._used = used;
  }

  public static fromJSON(tuftProductJSON: any): TuftProduct {
    return new TuftProduct(
      tuftProductJSON.id,
      tuftProductJSON.name,
      tuftProductJSON.version,
      tuftProductJSON.description,
      tuftProductJSON.color,
      tuftProductJSON.blend,
      tuftProductJSON.widthInCm,
      Gauge.fromJSON(tuftProductJSON.gauge),
      tuftProductJSON.stitchRateInStitchesPerCm,
      tuftProductJSON.pileHeightInCm,
      tuftProductJSON.weightInGramsPerMeter,
      WeaveProduct.fromJSON(tuftProductJSON.primaryBacking),
      tuftProductJSON.standardSpeed,
      tuftProductJSON.standardEfficiency,
      tuftProductJSON.tuftingNotes,
      tuftProductJSON.backingNotes,
      tuftProductJSON.used
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version,
      description: this._description,
      color: this._color,
      blend: this._blend,
      widthInCm: this._widthInCm,
      gaugeId: this._gauge.id,
      stitchRateInStitchesPerCm: this._stitchRateInStitchesPerCm,
      pileHeightInCm: this._pileHeightInCm,
      weightInGramsPerMeter: this._weightInGramsPerMeter,
      primaryBackingId: this._primaryBacking.id,
      standardSpeed: this._standardSpeed,
      standardEfficiency: this._standardEfficiency,
      tuftingNotes: this._tuftingNotes,
      backingNotes: this._backingNotes,
      used: this._used
    } as any as JSON;
  }

  public static createEmptyTuftProduct(): TuftProduct {
    return new TuftProduct(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false);
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get version(): number {
    return this._version;
  }

  public set version(value: number) {
    this._version = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get color(): string {
    return this._color;
  }

  public set color(value: string) {
    this._color = value;
  }

  public get blend(): string {
    return this._blend;
  }

  public set blend(value: string) {
    this._blend = value;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public set widthInCm(value: number) {
    this._widthInCm = value;
  }

  public get gauge(): Gauge {
    return this._gauge;
  }

  public set gauge(value: Gauge) {
    this._gauge = value;
  }

  public get stitchRateInStitchesPerCm(): number {
    return this._stitchRateInStitchesPerCm;
  }

  public set stitchRateInStitchesPerCm(value: number) {
    this._stitchRateInStitchesPerCm = value;
  }

  public get pileHeightInCm(): number {
    return this._pileHeightInCm;
  }

  public set pileHeightInCm(value: number) {
    this._pileHeightInCm = value;
  }

  public get weightInGramsPerMeter(): number {
    return this._weightInGramsPerMeter;
  }

  public set weightInGramsPerMeter(value: number) {
    this._weightInGramsPerMeter = value;
  }

  public get primaryBacking(): WeaveProduct {
    return this._primaryBacking;
  }

  public set primaryBacking(value: WeaveProduct) {
    this._primaryBacking = value;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public set standardSpeed(value: number) {
    this._standardSpeed = value;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public set standardEfficiency(value: number) {
    this._standardEfficiency = value;
  }

  public get tuftingNotes(): string {
    return this._tuftingNotes;
  }

  public set tuftingNotes(value: string) {
    this._tuftingNotes = value;
  }

  public get backingNotes(): string {
    return this._backingNotes;
  }

  public set backingNotes(value: string) {
    this._backingNotes = value;
  }

  public get used(): boolean {
    return this._used;
  }

  public set used(value: boolean) {
    this._used = value;
  }
}
