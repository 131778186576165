import {StringUtils} from '@vdw/angular-component-library';

export class ProductionScheduleProductConfiguration {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;

  public constructor(id: number, name: string, version: number) {
    this._id = id;
    this._name = name;
    this._version = version;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._name, this._version);
  }

  public static fromJSON(productConfigurationJSON: any): ProductionScheduleProductConfiguration {
    return new ProductionScheduleProductConfiguration(productConfigurationJSON.id, productConfigurationJSON.name, productConfigurationJSON.version);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version
    } as any as JSON;
  }
}
