export enum ProductionScheduleGlobalStatus {
  CREATE = 'CREATE',
  CONFIRM = 'CONFIRM',
  SEND = 'SEND',
  PLAN = 'PLAN',
  REPLAN = 'REPLAN',
  PRODUCE = 'PRODUCE'
}

const CREATE_ICON_NAME = 'solid-create';
const CONFIRM_ICON_NAME = 'solid-processing';
const SEND_ICON_NAME = 'solid-send';
const PLAN_ICON_NAME = 'solid-plan';
const REPLAN_ICON_NAME = 'solid-replan';
const PRODUCE_ICON_NAME = 'solid-execute';

const productionScheduleGlobalStatusIconMap: Map<ProductionScheduleGlobalStatus, string> = new Map<ProductionScheduleGlobalStatus, string>([
  [ProductionScheduleGlobalStatus.CREATE, CREATE_ICON_NAME],
  [ProductionScheduleGlobalStatus.CONFIRM, CONFIRM_ICON_NAME],
  [ProductionScheduleGlobalStatus.SEND, SEND_ICON_NAME],
  [ProductionScheduleGlobalStatus.PLAN, PLAN_ICON_NAME],
  [ProductionScheduleGlobalStatus.REPLAN, REPLAN_ICON_NAME],
  [ProductionScheduleGlobalStatus.PRODUCE, PRODUCE_ICON_NAME]
]);

export namespace ProductionScheduleGlobalStatus {
  export function getStatusIconName(status: ProductionScheduleGlobalStatus): string {
    return productionScheduleGlobalStatusIconMap.get(status);
  }
}
