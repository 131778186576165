import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {ColorConstants} from '../../../common/colors-variables.generated';
import {DialogData} from '../../dialog-button/dialog-data.interface';

@Component({
  templateUrl: './color-preset-picker-menu.component.html',
  styleUrls: ['./color-preset-picker-menu.component.scss']
})
export class ColorPresetPickerMenuComponent implements DialogData<string>, OnDestroy {
  public limited: boolean;
  public selectedColor: string;
  public selectedColorChanges = new Subject<string>();
  public readonly presetColors = ['PRIMARY_VIOLET', 'PRIMARY_AQUA', 'PRIMARY_TEAL', 'SEMANTIC_SUCCESS', 'PRIMARY_YELLOW', 'SEMANTIC_WARNING', 'SEMANTIC_ERROR', 'PRIMARY_PINK', 'PRIMARY_PURPLE'];

  public constructor(@Inject(MAT_DIALOG_DATA) data: {limited: boolean; selectedColor: string}) {
    this.limited = data.limited;
    this.selectedColor = data.selectedColor;
  }

  public ngOnDestroy(): void {
    this.selectedColorChanges.complete();
  }

  public getPresetColorsWithTone(tone: number): string[] {
    return this.presetColors.map((presetColor: string) => ColorConstants[`${presetColor}_${tone.toString()}`]);
  }

  public isColorSelected(color: string): boolean {
    return color === this.selectedColor;
  }

  public selectColor(color: string): void {
    this.selectedColor = color;
    this.selectedColorChanges.next(this.selectedColor);
  }

  public getValue(): Observable<string> {
    return this.selectedColorChanges.asObservable();
  }
}
