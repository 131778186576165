import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {RouteData} from '@application/helper/routing/route-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils} from '@vdw/angular-component-library';

export const canActivatePermissionPrototypeGuard = (route: ActivatedRouteSnapshot): boolean => {
  const router = inject(Router);
  const authentication = inject(AUTHENTICATION);
  const navigationService = inject(NavigationService);

  const routeData = route.data as RouteData;
  const currentSubscription = authentication.getCurrentSubscription();
  const hasPermission = AssertionUtils.isNullOrUndefined(routeData?.requiredPermission) || currentSubscription?.hasPermission(routeData.requiredPermission);

  if (!hasPermission && !navigationService.checkIsSwitchingSubscription(currentSubscription)) {
    router.navigateByUrl(RouteUtils.paths.unauthorized.absolutePath);
  }

  return hasPermission;
};
