import {CuttingLineProperties} from '@domain/textile-data/finishing-and-finishing-template/cutting-line-properties';
import {FoldingCorrectionProperties} from '@domain/textile-data/finishing-and-finishing-template/folding-correction-properties';
import {CutbarPropertiesForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/cutbar-properties-for-machine-quality';
import {isNil} from 'lodash-es';

export class CutbarsInformationForMachineQuality {
  private _cutbarBeforeProperties: CutbarPropertiesForMachineQuality;
  private _cutbarAfterProperties: CutbarPropertiesForMachineQuality;
  private _cuttingLineProperties: CuttingLineProperties;
  private _foldingCorrectionProperties: FoldingCorrectionProperties;

  public constructor(
    cutbarBeforeProperties: CutbarPropertiesForMachineQuality,
    cutbarAfterProperties: CutbarPropertiesForMachineQuality,
    cuttingLineProperties: CuttingLineProperties,
    foldingCorrectionProperties: FoldingCorrectionProperties
  ) {
    this._cutbarBeforeProperties = cutbarBeforeProperties;
    this._cutbarAfterProperties = cutbarAfterProperties;
    this._cuttingLineProperties = cuttingLineProperties;
    this._foldingCorrectionProperties = foldingCorrectionProperties;
  }

  public get cutbarBeforeProperties(): CutbarPropertiesForMachineQuality {
    return this._cutbarBeforeProperties;
  }

  public get cutbarAfterProperties(): CutbarPropertiesForMachineQuality {
    return this._cutbarAfterProperties;
  }

  public get cuttingLineProperties(): CuttingLineProperties {
    return this._cuttingLineProperties;
  }

  public get foldingCorrectionProperties(): FoldingCorrectionProperties {
    return this._foldingCorrectionProperties;
  }

  public static fromJSON(cutbarsInformationForCompatibilityWithMachineQualityJSON: any): CutbarsInformationForMachineQuality {
    return !isNil(cutbarsInformationForCompatibilityWithMachineQualityJSON)
      ? new CutbarsInformationForMachineQuality(
          CutbarPropertiesForMachineQuality.fromJSON(cutbarsInformationForCompatibilityWithMachineQualityJSON.cutbarBeforeProperties),
          CutbarPropertiesForMachineQuality.fromJSON(cutbarsInformationForCompatibilityWithMachineQualityJSON.cutbarAfterProperties),
          CuttingLineProperties.fromJSON(cutbarsInformationForCompatibilityWithMachineQualityJSON.cuttingLineProperties),
          FoldingCorrectionProperties.fromJSON(cutbarsInformationForCompatibilityWithMachineQualityJSON.foldingCorrectionProperties)
        )
      : null;
  }

  public hasDeficitLength(): boolean {
    return (!isNil(this.cutbarBeforeProperties) && this.cutbarBeforeProperties.hasDeficitLength()) || (!isNil(this.cutbarAfterProperties) && this.cutbarAfterProperties.hasDeficitLength());
  }

  public getGreatestDeficitLength(): number {
    let result = 0;

    if (this.hasDeficitLength()) {
      if (!isNil(this.cutbarBeforeProperties) && this.cutbarBeforeProperties.getGreatestDeficitLength() > result) {
        result = this.cutbarBeforeProperties.getGreatestDeficitLength();
      }

      if (!isNil(this.cutbarAfterProperties) && this.cutbarAfterProperties.getGreatestDeficitLength() > result) {
        result = this.cutbarAfterProperties.getGreatestDeficitLength();
      }
    }

    return result;
  }

  public getNumberOfIncompatibilities(): number {
    let result = 0;

    if (this.hasDeficitLength()) {
      if (!isNil(this.cutbarBeforeProperties)) {
        result += this.cutbarBeforeProperties.getNumberOfIncompatibilities();
      }

      if (!isNil(this.cutbarAfterProperties)) {
        result += this.cutbarAfterProperties.getNumberOfIncompatibilities();
      }
    }
    return result;
  }
}
