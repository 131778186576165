import {Inject, Injectable, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {CalendarItem} from '@domain/planning/calendar-item';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningMachineCarpetRun} from '@domain/planning/carpet/planning-machine-carpet-run';
import {ProductionOrderCarpetForPlanningOrder} from '@domain/planning/carpet/production-order-carpet-for-planning-order';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {PlanningOrderData} from '@domain/planning/planning-order-data';
import {PlanningMachineTuftingRun} from '@domain/planning/tufting/planning-machine-tufting-run';
import {ProductionOrderTuftingForPlanningOrder} from '@domain/planning/tufting/production-order-tufting-for-planning-order';
import {PlanningMachineWeavingRun} from '@domain/planning/weaving/planning-machine-weaving-run';
import {ProductionOrderWeavingForPlanningOrder} from '@domain/planning/weaving/production-order-weaving-for-planning-order';
import {AssertionUtils, DragDropData, PlanningDragDropService, PlanningGroupData, PlanningItemData, Point, TranslateService} from '@vdw/angular-component-library';
import {filter, Subject, takeUntil} from 'rxjs';
import {PlanningScheduler, PLANNING_SCHEDULER} from '../../planning/planning-scheduler/planning-scheduler';
import {NeedsAttentionSidebarComponent} from '../../planning/sidebars/needs-attention-sidebar/needs-attention-sidebar.component';
import {PlanningContextService} from './planning-context.service';

@Injectable()
export class PlanningDragDropChangesService implements OnDestroy {
  private unsubscribeOnViewDestroy = new Subject<undefined>();
  public needsAttentionSidebar: NeedsAttentionSidebarComponent;

  private readonly NEW_RUN_TEXT = this.translate.instant('GENERAL.ACTIONS.NEW_OBJECT', {object: 'PLANNING.ADD_ITEM.TYPES.RUN'}).toUpperCase();

  public constructor(
    private readonly translate: TranslateService,
    private readonly planningContext: PlanningContextService,
    private readonly planningDragDrop: PlanningDragDropService,
    @Inject(PLANNING_SCHEDULER) private readonly planningScheduler: PlanningScheduler
  ) {
    this.planningDragDrop.dragEnd
      .pipe(
        takeUntil(this.unsubscribeOnViewDestroy),
        filter((eventData: DragDropData) => eventData.draggedItem.dataTransfer === this.planningContext.unplannedOrderItem)
      )
      .subscribe((eventData: DragDropData) => this.unplannedOrderDragEnd(eventData));

    this.planningDragDrop.drag
      .pipe(
        takeUntil(this.unsubscribeOnViewDestroy),
        filter((eventData: DragDropData) => eventData.draggedItem.dataTransfer === this.planningContext.unplannedOrderItem)
      )
      .subscribe((eventData: DragDropData) => this.unplannedOrderDrag(eventData));
  }

  public ngOnDestroy(): void {
    this.unsubscribeOnViewDestroy.next(undefined);
    this.unsubscribeOnViewDestroy.complete();
  }

  public onPlanningItemDragStart(dragDropData: DragDropData): void {
    const draggedPlanningItem = dragDropData.draggedItem.dataTransfer as PlanningItem;
    this.planningContext.isDragAndDropActive = true;
    this.planningContext.selectedPlanningItem = draggedPlanningItem;
  }

  public onPlanningOrderDragStart(dragDropData: DragDropData): void {
    const draggedPlanningOrder = dragDropData.draggedItem.dataTransfer as PlanningOrder;
    const originalParentRun = dragDropData.sourceGroup.dataTransfer as PlanningMachineRun;
    this.planningContext.isDragAndDropActive = true;
    this.planningContext.selectedPlanningItem = originalParentRun;
    this.planningContext.selectedPlanningOrderId = draggedPlanningOrder.id;
  }

  public planningItemDragEnd(dragDropData: DragDropData): void {
    const draggedPlanningItem = dragDropData.draggedItem.dataTransfer as PlanningItem;
    this.planningContext.isDragAndDropActive = false;
    if (!dragDropData.dropAllowed || !this.planningContext.canEditPlanningItem(draggedPlanningItem)) {
      this.planningContext.selectedPlanningItem = undefined;
      return;
    }

    const sourceMachine = dragDropData.sourceRow.dataTransfer as PlanningMachine;
    const destinationMachine = dragDropData.targetRow.dataTransfer as PlanningMachine;
    const destinationGroup = this.planningContext.groupedCalendarItems?.find((group: CalendarItemGroup) => group.machineId === destinationMachine.id);

    const executingRun = this.executingRunForPlanningGroup(destinationGroup);
    const newStartDate = executingRun != null && executingRun.endDate > dragDropData.targetTime ? executingRun.endDate : dragDropData.targetTime;

    draggedPlanningItem.updateStartWhilePreservingDuration(newStartDate);
    if (draggedPlanningItem instanceof PlanningMachineRun) {
      draggedPlanningItem.fitOrders();
    }
    this.planningScheduler.rescheduleItems(draggedPlanningItem, sourceMachine, destinationGroup, destinationMachine);

    this.planningContext.selectedPlanningItem = undefined;
  }

  public onPlanningOrderDragEnd(dragDropData: DragDropData): void {
    this.planningContext.isDragAndDropActive = false;
    const draggedPlanningOrder = dragDropData.draggedItem.dataTransfer as PlanningOrder;
    if (!dragDropData.dropAllowed || !draggedPlanningOrder.canBeRescheduled()) {
      this.planningContext.selectedPlanningOrderId = undefined;
      return;
    }

    const originalParentRun = dragDropData.sourceGroup.dataTransfer as PlanningMachineRun;
    const sourceMachine = dragDropData.sourceRow.dataTransfer as PlanningMachine;
    const destinationMachine = dragDropData.targetRow.dataTransfer as PlanningMachine;
    const destinationRun = dragDropData.targetItem?.dataTransfer as PlanningMachineRun;

    if (destinationRun == null) {
      const destinationGroup = this.planningContext.groupedCalendarItems?.find((group: CalendarItemGroup) => group.machineId === destinationMachine.id);

      const executingRun = this.executingRunForPlanningGroup(destinationGroup);
      const newStartDate = executingRun != null && executingRun.endDate > dragDropData.targetTime ? executingRun.endDate : dragDropData.targetTime;

      draggedPlanningOrder.updateStartWhilePreservingDuration(newStartDate);
      this.planningScheduler.addPlannedPlanningOrderToNewRun(draggedPlanningOrder, originalParentRun, sourceMachine.id, destinationMachine);
    } else {
      const position = this.getPlanningOrderPositionOnDragEnd(dragDropData);
      this.planningScheduler.reschedulePlanningOrder(draggedPlanningOrder, originalParentRun, destinationRun, position, destinationMachine, sourceMachine.id);
    }

    this.planningContext.selectedPlanningOrderId = undefined;
  }

  public planningOrderDragEnter(dragDropData: DragDropData): void {
    if (!dragDropData.dropAllowed) {
      return;
    }
    const targetMachine = dragDropData.targetRow?.dataTransfer as PlanningMachine;
    const draggedPlanningItem = dragDropData.draggedItem.dataTransfer as PlanningOrder;

    if (targetMachine && !draggedPlanningItem.isCompatibleWithMachineType(targetMachine.machineType)) {
      dragDropData.dropAllowed = false;
      return;
    }

    const targetRun = dragDropData.targetItem?.dataTransfer as PlanningMachineRun;
    const sourceRun = dragDropData.sourceGroup?.dataTransfer as PlanningMachineRun;

    dragDropData.dropAllowed = this.validateTargetRun(sourceRun, targetRun, dragDropData.dropAllowed);
  }

  public planningRunDragEnter(dragDropData: DragDropData): void {
    if (!dragDropData.dropAllowed) {
      return;
    }
    const targetMachine = dragDropData.targetRow?.dataTransfer as PlanningMachine;
    const draggedPlanningItem = dragDropData.draggedItem.dataTransfer as PlanningMachineRun;

    dragDropData.dropAllowed = targetMachine != null && draggedPlanningItem.isCompatibleWithMachineType(targetMachine.machineType);
  }

  public sidebarUnplannedOrderDragStart({event, unplannedOrder}: {event: DragEvent; unplannedOrder: PlanningOrderData}, template: TemplateRef<any>, viewContainerRef: ViewContainerRef): void {
    this.planningContext.isDragAndDropActive = true;
    if (!this.planningContext.canEdit()) {
      return;
    }

    const grid = this.planningContext.planningGridDisplay;
    this.planningContext.unplannedOrderItem = new PlanningOrder(0, null, null, 0, unplannedOrder);

    const planningItemData = new PlanningItemData();
    planningItemData.template = template;
    planningItemData.draggable = true;
    planningItemData.dataTransfer = this.planningContext.unplannedOrderItem;

    const planningGroupData = new PlanningGroupData();
    planningGroupData.draggable = true;
    planningGroupData.class = 'planning-run';

    const startPositionInContainer: Point = {
      x: event.clientX - grid.bodyContainer.nativeElement.parentElement.getBoundingClientRect().x + grid.bodyContainer.nativeElement.parentElement.scrollLeft,
      y: event.clientY - grid.bodyContainerOffset.y + grid.bodyContainer.nativeElement.parentElement.scrollTop
    };

    this.planningDragDrop.onDragStart(event, planningItemData, planningGroupData, null, 250, startPositionInContainer, this.NEW_RUN_TEXT, grid, viewContainerRef);
  }

  public unplannedOrderDrag(eventData: DragDropData): void {
    if (!eventData.dropAllowed) {
      return;
    }
    const draggedOrderData = eventData.draggedItem.dataTransfer as PlanningOrder;
    const targetMachine = eventData.targetRow?.dataTransfer as PlanningMachine;
    if (!targetMachine || !draggedOrderData.isCompatibleWithMachineType(targetMachine.machineType)) {
      eventData.dropAllowed = false;
      return;
    }
    const targetRun = eventData.targetItem?.dataTransfer as PlanningMachineRun;
    eventData.dropAllowed = this.validateTargetRunForOrder(targetRun, draggedOrderData.productionOrder);
  }

  public unplannedOrderDragEnd(dragDropData: DragDropData): void {
    this.planningContext.isDragAndDropActive = false;
    if (!dragDropData.dropAllowed || !this.planningContext.unplannedOrderItem.canBeRescheduled()) {
      this.planningContext.unplannedOrderItem = undefined;
      return;
    }

    const destinationMachine = dragDropData.targetRow?.dataTransfer as PlanningMachine;
    const destinationRun = dragDropData.targetItem?.dataTransfer as PlanningMachineRun;

    const destinationGroup = this.planningContext.groupedCalendarItems?.find((group: CalendarItemGroup) => group.machineId === destinationMachine.id);
    const executingRun = this.executingRunForPlanningGroup(destinationGroup);

    const newStartDate = executingRun != null && executingRun.endDate > dragDropData.targetTime ? executingRun.endDate : dragDropData.targetTime;
    this.planningContext.unplannedOrderItem.updateStartWhilePreservingDuration(newStartDate);

    if (destinationRun == null) {
      this.planningScheduler.addUnplannedPlanningOrderToNewRun(this.planningContext.unplannedOrderItem, destinationMachine, this.removeUnplannedOrderFromNeedsAttentionSidebar());
    } else {
      const pos = this.getPlanningOrderPositionOnDragEnd(dragDropData);
      this.planningScheduler.addUnplannedPlanningOrderToRun(this.planningContext.unplannedOrderItem, destinationRun, pos, destinationMachine, this.removeUnplannedOrderFromNeedsAttentionSidebar());
    }

    this.planningScheduler.resetPlanning();
  }

  private executingRunForPlanningGroup(group: CalendarItemGroup): PlanningMachineRun {
    return group?.items.find((item: CalendarItem) => item instanceof PlanningMachineRun && item.executing) as PlanningMachineRun;
  }

  private removeUnplannedOrderFromNeedsAttentionSidebar(): () => void {
    return (): void => {
      if (this.planningContext.unplannedOrderItem.isWeavingOrder) {
        this.needsAttentionSidebar?.removeProductionOrderWeaving(this.planningContext.unplannedOrderItem.productionOrder.id);
      } else if (this.planningContext.unplannedOrderItem.isCarpetOrder) {
        this.needsAttentionSidebar?.removeProductionOrderCarpet(this.planningContext.unplannedOrderItem.productionOrder.id);
      } else if (this.planningContext.unplannedOrderItem.isTuftingOrder) {
        this.needsAttentionSidebar?.removeProductionOrderTufting(this.planningContext.unplannedOrderItem.productionOrder.id);
      }
    };
  }

  private getPlanningOrderPositionOnDragEnd(dragDropData: DragDropData): number {
    const targetGroup = dragDropData.targetItem as PlanningGroupData;

    if (AssertionUtils.isNullOrUndefined(targetGroup)) {
      return 0;
    }

    if (AssertionUtils.isNullOrUndefined(dragDropData.targetChildItem)) {
      return targetGroup.items.length;
    }

    const foundIndex = targetGroup.items.findIndex((itemData: PlanningItemData) => itemData === dragDropData.targetChildItem);
    return Math.max(0, dragDropData.targetChildItem?.endDate === dragDropData.targetTime ? foundIndex + 1 : foundIndex);
  }

  private validateTargetRun(sourceRun: PlanningMachineRun, targetRun: PlanningMachineRun, defaultResult: boolean): boolean {
    if (sourceRun === targetRun) {
      return true;
    }
    if (sourceRun instanceof PlanningMachineWeavingRun && targetRun instanceof PlanningMachineWeavingRun) {
      return sourceRun.weaveProduct != null && sourceRun.weaveProduct.id === targetRun.weaveProduct?.id;
    }
    if (sourceRun instanceof PlanningMachineCarpetRun && targetRun instanceof PlanningMachineCarpetRun) {
      return sourceRun.machineQuality != null && sourceRun.creel != null && sourceRun.creel.id === targetRun.creel?.id && sourceRun.machineQuality.id === targetRun.machineQuality?.id;
    }
    if (sourceRun instanceof PlanningMachineTuftingRun && targetRun instanceof PlanningMachineTuftingRun) {
      return sourceRun.tuftProduct != null && sourceRun.tuftProduct.id === targetRun.tuftProduct?.id;
    }
    return defaultResult;
  }

  private validateTargetRunForOrder(targetRun: PlanningMachineRun, order: PlanningOrderData): boolean {
    if (order instanceof ProductionOrderCarpetForPlanningOrder && targetRun instanceof PlanningMachineCarpetRun) {
      return order.creel.id === targetRun.creel?.id && order.machineQuality.id === targetRun.machineQuality?.id;
    }

    if (order instanceof ProductionOrderWeavingForPlanningOrder && targetRun instanceof PlanningMachineWeavingRun) {
      return order.weaveProduct.id === targetRun.weaveProduct?.id;
    }

    if (order instanceof ProductionOrderTuftingForPlanningOrder && targetRun instanceof PlanningMachineTuftingRun) {
      return order.tuftProduct.id === targetRun.tuftProduct?.id;
    }

    return !targetRun;
  }
}
