import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ProgressCellRendererParams} from './progress-cell-renderer-params.interface';

@Component({
  templateUrl: './progress-cell-renderer.component.html',
  styleUrls: ['./progress-cell-renderer.component.scss']
})
export class ProgressCellRendererComponent implements ICellRendererAngularComp {
  public params: ProgressCellRendererParams;
  public isSaving = false;
  public isSmallView = true;
  private readonly COMPACT_GRID_HEIGHT = 24;

  public agInit(params: ProgressCellRendererParams): void {
    this.params = params;

    if (this.params?.node?.rowHeight > this.COMPACT_GRID_HEIGHT) {
      this.isSmallView = false;
    }
  }

  public valueChanged(event: boolean): void {
    this.params.setValue(event);
  }

  public isButtonDisabled(): boolean {
    return this.getQuantity(this.params) <= 0;
  }

  public refresh(params: ProgressCellRendererParams): boolean {
    return false;
  }

  private getQuantity(params: ProgressCellRendererParams): number {
    return params.isOrderline ? params.data.quantity : params.data.amount;
  }
}
