import {CutbarPattern} from '@domain/textile-data/finishing-and-finishing-template/finishing/cutbar-pattern';
import {isNil} from 'lodash-es';

export class FinishingCutbarProperties {
  private _commercialLengthInMM: number;
  private _technicalLengthInPicks: number;
  private _patternTemplate: CutbarPattern;

  public constructor(commercialLengthInMM: number, technicalLengthInPicks: number, patternTemplate: CutbarPattern) {
    this._commercialLengthInMM = commercialLengthInMM;
    this._technicalLengthInPicks = technicalLengthInPicks;
    this._patternTemplate = patternTemplate;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public get technicalLengthInPicks(): number {
    return this._technicalLengthInPicks;
  }

  public get patternTemplate(): CutbarPattern {
    return this._patternTemplate;
  }

  public static fromJSON(cutbarPropertiesJSON: any): FinishingCutbarProperties {
    return isNil(cutbarPropertiesJSON)
      ? null
      : new FinishingCutbarProperties(cutbarPropertiesJSON.commercialLengthInMM, cutbarPropertiesJSON.technicalLengthInPicks, CutbarPattern.fromJSON(cutbarPropertiesJSON.patternTemplate));
  }

  public toJSON(): JSON {
    return {
      commercialLengthInMM: this._commercialLengthInMM,
      technicalLengthInPicks: this._technicalLengthInPicks,
      patternTemplateId: !isNil(this._patternTemplate) ? this._patternTemplate.id : null
    } as any as JSON;
  }
}
