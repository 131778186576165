import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {interval, map, Observable, startWith} from 'rxjs';

@Pipe({
  name: 'minutesFromNow'
})
export class MinutesFromNowPipe implements PipeTransform {
  public transform(date: Date | string): Observable<string> {
    return interval(1000).pipe(
      startWith(0),
      map(() => {
        const dateToTransform = typeof date === 'string' ? new Date(date) : date;

        if (dateToTransform) {
          if (moment(dateToTransform).isAfter(moment().clone().subtract(1, 'hours'))) {
            return moment(dateToTransform).fromNow();
          } else {
            return moment(dateToTransform).format('DD/MM - HH:mm:ss');
          }
        }
        return '';
      })
    );
  }
}
