import {BackcoatingInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/backcoating-information';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';

export abstract class AbstractFinishing {
  private _id: number | string;
  private _name: string;
  private _finishingType: FinishingType;
  private _backcoatingInformation: BackcoatingInformation;
  private _used: boolean;

  public constructor(id: number | string, name: string, finishingType: FinishingType, backcoatingInformation: BackcoatingInformation, used: boolean) {
    this._id = id;
    this._name = name;
    this._finishingType = finishingType;
    this._backcoatingInformation = backcoatingInformation;
    this._used = used;
  }

  public get id(): number | string {
    return this._id;
  }

  public set id(value: number | string) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get finishingType(): FinishingType {
    return this._finishingType;
  }

  public get backcoatingInformation(): BackcoatingInformation {
    return this._backcoatingInformation;
  }

  public get used(): boolean {
    return this._used;
  }
}
