import {StringUtils} from '@application/helper/string-utils';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {AssertionUtils} from '@vdw/angular-component-library';

export class PlanningMachineQuality {
  private _id: number;
  private _technicalName: string;
  private _version: number;

  public constructor(id: number, technicalName: string, version: number) {
    this._id = id;
    this._technicalName = technicalName;
    this._version = version;
  }

  public get id(): number {
    return this._id;
  }

  public get technicalName(): string {
    return this._technicalName;
  }

  public get version(): number {
    return this._version;
  }

  public get technicalNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.technicalName, this.version);
  }

  public static fromJSON(planningMachineQualityJSON: any): PlanningMachineQuality {
    return AssertionUtils.isNullOrUndefined(planningMachineQualityJSON)
      ? null
      : new PlanningMachineQuality(planningMachineQualityJSON.id, planningMachineQualityJSON.technicalName, planningMachineQualityJSON.version);
  }

  public static fromOverviewListMachineQuality(overviewListMachineQuality: OverviewListMachineQuality): PlanningMachineQuality {
    return new PlanningMachineQuality(overviewListMachineQuality.id, overviewListMachineQuality.technicalName, overviewListMachineQuality.version);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      technicalName: this._technicalName,
      version: this._version
    } as any as JSON;
  }
}
