import {AbstractControl, FormGroup, UntypedFormArray, ValidatorFn} from '@angular/forms';

export class DuplicateValueValidator {
  public static createDuplicateValueValidator(formIndex: number): ValidatorFn {
    return (control: AbstractControl) => {
      let result = null;

      if (control.value === '' || control.value === null || control.value === undefined) {
        return null;
      }

      const formArray = control.parent && control.parent.parent ? (control.parent.parent as UntypedFormArray) : null;

      if (formArray && formArray.controls.length) {
        const controlName = DuplicateValueValidator.getControlName(control);

        for (let index = formIndex - 1; index >= 0; index--) {
          if ((formArray.at(index) as FormGroup).get(controlName).value === control.value) {
            result = {duplicateValue: true};
            break;
          }
        }
      }

      return result;
    };
  }

  public static createDuplicateValidator(itemList: string[], currentItem: string): ValidatorFn {
    return (control: AbstractControl) => {
      let result = null;

      if (control.value === '' || control.value === null || control.value === undefined) {
        return null;
      }

      if (itemList.filter((item: string) => item.toLowerCase() !== currentItem?.toLowerCase()).some((item: string) => item.toLowerCase() === control.value.toLowerCase())) {
        return {duplicateValue: true};
      }

      return result;
    };
  }

  public static getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find((name: string) => control === formGroup[name]) || null;
  }
}
