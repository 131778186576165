import {Moment, unitOfTime} from 'moment';
import {DateRange} from 'moment-range';
import {moment} from '../../../common/moment';
import {TimeViewMode} from '../../time-view-mode.enum';

export class PlanningDisplayHelper {
  public static split(range: DateRange, interval: unitOfTime.Diff, step: number, timeView: TimeViewMode): DateRange[] {
    let start: Moment = range.start;
    const dateRanges: DateRange[] = [];

    while (start < range.end) {
      const end = start.clone().add(step, interval);

      if (start.get('h') === 0 && timeView === TimeViewMode.DAY) {
        const hourOffset = PlanningDisplayHelper.getHourOffset(start, end);
        end.add(hourOffset);
      }

      dateRanges.push(moment.range(start, end));
      start = end;
    }

    return dateRanges;
  }

  public static getHoursForDate(date: Date): number {
    date.setHours(0);
    return moment.range(date, PlanningDisplayHelper.getNextDate(date)).duration('h');
  }

  public static getNextDate(date: Date): Date {
    return moment(date).set('h', 24).toDate();
  }

  public static isSameDate(firstDate: Date, secondDate: Date): boolean {
    if (firstDate == null || secondDate == null) {
      return false;
    }

    return firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getDate() === secondDate.getDate();
  }

  private static getHourOffset(start: Moment, end: Moment): number {
    const hourOffset = this.getHoursForDate(start.toDate()) - 24;
    const hourOffsetValid = hourOffset !== 0 && end.hours() > 0;
    const canApplyHourOffset = hourOffsetValid && ((hourOffset === -1 && end.hours() !== 23) || hourOffset === 1);

    return canApplyHourOffset ? hourOffset : 0;
  }
}
