import {JacquardMachineLoomLayoutZoneRepeatType} from './jacquard-machine-loom-layout-zone-repeat-type.enum';
import {JacquardMachineLoomLayoutZoneType} from './jacquard-machine-loom-layout-zone-type.enum';

export class JacquardMachineLoomLayoutZone {
  private _type: JacquardMachineLoomLayoutZoneType;
  private _numberOfDents: number;
  private _repeatType: JacquardMachineLoomLayoutZoneRepeatType;
  private _startDent: number;
  private _startDentRepeat: number;
  private _position: number;

  public constructor(
    type: JacquardMachineLoomLayoutZoneType,
    numberOfDents: number,
    repeatType: JacquardMachineLoomLayoutZoneRepeatType,
    startDent: number,
    startDentRepeat: number,
    position: number
  ) {
    this._type = type;
    this._numberOfDents = numberOfDents;
    this._repeatType = repeatType;
    this._startDent = startDent;
    this._startDentRepeat = startDentRepeat;
    this._position = position;
  }

  public get type(): JacquardMachineLoomLayoutZoneType {
    return this._type;
  }

  public get numberOfDents(): number {
    return this._numberOfDents;
  }

  public get repeatType(): JacquardMachineLoomLayoutZoneRepeatType {
    return this._repeatType;
  }

  public get startDent(): number {
    return this._startDent;
  }

  public get startDentRepeat(): number {
    return this._startDentRepeat;
  }

  public get position(): number {
    return this._position;
  }

  public toJSON(): any {
    return {
      type: JacquardMachineLoomLayoutZoneType[this._type],
      numberOfDents: this._numberOfDents,
      repeatType: JacquardMachineLoomLayoutZoneRepeatType[this._repeatType],
      startDent1Based: this._startDent,
      startDentRepeat: this._startDentRepeat,
      position: this._position
    };
  }

  public static fromJSON(jacquardMachineLoomLayoutZoneJSON: any): JacquardMachineLoomLayoutZone {
    return new JacquardMachineLoomLayoutZone(
      JacquardMachineLoomLayoutZoneType[jacquardMachineLoomLayoutZoneJSON.type as keyof typeof JacquardMachineLoomLayoutZone],
      jacquardMachineLoomLayoutZoneJSON.numberOfDents,
      JacquardMachineLoomLayoutZoneRepeatType[jacquardMachineLoomLayoutZoneJSON.repeatType as keyof typeof JacquardMachineLoomLayoutZoneRepeatType],
      jacquardMachineLoomLayoutZoneJSON.startDent1Based,
      jacquardMachineLoomLayoutZoneJSON.startDentRepeat,
      jacquardMachineLoomLayoutZoneJSON.position
    );
  }
}
