<div *ngIf="!isPhone()" class="full-width full-height">
  <app-navigation-resizer *ngIf="isAuthenticated"></app-navigation-resizer>
  <mat-drawer-container class="container">
    <mat-drawer
      #drawer
      class="side-navigation desktop-side-navigation"
      *ngIf="canShowMenu()"
      [mode]="getMode()"
      (openedChange)="onOpenedChange()"
      [disableClose]="!isTablet()"
      [opened]="!isSideNavCollapsed()"
      [class.initialized]="sideNavInitialized"
      [autoFocus]="false"
    >
      <div class="drawer-container flex-row">
        <ng-container [ngComponentOutlet]="applicationLayoutService.sidebarType"></ng-container>
      </div>
    </mat-drawer>
    <mat-drawer-content [class.no-transition]="resizeDirective?.isResizing ?? true">
      <div *ngIf="canShowMenu()" class="full-width">
        <ng-container *ngComponentOutlet="applicationLayoutService.navigationHeaderType"></ng-container>
      </div>
      <div class="content-area flex-row full-height full-width">
        <app-navigation-hover-area *ngIf="canShowMenu()" class="full-height"></app-navigation-hover-area>
        <div class="content desktop-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<div *ngIf="isPhone()" class="full-width full-height">
  <mat-sidenav-container hasBackdrop="false">
    <mat-sidenav-content class="main-container" [class.collapsed]="isSideNavCollapsed() && canShowMenu()" [class.open]="!isSideNavCollapsed() && canShowMenu()" fxFlex>
      <div appDataCy="collapse-navigation" class="collapse-navigation" fxLayout="center center" (click)="toggleSideNav()" *ngIf="canShowMenu()">
        <mat-icon [svgIcon]="getSideNavStateIcon()"></mat-icon>
      </div>

      <div class="main-content-with-nav-bar" fxFlex="1 0 100%">
        <section class="main-content">
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </section>

        <app-navigation-mobile *ngIf="canShowMobileNavigation()"></app-navigation-mobile>
      </div>
    </mat-sidenav-content>

    <mat-sidenav
      [mode]="getMode()"
      [opened]="true"
      class="side-navigation"
      [class.collapsed]="isSideNavCollapsed()"
      [class.initialized]="sideNavInitialized"
      [disableClose]="true"
      (openedChange)="onOpenedChange()"
      *ngIf="canShowMenu()"
      #sidenav
    >
      <app-mobile-navigation-sidebar></app-mobile-navigation-sidebar>
    </mat-sidenav>
  </mat-sidenav-container>
  <div class="rotate-device" *ngIf="sideNavInitialized">
    <mat-icon svgIcon="rotate-device"></mat-icon>
    <h3>{{ 'OVERLAY.ROTATE_DEVICE' | bmsTranslate }}</h3>
  </div>
</div>
