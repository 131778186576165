import {Injectable, InjectionToken} from '@angular/core';
import {AddProductionSchedule} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule';

export const ADD_PRODUCTION_SCHEDULE = new InjectionToken<AddProductionSchedule>('AddProductionSchedule');

@Injectable()
export class AddProductionScheduleService implements AddProductionSchedule {
  private isInitialPreparationsFormDirty = false;

  public hasUnsavedFormChanges(): boolean {
    return this.isInitialPreparationsFormDirty;
  }

  public notifyChangesToInitialPreparationsForm(hasInitialPreparationsFormChanged: boolean): void {
    this.isInitialPreparationsFormDirty = hasInitialPreparationsFormChanged;
  }
}
