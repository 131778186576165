import {MatDateFormats} from '@angular/material/core';

const BASE_FORMAT = 'DD MMM';

export const DATE_SELECT_FORMAT: MatDateFormats = {
  parse: {
    dateInput: BASE_FORMAT
  },
  display: {
    dateInput: BASE_FORMAT,
    dateA11yLabel: BASE_FORMAT,
    monthYearA11yLabel: BASE_FORMAT,
    monthYearLabel: BASE_FORMAT,
    monthLabel: BASE_FORMAT
  }
};
