import {IdName} from '@domain/id-name';
import {IdNameVersion} from '@domain/id-name-version';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import moment from 'moment';

export class TuftProductionOrder {
  private _id: number;
  private _name: string;
  private _tuftProduct: IdNameVersion;
  private _machine: IdName;
  private _plannedQuantityInMeters: number;
  private _tuftingNotes: string;
  private _dateCreated: Date;
  private _dueDate: Date;
  private _status: ProductionScheduleStatus;

  public constructor(
    id: number,
    name: string,
    tuftProduct: IdNameVersion,
    machine: IdName,
    plannedQuantityInMeters: number,
    tuftingNotes: string,
    dateCreated: Date,
    dueDate: Date,
    status: ProductionScheduleStatus
  ) {
    this._id = id;
    this._name = name;
    this._tuftProduct = tuftProduct;
    this._machine = machine;
    this._plannedQuantityInMeters = plannedQuantityInMeters;
    this._tuftingNotes = tuftingNotes;
    this._dateCreated = dateCreated;
    this._dueDate = dueDate;
    this._status = status;
  }

  public static fromJSON(tuftProductionOrderJSON: any): TuftProductionOrder {
    return new TuftProductionOrder(
      tuftProductionOrderJSON.id,
      tuftProductionOrderJSON.name,
      IdNameVersion.fromJSON(tuftProductionOrderJSON.tuftProduct),
      tuftProductionOrderJSON.machine ? IdName.fromJSON(tuftProductionOrderJSON.machine) : null,
      tuftProductionOrderJSON.plannedQuantityInMeters,
      tuftProductionOrderJSON.tuftingNotes,
      new Date(tuftProductionOrderJSON.creationDate),
      tuftProductionOrderJSON.dueDate ? new Date(tuftProductionOrderJSON.dueDate) : null,
      tuftProductionOrderJSON.status
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      tuftProduct: this._tuftProduct.toJSON(),
      machine: this._machine?.toJSON(),
      plannedQuantityInMeters: this.plannedQuantityInMeters,
      tuftingNotes: this.tuftingNotes,
      creationDate: this._dateCreated ? this._dateCreated.toISOString() : new Date().toISOString(),
      dueDate: moment(this._dueDate).startOf('day').subtract(new Date().getTimezoneOffset(), 'minutes').toISOString(),
      status: this.status
    } as any as JSON;
  }

  public static createEmptyTuftProductionOrder(): TuftProductionOrder {
    return new TuftProductionOrder(null, null, null, null, null, null, null, null, null);
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get tuftProduct(): IdNameVersion {
    return this._tuftProduct;
  }

  public set tuftProduct(value: IdNameVersion) {
    this._tuftProduct = value;
  }

  public get machine(): IdName {
    return this._machine;
  }

  public set machine(value: IdName) {
    this._machine = value;
  }

  public get plannedQuantityInMeters(): number {
    return this._plannedQuantityInMeters;
  }

  public set plannedQuantityInMeters(value: number) {
    this._plannedQuantityInMeters = value;
  }

  public get tuftingNotes(): string {
    return this._tuftingNotes;
  }

  public set tuftingNotes(value: string) {
    this._tuftingNotes = value;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public set dateCreated(value: Date) {
    this._dateCreated = value;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public set dueDate(value: Date) {
    this._dueDate = value;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }
}
