import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {BaseCreelTableComponent} from '@presentation/components/creel-tables/base-creel-table/base-creel-table.component';
import {PreviewType} from '@presentation/pages/textile-data/creel/add/preview-type';

@Component({
  template: ''
})
export abstract class CreelMappingTableBaseComponent extends BaseCreelTableComponent implements OnChanges {
  @Input() public coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent;
  @Input() public activeColoredYarnSetCreelMap: CreelMap;

  public activeMappedColoredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('coloredYarnSetWithStartDent' in changes) {
      this.coloredYarnSetWithStartDent = this.reverseColoredYarnSetWithStartDentCreelPositions(this.coloredYarnSetWithStartDent);
    }

    if ('activeColoredYarnSetCreelMap' in changes) {
      this.activeColoredYarnSetCreelMap ??= this.coloredYarnSetWithStartDent.getOriginalMappingForCurrentPositionOnMachine();
      this.activeMappedColoredYarnSetWithStartDent = this.getMappedColoredYarnSetWithStartDent(this.coloredYarnSetWithStartDent, this.activeColoredYarnSetCreelMap);
    }
    this.cellHeightInPx = this.getCellHeight();
  }

  public getCreelPositions(): number[] {
    const size = this.getSizeOfCreelPositions();
    return [...Array(size).keys()].map((index: number) => size - index);
  }

  public getYarnSetCreelPosition(creelPosition: OverviewListCreelPosition): OverviewListCreelPosition {
    return this.findByPosition(this.coloredYarnSetWithStartDent.coloredYarnSet.overviewListYarnSet.creelPositions, creelPosition.position);
  }

  public getMappedYarnSetCreelPosition(overviewListColoredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent, creelPosition: OverviewListCreelPosition): OverviewListCreelPosition {
    return this.findByPosition(overviewListColoredYarnSetWithStartDent.coloredYarnSet.overviewListYarnSet.creelPositions, creelPosition.position);
  }

  public isCreelPositionDifferentFromTheoreticalCreel(position: number): boolean {
    return !this.activeColoredYarnSetCreelMap.creelPositions.some(
      ({creelPosition, currentPositionOnMachine}: {creelPosition: number; currentPositionOnMachine: number}) => creelPosition === position && currentPositionOnMachine === position
    );
  }

  protected getSizeOfCreelPositions(): number {
    return this.coloredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions.length;
  }

  protected getCellHeight(): number {
    const height =
      this.previewType === PreviewType.COLOR
        ? this.getMaximumAmountOfColorsInColoredYarnSet(this.coloredYarnSetWithStartDent)
        : this.getMaximumAmountOfYarnSetsInColoredYarnSet(this.coloredYarnSetWithStartDent);

    return height * CreelMappingTableBaseComponent.colorLineHeightInPx + CreelMappingTableBaseComponent.cellPaddingInPx;
  }

  private findByPosition(list: OverviewListCreelPosition[], creelPosition: number): OverviewListCreelPosition {
    return list.find(({position}: OverviewListCreelPosition) => position === creelPosition);
  }
}
