import {MachineType} from './machine-type.enum';

export class Equipment {
  private _id: number;
  private _machineType: MachineType;
  private _name: string;
  private _orderId: string;

  public constructor(id: number, machineType: MachineType, name: string, orderId: string) {
    this._id = id;
    this._machineType = machineType;
    this._name = name;
    this._orderId = orderId;
  }

  public static fromJSON(machineJSON: any): Equipment {
    return new Equipment(machineJSON.id, MachineType[`${machineJSON.machineType}`], machineJSON.name, machineJSON.orderId);
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get machineType(): MachineType {
    return this._machineType;
  }

  public set machineType(machineType: MachineType) {
    this._machineType = machineType;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get orderId(): string {
    return this._orderId;
  }

  public set orderId(orderId: string) {
    this._orderId = orderId;
  }

  public toJSON(): JSON {
    return {
      machineType: `${MachineType[this.machineType]}`,
      name: this.name,
      orderId: this.orderId
    } as any as JSON;
  }
}
