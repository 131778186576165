import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {dateComparator} from '@application/helper/date-comparator';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {StringUtils} from '@application/helper/string-utils';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PageUrls} from '@domain/textile-data/page-urls';
import {ProductionOrdersWeaving, PRODUCTION_ORDERS_WEAVING} from '@infrastructure/http/production-order-weaving/production-orders-weaving';
import {ProductionOrderLiteStatusChange} from '@infrastructure/signalr/production-order-lite/production-order-lite-status-change';
import {RealtimeProductionOrderLite, REALTIME_PRODUCTION_ORDER_LITE} from '@infrastructure/signalr/production-order-lite/realtime-production-order-lite';
import {ProductionScheduleOverviewStatusComponent} from '@presentation/pages/texfab/production-schedule/overview/status/production-schedule-overview-status.component';
import {TextileDataOverviewComponent} from '@presentation/pages/textile-data/textile-data-overview/textile-data-overview.component';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {AgGridUtils, BaseComponent, ColDefBuilderFactoryService, LinkIconRendererComponent, TranslateService} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ITooltipParams, RowNode, ValueGetterParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {OverviewListProductionOrderWeaving} from './overview-list-production-order-weaving';

@Component({
  templateUrl: './production-order-weaving-overview-page.component.html'
})
export class ProductionOrderWeavingOverviewPageComponent extends BaseComponent implements OnInit {
  @ViewChild('textileDataOverview') public textileDataOverview: TextileDataOverviewComponent;

  public listOfProductionOrdersWeaving: OverviewListProductionOrderWeaving[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.PRODUCTION_ORDER_WEAVING_OVERVIEW;

  private pageUrls: PageUrls;

  public constructor(
    @Inject(PRODUCTION_ORDERS_WEAVING) public readonly productionOrdersWeaving: ProductionOrdersWeaving,
    @Inject(REALTIME_PRODUCTION_ORDER_LITE) private readonly realtimeProductionOrdersLite: RealtimeProductionOrderLite,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.pageUrls = LastModifiedCardUtils.getPageUrls('productionOrderWeaving');

    this.productionOrdersWeaving
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfProductionOrdersWeaving: OverviewListProductionOrderWeaving[]) => {
        this.listOfProductionOrdersWeaving = listOfProductionOrdersWeaving;
        this.subscribeToProductionOrderLiteUpdates();
      });
  }

  public getColumnDefsForListOfProductionOrdersWeaving(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('status')
        .withHeaderName('GENERAL.STATUS')
        .withCellRenderer(ProductionScheduleOverviewStatusComponent)
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`PRODUCTION_ORDER.STATUS.${params.value}`))
        .withCellClass('center')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .withLockVisible(true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withField('weaveProduct.name', true).withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('machine.name')
        .withHeaderName('MACHINE.MACHINE')
        .withComparator(StringUtils.stringComparator)
        .withTooltipValueGetter((params: ITooltipParams) => params.data.machine?.name)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('plannedLength')
        .withHeaderName('PRODUCTION_ORDER_LITE.PLANNED_LENGTH')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getValueWithMeter(params.data.plannedLength), true)
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('dueDate')
        .withHeaderName('GENERAL.DATETIME.DUE_DATE')
        .withCellRenderer((params: ICellRendererParams) => (!isNil(params.getValue()) ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()) : ''))
        .withTooltipValueGetter((params: ITooltipParams) => (!isNil(params.value) ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value) : ''))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('dateCreated')
        .withHeaderName('GENERAL.DATETIME.DATE_CREATED')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('actualStartDate')
        .withHeaderName('GENERAL.DATETIME.START_DATE')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('actualEndDate')
        .withHeaderName('GENERAL.DATETIME.END_DATE')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build()
    ];
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(this.pageUrls.settings);
  }

  private getValueWithMeter(value: number): string {
    return `${value} m`;
  }

  private subscribeToProductionOrderLiteUpdates(): void {
    this.realtimeProductionOrdersLite
      .getProductionOrdersLiteStatusChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(({id, status}: ProductionOrderLiteStatusChange) => this.updateProductionOrderLiteStatus(id, status));
  }

  private updateProductionOrderLiteStatus(id: number, status: ProductionScheduleStatus): void {
    this.textileDataOverview.forEachNode((node: RowNode) => {
      if (node.data?.id === id) {
        node.data.status = status;
        node.setData(node.data);
      }
    });
  }
}
