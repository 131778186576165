import {AdvancedSearchBoolInput} from './advanced-search-bool-input.enum';
import {AdvancedSearchDateInput} from './advanced-search-date-input.enum';
import {AdvancedSearchEnumInput} from './advanced-search-enum-input.enum';
import {AdvancedSearchNumberInput} from './advanced-search-number-input.enum';
import {AdvancedSearchObjectInput} from './advanced-search-object-input.enum';
import {AdvancedSearchStringInput} from './advanced-search-string-input.enum';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AdvancedSearchInput = {
  ...AdvancedSearchEnumInput,
  ...AdvancedSearchNumberInput,
  ...AdvancedSearchObjectInput,
  ...AdvancedSearchStringInput,
  ...AdvancedSearchDateInput,
  ...AdvancedSearchBoolInput
};

export type AdvancedSearchInput = typeof AdvancedSearchInput;
