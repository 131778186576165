import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {FixedShape} from '@domain/drawing-library/fixed-shape.enum';
import {OrderLinesTarget} from '@domain/order-lines-target.enum';
import {OrderLine} from '@domain/orderbook/order-line';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {Drawing} from '@domain/production-schedule/drawing';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {ColorSets, COLOR_SETS} from '@infrastructure/http/color-set/color-sets';
import {ColoredYarnSets, COLORED_YARN_SETS} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {Orderbook, ORDERBOOK} from '@infrastructure/http/orderbook/orderbook';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {AssertionUtils, BaseComponent, convertCommercialUnit, EnumUtils, LocaleUtils, skeletonViewAnimation, TranslateService, Unit} from '@vdw/angular-component-library';
import {L10nIntlService} from 'angular-l10n';
import {replace} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-orderline-details-sidebar',
  templateUrl: './orderline-details-sidebar.component.html',
  styleUrls: ['./orderline-details-sidebar.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .button-skeleton-wrapper')]
})
export class OrderlineDetailsSidebarComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public orderLineId: number;
  @Output() public orderlineDetailsSidebarClose: EventEmitter<void> = new EventEmitter<void>();
  public orderLine: OrderLine;
  public isLoadingOrderLine = true;
  public coloredYarnSet: ColoredYarnSet;
  public colorSet: ColorSet;
  public overviewListColorSet: OverviewListColorSet;
  public readonly dentsUnit = Unit.DENTS;
  public readonly picksUnit = Unit.PICKS;
  private currentSubscription: Subscription;
  private readonly l10nIntlService: L10nIntlService;
  private readonly translate: TranslateService;
  private readonly router: Router;

  public constructor(
    @Inject(ORDERBOOK) private readonly orderbook: Orderbook,
    l10nIntlService: L10nIntlService,
    translate: TranslateService,
    @Inject(COLOR_SETS) private readonly colorSets: ColorSets,
    @Inject(COLORED_YARN_SETS) private readonly coloredYarnSets: ColoredYarnSets,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    router: Router
  ) {
    super();

    this.l10nIntlService = l10nIntlService;
    this.translate = translate;
    this.router = router;
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.orderLine = OrderLine.createEmptyOrderLine();
    this.initializeOrderLine();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderLineId) {
      this.coloredYarnSet = null;
      this.colorSet = null;
      this.isLoadingOrderLine = true;
      this.initializeOrderLine();
    }
  }

  public closeOrderLineDetails(): void {
    this.orderlineDetailsSidebarClose.emit();
  }

  public getCommercialValueInCommercialUnit(value: number): string {
    const result = convertCommercialUnit({
      from: {
        unit: Unit.MILLIMETER,
        value
      },
      to: Unit.CENTIMETER
    });

    const unitTranslationKey = this.translate.instant(`GENERAL.UNIT.${EnumUtils.getKeyFromValue(Unit, Unit.CENTIMETER)}`);

    return `${LocaleUtils.formatNumber(result, this.l10nIntlService, 2)} ${unitTranslationKey}`;
  }

  public getTechnicalValueWithUnit(value: number, unit: Unit): string {
    const unitTranslationKey = this.translate.instant(`GENERAL.UNIT.${EnumUtils.getKeyFromValue(Unit, unit)}`);

    return `${LocaleUtils.formatNumber(value, this.l10nIntlService, 2)} ${unitTranslationKey}`;
  }

  public imageDownloaded(imageData: string): void {
    this.orderLine.article.design.image.data = imageData;
  }

  public navigateToProductionScheduleDetails(productionScheduleId: string | number): void {
    let path = RouteUtils.paths.texFab.productionOrder.editProductionOrder.absolutePath;
    if (this.hasPermissionToGoToNewBuilder()) {
      path = path + '/new';
    }
    this.router.navigate([replace(path, ':id', productionScheduleId.toString())]);
  }

  public canShowProductionSchedules(): boolean {
    return this.isLoadingOrderLine || this.orderLine.hasProductionSchedules();
  }

  public getOrderLineStatusLabel(): string {
    return OrderLineStatus[this.orderLine.status];
  }

  public isArticlePreviewMissingDesign(): boolean {
    return this.orderLine?.article?.design instanceof Drawing ? this.orderLine.article.design.missingDesign : false;
  }

  public getYarnSet(): OverviewListYarnSet {
    return this.orderLine.article.machineQuality.yarnSet;
  }

  private initializeOrderLine(): void {
    this.orderbook
      .getById(this.orderLineId, OrderLinesTarget.ORDER_BOOK)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((orderLine: OrderLine) => {
        this.orderLine = orderLine;

        if (this.orderLine.article.colorConfiguration?.coloredYarnSet?.id) {
          this.getColoredYarnSet();
        } else if (this.orderLine.article.colorConfiguration?.colorSet?.id) {
          this.getColorSet();
        }

        if (AssertionUtils.isNullOrUndefined(orderLine.article)) {
          this.isLoadingOrderLine = false;
        }
      });
  }

  private getColorSet(): void {
    this.colorSets
      .getById(this.orderLine.article.colorConfiguration?.colorSet?.id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (colorSet: ColorSet) => {
          this.colorSet = colorSet;
          this.overviewListColorSet = OverviewListColorSet.fromColorSet(colorSet);
          this.isLoadingOrderLine = false;
        },
        error: () => {
          this.colorSet = null;
        }
      });
  }

  private getColoredYarnSet(): void {
    this.coloredYarnSets
      .getById(this.orderLine.article.colorConfiguration?.coloredYarnSet?.id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (coloredYarnSet: ColoredYarnSet) => {
          this.coloredYarnSet = coloredYarnSet;
          this.isLoadingOrderLine = false;
        },
        error: () => {
          this.coloredYarnSet = null;
        }
      });
  }

  public getShape(shape: string): string {
    return FixedShape.getTranslationKeyForShape(shape, this.translate);
  }

  private hasPermissionToGoToNewBuilder(): boolean {
    return this.currentSubscription?.hasPermission(Permission.TEXFAB_NEW_BUILDER);
  }
}
