import {isEqual, isNil, replace, toLower} from 'lodash-es';

export enum MachineType {
  CARPET_LOOM = 'CARPET_LOOM',
  CARPET_LOOM_NO_JQ = 'CARPET_LOOM_NO_JQ',
  WEAVING_MACHINE = 'WEAVING_MACHINE',
  TUFT = 'TUFT',
  MYRIAD = 'MYRIAD',
  COLORTEC = 'COLORTEC',
  COLORTECLPLUS = 'COLORTECLPLUS',
  EXTRUSION = 'EXTRUSION',
  FINISHING = 'FINISHING',
  DYEING = 'DYEING',
  SUPERBADYE = 'SUPERBADYE',
  SUPERBAHEAT = 'SUPERBAHEAT',
  WINDER = 'WINDER',
  SUPERBAWINDER = 'SUPERBAWINDER',
  VELVET = 'VELVET',
  VELVET_NO_JQ = 'VELVET_NO_JQ',
  JACQUARD = 'JACQUARD',
  PLASTIC_MACHINE = 'PLASTIC_MACHINE',
  UNDEFINED = 'UNDEFINED'
}

export namespace MachineType {
  export function getIconNameForMachineType(machineType: MachineType): string {
    if (isEqual(machineType, MachineType.UNDEFINED) || isNil(machineType)) {
      return 'new-machine';
    } else if (machineType !== MachineType.WEAVING_MACHINE && machineType !== MachineType.VELVET_NO_JQ && machineType !== MachineType.PLASTIC_MACHINE) {
      return `${replace(toLower(MachineType[machineType]), /_/g, '-')}-machine`;
    } else {
      return machineType === MachineType.PLASTIC_MACHINE ? 'plastic-machine' : 'weaving-machine';
    }
  }
}
