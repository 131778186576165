import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {isNil} from 'lodash-es';
import {SettingsForFreeZonePosition} from '../settings-for-free-zone-position';

export class InitialFreeZone {
  private _left: SettingsForFreeZonePosition;
  private _inBetween: SettingsForFreeZonePosition;
  private _right: SettingsForFreeZonePosition;
  private _pattern: Pattern;

  public constructor(left: SettingsForFreeZonePosition, inBetween: SettingsForFreeZonePosition, right: SettingsForFreeZonePosition, pattern: Pattern) {
    this._left = left;
    this._inBetween = inBetween;
    this._right = right;
    this._pattern = pattern;

    if (this._pattern?.visualisations?.some((x: PatternVisualisation) => x?.isWeavePatternVisualisation())) {
      this._pattern.clearName();
    }
  }

  public get left(): SettingsForFreeZonePosition {
    return this._left;
  }

  public get inBetween(): SettingsForFreeZonePosition {
    return this._inBetween;
  }

  public get right(): SettingsForFreeZonePosition {
    return this._right;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public static fromJSON(initialFreeZonesJSON: any): InitialFreeZone {
    return new InitialFreeZone(
      this.settingsForFreeZonePositionFromJSON(initialFreeZonesJSON.left),
      this.settingsForFreeZonePositionFromJSON(initialFreeZonesJSON.inBetween),
      this.settingsForFreeZonePositionFromJSON(initialFreeZonesJSON.right),
      !isNil(initialFreeZonesJSON.pattern) ? Pattern.fromJSON(initialFreeZonesJSON.pattern) : null
    );
  }

  private static settingsForFreeZonePositionFromJSON(settingsForFreeZonePositionJSON: any): SettingsForFreeZonePosition {
    return !isNil(settingsForFreeZonePositionJSON)
      ? settingsForFreeZonePositionJSON
      : {
          commercialWidthInMM: null,
          technicalWidthInDents: null,
          useAsMaximum: null
        };
  }

  public toJSON(): JSON {
    return {
      left: {
        commercialWidthInMM: this._left.commercialWidthInMM,
        technicalWidthInDents: this._left.technicalWidthInDents,
        useAsMaximum: this._left.useAsMaximum
      },
      inBetween: {
        commercialWidthInMM: this._inBetween.commercialWidthInMM,
        technicalWidthInDents: this._inBetween.technicalWidthInDents,
        useAsMaximum: this._inBetween.useAsMaximum
      },
      right: {
        commercialWidthInMM: this._right.commercialWidthInMM,
        technicalWidthInDents: this._right.technicalWidthInDents,
        useAsMaximum: this._right.useAsMaximum
      },
      pattern: this._pattern.toJSON()
    } as any as JSON;
  }

  public isValid(): boolean {
    return (
      !isNil(this.pattern) &&
      !isNil(this.left) &&
      !isNil(this.left.commercialWidthInMM) &&
      !isNil(this.left.technicalWidthInDents) &&
      !isNil(this.inBetween) &&
      !isNil(this.inBetween.commercialWidthInMM) &&
      !isNil(this.inBetween.technicalWidthInDents) &&
      !isNil(this.right) &&
      !isNil(this.right.commercialWidthInMM) &&
      !isNil(this.right.technicalWidthInDents)
    );
  }
}
