import {Injectable} from '@angular/core';
import {FilterModelBuilder} from './grid-model-builder/filter-model-builder';
import {FilterModelType} from './grid-model-builder/filter-model-type.enum';
import {GridModelBuilder} from './grid-model-builder/grid-model-builder';

@Injectable({providedIn: 'root'})
export class GridModelBuilderFactoryService {
  public getBuilder(): GridModelBuilder {
    return new GridModelBuilder();
  }

  public getFilterBuilder(filterModelType: FilterModelType): FilterModelBuilder {
    return new FilterModelBuilder(filterModelType);
  }
}
