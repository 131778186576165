import {Component, HostListener, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {isClickOutsideGivenElements} from '@application/helper/is-click-outside-given-elements';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {PlanningPrototypeContextService, SelectedItemType} from '../utilities/planning-prototype-context.service';

@Component({
  selector: 'app-planning-prototype-sidebar',
  templateUrl: './planning-prototype-sidebar.component.html'
})
export class PlanningPrototypeSidebarComponent extends BaseComponent implements OnInit {
  private readonly CLASSES_THAT_SHOULD_NOT_CLOSE_SIDEBAR = [
    'maintenance',
    'mat-sidebar',
    'needs-attention',
    'fixed-schedules',
    'order',
    'row-indicator',
    'run',
    'mat-drawer-inner-container',
    'cdk-overlay-container'
  ];

  public selectedItemIsMachine = false;
  public selectedItemIsPlanningItem = false;
  public showOrdersToPlan = false;
  public showItemCreation = false;
  public showFixedSchedulesToPlan = false;

  public constructor(
    private readonly planningContext: PlanningPrototypeContextService,
    private readonly sideNav: MatSidenav
  ) {
    super();
  }

  public ngOnInit(): void {
    this.planningContext.selectedItemChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(this.onSelectedItemChanged);
  }

  @HostListener('document:click', ['$event'])
  public onClickOutsideSidebar(event: MouseEvent): void {
    if (isClickOutsideGivenElements(event.target as HTMLElement, this.CLASSES_THAT_SHOULD_NOT_CLOSE_SIDEBAR)) {
      this.planningContext.selectItem(null);
    }
  }

  private onSelectedItemChanged = (selectedItem: SelectedItemType): void => {
    if (AssertionUtils.isNullOrUndefined(selectedItem)) {
      this.sideNav.close();
    } else {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      this.sideNav.open();
    }
    this.selectedItemIsMachine = selectedItem instanceof PlanningEquipment;
    this.selectedItemIsPlanningItem = selectedItem instanceof PlanningItem;
    this.showOrdersToPlan = selectedItem === this.planningContext.ordersToPlan;
    this.showItemCreation = selectedItem === this.planningContext.itemCreationPlaceholder;
    this.showFixedSchedulesToPlan = selectedItem === this.planningContext.fixedSchedulesToPlan;
    if (!this.showItemCreation) {
      this.planningContext.itemCreationPlaceholder = null;
    }
  };
}
