import {CustomerAddressDetails} from './customer-address-details';
import {CustomerContactDetails} from './customer-contact-details';

export class Customer {
  private _id: number;
  private _number: number;
  private _name: string;
  private _contactDetails: CustomerContactDetails;
  private _addressDetails: CustomerAddressDetails;
  private _comments: string;
  private _used: boolean;

  public constructor(id: number, number: number, name: string, contactDetails: CustomerContactDetails, addressDetails: CustomerAddressDetails, comments: string, used?: boolean) {
    this._id = id;
    this._number = number;
    this._name = name;
    this._contactDetails = contactDetails;
    this._addressDetails = addressDetails;
    this._comments = comments;
    this._used = used;
  }

  public static fromJSON(customerJSON: any): Customer {
    const customerContactDetails = CustomerContactDetails.fromJSON(customerJSON);
    const customerAddressDetails = CustomerAddressDetails.fromJSON(customerJSON.address);
    return new Customer(customerJSON.id, customerJSON.customerNumber, customerJSON.name, customerContactDetails, customerAddressDetails, customerJSON.comments, customerJSON.used);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      customerNumber: this._number,
      name: this._name,
      comments: this._comments,
      address: this.addressDetails.toJSON(),
      ...this.contactDetails.toJSON()
    } as any as JSON;
  }

  public get id(): number {
    return this._id;
  }

  public get number(): number {
    return this._number;
  }

  public get name(): string {
    return this._name;
  }

  public get addressDetails(): CustomerAddressDetails {
    return this._addressDetails;
  }

  public get contactDetails(): CustomerContactDetails {
    return this._contactDetails;
  }

  public get comments(): string {
    return this._comments;
  }

  public get used(): boolean {
    return this._used;
  }
}
