import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {DialogModule} from '../../dialogs/dialog.module';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {MobileDropdownComponent} from './mobile-dropdown.component';
import {MobileSelectorComponent} from './mobile-selector/mobile-selector.component';

@NgModule({
  declarations: [MobileDropdownComponent, MobileSelectorComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatButtonToggleModule, MatDialogModule, TranslationModule, DialogModule, LabelModule],
  exports: [MobileDropdownComponent]
})
export class MobileDropdownModule {}
