import {isEqual} from 'lodash-es';

export function dateComparator(valueA: Date, valueB: Date): number {
  let result: number;

  valueA ??= new Date(0);
  valueB ??= new Date(0);

  if (isEqual(valueA, valueB)) {
    result = 0;
  } else if (valueA > valueB) {
    result = 1;
  } else {
    result = -1;
  }

  return result;
}
