import {OverlayRef} from '@angular/cdk/overlay';
import {isNil} from 'lodash-es';

export class ConnectivityToastRef {
  private readonly overlayRef: OverlayRef;

  public constructor(overlayRef: OverlayRef) {
    this.overlayRef = overlayRef;
  }

  public close(): void {
    this.overlayRef.dispose();
  }

  public isVisible(): boolean {
    return !isNil(this.overlayRef?.overlayElement);
  }
}
