import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class BuggyRoute extends RouteBranch {
  private _addBuggy: RouteLeaf;
  private _duplicateBuggy: RouteLeaf;
  private _editBuggy: RouteLeaf;

  public get addBuggy(): RouteLeaf {
    return this._addBuggy;
  }

  public set addBuggy(value: RouteLeaf) {
    this.replace(this._addBuggy, value);
    this._addBuggy = value;
  }

  public get duplicateBuggy(): RouteLeaf {
    return this._duplicateBuggy;
  }

  public set duplicateBuggy(value: RouteLeaf) {
    this.replace(this._duplicateBuggy, value);
    this._duplicateBuggy = value;
  }

  public get editBuggy(): RouteLeaf {
    return this._editBuggy;
  }

  public set editBuggy(value: RouteLeaf) {
    this.replace(this._editBuggy, value);
    this._editBuggy = value;
  }
}
