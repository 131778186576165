import {StringUtils} from '@application/helper/string-utils';
import {IdNameVersion} from '@domain/id-name-version';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';

export class DrawingConfigurationWeaveQuality extends IdNameVersion {
  public get technicalNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.name, this.version);
  }

  public static fromJSON(weaveQualityJSON: any): DrawingConfigurationWeaveQuality {
    return new DrawingConfigurationWeaveQuality(weaveQualityJSON.id, weaveQualityJSON.name, weaveQualityJSON.version);
  }

  public static fromOverviewListMachineQuality(overviewListMachineQuality: OverviewListMachineQuality): DrawingConfigurationWeaveQuality {
    return new DrawingConfigurationWeaveQuality(overviewListMachineQuality.id, overviewListMachineQuality.technicalName, overviewListMachineQuality.version);
  }
}
