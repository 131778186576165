import {OverviewListDoubleWidthWeaveStructure} from './overview-list-double-width-weave-structure';
import {WeaveStructureForMachineQuality} from './weave-structure-for-machine-quality';

export class DoubleWidthWeaveStructure {
  private readonly _id?: number;
  private readonly _topWeaveStructure: WeaveStructureForMachineQuality;
  private readonly _bottomWeaveStructure: WeaveStructureForMachineQuality;
  private readonly _backgroundWeaveStructure: WeaveStructureForMachineQuality;

  public constructor(
    topWeaveStructure: WeaveStructureForMachineQuality,
    bottomWeaveStructure: WeaveStructureForMachineQuality,
    backgroundWeaveStructure: WeaveStructureForMachineQuality,
    id?: number
  ) {
    this._id = id;
    this._topWeaveStructure = topWeaveStructure;
    this._bottomWeaveStructure = bottomWeaveStructure;
    this._backgroundWeaveStructure = backgroundWeaveStructure;
  }

  public get id(): number {
    return this._id;
  }

  public get topWeaveStructure(): WeaveStructureForMachineQuality {
    return this._topWeaveStructure;
  }

  public get bottomWeaveStructure(): WeaveStructureForMachineQuality {
    return this._bottomWeaveStructure;
  }

  public get backgroundWeaveStructure(): WeaveStructureForMachineQuality {
    return this._backgroundWeaveStructure;
  }

  public static fromJSON(doubleWidthWeaveStructureJSON: any): DoubleWidthWeaveStructure {
    return doubleWidthWeaveStructureJSON.doubleWidth
      ? new DoubleWidthWeaveStructure(
          WeaveStructureForMachineQuality.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.topWeaveStructure),
          WeaveStructureForMachineQuality.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.bottomWeaveStructure),
          WeaveStructureForMachineQuality.fromJSON(doubleWidthWeaveStructureJSON.doubleWidth.backgroundWeaveStructure),
          doubleWidthWeaveStructureJSON.doubleWidth.id
        )
      : null;
  }

  public static fromOverviewListDoubleWidthWeaveStructure(overviewListDoubleWidthWeaveStructure: OverviewListDoubleWidthWeaveStructure): DoubleWidthWeaveStructure {
    return new DoubleWidthWeaveStructure(
      WeaveStructureForMachineQuality.fromJSON(overviewListDoubleWidthWeaveStructure.topWeaveStructure),
      WeaveStructureForMachineQuality.fromJSON(overviewListDoubleWidthWeaveStructure.bottomWeaveStructure),
      WeaveStructureForMachineQuality.fromJSON(overviewListDoubleWidthWeaveStructure.backgroundWeaveStructure)
    );
  }
}
