<mat-toolbar class="bms-theme toolbar" [ngClass]="{shadow: !centered, column: hasHintsElement()}">
  <div class="wrapper gap-8">
    <div class="row-wrapper end">
      <ng-container *ngIf="withCancelButton">
        <button *ngIf="hasDefaultCancelDialogButton()" mat-button [mat-dialog-close]>
          {{ cancelEventName | bmsTranslate }}
        </button>
        <button *ngIf="!hasDefaultCancelDialogButton()" mat-button (click)="cancelButtonClicked()">
          {{ cancelEventName | bmsTranslate }}
        </button>
      </ng-container>
      <ng-content></ng-content>
      <ng-container *ngIf="!centered || (centered && withSaveButton)">
        <vdw-split-button *ngIf="hasSaveMenu(); else singleAction" [label]="'ANGULAR_COMPONENT_LIBRARY.FOOTER.SAVE' | bmsTranslate" [disabled]="locked" (buttonClicked)="saveButtonClicked()">
          <button *ngIf="hasSaveAndCloseButton()" mat-menu-item (click)="saveAndCloseButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.FOOTER.SAVE_AND_CLOSE' | bmsTranslate }}</button>
          <button *ngIf="hasSaveAndCreateNewButton()" mat-menu-item (click)="saveAndCreateNewButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.FOOTER.SAVE_AND_CREATE' | bmsTranslate }}</button>
        </vdw-split-button>
        <ng-template #singleAction>
          <button mat-flat-button [disabled]="locked" (click)="saveButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.FOOTER.SAVE_CHANGES' | bmsTranslate }}</button>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="centered && withChooseButton">
        <button *ngIf="hasDefaultChooseDialogButton()" mat-flat-button [mat-dialog-close]="chooseDialogCloseValue" [disabled]="locked">
          {{ chooseEventName | bmsTranslate }}
        </button>
        <vdw-progress-button
          *ngIf="!hasDefaultChooseDialogButton()"
          [class]="CHOOSE_BUTTON_CLASS_NAME"
          [label]="chooseEventName"
          [disabled]="locked"
          [inProgress]="chooseEventInProgress"
          [canShowBMSTheme]="canShowBMSTheme"
          (progressClick)="chooseButtonClicked()"
        ></vdw-progress-button>
      </ng-container>
    </div>
    <ng-content select="[hints]"></ng-content>
  </div>
</mat-toolbar>
