import {PathLayoutTemplatePath} from './path-layout-template-path';

export class PathLayoutTemplate {
  private _id: number;
  private _name: string;
  private _loomGroup: string;
  private _pathLayoutTemplatePaths: PathLayoutTemplatePath[];

  public constructor(id: number, name: string, loomGroup: string, pathLayoutTemplatePaths: PathLayoutTemplatePath[]) {
    this._id = id;
    this._name = name;
    this._loomGroup = loomGroup;
    this._pathLayoutTemplatePaths = pathLayoutTemplatePaths;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get loomGroup(): string {
    return this._loomGroup;
  }

  public set loomGroup(value: string) {
    this._loomGroup = value;
  }

  public get pathLayoutTemplatePaths(): PathLayoutTemplatePath[] {
    return this._pathLayoutTemplatePaths;
  }

  public set pathLayoutTemplatePaths(value: PathLayoutTemplatePath[]) {
    this._pathLayoutTemplatePaths = value;
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      loomType: this._loomGroup,
      pathLayoutTemplatePaths: this._pathLayoutTemplatePaths.map((pathLayoutTemplatePath: PathLayoutTemplatePath) => pathLayoutTemplatePath.toJSON())
    } as any as JSON;
  }

  public static fromJSON(pathLayoutTemplateJSON: any): PathLayoutTemplate {
    return new PathLayoutTemplate(
      pathLayoutTemplateJSON.id,
      pathLayoutTemplateJSON.name,
      pathLayoutTemplateJSON.loomType,
      pathLayoutTemplateJSON.pathLayoutTemplatePaths?.map((pathLayoutTemplatePath: any) => PathLayoutTemplatePath.fromJSON(pathLayoutTemplatePath))
    );
  }

  public canDuplicate(): boolean {
    return true;
  }
}
