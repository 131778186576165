import {OverviewListColorSetStubFactory} from '@application/helper/textile-data/color-set/overview-list-color-set-stub-factory';
import {OverviewListYarnSetStubFactory} from '@application/helper/textile-data/yarn-set/overview-list-yarn-set-stub-factory';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';

export class OverviewListColoredYarnSetStubFactory {
  public static getRandomColoredYarnSet(): OverviewListColoredYarnSet {
    return OverviewListColoredYarnSetStubFactory.getRandomColoredYarnSetWithId(1);
  }

  public static getRandomColoredYarnSetWithId(id: number): OverviewListColoredYarnSet {
    return OverviewListColoredYarnSetStubFactory.getRandomColoredYarnSetWithIdAndNumberOfCreelPositions(id, 5);
  }

  public static getRandomColoredYarnSetWithIdAndName(id: number, name: string): OverviewListColoredYarnSet {
    return new OverviewListColoredYarnSet(
      id,
      name,
      OverviewListColorSetStubFactory.getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(1, 3),
      OverviewListYarnSetStubFactory.getRandomYarnSetOverviewListWithIdAndNumberOfCreelPositions(1, 3),
      false
    );
  }

  public static getRandomColoredYarnSetWithIdAndNumberOfCreelPositions(id: number, numberOfCreelPositions: number): OverviewListColoredYarnSet {
    return new OverviewListColoredYarnSet(
      id,
      `ColoredYarnSet_${id}`,
      OverviewListColorSetStubFactory.getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(1, numberOfCreelPositions),
      OverviewListYarnSetStubFactory.getRandomYarnSetOverviewListWithIdAndNumberOfCreelPositions(1, numberOfCreelPositions),
      false
    );
  }
}
