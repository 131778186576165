import {isEqual} from 'lodash-es';

export function intComparator(valueA: any, valueB: any): number {
  let result: number;

  valueA ??= 0;
  valueB ??= 0;

  if (isEqual(valueA, valueB)) {
    result = 0;
  } else if (valueA > valueB) {
    result = 1;
  } else {
    result = -1;
  }

  return result;
}
