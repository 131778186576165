<section #rootElement class="full-height">
  <mat-icon
    *ngIf="canShowArrow()"
    class="arrow"
    [class.top]="isArrowPositionedAtTop()"
    [class.bottom]="isArrowPositionedAtBottom()"
    [class.right]="isArrowPositionedAtRight()"
    [class.left]="isArrowPositionedAtLeft()"
    [style.top.px]="arrowPositionTop"
    [style.left.px]="arrowPositionLeft"
    svgIcon="atoms-arrow"
  ></mat-icon>
  <ng-template #container> </ng-template>
</section>
