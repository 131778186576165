import {Component, OnDestroy} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {ColDefBuilderFactoryService, DialogData, GridOptionsBuilderFactoryService} from '@vdw/angular-component-library';
import {GetDataPath, GetRowIdParams, GridOptions, GridReadyEvent, IRowNode, SelectionChangedEvent, ValueGetterParams} from 'ag-grid-community';
import {NEVER, Observable} from 'rxjs';
import {FormattedMachineGroup, PlanningFilterService} from '../utilities/planning-filter.service';

@Component({
  selector: 'app-machine-group-filter',
  templateUrl: './machine-group-filter.component.html',
  styleUrls: ['./machine-group-filter.component.scss']
})
export class MachineGroupFilterComponent implements DialogData<number[]>, OnDestroy {
  private selectionCache: number[] = [];
  public gridOptions: GridOptions;
  public get rowData(): FormattedMachineGroup[] {
    return this.planningFilterService.formattedMachineGroups;
  }

  public constructor(
    private readonly planningFilterService: PlanningFilterService,
    private readonly gridOptionsBuilder: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService
  ) {
    this.gridOptions = this.initGridOptions();
  }

  public ngOnDestroy(): void {
    this.planningFilterService.updateSelectedMachineGroups(this.selectionCache);
  }

  public getValue(): Observable<number[]> {
    return NEVER;
  }

  public getDataPath: GetDataPath<FormattedMachineGroup> = (data: FormattedMachineGroup) => data.hierarchy;

  private onSelectionChanged = (changed: SelectionChangedEvent<FormattedMachineGroup>): void => {
    const selectedRows = changed.api.getSelectedRows();
    const allSelectedGroups = selectedRows.map((node: FormattedMachineGroup) => node.id);
    this.selectionCache = selectedRows
      .filter((group: FormattedMachineGroup) => !group.hierarchy.slice(0, -1).some((id: string) => allSelectedGroups.includes(Number(id))))
      .map((node: FormattedMachineGroup) => node.id);
  };

  private getRowId = (params: GetRowIdParams<FormattedMachineGroup>): string => params.data.id.toString();

  private onGridReady = (gridReady: GridReadyEvent<FormattedMachineGroup>): void => {
    gridReady.api.forEachNode((node: IRowNode<FormattedMachineGroup>) => {
      node.setSelected(this.planningFilterService.selectedMachineGroups.includes(node.data.id));
    });
    gridReady.api.sizeColumnsToFit();
  };

  private initGridOptions(): GridOptions {
    const autoGroupColumnDef = this.colDefBuilderFactory
      .getBuilder()
      .withHeaderName('PLANNING.MACHINE_GROUP')
      .withFlex(1)
      .withResizable(false)
      .withCheckboxSelection(true)
      .withPinned(false)
      .withoutFilter()
      .withSortable(true)
      .withSuppressHeaderMenuButton()
      .withValueGetter((params: ValueGetterParams<FormattedMachineGroup>) => params.data.name)
      .build();

    return this.gridOptionsBuilder
      .getBuilder([], GridIdentifier.SELECT_MACHINE_GROUP)
      .withRowSelection(true, true, false, false, false)
      .withAutoGroupColumnDef(autoGroupColumnDef)
      .withTreeData()
      .withoutColumnMenu()
      .withGetRowId(this.getRowId)
      .withOnGridReady(this.onGridReady)
      .withOnSelectionChanged(this.onSelectionChanged)
      .build();
  }
}
