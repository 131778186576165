import {Injectable} from '@angular/core';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';

@Injectable({
  providedIn: 'root'
})
export class NavigationContextService {
  public companies: Company[];
  public subscriptions: Subscription[];
}
