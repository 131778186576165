import {PathLabelType} from '@domain/production-schedule/path-label-type.enum';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {isEqual, isNil, map} from 'lodash-es';

export class PathLabelInformationForPart {
  private _placeholders: PlaceholderComponent[];
  private _pathLabelType: PathLabelType;

  public constructor(placeholders: PlaceholderComponent[], pathLabelType: PathLabelType) {
    this._placeholders = placeholders;
    this._pathLabelType = pathLabelType;
  }

  public get placeholders(): PlaceholderComponent[] {
    return this._placeholders;
  }

  public set placeholders(value: PlaceholderComponent[]) {
    this._placeholders = value;
  }

  public get pathLabelType(): PathLabelType {
    return this._pathLabelType;
  }

  public set pathLabelType(value: PathLabelType) {
    this._pathLabelType = value;
  }

  public static fromJSON(pathLabelInformationForPartJSON: any): PathLabelInformationForPart {
    return isNil(pathLabelInformationForPartJSON)
      ? null
      : new PathLabelInformationForPart(
          map(pathLabelInformationForPartJSON.placeholders, (placeholdersJSON: any) => PlaceholderComponent.fromJSON(placeholdersJSON)),
          pathLabelInformationForPartJSON.pathLabelType
        );
  }

  public toJSON(): JSON {
    return {
      placeholders: map(this._placeholders, (placeholder: PlaceholderComponent) => {
        return placeholder.toFullJSON();
      }),
      pathLabelType: this._pathLabelType
    } as any as JSON;
  }

  public isText(): boolean {
    return isEqual(this._pathLabelType, PathLabelType.TEXT);
  }
}
