import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Subscription} from '@domain/profile/subscription';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {BaseComponent} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {MobileNavigationBarItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-item';
import {MobileNavigationBarRouteLinkItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-route-link-item';
import {MobileNavigationBarItemSize} from '@application/helper/mobile-navigation/mobile-navigation-bar-item-size.enum';
import {MobileNavigationComponentService} from './mobile-navigation-component.service';
import {MobileNavigationService} from '@application/services/navigation/mobile-navigation.service';

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.scss']
})
export class NavigationMobileComponent extends BaseComponent implements OnInit {
  public quickAccessNavBarItems: MobileNavigationBarItem[];
  private currentSubscription: Subscription;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly mobileNavigationService: MobileNavigationService,
    private readonly navigationComponentService: MobileNavigationComponentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.setNavBarItems();

    this.authentication
      .currentSubscriptionChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((currentSubscription: Subscription) => {
        this.currentSubscription = currentSubscription;
        this.setNavBarItems();
      });
  }

  private setNavBarItems(): void {
    this.quickAccessNavBarItems = this.mobileNavigationService.getQuickAccessBarItems(this.currentSubscription);
  }

  public isSmallItem(item: MobileNavigationBarItem): boolean {
    return item.size === MobileNavigationBarItemSize.SMALL;
  }

  public isActive(item: MobileNavigationBarItem): boolean {
    return this.navigationComponentService.isActive(item);
  }

  public chooseMenuItem(event: MouseEvent, navigationBarItem: MobileNavigationBarItem): any {
    if (navigationBarItem.id === 'search' && !this.isSearchAvailable()) {
      return;
    }
    this.navigationComponentService.chooseMenuItem(event, navigationBarItem, this.changeDetectorRef, this.unSubscribeOnViewDestroy);
  }

  public isSearchAvailable(item?: MobileNavigationBarItem): boolean {
    const activeNavigationBarItem = this.getCurrentActiveNavigationItem();

    return (activeNavigationBarItem as MobileNavigationBarRouteLinkItem)?.canSearch || (item && this.isActive(item) && item.id === 'search');
  }

  private getCurrentActiveNavigationItem(): MobileNavigationBarItem {
    return this.quickAccessNavBarItems.find((navigationBarItem: MobileNavigationBarItem) => this.isActive(navigationBarItem));
  }
}
