<div class="container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="4px" fxFlex="calc(100% - 40px)">
    <app-color-list-preview
      #colorListPreview
      *ngFor="let coloredYarnSet of listOfColoredYarnSets; let index = index"
      [colorList]="coloredYarnSet"
      [showDetailsOnClick]="false"
      (colorListSelected)="showColoredYarnSetDetails($event, index)"
      [maximumCreelPositionsToShow]="8"
      [creelMapping]="creelMaps ? creelMaps[index] : null"
      [startDent]="getStartDent(index)"
    >
    </app-color-list-preview>
  </div>
</div>
