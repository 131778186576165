import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {PlanningOrderData} from '../planning-order-data';
import {ProductionOrderYield} from '../production-order-yield';
import {PlanningWeaveProduct} from './planning-weave-product';

export class ProductionOrderWeavingForPlanningOrder extends PlanningOrderData {
  private _weaveProduct: PlanningWeaveProduct;

  public constructor(id: number, name: string, status: ProductionScheduleStatus, weaveProduct: PlanningWeaveProduct, orderYield: ProductionOrderYield, dueDate: Date) {
    super(id, name, dueDate, status);
    this._weaveProduct = weaveProduct;
    this.yield = orderYield;
  }

  public get weaveProduct(): PlanningWeaveProduct {
    return this._weaveProduct;
  }

  public static fromNeedsAttentionItemJSON(jsonData: any): ProductionOrderWeavingForPlanningOrder {
    return new ProductionOrderWeavingForPlanningOrder(
      jsonData.productionOrderLiteId,
      jsonData.name,
      jsonData.status,
      PlanningWeaveProduct.fromJSON(jsonData.weaveProduct),
      jsonData.yield,
      AssertionUtils.isNullOrUndefined(jsonData.dueDate) ? null : new Date(jsonData.dueDate)
    );
  }

  public static fromJSON(jsonData: any): ProductionOrderWeavingForPlanningOrder {
    return new ProductionOrderWeavingForPlanningOrder(
      jsonData.id,
      jsonData.name,
      jsonData.status,
      AssertionUtils.isNullOrUndefined(jsonData.weaveProduct) ? null : PlanningWeaveProduct.fromJSON(jsonData.weaveProduct),
      {current: null, planned: null},
      AssertionUtils.isNullOrUndefined(jsonData.dueDate) ? null : new Date(jsonData.dueDate)
    );
  }

  public toJSON(): JSON {
    return {
      plannedAmountInMeters: this.yield.planned,
      id: this.id,
      name: this.name,
      dueDate: this.dueDate,
      status: this.status
    } as any as JSON;
  }

  public canPrintWeavingOrder(): boolean {
    return false;
  }

  public canBeQueued(): boolean {
    return false;
  }
}
