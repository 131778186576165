import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {EpDrawing} from '@domain/production-schedule/ep-drawing';
import {TftDrawing} from '@domain/production-schedule/tft-drawing';

export function convertJSONToDrawing(drawingJSON: any): Drawing {
  let drawing: Drawing;

  if (drawingJSON.drawingType === DrawingType.BMP) {
    drawing = BmpDrawing.fromJSON(drawingJSON);
  } else if (drawingJSON.drawingType === DrawingType.EP) {
    drawing = EpDrawing.fromJSON(drawingJSON);
  } else {
    drawing = TftDrawing.fromJSON(drawingJSON);
  }

  return drawing;
}
