import {Component} from '@angular/core';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {TagColor} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-grid-order-line-status',
  templateUrl: './grid-order-line-status.component.html',
  styleUrls: ['./grid-order-line-status.component.scss']
})
export class GridOrderLineStatusComponent implements ICellRendererAngularComp {
  public statusLabel: string;
  public canBePlanned: boolean;
  public missingDesign: boolean;

  private readonly colorForStatus = {
    [OrderLineStatus.TO_PLAN]: TagColor.PRIMARY,
    [OrderLineStatus.PLANNED]: TagColor.SUCCESS,
    [OrderLineStatus.PRODUCED]: TagColor.SUCCESS,
    [OrderLineStatus.PARTIALLY_PLANNED]: TagColor.WARNING
  };

  public agInit(params: ICellRendererParams): void {
    this.statusLabel = OrderLineStatus[params.value];
    this.canBePlanned = params.data.canBePlanned;
    this.missingDesign = params.data.missingDesign;
  }

  public refresh(): boolean {
    return false;
  }

  public getColor(): TagColor {
    return this.colorForStatus[OrderLineStatus[this.statusLabel]];
  }
}
