export class UserIdentity {
  private readonly _subscriptionId: string;
  private readonly _companyId: string;
  private readonly _userId: string;

  public constructor(userId: string, companyId: string, subscriptionId: string) {
    this._userId = userId;
    this._companyId = companyId;
    this._subscriptionId = subscriptionId;
  }

  public get subscriptionId(): string {
    return this._subscriptionId;
  }

  public get companyId(): string {
    return this._companyId;
  }

  public get userId(): string {
    return this._userId;
  }

  public static fromJSON(userIdentityJSON: any): UserIdentity {
    return new UserIdentity(userIdentityJSON.userId, userIdentityJSON.companyId, userIdentityJSON.subscriptionId);
  }

  public toJSON(): JSON {
    return {
      userId: this._userId,
      companyId: this._companyId,
      subscriptionId: this._subscriptionId
    } as unknown as JSON;
  }
}
