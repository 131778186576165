export class MachineManufacturer {
  private _id: number;
  private _name: string;
  private _jacquardTypes: string[];

  public constructor(id: number, name: string = null, jacquardTypes: string[] = []) {
    this._id = id;
    this._name = name;
    this._jacquardTypes = jacquardTypes;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get jacquardTypes(): string[] {
    return this._jacquardTypes;
  }

  public static getRandomMachineManufacturerWithId(id: number): MachineManufacturer {
    return new MachineManufacturer(id, `Machine Manufacturer_0${id}`, []);
  }

  public static fromJSON(machineManufacturerJSON: any): MachineManufacturer {
    return new MachineManufacturer(machineManufacturerJSON.id, machineManufacturerJSON.name, machineManufacturerJSON.jacquardTypes);
  }
}
