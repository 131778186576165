import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AgGridAngular} from 'ag-grid-angular';
import {Observable, of} from 'rxjs';
import {CrudOverviewExtraActionConfig} from '../../crud-overview-data/interfaces/crud-overview-extra-action-config.interface';
import {DialogData} from '../../dialog-button/dialog-data.interface';

@Component({
  templateUrl: './grid-selection-extra-actions-dialog.component.html',
  styleUrls: ['./grid-selection-extra-actions-dialog.component.scss']
})
export class GridSelectionExtraActionsDialogComponent implements DialogData<any> {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly config: {extraActions: CrudOverviewExtraActionConfig[]; grid: AgGridAngular},
    private readonly dialogRef: MatDialogRef<GridSelectionExtraActionsDialogComponent>
  ) {}

  public getValue(): Observable<any> {
    return of();
  }

  public isActionAvailable(extraAction: CrudOverviewExtraActionConfig): boolean {
    return extraAction.onlySingleSelection === true ? this.config.grid?.api?.getSelectedRows()?.length === 1 : true;
  }

  public evaluateCloseDialog(extraAction: CrudOverviewExtraActionConfig): void {
    if (extraAction.closeMenuOnClick === true) {
      this.dialogRef.close();
    }
  }
}
