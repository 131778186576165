import {Injectable} from '@angular/core';
import {OnboardingDialogComponent} from '@presentation/components/onboarding-dialog/onboarding-dialog.component';
import {OnboardingType} from '@presentation/components/onboarding-dialog/onboarding-type';
import {DialogBuilder, DialogBuilderFactoryService, LocalStorageService} from '@vdw/angular-component-library';
import {defaultTo, find, first, get, isEqual, isNil, lowerCase, noop, pull} from 'lodash-es';
import {Observable, Subject} from 'rxjs';
import {Onboarding} from './onboarding';
import {OnboardingConfig} from './onboarding-config';

interface QueuedOnboarding {
  isRunning: boolean;
  config: OnboardingConfig;
}

@Injectable()
export class OnboardingService implements Onboarding {
  public static readonly LOCAL_STORAGE_KEY = 'onboarding';
  private queuedOnboarding: QueuedOnboarding[] = [];
  private dialogBuilder: DialogBuilder;
  private restartOnboardingSubject: Subject<void> = new Subject<void>();

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly localStorage: LocalStorageService
  ) {}

  public start(config: OnboardingConfig): void {
    this.queuedOnboarding.push({
      isRunning: false,
      config
    });

    this.processFirstItemInQueuedOnboarding();
  }

  public stop(): void {
    if (!isNil(this.dialogBuilder)) {
      this.dialogBuilder.close(false);
    }
  }

  public canStartOnboarding(onboardingType: OnboardingType): boolean {
    return !get(this.localStorage.get<Record<string, unknown>>(OnboardingService.LOCAL_STORAGE_KEY), lowerCase(onboardingType), false);
  }

  public onOnboardingRestart(): Observable<void> {
    return this.restartOnboardingSubject;
  }

  public restart(type: OnboardingType): void {
    this.updateOnboardingState(type, false);

    if (isEqual(type, OnboardingType.NAVIGATION)) {
      this.restartOnboardingSubject.next();
    }
  }

  public completeTypeOfOnboarding(type: OnboardingType): void {
    this.updateOnboardingState(type, true);
  }

  private processFirstItemInQueuedOnboarding(): void {
    const firstItemInQueue: QueuedOnboarding = first(this.queuedOnboarding);
    if (!isNil(firstItemInQueue) && !firstItemInQueue.isRunning) {
      firstItemInQueue.isRunning = true;
      const {panelClass, showBackdrop, type, steps, sourceElement, leftPositionInPx, position, onNextStep, onCompleted} = this.setDefaultValuesForOnboardingConfig(firstItemInQueue.config);

      this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder().withClass(panelClass);
      this.dialogBuilder = showBackdrop ? this.dialogBuilder.withCustomBackdrop('onboarding-backdrop') : this.dialogBuilder.withoutBackdrop();

      this.dialogBuilder
        .openDialog(OnboardingDialogComponent, {
          type,
          steps,
          onNextStep,
          sourceElement,
          leftPositionInPx,
          position
        })
        .subscribe((updateLocalStorage: boolean) => {
          if (updateLocalStorage) {
            this.completeTypeOfOnboarding(type);
            onCompleted?.();
            this.cleanUpAndHandleNextItemInQueue();
          } else {
            this.dialogBuilder = null;
            this.queuedOnboarding = [];
          }
        });
    }
  }

  private cleanUpAndHandleNextItemInQueue(): void {
    pull(this.queuedOnboarding, find(this.queuedOnboarding, {isRunning: true}));
    this.dialogBuilder = null;
    this.processFirstItemInQueuedOnboarding();
  }

  private setDefaultValuesForOnboardingConfig(config: OnboardingConfig): OnboardingConfig {
    config.showBackdrop = defaultTo(config.showBackdrop, false);
    config.leftPositionInPx = defaultTo(config.leftPositionInPx, null);
    config.steps = defaultTo(config.steps, []);
    config.panelClass = defaultTo(config.panelClass, 'onboarding-dialog');
    config.onNextStep = defaultTo(config.onNextStep, noop);

    return config;
  }

  private updateOnboardingState(type: OnboardingType, value: boolean): void {
    const storedOnboardingStates = defaultTo(this.localStorage.get<Record<string, unknown>>(OnboardingService.LOCAL_STORAGE_KEY), {});
    storedOnboardingStates[lowerCase(type)] = value;
    this.localStorage.set(OnboardingService.LOCAL_STORAGE_KEY, storedOnboardingStates);
  }
}
