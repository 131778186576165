import {PlanningMachineCreelChange} from '@domain/planning/carpet/planning-machine-creel-change';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningMachineMaintenance} from '@domain/planning/planning-machine-maintenance';
import {PlanningMachineRunFactory} from '@domain/planning/planning-machine-run-factory';
import {PlanningMachineUnavailability} from '@domain/planning/planning-machine-unavailability';
import {PlanningMachineWarpChange} from '@domain/planning/weaving/planning-machine-warp-change';
import {Subscription} from '@domain/profile/subscription';
import {MachineEventGroup} from '@presentation/pages/texbi/machine-event-board/machine-event-group';
import {AssertionUtils} from '@vdw/angular-component-library';
import {CalendarItem} from './calendar-item';

export class CalendarItemGroup {
  private _machineId: number;
  private _items: CalendarItem[];

  public constructor(machineId: number, items: CalendarItem[]) {
    this._machineId = machineId;
    this._items = items;
  }

  public get machineId(): number {
    return this._machineId;
  }

  public get items(): CalendarItem[] {
    return this._items;
  }

  public set items(items: CalendarItem[]) {
    this._items = items;
  }

  public static fromJSON(calendarItemGroupJSON: any, subscription: Subscription = null): CalendarItemGroup {
    return new CalendarItemGroup(
      calendarItemGroupJSON.machineId,
      calendarItemGroupJSON.items
        .map((itemJSON: any) => CalendarItemGroup.calendarItemFromJSON(itemJSON, subscription))
        .filter((itemGroup: CalendarItemGroup) => !AssertionUtils.isNullOrUndefined(itemGroup))
    );
  }

  private static calendarItemFromJSON(itemJSON: any, subscription: Subscription = null): CalendarItem {
    if (MachineEventGroup.canDeSerializeJSON(itemJSON)) {
      return MachineEventGroup.fromJSON(itemJSON, subscription);
    }

    const planningItems = {
      RUN: PlanningMachineRunFactory,
      CREEL_CHANGE: PlanningMachineCreelChange,
      MAINTENANCE: PlanningMachineMaintenance,
      MACHINE_UNAVAILABLE: PlanningMachineUnavailability,
      WARP_CHANGE: PlanningMachineWarpChange
    };
    return planningItems[`${itemJSON.type}`].fromJSON(itemJSON, subscription.timeZone.code);
  }

  private static calendarItemToJSON(item: CalendarItem, subscription: Subscription = null): CalendarItem {
    return (item as PlanningItem).toJSON(AssertionUtils.isNullOrUndefined(subscription) ? null : subscription.timeZone.code);
  }

  public toJSON(subscription: Subscription = null): JSON {
    return {
      machineId: this.machineId,
      items: this.items.map((item: CalendarItem) => CalendarItemGroup.calendarItemToJSON(item, subscription))
    } as any as JSON;
  }
}
