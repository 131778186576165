import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class MachineQualityRoute extends RouteBranch {
  private _addMachineQuality: RouteLeaf;
  private _duplicateMachineQuality: RouteLeaf;
  private _editMachineQuality: RouteLeaf;
  private _addFinishing: RouteLeaf;
  private _addFinishingFromQuality: RouteLeaf;
  private _duplicateFinishing: RouteLeaf;
  private _editFinishing: RouteLeaf;
  private _editFinishingFromQuality: RouteLeaf;

  public get addMachineQuality(): RouteLeaf {
    return this._addMachineQuality;
  }

  public set addMachineQuality(value: RouteLeaf) {
    this.replace(this._addMachineQuality, value);
    this._addMachineQuality = value;
  }

  public get duplicateMachineQuality(): RouteLeaf {
    return this._duplicateMachineQuality;
  }

  public set duplicateMachineQuality(value: RouteLeaf) {
    this.replace(this._duplicateMachineQuality, value);
    this._duplicateMachineQuality = value;
  }

  public get editMachineQuality(): RouteLeaf {
    return this._editMachineQuality;
  }

  public set editMachineQuality(value: RouteLeaf) {
    this.replace(this._editMachineQuality, value);
    this._editMachineQuality = value;
  }

  public get addFinishing(): RouteLeaf {
    return this._addFinishing;
  }

  public set addFinishing(value: RouteLeaf) {
    this.replace(this._addFinishing, value);
    this._addFinishing = value;
  }

  public get addFinishingFromQuality(): RouteLeaf {
    return this._addFinishingFromQuality;
  }

  public set addFinishingFromQuality(value: RouteLeaf) {
    this.replace(this._addFinishingFromQuality, value);
    this._addFinishingFromQuality = value;
  }

  public get duplicateFinishing(): RouteLeaf {
    return this._duplicateFinishing;
  }

  public set duplicateFinishing(value: RouteLeaf) {
    this.replace(this._duplicateFinishing, value);
    this._duplicateFinishing = value;
  }

  public get editFinishing(): RouteLeaf {
    return this._editFinishing;
  }

  public set editFinishing(value: RouteLeaf) {
    this.replace(this._editFinishing, value);
    this._editFinishing = value;
  }

  public get editFinishingFromQuality(): RouteLeaf {
    return this._editFinishingFromQuality;
  }

  public set editFinishingFromQuality(value: RouteLeaf) {
    this.replace(this._editFinishingFromQuality, value);
    this._editFinishingFromQuality = value;
  }
}
