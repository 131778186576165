import {Directive, Input, OnDestroy, OnInit, Optional, Output, TemplateRef} from '@angular/core';
import {PlanningRowComponent} from '../../planning-row/planning-row.component';
import {PlanningGroupComponent} from '../planning-group/planning-group.component';
import {PlanningItemData} from '../planning-item-base/planning-item-data';

@Directive({
  selector: '[vdwPlanningItem]'
})
export class PlanningItemDirective implements OnInit, OnDestroy {
  public readonly data = new PlanningItemData();

  @Input()
  public set startDate(value: Date) {
    this.data.startDate = value;
  }

  public get startDate(): Date {
    return this.data.startDate;
  }

  @Input()
  public set endDate(value: Date) {
    this.data.endDate = value;
  }

  public get endDate(): Date {
    return this.data.endDate;
  }

  @Input()
  public set applyCustomBorder(value: boolean) {
    this.data.applyCustomBorder = value;
  }

  public get applyCustomBorder(): boolean {
    return this.data.applyCustomBorder;
  }

  @Input()
  public set applyBackground(value: boolean) {
    this.data.applyBackground = value;
  }

  public get applyBackground(): boolean {
    return this.data.applyBackground;
  }

  @Input()
  public set dataTransfer(value: any) {
    this.data.dataTransfer = value;
  }

  public get dataTransfer(): any {
    return this.data.dataTransfer;
  }

  @Input()
  public set clickable(value: boolean) {
    this.data.clickable = value;
  }

  public get clickable(): boolean {
    return this.data.clickable;
  }

  @Input()
  public set class(value: string) {
    this.data.class = value;
  }

  public get class(): string {
    return this.data.class;
  }

  @Output()
  public selected = this.data.selected;

  public constructor(
    template: TemplateRef<any>,
    @Optional() private readonly group: PlanningGroupComponent,
    private readonly row: PlanningRowComponent
  ) {
    this.data.template = template;
  }

  public ngOnInit(): void {
    this.registerSelf();
  }

  public ngOnDestroy(): void {
    this.unRegisterSelf();
  }

  private registerSelf(): void {
    if (this.group != null) {
      this.group.data.addItem(this.data);
    } else {
      this.row.data.addItem(this.data);
    }
  }

  private unRegisterSelf(): void {
    if (this.group != null) {
      this.group.data.removeItem(this.data);
    } else {
      this.row.data.removeItem(this.data);
    }
  }
}
