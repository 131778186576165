import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {FixedSchedule} from './fixed-schedule';

export class FixedSchedulePlaceholder extends PlanningItem {
  private _fixedSchedule: FixedSchedule;

  public constructor(itemProperties: PlanningItemProperties, fixedSchedule: FixedSchedule) {
    super(itemProperties, PlanningItemType.FIXED_SCHEDULE_PLACEHOLDER);
    this._fixedSchedule = fixedSchedule;
  }

  public get fixedSchedule(): FixedSchedule {
    return this._fixedSchedule;
  }

  public override toJSON(clearCompletedItems?: boolean): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      fixedScheduleId: this.fixedSchedule.id
    } as any as JSON;
  }
}
