import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Unit} from '@vdw/angular-component-library';

export class OverviewListYarnTypeStubFactory {
  public static getRandomOverviewListYarnTypeWithId(id: number): OverviewListYarnType {
    return new OverviewListYarnType(
      id,
      `New yarn type ${id}`,
      5,
      Unit.NUMBER_METRIC,
      [
        {
          rawMaterial: 'bcf'
        },
        {
          rawMaterial: 'vis'
        }
      ],
      'Description',
      'Group',
      'Comment',
      {
        commercialLengthInMM: 1000.01,
        weightInKilogramPerSquareMeter: 1000,
        weightInKilogram: 1000
      }
    );
  }
}
