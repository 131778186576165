import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {Patterns} from './patterns';

@Injectable()
export class HttpPatternsService implements Patterns {
  private readonly httpClient: HttpClient;
  private readonly backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public getAllRestZonePatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/rest-zone?color-set=${colorSetId}`)
      .pipe(rxjsMap((restZonePatternsJSON: any) => map(restZonePatternsJSON, Pattern.fromJSON)));
  }

  public getAllFreeZonePatterns(machineQualityId: number): Observable<Pattern[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/free-zone`)
      .pipe(rxjsMap((freeZonePatternsJSON: any) => map(freeZonePatternsJSON, Pattern.fromJSON)));
  }

  public getAllJutePatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/jute?color-set=${colorSetId}`)
      .pipe(rxjsMap((jutePatternsJSON: any) => map(jutePatternsJSON, Pattern.fromJSON)));
  }

  public getJutePatternsOrDrawings(weaveStructureId: number): Observable<Pattern[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/jute`)
      .pipe(rxjsMap((freeZonePatternsJSON: any) => map(freeZonePatternsJSON, Pattern.fromJSON)));
  }

  public getJutePatterns(weaveStructureId: number): Observable<Pattern[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/jute?exclude-drawings=true`)
      .pipe(rxjsMap((freeZonePatternsJSON: any) => map(freeZonePatternsJSON, Pattern.fromJSON)));
  }

  public getWeavePatternsOrDrawings(weaveStructureId: number, excludeColors: boolean): Observable<Pattern[]> {
    const params = new HttpParams().set('exclude-colors', excludeColors);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/weave`, {params})
      .pipe(rxjsMap((weavePatternsOrDrawingsJSON: any) => map(weavePatternsOrDrawingsJSON, Pattern.fromJSON)));
  }

  public getPatternVisualisationByPatternId(weaveStructureId: number, weavePatternId: number, type: PatternVisualisationType): Observable<PatternVisualisation> {
    const params = new HttpParams().set('type', PatternVisualisationType[type]);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/${weavePatternId}/visualisation`, {params})
      .pipe(rxjsMap((patternVisualisationJSON: any) => Pattern.fromPatternVisualisationJSON(patternVisualisationJSON)));
  }

  public saveRestZonePattern(name: string, imageData: string, machineQualityId: number, colorSetId: number): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/rest-zone?color-set=${colorSetId}`, this.getNewPatternBody(name, imageData))
      .pipe(rxjsMap(({id}: {id: number}) => id));
  }

  public saveFreeZonePattern(name: string, imageData: string, machineQualityId: number): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/free-zone`, this.getNewPatternBody(name, imageData))
      .pipe(rxjsMap(({id}: {id: number}) => id));
  }

  public saveJutePattern(name: string, imageData: string, machineQualityId: number, colorSetId: number): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/patterns/jute?color-set=${colorSetId}`, this.getNewPatternBody(name, imageData))
      .pipe(rxjsMap(({id}: {id: number}) => id));
  }

  public saveJutePatternWithWeaveStructureId(name: string, imageData: string, weaveStructureId: number): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/jute`, this.getNewPatternBody(name, imageData))
      .pipe(rxjsMap(({id}: {id: number}) => id));
  }

  public saveWeavePatternWithWeaveStructureId(name: string, imageData: string, weaveStructureId: number): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}machinequalities/weavestructures/${weaveStructureId}/patterns/weave`, this.getNewPatternBody(name, imageData))
      .pipe(rxjsMap(({id}: {id: number}) => id));
  }

  public isIdentifierAvailable(identifier: string): Observable<boolean> {
    const params = new HttpParams().set('name', identifier);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/patterns/check-name`, {params})
      .pipe(rxjsMap((response: {patternNameAvailable: boolean}) => response.patternNameAvailable));
  }

  private getNewPatternBody(name: string, imageData: string): Record<string, string> {
    return {
      name,
      image: imageData,
      patternType: PatternVisualisationType[PatternVisualisationType.DRAWING]
    };
  }
}
