import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {dateComparator} from '@application/helper/date-comparator';
import {ErrorMessageHelper} from '@application/helper/error-message-helper';
import {intComparator} from '@application/helper/int-comparator';
import {StringUtils} from '@application/helper/string-utils';
import {DurationFormatPipe} from '@application/pipes/duration-format.pipe';
import {BackgroundJob} from '@domain/background-jobs/background-job';
import {BackgroundJobType} from '@domain/background-jobs/background-job-type';
import {BackgroundStatus} from '@domain/background-jobs/background-status';
import {BackgroundTask} from '@domain/background-jobs/background-task';
import {BackgroundTaskAction} from '@domain/background-jobs/background-task-action';
import {ListOverviewActionsComponent} from '@presentation/pages/texfab/production-schedule/overview/actions/list-overview-actions.component';
import {ListOverviewAction} from '@presentation/pages/texfab/production-schedule/overview/list-overview-action';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogBuilderFactoryService,
  DialogType,
  EnumUtils,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  ResponsivenessViewMode,
  RESPONSIVENESS_VIEW_MODE,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {
  ColDef,
  GetQuickFilterTextParams,
  GridOptions,
  ITooltipParams,
  RowClassParams,
  RowClickedEvent,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community';
import {findLast, first, flatMap, isEmpty, isEqual, isNil, map, reject, some} from 'lodash-es';
import {map as rxjsMap} from 'rxjs/operators';
import {BackgroundJobsOverviewStatusComponent} from '../background-jobs-overview-status/background-jobs-overview-status.component';
import {BackgroundTaskWithJob} from '../background-task-with-job';
import {DateCellRendererComponent} from '../date-cell-renderer/date-cell-renderer.component';
import {TypeDate} from '../date-cell-renderer/type-date';

@Component({
  selector: 'app-background-jobs-grid',
  templateUrl: './background-jobs-grid.component.html',
  styleUrls: ['./background-jobs-grid.component.scss']
})
export class BackgroundJobsGridComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('backgroundJobsGrid') public backgroundJobsGrid: AgGridAngular;

  @Input() public filterText: string;
  @Input() public backgroundJobs: BackgroundJob[];
  @Output() public backgroundJobSelected: EventEmitter<BackgroundJob> = new EventEmitter<BackgroundJob>();
  @Output() public retryBackgroundJob: EventEmitter<BackgroundJob> = new EventEmitter<BackgroundJob>();
  @Output() public cancelBackgroundJob: EventEmitter<BackgroundJob> = new EventEmitter<BackgroundJob>();

  @Input() public selectedBackgroundJobs: BackgroundJob[] = [];
  @Output() public selectedBackgroundJobsChange: EventEmitter<BackgroundJob[]> = new EventEmitter<BackgroundJob[]>();

  public backgroundTaskWithJobs: BackgroundTaskWithJob[];
  public gridOptionsForListOfBackgroundJobs: GridOptions;

  private jobType = 'BACKGROUND_JOBS.JOB_TYPE.JOB_TYPE';

  public constructor(
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly translate: TranslateService,
    private readonly durationFormatPipe: DurationFormatPipe,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly errorMessageHelper: ErrorMessageHelper
  ) {
    super();

    this.translate = translate;
    this.durationFormatPipe = durationFormatPipe;
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfBackgroundJobs();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('backgroundJobs' in changes && !changes.backgroundJobs.isFirstChange()) {
      if (this.responsivenessViewMode.isPhone) {
        this.backgroundTaskWithJobs = map(this.backgroundJobs, (backgroundJob: BackgroundJob) => {
          return {
            backgroundJob,
            backgroundTask: findLast(backgroundJob.tasks, (task: BackgroundTask) => !isEqual(task.status, BackgroundStatus.NOT_STARTED))
          };
        });
      } else {
        this.backgroundTaskWithJobs = flatMap(this.backgroundJobs, (backgroundJob: BackgroundJob) => {
          return flatMap(backgroundJob.tasks, (backgroundTask: BackgroundTask) => {
            return {
              backgroundJob,
              backgroundTask
            } as BackgroundTaskWithJob;
          });
        });
      }
    }
  }

  private setGridOptionsForListOfBackgroundJobs(): void {
    const titleForListOfJobs = 'BACKGROUND_JOBS.PROCESSES';

    this.gridOptionsForListOfBackgroundJobs = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefs(this.responsivenessViewMode.isPhone), GridIdentifier.BACKGROUND_JOBS)
      .withOnRowClicked((event: RowClickedEvent) => this.rowClick(event))
      .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => {
        if (!isEmpty(this.selectedBackgroundJobs)) {
          api.forEachNode((node: RowNode) => {
            node.setExpanded(some(this.selectedBackgroundJobs, {id: node.key}));
          });
        }
      })
      .withOnRowGroupOpened((event: RowGroupOpenedEvent) => this.handleBackgroundJobSelection(first(event.node.allLeafChildren).data.backgroundJob))
      .withLoadingOverlay()
      .withNoRowsOverlay({
        titleParam: titleForListOfJobs,
        hideDescription: true
      } as NoDataOverlayComponentParams)
      .withAutoGroupColumnDef(
        this.colDefBuilderFactoryService
          .getBuilder()
          .withHeaderName(this.jobType)
          .withCellRenderer('agGroupCellRenderer', {
            suppressCount: true
          })
          .withSortable(false)
          .withSuppressMovable()
          .build()
      )
      .withGetRowClass((params: RowClassParams): string | string[] => {
        let result: string | string[] = 'normal';
        if (params.node.group) {
          const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
          if (isEqual(backgroundTaskWithJob.backgroundJob.status, BackgroundStatus.FAILED)) {
            result = 'error';
          }
        } else {
          const backgroundTaskWithJob: BackgroundTaskWithJob = params.data;
          if (isEqual(backgroundTaskWithJob.backgroundJob.status, BackgroundStatus.FAILED)) {
            result = ['error', isEqual(backgroundTaskWithJob.backgroundTask.status, BackgroundStatus.FAILED) ? 'dark' : ''];
          }
        }
        return result;
      })
      .withSuppressRowClickSelection()
      .withoutGetRowId()
      .build();
  }

  private getStatusColumnDefForListOfBackgroundJobs(): ColDef {
    return this.colDefBuilderFactoryService
      .getBuilder()
      .withHeaderName('GENERAL.STATUS')
      .withValueGetter((params: ValueGetterParams) => {
        return params.node.group ? params.node.allLeafChildren[0].data.backgroundJob.status : params.data.backgroundTask.status;
      })
      .withComparator(intComparator)
      .withTooltipValueGetter((params: ITooltipParams) => this.getLabelForActiveTaskStatus(params))
      .withCellRenderer(BackgroundJobsOverviewStatusComponent)
      .build();
  }

  private getDesktopColumnDefsForListOfBackgroundJobs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('backgroundJob.id')
        .withRowGroup()
        .withHide(true)
        .withComparator(StringUtils.stringComparator)
        .withValueFormatter((params: ValueFormatterParams) => {
          let result = '';
          if (params.node.group) {
            const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
            result = this.translate.instant(`BACKGROUND_JOBS.JOB_TYPE.${EnumUtils.getKeyFromValue(BackgroundJobType, backgroundTaskWithJob.backgroundJob.jobType)}`);
          }
          return result;
        })
        .withGetQuickFilterText((params: GetQuickFilterTextParams) => {
          const backgroundTaskWithJob: BackgroundTaskWithJob = params.data;
          return EnumUtils.getKeyFromValue(BackgroundJobType, backgroundTaskWithJob.backgroundJob.jobType);
        })
        .withLockVisible()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('BACKGROUND_JOBS.TASK.TASK')
        .withComparator(StringUtils.stringComparator)
        .withTooltipField('name')
        .withValueGetter((params: ValueGetterParams) => this.getLabelForActiveTaskAction(params))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.NAME')
        .withTooltipField('backgroundJob.name')
        .withComparator(StringUtils.stringComparator)
        .withFilterValueGetter(this.getFilterValueForBackgroundJobName)
        .withValueGetter(this.getLabelForBackgroundJobName, true)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.DATETIME.START_DATE')
        .withValueGetter((params: ValueGetterParams) => {
          return params.node.group ? params.node.allLeafChildren[0].data.backgroundJob.startTime : params.data.backgroundTask.startTime;
        })
        .withComparator(dateComparator)
        .withCellRenderer(DateCellRendererComponent, {
          typeDate: TypeDate.START_DATE
        })
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.DATETIME.DURATION')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getDurationForRow(params))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withValueGetter((params: ValueGetterParams) => {
          return params.node.group ? params.node.allLeafChildren[0].data.backgroundJob.endTime : params.data.backgroundTask.endTime;
        })
        .withHeaderName('GENERAL.DATETIME.END_DATE')
        .withComparator(dateComparator)
        .withCellRenderer(DateCellRendererComponent, {
          typeDate: TypeDate.END_DATE
        })
        .build(),
      this.getStatusColumnDefForListOfBackgroundJobs(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.ACTIONS.ACTIONS')
        .withCellRenderer(ListOverviewActionsComponent)
        .withValueGetter((params: ValueGetterParams) => this.getActionsForBackgroundJob(params))
        .withSortable(false)
        .build()
    ];
  }

  private getMobileColumnDefsForListOfBackgroundJobs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('backgroundJob.jobType')
        .withHeaderName(this.jobType)
        .withComparator(StringUtils.stringComparator)
        .withTooltipField('name')
        .withValueFormatter((params: ValueFormatterParams) => this.translate.instant(`BACKGROUND_JOBS.JOB_TYPE.${EnumUtils.getKeyFromValue(BackgroundJobType, params.value)}`))
        .withGetQuickFilterText((params: GetQuickFilterTextParams) => this.translate.instant(`BACKGROUND_JOBS.JOB_TYPE.${EnumUtils.getKeyFromValue(BackgroundJobType, params.value)}`))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('backgroundTask.action')
        .withHeaderName('BACKGROUND_JOBS.TASK.LAST_TASK')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getLabelForActiveTaskAction(params))
        .withGetQuickFilterText((params: GetQuickFilterTextParams) => this.getLabelForActiveTaskAction(params))
        .build(),
      this.getStatusColumnDefForListOfBackgroundJobs()
    ];
  }

  private showErrorMessage(job: BackgroundJob): void {
    let errorMsg = job.errorMessage;
    try {
      let jsonMsg = JSON.parse(job.errorMessage);
      if (!AssertionUtils.isNullOrUndefined(jsonMsg?.errorCode)) {
        let result = this.errorMessageHelper.getErrorMessageFromBackendError(jsonMsg).pipe(
          rxjsMap((errorMessage: string) => {
            errorMsg = errorMessage;
          })
        );
        result.subscribe();
      }
    } catch {}

    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: 'GENERAL.ERRORS.SOMETHING_WRONG',
      messageText: errorMsg,
      type: DialogType.ERROR
    });
  }

  private getActionsForBackgroundJob(params: ValueGetterParams): ListOverviewAction[] {
    let actions: ListOverviewAction[] = [];

    if (params.node.group) {
      const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
      const backgroundJob = backgroundTaskWithJob.backgroundJob;

      if (backgroundJob.canRetry()) {
        actions = [
          {
            name: 'GENERAL.ACTIONS.RETRY',
            actionFn: (): void => this.retryBackgroundJob.emit(backgroundJob),
            cssClass: backgroundJob.hasFailed() ? 'error' : ''
          }
        ];
      } else if (backgroundJob.canBeCanceled()) {
        actions = [
          {
            name: 'GENERAL.ACTIONS.CANCEL',
            actionFn: (): void => this.cancelBackgroundJob.emit(backgroundJob)
          }
        ];
      } else if (backgroundTaskWithJob.backgroundJob.canBeCanceled()) {
        actions = [
          {
            name: 'GENERAL.ACTIONS.CANCEL',
            actionFn: (): void => this.cancelBackgroundJob.emit(backgroundTaskWithJob.backgroundJob)
          }
        ];
      }

      if (backgroundJob.hasErrorMessage()) {
        actions.push({
          name: 'GENERAL.ERRORS.ERROR_DETAILS',
          actionFn: (): void => this.showErrorMessage(backgroundTaskWithJob.backgroundJob),
          cssClass: 'error'
        });
      }
    }

    return actions;
  }

  private getLabelForActiveTaskStatus(params: ITooltipParams): string {
    const backgroundTaskWithJob: BackgroundTaskWithJob = params.node.group ? first(params.node.allLeafChildren).data : params.data;
    const status = params.node.group || this.responsivenessViewMode.isPhone ? backgroundTaskWithJob.backgroundJob.status : backgroundTaskWithJob.backgroundTask.status;

    return this.translate.instant(`BACKGROUND_JOBS.STATUS.${EnumUtils.getKeyFromValue(BackgroundStatus, status)}`);
  }

  private getDurationForRow(params: ValueGetterParams): string {
    let result = '';
    if (params.node.group) {
      const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
      if (!isNil(backgroundTaskWithJob.backgroundJob.endTime)) {
        result = this.durationFormatPipe.transform(backgroundTaskWithJob.backgroundJob.durationInMs, 'ms', 'd[d] h[h] m[m]');
      }
    } else {
      const backgroundTaskWithJob: BackgroundTaskWithJob = params.data;
      if (!isNil(backgroundTaskWithJob.backgroundTask.startTime) && !isNil(backgroundTaskWithJob.backgroundTask.endTime)) {
        result = this.durationFormatPipe.transform(backgroundTaskWithJob.backgroundTask.durationInMs, 'ms', 'd[d] h[h] m[m]');
      }
    }
    return result;
  }

  private getLabelForBackgroundJobName(params: ValueGetterParams | ITooltipParams): string {
    let result = '';
    if (params.node.group) {
      const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
      result = backgroundTaskWithJob.backgroundJob.name;
    }
    return result;
  }

  private getLabelForActiveTaskAction(params: ValueGetterParams | GetQuickFilterTextParams): string {
    let result = '';
    if (params.node.group) {
      const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
      const activeTask: BackgroundTask = backgroundTaskWithJob.backgroundJob.getActiveTask();
      result = !isNil(activeTask) ? this.translate.instant(`BACKGROUND_JOBS.TASK.ACTION.${EnumUtils.getKeyFromValue(BackgroundTaskAction, activeTask.action)}`) : '';
    } else {
      const backgroundTaskWithJob: BackgroundTaskWithJob = params.data;
      result = this.translate.instant(`BACKGROUND_JOBS.TASK.ACTION.${EnumUtils.getKeyFromValue(BackgroundTaskAction, backgroundTaskWithJob.backgroundTask.action)}`);
    }
    return result;
  }

  private rowClick(event: RowClickedEvent): void {
    if (event.node.group) {
      event.node.setExpanded(!event.node.expanded);
    } else {
      this.handleBackgroundJobSelection(event.node.data.backgroundJob);
    }
  }

  private getColumnDefs(isPhone: boolean): ColDef[] {
    return isPhone ? this.getMobileColumnDefsForListOfBackgroundJobs() : this.getDesktopColumnDefsForListOfBackgroundJobs();
  }

  private handleBackgroundJobSelection(backgroundJob: BackgroundJob): void {
    if (some(this.selectedBackgroundJobs, {id: backgroundJob.id})) {
      this.selectedBackgroundJobs = reject(this.selectedBackgroundJobs, {id: backgroundJob.id});
    } else {
      this.selectedBackgroundJobs = [...this.selectedBackgroundJobs, backgroundJob];
    }

    this.selectedBackgroundJobsChange.emit(this.selectedBackgroundJobs);
  }

  private getFilterValueForBackgroundJobName(params: ValueGetterParams): string {
    return params.node.data.backgroundJob.name;
  }
}
