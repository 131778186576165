import {Component} from '@angular/core';
import {BackgroundTaskWithJob} from '@presentation/components/background-jobs/background-task-with-job';
import {DateCellRendererParams} from '@presentation/components/background-jobs/date-cell-renderer/date-cell-renderer-params';
import {TypeDate} from '@presentation/components/background-jobs/date-cell-renderer/type-date';
import {AgGridUtils} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {first, isEqual, isNil} from 'lodash-es';

@Component({
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['./date-cell-renderer.component.scss']
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  public date: Date;

  public agInit(params: DateCellRendererParams): void {
    if (params.node.group) {
      const backgroundTaskWithJob: BackgroundTaskWithJob = first(params.node.allLeafChildren).data;
      if (isEqual(params.typeDate, TypeDate.START_DATE)) {
        this.date = backgroundTaskWithJob.backgroundJob.startTime;
      } else {
        this.date = backgroundTaskWithJob.backgroundJob.endTime;
      }
    } else {
      const backgroundTaskWithJob: BackgroundTaskWithJob = params.data;
      if (isEqual(params.typeDate, TypeDate.START_DATE)) {
        this.date = backgroundTaskWithJob.backgroundTask.startTime;
      } else {
        this.date = backgroundTaskWithJob.backgroundTask.endTime;
      }
    }
  }

  public refresh(params: any): boolean {
    return false;
  }

  public canShowDate(): boolean {
    return !isNil(this.date);
  }

  public getFormattedDate(): string {
    return AgGridUtils.buildAgGridCellDateAsDDMMYYYY(this.date);
  }
}
