import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Inject, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[appThumbnailSvgLoader]'
})
export class ThumbnailSvgLoaderDirective implements OnChanges {
  @Input() public appThumbnailSvgLoader: string;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly element: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnChanges(): void {
    if (this.appThumbnailSvgLoader) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(this.appThumbnailSvgLoader, 'image/svg+xml');

      if (svgDocument.documentElement.hasChildNodes()) {
        const svg = this.document.createElementNS('http://www.w3.org/2000/svg', 'svg');

        let elementToAppend: Element = svgDocument.querySelector('text')?.previousElementSibling;

        while (elementToAppend) {
          const previousElementSibling = elementToAppend.previousElementSibling;

          svg.prepend(elementToAppend);

          elementToAppend = previousElementSibling;
        }

        this.renderer.setAttribute(svg, 'width', '100%');
        this.renderer.addClass(svg, svgDocument.firstElementChild.classList.value);
        this.renderer.appendChild(this.element.nativeElement, svg);

        const svgBBox = svg.getBBox();
        const size = Math.max(svgBBox.width, svgBBox.height);
        const minXPosition = svgBBox.x - (size - svgBBox.width) / 2;
        const minYPosition = svgBBox.y - (size - svgBBox.height) / 2;

        this.renderer.setAttribute(svg, 'viewBox', `${minXPosition} ${minYPosition} ${size} ${size}`);
      }
    }
  }
}
