import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {UserInfo} from '@domain/profile/user-info';

export class ProfileInfo {
  private readonly _userInfo: UserInfo;
  private _activeSubscription: Subscription;

  public constructor(userInfo: UserInfo, activeSubscription: Subscription) {
    this._userInfo = userInfo;
    this._activeSubscription = activeSubscription;
  }

  public get userInfo(): UserInfo {
    return this._userInfo;
  }

  public get activeSubscription(): Subscription {
    return this._activeSubscription;
  }

  public set activeSubscription(subscription: Subscription) {
    this._activeSubscription = subscription;
  }

  public static fromJSON(profileInfoJSON: any): ProfileInfo {
    return new ProfileInfo(UserInfo.fromJSON(profileInfoJSON.user), Subscription.fromJSON(profileInfoJSON.activeSubscription));
  }

  public hasPermission(permission: Permission): boolean {
    return this._activeSubscription.hasPermission(permission);
  }
}
