import {PlanningWeaveProduct} from '@domain/planning/weaving/planning-weave-product';
import {ProductionOrderWeavingForPlanningOrder} from '@domain/planning/weaving/production-order-weaving-for-planning-order';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';

export class ProductionOrderWeavingForPlanningOrderStubFactory {
  public static getRandomProductionOrderLiteWithIdAndStatusValue(id: number, status: ProductionScheduleStatus): ProductionOrderWeavingForPlanningOrder {
    return new ProductionOrderWeavingForPlanningOrder(id, `ProductionOrderLite_${id}`, status, this.getRandomPlanningWeaveProduct(), {current: 0, planned: 1000}, null);
  }

  public static getRandomProductionOrderLiteWithStatusValue(status: ProductionScheduleStatus): ProductionOrderWeavingForPlanningOrder {
    return this.getRandomProductionOrderLiteWithIdAndStatusValue(1, status);
  }

  public static getRandomProductionOrderLiteWithIdAndDueDate(id: number, dueDate: Date): ProductionOrderWeavingForPlanningOrder {
    return new ProductionOrderWeavingForPlanningOrder(id, `ProductionOrderLite_${id}`, ProductionScheduleStatus.TO_PLAN, this.getRandomPlanningWeaveProduct(), {current: 0, planned: 1000}, dueDate);
  }

  public static getRandomProductionOrderLite(): ProductionOrderWeavingForPlanningOrder {
    return this.getRandomProductionOrderLiteWithStatusValue(ProductionScheduleStatus.CREATED);
  }

  public static getRandomPlanningWeaveProduct(): PlanningWeaveProduct {
    return new PlanningWeaveProduct(0, 'WeaveProduct_0');
  }

  public static getRandomProductionOrderLiteWithIdAndWeaveProductId(id: number, weaveProductId: number): ProductionOrderWeavingForPlanningOrder {
    return new ProductionOrderWeavingForPlanningOrder(
      id,
      `productionOrderLite_${id}`,
      ProductionScheduleStatus.TO_PLAN,
      new PlanningWeaveProduct(weaveProductId, `weaveProduct_${weaveProductId}`),
      {current: 0, planned: 1000},
      null
    );
  }
}
