import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class CreelRoute extends RouteBranch {
  private _addCreel: RouteLeaf;
  private _duplicateCreel: RouteLeaf;
  private _editCreel: RouteLeaf;
  private _creelSettings: RouteLeaf;

  public get addCreel(): RouteLeaf {
    return this._addCreel;
  }

  public set addCreel(value: RouteLeaf) {
    this.replace(this._addCreel, value);
    this._addCreel = value;
  }

  public get duplicateCreel(): RouteLeaf {
    return this._duplicateCreel;
  }

  public set duplicateCreel(value: RouteLeaf) {
    this.replace(this._duplicateCreel, value);
    this._duplicateCreel = value;
  }

  public get editCreel(): RouteLeaf {
    return this._editCreel;
  }

  public set editCreel(value: RouteLeaf) {
    this.replace(this._editCreel, value);
    this._editCreel = value;
  }

  public get creelSettings(): RouteLeaf {
    return this._creelSettings;
  }

  public set creelSettings(value: RouteLeaf) {
    this.replace(this._creelSettings, value);
    this._creelSettings = value;
  }
}
