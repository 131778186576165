export enum NameGeneratorSeparator {
  NO_SEPARATOR,
  POINT,
  COMMA,
  DASH,
  UNDERSCORE,
  SPACE,
  VERTICAL_BAR,
  TAB
}
