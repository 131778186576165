import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {intComparator} from '@application/helper/int-comparator';
import {SourceElementUtils} from '@application/helper/source-element-utils';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {PropertyValue} from '@domain/property-value';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {
  AgGridUtils,
  ColDefBuilderFactoryService,
  convertCommercialUnit,
  DialogBuilderFactoryService,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {ColDef, GridOptions, ICellRendererParams, ITooltipParams, RowClassParams, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {PathLengthComponent} from './path-length/path-length.component';
import {PathWidthComponent} from './path-width/path-width.component';

@Component({
  selector: 'app-production-schedule-builder-summary',
  templateUrl: './production-schedule-builder-summary.component.html',
  styleUrls: ['./production-schedule-builder-summary.component.scss']
})
export class ProductionScheduleBuilderSummaryComponent implements OnInit, OnChanges {
  @Input() public listOfCustomSettings: PropertyValue[] = [];
  @Input() public productionSchedule: ProductionSchedule;
  @Input() public commercialUnit: Unit;

  public gridOptionsForProductionScheduleColoredYarnSets: GridOptions[] = [];

  private maxDifferenceInPathCommercialLength = 0;
  private readonly percentageUnit = '%';

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {}

  public ngOnInit(): void {
    this.calculateMaxDifferenceInPathCommercialLength();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('productionSchedule' in changes) {
      this.gridOptionsForProductionScheduleColoredYarnSets = this.productionSchedule.productionSchedulePathsOfColoredYarnSets.map(() => {
        return this.gridOptionsBuilderFactoryService
          .getBuilder(this.getColumnDefsForListOfPaths(), GridIdentifier.PRODUCTION_SCHEDULE_COLORED_YARN_SETS)
          .withoutColumnMenu()
          .withNoRowsOverlay({
            smallTableMode: true,
            hideDescription: true
          } as NoDataOverlayComponentParams)
          .withLoadingOverlay({
            smallTableMode: true
          } as OverlayComponentParams)
          .withGetRowClass((params: RowClassParams) => this.getRowClass(params.data))
          .withTooltipShowDelay(100)
          .withoutGetRowId()
          .build();
      });

      this.gridOptionsForProductionScheduleColoredYarnSets.forEach((gridOptionsForProductionScheduleColoredYarnSet: GridOptions, indexA: number) => {
        gridOptionsForProductionScheduleColoredYarnSet.alignedGrids = this.gridOptionsForProductionScheduleColoredYarnSets.filter((_: GridOptions, indexB: number) => indexA !== indexB);
      });
    }
  }

  public trackByColoredYarnSetPathId = (index: number, coloredYarnSetPath: any): number => coloredYarnSetPath.id;

  public showColoredYarnSetDetails(event: MouseEvent, coloredYarnSetIndex: number): void {
    event.stopPropagation();

    const productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent = this.productionSchedule.coloredYarnSets[coloredYarnSetIndex];
    const startDent = `${this.translate.instant('GENERAL.START_DENT')}: ${productionScheduleColoredYarnSet.startDent}`;
    const widthInDents = `${this.translate.instant('GENERAL.WIDTH_IN_DENTS')}: ${productionScheduleColoredYarnSet.technicalWidthInDents}`;
    const textileDataAmount = `${startDent}, ${widthInDents}`;

    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('reposition-dialog')
      .withWidth('706px')
      .openDialog(RepositionDialogComponent, {
        textileData: productionScheduleColoredYarnSet.coloredYarnSet,
        textileDataAmount,
        component: TextileDataDetailComponent,
        sourceElement: SourceElementUtils.findSourceElementForDialog(event, 'color-list-preview-container'),
        positionOfDialog: PositionOfDialog.RIGHT
      });
  }

  public getColoredYarnSetCreelMap(coloredYarnSetWithStartDentId: number): CreelMap {
    return this.productionSchedule.mappingForCurrentPositionOnMachine.find((creelMap: CreelMap) => {
      return creelMap.coloredYarnSetId === coloredYarnSetWithStartDentId;
    });
  }

  private getColumnDefsForListOfPaths(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').withoutFilter().build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('commercialWidthInMM')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withCellRenderer(PathWidthComponent, {
          commercialUnit: this.commercialUnit
        })
        .withRightAlignment()
        .withComparator(intComparator)
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('totalLengthInMM')
        .withHeaderName('GENERAL.DIMENSIONS.LENGTH')
        .withCellRenderer(PathLengthComponent, {
          commercialUnit: this.commercialUnit,
          productionScheduleLengthInMillimeters: this.productionSchedule.getProductionScheduleLengthInMillimeters()
        })
        .withRightAlignment()
        .withComparator(intComparator)
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('PRODUCTION_ORDER.WEAVE_PLAN.WEAVE_PLAN_SUMMARY.REST_LENGTH')
        .withValueGetter((params: ValueGetterParams) => this.getRestLengthInCommercialUnit(params.data))
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, this.commercialUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, this.commercialUnit))
        .withRightAlignment()
        .withComparator(intComparator)
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('PRODUCTION_ORDER.WEAVE_PLAN.WEAVE_PLAN_SUMMARY.LOSS')
        .withRightAlignment()
        .withValueGetter((params: ValueGetterParams) => this.getLossPercentage(params.data))
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, this.percentageUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, this.percentageUnit))
        .withComparator(intComparator)
        .withoutFilter()
        .build()
    ];
  }

  private calculateMaxDifferenceInPathCommercialLength(): void {
    this.maxDifferenceInPathCommercialLength = convertCommercialUnit({
      from: {
        unit: Unit.MILLIMETER,
        value: this.listOfCustomSettings.find(({propertyName}: PropertyValue) => propertyName === 'maxDifferenceInPathLengthInMillimeters').propertyValue
      },
      to: this.commercialUnit
    });
  }

  private getRestLengthInCommercialUnit(productionSchedulePath: ProductionSchedulePath): number {
    const restLengthInMillimeters = this.productionSchedule.getProductionScheduleLengthInMillimeters() - productionSchedulePath.totalLengthInMM;
    const commercialRestLengthOfPath: number = convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: restLengthInMillimeters}, to: this.commercialUnit});
    return +Math.round(commercialRestLengthOfPath).toFixed(2);
  }

  private getLossPercentage(productionSchedulePath: ProductionSchedulePath): number {
    const restLengthInMillimeters = this.productionSchedule.getProductionScheduleLengthInMillimeters() - productionSchedulePath.totalLengthInMM;
    return +Math.round((restLengthInMillimeters / this.productionSchedule.getProductionScheduleLengthInMillimeters()) * 100).toFixed(2);
  }

  private getRowClass(productionSchedulePath: ProductionSchedulePath): string[] {
    return this.getRestLengthInCommercialUnit(productionSchedulePath) > this.maxDifferenceInPathCommercialLength ? ['warning'] : ['normal'];
  }

  private buildAgGridCellTextWithUnit(value: number, unit: string): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, unit, this.l10nIntlService);
  }

  private buildAgGridCellTooltipWithUnit(value: number, unit: string): string {
    return AgGridUtils.buildAgGridCellTooltipWithUnit(value, unit, this.l10nIntlService);
  }
}
