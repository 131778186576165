import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class EventRoute extends RouteBranch {
  private _addEvent: RouteLeaf;
  private _duplicateEvent: RouteLeaf;
  private _editEvent: RouteLeaf;

  public get addEvent(): RouteLeaf {
    return this._addEvent;
  }

  public set addEvent(value: RouteLeaf) {
    this.replace(this._addEvent, value);
    this._addEvent = value;
  }

  public get duplicateEvent(): RouteLeaf {
    return this._duplicateEvent;
  }

  public set duplicateEvent(value: RouteLeaf) {
    this.replace(this._duplicateEvent, value);
    this._duplicateEvent = value;
  }

  public get editEvent(): RouteLeaf {
    return this._editEvent;
  }

  public set editEvent(value: RouteLeaf) {
    this.replace(this._editEvent, value);
    this._editEvent = value;
  }
}
