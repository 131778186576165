import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TranslationModule} from '../../translation/translation.module';
import {SelectionListComponent} from './selection-list.component';

@NgModule({
  imports: [CommonModule, MatCheckboxModule, TranslationModule],
  declarations: [SelectionListComponent],
  exports: [SelectionListComponent]
})
export class SelectionListModule {}
