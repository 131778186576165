import {InjectionToken} from '@angular/core';
import {SalesOrder} from '@domain/sales-order/sales-order';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListSalesOrder} from '@presentation/pages/texfab/sales-order/overview/overview-list-sales-order';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';

export const SALES_ORDERS = new InjectionToken<SalesOrders>('SalesOrders');

export interface SalesOrders extends Repository<SalesOrder, OverviewListSalesOrder>, UniqueIdentifierObjectRepository, ConflictsRepository {
  copyToOrderbook(id: number): Observable<void>;
}
