import {CommercialDimensions} from '@domain/commercial-dimensions';
import {CutbarsLength} from '@domain/production-schedule/cutbars-length';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {TechnicalDimensions} from '@domain/technical-dimensions';
import {isNil, noop} from 'lodash-es';
import {NonProductionItemDrawing} from './non-production-item-drawing';
import {ProductionScheduleItemInPathGroupStatus} from './production-schedule-item-in-path-group-status';

export class NonProductionItemInPathGroup extends ProductionScheduleItemInPathGroup {
  public constructor(
    drawing: NonProductionItemDrawing,
    quantity: number,
    commercialDimensionsInMM: CommercialDimensions,
    technicalDimensions: TechnicalDimensions,
    mainColorPosition: number,
    borderColorPosition: number,
    uuid: string,
    amountProduced: number,
    status: ProductionScheduleItemInPathGroupStatus,
    id: number = null,
    epId: number = null,
    totalWovenHeightInPicks: number = null,
    totalWovenHeightInMM: number = null,
    amountIncomplete: number = null
  ) {
    super(
      drawing,
      quantity,
      commercialDimensionsInMM,
      technicalDimensions,
      false,
      mainColorPosition,
      borderColorPosition,
      uuid,
      amountProduced,
      status,
      DrawingType.NON_PRODUCTION_ITEM,
      null,
      id,
      epId,
      totalWovenHeightInPicks,
      totalWovenHeightInMM,
      amountIncomplete
    );
  }

  public static fromJSON(productionScheduleItemInPathGroup: any): NonProductionItemInPathGroup {
    return new NonProductionItemInPathGroup(
      NonProductionItemDrawing.fromJSON(productionScheduleItemInPathGroup.design),
      productionScheduleItemInPathGroup.quantity,
      productionScheduleItemInPathGroup.commercialDimensions,
      productionScheduleItemInPathGroup.technicalDimensions,
      productionScheduleItemInPathGroup.mainColorPosition,
      productionScheduleItemInPathGroup.borderColorPosition,
      null,
      productionScheduleItemInPathGroup.amountProduced,
      isNil(productionScheduleItemInPathGroup.status) ? null : ProductionScheduleItemInPathGroupStatus[`${productionScheduleItemInPathGroup.status}`],
      productionScheduleItemInPathGroup.id,
      productionScheduleItemInPathGroup.epId,
      productionScheduleItemInPathGroup.nrWovenInPicks,
      productionScheduleItemInPathGroup.nrWovenInMM,
      productionScheduleItemInPathGroup.amountIncomplete
    );
  }

  public getCommercialCutbarsLengthInMM(): number {
    return 0;
  }

  public getCutbarInformation(): CutbarsLength {
    return {
      cutbarBefore: {
        commercialLengthInMM: 0,
        technicalLengthInNumberOfPicks: 0
      },
      cutbarAfter: {
        commercialLengthInMM: 0,
        technicalLengthInNumberOfPicks: 0
      }
    };
  }

  public getTechnicalCutbarsLengthInNumberOfPicks(): number {
    return 0;
  }

  public canBeRecolored(): boolean {
    return false;
  }

  public recolorDrawing(): void {
    noop();
  }
}
