import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Pattern} from '@domain/pattern';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';

export class ManualNonProductionItemDrawing extends Drawing {
  private _pattern: Pattern;

  public constructor(id: number, name: string, dimensionsInPixels: DimensionsInPx, pattern: Pattern) {
    super(id, name, DrawingType.MANUAL_NON_PRODUCTION_ITEM, null, dimensionsInPixels, '', false, false);
    this._pattern = pattern;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public set pattern(value: Pattern) {
    this._pattern = value;
  }

  public static fromJSON(drawingJSON: any): ManualNonProductionItemDrawing {
    return new ManualNonProductionItemDrawing(
      drawingJSON.id,
      drawingJSON.name,
      {
        widthInPx: drawingJSON.dimensionsInPx.widthInPx,
        heightInPx: drawingJSON.dimensionsInPx.heightInPx
      },
      Pattern.fromJSON(drawingJSON.pattern)
    );
  }
}
