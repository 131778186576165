import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class ResourceTypeRoute extends RouteBranch {
  private _addResourceType: RouteLeaf;
  private _editResourceType: RouteLeaf;
  private _duplicateResourceType: RouteLeaf;

  public get addResourceType(): RouteLeaf {
    return this._addResourceType;
  }

  public set addResourceType(value: RouteLeaf) {
    this.replace(this._addResourceType, value);
    this._addResourceType = value;
  }

  public get editResourceType(): RouteLeaf {
    return this._editResourceType;
  }

  public set editResourceType(value: RouteLeaf) {
    this.replace(this._editResourceType, value);
    this._editResourceType = value;
  }

  public get duplicateResourceType(): RouteLeaf {
    return this._duplicateResourceType;
  }

  public set duplicateResourceType(value: RouteLeaf) {
    this.replace(this._duplicateResourceType, value);
    this._duplicateResourceType = value;
  }
}
