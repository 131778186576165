import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {isNil} from 'lodash-es';

export class CutbarPattern extends Pattern {
  public constructor(id: number, name: string, visualisations: PatternVisualisation[], type?: string, comment?: string) {
    super(id, name, visualisations, type, comment);
  }

  public static fromJSON(cutbarPatternJSON: any): CutbarPattern {
    let result = null;

    if (!isNil(cutbarPatternJSON)) {
      const pattern = super.fromFoldingTypeJSON(cutbarPatternJSON);
      result = new CutbarPattern(pattern.id, pattern.name, pattern.visualisations, pattern.type, pattern.comment);
    }

    return result;
  }
}
