import {OrderLineAdditionalInfo} from '@domain/orderbook/order-line-additional-info';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {OverviewListArticleForOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-article-for-order-line';
import {Priority} from '@vdw/angular-component-library';

export class ProductionScheduleOrderLineWithQuantity extends ProductionScheduleOrderLine {
  private _quantity: number;
  private _heightInMM: number;
  private _rollLengthInMeter: number;

  private constructor(
    id: number,
    salesOrderReference: string,
    salesOrderLineReference: string,
    customerReference: string,
    desiredDate: Date,
    priority: Priority,
    article: OverviewListArticleForOrderLine,
    amount: number,
    amountLeft: number,
    amountProduced: number,
    amountPlanned: number,
    isSelectable: boolean,
    status: OrderLineStatus,
    orderType: string,
    canBePlanned: boolean,
    missingDesign: boolean,
    additionalInfo: OrderLineAdditionalInfo[],
    commercialLengthInMM: number,
    amountInProductionSchedule: number,
    heightInProductionScheduleInMM: number,
    rollLengthInMeter?: number
  ) {
    super(
      id,
      salesOrderReference,
      salesOrderLineReference,
      customerReference,
      desiredDate,
      priority,
      article,
      amount,
      amountLeft,
      amountProduced,
      amountPlanned,
      isSelectable,
      status,
      orderType,
      canBePlanned,
      missingDesign,
      additionalInfo,
      commercialLengthInMM
    );

    this.amountInProductionSchedule = amountInProductionSchedule;
    this.heightInProductionScheduleInMM = heightInProductionScheduleInMM;
    this._rollLengthInMeter = rollLengthInMeter;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(value: number) {
    this._quantity = value;
  }

  public get heightInMM(): number {
    return this._heightInMM;
  }

  public set heightInMM(value: number) {
    this._heightInMM = value;
  }

  public get rollLengthInMeter(): number {
    return this._rollLengthInMeter;
  }

  public set rollLengthInMeter(value: number) {
    this._rollLengthInMeter = value;
  }

  public static fromProductionScheduleOrderLine(productionScheduleOrderLine: ProductionScheduleOrderLine): ProductionScheduleOrderLineWithQuantity {
    return new ProductionScheduleOrderLineWithQuantity(
      productionScheduleOrderLine.id,
      productionScheduleOrderLine.salesOrderReference,
      productionScheduleOrderLine.salesOrderLineReference,
      productionScheduleOrderLine.customerReference,
      productionScheduleOrderLine.desiredDate,
      productionScheduleOrderLine.priority,
      productionScheduleOrderLine.article,
      productionScheduleOrderLine.amount,
      productionScheduleOrderLine.amountLeft,
      productionScheduleOrderLine.amountProduced,
      productionScheduleOrderLine.amountPlanned,
      productionScheduleOrderLine.isSelectable,
      productionScheduleOrderLine.status,
      productionScheduleOrderLine.orderType,
      productionScheduleOrderLine.canBePlanned,
      productionScheduleOrderLine.missingDesign,
      productionScheduleOrderLine.additionalInfo,
      productionScheduleOrderLine.commercialLengthInMM,
      productionScheduleOrderLine.amountInProductionSchedule,
      productionScheduleOrderLine.heightInProductionScheduleInMM
    );
  }
}
