import {MachineType} from '@domain/machine/machine-type.enum';
import {TimezoneUtils} from '@vdw/angular-component-library';
import {PlanningMachineRun} from '../planning-machine-run';
import {PlanningOrder} from '../planning-order';
import {PlanningTuftProduct} from './planning-tuft-product';

export class PlanningMachineTuftingRun extends PlanningMachineRun {
  private _tuftProduct: PlanningTuftProduct;

  public constructor(id: number, tuftProduct: PlanningTuftProduct, planningOrders: PlanningOrder[], startDate: Date, endDate: Date, compatibleWithMachine: boolean) {
    super(id, planningOrders, startDate, endDate, compatibleWithMachine);

    this._tuftProduct = tuftProduct;
  }

  public get tuftProduct(): PlanningTuftProduct {
    return this._tuftProduct;
  }

  public static fromJSON(planningMachineRunJSON: any, timezoneCode: string): PlanningMachineTuftingRun {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.endDate, timezoneCode);

    const planningOrders = planningMachineRunJSON.planningOrders?.map((planningOrderJSON: any) => PlanningOrder.fromTuftingJSON(planningOrderJSON, timezoneCode)) ?? [];

    return new PlanningMachineTuftingRun(
      planningMachineRunJSON.id,
      PlanningTuftProduct.fromJSON(planningMachineRunJSON.tuftProduct),
      planningOrders,
      startDate,
      endDate,
      planningMachineRunJSON.compatibleWithMachine
    );
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      ...super.toJSON(timezoneCode),
      tuftProduct: this._tuftProduct?.toJSON()
    } as any as JSON;
  }

  public isCompatibleWithMachineType(machineType: MachineType): boolean {
    return machineType === MachineType.TUFT;
  }
}
