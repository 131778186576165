export class CustomerContactDetails {
  private _email: string;
  private _telephone: string;
  private _fax: string;

  public constructor(email: string, telephone: string, fax: string) {
    this._email = email;
    this._telephone = telephone;
    this._fax = fax;
  }

  public static fromJSON(customerContactJSON: any): CustomerContactDetails {
    return new CustomerContactDetails(customerContactJSON.email, customerContactJSON.telephone, customerContactJSON.fax);
  }

  public toJSON(): JSON {
    return {
      email: this._email,
      telephone: this._telephone,
      fax: this._fax
    } as any as JSON;
  }

  public get email(): string {
    return this._email;
  }

  public get telephone(): string {
    return this._telephone;
  }

  public get fax(): string {
    return this._fax;
  }
}
