import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {L10nIntlService} from 'angular-l10n';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {convertUnit} from '../../common/converters/convert-unit';
import {Unit} from '../../common/unit.enum';
import {LocaleUtils} from '../../common/utils/locale-utils';
import {TranslateService} from '../../translation/translate.service';
import {ZoneInputHelperService} from './helper/zone-input-helper.service';
import {ZoneInputType} from './zone-input-type.enum';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-zone-input',
  templateUrl: './zone-input.component.html',
  styleUrls: ['./zone-input.component.scss']
})
export class ZoneInputComponent implements OnChanges, AfterViewInit {
  @Input() public disabled: boolean = false;
  @Input() public commercialValueInMM: number = 0;
  @Input() public technicalValue: number = 0;
  @Input() public maximumTechnicalValue?: number;
  @Input() public zoneType: ZoneInputType = ZoneInputType.FREEZONE;
  @Input() public zoneKey: string;

  @Output() public valueChanged = new EventEmitter<number>();

  @ViewChild('inputField') public inputField: ElementRef;

  public zoneInputType = ZoneInputType;
  public isErrorState: boolean = false;

  private technicalValueSubject = new Subject<number>();
  private readonly debounceTimeMs = 500;
  private previousTechnicalValue: number;

  public constructor(
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService,
    private readonly zoneInputHelperService: ZoneInputHelperService
  ) {}

  public ngAfterViewInit(): void {
    if (this.isPopupVisible) {
      this.setFocus();
    }

    this.technicalValueSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.validateTechnicalValue();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('technicalValue' in changes && !changes.technicalValue.isFirstChange()) {
      this.validateTechnicalValue();
    } else {
      this.previousTechnicalValue = this.technicalValue;
    }
  }

  public onChangeTechnicalValue(): void {
    this.technicalValueSubject.next(this.technicalValue);
  }

  public get isPopupVisible(): boolean {
    return this.zoneInputHelperService.isPopupVisible(this.zoneKey);
  }

  public set isPopupVisible(visible: boolean) {
    this.zoneInputHelperService.setPopupVisible(this.zoneKey, visible);
  }

  public getZoneInputLabel(): string {
    if (AssertionUtils.isNullOrUndefined(this.technicalValue) || this.technicalValue < 0) {
      this.technicalValue = 0;
    }

    const isRestZone = this.zoneType === ZoneInputType.RESTZONE;

    if (isRestZone) {
      const distanceInCommercialUnit = this.convertValueToCommercialUnit(this.commercialValueInMM, Unit.MILLIMETER, Unit.CENTIMETER);
      return `${this.technicalValue} / ${distanceInCommercialUnit}`;
    }

    return `${this.technicalValue}`;
  }

  public getZoneInputErrorLabel(): string {
    const max = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.ZONE_INPUT.MAX');
    const unit = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.ZONE_INPUT.UNIT.DENTS');

    return `${max} ${this.maximumTechnicalValue} ${unit}`;
  }

  public togglePopup(event: Event): void {
    if (this.disabled) {
      return;
    }

    event.stopPropagation();

    this.isPopupVisible = !this.isPopupVisible;

    this.setFocus();
  }

  public closePopup(event: Event): void {
    event.stopPropagation();
    this.isPopupVisible = false;
  }

  public onPopupClick(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(): void {
    if (!this.isPopupVisible) {
      return;
    }

    this.isPopupVisible = false;
  }

  public validateTechnicalValue(): void {
    if (this.technicalValue < 0) {
      this.technicalValue = 0;
    }

    if (!AssertionUtils.isNullOrUndefined(this.maximumTechnicalValue)) {
      this.isErrorState = this.technicalValue > this.maximumTechnicalValue;
    } else {
      this.isErrorState = false;
    }

    if (!this.disabled && this.technicalValue !== this.previousTechnicalValue && !AssertionUtils.isEmpty(this.technicalValue.toString()) && !this.isErrorState) {
      this.valueChanged.emit(this.technicalValue);
      this.previousTechnicalValue = this.technicalValue;
    }
  }

  private convertValueToCommercialUnit(value: number, fromUnit: Unit, toUnit: Unit): string {
    const convertedValue = convertUnit({
      from: {
        value: value,
        unit: fromUnit
      },
      to: toUnit
    });

    const formattedNumber = LocaleUtils.formatNumber(convertedValue, this.l10nIntlService, 2);

    return `${formattedNumber}`;
  }

  private setFocus(): void {
    if (this.isPopupVisible) {
      setTimeout(() => {
        this.inputField.nativeElement.focus();
        this.zoneInputHelperService.setInputFocused(this.zoneKey, true);
      }, 100);
    }
  }
}
