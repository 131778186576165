import {Directive, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, Renderer2} from '@angular/core';
import {LoadImageDirective} from '@application/directives/load-image.directive';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {BackendErrorSeverity, BlobUtils, UnhandledBackendError} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {fromEvent, noop, Observable, throwError} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appLoadDrawingImage]'
})
export class LoadDrawingImageDirective extends LoadImageDirective implements OnInit, OnChanges {
  @Input() public qualityId: number;
  @Input() public colorSetId: number;
  @Input() public drawingDimensionsInPx: DimensionsInPx;
  @Input() public shouldDownloadImage: boolean;
  @Input() public imageSignature: string;
  @Output() public imageDownloaded: EventEmitter<string> = new EventEmitter<string>();

  public constructor(
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    renderer: Renderer2,
    elementRef: ElementRef<HTMLElement>
  ) {
    super(renderer, elementRef);
  }

  public ngOnChanges(): void {
    if (!isNil(this.imageId) && this.shouldDownloadImage) {
      fromEvent(this.elementRef.nativeElement, 'load')
        .pipe(first())
        .subscribe(() => {
          this.downloadAndSetImage(this.imageId, this.imageSignature ?? '', this.qualityId, this.colorSetId, this.drawingDimensionsInPx);
        });
    }
  }

  protected downloadAndSetImage(imageId: string, imageSignature: string, qualityId: number, colorSetId: number, drawingDimensionsInPx: DimensionsInPx): void {
    if (isNil(qualityId) || isNil(colorSetId) || isNil(drawingDimensionsInPx)) {
      this.downloadOriginalDrawing(imageId, imageSignature)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (blob: Blob) => {
            this.setDownloadedImage(blob);
          },
          error: noop
        });
    } else {
      this.downloadRecoloredDrawing(imageId, qualityId, colorSetId, drawingDimensionsInPx)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (blob: Blob) => {
            this.setDownloadedImage(blob);
          },
          error: () => throwError(() => new UnhandledBackendError(BackendErrorSeverity.INFO, 'Something went wrong'))
        });
    }
  }

  protected setDownloadedImage(blob: Blob): void {
    BlobUtils.blobToImageData(blob).then((imageData: string) => {
      this.setImageFromData(imageData);
      this.imageDownloaded.emit(imageData);
    });
  }

  private downloadOriginalDrawing(imageId: string, imageSignature: string): Observable<Blob> {
    return this.drawings.getDrawing(imageId, imageSignature);
  }

  private downloadRecoloredDrawing(imageId: string, qualityId: number, colorSetId: number, drawingDimensionsInPx: DimensionsInPx): Observable<Blob> {
    return this.drawings.getRecoloredImage(imageId, qualityId, colorSetId, drawingDimensionsInPx);
  }
}
