import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';

export class PlanningWeaveProduct {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(jsonData: any): PlanningWeaveProduct {
    return new PlanningWeaveProduct(jsonData.id, jsonData.name);
  }

  public static fromOverviewListWeaveProduct(weaveProduct: OverviewListWeaveProduct): PlanningWeaveProduct {
    return new PlanningWeaveProduct(weaveProduct.id, weaveProduct.name);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name
    } as any as JSON;
  }
}
