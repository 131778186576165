import {Component, Input} from '@angular/core';
import {Pattern} from './pattern.enum';

@Component({
  selector: 'vdw-pattern',
  templateUrl: './pattern.component.html',
  styleUrls: ['./pattern.component.scss']
})
export class PatternComponent {
  @Input() public pattern: Pattern;

  public getPatternImage(): string {
    switch (this.pattern) {
      case Pattern.LINE:
        return 'line-pattern';
      case Pattern.STRIPED:
        return 'striped-pattern';
      case Pattern.DOTTED:
        return 'dotted-pattern';
      case Pattern.DIAGONAL:
        return 'diagonal-pattern';
    }
  }
}
