import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CheckboxToggleButtonDirective} from './checkbox-toggle-button.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CheckboxToggleButtonDirective],
  exports: [CheckboxToggleButtonDirective]
})
export class CheckboxToggleButtonModule {}
