import {Component, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {MatFormFieldControlHelper} from '../../helper/form-field-control-helper';
import {Pattern} from '../../pattern/pattern.enum';

@Component({
  selector: 'vdw-select-patterns',
  templateUrl: './select-patterns.component.html',
  styleUrls: ['./select-patterns.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectPatternsComponent}]
})
export class SelectPatternsComponent extends MatFormFieldControlHelper<Pattern> implements OnDestroy, OnInit {
  public static nextId = 0;
  @ViewChild(MatSelect, {static: true}) public formControl: MatSelect;
  public patterns: Pattern[] = [Pattern.NONE, Pattern.LINE, Pattern.STRIPED, Pattern.DOTTED, Pattern.DIAGONAL];
  public patternEnum: typeof Pattern = Pattern;

  public id = `vdw-select-patterns-${SelectPatternsComponent.nextId++}`;
  public controlType = 'mat-select';

  public constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }

  public ngOnInit(): void {
    super.init();
  }

  public ngOnDestroy(): void {
    super.destroy();
  }
}
