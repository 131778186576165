export class ProductionScheduleForOrderLine {
  private _id: string | number;
  private _name: string;
  private _amountOfItems: number;

  public constructor(id: string | number, name: string, amountOfItems: number) {
    this._id = id;
    this._name = name;
    this._amountOfItems = amountOfItems;
  }

  public get id(): string | number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get amountOfItems(): number {
    return this._amountOfItems;
  }

  public static fromJSON(productionScheduleForOrderLineJSON: any): ProductionScheduleForOrderLine {
    return new ProductionScheduleForOrderLine(productionScheduleForOrderLineJSON.id, productionScheduleForOrderLineJSON.name, productionScheduleForOrderLineJSON.amount);
  }
}
