export enum FilterType {
  EQUALS,
  NOTEQUAL,
  CONTAINS,
  NOTCONTAINS,
  STARTSWITH,
  ENDSWITH,
  LESSTHAN,
  LESSTHANOREQUAL,
  GREATERTHAN,
  GREATERTHANOREQUAL,
  INRANGE,
  NULL,
  NOTNULL
}
