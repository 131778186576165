import {StringUtils} from '@application/helper/string-utils';
import {WeaveStructureForProductionSchedule} from '@domain/production-schedule/weave-structure-for-production-schedule';
import {GeneralJuteSettings} from '@domain/textile-data/machine-quality/general-jute-settings';
import {Jute} from '@domain/textile-data/machine-quality/jute';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {AssertionUtils} from '@vdw/angular-component-library';
import {map} from 'lodash-es';

export class MachineQualityForProductionSchedule {
  private _id: number;
  private _technicalName: string;
  private _version: number;
  private _weaveStructure: WeaveStructureForProductionSchedule;
  private _reedDensity: number;
  private _pickDensity: number;
  private _weftDensity: number;
  private _practicalPickDensity: number;
  private _buggyLengthMin: number;
  private _buggyLengthMax: number;
  private _tolerance: number;
  private _picksPerHourMin: number;
  private readonly _used: boolean;
  private _numberOfColumnsToDropForWeftSelection: number;
  private _jute: Jute[];
  private _juteGeneralSettings: GeneralJuteSettings;

  public constructor(
    id: number,
    technicalName: string,
    version: number,
    weaveStructure: WeaveStructureForProductionSchedule,
    reedDensity: number,
    pickDensity: number,
    weftDensity: number,
    practicalPickDensity: number,
    buggyLengthMin: number,
    buggyLengthMax: number,
    tolerance: number,
    picksPerHourMin: number,
    used: boolean,
    numberOfColumnsToDropForWeftSelection: number,
    juteConditions: Jute[],
    juteGeneralSettings: GeneralJuteSettings
  ) {
    this._id = id;
    this._technicalName = technicalName;
    this._weaveStructure = weaveStructure;
    this._reedDensity = reedDensity;
    this._pickDensity = pickDensity;
    this._weftDensity = weftDensity;
    this._practicalPickDensity = practicalPickDensity;
    this._buggyLengthMin = buggyLengthMin;
    this._buggyLengthMax = buggyLengthMax;
    this._tolerance = tolerance;
    this._picksPerHourMin = picksPerHourMin;
    this._version = version;
    this._used = used;
    this._numberOfColumnsToDropForWeftSelection = numberOfColumnsToDropForWeftSelection;
    this._jute = juteConditions;
    this._juteGeneralSettings = juteGeneralSettings;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get technicalName(): string {
    return this._technicalName;
  }

  public get version(): number {
    return this._version;
  }

  public get technicalNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.technicalName, this.version);
  }

  public get weaveStructure(): WeaveStructureForProductionSchedule {
    return this._weaveStructure;
  }

  public get reedDensity(): number {
    return this._reedDensity;
  }

  public get pickDensity(): number {
    return this._pickDensity;
  }

  public set pickDensity(value: number) {
    this._pickDensity = value;
  }

  public set reedDensity(value: number) {
    this._reedDensity = value;
  }

  public get weftDensity(): number {
    return this._weftDensity;
  }

  public get practicalPickDensity(): number {
    return this._practicalPickDensity;
  }

  public get buggyLengthMin(): number {
    return this._buggyLengthMin;
  }

  public get buggyLengthMax(): number {
    return this._buggyLengthMax;
  }

  public get tolerance(): number {
    return this._tolerance;
  }

  public get picksPerHourMin(): number {
    return this._picksPerHourMin;
  }

  public get used(): boolean {
    return this._used;
  }

  public get numberOfColumnsToDropForWeftSelection(): number {
    return this._numberOfColumnsToDropForWeftSelection;
  }

  public get juteConditions(): Jute[] {
    return this._jute;
  }

  public set juteConditions(value: Jute[]) {
    this._jute = value;
  }

  public get juteGeneralSettings(): GeneralJuteSettings {
    return this._juteGeneralSettings;
  }

  public set juteGeneralSettings(value: GeneralJuteSettings) {
    this._juteGeneralSettings = value;
  }

  public static fromWeaveQuality(weaveQuality: WeaveQuality): MachineQualityForProductionSchedule {
    return new MachineQualityForProductionSchedule(
      weaveQuality.id,
      weaveQuality.technicalName,
      weaveQuality.version,
      weaveQuality.singleWidth
        ? WeaveStructureForProductionSchedule.fromWeaveStructure(weaveQuality.singleWidth.weaveStructure)
        : WeaveStructureForProductionSchedule.fromWeaveStructure(weaveQuality.doubleWidth.bottomWeaveStructure),
      weaveQuality.reedDensityInDentsPerMM,
      weaveQuality.pickDensityInPicksPerMM,
      weaveQuality.weftDensityInDentsPerMM,
      weaveQuality.practicalPickDensityInPicksPerMM,
      weaveQuality.buggyLengthMinInMM,
      weaveQuality.buggyLengthMaxInMM,
      weaveQuality.tolerance,
      weaveQuality.picksPerHourMin,
      weaveQuality.used,
      weaveQuality.numberOfColumnsToDropForWeftSelection,
      weaveQuality.jute,
      weaveQuality.juteGeneralSettings
    );
  }

  public static fromJSON(machineQualityJSON: any): MachineQualityForProductionSchedule {
    let result = null;

    if (!AssertionUtils.isNullOrUndefined(machineQualityJSON)) {
      result = new MachineQualityForProductionSchedule(
        machineQualityJSON.id,
        machineQualityJSON.technicalName,
        machineQualityJSON.version,
        !AssertionUtils.isNullOrUndefined(machineQualityJSON.weaveStructure) ? WeaveStructureForProductionSchedule.fromJSON(machineQualityJSON.weaveStructure) : null,
        machineQualityJSON.reedDensity,
        machineQualityJSON.pickDensity,
        machineQualityJSON.weftDensityInWefts,
        machineQualityJSON.practicalPickDensity,
        machineQualityJSON.buggyLengthMinInMM,
        machineQualityJSON.buggyLengthMaxInMM,
        machineQualityJSON.tolerance,
        machineQualityJSON.picksPerHourMin,
        machineQualityJSON.used,
        machineQualityJSON.nrColumnsToDropForWeftSelection,
        map(machineQualityJSON.jute, (jute: any) => {
          return Jute.fromJSON(jute);
        }),
        machineQualityJSON.juteGeneralSettings
      );
    }

    return result;
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      technicalName: this._technicalName,
      version: this._version,
      weaveStructure: this._weaveStructure?.toJSON(),
      reedDensity: this._reedDensity,
      pickDensity: this._pickDensity,
      weftDensityInWefts: this._weftDensity,
      practicalPickDensity: this._practicalPickDensity,
      buggyLengthMinInMM: this._buggyLengthMin,
      buggyLengthMaxInMM: this._buggyLengthMax,
      tolerance: this._tolerance,
      picksPerHourMin: this._picksPerHourMin,
      nrColumnsToDropForWeftSelection: this._numberOfColumnsToDropForWeftSelection
    } as any as JSON;
  }
}
