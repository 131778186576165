import {HorizontalPositionOfColorCreelPosition} from '@domain/textile-data/color-set/horizontal-position-of-color-creel-position';
import {HorizontalPositionOfGroupCreelPosition} from '@domain/textile-data/colored-yarn-set/horizontal-position-of-group-creel-position';
import {HorizontalPositionOfCreelPosition} from '@domain/textile-data/creel/horizontal-position-of-creel-position';
import {HorizontalPositionOfCreelPositionType} from '@domain/textile-data/creel/horizontal-position-of-creel-position-type.enum';
import {HorizontalPositionOfYarnTypeCreelPosition} from '@domain/textile-data/yarn-set/horizontal-position-of-yarn-type-creel-position';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {Color} from '@vdw/angular-component-library';
import {each, flatMap, isNil, map, size, uniqBy} from 'lodash-es';

export class CreelPosition {
  private readonly _horizontalPositionsOfCreelPosition: HorizontalPositionOfCreelPosition[];
  private _isSelected: boolean;

  public constructor(creelPositionHorizontalPositions: HorizontalPositionOfCreelPosition[], isSelected: boolean = false) {
    this._horizontalPositionsOfCreelPosition = creelPositionHorizontalPositions;
    this._isSelected = isSelected;
  }

  public get horizontalPositionsOfCreelPosition(): HorizontalPositionOfCreelPosition[] {
    return this._horizontalPositionsOfCreelPosition;
  }

  public static fromJSON(creelPositionJSON: any): CreelPosition {
    return new CreelPosition(
      map(creelPositionJSON.creel, (creelJSON: any) => {
        let position: HorizontalPositionOfCreelPosition;

        const type: HorizontalPositionOfCreelPositionType = creelJSON.type as HorizontalPositionOfCreelPositionType;
        const valueOfType: HorizontalPositionOfCreelPositionType = HorizontalPositionOfCreelPositionType[type] as any as HorizontalPositionOfCreelPositionType;

        switch (valueOfType) {
          case HorizontalPositionOfCreelPositionType.COLOR:
            position = HorizontalPositionOfColorCreelPosition.fromJSON(creelJSON);
            break;
          case HorizontalPositionOfCreelPositionType.GROUP:
            position = HorizontalPositionOfGroupCreelPosition.fromJSON(creelJSON);
            break;
          case HorizontalPositionOfCreelPositionType.YARN_TYPE:
            position = HorizontalPositionOfYarnTypeCreelPosition.fromJSON(creelJSON);
            break;
        }
        return position;
      })
    );
  }

  public toJSON(): JSON {
    return map(this.horizontalPositionsOfCreelPosition, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
      let result: JSON;

      switch (horizontalPositionOfCreelPosition.type) {
        case HorizontalPositionOfCreelPositionType.COLOR:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfColorCreelPosition).toJSON();
          break;
        case HorizontalPositionOfCreelPositionType.GROUP:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfGroupCreelPosition).toJSON();
          break;
        case HorizontalPositionOfCreelPositionType.YARN_TYPE:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfYarnTypeCreelPosition).toJSON();
          break;
      }

      return result;
    }) as any as JSON;
  }

  public getSortedItems(): MaterialType[] {
    return flatMap(this.horizontalPositionsOfCreelPosition, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
      let result;

      switch (horizontalPositionOfCreelPosition.type) {
        case HorizontalPositionOfCreelPositionType.COLOR:
          result = !isNil(horizontalPositionOfCreelPosition.amount)
            ? Array(horizontalPositionOfCreelPosition.amount).fill((horizontalPositionOfCreelPosition as HorizontalPositionOfColorCreelPosition).color)
            : (horizontalPositionOfCreelPosition as HorizontalPositionOfColorCreelPosition).color;
          break;
        case HorizontalPositionOfCreelPositionType.GROUP:
          result = (horizontalPositionOfCreelPosition as HorizontalPositionOfGroupCreelPosition).getItems();
          break;
        case HorizontalPositionOfCreelPositionType.YARN_TYPE:
          result = !isNil(horizontalPositionOfCreelPosition.amount)
            ? Array(horizontalPositionOfCreelPosition.amount).fill((horizontalPositionOfCreelPosition as HorizontalPositionOfYarnTypeCreelPosition).yarnType)
            : (horizontalPositionOfCreelPosition as HorizontalPositionOfYarnTypeCreelPosition).yarnType;

          break;
        default:
          result = [];
      }

      return result;
    });
  }

  public getPatternForGivenNumberOfDents(numberOfDents: number): Color[] | OverviewListYarnType[] {
    const patternForGivenNumberOfDents = [];

    while (this.hasMaximumSizeOfDentsBeenReached(patternForGivenNumberOfDents, numberOfDents)) {
      each(this.horizontalPositionsOfCreelPosition, (horizontalPositionOfCreelPosition: HorizontalPositionOfCreelPosition) => {
        if (this.hasMaximumSizeOfDentsBeenReached(patternForGivenNumberOfDents, numberOfDents)) {
          each(horizontalPositionOfCreelPosition.getPatternForHorizontalPositionOfCreelPosition(), (item: Color | OverviewListYarnType) => {
            if (this.hasMaximumSizeOfDentsBeenReached(patternForGivenNumberOfDents, numberOfDents)) {
              patternForGivenNumberOfDents.push(item);
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    }

    return patternForGivenNumberOfDents;
  }

  public getUniqueItems(): MaterialType[] {
    return uniqBy(this.getSortedItems(), 'id');
  }

  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isSelected(value: boolean) {
    this._isSelected = value;
  }

  private hasMaximumSizeOfDentsBeenReached(mapped: any[], numberOfDents: number): boolean {
    return size(mapped) < numberOfDents;
  }
}

export type MaterialType = OverviewListYarnType | Color;
