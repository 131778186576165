import {BackgroundJobType} from '@domain/background-jobs/background-job-type';
import {BackgroundStatus} from '@domain/background-jobs/background-status';
import {BackgroundTask} from '@domain/background-jobs/background-task';
import {Subscription} from '@domain/profile/subscription';
import {AssertionUtils, moment, TimeUtils, TimezoneUtils} from '@vdw/angular-component-library';
import {find, isEqual, isNil, map} from 'lodash-es';
import {BackgroundTaskAction} from './background-task-action';

export class BackgroundJob {
  private _id: string;
  private _jobType: BackgroundJobType;
  private _name: string;
  private _startTime: Date;
  private _endTime: Date;
  private _status: BackgroundStatus;
  private _tasks: BackgroundTask[];
  private _errorMessage: string;

  public constructor(id: string, jobType: BackgroundJobType, name: string, startTime: Date, endTime: Date, status: BackgroundStatus, tasks: BackgroundTask[], errorMessage: string) {
    this._id = id;
    this._jobType = jobType;
    this._name = name;
    this._startTime = startTime;
    this._endTime = endTime;
    this._status = status;
    this._tasks = tasks;
    this._errorMessage = errorMessage;
  }

  public get id(): string {
    return this._id;
  }

  public get jobType(): BackgroundJobType {
    return this._jobType;
  }

  public get name(): string {
    return this._name;
  }

  public get startTime(): Date {
    return this._startTime;
  }

  public get endTime(): Date {
    return this._endTime;
  }

  public get durationInMs(): number {
    return moment(this._endTime).diff(this._startTime, TimeUtils.MILLISECONDS_UNIT);
  }

  public get status(): BackgroundStatus {
    return this._status;
  }

  public get tasks(): BackgroundTask[] {
    return this._tasks;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public static fromJSON(backgroundJobJSON: any, subscription: Subscription): BackgroundJob {
    const timezoneCode: string = subscription.timeZone.code;
    const startTime = TimezoneUtils.convertToDateWithCurrentOffset(backgroundJobJSON.startTime, timezoneCode);
    const endTime = TimezoneUtils.convertToDateWithCurrentOffset(backgroundJobJSON.endTime, timezoneCode);

    return new BackgroundJob(
      backgroundJobJSON.id,
      BackgroundJobType[`${backgroundJobJSON.jobType}`],
      backgroundJobJSON.name,
      startTime,
      endTime,
      BackgroundStatus[`${backgroundJobJSON.status}`],
      map(backgroundJobJSON.tasks, (backgroundTaskJSON: any) => BackgroundTask.fromJSON(backgroundTaskJSON, subscription)),
      backgroundJobJSON.errorMessage
    );
  }

  public getActiveTask(): BackgroundTask {
    let result: BackgroundTask;

    if (this.hasCompleted()) {
      result = null;
    } else if (this.hasFailed()) {
      result = this.getFailedTask();
    } else if (this.hasCancelled()) {
      result = this.getCancelledTask();
    } else {
      result = find(this.tasks, ['status', BackgroundStatus.STARTED]);

      if (isNil(result)) {
        result = find(this.tasks, ['status', BackgroundStatus.NOT_STARTED]);
      }
    }

    return result;
  }

  public canRetry(): boolean {
    let result = false;

    const hasJobFailed = this.hasFailed();
    const hasJobBeenCancelled = this.hasCancelled();

    if (hasJobFailed || hasJobBeenCancelled) {
      if (this.isJobTypeProcessSingleBmp() || this.isJobTypeGenerateScheduleZip()) {
        result = true;
      } else if (this.isJobTypeCompleteOrder()) {
        const task = hasJobFailed ? this.getFailedTask() : this.getCancelledTask();
        result = !isNil(task) && !isEqual(task.action, BackgroundTaskAction.CREATE_ORDER);
      }
    }

    return result;
  }

  public hasCompleted(): boolean {
    return isEqual(this.status, BackgroundStatus.COMPLETED);
  }

  public hasErrorMessage(): boolean {
    return !AssertionUtils.isEmpty(this.errorMessage);
  }

  public canBeCanceled(): boolean {
    return isEqual(this._status, BackgroundStatus.STARTED) || isEqual(this._status, BackgroundStatus.NOT_STARTED);
  }

  public hasFailed(): boolean {
    return isEqual(this.status, BackgroundStatus.FAILED);
  }

  private getFailedTask(): BackgroundTask {
    return find(this.tasks, ['status', BackgroundStatus.FAILED]);
  }

  private getCancelledTask(): BackgroundTask {
    return find(this.tasks, ['status', BackgroundStatus.CANCELLED]);
  }

  private hasCancelled(): boolean {
    return isEqual(this.status, BackgroundStatus.CANCELLED);
  }

  private isJobTypeCompleteOrder(): boolean {
    return isEqual(this.jobType, BackgroundJobType.COMPLETE_ORDER);
  }

  private isJobTypeProcessSingleBmp(): boolean {
    return isEqual(this.jobType, BackgroundJobType.PROCESS_SINGLE_BMP);
  }

  private isJobTypeGenerateScheduleZip(): boolean {
    return this.jobType === BackgroundJobType.GENERATE_SCHEDULE_ZIP;
  }
}
