import {RouteBranch} from '@application/helper/routing/route-branch';
import {ShiftRoute} from './shift-route';

export class CalendarRoute extends RouteBranch {
  private _shift: ShiftRoute;

  public get shift(): ShiftRoute {
    return this._shift;
  }

  public set shift(value: ShiftRoute) {
    this.replace(this._shift, value);
    this._shift = value;
  }
}
