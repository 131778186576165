import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Pattern} from '@domain/pattern';
import {PropertyValue} from '@domain/property-value';
import {CutbarPattern} from '@domain/textile-data/finishing-and-finishing-template/finishing/cutbar-pattern';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {ExtendedHttpParams} from '@infrastructure/http/extended-http-params';
import {Finishings, GetAllFinishingsParameters} from '@infrastructure/http/finishing/finishings';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpFinishingsService implements Finishings {
  private readonly VARIABLE_NAMES_TO_HTTP_PARAMS_MAP = new Map<string, string>([
    ['showOnlyLatestVersion', 'only-latest-versions'],
    ['onlyRecommended', 'only-recommended'],
    ['machineQualityId', 'machine-quality-id'],
    ['weaveStructureId', 'weave-structure-id']
  ]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}finishings/check-name`, {params}).pipe(rxjsMap((response: any) => CheckName.fromJSON(response)));
  }

  public getPatternsForCutbarForMachineQuality(parameters?: Partial<GetAllFinishingsParameters>): Observable<CutbarPattern[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint(2)}finishings/cutbar-patterns`, {params})
      .pipe(rxjsMap((cutbarPatternsJSON: any) => map(cutbarPatternsJSON, (cutbarPatternJSON: any) => CutbarPattern.fromJSON(cutbarPatternJSON))));
  }

  public getFinishingsForMachineQualityId(parameters?: Partial<GetAllFinishingsParameters>): Observable<OverviewListFinishing[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}finishings/machine-quality/${parameters.machineQualityId}`, {params})
      .pipe(rxjsMap((finishingsJSON: any) => map(finishingsJSON, (finishingJSON: any) => OverviewListFinishing.fromJSON(finishingJSON))));
  }

  public generateFinishingForFinishingTemplateAndMachineQuality(finishingTemplateId: number, machineQualityId: number): Observable<Finishing> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}finishings/finishing-template/${finishingTemplateId}/machine-quality/${machineQualityId}`)
      .pipe(rxjsMap((finishingJSON: any) => Finishing.fromJSON(finishingJSON)));
  }

  public generateFinishingForFinishingTemplateAndQuality(finishingTemplateId: number, weaveStructureId: number, pickDensity: number): Observable<Finishing> {
    const params = new HttpParams().set('weave-structure-id', weaveStructureId).set('pick-density', pickDensity);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint(2)}finishings/finishing-template/${finishingTemplateId}`, {params})
      .pipe(rxjsMap((finishingJSON: any) => Finishing.fromJSON(finishingJSON)));
  }

  public getOverviewListFinishingById(id: number): Observable<OverviewListFinishing> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishings/${id}`).pipe(rxjsMap((finishingJSON: any) => OverviewListFinishing.fromJSON(finishingJSON)));
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}finishings/${id}`);
  }

  public getAll(parameters?: Partial<GetAllFinishingsParameters>): Observable<OverviewListFinishing[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}finishings`, {params})
      .pipe(rxjsMap((finishingsJSON: any) => map(finishingsJSON, (finishingJSON: any) => OverviewListFinishing.fromJSON(finishingJSON))));
  }

  public getById(id: number): Observable<Finishing> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishings/${id}`).pipe(rxjsMap((finishingJSON: any) => Finishing.fromJSON(finishingJSON)));
  }

  public save(item: Finishing): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}finishings`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public update(item: Finishing): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}finishings/${item.id}`, item.toJSON());
  }

  public getMinNumberOfPicks(foldingTypeName: string, machineQualityId: number): Observable<number> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}finishings/folding-types/${foldingTypeName}/min-number-of-cutbar-picks/machine-quality-id=${machineQualityId}`)
      .pipe(rxjsMap((response: {minNumberOfCutbarPicks: number}) => response.minNumberOfCutbarPicks));
  }

  public getMinNumberOfCutbarPicks(foldingTypeName: string, weaveStructureId: number): Observable<number> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject({weaveStructureId}, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint(2)}finishings/folding-types/${foldingTypeName}/min-number-of-cutbar-picks`, {params})
      .pipe(rxjsMap((response: {minNumberOfCutbarPicks: number}) => response.minNumberOfCutbarPicks));
  }

  public getFoldingTypes(weaveStructureId: number): Observable<Pattern[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishings/folding-types/weave-structure-id/${weaveStructureId}`).pipe(
      rxjsMap((patternsJSON: any) => {
        return patternsJSON.map((patternJSON: any) => {
          return Pattern.fromFoldingTypeJSON(patternJSON);
        });
      })
    );
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}finishings/${id}/conflicts`).pipe(
      rxjsMap((conflictsJSON: any) => {
        return map(conflictsJSON, (conflictJSON: any) => {
          return Conflict.fromJSON(conflictJSON);
        });
      })
    );
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get<PropertyValue[]>(`${this.backendConfiguration.getEndpoint()}finishings/custom-settings`);
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}finishings/custom-settings/already-adjusted`)
      .pipe(rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => response.alreadyAdjustedCustomSettings));
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}finishings/custom-settings`, chosenSettings);
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getEndpoint()}finishings/custom-settings/name-generation-properties`);
  }

  public generateName(item: Finishing): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getEndpoint()}finishings/custom-settings/generate-name`, item.toJSON(false));
  }
}
