import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {SourceElementUtils} from '@application/helper/source-element-utils';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {RepositionDialogData} from '@presentation/components/reposition-dialog/reposition-dialog-data.interface';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {TextileDataDetailDialogData} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail-dialog-data.interface';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {DialogBuilderFactoryService, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE, TranslateService} from '@vdw/angular-component-library';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {isNil, map} from 'lodash-es';

@Component({
  templateUrl: './grid-creel-preview.component.html',
  styleUrls: ['./grid-creel-preview.component.scss']
})
export class GridCreelPreviewComponent implements ICellEditorAngularComp {
  public listOfColoredYarnSets: OverviewListColoredYarnSet[];
  public value: OverviewListColoredYarnSetWithStartDent[];

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    @Inject(RESPONSIVENESS_VIEW_MODE) protected responsivenessViewMode: ResponsivenessViewMode,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public agInit(params: ICellEditorParams): void {
    this.value = params.value;
    this.setListOfColoredYarnSets();
  }

  public getValue(): OverviewListColoredYarnSetWithStartDent[] {
    return this.value;
  }

  public getStartDent(index: number): number {
    return this.listOfColoredYarnSets.length > 1 ? this.value[index].startDent : null;
  }

  public showColoredYarnSetDetails(data: {colorList: OverviewListColorSet | OverviewListColoredYarnSet; event: MouseEvent}, index: number): void {
    if (!this.isMobile()) {
      const colorAmountExceedsEight = data.colorList instanceof OverviewListColorSet && data.colorList.creelPositions.length > 8;
      const coloredYarnSetAmountExceedsEight = data.colorList instanceof OverviewListColoredYarnSet && data.colorList.overviewListColorSet.creelPositions.length > 8;

      this.dialogBuilderFactoryService
        .getBuilder()
        .withClass(['reposition-dialog', colorAmountExceedsEight || coloredYarnSetAmountExceedsEight ? 'colored-list-dialog' : ''])
        .withWidth('706px')
        .openDialog(RepositionDialogComponent, this.getDialogDataForColoredYarnSetDetailsDialog(data, index));
    }
  }

  protected getDialogDataForColoredYarnSetDetailsDialog(
    data: {colorList: OverviewListColorSet | OverviewListColoredYarnSet; event: MouseEvent},
    index: number
  ): RepositionDialogData<TextileDataDetailComponent> & TextileDataDetailDialogData {
    const {colorList, event} = data;
    const coloredYarnSet: OverviewListColoredYarnSetWithStartDent = this.getValue()[index];

    return {
      textileData: colorList,
      textileDataAmount: this.getTextileDataAmount(coloredYarnSet),
      component: TextileDataDetailComponent,
      sourceElement: SourceElementUtils.findSourceElementForDialog(
        event,
        this.listOfColoredYarnSets.length <= 1 ? 'color-list-preview-container' : 'color-list-multiple-preview-containers',
        PositionOfDialog.LEFT
      ),
      positionOfDialog: PositionOfDialog.LEFT
    };
  }

  private isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }

  private setListOfColoredYarnSets(): void {
    this.listOfColoredYarnSets = map(this.getValue(), (coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) => coloredYarnSetWithStartDent.coloredYarnSet);
    this.changeDetectorRef.detectChanges();
  }

  private getTextileDataAmount(coloredYarnSet: OverviewListColoredYarnSetWithStartDent): string {
    let widthInDents: number;

    if (coloredYarnSet instanceof OverviewListColoredYarnSetWithStartDent) {
      widthInDents = coloredYarnSet.technicalWidthInDents;
    }

    const startDentText = `${this.translate.instant('GENERAL.START_DENT')}: ${coloredYarnSet.startDent}`;
    let textileDataAmount: string;

    if (!isNil(widthInDents)) {
      const widthInDentsText = `${this.translate.instant('GENERAL.WIDTH_IN_DENTS')}: ${widthInDents}`;
      textileDataAmount = `${startDentText}, ${widthInDentsText}`;
    } else {
      textileDataAmount = startDentText;
    }

    return textileDataAmount;
  }
}
