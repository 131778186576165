import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-disabled-form-input',
  templateUrl: './disabled-form-input.component.html',
  styleUrls: ['./disabled-form-input.component.scss']
})
export class DisabledFormInputComponent {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public unit: string;

  public getValue(): string {
    return this.value ?? '';
  }

  public canShowUnit(): boolean {
    return this.unit !== null;
  }

  public getUnit(): string {
    return this.unit ?? '';
  }
}
