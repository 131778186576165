import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule, MAT_CARD_CONFIG} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {TagModule} from '../tag/tag.module';
import {MobilePageHeaderComponent} from './mobile-page-header.component';

@NgModule({
  declarations: [MobilePageHeaderComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatCardModule, TranslationModule, RouterModule, MatButtonModule, LabelModule, TagModule],
  exports: [MobilePageHeaderComponent],
  providers: [
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined'
      }
    }
  ]
})
export class MobilePageHeaderModule {}
