import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {ProductionOrder} from './production-order';

export class OrderCarpet extends PlanningItem {
  private _productionOrder?: ProductionOrder;
  private _creelMappingFormula?: string;
  private _producedQuantityInPicks: number;
  private _estimatedProductionTime: string;

  public constructor(
    itemProperties: PlanningItemProperties,
    estimatedProductionTime: string,
    productionOrder?: ProductionOrder,
    producedQuantityInPicks: number = 0,
    creelMappingFormula: string = null,
    ...planningItems: PlanningItem[]
  ) {
    super(itemProperties, PlanningItemType.ORDER_CARPET, ...planningItems);
    this._productionOrder = productionOrder;
    this._creelMappingFormula = creelMappingFormula;
    this._producedQuantityInPicks = producedQuantityInPicks;
    this._estimatedProductionTime = estimatedProductionTime;
  }

  public get productionOrder(): ProductionOrder {
    return this._productionOrder;
  }

  public get creelMappingFormula(): string {
    return this._creelMappingFormula;
  }

  public set creelMappingFormula(value: string) {
    this._creelMappingFormula = value;
  }

  public get producedQuantityInPicks(): number {
    return this._producedQuantityInPicks;
  }

  public get estimatedProductionTime(): string {
    return this._estimatedProductionTime;
  }

  public set estimatedProductionTime(value: string) {
    this._estimatedProductionTime = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      productionOrderId: this.productionOrder?.id,
      creelMappingFormula: this.creelMappingFormula,
      estimatedProductionTime: this.estimatedProductionTime
    } as any as JSON;
  }
}
