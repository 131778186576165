<article fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <section fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="end center">
    <div>
      <button mat-icon-button mat-dialog-close class="mat-icon-action-button-lg-with-border">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </section>
  <section fxLayout="row" fxLayoutAlign="center center" class="full">
    <app-pattern-preview [patternVisualisation]="drawingPatternVisualisation" [viewMode]="'full'"></app-pattern-preview>
  </section>
</article>
