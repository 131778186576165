import {Component, Input} from '@angular/core';

@Component({
  selector: 'vdw-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {
  @Input() public stroked = false;
  @Input() public compact = false;
  @Input() public invalid = false;
}
