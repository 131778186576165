import {AssertionUtils, StringUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';

export class MachineGroup {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string = null) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(machineGroupJSON: any): MachineGroup {
    return isNil(machineGroupJSON) ? null : new MachineGroup(machineGroupJSON.id, machineGroupJSON.name);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name
    } as any as JSON;
  }

  public static compare(machineGroupA: MachineGroup, machineGroupB: MachineGroup): number {
    if (machineGroupA?.id === machineGroupB?.id) {
      return 0;
    }

    if (AssertionUtils.isNullOrUndefined(machineGroupA)) {
      return 1;
    }

    if (AssertionUtils.isNullOrUndefined(machineGroupB)) {
      return -1;
    }

    return StringUtils.stringComparator(machineGroupA.name, machineGroupB.name);
  }
}
