<section class="flex-column full-height overflow-hidden">
  <vdw-tabs-menu-card *ngIf="headerVisible" [icon]="getHeaderIcon()" [action]="getActionText()" [title]="productionScheduleInitialPreparationsForm?.value?.name" (backEvent)="navigateBack()">
    <app-production-schedule-menu-items-new
      class="full-width"
      [loadingProperties]="getMenuItemsLoadingProperties()"
      [productionScheduleInitialPreparationsForm]="productionScheduleInitialPreparationsForm"
      [productionSchedulePhase]="currentProductionSchedulePhase"
      [productionScheduleStatus]="productionScheduleToAdd?.status"
      [customErrorMessage]="getInvalidFormMessageError()"
      [canShowProperties]="getMenuItemsCanShowProperties()"
      [pathContentSettingsDialogData]="pathContentSettingsDialogData"
      (clickEvent)="onClickEvent($event)"
      (productionSchedulePhaseClicked)="onProductionSchedulePhaseClicked($event)"
      (pathContentSettingsDialogDataChanged)="onPathContentSettingsDialogDataChanged($event)"
    ></app-production-schedule-menu-items-new>
  </vdw-tabs-menu-card>
  <form [formGroup]="productionScheduleInitialPreparationsForm" appBreadcrumbFormLinker></form>
  <div fxFlex fxLayout="column" fxLayoutAlign="stretch" class="production-schedule-initial-preparations-container" *ngIf="canShowInitialPreparationsForProductionSchedule()">
    <app-production-schedule-initial-preparations-new
      #initialPreparations
      [productionScheduleInitialPreparationsForm]="productionScheduleInitialPreparationsForm"
      [listOfCustomSettings]="listOfParametersForDefaultParameters"
      [listOfMachineQualityCustomSettings]="listOfMachineQualityCustomSettings"
      [productionSchedule]="productionScheduleToAdd"
      [needsToShowValidationFeedback]="canShowInvalidFormMessageError()"
      (formValuesChanged)="initialPreparationsChanged()"
      (restZoneLoaded)="restZoneLoaded()"
      (freeZoneLoaded)="freeZoneLoaded()"
      (pathZoneLoaded)="pathZoneLoaded()"
      (juteZoneLoaded)="juteZoneLoaded()"
      (hideParentHeader)="setHeaderVisibility($event)"
    >
    </app-production-schedule-initial-preparations-new>
  </div>
  <app-production-schedule-new-drawing-library
    *ngIf="canShowPreselectionForProductionSchedule()"
    class="full-height"
    [productionSchedule]="productionScheduleToAdd"
    [productionSchedulePhase]="currentProductionSchedulePhase"
    [listOfProductionOrderCustomSettings]="listOfParametersForDefaultParameters"
    [pathWidths]="pathWidths"
    [isPreSelection]="true"
    [preselectedOrderlineIds]="preselectedOrderlineIds"
    [preselectedDesignIds]="preselectedDesignIds"
    [preselectedConfigurationIds]="preselectedConfigurationIds"
    [addProductionScheduleNavigationState]="addProductionScheduleNavigationState"
    [preselectedDrawings]="preselectedDrawings"
    (preselectedOrderlineIdsOutput)="preselectedOrderlineIdsChanged($event)"
    (preselectedDrawingsOutput)="preselectedDrawingsChanged($event)"
    (preselectedDrawingIdsOutput)="preselectedDrawingIdsChanged($event)"
    (preselectedConfigurationIdsOutput)="preselectedConfigurationIdsChanged($event)"
  ></app-production-schedule-new-drawing-library>
  <app-production-schedule-plan-new
    #productionSchedulePlan
    *ngIf="canShowProductionSchedulePlan()"
    class="full-height overflow-hidden"
    [pathWidths]="pathWidths"
    [productionSchedule]="productionScheduleToAdd"
    [pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks]="pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks"
    [pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit]="pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit"
    [productionSchedulePhase]="currentProductionSchedulePhase"
    [lastProductionSchedulePhase]="lastProductionSchedulePhase"
    [listOfCustomSettings]="listOfParametersForDefaultParameters"
    [listOfMachineQualityCustomSettings]="listOfMachineQualityCustomSettings"
    [isVerifyingFromConfigurePhase]="isVerifyingFromConfigurePhase"
    [pathContentSettingsDialogData]="pathContentSettingsDialogData"
    [preselectedOrderlineIds]="preselectedOrderlineIds"
    [preselectedDesignIds]="preselectedDesignIds"
    [preselectedConfigurationIds]="preselectedConfigurationIds"
    (returnToProductionSchedulePhase)="setProductionSchedulePhase($event)"
    (productionSchedulePlanChanged)="productionSchedulePlanChanged()"
    (verifiedProductionSchedule)="productionScheduleVerified($event)"
    (navigateBackOutput)="navigateBack()"
  >
  </app-production-schedule-plan-new>

  <vdw-loading-overlay fxFlex *ngIf="isLoadingProductionSchedule" [hideDescription]="false" [scale]="1"> </vdw-loading-overlay>
</section>
