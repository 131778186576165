import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddSalesOrderPageComponent} from '@presentation/pages/texfab/sales-order/add/add-sales-order-page.component';
import {SalesOrderOverviewPageComponent} from '@presentation/pages/texfab/sales-order/overview/sales-order-overview-page.component';

export const salesOrderRoutes = [
  Factory.createPrototypeComponentRoute(SalesOrderOverviewPageComponent, {route: '', requiredPermission: null, id: NavigationId.SALES_ORDER}),
  Factory.createPrototypeComponentRoute(AddSalesOrderPageComponent, {route: 'add/:id', requiredPermission: Permission.TEXFAB_SALES_ORDER_EDIT, id: NavigationId.DUPLICATE_SALES_ORDER}),
  Factory.createPrototypeComponentRoute(AddSalesOrderPageComponent, {route: 'edit/:id', requiredPermission: Permission.TEXFAB_SALES_ORDER_VIEW, id: NavigationId.EDIT_SALES_ORDER}),
  Factory.createPrototypeComponentRoute(AddSalesOrderPageComponent, {route: 'add', requiredPermission: Permission.TEXFAB_SALES_ORDER_EDIT, reuse: true, id: NavigationId.ADD_SALES_ORDER})
];

export const salesOrderDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(salesOrderRoutes);
