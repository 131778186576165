import {Drawing} from '@domain/production-schedule/drawing';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ListDrawing} from './list-drawing';

export class ListDrawingOrderLine implements ListDrawing {
  private _orderLine: ProductionScheduleOrderLine;
  private _amount: number;
  private _commercialLengthInMM: number;
  private _picksPerColorLine: number;
  private _rollLengthInMeter: number;

  public constructor(orderLine: ProductionScheduleOrderLine, amount?: number, commercialLengthInMM?: number, picksPerColorLine?: number, rollLengthInMeter?: number) {
    this._orderLine = orderLine;
    this._amount = amount ?? orderLine.amountLeft;
    this._commercialLengthInMM = commercialLengthInMM ?? orderLine.heightInProductionScheduleInMM;
    this._picksPerColorLine = picksPerColorLine;
    this._rollLengthInMeter = rollLengthInMeter;
  }

  public get id(): string {
    return `${this._orderLine.id}_${this.drawing.image.id}`;
  }

  public get drawing(): Drawing {
    return this._orderLine.article.design;
  }

  public set drawing(drawing: Drawing) {
    this._orderLine.article.design = drawing;
  }

  public get orderLine(): ProductionScheduleOrderLine {
    return this._orderLine;
  }

  public get amount(): number {
    return this._amount;
  }

  public set amount(value: number) {
    this._amount = value;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public set commercialLengthInMM(value: number) {
    this._commercialLengthInMM = value;
  }

  public get picksPerColorLine(): number {
    return this._picksPerColorLine;
  }

  public set picksPerColorLine(value: number) {
    this._picksPerColorLine = value;
  }

  public get rollLengthInMeter(): number {
    return this._rollLengthInMeter;
  }

  public static fromProductionScheduleOrderLine(
    productionScheduleOrderLine: ProductionScheduleOrderLine,
    amount: number = null,
    commercialLengthInMM: number = null,
    picksPerColorLine: number = null,
    rollLengthInMeter?: number
  ): ListDrawingOrderLine {
    return new ListDrawingOrderLine(productionScheduleOrderLine, amount, commercialLengthInMM, picksPerColorLine, rollLengthInMeter);
  }
}
