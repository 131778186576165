export class HeaderButtons {
  public back = false;
  public cancel = false;
  public delete = false;
  public duplicate = false;
  public save = false;
  public saveAndClose = false;
  public saveAndCreateNew = false;
  public choose = false;
  public settings = false;
  public inUse = false;
}
