import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {MachineType} from '@domain/machine/machine-type.enum';
import {Regime} from '@domain/machine/regime';
import {Shift} from '@domain/machine/shift';
import {FixedSchedulePlaceholder} from '@domain/planning-prototype/carpet/fixed-schedule-placeholder';
import {OrderCarpet} from '@domain/planning-prototype/carpet/order-carpet';
import {RunCarpet} from '@domain/planning-prototype/carpet/run-carpet';
import {PlanningFilter} from '@domain/planning-prototype/custom-settings/planning-filter';
import {PlanningOrderLite} from '@domain/planning-prototype/generic-order.interface';
import {Maintenance} from '@domain/planning-prototype/maintenance';
import {MaintenanceType} from '@domain/planning-prototype/maintenance-type';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningForecast} from '@domain/planning-prototype/planning-forecast';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {PlanningItemForecast} from '@domain/planning-prototype/planning-item-forecast';
import {PlanningLine} from '@domain/planning-prototype/planning-line';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {ProductionOrderType} from '@domain/planning-prototype/to-plan/production-order-type';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {MACHINE_OVERVIEW, MachineOverview} from '@infrastructure/http/machine-overview/machine-overview';
import {HttpPlanningPrototypeService} from '@infrastructure/http/planning-prototype/http-planning-prototype.service';
import {SignalRPlanningPrototypeService} from '@infrastructure/signalr/planning-prototype/signalr-planning-prototype.service';
import {ProductionOrderLiteStatusChange} from '@infrastructure/signalr/production-order-lite/production-order-lite-status-change';
import {REALTIME_PRODUCTION_ORDER_LITE, RealtimeProductionOrderLite} from '@infrastructure/signalr/production-order-lite/realtime-production-order-lite';
import {ProductionOrderStatusChange} from '@infrastructure/signalr/production-order/production-order-status-change';
import {REALTIME_PRODUCTION_ORDER, RealtimeProductionOrder} from '@infrastructure/signalr/production-order/realtime-production-order';
import {OverviewMachineGroup} from '@presentation/pages/machine-overview/overview-machine-group';
import {
  ArrayUtils,
  AssertionUtils,
  BaseComponent,
  DragDropData,
  ObjectActionType,
  PlanningGridComponent,
  TimeUtils,
  ToastHelperService,
  TranslateService,
  UuidUtils
} from '@vdw/angular-component-library';
import {UUID} from 'crypto';
import moment, {Moment} from 'moment';
import {buffer, combineLatest, concatMap, debounceTime, distinctUntilChanged, filter, finalize, interval, map, merge, of, startWith, switchMap, take, takeUntil, tap} from 'rxjs';
import {MachineGroupFilterComponent} from './machine-group-filter/machine-group-filter.component';
import {PlanningFilterService} from './utilities/planning-filter.service';
import {PlanningItemDragDropService} from './utilities/planning-item-drag-drop.service';
import {PlanningPrototypeContextService} from './utilities/planning-prototype-context.service';

export interface DeferredAction {
  action: () => void;
}
@Component({
  selector: 'app-planning-prototype',
  templateUrl: './planning-prototype.component.html',
  styleUrls: ['./planning-prototype.component.scss']
})
export class PlanningPrototypeComponent extends BaseComponent implements OnInit {
  @ViewChild(PlanningGridComponent)
  public planningGrid: PlanningGridComponent;

  public get machines(): PlanningEquipment[] {
    return this.planningContext.machines;
  }

  public readonly NEW_RUN_TEXT = this.translate.instant('GENERAL.ACTIONS.NEW_OBJECT', {object: 'PLANNING.ADD_ITEM.TYPES.RUN'}).toUpperCase();
  public readonly FILTER_SETTINGS_COMPONENT = MachineGroupFilterComponent;
  public updatedPlanningLines: PlanningLine[] = [];
  public loading = true;
  public regimes: Regime[] = [];
  public startDate: Moment;
  public endDate: Moment;

  private currentSubscription: Subscription;
  private translationForUnknownItem = 'UNKNOWN';
  private readonly HISTORY_REQUEST_FORMAT = 'yyyy-MM-DD';
  private readonly INTERVAL_WAIT_ON_INITIALIZED = 200;
  private readonly INTERVAL_AGGREGATE_BACKEND_UPDATES = 3000;
  private readonly INTERVAL_AUTO_REFRESH_FORECASTS = 30000;
  private readonly DEBOUNCE_AGGREGATE_TO_PLAN_UPDATES = 200;

  public constructor(
    private readonly planning: HttpPlanningPrototypeService,
    private readonly planningContext: PlanningPrototypeContextService,
    private readonly translate: TranslateService,
    private readonly planningDragDrop: PlanningItemDragDropService,
    private readonly planningUpdates: SignalRPlanningPrototypeService,
    private readonly planningFilterService: PlanningFilterService,
    private readonly toastHelper: ToastHelperService,
    @Inject(REALTIME_PRODUCTION_ORDER) private readonly realtimeProductionOrder: RealtimeProductionOrder,
    @Inject(REALTIME_PRODUCTION_ORDER_LITE) private readonly realtimeProductionOrderLite: RealtimeProductionOrderLite,
    @Inject(MACHINE_OVERVIEW) private readonly machineOverview: MachineOverview,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication
  ) {
    super();
    planningContext.reset();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.translationForUnknownItem = this.translate.instant('PLANNING.UNKNOWN');

    this.loadInitialData();
    this.fetchUpdatedForecastsWhenPlanningLinesAreUpdated();
    this.trackUpdatedPlanningLines();
    this.applyRequiredChangesOnBackendUpdates();
  }

  public loadHistoryData(range: {startDate: Moment; endDate: Moment}): void {
    if (AssertionUtils.isNullOrUndefined(range.startDate) || AssertionUtils.isNullOrUndefined(range.endDate)) {
      return;
    }

    interval(this.INTERVAL_WAIT_ON_INITIALIZED)
      .pipe(
        startWith(-1),
        filter(() => !AssertionUtils.isNullOrUndefined(this.planningContext.planningForecast)),
        take(1),
        switchMap(() => this.planning.getPlanningHistory(range.startDate.format(this.HISTORY_REQUEST_FORMAT), range.endDate.format(this.HISTORY_REQUEST_FORMAT))),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((history: {[ids: number]: PlanningItem[]}) => this.planningContext.mergeHistoryData(history));
  }

  public getIconForMachineType(type: MachineType): string {
    return MachineType.getIconNameForMachineType(type);
  }

  public getPlanningItemsForMachine(machine: PlanningEquipment): PlanningItem[] {
    return this.planningContext.planningItemsByMachine[machine.id];
  }

  public canShowFixedSchedules(): boolean {
    return !AssertionUtils.isEmpty(this.planningContext.fixedSchedulesToPlan) && this.currentSubscription?.hasPermission(Permission.FIXED_SCHEDULE_EDIT);
  }

  public getHistoryForMachine(machine: PlanningEquipment): PlanningItem[] {
    const planningLine = this.planningContext.planningLinesByMachine[machine.id];
    if (AssertionUtils.isNullOrUndefined(planningLine)) {
      return [];
    }

    return this.planningContext.planningHistory[planningLine.id];
  }

  public getPlanningItemIsGroup(planningItem: PlanningItem): boolean {
    return PlanningItemHelper.isRunItem(planningItem);
  }

  public getForecastForPlanningItem(item: PlanningItem): PlanningItemForecast {
    return this.planningContext.sortedPlanningItemForecasts[item.draftId];
  }

  public getPlanningItemDisplayName(item: PlanningItem): string {
    if (item instanceof RunCarpet) {
      return `${item.creel?.name ?? this.translationForUnknownItem}/${item.quality?.name ?? this.translationForUnknownItem}`;
    }

    return PlanningItemHelper.getItemName(item) ?? this.translationForUnknownItem;
  }

  public getIsDifferentConfig(item: PlanningItem): boolean {
    return this.planningContext.productChanges.includes(item.draftId);
  }

  public selectMachine(machine: PlanningEquipment): void {
    this.planningContext.selectItem(machine);
  }

  public selectItem(item: PlanningItem): void {
    this.planningContext.selectItem(item);
  }

  public getTimelineStartDate(item: PlanningItem): Date {
    return PlanningItemHelper.getTimelineStartDate(item, this.getForecastForPlanningItem(item));
  }

  public getTimelineEndDate(item: PlanningItem): Date {
    return PlanningItemHelper.getTimelineEndDate(item, this.getForecastForPlanningItem(item));
  }

  public getOrderIsPastDue(item: PlanningItem): boolean {
    if (PlanningItemHelper.isOrderItem(item) && !AssertionUtils.isNullOrUndefined(item.productionOrder?.dueDate)) {
      return this.getTimelineEndDate(item) > item.productionOrder.dueDate;
    }
    return false;
  }

  public openToPlan(): void {
    this.planningContext.selectItem(this.planningContext.ordersToPlan);
  }

  public openFixedSchedules(): void {
    this.planningContext.selectItem(this.planningContext.fixedSchedulesToPlan);
  }

  public onDragStart(): void {
    this.planningContext.selectItem(null);
  }

  public onDragEnd(data: DragDropData): void {
    this.planningDragDrop.onDragEnd(data);
  }

  public onDragMove(data: DragDropData): void {
    this.planningDragDrop.onDragMove(data);
  }

  public saveChanges(): void {
    if (AssertionUtils.isEmpty(this.updatedPlanningLines)) {
      return;
    }
    this.saving = true;
    this.planning
      .patchPlanningLines(this.updatedPlanningLines)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving()))
      .subscribe((ids: {[draftId: UUID]: number}) => {
        this.planningContext.updatePlanningItemIds(ids);
        this.toastHelper.showToastForObjectAction(ObjectActionType.SAVE, 'PLANNING.PLANNING', undefined);
        this.updatedPlanningLines = [];
      });
  }

  public openNewItemForm({event, rowIndex, date}: {event: PointerEvent; rowIndex: number; date: Date}): void {
    if (this.planningContext.selectedItem || date < new Date()) {
      return;
    }
    event?.stopImmediatePropagation();
    const machine = this.machines[rowIndex];
    const draftId = UuidUtils.generateV4Uuid() as UUID;
    this.planningContext.itemCreationPlaceholder = new Maintenance(
      {
        draftId,
        sequenceNumber: -1,
        minimumDuration: '01:00:00',
        earliestStartDate: date
      },
      MaintenanceType.MAINTENANCE
    );
    this.planningContext.sortedPlanningItemForecasts[draftId] = new PlanningItemForecast(draftId, date, new Date(date.getTime() + TimeUtils.HOUR_IN_MS));
    this.planningContext.machinesByPlanningItem[draftId] = machine;
    this.planningContext.selectItem(this.planningContext.itemCreationPlaceholder);
  }

  public getItemCreationPlaceholder(machine: PlanningEquipment): PlanningItem {
    if (this.planningContext.itemCreationPlaceholder && this.planningContext.machinesByPlanningItem[this.planningContext.itemCreationPlaceholder.draftId] === machine) {
      return this.planningContext.itemCreationPlaceholder;
    }
    return null;
  }

  public getShiftName(shift: Shift, regime: Regime): string {
    return `Shift: ${shift.name} ${shift.shiftStart} - ${shift.shiftEnd} (regime: ${regime.name})`;
  }

  public dateRangeChanged({startDate, endDate}: {startDate: Moment; endDate: Moment}): void {
    if (AssertionUtils.isNullOrUndefined(startDate) || AssertionUtils.isNullOrUndefined(endDate)) {
      return;
    }
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadHistoryData({startDate, endDate});
  }

  public planningItemIsFixedSchedulePlaceholder(item: PlanningItem): item is FixedSchedulePlaceholder {
    return item instanceof FixedSchedulePlaceholder;
  }

  private loadInitialData(): void {
    this.planningFilterService.filterChanged
      .pipe(
        distinctUntilChanged(ArrayUtils.haveSameContent),
        switchMap((equipmentGroupIds: number[]) =>
          combineLatest([
            this.planning.getForecast(equipmentGroupIds),
            this.planning.getProductionOrdersToPlan(equipmentGroupIds),
            this.currentSubscription?.hasPermission(Permission.FIXED_SCHEDULE_VIEW) ? this.planning.getFixedSchedulesToPlan() : of([]),
            this.planning.setFilter(new PlanningFilter(equipmentGroupIds))
          ])
        ),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(([forecast, ordersToPlan, fixedSchedulesToPlan, _]: [PlanningForecast, ProductionOrderToPlan[], FixedScheduleToPlan[], void]) => {
        this.loading = false;
        this.planningContext.init(forecast, ordersToPlan, fixedSchedulesToPlan);
        this.planningDragDrop.init(this.planningGrid, this.NEW_RUN_TEXT);
      });

    combineLatest([this.machineOverview.getRootGroup(), this.planning.getFilter()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([rootGroup, storedFilter]: [OverviewMachineGroup, PlanningFilter]) => this.planningFilterService.init(rootGroup, storedFilter));

    this.machineOverview
      .getListOfCustomSettings()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((machineOverviewSettings: PropertyValue[]) => this.initRegimes(machineOverviewSettings));
  }

  private trackUpdatedPlanningLines(): void {
    this.planningContext.planningLineChanges
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((planningLine: PlanningLine) => (this.updatedPlanningLines = ArrayUtils.distinctBy([planningLine, ...this.updatedPlanningLines], (line: PlanningLine) => line.id)));
  }

  private fetchUpdatedForecastsWhenPlanningLinesAreUpdated(): void {
    const allChanges = this.planningContext.planningLineChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy));
    allChanges
      .pipe(
        buffer(allChanges.pipe(debounceTime(10))),
        filter((planningLines: PlanningLine[]) => !AssertionUtils.isEmpty(planningLines)),
        tap((planningLines: PlanningLine[]) => planningLines.forEach((line: PlanningLine) => this.planningContext.removeEmptyItems(line.planningItems))),
        concatMap((planningLines: PlanningLine[]) => this.planning.getForecastForDraft(planningLines)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((forecasts: PlanningItemForecast[]) => this.planningContext.updateForecasts(forecasts));

    interval(this.INTERVAL_AUTO_REFRESH_FORECASTS)
      .pipe(
        filter(() => !AssertionUtils.isNullOrUndefined(this.planningContext.planningForecast)),
        switchMap(() => this.planning.getForecastForDraft(this.planningContext.planningForecast.planningLines)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((forecasts: PlanningItemForecast[]) => this.planningContext.updateForecasts(forecasts));
  }

  private applyRequiredChangesOnBackendUpdates(): void {
    const poChanges = this.realtimeProductionOrder.getProductionOrdersStatusChanges().pipe(
      takeUntil(this.unSubscribeOnViewDestroy),
      map(
        (update: ProductionOrderStatusChange) =>
          ({
            action: (): void => {
              const itemToUpdate = this.planningContext
                .getFlatListOfPlanningItems()
                .find((item: PlanningItem): item is OrderCarpet => item instanceof OrderCarpet && item.productionOrder?.id === update.id);
              if (!AssertionUtils.isNullOrUndefined(itemToUpdate)) {
                itemToUpdate.productionOrder.status = update.status;
              }

              const orderToUpdate = this.planningContext.ordersToPlan.find((order: ProductionOrderToPlan) => order.productionOrder.id === update.id && order.type === ProductionOrderType.CARPET);
              if (!AssertionUtils.isNullOrUndefined(orderToUpdate)) {
                orderToUpdate.productionOrder.status = update.status;
              }
            }
          }) as DeferredAction
      )
    );

    const poLiteChanges = this.realtimeProductionOrderLite.getProductionOrdersLiteStatusChanges().pipe(
      takeUntil(this.unSubscribeOnViewDestroy),
      map(
        (update: ProductionOrderLiteStatusChange) =>
          ({
            action: (): void => {
              const itemToUpdate = this.planningContext
                .getFlatListOfPlanningItems()
                .find((item: PlanningItem): item is PlanningOrderLite => PlanningItemHelper.isOrderLiteItem(item) && item.productionOrder?.id === update.id);
              if (!AssertionUtils.isNullOrUndefined(itemToUpdate)) {
                itemToUpdate.productionOrder.status = update.status;
              }

              const orderToUpdate = this.planningContext.ordersToPlan.find((order: ProductionOrderToPlan) => order.productionOrder.id === update.id && order.type !== ProductionOrderType.CARPET);
              if (!AssertionUtils.isNullOrUndefined(orderToUpdate)) {
                orderToUpdate.productionOrder.status = update.status;
              }
            }
          }) as DeferredAction
      )
    );

    const sequenceChanges = this.planningUpdates.itemsToPlanChanged().pipe(
      debounceTime(this.DEBOUNCE_AGGREGATE_TO_PLAN_UPDATES),
      switchMap(() => combineLatest([this.planning.getForecast(), this.planning.getProductionOrdersToPlan()])),
      takeUntil(this.unSubscribeOnViewDestroy),
      map(
        ([forecast, ordersToPlan]: [PlanningForecast, ProductionOrderToPlan[]]) =>
          ({
            action: (): void => {
              if (this.updatedPlanningLines.length > 0) {
                this.planningContext.mergeUpdatedPlanning(forecast, ordersToPlan);
              } else {
                this.planningContext.init(forecast, ordersToPlan, this.planningContext.fixedSchedulesToPlan);
              }
              this.loadHistoryData({
                startDate: moment().subtract(1, 'day').startOf('day'),
                endDate: moment()
              });
            }
          }) as DeferredAction
      )
    );

    const timing = interval(this.INTERVAL_AGGREGATE_BACKEND_UPDATES).pipe(
      takeUntil(this.unSubscribeOnViewDestroy),
      filter(() => !this.planningDragDrop.dragActive)
    );

    merge(poChanges, poLiteChanges, sequenceChanges)
      .pipe(
        buffer(timing),
        filter((actions: DeferredAction[]) => !AssertionUtils.isEmpty(actions))
      )
      .subscribe((actions: DeferredAction[]) => actions.forEach(({action}: DeferredAction) => action()));
  }

  private initRegimes(machineOverviewSettings: PropertyValue[]): void {
    const regimesValue = machineOverviewSettings.find((propertyValue: PropertyValue) => propertyValue.propertyName === 'regimes');
    if (!AssertionUtils.isNullOrUndefined(regimesValue)) {
      this.regimes = regimesValue.propertyValue;
    }
  }
}
