import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {GenericOrder} from '@domain/planning-prototype/generic-order.interface';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils, BaseComponent, DOMUtils} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';

@Component({
  selector: 'app-planning-order',
  templateUrl: './planning-order.component.html',
  styleUrls: ['./planning-order.component.scss']
})
export class PlanningOrderComponent extends BaseComponent implements OnInit {
  @Input() public order: GenericOrder | ProductionOrderToPlan;

  public shouldShowSmallInfo = false;
  private readonly SMALL_ORDER_THRESHOLD = 72;

  public constructor(private readonly element: ElementRef<HTMLElement>) {
    super();
  }

  public ngOnInit(): void {
    DOMUtils.observeResize(this.element.nativeElement)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((entries: ResizeObserverEntry[]) => {
        this.shouldShowSmallInfo = entries[0].contentRect.width < this.SMALL_ORDER_THRESHOLD;
      });
  }

  public getIconNameForProductionOrderStatus(): string {
    return ProductionScheduleStatus.getStatusIconName(this.getProductionOrderStatus());
  }

  public getProductionOrderStatus(): ProductionScheduleStatus {
    if (!AssertionUtils.isNullOrUndefined(this.order.productionOrder)) {
      return this.order.productionOrder.status;
    }
    if (this.order instanceof ProductionOrderToPlan) {
      return null;
    }
    return PlanningItemHelper.getItemStatus(this.order);
  }

  public getItemName(): string {
    if (this.order instanceof ProductionOrderToPlan) {
      return this.order.productionOrder.name;
    }
    return PlanningItemHelper.getItemName(this.order);
  }
}
