<vdw-slide-toggle
  *ngIf="isBrowserSupported()"
  class="push-notifications-toggle"
  [(checked)]="toggleValue"
  label="NOTIFICATIONS.PUSH_NOTIFICATIONS"
  [isInteractable]="!isSubscriptionProcessing"
  (afterCheckedChange)="toggleSubscription()"
></vdw-slide-toggle>
<vdw-selection-list-container
  [containerTitle]="'NOTIFICATIONS.SHOW_PRIORITY_LEVELS'"
  [selectListOptions]="data.options"
  (selectListOptionsChanged)="onFilterOptionChanged($event)"
></vdw-selection-list-container>
