import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgModule} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpNotificationsService} from '@infrastructure/http/notifications/http-notifications.service';
import {SignalrNotificationsService} from '@infrastructure/signalr/notifications/signalr-notifications.service';
import {NotificationMobileOverviewHeaderPipe} from '@presentation/pages/notifications/notification-mobile-overview-page/notification-mobile-overview-header.pipe';
import {NotificationMobileOverviewPageComponent} from '@presentation/pages/notifications/notification-mobile-overview-page/notification-mobile-overview-page.component';
import {notificationRouting} from '@presentation/pages/notifications/notifications.routing';
import {SharedModule} from '@shared/shared.module';
import {HtmlWithVariablesToTextPipeModule, SelectionListContainerModule, SelectionListModule} from '@vdw/angular-component-library';
import {NotificationListSettingsComponent} from './notification-list/notification-list-settings.component';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {NotificationMobileListFilterComponent} from './notification-mobile-overview-page/notification-mobile-list-filter/notification-mobile-list-filter.component';
import {NotificationsComponent} from './notifications.component';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationsComponent,
    NotificationListSettingsComponent,
    NotificationMobileOverviewPageComponent,
    NotificationMobileOverviewHeaderPipe,
    NotificationMobileListFilterComponent
  ],
  imports: [SharedModule, SelectionListModule, ScrollingModule, notificationRouting, HtmlWithVariablesToTextPipeModule, SelectionListContainerModule],
  exports: [NotificationsComponent, NotificationListComponent],
  providers: [HttpNotificationsService, SignalrNotificationsService, {provide: MatDialogRef, useValue: {}}]
})
export class NotificationsModule {}
