export class BackgroundJobGlobalStatusUpdate {
  private _amountOfStartedJobs: number;
  private _amountOfFailedJobs: number;
  private _amountOfCompletedJobs: number;

  public constructor(amountOfStartedJobs: number, amountOfFailedJobs: number, amountOfCompletedJobs: number) {
    this._amountOfStartedJobs = amountOfStartedJobs;
    this._amountOfFailedJobs = amountOfFailedJobs;
    this._amountOfCompletedJobs = amountOfCompletedJobs;
  }

  public static fromJSON(backgroundJobGlobalStatusUpdateJSON: any): BackgroundJobGlobalStatusUpdate {
    return new BackgroundJobGlobalStatusUpdate(
      backgroundJobGlobalStatusUpdateJSON.amountOfStartedJobs,
      backgroundJobGlobalStatusUpdateJSON.amountOfFailedJobs,
      backgroundJobGlobalStatusUpdateJSON.amountOfCompletedJobs
    );
  }

  public get amountOfStartedJobs(): number {
    return this._amountOfStartedJobs;
  }

  public get amountOfFailedJobs(): number {
    return this._amountOfFailedJobs;
  }

  public get amountOfCompletedJobs(): number {
    return this._amountOfCompletedJobs;
  }

  public get icon(): string {
    let icon = 'process';
    if (this._amountOfFailedJobs > 0) {
      icon = 'process-error';
    } else if (this._amountOfStartedJobs > 0) {
      icon = 'process-working';
    } else if (this._amountOfCompletedJobs > 0) {
      icon = 'process-done';
    }
    return icon;
  }
}
