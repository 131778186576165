<div class="account-container">
  @if (navigationContext.companies) {
    @for (company of navigationContext.companies; track company.id) {
      <div class="company-container gap-4 flex-column">
        <div class="company-name">{{ company.name }}</div>
        @for (subscription of getSubscriptions(company); track subscription.id) {
          <vdw-account-switch
            class="account-switch"
            [class.active]="isActiveSubscription(subscription.id)"
            (click)="subscriptionClicked(subscription.id, company.id)"
            [name]="subscription.name"
            [id]="subscription.id"
          ></vdw-account-switch>
        }
      </div>
    }
  } @else {
    <vdw-progress-spinner [compact]="false"></vdw-progress-spinner>
  }
</div>

<div class="button-container flex-column">
  <button mat-button (click)="profileClicked()">{{ 'PROFILE.PROFILE' | bmsTranslate }}</button>
  <button mat-button (click)="manageSubscriptionsClicked()">{{ 'AUTHENTICATION.SUBSCRIPTIONS.MANAGE_SUBSCRIPTIONS' | bmsTranslate }}</button>
  <button mat-button (click)="logoutClicked()">{{ 'AUTHENTICATION.LOGOUT' | bmsTranslate }}</button>
</div>
