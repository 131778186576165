import {IdName} from '@domain/id-name';
import {StandardSpeed} from '@domain/machine/standard-speed.enum';
import {AssertionUtils, Color} from '@vdw/angular-component-library';
import {UnitOfMeasurement} from './unit-of-measurement.enum';

export class PlasticProduct {
  private _id: number;
  private _name: string;
  private _unitOfMeasurement: UnitOfMeasurement;
  private _weightInKg: number;
  private _standardEfficiency: number;
  private _standardCycleTimeInSeconds: number;
  private _speedUnit: StandardSpeed;
  private _description: string;
  private _mountTimeInMinutes: number;
  private _dismountTimeInMinutes: number;
  private _loadTimeInMinutes: number;
  private _unloadTimeInMinutes: number;
  private _productGroup: IdName;
  private _material: IdName;
  private _color: Color;
  private _customer: IdName;

  public constructor(
    id: number,
    name: string,
    unitOfMeasurement: UnitOfMeasurement,
    weightInKg: number,
    standardEfficiency: number,
    standardCycleTimeInSeconds: number,
    speedUnit: StandardSpeed,
    description: string,
    mountTimeInMinutes: number,
    dismountTimeInMinutes: number,
    loadTimeInMinutes: number,
    unloadTimeInMinutes: number,
    productGroup?: IdName,
    material?: IdName,
    color?: Color,
    customer?: IdName
  ) {
    this._id = id;
    this._name = name;
    this._unitOfMeasurement = unitOfMeasurement;
    this._weightInKg = weightInKg;
    this._standardEfficiency = standardEfficiency;
    this._standardCycleTimeInSeconds = standardCycleTimeInSeconds;
    this._speedUnit = speedUnit;
    this._description = description;
    this._mountTimeInMinutes = mountTimeInMinutes;
    this._dismountTimeInMinutes = dismountTimeInMinutes;
    this._loadTimeInMinutes = loadTimeInMinutes;
    this._unloadTimeInMinutes = unloadTimeInMinutes;
    this._productGroup = productGroup;
    this._material = material;
    this._color = color;
    this._customer = customer;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get unitOfMeasurement(): UnitOfMeasurement {
    return this._unitOfMeasurement;
  }

  public set unitOfMeasurement(value: UnitOfMeasurement) {
    this._unitOfMeasurement = value;
  }

  public get weightInKg(): number {
    return this._weightInKg;
  }

  public set weightInKg(value: number) {
    this._weightInKg = value;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public set standardEfficiency(value: number) {
    this._standardEfficiency = value;
  }

  public get standardCycleTimeInSeconds(): number {
    return this._standardCycleTimeInSeconds;
  }

  public set standardCycleTimeInSeconds(value: number) {
    this._standardCycleTimeInSeconds = value;
  }

  public get speedUnit(): StandardSpeed {
    return this._speedUnit;
  }

  public set speedUnit(value: StandardSpeed) {
    this._speedUnit = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get mountTimeInMinutes(): number {
    return this._mountTimeInMinutes;
  }

  public set mountTimeInMinutes(value: number) {
    this._mountTimeInMinutes = value;
  }

  public get dismountTimeInMinutes(): number {
    return this._dismountTimeInMinutes;
  }

  public set dismountTimeInMinutes(value: number) {
    this._dismountTimeInMinutes = value;
  }

  public get loadTimeInMinutes(): number {
    return this._loadTimeInMinutes;
  }

  public set loadTimeInMinutes(value: number) {
    this._loadTimeInMinutes = value;
  }

  public get unloadTimeInMinutes(): number {
    return this._unloadTimeInMinutes;
  }

  public set unloadTimeInMinutes(value: number) {
    this._unloadTimeInMinutes = value;
  }

  public get productGroup(): IdName {
    return this._productGroup;
  }

  public set productGroup(value: IdName) {
    this._productGroup = value;
  }

  public get material(): IdName {
    return this._material;
  }

  public set material(value: IdName) {
    this._material = value;
  }

  public get color(): Color {
    return this._color;
  }

  public set color(value: Color) {
    this._color = value;
  }

  public get customer(): IdName {
    return this._customer;
  }

  public set customer(value: IdName) {
    this._customer = value;
  }

  public static fromJSON(plasticProductJSON: any): PlasticProduct {
    return new PlasticProduct(
      plasticProductJSON.id,
      plasticProductJSON.name,
      plasticProductJSON.unitOfMeasurement,
      plasticProductJSON.weightInKg,
      plasticProductJSON.standardEfficiency,
      plasticProductJSON.standardCycleTimeInSeconds,
      plasticProductJSON.speedUnit,
      plasticProductJSON.description,
      plasticProductJSON.mountTimeInMinutes,
      plasticProductJSON.dismountTimeInMinutes,
      plasticProductJSON.loadTimeInMinutes,
      plasticProductJSON.unloadTimeInMinutes,
      AssertionUtils.isNullOrUndefined(plasticProductJSON.productGroup) ? null : IdName.fromJSON(plasticProductJSON.productGroup),
      AssertionUtils.isNullOrUndefined(plasticProductJSON.material) ? null : IdName.fromJSON(plasticProductJSON.material),
      AssertionUtils.isNullOrUndefined(plasticProductJSON.color) ? null : Color.fromJSON(plasticProductJSON.color),
      AssertionUtils.isNullOrUndefined(plasticProductJSON.customer) ? null : IdName.fromJSON(plasticProductJSON.customer)
    );
  }

  public toJSON(): JSON {
    return {
      name: this._name,
      unitOfMeasurement: this._unitOfMeasurement,
      weightInKg: this._weightInKg,
      standardEfficiency: this._standardEfficiency,
      standardCycleTimeInSeconds: this._standardCycleTimeInSeconds,
      speedUnit: this._speedUnit,
      description: this._description,
      mountTimeInMinutes: this._mountTimeInMinutes,
      dismountTimeInMinutes: this._dismountTimeInMinutes,
      loadTimeInMinutes: this._loadTimeInMinutes,
      unloadTimeInMinutes: this._unloadTimeInMinutes,
      productGroupId: this._productGroup?.id,
      materialId: this._material?.id,
      colorId: this._color?.id,
      customerId: this._customer?.id
    } as any as JSON;
  }
}
