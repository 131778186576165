export class AssertionUtils {
  public static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  public static isNullOrWhitespace(value: string): boolean {
    return AssertionUtils.isEmpty(value?.trim());
  }

  public static isEmpty(value: any[] | string | undefined): boolean {
    return !(value?.length > 0);
  }

  public static isString(value: any): value is string {
    return typeof value === 'string';
  }

  public static isNumber(value: any): value is number {
    return typeof value === 'number';
  }

  public static datesAreEqual(a: Date, b: Date): boolean {
    return a?.getTime() === b?.getTime();
  }
}
