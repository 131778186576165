import {MobileNavigationBarItem, NavigationBarMobileItemProperties} from './mobile-navigation-bar-item';

export interface MobileNavigationBarExternalLinkItemProperties extends NavigationBarMobileItemProperties {
  link: string;
}

export class MobileNavigationBarExternalLinkItem extends MobileNavigationBarItem {
  private _link: string;

  public constructor(properties: MobileNavigationBarExternalLinkItemProperties) {
    const {link, ...navigationBarItemProperties} = properties;

    super(navigationBarItemProperties);
    this._link = link;
  }

  public get path(): string {
    return this._link;
  }
}
