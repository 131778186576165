import {Pattern} from '@domain/pattern';

export class WeavePattern {
  private _id: number;
  private readonly _pattern: Pattern;
  private readonly _patternNumber: number;
  private readonly _patternTemplate: number;
  private readonly _type: string;
  private readonly _comment: string;
  private readonly _weavingChores: number[];
  private readonly _widthInDents: number;
  private readonly _widthInFrames: number;
  private readonly _heightInPicks: number;

  public constructor(
    id: number,
    pattern: Pattern,
    patternNumber: number,
    patternTemplate: number,
    type: string,
    comment: string,
    weavingChores?: number[],
    widthInDents?: number,
    widthInFrames?: number,
    heightInPicks?: number
  ) {
    this._id = id;
    this._pattern = pattern;
    this._patternNumber = patternNumber;
    this._patternTemplate = patternTemplate;
    this._type = type;
    this._comment = comment;
    this._weavingChores = weavingChores;
    this._widthInDents = widthInDents;
    this._widthInFrames = widthInFrames;
    this._heightInPicks = heightInPicks;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get id(): number {
    return this._id;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public get patternNumber(): number {
    return this._patternNumber;
  }

  public get patternTemplate(): number {
    return this._patternTemplate;
  }

  public get type(): string {
    return this._type;
  }

  public get comment(): string {
    return this._comment;
  }

  public get weavingChores(): number[] {
    return this._weavingChores;
  }

  public get widthInDents(): number {
    return this._widthInDents;
  }

  public get widthInFrames(): number {
    return this._widthInFrames;
  }

  public get heightInPicks(): number {
    return this._heightInPicks;
  }

  public static fromJSON(weavePatternJSON: any): WeavePattern {
    return new WeavePattern(
      weavePatternJSON.id,
      weavePatternJSON.pattern ? Pattern.fromJSON(weavePatternJSON.pattern) : null,
      weavePatternJSON.patternNumber,
      weavePatternJSON.patternTemplate,
      weavePatternJSON.type,
      weavePatternJSON.comment,
      weavePatternJSON.weavingChores?.length > 0 ? weavePatternJSON.weavingChores : null,
      weavePatternJSON.widthInDents,
      weavePatternJSON.widthInFrames,
      weavePatternJSON.heightInPicks
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      pattern: this.pattern.toJSON(),
      patternNumber: this.patternNumber,
      patternTemplate: this.patternTemplate,
      type: this.type,
      comment: this.comment
    } as any as JSON;
  }
}
