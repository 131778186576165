import {NgModule} from '@angular/core';
import {BreakpointResponsivenessViewModeService} from './breakpoint-responsiveness-view-mode.service';
import {ResponsiveClassDirective} from './responsive-class.directive';
import {RESPONSIVENESS_VIEW_MODE} from './responsiveness-view.mode';

@NgModule({
  declarations: [ResponsiveClassDirective],
  exports: [ResponsiveClassDirective],
  providers: [{provide: RESPONSIVENESS_VIEW_MODE, useClass: BreakpointResponsivenessViewModeService}]
})
export class ResponsivenessModule {}
