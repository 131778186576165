import {Pipe, PipeTransform} from '@angular/core';
import {moment} from '@vdw/angular-component-library';
import {isNaN} from 'lodash-es';
import {unitOfTime} from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {
  public transform(time: number, timeUnit: unitOfTime.DurationConstructor, format: string): string {
    let result = '';

    if (!isNaN(time)) {
      result = moment.duration(time, timeUnit).format(format, {trim: 'both'});
    }

    return result;
  }
}
