<div class="container flex-column">
  <div *ngFor="let color of colors; trackBy: trackByColorId" class="flex-row inside-container gap-12">
    <div [matTooltip]="getTooltipForColor(color)" class="color-preview" [style.background-color]="color?.hexadecimalColorCode"></div>
    <div class="name-width flex-row gap-16">
      <vdw-ellipsis-label
        *ngIf="showLabel"
        [text]="color?.name"
        matTooltipPosition="above"
        [tooltipDisabled]="tooltipDisabled"
        class="color-name min-width-0"
        [class.color-name-width-with-rgb-values]="showRgbValues"
      >
      </vdw-ellipsis-label>
      <span *ngIf="showRgbValues" class="rgb">{{ color?.getRgbAsCommaSeparatedValue() }}</span>
    </div>
  </div>
</div>
