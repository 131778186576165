import {AbstractControl} from '@angular/forms';

export function ipAddressValidator(control: AbstractControl): {[key: string]: boolean} {
  let result = null;

  if (control.value === '' || control.value === null || control.value === undefined) {
    return result;
  } else if (!/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(control.value)) {
    result = {invalidIpAddress: true};
  }

  return result;
}
