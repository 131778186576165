import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {CalculatedYarnConsumption} from '@domain/yarn-consumption/calculated-yarn-consumption';
import {CalculatedYarnConsumptionForProductionOrder} from '@domain/yarn-consumption/calculated-yarn-consumption-for-production-order';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {merge, Observable} from 'rxjs';
import {RealtimeYarnConsumption} from './realtime-yarn-consumption';

@Injectable()
export class SignalrYarnConsumptionService implements RealtimeYarnConsumption {
  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public getCalculatedYarnConsumption(): Observable<CalculatedYarnConsumption> {
    return merge(
      this.signalrHubConnectionFactoryService.getSignalrHubEvents<CalculatedYarnConsumption>(
        `${this.backendConfiguration.getProcessingHubEndPoint()}processing`,
        'OnYarnConsumptionOrderLineCalculated'
      ),
      this.signalrHubConnectionFactoryService.getSignalrHubEvents<CalculatedYarnConsumption>(`${this.backendConfiguration.getProcessingHubEndPoint()}processing`, 'OnYarnConsumptionDesignCalculated')
    );
  }

  public getCalculatedYarnConsumptionForProductionOrder(): Observable<CalculatedYarnConsumptionForProductionOrder> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<CalculatedYarnConsumptionForProductionOrder>(
      `${this.backendConfiguration.getProcessingHubEndPoint()}processing`,
      'OnYarnConsumptionProductionOrderCalculated'
    );
  }

  public getSingleBmpProcessed(): Observable<any> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<any>(`${this.backendConfiguration.getTexCatalogHubEndPoint()}texcatalog`, 'OnSingleBmpProcessed');
  }
}
