import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {IdName} from '@domain/id-name';
import {IdNameVersion} from '@domain/id-name-version';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {TuftProductionOrder} from '@domain/texfab/tuft-production-order/tuft-production-order';
import {TuftProduct} from '@domain/textile-data/tuft-product/tuft-product';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {MACHINES, Machines} from '@infrastructure/http/machine/machines.interface';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {HttpTuftProductsService} from '@infrastructure/http/tuft-product/http-tuft-products.service';
import {GetAllTuftProductsParameters} from '@infrastructure/http/tuft-product/tuft-products.interface';
import {HttpTuftProductionOrdersService} from '@infrastructure/http/tuft-production-order/http-tuft-production-orders.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  canShowErrorForErrorCodeAndButtonForFormGroup,
  DatepickerHeaderComponent,
  DialogBuilderFactoryService,
  DialogOptions,
  DialogType,
  FormValidationHelper,
  ObjectUtils,
  SaveType,
  skeletonViewAnimation,
  ToastService,
  TranslateService
} from '@vdw/angular-component-library';
import {forkJoin} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {SelectMachineComponent} from '../../production-schedule/add/initial-preparations-new/select-machine/select-machine.component';
import {OverviewListTuftingMachine} from './../overview/overview-list-tufting-machine';
import {NavigationTuftProductionOrderData} from './navigation-tuft-production-order-data.interface';
import {SelectTuftProductComponent} from './select-tuft-product/select-tuft-product.component';

@Component({
  templateUrl: './add-tuft-production-order-page.component.html',
  styleUrls: ['./add-tuft-production-order-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .button-skeleton-wrapper, .mat-expansion-panel, .checkbox-skeleton, .text-skeleton-wrapper, .thread-item header')]
})
export class AddTuftProductionOrderPageComponent extends BaseComponent implements OnInit {
  public addTuftProductionOrderForm: UntypedFormGroup;
  public translationKeyForProductionOrder = 'PRODUCTION_ORDER.PRODUCTION_ORDER';
  public suggestedTuftProductionOrderName: string;

  public readonly datepickerHeader = DatepickerHeaderComponent;
  public readonly MACHINE_BASE_ROUTER_LINK = RouteUtils.paths.texState.editMachine.absolutePath;
  public readonly TUFT_PRODUCT_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.tuftProduct.editTuftProduct.absolutePath;

  private saveButtonTouched = false;
  private selectedMachineId: number;
  private selectedTuftProductId: number;
  private tuftProductionOrderToSave: TuftProductionOrder;
  private status: ProductionScheduleStatus;
  private runId: number;
  private currentSubscription: Subscription;
  private hasTuftProductionOrderState = false;

  private readonly URL_PRODUCTION_ORDER_OVERVIEW = NavigationUtils.getAbsolutePath(NavigationId.TUFT_ORDER);
  private readonly URL_EDIT_TUFT_PRODUCTION_ORDER = NavigationUtils.getRoute(NavigationId.EDIT_TUFT_ORDER);
  private readonly URL_DUPLICATE_TUFT_PRODUCTION_ORDER = NavigationUtils.getRoute(NavigationId.DUPLICATE_TUFT_ORDER);
  private readonly QUEUE_PERMISSION = Permission.TEXFAB_TUFT_QUEUE;
  private readonly EDIT_PERMISSION = Permission.TEXFAB_TUFT_ORDER_EDIT;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_TUFT_PRODUCTION_ORDER;
  public readonly SAVE = SaveType.SAVE;
  public readonly SAVE_AND_CLOSE = SaveType.SAVE_AND_CLOSE;
  public readonly SAVE_AND_CREATE_NEW = SaveType.SAVE_AND_CREATE_NEW;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    private readonly navigationHistory: NavigationHistoryService,
    @Inject(MACHINES) private readonly machines: Machines,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly tuftProductionOrders: HttpTuftProductionOrdersService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly toast: ToastService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly tuftProducts: HttpTuftProductsService,
    private readonly navigationHelperService: NavigationHelperService<NavigationTuftProductionOrderData>,
    private readonly textileService: TextileService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.tuftProductionOrderToSave = TuftProductionOrder.createEmptyTuftProductionOrder();
    this.setTuftProductionOrderForm();

    const emptyTuftProductionOrderState = {tuftProductionOrder: null, runId: null} as NavigationTuftProductionOrderData;
    const tuftProductionOrderState = this.navigationHelperService.getPartialState<NavigationTuftProductionOrderData>(Object.keys(emptyTuftProductionOrderState));

    if (!AssertionUtils.isNullOrUndefined(tuftProductionOrderState) && !ObjectUtils.isDeepEqual(tuftProductionOrderState, emptyTuftProductionOrderState)) {
      this.hasTuftProductionOrderState = true;
      this.runId = tuftProductionOrderState.runId;
      this.setTuftProductionOrder(tuftProductionOrderState.tuftProductionOrder);
    } else if (this.isEditingTuftProductionOrder() || this.isDuplicatingTuftProductionOrder()) {
      this.tuftProductionOrders
        .getById(Number(this.activatedRoute.snapshot.params.id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (tuftProductionOrder: TuftProductionOrder) => {
            this.setTuftProductionOrder(tuftProductionOrder);
          },
          error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.URL_PRODUCTION_ORDER_OVERVIEW)
        });
    }

    if (this.isAddingProductionOrderForRun()) {
      const {runId, tuftProductId, machineId} = this.navigationHistory.getState() as {
        runId: number;
        tuftProductId: number;
        machineId: number;
      };
      this.initializeForRun(runId, tuftProductId, machineId);
    } else if (this.isAddingProductionOrderForMachine()) {
      this.initializeForMachine(Number(this.activatedRoute.snapshot.params.id));
    }

    this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
      ({newItemId}: NavigationNewItemData) => this.setTuftProduct(newItemId),
      () => this.selectTuftProduct()
    );
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationTuftProductionOrderData>({tuftProductionOrder: this.getCurrentTuftProductionOrder(), runId: this.runId});
  }

  public selectMachine(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectMachineComponent, {
        allowedMachineTypes: [MachineType.TUFT],
        tuftProductId: this.selectedTuftProductId
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((selectedMachine: OverviewListTuftingMachine) => {
        if (!AssertionUtils.isNullOrUndefined(selectedMachine)) {
          this.addTuftProductionOrderForm.get('machine').setValue(selectedMachine);
        }
      });
  }

  public selectTuftProduct(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectTuftProductComponent, {machineId: this.selectedMachineId})
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((selectedTuftProduct: OverviewListTuftProduct) => {
        if (!!selectedTuftProduct) {
          this.addTuftProductionOrderForm.get('tuftProduct').setValue(new IdNameVersion(selectedTuftProduct.id, selectedTuftProduct.name, selectedTuftProduct.version));
        }
      });
  }

  public getEarliestPossibleDueDate(): Date {
    return new Date();
  }

  public canShowErrorForButton(errorCode: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndButtonForFormGroup(errorCode, formControlName, this.addTuftProductionOrderForm);
  }

  public cancel(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.URL_PRODUCTION_ORDER_OVERVIEW);
  }

  public saveTuftProductionOrder(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.addTuftProductionOrderForm], this.document);

    if (isValid) {
      this.tuftProductionOrderToSave = this.getCurrentTuftProductionOrder();
      this.saving = true;
      this.isEditingTuftProductionOrder() ? this.editTuftProductionOrder(saveType) : this.addTuftProductionOrder(saveType);
    }
  }

  public duplicateTuftProductionOrder(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.DUPLICATE_TUFT_ORDER).replace(':id', this.activatedRoute.snapshot.params.id));
  }

  public deleteTuftProductionOrder(): void {
    this.textileService.removeConfirmation(this.getCurrentTuftProductionOrder(), TextileDataType.TUFT_PRODUCTION_ORDER, false, null, this.tuftProductionOrders);
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = Object.values(this.addTuftProductionOrderForm.controls).some((control: UntypedFormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public getTitle(): string {
    return this.addTuftProductionOrderForm.value.name;
  }

  public getActionText(): string {
    return this.translate.instant(this.isEditingTuftProductionOrder() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', {
      object: this.translate.instant(this.translationKeyForProductionOrder, {count: 1}).toLowerCase()
    });
  }

  public hasPermissionToEditTuftProductionOrder(): boolean {
    return this.currentSubscription?.hasPermission(this.EDIT_PERMISSION);
  }

  public isEditingTuftProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.URL_EDIT_TUFT_PRODUCTION_ORDER;
  }

  public canDisableForm(): boolean {
    return this.isEditingTuftProductionOrder() && (!this.currentSubscription?.hasPermission(this.EDIT_PERMISSION) || this.status !== ProductionScheduleStatus.TO_PLAN);
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.TUFT_ORDER_SETTINGS));
  }

  public canShowSuggestionForTuftProductionOrderName(): boolean {
    return this.suggestedTuftProductionOrderName?.length > 0 && this.addTuftProductionOrderForm.get('name').value !== this.suggestedTuftProductionOrderName;
  }

  public applySuggestionForTuftProductionOrderName(): void {
    this.addTuftProductionOrderForm.get('name').setValue(this.suggestedTuftProductionOrderName);
  }

  private setTuftProductionOrder(tuftProductionOrder: TuftProductionOrder): void {
    this.status = tuftProductionOrder.status;
    this.tuftProductionOrderToSave = tuftProductionOrder;
    this.tuftProductionOrderToSave.name = this.isDuplicatingTuftProductionOrder() ? null : this.tuftProductionOrderToSave.name;
    this.tuftProductionOrderToSave.machine = tuftProductionOrder.machine
      ? new OverviewListTuftingMachine(MachineType.TUFT, this.tuftProductionOrderToSave.machine.id, this.tuftProductionOrderToSave.machine.name)
      : null;

    this.setFormValues();
  }

  private setFormValues(): void {
    let dueDate = this.tuftProductionOrderToSave.dueDate;
    if (this.canDisableForm()) {
      this.addTuftProductionOrderForm.disable();
    } else if (!AssertionUtils.isNullOrUndefined(dueDate) && dueDate <= this.getEarliestPossibleDueDate()) {
      dueDate = null;
    }

    this.addTuftProductionOrderForm.reset({
      name: this.tuftProductionOrderToSave.name,
      tuftProduct: this.tuftProductionOrderToSave.tuftProduct,
      machine: this.hasQueuePermission() ? this.tuftProductionOrderToSave.machine : null,
      plannedQuantityInMeters: this.tuftProductionOrderToSave.plannedQuantityInMeters,
      dueDate: dueDate,
      tuftingNotes: this.tuftProductionOrderToSave.tuftingNotes
    });

    this.addTuftProductionOrderForm.controls.name.setAsyncValidators(AsyncUniqueValidator.createValidator(this.productionSchedules, this.tuftProductionOrderToSave.name));
    this.addTuftProductionOrderForm.controls.name.updateValueAndValidity();
  }

  private initializeForRun(runId: number, tuftProductId: number, machineId: number): void {
    if (this.hasTuftProductionOrderState) {
      this.addTuftProductionOrderForm.get('tuftProduct').disable();
      this.addTuftProductionOrderForm.get('machine').disable();
      this.selectedTuftProductId = this.addTuftProductionOrderForm.get('tuftProduct').value.id;
    } else if (AssertionUtils.isNullOrUndefined(runId) || AssertionUtils.isNullOrUndefined(tuftProductId) || AssertionUtils.isNullOrUndefined(machineId)) {
      this.navigateToPlanning();
    } else {
      this.runId = runId;
      forkJoin([this.tuftProducts.getById(tuftProductId), this.machines.getSummaryForMachine(machineId)])
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(([tuftProductDetails, machineSummary]: [TuftProduct, MachineSummary]) => {
          const tuftProduct = new IdNameVersion(tuftProductDetails.id, tuftProductDetails.name, tuftProductDetails.version);
          const machine = OverviewListTuftingMachine.fromMachineSummary(machineSummary);
          const tuftProductControl = this.addTuftProductionOrderForm.get('tuftProduct');
          const machineControl = this.addTuftProductionOrderForm.get('machine');
          tuftProductControl.setValue(tuftProduct);
          tuftProductControl.disable({emitEvent: true});
          machineControl.setValue(machine);
          machineControl.disable({emitEvent: true});

          this.selectedTuftProductId = tuftProductId;
        });
    }
  }

  private initializeForMachine(machineId: number): void {
    if (isNaN(machineId)) {
      return;
    }

    if (this.hasTuftProductionOrderState) {
      this.addTuftProductionOrderForm.get('machine').disable();
    } else {
      this.machines
        .getSummaryForMachine(machineId)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((machineSummary: MachineSummary) => {
          const machine = OverviewListTuftingMachine.fromMachineSummary(machineSummary);
          const machineControl = this.addTuftProductionOrderForm.get('machine');
          machineControl.setValue(machine);
          machineControl.disable({emitEvent: true});
        });
    }
  }

  private addTuftProductionOrder(saveType: SaveType): void {
    const action = this.canQueue() ? this.tuftProductionOrders.saveAndPlan(this.tuftProductionOrderToSave, this.runId) : this.tuftProductionOrders.save(this.tuftProductionOrderToSave);

    action.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
      next: (id: number) =>
        this.textileService.navigateAndShowToast(saveType, TextileDataType.TUFT_PRODUCTION_ORDER, this.translationKeyForProductionOrder, false, this.tuftProductionOrderToSave.name, id),
      error: (backendError: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.CREATE_OBJECT', backendError)
    });
  }

  private editTuftProductionOrder(saveType: SaveType): void {
    const action = this.canQueue() ? this.tuftProductionOrders.updateAndPlan(this.tuftProductionOrderToSave, this.runId) : this.tuftProductionOrders.update(this.tuftProductionOrderToSave);

    action.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
      next: () => this.textileService.navigateAndShowToast(saveType, TextileDataType.TUFT_PRODUCTION_ORDER, this.translationKeyForProductionOrder, true, this.tuftProductionOrderToSave.name),
      error: (backendError: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.EDIT_OBJECT', backendError)
    });
  }

  private showErrorDialogForBackendError(action: string, error: BackendError): void {
    if (Number(BackendErrorCodeEnum[error.errorContext?.errorCode]) === BackendErrorCodeEnum.LINKED_ENTITIES) {
      this.showProductionOrderIncompatibleWithMachineAlert(error);
    } else {
      const messageText = error.message;
      const titleText = this.translate.instant(action, {
        object: this.translate.instant(this.translationKeyForProductionOrder, {count: 1})
      });

      this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
        titleText,
        messageText,
        type: DialogType.INFORMATION
      } as DialogOptions);
    }
  }

  private showProductionOrderIncompatibleWithMachineAlert(error: BackendError): void {
    const productionOrderName = this.getPropertyValue('name');
    const machineName = this.getPropertyValue('machine').name;
    const INCOMPATIBLE_MESSAGE_KEY = 'PLANNING.ERRORS.INCOMPATIBLE_PRODUCTION_ORDER';
    const translationParams = {
      productionOrderName,
      machineName,
      amountOfCompatibleMachines: error.errorContext.linkedEntityNames?.length ?? 0
    };

    if (translationParams.amountOfCompatibleMachines > 0) {
      translationParams['compatibleMachinesList'] = error.errorContext.linkedEntityNames.join(', ');
    }

    const dialogResult = this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant('PLANNING.ERRORS.INCOMPATIBLE_ENTITY', {entity: (this.translate.instant('PRODUCTION_ORDER.PRODUCTION_ORDER', {count: 1}) as string).toLowerCase()}),
      messageText: this.translate.instant(INCOMPATIBLE_MESSAGE_KEY, translationParams),
      type: DialogType.INFORMATION
    });

    if (!this.isEditingTuftProductionOrder() && !AssertionUtils.isNullOrUndefined(error.errorContext.entityId)) {
      dialogResult.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
        this.router.navigate([NavigationUtils.getAbsolutePath(NavigationId.EDIT_TUFT_ORDER).replace(':id', error.errorContext.entityId)]);
        this.showToast(productionOrderName);
      });
    }
  }

  private showToast(tuftProductionOrderName: string): void {
    this.toast.showInfo({
      tapToDismiss: false,
      timeOut: 2000,
      message: this.translate.instant(this.isEditingTuftProductionOrder() ? 'GENERAL.ACTIONS.EDITED_OBJECT' : 'GENERAL.ACTIONS.CREATED_OBJECT', {
        object: this.translate.instant(this.translationKeyForProductionOrder, {count: 1}),
        name: tuftProductionOrderName,
        count: 1
      })
    });
  }

  private getCurrentTuftProductionOrder(): TuftProductionOrder {
    const name: string = this.getPropertyValue('name');
    const tuftProduct: IdNameVersion = this.getPropertyValue('tuftProduct');
    const overviewListTuftingMachine: OverviewListTuftingMachine = this.getPropertyValue('machine');
    const plannedQuantityInMeters: number = this.getPropertyValue('plannedQuantityInMeters');
    const tuftingNotes: string = this.getPropertyValue('tuftingNotes');
    const dueDate: Date = this.getPropertyValue('dueDate');

    const machine = overviewListTuftingMachine ? new IdName(overviewListTuftingMachine.id, overviewListTuftingMachine.name) : null;

    return new TuftProductionOrder(
      this.isEditingTuftProductionOrder() ? Number(this.activatedRoute.snapshot.params.id) : null,
      name,
      tuftProduct,
      machine,
      plannedQuantityInMeters,
      tuftingNotes,
      this.isDuplicatingTuftProductionOrder() ? null : this.tuftProductionOrderToSave?.dateCreated,
      dueDate,
      this.status
    );
  }

  private setTuftProductionOrderForm(): void {
    this.addTuftProductionOrderForm = this.formBuilder.group({
      name: [null, Validators.required, AsyncUniqueValidator.createValidator(this.productionSchedules, null)],
      tuftProduct: [null, Validators.required],
      machine: [{value: null, disabled: !this.hasQueuePermission()}],
      plannedQuantityInMeters: [null, [Validators.required, Validators.min(1)]],
      dueDate: [null],
      tuftingNotes: [null]
    });

    this.addTuftProductionOrderForm.controls.machine.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.selectedMachineId = this.addTuftProductionOrderForm.controls.machine.value?.id;
      if (this.addTuftProductionOrderForm.controls.machine.valid) {
        this.getSuggestionForTuftProductionOrderName();
      }
    });

    this.addTuftProductionOrderForm.controls.tuftProduct.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.selectedTuftProductId = this.addTuftProductionOrderForm.controls.tuftProduct.value?.id;
      if (this.addTuftProductionOrderForm.controls.tuftProduct.valid) {
        this.getSuggestionForTuftProductionOrderName();
      }
    });
  }

  private navigateToPlanning(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.PLANNING));
  }

  private getPropertyValue(propertyName: string): any {
    return this.addTuftProductionOrderForm.get(propertyName).value;
  }

  private isDuplicatingTuftProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.URL_DUPLICATE_TUFT_PRODUCTION_ORDER;
  }

  private hasChosenProperty(propertyName: string): boolean {
    return !AssertionUtils.isNullOrUndefined(this.getPropertyValue(propertyName));
  }

  private isAddingProductionOrderForRun(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path.endsWith(NavigationUtils.getRoute(NavigationId.ADD_TUFT_ORDER_RUN));
  }

  private canQueue(): boolean {
    return this.hasQueuePermission() && this.hasChosenProperty('machine');
  }

  private hasQueuePermission(): boolean {
    return this.currentSubscription?.hasPermission(this.QUEUE_PERMISSION);
  }

  private isAddingProductionOrderForMachine(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path.endsWith(NavigationUtils.getRoute(NavigationId.ADD_TUFT_ORDER_MACHINE));
  }

  private setTuftProduct(id: number): void {
    const parameters: Partial<GetAllTuftProductsParameters> = {showOnlyLatestVersion: true};
    const request = AssertionUtils.isNullOrUndefined(this.selectedMachineId) ? this.tuftProducts.getAll(parameters) : this.tuftProducts.getAllForMachineId(this.selectedMachineId, parameters);
    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((tuftProducts: OverviewListTuftProduct[]) => {
      const newTuftProduct = tuftProducts.find((tuftProduct: OverviewListTuftProduct) => tuftProduct.id === id);
      this.addTuftProductionOrderForm.get('tuftProduct').setValue(new IdNameVersion(newTuftProduct.id, newTuftProduct.name, newTuftProduct.version));
    });
  }

  private getSuggestionForTuftProductionOrderName(): void {
    if (this.addTuftProductionOrderForm.controls.machine.value && this.addTuftProductionOrderForm.controls.tuftProduct.value && this.currentSubscription?.hasPermission(this.EDIT_PERMISSION)) {
      this.tuftProductionOrders
        .generateName(this.getCurrentTuftProductionOrder())
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((name: string) => (this.suggestedTuftProductionOrderName = name));
    }
  }
}
