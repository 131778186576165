import {Permission} from '@domain/profile/permission.enum';
import {OnboardingNavigationStep} from '@presentation/components/onboarding-dialog/onboarding-navigation-step.enum';
import {Observable} from 'rxjs';
import {MobileNavigationBarItemMobilePosition} from './mobile-navigation-bar-item-mobile-position.enum';
import {MobileNavigationBarItemPosition} from './mobile-navigation-bar-item-position.enum';
import {MobileNavigationBarItemSize} from './mobile-navigation-bar-item-size.enum';

export interface NavigationBarMobileItemProperties {
  name: string;
  icon: string;
  position: MobileNavigationBarItemPosition;
  mobilePositions: MobileNavigationBarItemMobilePosition[];
  requiredPermission: Permission;
  id: string;
  onboardingStep?: OnboardingNavigationStep;
  badge?: Observable<number>;
  size?: MobileNavigationBarItemSize;
}

export abstract class MobileNavigationBarItem {
  private _requiredPermission: Permission;
  private _name: string;
  private _icon: string;
  private _size: MobileNavigationBarItemSize;
  private _mobileFriendly: boolean;
  private _mobileOnly: boolean;
  private _id: string;
  private _onboardingStep: OnboardingNavigationStep;
  private _position: MobileNavigationBarItemPosition;
  private _mobilePositions: MobileNavigationBarItemMobilePosition[];
  private _badge: Observable<number>;

  public constructor(properties: NavigationBarMobileItemProperties) {
    const {name, icon, position, mobilePositions, requiredPermission, id, onboardingStep, badge, size} = properties;

    this._name = name;
    this._icon = icon;
    this._id = id;
    this._position = position;
    this._requiredPermission = requiredPermission;
    this._mobilePositions = mobilePositions;
    this._onboardingStep = onboardingStep;
    this._badge = badge;
    this._size = size ?? MobileNavigationBarItemSize.NORMAL;
  }

  public get requiredPermission(): Permission {
    return this._requiredPermission;
  }

  public get name(): string {
    return this._name;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(icon: string) {
    this._icon = icon;
  }

  public get size(): MobileNavigationBarItemSize {
    return this._size;
  }

  public set size(iconSize: MobileNavigationBarItemSize) {
    this._size = iconSize;
  }

  public get mobileFriendly(): boolean {
    return this._mobileFriendly;
  }

  public get mobileOnly(): boolean {
    return this._mobileOnly;
  }

  public get id(): string {
    return this._id;
  }

  public get onboardingStep(): OnboardingNavigationStep {
    return this._onboardingStep;
  }

  public get position(): MobileNavigationBarItemPosition {
    return this._position;
  }

  public get mobilePositions(): MobileNavigationBarItemMobilePosition[] {
    return this._mobilePositions;
  }

  public get badge(): Observable<number> {
    return this._badge;
  }
}
