import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {max} from 'lodash-es';
import {Amount} from './amount';

@Component({
  templateUrl: './conflicts-with-order-lines-amount.component.html',
  styleUrls: ['./conflicts-with-order-lines-amount.component.scss']
})
export class ConflictsWithOrderLinesAmountComponent implements ICellRendererAngularComp, AfterViewInit {
  @ViewChild('requested') public requested: ElementRef;
  @ViewChild('currently') public currently: ElementRef;
  @ViewChild('planned') public planned: ElementRef;
  @ViewChild('tooMuch') public tooMuch: ElementRef;
  public amount: Amount;
  private renderer: Renderer2;

  public constructor(renderer: Renderer2) {
    this.renderer = renderer;
  }

  public ngAfterViewInit(): void {
    this.setLabelsWidth();
  }

  public agInit(params: ICellRendererParams): void {
    this.amount = {
      requested: params.value[0],
      inPlan: params.value[1],
      planned: params.value[2],
      tooMuch: params.value[3]
    };
  }

  public refresh(params: any): boolean {
    return false;
  }

  private setLabelsWidth(): void {
    const labelWidths = [this.requested.nativeElement.clientWidth, this.currently.nativeElement.clientWidth, this.planned.nativeElement.clientWidth, this.tooMuch.nativeElement.clientWidth];
    const biggestLabelWidth = max(labelWidths);
    this.renderer.setStyle(this.requested.nativeElement, 'min-width', `${biggestLabelWidth}px`);
    this.renderer.setStyle(this.currently.nativeElement, 'min-width', `${biggestLabelWidth}px`);
    this.renderer.setStyle(this.planned.nativeElement, 'min-width', `${biggestLabelWidth}px`);
    this.renderer.setStyle(this.tooMuch.nativeElement, 'min-width', `${biggestLabelWidth}px`);
  }
}
