import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[vdwElementPressed]'
})
export class ElementPressedDirective {
  private isPressed = false;

  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @HostListener('mousedown')
  public onMouseDown(): void {
    this.isPressed = true;
    this.elementRef.nativeElement.classList.add('pressed');
  }

  @HostListener('window:mouseup')
  public onMouseUp(): void {
    if (this.isPressed) {
      this.isPressed = false;
      this.elementRef.nativeElement.classList.remove('pressed');
    }
  }
}
