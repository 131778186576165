export class IdName {
  protected _id: number;
  protected _name: string;

  public constructor(id: number, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(object: any): IdName {
    return new IdName(object.id, object.name);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name
    } as any as JSON;
  }
}
