import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'capitalFirstLetter'})
export class CapitalFirstLetterPipe implements PipeTransform {
  public transform(str: string): string {
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str;
  }
}
