import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ProfileRoute extends RouteBranch {
  private _changelogMobile: RouteLeaf;

  public get changelogMobile(): RouteLeaf {
    return this._changelogMobile;
  }

  public set changelogMobile(value: RouteLeaf) {
    this.replace(this._changelogMobile, value);
    this._changelogMobile = value;
  }
}
