import {MachineGroup} from '@domain/machine/machine-group';
import {MachineType} from '@domain/machine/machine-type.enum';
import {OverviewMachineCreel} from '@presentation/pages/machine-overview/overview-machine-creel';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {Message, StringUtils} from '@vdw/angular-component-library';

export interface PlanningMachineProperties {
  id: number;
  name: string;
  machineType: MachineType;
  group: MachineGroup;
  activeQuality: OverviewListMachineQuality;
  activeCreel: OverviewMachineCreel;
  activeWeaveProduct: OverviewListWeaveProduct;
  activeTuftProduct: OverviewListTuftProduct;
  messages: Message[];
}

export class PlanningMachine {
  private _id: number;
  private _name: string;
  private _machineType: MachineType;
  private _group: MachineGroup;
  private _activeQuality: OverviewListMachineQuality;
  private _activeCreel: OverviewMachineCreel;
  private _activeWeaveProduct: OverviewListWeaveProduct;
  private _activeTuftProduct: OverviewListTuftProduct;
  private _messages: Message[];

  public constructor(values: PlanningMachineProperties) {
    this._id = values.id;
    this._name = values.name;
    this._machineType = values.machineType;
    this._group = values.group;
    this._activeQuality = values.activeQuality;
    this._activeCreel = values.activeCreel;
    this._messages = values.messages;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get machineType(): MachineType {
    return this._machineType;
  }

  public get group(): MachineGroup {
    return this._group;
  }

  public get activeQuality(): OverviewListMachineQuality {
    return this._activeQuality;
  }

  public set activeQuality(activeQuality: OverviewListMachineQuality) {
    this._activeQuality = activeQuality;
  }

  public get activeCreel(): OverviewMachineCreel {
    return this._activeCreel;
  }

  public set activeCreel(activeCreel: OverviewMachineCreel) {
    this._activeCreel = activeCreel;
  }

  public get messages(): Message[] {
    return this._messages;
  }

  public get activeWeaveProduct(): OverviewListWeaveProduct {
    return this._activeWeaveProduct;
  }

  public set activeWeaveProduct(activeTuftProduct: OverviewListWeaveProduct) {
    this._activeWeaveProduct = activeTuftProduct;
  }

  public get activeTuftProduct(): OverviewListTuftProduct {
    return this._activeTuftProduct;
  }

  public set activeTuftProduct(activeTuftProduct: OverviewListTuftProduct) {
    this._activeTuftProduct = activeTuftProduct;
  }

  public static fromJSON(machineJSON: any): PlanningMachine {
    const properties = {
      id: machineJSON.id,
      name: machineJSON.name,
      machineType: MachineType[`${machineJSON.machineType}`],
      group: MachineGroup.fromJSON(machineJSON.machineGroup),
      activeQuality: null,
      activeCreel: null,
      activeWeaveProduct: null,
      activeTuftProduct: null,
      messages: []
    };

    return new PlanningMachine(properties);
  }

  public static compare(planningMachineA: PlanningMachine, planningMachineB: PlanningMachine): number {
    if (planningMachineA.group?.id === planningMachineB.group?.id) {
      return StringUtils.stringComparator(planningMachineA.name, planningMachineB.name);
    }

    return MachineGroup.compare(planningMachineA.group, planningMachineB.group);
  }
}
