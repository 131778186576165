<section fxLayout="column" fxFlex>
  <vdw-header [title]="'GENERAL.ACTIONS.CHOOSE_OBJECT' | bmsTranslate: {object: 'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} | lowercase}" svgIcon="select-blue"></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddCustomer()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #customersGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfCustomers" [rowData]="listOfCustomers"></ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectCustomer()" [centered]="true" (chooseEvent)="selectCustomer()"></vdw-footer>
</section>
