import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {IRowNode} from 'ag-grid-community';
import {convertUnit} from './../../common/converters/convert-unit';
import {Unit} from './../../common/unit.enum';
import {NumberCellRendererParams} from './number-cell-renderer-params.interface';

@Component({
  templateUrl: './number-cell-renderer.component.html',
  styleUrls: ['./number-cell-renderer.component.scss']
})
export class NumberCellRendererComponent implements ICellRendererAngularComp {
  public value: number;
  public group: boolean;
  public showGroup: boolean;

  private unit: Unit;
  private node: IRowNode;

  public agInit(params: NumberCellRendererParams): void {
    this.unit = params.toUnit;
    this.group = params.node.group;
    this.showGroup = params.showGroup;
    this.node = params.node;

    if (params.fromUnit && params.toUnit) {
      this.value = convertUnit({
        from: {value: params.value, unit: params.fromUnit},
        to: params.toUnit
      });
    } else {
      this.value = params.value;
    }

    this.getUnitTranslationKey();
  }

  public refresh(_: NumberCellRendererParams): boolean {
    return false;
  }

  public getUnitTranslationKey(): string {
    return Unit.getKeyFromValue(this.unit);
  }

  public hasUnit(): boolean {
    return !!this.unit;
  }

  public isGroupExpanded(): boolean {
    return this.node.expanded;
  }

  public toggleGroup(event: PointerEvent): void {
    this.node.setExpanded(!this.node.expanded);

    event.stopPropagation();
  }
}
