<section class="full-height flex-column">
  <div class="account-switch-container">
    <div #accountSwitch>
      <vdw-account-switch [class.active]="accountSwitcherActive" (click)="accountSwitcherClicked()" [name]="subscriptionName" [id]="subscriptionId" [showExpanded]="true"></vdw-account-switch>
    </div>
  </div>
  <div class="sidebar-content">
    <div class="menu-item-container">
      <vdw-menu-item *ngFor="let item of navigationBarItems" [entry]="getMenuItemEntryFromNavigationBarItem(item)" iconWidthInPx="16" (menuItemClicked)="chooseMenuItem($event, item)"></vdw-menu-item>
    </div>
    <button mat-icon-button (click)="closeNavigation()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</section>
