import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DeviceTemplateRoute extends RouteBranch {
  private _addDeviceTemplate: RouteLeaf;
  private _editDeviceTemplate: RouteLeaf;
  private _duplicateDeviceTemplate: RouteLeaf;
  private _addCounter: RouteLeaf;
  private _editCounter: RouteLeaf;
  private _duplicateCounter: RouteLeaf;
  private _addConfiguration: RouteLeaf;
  private _editConfiguration: RouteLeaf;
  private _duplicateConfiguration: RouteLeaf;
  private _addDeviceTemplateFromCatalog: RouteLeaf;

  public get addDeviceTemplate(): RouteLeaf {
    return this._addDeviceTemplate;
  }

  public set addDeviceTemplate(value: RouteLeaf) {
    this.replace(this._addDeviceTemplate, value);
    this._addDeviceTemplate = value;
  }

  public get editDeviceTemplate(): RouteLeaf {
    return this._editDeviceTemplate;
  }

  public set editDeviceTemplate(value: RouteLeaf) {
    this.replace(this._editDeviceTemplate, value);
    this._editDeviceTemplate = value;
  }

  public get duplicateDeviceTemplate(): RouteLeaf {
    return this._duplicateDeviceTemplate;
  }

  public set duplicateDeviceTemplate(value: RouteLeaf) {
    this.replace(this._duplicateDeviceTemplate, value);
    this._duplicateDeviceTemplate = value;
  }

  public get addCounter(): RouteLeaf {
    return this._addCounter;
  }

  public set addCounter(value: RouteLeaf) {
    this.replace(this._addCounter, value);
    this._addCounter = value;
  }

  public get editCounter(): RouteLeaf {
    return this._editCounter;
  }

  public set editCounter(value: RouteLeaf) {
    this.replace(this._editCounter, value);
    this._editCounter = value;
  }

  public get duplicateCounter(): RouteLeaf {
    return this._duplicateCounter;
  }

  public set duplicateCounter(value: RouteLeaf) {
    this.replace(this._duplicateCounter, value);
    this._duplicateCounter = value;
  }

  public get addConfiguration(): RouteLeaf {
    return this._addConfiguration;
  }

  public set addConfiguration(value: RouteLeaf) {
    this.replace(this._addConfiguration, value);
    this._addConfiguration = value;
  }

  public get editConfiguration(): RouteLeaf {
    return this._editConfiguration;
  }

  public set editConfiguration(value: RouteLeaf) {
    this.replace(this._editConfiguration, value);
    this._editConfiguration = value;
  }

  public get duplicateConfiguration(): RouteLeaf {
    return this._duplicateConfiguration;
  }

  public set duplicateConfiguration(value: RouteLeaf) {
    this.replace(this._duplicateConfiguration, value);
    this._duplicateConfiguration = value;
  }

  public get addDeviceTemplateFromCatalog(): RouteLeaf {
    return this._addDeviceTemplateFromCatalog;
  }

  public set addDeviceTemplateFromCatalog(value: RouteLeaf) {
    this.replace(this._addDeviceTemplateFromCatalog, value);
    this._addDeviceTemplateFromCatalog = value;
  }
}
