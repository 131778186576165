import {DrawingForArticle} from '@domain/article/drawing-for-article';
import {MachineQualityForArticle} from '@domain/article/machine-quality-for-article';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {isNil} from 'lodash-es';

export class OverviewListArticle {
  private _id: number;
  private _name: string;
  private _design: DrawingForArticle;
  private _machineQuality: MachineQualityForArticle;
  private _coloredYarnSet: OverviewListColoredYarnSet;

  public constructor(id: number, name: string, design: DrawingForArticle, machineQuality: MachineQualityForArticle, coloredYarnSet: OverviewListColoredYarnSet) {
    this._id = id;
    this._name = name;
    this._design = design;
    this._machineQuality = machineQuality;
    this._coloredYarnSet = coloredYarnSet;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get design(): DrawingForArticle {
    return this._design;
  }

  public get machineQuality(): MachineQualityForArticle {
    return this._machineQuality;
  }

  public get coloredYarnSet(): OverviewListColoredYarnSet {
    return this._coloredYarnSet;
  }

  public static fromJSON(articleJSON: any): OverviewListArticle {
    return new OverviewListArticle(
      articleJSON.id,
      articleJSON.name,
      !isNil(articleJSON.design) ? DrawingForArticle.fromJSON(articleJSON.design) : null,
      !isNil(articleJSON.machineQuality) ? MachineQualityForArticle.fromJSON(articleJSON.machineQuality) : null,
      !isNil(articleJSON.coloredYarnSet) ? OverviewListColoredYarnSet.fromJSON(articleJSON.coloredYarnSet) : null
    );
  }

  public canDuplicate(): boolean {
    return true;
  }
}
