import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Notification} from '@domain/notifications/notification';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {OnNotificationMarkedAsReadInterface} from './on-notification-marked-as-read.interface';

@Injectable()
export class SignalrNotificationsService {
  public readonly newNotifications: Observable<Notification>;
  public readonly notificationsMarkedAsRead: Observable<OnNotificationMarkedAsReadInterface>;

  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {
    this.newNotifications = this.getNewNotifications();
    this.notificationsMarkedAsRead = this.getNotificationsMarkedAsRead();
  }

  private getNewNotifications(): Observable<Notification> {
    return this.signalrHubConnectionFactoryService
      .getSignalrHubEvents(`${this.backendConfiguration.getAlertingHubEndPoint()}alerting`, 'OnNotificationReceived')
      .pipe(map((notificationChanges: any) => Notification.fromJSON(notificationChanges)));
  }

  private getNotificationsMarkedAsRead(): Observable<OnNotificationMarkedAsReadInterface> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents(`${this.backendConfiguration.getAlertingHubEndPoint()}alerting`, 'OnNotificationMarkedAsRead').pipe(
      map((notificationChanges: any) => {
        return {readNotificationIds: notificationChanges.readNotificationIds, unreadCount: notificationChanges.unreadCount};
      })
    );
  }
}
