import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';

export const canActivateViaAuthGuard = (): boolean => {
  const authentication = inject(AUTHENTICATION);
  const router = inject(Router);

  let result = false;

  if (authentication.isAuthenticated()) {
    result = true;
  } else {
    router.navigate([RouteUtils.paths.login.absolutePath]);
  }
  return result;
};
