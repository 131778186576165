import {Drawing} from '@domain/production-schedule/drawing';
import {MachineQualityForProductionSchedule} from '@domain/production-schedule/machine-quality-for-production-schedule';

const THREAD_BY_THREAD_DRAWING_NUMBER_OF_COLUMNS_TO_DROP_FOR_WEFT_SELECTION = 2;

export function calculateDrawingWidthInDents(drawing: Drawing, machineQuality: MachineQualityForProductionSchedule): number {
  return drawing.threadByThread
    ? (drawing.dimensionsInPixels.widthInPx - THREAD_BY_THREAD_DRAWING_NUMBER_OF_COLUMNS_TO_DROP_FOR_WEFT_SELECTION) /
        (machineQuality.weaveStructure.numberOfGroundYarns / machineQuality.weaveStructure.numberOfCloths)
    : drawing.dimensionsInPixels.widthInPx - machineQuality.numberOfColumnsToDropForWeftSelection;
}
