import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MobilePositionIndicatorComponent} from './mobile-position-indicator.component';

@NgModule({
  declarations: [MobilePositionIndicatorComponent],
  imports: [CommonModule],
  exports: [MobilePositionIndicatorComponent]
})
export class MobilePositionIndicatorModule {}
