import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ShiftRoute extends RouteBranch {
  private _addShift: RouteLeaf;
  private _editShift: RouteLeaf;
  private _duplicateShift: RouteLeaf;

  public get addShift(): RouteLeaf {
    return this._addShift;
  }

  public set addShift(value: RouteLeaf) {
    this.replace(this._addShift, value);
    this._addShift = value;
  }

  public get editShift(): RouteLeaf {
    return this._editShift;
  }

  public set editShift(value: RouteLeaf) {
    this.replace(this._editShift, value);
    this._editShift = value;
  }

  public get duplicateShift(): RouteLeaf {
    return this._duplicateShift;
  }

  public set duplicateShift(value: RouteLeaf) {
    this.replace(this._duplicateShift, value);
    this._duplicateShift = value;
  }
}
