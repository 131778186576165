import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {PlanningDragDropDirective} from './planning-drag-drop.directive';
import {PlanningDragDropService} from './planning-drag-drop.service';
import {PlanningDragIndicatorComponent} from './planning-drag-indicator/planning-drag-indicator.component';
import {PlanningHourIndicatorComponent} from './planning-hour-indicator/planning-hour-indicator.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [PlanningDragDropDirective, PlanningDragIndicatorComponent, PlanningHourIndicatorComponent],
  providers: [PlanningDragDropService],
  exports: [PlanningDragDropDirective]
})
export class PlanningDragDropModule {}
