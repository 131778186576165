import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ColorSetRoute extends RouteBranch {
  private _addColorSet: RouteLeaf;
  private _duplicateColorSet: RouteLeaf;
  private _editColorSet: RouteLeaf;
  private _colorSetSettings: RouteLeaf;

  public get addColorSet(): RouteLeaf {
    return this._addColorSet;
  }

  public set addColorSet(value: RouteLeaf) {
    this.replace(this._addColorSet, value);
    this._addColorSet = value;
  }

  public get duplicateColorSet(): RouteLeaf {
    return this._duplicateColorSet;
  }

  public set duplicateColorSet(value: RouteLeaf) {
    this.replace(this._duplicateColorSet, value);
    this._duplicateColorSet = value;
  }

  public get editColorSet(): RouteLeaf {
    return this._editColorSet;
  }

  public set editColorSet(value: RouteLeaf) {
    this.replace(this._editColorSet, value);
    this._editColorSet = value;
  }

  public get colorSetSettings(): RouteLeaf {
    return this._colorSetSettings;
  }

  public set colorSetSettings(value: RouteLeaf) {
    this.replace(this._colorSetSettings, value);
    this._colorSetSettings = value;
  }
}
