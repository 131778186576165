import {Directive, ElementRef, Inject, Input, Renderer2} from '@angular/core';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';

@Directive({
  selector: '[appDataCy]'
})
export class DataCyDirective {
  private static readonly selectorName = 'appDataCy';

  private readonly elementRef: ElementRef;
  private readonly renderer: Renderer2;

  public constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    @Inject(RUNTIME_APPLICATION_ENVIRONMENT) private runtimeEnvironment: RuntimeApplicationEnvironment
  ) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }

  @Input('appDataCy')
  public set dataCy(dataCy: string) {
    if (this.runtimeEnvironment.getEnvironment().removeTestingAttributes) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, DataCyDirective.selectorName);
    } else if (!this.elementRef.nativeElement.hasAttribute(DataCyDirective.selectorName)) {
      this.renderer.setAttribute(this.elementRef.nativeElement, DataCyDirective.selectorName, dataCy);
    }
  }
}
