import {IdName} from '@domain/id-name';
import {CommercialMachineQuality} from '@domain/textile-data/machine-quality/commercial-machine-quality';
import {IncorporationFactorException} from '@domain/textile-data/machine-quality/incorporation-factor-exception';
import {TuftLengthException} from '@domain/textile-data/machine-quality/tuft-Length-exception';
import {DoubleWidthWeaveStructure} from '@domain/textile-data/weave-structure/double-width-weave-structure';
import {SingleWidthWeaveStructure} from '@domain/textile-data/weave-structure/single-width-weave-structure';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {MachineOverviewListHelper} from '@presentation/pages/machine-overview/machine-overview-list-helper';
import {WeaveStructureWidth} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-general/weave-structure-width.enum';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {isNil, map} from 'lodash-es';
import {Finishing} from '../finishing-and-finishing-template/finishing/finishing';
import {GeneralJuteSettings} from './general-jute-settings';
import {Jute} from './jute';
import {PathWidth} from './path-width';
import {WeaveQuality} from './weave-quality';
import {WeftSelection} from './weft-selection';

export class CarpetWeaveQuality extends WeaveQuality {
  public alternativeMachineQualityIds: number[];

  public constructor(
    id: number,
    technicalName: string,
    version: number,
    type: WeaveQualityType,
    weaveStructureWidth: WeaveStructureWidth,
    singleWidth: SingleWidthWeaveStructure,
    doubleWidth: DoubleWidthWeaveStructure,
    reedDensityInDentsPerMM: number,
    pickDensityInPicksPerMM: number,
    weftDensityInDentsPerMM: number,
    technicalCode: string,
    practicalPickDensityInPicksPerMM: number,
    commercialMachineQualities: CommercialMachineQuality[],
    pathWidths: PathWidth[],
    applicableMachines: MachineOverviewList[],
    machinesWithSpecificPickDensity: {machine: MachineOverviewList; specificPickDensity: number}[],
    pileHeightInMM: number,
    buggyLengthMinInMM: number,
    buggyLengthMaxInMM: number,
    tuftLengthInMM: number,
    incorporationFactor: number,
    tolerance: number,
    picksPerHourMin: number,
    tuftLengthPatternTypeExceptions: TuftLengthException[],
    tuftLengthYarnTypeExceptions: TuftLengthException[],
    incorporationFactorPatternTypeExceptions: IncorporationFactorException[],
    incorporationFactorYarnTypeExceptions: IncorporationFactorException[],
    used: boolean,
    numberOfColumnsToDropForWeftSelection: number,
    jute: Jute[],
    finishings: Finishing[],
    alternativeMachineQualityIds: number[],
    weftSelections: WeftSelection[],
    juteGeneralSettings: GeneralJuteSettings,
    yarnSet: IdName,
    missingDesignMarginInDents: number,
    canAddJute?: boolean
  ) {
    super(
      id,
      technicalName,
      version,
      type,
      weaveStructureWidth,
      singleWidth,
      doubleWidth,
      reedDensityInDentsPerMM,
      pickDensityInPicksPerMM,
      weftDensityInDentsPerMM,
      technicalCode,
      practicalPickDensityInPicksPerMM,
      commercialMachineQualities,
      pathWidths,
      applicableMachines,
      machinesWithSpecificPickDensity,
      pileHeightInMM,
      buggyLengthMinInMM,
      buggyLengthMaxInMM,
      tuftLengthInMM,
      incorporationFactor,
      tolerance,
      picksPerHourMin,
      tuftLengthPatternTypeExceptions,
      tuftLengthYarnTypeExceptions,
      incorporationFactorPatternTypeExceptions,
      incorporationFactorYarnTypeExceptions,
      used,
      numberOfColumnsToDropForWeftSelection,
      jute,
      weftSelections,
      juteGeneralSettings,
      yarnSet,
      missingDesignMarginInDents,
      finishings,
      canAddJute
    );
    this.alternativeMachineQualityIds = alternativeMachineQualityIds;
  }

  public get alternativeQualityIds(): number[] {
    return this.alternativeMachineQualityIds;
  }

  public static createEmptyCarpetWeaveQuality(): CarpetWeaveQuality {
    return new CarpetWeaveQuality(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      null,
      null,
      [],
      [],
      [],
      null,
      null,
      null,
      0
    );
  }

  public static fromJSON(carpetWeaveQualityJSON: any): CarpetWeaveQuality {
    let result: CarpetWeaveQuality = null;

    if (!isNil(carpetWeaveQualityJSON)) {
      result = new CarpetWeaveQuality(
        carpetWeaveQualityJSON.id,
        carpetWeaveQualityJSON.technicalName,
        carpetWeaveQualityJSON.version,
        WeaveQualityType.CARPET,
        carpetWeaveQualityJSON.weaveStructureWidthType ?? null,
        WeaveQuality.getBackwardsSingleWidthWeaveStructure(carpetWeaveQualityJSON),
        DoubleWidthWeaveStructure.fromJSON(carpetWeaveQualityJSON),
        carpetWeaveQualityJSON.reedDensityInDentsPerMM,
        carpetWeaveQualityJSON.theoreticalPickDensityInPicksPerMM,
        carpetWeaveQualityJSON.weftDensityInDentsPerMM,
        carpetWeaveQualityJSON.technicalCode,
        isNil(carpetWeaveQualityJSON.specificPickDensity) ? carpetWeaveQualityJSON.practicalPickDensityInPicksPerMM : carpetWeaveQualityJSON.specificPickDensity,
        map(carpetWeaveQualityJSON.commercialMachineQualities, (commercialMachineQualityJSON: any) => {
          return CommercialMachineQuality.fromJSON(commercialMachineQualityJSON);
        }),
        carpetWeaveQualityJSON.pathWidths?.map((pathWidthJSON: any) => {
          return PathWidth.fromJSON(pathWidthJSON);
        }) ?? [],
        map(carpetWeaveQualityJSON.applicableMachines, (applicableMachineJSON: any) => {
          return MachineOverviewListHelper.fromJSON(applicableMachineJSON);
        }),
        map(carpetWeaveQualityJSON.machinesWithSpecificPickDensity, (machineWithSpecificPickDensityJSON: any) => {
          return {
            machine: MachineOverviewListHelper.fromJSON(machineWithSpecificPickDensityJSON.machine),
            specificPickDensity: machineWithSpecificPickDensityJSON.specificPickDensity
          };
        }),
        carpetWeaveQualityJSON.pileHeightInMM,
        carpetWeaveQualityJSON.buggyLengthMinInMM,
        carpetWeaveQualityJSON.buggyLengthMaxInMM,
        carpetWeaveQualityJSON.tuftLengthInMM,
        carpetWeaveQualityJSON.incorporationFactor,
        carpetWeaveQualityJSON.tolerance,
        carpetWeaveQualityJSON.picksPerHourMin,
        WeaveQuality.getTuftLengthExceptions(carpetWeaveQualityJSON.tuftLengthPatternTypeExceptions),
        WeaveQuality.getTuftLengthExceptions(carpetWeaveQualityJSON.tuftLengthYarnTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(carpetWeaveQualityJSON.incorporationFactorPatternTypeExceptions),
        WeaveQuality.getIncorporationFactorExceptions(carpetWeaveQualityJSON.incorporationFactorYarnTypeExceptions),
        carpetWeaveQualityJSON.used,
        carpetWeaveQualityJSON.nrColumnsToDropForWeftSelection,
        carpetWeaveQualityJSON.jute?.map((jute: any) => {
          return Jute.fromJSON(jute);
        }) ?? [],
        carpetWeaveQualityJSON.finishings?.map((finishing: any) => Finishing.fromJSON(finishing)),
        carpetWeaveQualityJSON.alternativeMachineQualityIds,
        carpetWeaveQualityJSON.weftSelections
          ?.map((weftSelectionJSON: any) => WeftSelection.fromJSON(weftSelectionJSON))
          ?.sort((currentWeftSelection: WeftSelection, nextWeftSelection: WeftSelection) => {
            return currentWeftSelection.position < nextWeftSelection.position ? -1 : 1;
          }) ?? [],
        carpetWeaveQualityJSON.juteGeneralSettings ? GeneralJuteSettings.fromJSON(carpetWeaveQualityJSON.juteGeneralSettings) : null,
        carpetWeaveQualityJSON.yarnSet ? IdName.fromJSON(carpetWeaveQualityJSON.yarnSet) : null,
        carpetWeaveQualityJSON.missingDesignMarginInDents,
        carpetWeaveQualityJSON.canAddJute ?? false
      );
    }

    return result;
  }

  public toJSON(): JSON {
    const carpetWeaveQuality: any = super.toJSON();
    carpetWeaveQuality.alternativeQualityIds = this.alternativeMachineQualityIds;

    return carpetWeaveQuality as JSON;
  }
}
