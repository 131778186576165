import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {TagColor} from '../../custom-components/tag/tag-color.enum';
import {TagSize} from '../../custom-components/tag/tag-size.enum';
import {GridTagRenderParams} from './grid-tag-render-params.interface';

@Component({
  templateUrl: './grid-tag.component.html'
})
export class GridTagComponent implements ICellRendererAngularComp {
  public value: string;
  public size: TagSize = TagSize.SMALL;
  public label: string;

  public colorGetter: {[key: string]: TagColor};

  public agInit(params: GridTagRenderParams = null): void {
    this.setLabel(params.value, params.translationKey);
    this.value = params.value;
    this.size = params.size;
    this.colorGetter = params.colorGetter;
  }

  public refresh(params: any): boolean {
    return false;
  }

  private setLabel(value: string, translationKey: string): void {
    this.label = translationKey + value;
  }
}
