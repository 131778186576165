import {ElementRef, Inject, Injectable} from '@angular/core';
import {MachineType} from '@domain/machine/machine-type.enum';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningCreel} from '@domain/planning/carpet/planning-creel';
import {PlanningMachineCarpetRun} from '@domain/planning/carpet/planning-machine-carpet-run';
import {PlanningMachineQuality} from '@domain/planning/carpet/planning-machine-quality';
import {ProductionOrderCarpetForPlanningOrder} from '@domain/planning/carpet/production-order-carpet-for-planning-order';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ONBOARDING, Onboarding} from '@infrastructure/onboarding/onboarding';
import {OnboardingPlanningStep} from '@presentation/components/onboarding-dialog/onboarding-planning-step.enum';
import {OnboardingType} from '@presentation/components/onboarding-dialog/onboarding-type';
import {EnumUtils, PlanningDragDropService, PlanningGridComponent, PlanningItemData, TranslateService} from '@vdw/angular-component-library';
import moment from 'moment';
import {PlanningSidebarsComponent} from '../sidebars/planning-sidebars.component';
import {PlanningContextService} from './planning-context.service';

@Injectable()
export class PlanningOnboardingService {
  public readonly DEMO_ITEM_ID = -1;
  public demoPlanningItem: ElementRef<HTMLElement>;
  public currentOnboardingStep: OnboardingPlanningStep;

  private gridDisplay: PlanningGridComponent;
  private planningSidebars: PlanningSidebarsComponent;

  public constructor(
    private readonly translate: TranslateService,
    private readonly planningDragDrop: PlanningDragDropService,
    @Inject(ONBOARDING) private readonly onboarding: Onboarding,
    private readonly planningContext: PlanningContextService
  ) {}

  public initializeOnboarding(gridDisplay: PlanningGridComponent, planningSidebars: PlanningSidebarsComponent): void {
    this.gridDisplay = gridDisplay;
    this.planningSidebars = planningSidebars;
  }

  public startOnboarding(): void {
    if (!this.onboarding.canStartOnboarding(OnboardingType.PLANNING)) {
      return;
    }

    this.planningContext.isOnboardingRunning = true;
    const dialogDate = moment().startOf('day').add(45, 'h').toDate();

    this.onboarding.start({
      sourceElement: this.createStubHtmlElement(414, dialogDate),
      type: OnboardingType.PLANNING,
      position: PositionOfDialog.RIGHT,
      steps: EnumUtils.getEnumValues(OnboardingPlanningStep),
      onNextStep: (updateOnboardingDialog: (targetElement: HTMLElement, leftPositionInPx?: number) => void, currentOnboardingStep: OnboardingPlanningStep) => {
        this.currentOnboardingStep = currentOnboardingStep;

        if (currentOnboardingStep === OnboardingPlanningStep.ADD_ITEM) {
          this.handleOnboardingAddItemStep(updateOnboardingDialog);
        } else if (currentOnboardingStep === OnboardingPlanningStep.VIEW_DETAILS) {
          this.handleOnboardingViewDetailsStep(updateOnboardingDialog);
        } else if (currentOnboardingStep === OnboardingPlanningStep.DRAG_AND_DROP) {
          this.handleOnboardingDragAndDropStep(updateOnboardingDialog);
        }
      },
      onCompleted: () => {
        this.handleOnboardingCompletion();
      }
    });
  }

  private handleOnboardingAddItemStep(updateOnboardingDialog: (targetElement: HTMLElement) => void): void {
    this.planningContext.machines.unshift(
      new PlanningMachine({
        id: this.DEMO_ITEM_ID,
        name: this.getDemoEntityTranslation('MACHINE.MACHINE'),
        machineType: MachineType.CARPET_LOOM,
        group: this.planningContext.machines.find((machine: PlanningMachine) => machine?.group).group,
        activeQuality: null,
        activeCreel: null,
        activeWeaveProduct: null,
        activeTuftProduct: null,
        messages: []
      })
    );

    const dialogDate = moment().startOf('day').add(45, 'h').toDate();
    updateOnboardingDialog(this.createStubHtmlElement(320, dialogDate));
  }

  private handleOnboardingViewDetailsStep(updateOnboardingDialog: (targetElement: HTMLElement) => void): void {
    const endDate = moment().startOf('day').add(3, 'days').toDate();
    const startDate = moment().startOf('day').add(1, 'days').toDate();

    const creelName = this.getDemoEntityTranslation('TEXTILE_DATA.CREEL.CREEL');
    const orderName = this.getDemoEntityTranslation('PLANNING.PLANNING_ORDER');
    const machineQualityName = this.getDemoEntityTranslation('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY');

    const creelForDemoMachine = new PlanningCreel(this.DEMO_ITEM_ID, creelName);
    const machineQualityForDemoMachine = new PlanningMachineQuality(this.DEMO_ITEM_ID, machineQualityName, 1);
    const productionOrderForDemoMachine = new ProductionOrderCarpetForPlanningOrder(this.DEMO_ITEM_ID, orderName, null, ProductionScheduleStatus.EXECUTED);

    const planningOrderForDemoMachine = new PlanningOrder(this.DEMO_ITEM_ID, startDate, endDate, null, productionOrderForDemoMachine);
    const planningItemForDemoMachine = new PlanningMachineCarpetRun(this.DEMO_ITEM_ID, machineQualityForDemoMachine, creelForDemoMachine, [planningOrderForDemoMachine], startDate, endDate, true);
    const calendarItemGroupForDemoMachine = new CalendarItemGroup(this.DEMO_ITEM_ID, [planningItemForDemoMachine]);

    this.planningContext.groupedCalendarItems.push(calendarItemGroupForDemoMachine);

    const demoMachine = this.planningContext.machines.find((machine: PlanningMachine) => machine.id === this.DEMO_ITEM_ID);
    this.planningSidebars.openPlanningItemDetails(demoMachine, planningItemForDemoMachine);

    const dialogDate = moment(startDate).add(1, 'd').toDate();
    updateOnboardingDialog(this.createStubHtmlElement(320, dialogDate));
  }

  private handleOnboardingDragAndDropStep(updateOnboardingDialog: (targetElement: HTMLElement) => void): void {
    const hourInterval = 6;
    const demoMachineGroup = this.planningContext.groupedCalendarItems.find((group: CalendarItemGroup) => group.machineId === this.DEMO_ITEM_ID);
    const demoPlanningItem = demoMachineGroup.items[0] as PlanningMachineRun;

    const newEndDate = moment(demoPlanningItem.endDate).add(hourInterval, 'hours').toDate();
    const newStartDate = moment(demoPlanningItem.startDate).add(hourInterval, 'hours').toDate();

    this.planningSidebars.closeSidebar();
    demoPlanningItem.reschedule(newStartDate, newEndDate);

    const planningItemData = new PlanningItemData();
    planningItemData.dataTransfer = demoPlanningItem;

    const stopPropagation = (): void => {
      return;
    };

    const dialogDate = moment(newStartDate).add(1, 'd').toDate();
    updateOnboardingDialog(this.createStubHtmlElement(320, dialogDate));

    const positionX = (this.gridDisplay.getLeftPercentForDate(newStartDate) / 100) * this.gridDisplay.rowWidth + this.gridDisplay.ROW_INDICATOR_WIDTH;
    const positionY = this.gridDisplay.bodyContainerOffset.y + this.gridDisplay.COLUMN_HEADER_HEIGHT + 12;

    const dragEvent = {clientX: 0, clientY: positionY, offsetX: 0, offsetY: 0, stopPropagation} as any as DragEvent;

    this.planningDragDrop.onDragStart(dragEvent, planningItemData, null, null, 250, {x: positionX, y: 0}, undefined, this.gridDisplay);
  }

  private handleOnboardingCompletion(): void {
    this.planningContext.isOnboardingRunning = false;
    this.planningSidebars.closeSidebar(true);

    (this.planningDragDrop as any).hourIndicator?.hide();

    this.planningContext.machines = this.planningContext.machines.filter((machine: PlanningMachine) => machine.id !== this.DEMO_ITEM_ID);
    this.planningContext.machinesForSelectedPlanningGroups = this.planningContext.machinesForSelectedPlanningGroups.filter((machine: PlanningMachine) => machine.id !== this.DEMO_ITEM_ID);

    this.planningContext.groupedCalendarItems = this.planningContext.groupedCalendarItems.filter((group: CalendarItemGroup) => group.machineId !== this.DEMO_ITEM_ID);
  }

  private getDemoEntityTranslation(entityKey: string): string {
    return this.translate.instant('GENERAL.DEMO_ENTITY', {entity: this.translate.instant(entityKey, {count: 1}).toLowerCase()});
  }

  private createStubHtmlElement(top: number, date: Date): HTMLElement {
    this.gridDisplay.calculateRowWidth();
    this.gridDisplay.calculateBodyContainerOffset();
    const right = this.gridDisplay.bodyContainerOffset.x + (this.gridDisplay.getLeftPercentForDate(date) / 100) * this.gridDisplay.rowWidth + this.gridDisplay.ROW_INDICATOR_WIDTH;

    return {
      getBoundingClientRect(): DOMRect {
        return {top, right, width: 10, height: 10} as DOMRect;
      },
      scrollIntoView(_: boolean): void {
        return;
      }
    } as any as HTMLElement;
  }
}
