import {Route} from '@angular/router';
import {AssertionUtils} from './assertion-utils';

export class PrototypeRouteUtils {
  public static getAbsolutePath(routes: Route[], id: number, accumulatedPath: string = ''): string {
    const navigationItem = routes.find((route: Route) => route.data?.id === id);
    const data = navigationItem.data;

    let path = navigationItem.path + accumulatedPath;

    if (!AssertionUtils.isEmpty(navigationItem.path)) {
      path = '/' + path;
    }

    const parent = !AssertionUtils.isNullOrUndefined(data.parentId) ? routes.find((route: Route) => route.data?.id === data.parentId) : null;
    return !AssertionUtils.isNullOrUndefined(parent) ? this.getAbsolutePath(routes, parent.data.id, path) : path;
  }

  public static getPage(routes: Route[], path: '' | 'add' | 'add/:id' | 'edit/:id'): Route {
    return routes.find((route: Route) => route.path === path);
  }
}
