import {GridModel} from '../floating-filters/data/grid-model';
import {FilterModelBuilder} from './filter-model-builder';

export class GridModelBuilder {
  private startRow: number;
  private endRow: number;
  private filterList: any = {};

  public newFilter(columnName: string, newFilter: FilterModelBuilder): GridModelBuilder {
    this.filterList[columnName] = newFilter.build();
    return this;
  }

  public build(): GridModel {
    return new GridModel(this.startRow, this.endRow, undefined, this.filterList);
  }
}
