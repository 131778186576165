import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {NotFoundPageComponent} from '../status-codes/404/not-found-page.component';
import {canActivateProductionScheduleGuard} from './production-schedule/security/can-activate-production-schedule.guard';

export const texfabRoutes = [
  {path: '', component: NotFoundPageComponent},
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'planning',
      icon: 'solid-schedule',
      parentId: NavigationId.TEXFAB,
      id: NavigationId.PLANNING_MODULE,
      navigationKey: 'GENERAL.NAVIGATION.PLANNING_BOARD',
      requiredPermission: Permission.TEXFAB_PLANNINGBOARD
    },
    import('./planning/planning.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      icon: 'solid-schedule',
      route: 'planning-prototype',
      parentId: NavigationId.TEXFAB,
      id: NavigationId.PLANNING_PROTOTYPE_MODULE,
      navigationKey: 'GENERAL.NAVIGATION.PLANNING_BOARD',
      requiredPermission: Permission.TEXFAB_PLANNINGBOARD_NEW
    },
    import('./planning-prototype/planning-prototype.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {route: 'production-order', requiredPermission: Permission.TEXFAB_SCHEDULE, id: NavigationId.PRODUCTION_SCHEDULE_MODULE, parentId: NavigationId.TEXFAB, navigationKey: 'PRODUCTION_ORDER.CARPET'},
    import('./production-schedule/production-schedule.module'),
    [],
    [canActivateProductionScheduleGuard]
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {route: 'customer', requiredPermission: Permission.TEXFAB_CUSTOMER_VIEW, id: NavigationId.CUSTOMER_MODULE, parentId: NavigationId.TEXFAB, navigationKey: 'CUSTOMERS.CUSTOMER'},
    import('./customer/customer.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {route: 'article', requiredPermission: Permission.TEXFAB_ARTICLE_VIEW, id: NavigationId.ARTICLE_MODULE, parentId: NavigationId.TEXFAB, navigationKey: 'ARTICLES.ARTICLE'},
    import('./article/article.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {route: 'sales-order', requiredPermission: Permission.TEXFAB_SALES_ORDER_VIEW, id: NavigationId.SALES_ORDER_MODULE, parentId: NavigationId.TEXFAB, navigationKey: 'GENERAL.NAVIGATION.ORDERS'},
    import('./sales-order/sales-order.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {route: 'orderbook', requiredPermission: Permission.TEXFAB_ORDERBOOK, id: NavigationId.ORDERBOOK_MODULE, parentId: NavigationId.TEXFAB, navigationKey: 'ORDERBOOK.ORDERBOOK'},
    import('./orderbook/orderbook.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'weaving/production-order',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_VIEW,
      id: NavigationId.WEAVING_PRODUCTION_ORDER_MODULE,
      parentId: NavigationId.TEXFAB,
      navigationKey: 'PRODUCTION_ORDER.WEAVING'
    },
    import('./production-order-lite/production-order-weaving.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'tufting/production-order',
      requiredPermission: Permission.TEXFAB_TUFT_ORDER_VIEW,
      id: NavigationId.TUFT_PRODUCTION_ORDER_MODULE,
      parentId: NavigationId.TEXFAB,
      navigationKey: 'PRODUCTION_ORDER.TUFTING'
    },
    import('./tuft-production-order/tuft-production-order.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'plastic/production-order',
      requiredPermission: Permission.TEXFAB_PLASTIC_ORDER_VIEW,
      id: NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE,
      parentId: NavigationId.TEXFAB,
      navigationKey: 'PRODUCTION_ORDER.PLASTIC'
    },
    import('./plastic-production-order/plastic-production-order.module')
  ),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'path-layout-template',
      requiredPermission: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW,
      id: NavigationId.PATH_LAYOUT_TEMPLATE_MODULE,
      parentId: NavigationId.TEXFAB,
      navigationKey: 'PATH_LAYOUT_TEMPLATE.CARD_TITLE'
    },
    import('./path-layout-template/path-layout-template.module')
  )
];

export const texfabRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(texfabRoutes);
