<app-needs-attention-sidebar
  *ngIf="sidebarManager.isSidebarTypeNeedsAttention()"
  (closeNeedsAttentionSidebar)="closeSidebar()"
  (selectPlanningOrder)="openPlanningOrderDetails($event)"
  (unplannedOrderDragStart)="planningDragDropChanges.sidebarUnplannedOrderDragStart($event, unplannedOrderTemplate, viewContainerRef)"
>
</app-needs-attention-sidebar>
<app-machine-detail-sidebar *ngIf="sidebarManager.isSidebarTypeMachine()" [machine]="planningContext.selectedMachine" (machineDetailSidebarClose)="closeSidebar()"></app-machine-detail-sidebar>
<app-planning-item-sidebar
  *ngIf="sidebarManager.isSidebarTypePlanningItem()"
  [machine]="planningContext.selectedMachine"
  [planningItem]="planningContext.selectedPlaceholder ?? planningContext.selectedPlanningItem"
  (closePlanningItem)="closeSidebar(true)"
  (deletePlanningItem)="deletePlanningItem()"
  (savePlanningItem)="savePlanningItem($event)"
  (planningItemChange)="onPlanningItemChange($event)"
  (selectPlanningOrder)="openPlanningOrderDetails($event)"
></app-planning-item-sidebar>
<app-planning-order-detail-sidebar
  *ngIf="sidebarManager.isSidebarTypePlanningOrder()"
  [planningOrderId]="planningContext.selectedPlanningOrderId"
  (delete)="deletePlanningOrder()"
  (planningOrderDetailSidebarClose)="closeSidebar()"
></app-planning-order-detail-sidebar>

<ng-template #unplannedOrderTemplate>
  <app-planning-order-card class="full-height flex-row" [compatibleWithMachine]="true" [planningOrder]="planningContext.unplannedOrderItem"> </app-planning-order-card>
</ng-template>
