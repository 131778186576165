import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {LabelModule} from '../ellipsis-label/label.module';
import {AccountSwitchComponent} from './account-switch.component';

@NgModule({
  declarations: [AccountSwitchComponent],
  imports: [MatButtonModule, MatIconModule, LabelModule, CommonModule],
  exports: [AccountSwitchComponent]
})
export class AccountSwitchModule {}
