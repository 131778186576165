import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddPathLayoutTemplateComponent} from './add/add-path-layout-template.component';
import {PathLayoutTemplateOverviewPageComponent} from './overview/path-layout-template-overview-page.component';
import {PathLayoutTemplateSettingsComponent} from './settings/path-layout-template-settings.component';

const shared = {parentId: NavigationId.PATH_LAYOUT_TEMPLATE_MODULE};

export const pathLayoutTemplateRoutes = [
  Factory.createPrototypeComponentRoute(PathLayoutTemplateOverviewPageComponent, {route: '', requiredPermission: null, id: NavigationId.PATH_LAYOUT, ...shared}),
  Factory.createPrototypeComponentRoute(AddPathLayoutTemplateComponent, {
    route: 'add',
    requiredPermission: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT,
    reuse: true,
    id: NavigationId.ADD_PATH_LAYOUT,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddPathLayoutTemplateComponent, {
    route: 'edit/:id',
    requiredPermission: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW,
    id: NavigationId.EDIT_PATH_LAYOUT,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddPathLayoutTemplateComponent, {
    route: 'add/:id',
    requiredPermission: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT,
    id: NavigationId.DUPLICATE_PATH_LAYOUT,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(PathLayoutTemplateSettingsComponent, {
    route: 'settings',
    requiredPermission: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW,
    id: NavigationId.PATH_LAYOUT_SETTINGS,
    ...shared
  })
];

export const pathLayoutTemplateRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(pathLayoutTemplateRoutes);
