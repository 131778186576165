import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class CarpetLoomNoJacquardMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.CARPET_LOOM_NO_JQ, name, orderId);
  }

  public static fromJSON(carpetLoomNoJacquardMachineJSON: any): CarpetLoomNoJacquardMachine {
    return new CarpetLoomNoJacquardMachine(carpetLoomNoJacquardMachineJSON.id, carpetLoomNoJacquardMachineJSON.name, carpetLoomNoJacquardMachineJSON.orderId);
  }
}
