import {Component, OnInit} from '@angular/core';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {BaseOverlayComponent} from '../base-overlay.component';
import {OverlayComponentParams} from '../overlay-component-params';

@Component({
  selector: 'vdw-loading-overlay',
  templateUrl: '../base-overlay.component.html',
  styleUrls: ['../base-overlay.component.scss']
})
export class LoadingOverlayComponent extends BaseOverlayComponent implements ILoadingOverlayAngularComp, OnInit {
  private params: OverlayComponentParams;

  public agInit({api, ...overlayComponentParams}: ILoadingOverlayParams): void {
    this.params = overlayComponentParams as OverlayComponentParams;
  }

  public ngOnInit(): void {
    if (isNil(this.params)) {
      this.params = this.createParamsObject();
    }

    this.initializeOverlayComponent(this.params);
    this.title = this.getTranslation('ANGULAR_COMPONENT_LIBRARY.OVERLAY.LOADING.TITLE');
    this.image = '/assets/icons/illustrations/img-loading.svg';
  }
}
