import {getDisplayValueForPlaceholderSeparator} from '@application/helper/get-display-value-for-placeholder-separator';
import {has, isEqual, isNil, join, map, replace} from 'lodash-es';

export class PlaceholderComponent {
  private readonly _id: number;
  private readonly _name: string;
  private _maxLength: number;
  private _categoryName: string;
  private _value: number | string;
  private _configurableValue: boolean;

  public constructor(id: number, name: string, maxLength?: number, value?: number | string, configurableValue?: boolean) {
    this._id = id;
    this._name = name;
    this._maxLength = maxLength;
    this._value = value;
    this._configurableValue = configurableValue;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get maxLength(): number {
    return this._maxLength;
  }

  public set maxLength(value: number) {
    this._maxLength = value;
  }

  public get categoryName(): string {
    return this._categoryName;
  }

  public set categoryName(value: string) {
    this._categoryName = value;
  }

  public get value(): number | string {
    return this._value;
  }

  public set value(value: number | string) {
    this._value = value;
  }

  public get configurableValue(): boolean {
    return this._configurableValue;
  }

  public set configurableValue(value: boolean) {
    this._configurableValue = value;
  }

  public getLabel(separator: string): string {
    return this.getReadableName().split(' ').join(getDisplayValueForPlaceholderSeparator(separator));
  }

  public static getLabelFromPlaceholderComponents(placeholderParts: PlaceholderComponent[], separator: string): string {
    return join(
      map(placeholderParts, (placeholderComponent: PlaceholderComponent) => {
        return placeholderComponent.getReadableName();
      }),
      getDisplayValueForPlaceholderSeparator(separator)
    );
  }

  public static fromJSON(placeholderComponentJSON: any): PlaceholderComponent {
    return new PlaceholderComponent(
      has(placeholderComponentJSON, 'id') ? placeholderComponentJSON.id : null,
      placeholderComponentJSON.name,
      placeholderComponentJSON.maxLength,
      placeholderComponentJSON.value,
      placeholderComponentJSON.configurableValue
    );
  }

  public getReadableName(): string {
    let result = replace(this.name, / /g, '');
    if (isEqual(result, 'alphabeticstart')) {
      result = 'alphabet';
    }

    if (isEqual(result, 'numericstart')) {
      result = 'number';
    }

    if (!isNil(this.maxLength)) {
      result += `(${this.maxLength})`;
    }

    if (!isNil(this.value)) {
      result += `(start from ${this.value})`;
    }
    return result;
  }

  public toJSON(): JSON {
    let result: JSON;

    if (isNil(this.id)) {
      result = {
        name: this._name,
        maxLength: this._maxLength
      } as any as JSON;
    } else {
      result = {
        id: this._id,
        maxLength: this._maxLength
      } as any as JSON;
    }
    return result;
  }

  public toFullJSON(): JSON {
    let result: JSON;

    if (isNil(this.id)) {
      result = {
        name: this._name,
        maxLength: this._maxLength
      } as any as JSON;
    } else {
      result = {
        id: this._id,
        name: this._name,
        maxLength: this._maxLength
      } as any as JSON;
    }
    return result;
  }
}
