import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProgressSpinnerComponent} from './progress-spinner.component';

@NgModule({
  imports: [MatProgressSpinnerModule],
  exports: [ProgressSpinnerComponent],
  declarations: [ProgressSpinnerComponent]
})
export class ProgressSpinnerModule {}
