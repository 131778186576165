import {FinishingTemplateForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/finishing-template-for-machine-quality';
import {RowNode} from 'ag-grid-community';
import {isEqual, isNil} from 'lodash-es';

export function finishingTemplateDeficitLengthComparator(valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode): number {
  let result;

  const finishingTemplateFirstNode: FinishingTemplateForMachineQuality = nodeA.data;
  const finishingTemplateSecondNode: FinishingTemplateForMachineQuality = nodeB.data;

  if (!finishingTemplateFirstNode.hasCutbarsInformation() && !finishingTemplateSecondNode.hasCutbarsInformation()) {
    result = 0;
  } else if (isNil(finishingTemplateFirstNode.cutbarsInformation)) {
    result = -1;
  } else if (isNil(finishingTemplateSecondNode.cutbarsInformation)) {
    result = 1;
  } else if (isEqual(finishingTemplateFirstNode.cutbarsInformation.getGreatestDeficitLength(), finishingTemplateSecondNode.cutbarsInformation.getGreatestDeficitLength())) {
    result = 0;
  } else if (finishingTemplateFirstNode.cutbarsInformation.getGreatestDeficitLength() > finishingTemplateSecondNode.cutbarsInformation.getGreatestDeficitLength()) {
    result = 1;
  } else {
    result = -1;
  }

  return result;
}
