<button *ngIf="canShowSingleIcon()" [matTooltip]="buttons[0].title | bmsTranslate" matTooltipPosition="above" class="cell-icon-button mr-4" (click)="iconClicked(buttons[0])">
  <mat-icon [svgIcon]="buttons[0].icon" class="icon-12 flex-icon"></mat-icon>
</button>

<button #hoverButton *ngIf="!canShowSingleIcon()" (mouseenter)="showOverlay()" (mouseleave)="onMouseLeave()" class="cell-icon-button mr-4">
  <mat-icon svgIcon="solid-more-vert" class="icon-16 flex-icon"></mat-icon>
</button>

<ng-template #overlayTemplate class="menu-container">
  <div class="cell-icon-container" (mouseenter)="cancelClose()" (mouseleave)="closeOverlay()">
    <button *ngFor="let button of buttons" [matTooltip]="button.title | bmsTranslate" matTooltipPosition="above" class="cell-icon-button" (click)="iconClicked(button)">
      <mat-icon [svgIcon]="button.icon" class="icon-12 flex-icon"></mat-icon>
    </button>
  </div>
</ng-template>
