<div class="flex-column flex-center gap-24">
  <img *ngIf="shouldDisplayImage" [src]="image" alt="illustration" [style.height.px]="scaled(heightImage)" />

  <div class="flex-column flex-center">
    <h3 class="overlay-title" *ngIf="shouldDisplayTitle" [innerHTML]="title"></h3>
    <p *ngIf="shouldDisplayDescription" class="b1 description flex" [style.fontSize.px]="scaled(descriptionFontSize)">{{ description }}</p>
  </div>

  <div class="flex-row gap-16 flex-start-center" *ngIf="shouldDisplayActions">
    <div *ngFor="let action of actions">
      <button mat-flat-button type="button" color="primary" *ngIf="getActionClass(action)" (click)="onActionClicked(action.key)" [disabled]="isActionDisabled(action.disabled)">
        {{ action.titleKey | bmsTranslate: {count: 1} }}
      </button>
      <button mat-button type="button" *ngIf="!getActionClass(action)" (click)="onActionClicked(action.key)">{{ action.titleKey | bmsTranslate: {count: 1} }}</button>
    </div>
  </div>
</div>
