import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SourceElementUtils} from '@application/helper/source-element-utils';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {BaseComponent, Color, DialogBuilderFactoryService, DOMUtils} from '@vdw/angular-component-library';
import {flatMap, isNil, take, uniqBy, without} from 'lodash-es';
import {debounceTime, takeUntil} from 'rxjs';

@Component({
  selector: 'app-color-list-preview',
  templateUrl: './color-list-preview.component.html',
  styleUrls: ['./color-list-preview.component.scss']
})
export class ColorListPreviewComponent extends BaseComponent implements AfterViewInit {
  @Input() public colorList: OverviewListColorSet | OverviewListColoredYarnSet;
  @Input() public maximumCreelPositionsToShow;
  @Input() public maximumColorsToShowPerCreelPosition = 4;
  @Input() public showNameOfFirstColor: boolean;
  @Input() public showDetailsOnClick = true;
  @Input() public positionOfDialog: PositionOfDialog = PositionOfDialog.LEFT;
  @Input() public showBoxShadow = true;
  @Input() public creelMapping: CreelMap = null;
  @Input() public startDent: number = null;
  @Output() public colorListSelected: EventEmitter<{colorList: OverviewListColorSet | OverviewListColoredYarnSet; event: MouseEvent}> = new EventEmitter<{
    colorList: OverviewListColorSet | OverviewListColoredYarnSet;
    event: MouseEvent;
  }>();

  @ViewChild('colorListPreviewContainer') public colorListPreviewContainer: ElementRef<HTMLDivElement>;
  @ViewChild('startDentLabel', {read: ElementRef}) public startDentLabelElement: ElementRef<HTMLLabelElement>;
  public creelPositionsToShow: OverviewListCreelPosition[];
  public amountOfCreelPositionsToShow: number;
  public colorMapping = 6;
  public readonly colorPreviewWidthInPx = 16;
  public readonly marginBetweenColorPreviewsInPx = 2;

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    DOMUtils.observeResize(this.colorListPreviewContainer.nativeElement)
      .pipe(debounceTime(250), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.calculateCreelPositionsToShow());
  }

  public moreCreelPositionsAvailable(): boolean {
    return this.amountOfCreelPositionsToShow < this.getColorSet().creelPositions.length;
  }

  public getCreelMapping(index: number): number {
    const currentPositionOnMachine = index + 1;
    return this.creelMapping?.creelPositions.find((value: {creelPosition: number; currentPositionOnMachine: number}) => currentPositionOnMachine === value.currentPositionOnMachine)?.creelPosition;
  }

  public isCreelMappingDoubleDigit(colorIndex: number): number {
    return this.creelMapping?.creelPositions[colorIndex]?.currentPositionOnMachine < 10 ? 5 : 2;
  }

  public colorHasMapping(colorIndex: number): boolean {
    return this.creelMapping?.creelPositions[colorIndex]?.creelPosition !== this?.creelMapping?.creelPositions[colorIndex]?.currentPositionOnMachine;
  }

  public getColorListPreviewContainerClass(): string {
    return this.startDent === null ? 'color-list-preview-container' : 'color-list-multiple-preview-containers';
  }

  public getNamesOfOtherColors(): string {
    return flatMap(without(this.getColorSet().creelPositions, ...this.creelPositionsToShow), (creelPosition: OverviewListCreelPosition) => {
      return flatMap(creelPosition.items, (color: Color) => color.name);
    }).join(', ');
  }

  public getAmountOfMoreCreelPositionsAvailable(): number {
    return Math.max(this.getColorSet().creelPositions.length - this.amountOfCreelPositionsToShow, 0);
  }

  public canShowNameOfFirstColorOfCreelPosition(creelPosition: OverviewListCreelPosition): boolean {
    return this.showNameOfFirstColor && creelPosition.items.length === 1;
  }

  public getFirstColorOfFirstCreelPosition(): Color {
    return this.getFirstColorOfCreelPosition(this.getColorSet().creelPositions[0]);
  }

  public getFirstColorOfCreelPosition(creelPosition: OverviewListCreelPosition): Color {
    return (creelPosition.items as Color[])[0];
  }

  public getColorsOfCreelPosition(creelPosition: OverviewListCreelPosition): Color[] {
    return uniqBy(creelPosition.items as Color[], 'id');
  }

  public showColorSetDetails(event: MouseEvent): void {
    if (this.showDetailsOnClick) {
      this.dialogBuilderFactoryService
        .getBuilder()
        .withClass(['reposition-dialog', this.getColorSet().creelPositions.length > 8 ? 'colored-list-dialog' : ''])
        .withWidth('706px')
        .withMaxHeight('100%')
        .openDialog(RepositionDialogComponent, {
          textileData: this.colorList,
          component: TextileDataDetailComponent,
          sourceElement: SourceElementUtils.findSourceElementForDialog(event, this.getColorListPreviewContainerClass(), this.positionOfDialog),
          positionOfDialog: this.positionOfDialog
        });
    } else {
      this.colorListSelected.emit({
        colorList: this.colorList,
        event
      });
    }
  }

  public getColorSet(): OverviewListColorSet {
    if (this.colorList instanceof OverviewListColorSet || isNil(this.colorList)) {
      return this.colorList;
    } else {
      return this.colorList.overviewListColorSet;
    }
  }

  public calculateCreelPositionsToShow(): void {
    this.amountOfCreelPositionsToShow = this.getAmountOfCreelPositionsToShow();
    this.creelPositionsToShow = this.getCreelPositionsToShow();

    this.changeDetectorRef.detectChanges();
  }

  private getCreelPositionsToShow(): OverviewListCreelPosition[] {
    return take(this.getColorSet().creelPositions, this.amountOfCreelPositionsToShow);
  }

  private getAmountOfCreelPositionsToShow(): number {
    let possibleCreelPositionsOnScreen = this.getPossibleCreelPositionsOnScreen();
    if (possibleCreelPositionsOnScreen < this.getColorSet().creelPositions.length) {
      possibleCreelPositionsOnScreen--;
    }

    return isNil(this.maximumCreelPositionsToShow)
      ? Math.min(possibleCreelPositionsOnScreen, this.getColorSet().creelPositions.length)
      : Math.min(possibleCreelPositionsOnScreen, this.maximumCreelPositionsToShow, this.getColorSet().creelPositions.length);
  }

  private getPossibleCreelPositionsOnScreen(): number {
    let widthOfCreelPositionListWithoutPossibleMoreBlock = this.colorListPreviewContainer.nativeElement.parentElement.parentElement.offsetWidth;

    if (this.startDentLabelElement) {
      widthOfCreelPositionListWithoutPossibleMoreBlock -= this.startDentLabelElement.nativeElement.offsetWidth;
    }

    return Math.floor(widthOfCreelPositionListWithoutPossibleMoreBlock / (this.colorPreviewWidthInPx + this.marginBetweenColorPreviewsInPx));
  }
}
