import {InjectionToken} from '@angular/core';
import {BackgroundJob} from '@domain/background-jobs/background-job';
import {BackgroundJobGlobalStatusUpdate} from '@domain/background-jobs/background-job-global-status-update';
import {Observable} from 'rxjs';

export const BACKGROUND_JOBS = new InjectionToken<BackgroundJobs>('BackgroundJobs');

export interface BackgroundJobs {
  getAll(): Observable<BackgroundJob[]>;
  retry(backgroundJob: BackgroundJob): Observable<void>;
  getGlobalStatus(): Observable<BackgroundJobGlobalStatusUpdate>;
  cancelJob(jobId: string): Observable<void>;
}
