import {OverviewListYarnTypeStubFactory} from '@application/helper/textile-data/yarn-type/overview-list-yarn-type-stub-factory';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {HorizontalPositionOfYarnTypeCreelPosition} from '@domain/textile-data/yarn-set/horizontal-position-of-yarn-type-creel-position';
import {YarnSet} from '@domain/textile-data/yarn-set/yarn-set';
import {times} from 'lodash-es';

export class YarnSetStubFactory {
  public static getRandomYarnSetOverviewListWithIdAndNumberOfCreelPositions(id: number, numberOfCreelPositions: number): YarnSet {
    return new YarnSet(
      id,
      `Yarn set ${id}`,
      times(numberOfCreelPositions, (creelPositionIndex: number) => {
        return new CreelPosition([new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(creelPositionIndex))]);
      }),
      false
    );
  }

  public static getRandomYarnSetWithIdAndName(id: number, name: string): YarnSet {
    return new YarnSet(
      1,
      name,
      [
        new CreelPosition([new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(1))]),
        new CreelPosition([
          new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(1)),
          new HorizontalPositionOfYarnTypeCreelPosition(1, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(2)),
          new HorizontalPositionOfYarnTypeCreelPosition(5, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(3))
        ]),
        new CreelPosition([
          new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(1)),
          new HorizontalPositionOfYarnTypeCreelPosition(5, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(3))
        ]),
        new CreelPosition([
          new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(1)),
          new HorizontalPositionOfYarnTypeCreelPosition(1, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(2)),
          new HorizontalPositionOfYarnTypeCreelPosition(5, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(3))
        ]),
        new CreelPosition([
          new HorizontalPositionOfYarnTypeCreelPosition(3, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(1)),
          new HorizontalPositionOfYarnTypeCreelPosition(1, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(2)),
          new HorizontalPositionOfYarnTypeCreelPosition(5, OverviewListYarnTypeStubFactory.getRandomOverviewListYarnTypeWithId(3))
        ])
      ],
      false
    );
  }

  public static getRandomYarnSetWithId(id: number): YarnSet {
    return this.getRandomYarnSetWithIdAndName(id, `Yarn set ${id}`);
  }
}
