import {duration, Duration, Moment} from 'moment';

export class ShiftInstance {
  private _name: string;
  private _start: Moment;
  private _end: Moment;
  private _durationInMinutes: number;

  public alternate = false;

  public constructor(start: Moment, end: Moment, name?: string) {
    this._name = name;
    this._start = start;
    this._end = end;
    this._durationInMinutes = this.getDuration().asMinutes();
  }

  public get name(): string {
    return this._name;
  }

  public get start(): Moment {
    return this._start;
  }

  public get end(): Moment {
    return this._end;
  }

  public get durationInMinutes(): number {
    return this._durationInMinutes;
  }

  public getDuration(): Duration {
    return duration(this.end.diff(this.start));
  }

  public fit(min: Moment, max: Moment): void {
    if (this.start < min) {
      this._start = min;
    }
    if (this.end > max) {
      this._end = max;
    }
    this._durationInMinutes = this.getDuration().asMinutes();
  }
}
