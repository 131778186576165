import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class ColortecMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.COLORTEC, name, orderId);
  }

  public static fromJSON(colortecMachineJSON: any): ColortecMachine {
    return new ColortecMachine(colortecMachineJSON.id, colortecMachineJSON.name, colortecMachineJSON.orderId);
  }
}
