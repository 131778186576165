import {InjectionToken} from '@angular/core';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {Observable} from 'rxjs';
import {DeletedPlanningItem} from './deleted-planning-item';

export const REALTIME_PLANNING = new InjectionToken<RealtimePlanning>('RealtimePlanning');

export interface RealtimePlanning {
  getPlanningChanges(): Observable<CalendarItemGroup[]>;
  getDeletedPlanningItem(): Observable<DeletedPlanningItem>;
}
