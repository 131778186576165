import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MobileSelectorOption} from '../mobile-dropdown/mobile-selector/mobile-selector-option.interface';

@Component({
  selector: 'vdw-mobile-bottom-selector',
  templateUrl: './mobile-bottom-selector.component.html',
  styleUrls: ['./mobile-bottom-selector.component.scss']
})
export class MobileBottomSelectorComponent {
  @Input() public selectedOption: MobileSelectorOption;
  @Input() public options: MobileSelectorOption[];
  @Input() public dialogTitleKey: string;
  @Output() public optionChange = new EventEmitter<MobileSelectorOption>();

  public get currentPositionIndex(): number {
    return this.options.findIndex((option: MobileSelectorOption) => option.id === this.selectedOption.id);
  }

  public get totalPositions(): number {
    return this.options.length;
  }

  public selectorChange(newOption: MobileSelectorOption): void {
    this.selectedOption = newOption;
    this.optionChange.emit(this.selectedOption);
  }

  public previousOption(): void {
    const currentIndex = this.currentPositionIndex;
    if (currentIndex > 0) {
      this.selectorChange(this.options[currentIndex - 1]);
    } else {
      this.selectorChange(this.options[this.totalPositions - 1]);
    }
  }

  public nextOption(): void {
    const currentIndex = this.currentPositionIndex;
    if (currentIndex < this.totalPositions - 1) {
      this.selectorChange(this.options[currentIndex + 1]);
    } else {
      this.selectorChange(this.options[0]);
    }
  }
}
