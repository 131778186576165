import {VelvetMachineDrawingInType} from './velvet-machine-drawing-in-type.enum';

export abstract class VelvetMachineDrawingIn {
  private _number: number;
  private _type: VelvetMachineDrawingInType;
  private _position: number;

  public constructor(number: number, type: VelvetMachineDrawingInType, position: number) {
    this._number = number;
    this._type = type;
    this._position = position;
  }

  public get number(): number {
    return this._number;
  }

  public get type(): VelvetMachineDrawingInType {
    return this._type;
  }

  public get position(): number {
    return this._position;
  }

  public abstract toJSON(): any;
}
