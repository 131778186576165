import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationSearchService {
  public navigationOpenSearchEmitter = new Subject<void>();
  public navigationCloseSearchEmitter = new Subject<void>();

  public notifySearchOpened(): void {
    this.navigationOpenSearchEmitter.next();
  }

  public notifySearchClosed(): void {
    this.navigationCloseSearchEmitter.next();
  }
}
