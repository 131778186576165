import {MachineGroup} from '@domain/machine/machine-group';
import {MachineType} from '@domain/machine/machine-type.enum';
import {PlasticsMachineType} from '@domain/machine/plastics-machine-type';
import {MachineOverviewList} from './machine-overview-list';

export class PlasticMachineOverviewList extends MachineOverviewList {
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _plasticsMachineType: PlasticsMachineType;

  public constructor(id: number, name: string, machineGroup: MachineGroup, standardSpeed: number, standardEfficiency: number, plasticsMachineType: PlasticsMachineType) {
    super(id, name, null, null, machineGroup, MachineType.PLASTIC_MACHINE);
    this._standardSpeed = standardSpeed;
    this._standardEfficiency = standardEfficiency;
    this._plasticsMachineType = plasticsMachineType;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public get plasticsMachineType(): PlasticsMachineType {
    return this._plasticsMachineType;
  }
}
