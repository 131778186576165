import {CutFrom} from '../../name-generator/enums/cut-from.enum';
import {NameGeneratorPart} from '../name-generator-part';
import {NameGeneratorPartType} from '../name-generator-part-type.enum';

export class EntityPropertyPart extends NameGeneratorPart {
  private _name: string;
  private _cutFromDirection: CutFrom;
  private _cutStartCharacter: number;
  private _cutLength: number;

  public constructor(name: string, cutFromDirection: CutFrom, cutStartCharacter: number, cutLength: number) {
    super();
    this.type = NameGeneratorPartType.PROPERTY_VALUE;
    this.name = name;
    this.cutFromDirection = cutFromDirection;
    this.cutStartCharacter = cutStartCharacter;
    this.cutLength = cutLength;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get cutFromDirection(): CutFrom {
    return this._cutFromDirection;
  }

  public set cutFromDirection(value: CutFrom) {
    this._cutFromDirection = value;
  }

  public get cutStartCharacter(): number {
    return this._cutStartCharacter;
  }

  public set cutStartCharacter(value: number) {
    this._cutStartCharacter = value;
  }

  public get cutLength(): number {
    return this._cutLength;
  }

  public set cutLength(value: number) {
    this._cutLength = value;
  }

  public toJSON(): JSON {
    return {
      type: this.type,
      name: this.name,
      cutFromDirection: this.cutFromDirection,
      cutStartCharacter: this.cutStartCharacter,
      cutLength: this.cutLength
    } as any as JSON;
  }
}
