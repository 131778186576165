import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class YarnRoute extends RouteBranch {
  private _addYarn: RouteLeaf;
  private _duplicateYarn: RouteLeaf;
  private _editYarn: RouteLeaf;

  public get addYarn(): RouteLeaf {
    return this._addYarn;
  }

  public set addYarn(value: RouteLeaf) {
    this.replace(this._addYarn, value);
    this._addYarn = value;
  }

  public get duplicateYarn(): RouteLeaf {
    return this._duplicateYarn;
  }

  public set duplicateYarn(value: RouteLeaf) {
    this.replace(this._duplicateYarn, value);
    this._duplicateYarn = value;
  }

  public get editYarn(): RouteLeaf {
    return this._editYarn;
  }

  public set editYarn(value: RouteLeaf) {
    this.replace(this._editYarn, value);
    this._editYarn = value;
  }
}
