import {Rectangle} from '@domain/rectangle';
import {FinishingTemplatePositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-position-in-carpet';
import {LabelPositionInLabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-position-in-label-place.enum';
import {CarpetView} from '@presentation/components/finishing-and-finishing-template/carpet-view.enum';
import {Point} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {LabelPlace} from '../label-place.enum';

export abstract class FinishingTemplateLabel {
  protected abstract _label: string;

  private _id: number;
  private _place: LabelPlace;
  private _rotation: number;
  private _mirrored: boolean;
  private _positionInTopCarpet: FinishingTemplatePositionInCarpet;
  private _positionInBottomCarpet: FinishingTemplatePositionInCarpet;

  public constructor(
    id: number,
    place: LabelPlace,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingTemplatePositionInCarpet,
    positionInBottomCarpet: FinishingTemplatePositionInCarpet
  ) {
    this._id = id;
    this._place = place;
    this._rotation = rotation;
    this._mirrored = mirrored;
    this._positionInTopCarpet = positionInTopCarpet;
    this._positionInBottomCarpet = positionInBottomCarpet;
  }

  public get label(): string {
    return this._label;
  }

  public get id(): number {
    return this._id;
  }

  public get place(): LabelPlace {
    return this._place;
  }

  public get rotation(): number {
    return this._rotation;
  }

  public set rotation(value: number) {
    this._rotation = value;
  }

  public get mirrored(): boolean {
    return this._mirrored;
  }

  public get positionInTopCarpet(): FinishingTemplatePositionInCarpet {
    return this._positionInTopCarpet;
  }

  public get positionInBottomCarpet(): FinishingTemplatePositionInCarpet {
    return this._positionInBottomCarpet;
  }

  public getOriginPointFromBoundingBox(rectangleForBoundingBox: Rectangle, carpetView: CarpetView): Point {
    let result: Point;

    const positioningInLabelPlace: LabelPositionInLabelPlace = isEqual(carpetView, CarpetView.TOP_CARPET)
      ? this._positionInTopCarpet.positioningInLabelPlace
      : this._positionInBottomCarpet.positioningInLabelPlace;

    switch (positioningInLabelPlace) {
      case LabelPositionInLabelPlace.TOP_LEFT:
        result = rectangleForBoundingBox.topLeftPoint;
        break;
      case LabelPositionInLabelPlace.TOP_MIDDLE:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx / 2,
          y: rectangleForBoundingBox.topLeftPoint.y
        };
        break;
      case LabelPositionInLabelPlace.TOP_RIGHT:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx,
          y: rectangleForBoundingBox.topLeftPoint.y
        };
        break;
      case LabelPositionInLabelPlace.CENTER_LEFT:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx / 2
        };
        break;
      case LabelPositionInLabelPlace.CENTER:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx / 2,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx / 2
        };
        break;
      case LabelPositionInLabelPlace.CENTER_RIGHT:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx / 2
        };
        break;
      case LabelPositionInLabelPlace.BOTTOM_LEFT:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx
        };
        break;
      case LabelPositionInLabelPlace.BOTTOM_MIDDLE:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx / 2,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx
        };
        break;
      case LabelPositionInLabelPlace.BOTTOM_RIGHT:
        result = {
          x: rectangleForBoundingBox.topLeftPoint.x + rectangleForBoundingBox.dimensionsInPx.widthInPx,
          y: rectangleForBoundingBox.topLeftPoint.y + rectangleForBoundingBox.dimensionsInPx.heightInPx
        };
        break;
    }

    return result;
  }

  public isTopCarpetSameAsBottomCarpet(): boolean {
    return (
      isEqual(this.positionInTopCarpet.commercialHorizontalOffsetInMM, this.positionInBottomCarpet.commercialHorizontalOffsetInMM) &&
      isEqual(this.positionInTopCarpet.commercialVerticalOffsetInMM, this.positionInBottomCarpet.commercialVerticalOffsetInMM) &&
      isEqual(this.positionInTopCarpet.positioningInCarpet, this.positionInBottomCarpet.positioningInCarpet)
    );
  }

  public abstract toJSON(): JSON;
}
