import {InjectionToken} from '@angular/core';
import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {Observable} from 'rxjs';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

export const PATTERNS = new InjectionToken<Patterns>('Patterns');

export interface Patterns extends UniqueIdentifierObjectRepository {
  getAllRestZonePatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]>;

  getAllFreeZonePatterns(machineQualityId: number): Observable<Pattern[]>;

  getAllJutePatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]>;

  getJutePatternsOrDrawings(weaveStructureId: number): Observable<Pattern[]>;

  getJutePatterns(weaveStructureId: number): Observable<Pattern[]>;

  getWeavePatternsOrDrawings(weaveStructureId: number, excludeColors: boolean): Observable<Pattern[]>;

  getPatternVisualisationByPatternId(weaveStructureId: number, weavePatternId: number, type: PatternVisualisationType): Observable<PatternVisualisation>;

  saveRestZonePattern(name: string, imageData: string, machineQualityId: number, colorSetId: number): Observable<number>;

  saveFreeZonePattern(name: string, imageData: string, machineQualityId: number): Observable<number>;

  saveJutePattern(name: string, imageData: string, machineQualityId: number, colorSetId: number): Observable<number>;

  saveJutePatternWithWeaveStructureId(name: string, imageData: string, weaveStructureId: number): Observable<number>;

  saveWeavePatternWithWeaveStructureId(name: string, imageData: string, weaveStructureId: number): Observable<number>;
}
