import {HorizontalPositionOfCreelPosition} from '@domain/textile-data/creel/horizontal-position-of-creel-position';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {fill, map} from 'lodash-es';
import {HorizontalPositionOfCreelPositionType} from '../creel/horizontal-position-of-creel-position-type.enum';

export class HorizontalPositionOfYarnTypeCreelPosition extends HorizontalPositionOfCreelPosition {
  private _yarnType: OverviewListYarnType;

  public constructor(amount: number, yarnType: OverviewListYarnType, isSelected: boolean = false) {
    super(HorizontalPositionOfCreelPositionType.YARN_TYPE, amount, isSelected);

    this._yarnType = yarnType;
  }

  public get yarnType(): OverviewListYarnType {
    return this._yarnType;
  }

  public static fromJSON(yarnTypeCreelPositionJSON: any): HorizontalPositionOfYarnTypeCreelPosition {
    return new HorizontalPositionOfYarnTypeCreelPosition(
      yarnTypeCreelPositionJSON.amount,
      new OverviewListYarnType(
        yarnTypeCreelPositionJSON.single.id,
        yarnTypeCreelPositionJSON.single.name,
        null,
        null,
        map(yarnTypeCreelPositionJSON.single.threads, (threadJSON: any) => {
          return {rawMaterial: threadJSON.rawMaterial};
        })
      )
    );
  }

  public toJSON(): JSON {
    return {
      type: HorizontalPositionOfCreelPositionType[this.type],
      id: this.yarnType.id,
      amount: this.amount
    } as any as JSON;
  }

  public getAmountOfDentsInHorizontalPositionOfCreelPosition(): number {
    return this.amount;
  }

  public getPatternForHorizontalPositionOfCreelPosition(): OverviewListYarnType[] {
    return fill(new Array(this.amount), this.yarnType);
  }
}
