import {InjectionToken} from '@angular/core';
import {ProductConfiguration} from '@domain/product-configuration/product-configuration';
import {SelectListProductConfiguration} from '@domain/product-configuration/select-list-product-configuration';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {OverviewListProductConfiguration} from '@presentation/pages/texedit/product-configuration/overview/overview-list-product-configuration';
import {GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

export const PRODUCT_CONFIGURATIONS = new InjectionToken<ProductConfigurations>('ProductConfiguration');

export interface ProductConfigurations
  extends UniqueIdentifierObjectRepository,
    CustomSettingsObjectRepository,
    Repository<ProductConfiguration, OverviewListProductConfiguration>,
    NameGenerationRepository<ProductConfiguration> {
  getSlice(gridModel: GridModel): Observable<OverviewListProductConfiguration[]>;
  updateLocked(id: number, locked: boolean): Observable<void>;
  updateReadyForProduction(id: number, version: number): Observable<void>;
  updateAdditionalInformation(id: number, version: number, additionalInformation: string): Observable<void>;
  getPossibleProductConfigurationsForMachine(machineId: number): Observable<SelectListProductConfiguration[]>;
  getCategoriesWithComponentsForProductConfigurationName(): Observable<PlaceholderCategory[]>;
  getPossibleValues(columnIdentifier: string): Observable<string[]>;
  getGeneratedProductConfigurationName(machineId: number, qualityId: number): Observable<string>;
}
