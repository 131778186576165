import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class TexBotTrainingRoute extends RouteBranch {
  private _machineDetails: RouteLeaf;
  private _details: RouteLeaf;
  private _manage: RouteLeaf;
  private _manageTags: RouteLeaf;
  private _manageDetails: RouteLeaf;

  public get machineDetails(): RouteLeaf {
    return this._machineDetails;
  }

  public set machineDetails(value: RouteLeaf) {
    this.replace(this._machineDetails, value);
    this._machineDetails = value;
  }

  public get details(): RouteLeaf {
    return this._details;
  }

  public set details(value: RouteLeaf) {
    this.replace(this._details, value);
    this._details = value;
  }

  public get manage(): RouteLeaf {
    return this._manage;
  }

  public set manage(value: RouteLeaf) {
    this.replace(this._manage, value);
    this._manage = value;
  }

  public get manageTags(): RouteLeaf {
    return this._manageTags;
  }

  public set manageTags(value: RouteLeaf) {
    this.replace(this._manageTags, value);
    this._manageTags = value;
  }

  public get manageDetails(): RouteLeaf {
    return this._manageDetails;
  }

  public set manageDetails(value: RouteLeaf) {
    this.replace(this._manageDetails, value);
    this._manageDetails = value;
  }
}
