import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {includes, isEqual, isNil} from 'lodash-es';
import {ENVIRONMENT, EnvironmentVariables} from '../../common/environment-variables';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {WINDOW} from '../../window/window.service';
import {AlertDialogConfirmationType, AlertDialogData, AlertDialogResult} from './alert-dialog-data';
import {AlertDialogUtils} from './alert-dialog-utils';

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  public dialogData: AlertDialogData;

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENVIRONMENT) private environment: EnvironmentVariables
  ) {
    this.dialogData = data;
  }

  public cancel(): void {
    if (this.hasDiscardButton() && this.hasSaveButton()) {
      this.discard();
    } else {
      this.dialogRef.close(AlertDialogResult.CANCEL);
    }
  }

  public save(): void {
    this.dialogRef.close(AlertDialogResult.SAVE);
  }

  public getChooseEventName(): string {
    if (this.hasReportButton()) {
      return this.getReportButtonText();
    }

    if (this.hasConfirmButton()) {
      return this.getConfirmButtonText();
    }
  }

  public chooseEvent(): void {
    if (this.hasReportButton()) {
      return this.report();
    }

    if (this.hasConfirmButton()) {
      return this.confirm();
    }
  }

  public canShowChooseButton(): boolean {
    return this.hasReportButton() || this.hasConfirmButton();
  }

  public hasSaveButton(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.dialogData) && this.dialogData.saveButton;
  }

  public hasCancelButton(): boolean {
    return !isNil(this.dialogData) && this.hasCancelText();
  }

  public hasReportButton(): boolean {
    return !isNil(this.dialogData) && this.hasConfirmationTypeReport();
  }

  public hasDiscardButton(): boolean {
    return !isNil(this.dialogData) && this.hasConfirmationTypeDiscard();
  }

  public hasDeleteButton(): boolean {
    return this.dialogData !== null && this.hasConfirmationTypeConfirmDelete();
  }

  public hasOverrideButton(): boolean {
    return this.dialogData !== null && this.hasConfirmationTypeOverride();
  }

  public getIcon(): string {
    return this.hasConfirmButtonText() ? 'empty-2' : 'report-warning';
  }

  public getIconClass(): string {
    return this.hasConfirmButtonText() ? 'empty-icon-content' : 'report-warning-icon-content';
  }

  public getConfirmButtonText(): string {
    let result = '';
    if (!isNil(this.dialogData)) {
      result = isNil(this.dialogData.confirmButtonText) ? AlertDialogUtils.getButtonTextForConfirmationType(AlertDialogConfirmationType.CONFIRM) : this.dialogData.confirmButtonText;
    }

    return result;
  }

  public getReportButtonText(): string {
    return this.hasConfirmButtonText() ? this.dialogData.confirmButtonText : 'ANGULAR_COMPONENT_LIBRARY.ALERT_DIALOG.REPORT';
  }

  public discard(): void {
    this.dialogRef.close(AlertDialogResult.DISCARD);
  }

  public confirm(): void {
    this.dialogRef.close(AlertDialogResult.CONFIRM);
  }

  public hasMessageText(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.dialogData.messageText);
  }

  public hasExtraInformationText(): boolean {
    return !isNil(this.dialogData.extraInformationText);
  }

  public canShowReportIssueText(): boolean {
    return this.hasReportButton() || this.hasDeleteButton() || this.hasOverrideButton() || this.hasDiscardButton();
  }

  public hasConfirmButtonText(): boolean {
    return !isNil(this.dialogData.confirmButtonText);
  }

  public navigateToUrl(route: string): void {
    this.window.open(route);
  }

  private hasConfirmButton(): boolean {
    return !isNil(this.dialogData) && (this.hasConfirmationTypeConfirm() || this.hasConfirmationTypeDelete());
  }

  private hasConfirmationTypeConfirm(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.CONFIRM);
  }

  private hasConfirmationTypeDiscard(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.DISCARD);
  }

  private hasConfirmationTypeDelete(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.DELETE);
  }

  private hasConfirmationTypeReport(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.REPORT);
  }

  private hasConfirmationTypeConfirmDelete(): boolean {
    return this.dialogData.confirmationTypes?.includes(AlertDialogConfirmationType.CONFIRM_DELETE);
  }

  private hasConfirmationTypeOverride(): boolean {
    return this.dialogData.confirmationTypes?.includes(AlertDialogConfirmationType.OVERRIDE);
  }

  private hasCancelText(): boolean {
    return !isEqual(this.dialogData.cancelButtonText, '');
  }

  private hasInformationUrl(): boolean {
    return this.dialogData.informationUrl ? true : false;
  }

  private report(): void {
    let urlToOpen = this.environment.helpUrl;
    if (this.hasInformationUrl()) {
      urlToOpen = this.dialogData.informationUrl;
    }

    this.window.open(urlToOpen);
  }
}
