import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PrintDocument} from '@application/command/print-document';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {FilterModelsUtils} from '@application/helper/filter-models-utils';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {PageUrls} from '@domain/textile-data/page-urls';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {PRODUCTION_ORDERS, ProductionOrders} from '@infrastructure/http/production-order/production-orders';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {ProductionOrderCreated} from '@infrastructure/signalr/production-order/production-order-created';
import {ProductionOrderStatusChange} from '@infrastructure/signalr/production-order/production-order-status-change';
import {REALTIME_PRODUCTION_ORDER, RealtimeProductionOrder} from '@infrastructure/signalr/production-order/realtime-production-order';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {getRowHeightForCreelPreview} from '@presentation/components/select-creel/get-row-height-for-creel-preview/get-row-height-for-creel-preview';
import {ConfirmProductionScheduleOnDesktopCommand} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-command';
import {ConfirmProductionScheduleOnDesktopResult} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-result';
import {ShowReasonForFailureDialogCommand} from '@presentation/pages/texfab/production-schedule/overview/actions/show-reason-for-failure-dialog-command';
import {OverviewListProductionSchedule} from '@presentation/pages/texfab/production-schedule/overview/overview-list-production-schedule';
import {TextileDataOverviewComponent} from '@presentation/pages/textile-data/textile-data-overview/textile-data-overview.component';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AlertDialogResult,
  BackendError,
  BaseComponent,
  convertUnit,
  DialogBuilder,
  DialogBuilderFactoryService,
  DialogType,
  EnumUtils,
  GridModel,
  ToastService,
  TranslateService,
  UnavailableArchiveError,
  UnhandledBackendError,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {CellClickedEvent, ColDef, GridOptions, IMultiFilterModel, IServerSideDatasource, IServerSideGetRowsParams, RowNode, SortModelItem} from 'ag-grid-community';
import {cloneDeep, every, filter, find, first, isEqual, isNil, lowerCase, map, replace, size} from 'lodash-es';
import {forkJoin, Observable} from 'rxjs';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';
import {ColumnUnitsMap} from './column-units-map';
import {ProductionScheduleOverviewService} from './production-schedule-overview.service';

@Component({
  templateUrl: './production-schedule-overview-page.component.html',
  styleUrls: ['./production-schedule-overview-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionScheduleOverviewPageComponent extends BaseComponent implements OnInit {
  @ViewChild('downloadAnchor') public downloadAnchor: ElementRef<HTMLAnchorElement>;
  @ViewChild('textileDataOverview') public textileDataOverview: TextileDataOverviewComponent;
  @ViewChild('exportProductionSchedulesGrid') public exportProductionSchedulesGrid: AgGridAngular;

  public listOfProductionOrdersCustomSettings: PropertyValue[];
  public advancedSearchFilters: PropertyValue[] = [];
  public loadingForTheFirstTime = true;
  public weftDensityUnit = Unit.WEFTS_PER_METER;
  public pickDensityUnit = Unit.PICKS_PER_METER;
  public reedDensityUnit = Unit.DENTS_PER_METER;
  public isActionsMenuOpen = false;
  public dataSource: IServerSideDatasource;
  public exportGridOptions: GridOptions = {};
  public exportData: OverviewListProductionSchedule[] = [];
  public isExporting = false;

  public readonly permissionToDownloadEpFiles = Permission.TEXFAB_VIEW;
  public readonly quantityOfRowsToRequestPerSlice = 10;
  public readonly GRID_IDENTIFIER = GridIdentifier.PRODUCTION_SCHEDULE_OVERVIEW;

  private listOfMachineQualitiesCustomSettings: PropertyValue[];
  private pageUrls: PageUrls;
  private isConfirmingProductionSchedule = false;
  private isPrintingProductionSchedule = false;
  private sort: {sort: string; colId: string};
  private selectedProductionSchedules: OverviewListProductionSchedule[] = [];
  private currentSubscription: Subscription;
  private dialogBuilder: DialogBuilder;

  private readonly printWeaveOrderPermission: Permission = Permission.PRINT_WEAVE_ORDER;
  private readonly machineFileTranslation = 'MACHINE.DETAILS.IMAGE_LIST.MACHINE_FILE';
  private readonly STATUS_COL_ID = 'status';
  private readonly MACHINE_COL_ID = 'machineName';
  private COLUMN_UNITS_MAP: ColumnUnitsMap;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(MACHINE_QUALITIES) private readonly machineQualities: MachineQualities,
    @Inject(PRODUCTION_SCHEDULES) public readonly productionSchedules: ProductionSchedules,
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    @Inject(REALTIME_PRODUCTION_ORDER) private readonly realtimeProductionOrder: RealtimeProductionOrder,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly printDocument: PrintDocument,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly showReasonForFailureDialogCommand: ShowReasonForFailureDialogCommand,
    private readonly confirmProductionScheduleOnDesktopCommand: ConfirmProductionScheduleOnDesktopCommand,
    private readonly overviewService: ProductionScheduleOverviewService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.COLUMN_UNITS_MAP = this.overviewService.getDefaultColumnUnitsMap();

    this.initialiseAdvancedSearchFilters();
    this.pageUrls = LastModifiedCardUtils.getPageUrls('productionSchedule');
    this.currentSubscription = this.authentication.getCurrentSubscription();

    if (this.hasPermissionToNavigateToNewBuilder()) {
      this.pageUrls.add = this.pageUrls.add + '/new';
      this.pageUrls.manageAdd = this.pageUrls.manageAdd + '/new';
      this.pageUrls.duplicate = this.pageUrls.duplicate + '/new';
      this.pageUrls.edit = this.pageUrls.edit + '/new';
    }

    this.getServerSideDataSource();
  }

  public getTextileDataType(): TextileDataType {
    if (this.hasPermissionToNavigateToNewBuilder()) {
      return TextileDataType.PRODUCTION_SCHEDULE_NEW;
    }

    return TextileDataType.PRODUCTION_SCHEDULE;
  }

  public getColumnDefsForListOfProductionSchedules(): ColDef[] {
    return this.overviewService.getColumnDefs(
      this.pageUrls?.edit,
      (columnIdentifier: string) => this.getPossibleColumnsValues(columnIdentifier),
      () => this.COLUMN_UNITS_MAP
    );
  }

  public getRowHeight(params: {data: OverviewListProductionSchedule}): number {
    let rowHeight = 32;
    const productionSchedule: OverviewListProductionSchedule = params.data;

    if (!!productionSchedule) {
      const heightForCreelPreviews = getRowHeightForCreelPreview(productionSchedule.coloredYarnSets);

      rowHeight = Math.max(rowHeight, heightForCreelPreviews);
    }

    return rowHeight;
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(this.pageUrls.settings);
  }

  public canDownloadEpFilesForSelectedProductionSchedule(): boolean {
    return this.isSingleProductionScheduleSelected() && this.getSelectedProductionSchedule().canDownloadEpFiles();
  }

  public downloadEpFilesForSelectedProductionSchedule(): void {
    if (this.canDownloadEpFilesForSelectedProductionSchedule()) {
      const selectedProductionSchedule = this.getSelectedProductionSchedule();

      this.productionOrders
        .downloadEpFiles(`${selectedProductionSchedule.id}`)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (file: ArrayBuffer) => {
            const blob = new Blob([file]);

            this.renderer.setAttribute(this.downloadAnchor.nativeElement, 'href', URL.createObjectURL(blob));
            this.renderer.setAttribute(this.downloadAnchor.nativeElement, 'download', `${selectedProductionSchedule.name}.zip`);

            this.downloadAnchor.nativeElement.click();
          },
          error: (error: UnhandledBackendError) => {
            if (error instanceof UnavailableArchiveError) {
              this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
                titleText: 'PRODUCTION_ORDER.OVERVIEW.DOWNLOAD_EPS',
                messageText: 'PRODUCTION_ORDER.WARNINGS.NOT_YET_ARCHIVED',
                type: DialogType.INFORMATION
              });
            } else {
              throw error;
            }
          }
        });
    }
  }

  public canRemoveMachineFilesForSelectedProductionSchedule(): boolean {
    return this.isSingleProductionScheduleSelected() && this.getSelectedProductionSchedule().canRemoveMachineFiles();
  }

  public removeMachineFilesForSelectedProductionSchedule(): void {
    if (this.canRemoveMachineFilesForSelectedProductionSchedule()) {
      this.dialogBuilderFactoryService
        .getBuilder()
        .openAlertDialog({
          titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: lowerCase(this.translate.instant(this.machineFileTranslation, {count: 2}))}),
          messageText: this.translate.instant('GENERAL.ACTIONS.CONFIRM_DELETE_DESCRIPTION'),
          type: DialogType.CONFIRM_DELETE
        })
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((result: AlertDialogResult) => {
          if (isEqual(result, AlertDialogResult.CONFIRM)) {
            this.deleteSelectedProductionScheduleMachineFiles(this.getSelectedProductionSchedule());
          }
        });
    }
  }

  public reloadProductionSchedules(advancedSearchFilters: PropertyValue[]): void {
    this.advancedSearchFilters = advancedSearchFilters;
    this.textileDataOverview.hideOverlay();
    this.textileDataOverview.ensureIndexIsVisible(0);
    this.refreshServerSide(true);
  }

  public openActionsMenu(): void {
    this.isActionsMenuOpen = true;
  }

  public closeActionsMenu(): void {
    this.isActionsMenuOpen = false;
  }

  public canConfirmSelectedProductionSchedule(): boolean {
    return this.isSingleProductionScheduleSelected() && this.getSelectedProductionSchedule().canBeConfirmed();
  }

  public confirmSelectedProductionSchedule(): void {
    if (!this.isConfirmingProductionSchedule && this.canConfirmSelectedProductionSchedule()) {
      this.isConfirmingProductionSchedule = true;
      const productionScheduleId = this.getSelectedProductionSchedule().id;

      this.confirmProductionScheduleOnDesktopCommand
        .getAndConfirmProductionSchedule(productionScheduleId, this.listOfProductionOrdersCustomSettings)
        .pipe(
          finalize(() => (this.isConfirmingProductionSchedule = false)),
          takeUntil(this.unSubscribeOnViewDestroy)
        )
        .subscribe((confirmProductionScheduleOnDesktopResult: ConfirmProductionScheduleOnDesktopResult) => {
          if (isEqual(confirmProductionScheduleOnDesktopResult, ConfirmProductionScheduleOnDesktopResult.CONFIRMED)) {
            this.updateProductionOrderStatus(productionScheduleId, ProductionScheduleStatus.COMPLETED);
          } else if (isEqual(confirmProductionScheduleOnDesktopResult, ConfirmProductionScheduleOnDesktopResult.PLAN_ADJUSTMENT_NEEDED)) {
            this.router.navigate([replace(this.pageUrls.edit, '/:id', ''), productionScheduleId]);
          }
        });
    }
  }

  public canPrintSelectedProductionScheduleWeavingOrder(): boolean {
    return this.isSingleProductionScheduleSelected() && this.getSelectedProductionSchedule().canPrintWeavingOrder() && this.hasPrintWeaveOrderPermission();
  }

  public printWeavingOrderForSelectedProductionSchedule(): void {
    if (!this.isPrintingProductionSchedule && this.canPrintSelectedProductionScheduleWeavingOrder()) {
      const selectedProductionSchedule = this.getSelectedProductionSchedule();
      this.isPrintingProductionSchedule = true;
      forkJoin([
        this.productionOrders.getWeavingOrderXMLForProductionOrder(Number(selectedProductionSchedule.id)),
        this.productionOrders.getWeavingOrderXSLTForProductionOrder(Number(selectedProductionSchedule.id))
      ])
        .pipe(
          finalize(() => (this.isPrintingProductionSchedule = false)),
          takeUntil(this.unSubscribeOnViewDestroy)
        )
        .subscribe((result: [string, string]) => {
          this.printDocument.execute(result);
        });
    }
  }

  public canShowFailureReasonForSelectedProductionSchedule(): boolean {
    return this.isSingleProductionScheduleSelected() && this.getSelectedProductionSchedule().hasFailed();
  }

  public showFailureReasonForSelectedProductionSchedule(): void {
    if (this.canShowFailureReasonForSelectedProductionSchedule()) {
      this.showReasonForFailureDialogCommand.execute(this.getSelectedProductionSchedule());
    }
  }

  public hasSelectedProductionSchedule(): boolean {
    return !isNil(this.getSelectedProductionSchedule());
  }

  public hasPrintWeaveOrderPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.printWeaveOrderPermission);
  }

  public productionSchedulesSelected(selectedProductionSchedules: OverviewListProductionSchedule[]): void {
    this.selectedProductionSchedules = selectedProductionSchedules;
  }

  public canCompleteProductionSchedules(): boolean {
    return every(this.selectedProductionSchedules, (selectedProductionSchedule: OverviewListProductionSchedule) => selectedProductionSchedule.canBeCompleted());
  }

  public canCompletePartiallyProductionSchedules(): boolean {
    return isEqual(size(this.selectedProductionSchedules), 1) && first(this.selectedProductionSchedules).canBeCompleted();
  }

  public completeProductionSchedules(): void {
    if (this.canCompleteProductionSchedules()) {
      const requests = map(this.selectedProductionSchedules, (selectedProductionSchedule: OverviewListProductionSchedule) =>
        this.productionOrders.completeProductionOrder(Number(selectedProductionSchedule.id), ProductionScheduleStatus.EXECUTED_COMPLETE)
      );

      forkJoin(requests).pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe();
    }
  }

  public completePartiallyProductionSchedules(): void {
    if (this.canCompletePartiallyProductionSchedules()) {
      this.editProductionSchedule(first(this.selectedProductionSchedules), true);
    }
  }

  public exportAllData(filterModel: IMultiFilterModel): void {
    this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();
    this.dialogBuilder.openAlertDialog({
      titleText: 'AGGRID.EXPORT',
      messageText: 'AGGRID.EXPORTMESSAGE',
      type: DialogType.INFORMATION
    });

    this.isExporting = true;
    this.exportProductionSchedulesGrid.api.setGridOption('columnDefs', this.getColumnDefsForListOfProductionSchedules());
    const sortModel: SortModelItem[] = [];
    let startRow: number = 0;
    let endRow: number = 30;
    const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);

    this.fetchExportData(gridModel);
  }

  private fetchExportData(gridModel: GridModel): void {
    this.productionOrders
      .getSlice(gridModel)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfProductionSchedules: OverviewListProductionSchedule[]) => {
        listOfProductionSchedules.forEach((productionSchedule: OverviewListProductionSchedule) => {
          this.exportData.push(productionSchedule);
        });

        if (listOfProductionSchedules.length < 29) {
          this.exportProductionSchedulesGrid.api.setGridOption('rowData', this?.exportData);
          this.exportGridOptions.cacheBlockSize = this?.exportData.length;
          this.exportProductionSchedulesGrid.api.exportDataAsCsv({
            processCellCallback: (params: any) => {
              if (params.column.colId === this.MACHINE_COL_ID) {
                return params.value.name;
              } else {
                return params.value;
              }
            },
            columnKeys: [
              'status',
              'name',
              'machineName',
              'technicalName',
              'pickDensity',
              'reedDensity',
              'pathWidthsInMM',
              'pathWidthsInDents',
              'lengthInMM',
              'lengthInPicks',
              'weftDensity',
              'dateCreated',
              'dueDate'
            ]
          });
          this.exportData = [];
          this.isExporting = false;
          this.dialogBuilder.close();
        } else {
          gridModel.startRow += 30;
          gridModel.endRow += 30;
          this.fetchExportData(gridModel);
        }
      });
  }

  private hasPermissionToNavigateToNewBuilder(): boolean {
    return this.currentSubscription?.hasPermission(Permission.TEXFAB_NEW_BUILDER);
  }

  private getServerSideDataSource(): void {
    this.dataSource = {
      getRows: (params: IServerSideGetRowsParams): void => {
        this.textileDataOverview.hideOverlay();
        const sources: Observable<PropertyValue[]>[] = [];
        const sortModel: SortModelItem[] = params.request.sortModel;
        const startRow: number = params.request.startRow;
        const endRow: number = params.request.endRow;
        const filterModel = this.convertFilterModel(cloneDeep(params.request.filterModel));
        const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);

        if (!this.haveCustomSettingsBeenLoaded()) {
          sources.push(...[this.machineQualities.getListOfCustomSettings(), this.productionOrders.getListOfCustomSettings()]);
        }

        forkJoin([this.productionOrders.getSlice(gridModel), ...sources])
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe({
            next: ([listOfProductionSchedules, listOfMachineQualitiesCustomSettings, listOfProductionOrdersCustomSettings]: [OverviewListProductionSchedule[], PropertyValue[], PropertyValue[]]) => {
              if (!this.haveCustomSettingsBeenLoaded()) {
                this.listOfMachineQualitiesCustomSettings = listOfMachineQualitiesCustomSettings;
                this.listOfProductionOrdersCustomSettings = listOfProductionOrdersCustomSettings;

                this.weftDensityUnit = Unit[this.getDefaultParameterForPropertyName('weftDensityUnit').propertyValue as string];
                this.pickDensityUnit = Unit[this.getDefaultParameterForPropertyName('pickDensityUnit').propertyValue as string];
                this.reedDensityUnit = Unit[this.getDefaultParameterForPropertyName('reedDensityUnit').propertyValue as string];
                this.COLUMN_UNITS_MAP = this.overviewService.updateColumnUnitsMap(this.COLUMN_UNITS_MAP, this.weftDensityUnit, this.pickDensityUnit, this.reedDensityUnit);

                this.changeDetectorRef.detectChanges();

                this.subscribeToRealtimeProductionOrderChanges();
              }

              let endRowPO: number;

              if (listOfProductionSchedules.length < this.quantityOfRowsToRequestPerSlice) {
                endRowPO = startRow + listOfProductionSchedules.length;
              }

              if (startRow === 0 && listOfProductionSchedules.length === 0) {
                this.textileDataOverview.showNoRowsOverlay();
              }

              this.loadingForTheFirstTime = false;

              this.changeDetectorRef.detectChanges();

              this.textileDataOverview.setLoadingCellRendererParams({
                loadingForTheFirstTime: this.loadingForTheFirstTime
              });

              params.success({
                rowData: listOfProductionSchedules,
                rowCount: endRowPO
              });
            },
            error: () => {
              params.success({
                rowData: [],
                rowCount: 0
              });
              this.textileDataOverview.showNoRowsOverlay();
            }
          });
      }
    };
  }

  private convertFilterModel(filterModel: {[key: string]: any}): {[key: string]: any} {
    Object.keys(filterModel).forEach((key: string) => {
      if (key in this.COLUMN_UNITS_MAP) {
        const numberFilterModel = filterModel[key].filterModels.find((model: any) => model?.filterType === 'number');
        if (numberFilterModel) {
          if (numberFilterModel.operator !== undefined) {
            numberFilterModel.conditions.forEach((condition: any) => this.convertNumberFilterValue(key, condition));
          } else {
            this.convertNumberFilterValue(key, numberFilterModel);
          }
        }

        const setFilterModel = filterModel[key].filterModels.find((model: any) => model?.filterType === 'set');
        if (setFilterModel) {
          setFilterModel.values = setFilterModel.values.map((value: string) => this.convertFilterValue(key, parseFloat(value)));
        }
      } else if (key === 'status') {
        FilterModelsUtils.setFilterModelsForColumnId(this.translate.instant('PRODUCTION_ORDER.PRODUCTION_SCHEDULE_COMBINED_STATES'), filterModel, key);
      }
    });
    return filterModel;
  }

  private convertNumberFilterValue(key: string, numberFilterModel: any): void {
    if (numberFilterModel.filter) {
      numberFilterModel.filter = this.convertFilterValue(key, numberFilterModel.filter);
    }
    if (numberFilterModel.filterTo) {
      numberFilterModel.filterTo = this.convertFilterValue(key, numberFilterModel.filterTo);
    }
  }

  private convertFilterValue(key: string, value: number): number {
    return convertUnit({
      from: {
        value,
        unit: this.COLUMN_UNITS_MAP[key].getDisplayUnit()
      },
      to: this.COLUMN_UNITS_MAP[key].dataToUnit
    });
  }

  private initialiseAdvancedSearchFilters(): void {
    this.advancedSearchFilters.push(
      {
        propertyName: AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS,
        possiblePropertyValues: filter(EnumUtils.getEnumValues(ProductionScheduleStatus), (status: ProductionScheduleStatus) => !isEqual(status, ProductionScheduleStatus.TO_PLAN))
      },
      {propertyName: AdvancedSearchInput.NAME},
      {propertyName: AdvancedSearchInput.MACHINE},
      {propertyName: AdvancedSearchInput.PICK_DENSITY},
      {propertyName: AdvancedSearchInput.REED_DENSITY},
      {propertyName: AdvancedSearchInput.COMPLETION_DATE_START},
      {propertyName: AdvancedSearchInput.COMPLETION_DATE_END}
    );
  }

  private deleteSelectedProductionScheduleMachineFiles(selectedProductionSchedule: OverviewListProductionSchedule): void {
    this.productionOrders
      .removeMachineFiles(selectedProductionSchedule.id, selectedProductionSchedule.machine.id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: () => {
          this.toastService.showInfo({
            tapToDismiss: false,
            timeOut: 2000,
            message: this.translate.instant('GENERAL.ACTIONS.DELETED_OBJECT', {object: this.translate.instant(this.machineFileTranslation, {count: 2})})
          });
        },
        error: (errorMessage: BackendError) => {
          this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
            titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: lowerCase(this.translate.instant(this.machineFileTranslation, {count: 2}))}),
            messageText: errorMessage.message,
            type: DialogType.INFORMATION
          });
        }
      });
  }

  private editProductionSchedule(event: CellClickedEvent | OverviewListProductionSchedule, completingPartially: boolean = false): void {
    this.router.navigate([this.pageUrls.edit.replace(':id', event instanceof OverviewListProductionSchedule ? event.id : event.data.id)], {
      state: {completingPartially}
    });
  }

  private getDefaultParameterForPropertyName(propertyName: string): PropertyValue {
    return find(this.listOfMachineQualitiesCustomSettings, ['propertyName', propertyName]);
  }

  private subscribeToRealtimeProductionOrderChanges(): void {
    this.realtimeProductionOrder
      .getProductionOrdersStatusChanges()
      .pipe(debounceTime(1000), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionOrderStatusChange: ProductionOrderStatusChange) => {
        if (this.isGridSortedByStatus()) {
          this.refreshServerSide(false);
        } else {
          this.updateProductionOrderStatus(productionOrderStatusChange.id, productionOrderStatusChange.status);
        }
      });

    this.realtimeProductionOrder
      .getProductionOrderCreated()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionOrderCreated: ProductionOrderCreated) => {
        this.updateProductionOrderId(productionOrderCreated.scheduleId, productionOrderCreated.productionOrderId);
      });
  }

  private updateProductionOrderStatus(id: number | string, status: ProductionScheduleStatus): void {
    this.textileDataOverview.forEachNode((node: RowNode) => {
      if (isEqual(node.data?.id, `${id}`)) {
        node.data.status = status;
        node.setData(node.data);
      }
    });
  }

  private updateProductionOrderId(oldId: string, newId: number): void {
    this.textileDataOverview.forEachNode((node: RowNode) => {
      if (isEqual(node.data?.id, oldId)) {
        node.data.id = `${newId}`;
        node.setData(node.data);
      }
    });
  }

  private getPossibleColumnsValues(columnIdentifier: string): Observable<string[] | number[]> {
    return this.productionOrders?.getPossibleValues(columnIdentifier).pipe(takeUntil(this.unSubscribeOnViewDestroy));
  }

  private refreshServerSide(purge: boolean): void {
    this.textileDataOverview.refreshServerSide(purge);
  }

  private isGridSortedByStatus(): boolean {
    return this.sort?.colId === this.STATUS_COL_ID;
  }

  private haveCustomSettingsBeenLoaded(): boolean {
    return !isNil(this.listOfMachineQualitiesCustomSettings) && !isNil(this.listOfProductionOrdersCustomSettings);
  }

  private getSelectedProductionSchedule(): OverviewListProductionSchedule {
    return first(this.selectedProductionSchedules);
  }

  private isSingleProductionScheduleSelected(): boolean {
    return isEqual(size(this.selectedProductionSchedules), 1);
  }
}
