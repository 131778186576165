<div #colorListPreviewContainer (click)="showColorSetDetails($event)" [ngClass]="getColorListPreviewContainerClass()" class="flex-row small-gap">
  <div *ngFor="let creelPosition of creelPositionsToShow; let index = index" class="creel-position-preview flex-row gap-8">
    <span class="color-name" *ngIf="canShowNameOfFirstColorOfCreelPosition(creelPosition)">{{ getFirstColorOfFirstCreelPosition().name }}</span>
    <div
      *ngIf="!creelPosition.hasMultipleItems()"
      [matTooltip]="getFirstColorOfCreelPosition(creelPosition)?.name"
      class="color-preview"
      [style.width.px]="colorPreviewWidthInPx"
      [style.background-color]="getFirstColorOfCreelPosition(creelPosition)?.hexadecimalColorCode"
    >
      <span *ngIf="creelMapping && colorHasMapping(index)" class="color-mapping b2 font-weight-bold">{{ getCreelMapping(index) }}</span>
    </div>
    <div *ngIf="creelPosition.hasMultipleItems()" class="multiple-color-creel-preview" [class.shadow]="showBoxShadow" [matTooltip]="creelPosition.getItemNames()">
      <div
        *ngFor="let color of getColorsOfCreelPosition(creelPosition) | slice: 0 : maximumColorsToShowPerCreelPosition"
        class="flex-start-start flex-1"
        [style.width.px]="colorPreviewWidthInPx"
        [style.background-color]="color?.hexadecimalColorCode"
      ></div>
      <span *ngIf="creelMapping && colorHasMapping(index)" [style.margin-left.px]="isCreelMappingDoubleDigit(index)" class="latte-color-mapping b2 font-weight-bold">{{ getCreelMapping(index) }}</span>
    </div>
  </div>

  <app-more-items-available *ngIf="moreCreelPositionsAvailable()" [maximumItemsToShow]="amountOfCreelPositionsToShow" [items]="getColorSet().creelPositions"></app-more-items-available>
  <label *ngIf="startDent" #startDentLabel vdw-ellipsis-label matTooltipPosition="above" [text]="startDent" class="start-dent b1 font-weight-semibold"></label>
</div>
