/* eslint-disable @angular-eslint/directive-selector */
import {BreakpointState} from '@angular/cdk/layout';
import {Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {BehaviorSubject, combineLatest, takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../utils/assertion-utils';
import {RESPONSIVENESS_VIEW_MODE, ResponsivenessViewMode} from './responsiveness-view.mode';

const mediaQueries: {[key: string]: string} = {
  'gt-xs': '(min-width: 600px)',
  'gt-sm': '(min-width: 960px)',
  'gt-md': '(min-width: 1280px)',
  'gt-lg': '(min-width: 1920px)',
  'lt-sm': '(max-width: 599.98px)',
  'lt-md': '(max-width: 959.97px)',
  'lt-lg': '(max-width: 1279.98px)',
  'lt-xl': '(max-width: 1919.98px)'
};

@Directive({
  selector: '[vdwBp.gt-xs], [vdwBp.gt-sm], [vdwBp.gt-md], [vdwBp.gt-lg], [vdwBp.lt-sm], [vdwBp.lt-md], [vdwBp.lt-lg], [vdwBp.lt-xl]'
})
export class ResponsiveClassDirective extends BaseComponent implements OnInit, OnChanges {
  @Input('vdwBp.gt-xs') public classGtXs: string | {[key: string]: boolean} = '';
  @Input('vdwBp.gt-sm') public classGtSm: string | {[key: string]: boolean} = '';
  @Input('vdwBp.gt-md') public classGtMd: string | {[key: string]: boolean} = '';
  @Input('vdwBp.gt-lg') public classGtLg: string | {[key: string]: boolean} = '';
  @Input('vdwBp.lt-sm') public classLtSm: string | {[key: string]: boolean} = '';
  @Input('vdwBp.lt-md') public classLtMd: string | {[key: string]: boolean} = '';
  @Input('vdwBp.lt-lg') public classLtLg: string | {[key: string]: boolean} = '';
  @Input('vdwBp.lt-xl') public classLtXl: string | {[key: string]: boolean} = '';

  private readonly reevaluateSubject = new BehaviorSubject(null);
  private classMap: {[key: string]: string} = {};

  public constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode
  ) {
    super();
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.evaluateClassMap();

    this.reevaluateSubject.next(null);
  }

  public ngOnInit(): void {
    this.evaluateClassMap();

    this.applyResponsiveClass();
  }

  private applyResponsiveClass(): void {
    combineLatest([this.responsivenessViewMode.observeBreakpointChanges(Object.values(mediaQueries)), this.reevaluateSubject])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([breakpointState, _]: [BreakpointState, any]) => {
        this.removeClasses();

        Object.keys(mediaQueries)
          .filter((key: string) => breakpointState.breakpoints[mediaQueries[key]] && this.classMap[key])
          .forEach((key: string) => this.applyClass(this.classMap[key]));
      });
  }

  private applyClass(className: string): void {
    const classNames = className.split(' ');
    for (const cn of classNames) {
      this.renderer.addClass(this.elementRef.nativeElement, cn);
    }
  }

  private removeClasses(): void {
    Object.values(this.classMap).forEach((className: string) => {
      if (className) {
        const classNames = className.split(' ');
        for (const c of classNames) {
          this.renderer.removeClass(this.elementRef.nativeElement, c);
        }
      }
    });
  }

  private evaluateClassMap(): void {
    this.classMap = {
      'gt-xs': ResponsiveClassDirective.createClasses(this.classGtXs),
      'gt-sm': ResponsiveClassDirective.createClasses(this.classGtSm),
      'gt-md': ResponsiveClassDirective.createClasses(this.classGtMd),
      'gt-lg': ResponsiveClassDirective.createClasses(this.classGtLg),
      'lt-sm': ResponsiveClassDirective.createClasses(this.classLtSm),
      'lt-md': ResponsiveClassDirective.createClasses(this.classLtMd),
      'lt-lg': ResponsiveClassDirective.createClasses(this.classLtLg),
      'lt-xl': ResponsiveClassDirective.createClasses(this.classLtXl)
    };
  }

  private static createClasses(className: string | {[key: string]: boolean}): string {
    if (AssertionUtils.isString(className)) {
      return className;
    }

    return Object.entries(className)
      .filter(([_, value]: [string, boolean]) => value)
      .map(([key, _]: [string, boolean]) => key)
      .join(' ');
  }
}
