import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoneInputHelperService {
  private popupStates: {[key: string]: boolean} = {};
  private focusStates: {[key: string]: boolean} = {};

  public isPopupVisible(key: string): boolean {
    return this.popupStates[key] || false;
  }

  public setPopupVisible(key: string, visible: boolean): void {
    this.popupStates[key] = visible;
  }

  public isInputFocused(key: string): boolean {
    return this.focusStates[key] || false;
  }

  public setInputFocused(key: string, focused: boolean): void {
    this.focusStates[key] = focused;
  }
}
