import {ColoredYarnSetForArticle} from '@domain/article/colored-yarn-set-for-article';
import {CreelPositionForArticle} from '@domain/article/creel-position-for-article';
import {FinishingForArticle} from '@domain/article/finishing-for-article';
import {MachineQualityForArticle} from '@domain/article/machine-quality-for-article';
import {DesignForOrderLine} from '@domain/orderbook/design-for-order-line';
import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {Drawing} from '@domain/production-schedule/drawing';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {AssertionUtils} from '@vdw/angular-component-library';

export class ArticleForOrderLine {
  private _id: number;
  private _name: string;
  private _machineQuality: MachineQualityForArticle;
  private _design: DesignForOrderLine | Drawing;
  private _colorConfiguration: {
    coloredYarnSet: ColoredYarnSetForArticle;
    mainColor: CreelPositionForArticle;
    borderColor: CreelPositionForArticle;
    colorSet: ColorSet;
  };

  private _finishing: FinishingForArticle;
  private _commercialWidthInMM: number;
  private _commercialHeightInMM: number;

  public constructor(
    id: number,
    name: string,
    machineQuality: MachineQualityForArticle,
    design: DesignForOrderLine | Drawing,
    colorConfiguration: {
      coloredYarnSet: ColoredYarnSetForArticle;
      mainColor: CreelPositionForArticle;
      borderColor: CreelPositionForArticle;
      colorSet: ColorSet;
    },
    finishing: FinishingForArticle,
    commercialWidthInMM: number,
    commercialHeightInMM: number
  ) {
    this._id = id;
    this._name = name;
    this._machineQuality = machineQuality;
    this._design = design;
    this._colorConfiguration = colorConfiguration;
    this._finishing = finishing;
    this._commercialWidthInMM = commercialWidthInMM;
    this._commercialHeightInMM = commercialHeightInMM;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get machineQuality(): MachineQualityForArticle {
    return this._machineQuality;
  }

  public get design(): DesignForOrderLine | Drawing {
    return this._design;
  }

  public get colorConfiguration(): {coloredYarnSet: ColoredYarnSetForArticle; mainColor: CreelPositionForArticle; borderColor: CreelPositionForArticle; colorSet: ColorSet} {
    return this._colorConfiguration;
  }

  public get finishing(): FinishingForArticle {
    return this._finishing;
  }

  public get commercialWidthInMM(): number {
    return this._commercialWidthInMM;
  }

  public get commercialHeightInMM(): number {
    return this._commercialHeightInMM;
  }

  public static fromJSON(articleForOrderLineJSON: any, isTargetOrderBook: boolean): ArticleForOrderLine {
    return new ArticleForOrderLine(
      articleForOrderLineJSON.id,
      articleForOrderLineJSON.name,
      MachineQualityForArticle.fromJSON(articleForOrderLineJSON.machineQuality),
      isTargetOrderBook ? DesignForOrderLine.fromJSON(articleForOrderLineJSON.design) : BmpDrawing.fromJSON(articleForOrderLineJSON.design),
      {
        coloredYarnSet: !AssertionUtils.isNullOrUndefined(articleForOrderLineJSON.coloredYarnSet) ? ColoredYarnSetForArticle.fromJSON(articleForOrderLineJSON.coloredYarnSet) : null,
        mainColor: !AssertionUtils.isNullOrUndefined(articleForOrderLineJSON.mainColor) ? CreelPositionForArticle.fromJSON(articleForOrderLineJSON.mainColor) : null,
        borderColor: !AssertionUtils.isNullOrUndefined(articleForOrderLineJSON.borderColor) ? CreelPositionForArticle.fromJSON(articleForOrderLineJSON.borderColor) : null,
        colorSet: !AssertionUtils.isNullOrUndefined(articleForOrderLineJSON.colorSet) ? ColorSet.fromJSON(articleForOrderLineJSON.colorSet) : null
      },
      !AssertionUtils.isNullOrUndefined(articleForOrderLineJSON.finishing) ? FinishingForArticle.fromJSON(articleForOrderLineJSON.finishing) : null,
      articleForOrderLineJSON.commercialWidthInMM,
      articleForOrderLineJSON.commercialHeightInMM
    );
  }
}
