import {AssertionUtils} from '@vdw/angular-component-library';
import {UUID} from 'crypto';
import {PlanningItemProperties} from './planning-item-properties';
import {PlanningItemType} from './planning-item-type';

export class PlanningItem {
  private _id?: number;
  private _draftId: UUID;
  private _name?: string;
  private _sequenceNumber: number;
  private _minimumDuration?: string;
  private _earliestStartDate?: Date;
  private _type: PlanningItemType;
  private _planningItems: PlanningItem[];
  private _actualStartDate?: Date;
  private _actualEndDate?: Date;
  private _estimatedEndDate?: Date;

  public constructor(itemProperties: PlanningItemProperties, type: PlanningItemType, ...planningItems: PlanningItem[]) {
    this._draftId = itemProperties.draftId;
    this._sequenceNumber = itemProperties.sequenceNumber;
    this._type = type;
    this._planningItems = planningItems;
    this._id = itemProperties.id;
    this._name = itemProperties.name;
    this._minimumDuration = itemProperties.minimumDuration;
    this._earliestStartDate = itemProperties.earliestStartDate;
    this._actualStartDate = itemProperties.actualStartDate;
    this._estimatedEndDate = itemProperties.estimatedEndDate;
    this._actualEndDate = itemProperties.actualEndDate;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get draftId(): UUID {
    return this._draftId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get sequenceNumber(): number {
    return this._sequenceNumber;
  }

  public set sequenceNumber(value: number) {
    this._sequenceNumber = value;
  }

  public get minimumDuration(): string {
    return this._minimumDuration;
  }

  public set minimumDuration(value: string) {
    this._minimumDuration = value;
  }

  public get earliestStartDate(): Date {
    return this._earliestStartDate;
  }

  public set earliestStartDate(value: Date) {
    this._earliestStartDate = value;
  }

  public get type(): PlanningItemType {
    return this._type;
  }

  public get planningItems(): PlanningItem[] {
    return this._planningItems;
  }

  public set planningItems(value: PlanningItem[]) {
    this._planningItems = value;
  }

  public get actualStartDate(): Date {
    return this._actualStartDate;
  }

  public get actualEndDate(): Date {
    return this._actualEndDate;
  }

  public get estimatedEndDate(): Date {
    return this._estimatedEndDate;
  }

  public get isActive(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.actualStartDate) && AssertionUtils.isNullOrUndefined(this.actualEndDate);
  }

  public get isPlanned(): boolean {
    return AssertionUtils.isNullOrUndefined(this.actualStartDate);
  }

  public get isCompleted(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.actualEndDate);
  }

  public toJSON(clearCompletedItems: boolean = false): JSON {
    const planningItems = [...(this.planningItems ?? [])].filter((item: PlanningItem) => !clearCompletedItems || !item.isCompleted);
    planningItems.sort(PlanningItem.compareBySequenceNumber);
    return {
      id: this.id,
      draftId: this.draftId,
      name: this.name,
      sequenceNumber: this.sequenceNumber,
      minimumDuration: this.minimumDuration,
      earliestStartDate: this.earliestStartDate,
      planningItems: planningItems.map((item: PlanningItem) => item.toJSON(clearCompletedItems)),
      actualStartDate: this.actualStartDate,
      actualEndDate: this.actualEndDate,
      type: this.type
    } as any as JSON;
  }

  public static compareBySequenceNumber(itemA: PlanningItem, itemB: PlanningItem): number {
    if (AssertionUtils.isNullOrUndefined(itemA.sequenceNumber)) {
      if (AssertionUtils.isNullOrUndefined(itemB.sequenceNumber)) {
        return itemA.actualStartDate.getTime() - itemB.actualStartDate.getTime();
      }
      return -1;
    } else if (AssertionUtils.isNullOrUndefined(itemB.sequenceNumber)) {
      return 1;
    }
    return itemA.sequenceNumber - itemB.sequenceNumber;
  }
}
