import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {TranslationModule} from '../translation/translation.module';
import {InvalidFormMessageComponent} from './invalid-form-message/invalid-form-message.component';

@NgModule({
  imports: [CommonModule, TranslationModule, MatIconModule, MatFormFieldModule, FlexLayoutModule, RouterModule],
  exports: [InvalidFormMessageComponent],
  declarations: [InvalidFormMessageComponent],
  providers: []
})
export class CommonFormsModule {}
