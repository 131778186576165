import {StringUtils as CommonStringUtils, TranslateService as CommonTranslateService, TranslateService} from '@vdw/angular-component-library';
import {get, isEmpty, join, lowerCase, map} from 'lodash-es';

export class StringUtils extends CommonStringUtils {
  public static createStringFromColorsOrYarnTypes(colorsOrYarnTypes: any[]): string {
    return join(map(colorsOrYarnTypes, 'name'), ', ');
  }

  public static getPropertyValueOrGeneralPlaceholderIfEmpty(objectToInspect: any, property: string, translate: TranslateService | CommonTranslateService): string {
    const value = get(objectToInspect, property);
    return isEmpty(value) ? translate.instant('GENERAL.NO_DATA', {object: lowerCase(translate.instant('GENERAL.NAME'))}) : value;
  }
}
