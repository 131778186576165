import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {map} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class DynamicPathGuard {
  private router: Router;

  public constructor(router: Router) {
    this.router = router;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (RouteUtils.isKnownPath(map(route.url, 'path'))) {
      setTimeout(() => {
        this.router.navigateByUrl(state.url);
      });
    } else {
      this.router.navigateByUrl(RouteUtils.paths.notFound.absolutePath);
    }

    return false;
  }
}
