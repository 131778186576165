import {DateRange} from '@domain/date-range';
import {moment} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';

export function getTotalRunningTimeInMsForDateRange<T extends {startDate: Date; endDate: Date}>(object: T, dateRange: DateRange): number {
  const momentDateRange = moment.range(dateRange);
  const momentItemDateRange = moment.range(object.startDate, object.endDate);
  const intersection = momentDateRange.intersect(momentItemDateRange);

  return !isNil(intersection) ? intersection.diff() : 0;
}
