import {VelvetMachineDrawingIn} from './velvet-machine-drawing-in';
import {VelvetMachineDrawingInType} from './velvet-machine-drawing-in-type.enum';

export class VelvetMachinePileDrawingIn extends VelvetMachineDrawingIn {
  public constructor(number: number, position: number) {
    super(number, VelvetMachineDrawingInType.PILE, position);
  }

  public toJSON(): any {
    return {
      number: this.number,
      type: VelvetMachineDrawingInType[this.type],
      position: this.position
    };
  }

  public static fromJSON(velvetMachinePileDrawingInJSON: any): VelvetMachinePileDrawingIn {
    return new VelvetMachinePileDrawingIn(velvetMachinePileDrawingInJSON.number, velvetMachinePileDrawingInJSON.position);
  }
}
