import {Component, HostBinding, Input} from '@angular/core';
import {TagColor} from './tag-color.enum';
import {TagSize} from './tag-size.enum';

@Component({
  selector: 'vdw-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() public value = '';
  @Input() public color: TagColor = TagColor.PRIMARY;
  @Input() public size: TagSize = TagSize.SMALL;

  @HostBinding('class') public get class(): string {
    return `${this.size} ${this.color}`;
  }
}
