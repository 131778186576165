<mat-form-field [style.width]="filterWidth" class="no-subscript">
  <input
    #inputElement
    vdwAutoFocus
    matInput
    appDataCy="filter"
    autocomplete="off"
    [ngModel]="filteredText"
    (ngModelChange)="filterChanged($event)"
    [disabled]="disabled"
    [placeholder]="placeholder | bmsTranslate"
  />
  <button appDataCy="filter-button" mat-icon-button matSuffix *ngIf="!isIconDisabled()" class="mat-icon-action-button-sm-without-border" (click)="iconClicked($event)" type="button">
    <mat-icon #iconElement [svgIcon]="icon"></mat-icon>
  </button>
</mat-form-field>
