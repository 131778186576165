import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LabelModule} from '../../custom-components/ellipsis-label/label.module';
import {HexValuePipeModule} from '../../custom-pipes/hex-value-pipe/hex-value-pipe.module';
import {BitPatternEditorFormFieldComponent} from './bit-pattern-editor-form-field/bit-pattern-editor-form-field.component';
import {BitPatternEditorComponent} from './bit-pattern-editor.component';

@NgModule({
  declarations: [BitPatternEditorComponent, BitPatternEditorFormFieldComponent],
  imports: [CommonModule, FlexLayoutModule, MatCheckboxModule, ReactiveFormsModule, LabelModule, FormsModule, MatFormFieldModule, MatInputModule, HexValuePipeModule],
  exports: [BitPatternEditorComponent, BitPatternEditorFormFieldComponent]
})
export class BitPatternEditorModule {}
