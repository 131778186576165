<section *ngIf="canShowArticleForm()" class="full-height flex-column">
  <vdw-tabs-menu-card
    [icon]="isEditingArticle() ? 'details-blue' : 'add-blue'"
    [action]="getActionText()"
    [title]="addArticleForm.controls.name.value"
    [isInUse]="isArticleBeingUsed()"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [isLoading]="isLoadingArticle"
    [hasPermissionToEdit]="hasEditPermission()"
    [isCreateNewPage]="!isEditingArticle()"
    [saving]="saving"
    [identifier]="HEADER_IDENTIFIER"
    (backEvent)="cancel()"
    (cancelEvent)="cancel()"
    (saveEvent)="saveArticle(SAVE_TYPE.SAVE)"
    (saveAndCloseEvent)="saveArticle(SAVE_TYPE.SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="saveArticle(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (deleteEvent)="deleteArticle()"
    (duplicateEvent)="duplicateArticle()"
    (inUseEvent)="openConflictsDialog()"
  ></vdw-tabs-menu-card>

  <div class="full-height flex-row">
    <mat-card class="skeleton-wrapper full-height no-margin overflow-auto full-width-mobile" [class.skeleton]="isLoadingArticle" [@fadeIn]="isLoadingArticle">
      <mat-card-content>
        <article>
          <form [formGroup]="addArticleForm" appBreadcrumbFormLinker>
            <fieldset class="full-width" [disabled]="!canEditArticle()">
              <div class="flex-column gap-24">
                <div class="flex-row gap-16" vdwBp.lt-sm="flex-column no-gap">
                  <div class="full-width form-field-skeleton-wrapper">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
                      <input matInput type="text" formControlName="name" />
                      <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
                        {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate: {attribute: 'GENERAL.NAME' | bmsTranslate} }}
                      </mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
                        {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <mat-form-field class="full-width">
                    <mat-label>{{ 'DESIGN_LIBRARY.DESIGN' | bmsTranslate: {count: 1} }}</mat-label>
                    <vdw-object-selection
                      formControlName="design"
                      [objectName]="'DESIGN_LIBRARY.DESIGN' | bmsTranslate: {count: 1} | lowercase"
                      (selectClicked)="selectDesign()"
                    ></vdw-object-selection>
                    <mat-error *vdwCanShowErrorForFormControl="'design'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field *appHasPermission="FINISHING_VIEW_PERMISSION" class="full-width">
                    <mat-label>
                      {{ 'TEXTILE_DATA.FINISHING.FINISHING' | bmsTranslate: {count: 1} }}<span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                    </mat-label>
                    <vdw-object-selection
                      formControlName="finishing"
                      [objectName]="'TEXTILE_DATA.FINISHING.FINISHING' | bmsTranslate: {count: 1} | lowercase"
                      [chipValueKey]="FINISHING_CHIP_VALUE_KEY"
                      [baseRouterLink]="getRouterLink()"
                      (selectClicked)="selectFinishing()"
                    ></vdw-object-selection>
                    <mat-error *vdwCanShowErrorForFormControl="'finishing'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <app-drawing-configuration
                  *ngIf="canShowDesignConfiguration()"
                  [form]="addArticleForm.get('configuration')"
                  [useColoredYarnSet]="true"
                  [drawingId]="addArticleForm.get('design').value.id"
                  [hasMissingDesign]="addArticleForm.get('design').value.missingDesign"
                  [allowToSaveAsEp]="false"
                  [drawingDimensionsInPx]="addArticleForm.get('design').value.dimensionsInPixels"
                  [canEditInputs]="canEditArticle()"
                  [canDelete]="false"
                  [canDeleteQuality]="true"
                  [allowNumberOfCreelPositions]="true"
                  [canShowInvalidFormMessageError]="canShowInvalidFormMessageError()"
                  [newItemId]="newItemId"
                  [dialogComponent]="dialogComponent"
                  (qualitySelected)="onQualityChanged($event)"
                >
                </app-drawing-configuration>
              </div>
            </fieldset>
          </form>
        </article>
      </mat-card-content>
    </mat-card>
  </div>
</section>
