import {ElementRef, EventEmitter, Injectable} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';

@Injectable({providedIn: 'root'})
export class CrudGridContextService {
  public grid: AgGridAngular;
  public gridContainer: ElementRef<HTMLDivElement>;

  public gridReady = new EventEmitter<void>();
}
