import {AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base-component';
import {PlanningGridComponent} from '../../planning-display/planning-grid/planning-grid.component';
import {PlanningRowComponent} from '../../planning-row/planning-row.component';
import {PlanningGroupData} from '../planning-item-base/planning-group-data';

@Component({
  selector: 'vdw-planning-group',
  templateUrl: './planning-group.component.html',
  styleUrls: ['./planning-group.component.scss']
})
export class PlanningGroupComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  public readonly data = new PlanningGroupData();

  @Input()
  public groupText: string;

  @Input()
  public set dataTransfer(value: any) {
    this.data.dataTransfer = value;
  }

  public get dataTransfer(): any {
    return this.data.dataTransfer;
  }

  @Input()
  public set startDate(value: Date) {
    this.data.startDate = value;
  }

  public get startDate(): Date {
    return this.data.startDate;
  }

  @Input()
  public set endDate(value: Date) {
    this.data.endDate = value;
  }

  public get endDate(): Date {
    return this.data.endDate;
  }

  @Input()
  public set applyCustomBorder(value: boolean) {
    this.data.applyCustomBorder = value;
  }

  public get applyCustomBorder(): boolean {
    return this.data.applyCustomBorder;
  }

  @Input()
  public set clickable(value: boolean) {
    this.data.clickable = value;
  }

  public get clickable(): boolean {
    return this.data.clickable;
  }

  @Output()
  public selected = this.data.selected;

  @ViewChild('groupTemplate')
  public set template(value: TemplateRef<any>) {
    this.data.template = value;
  }

  public constructor(
    private readonly row: PlanningRowComponent,
    private readonly grid: PlanningGridComponent,
    private readonly hostElement: ElementRef<HTMLElement>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.row.data.addItem(this.data);
    this.data.sortItems();
  }

  public ngAfterContentChecked(): void {
    this.data.sortItems();
    this.data.class = this.hostElement.nativeElement.className;
  }

  public ngOnDestroy(): void {
    this.row.data.removeItem(this.data);
  }

  public getEndDateForPositioning(): Date {
    return new Date(Math.min(this.endDate.getTime(), this.grid.endDate.getTime()));
  }

  public getStartDateForPositioning(): Date {
    return new Date(Math.max(this.startDate.getTime(), this.grid.startDate.getTime()));
  }
}
