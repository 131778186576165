export enum OnboardingNavigationStep {
  NOTIFICATIONS = 'NOTIFICATIONS',
  TEXSTATE = 'TEXSTATE',
  TEXBOT = 'TEXBOT',
  TEXEDIT = 'TEXEDIT',
  TEXSTYLE = 'TEXSTYLE',
  TEXFAB = 'TEXFAB',
  TEXBI = 'TEXBI',
  TEXCREDITS = 'TEXCREDITS',
  PROFILE = 'PROFILE',
  HELP = 'HELP',
  PROCESSES = 'PROCESSES'
}
