<section fxLayout="column" fxFlex>
  <vdw-header [title]="'PRODUCTION_ORDER.WEAVE_PLAN.CONFIRMATION.TITLE' | bmsTranslate" svgIcon="production-order-blue" [canShowClose]="true"></vdw-header>
  <article class="dialog-content">
    <div fxFlex="100%" fxLayout="column" fxLayoutGap="16px">
      <div [formGroup]="confirmationForm" *ngIf="canShowConfirmationForm()">
        <div fxLayout="row" fxLayoutGap="24px">
          <mat-form-field fxFlex="0 0 calc(50% - 12px)">
            <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="canShowErrorForFormControl('required', 'name')">
              {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
            </mat-error>
            <mat-error *ngIf="canShowErrorForFormControl('identifierTaken', 'name')">
              {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
            </mat-error>
          </mat-form-field>
          <vdw-datepicker-form-input
            [inputLabel]="'GENERAL.DATETIME.DUE_DATE' | bmsTranslate"
            [form]="confirmationForm"
            formControlNameValue="dueDate"
            [minValue]="getEarliestPossibleDueDate()"
            [hint]="'GENERAL.HINTS.UNAUTHORIZED' | bmsTranslate"
            [showHint]="!hasScheduleDueDatePermission()"
            fxFlex="0 0 calc(50% - 12px)"
          ></vdw-datepicker-form-input>
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-label>{{ 'GENERAL.COMMENT' | bmsTranslate }}</mat-label>
            <input matInput formControlName="comment" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="24px">
          <div fxFlex>
            <app-disabled-form-input [label]="'MACHINE.MACHINE' | bmsTranslate: {count: 1}" [value]="productionSchedule.machine.name"></app-disabled-form-input>
          </div>
          <div fxFlex>
            <app-disabled-form-input
              [label]="'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY' | bmsTranslate: {count: 1}"
              [value]="productionSchedule.machineQuality.technicalNameWithVersion"
            ></app-disabled-form-input>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="24px">
          <div fxFlex>
            <app-disabled-form-input [label]="'TEXTILE_DATA.FINISHING.FINISHING' | bmsTranslate: {count: 1}" [value]="productionSchedule.finishing?.nameWithVersion"></app-disabled-form-input>
          </div>
          <mat-form-field *appHasPermission="accessAllPermission" fxFlex>
            <mat-label>{{ 'PRODUCTION_ORDER.REPEAT_ORDER' | bmsTranslate }}</mat-label>
            <input (change)="amountChanged($event.target.value)" #quantityInput matInput appL10nDecimal [value]="productionSchedule.numberOfRepeats" type="number" min="0" />
            <mat-hint>{{ getNumberOfRepeatsForecast() }}</mat-hint>
            <mat-icon (click)="incrementNumberOfRepeats()" class="arrow arrow-up icon-16" svgIcon="up"></mat-icon>
            <mat-icon (click)="decrementNumberOfRepeats()" class="arrow arrow-down icon-16" svgIcon="down"></mat-icon>
          </mat-form-field>
        </div>
      </div>

      <app-warnings-for-production-schedule-plan
        *ngIf="canShowWarningsForProductionSchedule()"
        [maximumLossOfProductionScheduleInPercent]="maximumLossOfProductionScheduleInPercent"
        [productionScheduleWarnings]="productionSchedule.warnings"
      >
      </app-warnings-for-production-schedule-plan>

      <app-production-schedule-builder-summary
        fxFlex
        #productionScheduleBuilderSummary
        [productionSchedule]="productionSchedule"
        [commercialUnit]="commercialUnit"
        [listOfCustomSettings]="listOfProductionOrdersCustomSettings"
      >
      </app-production-schedule-builder-summary>
    </div>
  </article>

  <div class="flex-column">
    <vdw-footer
      [locked]="productionSchedule.missingDesigns"
      chooseEventName="PRODUCTION_ORDER.ACTIONS.PLAN_AND_QUEUE"
      [centered]="true"
      [withChooseButton]="canPlanAndQueue()"
      [chooseEventInProgress]="completingAndQueuing"
      [canShowBMSTheme]="true"
      (chooseEvent)="completeAndQueue()"
    >
      <vdw-progress-button
        *ngIf="canProcess()"
        label="PRODUCTION_ORDER.ACTIONS.PROCESS"
        [inProgress]="processing"
        [raisedButton]="!productionSchedule.machine.isMachinePremium()"
        (progressClick)="process()"
        [canShowBMSTheme]="true"
      ></vdw-progress-button>

      <vdw-progress-button
        *ngIf="canShowProcessAndSendButton()"
        label="PRODUCTION_ORDER.ACTIONS.PROCESS_AND_SEND"
        [inProgress]="completingAndProcess"
        [disabled]="!productionSchedule.machine.isMachinePremium()"
        [raisedButton]="isProcessAndSendRaisedButton()"
        (progressClick)="completeAndQueue()"
        [canShowBMSTheme]="true"
      ></vdw-progress-button>

      <vdw-progress-button
        *ngIf="canPlanAndQueue()"
        label="PRODUCTION_ORDER.ACTIONS.PLAN"
        [inProgress]="completingAndPlanning"
        [raisedButton]="false"
        (progressClick)="completeAndPlan()"
        [canShowBMSTheme]="true"
      ></vdw-progress-button>
      <div class="flex-row flex-end-center" *ngIf="!productionSchedule.machine.isMachinePremium()" hints>
        <p class="b1 hint">({{ 'GENERAL.HINTS.MACHINE_NOT_PREMIUM' | bmsTranslate }})</p>
      </div>
    </vdw-footer>
  </div>
</section>
