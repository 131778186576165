import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class TexStateRoute extends RouteBranch {
  private _settings: RouteLeaf;
  private _editMachine: RouteLeaf;
  private _kiosk: RouteLeaf;
  private _addTemplate: RouteLeaf;
  private _editTemplate: RouteLeaf;
  private _duplicateTemplate: RouteLeaf;
  private _addWeavingMachineType: RouteLeaf;
  private _addTuftingMachineType: RouteLeaf;
  private _addWinderMachineType: RouteLeaf;
  private _addDyeingMachineType: RouteLeaf;
  private _addPlasticMachineType: RouteLeaf;
  private _prototype: RouteLeaf;

  public get editMachine(): RouteLeaf {
    return this._editMachine;
  }

  public set editMachine(value: RouteLeaf) {
    this.replace(this._editMachine, value);
    this._editMachine = value;
  }

  public get settings(): RouteLeaf {
    return this._settings;
  }

  public set settings(value: RouteLeaf) {
    this.replace(this._settings, value);
    this._settings = value;
  }

  public get addTemplate(): RouteLeaf {
    return this._addTemplate;
  }

  public set addTemplate(value: RouteLeaf) {
    this.replace(this._addTemplate, value);
    this._addTemplate = value;
  }

  public get editTemplate(): RouteLeaf {
    return this._editTemplate;
  }

  public set editTemplate(value: RouteLeaf) {
    this.replace(this._editTemplate, value);
    this._editTemplate = value;
  }

  public get duplicateTemplate(): RouteLeaf {
    return this._duplicateTemplate;
  }

  public set duplicateTemplate(value: RouteLeaf) {
    this.replace(this._duplicateTemplate, value);
    this._duplicateTemplate = value;
  }

  public get kiosk(): RouteLeaf {
    return this._kiosk;
  }

  public set kiosk(value: RouteLeaf) {
    this.replace(this._kiosk, value);
    this._kiosk = value;
  }

  public get addWeavingMachineType(): RouteLeaf {
    return this._addWeavingMachineType;
  }

  public set addWeavingMachineType(value: RouteLeaf) {
    this.replace(this._addWeavingMachineType, value);
    this._addWeavingMachineType = value;
  }

  public get addTuftingMachineType(): RouteLeaf {
    return this._addTuftingMachineType;
  }

  public set addTuftingMachineType(value: RouteLeaf) {
    this.replace(this._addTuftingMachineType, value);
    this._addTuftingMachineType = value;
  }

  public get addWinderMachineType(): RouteLeaf {
    return this._addWinderMachineType;
  }

  public set addWinderMachineType(value: RouteLeaf) {
    this.replace(this._addWinderMachineType, value);
    this._addWinderMachineType = value;
  }

  public get addDyeingMachineType(): RouteLeaf {
    return this._addDyeingMachineType;
  }

  public set addDyeingMachineType(value: RouteLeaf) {
    this.replace(this._addDyeingMachineType, value);
    this._addDyeingMachineType = value;
  }

  public get addPlasticMachineType(): RouteLeaf {
    return this._addPlasticMachineType;
  }

  public set addPlasticMachineType(value: RouteLeaf) {
    this.replace(this._addPlasticMachineType, value);
    this._addPlasticMachineType = value;
  }

  public get prototype(): RouteLeaf {
    return this._prototype;
  }

  public set prototype(value: RouteLeaf) {
    this.replace(this._prototype, value);
    this._prototype = value;
  }
}
