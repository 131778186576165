import {Inject, Injectable} from '@angular/core';
import {RUNTIME_APPLICATION_ENVIRONMENT, RuntimeApplicationEnvironment} from '@application/configuration/runtime-application-environment';
import {ApplicationMaintenance} from '@domain/profile/application-maintenance';
import {AssertionUtils, LocalStorageService, WINDOW} from '@vdw/angular-component-library';
import {isEmpty, keys, map, replace} from 'lodash-es';

@Injectable()
export class BackendConfiguration {
  private localStorageService: LocalStorageService;
  private browserInstanceId: string;

  private readonly storageConstants = {
    SUBSCRIPTION_ID: 'subscription.id',
    COMPANY_ID: 'company.id',
    APPLICATION_MAINTENANCE: 'applicationMaintenance'
  };

  public constructor(
    localStorageService: LocalStorageService,
    @Inject(RUNTIME_APPLICATION_ENVIRONMENT) private runtimeEnvironment: RuntimeApplicationEnvironment,
    @Inject(WINDOW) private readonly window: Window
  ) {
    this.localStorageService = localStorageService;
  }

  public getEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.endpoint, versionNumber);
  }

  public getMachineOverviewEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.machineOverviewEndpoint, versionNumber);
  }

  public getProductionScheduleEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.productionScheduleEndpoint, versionNumber);
  }

  public getProductionOrderEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.productionOrderEndpoint, versionNumber);
  }

  public getAggregatorEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.aggregatorEndpoint, versionNumber);
  }

  public getWorkerEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.workerEndpoint, versionNumber);
  }

  public getAuthenticationEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.authenticationEndpoint, versionNumber);
  }

  public getMachineEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.machineEndpoint, versionNumber);
  }

  public getIotConfigEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.iotConfigEndpoint, versionNumber);
  }

  public getAlertingEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.alertingEndpoint, versionNumber);
  }

  public getImageProcessingEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.imageProcessingEndpoint, versionNumber);
  }

  public getSettingsEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.settingsEndpoint, versionNumber);
  }

  public getPlanningEndpoint(versionNumber: number = 1): string {
    return this.getEndpointWithVersionNumber(this.runtimeEnvironment.getEnvironment().origin.planningEndpoint, versionNumber);
  }

  public getSubscriptionId(): string {
    const storedSubscriptionId: string = this.localStorageService.get(this.storageConstants.SUBSCRIPTION_ID);
    return AssertionUtils.isNullOrUndefined(storedSubscriptionId) ? '' : storedSubscriptionId;
  }

  public setSubscriptionId(id: string): void {
    this.localStorageService.set(this.storageConstants.SUBSCRIPTION_ID, id);
  }

  public setApplicationMaintenance(applicationMaintenance: ApplicationMaintenance): void {
    if (!applicationMaintenance) {
      this.localStorageService.remove(this.storageConstants.APPLICATION_MAINTENANCE);
    } else {
      this.localStorageService.set(this.storageConstants.APPLICATION_MAINTENANCE, applicationMaintenance);
    }
  }

  public getApplicationMaintenance(): ApplicationMaintenance {
    return this.localStorageService.get<ApplicationMaintenance>(this.storageConstants.APPLICATION_MAINTENANCE);
  }

  public getCompanyId(): string {
    const storedCompanyId: string = this.localStorageService.get(this.storageConstants.COMPANY_ID);
    return AssertionUtils.isNullOrUndefined(storedCompanyId) ? '' : storedCompanyId;
  }

  public getBrowserInstanceId(): string {
    return AssertionUtils.isNullOrUndefined(this.browserInstanceId) ? '' : this.browserInstanceId;
  }

  public setCompanyId(id: string): void {
    this.localStorageService.set(this.storageConstants.COMPANY_ID, id);
  }

  public getHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.hubEndPoint;
  }

  public getNotificationsHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.notificationsHubEndPoint;
  }

  public getReportItemsHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.reportItemsHubEndPoint;
  }

  public getPlanningHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.planningHubEndPoint;
  }

  public getAlertingHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.alertingHubEndPoint;
  }

  public getMachineHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.machineHubEndPoint;
  }

  public getMachineOverviewHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.machineOverviewHubEndPoint;
  }

  public getProcessingHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.processingHubEndPoint;
  }

  public getTexCatalogHubEndPoint(): string {
    return this.runtimeEnvironment.getEnvironment().origin.texCatalogHubEndPoint;
  }

  public getAuthenticationAuthority(): string {
    return this.runtimeEnvironment.getEnvironment().authentication.authority;
  }

  public getAuthenticationClientId(): string {
    return this.runtimeEnvironment.getEnvironment().authentication.clientId;
  }

  public getAuthenticationScopes(): string[] {
    return this.runtimeEnvironment.getEnvironment().authentication.scopes;
  }

  public getRedirectURL(): string {
    const redirectUrl = this.runtimeEnvironment.getEnvironment().authentication.redirectUrl;
    return isEmpty(redirectUrl) ? `${this.window.location.origin}/login` : redirectUrl;
  }

  public getRetryAttempts503(): number {
    return this.runtimeEnvironment.getEnvironment().retryAttempts503;
  }

  public getApplicationInsightsInstrumentationKey(): string {
    return this.runtimeEnvironment.getEnvironment().appInsightsInstrumentationKey;
  }

  public clearLocalStorage(): void {
    this.localStorageService.remove(...map(keys(this.storageConstants), (storageConstantKey: string) => this.storageConstants[storageConstantKey]));
  }

  private getEndpointWithVersionNumber(baseEndpointUrl: string, versionNumber: number): string {
    return replace(baseEndpointUrl, '{versionNumber}', `${versionNumber}`);
  }

  public setBrowserInstanceId(id: string): void {
    this.browserInstanceId = id;
  }
}
