import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {YarnSet} from '@domain/textile-data/yarn-set/yarn-set';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {isNil} from 'lodash-es';

export class WeftColoredYarnSet extends OverviewListColoredYarnSet {
  private readonly _colorSet: ColorSet;
  private readonly _yarnSet: YarnSet;

  public constructor(id: number, name: string, colorSet: ColorSet, yarnSet: YarnSet, used: boolean) {
    const overviewListColorSet: OverviewListColorSet = !isNil(colorSet) ? OverviewListColorSet.fromColorSet(colorSet) : null;
    const overviewListYarnSet: OverviewListYarnSet = !isNil(yarnSet) ? OverviewListYarnSet.fromYarnSet(yarnSet) : null;

    super(id, name, overviewListColorSet, overviewListYarnSet, used);
    this._colorSet = colorSet;
    this._yarnSet = yarnSet;
  }

  public get colorSet(): ColorSet {
    return this._colorSet;
  }

  public get yarnSet(): YarnSet {
    return this._yarnSet;
  }

  public static createEmptyWeftColoredYarnSet(): WeftColoredYarnSet {
    return new WeftColoredYarnSet(null, null, null, null, null);
  }

  public static fromJSON(weftColoredYarnSetJSON: any): WeftColoredYarnSet {
    return new WeftColoredYarnSet(
      weftColoredYarnSetJSON.id,
      weftColoredYarnSetJSON.name,
      ColorSet.fromJSON(weftColoredYarnSetJSON.colorSet),
      YarnSet.fromJSON(weftColoredYarnSetJSON.yarnSet),
      weftColoredYarnSetJSON.used
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      colorSetId: this.colorSet.id,
      yarnSetId: this.yarnSet.id
    } as any as JSON;
  }
}
