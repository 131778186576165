import {PlanningEquipment} from '../planning-equipment';

export class CompatibleMachine {
  private _machine: PlanningEquipment;
  private _estimatedProductionTime: string;

  public constructor(machine: PlanningEquipment, estimatedProductionTime: string) {
    this._machine = machine;
    this._estimatedProductionTime = estimatedProductionTime;
  }

  public get machine(): PlanningEquipment {
    return this._machine;
  }

  public get estimatedProductionTime(): string {
    return this._estimatedProductionTime;
  }

  public static fromJSON(json: any): CompatibleMachine {
    return new CompatibleMachine(PlanningEquipment.fromJSON(json.machine), json.estimatedProductionTime);
  }
}
