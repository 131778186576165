<app-textile-data-overview
  iconForCard="articles"
  [columnDefs]="getColumnDefs()"
  [listOfTextileData]="listOfArticles"
  [textileDataService]="articles"
  [textileDataType]="textileDataType.ARTICLE"
  [gridIdentifier]="GRID_IDENTIFIER"
  [multiSelection]="true"
  (exportGridWithComplexColumns)="exportGridForArticles($event)"
  [showSearchBar]="false"
>
</app-textile-data-overview>
