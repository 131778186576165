import {StringUtils} from '@application/helper/string-utils';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';

export class MachineQualityForArticle {
  private _id: number;
  private _name: string;
  private _version: number;
  private _yarnSet: OverviewListYarnSet;

  public constructor(id: number, name: string, version: number, yarnSet?: OverviewListYarnSet) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._yarnSet = yarnSet;
  }

  public get id(): number {
    return this._id;
  }

  private get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get yarnSet(): OverviewListYarnSet {
    return this._yarnSet;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.name, this.version);
  }

  public static fromJSON(machineQualityForArticleJSON: any): MachineQualityForArticle {
    return new MachineQualityForArticle(
      machineQualityForArticleJSON.id,
      machineQualityForArticleJSON.name,
      machineQualityForArticleJSON.version,
      machineQualityForArticleJSON.yarnSet ? OverviewListYarnSet.fromJSON(machineQualityForArticleJSON.yarnSet) : null
    );
  }

  public static fromOverviewListMachineQuality(overviewListMachineQuality: OverviewListMachineQuality): MachineQualityForArticle {
    return new MachineQualityForArticle(overviewListMachineQuality.id, overviewListMachineQuality.technicalName, overviewListMachineQuality.version);
  }
}
