<div class="mobile-overview full-height">
  <vdw-mobile-page-header
    [title]="'NOTIFICATIONS.MENU_NAME' | notificationMobileOverviewHeader: (unreadCount | async)"
    [showBackButton]="false"
    [icon]="unreadCount && FILTER_ICON"
    (iconClicked)="onFilterClicked()"
  ></vdw-mobile-page-header>

  <ng-container *appHasPermission="VIEW_NOTIFICATIONS_PERMISSION">
    <app-notification-list class="full-height" [notificationList]="notificationsList" (listScrolled)="getNotifications()"></app-notification-list>

    <div *ngIf="unreadCount | async" class="mark-as-read full-width">
      <span class="b2 url" (click)="markAllAsRead()">{{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | bmsTranslate }}</span>
    </div>
  </ng-container>

  <div *appHasNotPermission="VIEW_NOTIFICATIONS_PERMISSION" class="full-height flex-center">
    <vdw-overlay class="unauthorized-container" titleKey="NOTIFICATIONS.UNAUTHORIZED_TITLE" [hideActions]="true" [descriptionKey]="'NOTIFICATIONS.UNAUTHORIZED_DESCRIPTION'" [scale]="scaleImage">
    </vdw-overlay>
  </div>
</div>
