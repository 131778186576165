import {EnumUtils} from './utils/enum-utils';

export enum Unit {
  DENTS = 'd',
  PICKS = 'p',
  DENTS_PER_METER = 'd/m',
  DENTS_PER_CENTIMETER = 'd/cm',
  DENTS_PER_MILLIMETER = 'd/mm',
  PICKS_PER_METER = 'p/m',
  PICKS_PER_DECIMETER = 'p/dm',
  PICKS_PER_CENTIMETER = 'p/cm',
  PICKS_PER_MILLIMETER = 'p/mm',
  WEFTS_PER_METER = 'w/m',
  WEFTS_PER_DECIMETER = 'w/dm',
  WEFTS_PER_CENTIMETER = 'w/cm',
  WEFTS_PER_MILLIMETER = 'w/mm',
  METER = 'm',
  DECIMETER = 'dm',
  CENTIMETER = 'cm',
  MILLIMETER = 'mm',
  SQUARE_METER = 'm²',
  SQUARE_CENTIMETER = 'cm²',
  SQUARE_MILLIMETER = 'mm²',
  NUMBER_METRIC = 'nm',
  DENIER = 'den',
  TEX = 'tex',
  DECITEX = 'decitex',
  PER_METER = '/m',
  PER_INCH = '/i',
  INCH = 'inch',
  ROTATIONS_PER_MINUTE = 'rpm',
  ENGLISH_COTTON_COUNT = 'NeC',
  LINENCOUNT = 'LEA',
  LIBRA_POUNDS = 'LBS',
  PIXEL = 'px',
  PERCENTAGE = '%',
  BYTE = 'Byte',
  KILOBYTE = 'KB',
  KILOGRAM = 'kg',
  GRAMS_PER_METER = 'g/m',
  METER_PER_MINUTE = 'm/min',
  KILOWATT_HOUR = 'kWh',
  CUBIC_METERS = 'm³',
  DEGREES_CELSIUS = '°C',
  NORMAL_CUBIC_METER = 'Nm³',
  NORMAL_CUBIC_METER_PER_SQUARE_METER = 'Nm³/m²',
  KILOWATT_HOUR_PER_SQUARE_METER = 'kWh/m²',
  PER_CENTIMETER = '/cm',
  PATHS_PER_CENTIMETER = 'paths/cm',
  PATHS_PER_DENTS = 'paths/d',
  LENGTH_PER_METER = 'length/m',
  LENGTH_PER_PICKS = 'length/p'
}

export namespace Unit {
  export function getKeyFromValue(value: string): string {
    return EnumUtils.getKeyFromValue(Unit, value);
  }

  export function isConvertibleToCommercialUnit(unit: Unit): boolean {
    let convertible = false;

    switch (unit) {
      case Unit.MILLIMETER:
      case Unit.CENTIMETER:
      case Unit.DECIMETER:
      case Unit.METER:
      case Unit.SQUARE_MILLIMETER:
      case Unit.SQUARE_CENTIMETER:
      case Unit.SQUARE_METER:
        convertible = true;
        break;
    }

    return convertible;
  }

  export function isConvertibleToDensityUnit(unit: Unit): boolean {
    let convertible = false;

    switch (unit) {
      case Unit.DENTS_PER_METER:
      case Unit.DENTS_PER_CENTIMETER:
      case Unit.DENTS_PER_MILLIMETER:
      case Unit.PICKS_PER_METER:
      case Unit.PICKS_PER_DECIMETER:
      case Unit.PICKS_PER_CENTIMETER:
      case Unit.PICKS_PER_MILLIMETER:
      case Unit.WEFTS_PER_METER:
      case Unit.WEFTS_PER_DECIMETER:
      case Unit.WEFTS_PER_CENTIMETER:
      case Unit.WEFTS_PER_MILLIMETER:
      case Unit.PATHS_PER_CENTIMETER:
      case Unit.PATHS_PER_DENTS:
      case Unit.LENGTH_PER_METER:
      case Unit.LENGTH_PER_PICKS:
        convertible = true;
        break;
    }

    return convertible;
  }

  export function isConvertibleToTextileUnit(unit: Unit): boolean {
    let convertible = false;

    switch (unit) {
      case Unit.NUMBER_METRIC:
      case Unit.DENIER:
      case Unit.DECITEX:
      case Unit.TEX:
      case Unit.LINENCOUNT:
      case Unit.LIBRA_POUNDS:
      case Unit.ENGLISH_COTTON_COUNT:
        convertible = true;
        break;
    }

    return convertible;
  }

  export function isConvertibleToFileSizeUnit(unit: Unit): boolean {
    let convertible = false;

    switch (unit) {
      case Unit.BYTE:
      case Unit.KILOBYTE:
        convertible = true;
        break;
    }

    return convertible;
  }
}
