import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationEnvironment} from '@application/configuration/application-environment';
import {LoadRuntimeApplicationEnvironmentError} from '@application/configuration/load-runtime-application-environment-error';
import {RuntimeApplicationEnvironment} from '@application/configuration/runtime-application-environment';
import {VERSION} from '@environments/version';
import {catchError, firstValueFrom, map, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRuntimeApplicationEnvironmentService implements RuntimeApplicationEnvironment {
  private static readonly environmentUrl = '/env.json';
  private environment: ApplicationEnvironment;

  public constructor(private readonly httpClient: HttpClient) {}

  public loadEnvironment(): Promise<void> {
    return firstValueFrom(
      this.httpClient.get(HttpRuntimeApplicationEnvironmentService.environmentUrl, {params: new HttpParams().append('v', VERSION.version)}).pipe(
        tap((environment: ApplicationEnvironment) => (this.environment = environment)),
        map(() => null),
        catchError(() => {
          throw new LoadRuntimeApplicationEnvironmentError();
        })
      )
    );
  }

  public getEnvironment(): ApplicationEnvironment {
    return this.environment;
  }
}
