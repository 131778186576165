import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {WeftColoredYarnSet} from '@domain/textile-data/weft-colored-yarn-set/weft-colored-yarn-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {Conflict} from '@vdw/angular-component-library';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {WeftColoredYarnSets} from './weft-colored-yarn-sets';

@Injectable()
export class HttpWeftColoredYarnSetsService implements WeftColoredYarnSets {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets/${id}`);
  }

  public getAll(): Observable<OverviewListColoredYarnSet[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets`).pipe(
      rxjsMap((weftColoredYarnSetsJSON: any) => {
        return map(weftColoredYarnSetsJSON, (coloredYarnSetJSON: any) => {
          return OverviewListColoredYarnSet.fromJSON(coloredYarnSetJSON);
        });
      })
    );
  }

  public getById(id: number): Observable<WeftColoredYarnSet> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets/${id}`).pipe(
      rxjsMap((weftColoredYarnSetJSON: any) => {
        return WeftColoredYarnSet.fromJSON(weftColoredYarnSetJSON);
      })
    );
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets/check-name`, {params}).pipe(
      rxjsMap((response: {weftColoredYarnSetNameAvailable: boolean}) => {
        return response.weftColoredYarnSetNameAvailable;
      })
    );
  }

  public save(item: WeftColoredYarnSet): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public update(item: WeftColoredYarnSet): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets/${item.id}`, item.toJSON());
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}weftcoloredyarnsets/${id}/conflicts`)
      .pipe(rxjsMap((conflictsJSON: any) => map(conflictsJSON, (conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }
}
