import {InjectionToken} from '@angular/core';
import {Article} from '@domain/article/article';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';

export const ARTICLES = new InjectionToken<Articles>('Articles');

export interface Articles extends Repository<Article, OverviewListArticle>, UniqueIdentifierObjectRepository, ConflictsRepository {
  deleteMulti(ids: number[]): Observable<MultiDeleteResponse[]>;
}
