import {NameGeneratorPartType} from './name-generator-part-type.enum';

export abstract class NameGeneratorPart {
  private _type: NameGeneratorPartType;

  public get type(): NameGeneratorPartType {
    return this._type;
  }

  public set type(value: NameGeneratorPartType) {
    this._type = value;
  }

  public abstract toJSON(): JSON;
}
