import {InjectionToken} from '@angular/core';
import {CalculatedYarnConsumption} from '@domain/yarn-consumption/calculated-yarn-consumption';
import {CalculatedYarnConsumptionForProductionOrder} from '@domain/yarn-consumption/calculated-yarn-consumption-for-production-order';
import {Observable} from 'rxjs';

export const REALTIME_YARN_CONSUMPTION = new InjectionToken<RealtimeYarnConsumption>('RealtimeYarnConsumption');

export interface RealtimeYarnConsumption {
  getCalculatedYarnConsumption(): Observable<CalculatedYarnConsumption>;
  getCalculatedYarnConsumptionForProductionOrder(): Observable<CalculatedYarnConsumptionForProductionOrder>;
  getSingleBmpProcessed(): Observable<any>;
}
