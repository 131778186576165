<p
  #ellipsisTooltip="matTooltip"
  class="no-margin"
  [matTooltipDisabled]="!showTooltip"
  [matTooltip]="text"
  [matTooltipPosition]="matTooltipPosition"
  [matTooltipClass]="[tooltipClass, 'unset-max-width']"
>
  <span #label class="ellipsis-label">
    <ng-container *ngIf="hasContent">
      <ng-content></ng-content>
    </ng-container>
    <ng-container *ngIf="!hasContent">{{ text }}</ng-container>
  </span>
</p>
