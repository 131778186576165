export enum NavigationId {
  TEXFAB,
  PLANNING_MODULE,
  PLANNING_PROTOTYPE_MODULE,
  PRODUCTION_SCHEDULE_MODULE,
  CUSTOMER_MODULE,
  ARTICLE_MODULE,
  SALES_ORDER_MODULE,
  ORDERBOOK_MODULE,
  WEAVING_PRODUCTION_ORDER_MODULE,
  TUFT_PRODUCTION_ORDER_MODULE,
  PLASTIC_PRODUCTION_ORDER_MODULE,
  PATH_LAYOUT_TEMPLATE_MODULE,

  PLANNING,
  PLANNING_PROTOTYPE,

  CARPET_PRODUCTION_ORDER,
  ADD_CARPET_PRODUCTION_SCHEDULE,
  ADD_CARPET_PRODUCTION_SCHEDULE_NEW,
  ADD_CARPET_PRODUCTION_SCHEDULE_MACHINE,
  ADD_CARPET_PRODUCTION_SCHEDULE_MACHINE_NEW,
  ADD_CARPET_PRODUCTION_SCHEDULE_RUN,
  ADD_CARPET_PRODUCTION_SCHEDULE_RUN_NEW,
  ADD_CARPET_PRODUCTION_SCHEDULE_CONFIGURATION,
  ADD_CARPET_PRODUCTION_SCHEDULE_CONFIGURATION_NEW,

  EDIT_CARPET_PRODUCTION_SCHEDULE,
  DUPLICATE_CARPET_PRODUCTION_SCHEDULE,

  EDIT_CARPET_PRODUCTION_SCHEDULE_NEW,
  DUPLICATE_CARPET_PRODUCTION_SCHEDULE_NEW,

  CARPET_PRODUCTION_SCHEDULE_SETTINGS,

  WEAVE_ORDER,
  ADD_WEAVE_ORDER,
  ADD_WEAVE_ORDER_MACHINE,
  ADD_WEAVE_ORDER_RUN,
  EDIT_WEAVE_ORDER,
  DUPLICATE_WEAVE_ORDER,
  WEAVE_ORDER_SETTINGS,

  TUFT_ORDER,
  ADD_TUFT_ORDER,
  ADD_TUFT_ORDER_MACHINE,
  ADD_TUFT_ORDER_RUN,
  EDIT_TUFT_ORDER,
  DUPLICATE_TUFT_ORDER,
  TUFT_ORDER_SETTINGS,

  PLASTIC_ORDER,
  ADD_PLASTIC_ORDER,
  EDIT_PLASTIC_ORDER,
  DUPLICATE_PLASTIC_ORDER,

  PATH_LAYOUT,
  ADD_PATH_LAYOUT,
  EDIT_PATH_LAYOUT,
  DUPLICATE_PATH_LAYOUT,
  PATH_LAYOUT_SETTINGS,

  CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,

  ARTICLE,
  ADD_ARTICLE,
  EDIT_ARTICLE,
  DUPLICATE_ARTICLE,

  SALES_ORDER,
  ADD_SALES_ORDER,
  EDIT_SALES_ORDER,
  DUPLICATE_SALES_ORDER,

  ORDERBOOK,
  ORDERBOOK_SETTINGS
}
