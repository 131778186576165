import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from './route-leaf';

export class ColoredYarnRoute extends RouteBranch {
  private _addColoredYarn: RouteLeaf;
  private _duplicateColoredYarn: RouteLeaf;
  private _editColoredYarn: RouteLeaf;

  public get addColoredYarn(): RouteLeaf {
    return this._addColoredYarn;
  }

  public set addColoredYarn(value: RouteLeaf) {
    this.replace(this._addColoredYarn, value);
    this._addColoredYarn = value;
  }

  public get duplicateColoredYarn(): RouteLeaf {
    return this._duplicateColoredYarn;
  }

  public set duplicateColoredYarn(value: RouteLeaf) {
    this.replace(this._duplicateColoredYarn, value);
    this._duplicateColoredYarn = value;
  }

  public get editColoredYarn(): RouteLeaf {
    return this._editColoredYarn;
  }

  public set editColoredYarn(value: RouteLeaf) {
    this.replace(this._editColoredYarn, value);
    this._editColoredYarn = value;
  }
}
