import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Observable} from 'rxjs';
import {YarnConsumption} from './yarn-consumption';

@Injectable()
export class HttpYarnConsumptionService implements YarnConsumption {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public calculateYarnConsumptionForOrderline(id: number): Observable<void> {
    return this.httpClient.get<void>(`${this.backendConfiguration.getAggregatorEndpoint()}yarnconsumptions/order-line/${id}`);
  }

  public calculateYarnConsumptionForProductionOrder(id: number | string): Observable<void> {
    return this.httpClient.get<void>(`${this.backendConfiguration.getAggregatorEndpoint()}yarnconsumptions/production-order/${id}`);
  }

  public calculateYarnConsumptionForDesign(id: number, colorSetId: number, yarnSetId: number, machineQualityId: number, calculateYarnConsumptionPerDent: boolean = false): Observable<void> {
    return this.httpClient.get<void>(
      `${this.backendConfiguration.getAggregatorEndpoint()}yarnconsumptions/design/${id}?color-set=${colorSetId}&yarn-set=${yarnSetId}&machine-quality=${machineQualityId}&calculateYarnConsumptionPerDent=${calculateYarnConsumptionPerDent}`
    );
  }
}
