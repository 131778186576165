// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const SOLID_ICONS = [
  {
    "path": "assets/solid-icons/solid-add.svg",
    "name": "solid-add"
  },
  {
    "path": "assets/solid-icons/solid-analytics.svg",
    "name": "solid-analytics"
  },
  {
    "path": "assets/solid-icons/solid-arrow-back.svg",
    "name": "solid-arrow-back"
  },
  {
    "path": "assets/solid-icons/solid-arrow-downward-alt.svg",
    "name": "solid-arrow-downward-alt"
  },
  {
    "path": "assets/solid-icons/solid-arrow-upward-alt.svg",
    "name": "solid-arrow-upward-alt"
  },
  {
    "path": "assets/solid-icons/solid-article.svg",
    "name": "solid-article"
  },
  {
    "path": "assets/solid-icons/solid-block.svg",
    "name": "solid-block"
  },
  {
    "path": "assets/solid-icons/solid-build.svg",
    "name": "solid-build"
  },
  {
    "path": "assets/solid-icons/solid-calendar-month.svg",
    "name": "solid-calendar-month"
  },
  {
    "path": "assets/solid-icons/solid-calendar-today.svg",
    "name": "solid-calendar-today"
  },
  {
    "path": "assets/solid-icons/solid-check-small.svg",
    "name": "solid-check-small"
  },
  {
    "path": "assets/solid-icons/solid-check.svg",
    "name": "solid-check"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-checked-disabled.svg",
    "name": "solid-checkbox-checked-disabled"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-checked-invalid.svg",
    "name": "solid-checkbox-checked-invalid"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-checked.svg",
    "name": "solid-checkbox-checked"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-indeterminate.svg",
    "name": "solid-checkbox-indeterminate"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-unchecked-disabled.svg",
    "name": "solid-checkbox-unchecked-disabled"
  },
  {
    "path": "assets/solid-icons/solid-checkbox-unchecked.svg",
    "name": "solid-checkbox-unchecked"
  },
  {
    "path": "assets/solid-icons/solid-close.svg",
    "name": "solid-close"
  },
  {
    "path": "assets/solid-icons/solid-colorize.svg",
    "name": "solid-colorize"
  },
  {
    "path": "assets/solid-icons/solid-content-copy.svg",
    "name": "solid-content-copy"
  },
  {
    "path": "assets/solid-icons/solid-create.svg",
    "name": "solid-create"
  },
  {
    "path": "assets/solid-icons/solid-delete.svg",
    "name": "solid-delete"
  },
  {
    "path": "assets/solid-icons/solid-density-large.svg",
    "name": "solid-density-large"
  },
  {
    "path": "assets/solid-icons/solid-density-medium.svg",
    "name": "solid-density-medium"
  },
  {
    "path": "assets/solid-icons/solid-density-small.svg",
    "name": "solid-density-small"
  },
  {
    "path": "assets/solid-icons/solid-download.svg",
    "name": "solid-download"
  },
  {
    "path": "assets/solid-icons/solid-drag-indicator.svg",
    "name": "solid-drag-indicator"
  },
  {
    "path": "assets/solid-icons/solid-duplicate.svg",
    "name": "solid-duplicate"
  },
  {
    "path": "assets/solid-icons/solid-edit-document.svg",
    "name": "solid-edit-document"
  },
  {
    "path": "assets/solid-icons/solid-edit.svg",
    "name": "solid-edit"
  },
  {
    "path": "assets/solid-icons/solid-error-circle-rounded.svg",
    "name": "solid-error-circle-rounded"
  },
  {
    "path": "assets/solid-icons/solid-execute.svg",
    "name": "solid-execute"
  },
  {
    "path": "assets/solid-icons/solid-expand-more.svg",
    "name": "solid-expand-more"
  },
  {
    "path": "assets/solid-icons/solid-file-copy.svg",
    "name": "solid-file-copy"
  },
  {
    "path": "assets/solid-icons/solid-filter-list.svg",
    "name": "solid-filter-list"
  },
  {
    "path": "assets/solid-icons/solid-forklift.svg",
    "name": "solid-forklift"
  },
  {
    "path": "assets/solid-icons/solid-grid-view.svg",
    "name": "solid-grid-view"
  },
  {
    "path": "assets/solid-icons/solid-help.svg",
    "name": "solid-help"
  },
  {
    "path": "assets/solid-icons/solid-image.svg",
    "name": "solid-image"
  },
  {
    "path": "assets/solid-icons/solid-insights.svg",
    "name": "solid-insights"
  },
  {
    "path": "assets/solid-icons/solid-keyboard-arrow-down.svg",
    "name": "solid-keyboard-arrow-down"
  },
  {
    "path": "assets/solid-icons/solid-keyboard-arrow-right.svg",
    "name": "solid-keyboard-arrow-right"
  },
  {
    "path": "assets/solid-icons/solid-keyboard-arrow-up.svg",
    "name": "solid-keyboard-arrow-up"
  },
  {
    "path": "assets/solid-icons/solid-keyboard-double-arrow-left.svg",
    "name": "solid-keyboard-double-arrow-left"
  },
  {
    "path": "assets/solid-icons/solid-keyboard-double-arrow-right.svg",
    "name": "solid-keyboard-double-arrow-right"
  },
  {
    "path": "assets/solid-icons/solid-list-alt.svg",
    "name": "solid-list-alt"
  },
  {
    "path": "assets/solid-icons/solid-list.svg",
    "name": "solid-list"
  },
  {
    "path": "assets/solid-icons/solid-menu.svg",
    "name": "solid-menu"
  },
  {
    "path": "assets/solid-icons/solid-monitoring.svg",
    "name": "solid-monitoring"
  },
  {
    "path": "assets/solid-icons/solid-more-vert.svg",
    "name": "solid-more-vert"
  },
  {
    "path": "assets/solid-icons/solid-nest-clock-farsight-analog.svg",
    "name": "solid-nest-clock-farsight-analog"
  },
  {
    "path": "assets/solid-icons/solid-notifications.svg",
    "name": "solid-notifications"
  },
  {
    "path": "assets/solid-icons/solid-open-in-full.svg",
    "name": "solid-open-in-full"
  },
  {
    "path": "assets/solid-icons/solid-open-in-new.svg",
    "name": "solid-open-in-new"
  },
  {
    "path": "assets/solid-icons/solid-plan.svg",
    "name": "solid-plan"
  },
  {
    "path": "assets/solid-icons/solid-processing.svg",
    "name": "solid-processing"
  },
  {
    "path": "assets/solid-icons/solid-push-pin.svg",
    "name": "solid-push-pin"
  },
  {
    "path": "assets/solid-icons/solid-radio-button-checked.svg",
    "name": "solid-radio-button-checked"
  },
  {
    "path": "assets/solid-icons/solid-radio-button-unchecked.svg",
    "name": "solid-radio-button-unchecked"
  },
  {
    "path": "assets/solid-icons/solid-replan.svg",
    "name": "solid-replan"
  },
  {
    "path": "assets/solid-icons/solid-schedule.svg",
    "name": "solid-schedule"
  },
  {
    "path": "assets/solid-icons/solid-search.svg",
    "name": "solid-search"
  },
  {
    "path": "assets/solid-icons/solid-send.svg",
    "name": "solid-send"
  },
  {
    "path": "assets/solid-icons/solid-settings.svg",
    "name": "solid-settings"
  },
  {
    "path": "assets/solid-icons/solid-star-rate.svg",
    "name": "solid-star-rate"
  },
  {
    "path": "assets/solid-icons/solid-star.svg",
    "name": "solid-star"
  },
  {
    "path": "assets/solid-icons/solid-storefront.svg",
    "name": "solid-storefront"
  },
  {
    "path": "assets/solid-icons/solid-swap-vert.svg",
    "name": "solid-swap-vert"
  },
  {
    "path": "assets/solid-icons/solid-textarea.svg",
    "name": "solid-textarea"
  },
  {
    "path": "assets/solid-icons/solid-tune.svg",
    "name": "solid-tune"
  },
  {
    "path": "assets/solid-icons/solid-unfold-more.svg",
    "name": "solid-unfold-more"
  },
  {
    "path": "assets/solid-icons/solid-view-list.svg",
    "name": "solid-view-list"
  },
  {
    "path": "assets/solid-icons/solid-visibility-off.svg",
    "name": "solid-visibility-off"
  },
  {
    "path": "assets/solid-icons/solid-visibility.svg",
    "name": "solid-visibility"
  },
  {
    "path": "assets/solid-icons/solid-width.svg",
    "name": "solid-width"
  },
  {
    "path": "assets/solid-icons/solid-zoom-in.svg",
    "name": "solid-zoom-in"
  },
  {
    "path": "assets/solid-icons/solid-zoom-out.svg",
    "name": "solid-zoom-out"
  }
];
