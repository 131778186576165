<div fxLayout="column" class="mobile-selector full-width">
  <div class="header" fxLayoutAlign="space-between">
    <h4>{{ title | bmsTranslate }}</h4>
    <button mat-icon-button class="mat-icon-action-button-sm-without-border" [mat-dialog-close]>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-button-toggle-group class="full-width" [vertical]="true" [value]="selectedOption.id">
    <mat-button-toggle *ngFor="let option of options" [value]="option.id" (click)="closeDialog(option)">{{ option.translationKey | bmsTranslate }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>
