<section class="full-height" fxLayout="column">
  <vdw-header [title]="'PLANNING.NEEDS_ATTENTION.TITLE' | bmsTranslate" svgIcon="warning-full-36px" [canShowClose]="true" (closeEvent)="close()"></vdw-header>
  <article class="sidebar-content" fxFlex fxLayout="row">
    <div fxLayout="column" fxFlex="100">
      <vdw-loading-overlay fxFlex="100" *ngIf="loadingProductionOrders; else productionOrdersLoaded" [hideDescription]="true" [scale]="0.7"> </vdw-loading-overlay>
      <ng-template #productionOrdersLoaded>
        <ng-container *ngIf="hasProductionOrders(); else noDataOverlay">
          <div fxLayout="row" class="search-container">
            <div fxLayout="column" fxFlex>
              <vdw-filter
                #nameFilter
                filterWidth="100%"
                icon="filter"
                [filteredText]="getNameFilterValue()"
                (filterIconClicked)="showAdvancedSearch()"
                (filteredTextChanged)="nameFilterTextChanged($event)"
              ></vdw-filter>
              <app-search-filters
                fxFlex
                #searchFilters
                [searchFilters]="advancedSearchFilters"
                (searchFiltersChanged)="filterNeedsAttentionProductionOrders()"
                (showAdvancedSearch)="showAdvancedSearch()"
              ></app-search-filters>
            </div>
          </div>
          <virtual-scroller fxFlex #virtualScroller *ngIf="hasFilteredProductionOrders(); else noResultsOverlay" [items]="filteredNeedsAttentionItems">
            <ng-container *ngFor="let needsAttentionItem of virtualScroller.viewPortItems">
              <ng-container *ngIf="needsAttentionItem.isCarpetOrder">
                <app-unplanned-order-card
                  *ngIf="needsAttentionItem.productionOrderCarpet.needsToBePlanned()"
                  [unplannedOrder]="needsAttentionItem.productionOrderCarpet"
                  (deleteUnplannedOrder)="deleteUnplannedOrder(needsAttentionItem)"
                  (unplannedOrderDragStart)="onUnplannedOrderDragStart($event, needsAttentionItem)"
                  (unplannedOrderDrag)="onUnplannedOrderDrag($event)"
                  (unplannedOrderDragEnd)="onUnplannedOrderDragEnd()"
                >
                </app-unplanned-order-card>

                <app-planned-order-card
                  *ngIf="needsAttentionItem.productionOrderCarpet.needsToBeReconfirmed()"
                  [plannedOrder]="needsAttentionItem.productionOrderCarpet"
                  (plannedOrderReconfirmed)="removeProductionOrderCarpet(needsAttentionItem.productionOrderCarpet.id)"
                  (viewPlannedOrderDetails)="viewPlannedOrderDetails(needsAttentionItem.planningOrderId)"
                >
                </app-planned-order-card>
              </ng-container>

              <ng-container *ngIf="needsAttentionItem.isWeavingOrder">
                <app-unplanned-order-card
                  [unplannedOrder]="needsAttentionItem.productionOrderWeaving"
                  (deleteUnplannedOrder)="deleteUnplannedOrder(needsAttentionItem)"
                  (unplannedOrderDragStart)="onUnplannedOrderDragStart($event, needsAttentionItem)"
                  (unplannedOrderDrag)="onUnplannedOrderDrag($event)"
                  (unplannedOrderDragEnd)="onUnplannedOrderDragEnd()"
                >
                </app-unplanned-order-card>
              </ng-container>
              <ng-container *ngIf="needsAttentionItem.isTuftingOrder">
                <app-unplanned-order-card
                  [unplannedOrder]="needsAttentionItem.productionOrderTufting"
                  (deleteUnplannedOrder)="deleteUnplannedOrder(needsAttentionItem)"
                  (unplannedOrderDragStart)="onUnplannedOrderDragStart($event, needsAttentionItem)"
                  (unplannedOrderDrag)="onUnplannedOrderDrag($event)"
                  (unplannedOrderDragEnd)="onUnplannedOrderDragEnd()"
                >
                </app-unplanned-order-card>
              </ng-container>
            </ng-container>
          </virtual-scroller>

          <ng-template #noResultsOverlay>
            <vdw-nodata-overlay fxFlex [shouldShowFilterNoResult]="true" [hideDescription]="true" scale="0.7"> </vdw-nodata-overlay>
          </ng-template>
        </ng-container>

        <ng-template #noDataOverlay>
          <vdw-nodata-overlay fxFlex="100" [titleKey]="'PLANNING.NEEDS_ATTENTION.NO_PRODUCTION_ORDERS'" [shouldShowFilterNoResult]="false" [hideDescription]="true" [scale]="0.7" [hideActions]="true">
          </vdw-nodata-overlay>
        </ng-template>
      </ng-template>
    </div>
  </article>
</section>
