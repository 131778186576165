import {Component, EventEmitter, Inject, Input, OnDestroy, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {SelectionListItem} from '../selection-list/selection-list-item.interface';
import {SelectionListContainerData} from './selection-list-container-data.interface';

@Component({
  selector: 'vdw-selection-list-container',
  templateUrl: './selection-list-container.component.html',
  styleUrls: ['./selection-list-container.component.scss']
})
export class SelectionListContainerComponent implements OnDestroy {
  @Input() public maxHeight: string;
  @Input() public containerTitle: string;
  @Input() public selectListOptions: SelectionListItem[];
  @Output() public selectListOptionsChanged = new EventEmitter<SelectionListItem[]>();

  private readonly selectOptionsChanges = new Subject<SelectionListItem[]>();

  public constructor(@Inject(MAT_DIALOG_DATA) data: SelectionListContainerData) {
    this.maxHeight = data?.maxHeight;
    this.selectListOptions = data.options;
    this.containerTitle = data.containerTitle;
  }

  public ngOnDestroy(): void {
    this.selectOptionsChanges.complete();
  }

  public getValue(): Observable<SelectionListItem[]> {
    return this.selectOptionsChanges.asObservable();
  }

  public onSelectOptionChanged(update: SelectionListItem[]): void {
    this.selectListOptionsChanged.emit(update);
    this.selectOptionsChanges.next(update);
  }
}
