import {isEqual} from 'lodash-es';

export function convertMachineOrderIdIfLegacy(machineOrderId: string): string {
  const amountOfNumbersInLegacyMachineOrderId = 7;
  const amountOfNumbersInGivenMachineOrderId = machineOrderId.replace(/\D/g, '').length;

  if (isEqual(amountOfNumbersInLegacyMachineOrderId, amountOfNumbersInGivenMachineOrderId)) {
    const indexOfFirstDigit = machineOrderId.search(/\d/);

    machineOrderId = [machineOrderId.slice(0, indexOfFirstDigit), '3000', machineOrderId.slice(indexOfFirstDigit)].join('');
  }

  return machineOrderId;
}
