import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {FilterChipsModule} from '../filter-chips/filter-chips.module';
import {StatusBarComponent} from './status-bar.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatButtonModule, TranslationModule, MatIconModule, MatMenuModule, LabelModule, FilterChipsModule, FilterChipsModule, MatSlideToggleModule],
  exports: [StatusBarComponent],
  declarations: [StatusBarComponent]
})
export class StatusBarModule {}
