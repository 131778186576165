import {Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {WINDOW} from '../../window/window.service';

@Directive({
  selector: '[vdwResizeElement]'
})
export class ResizeElementDirective implements OnInit, OnDestroy {
  private _isResizing = false;

  private mouseUpListener: () => void;
  private mouseMoveListener: () => void;

  @Input() public minResizeWidth: number;
  @Input() public maxResizeWidth: number;
  @Output() public resizeElementEnd = new EventEmitter();
  @Output() public resizeElement = new EventEmitter<MouseEvent>();

  public get isResizing(): boolean {
    return this._isResizing;
  }

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
    private readonly renderer: Renderer2,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  public ngOnInit(): void {
    this.elementRef?.nativeElement?.addEventListener('mousedown', this.onResizerMouseDown);
  }

  private onResizerMouseDown = (event: MouseEvent): void => {
    event.preventDefault();

    if (event.button === 0) {
      this._isResizing = true;

      this.zone.runOutsideAngular(() => {
        this.mouseUpListener = this.renderer.listen(this.window, 'mouseup', () => this.cancelListeners());
        this.mouseMoveListener = this.renderer.listen(this.window, 'mousemove', (mouseMoveEvent: MouseEvent) => {
          const calculatedWidth = this.elementRef.nativeElement.clientWidth + mouseMoveEvent.x;
          const minWidthCheck = AssertionUtils.isNullOrUndefined(this.minResizeWidth) || calculatedWidth >= this.minResizeWidth;
          const maxWidthCheck = AssertionUtils.isNullOrUndefined(this.maxResizeWidth) || calculatedWidth <= this.maxResizeWidth;

          if (this._isResizing === true && minWidthCheck && maxWidthCheck) {
            this.resizeElement.emit(mouseMoveEvent);
          }
        });
      });
    }
  };

  private cancelListeners(): void {
    this._isResizing = false;
    this.resizeElementEnd.emit();

    if (this.mouseMoveListener) {
      this.mouseMoveListener();
      this.mouseMoveListener = null;
    }

    if (this.mouseUpListener) {
      this.mouseUpListener();
      this.mouseUpListener = null;
    }
  }

  public ngOnDestroy(): void {
    this.elementRef?.nativeElement?.removeEventListener('mousedown', this.onResizerMouseDown);
  }
}
