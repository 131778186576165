import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Device} from './device.enum';
import {ResponsivenessViewMode} from './responsiveness-view.mode';

@Injectable({
  providedIn: 'root'
})
export class BreakpointResponsivenessViewModeService implements ResponsivenessViewMode {
  private deviceChangeSubject: Subject<Device>;
  private currentDevice: Device;

  public constructor(private breakpointObserver: BreakpointObserver) {
    this.deviceChangeSubject = new Subject<Device>();

    this.observeDefaultBreakpointChanges();
  }

  public get isDesktop(): boolean {
    return this.currentDevice === Device.DESKTOP;
  }

  public get isPhone(): boolean {
    return this.currentDevice === Device.PHONE;
  }

  public get isTablet(): boolean {
    return this.currentDevice === Device.TABLET;
  }

  public observeBreakpointChanges(mediaQuery: string | string[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(mediaQuery);
  }

  public matchesBreakpoint(mediaQuery: string): boolean {
    return this.breakpointObserver.isMatched(mediaQuery);
  }

  public observeDeviceChanges(): Observable<Device> {
    return this.deviceChangeSubject.asObservable().pipe(startWith(this.currentDevice));
  }

  private observeDefaultBreakpointChanges(): void {
    this.getInitialDevice();

    this.breakpointObserver.observe(Breakpoints.XSmall).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.PHONE;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });

    this.breakpointObserver.observe(Breakpoints.Tablet).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.TABLET;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });

    this.breakpointObserver.observe(Breakpoints.WebLandscape).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.DESKTOP;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });
  }

  private getInitialDevice(): void {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.currentDevice = Device.PHONE;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.currentDevice = Device.TABLET;
    } else {
      this.currentDevice = Device.DESKTOP;
    }
  }
}
