export enum RestZonePosition {
  LEFT,
  RIGHT,
  SYMMETRICAL,
  USER_DEFINED
}

export namespace RestZonePosition {
  export function getUserDefinedRestZonePositions(): RestZonePosition[] {
    return [RestZonePosition.LEFT, RestZonePosition.RIGHT];
  }

  export function getRestZonePositions(): RestZonePosition[] {
    return [RestZonePosition.LEFT, RestZonePosition.RIGHT, RestZonePosition.SYMMETRICAL, RestZonePosition.USER_DEFINED];
  }
}
