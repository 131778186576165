import {StringUtils} from '@application/helper/string-utils';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';

export class MachineQualityForFinishing {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;
  private readonly _picksPerColorLine: number;

  public constructor(id: number, name: string, version: number, picksPerColorLine: number) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._picksPerColorLine = picksPerColorLine;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.name, this.version);
  }

  public get picksPerColorLine(): number {
    return this._picksPerColorLine;
  }

  public static fromJSON(machineQualityJSON: any): MachineQualityForFinishing {
    return new MachineQualityForFinishing(machineQualityJSON.id, machineQualityJSON.name, machineQualityJSON.version, machineQualityJSON.picksPerColorLine);
  }

  public static fromWeaveQuality(weaveQuality: WeaveQuality): MachineQualityForFinishing {
    return new MachineQualityForFinishing(weaveQuality.id, weaveQuality.technicalName, weaveQuality.version, weaveQuality.singleWidth.weaveStructure.picksPerColorLine);
  }
}
