import {RouteBranch} from '@application/helper/routing/route-branch';
import {ToolRoute} from './tool-route';

export class UtilitiesRoute extends RouteBranch {
  private _tool: ToolRoute;

  public get tool(): ToolRoute {
    return this._tool;
  }

  public set tool(value: ToolRoute) {
    this.replace(this._tool, value);
    this._tool = value;
  }
}
