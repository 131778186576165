import {CommercialDimensions} from '@domain/commercial-dimensions';
import {CommercialDimensionsCM} from '@domain/production-schedule/commercial-dimensions-cm';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingConfiguration} from '@domain/production-schedule/drawing-configuration';
import {DrawingConfigurationWithColorAndYarnSet} from '@domain/production-schedule/drawing-configuration-with-color-and-yarn-set';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {ObjectUtils} from '@vdw/angular-component-library';
import {cloneDeep, each, isEmpty, reduce} from 'lodash-es';
import {ListDrawing} from './list-drawing';

export class ListDrawingConfiguration implements ListDrawing {
  private _configurationIndex: number;
  private _configurationId: number;
  private _drawing: Drawing;
  private _amount: number;
  private _quality: string;
  private _colorSet: string;
  private _mainColor: OverviewListCreelPosition;
  private _borderColor: OverviewListCreelPosition;
  private _widthInCM: number;
  private _commercialDimensionsInMM: CommercialDimensions;
  private _commercialDimensionsInCM: CommercialDimensionsCM;
  private _technicalWidthInPx: number;
  private _rollLengthInMeter: number;

  public constructor(drawing: Drawing, configurationIndex: number, amount?: number, widthInPx?: number, drawingConfiguration?: DrawingConfiguration, rollLengthInMeter?: number) {
    this._drawing = drawing;
    this._configurationIndex = configurationIndex;
    this._amount = amount;
    this._technicalWidthInPx = widthInPx;

    drawingConfiguration ??= drawing?.configurations[0];
    this._configurationId = drawingConfiguration?.id;
    this._quality = ObjectUtils.tryGetValue(drawingConfiguration?.quality?.name, '');

    this._colorSet = drawingConfiguration instanceof DrawingConfigurationWithColorAndYarnSet ? ObjectUtils.tryGetValue(drawingConfiguration?.colorSet?.name, '') : '';

    this._mainColor = drawingConfiguration?.mainColorsCreelPosition;
    this._borderColor = drawingConfiguration?.borderColorsCreelPosition;
    this._commercialDimensionsInMM = drawingConfiguration?.commercialDimensionsInMm;
    this._commercialDimensionsInCM = drawingConfiguration?.commercialDimensionsInCm;
    this._rollLengthInMeter = rollLengthInMeter;
  }

  public get id(): string {
    return this._drawing.image.id;
  }

  public get configurationId(): number {
    return this._configurationId;
  }

  public set configurationId(configurationId: number) {
    this._configurationId = configurationId;
  }

  public get drawing(): Drawing {
    return this._drawing;
  }

  public set drawing(drawing: Drawing) {
    this._drawing = drawing;
  }

  public get amount(): number {
    return this._amount;
  }

  public set amount(value: number) {
    this._amount = value;
  }

  public get configurationIndex(): number {
    return this._configurationIndex;
  }

  public get quality(): string {
    return this._quality;
  }

  public set quality(quality: string) {
    this._quality = quality;
  }

  public get colorSet(): string {
    return this._colorSet;
  }

  public set colorSet(colorSet: string) {
    this._colorSet = colorSet;
  }

  public get mainColor(): any {
    return this._mainColor;
  }

  public set mainColor(mainColor: any) {
    this._mainColor = mainColor;
  }

  public get borderColor(): any {
    return this._borderColor;
  }

  public set borderColor(borderColor: any) {
    this._borderColor = borderColor;
  }

  public get widthInCM(): number {
    return this._widthInCM;
  }

  public set widthInCM(widthInCM: number) {
    this._widthInCM = widthInCM;
  }

  public get commercialDimensionsInMM(): CommercialDimensions {
    return this._commercialDimensionsInMM;
  }

  public get rollLengthInMeter(): number {
    return this._rollLengthInMeter;
  }

  public set rollLengthInMeter(value: number) {
    this._rollLengthInMeter = value;
  }

  public set commercialDimensionsInMM(commercialDimensionsInMM: CommercialDimensions) {
    this._commercialDimensionsInMM = commercialDimensionsInMM;
  }

  public get commercialDimensionsInCm(): CommercialDimensionsCM {
    return this._commercialDimensionsInCM;
  }

  public set commercialDimensionsInCm(commercialDimensionsInCm: CommercialDimensionsCM) {
    this._commercialDimensionsInCM = commercialDimensionsInCm;
  }

  public set widthInPx(widthInPx: number) {
    this._technicalWidthInPx = widthInPx;
  }

  public get widthInPx(): number {
    return this._technicalWidthInPx;
  }

  public static getDrawingsForAllConfigurations(drawings: Drawing[]): ListDrawingConfiguration[] {
    return reduce(
      drawings,
      (result: ListDrawingConfiguration[], drawing: Drawing) => {
        if (!isEmpty(drawing.configurations)) {
          each(drawing.configurations, (configuration: DrawingConfigurationWithColorAndYarnSet, index: number) => {
            const drawingWithUpdatedImage = cloneDeep(drawing);
            drawingWithUpdatedImage.image.id = drawing.image.id;
            drawingWithUpdatedImage.configurations = [configuration];
            const item = new ListDrawingConfiguration(drawingWithUpdatedImage, index);
            result.push(item);
          });
        } else {
          const item = new ListDrawingConfiguration(drawing, 0);
          result.push(item);
        }

        return result;
      },
      []
    );
  }
}
