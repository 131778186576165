import {Injectable} from '@angular/core';
import {TemporaryStateKey} from './temporary-state-key.enum';
import {TemporaryState} from './temporary-state.interface';

@Injectable({providedIn: 'root'})
export class TemporaryStateService<T> {
  private temporaryState: TemporaryState<T>;

  public getTemporaryState(key: TemporaryStateKey): T {
    return this.temporaryState && this.temporaryState?.key === key ? this.temporaryState.value : null;
  }

  public setTemporaryState(temporaryState: TemporaryState<T>): void {
    this.temporaryState = temporaryState;
  }

  public clear(): void {
    this.temporaryState = null;
  }
}
