import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddCustomerComponent} from './add/add-customer.component';
import {CustomerOverviewPageComponent} from './overview/customer-overview-page.component';

const shared = {parentId: NavigationId.CUSTOMER_MODULE};

export const customerRoutes = [
  Factory.createPrototypeComponentRoute(CustomerOverviewPageComponent, {route: '', requiredPermission: null, id: NavigationId.CUSTOMER, ...shared}),
  Factory.createPrototypeComponentRoute(AddCustomerComponent, {route: 'edit/:id', requiredPermission: Permission.TEXFAB_CUSTOMER_VIEW, id: NavigationId.EDIT_CUSTOMER, ...shared}),
  Factory.createPrototypeComponentRoute(AddCustomerComponent, {route: 'add', requiredPermission: Permission.TEXFAB_CUSTOMER_EDIT, reuse: true, id: NavigationId.ADD_CUSTOMER, ...shared})
];

export const customerDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(customerRoutes);
