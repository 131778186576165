import {InjectionToken} from '@angular/core';
import {Pattern} from '@domain/pattern';
import {PropertyValue} from '@domain/property-value';
import {FinishingTemplate} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template';
import {ImageForFinishingImageLabel} from '@domain/textile-data/finishing-and-finishing-template/image-for-finishing-image-label';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {CustomSettingsObjectRepository} from '@infrastructure/http/custom-settings-object-repository';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListFinishingTemplate} from '@presentation/pages/textile-data/finishing-template/overview/overview-list-finishing-template';
import {FinishingTemplateForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/finishing-template-for-machine-quality';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const FINISHING_TEMPLATES = new InjectionToken<FinishingTemplates>('FinishingTemplates');

export interface FinishingTemplates
  extends Repository<FinishingTemplate, OverviewListFinishingTemplate>,
    UniqueIdentifierObjectRepository,
    CustomSettingsObjectRepository,
    ConflictsRepository,
    NameGenerationRepository<FinishingTemplate> {
  getListOfFinishingTypes(): Observable<PropertyValue>;

  getFinishingTemplatesForMachineQuality(machineQualityId: number): Observable<FinishingTemplateForMachineQuality[]>;

  getFinishingTemplatesForQuality(weaveStructureId: number, pickDensity: number): Observable<FinishingTemplateForMachineQuality[]>;

  getFoldingTypes(): Observable<Pattern[]>;

  getImagesForLabels(): Observable<ImageForFinishingImageLabel[]>;

  getCategoriesWithComponents(): Observable<PlaceholderCategory[]>;

  uploadImagesForLabels(imagesForFinishingImageLabel: ImageForFinishingImageLabel[]): Observable<ImageForFinishingImageLabel[]>;

  getImageForLabels(imageId: string): Observable<Blob>;

  deleteImageForLabels(imageId: string): Observable<void>;
}
