import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {AssertionUtils, BlobUtils, DialogBuilderFactoryService, TranslateService} from '@vdw/angular-component-library';
import {find, isEqual, isNil, size, toLower} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {ImageUploadHelper} from '../image-upload-helper';
import {NavigationHelperService} from '../navigation-helper/navigation-helper.service';

@Injectable()
export class DrawingImageUploadHelper extends ImageUploadHelper<DrawingType, DrawingImage> {
  protected dialogTitle = 'DESIGN_LIBRARY.DESIGN_LIBRARY';

  private readonly fileTypes: DrawingType[] = [DrawingType.BMP, DrawingType.EP, DrawingType.TFT, DrawingType.PVD];
  private readonly urlToNavigateToAddDrawing = RouteUtils.paths.texEdit.addDesign.absolutePath;

  public constructor(
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    private readonly router: Router,
    dialogBuilderFactoryService: DialogBuilderFactoryService,
    translate: TranslateService,
    private readonly navigationHelperService: NavigationHelperService<any>
  ) {
    super(translate, dialogBuilderFactoryService);
  }

  public uploadFiles(files: File[], navigationData?: any): void {
    this.removeSubscriptionForImageSubject();
    this.drawings.clearUploadQueue();
    const localFilesCopy: File[] = [];
    localFilesCopy.push(...files);

    this.subscriptionForImageSubject = this.imageSubject.pipe(takeUntil(this._unSubscribeOnViewDestroy)).subscribe((result: {error: string; image: DrawingImage}) => {
      if (!isNil(result.error)) {
        this.displayAlertDialog(result.error);
      } else {
        this.drawings.addImageToUploadQueue(result.image);
        if (isEqual(size(this.drawings.getUploadQueue()), size(localFilesCopy))) {
          if (AssertionUtils.isNullOrUndefined(navigationData)) {
            this.navigateToPage(this.urlToNavigateToAddDrawing);
          } else {
            this.navigationHelperService.navigateToNextRouteWithPartialState(navigationData, this.urlToNavigateToAddDrawing);
          }
        }
      }
    });

    this.processFileChange(localFilesCopy, this.fileTypes);
  }

  protected processFile(fileName: string, file: File, fileDrawingType: DrawingType): void {
    if (isEqual(fileDrawingType, DrawingType.BMP)) {
      BlobUtils.blobToArrayBuffer(file).then(this.onImageReaderLoadForFormatLocalValidation(fileName, file, fileDrawingType));
    } else {
      this.imageSubject.next({
        error: null,
        image: {
          name: fileName,
          file,
          type: fileDrawingType
        }
      });
    }
  }

  protected onImageLoad(drawingImage: HTMLImageElement, file: File, drawingType: DrawingType): () => void {
    return (): void => {
      this.imageSubject.next({
        error: null,
        image: {
          dimensionsInPx: {
            widthInPx: drawingImage.naturalWidth,
            heightInPx: drawingImage.naturalHeight
          },
          data: drawingImage.src,
          name: drawingImage.id,
          file,
          type: drawingType
        }
      });
    };
  }

  protected getFileTypeFromAllowedTypes(fileExtension: string, allowedTypes: DrawingType[]): DrawingType {
    return find(allowedTypes, (type: DrawingType) => isEqual(fileExtension, toLower(type)));
  }

  private navigateToPage(pageUrl: string): void {
    this.router.navigateByUrl(pageUrl);
  }
}
