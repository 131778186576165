import {Pattern} from '@domain/pattern';
import {CuttingLineProperties} from '@domain/textile-data/finishing-and-finishing-template/cutting-line-properties';
import {FinishingCutbarProperties} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-cutbar-properties';
import {isNil} from 'lodash-es';

export class FinishingCutbarsInformation {
  private _cutbarBeforeProperties: FinishingCutbarProperties;
  private _cutbarAfterProperties: FinishingCutbarProperties;
  private _cuttingLineProperties: CuttingLineProperties;
  private _foldingType: Pattern;

  public constructor(cutbarBeforeProperties: FinishingCutbarProperties, cutbarAfterProperties: FinishingCutbarProperties, cuttingLineProperties: CuttingLineProperties, foldingType: Pattern) {
    this._cutbarBeforeProperties = cutbarBeforeProperties;
    this._cutbarAfterProperties = cutbarAfterProperties;
    this._cuttingLineProperties = cuttingLineProperties;
    this._foldingType = foldingType;
  }

  public get cutbarBeforeProperties(): FinishingCutbarProperties {
    return this._cutbarBeforeProperties;
  }

  public get cutbarAfterProperties(): FinishingCutbarProperties {
    return this._cutbarAfterProperties;
  }

  public get cuttingLineProperties(): CuttingLineProperties {
    return this._cuttingLineProperties;
  }

  public get foldingType(): Pattern {
    return this._foldingType;
  }

  public static fromJSON(cutbarsInformationJSON: any): FinishingCutbarsInformation {
    return isNil(cutbarsInformationJSON)
      ? null
      : new FinishingCutbarsInformation(
          FinishingCutbarProperties.fromJSON(cutbarsInformationJSON.cutbarBeforeProperties),
          FinishingCutbarProperties.fromJSON(cutbarsInformationJSON.cutbarAfterProperties),
          CuttingLineProperties.fromJSON(cutbarsInformationJSON.cuttingLineProperties),
          Pattern.fromFoldingTypeJSON(cutbarsInformationJSON.foldingType)
        );
  }

  public hasAddedCutbarBefore(): boolean {
    return !isNil(this.cutbarBeforeProperties);
  }

  public getLengthOfCutbarBeforeInMillimeters(): number {
    return this.hasAddedCutbarBefore() ? this.cutbarBeforeProperties.commercialLengthInMM : 0;
  }

  public getLengthOfCutbarBeforeInNumberOfPicks(): number {
    return this.hasAddedCutbarBefore() ? this.cutbarBeforeProperties.technicalLengthInPicks : 0;
  }

  public hasAddedCutbarAfter(): boolean {
    return !isNil(this.cutbarAfterProperties);
  }

  public getLengthOfCutbarAfterInMillimeters(): number {
    return this.hasAddedCutbarAfter() ? this.cutbarAfterProperties.commercialLengthInMM : 0;
  }

  public getLengthOfCutbarAfterInNumberOfPicks(): number {
    return this.hasAddedCutbarAfter() ? this.cutbarAfterProperties.technicalLengthInPicks : 0;
  }

  public hasAddedCuttingLine(): boolean {
    return !isNil(this.cuttingLineProperties);
  }

  public hasAdjustedFoldingType(): boolean {
    return !isNil(this.foldingType);
  }

  public toJSON(): JSON {
    let result = null;

    if (this.hasAddedCutbarBefore() || this.hasAddedCutbarAfter() || this.hasAddedCuttingLine() || this.hasAdjustedFoldingType()) {
      result = {
        cutbarBeforeProperties: this.hasAddedCutbarBefore() ? this._cutbarBeforeProperties.toJSON() : null,
        cutbarAfterProperties: this.hasAddedCutbarAfter() ? this._cutbarAfterProperties.toJSON() : null,
        cuttingLineProperties: this.hasAddedCuttingLine() ? this._cuttingLineProperties.toJSON() : null,
        foldingTypeId: this.hasAdjustedFoldingType() ? this._foldingType.id : null
      } as any as JSON;
    }

    return result;
  }
}
