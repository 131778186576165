import {CommercialDimensions} from '@domain/commercial-dimensions';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {find, isNil, map} from 'lodash-es';
import {MappingForDesign} from './mapping-for-design';

export class DrawingRecolorInformation {
  private _code: string;
  private _image: string;
  private _shape: string;
  private _colorSet: OverviewListColorSet;
  private _mainColorsCreelPosition: OverviewListCreelPosition;
  private _borderColorsCreelPosition: OverviewListCreelPosition;
  private _commercialDimensionsInMm: CommercialDimensions;
  private _mappingForDesign: MappingForDesign[];
  private _finishing: OverviewListFinishing;

  public constructor(
    code: string,
    shape: string,
    image: string,
    colorSet: OverviewListColorSet,
    mainColorsCreelPosition: OverviewListCreelPosition,
    borderColorsCreelPosition: OverviewListCreelPosition,
    commercialDimensionsInMm: CommercialDimensions,
    mappingForDesign: MappingForDesign[] = [],
    finishing?: OverviewListFinishing
  ) {
    this._code = code;
    this._image = image;
    this._shape = shape;
    this._colorSet = colorSet;
    this._mainColorsCreelPosition = mainColorsCreelPosition;
    this._borderColorsCreelPosition = borderColorsCreelPosition;
    this._commercialDimensionsInMm = commercialDimensionsInMm;
    this._mappingForDesign = mappingForDesign;
    this._finishing = finishing;
  }

  public get code(): string {
    return this._code;
  }

  public get image(): string {
    return this._image;
  }

  public get shape(): string {
    return this._shape;
  }

  public get mainColorsCreelPosition(): OverviewListCreelPosition {
    return this._mainColorsCreelPosition;
  }

  public get colorSet(): OverviewListColorSet {
    return this._colorSet;
  }

  public get borderColorsCreelPosition(): OverviewListCreelPosition {
    return this._borderColorsCreelPosition;
  }

  public get commercialDimensionsInMm(): CommercialDimensions {
    return this._commercialDimensionsInMm;
  }

  public get finishing(): OverviewListFinishing {
    return this._finishing;
  }

  public get mappingForDesign(): MappingForDesign[] {
    return this._mappingForDesign;
  }

  public static fromJSON(drawingRecolorInformationJSON: any): DrawingRecolorInformation {
    const colorSet: OverviewListColorSet = OverviewListColorSet.fromJSON(drawingRecolorInformationJSON.colorSet);
    const mainColorsCreelPosition: OverviewListCreelPosition = find(colorSet.creelPositions, {position: drawingRecolorInformationJSON.mainColorPosition});
    const borderColorsCreelPosition: OverviewListCreelPosition = find(colorSet.creelPositions, {position: drawingRecolorInformationJSON.borderColorPosition});
    const mappingForDesign: MappingForDesign[] = map(drawingRecolorInformationJSON.mappingForDesign, (mappingForDesignJSON: any) => MappingForDesign.fromJSON(mappingForDesignJSON));

    const commercialDimensions: CommercialDimensions = {
      widthInMM: drawingRecolorInformationJSON.commercialDimensions.widthInMM,
      heightInMM: drawingRecolorInformationJSON.commercialDimensions.heightInMM
    };

    const finishing: OverviewListFinishing = !isNil(drawingRecolorInformationJSON.finishing) ? OverviewListFinishing.fromJSON(drawingRecolorInformationJSON.finishing) : null;

    return new DrawingRecolorInformation(
      drawingRecolorInformationJSON.code,
      drawingRecolorInformationJSON.shape,
      drawingRecolorInformationJSON.image,
      colorSet,
      mainColorsCreelPosition,
      borderColorsCreelPosition,
      commercialDimensions,
      mappingForDesign,
      finishing
    );
  }
}
