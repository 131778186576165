import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PriorityLevel} from '@domain/event/priority-level.enum';
import {HighestSeverityNotification} from '@domain/notifications/highest-severity-notification';
import {Notification} from '@domain/notifications/notification';
import {WebNotificationSubscription} from '@domain/notifications/web-notification-subscription';
import {EnumUtils} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HttpNotificationsService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getSlice(start: number, count: number, priorities: PriorityLevel[], groupId?: number, equipmentId?: number): Observable<Notification[]> {
    const filters = priorities.map((priority: PriorityLevel) => EnumUtils.getKeyFromValue(PriorityLevel, priority));
    const params = new HttpParams().set('unread', true).set('start', start).set('count', count);

    return this.httpClient
      .post(`${this.backendConfiguration.getAlertingEndpoint()}notifications`, {priorities: filters, groupId, equipmentId}, {params})
      .pipe(map((notificationsJSON: any) => notificationsJSON.map((notifications: any) => Notification.fromJSON(notifications))));
  }

  public markAsRead(notificationId?: number): Observable<void> {
    const url = notificationId ? `${this.backendConfiguration.getAlertingEndpoint()}notifications/read/${notificationId}` : `${this.backendConfiguration.getAlertingEndpoint()}notifications/read`;
    return this.httpClient.put<void>(url, null);
  }

  public getUnreadCount(): Observable<number> {
    const params = new HttpParams().set('unread', true);
    return this.httpClient.get<number>(`${this.backendConfiguration.getAlertingEndpoint()}notifications/count`, {params});
  }

  public addWebNotificationSubscription(webNotificationSubscription: WebNotificationSubscription): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getAlertingEndpoint()}notifications/web-notification-subscription`, webNotificationSubscription.toJSON())
      .pipe(map((response: {id: number}) => response.id));
  }

  public deleteWebNotificationSubscription(endpoint: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getAlertingEndpoint()}notifications/web-notification-subscription`, {body: {endpoint}});
  }

  public getHighestSeverityNotifications(): Observable<HighestSeverityNotification[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getAlertingEndpoint()}notifications/highest-severity`)
      .pipe(map((notificationsJson: any[]) => notificationsJson.map((notificationJson: any) => HighestSeverityNotification.fromJSON(notificationJson))));
  }

  public getHighestSeverityNotificationsForIds(equipmentIds: number[], groupIds: number[]): Observable<HighestSeverityNotification[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getAlertingEndpoint()}notifications/highest-severity`, {equipmentIds, groupIds})
      .pipe(map((notificationsJson: any[]) => notificationsJson.map((notificationJson: any) => HighestSeverityNotification.fromJSON(notificationJson))));
  }
}
