import {CommercialUnitConversionError} from '../error/commercial-unit-conversion-error';
import {Unit} from '../unit.enum';
import {convertToCommercialUnitCentimeter} from './convert-to-commercial-unit-centimeter';
import {convertToCommercialUnitDecimeter} from './convert-to-commercial-unit-decimeter';
import {convertToCommercialUnitMeter} from './convert-to-commercial-unit-meter';
import {convertToCommercialUnitMillimeter} from './convert-to-commercial-unit-millimeter';
import {convertToCommercialUnitSquareCentimeter} from './convert-to-commercial-unit-square-centimeter';
import {convertToCommercialUnitSquareMeter} from './convert-to-commercial-unit-square-meter';
import {convertToCommercialUnitSquareMillimeter} from './convert-to-commercial-unit-square-millimeter';
import {UnitConversionParameters} from './unit-conversion-parameters';

export function convertCommercialUnit(unitConversionParameters: UnitConversionParameters): number {
  let result: number;

  if (unitConversionParameters.from.value === undefined) {
    return undefined;
  } else if (unitConversionParameters.from.value === null) {
    return null;
  }

  switch (unitConversionParameters.to) {
    case Unit.MILLIMETER:
      result = convertToCommercialUnitMillimeter(unitConversionParameters.from);
      break;
    case Unit.CENTIMETER:
      result = convertToCommercialUnitCentimeter(unitConversionParameters.from);
      break;
    case Unit.DECIMETER:
      result = convertToCommercialUnitDecimeter(unitConversionParameters.from);
      break;
    case Unit.METER:
      result = convertToCommercialUnitMeter(unitConversionParameters.from);
      break;
    case Unit.SQUARE_MILLIMETER:
      result = convertToCommercialUnitSquareMillimeter(unitConversionParameters.from);
      break;
    case Unit.SQUARE_CENTIMETER:
      result = convertToCommercialUnitSquareCentimeter(unitConversionParameters.from);
      break;
    case Unit.SQUARE_METER:
      result = convertToCommercialUnitSquareMeter(unitConversionParameters.from);
      break;
    default:
      throw new CommercialUnitConversionError(`No conversion found from ${unitConversionParameters.from.unit} to ${unitConversionParameters.to}`);
  }

  return result;
}
