<div class="flex-row gap-8 flex-start-center">
  <button mat-icon-button type="button" (click)="goBackwards()">
    <mat-icon svgIcon="chevron-left"></mat-icon>
  </button>

  <mat-form-field subscriptSizing="dynamic" [formGroup]="dateSelectForm">
    <mat-date-range-input [rangePicker]="datePicker">
      <input #startDate matStartDate formControlName="startDate" />
      <input #endDate matEndDate formControlName="endDate" />
    </mat-date-range-input>

    <h4 class="date-range-display url" (click)="datePicker.open()">{{ startDate.value }} - {{ endDate.value || startDate.value }}</h4>

    <mat-datepicker-toggle matSuffix [for]="datePicker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="mat-datepicker-toggle-icon"></mat-icon>
    </mat-datepicker-toggle>

    <mat-date-range-picker #datePicker [calendarHeaderComponent]="DATE_PICKER_HEADER">
      <mat-datepicker-actions class="date-actions">
        <button mat-button (click)="clear()">
          {{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER.CLEAR' | bmsTranslate }}
        </button>
        <button mat-button (click)="today()">
          {{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER.TODAY' | bmsTranslate }}
        </button>
      </mat-datepicker-actions>
    </mat-date-range-picker>
  </mat-form-field>

  <button mat-icon-button type="button" (click)="goForwards()">
    <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>
</div>
