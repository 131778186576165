<div class="full-height">
  <section class="full-height sidebar-content skeleton-wrapper" [class.skeleton]="isLoadingOrderLine" [@fadeIn]="isLoadingOrderLine" fxLayout="column">
    <vdw-header [title]="'ORDERBOOK.ORDER_LINE_INFORMATION' | bmsTranslate" svgIcon="info-blue" [canShowClose]="true" (closeEvent)="closeOrderLineDetails()"></vdw-header>
    <article fxFlex="2 0" fxLayoutGap="16px" fxLayout="column">
      <div fxLayout="column" fxLayoutGap="8px">
        <h3 class="font-weight-semibold">{{ 'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} }}</h3>
        <div class="form-field-skeleton-wrapper" fxLayout="row" fxLayoutGap="16px">
          <app-disabled-form-input [label]="'ORDERBOOK.CUSTOMER_REFERENCE' | bmsTranslate" [value]="orderLine.customerReference" fxFlex="100"></app-disabled-form-input>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <h3 class="font-weight-semibold">{{ 'ORDERBOOK.ORDER' | bmsTranslate }}</h3>
        <div>
          <div fxLayout="row" fxLayoutGap="16px">
            <div fxFlex="240px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input
                [label]="'ORDERBOOK.ORDERBOOK_REFERENCE' | bmsTranslate"
                [value]="orderLine.salesOrderReference + ', ' + orderLine.salesOrderLineReference"
              ></app-disabled-form-input>
            </div>
            <div fxFlex="240px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input fxFlex [label]="'SALES_ORDERS.DESIRED_DATE' | bmsTranslate" [value]="orderLine.desiredDate | momentDate: 'dddd MMM D, YYYY'"></app-disabled-form-input>
            </div>
            <div fxFlex="148px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input
                fxFlex
                [label]="'SALES_ORDERS.PRIORITY.PRIORITY' | bmsTranslate"
                [value]="'SALES_ORDERS.PRIORITY.' + orderLine.priority | bmsTranslate"
              ></app-disabled-form-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="16px">
            <div fxFlex="240px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input [label]="'GENERAL.STATUS' | bmsTranslate" [value]="'ORDERBOOK.ORDER_LINE_STATUS.' + getOrderLineStatusLabel() | bmsTranslate"></app-disabled-form-input>
            </div>
            <div fxFlex="240px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input [label]="'GENERAL.DIMENSIONS.LENGTH' | bmsTranslate" [value]="getCommercialValueInCommercialUnit(orderLine.commercialLengthInMM)"></app-disabled-form-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="16px">
            <div fxFlex="148px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input [label]="'ORDERBOOK.PRODUCED_AMOUNT' | bmsTranslate" [value]="orderLine?.amountProduced"></app-disabled-form-input>
            </div>
            <div fxFlex="148px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input [label]="'ORDERBOOK.PLANNED_AMOUNT' | bmsTranslate" [value]="orderLine?.amountPlanned"></app-disabled-form-input>
            </div>
            <div fxFlex="148px" class="form-field-skeleton-wrapper">
              <app-disabled-form-input fxFlex [label]="'ORDERBOOK.REQUESTED_AMOUNT' | bmsTranslate" [value]="orderLine?.amount"></app-disabled-form-input>
            </div>
          </div>
        </div>
      </div>
      <div class="production-schedules" *ngIf="canShowProductionSchedules()" fxLayout="column" fxLayoutGap="8px">
        <h3 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.PRODUCTION_ORDER' | bmsTranslate: {count: 2} }}</h3>
        <div class="production-schedules-overview" fxLayout="row" *ngIf="isLoadingOrderLine" fxLayoutGap="16px">
          <div class="text-skeleton-wrapper" fxFlex="0 1 calc((100% - 48px) / 4"></div>
          <div class="text-skeleton-wrapper" fxFlex="0 1 calc((100% - 48px) / 4"></div>
          <div class="text-skeleton-wrapper" fxFlex="0 1 calc((100% - 48px) / 4"></div>
          <div class="text-skeleton-wrapper" fxFlex="0 1 calc((100% - 48px) / 4"></div>
        </div>
        <div *ngIf="!isLoadingOrderLine" fxLayout="row wrap" fxLayoutGap="16px">
          <a class="url" *ngFor="let productionSchedule of orderLine.productionSchedules" (click)="navigateToProductionScheduleDetails(productionSchedule.id)">
            <ng-container *ngIf="productionSchedule.name; else noNameYet">
              {{ productionSchedule.name }}
            </ng-container>
            <ng-template #noNameYet> {{ 'GENERAL.NO_DATA' | bmsTranslate: {object: 'GENERAL.NAME' | bmsTranslate | lowercase} }} </ng-template>
            ({{ 'ORDERBOOK.AMOUNT_OF_ITEMS' | bmsTranslate: {amount: productionSchedule.amountOfItems} }})
          </a>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <h3 class="font-weight-semibold">{{ 'ARTICLES.ARTICLE' | bmsTranslate: {count: 1} }}</h3>
        <div fxLayout="row" fxLayoutGap="16px">
          <div fxFlex="134px" fxLayout="column" fxLayoutGap="6px">
            <label class="disabled-form-input-label b2">
              {{ 'GENERAL.PREVIEW' | bmsTranslate }}
            </label>
            <div class="drawing-image" fxFlex="134px">
              <img height="132" *ngIf="isLoadingOrderLine" src="/assets/images/image-placeholder.svg" />
              <app-drawing-library-image-preview
                *ngIf="!isLoadingOrderLine"
                draggable="false"
                [drawingImage]="orderLine?.article?.design.image"
                [qualityId]="orderLine?.article?.machineQuality.id"
                [colorSetId]="coloredYarnSet?.colorSet?.id ?? colorSet.id"
                [hasMissingDesign]="isArticlePreviewMissingDesign()"
                [drawingDimensionsInPx]="orderLine?.article?.design.dimensionsInPixels"
                [areImagesUploading]="false"
                [shouldDownloadImage]="true"
                (imageDownloaded)="imageDownloaded($event)"
              >
              </app-drawing-library-image-preview>
            </div>
          </div>
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'GENERAL.NAME' | bmsTranslate" [value]="orderLine.article?.name"></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'DESIGN_LIBRARY.DESIGN' | bmsTranslate: {count: 1}" [value]="orderLine.article?.design.name"></app-disabled-form-input>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY' | bmsTranslate: {count: 1}"
                  [value]="orderLine.article?.machineQuality.nameWithVersion"
                ></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'TEXTILE_DATA.COLOR_SET.COLOR_SET' | bmsTranslate: {count: 1}"
                  [value]="orderLine.article?.colorConfiguration?.coloredYarnSet?.colorSet?.name ?? orderLine.article?.colorConfiguration?.colorSet?.name"
                ></app-disabled-form-input>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'DESIGN_LIBRARY.DETAILS.COLOR_MAIN' | bmsTranslate" [value]="orderLine.article?.colorConfiguration.mainColor?.name"></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'DESIGN_LIBRARY.DETAILS.COLOR_BORDER' | bmsTranslate" [value]="orderLine.article?.colorConfiguration.borderColor?.name"></app-disabled-form-input>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'GENERAL.DIMENSIONS.WIDTH' | bmsTranslate"
                  [value]="getTechnicalValueWithUnit(orderLine.article?.design?.technicalDimensions?.widthInDents, dentsUnit)"
                ></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'GENERAL.DIMENSIONS.HEIGHT' | bmsTranslate"
                  [value]="getTechnicalValueWithUnit(orderLine.article?.design?.technicalDimensions?.heightInPicks, picksUnit)"
                ></app-disabled-form-input>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'GENERAL.DIMENSIONS.WIDTH' | bmsTranslate"
                  [value]="getCommercialValueInCommercialUnit(orderLine.article?.design?.commercialDimensions?.widthInMM)"
                ></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input
                  [label]="'GENERAL.DIMENSIONS.HEIGHT' | bmsTranslate"
                  [value]="getCommercialValueInCommercialUnit(orderLine.article?.design?.commercialDimensions?.heightInMM)"
                ></app-disabled-form-input>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'GENERAL.SHAPE' | bmsTranslate" [value]="getShape(orderLine.article?.design.shape)"></app-disabled-form-input>
              </div>
              <div fxFlex="calc((100% - 16px)/2)" class="form-field-skeleton-wrapper">
                <app-disabled-form-input [label]="'TEXTILE_DATA.FINISHING.FINISHING' | bmsTranslate: {count: 1}" [value]="orderLine.article?.finishing?.nameWithVersion"></app-disabled-form-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-colored-yarn-set-preview
        *ngIf="colorSet"
        [coloredYarnSet]="coloredYarnSet"
        [colorSet]="overviewListColorSet"
        [yarnSet]="getYarnSet()"
        [orderlineId]="orderLineId"
      ></app-colored-yarn-set-preview>
    </article>
  </section>
</div>
