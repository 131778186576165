import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PropertyValue} from '@domain/property-value';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {ColorSets} from '@infrastructure/http/color-set/color-sets';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpColorSetsService implements ColorSets {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}colorsets/check-name`, {params}).pipe(rxjsMap((response: any) => CheckName.fromJSON(response)));
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}colorsets/${id}`);
  }

  public getAll(): Observable<OverviewListColorSet[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}colorsets`).pipe(rxjsMap(this.mapOverviewListColorSet));
  }

  public getAllByMachineQuality(machineQualityId: number): Observable<OverviewListColorSet[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}colorsets/quality=${machineQualityId}`).pipe(rxjsMap(this.mapOverviewListColorSet));
  }

  public getById(id: number): Observable<ColorSet> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}colorsets/${id}`).pipe(
      rxjsMap((colorSetJSON: any) => {
        return ColorSet.fromJSON(colorSetJSON);
      })
    );
  }

  public save(item: ColorSet): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}colorsets`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public update(item: ColorSet): Observable<number> {
    return this.httpClient.put(`${this.backendConfiguration.getEndpoint()}colorsets/${item.id}`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}colorsets/${id}/conflicts`)
      .pipe(rxjsMap((conflictsJSON: any) => map(conflictsJSON, (conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get<PropertyValue[]>(`${this.backendConfiguration.getEndpoint()}colorsets/custom-settings`);
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}colorsets/custom-settings/already-adjusted`).pipe(
      rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}colorsets/custom-settings`, chosenSettings);
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getEndpoint()}colorsets/custom-settings/name-generation-properties`);
  }

  public generateName(item: ColorSet): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getEndpoint()}colorsets/custom-settings/generate-name`, item.toJSON());
  }

  private mapOverviewListColorSet(colorSetsJSON: any): OverviewListColorSet[] {
    return map(colorSetsJSON, (colorSetJSON: any) => {
      return OverviewListColorSet.fromJSON(colorSetJSON);
    });
  }
}
