import {Selvedge} from './selvedge';

export class Selvedges {
  private _left: Selvedge;
  private _right: Selvedge;
  private _center: Selvedge;

  public constructor(left: Selvedge, right: Selvedge, center: Selvedge) {
    this._left = left;
    this._right = right;
    this._center = center;
  }

  public get left(): Selvedge {
    return this._left;
  }

  public get right(): Selvedge {
    return this._right;
  }

  public get center(): Selvedge {
    return this._center;
  }

  public static fromJSON(selvedgesJSON: any): Selvedges {
    return new Selvedges(
      selvedgesJSON.left === null ? null : Selvedge.fromJSON(selvedgesJSON.left),
      selvedgesJSON.right === null ? null : Selvedge.fromJSON(selvedgesJSON.right),
      selvedgesJSON.center === null ? null : Selvedge.fromJSON(selvedgesJSON.center)
    );
  }

  public toJSON(): JSON {
    return {
      left: this._left === null ? null : this._left.toJSON(),
      right: this._right === null ? null : this._right.toJSON(),
      center: this._center === null ? null : this._center.toJSON()
    } as any as JSON;
  }
}
