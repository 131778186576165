import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {PlaceholderResponse} from '@domain/textile-data/finishing-and-finishing-template/placeholder-response';
import {BaseComponent, DialogBuilderFactoryService} from '@vdw/angular-component-library';
import {isEmpty, isNil} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {ChangePlaceholderPartsDialogComponent} from '../change-placeholder-parts-dialog/change-placeholder-parts-dialog.component';

@Component({
  selector: 'app-label-placeholder',
  templateUrl: './label-placeholder.component.html'
})
export class LabelPlaceholderComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public isPathLabelBefore: boolean;
  @Input() public isPathLabelAfter: boolean;
  @Input() public buttonTitle: string;
  @Input() public linkFormInputPlaceholder: string;
  @Input() public headerTitle: string;
  @Input() public columnTitle: string;
  @Input() public requiredMaxLength = false;
  @Input() public required = false;
  @Input() public touched = false;
  @Input() public isSerialNumberAllowedFreePosition = true;
  @Output() public placeholderPartsChanged: EventEmitter<PlaceholderComponent[]> = new EventEmitter<PlaceholderComponent[]>();
  public placeholderForm: FormGroup<{
    placeholderLabel: FormControl<string>;
  }>;

  private _listOfPlaceholderCategoriesWithComponents: PlaceholderCategory[];
  private _separatorForTextPlaceholder: string;
  private _placeholderParts: PlaceholderComponent[];
  private filteredEditedPlaceholderParts: PlaceholderComponent[];

  @Input()
  public get listOfPlaceholderCategoriesWithComponents(): PlaceholderCategory[] {
    return this._listOfPlaceholderCategoriesWithComponents;
  }

  public set listOfPlaceholderCategoriesWithComponents(placeholderCategoriesWithComponents: PlaceholderCategory[]) {
    this._listOfPlaceholderCategoriesWithComponents = placeholderCategoriesWithComponents;
    this.setEditedPlaceholderWithCategories();
    this.setFilteredEditedPlaceholderParts();
    this.setLabelFromPlaceholderComponents();
  }

  @Input()
  public get separatorForTextPlaceholder(): string {
    return this._separatorForTextPlaceholder;
  }

  public set separatorForTextPlaceholder(separator: string) {
    this._separatorForTextPlaceholder = separator;
    this.setLabelFromPlaceholderComponents();
  }

  @Input()
  public get placeholderParts(): PlaceholderComponent[] {
    return this._placeholderParts;
  }

  public set placeholderParts(listOfPlaceholderParts: PlaceholderComponent[]) {
    this._placeholderParts = listOfPlaceholderParts;
    if (this._placeholderParts?.length > 0) {
      this.setEditedPlaceholderWithCategories();
      this.setFilteredEditedPlaceholderParts();
    }
    this.setLabelFromPlaceholderComponents();
  }

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly formBuilder: FormBuilder
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setPlaceholderForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('touched' in changes && !changes.touched.isFirstChange() && this.touched) {
      this.placeholderForm.controls.placeholderLabel.markAsTouched();
    }
  }

  public hasChosenPlaceholderParts(): boolean {
    return !isEmpty(this.placeholderParts);
  }

  public setLabelFromPlaceholderComponents(): void {
    this.placeholderForm?.controls.placeholderLabel.setValue(PlaceholderComponent.getLabelFromPlaceholderComponents(this.filteredEditedPlaceholderParts, this.separatorForTextPlaceholder));
  }

  public removePlaceholderParts(): void {
    this.filteredEditedPlaceholderParts = [];
    this.placeholderParts = [];
    this.placeholderPartsChanged.emit(this.placeholderParts);
  }

  public showBuilderForPlaceholderParts(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('change-placeholder-parts')
      .openDialog(ChangePlaceholderPartsDialogComponent, {
        placeholderParts: this._placeholderParts,
        listOfPlaceholderCategoriesWithComponents: this._listOfPlaceholderCategoriesWithComponents,
        separatorForTextPlaceholder: this.separatorForTextPlaceholder,
        specifyTitle: this.buttonTitle,
        headerTitle: this.headerTitle,
        columnTitle: this.columnTitle,
        filteredPlaceholderParts: this.filteredEditedPlaceholderParts,
        requiredMaxLength: this.requiredMaxLength,
        isSerialNumberAllowedFreePosition: this.isSerialNumberAllowedFreePosition
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((placeholderResponse: PlaceholderResponse) => {
        if (!isNil(placeholderResponse)) {
          this.filteredEditedPlaceholderParts = placeholderResponse.filteredEditedPlaceholderParts;
          this._placeholderParts = placeholderResponse.editedPlaceholderParts;
          this.setLabelFromPlaceholderComponents();
          this.placeholderPartsChanged.emit(placeholderResponse.editedPlaceholderParts);
        } else {
          this.placeholderPartsChanged.emit(this._placeholderParts);
        }
      });
  }

  private setFilteredEditedPlaceholderParts(): void {
    if (this._placeholderParts) {
      const filteredPlaceholderParts: PlaceholderComponent[] = [];
      this._placeholderParts.forEach((placeholderComponent: PlaceholderComponent) => {
        const categoryName = placeholderComponent.categoryName;
        if (!filteredPlaceholderParts.find((placeholder: PlaceholderComponent) => placeholder.categoryName === categoryName)) {
          filteredPlaceholderParts.push(placeholderComponent);
        } else {
          if (!placeholderComponent.configurableValue) {
            filteredPlaceholderParts.push(placeholderComponent);
          }
        }
      });
      this.filteredEditedPlaceholderParts = filteredPlaceholderParts;
    }
  }

  private setEditedPlaceholderWithCategories(): void {
    if (this._placeholderParts) {
      this._placeholderParts.forEach((editedPlaceholderParts: PlaceholderComponent) => {
        this._listOfPlaceholderCategoriesWithComponents?.forEach((placeholderCategoriesWithComponents: PlaceholderCategory) => {
          if (placeholderCategoriesWithComponents.placeholderComponents.find((placeholderComponent: PlaceholderComponent) => placeholderComponent.id === editedPlaceholderParts.id)) {
            editedPlaceholderParts.configurableValue = placeholderCategoriesWithComponents.placeholderComponents[0].configurableValue;
            editedPlaceholderParts.categoryName = placeholderCategoriesWithComponents.name;
          }
        });
      });
    }
  }

  private setPlaceholderForm(): void {
    this.placeholderForm = this.formBuilder.group({
      placeholderLabel: this.formBuilder.control(null, this.required ? Validators.required : [])
    });
    this.setLabelFromPlaceholderComponents();
  }
}
