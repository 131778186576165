import {Component, Inject} from '@angular/core';
import {InterfaceOf} from '../../../common/types/interface-of';
import type {PlanningGridComponent} from '../planning-grid/planning-grid.component';
import {PLANNING_GRID_TOKEN} from '../planning-grid/planning-grid.token';

@Component({
  selector: 'vdw-date-header-row',
  templateUrl: './date-header-row.component.html',
  styleUrls: ['./date-header-row.component.scss']
})
export class DateHeaderRowComponent {
  public constructor(@Inject(PLANNING_GRID_TOKEN) public readonly grid: InterfaceOf<PlanningGridComponent>) {}
}
