import {BreakpointState} from '@angular/cdk/layout';
import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {Device} from './device.enum';

export const RESPONSIVENESS_VIEW_MODE = new InjectionToken<ResponsivenessViewMode>('ResponsivenessViewMode');

export interface ResponsivenessViewMode {
  readonly isPhone: boolean;
  readonly isTablet: boolean;
  readonly isDesktop: boolean;

  observeBreakpointChanges(mediaQuery: string | string[]): Observable<BreakpointState>;

  matchesBreakpoint(mediaQuery: string): boolean;

  observeDeviceChanges(): Observable<Device>;
}
