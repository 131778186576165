import {Injectable} from '@angular/core';
import {NameGeneratorPartType} from '@domain/name-generator/enums/name-generator-part-type.enum';
import {NameGenerationProperty} from '@domain/name-generator/name-generation-property';
import {NameGeneratorPart} from '@domain/name-generator/name-generator-part';
import {CustomTextParameters} from '@domain/name-generator/parameters/custom-text-parameters.interface';
import {EntityPropertyParameters} from '@domain/name-generator/parameters/entity-property-parameters.interface';
import {SerialNumberParameters} from '@domain/name-generator/parameters/serial-number-parameters.interface';
import {AssertionUtils, TranslateService} from '@vdw/angular-component-library';
import {getDisplayValueForPlaceholderSeparator} from '../get-display-value-for-placeholder-separator';

@Injectable({providedIn: 'root'})
export class NameGeneratorPartService {
  public constructor(private readonly translate: TranslateService) {}

  public getPreviewText(nameGeneratorParts: NameGeneratorPart<NameGeneratorPartType>[], namePlaceholderSeparator: string): string {
    let nameGenerationPatternPreviewText: string[] = [];

    nameGeneratorParts?.forEach((nameGeneratorPart: NameGeneratorPart<NameGeneratorPartType>) => {
      let previewText = '';
      let previewTextsInBracket: (string | number)[];

      if (nameGeneratorPart.type === NameGeneratorPartType.CUSTOM_TEXT) {
        previewText = (nameGeneratorPart.parameters as CustomTextParameters).value ?? '';
      } else {
        if (nameGeneratorPart.type === NameGeneratorPartType.ENTITY_PROPERTY) {
          const parameters = nameGeneratorPart.parameters as EntityPropertyParameters;
          previewText = this.translate.instant(NameGenerationProperty.getTranslationKey(parameters.value), {count: 1}).replace(/\s/g, '');
          previewTextsInBracket = [this.translate.instant(`GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.${parameters.cutFrom}`), parameters.startCharacter, parameters.length];
        } else {
          const parameters = nameGeneratorPart.parameters as SerialNumberParameters;
          previewText = this.translate.instant(NameGenerationProperty.getTranslationKey(nameGeneratorPart.type)).split(' ')[0];
          previewTextsInBracket = [parameters.length, parameters.startValue, parameters.stepSize];
        }
        previewTextsInBracket = previewTextsInBracket.filter((previewTextInBracket: string | number) => !AssertionUtils.isNullOrUndefined(previewTextInBracket) && previewTextInBracket !== '');
      }
      nameGenerationPatternPreviewText.push(previewText + (previewTextsInBracket?.length ? `(${previewTextsInBracket.join(',')})` : ''));
    });

    return nameGenerationPatternPreviewText.join(getDisplayValueForPlaceholderSeparator(namePlaceholderSeparator));
  }
}
