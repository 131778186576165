import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {DndListModule} from '@ryware/ngx-drag-and-drop-lists';
import {DndDragDropHelperService} from './dnd-drag-drop-helper-service/dnd-drag-drop-helper.service';
import {DndDragPreviewContentDirective} from './dnd-drag-preview-content.directive';
import {DndDraggableDropListComponent} from './dnd-draggable-drop-list/dnd-draggable-drop-list.component';
import {DndDropListContentDirective} from './dnd-drop-list-content.directive';

@NgModule({
  imports: [CommonModule, MatExpansionModule, DndListModule],
  providers: [DndDragDropHelperService],
  exports: [DndDraggableDropListComponent, DndListModule, DndDragPreviewContentDirective, DndDropListContentDirective],
  declarations: [DndDraggableDropListComponent, DndDragPreviewContentDirective, DndDropListContentDirective]
})
export class DndDraggableDropListModule {}
