import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {HorizontalPositionOfColorCreelPosition} from '@domain/textile-data/color-set/horizontal-position-of-color-creel-position';
import {HorizontalPositionOfGroupCreelPosition} from '@domain/textile-data/colored-yarn-set/horizontal-position-of-group-creel-position';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {ColorStubFactory} from '@vdw/angular-component-library';
import {times} from 'lodash-es';

export class ColorSetStubFactory {
  public static getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(id: number, numberOfCreelPositions: number): ColorSet {
    return new ColorSet(
      id,
      `Color set ${id}`,
      times(numberOfCreelPositions, (creelPositionIndex: number) => {
        return new CreelPosition([new HorizontalPositionOfColorCreelPosition(3, ColorStubFactory.getRandomColorWithId(creelPositionIndex))]);
      }),
      false,
      null,
      null
    );
  }

  public static getRandomColorSetWithIdAndName(id: number, name: string): ColorSet {
    return new ColorSet(
      id,
      name,
      [
        new CreelPosition([new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(1, '#10bb12'))]),
        new CreelPosition([
          new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(2, '#EFA355')),
          new HorizontalPositionOfGroupCreelPosition(2, [
            new HorizontalPositionOfColorCreelPosition(1, ColorStubFactory.getRandomColorWithIdAndColorCode(3, '#DD3406')),
            new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(4, '#00f33c')),
            new HorizontalPositionOfColorCreelPosition(4, ColorStubFactory.getRandomColorWithIdAndColorCode(5, '#c62adc'))
          ]),
          new HorizontalPositionOfColorCreelPosition(3, ColorStubFactory.getRandomColorWithIdAndColorCode(6, '#2e6cff')),
          new HorizontalPositionOfColorCreelPosition(1, ColorStubFactory.getRandomColorWithIdAndColorCode(7, '#efd800')),
          new HorizontalPositionOfGroupCreelPosition(3, [
            new HorizontalPositionOfColorCreelPosition(3, ColorStubFactory.getRandomColorWithIdAndColorCode(8, '#537dff')),
            new HorizontalPositionOfColorCreelPosition(4, ColorStubFactory.getRandomColorWithIdAndColorCode(9, '#ffc1de'))
          ])
        ]),
        new CreelPosition([
          new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(8, '#9867bb')),
          new HorizontalPositionOfColorCreelPosition(1, ColorStubFactory.getRandomColorWithIdAndColorCode(9, '#c4dbff'))
        ]),
        new CreelPosition([
          new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(10, '#3f53ff')),
          new HorizontalPositionOfColorCreelPosition(3, ColorStubFactory.getRandomColorWithIdAndColorCode(11, '#fff46a')),
          new HorizontalPositionOfColorCreelPosition(1, ColorStubFactory.getRandomColorWithIdAndColorCode(12, '#ef7513'))
        ]),
        new CreelPosition([
          new HorizontalPositionOfColorCreelPosition(2, ColorStubFactory.getRandomColorWithIdAndColorCode(13, '#3f53ff')),
          new HorizontalPositionOfColorCreelPosition(3, ColorStubFactory.getRandomColorWithIdAndColorCode(14, '#fff46a')),
          new HorizontalPositionOfColorCreelPosition(1, ColorStubFactory.getRandomColorWithIdAndColorCode(15, '#ef7513'))
        ])
      ],
      false,
      null,
      null
    );
  }

  public static getRandomColorSetWithId(id: number): ColorSet {
    return this.getRandomColorSetWithIdAndName(id, `Color set ${id}`);
  }

  public static getRandomColorSet(): ColorSet {
    return this.getRandomColorSetWithId(1);
  }
}
