import {Inject, Injectable} from '@angular/core';
import {DateRange} from '@domain/date-range';
import {CalendarItem} from '@domain/planning/calendar-item';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningItemType} from '@domain/planning/planning-item-type.enum';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {DeletedPlanningItem} from '@infrastructure/signalr/planning/deleted-planning-item';
import {moment, ToastService, TranslateService} from '@vdw/angular-component-library';
import {PLANNING_SCHEDULER, PlanningScheduler} from '../planning-scheduler/planning-scheduler';
import {PlanningSidebarManager} from '../sidebars/planning-sidebar-manager';
import {PlanningSidebarsComponent} from '../sidebars/planning-sidebars.component';
import {PlanningContextService} from './planning-context.service';

@Injectable()
export class PlanningRealtimeChangesService {
  public selectedDateRange: DateRange;
  private planningSideBars: PlanningSidebarsComponent;
  private planningSidebarManager: PlanningSidebarManager;

  public constructor(
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly planningContext: PlanningContextService,
    @Inject(PLANNING_SCHEDULER) private readonly planningScheduler: PlanningScheduler
  ) {}

  public initialize(dateRange: DateRange, sidebarManager: PlanningSidebarManager, sideBars: PlanningSidebarsComponent): void {
    this.planningSideBars = sideBars;
    this.selectedDateRange = dateRange;
    this.planningSidebarManager = sidebarManager;
  }

  public updateGroupedCalendarItems(updatedGroupedCalendarItems: CalendarItemGroup[]): void {
    for (const updatedGroupedCalendarItem of updatedGroupedCalendarItems) {
      const calendarItems = updatedGroupedCalendarItem.items.map((item: CalendarItem) => item as PlanningItem);

      for (const planningItem of calendarItems) {
        if (this.isItemSelectedItem(planningItem)) {
          continue;
        }

        if (this.isItemInSelectedRange(planningItem)) {
          this.removeOutdatedPlanningItem(planningItem.uniqueId, (group: CalendarItemGroup) => group.machineId !== updatedGroupedCalendarItem.machineId);
          const groupedCalendarItemToUpdate = this.planningContext.groupedCalendarItems.find((group: CalendarItemGroup) => group.machineId === updatedGroupedCalendarItem.machineId);

          if (groupedCalendarItemToUpdate != null) {
            this.updateItemInCalendarItemGroup(groupedCalendarItemToUpdate, planningItem);
          }
        } else {
          this.removeOutdatedPlanningItem(planningItem.uniqueId, () => true);
        }
      }
    }
    this.planningScheduler.setGroupedCalendarItems(this.planningContext.groupedCalendarItems);
  }

  public removeOutdatedPlanningItem(planningItemUniqueId: string, predicate: (groupedCalendarItem: CalendarItemGroup) => boolean): void {
    for (const groupedCalendarItem of this.planningContext.groupedCalendarItems) {
      if (predicate(groupedCalendarItem)) {
        groupedCalendarItem.items = groupedCalendarItem.items.filter((item: PlanningItem) => item.uniqueId !== planningItemUniqueId);
      }
    }
  }

  public updateItemInCalendarItemGroup(groupedCalendarItem: CalendarItemGroup, updateItem: PlanningItem): void {
    groupedCalendarItem.items = groupedCalendarItem.items.filter((item: CalendarItem) => item.id != null);
    const planningItemIndex = groupedCalendarItem.items.findIndex((item: PlanningItem) => item.uniqueId === updateItem.uniqueId);

    if (planningItemIndex === -1) {
      groupedCalendarItem.items.push(updateItem);
    } else {
      groupedCalendarItem.items[planningItemIndex] = updateItem;
    }
  }

  public removePlanningItemFromGroupedCalendarItems(deletedItem: DeletedPlanningItem): void {
    for (const calendarItemGroup of this.planningContext.groupedCalendarItems) {
      const originalSize = calendarItemGroup.items.length;
      calendarItemGroup.items = calendarItemGroup.items.filter((item: PlanningItem) => PlanningItemType[item.type] !== deletedItem.type || item.id !== deletedItem.id);

      if (originalSize > calendarItemGroup.items.length) {
        break;
      }
    }

    if (this.planningSidebarManager.isSidebarTypePlanningItem()) {
      this.planningSideBars.closeSidebar();
    }

    this.planningScheduler.setGroupedCalendarItems(this.planningContext.groupedCalendarItems);
    this.planningContext.groupedCalendarItems = this.planningContext.groupedCalendarItems.slice();
  }

  public showToastForDeletedPlanningItem(type: string): void {
    this.toastService.showInfo({
      timeOut: 3000,
      tapToDismiss: false,
      message: this.translate.instant('GENERAL.ACTIONS.DELETED_OBJECT', {object: this.translate.instant(`PLANNING.ADD_ITEM.TYPES.${type}`)})
    });
  }

  public updatePlanningOrderStatus(id: number, status: ProductionScheduleStatus, isWeaving: boolean): void {
    const calendarItems = this.planningContext.groupedCalendarItems.flatMap((group: CalendarItemGroup) => group.items);

    for (const calendarItem of calendarItems) {
      const planningItem = calendarItem as PlanningItem;

      if (planningItem instanceof PlanningMachineRun) {
        const ordersFilteredByType = planningItem.planningOrders.filter((order: PlanningOrder) => order.isWeavingOrder === isWeaving);
        const foundPlanningOrder = ordersFilteredByType.find((order: PlanningOrder) => order.productionOrder.id === id);

        if (foundPlanningOrder) {
          foundPlanningOrder.productionOrder.status = status;
        }
      }
    }
  }

  private isItemInSelectedRange(planningItem: PlanningItem): boolean {
    return moment.range(this.selectedDateRange).overlaps(moment.range(planningItem.startDate, planningItem.endDate));
  }

  private isItemSelectedItem(planningItem: PlanningItem): boolean {
    return planningItem.id === this.planningContext.selectedPlanningItem?.id;
  }
}
