import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {ProductionOrderTuft} from './production-order-tuft';

export class OrderTufting extends PlanningItem {
  private _productionOrder: ProductionOrderTuft;
  private _producedQuantityInMeters: number;
  private _estimatedProductionTime: string;

  public constructor(
    itemProperties: PlanningItemProperties,
    estimatedProductionTime: string,
    productionOrder: ProductionOrderTuft,
    producedQuantityInMeters: number = 0,
    ...planningItems: PlanningItem[]
  ) {
    super(itemProperties, PlanningItemType.ORDER_TUFTING, ...planningItems);
    this._productionOrder = productionOrder;
    this._producedQuantityInMeters = producedQuantityInMeters;
    this._estimatedProductionTime = estimatedProductionTime;
  }

  public get productionOrder(): ProductionOrderTuft {
    return this._productionOrder;
  }

  public get producedQuantityInMeters(): number {
    return this._producedQuantityInMeters;
  }

  public get estimatedProductionTime(): string {
    return this._estimatedProductionTime;
  }

  public set estimatedProductionTime(value: string) {
    this._estimatedProductionTime = value;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      productionOrderLiteId: this.productionOrder.id,
      estimatedProductionTime: this.estimatedProductionTime
    } as any as JSON;
  }
}
