import {Component, Inject} from '@angular/core';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {NotificationsComponent} from '@presentation/pages/notifications/notifications.component';
import {NotificationsService} from '@presentation/pages/notifications/notifications.service';
import {DialogBuilderFactoryService, LocalStorageService, SelectionListItem} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {PushNotificationsService} from '../push-notifications.service';
import {NotificationListFilterComponentMatDialogConfigDataInterface} from './notification-mobile-list-filter/notification-list-filter-component-mat-dialog-config-data.interface';
import {NotificationMobileListFilterComponent} from './notification-mobile-list-filter/notification-mobile-list-filter.component';

@Component({
  templateUrl: './notification-mobile-overview-page.component.html',
  styleUrls: ['./notification-mobile-overview-page.component.scss']
})
export class NotificationMobileOverviewPageComponent extends NotificationsComponent {
  public readonly URL_TO_ROOT = RouteUtils.paths.absolutePath;
  public readonly FILTER_ICON = 'settings';

  private readonly HEIGHT_WITH_PUSH_NOTIFICATION_TOGGLE = '372px';
  private readonly HEIGHT_WITHOUT_PUSH_NOTIFICATION_TOGGLE = '336px';

  public constructor(
    localStorage: LocalStorageService,
    @Inject(AUTHENTICATION) authentication: Authentication,
    public readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    notificationsService: NotificationsService,
    private readonly pushNotificationsService: PushNotificationsService
  ) {
    super(localStorage, notificationsService, authentication);
  }

  public onFilterClicked(): void {
    const height = this.pushNotificationsService.isBrowserSupported() ? this.HEIGHT_WITH_PUSH_NOTIFICATION_TOGGLE : this.HEIGHT_WITHOUT_PUSH_NOTIFICATION_TOGGLE;

    this.dialogBuilderFactoryService
      .getBuilder()
      .withWidth('100%')
      .withMaxWidth('unset')
      .withHeight(height)
      .withPosition({
        bottom: '0'
      })
      .withClass('notifications-mobile-panel')
      .openDialog<NotificationMobileListFilterComponent, NotificationListFilterComponentMatDialogConfigDataInterface, SelectionListItem[]>(NotificationMobileListFilterComponent, {
        priorities: this.priorities
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((priorities: SelectionListItem[] | undefined) => {
        if (priorities) {
          this.onOptionChanged(priorities);
        }
      });
  }
}
