import {Type} from '@angular/core';
import {ActivatedRouteSnapshot, LoadChildrenCallback, Route} from '@angular/router';
import {canActivatePermissionPrototypeGuard} from '@application/security/can-activate-permission-prototype.guard';
import {canActivatePermissionGuard} from '@application/security/can-activate-permission.guard';
import {Permission} from '@domain/profile/permission.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {RouteData} from './route-data.interface';
import {RouteNode} from './route-node';

export class AuthorizedRouteFactory {
  public static createModuleRoute(routeNode: RouteNode, loadChildren: LoadChildrenCallback, data: any = {}): Route {
    return {
      path: routeNode.path,
      loadChildren,
      canActivate: [canActivatePermissionGuard],
      data: {
        requiredPermission: routeNode.requiredPermission,
        ...data
      }
    };
  }

  public static createPrototypeModuleRoute(data: RouteData, modulePath: Promise<any>, canActivateGuards: any[] = [], canActivateChild: any[] = [], canLoad: any[] = []): Route {
    const loadChildren = (): any =>
      modulePath.then((m: any) => {
        const module = Object.keys(m).find((key: string) => m[key] instanceof Object);

        return m[module];
      });

    return {path: data.route, loadChildren, canActivate: this.getActivateGuards(canActivateGuards), data, canActivateChild};
  }

  public static createComponentRoute(
    routeNode: RouteNode,
    component: Type<any>,
    data: any = {},
    requiredPermissionFactory: (route: ActivatedRouteSnapshot) => Permission = null,
    canActivateGuards: any[] = [],
    canDeavtivateGuards: any[] = []
  ): Route {
    return {
      path: routeNode.path,
      component,
      canActivate: AssertionUtils.isEmpty(canActivateGuards) ? [canActivatePermissionGuard] : canActivateGuards,
      canDeactivate: canDeavtivateGuards,
      data: AssertionUtils.isNullOrUndefined(requiredPermissionFactory) ? {requiredPermission: routeNode.requiredPermission, ...data} : {requiredPermissionFactory, ...data}
    };
  }

  public static createPrototypeComponentRoute(component: Type<any>, data: RouteData, canActivateGuards: any[] = [], canDeactivateGuards: any[] = []): Route {
    return {path: data.route, component, canActivate: this.getActivateGuards(canActivateGuards), canDeactivate: canDeactivateGuards, data};
  }

  private static getActivateGuards(canActivateGuards: any[]): any {
    return AssertionUtils.isEmpty(canActivateGuards) ? [canActivatePermissionPrototypeGuard] : [canActivatePermissionPrototypeGuard, ...canActivateGuards];
  }
}
