import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class WeaveStructureRoute extends RouteBranch {
  private _addWeaveStructure: RouteLeaf;
  private _duplicateWeaveStructure: RouteLeaf;
  private _editWeaveStructure: RouteLeaf;
  private _manageAddWeaveStructure: RouteLeaf;
  private _overviewWeaveStructure: RouteLeaf;
  private _manageOverviewWeaveStructure: RouteLeaf;
  private _weaveStructureSettings: RouteLeaf;

  public get overviewWeaveStructure(): RouteLeaf {
    return this._overviewWeaveStructure;
  }

  public set overviewWeaveStructure(value: RouteLeaf) {
    this.replace(this._overviewWeaveStructure, value);
    this._overviewWeaveStructure = value;
  }

  public get addWeaveStructure(): RouteLeaf {
    return this._addWeaveStructure;
  }

  public set addWeaveStructure(value: RouteLeaf) {
    this.replace(this._addWeaveStructure, value);
    this._addWeaveStructure = value;
  }

  public get duplicateWeaveStructure(): RouteLeaf {
    return this._duplicateWeaveStructure;
  }

  public set duplicateWeaveStructure(value: RouteLeaf) {
    this.replace(this._duplicateWeaveStructure, value);
    this._duplicateWeaveStructure = value;
  }

  public get editWeaveStructure(): RouteLeaf {
    return this._editWeaveStructure;
  }

  public set editWeaveStructure(value: RouteLeaf) {
    this.replace(this._editWeaveStructure, value);
    this._editWeaveStructure = value;
  }

  public get weaveStructureSettings(): RouteLeaf {
    return this._weaveStructureSettings;
  }

  public set weaveStructureSettings(value: RouteLeaf) {
    this.replace(this._weaveStructureSettings, value);
    this._weaveStructureSettings = value;
  }

  public get manageAddWeaveStructure(): RouteLeaf {
    return this._manageAddWeaveStructure;
  }

  public set manageAddWeaveStructure(value: RouteLeaf) {
    this.replace(this._manageAddWeaveStructure, value);
    this._manageAddWeaveStructure = value;
  }

  public get manageOverviewWeaveStructure(): RouteLeaf {
    return this._manageOverviewWeaveStructure;
  }

  public set manageOverviewWeaveStructure(value: RouteLeaf) {
    this.replace(this._manageOverviewWeaveStructure, value);
    this._manageOverviewWeaveStructure = value;
  }
}
