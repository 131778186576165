import {map, reject} from 'lodash-es';

export enum LabelPositionInLabelPlace {
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_MIDDLE = 'BOTTOM_MIDDLE',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER = 'CENTER',
  CENTER_RIGHT = 'CENTER_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP_MIDDLE = 'TOP_MIDDLE',
  TOP_RIGHT = 'TOP_RIGHT'
}

export namespace LabelPositionInLabelPlace {
  export function getKeysOfLabelPosition(): string[] {
    return map(
      reject(Object.keys(LabelPositionInLabelPlace), (key: string) => {
        return typeof LabelPositionInLabelPlace[key] !== 'string';
      }),
      (key: string) => {
        return LabelPositionInLabelPlace[key].toUpperCase();
      }
    );
  }
}
