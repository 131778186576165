export class MachineSummaryJacquard {
  private _id: number;
  private _amountOfPossiblePaths: number;
  private _isSisal: boolean;
  private _allowEmptySpaceInPath: boolean;

  public constructor(id: number, amountOfPossiblePaths: number, isSisal: boolean, allowEmptySpaceInPath: boolean) {
    this._id = id;
    this._amountOfPossiblePaths = amountOfPossiblePaths;
    this._isSisal = isSisal;
    this._allowEmptySpaceInPath = allowEmptySpaceInPath;
  }

  public get id(): number {
    return this._id;
  }

  public get amountOfPossiblePaths(): number {
    return this._amountOfPossiblePaths;
  }

  public get isSisal(): boolean {
    return this._isSisal;
  }

  public set isSisal(value: boolean) {
    this._isSisal = value;
  }

  public get allowEmptySpaceInPath(): boolean {
    return this._allowEmptySpaceInPath;
  }

  public static fromJSON(machineSummaryJacquard: any): MachineSummaryJacquard {
    return new MachineSummaryJacquard(machineSummaryJacquard.id, machineSummaryJacquard.amountOfPossiblePaths, machineSummaryJacquard.isSisal, machineSummaryJacquard.allowEmptySpaceInPath);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      amountOfPossiblePaths: this._amountOfPossiblePaths,
      isSisal: this._isSisal,
      allowEmptySpaceInPath: this._allowEmptySpaceInPath
    } as any as JSON;
  }
}
