import {FinishingTemplateLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-label';
import {FinishingPositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-position-in-carpet';
import {LabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-place.enum';
import {isEqual} from 'lodash-es';
import {FinishingLabelType} from './finishing-label-type.enum';

export abstract class FinishingLabel extends FinishingTemplateLabel {
  private _finishingLabelType: FinishingLabelType;

  protected constructor(
    id: number,
    place: LabelPlace,
    rotation: number,
    mirrored: boolean,
    positionInTopCarpet: FinishingPositionInCarpet,
    positionInBottomCarpet: FinishingPositionInCarpet,
    finishingLabelType: FinishingLabelType
  ) {
    super(id, place, rotation, mirrored, positionInTopCarpet, positionInBottomCarpet);

    this._finishingLabelType = finishingLabelType;
  }

  public get finishingLabelType(): FinishingLabelType {
    return this._finishingLabelType;
  }

  public isTopCarpetSameAsBottomCarpet(): boolean {
    return (
      isEqual(this.positionInTopCarpet.commercialHorizontalOffsetInMM, this.positionInBottomCarpet.commercialHorizontalOffsetInMM) &&
      isEqual((this.positionInTopCarpet as FinishingPositionInCarpet).technicalHorizontalOffsetInDents, (this.positionInBottomCarpet as FinishingPositionInCarpet).technicalHorizontalOffsetInDents) &&
      isEqual(this.positionInTopCarpet.commercialVerticalOffsetInMM, this.positionInBottomCarpet.commercialVerticalOffsetInMM) &&
      isEqual((this.positionInTopCarpet as FinishingPositionInCarpet).technicalVerticalOffsetInPicks, (this.positionInBottomCarpet as FinishingPositionInCarpet).technicalVerticalOffsetInPicks) &&
      isEqual(this.positionInTopCarpet.positioningInCarpet, this.positionInBottomCarpet.positioningInCarpet)
    );
  }
}
