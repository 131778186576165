import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Duration, duration, Moment} from 'moment';
import {DateRange} from 'moment-range';
import {BehaviorSubject, interval, map, Observable, startWith, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {Point} from '../../../common/interfaces/point';
import {moment} from '../../../common/moment';
import {PlanningRowComponent} from '../../planning-row/planning-row.component';
import {ShiftScheduleComponent} from '../../shift-schedule/shift-schedule.component';
import {TimeViewMode} from '../../time-view-mode.enum';
import {DayCellData} from './day-cell-data';
import {PLANNING_GRID_TOKEN} from './planning-grid.token';

@Component({
  selector: 'vdw-planning-grid',
  templateUrl: './planning-grid.component.html',
  styleUrls: ['./planning-grid.component.scss'],
  providers: [
    {
      provide: PLANNING_GRID_TOKEN,
      useExisting: PlanningGridComponent
    }
  ]
})
export class PlanningGridComponent extends BaseComponent implements AfterContentInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() public minCellWidth = 71;
  @Input() public rowTitle: string;

  @Output() public selectedHourChanged = new EventEmitter<DateRange>();
  @Output() public gridSelected = new EventEmitter<{event: PointerEvent; rowIndex: number; date: Date}>();

  @Output() public scrolledToTop = new EventEmitter();
  @Output() public scrolledToBottom = new EventEmitter();
  @Output() public scrolled = new EventEmitter<number>();

  @ViewChild('dragContainer', {read: ViewContainerRef})
  public dragContainer: ViewContainerRef;

  @ViewChild('bodyContainer')
  public bodyContainer: ElementRef<HTMLDivElement>;

  @ViewChild('scrollContainer')
  public scrollContainer: ElementRef<HTMLDivElement>;

  @ContentChildren(PlanningRowComponent)
  public _rowIndicators: QueryList<PlanningRowComponent>;

  public rowIndicators: PlanningRowComponent[] = [];

  @ContentChildren(ShiftScheduleComponent)
  private _shiftSchedules: QueryList<ShiftScheduleComponent>;

  public shiftSchedules: Observable<ShiftScheduleComponent[]>;

  public readonly ROW_INDICATOR_WIDTH = 184;
  public readonly COLUMN_HEADER_HEIGHT = 96;
  public readonly MILLISECONDS_IN_MINUTE = 60000;

  private readonly MAX_CELLS_PER_DAY = 8;
  private readonly ROW_HEIGHT = 91;
  private readonly DEFAULT_CELLS_PER_DAY = 4;
  private readonly MAX_CELLS_PER_DAY_FOR_HOUR_VIEW = 12;

  public totalTime: Duration;
  public timeView = TimeViewMode.DAY;
  public bodyContainerOffset: Point;
  public dayCellData: DayCellData[] = [];
  public onChanges = new BehaviorSubject<SimpleChanges>(undefined);
  public get onDestroy(): Observable<boolean> {
    return this.unSubscribeOnViewDestroy;
  }

  private _cachedScrollTop: number;

  private _rowWidth = 0;
  private _currentTime: Date;
  private _endDate = new Date('2022-10-27');
  private _startDate = new Date('2022-10-20');
  private _cellsPerDay = this.DEFAULT_CELLS_PER_DAY;
  private _cellsPerHour = this.MAX_CELLS_PER_DAY_FOR_HOUR_VIEW;
  private _resizeObserver: ResizeObserver;

  @Input()
  public set cellsPerDay(newCellsPerDay: number) {
    this._cellsPerDay = Math.min(Math.max(newCellsPerDay, 1), this.MAX_CELLS_PER_DAY);

    if (this.timeView === TimeViewMode.DAY) {
      this.updatePlanningVariables();
    }
  }

  @Input()
  public set cellsPerHour(newCellsPerHour: number) {
    this._cellsPerHour = Math.min(Math.max(newCellsPerHour, 1), this.MAX_CELLS_PER_DAY_FOR_HOUR_VIEW);

    if (this.timeView === TimeViewMode.HOUR) {
      this.updatePlanningVariables();
    }
  }

  @Input()
  public set timeViewMode(newTimeViewMode: TimeViewMode) {
    if (this.timeView === newTimeViewMode) {
      return;
    }

    this.timeView = newTimeViewMode;
    if (this.timeView === TimeViewMode.HOUR) {
      this.endDate = moment(this._startDate).startOf('h').add(1, 'h').toDate();
    } else if (this.timeView === TimeViewMode.DAY) {
      this.startDate = this._startDate;
      this.endDate = this._startDate;
    }
  }

  @Input()
  public set startDate(newStartDate: Date) {
    this._startDate = this.timeView === TimeViewMode.DAY ? moment(newStartDate).startOf('d').toDate() : moment(newStartDate).startOf('h').toDate();
    this.updatePlanningVariables();
  }

  public get startDate(): Date {
    return this._startDate;
  }

  @Input()
  public set endDate(newEndDate: Date) {
    this._endDate = this.timeView === TimeViewMode.DAY ? moment(newEndDate).startOf('d').add(1, 'd').toDate() : moment(newEndDate).startOf('h').toDate();
    this.updatePlanningVariables();
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public get rowWidth(): number {
    return this._rowWidth;
  }

  public get currentTime(): Date {
    return this._currentTime;
  }

  public ngAfterContentInit(): void {
    this._rowIndicators.changes
      .pipe(
        startWith(undefined),
        map(() => this._rowIndicators.toArray()),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((rowIndicators: PlanningRowComponent[]) => (this.rowIndicators = rowIndicators));

    this.shiftSchedules = this._shiftSchedules.changes.pipe(
      startWith(undefined),
      map(() => this._shiftSchedules.toArray())
    );

    this._currentTime = new Date();

    interval(this.MILLISECONDS_IN_MINUTE)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => (this._currentTime = new Date()));
  }

  public ngAfterViewInit(): void {
    this._resizeObserver = new ResizeObserver(() => {
      this.calculateRowWidth();
      this.calculateBodyContainerOffset();
    });
    this._resizeObserver.observe(this.bodyContainer.nativeElement.parentElement);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._resizeObserver?.disconnect();
  }

  public scrolling(event: Event): void {
    const nativeElement = this.scrollContainer.nativeElement;

    this._cachedScrollTop = nativeElement.scrollTop;

    this.scrolled.emit(Math.floor(nativeElement.scrollTop / this.ROW_HEIGHT));

    if (nativeElement.scrollTop === 0) {
      this.scrolledToTop.emit();
    }

    if (nativeElement.scrollTop > nativeElement.scrollHeight - nativeElement.offsetHeight) {
      this.scrolledToBottom.emit();
    }

    event.stopPropagation();
  }

  public resetScrollPosition(): void {
    this.scrollContainer.nativeElement.scrollTop = 0;
  }

  public onMouseWheelInput(event: WheelEvent): void {
    if (event.deltaY < 0 && this._cachedScrollTop === 0) {
      this.scrolledToTop.emit();
    }

    event.stopPropagation();
  }

  public getLeftPercentForDate(start: Date): number {
    return (duration(moment(start).diff(this.startDate)).asMinutes() / this.totalTime.asMinutes()) * 100;
  }

  public getDateForHorizontalPosition(positionX: number): Date {
    const minutesForPosition = (positionX / this._rowWidth) * this.totalTime.asMinutes();
    return moment(this._startDate).add(minutesForPosition, 'm').toDate();
  }

  public calculateRowWidth(): void {
    if (this.bodyContainer?.nativeElement) {
      this._rowWidth = this.bodyContainer.nativeElement.clientWidth - this.ROW_INDICATOR_WIDTH;
    }
  }

  public calculateBodyContainerOffset(): void {
    this.bodyContainerOffset = this.bodyContainer.nativeElement.parentElement.getBoundingClientRect();
  }

  public setTimeToHour(hour: Date): void {
    this._startDate = new Date(hour);
    this._endDate = moment(hour).add(1, 'h').toDate();

    this.updatePlanningVariables();
  }

  public onGridSelected(rowIndex: number, event: PointerEvent): void {
    this.calculateRowWidth();
    this.gridSelected.emit({event, rowIndex, date: this.getDateForHorizontalPosition(event.offsetX)});
  }

  public onSelectedHourChanged(selectedHour: DateRange): void {
    this.selectedHourChanged.emit(selectedHour);
  }

  private updatePlanningVariables(): void {
    this.generateCells();
    this.totalTime = duration(moment(this.endDate).diff(this.startDate));
    this.ngOnChanges({gridChange: new SimpleChange('', `start: ${this._startDate.toString()}, end: ${this._endDate.toString()}`, false)});
  }

  private generateCells(): void {
    this.dayCellData = [];
    const end: Moment = moment(this._endDate);
    const currentDate: Moment = moment(this._startDate);
    const normalCellDuration = this.timeView === TimeViewMode.DAY ? 24 / this._cellsPerDay : 60 / this._cellsPerHour;
    const timeInterval = this.timeView === TimeViewMode.DAY ? 'hours' : 'minutes';

    const currentCells = this.timeView === TimeViewMode.DAY ? this._cellsPerDay : this._cellsPerHour;

    while (currentDate < end) {
      const dayCell: DayCellData = {date: currentDate.toDate(), duration: duration(currentDate.clone().add(1, 'd').diff(currentDate)).asHours(), items: []};
      this.dayCellData.push(dayCell);

      for (let cellIndex = 1; cellIndex <= currentCells; cellIndex++) {
        const dateForCell = currentDate.toDate();

        currentDate.set(timeInterval, cellIndex * normalCellDuration);
        dayCell.items.push({date: dateForCell, duration: duration(currentDate.diff(dateForCell)).asMinutes()});
      }
    }
  }
}
