import {RouteNode} from '../routing/route-node';
import {MobileNavigationBarItem, NavigationBarMobileItemProperties} from './mobile-navigation-bar-item';

export interface MobileNavigationBarRouteLinkItemProperties extends Omit<NavigationBarMobileItemProperties, 'requiredPermission' | 'mobileFriendly'> {
  route: RouteNode;
  canSearch?: boolean;
}

export class MobileNavigationBarRouteLinkItem extends MobileNavigationBarItem {
  private _route: RouteNode;
  private _canSearch: boolean;

  public constructor(properties: MobileNavigationBarRouteLinkItemProperties) {
    const {route, canSearch, ...navigationBarItemProperties} = properties;

    super({...navigationBarItemProperties, requiredPermission: route.requiredPermission});

    this._route = route;
    this._canSearch = canSearch ?? false;
  }

  public get path(): string {
    return this._route.absolutePath;
  }

  public get route(): RouteNode {
    return this._route;
  }

  public get canSearch(): boolean {
    return this._canSearch;
  }
}
