import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DeviceTemplateCatalogRoute extends RouteBranch {
  private _addDeviceTemplateCatalog: RouteLeaf;
  private _editDeviceTemplateCatalog: RouteLeaf;
  private _duplicateDeviceTemplateCatalog: RouteLeaf;
  private _addCounterCatalog: RouteLeaf;
  private _editCounterCatalog: RouteLeaf;
  private _duplicateCounterCatalog: RouteLeaf;
  private _addConfigurationCatalog: RouteLeaf;
  private _editConfigurationCatalog: RouteLeaf;
  private _duplicateConfigurationCatalog: RouteLeaf;

  public get addDeviceTemplateCatalog(): RouteLeaf {
    return this._addDeviceTemplateCatalog;
  }

  public set addDeviceTemplateCatalog(value: RouteLeaf) {
    this.replace(this._addDeviceTemplateCatalog, value);
    this._addDeviceTemplateCatalog = value;
  }

  public get editDeviceTemplateCatalog(): RouteLeaf {
    return this._editDeviceTemplateCatalog;
  }

  public set editDeviceTemplateCatalog(value: RouteLeaf) {
    this.replace(this._editDeviceTemplateCatalog, value);
    this._editDeviceTemplateCatalog = value;
  }

  public get duplicateDeviceTemplateCatalog(): RouteLeaf {
    return this._duplicateDeviceTemplateCatalog;
  }

  public set duplicateDeviceTemplateCatalog(value: RouteLeaf) {
    this.replace(this._duplicateDeviceTemplateCatalog, value);
    this._duplicateDeviceTemplateCatalog = value;
  }

  public get addCounterCatalog(): RouteLeaf {
    return this._addCounterCatalog;
  }

  public set addCounterCatalog(value: RouteLeaf) {
    this.replace(this._addCounterCatalog, value);
    this._addCounterCatalog = value;
  }

  public get editCounterCatalog(): RouteLeaf {
    return this._editCounterCatalog;
  }

  public set editCounterCatalog(value: RouteLeaf) {
    this.replace(this._editCounterCatalog, value);
    this._editCounterCatalog = value;
  }

  public get duplicateCounterCatalog(): RouteLeaf {
    return this._duplicateCounterCatalog;
  }

  public set duplicateCounterCatalog(value: RouteLeaf) {
    this.replace(this._duplicateCounterCatalog, value);
    this._duplicateCounterCatalog = value;
  }

  public get addConfigurationCatalog(): RouteLeaf {
    return this._addConfigurationCatalog;
  }

  public set addConfigurationCatalog(value: RouteLeaf) {
    this.replace(this._addConfigurationCatalog, value);
    this._addConfigurationCatalog = value;
  }

  public get editConfigurationCatalog(): RouteLeaf {
    return this._editConfigurationCatalog;
  }

  public set editConfigurationCatalog(value: RouteLeaf) {
    this.replace(this._editConfigurationCatalog, value);
    this._editConfigurationCatalog = value;
  }

  public get duplicateConfigurationCatalog(): RouteLeaf {
    return this._duplicateConfigurationCatalog;
  }

  public set duplicateConfigurationCatalog(value: RouteLeaf) {
    this.replace(this._duplicateConfigurationCatalog, value);
    this._duplicateConfigurationCatalog = value;
  }
}
