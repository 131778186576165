import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {BaseComponent, TranslateService, WINDOW} from '@vdw/angular-component-library';
import {isEmpty, isEqual, isNil, isString, keys, map, max, size} from 'lodash-es';
import {fromEvent} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {OnboardingStep} from './onboarding-step.enum';
import {OnboardingType} from './onboarding-type';

@Component({
  selector: 'app-onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.scss']
})
export class OnboardingDialogComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('dialogHeader') public dialogHeader: ElementRef;
  public onboardingStepFeatures: string[] = [];
  public arrowLeftPositionInPx: number;
  public arrowTopPositionInPx: number;
  public canShowBlueArrow = false;

  private readonly translate: TranslateService;
  private readonly matDialogRef: MatDialogRef<OnboardingDialogComponent>;
  private readonly elementRef: ElementRef;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly onboardingTranslationKey = 'ONBOARDING';
  private readonly arrowPadding = 5;
  private readonly arrowSize = 24;
  private readonly dialogMargin = 16;
  private readonly position: PositionOfDialog;
  private readonly genericTitleKey = `${this.onboardingTranslationKey}.TITLE`;

  private onboardingType: OnboardingType;
  private currentOnboardingStep: OnboardingStep;
  private currentStepIndex = 0;
  private onboardingSteps: OnboardingStep[] = [];
  private onNextStep: (updateDialog: (sourceElement: HTMLElement, leftPositionInPx?: number) => void, currentOnboardingStep: OnboardingStep) => void;
  private sourceElement: HTMLElement;
  private initialLeftPositionInPx: number;
  private navigatingToNextStep = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(WINDOW) private readonly window: Window,
    translate: TranslateService,
    matDialogRef: MatDialogRef<OnboardingDialogComponent>,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.translate = translate;
    this.matDialogRef = matDialogRef;
    this.elementRef = elementRef;
    this.changeDetectorRef = changeDetectorRef;

    this.onboardingType = data.type;
    this.onboardingSteps = data.steps;
    this.currentOnboardingStep = data.steps[this.currentStepIndex];
    this.position = data.position;
    this.onNextStep = data.onNextStep;
    this.setSourceElementAndInitialLeftPositionInPx(data.sourceElement, data.leftPositionInPx);
    this.setOnboardingStepFeatures();
  }

  public ngAfterViewInit(): void {
    fromEvent(this.window, 'resize')
      .pipe(debounceTime(250), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.updateDialog(this.sourceElement, this.initialLeftPositionInPx);
      });

    this.updateDialog(this.sourceElement, this.initialLeftPositionInPx);
    this.changeDetectorRef.detectChanges();
  }

  public canShowFeatures(): boolean {
    return !isEmpty(this.onboardingStepFeatures);
  }

  public finishTour(): void {
    if (!this.navigatingToNextStep) {
      this.matDialogRef.close(true);
    }
  }

  public canGoToNextStep(): boolean {
    return this.currentStepIndex + 1 < size(this.onboardingSteps);
  }

  public getTitleKey(): string {
    const onboardingTypeTitleKey = `${this.onboardingTranslationKey}.${this.onboardingType}.TITLE`;

    return this.translate.has(onboardingTypeTitleKey) ? onboardingTypeTitleKey : this.genericTitleKey;
  }

  public getStepTitleKey(): string {
    return `${this.onboardingTranslationKey}.${this.onboardingType}.${this.currentOnboardingStep}.TITLE`;
  }

  public getStepDescriptionKey(): string {
    return `${this.onboardingTranslationKey}.${this.onboardingType}.${this.currentOnboardingStep}.DESCRIPTION`;
  }

  public hasOnboardingSteps(): boolean {
    return !isEmpty(this.onboardingSteps);
  }

  public isArrowPositionedAtTop(): boolean {
    return isEqual(this.position, PositionOfDialog.BOTTOM);
  }

  public getChooseEventName(): string {
    return this.canGoToNextStep() ? 'ONBOARDING.NEXT' : 'ONBOARDING.FINISH_TOUR';
  }

  public chooseEvent(): void {
    this.canGoToNextStep() ? this.goToNextStep() : this.finishTour();
  }

  private goToNextStep(): void {
    this.currentStepIndex++;
    this.currentOnboardingStep = this.onboardingSteps[this.currentStepIndex];
    this.navigatingToNextStep = true;

    this.setOnboardingStepFeatures();

    setTimeout(() => {
      this.onNextStep((sourceElement: HTMLElement, leftPositionInPx?: number): void => {
        this.updateDialog(sourceElement, leftPositionInPx);
      }, this.currentOnboardingStep);
      this.navigatingToNextStep = false;
    });
  }

  private setOnboardingStepFeatures(): void {
    const onboardingStepFeatures = this.translate.instant(`${this.onboardingTranslationKey}.${this.onboardingType}.${this.currentOnboardingStep}.FEATURES`);

    this.onboardingStepFeatures = !isString(onboardingStepFeatures)
      ? map(keys(onboardingStepFeatures), (onboardingStepFeatureKey: string) => {
          return `${this.onboardingTranslationKey}.${this.onboardingType}.${this.currentOnboardingStep}.FEATURES.${onboardingStepFeatureKey}`;
        })
      : [];
  }

  private updateDialog(sourceElement: HTMLElement, leftPositionInPx?: number): void {
    this.setSourceElementAndInitialLeftPositionInPx(sourceElement, leftPositionInPx);
    sourceElement?.scrollIntoView(false);
    this.repositionDialog(sourceElement);

    this.canShowBlueArrow = (this.dialogHeader.nativeElement as HTMLElement).getBoundingClientRect().bottom >= this.arrowTopPositionInPx + this.arrowSize;
  }

  private repositionDialog(sourceElement: HTMLElement): void {
    if (!sourceElement) {
      return;
    }

    const sourceElementDOMRect: DOMRect = sourceElement.getBoundingClientRect();
    const dialogDOMRect: DOMRect = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect();

    const {arrowLeftPositionInPx, dialogLeftPositionInPx} = this.calculateArrowAndDialogLeftPositionInPx(sourceElementDOMRect, dialogDOMRect);

    const {arrowTopPositionInPx, dialogTopPositionInPx} = this.calculateArrowAndDialogTopPositionInPx(sourceElementDOMRect, dialogDOMRect);

    this.arrowLeftPositionInPx = arrowLeftPositionInPx;
    this.arrowTopPositionInPx = arrowTopPositionInPx;

    this.matDialogRef.updatePosition({left: `${dialogLeftPositionInPx}px`, top: `${dialogTopPositionInPx}px`});
  }

  private setSourceElementAndInitialLeftPositionInPx(sourceElement: HTMLElement, leftPositionInPx?: number): void {
    this.sourceElement = sourceElement;
    this.initialLeftPositionInPx = isNil(leftPositionInPx) ? sourceElement?.getBoundingClientRect().right : leftPositionInPx;
  }

  private calculateArrowAndDialogLeftPositionInPx(sourceElementDOMRect: DOMRect, dialogDOMRect: DOMRect): {arrowLeftPositionInPx: number; dialogLeftPositionInPx: number} {
    let arrowLeftPositionInPx: number;
    let dialogLeftPositionInPx: number;

    if (isEqual(this.position, PositionOfDialog.RIGHT)) {
      arrowLeftPositionInPx = this.initialLeftPositionInPx;
      dialogLeftPositionInPx = arrowLeftPositionInPx + this.arrowSize - this.arrowPadding;
    } else {
      arrowLeftPositionInPx = sourceElementDOMRect.left + sourceElementDOMRect.width / 2 - (sourceElementDOMRect.width - this.arrowSize) / 2;
      dialogLeftPositionInPx = sourceElementDOMRect.left;

      if (dialogDOMRect.width + dialogLeftPositionInPx > this.window.innerWidth + this.dialogMargin) {
        dialogLeftPositionInPx = max([this.dialogMargin, this.window.innerWidth - dialogDOMRect.width - this.dialogMargin]);
      }
    }

    return {arrowLeftPositionInPx, dialogLeftPositionInPx};
  }

  private calculateArrowAndDialogTopPositionInPx(sourceElementDOMRect: DOMRect, dialogDOMRect: DOMRect): {arrowTopPositionInPx: number; dialogTopPositionInPx: number} {
    let arrowTopPositionInPx: number;
    let dialogTopPositionInPx: number;

    if (isEqual(this.position, PositionOfDialog.RIGHT)) {
      arrowTopPositionInPx = sourceElementDOMRect.top + sourceElementDOMRect.height / 2 - this.arrowSize / 2;
      dialogTopPositionInPx = arrowTopPositionInPx - (this.dialogHeader.nativeElement.offsetHeight - this.arrowSize) / 2;

      if (dialogDOMRect.height + dialogTopPositionInPx > this.window.innerHeight - this.dialogMargin) {
        dialogTopPositionInPx = max([this.dialogMargin, this.window.innerHeight - dialogDOMRect.height - this.dialogMargin]);
      }
    } else {
      arrowTopPositionInPx = sourceElementDOMRect.bottom - this.arrowPadding;
      dialogTopPositionInPx = arrowTopPositionInPx + this.arrowSize - this.arrowPadding;
    }

    return {arrowTopPositionInPx, dialogTopPositionInPx};
  }
}
