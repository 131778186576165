import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestManagerService {
  private cancelPendingRequestsSubject: Subject<void> = new Subject<void>();

  public cancelPendingRequests(): void {
    this.cancelPendingRequestsSubject.next();
  }

  public onCancelPendingRequests(): Observable<void> {
    return this.cancelPendingRequestsSubject.asObservable();
  }
}
