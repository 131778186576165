import {Customer} from '@domain/customer/customer';

export class CustomerForSalesOrder {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(customerJSON: any): CustomerForSalesOrder {
    return new CustomerForSalesOrder(customerJSON.id, customerJSON.name);
  }

  public static fromCustomer(customer: Customer): CustomerForSalesOrder {
    return new CustomerForSalesOrder(customer.id, customer.name);
  }
}
