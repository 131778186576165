import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {PlanningOrderData} from '../planning-order-data';
import {ProductionOrderYield} from '../production-order-yield';
import {PlanningTuftProduct} from './planning-tuft-product';

export class ProductionOrderTuftingForPlanningOrder extends PlanningOrderData {
  private _tuftProduct: PlanningTuftProduct;

  public constructor(id: number, name: string, status: ProductionScheduleStatus, tuftProduct: PlanningTuftProduct, orderYield: ProductionOrderYield, dueDate: Date) {
    super(id, name, dueDate, status);
    this._tuftProduct = tuftProduct;
    this.yield = orderYield;
  }

  public get tuftProduct(): PlanningTuftProduct {
    return this._tuftProduct;
  }

  public static fromNeedsAttentionItemJSON(jsonData: any): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(
      jsonData.productionOrderLiteId,
      jsonData.name,
      jsonData.status,
      PlanningTuftProduct.fromJSON(jsonData.tuftProduct),
      jsonData.yield,
      AssertionUtils.isNullOrUndefined(jsonData.dueDate) ? null : new Date(jsonData.dueDate)
    );
  }

  public static fromJSON(jsonData: any): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(
      jsonData.id,
      jsonData.name,
      jsonData.status,
      AssertionUtils.isNullOrUndefined(jsonData.tuftProduct) ? null : PlanningTuftProduct.fromJSON(jsonData.tuftProduct),
      {current: null, planned: null},
      AssertionUtils.isNullOrUndefined(jsonData.dueDate) ? null : new Date(jsonData.dueDate)
    );
  }

  public toJSON(): JSON {
    return {
      plannedAmountInMeters: this.yield.planned,
      id: this.id,
      name: this.name,
      dueDate: this.dueDate,
      status: this.status
    } as any as JSON;
  }

  public canPrintWeavingOrder(): boolean {
    return false;
  }

  public canBeQueued(): boolean {
    return false;
  }

  public canBeProcessed(): boolean {
    return false;
  }
}
