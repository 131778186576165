import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {ReportItemField} from '@domain/planning-prototype/real-time/report-item-field.enum';
import {ReportItemGroupType} from '@domain/planning-prototype/real-time/report-item-group-type.enum';
import {ReportItemSubscription} from '@domain/planning-prototype/real-time/report-item-subscription';
import {ReportItemUpdate} from '@domain/planning-prototype/real-time/report-item-update';
import {HubConnection} from '@microsoft/signalr';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {EMPTY, Observable, Subscriber, catchError, filter, switchMap} from 'rxjs';

@Injectable()
export class SignalRPlanningPrototypeService {
  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public itemsToPlanChanged(): Observable<void> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<void>(`${this.backendConfiguration.getPlanningHubEndPoint()}planning`, 'OnItemsToPlanChanged');
  }

  public getReportItemUpdates<T = unknown>(machineId: number, reportItem: ReportItemField): Observable<ReportItemUpdate<T>> {
    return this.signalrHubConnectionFactoryService.getSignalrHubConnection(`${this.backendConfiguration.getPlanningHubEndPoint()}planning`).pipe(
      switchMap(
        (connection: HubConnection) =>
          new Observable((subscriber: Subscriber<ReportItemUpdate<T>>) => {
            const callBack = (update: ReportItemUpdate<T>): void => subscriber.next(update);
            const subscription: ReportItemSubscription = {groupId: machineId, reportItem, groupType: ReportItemGroupType.MACHINE};
            connection.on('OnReportItemChanged', callBack);
            connection.invoke('SubscribeToReportItemAsync', subscription);
            return () => {
              connection.invoke('UnsubscribeFromReportItemAsync', subscription);
              connection.off('OnReportItemChanged', callBack);
            };
          })
      ),
      filter((update: ReportItemUpdate): update is ReportItemUpdate<T> => update.groupId === machineId && update.reportItem === reportItem && update.groupType === ReportItemGroupType.MACHINE),
      catchError(() => EMPTY)
    );
  }
}
