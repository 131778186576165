import {CustomerForSalesOrder} from '@domain/sales-order/customer-for-sales-order';
import {OrderLineForSalesOrder} from '@domain/sales-order/order-line-for-sales-order';
import {PaymentStatus} from '@domain/sales-order/payment-status';
import {EnumUtils, Priority} from '@vdw/angular-component-library';
import {map, sum} from 'lodash-es';
import {SalesOrderStatus} from './sales-order-status';

export class SalesOrder {
  private _id: number;
  private _orderNumber: number;
  private _customer: CustomerForSalesOrder;
  private _desiredDate: Date;
  private _priority: Priority;
  private _paymentStatus: PaymentStatus;
  private _orderLines: OrderLineForSalesOrder[];
  private _status: SalesOrderStatus;
  private _used: boolean;

  public constructor(
    id: number,
    orderNumber: number,
    customer: CustomerForSalesOrder,
    desiredDate: Date,
    priority: Priority,
    paymentStatus: PaymentStatus,
    orderLines: OrderLineForSalesOrder[],
    status: SalesOrderStatus,
    used?: boolean
  ) {
    this._id = id;
    this._orderNumber = orderNumber;
    this._customer = customer;
    this._desiredDate = desiredDate;
    this._priority = priority;
    this._paymentStatus = paymentStatus;
    this._orderLines = orderLines;
    this._status = status;
    this._used = used;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get orderNumber(): number {
    return this._orderNumber;
  }

  public set orderNumber(value: number) {
    this._orderNumber = value;
  }

  public get customer(): CustomerForSalesOrder {
    return this._customer;
  }

  public set customer(value: CustomerForSalesOrder) {
    this._customer = value;
  }

  public get desiredDate(): Date {
    return this._desiredDate;
  }

  public set desiredDate(value: Date) {
    this._desiredDate = value;
  }

  public get priority(): Priority {
    return this._priority;
  }

  public set priority(value: Priority) {
    this._priority = value;
  }

  public get paymentStatus(): PaymentStatus {
    return this._paymentStatus;
  }

  public set paymentStatus(value: PaymentStatus) {
    this._paymentStatus = value;
  }

  public get orderLines(): OrderLineForSalesOrder[] {
    return this._orderLines;
  }

  public set orderLines(value: OrderLineForSalesOrder[]) {
    this._orderLines = value;
  }

  public get status(): SalesOrderStatus {
    return this._status;
  }

  public set status(value: SalesOrderStatus) {
    this._status = value;
  }

  public get used(): boolean {
    return this._used;
  }

  public set used(value: boolean) {
    this._used = value;
  }

  public static createEmptySalesOrder(): SalesOrder {
    return new SalesOrder(null, null, null, null, null, null, [], null);
  }

  public static fromJSON(salesOrderJSON: any): SalesOrder {
    return new SalesOrder(
      salesOrderJSON.id,
      salesOrderJSON.orderNumber,
      CustomerForSalesOrder.fromJSON(salesOrderJSON.customer),
      new Date(salesOrderJSON.desiredDate),
      salesOrderJSON.priority,
      salesOrderJSON.paymentStatus,
      map(salesOrderJSON.orderLines, (orderLineJSON: any) => OrderLineForSalesOrder.fromJSON(orderLineJSON)),
      salesOrderJSON.status,
      salesOrderJSON.used
    );
  }

  public getTotalAmountRequested(): number {
    return sum(map(this._orderLines, (orderLine: OrderLineForSalesOrder) => orderLine.amount));
  }

  public getTotalAmountProduced(): number {
    return sum(map(this._orderLines, (orderLine: OrderLineForSalesOrder) => orderLine.amountProduced));
  }

  public toJSON(): JSON {
    return {
      orderNumber: this._orderNumber,
      customerId: this._customer.id,
      desiredDate: this._desiredDate.toISOString(),
      priority: EnumUtils.getKeyFromValue(Priority, this._priority),
      paymentStatus: EnumUtils.getKeyFromValue(PaymentStatus, this._paymentStatus),
      orderLines: map(this._orderLines, (orderLine: OrderLineForSalesOrder) => orderLine.toJSON())
    } as any as JSON;
  }
}
