export enum ProductionSchedulePhase {
  PRESELECTION = 'PRESELECTION',
  CONFIGURE = 'CONFIGURE',
  BUILD = 'BUILD',
  VERIFY = 'VERIFY',
  COMPLETE = 'COMPLETE'
}

export namespace ProductionSchedulePhase {
  const phasesWithIcon = new Map();
  phasesWithIcon.set(ProductionSchedulePhase.PRESELECTION, 'library-blue');
  phasesWithIcon.set(ProductionSchedulePhase.CONFIGURE, 'add-blue');
  phasesWithIcon.set(ProductionSchedulePhase.BUILD, 'builder-blue');
  phasesWithIcon.set(ProductionSchedulePhase.VERIFY, 'verify-blue');

  export function getIcon(productionSchedulePhase: ProductionSchedulePhase): string {
    return phasesWithIcon.get(productionSchedulePhase);
  }
}
