import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {LabelModule} from '../ellipsis-label/label.module';
import {InputChipComponent} from './input-chip.component';

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, LabelModule, MatButtonModule],
  exports: [InputChipComponent],
  declarations: [InputChipComponent]
})
export class InputChipModule {}
