import {SortModelItem} from 'ag-grid-community';

export class GridModel {
  private _startRow: number;
  private _endRow: number;
  private _sortModel: SortModelItem[];
  private _filterModel: any;

  public constructor(startRow: number, endRow: number, sortModel: SortModelItem[], filterModel: any) {
    this._startRow = startRow;
    this._endRow = endRow;
    this._sortModel = sortModel ?? [];
    this._filterModel = filterModel;
  }

  public get startRow(): number {
    return this._startRow;
  }

  public set startRow(startRow: number) {
    this._startRow = startRow;
  }

  public get endRow(): number {
    return this._endRow;
  }

  public set endRow(endRow: number) {
    this._endRow = endRow;
  }

  public get sortModel(): SortModelItem[] {
    return this._sortModel;
  }

  public set sortModel(sortModel: SortModelItem[]) {
    this._sortModel = sortModel;
  }

  public get filterModel(): any {
    return this._filterModel;
  }

  public set filterModel(filterModel: any) {
    this._filterModel = filterModel;
  }

  public toJSON(): JSON {
    return {
      startRow: this._startRow,
      endRow: this._endRow,
      sortModel: this._sortModel,
      filterModel: this._filterModel
    } as any as JSON;
  }
}
