import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {CdkDragDropHelperService} from './cdk-drag-drop-helper-service/cdk-drag-drop-helper.service';
import {CdkDragPreviewContentDirective} from './cdk-drag-preview-content.directive';
import {CdkDraggableDropListComponent} from './cdk-draggable-drop-list/cdk-draggable-drop-list.component';
import {CdkDropListContentDirective} from './cdk-drop-list-content.directive';

@NgModule({
  imports: [CommonModule, DragDropModule, MatExpansionModule],
  providers: [CdkDragDropHelperService],
  exports: [CdkDraggableDropListComponent, CdkDragPreviewContentDirective, CdkDropListContentDirective],
  declarations: [CdkDraggableDropListComponent, CdkDragPreviewContentDirective, CdkDropListContentDirective]
})
export class CdkDraggableDropListModule {}
