import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {PlanningOrderData} from '../planning-order-data';
import {PlanningCreel} from './planning-creel';
import {PlanningMachineQuality} from './planning-machine-quality';

export class ProductionOrderCarpetForPlanningOrder extends PlanningOrderData {
  private _machineQuality: PlanningMachineQuality;
  private _creel: PlanningCreel;

  private readonly STATUSES_REQUIRED_TO_PRINT_WEAVING_ORDER = [
    ProductionScheduleStatus.PROCESSED,
    ProductionScheduleStatus.FINALIZING,
    ProductionScheduleStatus.FINALIZED,
    ProductionScheduleStatus.TRANSLATING,
    ProductionScheduleStatus.TRANSLATED,
    ProductionScheduleStatus.SENDING,
    ProductionScheduleStatus.ARRIVED,
    ProductionScheduleStatus.EXECUTING,
    ProductionScheduleStatus.EXECUTED,
    ProductionScheduleStatus.EXECUTED_COMPLETE,
    ProductionScheduleStatus.EXECUTED_PARTIAL,
    ProductionScheduleStatus.CANCELLED,
    ProductionScheduleStatus.QUEUING,
    ProductionScheduleStatus.QUEUED
  ];

  public constructor(id: number, name: string, dueDate: Date, status: ProductionScheduleStatus) {
    super(id, name, dueDate, status);
  }

  public static fromJSON(productionOrderForPlanningOrderJSON: any): ProductionOrderCarpetForPlanningOrder {
    return new ProductionOrderCarpetForPlanningOrder(
      productionOrderForPlanningOrderJSON.id,
      productionOrderForPlanningOrderJSON.name,
      AssertionUtils.isNullOrUndefined(productionOrderForPlanningOrderJSON.dueDate) ? null : new Date(productionOrderForPlanningOrderJSON.dueDate),
      ProductionScheduleStatus[productionOrderForPlanningOrderJSON.status]
    );
  }

  public static fromNeedsAttentionItemJSON(needsAttentionJSON: any): ProductionOrderCarpetForPlanningOrder {
    const productionOrder = new ProductionOrderCarpetForPlanningOrder(
      Number(needsAttentionJSON.productionOrderId),
      needsAttentionJSON.name,
      AssertionUtils.isNullOrUndefined(needsAttentionJSON.dueDate) ? null : new Date(needsAttentionJSON.dueDate),
      ProductionScheduleStatus[needsAttentionJSON.status]
    );

    productionOrder.machineQuality = PlanningMachineQuality.fromJSON(needsAttentionJSON.machineQuality);
    productionOrder.creel = PlanningCreel.fromJSON(needsAttentionJSON.creel);
    productionOrder.yield = needsAttentionJSON.yield;

    return productionOrder;
  }

  public get machineQuality(): PlanningMachineQuality {
    return this._machineQuality;
  }

  public set machineQuality(machineQuality: PlanningMachineQuality) {
    this._machineQuality = machineQuality;
  }

  public get creel(): PlanningCreel {
    return this._creel;
  }

  public set creel(creel: PlanningCreel) {
    this._creel = creel;
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      dueDate: this.dueDate,
      totalNrPicks: this.yield.planned
    } as any as JSON;
  }

  public canPrintWeavingOrder(): boolean {
    return this.STATUSES_REQUIRED_TO_PRINT_WEAVING_ORDER.includes(this.status);
  }

  public canBeQueued(): boolean {
    return this.status === ProductionScheduleStatus.PROCESSED || this.status === ProductionScheduleStatus.TRANSLATED;
  }
}
