import {Directive, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {distinctUntilChanged, pairwise, Subject, takeUntil, throttleTime} from 'rxjs';
import {BaseComponent} from '../../base-component';

@Directive({
  selector: '[vdwShowOverlayForVirtualScroller]'
})
export class ShowOverlayForVirtualScrollerDirective extends BaseComponent implements OnInit {
  @Output() public showOverlayChange = new EventEmitter<boolean>();
  public forceShowOverlay = new Subject<void>();

  private overlayChangeSubject = new Subject<boolean>();
  private readonly virtualScrollSubject = new Subject<number>();

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.toggleOverlayBasedOnScrollDirection();

    this.overlayChangeSubject.pipe(distinctUntilChanged(), throttleTime(100), takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: boolean) => this.showOverlayChange.next(value));
    this.subscribeToForceShowOverlay();
  }

  @HostListener('scroll', ['$event'])
  public onScrollEvent(event: Event): void {
    this.virtualScrollSubject.next((event.target as Element).scrollTop);
  }

  private toggleOverlayBasedOnScrollDirection(): void {
    this.virtualScrollSubject.pipe(pairwise(), takeUntil(this.unSubscribeOnViewDestroy)).subscribe(([first, second]: [number, number]) => {
      if (second > first) {
        this.overlayChangeSubject.next(false);
      } else {
        this.overlayChangeSubject.next(true);
      }
    });
  }

  private subscribeToForceShowOverlay(): void {
    this.forceShowOverlay.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.showOverlayChange.emit(true);
    });
  }
}
