import {NameGeneratorPart} from './name-generator-part';
import {nameGeneratorPartFromJSON} from './name-generator-parts/part-from-json';

export class NameGenerator {
  private _id: number;
  private _separatorName: string;
  private _separatorSymbol: string;
  private _parts: NameGeneratorPart[];

  public constructor(id: number, separatorName: string, separatorSymbol: string, parts: NameGeneratorPart[]) {
    this.id = id;
    this.separatorName = separatorName;
    this.separatorSymbol = separatorSymbol;
    this.parts = parts;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get separatorName(): string {
    return this._separatorName;
  }

  public set separatorName(value: string) {
    this._separatorName = value;
  }

  public get separatorSymbol(): string {
    return this._separatorSymbol;
  }

  public set separatorSymbol(value: string) {
    this._separatorSymbol = value;
  }

  public get parts(): NameGeneratorPart[] {
    return this._parts;
  }

  public set parts(value: NameGeneratorPart[]) {
    this._parts = value;
  }

  public toJSON(): JSON {
    return {
      separatorName: this.separatorName,
      parts: this.parts.map((part: NameGeneratorPart) => part.toJSON())
    } as any as JSON;
  }

  public static fromJSON(json: any): NameGenerator {
    return new NameGenerator(
      json.id,
      json.separatorName,
      json.separatorSymbol,
      json.parts.map((jsonPart: any) => nameGeneratorPartFromJSON(jsonPart))
    );
  }
}
