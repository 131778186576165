import {InjectionToken} from '@angular/core';
import {Drawing} from '@domain/production-schedule/drawing';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePhase} from '@domain/production-schedule/production-schedule-phase.enum';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {Zone} from '@presentation/pages/texfab/production-schedule/add/plan/builder/zone';
import {Observable} from 'rxjs';

export const PRODUCTION_SCHEDULE_PLAN = new InjectionToken<ProductionSchedulePlan>('ProductionSchedulePlan');

export interface ProductionSchedulePlan {
  currentUpdatedProductionSchedule: Observable<ProductionSchedule>;
  currentEditingPaths: Observable<boolean>;
  didPatchProductionSchedulePlan: Observable<void>;
  currentSelectedZone: Observable<Zone>;

  applyMeasurementsCalculationsFactor(factorForConversionFromMillimetersToDents: number): void;

  initialiseProductionSchedule(productionSchedule: ProductionSchedule): Observable<void>;

  hasUnsavedChanges(newBuilder: boolean): boolean;

  hasNoPaths(productionSchedule: ProductionSchedule): boolean;

  disableUnsavedChanges(): void;

  discardUnsavedChanges(): void;

  discardTemporarySavedChanges(): void;

  dispatchNewProductionSchedule(productionSchedule: ProductionSchedule): void;

  updateEditingPaths(isEditingPaths: boolean): void;

  setSelectedZone(zone: Zone): void;

  canVerify(): boolean;

  manualSaveProductionSchedule(id?: string, productionSchedulePhase?: ProductionSchedulePhase): void;

  addDrawingToColoredYarnSet(listDrawing: ListDrawing, startDent: number, indexInColoredYarnSet?: number): Observable<ProductionSchedulePath>;

  addDrawingToProductionSchedulePath(
    listDrawing: ListDrawing,
    productionSchedulePathNumber: number,
    productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    indexInProductionSchedulePath?: number
  ): Observable<ProductionSchedulePath>;

  addPathLayoutTemplates(productionSchedule: ProductionSchedule): void;

  removeProductionScheduleItem(productionScheduleItemInPathGroupUuid: string, productionSchedulePathNumber: number, startDentOfColoredYarnSet, newBuilder?: boolean): void;

  removeProductionScheduleItems(productionScheduleItems: ProductionScheduleItemInPathGroup[], onSuccess?: () => void): void;

  removeProductionSchedulePath(productionSchedulePathNumber: number, coloredYarnSetId: number): void;

  editProductionScheduleItem(
    productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup,
    productionScheduleItemInPathGroupIndex: number,
    productionSchedulePathIndex: number,
    startDentOfColoredYarnSet: number
  ): void;

  moveProductionScheduleItemToProductionSchedulePath(
    sourceProductionSchedulePathNumber: number,
    indexInSourceProductionSchedulePath: number,
    sourceProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    destinationProductionSchedulePathNumber: number,
    indexInDestinationProductionSchedulePath: number,
    destinationProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup
  ): void;

  moveProductionScheduleItemToColoredYarnSet(
    sourceProductionSchedulePathNumber: number,
    sourceProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    destinationProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup,
    indexInColoredYarnSet?: number
  ): void;

  moveProductionSchedulePathToColoredYarnSet(
    sourceProductionSchedulePath: ProductionSchedulePath,
    sourceProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    destinationProductionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    indexInProductionSchedulePathsOfColoredYarnSet?: number,
    pathIndex?: number
  ): void;

  updateDrawingsAndOrderLinesOfProductionSchedule(drawings: Drawing[], orderLines: ProductionScheduleOrderLine[], productionSchedule?: ProductionSchedule): void;

  setProductionSchedule(productionSchedule: ProductionSchedule): void;

  cancelOngoingRecoloring(): void;

  lookupCommercialWidthInPathWidths(commercialWidthInMM: number, pathWidths: PathWidth[]): number | null;
}
