<button
  mat-flat-button
  [class.small-items]="buttonSize === 'SMALL'"
  [class.medium-items]="buttonSize === 'MEDIUM'"
  class="{{ class }}"
  [tabIndex]="tabIndex"
  *ngIf="isRaisedButton()"
  color="primary"
  type="button"
  [disabled]="isDisabled()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid,
    'mat-button-with-icon': hasIcon
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>
<button
  mat-stroked-button
  [class.small-items]="buttonSize === 'SMALL'"
  [class.medium-items]="buttonSize === 'MEDIUM'"
  class="{{ class }}"
  [tabIndex]="tabIndex"
  *ngIf="!isRaisedButton()"
  type="button"
  [disabled]="isDisabled()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid,
    'mat-button-with-icon': hasIcon
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>
<ng-template #btnContent>
  <div class="items-before-and-with-label">
    <mat-icon *ngIf="iconName && !inProgress" [svgIcon]="iconName" class="svg-icon"></mat-icon>
    <mat-spinner *ngIf="isInProgress()" [diameter]="getSpinnerDiameter()" mode="indeterminate"></mat-spinner>
    <span class="button-text" [class.hide]="isInProgress() && !canShowBMSTheme">
      {{ label | bmsTranslate: labelReplaceableValues }}
    </span>
  </div>
  <ng-content></ng-content>
</ng-template>
