import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {ARTICLES, Articles} from '@infrastructure/http/article/articles';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';
import {GridColoredYarnSetPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/overview/grid-colored-yarn-set-preview/grid-colored-yarn-set-preview.component';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-article.component.html'
})
export class SelectArticleComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('articlesGrid') public articlesGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfArticles: OverviewListArticle[];
  private readonly getArticlesCommand: Observable<OverviewListArticle[]>;

  public constructor(
    @Inject(ARTICLES) private readonly articles: Articles,
    private readonly dialogRef: MatDialogRef<SelectArticleComponent>,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectArticleComponent>>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super();

    if (!isNil(data?.getArticlesCommand)) {
      this.getArticlesCommand = data.getArticlesCommand;
    }
  }

  public get gridOptionsForListOfArticles(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfArticles();
    this.getArticles();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.articlesGrid?.api];
  }

  public canSelectArticle(): boolean {
    return !AssertionUtils.isEmpty(this.listOfArticles) && !AssertionUtils.isNullOrUndefined(this.articlesGrid?.api) && this.articlesGrid.api.getSelectedRows().length > 0;
  }

  public selectArticle(): void {
    this.dialogRef.close(this.articlesGrid.api.getSelectedRows()[0]);
  }

  public navigateToAddArticle(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState({dialogComponent: SelectArticleComponent}, RouteUtils.paths.texFab.article.addArticle.absolutePath);
  }

  private setGridOptionsForListOfArticles(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfArticles(), GridIdentifier.SELECT_ARTICLE)
        .withOnRowDoubleClicked((row: RowDoubleClickedEvent) => {
          if (!row.node.isSelected()) {
            row.node.setSelected(true);
          }
          this.selectArticle();
        })
        .withNoRowsOverlay({
          titleParam: 'ARTICLES.ARTICLE',
          hideDescription: true,
          scale: 0.7
        } as NoDataOverlayComponentParams)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .build()
    ];
  }

  private getArticles(): void {
    const request = !isNil(this.getArticlesCommand) ? this.getArticlesCommand : this.articles.getAll();
    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((articles: OverviewListArticle[]) => {
      this.listOfArticles = articles;
    });
  }

  private getColumnDefsForListOfArticles(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('name').withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('design.name').withHeaderName('DESIGN_LIBRARY.DESIGN').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('machineQuality.nameWithVersion')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('coloredYarnSet')
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET')
        .withCellRenderer(GridColoredYarnSetPreviewComponent)
        .withSortable(false)
        .build()
    ];
  }
}
