import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {convertFileSizeUnitByte} from './convert-to-file-size-unit-byte';
import {convertFileSizeUnitKilobyte} from './convert-to-file-size-unit-kilobyte';
import {UnitConversionParameters} from './unit-conversion-parameters';

export function convertFileSizeUnit(unitConversionParameters: UnitConversionParameters): number {
  let result: number;

  if (unitConversionParameters.from.value === undefined) {
    return undefined;
  } else if (unitConversionParameters.from.value === null) {
    return null;
  }

  switch (unitConversionParameters.to) {
    case Unit.KILOBYTE:
      result = convertFileSizeUnitKilobyte(unitConversionParameters.from);
      break;
    case Unit.BYTE:
      result = convertFileSizeUnitByte(unitConversionParameters.from);
      break;
    default:
      throw new TextileUnitConversionError(`No conversion found from ${unitConversionParameters.from.unit} to ${unitConversionParameters.to}`);
  }

  return result;
}
