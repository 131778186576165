import {AnimationEvent} from '@angular/animations';
import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {slideDownUpAnimation, ToastAnimationState} from '@presentation/components/connectivity-toast/animations/connectivty-toast.animation';
import {ConnectivityToast, CONNECTIVITY_TOAST} from '@presentation/components/connectivity-toast/connectivity-toast';
import {BaseComponent} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {filter, takeUntil} from 'rxjs/operators';
import Timeout = NodeJS.Timeout;

@Component({
  templateUrl: './connectivity-toast.component.html',
  styleUrls: ['./connectivity-toast.component.scss'],
  animations: [slideDownUpAnimation()]
})
export class ConnectivityToastComponent extends BaseComponent implements OnInit, OnDestroy {
  public animationState = ToastAnimationState.DEFAULT;

  private connectivityToastTimeout: Timeout;
  private readonly elementRef: ElementRef;
  private readonly renderer: Renderer2;
  private readonly router: Router;

  public constructor(
    @Inject(CONNECTIVITY_TOAST) private readonly connectivityToast: ConnectivityToast,
    elementRef: ElementRef,
    renderer: Renderer2,
    router: Router
  ) {
    super();
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.router = router;
  }

  public ngOnInit(): void {
    this.initialiseComponent();

    this.connectivityToast.didRequestEnd.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.setAnimationStateToClosing();
    });
  }

  public ngOnDestroy(): void {
    clearTimeout(this.connectivityToastTimeout);
  }

  public onSlideAnimationFinished(event: AnimationEvent): void {
    const isToStateClosing = isEqual(event.toState, ToastAnimationState.CLOSING);
    const isAnimationStateClosing = isEqual(this.animationState, ToastAnimationState.CLOSING);

    if (isToStateClosing && isAnimationStateClosing) {
      this.connectivityToast.hide();
    }
  }

  private initialiseComponent(): void {
    this.subscribeToRouteEventChanges();
    this.attachClickEvent();
  }

  private attachClickEvent(): void {
    this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
      this.setAnimationStateToClosing();
    });
  }

  private subscribeToRouteEventChanges(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent | Event): boolean => event instanceof NavigationEnd),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.setAnimationStateToClosing();
      });
  }

  private setAnimationStateToClosing(): void {
    this.animationState = ToastAnimationState.CLOSING;
  }
}
