import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-input-chip',
  templateUrl: './input-chip.component.html',
  styleUrls: ['./input-chip.component.scss']
})
export class InputChipComponent {
  @Input() public value: string;
  @Input() public disabled = false;
  @Input() public error = false;
  @Input() public route: string;
  @Input() public multiSelect = false;
  @Input() public canShowBMSTheme = false;
  @Input() public canMoveChips = false;
  @Input() public color: string;
  @Output() public removeClick = new EventEmitter<void>();

  public constructor(public elementRef: ElementRef) {}

  public canShowCloseIcon(): boolean {
    return this.multiSelect && !this.disabled && !this.canShowBMSTheme;
  }

  public canShowCloseIconForBMSTheme(): boolean {
    return this.multiSelect && this.canShowBMSTheme;
  }

  public onRemoveClick(event: Event): void {
    event.stopPropagation();
    this.removeClick.emit();
  }

  public getTabIndex(): number {
    return AssertionUtils.isNullOrUndefined(this.route) || this.route === '' ? -1 : 0;
  }
}
