import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class RejectReasonRoute extends RouteBranch {
  private _addRejectReason: RouteLeaf;
  private _editRejectReason: RouteLeaf;
  private _duplicateRejectReason: RouteLeaf;

  public get addRejectReason(): RouteLeaf {
    return this._addRejectReason;
  }

  public set addRejectReason(value: RouteLeaf) {
    this.replace(this._addRejectReason, value);
    this._addRejectReason = value;
  }

  public get editRejectReason(): RouteLeaf {
    return this._editRejectReason;
  }

  public set editRejectReason(value: RouteLeaf) {
    this.replace(this._editRejectReason, value);
    this._editRejectReason = value;
  }

  public get duplicateRejectReason(): RouteLeaf {
    return this._duplicateRejectReason;
  }

  public set duplicateRejectReason(value: RouteLeaf) {
    this.replace(this._duplicateRejectReason, value);
    this._duplicateRejectReason = value;
  }
}
