import {AfterViewInit, Directive, ElementRef, HostListener, Input, Optional, Renderer2, SkipSelf} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NgControl, ValidationErrors, Validator} from '@angular/forms';
import {L10nIntlService} from 'angular-l10n';
import {MimeType} from '../../common/mime-type.enum';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {LocaleUtils} from '../../common/utils/locale-utils';

@Directive({
  selector: 'input[vdwL10nDecimal]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputL10nDecimalDirective,
      multi: true
    }
  ]
})
export class InputL10nDecimalDirective implements Validator, AfterViewInit {
  @Input() public decimals = false;
  @Input() public negativeNumbers = false;
  @Input() public maximumFractionDigits = 13;
  @Input() public canShowArrows = false;

  public constructor(
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly l10nIntlService: L10nIntlService,
    private renderer: Renderer2,
    @Optional() @SkipSelf() private readonly ngControl: NgControl
  ) {}

  @HostListener('ngModelChange')
  public onNgModelChange(): void {
    const currentValue = this.getNormalizedText(this.getCurrentTextInput());
    if (this.shouldSkipFormatting(currentValue)) {
      return;
    }
    let value: number = LocaleUtils.parseNumber(currentValue, this.l10nIntlService);

    if (!AssertionUtils.isNumber(value)) {
      return;
    }
    if (!this.negativeNumbers) {
      value = Math.max(0, value);
    }
    const formattedValue = LocaleUtils.formatNumber(value, this.l10nIntlService, this.getMaximumFractionDigits());

    this.elementRef.nativeElement.value = formattedValue;
    this.ngControl?.control.patchValue(formattedValue, {emitEvent: false, emitViewToModelChange: false});
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent: KeyboardEvent): void {
    if (!this.isNumericKeyCode(keyboardEvent.key) && !this.isCommand(keyboardEvent) && !this.allowNegativeNumbers(keyboardEvent.key) && !this.allowDecimals(keyboardEvent.key)) {
      keyboardEvent.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData(MimeType.PLAIN_TEXT);
    this.elementRef.nativeElement.value = this.getProcessedText(pastedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    const droppedInput: string = event.dataTransfer.getData(MimeType.TEXT).replace(/\D/g, '');
    this.elementRef.nativeElement.value = this.getProcessedText(droppedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    this.elementRef.nativeElement.focus();
  }

  public ngAfterViewInit(): void {
    if (this.canShowArrows) {
      const parent = this.elementRef.nativeElement.closest('mat-form-field');
      const matFormFieldFlexElement = parent.querySelector('.mat-mdc-form-field-flex');

      if (matFormFieldFlexElement) {
        const arrowsElement = this.renderer.createElement('div');
        this.renderer.addClass(arrowsElement, 'flex-center');
        this.renderer.addClass(arrowsElement, 'arrow-wrapper');

        const decrementButtonElement = this.createElementForButton();
        this.renderer.appendChild(arrowsElement, decrementButtonElement);

        const incrementButtonElement = this.createElementForButton(true);
        this.renderer.appendChild(arrowsElement, incrementButtonElement);

        this.renderer.appendChild(matFormFieldFlexElement, arrowsElement);
      }
    }
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (!this.elementRef.nativeElement.validity.badInput) {
      return null;
    }
    return {badInput: true};
  }

  private getMaximumFractionDigits(): number {
    return this.decimals ? this.maximumFractionDigits : 0;
  }

  private isNumericKeyCode(key: string): boolean {
    return /^\d$/.test(key);
  }

  private isCommand(keyboardEvent: KeyboardEvent): boolean {
    return this.isControlCommand(keyboardEvent) || this.isStandardCommand(keyboardEvent.key);
  }

  private isControlCommand(keyboardEvent: KeyboardEvent): boolean {
    return /^[acvxyz]$/.test(keyboardEvent.key) && (keyboardEvent.ctrlKey || keyboardEvent.metaKey);
  }

  private isStandardCommand(key: string): boolean {
    const standardCommands: string[] = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Shift', 'Home', 'End'];
    return new RegExp(`^(${standardCommands.join('|')})$`).test(key);
  }

  private allowDecimals(key: string): boolean {
    return this.isDecimalSeparator(key) && this.decimals && !new RegExp(`[${this.getDecimalSeparator()}]`).test(this.getCurrentTextInput());
  }

  private isDecimalSeparator(key: string): boolean {
    return key === this.getDecimalSeparator();
  }

  private getDecimalSeparator(): string {
    return LocaleUtils.getDecimalSeparator(this.l10nIntlService);
  }

  private allowNegativeNumbers(key: string): boolean {
    return '-' === key && this.negativeNumbers;
  }

  private getCurrentTextInput(): string {
    let input = this.elementRef.nativeElement.value;
    if (AssertionUtils.isNumber(input)) {
      input = LocaleUtils.formatNumber(input, this.l10nIntlService, this.maximumFractionDigits);
    }
    return `${input}`;
  }

  private getProcessedText(rawText: string): string {
    const decimalSeparator = this.getDecimalSeparator();
    const forbiddenCharacters = new RegExp(`[^0-9${decimalSeparator}-]`, 'g');
    let processedText = rawText.replace(forbiddenCharacters, '');

    if (!this.decimals) {
      const decimalSeparators = new RegExp(`[${decimalSeparator}]`, 'g');
      processedText = processedText.replace(decimalSeparators, '');
    }

    if (!this.negativeNumbers) {
      processedText = processedText.replace(/-/g, '');
    }

    return processedText;
  }

  private getNormalizedText(rawText: string): string {
    if (AssertionUtils.isEmpty(rawText)) {
      return rawText;
    }
    return rawText.charAt(0).concat(rawText.slice(1).replace(/-/g, '')).replace(/[.,]/g, this.getDecimalSeparator());
  }

  private shouldSkipFormatting(text: string): boolean {
    const decimalSeparator = this.getDecimalSeparator();
    if (!new RegExp(`[${decimalSeparator}]`).test(text)) {
      return true;
    }
    const textSections = text.split(decimalSeparator);
    const decimalValue = textSections[textSections.length - 1];
    const lastChar = text.charAt(text.length - 1);
    return this.isDecimalSeparator(lastChar) || (lastChar === '0' && decimalValue.length <= this.getMaximumFractionDigits());
  }

  private createElementForButton(isIncrement: boolean = false): Element {
    const inputElement = this.elementRef.nativeElement.closest('input');

    const buttonElement = this.renderer.createElement('button');
    this.renderer.setAttribute(buttonElement, 'mat-icon-button', '');
    this.renderer.addClass(buttonElement, 'mdc-icon-button');
    this.renderer.addClass(buttonElement, 'mat-mdc-icon-button');
    this.renderer.addClass(buttonElement, 'mat-mdc-button-base');

    const iconElement = this.renderer.createElement('div');
    const icon = isIncrement ? 'solid-keyboard-arrow-up' : 'solid-keyboard-arrow-down';
    this.renderer.addClass(iconElement, 'mat-icon');
    this.renderer.setStyle(iconElement, 'mask-image', `url(/assets/solid-icons/${icon}.svg)`);

    this.renderer.listen(buttonElement, 'click', () => {
      if (!inputElement['disabled']) {
        return isIncrement ? inputElement.stepUp() : inputElement.stepDown();
      }
    });
    this.renderer.listen(buttonElement, 'focus', () => this.renderer.addClass(buttonElement, 'cdk-mouse-focused'));
    this.renderer.listen(buttonElement, 'blur', () => this.renderer.removeClass(buttonElement, 'cdk-mouse-focused'));

    this.renderer.appendChild(buttonElement, iconElement);

    return buttonElement;
  }
}
