import {BlobUtils, DialogBuilderFactoryService, DialogType, TranslateService} from '@vdw/angular-component-library';
import {first, forEach, isEmpty, isEqual, isNil, join, last, some} from 'lodash-es';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BitmapMetadata} from './bitmap-metadata/bitmap-metadata.model';
import {BitmapMetadataReader} from './bitmap-metadata/bitmap-metadata.reader';

export abstract class ImageUploadHelper<T, S> {
  protected maxFileSizeAllowedInMb = 100;
  protected validBitmapBitsPerPixelValues: [number] = [8];
  protected imageSubject: Subject<{error: string; image: S}> = new Subject();
  protected _unSubscribeOnViewDestroy: Subject<boolean>;
  protected subscriptionForImageSubject: Subscription;

  protected abstract dialogTitle: string;

  protected constructor(
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {}

  public set unSubscribeOnViewDestroy(unSubscribeOnViewDestroy: Subject<boolean>) {
    this._unSubscribeOnViewDestroy = unSubscribeOnViewDestroy;
  }

  public uploadFilesWithCustomResultAction(files: File[], fileType: T[], resultAction: (image: S, localFileCopy?: File[]) => void): void {
    this.removeSubscriptionForImageSubject();

    const localFilesCopy: File[] = [];
    localFilesCopy.push(...files);

    this.subscriptionForImageSubject = this.imageSubject.pipe(takeUntil(this._unSubscribeOnViewDestroy)).subscribe((result: {error: string; image: S}) => {
      if (!isNil(result.error)) {
        this.displayAlertDialog(result.error);
      }

      resultAction(result.image, localFilesCopy);
    });

    this.processFileChange(localFilesCopy, fileType);
  }

  protected processFileChange(files: File[], allowedTypes: T[]): void {
    if (!isEmpty(files)) {
      const pvdFiles = files.filter((file: File) => file.name.includes('.pvd'));

      if (files.length === pvdFiles.length) {
        this.imageSubject.next({
          error: 'GENERAL.WARNINGS.PVD_PAIRED_WITH_BMP',
          image: null
        });
      } else {
        forEach(files, (file: File) => {
          const filePathParts: string[] = file.name.split('.');
          const fileExtension: string = last(filePathParts).toLowerCase();
          const fileName: string = first(filePathParts);
          const fileType: T = this.getFileTypeFromAllowedTypes(fileExtension, allowedTypes);
          if (isNil(fileType)) {
            this.imageSubject.next({
              error: this.translate.instant('GENERAL.WARNINGS.WRONG_FILE_FORMAT', {
                name: fileName,
                fileType: join(allowedTypes, ', ')
              }),
              image: null
            });
          } else if (this.getFileSize(file) > this.maxFileSizeAllowedInMb) {
            this.imageSubject.next({
              error: this.translate.instant('GENERAL.WARNINGS.FILE_TOO_LARGE', {
                name: fileName,
                maxSize: this.maxFileSizeAllowedInMb
              }),
              image: null
            });
          } else {
            this.processFile(fileName, file, fileType);
          }
        });
      }
    } else {
      this.imageSubject.next({error: 'GENERAL.WARNINGS.NO_FILES_FOUND', image: null});
    }
  }

  protected displayAlertDialog(message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.dialogTitle,
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  protected onImageReaderLoadForFormatLocalValidation(fileName: string, file: File, fileType: T): (imageArrayBuffer: ArrayBuffer) => void {
    return (imageArrayBuffer: ArrayBuffer): void => {
      if (!this.isBitmapFormatValid(imageArrayBuffer)) {
        this.imageSubject.next({error: 'GENERAL.WARNINGS.BITMAP_FORMAT_NOT_SUPPORTED_MESSAGE', image: null});
        return;
      }

      BlobUtils.blobToImageData(file).then(this.onImageReaderLoad(fileName, file, fileType));
    };
  }

  protected removeSubscriptionForImageSubject(): void {
    if (!isNil(this.subscriptionForImageSubject)) {
      this.subscriptionForImageSubject.unsubscribe();
    }
  }

  private isBitmapFormatValid(imageData: ArrayBuffer): boolean {
    if (isNil(imageData)) {
      return false;
    }

    const bytes: number[] = [];
    const numberOfBytesToUseForTheHeader = 100;
    const firstBytesOfBufferForCreatingBytesArrayToWorkWith = new Int8Array(imageData.slice(0, numberOfBytesToUseForTheHeader));
    firstBytesOfBufferForCreatingBytesArrayToWorkWith.forEach((value: number) => bytes.push(value));

    const metadata: BitmapMetadata = BitmapMetadataReader.readMetadata(bytes);
    return some(this.validBitmapBitsPerPixelValues, (value: number) => isEqual(value, metadata.bitsPerPixel));
  }

  private onImageReaderLoad(fileName: string, file: File, fileType: T): (imageData: string) => void {
    return (imageData: string): void => {
      const drawingImage: HTMLImageElement = new Image();
      drawingImage.src = imageData;
      drawingImage.id = fileName;
      drawingImage.onload = this.onImageLoad(drawingImage, file, fileType);
    };
  }

  private getFileSize(file: File): number {
    return file.size / Math.pow(1024, 2);
  }

  protected abstract getFileTypeFromAllowedTypes(fileExtension: string, allowedTypes: T[]): T;

  protected abstract processFile(fileName: string, file: File, fileType: T): void;

  protected abstract onImageLoad(image: HTMLImageElement, file: File, fileType: T): () => void;
}
