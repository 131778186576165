import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'badgeLabel'
})
export class BadgeLabelPipe implements PipeTransform {
  public transform(value: number): string {
    if (value === 0) {
      return '';
    } else if (value > 9) {
      return '+9';
    }
    return value?.toString();
  }
}
