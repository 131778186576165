import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {FinishingTemplateLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-label';
import {FinishingLabelType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label-type.enum';
import {TranslateService} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {filter, isEmpty, isEqual, isNil, isUndefined, join, lowerCase, size} from 'lodash-es';

@Component({
  styleUrls: ['./grid-labels.component.scss'],
  templateUrl: './grid-labels.component.html'
})
export class GridLabelsComponent implements ICellRendererAngularComp, AfterViewChecked {
  @ViewChild('textElement') public text: ElementRef;
  public tooltipHideDelayMs = 250;
  public tooltipShowDelayMs = 500;
  public showTooltip = false;
  private params: ICellRendererParams;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly translate: TranslateService;

  public constructor(changeDetectorRef: ChangeDetectorRef, translate: TranslateService) {
    this.changeDetectorRef = changeDetectorRef;
    this.translate = translate;
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public getText(): string {
    let hasLabels: string = this.translate.instant(this.hasLabels() ? 'GENERAL.CONDITION.YES' : 'GENERAL.CONDITION.NO');
    const numberOfLabels: string[] = [];
    if (this.canShowNumberOfImageLabels()) {
      numberOfLabels.push(`${this.getNumberOfImageLabels()} ${lowerCase(this.translate.instant('GENERAL.IMAGE', {count: this.getNumberOfImageLabels()}))}`);
    }
    if (this.canShowNumberOfTextLabels()) {
      numberOfLabels.push(`${this.getNumberOfTextLabels()} ${lowerCase(this.translate.instant('GENERAL.TEXT', {count: this.getNumberOfTextLabels()}))}`);
    }
    if (!isEmpty(numberOfLabels)) {
      hasLabels = `${hasLabels} (${join(numberOfLabels, ', ')})`;
    }
    return hasLabels;
  }

  public hasLabels(): boolean {
    return !isNil(this.getCurrentFinishingTemplateLabel()) && !isEmpty(this.getCurrentFinishingTemplateLabel());
  }

  public getNumberOfTextLabels(): number {
    return size(filter(this.getCurrentFinishingTemplateLabel(), ['finishingLabelType', FinishingLabelType.TEXT]));
  }

  public getNumberOfImageLabels(): number {
    return size(filter(this.getCurrentFinishingTemplateLabel(), ['finishingLabelType', FinishingLabelType.IMAGE]));
  }

  public canShowNumberOfImageLabels(): boolean {
    return !isEqual(this.getNumberOfImageLabels(), 0);
  }

  public canShowNumberOfTextLabels(): boolean {
    return !isEqual(this.getNumberOfTextLabels(), 0);
  }

  private getCurrentFinishingTemplateLabel(): FinishingTemplateLabel {
    return this.params.getValue();
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.text) && this.text.nativeElement.offsetWidth < this.text.nativeElement.scrollWidth;
  }
}
