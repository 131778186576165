import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {CheckName} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {map, map as rxjsMap} from 'rxjs/operators';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpBuggyService implements Repository<BuggyForProductionSchedule, BuggyForProductionSchedule>, UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<BuggyForProductionSchedule[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies`)
      .pipe(map((bugiesJSON: any) => bugiesJSON.map((buggyJSON: any) => BuggyForProductionSchedule.fromJSON(buggyJSON))));
  }

  public getCategoriesWithComponentsForBuggyName(): Observable<PlaceholderCategory[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/categories-with-components-for-buggy-name`)
      .pipe(map((placeholderCategoriesJSON: any) => placeholderCategoriesJSON.map((placeHolderJSON: any) => PlaceholderCategory.fromJSON(placeHolderJSON))));
  }

  public getById(id: number): Observable<BuggyForProductionSchedule> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/${id}`).pipe(map((buggyJSON: any) => BuggyForProductionSchedule.fromJSON(buggyJSON)));
  }

  public delete(id: number | string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/${id}`);
  }

  public save(item: BuggyForProductionSchedule): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies`, item.toJSON()).pipe(rxjsMap((response: number) => response));
  }

  public update(item: BuggyForProductionSchedule): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(identifier: string): Observable<CheckName> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/check-name?name=${identifier}`).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getGeneratedBuggyName(machineId: number, qualityId: number, creelId: number): Observable<string> {
    return this.httpClient
      .post(`${this.backendConfiguration.getAggregatorEndpoint()}buggies/generate-name?machineId=${machineId}&qualityId=${qualityId}&creelId=${creelId}`, null)
      .pipe(rxjsMap((response: string) => response));
  }

  public getBuggyReportXSLT(): Observable<string> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}buggies/overview/xslt`, {responseType: 'text'});
  }

  public getBuggyReportXML(buggyId: number): Observable<string> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}buggies/${buggyId}/overview/xml`, {responseType: 'text'});
  }
}
