import {OverlayModule} from '@angular/cdk/overlay';
import {NgModule} from '@angular/core';
import {CONNECTIVITY_TOAST} from '@presentation/components/connectivity-toast/connectivity-toast';
import {ConnectivityToastComponent} from '@presentation/components/connectivity-toast/connectivity-toast.component';
import {ConnectivityToastService} from '@presentation/components/connectivity-toast/connectivity-toast.service';
import {TranslationModule} from '@vdw/angular-component-library';
import {MaterialModule} from '../../../material.module';

@NgModule({
  imports: [OverlayModule, MaterialModule, TranslationModule],
  declarations: [ConnectivityToastComponent],
  providers: [
    {
      provide: CONNECTIVITY_TOAST,
      useClass: ConnectivityToastService
    }
  ]
})
export class ConnectivityToastModule {}
