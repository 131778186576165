import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UUID} from 'crypto';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {UuidUtils} from '../../common/utils/uuid-utils';
import {DialogBuilder} from '../dialog-builder/dialog-builder';
import {DialogOpenerService} from '../dialog-opener/dialog-opener.service';
import {RepositionWatchDialogFactoryService} from '../dialog-reposition/reposition-watch-dialog-factory.service';

@Injectable()
export class HoverDialogBuilderService {
  private id: UUID;
  private timeout: number;
  private dialogRef: HTMLElement;
  private createdBuilder: DialogBuilder;
  private hoveredElements: {element: HTMLElement; isHovered: boolean}[] = [];

  public constructor(
    private readonly repositionDialogWatchFactoryService: RepositionWatchDialogFactoryService,
    private readonly dialogOpener: DialogOpenerService
  ) {}

  public getBuilderWithHoverElements(hoveredElement: HTMLElement, otherElements: HTMLElement[], timeout: number = 100): DialogBuilder {
    if (!AssertionUtils.isNullOrUndefined(this.createdBuilder)) {
      return this.createdBuilder;
    }

    this.timeout = timeout;
    this.id = UuidUtils.generateV4Uuid() as UUID;
    [hoveredElement, ...otherElements].forEach((element: HTMLElement) => this.addEventListeners(element));

    this.hoveredElements.push({element: hoveredElement, isHovered: true});
    otherElements.forEach((element: HTMLElement) => this.hoveredElements.push({element, isHovered: false}));

    this.createdBuilder = new DialogBuilder(this.repositionDialogWatchFactoryService.getService(), this.dialogOpener).withId(this.id).withOnDialogOpened((dialog: MatDialogRef<any>) => {
      this.dialogRef = dialog._containerInstance['_elementRef'].nativeElement as HTMLElement;
      this.hoveredElements.push({element: this.dialogRef, isHovered: false});

      this.addEventListeners(this.dialogRef);
    });

    return this.createdBuilder;
  }

  public registerElement(element: HTMLElement, isHovered: boolean): void {
    if (AssertionUtils.isNullOrUndefined(this.createdBuilder)) {
      return;
    }

    this.hoveredElements.push({element: element, isHovered});
    this.addEventListeners(element);
  }

  public setHovered(element: HTMLElement, isHovered: boolean): void {
    const foundElement = this.hoveredElements.find((hoveredElement: {element: HTMLElement; isHovered: boolean}) => hoveredElement.element.id === element.id);

    if (!AssertionUtils.isNullOrUndefined(foundElement)) {
      foundElement.isHovered = isHovered;
    }
  }

  public closeHoverDialog(): void {
    if (!AssertionUtils.isNullOrUndefined(this.createdBuilder)) {
      this.createdBuilder.close(this.id);
      this.cleanup();
    }
  }

  private mouseEnter = (event: MouseEvent): void => {
    this.setHovered(event.target as HTMLElement, true);
  };

  private mouseLeave = (event: MouseEvent): void => {
    this.setHovered(event.target as HTMLElement, false);

    if (!AssertionUtils.isNullOrUndefined(this.createdBuilder)) {
      setTimeout(() => {
        const anyHovered = this.hoveredElements.map((hoveredElement: {element: HTMLElement; isHovered: boolean}) => hoveredElement.isHovered).some((value: boolean) => value === true);

        if (!anyHovered && !(event.relatedTarget as HTMLElement)?.classList.contains('mat-mdc-tooltip')) {
          this.closeHoverDialog();
        }
      }, this.timeout);
    }
  };

  private cleanup(): void {
    const elements = this.hoveredElements.map((hoveredElement: {element: HTMLElement; isHovered: boolean}) => hoveredElement.element);

    elements.forEach((element: HTMLElement) => this.removeEventListeners(element));
    this.removeEventListeners(this.dialogRef);

    this.hoveredElements = [];
    this.createdBuilder = null;
  }

  private addEventListeners(element: HTMLElement): void {
    element.addEventListener('mouseenter', this.mouseEnter);
    element.addEventListener('mouseleave', this.mouseLeave);
  }

  private removeEventListeners(element: HTMLElement): void {
    element?.removeEventListener('mouseenter', this.mouseEnter);
    element?.removeEventListener('mouseleave', this.mouseLeave);
  }
}
