import {FinishingForArticle} from '@domain/article/finishing-for-article';
import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {isNil} from 'lodash-es';

export class Article {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _design: Drawing;
  private readonly _finishing: FinishingForArticle;
  private readonly _used: boolean;

  public constructor(id: number, name: string, design: Drawing, finishing: FinishingForArticle, used?: boolean) {
    this._id = id;
    this._name = name;
    this._design = design;
    this._finishing = finishing;
    this._used = used;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get design(): Drawing {
    return this._design;
  }

  public get finishing(): FinishingForArticle {
    return this._finishing;
  }

  public get used(): boolean {
    return this._used;
  }

  public static fromJSON(articleJSON: any): Article {
    return new Article(
      articleJSON.id,
      articleJSON.name,
      !isNil(articleJSON.design) && articleJSON.design.drawingType === DrawingType.BMP ? BmpDrawing.fromJSON(articleJSON.design) : null,
      !isNil(articleJSON.finishing) ? FinishingForArticle.fromJSON(articleJSON.finishing) : null,
      articleJSON.used
    );
  }

  public static createEmptyArticle(): Article {
    return new Article(null, null, null, null, false);
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      finishingId: !isNil(this.finishing) ? this.finishing.id : null,
      design: {
        id: this.design.id,
        configuration: this.design.configurations[0].toJSON()
      }
    } as any as JSON;
  }
}
