import {AfterContentInit, Component, ContentChildren, Input, QueryList} from '@angular/core';
import moment, {duration} from 'moment';
import {map, startWith, takeUntil, tap} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ShiftInstance} from './shift-instance';
import {ShiftComponent} from './shift.component';

@Component({
  selector: 'vdw-shift-schedule',
  template: ``
})
export class ShiftScheduleComponent extends BaseComponent implements AfterContentInit {
  @Input()
  public days: number[];

  @ContentChildren(ShiftComponent)
  private shiftsQuery: QueryList<ShiftComponent>;

  private shifts: ShiftComponent[];

  public ngAfterContentInit(): void {
    this.shiftsQuery.changes
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        startWith(undefined),
        map(() => this.shiftsQuery.toArray()),
        tap(this.sortShifts)
      )
      .subscribe((shifts: ShiftComponent[]) => (this.shifts = shifts));
  }

  public getShiftsForPeriod(start: Date, end: Date): ShiftInstance[] {
    if (AssertionUtils.isEmpty(this.days) || AssertionUtils.isEmpty(this.shifts)) {
      return [];
    }

    const startDate = moment(start);
    const refDate = startDate.clone().startOf('day').add(-1, 'd');

    const endDate = moment(end);
    const result: ShiftInstance[] = [];
    while (refDate < endDate) {
      for (const shift of this.shifts) {
        this.addShiftInstancesForDate(refDate, shift, result, startDate);
      }
      refDate.add(1, 'd');
    }

    if (!AssertionUtils.isEmpty(result) && result[result.length - 1].end < endDate) {
      this.addEmptySpace(result, refDate, endDate, startDate);
    }

    result?.forEach((instance: ShiftInstance) => instance.fit(startDate, endDate));
    return result?.filter((instance: ShiftInstance) => instance.durationInMinutes > 0);
  }

  public getShiftsForDay(date: Date): ShiftInstance[] {
    return this.getShiftsForPeriod(moment(date).startOf('day').toDate(), moment(date).endOf('day').toDate());
  }

  private addShiftInstancesForDate(refDate: moment.Moment, shift: ShiftComponent, result: ShiftInstance[], startDate: moment.Moment): void {
    while (!this.days.includes(refDate.weekday())) {
      refDate.add(1, 'd');
    }

    let shiftStart = shift.getStartOnDate(refDate);
    this.addEmptySpace(result, refDate, shiftStart, startDate);

    const shiftEnd = shift.getEndOnDate(refDate);
    if (shiftEnd < shiftStart) {
      shiftEnd.add(1, 'd');
    }
    if (shiftStart < startDate) {
      shiftStart = startDate.clone();
    }

    if (shiftEnd <= startDate) {
      return;
    }
    result.push(new ShiftInstance(shiftStart, shiftEnd, shift.name));
  }

  private addEmptySpace(result: ShiftInstance[], refDate: moment.Moment, shiftStart: moment.Moment, startDate: moment.Moment): void {
    const previousIterEnd = result.length > 0 ? result[result.length - 1].end : refDate;
    const emptySpaceDuration = duration(shiftStart.diff(previousIterEnd));
    if (emptySpaceDuration.asMinutes() < 0) {
      throw new Error('Overlapping shifts are not allowed!');
    }

    if (emptySpaceDuration.asMinutes() === 0 || shiftStart <= startDate) {
      return;
    }
    result.push(new ShiftInstance(previousIterEnd, shiftStart));
  }

  private sortShifts(shifts: ShiftComponent[]): void {
    shifts.sort((shiftA: ShiftComponent, shiftB: ShiftComponent) => {
      const [hoursA, minutesA] = shiftA.getTimeAsNumeric(shiftA.start);
      const [hoursB, minutesB] = shiftB.getTimeAsNumeric(shiftB.start);
      if (hoursA === hoursB) {
        return minutesA - minutesB;
      }
      return hoursA - hoursB;
    });
  }
}
