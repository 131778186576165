import {EventEmitter, Injectable} from '@angular/core';
import {UUID} from 'crypto';
import {AssertionUtils} from '../../../../common/utils/assertion-utils';
import {UuidUtils} from '../../../../common/utils/uuid-utils';
import {DndDragStartedData} from '../dnd-drop-list-interfaces/dnd-drag-started-data';
import {DndDraggableDropListEntry} from '../dnd-drop-list-interfaces/dnd-draggable-drop-list-entry';
import {DndEntryRemovedData} from '../dnd-drop-list-interfaces/dnd-entry-removed-data';

@Injectable({providedIn: 'root'})
export class DndDragDropHelperService {
  public maxNestedDepth: number = null;
  public dragActive = false;
  public dragStarted = new EventEmitter<DndDragStartedData>();
  public dragEnded = new EventEmitter<DndDraggableDropListEntry>();
  public dragDrop = new EventEmitter<DndDraggableDropListEntry>();
  public entryRemoved = new EventEmitter<DndEntryRemovedData>();

  public readonly DEFAULT_MAX_NESTED_DEPTH = 6;

  public rootEntry: DndDraggableDropListEntry;
  private dataByKey: {[id: UUID]: any} = {};

  public assignData<T>(data: T[], getChildren: (parent: T) => T[], isExpanded: boolean = true, isExpandedChildren: boolean = true, isDragDisabled: boolean = false): void {
    this.dataByKey = {};
    this.rootEntry = {
      key: '00000000-0000-0000-0000-000000000000',
      childEntries: data.map((item: T) => this.toDraggableDropListEntry(item, getChildren, isExpanded, isExpandedChildren, isDragDisabled))
    };
  }

  public getDataByKey<T>(key: UUID): T {
    return this.dataByKey[key];
  }

  public getAllData<T>(): T[] {
    return Object.values(this.dataByKey);
  }

  public findKeyForData<T>(data: T): UUID {
    return Object.keys(this.dataByKey).find((key: UUID) => this.dataByKey[key] === data) as UUID;
  }

  public findDraggableDropListEntryByKey(key: UUID, parent?: DndDraggableDropListEntry): DndDraggableDropListEntry {
    parent ??= this.rootEntry;
    if (parent.key === key) {
      return parent;
    }

    for (const childEntry of parent.childEntries) {
      const entry = this.findDraggableDropListEntryByKey(key, childEntry);
      if (!AssertionUtils.isNullOrUndefined(entry)) {
        return entry;
      }
    }

    return null;
  }

  public findParentOfItemWithKey(key: UUID, parent?: DndDraggableDropListEntry): DndDraggableDropListEntry {
    parent ??= this.rootEntry;
    for (const childEntry of parent.childEntries) {
      if (childEntry.key === key) {
        return parent;
      }
      const foundParent = this.findParentOfItemWithKey(key, childEntry);
      if (!AssertionUtils.isNullOrUndefined(foundParent)) {
        return foundParent;
      }
    }
    return null;
  }

  public getNestedDepth(entry: DndDraggableDropListEntry): number {
    if (this.rootEntry.childEntries.includes(entry)) {
      return 0;
    }
    const parent = this.findParentOfItemWithKey(entry.key);
    if (AssertionUtils.isNullOrUndefined(parent)) {
      return -1;
    }
    return this.getNestedDepth(parent) + 1;
  }

  public toDraggableDropListEntry<T>(
    item: T,
    getChildren: (parent: T) => T[],
    isExpanded: boolean = true,
    isExpandedChildren: boolean = true,
    isDragDisabled: boolean = false
  ): DndDraggableDropListEntry {
    const key = UuidUtils.generateV4Uuid() as UUID;
    this.dataByKey[key] = item;

    return {
      key,
      isExpanded: isExpanded,
      childEntries: getChildren(item).map((childItem: T) => this.toDraggableDropListEntry(childItem, getChildren, isExpandedChildren, isExpandedChildren, isDragDisabled)),
      isDragDisabled: isDragDisabled
    };
  }

  public fromDraggableDropListEntry<T>(entry: DndDraggableDropListEntry, setChildren?: (parent: T, children: T[]) => void): T {
    const item = this.getDataByKey<T>(entry.key);
    if (!AssertionUtils.isNullOrUndefined(setChildren)) {
      setChildren(
        item,
        entry.childEntries?.map((childEntry: DndDraggableDropListEntry) => this.fromDraggableDropListEntry(childEntry, setChildren))
      );
    }
    return item;
  }
}
