import {InjectionToken} from '@angular/core';
import {WeftColoredYarnSet} from '@domain/textile-data/weft-colored-yarn-set/weft-colored-yarn-set';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {ConflictsRepository} from '../conflicts-repository';

export const WEFT_COLORED_YARN_SETS = new InjectionToken<WeftColoredYarnSets>('WeftColoredYarnSets');

export interface WeftColoredYarnSets extends Repository<WeftColoredYarnSet, OverviewListColoredYarnSet>, UniqueIdentifierObjectRepository, ConflictsRepository {}
