import {InjectionToken} from '@angular/core';
import {DateRange} from '@domain/date-range';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {PlanningItem} from '@domain/planning/planning-item';
import {PlanningMachine} from '@domain/planning/planning-machine';
import {PlanningMachineRun} from '@domain/planning/planning-machine-run';
import {PlanningOrder} from '@domain/planning/planning-order';
import {Observable} from 'rxjs';

export const PLANNING_SCHEDULER = new InjectionToken<PlanningScheduler>('PlanningScheduler');

export interface PlanningScheduler {
  isUpdating: boolean;
  onPlanningChanges(): Observable<CalendarItemGroup[]>;

  getPlanning(selectedDateRange: DateRange, onSuccess?: () => void): void;

  rescheduleItems(planningItem: PlanningItem, sourceMachine: PlanningMachine, destinationGroupedCalendarItem: CalendarItemGroup, destinationMachine: PlanningMachine): void;

  reschedulePlanningOrder(
    draggedPlanningOrder: PlanningOrder,
    sourceRun: PlanningMachineRun,
    destinationRun: PlanningMachineRun,
    planningOrderNewPosition: number,
    destinationMachine: PlanningMachine,
    sourceMachineId: number
  ): void;

  addNewPlanningItem(newPlanningItem: PlanningItem, destinationGroupedCalendarItem: CalendarItemGroup): void;

  resetPlanning(): void;

  getCachedGroupedCalendarItem(machineId: number): CalendarItemGroup;

  setGroupedCalendarItems(groupedCalendarItems: CalendarItemGroup[]): void;

  addUnplannedPlanningOrderToRun(unplannedPlanningOrder: PlanningOrder, run: PlanningMachineRun, planningOrderPosition: number, machine: PlanningMachine, onSuccess: () => void);

  addUnplannedPlanningOrderToNewRun(unplannedPlanningOrder: PlanningOrder, machine: PlanningMachine, onSuccess: () => void);

  addPlannedPlanningOrderToNewRun(plannedPlanningOrder: PlanningOrder, sourceRun: PlanningMachineRun, sourceMachineId: number, destinationMachine: PlanningMachine): void;
}
