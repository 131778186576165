import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {FinishingAndFinishingTemplateLabelOverviewList} from '@presentation/pages/textile-data/finishing-template/overview/finishing-and-finishing-template-label-overview-list';
import {CutbarsInformationForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/cutbars-information-for-machine-quality';
import {isNil} from 'lodash-es';

export class FinishingTemplateForMachineQuality {
  private _id: number;
  private _name: string;
  private _finishingType: FinishingType;
  private _cutbarsInformation: CutbarsInformationForMachineQuality;
  private _labels: FinishingAndFinishingTemplateLabelOverviewList[];

  public constructor(id: number, name: string, finishingType: FinishingType, cutbarsInformation: CutbarsInformationForMachineQuality, labels: FinishingAndFinishingTemplateLabelOverviewList[]) {
    this._id = id;
    this._name = name;
    this._finishingType = finishingType;
    this._cutbarsInformation = cutbarsInformation;
    this._labels = labels;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get finishingType(): FinishingType {
    return this._finishingType;
  }

  public get cutbarsInformation(): CutbarsInformationForMachineQuality {
    return this._cutbarsInformation;
  }

  public get labels(): FinishingAndFinishingTemplateLabelOverviewList[] {
    return this._labels;
  }

  public static fromJSON(finishingTemplateForForCompatibilityWithMachineQualityJSON: any): FinishingTemplateForMachineQuality {
    return new FinishingTemplateForMachineQuality(
      finishingTemplateForForCompatibilityWithMachineQualityJSON.id,
      finishingTemplateForForCompatibilityWithMachineQualityJSON.name,
      finishingTemplateForForCompatibilityWithMachineQualityJSON.finishingType as FinishingType,
      CutbarsInformationForMachineQuality.fromJSON(finishingTemplateForForCompatibilityWithMachineQualityJSON.cutbarsInformation),
      finishingTemplateForForCompatibilityWithMachineQualityJSON.labels?.map((finishingTemplateLabelJSON: any) => FinishingAndFinishingTemplateLabelOverviewList.fromJSON(finishingTemplateLabelJSON))
    );
  }

  public hasCutbarsInformation(): boolean {
    return !isNil(this.cutbarsInformation);
  }
}
