<mat-expansion-panel class="mat-elevation-z" [expanded]="true">
  <mat-expansion-panel-header expandedHeight="54px" collapsedHeight="54px">
    <mat-panel-title fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <h3 class="font-weight-semibold">{{ getLabelName() }}</h3>
      <app-color-list-preview [colorList]="getColorList()" [showDetailsOnClick]="false"> </app-color-list-preview>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <section fxLayout="column" fxLayoutGap="24px">
    <app-card-info
      [message]="{main: 'TEXTILE_DATA.YARN_TYPE.CALCULATE_YARN_CONSUMPTION' | bmsTranslate}"
      [information]="'TEXTILE_DATA.YARN_TYPE.CALCULATE_YARN_CONSUMPTION_WARNING' | bmsTranslate"
      [progressButton]="'TEXTILE_DATA.YARN_TYPE.CALCULATE' | bmsTranslate"
      [inProgress]="calculatingYarnConsumption"
      [authorized]="hasPermissionToCalculateYarnConsumption()"
      (clickButtonEvent)="calculateYarnConsumption()"
    ></app-card-info>
    <ag-grid-angular #creelPositionsGrid class="ag-theme-vdw" domLayout="autoHeight" [gridOptions]="gridOptionsForListOfCreelPositions" [rowData]="listOfCreelPositions"></ag-grid-angular>
  </section>
</mat-expansion-panel>
