import {EquipmentManufacturer} from '@domain/machine/equipment-manufacturer';

export abstract class BaseMachineType {
  private _id: number;
  private _name: string;
  private _manufacturer: EquipmentManufacturer;
  private _model: string;

  protected constructor(id: number, name: string, manufacturer: EquipmentManufacturer, model: string) {
    this._id = id;
    this._name = name;
    this._manufacturer = manufacturer;
    this._model = model;
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      manufacturerId: this.manufacturer?.id,
      model: this.model
    } as any as JSON;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get manufacturer(): EquipmentManufacturer {
    return this._manufacturer;
  }

  public set manufacturer(manufacturer: EquipmentManufacturer) {
    this._manufacturer = manufacturer;
  }

  public get model(): string {
    return this._model;
  }

  public set model(model: string) {
    this._model = model;
  }
}
