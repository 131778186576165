export enum BackgroundTaskAction {
  CREATE_ORDER,
  PROCESS_ORDER,
  TRANSLATE_ORDER,
  SEND_ORDER,
  QUEUE_ORDER,
  REMOVE_FILES,
  GENERATE_SCHEDULE_ZIP,
  CPS_QUERY_MACHINE_INFO,
  CPS_CREATE_AND_GET_QUALITIES,
  CPS_CREATE_AND_GET_DEFAULT_CREEL,
  CPS_GET_DESIGNS,
  CPS_CREATE_ORDERLINES,
  CPS_CREATE_SCHEDULE,
  CPS_VERIFY_SCHEDULE,
  CPS_CREATE_PRODUCTION_ORDER
}
