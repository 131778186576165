import {OverviewListColoredYarnSetStubFactory} from '@application/helper/textile-data/colored-yarn-set/overview-list-colored-yarn-set-stub-factory';
import {DrawingForArticle} from '@domain/article/drawing-for-article';
import {MachineQualityForArticle} from '@domain/article/machine-quality-for-article';
import {FixedShape} from '@domain/drawing-library/fixed-shape.enum';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';

export class OverviewListArticleStubFactory {
  public static getRandomArticleWithId(id: number): OverviewListArticle {
    return new OverviewListArticle(
      id,
      `Article_${id}`,
      new DrawingForArticle(767, '6core_20002720', FixedShape[FixedShape.RECTANGULAR], false),
      new MachineQualityForArticle(387, 'TH2038', 1),
      OverviewListColoredYarnSetStubFactory.getRandomColoredYarnSet()
    );
  }
}
