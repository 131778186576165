export const shade = [
  {name: 'aliceblue', hex: '#F0F8FF', red: 240, green: 248, blue: 255, hue: 208, saturation: 100, luminance: 97},
  {name: 'antiquewhite', hex: '#FAEBD7', red: 250, green: 235, blue: 215, hue: 34, saturation: 78, luminance: 91},
  {name: 'aqua', hex: '#00FFFF', red: 0, green: 255, blue: 255, hue: 180, saturation: 100, luminance: 50},
  {name: 'aquamarine', hex: '#7FFFD4', red: 127, green: 255, blue: 212, hue: 160, saturation: 100, luminance: 75},
  {name: 'azure', hex: '#F0FFFF', red: 240, green: 255, blue: 255, hue: 180, saturation: 100, luminance: 97},
  {name: 'beige', hex: '#F5F5DC', red: 245, green: 245, blue: 220, hue: 60, saturation: 56, luminance: 91},
  {name: 'bisque', hex: '#FFE4C4', red: 255, green: 228, blue: 196, hue: 33, saturation: 100, luminance: 88},
  {name: 'black', hex: '#000000', red: 0, green: 0, blue: 0, hue: 0, saturation: 0, luminance: 0},
  {name: 'blanchedalmond', hex: '#FFEBCD', red: 255, green: 235, blue: 205, hue: 36, saturation: 100, luminance: 90},
  {name: 'blue', hex: '#0000FF', red: 0, green: 0, blue: 255, hue: 240, saturation: 100, luminance: 50},
  {name: 'blueviolet', hex: '#8A2BE2', red: 138, green: 43, blue: 226, hue: 271, saturation: 76, luminance: 53},
  {name: 'brown', hex: '#A52A2A', red: 165, green: 42, blue: 42, hue: 0, saturation: 59, luminance: 41},
  {name: 'burlywood', hex: '#DEB887', red: 222, green: 184, blue: 135, hue: 34, saturation: 57, luminance: 70},
  {name: 'cadetblue', hex: '#5F9EA0', red: 95, green: 158, blue: 160, hue: 182, saturation: 25, luminance: 50},
  {name: 'chartreuse', hex: '#7FFF00', red: 127, green: 255, blue: 0, hue: 90, saturation: 100, luminance: 50},
  {name: 'chocolate', hex: '#D2691E', red: 210, green: 105, blue: 30, hue: 25, saturation: 75, luminance: 47},
  {name: 'coral', hex: '#FF7F50', red: 255, green: 127, blue: 80, hue: 16, saturation: 100, luminance: 66},
  {name: 'cornflowerblue', hex: '#6495ED', red: 100, green: 149, blue: 237, hue: 219, saturation: 79, luminance: 66},
  {name: 'cornsilk', hex: '#FFF8DC', red: 255, green: 248, blue: 220, hue: 48, saturation: 100, luminance: 93},
  {name: 'crimson', hex: '#DC143C', red: 220, green: 20, blue: 60, hue: 348, saturation: 83, luminance: 47},
  {name: 'cyan', hex: '#00FFFF', red: 0, green: 255, blue: 255, hue: 180, saturation: 100, luminance: 50},
  {name: 'darkblue', hex: '#00008B', red: 0, green: 0, blue: 139, hue: 240, saturation: 100, luminance: 27},
  {name: 'darkcyan', hex: '#008B8B', red: 0, green: 139, blue: 139, hue: 180, saturation: 100, luminance: 27},
  {name: 'darkgoldenrod', hex: '#B8860B', red: 184, green: 134, blue: 11, hue: 43, saturation: 89, luminance: 38},
  {name: 'darkgray', hex: '#A9A9A9', red: 169, green: 169, blue: 169, hue: 0, saturation: 0, luminance: 66},
  {name: 'darkgreen', hex: '#006400', red: 0, green: 100, blue: 0, hue: 120, saturation: 100, luminance: 20},
  {name: 'darkgrey', hex: '#A9A9A9', red: 169, green: 169, blue: 169, hue: 0, saturation: 0, luminance: 66},
  {name: 'darkkhaki', hex: '#BDB76B', red: 189, green: 183, blue: 107, hue: 56, saturation: 38, luminance: 58},
  {name: 'darkmagenta', hex: '#8B008B', red: 139, green: 0, blue: 139, hue: 300, saturation: 100, luminance: 27},
  {name: 'darkolivegreen', hex: '#556B2F', red: 85, green: 107, blue: 47, hue: 82, saturation: 39, luminance: 30},
  {name: 'darkorange', hex: '#FF8C00', red: 255, green: 140, blue: 0, hue: 33, saturation: 100, luminance: 50},
  {name: 'darkorchid', hex: '#9932CC', red: 153, green: 50, blue: 204, hue: 280, saturation: 61, luminance: 50},
  {name: 'darkred', hex: '#8B0000', red: 139, green: 0, blue: 0, hue: 0, saturation: 100, luminance: 27},
  {name: 'darksalmon', hex: '#E9967A', red: 233, green: 150, blue: 122, hue: 15, saturation: 72, luminance: 70},
  {name: 'darkseagreen', hex: '#8FBC8F', red: 143, green: 188, blue: 143, hue: 120, saturation: 25, luminance: 65},
  {name: 'darkslateblue', hex: '#483D8B', red: 72, green: 61, blue: 139, hue: 248, saturation: 39, luminance: 39},
  {name: 'darkslategray', hex: '#2F4F4F', red: 47, green: 79, blue: 79, hue: 180, saturation: 25, luminance: 25},
  {name: 'darkslategrey', hex: '#2F4F4F', red: 47, green: 79, blue: 79, hue: 180, saturation: 25, luminance: 25},
  {name: 'darkturquoise', hex: '#00CED1', red: 0, green: 206, blue: 209, hue: 181, saturation: 100, luminance: 41},
  {name: 'darkviolet', hex: '#9400D3', red: 148, green: 0, blue: 211, hue: 282, saturation: 100, luminance: 41},
  {name: 'deeppink', hex: '#FF1493', red: 255, green: 20, blue: 147, hue: 328, saturation: 100, luminance: 54},
  {name: 'deepskyblue', hex: '#00BFFF', red: 0, green: 191, blue: 255, hue: 195, saturation: 100, luminance: 50},
  {name: 'dimgray', hex: '#696969', red: 105, green: 105, blue: 105, hue: 0, saturation: 0, luminance: 41},
  {name: 'dimgrey', hex: '#696969', red: 105, green: 105, blue: 105, hue: 0, saturation: 0, luminance: 41},
  {name: 'dodgerblue', hex: '#1E90FF', red: 30, green: 144, blue: 255, hue: 210, saturation: 100, luminance: 56},
  {name: 'firebrick', hex: '#B22222', red: 178, green: 34, blue: 34, hue: 0, saturation: 68, luminance: 42},
  {name: 'floralwhite', hex: '#FFFAF0', red: 255, green: 250, blue: 240, hue: 40, saturation: 100, luminance: 97},
  {name: 'forestgreen', hex: '#228B22', red: 34, green: 139, blue: 34, hue: 120, saturation: 61, luminance: 34},
  {name: 'fuchsia', hex: '#FF00FF', red: 255, green: 0, blue: 255, hue: 300, saturation: 100, luminance: 50},
  {name: 'gainsboro', hex: '#DCDCDC', red: 220, green: 220, blue: 220, hue: 0, saturation: 0, luminance: 86},
  {name: 'ghostwhite', hex: '#F8F8FF', red: 248, green: 248, blue: 255, hue: 240, saturation: 100, luminance: 99},
  {name: 'gold', hex: '#FFD700', red: 255, green: 215, blue: 0, hue: 51, saturation: 100, luminance: 50},
  {name: 'goldenrod', hex: '#DAA520', red: 218, green: 165, blue: 32, hue: 43, saturation: 74, luminance: 49},
  {name: 'gray', hex: '#808080', red: 128, green: 128, blue: 128, hue: 0, saturation: 0, luminance: 50},
  {name: 'green', hex: '#008000', red: 0, green: 128, blue: 0, hue: 120, saturation: 100, luminance: 25},
  {name: 'greenyellow', hex: '#ADFF2F', red: 173, green: 255, blue: 47, hue: 84, saturation: 100, luminance: 59},
  {name: 'grey', hex: '#808080', red: 128, green: 128, blue: 128, hue: 0, saturation: 0, luminance: 50},
  {name: 'honeydew', hex: '#F0FFF0', red: 240, green: 255, blue: 240, hue: 120, saturation: 100, luminance: 97},
  {name: 'hotpink', hex: '#FF69B4', red: 255, green: 105, blue: 180, hue: 330, saturation: 100, luminance: 71},
  {name: 'indianred', hex: '#CD5C5C', red: 205, green: 92, blue: 92, hue: 0, saturation: 53, luminance: 58},
  {name: 'indigo', hex: '#4B0082', red: 75, green: 0, blue: 130, hue: 275, saturation: 100, luminance: 25},
  {name: 'ivory', hex: '#FFFFF0', red: 255, green: 255, blue: 240, hue: 60, saturation: 100, luminance: 97},
  {name: 'khaki', hex: '#F0E68C', red: 240, green: 230, blue: 140, hue: 54, saturation: 77, luminance: 75},
  {name: 'lavender', hex: '#E6E6FA', red: 230, green: 230, blue: 250, hue: 240, saturation: 67, luminance: 94},
  {name: 'lavenderblush', hex: '#FFF0F5', red: 255, green: 240, blue: 245, hue: 340, saturation: 100, luminance: 97},
  {name: 'lawngreen', hex: '#7CFC00', red: 124, green: 252, blue: 0, hue: 90, saturation: 100, luminance: 49},
  {name: 'lemonchiffon', hex: '#FFFACD', red: 255, green: 250, blue: 205, hue: 54, saturation: 100, luminance: 90},
  {name: 'lightblue', hex: '#ADD8E6', red: 173, green: 216, blue: 230, hue: 195, saturation: 53, luminance: 79},
  {name: 'lightcoral', hex: '#F08080', red: 240, green: 128, blue: 128, hue: 0, saturation: 79, luminance: 72},
  {name: 'lightcyan', hex: '#E0FFFF', red: 224, green: 255, blue: 255, hue: 180, saturation: 100, luminance: 94},
  {name: 'lightgoldenrodyellow', hex: '#FAFAD2', red: 250, green: 250, blue: 210, hue: 60, saturation: 80, luminance: 90},
  {name: 'lightgray', hex: '#D3D3D3', red: 211, green: 211, blue: 211, hue: 0, saturation: 0, luminance: 83},
  {name: 'lightgreen', hex: '#90EE90', red: 144, green: 238, blue: 144, hue: 120, saturation: 73, luminance: 75},
  {name: 'lightgrey', hex: '#D3D3D3', red: 211, green: 211, blue: 211, hue: 0, saturation: 0, luminance: 83},
  {name: 'lightpink', hex: '#FFB6C1', red: 255, green: 182, blue: 193, hue: 351, saturation: 100, luminance: 86},
  {name: 'lightsalmon', hex: '#FFA07A', red: 255, green: 160, blue: 122, hue: 17, saturation: 100, luminance: 74},
  {name: 'lightseagreen', hex: '#20B2AA', red: 32, green: 178, blue: 170, hue: 177, saturation: 70, luminance: 41},
  {name: 'lightskyblue', hex: '#87CEFA', red: 135, green: 206, blue: 250, hue: 203, saturation: 92, luminance: 75},
  {name: 'lightslategray', hex: '#778899', red: 119, green: 136, blue: 153, hue: 210, saturation: 14, luminance: 53},
  {name: 'lightslategrey', hex: '#778899', red: 119, green: 136, blue: 153, hue: 210, saturation: 14, luminance: 53},
  {name: 'lightsteelblue', hex: '#B0C4DE', red: 176, green: 196, blue: 222, hue: 214, saturation: 41, luminance: 78},
  {name: 'lightyellow', hex: '#FFFFE0', red: 255, green: 255, blue: 224, hue: 60, saturation: 100, luminance: 94},
  {name: 'lime', hex: '#00FF00', red: 0, green: 255, blue: 0, hue: 120, saturation: 100, luminance: 50},
  {name: 'limegreen', hex: '#32CD32', red: 50, green: 205, blue: 50, hue: 120, saturation: 61, luminance: 50},
  {name: 'linen', hex: '#FAF0E6', red: 250, green: 240, blue: 230, hue: 30, saturation: 67, luminance: 94},
  {name: 'magenta', hex: '#FF00FF', red: 255, green: 0, blue: 255, hue: 300, saturation: 100, luminance: 50},
  {name: 'maroon', hex: '#800000', red: 128, green: 0, blue: 0, hue: 0, saturation: 100, luminance: 25},
  {name: 'mediumaquamarine', hex: '#66CDAA', red: 102, green: 205, blue: 170, hue: 160, saturation: 51, luminance: 60},
  {name: 'mediumblue', hex: '#0000CD', red: 0, green: 0, blue: 205, hue: 240, saturation: 100, luminance: 40},
  {name: 'mediumorchid', hex: '#BA55D3', red: 186, green: 85, blue: 211, hue: 288, saturation: 59, luminance: 58},
  {name: 'mediumpurple', hex: '#9370DB', red: 147, green: 112, blue: 219, hue: 260, saturation: 60, luminance: 65},
  {name: 'mediumseagreen', hex: '#3CB371', red: 60, green: 179, blue: 113, hue: 147, saturation: 50, luminance: 47},
  {name: 'mediumslateblue', hex: '#7B68EE', red: 123, green: 104, blue: 238, hue: 249, saturation: 80, luminance: 67},
  {name: 'mediumspringgreen', hex: '#00FA9A', red: 0, green: 250, blue: 154, hue: 157, saturation: 100, luminance: 49},
  {name: 'mediumturquoise', hex: '#48D1CC', red: 72, green: 209, blue: 204, hue: 178, saturation: 60, luminance: 55},
  {name: 'mediumvioletred', hex: '#C71585', red: 199, green: 21, blue: 133, hue: 322, saturation: 81, luminance: 43},
  {name: 'midnightblue', hex: '#191970', red: 25, green: 25, blue: 112, hue: 240, saturation: 64, luminance: 27},
  {name: 'mintcream', hex: '#F5FFFA', red: 245, green: 255, blue: 250, hue: 150, saturation: 100, luminance: 98},
  {name: 'mistyrose', hex: '#FFE4E1', red: 255, green: 228, blue: 225, hue: 6, saturation: 100, luminance: 94},
  {name: 'moccasin', hex: '#FFE4B5', red: 255, green: 228, blue: 181, hue: 38, saturation: 100, luminance: 85},
  {name: 'navajowhite', hex: '#FFDEAD', red: 255, green: 222, blue: 173, hue: 36, saturation: 100, luminance: 84},
  {name: 'navy', hex: '#000080', red: 0, green: 0, blue: 128, hue: 240, saturation: 100, luminance: 25},
  {name: 'oldlace', hex: '#FDF5E6', red: 253, green: 245, blue: 230, hue: 39, saturation: 85, luminance: 95},
  {name: 'olive', hex: '#808000', red: 128, green: 128, blue: 0, hue: 60, saturation: 100, luminance: 25},
  {name: 'olivedrab', hex: '#6B8E23', red: 107, green: 142, blue: 35, hue: 80, saturation: 60, luminance: 35},
  {name: 'orange', hex: '#FFA500', red: 255, green: 165, blue: 0, hue: 39, saturation: 100, luminance: 50},
  {name: 'orangered', hex: '#FF4500', red: 255, green: 69, blue: 0, hue: 16, saturation: 100, luminance: 50},
  {name: 'orchid', hex: '#DA70D6', red: 218, green: 112, blue: 214, hue: 302, saturation: 59, luminance: 65},
  {name: 'palegoldenrod', hex: '#EEE8AA', red: 238, green: 232, blue: 170, hue: 55, saturation: 67, luminance: 80},
  {name: 'palegreen', hex: '#98FB98', red: 152, green: 251, blue: 152, hue: 120, saturation: 93, luminance: 79},
  {name: 'paleturquoise', hex: '#AFEEEE', red: 175, green: 238, blue: 238, hue: 180, saturation: 65, luminance: 81},
  {name: 'palevioletred', hex: '#DB7093', red: 219, green: 112, blue: 147, hue: 340, saturation: 60, luminance: 65},
  {name: 'papayawhip', hex: '#FFEFD5', red: 255, green: 239, blue: 213, hue: 37, saturation: 100, luminance: 92},
  {name: 'peachpuff', hex: '#FFDAB9', red: 255, green: 218, blue: 185, hue: 28, saturation: 100, luminance: 86},
  {name: 'peru', hex: '#CD853F', red: 205, green: 133, blue: 63, hue: 30, saturation: 59, luminance: 53},
  {name: 'pink', hex: '#FFC0CB', red: 255, green: 192, blue: 203, hue: 350, saturation: 100, luminance: 88},
  {name: 'plum', hex: '#DDA0DD', red: 221, green: 160, blue: 221, hue: 300, saturation: 47, luminance: 75},
  {name: 'powderblue', hex: '#B0E0E6', red: 176, green: 224, blue: 230, hue: 187, saturation: 52, luminance: 80},
  {name: 'purple', hex: '#800080', red: 128, green: 0, blue: 128, hue: 300, saturation: 100, luminance: 25},
  {name: 'rebeccapurple', hex: '#663399', red: 102, green: 51, blue: 153, hue: 270, saturation: 50, luminance: 40},
  {name: 'red', hex: '#FF0000', red: 255, green: 0, blue: 0, hue: 0, saturation: 100, luminance: 50},
  {name: 'rosybrown', hex: '#BC8F8F', red: 188, green: 143, blue: 143, hue: 0, saturation: 25, luminance: 65},
  {name: 'royalblue', hex: '#4169E1', red: 65, green: 105, blue: 225, hue: 225, saturation: 73, luminance: 57},
  {name: 'saddlebrown', hex: '#8B4513', red: 139, green: 69, blue: 19, hue: 25, saturation: 76, luminance: 31},
  {name: 'salmon', hex: '#FA8072', red: 250, green: 128, blue: 114, hue: 6, saturation: 93, luminance: 71},
  {name: 'sandybrown', hex: '#F4A460', red: 244, green: 164, blue: 96, hue: 28, saturation: 87, luminance: 67},
  {name: 'seagreen', hex: '#2E8B57', red: 46, green: 139, blue: 87, hue: 146, saturation: 50, luminance: 36},
  {name: 'seashell', hex: '#FFF5EE', red: 255, green: 245, blue: 238, hue: 25, saturation: 100, luminance: 97},
  {name: 'sienna', hex: '#A0522D', red: 160, green: 82, blue: 45, hue: 19, saturation: 56, luminance: 40},
  {name: 'silver', hex: '#C0C0C0', red: 192, green: 192, blue: 192, hue: 0, saturation: 0, luminance: 75},
  {name: 'skyblue', hex: '#87CEEB', red: 135, green: 206, blue: 235, hue: 197, saturation: 71, luminance: 73},
  {name: 'slateblue', hex: '#6A5ACD', red: 106, green: 90, blue: 205, hue: 248, saturation: 53, luminance: 58},
  {name: 'slategray', hex: '#708090', red: 112, green: 128, blue: 144, hue: 210, saturation: 13, luminance: 50},
  {name: 'slategrey', hex: '#708090', red: 112, green: 128, blue: 144, hue: 210, saturation: 13, luminance: 50},
  {name: 'snow', hex: '#FFFAFA', red: 255, green: 250, blue: 250, hue: 0, saturation: 100, luminance: 99},
  {name: 'springgreen', hex: '#00FF7F', red: 0, green: 255, blue: 127, hue: 150, saturation: 100, luminance: 50},
  {name: 'steelblue', hex: '#4682B4', red: 70, green: 130, blue: 180, hue: 207, saturation: 44, luminance: 49},
  {name: 'tan', hex: '#D2B48C', red: 210, green: 180, blue: 140, hue: 34, saturation: 44, luminance: 69},
  {name: 'teal', hex: '#008080', red: 0, green: 128, blue: 128, hue: 180, saturation: 100, luminance: 25},
  {name: 'thistle', hex: '#D8BFD8', red: 216, green: 191, blue: 216, hue: 300, saturation: 24, luminance: 80},
  {name: 'tomato', hex: '#FF6347', red: 255, green: 99, blue: 71, hue: 9, saturation: 100, luminance: 64},
  {name: 'turquoise', hex: '#40E0D0', red: 64, green: 224, blue: 208, hue: 174, saturation: 72, luminance: 56},
  {name: 'violet', hex: '#EE82EE', red: 238, green: 130, blue: 238, hue: 300, saturation: 76, luminance: 72},
  {name: 'wheat', hex: '#F5DEB3', red: 245, green: 222, blue: 179, hue: 39, saturation: 77, luminance: 83},
  {name: 'white', hex: '#FFFFFF', red: 255, green: 255, blue: 255, hue: 0, saturation: 0, luminance: 100},
  {name: 'whitesmoke', hex: '#F5F5F5', red: 245, green: 245, blue: 245, hue: 0, saturation: 0, luminance: 96},
  {name: 'yellow', hex: '#FFFF00', red: 255, green: 255, blue: 0, hue: 60, saturation: 100, luminance: 50},
  {name: 'yellowgreen', hex: '#9ACD32', red: 154, green: 205, blue: 50, hue: 80, saturation: 61, luminance: 5}
];
