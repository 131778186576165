import {isEqual} from 'lodash-es';
import {shade} from '../data/shade';
import {ColorHSLRepresentation} from '../interfaces/color-hsl-representation';
import {ColorHSVRepresentation} from '../interfaces/color-hsv-representation';
import {ColorRGBRepresentation} from '../interfaces/color-rgb-representation';
import {ColorRGBARepresentation} from './../interfaces/color-rgba-representation';

export function convertHSLToRGB(hslRepresentation: ColorHSLRepresentation): ColorRGBRepresentation {
  let red: number;
  let green: number;
  let blue: number;

  const hue: number = hslRepresentation.hue / 360;
  const saturation: number = hslRepresentation.saturation / 100;
  const luminance: number = hslRepresentation.luminance / 100;

  if (isEqual(saturation, 0)) {
    red = green = blue = luminance;
  } else {
    const temporaryValue1: number = luminance < 0.5 ? luminance * (1 + saturation) : luminance + saturation - luminance * saturation;
    const temporaryValue2: number = 2 * luminance - temporaryValue1;

    red = hslHueToRGB(temporaryValue1, temporaryValue2, hue + 1 / 3);
    green = hslHueToRGB(temporaryValue1, temporaryValue2, hue);
    blue = hslHueToRGB(temporaryValue1, temporaryValue2, hue - 1 / 3);
  }

  return {red: red * 255, green: green * 255, blue: blue * 255};
}

export function convertHSVToRGB(hsvRepresentation: ColorHSVRepresentation): ColorRGBRepresentation {
  let red: number;
  let green: number;
  let blue: number;

  const hue: number = hsvRepresentation.hue / 360;
  const saturation: number = hsvRepresentation.saturation / 100;
  const value: number = hsvRepresentation.value / 100;

  const temporaryValue1: number = Math.floor(hue * 6);
  const temporaryValue2: number = hue * 6 - temporaryValue1;
  const possibleValue1: number = value * (1 - saturation);
  const possibleValue2: number = value * (1 - temporaryValue2 * saturation);
  const possibleValue3: number = value * (1 - (1 - temporaryValue2) * saturation);

  switch (temporaryValue1 % 6) {
    case 0:
      red = value;
      green = possibleValue3;
      blue = possibleValue1;
      break;
    case 1:
      red = possibleValue2;
      green = value;
      blue = possibleValue1;
      break;
    case 2:
      red = possibleValue1;
      green = value;
      blue = possibleValue3;
      break;
    case 3:
      red = possibleValue1;
      green = possibleValue2;
      blue = value;
      break;
    case 4:
      red = possibleValue3;
      green = possibleValue1;
      blue = value;
      break;
    case 5:
      red = value;
      green = possibleValue1;
      blue = possibleValue2;
      break;
  }

  return {red: red * 255, green: green * 255, blue: blue * 255};
}

export function convertRGBToHexadecimalColorCode(rgbRepresentation: ColorRGBRepresentation): string {
  const hexadecimalRed: string = decimalToHexadecimal(Math.round(rgbRepresentation.red));
  const hexadecimalGreen: string = decimalToHexadecimal(Math.round(rgbRepresentation.green));
  const hexadecimalBlue: string = decimalToHexadecimal(Math.round(rgbRepresentation.blue));
  return `#${hexadecimalRed}${hexadecimalGreen}${hexadecimalBlue}`;
}

export function convertRGBAToHexadecimalColorCode(rgbaRepresentation: ColorRGBARepresentation): string {
  return convertRGBToHexadecimalColorCode(rgbaRepresentation) + `${decimalToHexadecimal(rgbaRepresentation.alpha)}`;
}

export function convertRGBToHSL(rgbRepresentation: ColorRGBRepresentation): ColorHSLRepresentation {
  const red: number = rgbRepresentation.red / 255;
  const green: number = rgbRepresentation.green / 255;
  const blue: number = rgbRepresentation.blue / 255;

  const max: number = Math.max(red, green, blue);
  const min: number = Math.min(red, green, blue);
  const maxMinDifference: number = max - min;
  const luminance: number = (max + min) / 2;
  let saturation: number;

  if (isEqual(max, min)) {
    saturation = 0;
  } else if (luminance > 0.5) {
    saturation = maxMinDifference / (2 - max - min);
  } else {
    saturation = maxMinDifference / (max + min);
  }

  return {hue: convertRGBToHue(red, green, blue) * 360, saturation: saturation * 100, luminance: luminance * 100};
}

export function convertRGBToHSV(rgbRepresentation: ColorRGBRepresentation): ColorHSVRepresentation {
  const red: number = rgbRepresentation.red / 255;
  const green: number = rgbRepresentation.green / 255;
  const blue: number = rgbRepresentation.blue / 255;

  const max: number = Math.max(red, green, blue);
  const min: number = Math.min(red, green, blue);
  const maxMinDifference: number = max - min;
  let saturation: number;
  if (isEqual(max, 0)) {
    saturation = 0;
  } else {
    saturation = maxMinDifference / max;
  }

  return {hue: convertRGBToHue(red, green, blue) * 360, saturation: saturation * 100, value: max * 100};
}

export function convertRGBToRGBA(rgbRepresentation: ColorRGBRepresentation, alpha: number): ColorRGBARepresentation {
  return {red: rgbRepresentation.red, blue: rgbRepresentation.blue, green: rgbRepresentation.green, alpha: Math.round(alpha * 255)};
}

export function convertHexadecimalColorCodeToRGB(hex: string): ColorRGBRepresentation {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16)
  };
}

export function addAlphaToHexRGB(rgbHex: string, alpha: number): string {
  const rgb = convertHexadecimalColorCodeToRGB(rgbHex);
  const rgba = convertRGBToRGBA(rgb, alpha);
  return convertRGBAToHexadecimalColorCode(rgba);
}

export function convertRGBToShadeName(red: number, green: number, blue: number): string {
  const colorRGBRepresentation: ColorRGBRepresentation = {red, green, blue};
  const hsl = convertRGBToHSL(colorRGBRepresentation);
  const hex = convertRGBToHexadecimalColorCode(colorRGBRepresentation);

  let rgbDistance = 0;
  let hslDistance = 0;
  let totalDistance = 0;
  let smallIndex = -1;
  let smallestDistance = -1;

  for (let i = 0; i < shade.length; i++) {
    if (hex === shade[i].hex) {
      return shade[i].name;
    }

    rgbDistance = Math.pow(colorRGBRepresentation.red - shade[i].red, 2) + Math.pow(colorRGBRepresentation.green - shade[i].green, 2) + Math.pow(colorRGBRepresentation.blue - shade[i].blue, 2);
    hslDistance = Math.pow(hsl.hue - shade[i].hue, 2) + Math.pow(hsl.saturation - shade[i].saturation, 2) + Math.pow(hsl.luminance - shade[i].luminance, 2);
    totalDistance = rgbDistance + hslDistance * 2;
    if (smallestDistance < 0 || smallestDistance > totalDistance) {
      smallestDistance = totalDistance;
      smallIndex = i;
    }
  }

  return shade[smallIndex].name;
}

function decimalToHexadecimal(decimal: number): string {
  return decimal.toString(16).padStart(2, '0');
}

function hslHueToRGB(temporaryValue1: number, temporaryValue2: number, hueWithOffset: number): number {
  if (hueWithOffset < 0) {
    hueWithOffset += 1;
  }
  if (hueWithOffset > 1) {
    hueWithOffset -= 1;
  }
  let result: number;
  if (hueWithOffset < 1 / 6) {
    result = temporaryValue2 + (temporaryValue1 - temporaryValue2) * 6 * hueWithOffset;
  } else if (hueWithOffset < 1 / 2) {
    result = temporaryValue1;
  } else if (hueWithOffset < 2 / 3) {
    result = temporaryValue2 + (temporaryValue1 - temporaryValue2) * (2 / 3 - hueWithOffset) * 6;
  } else {
    result = temporaryValue2;
  }
  return result;
}

function convertRGBToHue(red: number, green: number, blue: number): number {
  const max: number = Math.max(red, green, blue);
  const min: number = Math.min(red, green, blue);
  let hue: number;
  if (isEqual(max, min)) {
    hue = 0;
  } else {
    const maxMinDifference: number = max - min;
    switch (max) {
      case red:
        hue = (green - blue) / maxMinDifference + (green < blue ? 6 : 0);
        break;
      case green:
        hue = (blue - red) / maxMinDifference + 2;
        break;
      case blue:
        hue = (red - green) / maxMinDifference + 4;
        break;
    }
    hue /= 6;
  }
  return hue;
}
