import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {AutoFocusModule} from '../../custom-directives/auto-focus/auto-focus.module';
import {TranslationModule} from '../../translation/translation.module';
import {FilterComponent} from './filter.component';
import {MobileFilterComponent} from './mobile-filter/mobile-filter.component';

@NgModule({
  declarations: [FilterComponent, MobileFilterComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, TranslationModule, AutoFocusModule],
  exports: [FilterComponent, MobileFilterComponent]
})
export class FilterModule {}
