import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslationModule} from '../../../translation/translation.module';
import {LabelModule} from '../../ellipsis-label/label.module';
import {SelectColorsComponent} from './select-colors.component';

@NgModule({
  imports: [CommonModule, MatSelectModule, TranslationModule, ReactiveFormsModule, LabelModule, FormsModule, MatInputModule],
  exports: [SelectColorsComponent],
  declarations: [SelectColorsComponent]
})
export class SelectColorsModule {}
