import {Injectable} from '@angular/core';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {
  ColDefBuilderFactoryService,
  GridOptionsBuilder,
  LinkIconRendererComponent,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  StringUtils,
  TranslateService
} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ValueGetterParams} from 'ag-grid-community';
import {GridColorPreviewComponent} from '../../color/overview/grid-color-preview/grid-color-preview.component';

@Injectable()
export class PlasticProductOverviewService {
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('plasticProduct');

  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(withLinkIcon: boolean = true): ColDef[] {
    let nameColDef = this.colDefBuilderFactoryService.getBuilder().withHeaderName('GENERAL.NAME').withColIdAndField('name', true).withComparator(StringUtils.stringComparator);

    if (withLinkIcon) {
      nameColDef = nameColDef.withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
        return {
          params,
          editPageUrl: this.pageUrls.edit
        };
      });
    }

    return [
      nameColDef.build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('color')
        .withHeaderName('TEXTILE_DATA.COLOR.COLOR')
        .withValueGetter('data.color')
        .withCellRenderer(GridColorPreviewComponent)
        .withFilterValueGetter((params: ValueGetterParams) => params.data.color?.name)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT_GROUP')
        .withColId('productGroup')
        .withField('productGroup.name', true)
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('unitOfMeasurementId')
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.UNIT_OF_MEASUREMENT')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.${params.data.unitOfMeasurement}`), true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('GENERAL.WEIGHT').withColIdAndField('weightInKg', true).withCellClass('right').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL')
        .withColId('material')
        .withField('material.name', true)
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('CUSTOMERS.CUSTOMER').withColId('customer').withField('customer.name', true).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('MACHINE.DETAILS.STANDARD_EFFICIENCY').withColIdAndField('standardEfficiency', true).withCellClass('right').build(),
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('UTILITIES.TOOL.SPEED').withColIdAndField('standardCycleTimeInSeconds', true).withCellClass('right').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('UTILITIES.TOOL.SPEED_UNIT.SPEED_UNIT')
        .withColId('speedUnitId')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`UTILITIES.TOOL.SPEED_UNIT.${params.data.speedUnit}`), true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('GENERAL.DESCRIPTION').withColIdAndField('description', true).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.MOUNT_TIME', 1, false, 'GENERAL.UNIT.MINUTE')
        .withColIdAndField('mountTimeInMinutes', true)
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.DISMOUNT_TIME', 1, false, 'GENERAL.UNIT.MINUTE')
        .withColIdAndField('dismountTimeInMinutes', true)
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.LOAD_TIME', 1, false, 'GENERAL.UNIT.MINUTE')
        .withColIdAndField('loadTimeInMinutes', true)
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.UNLOAD_TIME', 1, false, 'GENERAL.UNIT.MINUTE')
        .withColIdAndField('unloadTimeInMinutes', true)
        .withCellClass('right')
        .build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT', {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
