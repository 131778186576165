export class TuftLengthException {
  private _id: number;
  private _name: string;
  private _tuftLengthInMM: number;

  public constructor(id: number, name: string, tuftLengthInMM: number) {
    this._id = id;
    this._name = name;
    this._tuftLengthInMM = tuftLengthInMM;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get tuftLengthInMM(): number {
    return this._tuftLengthInMM;
  }

  public static fromJSON(tuftLengthExceptionJSON: any): TuftLengthException {
    return new TuftLengthException(tuftLengthExceptionJSON.id, tuftLengthExceptionJSON.name, tuftLengthExceptionJSON.tuftLengthInMM);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      tuftLengthInMM: this._tuftLengthInMM
    } as any as JSON;
  }
}
