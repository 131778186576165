import {LongFloatCorrectionRule} from './long-float-correction-rule';

export class PostProcessing {
  private _longFloatCorrectionRules: LongFloatCorrectionRule[];

  public constructor(longFloatCorrectionRules: LongFloatCorrectionRule[]) {
    this._longFloatCorrectionRules = longFloatCorrectionRules;
  }

  public get longFloatCorrectionRules(): LongFloatCorrectionRule[] {
    return this._longFloatCorrectionRules;
  }

  public static fromJSON(postProcessingJSON: any): PostProcessing {
    return new PostProcessing(
      postProcessingJSON?.longFloatCorrectionRules.map((longFloatCorrectionRuleJSON: any) => {
        return LongFloatCorrectionRule.fromJSON(longFloatCorrectionRuleJSON);
      })
    );
  }

  public toJSON(): JSON {
    return {
      longFloatCorrectionRules: this.longFloatCorrectionRules.map((longFloatCorrectionRule: LongFloatCorrectionRule) => {
        return longFloatCorrectionRule.toJSON();
      })
    } as any as JSON;
  }
}
