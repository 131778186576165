import {DimensionsInPx} from '@domain/dimensions-in-px';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {isNil, map} from 'lodash-es';
import {DrawingConfiguration} from './drawing-configuration';
import {DrawingConfigurationWithColorAndYarnSet} from './drawing-configuration-with-color-and-yarn-set';
import {DrawingImage} from './drawing-image';
import {MappingForDesign} from './mapping-for-design';

export abstract class Drawing {
  private _id: number;
  private _name: string;
  private _drawingType: DrawingType;
  private _image: DrawingImage;
  private _dimensionsInPixels: DimensionsInPx;
  private _shape: string;
  private _shapeId: number;
  private _code: string;
  private _configurations: DrawingConfiguration[];
  private _mappingForDesign: MappingForDesign[];
  private _used: boolean;
  private _threadByThread: boolean;
  private _repeated: boolean;
  private _nrColumnsForWeftSelection: number;
  private _sizeInKB: number;
  private _dateModified: Date;
  private _missingDesign: boolean;

  protected constructor(
    id: number,
    name: string,
    drawingType: DrawingType,
    image: DrawingImage,
    dimensionsInPixels: DimensionsInPx,
    shape: string,
    used: boolean,
    repeated: boolean,
    mappingForDesign: MappingForDesign[] = [],
    code?: string,
    configurations: DrawingConfiguration[] = [],
    threadByThread: boolean = false,
    nrColumnsForWeftSelection?: number,
    sizeInKB?: number,
    dateModified?: Date,
    missingDesign?: boolean,
    shapeId?: number
  ) {
    this._id = id;
    this._name = name;
    this._drawingType = drawingType;
    this._image = image;
    this._dimensionsInPixels = dimensionsInPixels;
    this._shape = shape;
    this._code = code;
    this._configurations = configurations;
    this._mappingForDesign = mappingForDesign;
    this._image = image;
    this._used = used;
    this._threadByThread = threadByThread;
    this._repeated = repeated;
    this._nrColumnsForWeftSelection = nrColumnsForWeftSelection ?? 0;
    this._sizeInKB = sizeInKB ?? 0;
    this._dateModified = dateModified;
    this._missingDesign = missingDesign;
    this._shapeId = shapeId;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get drawingType(): DrawingType {
    return this._drawingType;
  }

  public get image(): DrawingImage {
    return this._image;
  }

  public set image(image: DrawingImage) {
    this._image = image;
  }

  public get dimensionsInPixels(): DimensionsInPx {
    return this._dimensionsInPixels;
  }

  public set dimensionsInPixels(dimensionsInPixels: DimensionsInPx) {
    this._dimensionsInPixels = dimensionsInPixels;
  }

  public get shape(): string {
    return this._shape;
  }

  public set shape(shape: string) {
    this._shape = shape;
  }

  public get shapeId(): number {
    return this._shapeId;
  }

  public set shapeId(shapeId: number) {
    this._shapeId = shapeId;
  }

  public get configurations(): DrawingConfiguration[] {
    return this._configurations;
  }

  public set configurations(drawingConfigurations: DrawingConfiguration[]) {
    this._configurations = drawingConfigurations;
  }

  public get code(): string {
    return this._code;
  }

  public set code(code: string) {
    this._code = code;
  }

  public get mappingForDesign(): MappingForDesign[] {
    return this._mappingForDesign;
  }

  public set mappingForDesign(mappingForDesign: MappingForDesign[]) {
    this._mappingForDesign = mappingForDesign;
  }

  public get used(): boolean {
    return this._used;
  }

  public set used(used: boolean) {
    this._used = used;
  }

  public get threadByThread(): boolean {
    return this._threadByThread;
  }

  public get repeated(): boolean {
    return this._repeated;
  }

  public set repeated(repeated: boolean) {
    this._repeated = repeated;
  }

  public get nrColumnsForWeftSelection(): number {
    return this._nrColumnsForWeftSelection;
  }

  public get sizeInKB(): number {
    return this._sizeInKB;
  }

  public get dateModified(): Date {
    return this._dateModified;
  }

  public get missingDesign(): boolean {
    return this._missingDesign;
  }

  public set missingDesign(missingDesign: boolean) {
    this._missingDesign = missingDesign;
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      imageId: this.image.id,
      dimensionsInPx: {
        widthInPx: this.dimensionsInPixels.widthInPx,
        heightInPx: this.dimensionsInPixels.heightInPx
      },
      shape: this.shape,
      shapeId: this.shapeId,
      threadByThread: this.threadByThread,
      drawingCode: this.code,
      drawingType: `${DrawingType[this.drawingType]}`,
      configurations: map(this.configurations, (configuration: DrawingConfigurationWithColorAndYarnSet) => configuration.toJSON()),
      mappingForDesign: map(this.mappingForDesign, (mappingForDesign: MappingForDesign) => mappingForDesign.toJSON()),
      repeated: this.repeated,
      nrColumnsForWeftSelection: this.nrColumnsForWeftSelection
    } as any as JSON;
  }

  public toFullJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      image: !isNil(this.image)
        ? {
            id: this.image.id
          }
        : null,
      dimensionsInPx: {
        widthInPx: this.dimensionsInPixels.widthInPx,
        heightInPx: this.dimensionsInPixels.heightInPx
      },
      shape: this.shape,
      shapeId: this.shapeId,
      threadByThread: this.threadByThread,
      drawingCode: this.code,
      drawingType: `${DrawingType[this.drawingType]}`,
      configurations: map(this.configurations, (configuration: DrawingConfigurationWithColorAndYarnSet) => configuration.toJSON()),
      mappingForDesign: map(this.mappingForDesign, (mappingForDesign: MappingForDesign) => mappingForDesign.toJSON()),
      repeated: this.repeated,
      missingDesign: this.missingDesign,
      nrColumnsForWeftSelection: this.nrColumnsForWeftSelection
    } as any as JSON;
  }
}
