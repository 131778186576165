import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Color} from '@vdw/angular-component-library';
import {isUndefined} from 'lodash-es';

@Component({
  selector: 'app-color-grid-preview',
  templateUrl: './color-grid-preview.component.html',
  styleUrls: ['./color-grid-preview.component.scss']
})
export class ColorGridPreviewComponent implements AfterViewChecked {
  @Input() public colors: Color[];
  @Input() public showRgbValues = true;
  @Input() public showLabel = true;
  @Input() public tooltipDisabled = false;
  @ViewChild('name') public name: ElementRef;
  public showTooltip = false;
  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }

  public trackByColorId = (index: number, color: Color): number => color?.id;

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public getTooltipForColor(color: Color): string {
    return this.showRgbValues ? color?.name : color?.getRgbAsCommaSeparatedValue();
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.name) && this.name?.nativeElement.offsetWidth < this.name?.nativeElement.scrollWidth;
  }
}
