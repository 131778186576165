import {FixedShape} from '@domain/drawing-library/fixed-shape.enum';
import {MachineEfficiencyLevel} from '@domain/machine/machine-efficiency-level.enum';
import {MachineStatus} from '@domain/machine/machine-status.enum';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PropertyValue} from '@domain/property-value';
import {AdvancedSearchBoolInput} from '@presentation/components/search-filters/advanced-search/advanced-search-bool-input.enum';
import {AdvancedSearchDateInput} from '@presentation/components/search-filters/advanced-search/advanced-search-date-input.enum';
import {AdvancedSearchEnumInput} from '@presentation/components/search-filters/advanced-search/advanced-search-enum-input.enum';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {AdvancedSearchNumberInput} from '@presentation/components/search-filters/advanced-search/advanced-search-number-input.enum';
import {AdvancedSearchObjectInput} from '@presentation/components/search-filters/advanced-search/advanced-search-object-input.enum';
import {AdvancedSearchStringInput} from '@presentation/components/search-filters/advanced-search/advanced-search-string-input.enum';
import {convertCommercialUnit, convertUnit, EnumUtils, moment, Priority, TranslateService, Unit} from '@vdw/angular-component-library';
import {camelCase, includes, isEmpty, isEqual, isNil, toNumber} from 'lodash-es';

export enum AdvancedSearchInputType {
  STRING,
  NUMBER,
  ENUM,
  OBJECT,
  DATE,
  BOOL
}

export class AdvancedSearchUtils {
  private static inputTypeMap = new Map<AdvancedSearchInputType, any[]>([
    [AdvancedSearchInputType.STRING, EnumUtils.getEnumValues(AdvancedSearchStringInput)],
    [AdvancedSearchInputType.NUMBER, EnumUtils.getEnumValues(AdvancedSearchNumberInput)],
    [AdvancedSearchInputType.OBJECT, EnumUtils.getEnumValues(AdvancedSearchObjectInput)],
    [AdvancedSearchInputType.ENUM, EnumUtils.getEnumValues(AdvancedSearchEnumInput)],
    [AdvancedSearchInputType.DATE, EnumUtils.getEnumValues(AdvancedSearchDateInput)],
    [AdvancedSearchInputType.BOOL, EnumUtils.getEnumValues(AdvancedSearchBoolInput)]
  ]);

  private static jsonKeyMap = new Map<string, string>([
    [AdvancedSearchInput.DRAWING_CODE, 'drawingCode'],
    [AdvancedSearchInput.LENGTH, 'commercialDimensions'],
    [AdvancedSearchInput.WIDTH, 'commercialDimensions'],
    [AdvancedSearchInput.ORDER_REFERENCE, 'orderReference'],
    [AdvancedSearchInput.CUSTOMER_REFERENCE, 'customerReference'],
    [AdvancedSearchInput.DRAWING_TYPE, 'drawingType'],
    [AdvancedSearchInput.MACHINE_STATUS, 'machineStatus'],
    [AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS, 'status'],
    [AdvancedSearchInput.MACHINE_EFFICIENCY, 'machineEfficiency'],
    [AdvancedSearchInput.DRAWING_NAME, 'designName'],
    [AdvancedSearchInput.PICK_DENSITY, 'pickDensity'],
    [AdvancedSearchInput.REED_DENSITY, 'reedDensity'],
    [AdvancedSearchInput.COMPLETION_DATE_START, 'completionDate'],
    [AdvancedSearchInput.COMPLETION_DATE_END, 'completionDate'],
    [AdvancedSearchInput.WIDTH_IN_DENTS, 'technicalDimensions'],
    [AdvancedSearchInput.HEIGHT_IN_PICKS, 'technicalDimensions'],
    [AdvancedSearchInput.MISSING_DESIGN, 'missingDesign']
  ]);

  public static clearValue(propertyValue: PropertyValue): void {
    propertyValue.propertyValue = null;
  }

  public static hasValue(propertyValue: PropertyValue): boolean {
    let result = false;
    switch (AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName)) {
      case AdvancedSearchInputType.ENUM:
      case AdvancedSearchInputType.BOOL:
        result = !isNil(propertyValue.propertyValue);
        break;
      case AdvancedSearchInputType.NUMBER:
        result = !isNil(propertyValue.propertyValue) && isFinite(propertyValue.propertyValue);
        break;
      case AdvancedSearchInputType.OBJECT:
      case AdvancedSearchInputType.STRING:
        result = !isEmpty(propertyValue.propertyValue);
        break;
      case AdvancedSearchInputType.DATE:
        result = !isNil(propertyValue.propertyValue);
        break;
    }
    return result;
  }

  public static getDisplayValueForAdvancedSearchFilter(propertyName: string, value: any, translate: TranslateService, defaultUnit: Unit): string {
    let displayValue: string;
    switch (AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyName)) {
      case AdvancedSearchInputType.ENUM:
        displayValue = this.getDisplayValueForAdvancedSearchFilterEnumCase(propertyName, value, translate);
        break;
      case AdvancedSearchInputType.NUMBER:
        displayValue = this.getDisplayValueForAdvancedSearchFilterNumberCase(propertyName, value, defaultUnit);
        break;
      case AdvancedSearchInputType.STRING:
        displayValue = this.getDisplayValueForAdvancedSearchFilterStringCase(propertyName, value, translate);
        break;
      case AdvancedSearchInputType.OBJECT:
        displayValue = this.getDisplayValueForAdvancedSearchFilterObjectCase(propertyName, value);
        break;
      case AdvancedSearchInputType.DATE:
        displayValue = this.getDisplayValueForAdvancedSearchFilterDateCase(value);
        break;
      case AdvancedSearchInputType.BOOL:
        displayValue = value ? 'true' : 'false';
        break;
    }
    return displayValue;
  }

  public static getInputTypeForAdvancedSearchFilter(key: string): AdvancedSearchInputType {
    let inputType: AdvancedSearchInputType;
    if (this.inputTypeIsOfTypeString(key)) {
      inputType = AdvancedSearchInputType.STRING;
    } else if (this.inputTypeIsOfTypeNumber(key)) {
      inputType = AdvancedSearchInputType.NUMBER;
    } else if (this.inputTypeIsOfTypeEnum(key)) {
      inputType = AdvancedSearchInputType.ENUM;
    } else if (this.inputTypeIsOfTypeObject(key)) {
      inputType = AdvancedSearchInputType.OBJECT;
    } else if (this.inputTypeIsOfTypeDate(key)) {
      inputType = AdvancedSearchInputType.DATE;
    } else if (this.inputTypeIsOfTypeBool(key)) {
      inputType = AdvancedSearchInputType.BOOL;
    }
    return inputType;
  }

  public static getJsonBodyForAdvancedSearchFilters(filters: PropertyValue[], pickDensityUnit?: Unit, reedDensityUnit?: Unit): JSON {
    const body: JSON = {} as JSON;
    for (const filter of filters) {
      if (AdvancedSearchUtils.hasValue(filter) && !isEqual(filter.propertyName, AdvancedSearchInput.COMPLETION_DATE_START) && !isEqual(filter.propertyName, AdvancedSearchInput.COMPLETION_DATE_END)) {
        body[AdvancedSearchUtils.getJsonKeyForAdvancedSearchFilter(filter.propertyName)] = AdvancedSearchUtils.getJsonValueForAdvancedSearchFilter(filter, body, pickDensityUnit, reedDensityUnit);
      } else if (AdvancedSearchUtils.hasValue(filter)) {
        const subJson: JSON = {...body[AdvancedSearchUtils.getJsonKeyForAdvancedSearchFilter(filter.propertyName)]} as JSON;
        subJson[AdvancedSearchUtils.getSubJsonKeyForAdvancedSearchFilter(filter.propertyName)] = AdvancedSearchUtils.getJsonValueForAdvancedSearchFilter(filter, body);
        body[AdvancedSearchUtils.getJsonKeyForAdvancedSearchFilter(filter.propertyName)] = subJson;
      }
    }
    return body;
  }

  public static getAgGridJsonBodyForAdvancedSearchFilters(filters: PropertyValue[]): any {
    const body: any = {
      filterModel: {}
    };

    for (const filter of filters) {
      if (AdvancedSearchUtils.hasValue(filter)) {
        const filterModel = {
          filterType: 'multi',
          filterModels: [
            {
              filterType: 'text',
              type: 'contains',
              filter: filter.propertyValue
            }
          ]
        };
        body.filterModel[filter.propertyName] = {...filterModel};
      }
    }
    return body;
  }

  private static getJsonKeyForAdvancedSearchFilter(key: string): string {
    let result: string = key;
    const mappedValue = this.jsonKeyMap.get(key);

    if (!isNil(mappedValue)) {
      result = mappedValue;
    } else if (isEqual(AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(key), AdvancedSearchInputType.OBJECT)) {
      result = camelCase(key) + 'Id';
    }

    return result;
  }

  private static getSubJsonKeyForAdvancedSearchFilter(key: string): string {
    let jsonKey: string;
    if (isEqual(key, AdvancedSearchInput.COMPLETION_DATE_START)) {
      jsonKey = 'start';
    } else if (isEqual(key, AdvancedSearchInput.COMPLETION_DATE_END)) {
      jsonKey = 'end';
    }

    return jsonKey;
  }

  private static getJsonValueForAdvancedSearchFilter(propertyValue: PropertyValue, json: JSON, pickDensityUnit?: Unit, reedDensityUnit?: Unit): any {
    let jsonValue: any;
    switch (AdvancedSearchUtils.getInputTypeForAdvancedSearchFilter(propertyValue.propertyName)) {
      case AdvancedSearchInputType.ENUM:
        jsonValue = this.getJsonValueForEnumType(propertyValue);

        break;
      case AdvancedSearchInputType.OBJECT:
        jsonValue = propertyValue.propertyValue.id;
        break;
      case AdvancedSearchInputType.NUMBER:
        if (propertyValue.propertyName === AdvancedSearchInput.LENGTH || propertyValue.propertyName === AdvancedSearchInput.WIDTH) {
          const jsonKey: string = propertyValue.propertyName + 'InMM';
          jsonValue = json['commercialDimensions'];
          if (isNil(jsonValue)) {
            jsonValue = {} as JSON;
            json['commercialDimensions'] = jsonValue;
          }
          jsonValue[jsonKey] = parseInt(propertyValue.propertyValue, 10);
        } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.PICK_DENSITY)) {
          jsonValue = convertUnit({
            from: {
              unit: pickDensityUnit,
              value: propertyValue.propertyValue
            },
            to: Unit.PICKS_PER_MILLIMETER
          });
        } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.REED_DENSITY)) {
          jsonValue = convertUnit({
            from: {
              unit: reedDensityUnit,
              value: propertyValue.propertyValue
            },
            to: Unit.DENTS_PER_MILLIMETER
          });
        } else if (propertyValue.propertyName === AdvancedSearchInput.WIDTH_IN_DENTS || propertyValue.propertyName === AdvancedSearchInput.HEIGHT_IN_PICKS) {
          jsonValue = json['technicalDimensions'];
          if (jsonValue == null) {
            jsonValue = {} as JSON;
            json['technicalDimensions'] = jsonValue;
          }
          jsonValue[propertyValue.propertyName] = parseInt(propertyValue.propertyValue, 10);
        } else {
          jsonValue = parseInt(propertyValue.propertyValue, 10);
        }
        break;
      case AdvancedSearchInputType.STRING:
        jsonValue = propertyValue.propertyValue;
        break;
      case AdvancedSearchInputType.DATE:
        jsonValue = moment(propertyValue.propertyValue).format();
        break;
      case AdvancedSearchInputType.BOOL:
        jsonValue = propertyValue.propertyValue;
        break;
    }

    return jsonValue;
  }

  private static inputTypeIsOfTypeString(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.STRING, key);
  }

  private static inputTypeIsOfTypeNumber(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.NUMBER, key);
  }

  private static inputTypeIsOfTypeEnum(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.ENUM, key);
  }

  private static inputTypeIsOfTypeObject(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.OBJECT, key);
  }

  private static inputTypeIsOfTypeDate(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.DATE, key);
  }

  private static inputTypeIsOfTypeBool(key: string): boolean {
    return this.inputIsOfType(AdvancedSearchInputType.BOOL, key);
  }

  private static inputIsOfType(type: AdvancedSearchInputType, key: string): boolean {
    return includes(this.inputTypeMap.get(type), key);
  }

  private static getDisplayValueForAdvancedSearchFilterEnumCase(propertyName: string, value: any, translate: TranslateService): string {
    let displayValue: string;

    if (isEqual(propertyName, AdvancedSearchInput.DRAWING_TYPE)) {
      displayValue = DrawingType[value];
    } else if (isEqual(propertyName, AdvancedSearchInput.MACHINE_STATUS)) {
      displayValue = translate.instant('MACHINE.' + MachineStatus[value]);
    } else if (isEqual(propertyName, AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS)) {
      displayValue = translate.instant('PRODUCTION_ORDER.STATUS.' + ProductionScheduleStatus[value]);
    } else if (isEqual(propertyName, AdvancedSearchInput.MACHINE_EFFICIENCY)) {
      displayValue = translate.instant('MACHINE.' + MachineEfficiencyLevel[value]);
    } else if (isEqual(propertyName, AdvancedSearchInput.PRIORITY)) {
      displayValue = translate.instant('SALES_ORDERS.PRIORITY.' + Priority[value]);
    }

    return displayValue;
  }

  private static getDisplayValueForAdvancedSearchFilterNumberCase(propertyName: string, value: any, defaultUnit: Unit): string {
    let displayValue: string;

    if ((propertyName === AdvancedSearchInput.LENGTH || propertyName === AdvancedSearchInput.WIDTH) && value !== null) {
      displayValue = `${convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: toNumber(value)}, to: defaultUnit})}`;
    } else {
      displayValue = value;
    }

    return displayValue;
  }

  private static getDisplayValueForAdvancedSearchFilterStringCase(propertyName: string, value: any, translate: TranslateService): string {
    let displayValue: string;

    if (isEqual(propertyName, AdvancedSearchInput.DRAWING_SHAPE)) {
      displayValue = FixedShape.getTranslationKeyForShape(value, translate);
    } else {
      displayValue = value;
    }

    return displayValue;
  }

  private static getDisplayValueForAdvancedSearchFilterObjectCase(propertyName: string, value: any): string {
    let displayValue: string;
    if (isEqual(propertyName, AdvancedSearchInput.MACHINE_QUALITY)) {
      displayValue = value.technicalNameWithVersion;
    } else {
      displayValue = value.name;
    }

    return displayValue;
  }

  private static getDisplayValueForAdvancedSearchFilterDateCase(value: any): string {
    return moment(value).format('MM/DD/YYYY');
  }

  private static getJsonValueForEnumType(propertyValue: PropertyValue): any {
    let jsonValue: any;
    if (isEqual(propertyValue.propertyName, AdvancedSearchInput.DRAWING_TYPE)) {
      jsonValue = DrawingType[propertyValue.propertyValue];
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.MACHINE_STATUS)) {
      jsonValue = MachineStatus[propertyValue.propertyValue];
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS)) {
      jsonValue = ProductionScheduleStatus[propertyValue.propertyValue];
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.MACHINE_EFFICIENCY)) {
      jsonValue = MachineEfficiencyLevel[propertyValue.propertyValue];
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.PRIORITY)) {
      jsonValue = Priority[propertyValue.propertyValue];
    }

    return jsonValue;
  }
}
