import {Command} from '@application/command/command';
import {NavigationState} from '@domain/navigation/navigation-state.enum';
import {SideNavigationStateService} from '@presentation/navigation/side-navigation-state.service';
import {Observable} from 'rxjs/internal/Observable';

export class OpenMobileNavigationBarCommand implements Command<void, void> {
  public constructor(private readonly sideNavigationState: SideNavigationStateService) {}

  public execute(): Observable<NavigationState> {
    this.sideNavigationState.changeNavigationStateToOpen();
    return this.sideNavigationState.onNavigationStateChanged.asObservable();
  }
}
