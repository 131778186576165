import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule, MAT_CARD_CONFIG} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonFormsModule} from '../../forms/common-forms.module';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {ProgressButtonModule} from '../progress-button/progress-button.module';
import {HeaderComponent} from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslationModule,
    LabelModule,
    ProgressButtonModule,
    CommonFormsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    ProgressButtonModule,
    CommonFormsModule,
    MatDividerModule,
    MatTooltipModule
  ],
  exports: [HeaderComponent],
  providers: [
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined'
      }
    }
  ]
})
export class HeaderModule {}
