import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {SwUpdate, VersionDetectedEvent, VersionInstallationFailedEvent, VersionReadyEvent} from '@angular/service-worker';
import {VERSION} from '@environments/version';
import {ChangeLog} from '@presentation/components/new-update-available/changelog';
import {NewUpdateAvailableComponent} from '@presentation/components/new-update-available/new-update-available.component';
import {AssertionUtils, DialogBuilderFactoryService, LocalStorageService, WINDOW} from '@vdw/angular-component-library';
import {filter, isEqual} from 'lodash-es';
import {interval, partition, Subscription} from 'rxjs';
import {filter as rxjsFilter, mergeMap, tap} from 'rxjs/operators';
import semverCompare from 'semver-compare';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private readonly INTERVAL_TO_CHECK_FOR_UPDATES = 300000;
  private readonly MAX_DELAY_TO_RELOAD = 5000;
  private intervalSubscription: Subscription;
  private dialogOpen = false;

  public constructor(
    private readonly swUpdate: SwUpdate,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  public checkForUpdates(): void {
    const startTime = new Date().getTime();

    const swUpdateAvailable = this.swUpdate.versionUpdates.pipe(
      rxjsFilter((event: VersionReadyEvent | VersionDetectedEvent | VersionInstallationFailedEvent) => event.type === 'VERSION_READY'),
      mergeMap(() => this.httpClient.get('/changelog.json'))
    );

    const [updateOnStartup, updateOnInterval] = partition(swUpdateAvailable, () => {
      const elapsedTime = new Date().getTime() - startTime;
      return elapsedTime <= this.MAX_DELAY_TO_RELOAD;
    });

    updateOnStartup.pipe(tap(() => this.localStorageService.set('new-update-activation-timestamp', `${new Date().getTime()}`))).subscribe(() => this.window.location.reload());

    updateOnInterval.pipe(rxjsFilter(() => !this.dialogOpen)).subscribe({
      next: (changelogs: ChangeLog[]) => this.showNewUpdateDialog(this.getNewerVersionsChangeLog(changelogs)),
      error: () => {
        this.intervalSubscription?.unsubscribe();
        this.dialogOpen = false;
      }
    });

    this.pollForUpdates();
  }

  private getNewerVersionsChangeLog(changelogs: ChangeLog[]): ChangeLog[] {
    return filter(changelogs, (changelog: ChangeLog) => {
      return isEqual(semverCompare(changelog.version, VERSION.version), 1);
    });
  }

  private pollForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();

      this.intervalSubscription = interval(this.INTERVAL_TO_CHECK_FOR_UPDATES).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    }
  }

  private showNewUpdateDialog(changelogs: ChangeLog[]): void {
    if (!AssertionUtils.isNullOrUndefined(changelogs) && !AssertionUtils.isEmpty(changelogs)) {
      this.dialogOpen = true;
      this.dialogBuilderFactoryService
        .getBuilder()
        .withAutoFocus()
        .withClass('new-update-dialog')
        .openDialog(NewUpdateAvailableComponent, changelogs)
        .subscribe({
          error: () => {
            this.intervalSubscription?.unsubscribe();
            this.dialogOpen = false;
          }
        });
    }
  }
}
