import {CommercialDimensions} from '@domain/commercial-dimensions';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {CommercialDimensionsCM} from './commercial-dimensions-cm';
import {DrawingConfigurationWeaveQuality} from './drawing-configuration-weave-quality';

export abstract class DrawingConfiguration {
  private _id: number;
  private _image: DrawingImage;
  private _mainColorsCreelPosition: OverviewListCreelPosition;
  private _borderColorsCreelPosition: OverviewListCreelPosition;
  private _quality: DrawingConfigurationWeaveQuality;
  private _commercialDimensionsInMm: CommercialDimensions;
  private _commercialDimensionsInCm: CommercialDimensionsCM;

  public constructor(
    image: DrawingImage,
    mainColorsCreelPosition: OverviewListCreelPosition,
    borderColorsCreelPosition: OverviewListCreelPosition,
    quality: DrawingConfigurationWeaveQuality,
    commercialDimensionsInMm: CommercialDimensions,
    commercialDimensionsInCm?: CommercialDimensionsCM,
    id?: number
  ) {
    this._id = id;
    this._image = image;
    this._mainColorsCreelPosition = mainColorsCreelPosition;
    this._borderColorsCreelPosition = borderColorsCreelPosition;
    this._quality = quality;
    this._commercialDimensionsInMm = commercialDimensionsInMm;
    this._commercialDimensionsInCm = commercialDimensionsInCm;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get image(): DrawingImage {
    return this._image;
  }

  public set image(image: DrawingImage) {
    this._image = image;
  }

  public get mainColorsCreelPosition(): OverviewListCreelPosition {
    return this._mainColorsCreelPosition;
  }

  public get borderColorsCreelPosition(): OverviewListCreelPosition {
    return this._borderColorsCreelPosition;
  }

  public get quality(): DrawingConfigurationWeaveQuality {
    return this._quality;
  }

  public get commercialDimensionsInMm(): CommercialDimensions {
    return this._commercialDimensionsInMm;
  }

  public get commercialDimensionsInCm(): CommercialDimensionsCM {
    return this._commercialDimensionsInCm;
  }

  public abstract toJSON(): JSON;
}
