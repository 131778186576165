import {BaseMachineType} from '@domain/machine/base-machine-type';
import {ControllerType} from '@domain/machine/controller-type';
import {EquipmentManufacturer} from '@domain/machine/equipment-manufacturer';
import {Technology} from '@domain/machine/technology';

export class DyeingMachineType extends BaseMachineType {
  private _technology: Technology;
  private _controllerType: ControllerType;
  private _minimumLoadInKg: number;
  private _maximumLoadInKg: number;
  private _maximumTemperatureInCelsius: number;

  public constructor(
    id: number,
    name: string,
    technology: Technology,
    manufacturer: EquipmentManufacturer,
    model: string,
    controllerType: ControllerType,
    minimumLoadInKg: number,
    maximumLoadInKg: number,
    maximumTemperatureInCelsius: number
  ) {
    super(id, name, manufacturer, model);
    this._technology = technology;
    this._controllerType = controllerType;
    this._minimumLoadInKg = minimumLoadInKg;
    this._maximumLoadInKg = maximumLoadInKg;
    this._maximumTemperatureInCelsius = maximumTemperatureInCelsius;
  }

  public static fromJSON(dyeingMachineTypeJSON: any): DyeingMachineType {
    return new DyeingMachineType(
      dyeingMachineTypeJSON.id,
      dyeingMachineTypeJSON.name,
      new Technology(dyeingMachineTypeJSON.technologyId, dyeingMachineTypeJSON.technologyName),
      new EquipmentManufacturer(dyeingMachineTypeJSON.manufacturerId, dyeingMachineTypeJSON.manufacturerName),
      dyeingMachineTypeJSON.model,
      new ControllerType(dyeingMachineTypeJSON.controllerTypeId, dyeingMachineTypeJSON.controllerTypeName),
      dyeingMachineTypeJSON.minimumLoadInKg,
      dyeingMachineTypeJSON.maximumLoadInKg,
      dyeingMachineTypeJSON.maximumTemperatureInCelsius
    );
  }

  public get technology(): Technology {
    return this._technology;
  }

  public set technology(technology: Technology) {
    this._technology = technology;
  }

  public get controllerType(): ControllerType {
    return this._controllerType;
  }

  public set controllerType(controllerType: ControllerType) {
    this._controllerType = controllerType;
  }

  public get minimumLoadInKg(): number {
    return this._minimumLoadInKg;
  }

  public set minimumLoadInKg(minimumLoadInKg: number) {
    this._minimumLoadInKg = minimumLoadInKg;
  }

  public get maximumLoadInKg(): number {
    return this._maximumLoadInKg;
  }

  public set maximumLoadInKg(value: number) {
    this._maximumLoadInKg = value;
  }

  public get maximumTemperatureInCelsius(): number {
    return this._maximumTemperatureInCelsius;
  }

  public set maximumTemperatureInCelsius(value: number) {
    this._maximumTemperatureInCelsius = value;
  }

  public toJSON(): JSON {
    const machineTypeJSON = super.toJSON();
    machineTypeJSON['technologyId'] = this.technology?.id;
    machineTypeJSON['controllerTypeId'] = this.controllerType?.id;
    machineTypeJSON['minimumLoadInKg'] = this.minimumLoadInKg;
    machineTypeJSON['maximumLoadInKg'] = this.maximumLoadInKg;
    machineTypeJSON['maximumTemperatureInCelsius'] = this.maximumTemperatureInCelsius;
    return machineTypeJSON;
  }
}
