export class BackcoatingInformation {
  private _useBackcoating: boolean;
  private _weight: number;

  public constructor(useBackcoating: boolean, weight?: number) {
    this._useBackcoating = useBackcoating;
    this._weight = weight;
  }

  public get useBackcoating(): boolean {
    return this._useBackcoating;
  }

  public get weight(): number {
    return this._weight;
  }

  public static fromJSON(backcoatingInformationJSON: any): BackcoatingInformation {
    return new BackcoatingInformation(backcoatingInformationJSON.useBackcoating, backcoatingInformationJSON.useBackcoating ? backcoatingInformationJSON.weightInGramsPerSquareMeter : null);
  }
}
