import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {isNil} from 'lodash-es';

export class OverviewListWeavingMachine {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string = null) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(overviewListWeavingMachineJSON: any): OverviewListWeavingMachine {
    return isNil(overviewListWeavingMachineJSON) ? null : new OverviewListWeavingMachine(overviewListWeavingMachineJSON.id, overviewListWeavingMachineJSON.name);
  }

  public static fromMachineSummary(machine: MachineSummary): OverviewListWeavingMachine {
    return new OverviewListWeavingMachine(machine.id, machine.name);
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name
    } as any as JSON;
  }
}
