import {TextileUnitConversionError} from '../error/textile-unit-conversion-error';
import {Unit} from '../unit.enum';
import {UnitValue} from './unit-value';

export function convertToTextileUnitLibraPounds(unitValue: UnitValue): number {
  let result: number;

  switch (unitValue.unit) {
    case Unit.NUMBER_METRIC:
      result = 28.8 / unitValue.value;
      break;
    case Unit.DENIER:
      result = 3.2 * unitValue.value;
      break;
    case Unit.TEX:
      result = 28.8 * unitValue.value;
      break;
    case Unit.DECITEX:
      result = 2.88 * unitValue.value;
      break;
    case Unit.LINENCOUNT:
      result = 48 / unitValue.value;
      break;
    case Unit.LIBRA_POUNDS:
      result = unitValue.value;
      break;
    case Unit.ENGLISH_COTTON_COUNT:
      result = 17020.8 / unitValue.value;
      break;
    default:
      throw new TextileUnitConversionError(`No conversion found from ${unitValue.unit} to ${Unit.LIBRA_POUNDS}`);
  }

  return result;
}
