import {MachineType} from '@domain/machine/machine-type.enum';

export class PlanningEquipment {
  private _id: number;
  private _name: string;
  private _equipmentKind: MachineType;

  public constructor(id: number, name: string, equipmentKind: MachineType) {
    this._id = id;
    this._name = name;
    this._equipmentKind = equipmentKind;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get equipmentKind(): MachineType {
    return this._equipmentKind;
  }

  public static fromJSON(json: any): PlanningEquipment {
    return new PlanningEquipment(json.id, json.name, json.equipmentKind);
  }
}
