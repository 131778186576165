import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslationModule} from '../../translation/translation.module';
import {TabsMenuComponent} from './tabs-menu.component';

@NgModule({
  declarations: [TabsMenuComponent],
  imports: [CommonModule, MatTabsModule, TranslationModule],
  exports: [TabsMenuComponent]
})
export class TabsMenuModule {}
