import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {isEmpty, isNil, orderBy} from 'lodash-es';

export function getMappedColoredYarnSet(coloredYarnSet: OverviewListColoredYarnSet, coloredYarnSetCreelMap: CreelMap, sortDirection: any = 'desc'): OverviewListColoredYarnSet {
  let colorSet: OverviewListColorSet;
  let yarnSet: OverviewListYarnSet;

  if (!isNil(coloredYarnSet.overviewListColorSet)) {
    const sortedColorSetCreelPositions: OverviewListCreelPosition[] = orderBy(
      coloredYarnSet.overviewListColorSet.creelPositions,
      getCreelPositionSortingFunction(coloredYarnSetCreelMap),
      sortDirection
    );

    colorSet = new OverviewListColorSet(coloredYarnSet.overviewListColorSet.id, coloredYarnSet.overviewListColorSet.name, sortedColorSetCreelPositions);
  }

  if (!isNil(coloredYarnSet.overviewListYarnSet)) {
    const sortedYarnCreelPositions: OverviewListCreelPosition[] = orderBy(coloredYarnSet.overviewListYarnSet.creelPositions, getCreelPositionSortingFunction(coloredYarnSetCreelMap), sortDirection);

    yarnSet = new OverviewListYarnSet(coloredYarnSet.overviewListYarnSet.id, coloredYarnSet.overviewListYarnSet.name, sortedYarnCreelPositions);
  }

  return new OverviewListColoredYarnSet(coloredYarnSet.id, coloredYarnSet.name, colorSet, yarnSet, coloredYarnSet.used);
}

function getCreelPositionSortingFunction(creelMap: CreelMap): (creelPosition: OverviewListCreelPosition) => number {
  return !isEmpty(creelMap)
    ? (creelPosition: OverviewListCreelPosition): number =>
        creelMap.creelPositions.find((creelPositionInMap: {creelPosition: number; currentPositionOnMachine: number}) => creelPositionInMap.creelPosition === creelPosition.position)
          ?.currentPositionOnMachine
    : null;
}
