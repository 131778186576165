<div class="selector">
  <div class="selector-content">
    <button mat-icon-button type="button">
      <mat-icon svgIcon="chevron-left" (click)="previousOption()"></mat-icon>
    </button>
    <vdw-mobile-dropdown [selectedOption]="selectedOption" [options]="options" [dialogTitleKey]="dialogTitleKey" (selectorChange)="selectorChange($event)"></vdw-mobile-dropdown>
    <button mat-icon-button type="button">
      <mat-icon svgIcon="chevron-right" (click)="nextOption()"></mat-icon>
    </button>
  </div>
</div>
