import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {Subscription, timer} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {skeletonViewAnimation} from '../../common/animations/skeleton-view.animation';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {LocalStorageService} from '../../custom-services/local-storage.service/local-storage.service';
import {HeaderAction} from './header-action';
import {HeaderButtons} from './header-button';
import {HeaderTabIndex} from './header-tab-index';
import {SaveType} from './save-type.enum';

@Component({
  selector: 'vdw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [skeletonViewAnimation('.button-skeleton-wrapper, text-skeleton-wrapper')]
})
export class HeaderComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('saveMenuTrigger', {read: MatMenuTrigger}) public saveMenuTrigger: MatMenuTrigger;
  @ViewChild('optionMenuTrigger', {read: MatMenuTrigger}) public optionMenuTrigger: MatMenuTrigger;
  @ViewChild('saveMenu', {static: true}) public saveMenu: ElementRef;
  @Input() public title: string;
  @Input() public extraInformationTitle: string;
  @Input() public svgIcon: string;
  @Input() public action: string;
  @Input() public amount: number;
  @Input() public isInUse = false;
  @Input() public locked = false;
  @Input() public invalidFormMessageError;
  @Input() public hasPermissionToEdit = true;
  @Input() public isLoading = false;
  @Input() public saving = false;
  @Input() public headerButtons: HeaderButtons;
  @Input() public headerTabIndex: HeaderTabIndex = {tabIndexSave: 0, tabIndexCancel: 0, tabIndexCustomSettings: 0};
  @Input() public headerActions: HeaderAction[];
  @Input() public isCreateNewPage = false;
  @Input() public showCustomErrorMessage = false;
  @Input() public customErrorMessage: string;
  @Input() public identifier: string;
  @Input() public settingsTooltip: string;
  @Input() public canShowClose = false;
  @Input() public actionText = 'ANGULAR_COMPONENT_LIBRARY.HEADER.SAVE';
  @Output() public backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deleteEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public duplicateEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCloseEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAndCreateNewEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public settingsEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public inUseEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeEvent: EventEmitter<void> = new EventEmitter<void>();
  public saveMenuTimer: Subscription;
  public optionMenuTimer: Subscription;
  public defaultSaveType: SaveType;
  public readonly SAVE = SaveType.SAVE;
  public readonly SAVE_AND_CLOSE = SaveType.SAVE_AND_CLOSE;
  public readonly SAVE_AND_CREATE_NEW = SaveType.SAVE_AND_CREATE_NEW;
  private readonly LOCAL_STORAGE_KEY = 'saveType';

  public constructor(private readonly localStorage: LocalStorageService) {
    super();
  }

  public ngOnInit(): void {
    this.setDefaultSaveType(this.identifier);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.identifier && !changes.identifier.isFirstChange()) {
      this.setDefaultSaveType(changes.identifier.currentValue);
    }
  }

  public cancelButtonClicked(): void {
    this.cancelEvent.emit();
  }

  public deleteButtonClicked(): void {
    this.deleteEvent.emit();
  }

  public headerActionClicked(event: MouseEvent, headerAction: HeaderAction): void {
    headerAction.onClick(event);
  }

  public backButtonClicked(): void {
    this.backEvent.emit();
  }

  public duplicateButtonClicked(): void {
    this.duplicateEvent.emit();
  }

  public save(saveType: SaveType): void {
    if (this.identifier) {
      this.defaultSaveType = saveType;
      this.localStorage.set(`${this.LOCAL_STORAGE_KEY}.${this.identifier}`, this.defaultSaveType);
    }

    if (saveType === SaveType.SAVE) {
      this.saveEvent.emit();
    } else if (saveType === SaveType.SAVE_AND_CLOSE) {
      this.saveAndCloseEvent.emit();
    } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
      this.saveAndCreateNewEvent.emit();
    }
  }

  public settingsButtonClicked(): void {
    this.settingsEvent.emit();
  }

  public inUseButtonClicked(): void {
    this.inUseEvent.emit();
  }

  public closeClicked(): void {
    this.closeEvent.emit();
  }

  public hasDeleteButton(): boolean {
    return this.headerButtons ? this.headerButtons.delete : this.deleteEvent.observed;
  }

  public hasDuplicateButton(): boolean {
    return this.headerButtons ? this.headerButtons.duplicate : this.duplicateEvent.observed;
  }

  public isDefaultSaveType(saveType: SaveType): boolean {
    return saveType === this.defaultSaveType;
  }

  public hasSaveMenuButton(): boolean {
    return this.hasSaveButton() && !this.isDefaultSaveType(SaveType.SAVE);
  }

  public hasSaveAndCloseMenuButton(): boolean {
    return this.hasSaveAndCloseButton() && !this.isDefaultSaveType(SaveType.SAVE_AND_CLOSE);
  }

  public hasSaveAndCreateNewMenuButton(): boolean {
    return this.hasSaveAndCreateNewButton() && !this.isDefaultSaveType(SaveType.SAVE_AND_CREATE_NEW);
  }

  public hasSettingsButton(): boolean {
    return this.headerButtons ? this.headerButtons.settings : this.settingsEvent.observed;
  }

  public hasInUseButton(): boolean {
    return this.headerButtons ? this.headerButtons.inUse : this.inUseEvent.observed;
  }

  public hasBackButton(): boolean {
    return this.headerButtons ? this.headerButtons.back : this.backEvent.observed;
  }

  public canShowMoreButton(): boolean {
    return this.hasPermissionToEdit && this.hasOptionMenu() && !this.isCreateNewPage;
  }

  public canShowCancelButton(): boolean {
    return this.hasPermissionToEdit && this.hasCancelButton();
  }

  public canShowSaveButton(): boolean {
    if (AssertionUtils.isNullOrUndefined(this.defaultSaveType)) {
      this.setDefaultSaveType(this.identifier);
    }
    return !this.canShowInUseButton() && this.hasPermissionToEdit && this.defaultSaveType !== undefined && (this.hasSaveButton() || this.hasSaveAndCloseButton() || this.hasSaveAndCreateNewButton());
  }

  public canShowSettingsButton(): boolean {
    return this.hasPermissionToEdit && this.hasSettingsButton();
  }

  public canShowInUseButton(): boolean {
    return this.isInUse && this.hasPermissionToEdit && this.hasInUseButton();
  }

  public canShowDivider(): boolean {
    return this.canShowSettingsButton() && this.hasActionButtons();
  }

  public canShowAmount(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.amount) && this.amount > 0;
  }

  public mouseEnterOptionButton(): void {
    if (this.optionMenuTimer) {
      this.optionMenuTimer.unsubscribe();
    }
    this.optionMenuTrigger.openMenu();
  }

  public mouseEnterSaveButton(): void {
    if (this.saveMenuTimer) {
      this.saveMenuTimer.unsubscribe();
    }

    if (!this.locked) {
      this.saveMenuTrigger.openMenu();
    }
  }

  public mouseLeaveOptionButton(): void {
    this.optionMenuTimer = timer(500).subscribe(() => {
      this.optionMenuTrigger.restoreFocus = false;
      this.optionMenuTrigger.closeMenu();
    });
  }

  public mouseLeaveSaveButton(): void {
    this.saveMenuTimer = timer(500).subscribe(() => this.saveMenuTrigger.closeMenu());
  }

  public hasSaveMenu(): boolean {
    return this.hasSaveMenuButton() || this.hasSaveAndCloseMenuButton() || this.hasSaveAndCreateNewMenuButton();
  }

  public showSaveMenu(): ElementRef {
    return this.locked ? null : this.saveMenu;
  }

  public getSaveButtonTranslationKey(): string {
    switch (this.defaultSaveType) {
      case SaveType.SAVE_AND_CLOSE:
        return 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CLOSE';
      case SaveType.SAVE_AND_CREATE_NEW:
        return 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CREATE_NEW';
      default:
        return this.actionText;
    }
  }

  private hasOptionMenu(): boolean {
    return this.hasDuplicateButton() || this.hasDeleteButton() || this.headerActions?.length > 0;
  }

  private hasCancelButton(): boolean {
    return this.headerButtons ? this.headerButtons.cancel : this.cancelEvent.observed;
  }

  private hasSaveButton(): boolean {
    return this.headerButtons ? this.headerButtons.save : this.saveEvent.observed;
  }

  private hasSaveAndCloseButton(): boolean {
    return this.headerButtons ? this.headerButtons.saveAndClose : this.saveAndCloseEvent.observed;
  }

  private hasSaveAndCreateNewButton(): boolean {
    return this.headerButtons ? this.headerButtons.saveAndCreateNew : this.saveAndCreateNewEvent.observed;
  }

  private hasActionButtons(): boolean {
    return this.canShowMoreButton() || this.canShowCancelButton() || this.canShowSaveButton() || this.headerActions?.length > 0;
  }

  private setDefaultSaveType(identifier: string): void {
    const defaultSaveType = this.localStorage.get<SaveType>(`${this.LOCAL_STORAGE_KEY}.${identifier}`);

    if (identifier && this.isSaveTypeButtonAvailable(defaultSaveType)) {
      this.defaultSaveType = defaultSaveType;
    } else if (this.hasSaveButton()) {
      this.defaultSaveType = SaveType.SAVE;
    } else if (this.hasSaveAndCloseButton()) {
      this.defaultSaveType = SaveType.SAVE_AND_CLOSE;
    } else if (this.hasSaveAndCreateNewButton()) {
      this.defaultSaveType = SaveType.SAVE_AND_CREATE_NEW;
    }
  }

  private isSaveTypeButtonAvailable(saveType: SaveType): boolean {
    switch (saveType) {
      case SaveType.SAVE:
        return this.hasSaveButton();
      case SaveType.SAVE_AND_CLOSE:
        return this.hasSaveAndCloseButton();
      case SaveType.SAVE_AND_CREATE_NEW:
        return this.hasSaveAndCreateNewButton();
      default:
        return false;
    }
  }
}
