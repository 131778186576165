export class PathWidth {
  private _commercialInCM: number;
  private _calculatedDents: number;
  private _actualDents: number;

  public constructor(commercialInCM: number, calculatedDents: number, actualDents: number) {
    this._commercialInCM = commercialInCM;
    this._calculatedDents = calculatedDents;
    this._actualDents = actualDents;
  }

  public get commercialInCM(): number {
    return this._commercialInCM;
  }

  public get calculatedDents(): number {
    return this._calculatedDents;
  }

  public get actualDents(): number {
    return this._actualDents;
  }

  public static fromJSON(pathWidthJSON: any): PathWidth {
    return new PathWidth(pathWidthJSON.commercialInCM, pathWidthJSON.calculatedDents, pathWidthJSON.actualDents);
  }

  public toJSON(): JSON {
    return {
      commercialInCM: this._commercialInCM,
      calculatedDents: this._calculatedDents,
      actualDents: this._actualDents
    } as any as JSON;
  }
}
