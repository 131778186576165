import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class AlertRoute extends RouteBranch {
  private _addAlertForEvent: RouteLeaf;
  private _editAlertForEvent: RouteLeaf;

  public get addAlert(): RouteLeaf {
    return this._addAlertForEvent;
  }

  public set addAlert(value: RouteLeaf) {
    this.replace(this._addAlertForEvent, value);
    this._addAlertForEvent = value;
  }

  public get editAlert(): RouteLeaf {
    return this._editAlertForEvent;
  }

  public set editAlert(value: RouteLeaf) {
    this.replace(this._editAlertForEvent, value);
    this._editAlertForEvent = value;
  }
}
