import {Injectable, OnDestroy} from '@angular/core';
import {PlanningFilter} from '@domain/planning-prototype/custom-settings/planning-filter';
import {OverviewMachineGroup} from '@presentation/pages/machine-overview/overview-machine-group';
import {Observable, Subject} from 'rxjs';

export interface FormattedMachineGroup {
  hierarchy: string[];
  id: number;
  name: string;
}

@Injectable()
export class PlanningFilterService implements OnDestroy {
  private readonly _filterChanged = new Subject<number[]>();

  public formattedMachineGroups: FormattedMachineGroup[] = [];
  public selectedMachineGroups: number[] = [];

  public get filterChanged(): Observable<number[]> {
    return this._filterChanged;
  }

  public constructor() {
    this._filterChanged.subscribe((selectedMachineGroups: number[]) => {
      this.selectedMachineGroups = selectedMachineGroups;
    });
  }

  public ngOnDestroy(): void {
    this._filterChanged.complete();
  }

  public init(rootGroup: OverviewMachineGroup, storedFilter: PlanningFilter): void {
    storedFilter ??= new PlanningFilter([]);
    const flattenedGroup = PlanningFilterService.flattenGroup(rootGroup);
    const filter = storedFilter.equipmentGroupIds.filter((id: number) => flattenedGroup.includes(id));
    this._filterChanged.next(filter);
    this.formattedMachineGroups = PlanningFilterService.formatMachineGroups(rootGroup);
  }

  public updateSelectedMachineGroups = (selectedMachineGroups: number[]): void => {
    this._filterChanged.next(selectedMachineGroups);
  };

  private static flattenGroup(group: OverviewMachineGroup): number[] {
    return [group.id, ...group.groups.flatMap(PlanningFilterService.flattenGroup)];
  }

  private static formatMachineGroups(group: OverviewMachineGroup, hierarchy: string[] = []): FormattedMachineGroup[] {
    hierarchy.push(group.id.toString());
    return [
      {
        hierarchy,
        id: group.id,
        name: group.name
      },
      ...group.groups.flatMap((subGroup: OverviewMachineGroup) => PlanningFilterService.formatMachineGroups(subGroup, [...hierarchy]))
    ];
  }
}
