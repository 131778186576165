<section class="flex-column full-height overflow-hidden">
  <vdw-tabs-menu-card [icon]="getHeaderIcon()" [action]="getActionText()" [title]="productionScheduleInitialPreparationsForm?.value?.name" (backEvent)="navigateBack()">
    <app-production-schedule-menu-items
      class="full-width"
      [loadingProperties]="getMenuItemsLoadingProperties()"
      [productionScheduleInitialPreparationsForm]="productionScheduleInitialPreparationsForm"
      [productionSchedulePhase]="currentProductionSchedulePhase"
      [customErrorMessage]="getInvalidFormMessageError()"
      [canShowProperties]="getMenuItemsCanShowProperties()"
      (clickEvent)="onClickEvent($event)"
    ></app-production-schedule-menu-items>
  </vdw-tabs-menu-card>
  <div fxFlex fxLayout="column" fxLayoutAlign="stretch" class="production-schedule-initial-preparations-container" *ngIf="canShowInitialPreparationsForProductionSchedule()">
    <app-production-schedule-initial-preparations
      #initialPreparations
      [productionScheduleInitialPreparationsForm]="productionScheduleInitialPreparationsForm"
      [listOfCustomSettings]="listOfParametersForDefaultParameters"
      [listOfMachineQualityCustomSettings]="listOfMachineQualityCustomSettings"
      [productionSchedule]="productionScheduleToAdd"
      [needsToShowValidationFeedback]="canShowInvalidFormMessageError()"
      (formValuesChanged)="initialPreparationsChanged()"
      (restZoneLoaded)="restZoneLoaded()"
      (freeZoneLoaded)="freeZoneLoaded()"
      (pathZoneLoaded)="pathZoneLoaded()"
      (juteZoneLoaded)="juteZoneLoaded()"
      (hideParentHeader)="setHeaderVisibility($event)"
    >
    </app-production-schedule-initial-preparations>
  </div>
  <app-production-schedule-drawing-library
    *ngIf="canShowDesignsForProductionSchedule()"
    class="full-height"
    [productionSchedule]="productionScheduleToAdd"
    [productionSchedulePhase]="currentProductionSchedulePhase"
    [listOfProductionOrderCustomSettings]="listOfParametersForDefaultParameters"
    [canDragDrawings]="false"
    (selectedDrawingsChanged)="selectedDrawingsChanged($event)"
    (selectedOrderlinesChanged)="selectedOrderlinesChanged($event)"
    [factorForConversionFromMillimetersToPicks]="factorForConversionFromMillimetersToPicks"
    [factorForConversionFromMillimetersToDents]="factorForConversionFromMillimetersToDents"
    [isPreSelection]="true"
  ></app-production-schedule-drawing-library>
  <app-production-schedule-plan
    #productionSchedulePlan
    *ngIf="canShowProductionSchedulePlan()"
    class="full-height overflow-hidden"
    [factorForConversionFromMillimetersToDents]="factorForConversionFromMillimetersToDents"
    [pathWidths]="pathWidths"
    [productionSchedule]="productionScheduleToAdd"
    [productionSchedulePhase]="currentProductionSchedulePhase"
    [lastProductionSchedulePhase]="lastProductionSchedulePhase"
    [listOfCustomSettings]="listOfParametersForDefaultParameters"
    [listOfMachineQualityCustomSettings]="listOfMachineQualityCustomSettings"
    [isVerifyingFromConfigurePhase]="isVerifyingFromConfigurePhase"
    [preselectedOrderlineIds]="preselectedOrderlineIds"
    (returnToProductionSchedulePhase)="setProductionSchedulePhase($event)"
    (productionSchedulePlanChanged)="productionSchedulePlanChanged()"
    (verifiedProductionSchedule)="productionScheduleVerified($event)"
  >
  </app-production-schedule-plan>

  <vdw-loading-overlay fxFlex *ngIf="isLoadingProductionSchedule" [hideDescription]="false" [scale]="1"> </vdw-loading-overlay>
</section>
