import {isNil} from 'lodash-es';
import {CuttingLinePatternType} from './cutting-line-pattern-type.enum';

export class CuttingLineProperties {
  private _patternType: CuttingLinePatternType;

  public constructor(patternType?: CuttingLinePatternType) {
    this._patternType = patternType;
  }

  public get patternType(): CuttingLinePatternType {
    return this._patternType;
  }

  public static fromJSON(cuttingLinePropertiesJSON: any): CuttingLineProperties {
    return isNil(cuttingLinePropertiesJSON) ? null : new CuttingLineProperties(cuttingLinePropertiesJSON.patternType);
  }

  public toJSON(): JSON {
    return {patternType: this._patternType} as any as JSON;
  }
}
