import {unitOfTime} from 'moment';

export class TimeUtils {
  public static readonly HOURS_IN_A_DAY = 24;
  public static readonly SECOND_IN_MS = 1000;
  public static readonly SECONDS_IN_A_MINUTE = 60;
  public static readonly MINUTES_IN_AN_HOUR = 60;
  public static readonly MINUTE_IN_MS = TimeUtils.SECOND_IN_MS * TimeUtils.SECONDS_IN_A_MINUTE;
  public static readonly HOUR_IN_MS = TimeUtils.MINUTE_IN_MS * TimeUtils.MINUTES_IN_AN_HOUR;
  public static readonly SECONDS_IN_A_HOUR = TimeUtils.MINUTES_IN_AN_HOUR * TimeUtils.SECONDS_IN_A_MINUTE;

  public static readonly MILLISECONDS_UNIT: unitOfTime.Base = 'milliseconds';
}
