<ng-container *ngIf="!shouldShowSmallInfo; else smallEllipsis">
  <vdw-ellipsis-label class="order-name font-weight-semibold b2" [text]="getItemName()" matTooltipPosition="above"></vdw-ellipsis-label>
  <div class="planning-order-status">
    <div class="icon-container">
      <mat-icon
        class="icon"
        matTooltipPosition="above"
        [svgIcon]="getIconNameForProductionOrderStatus()"
        [matTooltip]="'PRODUCTION_ORDER.STATUS.' + getProductionOrderStatus() | bmsTranslate"
      ></mat-icon>
      <mat-icon class="icon icon-different-config" svgIcon="deviating" [matTooltip]="'PLANNING.STATUS.DIFFERENT_CONFIGURATION' | bmsTranslate" matTooltipPosition="above"></mat-icon>
      <mat-icon class="icon icon-past-due" svgIcon="clock" [matTooltip]="'PLANNING.STATUS.DUE' | bmsTranslate" matTooltipPosition="above"> </mat-icon>
    </div>
  </div>
</ng-container>

<ng-template #smallEllipsis>
  <div class="icon-container">
    <mat-icon class="icon" svgIcon="drag-handle"></mat-icon>
  </div>
</ng-template>
