import {FinishingLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label';
import {FinishingLabelType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label-type.enum';

export class FinishingAndFinishingTemplateLabelOverviewList {
  private _finishingLabelType: FinishingLabelType;

  public constructor(finishingLabelType: FinishingLabelType) {
    this._finishingLabelType = finishingLabelType;
  }

  public get finishingLabelType(): FinishingLabelType {
    return this._finishingLabelType;
  }

  public static fromJSON(finishingTemplateLabelJSON: any): FinishingAndFinishingTemplateLabelOverviewList {
    return new FinishingAndFinishingTemplateLabelOverviewList(finishingTemplateLabelJSON.finishingLabelType);
  }

  public toJSON(): JSON {
    return {
      finishingLabelType: this._finishingLabelType
    } as any as JSON;
  }

  public static fromLabel(label: FinishingLabel): FinishingAndFinishingTemplateLabelOverviewList {
    return label ? new FinishingAndFinishingTemplateLabelOverviewList(label.finishingLabelType) : null;
  }
}
