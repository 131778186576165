import {BaseMachineType} from './base-machine-type';
import {EquipmentManufacturer} from './equipment-manufacturer';
import {StandardSpeed} from './standard-speed.enum';
import {Technology} from './technology';

export class PlasticsMachineType extends BaseMachineType {
  private _standardSpeed: StandardSpeed;
  private _technology: Technology;

  public constructor(id: number, name: string, standardSpeed: StandardSpeed, manufacturer: EquipmentManufacturer, technology: Technology) {
    super(id, name, manufacturer, '');
    this._standardSpeed = standardSpeed;
    this._technology = technology;
  }

  public get standardSpeed(): StandardSpeed {
    return this._standardSpeed;
  }

  public set standardSpeed(standardSpeed: StandardSpeed) {
    this._standardSpeed = standardSpeed;
  }

  public get technology(): Technology {
    return this._technology;
  }

  public set technology(technology: Technology) {
    this._technology = technology;
  }

  public static fromJSON(plasticsMachineTypeJSON: any): PlasticsMachineType {
    return new PlasticsMachineType(
      plasticsMachineTypeJSON.id,
      plasticsMachineTypeJSON.name,
      plasticsMachineTypeJSON.speedKind,
      new EquipmentManufacturer(plasticsMachineTypeJSON.manufacturerId, plasticsMachineTypeJSON.manufacturerName, []),
      new Technology(plasticsMachineTypeJSON.technologyId, plasticsMachineTypeJSON.technologyName)
    );
  }

  public toJSON(): JSON {
    const machineTypeJSON = super.toJSON();
    machineTypeJSON['speedKind'] = this.standardSpeed;
    machineTypeJSON['technologyId'] = this.technology?.id;
    return machineTypeJSON;
  }
}
