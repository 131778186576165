import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';
import {BreakpointResponsivenessViewModeService} from '../../common/responsiveness/breakpoint-responsiveness-view-mode.service';
import {RESPONSIVENESS_VIEW_MODE} from '../../common/responsiveness/responsiveness-view.mode';
import {CanShowErrorForFormControlModule} from '../../custom-directives/can-show-error-for-context/can-show-error-for-form-control.module';
import {LocalStorageModule} from '../../custom-services/local-storage.service/local-storage.module';
import {DialogModule} from '../../dialogs/dialog.module';
import {CommonFormsModule} from '../../forms/common-forms.module';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {FooterModule} from '../footer/footer.module';
import {HeaderModule} from '../header/header.module';
import {AddColorComponent} from './add-color/add-color.component';
import {SliderTrackBackgroundDirective} from './add-color/slider-track-background.directive';
import {ColorPickerPopupComponent} from './popup-component/color-picker-popup.component';

@NgModule({
  exports: [AddColorComponent],
  declarations: [AddColorComponent, ColorPickerPopupComponent, SliderTrackBackgroundDirective],
  providers: [UntypedFormBuilder, {provide: RESPONSIVENESS_VIEW_MODE, useClass: BreakpointResponsivenessViewModeService}],
  imports: [
    CanShowErrorForFormControlModule,
    CommonModule,
    FlexLayoutModule,
    CommonFormsModule,
    ReactiveFormsModule,
    LocalStorageModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSliderModule,
    ReactiveFormsModule,
    TranslationModule,
    DialogModule,
    FooterModule,
    LabelModule,
    HeaderModule
  ]
})
export class ColorPickerModule {}
