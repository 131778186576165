import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CommonFormsModule} from '../../forms/common-forms.module';
import {TranslationModule} from '../../translation/translation.module';
import {ProgressButtonComponent} from './progress-button.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatToolbarModule, MatButtonModule, TranslationModule, MatDialogModule, CommonFormsModule, MatProgressSpinnerModule],
  exports: [ProgressButtonComponent],
  declarations: [ProgressButtonComponent]
})
export class ProgressButtonModule {}
