import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslationModule} from '../../translation/translation.module';
import {DistanceSpecifierComponent} from './distance-specifier.component';

@NgModule({
  declarations: [DistanceSpecifierComponent],
  imports: [CommonModule, MatTooltipModule, TranslationModule],
  exports: [DistanceSpecifierComponent]
})
export class DistanceSpecifierModule {}
