import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {BreadcrumbComponent} from './breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, LabelModule, TranslationModule],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule {}
