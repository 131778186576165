import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  template: ''
})
export class EmptyCellRendererComponent implements ICellRendererAngularComp {
  // eslint-disable-next-line no-empty-function
  public agInit(_: ICellRendererParams): void {}

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
