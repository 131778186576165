import {InjectionToken} from '@angular/core';
import {GridModelRepository} from '@infrastructure/http/grid-model-repository';
import {Repository} from '@infrastructure/http/repository';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {Color} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';
import {NameGenerationRepository} from '../name-generation-repository';

export const COLORS = new InjectionToken<Colors>('Colors');

export interface Colors
  extends Repository<Color, Color>,
    UniqueIdentifierObjectRepository,
    ConflictsRepository,
    GridModelRepository<Color>,
    CustomSettingsObjectRepository,
    NameGenerationRepository<Color> {
  getPossibleValues(columnIdentifier: string): Observable<string[]>;
}
