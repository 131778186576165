<ng-container [ngSwitch]="patternVisualisation.type">
  <div *ngSwitchCase="patternVisualisationType.COLOR" [style.background]="getPatternVisualisationRepresentation()" class="color-pattern-visualisation full-width full-height"></div>
  <ng-container *ngSwitchCase="patternVisualisationType.PATTERN">
    <div *ngIf="isThumbnailViewMode(); else fullPreview" [appThumbnailSvgLoader]="patternVisualisation['svg']" class="svg-pattern-visualisation-thumbnail"></div>
  </ng-container>
  <ng-container *ngSwitchCase="patternVisualisationType.MANCHON">
    <div *ngIf="isThumbnailViewMode(); else fullPreview" [appSvgLoader]="patternVisualisation['svg']" class="svg-pattern-visualisation-thumbnail"></div>
  </ng-container>

  <div *ngSwitchDefault [style.background-image]="getPatternVisualisationRepresentation()" class="image-drawing-pattern-visualisation full-width full-height"></div>
</ng-container>

<ng-template #fullPreview>
  <app-pannable-and-zoomable-pattern-preview [svg]="patternVisualisation['svg']"></app-pannable-and-zoomable-pattern-preview>
</ng-template>
