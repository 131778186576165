import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PriorityLevel} from '@domain/event/priority-level.enum';
import {BaseComponent, EnumUtils, SelectionListItem, ToastParams, ToastService, TranslateService} from '@vdw/angular-component-library';
import {take, takeUntil} from 'rxjs';
import {PushNotificationsService} from '../../push-notifications.service';
import {NotificationListFilterComponentMatDialogConfigDataInterface} from './notification-list-filter-component-mat-dialog-config-data.interface';

@Component({
  templateUrl: './notification-mobile-list-filter.component.html',
  styleUrls: ['./notification-mobile-list-filter.component.scss']
})
export class NotificationMobileListFilterComponent extends BaseComponent implements OnInit {
  public options: SelectionListItem[] = [];
  public enabledPriorities: SelectionListItem[] = [];

  public toggleValue = false;
  public isBrowserSupported = false;
  public isSubscriptionProcessing = false;

  private readonly toastParams: ToastParams = {
    tapToDismiss: true,
    timeOut: 4000,
    title: this.translate.instant('NOTIFICATIONS.SUBSCRIPTION_DISABLED_TOAST_TITLE'),
    message: this.translate.instant('NOTIFICATIONS.SUBSCRIPTION_DISABLED_TOAST_MESSAGE')
  };

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: NotificationListFilterComponentMatDialogConfigDataInterface,
    private readonly dialogRef: MatDialogRef<NotificationMobileListFilterComponent>,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService
  ) {
    super();
    this.options = data.priorities;
  }

  public ngOnInit(): void {
    this.isBrowserSupported = this.pushNotificationsService.isBrowserSupported();

    this.pushNotificationsService
      .subscriptionEnabledInBrowser()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), take(1))
      .subscribe((_: PushSubscription) => {
        this.toggleValue = Notification.permission === 'denied' ? false : this.pushNotificationsService.isSubscribed;
      });
  }

  public confirm(): void {
    this.dialogRef.close(this.options);
  }

  public onOptionChanged(value: SelectionListItem[]): void {
    this.options = value;
    this.setEnabledPriorities();
  }

  private setEnabledPriorities(): void {
    this.enabledPriorities = [];
    EnumUtils.getEnumNames(PriorityLevel).forEach((priorityLevel: PriorityLevel) => {
      const foundPriority = this.options.find((priority: SelectionListItem) => priority.name === `BMSCONFIG.EVENTS.PRIORITY_LEVEL.${priorityLevel}`);
      if (foundPriority?.value) {
        this.enabledPriorities.push(foundPriority);
        foundPriority.disabled = false;
      }
    });

    if (this.enabledPriorities.length === 1) {
      this.options.find((priority: SelectionListItem) => priority.value).disabled = true;
    }
  }

  public toggleSubscription(): void {
    if (Notification.permission === 'denied') {
      this.toggleValue = false;
      this.toastService.showError(this.toastParams);
      return;
    } else if (Notification.permission === 'default') {
      this.toggleValue = false;
    }

    this.isSubscriptionProcessing = true;

    if (this.pushNotificationsService.isSubscribed) {
      this.pushNotificationsService
        .unsubscribeToPushNotifications()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => (this.isSubscriptionProcessing = false));
    } else {
      this.pushNotificationsService
        .subscribeToPushNotifications()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: () => {
            this.toggleValue = true;
            this.isSubscriptionProcessing = false;
          },
          error: () => (this.isSubscriptionProcessing = false)
        });
    }
  }
}
