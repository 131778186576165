import {JacquardMachineManufacturerType} from '@domain/machine/jacquard-machine-manufacturer-type.enum';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineManufacturer} from '@domain/machine/machine-manufacturer';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineOverviewList} from './machine-overview-list';

export class WeavingMachineOverviewList extends MachineOverviewList {
  private _widthInCm: number;
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _numberOfFeeders: number;
  private _numberOfShafts: number;

  public constructor(
    id: number,
    name: string,
    manufacturer: MachineManufacturer,
    type: JacquardMachineManufacturerType,
    machineGroup: MachineGroup,
    machineType: MachineType,
    widthInCm: number,
    standardSpeed: number,
    numberOfShafts: number,
    numberOfFeeders: number,
    standardEfficiency: number
  ) {
    super(id, name, manufacturer, type, machineGroup, machineType);

    this._widthInCm = widthInCm;
    this._standardSpeed = standardSpeed;
    this._numberOfShafts = numberOfShafts;
    this._numberOfFeeders = numberOfFeeders;
    this._standardEfficiency = standardEfficiency;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public get numberOfShafts(): number {
    return this._numberOfShafts;
  }

  public get numberOfFeeders(): number {
    return this._numberOfFeeders;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }
}
