import {FinishingTemplatePositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-position-in-carpet';
import {LabelPositionInCarpet} from '@domain/textile-data/finishing-and-finishing-template/finishing/label-position-in-carpet.enum';
import {LabelPositionInLabelPlace} from '@domain/textile-data/finishing-and-finishing-template/label-position-in-label-place.enum';

export class FinishingPositionInCarpet extends FinishingTemplatePositionInCarpet {
  private _technicalHorizontalOffsetInDents: number;
  private _technicalVerticalOffsetInPicks: number;

  public constructor(
    positioningInCarpet: LabelPositionInCarpet,
    positioning: LabelPositionInLabelPlace,
    commercialHorizontalOffsetInMM: number,
    commercialVerticalOffsetInMm: number,
    technicalHorizontalOffsetInDents: number,
    technicalVerticalOffsetInPicks: number
  ) {
    super(positioningInCarpet, positioning, commercialHorizontalOffsetInMM, commercialVerticalOffsetInMm);
    this._technicalHorizontalOffsetInDents = technicalHorizontalOffsetInDents;
    this._technicalVerticalOffsetInPicks = technicalVerticalOffsetInPicks;
  }

  public get technicalHorizontalOffsetInDents(): number {
    return this._technicalHorizontalOffsetInDents;
  }

  public get technicalVerticalOffsetInPicks(): number {
    return this._technicalVerticalOffsetInPicks;
  }

  public static fromJSON(finishingPositionInCarpetJSON: any): FinishingPositionInCarpet {
    return new FinishingPositionInCarpet(
      finishingPositionInCarpetJSON.positioningInCarpet as LabelPositionInCarpet,
      finishingPositionInCarpetJSON.positioningInLabelPlace as LabelPositionInLabelPlace,
      finishingPositionInCarpetJSON.commercialHorizontalOffsetInMM,
      finishingPositionInCarpetJSON.commercialVerticalOffsetInMM,
      finishingPositionInCarpetJSON.technicalHorizontalOffsetInDents,
      finishingPositionInCarpetJSON.technicalVerticalOffsetInPicks
    );
  }

  public toJSON(): JSON {
    return {
      positioningInCarpet: this.positioningInCarpet,
      positioningInLabelPlace: this.positioningInLabelPlace,
      commercialHorizontalOffsetInMM: this.commercialHorizontalOffsetInMM,
      technicalHorizontalOffsetInDents: this.technicalHorizontalOffsetInDents,
      commercialVerticalOffsetInMM: this.commercialVerticalOffsetInMM,
      technicalVerticalOffsetInPicks: this.technicalVerticalOffsetInPicks
    } as any as JSON;
  }
}
