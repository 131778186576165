@if (limited) {
  <div class="limited-menu">
    <ng-container *ngTemplateOutlet="colorItems; context: {tone: 500}"></ng-container>
  </div>
} @else {
  <div class="full-menu">
    <ng-container *ngTemplateOutlet="colorItems; context: {tone: 200}"></ng-container>
    <ng-container *ngTemplateOutlet="colorItems; context: {tone: 400}"></ng-container>
    <ng-container *ngTemplateOutlet="colorItems; context: {tone: 600}"></ng-container>
    <ng-container *ngTemplateOutlet="colorItems; context: {tone: 800}"></ng-container>
  </div>
}
<ng-template #colorItems let-tone="tone">
  <div class="color-items">
    @for (color of getPresetColorsWithTone(tone); track $index) {
      <div class="color-item" [class.selected]="isColorSelected(color)" [style.background-color]="color" (click)="selectColor(color)">
        <mat-icon svgIcon="solid-check-small"></mat-icon>
      </div>
    }
  </div>
</ng-template>
