import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ArticleRoute extends RouteBranch {
  private _addArticle: RouteLeaf;
  private _duplicateArticle: RouteLeaf;
  private _editArticle: RouteLeaf;

  public get addArticle(): RouteLeaf {
    return this._addArticle;
  }

  public set addArticle(value: RouteLeaf) {
    this.replace(this._addArticle, value);
    this._addArticle = value;
  }

  public get duplicateArticle(): RouteLeaf {
    return this._duplicateArticle;
  }

  public set duplicateArticle(value: RouteLeaf) {
    this.replace(this._duplicateArticle, value);
    this._duplicateArticle = value;
  }

  public get editArticle(): RouteLeaf {
    return this._editArticle;
  }

  public set editArticle(value: RouteLeaf) {
    this.replace(this._editArticle, value);
    this._editArticle = value;
  }
}
