import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {FixedSchedulePlaceholder} from '@domain/planning-prototype/carpet/fixed-schedule-placeholder';
import {RunCarpet} from '@domain/planning-prototype/carpet/run-carpet';
import {GenericOrder} from '@domain/planning-prototype/generic-order.interface';
import {Maintenance} from '@domain/planning-prototype/maintenance';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {PlanningItemFactory} from '@domain/planning-prototype/planning-item-factory';
import {PlanningItemForecast} from '@domain/planning-prototype/planning-item-forecast';
import {PlanningLine} from '@domain/planning-prototype/planning-line';
import {CompatibleMachine} from '@domain/planning-prototype/to-plan/compatible-machine';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {ArrayUtils, AssertionUtils, DragDropData, PlanningDragDropService, PlanningGridComponent, PlanningGroupData, PlanningItemData, Point} from '@vdw/angular-component-library';
import moment from 'moment';
import {take, takeUntil} from 'rxjs';
import {PlanningPrototypeContextService} from './planning-prototype-context.service';

@Injectable()
export class PlanningItemDragDropService {
  public get dragActive(): boolean {
    return this.planningDragDrop.dragActive;
  }

  private planningGrid: PlanningGridComponent;
  private newRunText: string;

  public constructor(
    private readonly planningContext: PlanningPrototypeContextService,
    private readonly planningDragDrop: PlanningDragDropService
  ) {}

  public init(grid: PlanningGridComponent, newRunText: string): void {
    this.planningGrid = grid;
    this.newRunText = newRunText;
  }

  public onDragStart(event: DragEvent, toPlan: ProductionOrderToPlan | FixedScheduleToPlan, template: TemplateRef<any>, viewContainer: ViewContainerRef): void {
    let planningItem: PlanningItem;
    let duration: string;
    if (toPlan instanceof ProductionOrderToPlan) {
      planningItem = PlanningItemFactory.createOrderForToPlan(toPlan);
      duration = (planningItem as GenericOrder).estimatedProductionTime;
    } else {
      planningItem = PlanningItemFactory.createPlaceholderForFixedSchedule(toPlan.fixedSchedule);
      duration = planningItem.minimumDuration;
    }

    this.planningContext.sortedPlanningItemForecasts[planningItem.draftId] = new PlanningItemForecast(planningItem.draftId, new Date(0), new Date(moment.duration(duration).asMilliseconds()));
    const planningItemData = new PlanningItemData();
    planningItemData.template = template;
    planningItemData.draggable = true;
    planningItemData.dataTransfer = planningItem;

    const planningGroupData = new PlanningGroupData();
    planningGroupData.draggable = true;
    planningGroupData.class = 'run';

    const startPositionInContainer: Point = {
      x: event.clientX - this.planningGrid.bodyContainer.nativeElement.parentElement.getBoundingClientRect().x + this.planningGrid.bodyContainer.nativeElement.parentElement.scrollLeft,
      y: event.clientY - this.planningGrid.bodyContainerOffset.y + this.planningGrid.bodyContainer.nativeElement.parentElement.scrollTop
    };

    const dragEndEvent = this.planningDragDrop.dragEnd.pipe(take(1));
    this.planningDragDrop.drag.pipe(takeUntil(dragEndEvent)).subscribe((data: DragDropData) => this.onDragMove(data));

    dragEndEvent.subscribe((data: DragDropData) => this.onDragEnd(data));
    this.planningDragDrop.onDragStart(event, planningItemData, planningGroupData, null, 250, startPositionInContainer, this.newRunText, this.planningGrid, viewContainer);
  }

  public onDragEnd(data: DragDropData): void {
    if (!data.dropAllowed || AssertionUtils.isNullOrUndefined(data.draggedItem) || AssertionUtils.isNullOrUndefined(data.targetRow)) {
      return;
    }

    let {draggedItem, sourceList, targetList, sourceLine, targetLine, targetItem} = this.readDragDropData(data);

    targetList = this.addNewRunIfRequired(draggedItem, targetItem, targetList, data, targetLine);
    if (!AssertionUtils.isEmpty(sourceList)) {
      this.planningContext.removeItemFromList(draggedItem, sourceList);
    }
    this.planningContext.addItemToList(draggedItem, targetList, data.targetTime);
    this.updateEstimatedProductionTime(draggedItem, targetLine.parentEquipment);
    this.setEarliestStartDate(draggedItem, data.shiftKey, data.targetTime);

    this.reduceFlickeringBySettingATemporaryForecastOnDropLocation(draggedItem, data.targetTime, this.planningContext.sortedPlanningItemForecasts[draggedItem.draftId]);
    const updatedMachines = ArrayUtils.distinctBy(
      [sourceLine, targetLine].filter((line: PlanningLine) => !AssertionUtils.isNullOrUndefined(line)),
      (line: PlanningLine) => line.id
    ).map((line: PlanningLine) => line.parentEquipment);

    for (const machine of updatedMachines) {
      this.planningContext.markAsChanged(machine);
    }
  }

  public onDragMove(data: DragDropData): void {
    if (AssertionUtils.isNullOrUndefined(data.targetRow)) {
      return;
    }
    const {draggedItem, targetLine, targetItem, sourceLine} = this.readDragDropData(data);
    data.dropAllowed &&= this.isItemCompatible(draggedItem, targetLine, targetItem, sourceLine);
    this.setDragIcon(data);
  }

  private readDragDropData(data: DragDropData): {
    draggedItem: PlanningItem;
    sourceList: PlanningItem[];
    targetList: PlanningItem[];
    sourceLine: PlanningLine;
    targetLine: PlanningLine;
    targetItem?: PlanningItem;
  } {
    const draggedItem = data.draggedItem.dataTransfer as PlanningItem;
    const targetMachine = data.targetRow.dataTransfer as PlanningEquipment;
    const sourceMachine = data.sourceRow?.dataTransfer as PlanningEquipment;
    const targetItem = data.targetItem?.dataTransfer as PlanningItem;
    const sourceItem = data.sourceGroup?.dataTransfer as PlanningItem;

    const sourceLine = this.planningContext.planningForecast.planningLines.find((line: PlanningLine) => line.parentEquipment.id === sourceMachine?.id);
    const targetLine = this.planningContext.planningForecast.planningLines.find((line: PlanningLine) => line.parentEquipment.id === targetMachine.id);

    let sourceList = sourceItem?.planningItems ?? sourceLine?.planningItems;
    let targetList = targetItem?.planningItems ?? targetLine.planningItems;
    if (!PlanningItemHelper.canBeInRun(draggedItem)) {
      sourceList = sourceLine?.planningItems;
      targetList = targetLine.planningItems;
    }

    return {draggedItem, sourceList, targetList, sourceLine, targetLine, targetItem};
  }

  private setEarliestStartDate(draggedItem: PlanningItem, shiftKey: boolean, targetTime: Date): void {
    if (PlanningItemHelper.isOrderItem(draggedItem)) {
      return;
    }
    draggedItem.earliestStartDate = shiftKey ? targetTime : null;
  }

  private setDragIcon(data: DragDropData): void {
    if (data.dropAllowed) {
      data.dropEffect = data.shiftKey ? 'link' : 'move';
    }
  }

  private isItemCompatible(item: PlanningItem, targetLine: PlanningLine, targetItem: PlanningItem, sourceLine: PlanningLine): boolean {
    if (item instanceof Maintenance) {
      return PlanningItemHelper.getMaintenanceTypesForMachineType(targetLine.parentEquipment.equipmentKind).includes(item.maintenanceType);
    }
    if (!PlanningItemHelper.getMachineTypesForItem(item).includes(targetLine.parentEquipment.equipmentKind)) {
      return false;
    }

    if (item instanceof FixedSchedulePlaceholder) {
      return this.isFixedScheduleCompatibleWithTarget(item, targetLine, targetItem, sourceLine);
    }

    if (!PlanningItemHelper.isOrderItem(item)) {
      return true;
    }

    if (!this.isTargetMachineCompatible(item, targetLine, sourceLine)) {
      return false;
    }

    return PlanningItemHelper.isTargetItemCompatible(item, targetItem);
  }

  private isTargetMachineCompatible(item: GenericOrder, targetLine: PlanningLine, sourceLine: PlanningLine): boolean {
    if (sourceLine === targetLine) {
      return true;
    }
    const toPlan = this.planningContext.ordersToPlan.find(
      (order: ProductionOrderToPlan) => order.productionOrder.constructor === item.productionOrder.constructor && order.productionOrder.id === item.productionOrder.id
    );
    return !toPlan || toPlan.compatibleMachines.some((compatibleMachine: CompatibleMachine) => compatibleMachine.machine.id === targetLine.parentEquipment.id);
  }

  private reduceFlickeringBySettingATemporaryForecastOnDropLocation(item: PlanningItem, targetTime: Date, oldForecast: PlanningItemForecast): void {
    const tempEnd = new Date(targetTime.getTime() + (oldForecast.estimatedEnd.getTime() - oldForecast.estimatedStart.getTime()));
    this.planningContext.sortedPlanningItemForecasts[item.draftId] = new PlanningItemForecast(item.draftId, targetTime, tempEnd);
    const difference = targetTime.getTime() - oldForecast.estimatedStart.getTime();
    if (AssertionUtils.isEmpty(item.planningItems)) {
      return;
    }
    for (const child of item.planningItems) {
      const forecast = this.planningContext.sortedPlanningItemForecasts[child.draftId];
      const newStart = new Date(forecast.estimatedStart.getTime() + difference);
      const newEnd = new Date(forecast.estimatedEnd.getTime() + difference);
      this.planningContext.sortedPlanningItemForecasts[child.draftId] = new PlanningItemForecast(child.draftId, newStart, newEnd);
    }
  }

  private addNewRunIfRequired(draggedItem: PlanningItem, targetItem: PlanningItem, targetList: PlanningItem[], data: DragDropData, targetLine: PlanningLine): PlanningItem[] {
    if (!AssertionUtils.isNullOrUndefined(targetItem) || !PlanningItemHelper.canBeInRun(draggedItem)) {
      return targetList;
    }

    if (draggedItem instanceof FixedSchedulePlaceholder) {
      const toPlan = this.planningContext.fixedSchedulesToPlan.find((fixedScheduleToPlan: FixedScheduleToPlan) => fixedScheduleToPlan.fixedSchedule.id === draggedItem.fixedSchedule.id);
      targetItem = PlanningItemFactory.createRunForFixedSchedulePlaceholder(draggedItem, toPlan, targetLine.parentEquipment.id);
    } else {
      targetItem = PlanningItemFactory.createRunForPlanningItem(draggedItem);
    }
    this.planningContext.addItemToList(targetItem, targetList, data.targetTime);
    const forecast = this.planningContext.sortedPlanningItemForecasts[draggedItem.draftId];
    this.setEarliestStartDate(targetItem, data.shiftKey, data.targetTime);
    this.reduceFlickeringBySettingATemporaryForecastOnDropLocation(targetItem, data.targetTime, forecast);
    return targetItem.planningItems;
  }

  private updateEstimatedProductionTime(item: PlanningItem, machine: PlanningEquipment): void {
    if (!PlanningItemHelper.isOrderItem(item)) {
      return;
    }
    const toPlan = this.planningContext.ordersToPlan.find(
      (order: ProductionOrderToPlan) => order.productionOrder.constructor === item.productionOrder.constructor && order.productionOrder.id === item.productionOrder.id
    );
    if (toPlan) {
      item.estimatedProductionTime = PlanningItemHelper.getEstimatedProductionTime(toPlan, machine);
    }
  }

  private isFixedScheduleCompatibleWithTarget(item: FixedSchedulePlaceholder, targetLine: PlanningLine, targetItem: PlanningItem, sourceLine: PlanningLine): boolean {
    if (!AssertionUtils.isNullOrUndefined(sourceLine) && sourceLine !== targetLine) {
      return false;
    }

    const toPlan = this.planningContext.fixedSchedulesToPlan.find((fixedScheduleToPlan: FixedScheduleToPlan) => fixedScheduleToPlan.fixedSchedule.id === item.fixedSchedule.id);
    const targetMachine = this.planningContext.machines.find((machine: PlanningEquipment) => machine.id === targetLine.parentEquipment.id);
    if (AssertionUtils.isNullOrUndefined(targetMachine)) {
      return false;
    }
    const targetRun = this.planningContext.planningItemsByMachine[targetMachine.id].find((target: PlanningItem) => target.draftId === targetItem?.draftId) as RunCarpet;

    return PlanningItemHelper.isFixedScheduleCompatible(toPlan, targetMachine, targetRun?.creel, targetRun?.quality);
  }
}
