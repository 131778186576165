<section *ngIf="!canShowBMSTheme" class="flex-row statusbar full-width">
  <section class="flex-row gap-16 data-container">
    <div class="flex-row border-right" *ngIf="title">
      <h4 class="font-weight-bold mr-16">{{ title }}</h4>
    </div>
    <div class="flex-row border-right" *ngIf="totalGroupedRowsIsDefined()">
      <div class="data-element gap-4 flex-row">
        <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.GROUPED_ROWS' | bmsTranslate }}:</div>
        <div class="b2 font-weight-semibold mr-16">{{ statusBar?.totalGroupedRows }}</div>
      </div>
    </div>
    <div class="flex-row" [class.border-right]="canShowOnlySelected()">
      <div class="data-element gap-4 flex-row">
        <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.ROWS' | bmsTranslate }}:</div>
        <div class="b2 font-weight-semibold mr-8">{{ statusBar?.totalRows }}</div>
      </div>
      <div class="data-element gap-4 flex-row">
        <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.FILTERED' | bmsTranslate }}:</div>
        <div class="b2 font-weight-semibold mr-8">{{ statusBar?.filteredRows }}</div>
      </div>
      <div class="data-element gap-4 flex-row">
        <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.SELECTED' | bmsTranslate }}:</div>
        <div class="b2 font-weight-semibold" [ngClass]="canShowOnlySelected() ? 'mr-16' : 'mr-8'">{{ selectedRows ?? null }}</div>
      </div>
    </div>
    <div class="slide-toggle gap-8" *ngIf="canShowOnlySelected()">
      <mat-label class="b2 font-weight-semibold">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.ONLY_SHOW_SELECTED' | bmsTranslate }}:</mat-label>
      <mat-slide-toggle (change)="showSelectedRows($event.checked)" [checked]="initialToggleSelected"></mat-slide-toggle>
    </div>
  </section>

  <section class="flex-row gap-8 full-width">
    <section class="filter-chips gap-8 full-width">
      <vdw-filter-chips
        class="full-width chips"
        [chips]="advancedSearchFilters"
        [canMoveChips]="false"
        [compact]="true"
        [labelGetter]="getLabelForFilter"
        (deletedEvent)="deleteFilter($event)"
      ></vdw-filter-chips>
      <ng-container *ngIf="hasMultipleChips()">
        <div class="border-right horizontal-line"></div>
        <button class="clear-button" (click)="deleteAllFilters()">
          <label class="b2">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.CLEAR_ALL' | bmsTranslate }}</label>
        </button>
      </ng-container>
    </section>

    <ng-content></ng-content>
  </section>
</section>

<section *ngIf="canShowBMSTheme" class="bms-theme flex-row statusbar full-width" [class.large]="size === 'LARGE'" [class.compact]="size === 'COMPACT'">
  <div class="data-element gap-4 flex-row">
    <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.ROWS' | bmsTranslate }}:</div>
    <div class="b2 font-weight-semibold">{{ statusBar?.totalRows }}</div>
  </div>
  <div class="data-element gap-4 flex-row">
    <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.GROUPS' | bmsTranslate }}:</div>
    <div class="b2 font-weight-semibold">{{ statusBar?.totalGroupedRows ?? 0 }}</div>
  </div>
  <div class="data-element gap-4 flex-row">
    <div class="b2 font-weight-regular">{{ 'ANGULAR_COMPONENT_LIBRARY.STATUS_BAR.FILTER_RESULTS' | bmsTranslate }}:</div>
    <div class="b2 font-weight-semibold">{{ statusBar?.filteredRows }}</div>
  </div>
</section>
