import {Component} from '@angular/core';
import {ListOverviewAction} from '@presentation/pages/texfab/production-schedule/overview/list-overview-action';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './list-overview-action.component.html',
  styleUrls: ['./list-overview-action.component.scss']
})
export class ListOverviewActionsComponent implements ICellRendererAngularComp {
  public actions: ListOverviewAction[];

  public agInit(params: ICellRendererParams): void {
    this.actions = params.value;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
