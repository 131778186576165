import {Injectable} from '@angular/core';
import useMessage from '@messageformat/core';
import {L10nLocale, L10nTranslationService} from 'angular-l10n';
import {isArray, isString, reduce} from 'lodash-es';
import {Observable, of as observableOf} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TranslateService {
  private readonly l10nTranslationService: L10nTranslationService;

  public constructor(l10nTranslationService: L10nTranslationService) {
    this.l10nTranslationService = l10nTranslationService;
  }

  private static compile(params: any, value: any, language: string): string {
    let result: string;

    if (value && isString(value)) {
      result = new useMessage(language).compile(value)(params);
    } else {
      result = value;
    }
    return result;
  }

  public get(keys: string | string[], params?: any, language?: string): Observable<string | any> {
    let result: Observable<string | any>;
    if (isArray(keys)) {
      result = observableOf(
        reduce(
          keys,
          (translations: Record<string, unknown>, key: string) => {
            translations[key] = this.instant(key, params, language);
            return translations;
          },
          {}
        )
      );
    } else {
      result = observableOf(this.instant(keys, params, language));
    }
    return result;
  }

  public getLocale(): L10nLocale {
    return this.l10nTranslationService.getLocale();
  }

  public instant(keys: string | string[], params?: any, language?: string): string | any {
    const translatedParams = params ? {} : params;
    if (params) {
      Object.keys(params).forEach((key: string) => (translatedParams[key] = typeof params[key] === 'string' ? this.instant(params[key], language) : params[key]));
    }

    return TranslateService.compile(translatedParams, this.l10nTranslationService.translate(keys, translatedParams, language), language);
  }

  public setLocale(l10nLocale: L10nLocale): Promise<void> {
    return this.l10nTranslationService.setLocale(l10nLocale);
  }

  public has(key: string, language?: string): boolean {
    return this.l10nTranslationService.has(key, language);
  }
}
