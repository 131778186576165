import {EventEmitter, Injectable} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Injectable()
export class LazyLoadingScrollService {
  private pageSize: number;
  private rowHeight: number;
  private scrollContainer: HTMLElement;

  public scrolledToTop = new EventEmitter();
  public scrolledToBottom = new EventEmitter();
  public scrolled = new EventEmitter<number>();

  private scrollEventListener = (event: Event): void => this.scrolling(event);

  public init(pageSize: number, rowHeight: number, scrollContainer: HTMLElement): void {
    this.pageSize = pageSize;
    this.rowHeight = rowHeight;
    this.scrollContainer = scrollContainer;

    scrollContainer.addEventListener('scroll', this.scrollEventListener);
  }

  private scrolling(event: Event): void {
    this.scrolled.emit(Math.floor(this.scrollContainer.scrollTop / this.rowHeight));

    if (this.scrollContainer.scrollTop === 0) {
      this.scrolledToTop.emit();
    }

    if (this.scrollContainer.scrollTop > this.scrollContainer.scrollHeight - this.scrollContainer.offsetHeight) {
      this.scrolledToBottom.emit();
    }

    event.stopPropagation();
  }

  public resetScrollPosition(): void {
    if (!AssertionUtils.isNullOrUndefined(this.scrollContainer)) {
      this.scrollContainer.scrollTop = 1;
    }
  }

  public preserveScrollPosition(): void {
    this.scrollContainer.scrollTop = this.pageSize * this.rowHeight;
  }

  public removeListeners(): void {
    this.scrollContainer.removeEventListener('scroll', this.scrollEventListener);
  }
}
