import {IdName} from '@domain/id-name';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ObjectUtils} from '@vdw/angular-component-library';

export class ProductionOrder {
  private _id: number;
  private _name: string;
  private _creel: IdName;
  private _quality: IdName;
  private _status: ProductionScheduleStatus;
  private _plannedQuantityInPicks: number;
  private _dueDate?: Date;

  public constructor(id: number, name: string, creel: IdName, quality: IdName, status: ProductionScheduleStatus, plannedQuantityInPicks: number, dueDate?: Date) {
    this._id = id;
    this._name = name;
    this._creel = creel;
    this._quality = quality;
    this._status = status;
    this._plannedQuantityInPicks = plannedQuantityInPicks;
    this._dueDate = dueDate;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get creel(): IdName {
    return this._creel;
  }

  public get quality(): IdName {
    return this._quality;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get plannedQuantityInPicks(): number {
    return this._plannedQuantityInPicks;
  }

  public static fromJSON(json: any): ProductionOrder {
    return new ProductionOrder(
      json.id,
      json.name,
      IdName.fromJSON(json.creel),
      IdName.fromJSON(json.quality),
      json.status,
      json.plannedQuantityInPicks,
      ObjectUtils.parseNullable(json.dueDate, (dueDate: any) => new Date(dueDate))
    );
  }
}
