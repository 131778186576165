import {IdNameVersion} from '@domain/id-name-version';
import {Gauge} from '@domain/machine/gauge';
import {TuftProduct} from '@domain/textile-data/tuft-product/tuft-product';
import {AssertionUtils} from '@vdw/angular-component-library';

export class OverviewListTuftProduct {
  private _id: number;
  private _name: string;
  private _version: number;
  private _description: string;
  private _color: string;
  private _blend: string;
  private _widthInCm: number;
  private _gauge: Gauge;
  private _stitchRateInStitchesPerCm: number;
  private _pileHeightInCm: number;
  private _weightInGramsPerMeter: number;
  private _primaryBacking: IdNameVersion;
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _tuftingNotes: string;
  private _backingNotes: string;

  public constructor(
    id: number,
    name: string,
    version: number,
    description: string,
    color: string,
    blend: string,
    widthInCm: number,
    gauge: Gauge,
    stitchRateInStitchesPerCm: number,
    pileHeightInCm: number,
    weightInGramsPerMeter: number,
    primaryBacking: IdNameVersion,
    standardSpeed: number,
    standardEfficiency: number,
    tuftingNotes: string,
    backingNotes: string
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._description = description;
    this._color = color;
    this._blend = blend;
    this._widthInCm = widthInCm;
    this._gauge = gauge;
    this._stitchRateInStitchesPerCm = stitchRateInStitchesPerCm;
    this._pileHeightInCm = pileHeightInCm;
    this._weightInGramsPerMeter = weightInGramsPerMeter;
    this._primaryBacking = primaryBacking;
    this._standardSpeed = standardSpeed;
    this._standardEfficiency = standardEfficiency;
    this._tuftingNotes = tuftingNotes;
    this._backingNotes = backingNotes;
  }

  public static fromJSON(tuftProductJSON: any): OverviewListTuftProduct {
    return new OverviewListTuftProduct(
      tuftProductJSON.id,
      tuftProductJSON.name,
      tuftProductJSON.version,
      tuftProductJSON.description,
      tuftProductJSON.color,
      tuftProductJSON.blend,
      tuftProductJSON.widthInCm,
      AssertionUtils.isNullOrUndefined(tuftProductJSON.gauge) ? null : Gauge.fromJSON(tuftProductJSON.gauge),
      tuftProductJSON.stitchRateInStitchesPerCm,
      tuftProductJSON.pileHeightInCm,
      tuftProductJSON.weightInGramsPerMeter,
      AssertionUtils.isNullOrUndefined(tuftProductJSON.primaryBacking) ? null : IdNameVersion.fromJSON(tuftProductJSON.primaryBacking),
      tuftProductJSON.standardSpeed,
      tuftProductJSON.standardEfficiency,
      tuftProductJSON.tuftingNotes,
      tuftProductJSON.backingNotes
    );
  }

  public static fromTuftProduct(tuftProduct: TuftProduct): OverviewListTuftProduct {
    return new OverviewListTuftProduct(
      tuftProduct.id,
      tuftProduct.name,
      tuftProduct.version,
      tuftProduct.description,
      tuftProduct.color,
      tuftProduct.blend,
      tuftProduct.widthInCm,
      tuftProduct.gauge,
      tuftProduct.stitchRateInStitchesPerCm,
      tuftProduct.pileHeightInCm,
      tuftProduct.weightInGramsPerMeter,
      new IdNameVersion(tuftProduct.primaryBacking.id, tuftProduct.primaryBacking.name, tuftProduct.primaryBacking.version),
      tuftProduct.standardSpeed,
      tuftProduct.standardEfficiency,
      tuftProduct.tuftingNotes,
      tuftProduct.backingNotes
    );
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get description(): string {
    return this._description;
  }

  public get color(): string {
    return this._color;
  }

  public get blend(): string {
    return this._blend;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public get gauge(): Gauge {
    return this._gauge;
  }

  public get stitchRateInStitchesPerCm(): number {
    return this._stitchRateInStitchesPerCm;
  }

  public get pileHeightInCm(): number {
    return this._pileHeightInCm;
  }

  public get weightInGramsPerMeter(): number {
    return this._weightInGramsPerMeter;
  }

  public get primaryBacking(): IdNameVersion {
    return this._primaryBacking;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public get tuftingNotes(): string {
    return this._tuftingNotes;
  }

  public get backingNotes(): string {
    return this._backingNotes;
  }

  public canDuplicate(): boolean {
    return true;
  }
}
