import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {PropertyValue} from '@domain/property-value';
import {TranslateService, Unit} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {BaseAdvancedSearchComponent} from './base-advanced-search.component';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './base-advanced-search.component.html',
  styleUrls: ['./base-advanced-search.component.scss']
})
export class AdvancedSearchComponent extends BaseAdvancedSearchComponent implements OnInit {
  @Input() public advancedSearchFilters: PropertyValue[] = [];
  @Input() public defaultUnit: Unit = Unit.CENTIMETER;
  @Output() public advancedSearchClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() public update: EventEmitter<PropertyValue[]> = new EventEmitter<PropertyValue[]>();

  public constructor(formBuilder: UntypedFormBuilder, translate: TranslateService) {
    super(formBuilder, translate);
  }

  public ngOnInit(): void {
    if (isEqual(this.defaultUnit, Unit.CENTIMETER)) {
      this.allowDecimalCases = true;
      this.maximumFractionDigits = 2;
    }

    super.ngOnInit();
  }

  public clearAdvancedSearchFilters(): void {
    this.clearAdvancedSearchFilterValues();

    this.update.emit(this.advancedSearchFilters);
  }

  public applyAdvancedSearchFilters(): void {
    this.updateAdvancedSearchFilterValues();

    this.update.emit(this.advancedSearchFilters);
  }

  public closeAdvancedSearch(): void {
    this.advancedSearchClose.emit();
  }
}
