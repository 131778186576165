import {StringUtils} from '@application/helper/string-utils';
import {IdName} from '@domain/id-name';
import {CarpetWeaveQuality} from '@domain/textile-data/machine-quality/carpet-weave-quality';
import {OverviewListDoubleWidthWeaveStructure} from '@domain/textile-data/weave-structure/overview-list-double-width-weave-structure';
import {OverviewListSingleWidthWeaveStructure} from '@domain/textile-data/weave-structure/overview-list-single-width-weave-structure';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {WeaveStructureWidth} from '../add/machine-quality-general/weave-structure-width.enum';
import {WeaveQualityType} from './weave-quality-type.enum';

export class OverviewListMachineQuality {
  private readonly _id: number;
  private readonly _technicalName: string;
  private readonly _version: number;
  private readonly _type: WeaveQualityType;
  private readonly _weaveStructureWidth: WeaveStructureWidth;
  private readonly _singleWidth: OverviewListSingleWidthWeaveStructure;
  private readonly _doubleWidth: OverviewListDoubleWidthWeaveStructure;
  private readonly _reedDensityInDentsPerMM: number;
  private readonly _pickDensityInPicksPerMM: number;
  private readonly _weftDensityInDentsPerMM: number;
  private readonly _applicableMachines: MachineOverviewList[];
  private readonly _practicalPickDensityInPicksPerMM: number;
  private readonly _pileHeightInMM: number;
  private readonly _alternativeMachineQualityIds: number[];
  private readonly _used: boolean;
  private readonly _canAddJute: boolean;
  private readonly _technicalCode: string;
  private readonly _pileRowDensityInPileRowsPerMeter: number;
  private readonly _dateModified: Date;
  private readonly _alternativeQualities: IdName[];
  private readonly _juteConditionIds: number[];
  private readonly _finishings: IdName[];
  private readonly _yarnSet: IdName;

  public constructor(
    id: number,
    technicalName: string,
    version: number,
    type: WeaveQualityType,
    weaveStructureWidth: WeaveStructureWidth,
    singleWidth: OverviewListSingleWidthWeaveStructure,
    doubleWidth: OverviewListDoubleWidthWeaveStructure,
    reedDensity: number,
    pickDensityInPicksPerMM: number,
    weftDensityInDentsPerMM: number,
    practicalPickDensityInPicksPerMM: number,
    pileHeightInMM: number,
    applicableMachines: MachineOverviewList[],
    alternativeMachineQualityIds: number[],
    used: boolean,
    canAddJute?: boolean,
    dateModified?: Date,
    yarnSet?: IdName,
    technicalCode?: string,
    pileRowDensityInPileRowsPerMeter?: number,
    alternativeQualities?: IdName[],
    juteConditionIds?: number[],
    finishings?: IdName[]
  ) {
    this._id = id;
    this._technicalName = technicalName;
    this._version = version;
    this._type = type;
    this._weaveStructureWidth = weaveStructureWidth;
    this._singleWidth = singleWidth;
    this._doubleWidth = doubleWidth;
    this._reedDensityInDentsPerMM = reedDensity;
    this._pickDensityInPicksPerMM = pickDensityInPicksPerMM;
    this._weftDensityInDentsPerMM = weftDensityInDentsPerMM;
    this._practicalPickDensityInPicksPerMM = practicalPickDensityInPicksPerMM;
    this._pileHeightInMM = pileHeightInMM;
    this._applicableMachines = applicableMachines;
    this._alternativeMachineQualityIds = alternativeMachineQualityIds;
    this._used = used;
    this._canAddJute = canAddJute;
    this._technicalCode = technicalCode;
    this._pileRowDensityInPileRowsPerMeter = pileRowDensityInPileRowsPerMeter;
    this._dateModified = dateModified;
    this._alternativeQualities = alternativeQualities;
    this._juteConditionIds = juteConditionIds;
    this._finishings = finishings;
    this._yarnSet = yarnSet;
  }

  public get id(): number {
    return this._id;
  }

  public get technicalName(): string {
    return this._technicalName;
  }

  public get version(): number {
    return this._version;
  }

  public get technicalNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this.technicalName, this.version);
  }

  public get type(): WeaveQualityType {
    return this._type;
  }

  public get weaveStructureWidth(): WeaveStructureWidth {
    return this._weaveStructureWidth;
  }

  public get singleWidth(): OverviewListSingleWidthWeaveStructure {
    return this._singleWidth;
  }

  public get doubleWidth(): OverviewListDoubleWidthWeaveStructure {
    return this._doubleWidth;
  }

  public get reedDensityInDentsPerMM(): number {
    return this._reedDensityInDentsPerMM;
  }

  public get pickDensityInPicksPerMM(): number {
    return this._pickDensityInPicksPerMM;
  }

  public get weftDensityInDentsPerMM(): number {
    return this._weftDensityInDentsPerMM;
  }

  public get practicalPickDensityInPicksPerMM(): number {
    return this._practicalPickDensityInPicksPerMM;
  }

  public get pileHeightInMM(): number {
    return this._pileHeightInMM;
  }

  public get applicableMachines(): MachineOverviewList[] {
    return this._applicableMachines;
  }

  public get alternativeMachineQualityIds(): number[] {
    return this._alternativeMachineQualityIds;
  }

  public get used(): boolean {
    return this._used;
  }

  public get canAddJute(): boolean {
    return this._canAddJute;
  }

  public get technicalCode(): string {
    return this._technicalCode;
  }

  public get pileRowDensityInPileRowsPerMeter(): number {
    return this._pileRowDensityInPileRowsPerMeter;
  }

  public get dateModified(): Date {
    return this._dateModified;
  }

  public get alternativeQualities(): IdName[] {
    return this._alternativeQualities;
  }

  public get juteConditionIds(): number[] {
    return this._juteConditionIds;
  }

  public get finishings(): IdName[] {
    return this._finishings;
  }

  public get yarnSet(): IdName {
    return this._yarnSet;
  }

  public static fromJSON(machineQualityJSON: any): OverviewListMachineQuality {
    return new OverviewListMachineQuality(
      machineQualityJSON.id,
      machineQualityJSON.technicalName,
      machineQualityJSON.version,
      machineQualityJSON.type,
      machineQualityJSON.weaveStructureWidthType ? machineQualityJSON.weaveStructureWidthType : null,
      this.getBackwardsSingleWidthWeaveStructure(machineQualityJSON),
      machineQualityJSON.doubleWidth ? OverviewListDoubleWidthWeaveStructure.fromJSON(machineQualityJSON) : null,
      machineQualityJSON.reedDensityInDentsPerMM,
      machineQualityJSON.theoreticalPickDensityInPicksPerMM,
      machineQualityJSON.weftDensityInDentsPerMM,
      machineQualityJSON.practicalPickDensityInPicksPerMM,
      machineQualityJSON.pileHeightInMM,
      machineQualityJSON.applicableMachines,
      machineQualityJSON.alternativeMachineQualityIds,
      machineQualityJSON.used,
      machineQualityJSON.canAddJute ?? false,
      machineQualityJSON.dateModified ? new Date(machineQualityJSON.dateModified) : null,
      machineQualityJSON.yarnSet ? IdName.fromJSON(machineQualityJSON.yarnSet) : null,
      machineQualityJSON.technicalCode,
      machineQualityJSON.pileRowDensityInPileRowsPerMeter,
      machineQualityJSON.alternativeQualities?.map((alternativeQualityJSON: any) => IdName.fromJSON(alternativeQualityJSON)),
      machineQualityJSON.juteConditions?.map((juteConditionJSON: any) => juteConditionJSON.id),
      machineQualityJSON.finishings?.map((finishingJSON: any) => IdName.fromJSON(finishingJSON))
    );
  }

  public static fromCarpetWeaveQuality(carpetWeaveQuality: CarpetWeaveQuality): OverviewListMachineQuality {
    return new OverviewListMachineQuality(
      carpetWeaveQuality.id,
      carpetWeaveQuality.technicalName,
      carpetWeaveQuality.version,
      WeaveQualityType.CARPET,
      carpetWeaveQuality.weaveStructureWidth,
      carpetWeaveQuality.singleWidth ? OverviewListSingleWidthWeaveStructure.fromJSON(carpetWeaveQuality) : null,
      carpetWeaveQuality.doubleWidth ? OverviewListDoubleWidthWeaveStructure.fromJSON(carpetWeaveQuality) : null,
      carpetWeaveQuality.reedDensityInDentsPerMM,
      carpetWeaveQuality.pickDensityInPicksPerMM,
      carpetWeaveQuality.weftDensityInDentsPerMM,
      carpetWeaveQuality.practicalPickDensityInPicksPerMM,
      carpetWeaveQuality.pileHeightInMM,
      carpetWeaveQuality.applicableMachines,
      carpetWeaveQuality.alternativeMachineQualityIds,
      carpetWeaveQuality.used,
      carpetWeaveQuality.canAddJute ?? false
    );
  }

  public static getBackwardsSingleWidthWeaveStructure(weaveQualityJSON: any): OverviewListSingleWidthWeaveStructure {
    if (!weaveQualityJSON.singleWidth && !weaveQualityJSON.doubleWidth) {
      return weaveQualityJSON.weaveStructure ? OverviewListSingleWidthWeaveStructure.fromOldJSON(weaveQualityJSON) : null;
    } else if (!weaveQualityJSON.singleWidth && weaveQualityJSON.doubleWidth) {
      return null;
    } else {
      return OverviewListSingleWidthWeaveStructure.fromJSON(weaveQualityJSON);
    }
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
