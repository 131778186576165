import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ProductionOrderYield} from './production-order-yield';

export abstract class PlanningOrderData {
  private _id: number;
  private _name: string;
  private _dueDate: Date;
  private _status: ProductionScheduleStatus;
  private _yield: ProductionOrderYield;

  public constructor(id: number, name: string, dueDate: Date, status: ProductionScheduleStatus) {
    this._id = id;
    this._name = name;
    this._dueDate = dueDate;
    this._status = status;
    this._yield = {current: null, planned: null};
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(status: ProductionScheduleStatus) {
    this._status = status;
  }

  public get yield(): ProductionOrderYield {
    return this._yield;
  }

  public set yield(productionOrderYield: ProductionOrderYield) {
    this._yield = productionOrderYield ? {current: Math.round(productionOrderYield.current * 100) / 100, planned: productionOrderYield.planned} : null;
  }

  public get completed(): boolean {
    return this._status === ProductionScheduleStatus.EXECUTED_COMPLETE || this._status === ProductionScheduleStatus.EXECUTED_PARTIAL || this._status === ProductionScheduleStatus.EXECUTED;
  }

  public get executing(): boolean {
    return this._status === ProductionScheduleStatus.EXECUTING;
  }

  public get processing(): boolean {
    return this._status === ProductionScheduleStatus.PROCESSING;
  }

  public get queuing(): boolean {
    return this._status === ProductionScheduleStatus.QUEUING;
  }

  public canBeProcessed(): boolean {
    return this._status === ProductionScheduleStatus.CREATED;
  }

  public needsToBeReconfirmed(): boolean {
    return this._status === ProductionScheduleStatus.TO_RECONFIRM;
  }

  public needsToBePlanned(): boolean {
    return this._status === ProductionScheduleStatus.TO_PLAN;
  }

  public abstract canPrintWeavingOrder(): boolean;

  public abstract canBeQueued(): boolean;

  public abstract toJSON(): JSON;
}
