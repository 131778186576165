import {MachineType} from '@domain/machine/machine-type.enum';
import {TimezoneUtils} from '@vdw/angular-component-library';
import {map} from 'lodash-es';
import {PlanningMachineRun} from '../planning-machine-run';
import {PlanningOrder} from '../planning-order';
import {PlanningWeaveProduct} from './planning-weave-product';

export class PlanningMachineWeavingRun extends PlanningMachineRun {
  private _weaveProduct: PlanningWeaveProduct;

  public constructor(id: number, weaveProduct: PlanningWeaveProduct, planningOrders: PlanningOrder[], startDate: Date, endDate: Date, compatibleWithMachine: boolean) {
    super(id, planningOrders, startDate, endDate, compatibleWithMachine);

    this._weaveProduct = weaveProduct;
  }

  public static fromJSON(planningMachineRunJSON: any, timezoneCode: string): PlanningMachineWeavingRun {
    const startDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.startDate, timezoneCode);
    const endDate = TimezoneUtils.convertToDateWithCurrentOffset(planningMachineRunJSON.endDate, timezoneCode);

    const planningOrders = map(planningMachineRunJSON.planningOrders, (planningOrderJSON: any) => {
      return PlanningOrder.fromWeavingJSON(planningOrderJSON, timezoneCode);
    });

    return new PlanningMachineWeavingRun(
      planningMachineRunJSON.id,
      PlanningWeaveProduct.fromJSON(planningMachineRunJSON.weaveProduct),
      planningOrders,
      startDate,
      endDate,
      planningMachineRunJSON.compatibleWithMachine
    );
  }

  public toJSON(timezoneCode: string): JSON {
    return {
      ...super.toJSON(timezoneCode),
      weaveProduct: this._weaveProduct?.toJSON()
    } as any as JSON;
  }

  public get weaveProduct(): PlanningWeaveProduct {
    return this._weaveProduct;
  }

  public isCompatibleWithMachineType(machineType: MachineType): boolean {
    return machineType === MachineType.WEAVING_MACHINE;
  }
}
