import {InjectionToken} from '@angular/core';
import {OnboardingType} from '@presentation/components/onboarding-dialog/onboarding-type';
import {Observable} from 'rxjs';
import {OnboardingConfig} from './onboarding-config';

export const ONBOARDING = new InjectionToken<Onboarding>('Onboarding');

export interface Onboarding {
  start(config: OnboardingConfig): void;
  stop(): void;
  canStartOnboarding(onboardingType: OnboardingType): boolean;
  onOnboardingRestart(): Observable<void>;
  restart(onboardingType: OnboardingType): void;
  completeTypeOfOnboarding(type: OnboardingType): void;
}
