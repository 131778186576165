import {CdkDragDrop, CdkDropList} from '@angular/cdk/drag-drop';
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AssertionUtils} from '../../../../common/utils/assertion-utils';
import {CdkDragDropHelperService} from '../cdk-drag-drop-helper-service/cdk-drag-drop-helper.service';
import {CdkDraggableDropListEntry} from '../cdk-drop-list-interfaces/cdk-draggable-drop-list-entry';
import {CdkDraggableListEntryDroppedData} from '../cdk-drop-list-interfaces/cdk-draggable-list-entry-dropped-data';

@Component({
  selector: 'vdw-cdk-draggable-drop-list',
  templateUrl: './cdk-draggable-drop-list.component.html',
  styleUrls: ['./cdk-draggable-drop-list.component.scss']
})
export class CdkDraggableDropListComponent implements OnInit, AfterViewInit {
  @Input() public linkedDropLists: CdkDropList[];
  @Input() public dropListContent: TemplateRef<any>;
  @Input() public dragPreviewContent: TemplateRef<any>;
  @Input() public dropListEntry: CdkDraggableDropListEntry<any>;

  @Output() public entryDropped = new EventEmitter<CdkDraggableListEntryDroppedData<any>>();

  @ViewChild(CdkDropList) private dropList: CdkDropList;

  private nestedDepth: number;

  public isExpanded: boolean;
  public calculatedDropAllowed: boolean;

  public get isEmpty(): boolean {
    return AssertionUtils.isEmpty(this.dropListEntry?.childEntries);
  }

  public constructor(protected readonly dragDropHelperService: CdkDragDropHelperService) {}

  public ngOnInit(): void {
    const nestedChildDepth = this.dragDropHelperService.getNestedDepth(this.dropListEntry);
    this.nestedDepth = AssertionUtils.isNullOrUndefined(nestedChildDepth) ? 1 : nestedChildDepth;
    this.calculatedDropAllowed = this.nestedDepth < (this.dragDropHelperService.maxNestedDepth ?? this.dragDropHelperService.DEFAULT_MAX_NESTED_DEPTH);
  }

  public ngAfterViewInit(): void {
    if (!AssertionUtils.isNullOrUndefined(this.dropListEntry) && !AssertionUtils.isNullOrUndefined(this.dropList)) {
      this.dragDropHelperService.register(this.dropListEntry.id, this.dropList);
    }
  }

  public dropped(event: CdkDragDrop<CdkDraggableDropListEntry<any>>): void {
    if (!this.calculatedDropAllowed || !this.dropListEntry.dropAllowed) {
      return;
    }

    const oldGroup = event.previousContainer.data;
    const droppedEntry = this.dragDropHelperService.draggedElement?.draggedEntry;

    this.entryDropped.emit({draggedEntry: droppedEntry, oldGroup, newGroup: this.dropListEntry, event} as CdkDraggableListEntryDroppedData<any>);
  }

  public dragStarted(): void {
    const nestedChildren = this.dragDropHelperService.getNestedChildEntries(this.dropListEntry);
    const nestedChildDepth = this.dragDropHelperService.getNestedDepth(nestedChildren[nestedChildren.length - 1]);

    this.dragDropHelperService.draggedElement = {draggedEntry: this.dropListEntry, relativeNestedDepth: nestedChildDepth - this.nestedDepth};
  }

  public dragEntered(): void {
    if (AssertionUtils.isNullOrUndefined(this.dragDropHelperService.draggedElement)) {
      return;
    }

    const absuluteNestedDepth = this.nestedDepth + this.dragDropHelperService.draggedElement.relativeNestedDepth;

    this.calculatedDropAllowed = absuluteNestedDepth < (this.dragDropHelperService.maxNestedDepth ?? this.dragDropHelperService.DEFAULT_MAX_NESTED_DEPTH);
  }

  public childEntryDropped(event: CdkDraggableListEntryDroppedData<any>): void {
    this.entryDropped.emit(event);
  }
}
