<virtual-scroller #scroll class="full-height scroller" [items]="notificationList" [enableUnequalChildrenSizes]="true" (vsEnd)="loadMore($event)">
  <div class="notification-wrapper" *ngFor="let notification of scroll.viewPortItems" [class.read]="notification.read">
    <div class="notification" (click)="openMachineDetailSidebar(notification)">
      <div class="notification-content">
        <div class="b1 font-weight-semibold"><mat-icon class="icon-16" [svgIcon]="getPriorityIconName(notification.priority)"></mat-icon>{{ notification.message | htmlWithVariablesToText }}</div>
        <div>
          <div class="b1 no-wrap">{{ notification.timestamp | minutesFromNow | async }}</div>
          <mat-icon *ngIf="notificationClickable" svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <div *ngIf="notification.description" class="description b2">{{ notification.description | htmlWithVariablesToText }}</div>
      <div *ngIf="!notification.read" class="mark-as-read">
        <span class="b2 url" (click)="markAsRead(notification.id, $event)">{{ 'NOTIFICATIONS.MARK_AS_READ' | bmsTranslate }}</span>
      </div>
    </div>
  </div>
</virtual-scroller>
