import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslationModule} from '../../translation/translation.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {InputChipModule} from '../input-chip/input-chip.module';
import {ObjectSelectionComponent} from '../object-selection/object-selection.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslationModule,
    MatButtonModule,
    MatIconModule,
    InputChipModule,
    MatMenuModule,
    LabelModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatInputModule
  ],
  exports: [ObjectSelectionComponent],
  declarations: [ObjectSelectionComponent]
})
export class ObjectSelectionModule {}
