import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';

export class AsyncGreaterThanValidator {
  public static createGreaterThanValidator(compareValue: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const value = control.value;

      if (value === null || isNaN(value)) {
        return of(null);
      }

      if (value > compareValue) {
        return of(null);
      } else {
        return of({greaterThan: {value, required: compareValue}});
      }
    };
  }
}
