import {Injectable} from '@angular/core';
import {Command} from '@application/command/command';
import {PropertyValue} from '@domain/property-value';
import isNil from 'lodash-es/isNil';

@Injectable()
export class GetPossiblePropertyValuesOfList implements Command<PropertyValue, any[]> {
  public execute(propertyValue: PropertyValue): any[] {
    return !isNil(propertyValue) ? propertyValue.possiblePropertyValues : [];
  }
}
