import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Equipment} from '@domain/machine/equipment';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {CreelForProductionSchedule} from '@domain/production-schedule/creel-for-production-schedule';
import {MachineQualityForProductionSchedule} from '@domain/production-schedule/machine-quality-for-production-schedule';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {ProductionScheduleProductConfiguration} from '@domain/production-schedule/production-schedule-product-configuration';
import {PropertyValue} from '@domain/property-value';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {BaseComponent} from '@vdw/angular-component-library';

@Component({
  template: ''
})
export class BaseAddProductionScheduleNewPageComponent extends BaseComponent {
  @Input() public productionScheduleInitialPreparationsForm: ProductionScheduleInitialPreparationsFormGroup;
  @Input() public listOfMachineQualityCustomSettings: PropertyValue[];

  protected hasProductionScheduleState: boolean;

  protected readonly MAX_LENGTH_FOR_PRODUCTION_ORDER_NAME = 24;

  private readonly ADD_PRODUCTION_SCHEDULE_URL = RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView.path;
  private readonly EDIT_PRODUCTION_SCHEDULE_URL = RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView.path;
  private readonly DUPLICATE_PRODUCTION_SCHEDULE_URL = RouteUtils.paths.texFab.productionOrder.duplicateProductionOrderNewView.path;

  public constructor(protected readonly activatedRoute: ActivatedRoute) {
    super();
  }

  public isAddingProductionOrder(): boolean {
    return this.activatedRoute?.snapshot?.routeConfig?.path === this.ADD_PRODUCTION_SCHEDULE_URL;
  }

  public isAddingProductionOrderForRun(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForRun.path;
  }

  public isAddingProductionOrderForProductConfiguration(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForProductConfiguration.path;
  }

  public isAddingProductionOrderForMachine(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForMachine.path;
  }

  public isAddingAnyTypeOfProductionOrder(): boolean {
    return this.isAddingProductionOrderForType() || this.isAddingProductionOrder();
  }

  public isAddingProductionOrderForType(): boolean {
    return this.isAddingProductionOrderForMachine() || this.isAddingProductionOrderForProductConfiguration() || this.isAddingProductionOrderForRun();
  }

  public isAddingAnyTypeOrDuplicatingOfProductionOrder(): boolean {
    return this.isAddingAnyTypeOfProductionOrder() || this.isDuplicatingProductionOrder();
  }

  public isDuplicatingProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.DUPLICATE_PRODUCTION_SCHEDULE_URL;
  }

  public isEditingProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.EDIT_PRODUCTION_SCHEDULE_URL;
  }

  public getDefaultParameterForPropertyNameOfMachineQualitySettings(propertyName: string): PropertyValue {
    return this.getDefaultParameterForPropertyNameOfGivenSettings(propertyName, this.listOfMachineQualityCustomSettings);
  }

  public getDefaultParameterForPropertyNameOfGivenSettings(propertyName: string, listOfCustomSettings: PropertyValue[]): PropertyValue {
    return listOfCustomSettings?.find((value: PropertyValue) => value.propertyName === propertyName);
  }
}

export type ProductionScheduleInitialPreparationsFormGroup = FormGroup<{
  name: FormControl<string>;
  machine: FormControl<Equipment | MachineSummary>;
  machineQuality: FormControl<WeaveQuality | MachineQualityForProductionSchedule>;
  practicalPickDensity: FormControl<number>;
  productConfiguration: FormControl<ProductionScheduleProductConfiguration>;
  creel: FormControl<OverviewListCreel | CreelForProductionSchedule>;
  buggy: FormControl<BuggyForProductionSchedule>;
}>;
