import {AbstractFinishing} from '@domain/textile-data/finishing-and-finishing-template/abstract-finishing';
import {FinishingTemplateCutbarsInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-cutbars-information';
import {FinishingTemplateImageLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-image-label';
import {FinishingTemplateLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-label';
import {FinishingTemplateTextLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template-text-label';
import {BackcoatingInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/backcoating-information';
import {FinishingLabelType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label-type.enum';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {isEmpty, isEqual, isNil, map} from 'lodash-es';

export class FinishingTemplate extends AbstractFinishing {
  private readonly _cutbarsInformation: FinishingTemplateCutbarsInformation;
  private readonly _labels: FinishingTemplateLabel[];

  public constructor(
    id: number,
    name: string,
    finishingType: FinishingType,
    cutbarsInformation: FinishingTemplateCutbarsInformation,
    labels: FinishingTemplateLabel[],
    backcoatingInformation: BackcoatingInformation,
    used: boolean
  ) {
    super(id, name, finishingType, backcoatingInformation, used);

    this._labels = labels;
    this._cutbarsInformation = cutbarsInformation;
  }

  public get cutbarsInformation(): FinishingTemplateCutbarsInformation {
    return this._cutbarsInformation;
  }

  public get labels(): FinishingTemplateLabel[] {
    return this._labels;
  }

  public static createEmptyFinishingTemplate(): FinishingTemplate {
    return new FinishingTemplate(null, null, null, null, [], null, null);
  }

  public static fromJSON(finishingTemplateJSON: any): FinishingTemplate {
    return new FinishingTemplate(
      finishingTemplateJSON.id,
      finishingTemplateJSON.name,
      finishingTemplateJSON.finishingType as FinishingType,
      FinishingTemplateCutbarsInformation.fromJSON(finishingTemplateJSON.cutbarsInformation),
      map(finishingTemplateJSON.labels, (finishingTemplateLabelJSON: any) => {
        return isEqual(finishingTemplateLabelJSON.finishingLabelType, FinishingLabelType.TEXT)
          ? FinishingTemplateTextLabel.fromJSON(finishingTemplateLabelJSON)
          : FinishingTemplateImageLabel.fromJSON(finishingTemplateLabelJSON);
      }),
      new BackcoatingInformation(finishingTemplateJSON.backcoatingInformation.useBackcoating),
      finishingTemplateJSON.used
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      finishingType: this.finishingType,
      cutbarsInformation: this.cutbarsInformation.toJSON(),
      labels: map(this.labels, (label: FinishingTemplateLabel) => {
        return label.toJSON();
      }),
      backcoatingInformation: {
        useBackcoating: this.backcoatingInformation.useBackcoating
      }
    } as any as JSON;
  }

  public hasAddedCutbarsInformation(): boolean {
    return !isNil(this._cutbarsInformation);
  }

  public hasAddedCutbarBefore(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCutbarBefore();
  }

  public hasAddedCutbarAfter(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCutbarAfter();
  }

  public hasAddedCuttingLine(): boolean {
    return this.hasAddedCutbarsInformation() && this._cutbarsInformation.hasAddedCuttingLine();
  }

  public hasAddedLabels(): boolean {
    return !isEmpty(this.labels);
  }
}
