import {Component, Inject} from '@angular/core';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {Drawings, DRAWINGS} from '@infrastructure/http/drawing/drawings';
import {DesignPatternPreviewDialogComponent} from '@presentation/pages/texedit/product-configuration/add/design-pattern-preview-dialog/design-pattern-preview-dialog.component';
import {AssertionUtils, BaseComponent, BlobUtils, DialogBuilderFactoryService} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './design-preview.component.html',
  styleUrls: ['./design-preview.component.scss']
})
export class DesignPreviewComponent extends BaseComponent implements ICellRendererAngularComp {
  public drawing: Drawing;

  public constructor(
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public agInit(params: ICellRendererParams): void {
    this.drawing = params.value;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public imageDownloaded(imageData: string): void {
    this.drawing.image.data = imageData === 'data:' ? null : imageData;
  }

  public openDesignPatternPreviewDialog(event: Event): void {
    event.stopPropagation();

    const dialogBuilder = this.dialogBuilderFactoryService
      .getBuilder()
      .withWidth('fit-content')
      .withHeight('fit-content')
      .withMaxWidth('100vw')
      .withMaxHeight('100vh')
      .withClass(['overflow-hidden-dialog', 'box-shadow-none-dialog'])
      .withCustomBackdrop('white-backdrop');

    if (this.hasMissingDesign()) {
      dialogBuilder.openDialog(DesignPatternPreviewDialogComponent, {design: 'assets/images/missing-design.svg'});
    } else if (this.hasDrawingImageData()) {
      dialogBuilder.openDialog(DesignPatternPreviewDialogComponent, {design: this.drawing.image.data});
    } else {
      this.drawings
        .getDrawing(this.drawing.image.id, this.drawing.image.signature)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((blob: Blob) => {
          if (blob.size > 0) {
            BlobUtils.blobToImageData(blob).then((imageData: string) => {
              if (!AssertionUtils.isNullOrUndefined(imageData) && imageData !== 'data:') {
                dialogBuilder.openDialog(DesignPatternPreviewDialogComponent, {design: imageData});
              }
            });
          }
        });
    }
  }

  public isDrawingTypeBmp(): boolean {
    return this.drawing.drawingType === DrawingType.BMP;
  }

  public canZoomInOnDesign(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.drawing.image?.data) || this.hasMissingDesign();
  }

  private hasMissingDesign(): boolean {
    return this.drawing.missingDesign;
  }

  private hasDrawingImageData(): boolean {
    return this.drawing.image.data !== null;
  }
}
