import {Component, HostBinding} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {map, takeUntil} from 'rxjs/operators';
import {BaseComponent} from './../../base-component';
import {convertUnit} from './../../common/converters/convert-unit';
import {Unit} from './../../common/unit.enum';
import {NumberCellEditorParams} from './number-cell-editor-params.interface';

@Component({
  templateUrl: './number-cell-editor.component.html',
  styleUrls: ['./number-cell-editor.component.scss']
})
export class NumberCellEditorComponent extends BaseComponent implements ICellEditorAngularComp {
  @HostBinding('class.error')
  public errorState: boolean;

  public min: number;
  public max: number;
  public toUnit: Unit;
  public formControl: UntypedFormControl;
  public isSmall: boolean;
  public autoSelect: boolean = false;

  private fromUnit: Unit;
  private params: any;

  public agInit(params: NumberCellEditorParams): void {
    this.min = params.min;
    this.max = params.max;
    this.fromUnit = params.fromUnit;
    this.toUnit = params.toUnit;
    this.isSmall = params.isSmall ?? true;
    this.autoSelect = params.autoSelect;
    this.params = params;

    if (params.fromUnit && params.toUnit) {
      const value = convertUnit({
        from: {value: params.value, unit: params.fromUnit},
        to: params.toUnit
      });
      const max = convertUnit({
        from: {value: params.max, unit: params.fromUnit},
        to: params.toUnit
      });
      const min = convertUnit({
        from: {value: params.min, unit: params.fromUnit},
        to: params.toUnit
      });

      this.formControl = new UntypedFormControl(value, [Validators.min(min), Validators.max(max)]);
    } else {
      this.formControl = new UntypedFormControl(params.value, [Validators.min(params.min), Validators.max(params.max)]);
    }

    this.subscribeToStatusChanges();
  }

  public hasUnit(): boolean {
    return !!this.toUnit;
  }

  public getUnitTranslationKey(): string {
    return Unit.getKeyFromValue(this.toUnit);
  }

  public stopClickEvent(event: PointerEvent): void {
    event.stopPropagation();
  }

  public getValue(): number {
    let value = this.formControl.value;

    if (this.fromUnit && this.toUnit) {
      value = convertUnit({
        from: {value, unit: this.toUnit},
        to: this.fromUnit
      });
    }

    return value;
  }

  public isCancelAfterEnd(): boolean {
    return !this.formControl.valid;
  }

  public preventDefaultOnMouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public onModelChange(): void {
    this.params.api.dispatchEvent({type: 'cellValueChanged', event: {}});
  }

  private subscribeToStatusChanges(): void {
    this.formControl.statusChanges
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        map((valid: string) => valid === 'VALID')
      )
      .subscribe((valid: boolean) => (this.errorState = !valid));
  }
}
