import {RouteNode} from '@application/helper/routing/route-node';
import {Permission} from '@domain/profile/permission.enum';
import {first} from 'lodash-es';

export class RouteLeaf extends RouteNode {
  public constructor(path: string, requiredPermission?: Permission, isMobileFriendly: boolean = false, isMobileOnly: boolean = false) {
    super(path, requiredPermission, isMobileFriendly, isMobileOnly);
  }

  public isKnownPath(pathSegments: string[]): boolean {
    return this.matchesPath(first(pathSegments));
  }
}
