import {Component} from '@angular/core';
import {ITooltipAngularComp} from 'ag-grid-angular';
import {ITooltipParams} from 'ag-grid-community';
import {toString} from 'lodash-es';

@Component({
  selector: 'vdw-grid-tooltip',
  templateUrl: './grid-tooltip.component.html',
  styleUrls: ['./grid-tooltip.component.scss']
})
export class GridTooltipComponent implements ITooltipAngularComp {
  public tooltip: string;

  public agInit(params: ITooltipParams): void {
    this.tooltip = toString(params.value);
  }
}
