<div class="table">
  <article fxLayout="row">
    <div class="column position" fxLayout="column" fxFlex="0 0 40px">
      <div class="header cell"></div>
      <div
        class="b1 cell"
        fxLayout="column"
        fxLayoutAlign="center center"
        *ngFor="let creelPositionNumber of getCreelPositions(); let creelPositionIndex = index"
        [attr.data-index]="creelPositionIndex"
        [style.height.px]="cellHeightInPx"
      >
        {{ creelPositionNumber }}
      </div>
    </div>
    <div class="column" fxLayout="column" fxFlex="calc(50% - 20px)">
      <div class="header cell">
        <h4 class="font-weight-semibold">
          {{ 'MACHINE.DETAILS.CREEL_MAPPING.THEORETICAL_CREEL' | bmsTranslate }}
        </h4>
      </div>
      <div
        class="b1 cell"
        fxLayout="column"
        fxLayoutAlign="center start"
        *ngFor="let creelPosition of coloredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions"
        [style.height.px]="cellHeightInPx"
      >
        <div fxLayout="row" fxLayoutGap="10px" class="full-width">
          <ng-container *ngIf="isOfPreviewTypeColor()">
            <app-color-grid-preview [colors]="creelPosition.getUniqueItems()" [showRgbValues]="false"> </app-color-grid-preview>
          </ng-container>

          <ng-container *ngIf="!isOfPreviewTypeColor()">
            <div fxLayout="column" fxLayoutGap="6px">
              <span *ngFor="let item of getYarnSetCreelPosition(creelPosition).getUniqueItems()">
                {{ item.name }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="column" fxLayout="column" fxFlex="calc(50% - 20px)">
      <div class="header cell">
        <h4 class="font-weight-semibold">
          {{ 'GENERAL.ACTIVE_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.CREEL.CREEL' | bmsTranslate: {count: 1} | lowercase} }}
        </h4>
      </div>
      <div fxLayout="column">
        <div
          class="b1 cell"
          fxLayout="column"
          fxLayoutAlign="center start"
          *ngFor="let mappedCreelPosition of activeMappedColoredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions; let index = index"
          [style.height.px]="cellHeightInPx"
          [class.mapped]="isCreelPositionDifferentFromTheoreticalCreel(mappedCreelPosition.position)"
          [draggable]="!readOnly"
          (drop)="onDrop(mappedCreelPosition, index)"
          appDataCy="creel-droppable-zone"
          (dragstart)="setDraggedCreelPosition(mappedCreelPosition, index)"
          [appRunEventsOutsideAngular]="['dragover']"
          (outSideAngularEvent)="onDragOver($event)"
        >
          <div fxLayout="row" fxLayoutGap="10px" class="full-width">
            <mat-icon fxFlex="24px" *ngIf="!readOnly" appDataCy="creel-position-drag-handle" svgIcon="drag-handle"></mat-icon>
            <div fxLayout="column" fxLayoutAlign="center start" fxFlex="calc(100% - 64px)">
              <ng-container *ngIf="isOfPreviewTypeColor()">
                <app-color-grid-preview [colors]="mappedCreelPosition.getUniqueItems()" [showRgbValues]="false"> </app-color-grid-preview>
              </ng-container>

              <ng-container *ngIf="!isOfPreviewTypeColor()">
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="6px">
                  <span *ngFor="let item of getMappedYarnSetCreelPosition(activeMappedColoredYarnSetWithStartDent, mappedCreelPosition).getUniqueItems()"> e{{ item.name }} </span>
                </div>
              </ng-container>
            </div>

            <span fxFlex="20px" class="text-align-right original-position" fxFlexAlign="center" *ngIf="isCreelPositionDifferentFromTheoreticalCreel(mappedCreelPosition.position)">
              {{ '(' + mappedCreelPosition.position + ')' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
