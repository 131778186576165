import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslationModule} from '../../translation/translation.module';
import {DialogButtonModule} from '../dialog-button/dialog-button.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {SlideToggleModule} from '../slide-toggle/slide-toggle.module';
import {GridSelectionExtraActionsDialogComponent} from './grid-selection-extra-actions-dialog/grid-selection-extra-actions-dialog.component';
import {GridSelectionOptionsComponent} from './grid-selection-options.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, SlideToggleModule, DialogButtonModule, TranslationModule, LabelModule],
  declarations: [GridSelectionOptionsComponent, GridSelectionExtraActionsDialogComponent],
  exports: [GridSelectionOptionsComponent, GridSelectionExtraActionsDialogComponent]
})
export class GridSelectionsOptionsModule {}
