import {Injectable} from '@angular/core';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {GenericOrder} from '@domain/planning-prototype/generic-order.interface';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningForecast} from '@domain/planning-prototype/planning-forecast';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {PlanningItemForecast} from '@domain/planning-prototype/planning-item-forecast';
import {PlanningItemType} from '@domain/planning-prototype/planning-item-type';
import {PlanningLine} from '@domain/planning-prototype/planning-line';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {ArrayUtils, AssertionUtils} from '@vdw/angular-component-library';
import {UUID} from 'crypto';
import moment from 'moment';
import {BehaviorSubject, Observable, Subject, map} from 'rxjs';

export type SelectedItemType = PlanningEquipment | PlanningItem | ProductionOrderToPlan[] | FixedScheduleToPlan[];

@Injectable()
export class PlanningPrototypeContextService {
  public planningForecast: PlanningForecast;
  public ordersToPlan: ProductionOrderToPlan[];
  public fixedSchedulesToPlan: FixedScheduleToPlan[] = [];
  public machines: PlanningEquipment[];
  public planningItemsByMachine: {[id: number]: PlanningItem[]} = {};
  public machinesByPlanningItem: {[draftId: UUID]: PlanningEquipment} = {};
  public sortedPlanningItemForecasts: {[draftId: UUID]: PlanningItemForecast} = {};
  public productChanges: UUID[] = [];
  public planningLinesByMachine: {[id: number]: PlanningLine} = {};
  public planningHistory: {[planningLineId: number]: PlanningItem[]} = {};
  public itemCreationPlaceholder: PlanningItem;

  public get selectedItem(): SelectedItemType {
    return this._selectedItemChanges.value;
  }

  public get selectedItemChanges(): Observable<SelectedItemType> {
    return this._selectedItemChanges.asObservable();
  }

  public get planningLineChanges(): Observable<PlanningLine> {
    return this._machineChanges.pipe(map((machine: PlanningEquipment) => this.planningForecast.planningLines.find((line: PlanningLine) => line.parentEquipment.id === machine.id)));
  }

  private readonly _machineChanges = new Subject<PlanningEquipment>();
  private readonly _selectedItemChanges = new BehaviorSubject<SelectedItemType>(null);

  public init(forecast: PlanningForecast, ordersToPlan: ProductionOrderToPlan[], fixedSchedulesToPlan: FixedScheduleToPlan[]): void {
    this.reset();
    this.planningForecast = forecast;
    this.ordersToPlan = ordersToPlan;
    this.fixedSchedulesToPlan = fixedSchedulesToPlan;

    this.getMachinesFromForecast();
    this.getPlanningItemsByMachine();
    this.updateForecasts(forecast.forecasts);
    this.recalculateProductChanges();
    this.getMachinesByPlanningItem();
  }

  public reset(): void {
    this.selectItem(null);
    this.planningForecast = null;
    this.ordersToPlan = [];
    this.fixedSchedulesToPlan = [];
    this.machines = [];
    this.planningItemsByMachine = {};
    this.machinesByPlanningItem = {};
    this.sortedPlanningItemForecasts = {};
    this.productChanges = [];
    this.planningLinesByMachine = {};
    this.planningHistory = {};
    this.itemCreationPlaceholder = null;
  }

  public mergeUpdatedPlanning(forecast: PlanningForecast, ordersToPlan: ProductionOrderToPlan[]): void {
    const flatList = this.getFlatListOfPlanningItems();
    for (const planningLine of this.planningForecast.planningLines) {
      const updatedPlanningLine = forecast.planningLines.find((line: PlanningLine) => line.id === planningLine.id);
      this.mergeUpdatedPlanningLine(planningLine, updatedPlanningLine, flatList);
      this.removeItemsNotToPlan(planningLine, ordersToPlan);
    }
    this.planningForecast.forecasts.push(...forecast.forecasts);

    this.init(this.planningForecast, ordersToPlan, this.fixedSchedulesToPlan);
  }

  public selectItem(item: SelectedItemType): void {
    this._selectedItemChanges.next(item);
  }

  public getFlatListOfPlanningItems(parent?: PlanningItem): PlanningItem[] {
    if (AssertionUtils.isNullOrUndefined(parent)) {
      return (this.planningForecast?.planningLines ?? [])
        .flatMap((planningLine: PlanningLine) => planningLine.planningItems)
        .flatMap((planningItem: PlanningItem) => this.getFlatListOfPlanningItems(planningItem));
    }
    return [parent, ...(parent.planningItems?.flatMap((planningItem: PlanningItem) => this.getFlatListOfPlanningItems(planningItem)) ?? [])];
  }

  public mergeHistoryData(history: {[ids: number]: PlanningItem[]}): void {
    const updatedPlanningLines = Object.keys(history).map((key: string) => Number(key));
    for (const planningLineId of updatedPlanningLines) {
      const historyUpdate = history[planningLineId];
      const itemsToUpdate: PlanningItem[] = this.planningHistory[planningLineId] ?? [];

      const result = itemsToUpdate.filter((item: PlanningItem) => !historyUpdate.some((itemUpdate: PlanningItem) => item.id === itemUpdate.id));
      result.push(...historyUpdate);
      this.planningHistory[planningLineId] = result;

      const planningLine = (this.planningForecast?.planningLines ?? []).find((line: PlanningLine) => line.id === planningLineId);
      if (!AssertionUtils.isNullOrUndefined(planningLine)) {
        this.setMachineForPlanningItems(planningLine.parentEquipment, result);
      }
    }
  }

  public updateForecasts(forecasts: PlanningItemForecast[]): void {
    for (const forecast of forecasts) {
      this.sortedPlanningItemForecasts[forecast.draftId] = forecast;
    }
    this.recalculateProductChanges();
    this.getMachinesByPlanningItem();
  }

  public markAsChanged(machine: PlanningEquipment): void {
    this._machineChanges.next(machine);
  }

  public removeItemFromList(itemToRemove: PlanningItem, list: PlanningItem[]): void {
    const draggedItemIndex = list.indexOf(itemToRemove);
    const itemsToUpdate = list.filter((item: PlanningItem) => item.sequenceNumber > itemToRemove.sequenceNumber);
    for (const item of itemsToUpdate) {
      item.sequenceNumber--;
    }
    list.splice(draggedItemIndex, 1);
  }

  public removeEmptyItems(list: PlanningItem[]): void {
    const itemsToRemove = list.filter(
      (item: PlanningItem) => AssertionUtils.isNullOrUndefined(item.actualStartDate) && AssertionUtils.isEmpty(item.planningItems) && moment.duration(item.minimumDuration).asHours() === 0
    );
    for (const item of itemsToRemove) {
      this.removeItemFromList(item, list);
    }
  }

  public delete(item: PlanningItem, parentList: PlanningItem[], machine: PlanningEquipment): void {
    this.selectItem(null);
    this.removeItemFromList(item, parentList);
    this.markAsChanged(machine);
  }

  public addItemToList(itemToAdd: PlanningItem, list: PlanningItem[], targetTime: Date): void {
    const itemsBeforeTarget = list.filter(
      (item: PlanningItem) => !AssertionUtils.isNullOrUndefined(item.actualStartDate) || targetTime >= PlanningItemHelper.getTimelineEndDate(item, this.sortedPlanningItemForecasts[item.draftId])
    );
    itemsBeforeTarget.sort(PlanningItem.compareBySequenceNumber);
    const itemBeforeTarget = !AssertionUtils.isEmpty(itemsBeforeTarget) ? itemsBeforeTarget[itemsBeforeTarget.length - 1] : null;
    const itemsAfterTarget = list.filter((item: PlanningItem) => item.sequenceNumber > (itemBeforeTarget?.sequenceNumber ?? 0));
    for (const item of itemsAfterTarget) {
      item.sequenceNumber++;
    }
    itemToAdd.sequenceNumber = (itemBeforeTarget?.sequenceNumber ?? 0) + 1;
    list.push(itemToAdd);
  }

  public updatePlanningItemIds(update: {[draftId: UUID]: number}): void {
    const planningItems = this.getFlatListOfPlanningItems().filter((item: PlanningItem) => item.draftId in update);
    for (const item of planningItems) {
      item.id = update[item.draftId];
    }
  }

  private getMachinesFromForecast(): void {
    const machinesWithDuplicates = this.planningForecast.planningLines.map((planningLine: PlanningLine) => planningLine.parentEquipment);
    this.machines = ArrayUtils.distinctBy(machinesWithDuplicates, (equipment: PlanningEquipment) => equipment.id);
  }

  private getPlanningItemsByMachine(): void {
    for (const planningLine of this.planningForecast.planningLines) {
      this.planningLinesByMachine[planningLine.parentEquipment.id] = planningLine;
      this.planningItemsByMachine[planningLine.parentEquipment.id] = planningLine.planningItems;
    }
  }

  private recalculateProductChanges(): void {
    this.productChanges = [];
    for (const planningLine of this.planningForecast.planningLines) {
      const runs = planningLine.planningItems.filter((item: PlanningItem) => item.type !== PlanningItemType.MAINTENANCE && AssertionUtils.isNullOrUndefined(item.actualEndDate));
      runs.sort(PlanningItem.compareBySequenceNumber);

      let lastRun: PlanningItem;
      for (const run of runs) {
        if (PlanningItemHelper.isDifferentConfig(lastRun, run)) {
          this.productChanges.push(run.draftId);
          if (!AssertionUtils.isEmpty(run.planningItems)) {
            this.productChanges.push(...run.planningItems.map((order: PlanningItem) => order.draftId));
          }
        }
        lastRun = run;
      }
    }
  }

  private getMachinesByPlanningItem(): void {
    for (const planningLine of this.planningForecast.planningLines) {
      this.setMachineForPlanningItems(planningLine.parentEquipment, planningLine.planningItems);
    }
  }

  private setMachineForPlanningItems(machine: PlanningEquipment, items: PlanningItem[]): void {
    for (const item of items) {
      this.machinesByPlanningItem[item.draftId] = machine;
      if (!AssertionUtils.isEmpty(item.planningItems)) {
        this.setMachineForPlanningItems(machine, item.planningItems);
      }
    }
  }

  private mergeUpdatedPlanningLine(planningLine: PlanningLine, update: PlanningLine, flatList: PlanningItem[]): void {
    const updatedItems = update.planningItems.filter((item: PlanningItem) => item.isActive);
    for (const updatedChild of updatedItems.flatMap((item: PlanningItem) => item.planningItems)) {
      if (!PlanningItemHelper.isOrderItem(updatedChild)) {
        continue;
      }
      const existingItem = flatList.find(
        (item: PlanningItem): item is GenericOrder =>
          PlanningItemHelper.isOrderItem(item) &&
          ProductionOrderToPlan.getType(item.productionOrder) === ProductionOrderToPlan.getType(updatedChild.productionOrder) &&
          item.productionOrder.id === updatedChild.productionOrder.id
      );

      if (AssertionUtils.isNullOrUndefined(existingItem)) {
        continue;
      }

      const existingItemParent = flatList.find((item: PlanningItem) => item.planningItems.includes(existingItem));
      this.removeItemFromList(existingItem, existingItemParent.planningItems);
    }
    for (const activeItem of planningLine.planningItems.filter((item: PlanningItem) => item.isActive)) {
      this.removeItemFromList(activeItem, planningLine.planningItems);
    }

    planningLine.planningItems = planningLine.planningItems.filter((item: PlanningItem) => !item.isActive);
    planningLine.planningItems.push(...updatedItems);
    this.removeEmptyItems(planningLine.planningItems);
    this.markAsChanged(planningLine.parentEquipment);
  }

  private removeItemsNotToPlan(planningLine: PlanningLine, ordersToPlan: ProductionOrderToPlan[]): void {
    for (const topLevelItem of planningLine.planningItems) {
      const itemsToRemove = topLevelItem.planningItems.filter(
        (item: PlanningItem) =>
          AssertionUtils.isNullOrUndefined(item.actualStartDate) &&
          PlanningItemHelper.isOrderItem(item) &&
          !ordersToPlan.some((order: ProductionOrderToPlan) => order.productionOrder.id === item.productionOrder?.id && order.type === ProductionOrderToPlan.getType(order.productionOrder))
      );
      for (const itemToRemove of itemsToRemove) {
        this.removeItemFromList(itemToRemove, topLevelItem.planningItems);
      }
    }
    this.removeEmptyItems(planningLine.planningItems);
  }
}
