import {FormArray, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function orderbookSettingsUniqueFieldsValidator(): ValidatorFn {
  return (formArray: FormArray): ValidationErrors | null => {
    if (formArray) {
      const formControlsGroupedByLabelValue = getFormControlsGroupedByValue(formArray.controls as FormControl[]);

      Object.keys(formControlsGroupedByLabelValue).forEach((value: string) => {
        const nonUniqueValueExists = value.length > 0 && formControlsGroupedByLabelValue[value].length > 1;

        formControlsGroupedByLabelValue[value].forEach((formControl: FormControl) => {
          if (nonUniqueValueExists) {
            formControl.get('label').setErrors({unique: true});
            formControl.get('label').markAsTouched();
          } else if (formControl.get('label').errors) {
            const {unique, ...errors} = formControl.get('label').errors;
            formControl.get('label').setErrors(Object.keys(errors).length > 0 ? errors : null);
          } else {
            formControl.get('label').setErrors(null);
          }
        });
      });
    }

    return null;
  };
}

function getFormControlsGroupedByValue(controls: FormControl[]): Record<string, FormControl[]> {
  return controls.reduce((formControlsGroupedByLabelValue: Record<string, FormControl[]>, formControl: FormControl) => {
    const formControlValue = formControl.get('label').value?.toLowerCase() ?? '';

    if (formControlValue in formControlsGroupedByLabelValue) {
      formControlsGroupedByLabelValue[formControlValue].push(formControl);
    } else {
      formControlsGroupedByLabelValue[formControlValue] = [formControl];
    }

    return formControlsGroupedByLabelValue;
  }, {});
}
