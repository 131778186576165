import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';
import {ColoredYarnSets} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {Conflict} from '@vdw/angular-component-library';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpColoredYarnSetsService implements ColoredYarnSets {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}coloredyarnsets/${id}`);
  }

  public getAll(): Observable<OverviewListColoredYarnSet[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}coloredyarnsets`).pipe(
      rxjsMap((coloredYarnSetsJSON: any) => {
        return map(coloredYarnSetsJSON, (coloredYarnSetJSON: any) => {
          return OverviewListColoredYarnSet.fromJSON(coloredYarnSetJSON);
        });
      })
    );
  }

  public getById(id: number): Observable<ColoredYarnSet> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}coloredyarnsets/${id}`).pipe(
      rxjsMap((coloredYarnSetJSON: any) => {
        return ColoredYarnSet.fromJSON(coloredYarnSetJSON);
      })
    );
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}coloredyarnsets/check-name`, {params}).pipe(
      rxjsMap((response: {coloredYarnSetNameAvailable: boolean}) => {
        return response.coloredYarnSetNameAvailable;
      })
    );
  }

  public save(item: ColoredYarnSet): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}coloredyarnsets`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public update(item: ColoredYarnSet): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}coloredyarnsets/${item.id}`, item.toJSON());
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}coloredyarnsets/${id}/conflicts`)
      .pipe(rxjsMap((conflictsJSON: any) => map(conflictsJSON, (conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }
}
