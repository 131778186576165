import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {isEmpty, isEqual, isNil} from 'lodash-es';

@Component({
  selector: 'vdw-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Output() public filteredTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filterIconClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() public placeholder = 'ANGULAR_COMPONENT_LIBRARY.FILTER.SEARCH';
  @Input() public icon = 'search';
  @Input() public disabled = false;
  @Input() public filteredText;
  @Input() public iconDisabled = false;
  @Input() public filterWidth = 'auto';
  @ViewChild('inputElement', {read: ElementRef}) public inputElement: ElementRef;
  @ViewChild('iconElement', {read: ElementRef}) public iconElement: ElementRef;

  public focus(): void {
    this.inputElement.nativeElement.focus();
  }

  public filterChanged(newValue: string): void {
    if ((!isNil(newValue) || !isEmpty(newValue)) && isEqual(this.icon, 'search')) {
      this.icon = 'close';
    } else if ((isNil(newValue) || isEmpty(newValue)) && isEqual(this.icon, 'close')) {
      this.icon = 'search';
    }
    this.filteredText = newValue;
    this.filteredTextChanged.emit(this.filteredText);
  }

  public iconClicked(event: MouseEvent): void {
    if (isEqual(this.icon, 'close')) {
      this.filteredText = null;
      this.filteredTextChanged.emit(this.filteredText);
      this.icon = 'search';
    } else {
      this.filterIconClicked.emit(event);
    }
  }

  public clear(): void {
    this.filterChanged('');
  }

  public isIconDisabled(): boolean {
    return this.iconDisabled;
  }
}
