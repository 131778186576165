import {StringUtils} from '@application/helper/string-utils';
import {IdNameVersion} from '@domain/id-name-version';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {FinishingCutbarsInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-cutbars-information';
import {FinishingLabel} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-label';
import {FinishingType} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-type.enum';
import {FinishingAndFinishingTemplateLabelOverviewList} from '@presentation/pages/textile-data/finishing-template/overview/finishing-and-finishing-template-label-overview-list';
import {isNil, map} from 'lodash-es';

export class OverviewListFinishing {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;
  private readonly _finishingType: FinishingType;
  private readonly _machineQuality: IdNameVersion;
  private readonly _cutbarsInformation: FinishingCutbarsInformation;
  private readonly _labels: FinishingAndFinishingTemplateLabelOverviewList[];

  public constructor(
    id: number,
    name: string,
    version: number,
    finishingType: FinishingType,
    machineQuality: IdNameVersion,
    cutbarsInformation: FinishingCutbarsInformation,
    labels: FinishingAndFinishingTemplateLabelOverviewList[]
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._finishingType = finishingType;
    this._machineQuality = machineQuality;
    this._cutbarsInformation = cutbarsInformation;
    this._labels = labels;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get nameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._name, this._version);
  }

  public get finishingType(): FinishingType {
    return this._finishingType;
  }

  public get machineQuality(): IdNameVersion {
    return this._machineQuality;
  }

  public get machineQualityNameWithVersion(): string {
    return StringUtils.createNameWithVersion(this._machineQuality.name, this._machineQuality.version);
  }

  public get cutbarsInformation(): FinishingCutbarsInformation {
    return this._cutbarsInformation;
  }

  public get labels(): FinishingAndFinishingTemplateLabelOverviewList[] {
    return this._labels;
  }

  public static fromJSON(finishingJSON: any): OverviewListFinishing {
    return !isNil(finishingJSON)
      ? new OverviewListFinishing(
          finishingJSON.id,
          finishingJSON.name,
          finishingJSON.version,
          finishingJSON.finishingType,
          IdNameVersion.fromJSON(finishingJSON.machineQuality),
          FinishingCutbarsInformation.fromJSON(finishingJSON.cutbarsInformation),
          map(finishingJSON.labels, (finishingTemplateLabelJSON: any) => FinishingAndFinishingTemplateLabelOverviewList.fromJSON(finishingTemplateLabelJSON))
        )
      : null;
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version,
      finishingType: this._finishingType,
      machineQuality: {name: this._machineQuality.name, version: this._machineQuality.version},
      cutbarsInformation: !isNil(this._cutbarsInformation) ? this._cutbarsInformation.toJSON() : null,
      labels: map(this._labels, (label: FinishingAndFinishingTemplateLabelOverviewList) => label.toJSON())
    } as any as JSON;
  }

  public static fromFinishing(finishing: Finishing): OverviewListFinishing {
    return finishing
      ? new OverviewListFinishing(
          this.getFinishingId(finishing),
          finishing.name,
          finishing.version,
          finishing.finishingType,
          new IdNameVersion(finishing.machineQuality?.id, finishing.machineQuality?.name, finishing.machineQuality?.version),
          finishing.cutbarsInformation,
          this.convertLabels(finishing.labels)
        )
      : null;
  }

  private static getFinishingId(finishing: Finishing): number {
    return typeof finishing.id === 'number' ? finishing.id : null;
  }

  private static convertLabels(labels: FinishingLabel[]): FinishingAndFinishingTemplateLabelOverviewList[] {
    return labels ? map(labels, (label: FinishingLabel) => FinishingAndFinishingTemplateLabelOverviewList.fromLabel(label)) : [];
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return '';
  }
}
