import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {getMappedColoredYarnSet} from './get-mapped-colored-yarn-set';

export function getMappedColoredYarnSetWithStartDent(
  coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent,
  coloredYarnSetCreelMap: CreelMap,
  sortDirection: any = 'desc'
): OverviewListColoredYarnSetWithStartDent {
  return new OverviewListColoredYarnSetWithStartDent(
    getMappedColoredYarnSet(coloredYarnSetWithStartDent.coloredYarnSet, coloredYarnSetCreelMap, sortDirection),
    coloredYarnSetWithStartDent.startDent,
    coloredYarnSetWithStartDent.technicalWidthInDents
  );
}
