import {PriorityLevel} from '@domain/event/priority-level.enum';
import {IdName} from '@domain/id-name';

export class Notification {
  private readonly _id: number;
  private readonly _equipment: IdName;
  private readonly _equipmentGroup: IdName;
  private readonly _message: string;
  private readonly _description: string;
  private readonly _priority: PriorityLevel;
  private readonly _timestamp: string;
  private _read: boolean;

  public constructor(id: number, equipment: IdName, message: string, priority: PriorityLevel, timestamp: string, read: boolean, description: string) {
    this._id = id;
    this._equipment = equipment;
    this._message = message;
    this._priority = priority;
    this._timestamp = timestamp;
    this._read = read;
    this._description = description;
  }

  public get id(): number {
    return this._id;
  }

  public get equipment(): IdName {
    return this._equipment;
  }

  public get message(): string {
    return this._message;
  }

  public get priority(): PriorityLevel {
    return this._priority;
  }

  public get timestamp(): string {
    return this._timestamp;
  }

  public get read(): boolean {
    return this._read;
  }

  public set read(read: boolean) {
    this._read = read;
  }

  public get description(): string {
    return this._description;
  }

  public static fromJSON(notificationJSON: any): Notification {
    return new Notification(
      notificationJSON.id,
      notificationJSON.equipment,
      notificationJSON.message,
      PriorityLevel[notificationJSON.priority as string],
      notificationJSON.timestamp,
      notificationJSON.read,
      notificationJSON.description
    );
  }

  public static compare(notificationA: Notification, notificationB: Notification): number {
    return new Date(notificationB.timestamp).getTime() - new Date(notificationA.timestamp).getTime();
  }
}
