import {Component, OnInit} from '@angular/core';
import {EnumUtils, LocalStorageService, ToastService, TranslateService} from '@vdw/angular-component-library';
import {NGXLogger, NgxLoggerLevel} from 'ngx-logger';

@Component({
  selector: 'app-logger',
  templateUrl: './logger.component.html'
})
export class LoggerComponent implements OnInit {
  public loggerLevels: string[] = EnumUtils.getEnumNames(NgxLoggerLevel);
  public levelSelected: string;
  public inProgress = false;

  private logger: NGXLogger;

  private readonly translate: TranslateService;
  private readonly localStorage: LocalStorageService;

  public constructor(
    private readonly toastService: ToastService,
    translate: TranslateService,
    logger: NGXLogger,
    localStorage: LocalStorageService
  ) {
    this.logger = logger;
    this.localStorage = localStorage;
    this.translate = translate;
  }

  public ngOnInit(): void {
    this.setLevelSelected();
  }

  public setNewLogLevel(): void {
    this.logger.updateConfig({level: NgxLoggerLevel[`${this.levelSelected}`]});
    this.localStorage.set('loggerLevel', this.levelSelected);
    this.toastService.showInfo({
      tapToDismiss: false,
      timeOut: 2000,
      message: this.translate.instant('LOGGER.LOGGER_SELECTED', {object: this.levelSelected})
    });
  }

  private setLevelSelected(): void {
    this.levelSelected = EnumUtils.getKeyFromValue(NgxLoggerLevel, this.logger.level);
  }
}
