import {JacquardMachineManufacturerType} from '@domain/machine/jacquard-machine-manufacturer-type.enum';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineManufacturer} from '@domain/machine/machine-manufacturer';
import {MachineType} from '@domain/machine/machine-type.enum';

export class MachineOverviewList {
  private _id: number;
  private _name: string;
  private _manufacturer: MachineManufacturer;
  private _type: JacquardMachineManufacturerType;
  private _machineGroup: MachineGroup;
  private _machineType: MachineType;
  private _numberOfCreelPositions: number;
  private _orderId: string;
  private _dentsForFabric: number;

  public constructor(
    id: number,
    name: string,
    manufacturer: MachineManufacturer,
    type: JacquardMachineManufacturerType,
    machineGroup: MachineGroup,
    machineType: MachineType,
    numberOfCreelPositions?: number,
    orderId?: string,
    dentsForFabric?: number
  ) {
    this._id = id;
    this._name = name;
    this._manufacturer = manufacturer;
    this._type = type;
    this._machineGroup = machineGroup;
    this._machineType = machineType;
    this._numberOfCreelPositions = numberOfCreelPositions;
    this._orderId = orderId;
    this._dentsForFabric = dentsForFabric;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get manufacturer(): MachineManufacturer {
    return this._manufacturer;
  }

  public get type(): JacquardMachineManufacturerType {
    return this._type;
  }

  public get machineGroup(): MachineGroup {
    return this._machineGroup;
  }

  public get machineType(): MachineType {
    return this._machineType;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public get orderId(): string {
    return this._orderId;
  }

  public get dentsForFabric(): number {
    return this._dentsForFabric;
  }

  public static getRandomMachineWithIdAndMachineGroup(id: number, machineGroup: MachineGroup, machineType: MachineType = MachineType.CARPET_LOOM): MachineOverviewList {
    const machineManufacturerBonas: MachineManufacturer = new MachineManufacturer(1, 'Bonas');
    return new MachineOverviewList(id, `${id}`, machineManufacturerBonas, JacquardMachineManufacturerType.S800, machineGroup, machineType);
  }
}
