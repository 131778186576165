import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslationModule} from '../../translation/translation.module';
import {SlideToggleComponent} from './slide-toggle.component';

@NgModule({
  declarations: [SlideToggleComponent],
  imports: [CommonModule, MatSlideToggleModule, TranslationModule],
  exports: [SlideToggleComponent]
})
export class SlideToggleModule {}
