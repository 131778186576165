import {ApplicationMaintenance} from '@domain/profile/application-maintenance';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {find, map, sortBy} from 'lodash-es';

export class CompanySubscriptions {
  private readonly _companies: Company[];
  private readonly _subscriptions: Subscription[];
  private readonly _applicationMaintenance: ApplicationMaintenance;

  public constructor(companies: Company[], subscriptions: Subscription[], applicationMaintenance: ApplicationMaintenance) {
    this._companies = companies;
    this._subscriptions = subscriptions;
    this._applicationMaintenance = applicationMaintenance;
  }

  public get companies(): Company[] {
    return this._companies;
  }

  public get subscriptions(): Subscription[] {
    return this._subscriptions;
  }

  public get applicationMaintenance(): ApplicationMaintenance {
    return this._applicationMaintenance;
  }

  public static fromJSON(companySubscriptionsJSON: any): CompanySubscriptions {
    const companies: Company[] = sortBy(
      map(companySubscriptionsJSON.companies, (companyJSON: any) => Company.fromJSON(companyJSON)),
      (company: Company) => company.name
    );
    const subscriptions: Subscription[] = map(companySubscriptionsJSON.subscriptions, (subscriptionJSON: any) => {
      const subscription: Subscription = Subscription.fromJSON(subscriptionJSON);
      subscription.company = find(companies, {id: subscriptionJSON.companyId});

      return subscription;
    });

    return new CompanySubscriptions(companies, subscriptions, companySubscriptionsJSON.applicationMaintenance);
  }
}
