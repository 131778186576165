import {inject} from '@angular/core';
import {PRODUCTION_SCHEDULE_PLAN} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {AlertDialogResult, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {Observable, Observer} from 'rxjs';

export const canDeactivateProductionScheduleGuard = (): Observable<boolean> => {
  const productionSchedulePlan = inject(PRODUCTION_SCHEDULE_PLAN);
  const dialogBuilderFactoryService = inject(DialogBuilderFactoryService);

  return new Observable((observer: Observer<boolean>) => {
    if (productionSchedulePlan.hasUnsavedChanges(false)) {
      dialogBuilderFactoryService
        .getBuilder()
        .openAlertDialog({
          type: DialogType.DISCARD
        })
        .subscribe((result: AlertDialogResult) => {
          if (isEqual(result, AlertDialogResult.DISCARD)) {
            productionSchedulePlan.discardUnsavedChanges();
            observer.next(true);
          } else {
            observer.next(false);
          }
          observer.complete();
        });
    } else {
      observer.next(true);
      observer.complete();
    }
  });
};
