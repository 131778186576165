import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {MachineType} from '@domain/machine/machine-type.enum';
import {ProductionOrderWeaving} from '@domain/production-order-lite/production-order-weaving/production-order-weaving';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {Machines, MACHINES} from '@infrastructure/http/machine/machines.interface';
import {PRODUCTION_ORDERS_WEAVING, ProductionOrdersWeaving} from '@infrastructure/http/production-order-weaving/production-orders-weaving';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {WEAVE_PRODUCT, WeaveProducts} from '@infrastructure/http/weave-product/weave-products.interface';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  DatepickerHeaderComponent,
  DialogBuilderFactoryService,
  DialogType,
  FormValidationHelper,
  ObjectUtils,
  SaveType,
  skeletonViewAnimation,
  ToastService,
  TranslateService
} from '@vdw/angular-component-library';
import {forkJoin} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {SelectMachineComponent} from '../../production-schedule/add/initial-preparations-new/select-machine/select-machine.component';
import {OverviewListWeavingMachine} from '../overview/overview-list-weaving-machine';
import {NavigationAddProductionOrderWeavingPageData} from './navigation-add-production-order-weaving-page-data.type';
import {NavigationProductionOrderWeavingData} from './navigation-production-order-weaving-data.interface';
import {SelectWeaveProductComponent} from './select-weave-product/select-weave-product.component';

@Component({
  templateUrl: './add-production-order-weaving-page.component.html',
  styleUrls: ['./add-production-order-weaving-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .button-skeleton-wrapper, .mat-expansion-panel, .checkbox-skeleton, .text-skeleton-wrapper, .thread-item header')]
})
export class AddProductionOrderWeavingPageComponent extends BaseComponent implements OnInit {
  public addProductionOrderWeavingForm: FormGroup<{
    name: FormControl<string>;
    weaveProduct: FormControl<OverviewListWeaveProduct>;
    machine: FormControl<OverviewListWeavingMachine>;
    plannedLength: FormControl<number>;
    dueDate: FormControl<Date>;
  }>;

  public suggestedProductionOrderWeavingName: string;
  public translationKeyForProductionOrder = 'PRODUCTION_ORDER.PRODUCTION_ORDER';
  public readonly datepickerHeader = DatepickerHeaderComponent;
  public readonly MACHINE_BASE_ROUTER_LINK = RouteUtils.paths.texState.editMachine.absolutePath.replace(':machineType', 'weaving-machine');
  public readonly WEAVE_PRODUCT_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct.absolutePath;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_PRODUCTION_ORDER_WEAVING;
  public readonly SAVE = SaveType.SAVE;
  public readonly SAVE_AND_CLOSE = SaveType.SAVE_AND_CLOSE;
  public readonly SAVE_AND_CREATE_NEW = SaveType.SAVE_AND_CREATE_NEW;

  private readonly URL_TO_PRODUCTION_ORDER_OVERVIEW = NavigationUtils.getAbsolutePath(NavigationId.WEAVE_ORDER);
  private readonly URL_TO_EDIT_PRODUCTION_ORDER_WEAVING = NavigationUtils.getRoute(NavigationId.EDIT_WEAVE_ORDER);
  private readonly URL_TO_DUPLICATE_PRODUCTION_ORDER_WEAVING = NavigationUtils.getRoute(NavigationId.DUPLICATE_WEAVE_ORDER);
  private readonly QUEUE_PERMISSION = Permission.TEXFAB_WEAVE_QUEUE;
  private readonly EDIT_PERMISSION = Permission.TEXFAB_WEAVE_ORDER_EDIT;
  private saveButtonTouched = false;
  private status: ProductionScheduleStatus = null;
  private runId: number;
  private currentSubscription: Subscription;

  private selectedMachineId: number;
  private selectedWeaveProductId: number;

  public constructor(
    @Inject(PRODUCTION_ORDERS_WEAVING) private readonly productionOrdersWeaving: ProductionOrdersWeaving,
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly navigationHistory: NavigationHistoryService,
    @Inject(MACHINES) private readonly machines: Machines,
    @Inject(WEAVE_PRODUCT) private readonly weaveProducts: WeaveProducts,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly formBuilder: FormBuilder,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHelperService: NavigationHelperService<NavigationAddProductionOrderWeavingPageData>,
    private readonly textileService: TextileService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.setProductionOrderWeavingForm();

    const emptyProductionOrderWeavingState = {productionOrderWeaving: null} as NavigationProductionOrderWeavingData;
    const productionOrderWeavingState = this.navigationHelperService.getPartialState<NavigationProductionOrderWeavingData>(Object.keys(emptyProductionOrderWeavingState));

    if (!AssertionUtils.isNullOrUndefined(productionOrderWeavingState) && !ObjectUtils.isDeepEqual(productionOrderWeavingState, emptyProductionOrderWeavingState)) {
      this.initializeWithProductionOrderWeavingState(productionOrderWeavingState.productionOrderWeaving);
    } else if (this.isEditingProductionOrderWeaving() || this.isDuplicatingProductionOrderWeaving()) {
      this.productionOrdersWeaving
        .getById(Number(this.activatedRoute.snapshot.params.id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (productionOrderWeaving: ProductionOrderWeaving) => {
            this.status = productionOrderWeaving.status;
            this.setFormValues(productionOrderWeaving);
          },
          error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.URL_TO_PRODUCTION_ORDER_OVERVIEW)
        });
    } else if (this.isAddingProductionOrderForRun()) {
      const {runId, weaveProductId, machineId} = this.navigationHistory.getState() as {
        runId: number;
        weaveProductId: number;
        machineId: number;
      };
      this.initializeForRun(runId, weaveProductId, machineId);
    } else if (this.isAddingProductionOrderForMachine()) {
      this.initializeForMachine(Number(this.activatedRoute.snapshot.params.id));
    }
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationProductionOrderWeavingData>({productionOrderWeaving: this.getCurrentProductionOrderWeaving()});
  }

  public hasChosenProperty(propertyName: string): boolean {
    return !AssertionUtils.isNullOrUndefined(this.getPropertyValue(propertyName));
  }

  public getChosenProperty(propertyName: string): any {
    return this.getPropertyValue(propertyName);
  }

  public getControlDisabled(propertyName: string): any {
    return this.addProductionOrderWeavingForm.get(propertyName).disabled;
  }

  public selectMachine(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectMachineComponent, {
        allowedMachineTypes: [MachineType.WEAVING_MACHINE],
        weaveProductId: this.selectedWeaveProductId
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((selectedMachine: OverviewListWeavingMachine) => {
        if (!AssertionUtils.isNullOrUndefined(selectedMachine)) {
          this.addProductionOrderWeavingForm.controls.machine.setValue(selectedMachine);
        }
      });
  }

  public selectWeaveProduct(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectWeaveProductComponent, {
        machineId: this.selectedMachineId
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((selectedWeaveProduct: OverviewListWeaveProduct) => {
        if (!!selectedWeaveProduct) {
          this.addProductionOrderWeavingForm.controls.weaveProduct.setValue(selectedWeaveProduct);
        }
      });
  }

  public clearMachineValue(): void {
    this.addProductionOrderWeavingForm.controls.machine.setValue(null);
  }

  public getEarliestPossibleDueDate(): Date {
    return new Date();
  }

  public cancel(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.URL_TO_PRODUCTION_ORDER_OVERVIEW);
  }

  public saveProductionOrderWeaving(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.addProductionOrderWeavingForm], this.document);

    if (isValid) {
      const productionOrderWeavingToSave = this.getCurrentProductionOrderWeaving();
      this.saving = true;
      this.isEditingProductionOrderWeaving() ? this.editProductionOrderWeaving(productionOrderWeavingToSave, saveType) : this.addProductionOrderWeaving(productionOrderWeavingToSave, saveType);
    }
  }

  public duplicateProductionOrderWeaving(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.DUPLICATE_WEAVE_ORDER).replace(':id', this.activatedRoute.snapshot.params.id));
  }

  public deleteProductionOrderWeaving(): void {
    this.textileService.removeConfirmation(this.getCurrentProductionOrderWeaving(), TextileDataType.PRODUCTION_ORDER_WEAVING, false, null, this.productionOrdersWeaving);
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = Object.values(this.addProductionOrderWeavingForm.controls).some((control: FormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public getTitle(): string {
    const title = this.isEditingProductionOrderWeaving() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: (this.translate.instant(this.translationKeyForProductionOrder, {count: 1}) as string).toLowerCase()});
  }

  public hasPermissionToEditProductionOrderWeaving(): boolean {
    return this.currentSubscription?.hasPermission(this.EDIT_PERMISSION);
  }

  public canShowDelete(): boolean {
    return this.status === ProductionScheduleStatus.CREATED || this.status === ProductionScheduleStatus.TO_PLAN;
  }

  public canDisableForm(): boolean {
    return this.isEditingProductionOrderWeaving() && (!this.currentSubscription?.hasPermission(this.EDIT_PERMISSION) || this.status !== ProductionScheduleStatus.TO_PLAN);
  }

  public isEditingProductionOrderWeaving(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.URL_TO_EDIT_PRODUCTION_ORDER_WEAVING;
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.WEAVE_ORDER_SETTINGS));
  }

  public canShowSuggestionForProductionOrderWeavingName(): boolean {
    return this.suggestedProductionOrderWeavingName?.length > 0 && this.addProductionOrderWeavingForm.get('name').value !== this.suggestedProductionOrderWeavingName;
  }

  public applySuggestionForProductionOrderWeavingName(): void {
    this.addProductionOrderWeavingForm.get('name').setValue(this.suggestedProductionOrderWeavingName);
  }

  private addProductionOrderWeaving(productionOrderWeaving: ProductionOrderWeaving, saveType: SaveType): void {
    const addProductionOrderWeavingAction = this.canQueue() ? this.productionOrdersWeaving.saveAndPlan(productionOrderWeaving, this.runId) : this.productionOrdersWeaving.save(productionOrderWeaving);

    addProductionOrderWeavingAction.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
      next: (id: number) => this.textileService.navigateAndShowToast(saveType, TextileDataType.PRODUCTION_ORDER_WEAVING, this.translationKeyForProductionOrder, false, productionOrderWeaving.name, id),
      error: (backendError: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.CREATE_OBJECT', backendError)
    });
  }

  private editProductionOrderWeaving(productionOrderWeaving: ProductionOrderWeaving, saveType: SaveType): void {
    const editProductionOrderWeavingAction = this.hasChosenProperty('machine')
      ? this.productionOrdersWeaving.updateAndPlan(productionOrderWeaving, this.runId)
      : this.productionOrdersWeaving.update(productionOrderWeaving);

    editProductionOrderWeavingAction.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
      next: () => this.textileService.navigateAndShowToast(saveType, TextileDataType.PRODUCTION_ORDER_WEAVING, this.translationKeyForProductionOrder, true, productionOrderWeaving.name),
      error: (backendError: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.EDIT_OBJECT', backendError)
    });
  }

  private showErrorDialogForBackendError(action: string, error: BackendError): void {
    if (Number(BackendErrorCodeEnum[error.errorContext?.errorCode]) === BackendErrorCodeEnum.LINKED_ENTITIES) {
      this.showProductionOrderIncompatibleWithMachineAlert(error);
    } else {
      this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
        titleText: this.translate.instant(action, {
          object: this.translate.instant(this.translationKeyForProductionOrder, {count: 1})
        }),
        messageText: error.message,
        type: DialogType.INFORMATION
      });
    }
  }

  private showProductionOrderIncompatibleWithMachineAlert(error: BackendError): void {
    const productionOrderName = this.getPropertyValue('name');
    const machineName = this.getPropertyValue('machine').name;
    const INCOMPATIBLE_MESSAGE_KEY = 'PLANNING.ERRORS.INCOMPATIBLE_PRODUCTION_ORDER';
    const translationParams = {
      productionOrderName,
      machineName,
      amountOfCompatibleMachines: error.errorContext.linkedEntityNames?.length ?? 0
    };

    if (translationParams.amountOfCompatibleMachines > 0) {
      translationParams['compatibleMachinesList'] = error.errorContext.linkedEntityNames.join(', ');
    }

    const dialogResult = this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant('PLANNING.ERRORS.INCOMPATIBLE_ENTITY', {entity: (this.translate.instant('PRODUCTION_ORDER.PRODUCTION_ORDER', {count: 1}) as string).toLowerCase()}),
      messageText: this.translate.instant(INCOMPATIBLE_MESSAGE_KEY, translationParams),
      type: DialogType.INFORMATION
    });

    if (!this.isEditingProductionOrderWeaving() && !AssertionUtils.isNullOrUndefined(error.errorContext.entityId)) {
      dialogResult.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
        this.router.navigate([NavigationUtils.getAbsolutePath(NavigationId.EDIT_WEAVE_ORDER).replace(':id', error.errorContext.entityId)]);
        this.showToast(productionOrderName);
      });
    }
  }

  private showToast(productionOrderWeavingName: string): void {
    this.toastService.showInfo({
      tapToDismiss: false,
      timeOut: 2000,
      message: this.translate.instant(this.isEditingProductionOrderWeaving() ? 'GENERAL.ACTIONS.EDITED_OBJECT' : 'GENERAL.ACTIONS.CREATED_OBJECT', {
        object: this.translate.instant(this.translationKeyForProductionOrder, {count: 1}),
        name: productionOrderWeavingName,
        count: 1
      })
    });
  }

  private getCurrentProductionOrderWeaving(): ProductionOrderWeaving {
    const name: string = this.getPropertyValue('name');
    const weaveProduct: OverviewListWeaveProduct = this.getPropertyValue('weaveProduct');
    const machine: OverviewListWeavingMachine = this.getPropertyValue('machine');
    const plannedLength: number = this.getPropertyValue('plannedLength');
    const dueDate: Date = this.getPropertyValue('dueDate');
    return new ProductionOrderWeaving(
      this.isEditingProductionOrderWeaving() ? Number(this.activatedRoute.snapshot.params.id) : null,
      name,
      weaveProduct,
      machine,
      plannedLength,
      null,
      dueDate,
      this.status
    );
  }

  private setProductionOrderWeavingForm(): void {
    this.addProductionOrderWeavingForm = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required, Validators.maxLength(24)], AsyncUniqueValidator.createValidator(this.productionSchedules, null)),
      weaveProduct: this.formBuilder.control(null, Validators.required),
      machine: this.formBuilder.control({value: null, disabled: !this.hasQueuePermission()}),
      plannedLength: this.formBuilder.control(null, [Validators.required, Validators.min(1)]),
      dueDate: this.formBuilder.control(null)
    });

    this.addProductionOrderWeavingForm.controls.machine.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.selectedMachineId = this.addProductionOrderWeavingForm.controls.machine.value?.id;
      if (this.addProductionOrderWeavingForm.controls.machine.valid) {
        this.getSuggestionForProductionOrderWeavingName();
      }
    });

    this.addProductionOrderWeavingForm.controls.weaveProduct.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.selectedWeaveProductId = this.addProductionOrderWeavingForm.controls.weaveProduct.value?.id;
      if (this.addProductionOrderWeavingForm.controls.weaveProduct.valid) {
        this.getSuggestionForProductionOrderWeavingName();
      }
    });
  }

  private setWeaveProduct(id: number): void {
    this.weaveProducts
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((weaveProducts: OverviewListWeaveProduct[]) =>
        this.addProductionOrderWeavingForm.get('weaveProduct').setValue(weaveProducts.find((weaveProduct: OverviewListWeaveProduct) => weaveProduct.id === id))
      );
  }

  private initializeWithProductionOrderWeavingState(productionOrderWeaving: ProductionOrderWeaving): void {
    this.status = productionOrderWeaving.status;
    this.setFormValues(productionOrderWeaving);

    if (this.isAddingProductionOrderForRun()) {
      this.addProductionOrderWeavingForm.get('weaveProduct').disable();
      this.addProductionOrderWeavingForm.get('machine').disable();
    } else if (this.isAddingProductionOrderForMachine()) {
      this.addProductionOrderWeavingForm.get('machine').disable();
    }
  }

  private setFormValues(productionOrderWeaving: ProductionOrderWeaving): void {
    let dueDate = productionOrderWeaving.dueDate;
    if (this.canDisableForm()) {
      this.addProductionOrderWeavingForm.disable();
    } else if (!AssertionUtils.isNullOrUndefined(dueDate) && dueDate <= this.getEarliestPossibleDueDate()) {
      dueDate = null;
    }

    this.addProductionOrderWeavingForm.patchValue({
      name: this.isDuplicatingProductionOrderWeaving() ? null : productionOrderWeaving.name,
      weaveProduct: productionOrderWeaving.weaveProduct,
      machine: this.hasQueuePermission() ? productionOrderWeaving.machine : null,
      plannedLength: productionOrderWeaving.plannedLength,
      dueDate: dueDate
    });

    this.addProductionOrderWeavingForm.controls.name.setAsyncValidators(
      AsyncUniqueValidator.createValidator(this.productionSchedules, this.isDuplicatingProductionOrderWeaving() ? null : productionOrderWeaving.name)
    );
    this.addProductionOrderWeavingForm.controls.name.updateValueAndValidity();

    this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
      ({newItemId}: NavigationNewItemData) => this.setWeaveProduct(newItemId),
      () => this.selectWeaveProduct()
    );
  }

  private initializeForRun(runId: number, weaveProductId: number, machineId: number): void {
    if (AssertionUtils.isNullOrUndefined(runId) || AssertionUtils.isNullOrUndefined(weaveProductId) || AssertionUtils.isNullOrUndefined(machineId)) {
      this.navigateToPlanning();
      return;
    }
    this.runId = runId;
    forkJoin([this.weaveProducts.getAll(), this.machines.getSummaryForMachine(machineId)])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([weaveProducts, machineSummary]: [OverviewListWeaveProduct[], MachineSummary]) => {
        const weaveProduct = weaveProducts?.find((style: OverviewListWeaveProduct) => style.id === weaveProductId);
        const machine = OverviewListWeavingMachine.fromMachineSummary(machineSummary);
        const weaveProductControl = this.addProductionOrderWeavingForm.get('weaveProduct');
        const machineControl = this.addProductionOrderWeavingForm.get('machine');
        weaveProductControl.setValue(weaveProduct);
        weaveProductControl.disable({emitEvent: true});
        machineControl.setValue(machine);
        machineControl.disable({emitEvent: true});
      });

    this.selectedWeaveProductId = weaveProductId;
  }

  private initializeForMachine(machineId: number): void {
    if (isNaN(machineId)) {
      return;
    }
    this.machines
      .getSummaryForMachine(machineId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((machineSummary: MachineSummary) => {
        const machine = OverviewListWeavingMachine.fromMachineSummary(machineSummary);
        const machineControl = this.addProductionOrderWeavingForm.get('machine');
        machineControl.setValue(machine);
        machineControl.disable({emitEvent: true});
      });

    this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
      ({newItemId}: NavigationNewItemData) => this.setWeaveProduct(newItemId),
      () => this.selectWeaveProduct()
    );
  }

  private navigateToPlanning(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.PLANNING));
  }

  private getPropertyValue(propertyName: string): any {
    return this.addProductionOrderWeavingForm.get(propertyName).value;
  }

  private isDuplicatingProductionOrderWeaving(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === this.URL_TO_DUPLICATE_PRODUCTION_ORDER_WEAVING;
  }

  private isAddingProductionOrderForRun(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path.endsWith(NavigationUtils.getRoute(NavigationId.ADD_WEAVE_ORDER_RUN));
  }

  private isAddingProductionOrderForMachine(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path.endsWith(NavigationUtils.getRoute(NavigationId.ADD_WEAVE_ORDER_MACHINE));
  }

  private canQueue(): boolean {
    return this.hasQueuePermission() && this.hasChosenProperty('machine');
  }

  private hasQueuePermission(): boolean {
    return this.currentSubscription?.hasPermission(this.QUEUE_PERMISSION);
  }

  private getSuggestionForProductionOrderWeavingName(): void {
    if (this.addProductionOrderWeavingForm.controls.machine.valid && this.addProductionOrderWeavingForm.controls.weaveProduct.valid && this.currentSubscription?.hasPermission(this.EDIT_PERMISSION)) {
      this.productionOrdersWeaving
        .generateName(this.getCurrentProductionOrderWeaving())
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((name: string) => (this.suggestedProductionOrderWeavingName = name));
    }
  }
}
