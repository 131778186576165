import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {AssertionUtils} from '@vdw/angular-component-library';
import {ColoredYarnSetPosition} from './colored-yarn-set-position';
import {CreelMap} from './creel-map';
import {OverviewListCreelPosition} from './overview-list-creel-position';

export class OverviewListColoredYarnSetWithStartDent {
  private _coloredYarnSet: OverviewListColoredYarnSet;
  private _startDent: number;
  private _technicalWidthInDents: number;
  private _position: ColoredYarnSetPosition;

  public constructor(coloredYarnSet: OverviewListColoredYarnSet, startDent: number, technicalWidthInDents: number) {
    this._coloredYarnSet = coloredYarnSet;
    this._startDent = startDent;
    this._technicalWidthInDents = technicalWidthInDents;
  }

  public get coloredYarnSet(): OverviewListColoredYarnSet {
    return this._coloredYarnSet;
  }

  public set coloredYarnSet(value: OverviewListColoredYarnSet) {
    this._coloredYarnSet = value;
  }

  public get startDent(): number {
    return this._startDent;
  }

  public set startDent(value: number) {
    this._startDent = value;
  }

  public get technicalWidthInDents(): number {
    return this._technicalWidthInDents;
  }

  public set technicalWidthInDents(value: number) {
    this._technicalWidthInDents = value;
  }

  public get position(): ColoredYarnSetPosition {
    return this._position;
  }

  public set position(position: ColoredYarnSetPosition) {
    this._position = position;
  }

  public static fromJSON(coloredYarnSetWithStartDentJSON: any): OverviewListColoredYarnSetWithStartDent {
    const coloredYarnSet = new OverviewListColoredYarnSetWithStartDent(
      OverviewListColoredYarnSet.fromJSON(coloredYarnSetWithStartDentJSON.coloredYarnSet),
      coloredYarnSetWithStartDentJSON.startDent,
      coloredYarnSetWithStartDentJSON.technicalWidthInDents
    );

    if (!AssertionUtils.isNullOrUndefined(coloredYarnSetWithStartDentJSON.bottomTop)) {
      coloredYarnSet.position = ColoredYarnSetPosition[`${coloredYarnSetWithStartDentJSON.bottomTop}`];
    }

    return coloredYarnSet;
  }

  public toJSON(): JSON {
    return {
      startDent: this._startDent,
      technicalWidthInDents: this._technicalWidthInDents,
      coloredYarnSet: {
        id: this._coloredYarnSet.id,
        name: this._coloredYarnSet.name,
        colorSet: this._coloredYarnSet.overviewListColorSet.toJSON(),
        yarnSet: this._coloredYarnSet.overviewListYarnSet.toJSON()
      },
      bottomTop: AssertionUtils.isNullOrUndefined(this.position) ? ColoredYarnSetPosition.BOTTOM : this.position
    } as any as JSON;
  }

  public isBottomPosition(): boolean {
    return this._position === ColoredYarnSetPosition.BOTTOM;
  }

  public isEqual(other: OverviewListColoredYarnSetWithStartDent): boolean {
    return this.coloredYarnSet.id === other.coloredYarnSet.id && this._position === other.position;
  }

  public getOriginalMappingForCurrentPositionOnMachine(): CreelMap {
    return {
      coloredYarnSetId: this.coloredYarnSet.id,
      creelPositions: this.coloredYarnSet.overviewListColorSet.creelPositions.map((creelPosition: OverviewListCreelPosition) => ({
        creelPosition: creelPosition.position,
        currentPositionOnMachine: creelPosition.position
      }))
    };
  }
}
