<div
  class="zone-input-wrapper"
  [ngClass]="{'zone-input--error-state': isErrorState, 'zone-input--disabled': disabled}"
  (click)="togglePopup($event)"
  [matTooltip]="'PRODUCTION_ORDER.WEAVE_PLAN.' + (zoneType === zoneInputType.FREEZONE ? 'FREE_ZONE.FREE_ZONE' : 'REST_ZONE.REST_ZONE') | bmsTranslate: {count: 1}"
  matTooltipPosition="left"
>
  <div class="zone-input-error-popup" (click)="onPopupClick($event)">
    <span class="no-wrap">{{ getZoneInputErrorLabel() }}</span>
  </div>
  <div class="zone-input" [class.zone-input--freezone]="zoneType === zoneInputType.FREEZONE">
    <span class="zone-input-label">{{ getZoneInputLabel() }}</span>
  </div>
  <div *ngIf="isPopupVisible" class="zone-input-popup" (click)="onPopupClick($event)">
    <input #inputField vdwL10nDecimal type="number" [(ngModel)]="technicalValue" (input)="onChangeTechnicalValue()" />
    <label for="technicalValue">{{ 'ANGULAR_COMPONENT_LIBRARY.ZONE_INPUT.UNIT.DENTS' | bmsTranslate }}</label>
    <mat-icon class="close-button" svgIcon="close" (click)="closePopup($event)"></mat-icon>
  </div>
</div>
