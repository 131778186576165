import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {NeedsAttentionItem} from '@domain/planning/needs-attention-item';
import {PlanningOrderData} from '@domain/planning/planning-order-data';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PropertyValue} from '@domain/property-value';
import {PLANNING, Planning} from '@infrastructure/http/planning/planning';
import {PRODUCTION_ORDERS_WEAVING, ProductionOrdersWeaving} from '@infrastructure/http/production-order-weaving/production-orders-weaving';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {HttpTuftProductionOrdersService} from '@infrastructure/http/tuft-production-order/http-tuft-production-orders.service';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {AdvancedSearchDialogComponent} from '@presentation/components/search-filters/advanced-search/dialog/advanced-search-dialog.component';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {ConfirmProductionOrderDeletionCommand} from '@presentation/pages/texfab/planning/confirm-production-order-deletion/confirm-production-order-deletion-command';
import {AssertionUtils, BaseComponent, DialogBuilderFactoryService, FilterComponent, TranslateService} from '@vdw/angular-component-library';
import {cloneDeep, find, isEmpty} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-needs-attention-sidebar',
  templateUrl: './needs-attention-sidebar.component.html',
  styleUrls: ['./needs-attention-sidebar.component.scss']
})
export class NeedsAttentionSidebarComponent extends BaseComponent implements OnInit {
  @Output() public closeNeedsAttentionSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() public unplannedOrderDragStart: EventEmitter<{event: DragEvent; unplannedOrder: PlanningOrderData}> = new EventEmitter<{
    event: DragEvent;
    unplannedOrder: PlanningOrderData;
  }>();

  @Output() public unplannedOrderDrag: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
  @Output() public unplannedOrderDragEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() public selectPlanningOrder: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('nameFilter') public nameFilter: FilterComponent;
  @ViewChild('searchFilters') public searchFilters: SearchFiltersComponent;

  public filteredNeedsAttentionItems: NeedsAttentionItem[];
  public loadingProductionOrders = true;
  public advancedSearchFilters: PropertyValue[] = [
    {propertyName: AdvancedSearchInput.NAME},
    {propertyName: AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS, possiblePropertyValues: [ProductionScheduleStatus.TO_PLAN, ProductionScheduleStatus.TO_RECONFIRM]},
    {propertyName: AdvancedSearchInput.MACHINE_QUALITY},
    {propertyName: AdvancedSearchInput.CREEL},
    {propertyName: AdvancedSearchInput.WEAVE_PRODUCT},
    {propertyName: AdvancedSearchInput.TUFT_PRODUCT}
  ];

  private nameFilterChangeSubject: Subject<string> = new Subject<string>();
  private needsAttentionItems: NeedsAttentionItem[];

  public constructor(
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    @Inject(PLANNING) private readonly planning: Planning,
    @Inject(PRODUCTION_ORDERS_WEAVING) private readonly productionOrdersWeaving: ProductionOrdersWeaving,
    private readonly productionOrdersTufting: HttpTuftProductionOrdersService,
    private readonly confirmProductionOrderDeletionCommand: ConfirmProductionOrderDeletionCommand,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.planning
      .getOrdersThatRequireAttention()
      .pipe(
        finalize(() => (this.loadingProductionOrders = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((items: NeedsAttentionItem[]): void => {
        this.needsAttentionItems = items;
        this.filteredNeedsAttentionItems = items;
      });
    this.subscribeToNameFilterChanges();
  }

  public close(): void {
    this.closeNeedsAttentionSidebar.emit();
  }

  public hasProductionOrders(): boolean {
    return !isEmpty(this.needsAttentionItems);
  }

  public deleteUnplannedOrder(unplannedItem: NeedsAttentionItem): void {
    const title = this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {
      object: unplannedItem.productionOrderCarpet?.name || unplannedItem.productionOrderWeaving?.name || unplannedItem.productionOrderTufting?.name
    });
    const message = this.translate.instant('GENERAL.ACTIONS.CONFIRM_DELETE_DESCRIPTION');

    this.confirmProductionOrderDeletionCommand
      .execute({
        title,
        message
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        if (unplannedItem.isCarpetOrder) {
          this.productionSchedules
            .delete(unplannedItem.productionOrderCarpet.id)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe(() => this.removeProductionOrderCarpet(unplannedItem.productionOrderCarpet.id));
        }
        if (unplannedItem.isWeavingOrder) {
          this.productionOrdersWeaving
            .delete(unplannedItem.productionOrderWeaving.id)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe(() => this.removeProductionOrderWeaving(unplannedItem.productionOrderWeaving.id));
        }
        if (unplannedItem.isTuftingOrder) {
          this.productionOrdersTufting
            .delete(unplannedItem.productionOrderTufting.id)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe(() => this.removeProductionOrderTufting(unplannedItem.productionOrderTufting.id));
        }
      });
  }

  public onUnplannedOrderDragStart(event: DragEvent, unplannedItem: NeedsAttentionItem): void {
    this.unplannedOrderDragStart.emit({event, unplannedOrder: unplannedItem.productionOrderCarpet || unplannedItem.productionOrderWeaving || unplannedItem.productionOrderTufting});
  }

  public onUnplannedOrderDrag(event: DragEvent): void {
    this.unplannedOrderDrag.emit(event);
  }

  public onUnplannedOrderDragEnd(): void {
    this.unplannedOrderDragEnd.emit();
  }

  public removeProductionOrderCarpet(orderId: number): void {
    const filterFn = (needsAttentionItem: NeedsAttentionItem): boolean => needsAttentionItem.productionOrderCarpet?.id !== orderId;
    this.needsAttentionItems = this.needsAttentionItems.filter(filterFn);
    this.filteredNeedsAttentionItems = this.filteredNeedsAttentionItems.filter(filterFn);
  }

  public removeProductionOrderWeaving(orderId: number): void {
    const filterFn = (needsAttentionItem: NeedsAttentionItem): boolean => needsAttentionItem.productionOrderWeaving?.id !== orderId;
    this.needsAttentionItems = this.needsAttentionItems.filter(filterFn);
    this.filteredNeedsAttentionItems = this.filteredNeedsAttentionItems.filter(filterFn);
  }

  public removeProductionOrderTufting(orderId: number): void {
    const filterFn = (needsAttentionItem: NeedsAttentionItem): boolean => needsAttentionItem.productionOrderTufting?.id !== orderId;
    this.needsAttentionItems = this.needsAttentionItems.filter(filterFn);
    this.filteredNeedsAttentionItems = this.filteredNeedsAttentionItems.filter(filterFn);
  }

  public viewPlannedOrderDetails(planningOrderId: number): void {
    this.selectPlanningOrder.emit(planningOrderId);
  }

  public showAdvancedSearch(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass(['reposition-dialog', 'needs-attention-advanced-search'])
      .withWidth('650px')
      .withMaxHeight('100%')
      .openDialog(RepositionDialogComponent, {
        advancedSearchFilters: cloneDeep(this.advancedSearchFilters),
        component: AdvancedSearchDialogComponent,
        sourceElement: this.nameFilter.iconElement.nativeElement,
        positionOfDialog: PositionOfDialog.BOTTOM
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((advancedSearchFilters: PropertyValue[]) => {
        if (!isEmpty(advancedSearchFilters)) {
          this.advancedSearchFilters = advancedSearchFilters;
          this.filterNeedsAttentionProductionOrders();
        }
      });
  }

  public nameFilterTextChanged(filterText: string): void {
    this.nameFilterChangeSubject.next(filterText);
  }

  public getNameFilterValue(): string {
    return find(this.advancedSearchFilters, {propertyName: AdvancedSearchInput.NAME}).propertyValue;
  }

  public filterNeedsAttentionProductionOrders(): void {
    this.filteredNeedsAttentionItems = this.needsAttentionItems.filter(this.filterNeedsAttentionItem);
    this.searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();
  }

  public hasFilteredProductionOrders(): boolean {
    return !AssertionUtils.isEmpty(this.filteredNeedsAttentionItems);
  }

  private subscribeToNameFilterChanges(): void {
    this.nameFilterChangeSubject
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((filterText: string) => {
        find(this.advancedSearchFilters, {propertyName: AdvancedSearchInput.NAME}).propertyValue = filterText;
        this.filterNeedsAttentionProductionOrders();
      });
  }

  private filterNeedsAttentionItem = (needsAttentionItem: NeedsAttentionItem): boolean => {
    const orderData = needsAttentionItem.productionOrderCarpet || needsAttentionItem.productionOrderWeaving || needsAttentionItem.productionOrderTufting;
    let result = true;

    for (const advancedSearchFilter of this.advancedSearchFilters) {
      if (!result) {
        return false;
      }
      if (!AdvancedSearchUtils.hasValue(advancedSearchFilter)) {
        continue;
      }
      result = this.applySearchFilter(needsAttentionItem, orderData, advancedSearchFilter);
    }

    return result;
  };

  private applySearchFilter(needsAttentionItem: NeedsAttentionItem, orderData: PlanningOrderData, advancedSearchFilter: PropertyValue): boolean {
    switch (advancedSearchFilter.propertyName) {
      case AdvancedSearchInput.NAME:
        return orderData.name?.toLowerCase().includes(advancedSearchFilter.propertyValue?.toLowerCase());

      case AdvancedSearchInput.PRODUCTION_SCHEDULE_STATUS:
        return orderData.status === advancedSearchFilter.propertyValue;

      case AdvancedSearchInput.MACHINE_QUALITY:
        return needsAttentionItem.isCarpetOrder && needsAttentionItem.productionOrderCarpet.machineQuality.id === advancedSearchFilter.propertyValue.id;

      case AdvancedSearchInput.CREEL:
        return needsAttentionItem.isCarpetOrder && needsAttentionItem.productionOrderCarpet.creel.id === advancedSearchFilter.propertyValue.id;

      case AdvancedSearchInput.WEAVE_PRODUCT:
        return needsAttentionItem.isWeavingOrder && needsAttentionItem.productionOrderWeaving.weaveProduct.id === advancedSearchFilter.propertyValue.id;

      case AdvancedSearchInput.TUFT_PRODUCT:
        return needsAttentionItem.isTuftingOrder && needsAttentionItem.productionOrderTufting.tuftProduct.id === advancedSearchFilter.propertyValue.id;
    }
  }
}
