import {Command} from '@application/command/command';
import {CalendarItem} from '@domain/planning/calendar-item';
import {CalendarItemGroup} from '@domain/planning/calendar-item-group';
import {moment, TimeUtils} from '@vdw/angular-component-library';
import {each, isEmpty, isEqual, remove} from 'lodash-es';
import {RemovePlanningOrderParameters} from './remove-planning-order-parameters';

export class RemovePlanningOrderCommand implements Command<RemovePlanningOrderParameters, CalendarItemGroup[]> {
  public execute(commandArgs: RemovePlanningOrderParameters): CalendarItemGroup[] {
    const {sourceGroupedCalendarItem, groupedCalendarItems, planningOrder, run} = commandArgs;

    const adjustRunRunningTime = !!commandArgs.adjustRunRunningTime;

    const [removedPlanningOrder] = remove(run.planningOrders, {id: planningOrder.id});
    const runEndDate: Date =
      adjustRunRunningTime && !isEmpty(run.planningOrders) ? moment(run.endDate).subtract(removedPlanningOrder.totalRunningTimeInMs, TimeUtils.MILLISECONDS_UNIT).toDate() : run.endDate;

    run.reschedule(run.startDate, runEndDate);

    each(sourceGroupedCalendarItem.items, (item: CalendarItem, index: number) => {
      if (isEqual(item.id, run.id)) {
        sourceGroupedCalendarItem.items[index] = run;
        return false;
      }
    });

    each(groupedCalendarItems, (groupedCalendarItem: CalendarItemGroup, index: number) => {
      if (isEqual(groupedCalendarItem.machineId, sourceGroupedCalendarItem.machineId)) {
        groupedCalendarItems[index] = sourceGroupedCalendarItem;
        return false;
      }
    });

    return groupedCalendarItems;
  }
}
