import {BmpProductionScheduleItemInPathGroup} from '@domain/production-schedule/bmp-production-schedule-item-in-path-group';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {PathLabelDrawing} from '@domain/production-schedule/path-label-drawing';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleErrors} from '@domain/production-schedule/production-schedule-errors';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {each, filter, find, flatMap, isEmpty, isEqual, map, reduce, size, some, sumBy, uniqBy} from 'lodash-es';

export class ProductionSchedulePathsOfColoredYarnSet {
  private _productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent;
  private _productionSchedulePaths: ProductionSchedulePath[];
  private _errors: ProductionScheduleErrors;

  public constructor(productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent, productionSchedulePaths: ProductionSchedulePath[], errors: ProductionScheduleErrors) {
    this._productionScheduleColoredYarnSet = productionScheduleColoredYarnSet;
    this._productionSchedulePaths = productionSchedulePaths;
    this._errors = errors;
  }

  public get productionSchedulePaths(): ProductionSchedulePath[] {
    return this._productionSchedulePaths;
  }

  public set productionSchedulePaths(productionSchedulePaths: ProductionSchedulePath[]) {
    this._productionSchedulePaths = productionSchedulePaths;
  }

  public get productionScheduleColoredYarnSet(): OverviewListColoredYarnSetWithStartDent {
    return this._productionScheduleColoredYarnSet;
  }

  public set productionScheduleColoredYarnSet(productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent) {
    this._productionScheduleColoredYarnSet = productionScheduleColoredYarnSet;
  }

  public get errors(): ProductionScheduleErrors {
    return this._errors;
  }

  public set errors(errors: ProductionScheduleErrors) {
    this._errors = errors;
  }

  public static fromJSON(productionSchedulePathsOfColoredYarnSetJSON: any): ProductionSchedulePathsOfColoredYarnSet {
    return new ProductionSchedulePathsOfColoredYarnSet(
      OverviewListColoredYarnSetWithStartDent.fromJSON(productionSchedulePathsOfColoredYarnSetJSON),
      productionSchedulePathsOfColoredYarnSetJSON.productionSchedulePaths.map((productionSchedulePathJSON: any) => ProductionSchedulePath.fromJSON(productionSchedulePathJSON)),
      productionSchedulePathsOfColoredYarnSetJSON.errors
    );
  }

  public getProductionSchedulePath(pathNumber: number): ProductionSchedulePath {
    return find(this.productionSchedulePaths, ['pathNumber', pathNumber]);
  }

  public hasProductionScheduleItem(productionScheduleItem: ProductionScheduleItemInPathGroup): boolean {
    return some(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.hasProductionScheduleItem(productionScheduleItem));
  }

  public hasProductionSchedulePath(productionSchedulePath: ProductionSchedulePath): boolean {
    return some(this.productionSchedulePaths, ['name', productionSchedulePath.name]);
  }

  public getProductionSchedulePathAt(index: number): ProductionSchedulePath {
    return size(this.productionSchedulePaths) > index ? this.productionSchedulePaths[index] : null;
  }

  public getNonRecoloredProductionScheduleItems(): ProductionScheduleItemInPathGroup[] {
    return flatMap(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
      return productionSchedulePath.getNonRecoloredProductionScheduleItems();
    });
  }

  public getUniquePathLabelDrawings(): PathLabelDrawing[] {
    return uniqBy(
      flatMap(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
        return map(productionSchedulePath.getUniquePathLabelDrawings());
      }),
      'image.id'
    );
  }

  public toJSON(): JSON {
    return {
      coloredYarnSet: this._productionScheduleColoredYarnSet.coloredYarnSet.toJSON(),
      startDent: this._productionScheduleColoredYarnSet.startDent,
      productionSchedulePaths: map(this._productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.toJSON()),
      technicalWidthInDents: this._productionScheduleColoredYarnSet.technicalWidthInDents
    } as any as JSON;
  }

  public getProductionSchedulePathsWidthInDents(): number {
    return sumBy(this._productionSchedulePaths, 'technicalWidthInDents');
  }

  public getProductionSchedulePathsWidthInMillimeters(): number {
    return sumBy(this._productionSchedulePaths, 'commercialWidthInMM');
  }

  public updatePathLengths(productionSchedule: ProductionSchedule): void {
    each(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
      productionSchedulePath.updatePathLength(productionSchedule);
    });
  }

  public isEqual(other: ProductionSchedulePathsOfColoredYarnSet): boolean {
    return isEqual(this.productionScheduleColoredYarnSet.coloredYarnSet.id, other.productionScheduleColoredYarnSet.coloredYarnSet.id);
  }

  public updateLoadedPathLabelDrawing(pathLabelDrawing: PathLabelDrawing): void {
    const productionScheduleItems: ProductionScheduleItemInPathGroup[] = filter(
      flatMap(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
        return productionSchedulePath.pathGroups;
      }),
      ['drawing.image.id', pathLabelDrawing.image.id]
    );

    each(productionScheduleItems, (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => {
      productionScheduleItemInPathGroup.recolorDrawing(pathLabelDrawing);
    });
  }

  public updateFinishing(finishing: FinishingForProductionSchedule): void {
    const productionscheduleItems: ProductionScheduleItemInPathGroup[] = filter(
      flatMap(this.productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.pathGroups),
      (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup) => productionScheduleItemInPathGroup instanceof BmpProductionScheduleItemInPathGroup
    );
    each(productionscheduleItems, (productionScheduleItemInPathGroup: BmpProductionScheduleItemInPathGroup) => {
      productionScheduleItemInPathGroup.updateFinishing(finishing);
    });
  }

  public getOrderLineQuantityInProductionSchedulePaths(orderLineId: number): number {
    return reduce(
      this.productionSchedulePaths,
      (quantity: number, productionSchedulePath: ProductionSchedulePath) => {
        return quantity + productionSchedulePath.getOrderLineQuantityInProductionScheduleItems(orderLineId);
      },
      0
    );
  }

  public getOrderLineHeightInMMInProductionSchedulePaths(orderLineId: number): number {
    return this.productionSchedulePaths.reduce(
      (heightInMM: number, productionSchedulePath: ProductionSchedulePath) => heightInMM + productionSchedulePath.getOrderLineHeightInMMInProductionScheduleItems(orderLineId),
      0
    );
  }

  public getProductionScheduleItemsForListDrawing(listDrawing: ListDrawing): ProductionScheduleItemInPathGroup[] {
    return reduce(
      this._productionSchedulePaths,
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionSchedulePath: ProductionSchedulePath) => {
        productionScheduleItems.push(...productionSchedulePath.getProductionScheduleItemsForListDrawing(listDrawing));

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionScheduleItemsForOrderLine(orderLineId: number): ProductionScheduleItemInPathGroup[] {
    return reduce(
      this._productionSchedulePaths,
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionSchedulePath: ProductionSchedulePath) => {
        productionScheduleItems.push(...productionSchedulePath.getProductionScheduleItemsForOrderLine(orderLineId));

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionSchedulePathForProductionScheduleItem(productionScheduleItem: ProductionScheduleItemInPathGroup): ProductionSchedulePath {
    return find(this._productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
      return productionSchedulePath.hasProductionScheduleItem(productionScheduleItem);
    });
  }

  public hasProductionSchedulePaths(): boolean {
    return !isEmpty(this._productionSchedulePaths);
  }

  public removePathLabelsAndNonProductionItemsFromProductionSchedulePaths(): void {
    each(this._productionSchedulePaths, (productionSchedulePath: ProductionSchedulePath) => {
      productionSchedulePath.removePathLabelsAndNonProductionItemsFromPathGroups();
    });
  }
}
