<section class="flex-column full-height">
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT' | bmsTranslate: {count: 1} | lowercase}" svgIcon="select-blue"></vdw-header>
  <article class="full-size dialog-content flex-column gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddTuftProduct()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #tuftProductsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfTuftProducts" [rowData]="listOfTuftProducts"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectTuftProduct()" [centered]="true" (chooseEvent)="selectTuftProduct()"></vdw-footer>
</section>
