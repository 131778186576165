import {OrderLineForSalesOrderStubFactory} from '@application/helper/sales-order/order-line-for-sales-order-stub-factory';
import {CustomerForSalesOrder} from '@domain/sales-order/customer-for-sales-order';
import {OrderLineForSalesOrder} from '@domain/sales-order/order-line-for-sales-order';
import {PaymentStatus} from '@domain/sales-order/payment-status';
import {SalesOrder} from '@domain/sales-order/sales-order';
import {SalesOrderStatus} from '@domain/sales-order/sales-order-status';
import {Priority} from '@vdw/angular-component-library';

export class SalesOrderStubFactory {
  public static getRandomSalesOrderWithId(id: number): SalesOrder {
    return this.getRandomSalesOrderWithIdAndOrderLines(id, [OrderLineForSalesOrderStubFactory.getRandomOrderLine()]);
  }

  public static getRandomSalesOrderWithOrderLines(orderLines: OrderLineForSalesOrder[]): SalesOrder {
    return this.getRandomSalesOrderWithIdAndOrderLines(1, orderLines);
  }

  public static getRandomSalesOrderWithIdAndOrderLines(id: number, orderLines: OrderLineForSalesOrder[]): SalesOrder {
    return new SalesOrder(id, 100 + id, new CustomerForSalesOrder(1, 'name'), new Date(), Priority.HIGH, PaymentStatus.OPEN, orderLines, SalesOrderStatus.DRAFT);
  }
}
