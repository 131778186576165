import {EventEmitter} from '@angular/core';
import {PlanningItemData} from './planning-item-data';

export class PlanningRowData {
  public dataTransfer: any;
  public clickable = false;
  public selected = new EventEmitter<MouseEvent>();

  private _items: PlanningItemData[] = [];

  public get items(): readonly PlanningItemData[] {
    return this._items;
  }

  public addItem(item: PlanningItemData): void {
    if (this._items.includes(item)) {
      return;
    }
    this._items.push(item);
  }

  public removeItem(item: PlanningItemData): void {
    const itemIndex = this._items.indexOf(item);
    if (itemIndex < 0) {
      return;
    }
    this._items.splice(itemIndex, 1);
  }
}
