export class PathLayoutTemplatePath {
  private _widthInMM: number;
  private _position: number;

  public constructor(widthInMM: number, position: number) {
    this._widthInMM = widthInMM;
    this._position = position;
  }

  public get widthInMM(): number {
    return this._widthInMM;
  }

  public set widthInMM(value: number) {
    this._widthInMM = value;
  }

  public get position(): number {
    return this._position;
  }

  public set position(value: number) {
    this._position = value;
  }

  public toJSON(): JSON {
    return {
      widthInMM: this._widthInMM,
      position: this._position
    } as any as JSON;
  }

  public static fromJSON(pathLayoutTemplatePathJSON: any): PathLayoutTemplatePath {
    return new PathLayoutTemplatePath(pathLayoutTemplatePathJSON.widthInMM, pathLayoutTemplatePathJSON.position);
  }
}
