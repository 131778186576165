import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {CanShowErrorForFormControlModule} from '../../custom-directives/can-show-error-for-context/can-show-error-for-form-control.module';
import {TranslationModule} from '../../translation/translation.module';
import {DatepickerFormInputComponent} from './datepicker-form-input.component';
import {MatButtonModule} from '@angular/material/button';
import {CommonFormsModule} from '../../forms/common-forms.module';
import {MomentDatePipeModule} from '../../custom-pipes/moment-date-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslationModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    CanShowErrorForFormControlModule,
    MatFormFieldModule,
    MatNativeDateModule,
    TranslationModule,
    CommonFormsModule,
    MomentDatePipeModule
  ],
  exports: [DatepickerFormInputComponent],
  declarations: [DatepickerFormInputComponent]
})
export class DatepickerFormInputModule {}
