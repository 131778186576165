import {ProductionOrderYield} from '@domain/planning/production-order-yield';
import {PlanningTuftProduct} from '@domain/planning/tufting/planning-tuft-product';
import {ProductionOrderTuftingForPlanningOrder} from '@domain/planning/tufting/production-order-tufting-for-planning-order';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';

export class ProductionOrderTuftingForPlanningOrderStubFactory {
  public static getRandomProductionOrderTuft(): ProductionOrderTuftingForPlanningOrder {
    return this.getRandomProductionOrderTuftWithIdAndStatus(0, ProductionScheduleStatus.ARRIVED);
  }

  public static getRandomProductionOrderTuftWithIdAndStatus(id: number, status: ProductionScheduleStatus): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(id, '', status, new PlanningTuftProduct(1, ''), null, null);
  }

  public static getRandomProductionOrderTuftWithIdAndTuftProductId(id: number, tuftProductId: number): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(id, '', ProductionScheduleStatus.TO_PLAN, new PlanningTuftProduct(tuftProductId, ''), null, null);
  }

  public static getRandomProductionOrderTuftWithIdAndDueDate(id: number, dueDate: Date): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(id, '', ProductionScheduleStatus.TO_PLAN, new PlanningTuftProduct(1, ''), null, dueDate);
  }

  public static getRandomProductionOrderTuftWithYield(productYield: ProductionOrderYield): ProductionOrderTuftingForPlanningOrder {
    return new ProductionOrderTuftingForPlanningOrder(0, '', ProductionScheduleStatus.EXECUTING, new PlanningTuftProduct(1, ''), productYield, null);
  }
}
