<mat-card fxLayout="column" fxFlex>
  <vdw-header [title]="'BACKGROUND_JOBS.MONITOR_JOBS' | bmsTranslate" svgIcon="process-blue"> </vdw-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <article fxFlex fxLayout="column" class="dialog-content">
      <app-background-jobs-grid
        [backgroundJobs]="listOfBackgroundJobs"
        (retryBackgroundJob)="retryBackgroundJob($event)"
        (cancelBackgroundJob)="cancelBackgroundJob($event)"
      ></app-background-jobs-grid>
    </article>
  </mat-card-content>
</mat-card>
