import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {ProductionSchedules, PRODUCTION_SCHEDULES} from '@infrastructure/http/production-schedule/production-schedules';
import {convertCommercialUnit, TranslateService, Unit} from '@vdw/angular-component-library';
import {cloneDeep, find, sumBy, toNumber} from 'lodash-es';
import {ConfirmProductionScheduleBaseComponent} from '../confirm-production-schedule-base.component';

@Component({
  templateUrl: './confirm-production-schedule-desktop.component.html',
  styleUrls: ['./confirm-production-schedule-desktop.component.scss']
})
export class ConfirmProductionScheduleDesktopComponent extends ConfirmProductionScheduleBaseComponent implements OnInit {
  public commercialUnit: Unit;
  public confirmationForm: UntypedFormGroup;
  public listOfProductionOrdersCustomSettings: PropertyValue[];
  public accessAllPermission: Permission = Permission.ACCESS_ALL;
  private readonly dialogRef: MatDialogRef<ConfirmProductionScheduleDesktopComponent>;

  public constructor(
    dialogRef: MatDialogRef<ConfirmProductionScheduleDesktopComponent>,
    formBuilder: UntypedFormBuilder,
    @Inject(PRODUCTION_SCHEDULES) protected readonly productionSchedules: ProductionSchedules,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(AUTHENTICATION) protected authentication: Authentication,
    private translate: TranslateService
  ) {
    super(formBuilder, productionSchedules, authentication);

    this.dialogRef = dialogRef;
    this.productionSchedule = cloneDeep(data.productionSchedule);
    this.commercialUnit = data.commercialUnit as Unit;
    this.listOfProductionOrdersCustomSettings = data.listOfProductionOrdersCustomSettings;
    this.maximumLossOfProductionScheduleInPercent = find(this.listOfProductionOrdersCustomSettings, ['propertyName', 'maximumLossInPercent']).propertyValue;
  }

  public decrementNumberOfRepeats(): void {
    if (this.productionSchedule.numberOfRepeats > 0) {
      this.productionSchedule.numberOfRepeats--;
    }
  }

  public incrementNumberOfRepeats(): void {
    this.productionSchedule.numberOfRepeats++;
  }

  public amountChanged(numberOfRepeats: number): void {
    this.productionSchedule.numberOfRepeats = numberOfRepeats;
  }

  public getNumberOfRepeatsForecast(): string {
    const totalLengthInMM = sumBy(this.productionSchedule.productionSchedulePathsOfColoredYarnSets, (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      return sumBy(productionSchedulePathsOfColoredYarnSet.productionSchedulePaths, 'totalLengthInMM');
    });

    let hint;

    if (toNumber(this.productionSchedule.numberOfRepeats) === 0) {
      hint = this.translate.instant('GENERAL.HINTS.UNKNOWN');
    } else {
      hint = `${this.translate.instant('GENERAL.DIMENSIONS.TOTAL_LENGTH')}: ${this.productionSchedule.numberOfRepeats} x ${convertCommercialUnit({
        from: {
          unit: Unit.MILLIMETER,
          value: totalLengthInMM
        },
        to: Unit.METER
      })}${Unit.METER}`;
    }

    return hint;
  }

  protected onProductionScheduleCompleted(): void {
    this.dialogRef.close(this.productionSchedule);
  }
}
