import {Command} from '@application/command/command';
import {MobileNavigationBarItem, NavigationBarMobileItemProperties} from './mobile-navigation-bar-item';

export interface MobileNavigationBarActionItemProperties<T, R> extends NavigationBarMobileItemProperties {
  command: Command<T, R>;
}

export class MobileNavigationBarActionItem<T, R> extends MobileNavigationBarItem {
  private _command: Command<T, R>;
  public active = false;

  public constructor(properties: MobileNavigationBarActionItemProperties<T, R>) {
    const {command, ...navigationBarItemProperties} = properties;

    super(navigationBarItemProperties);
    this._command = command;
  }

  public executeCommand(commandArgs: T): R {
    return this._command.execute(commandArgs);
  }
}
