import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslationModule} from '../../translation/translation.module';
import {ProgressButtonModule} from '../progress-button/progress-button.module';
import {FooterComponent} from './footer.component';
import {SplitButtonModule} from '../split-button/split-button.module';

@NgModule({
  imports: [CommonModule, MatIconModule, MatToolbarModule, MatButtonModule, MatMenuModule, TranslationModule, MatDialogModule, ProgressButtonModule, SplitButtonModule],
  exports: [FooterComponent],
  declarations: [FooterComponent]
})
export class FooterModule {}
