import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class ToolRoute extends RouteBranch {
  private _addTool: RouteLeaf;
  private _editTool: RouteLeaf;
  private _duplicateTool: RouteLeaf;

  public get addTool(): RouteLeaf {
    return this._addTool;
  }

  public set addTool(value: RouteLeaf) {
    this.replace(this._addTool, value);
    this._addTool = value;
  }

  public get editTool(): RouteLeaf {
    return this._editTool;
  }

  public set editTool(value: RouteLeaf) {
    this.replace(this._editTool, value);
    this._editTool = value;
  }

  public get duplicateTool(): RouteLeaf {
    return this._duplicateTool;
  }

  public set duplicateTool(value: RouteLeaf) {
    this.replace(this._duplicateTool, value);
    this._duplicateTool = value;
  }
}
