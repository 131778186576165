import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class FinishingMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.FINISHING, name, orderId);
  }

  public static fromJSON(finishingMachineJSON: any): FinishingMachine {
    return new FinishingMachine(finishingMachineJSON.id, finishingMachineJSON.name, finishingMachineJSON.orderId);
  }
}
