import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ObjectUtils} from '@vdw/angular-component-library';
import {PlasticProduct} from './plastic-product';
import {Tool} from './tool';

export class ProductionOrderPlastic {
  private _id: number;
  private _name: string;
  private _plasticProduct: PlasticProduct;
  private _status: ProductionScheduleStatus;
  private _tool?: Tool;
  private _plannedQuantity: number;

  private _dueDate?: Date;

  public constructor(id: number, name: string, plasticProduct: PlasticProduct, status: ProductionScheduleStatus, plannedQuantity: number, tool?: Tool, dueDate?: Date) {
    this._id = id;
    this._name = name;
    this._plasticProduct = plasticProduct;
    this._status = status;
    this._tool = tool;
    this._plannedQuantity = plannedQuantity;
    this._dueDate = dueDate;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get plasticProduct(): PlasticProduct {
    return this._plasticProduct;
  }

  public get tool(): Tool | null {
    return this._tool;
  }

  public get plannedQuantity(): number {
    return this._plannedQuantity;
  }

  public get dueDate(): Date | null {
    return this._dueDate;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public static fromJSON(json: any): ProductionOrderPlastic {
    return new ProductionOrderPlastic(
      json.id,
      json.name,
      ObjectUtils.parseNullable(json.plasticProduct, PlasticProduct.fromJSON),
      json.status,
      json.plannedQuantity,
      ObjectUtils.parseNullable(json.tool, Tool.fromJSON),
      ObjectUtils.parseNullable(json.dueDate, (dueDate: any) => new Date(dueDate))
    );
  }
}
