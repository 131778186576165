<div *ngIf="showOverflowChip" class="filter chip overflow" [style.left.px]="filterOverflowPositionLeft" fxLayoutAlign="start center" (click)="onFilterOverflowClicked()">
  <span [matTooltip]="getFilterOverflowTooltip()">...</span>
</div>

<div #filterContainer class="filter-container flex-row">
  <div *ngFor="let searchFilter of searchFilters; let index = index" class="set-opacity">
    <div *ngIf="canShowFilter(searchFilter, index)" class="filter chip flex-row">
      <span appDataCy="filter-text" class="full-width" [matTooltipDisabled]="!canShowFilterTooltip(searchFilter)" [matTooltip]="getFilterTooltip(searchFilter)">
        {{ getFilterDisplayText(searchFilter) }}
      </span>
      <button mat-icon-button (click)="clearValue(searchFilter)">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>
</div>
