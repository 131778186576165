import {NgModule} from '@angular/core';
import {TranslationModule} from '../../translation/translation.module';
import {SelectionListModule} from '../selection-list/selection-list.module';
import {SelectionListContainerComponent} from './selection-list-container.component';

@NgModule({
  imports: [SelectionListModule, TranslationModule],
  declarations: [SelectionListContainerComponent],
  exports: [SelectionListContainerComponent]
})
export class SelectionListContainerModule {}
