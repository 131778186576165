import {PatternVisualisation} from './pattern-visualisation';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';

export class SvgManchonVisualisation extends PatternVisualisation {
  private _svg: string;

  public constructor(svg: string) {
    super(PatternVisualisationType.MANCHON);

    this._svg = svg;
  }

  public get svg(): string {
    return this._svg;
  }

  public static fromJSON(manchonVisualisationJSON: any): SvgManchonVisualisation {
    return new SvgManchonVisualisation(manchonVisualisationJSON.pattern);
  }

  public toJSON(): JSON {
    return {
      pattern: this._svg,
      patternType: PatternVisualisationType[this.type]
    } as any as JSON;
  }
}
