import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {dateComparator} from '@application/helper/date-comparator';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {StringUtils} from '@application/helper/string-utils';
import {PageUrls} from '@domain/textile-data/page-urls';
import {HttpTuftProductionOrdersService} from '@infrastructure/http/tuft-production-order/http-tuft-production-orders.service';
import {ProductionScheduleOverviewStatusComponent} from '@presentation/pages/texfab/production-schedule/overview/status/production-schedule-overview-status.component';
import {TextileDataOverviewComponent} from '@presentation/pages/textile-data/textile-data-overview/textile-data-overview.component';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {AgGridUtils, BaseComponent, ColDefBuilderFactoryService, LinkIconRendererComponent, TranslateService, Unit} from '@vdw/angular-component-library';
import {ColDef, GridApi, ICellRendererParams, ITooltipParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {isNil} from 'lodash-es';
import {takeUntil} from 'rxjs';
import {OverviewListTuftProductionOrder} from './overview-list-tuft-production-order';

@Component({
  templateUrl: './tuft-production-order-overview-page.component.html'
})
export class TuftProductionOrderOverviewPageComponent extends BaseComponent implements OnInit {
  @ViewChild('textileDataOverview') public textileDataOverview: TextileDataOverviewComponent;

  public listOfTuftProductionOrders: OverviewListTuftProductionOrder[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.TUFT_PRODUCTION_ORDER_OVERVIEW;

  private pageUrls: PageUrls;

  public constructor(
    public readonly tuftProductionOrders: HttpTuftProductionOrdersService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.pageUrls = LastModifiedCardUtils.getPageUrls('tuftProductionOrder');

    this.tuftProductionOrders
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfTuftProductionOrders: OverviewListTuftProductionOrder[]) => {
        this.listOfTuftProductionOrders = listOfTuftProductionOrders;
      });
  }

  public getColumnDefsForListOfTuftProductionOrders(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('status')
        .withField('status')
        .withHeaderName('GENERAL.STATUS')
        .withCellRenderer(ProductionScheduleOverviewStatusComponent)
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`PRODUCTION_ORDER.STATUS.${params.value}`))
        .withCellClass('center')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .withLockVisible(true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColId('tuftProduct').withField('tuftProduct.name', true).withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT').build(),
      this.colDefBuilderFactoryService.getBuilder().withColId('machineName').withField('machine.name', true).withHeaderName('MACHINE.MACHINE').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('plannedLength')
        .withField('plannedQuantityInMeters')
        .withHeaderName('TUFT_PRODUCTION_ORDER.PLANNED_LENGTH')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.METER))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.METER))
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('dueDate')
        .withField('dueDate')
        .withHeaderName('GENERAL.DATETIME.DUE_DATE')
        .withCellRenderer((params: ICellRendererParams) => (!isNil(params.getValue()) ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()) : ''))
        .withTooltipValueGetter((params: ITooltipParams) => (!isNil(params.value) ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value) : ''))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('dateCreated')
        .withField('dateCreated')
        .withHeaderName('GENERAL.DATETIME.DATE_CREATED')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('actualStartDate')
        .withHeaderName('GENERAL.DATETIME.START_DATE')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('actualEndDate')
        .withHeaderName('GENERAL.DATETIME.END_DATE')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build()
    ];
  }

  public exportGridForTuftProductionOrders(gridApi: GridApi): void {
    gridApi.exportDataAsCsv({
      processCellCallback: (params: any) => {
        if (params.column.colId === 'tuftProduct' || params.column.colId === 'machineName') {
          return params.value?.name;
        } else {
          return params.value;
        }
      }
    });
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(this.pageUrls.settings);
  }

  private buildAgGridCellTextWithUnit(value: number | string, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService);
  }

  private buildAgGridCellTooltipWithUnit(value: number | string, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTooltipWithUnit(value, defaultUnit, this.l10nIntlService);
  }
}
