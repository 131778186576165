import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslationModule} from '../../translation/translation.module';
import {SettingsIconButtonComponent} from '../settings-icon-button/settings-icon-button.component';
import {SplitButtonModule} from '../split-button/split-button.module';
import {TabsAndActionsHeaderComponent} from '../tabs-and-actions-header/tabs-and-actions-header.component';
import {EditorHeaderComponent} from './editor-header.component';

@NgModule({
  declarations: [EditorHeaderComponent],
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, TranslationModule, SplitButtonModule, TabsAndActionsHeaderComponent, SettingsIconButtonComponent],
  exports: [EditorHeaderComponent]
})
export class EditorHeaderModule {}
