export class UserInfo {
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _email: string;

  public constructor(firstName: string, lastName: string, email: string) {
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get email(): string {
    return this._email;
  }

  public static fromJSON(userInfoJSON: any): UserInfo {
    return new UserInfo(userInfoJSON.firstName, userInfoJSON.lastName, userInfoJSON.email);
  }
}
