import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MultiLineDragComponent} from './multi-line-drag.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MultiLineDragComponent],
  exports: [MultiLineDragComponent]
})
export class MultiLineDragModule {}
