export class CommercialMachineQuality {
  private _code: string;
  private _name: string;

  public constructor(code: string, name: string) {
    this._code = code;
    this._name = name;
  }

  public get code(): string {
    return this._code;
  }

  public get name(): string {
    return this._name;
  }

  public static fromJSON(commercialMachineQualityJSON: any): CommercialMachineQuality {
    return new CommercialMachineQuality(commercialMachineQualityJSON.code, commercialMachineQualityJSON.name);
  }

  public toJSON(): JSON {
    return {
      code: this._code,
      name: this._name
    } as any as JSON;
  }
}
