import {Component, Injector, OnInit, Optional, Type} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {CrudObjectSelectionDialogComponent} from '../crud-overview-data/crud-object-selection-dialog/crud-object-selection-dialog.component';
import {CrudOverviewDataPageComponent} from '../crud-overview-data/crud-overview-data-page/crud-overview-data-page.component';
import {ContentSwitcherDialogService} from './content-switcher-dialog.service';

@Component({
  templateUrl: './content-switcher-dialog.component.html'
})
export class ContentSwitcherDialogComponent extends BaseComponent implements OnInit {
  protected injector: Injector;

  public get component(): Type<any> {
    if (this.contentSwitcher.activeRoute.component === CrudOverviewDataPageComponent) {
      return AssertionUtils.isNullOrUndefined(this.dialog) ? CrudOverviewDataPageComponent : CrudObjectSelectionDialogComponent;
    }

    return this.contentSwitcher.activeRoute.component;
  }

  public get componentInputs(): Record<string, unknown> {
    return this.contentSwitcher.getInputs() ?? {};
  }

  public constructor(
    private readonly contentSwitcher: ContentSwitcherDialogService,
    @Optional() private readonly dialog: MatDialogRef<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.contentSwitcher.dialog = this.dialog;

    this.dialog
      ?.beforeClosed()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.contentSwitcher.reset());

    this.injector = Injector.create({providers: [{provide: MatDialogRef, useValue: this.dialog}], parent: this.contentSwitcher.parentInjector});
  }
}
