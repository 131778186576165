import {NgControl, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {combineLatest, startWith, takeUntil} from 'rxjs';
import {ControlValueAccessorHelper} from './control-value-accessor-helper';

export class MatFormFieldControlHelper<T> extends ControlValueAccessorHelper<T> implements MatFormFieldControl<T> {
  public errorState = false;
  public id: string;

  public get placeholder(): string {
    return this._placeholder;
  }

  public set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  public ngControl: NgControl;
  public focused: boolean;
  public empty: boolean;
  public shouldLabelFloat = true;
  public required: boolean;

  public controlType?: string;
  public autofilled?: boolean;
  public userAriaDescribedBy = '';
  private _placeholder: string;

  public constructor(ngControl: NgControl) {
    super();
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public setDescribedByIds(ids: string[]): void {
    if (this.formControl) {
      this.formControl.setDescribedByIds(ids);
    } else {
      this.userAriaDescribedBy = ids.join(' ');
    }
  }

  public onContainerClick(event: MouseEvent): void {
    if (this.formControl) {
      this.formControl.onContainerClick(event);
    }
  }

  public init(): void {
    if (this.formControl) {
      this.formControl.stateChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
        setTimeout(() => {
          this.errorState = this.ngControl.invalid && this.ngControl.touched;
          this.stateChanges.next();
        }, 0);
      });
    } else if (this.ngControl) {
      this.required = this.ngControl?.control?.hasValidator(Validators.required);
      this.disabled = this.ngControl.disabled;
      combineLatest([this.ngControl.statusChanges?.pipe(startWith(null)), this.stateChanges?.pipe(startWith(null))])
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => (this.errorState = this.ngControl.invalid && this.ngControl.touched));
    }
  }

  public destroy(): void {
    this.stateChanges.complete();
  }

  public check(): void {
    if (this.ngControl && this.ngControl.invalid && this.ngControl.touched) {
      this.stateChanges.next();
    }
  }
}
