import {Injectable} from '@angular/core';
import {ProductionOrderYield} from '@domain/planning/production-order-yield';
import {ProductionScheduleCombinedStatus} from '@domain/production-schedule/production-schedule-combined-states.enum';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {AgGridUtils, AssertionUtils, ColDefBuilderFactoryService, convertUnit, EnumUtils, LinkIconRendererComponent, TranslateService, Unit} from '@vdw/angular-component-library';
import {CellClassParams, ColDef, HeaderValueGetterParams, ICellRendererParams, ITooltipParams, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {Observable, of} from 'rxjs';
import {ColumnUnitsId} from './column-units-id-enum';
import {ColumnUnitsMap} from './column-units-map';
import {ProductionScheduleOverviewCreelPreviewComponent} from './creel-preview/production-schedule-overview-creel-preview.component';
import {ProductionScheduleOverviewMachineComponent} from './machine/production-schedule-overview-machine.component';
import {OverviewListProductionSchedule} from './overview-list-production-schedule';
import {ProductionScheduleOverviewStatusComponent} from './status/production-schedule-overview-status.component';

@Injectable()
export class ProductionScheduleOverviewService {
  private readonly booleanTrueTranslation = 'GENERAL.CONDITION.YES';
  private readonly booleanFalseTranslation = 'GENERAL.CONDITION.NO';
  private readonly STATUS_COL_ID = 'status';
  private readonly MACHINE_COL_ID = 'machineName';

  public readonly SLICE_SIZE = 10;

  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(
    editUrl: string,
    getPossibleColumnsValues: (columnIdentifier: string) => Observable<string[] | number[]>,
    getColumnUnits: () => {[colId: string]: {dataFromUnit: Unit; dataToUnit: Unit; getDisplayUnit: () => Unit}}
  ): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField(this.STATUS_COL_ID)
        .withHeaderName('GENERAL.STATUS')
        .withCellRenderer(ProductionScheduleOverviewStatusComponent)
        .withTooltipValueGetter((params: ITooltipParams) => {
          const data = params.data as OverviewListProductionSchedule;
          if (data && data.state) {
            const globalStatus = this.translate.instant(`PRODUCTION_ORDER.GLOBAL_STATUS.${data.state.globalStatus}` || params.value);
            const statusColor = this.translate.instant(`PRODUCTION_ORDER.STATUS_COLOR_DESCRIPTION.${data.state.statusColorDescription}` || params.value);
            const subStatus = this.translate.instant(`PRODUCTION_ORDER.STATUS.${data.state.subStatus}` || params.value);
            return `${globalStatus}-${statusColor}-${subStatus}`;
          }
          return params.value;
        })
        .withPinned(true)
        .withMobile()
        .withTextMultiFilter(of(EnumUtils.getEnumValues(ProductionScheduleCombinedStatus) as string[] | number[]))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withCellClass((cellClassParams: CellClassParams): string[] => this.getNameCellClass(cellClassParams))
        .withPinned(true)
        .withLockVisible()
        .withTextMultiFilter()
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: editUrl
          };
        })
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('machine')
        .withColId(this.MACHINE_COL_ID)
        .withHeaderName('MACHINE.MACHINE')
        .withTooltipField('machine.name')
        .withCellRenderer(ProductionScheduleOverviewMachineComponent)
        .withTextMultiFilter(getPossibleColumnsValues(this.MACHINE_COL_ID))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('numberOfPaths', true)
        .withHeaderName('PRODUCTION_ORDER.PATH', 2, true)
        .withNumericMultiFilter(getPossibleColumnsValues('numberOfPaths'))
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.PATH_WIDTHS_IN_MM_COL_ID)
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.OPTIONAL_SETTINGS.PATH_WIDTHS', 1, false, 'GENERAL.UNIT.CENTIMETER')
        .withValueGetter(
          (params: ValueGetterParams) => (params.data.pathWidthsInMM ? AgGridUtils.buildAgGridCellListWithoutUnit(params.data.pathWidthsInMM, Unit.MILLIMETER, Unit.CENTIMETER) : ''),
          true
        )
        .withNumericMultiFilter(
          getPossibleColumnsValues(ColumnUnitsId.PATH_WIDTHS_IN_MM_COL_ID),
          this.getConversionRate(ColumnUnitsId.PATH_WIDTHS_IN_MM_COL_ID, getColumnUnits()),
          this.l10nIntlService
        )
        .withRightAlignment()
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('pathWidthsInDents')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.OPTIONAL_SETTINGS.PATH_WIDTHS', 1, false, 'GENERAL.UNIT.DENTS')
        .withTooltipValueGetter((params: ITooltipParams) => (params.value ? params.value.toString().split(',').join(', ') : ''))
        .withNumericMultiFilter(getPossibleColumnsValues('pathWidthsInDents'))
        .withRightAlignment()
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('machineQuality.technicalName', true)
        .withColId('technicalName')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY')
        .withTextMultiFilter(getPossibleColumnsValues('technicalName'))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('creel.name', true)
        .withColId('creelName')
        .withHeaderName('TEXTILE_DATA.CREEL.CREEL')
        .withTextMultiFilter(getPossibleColumnsValues('creelName'))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET')
        .withValueGetter((params: ValueGetterParams) => {
          const names = params.data.coloredYarnSets.map((coloredYarnSets: OverviewListColoredYarnSetWithStartDent) => coloredYarnSets.coloredYarnSet.name);
          return names.length > 0 ? names.join(', ') : '';
        })
        .withCellRenderer(ProductionScheduleOverviewCreelPreviewComponent, undefined, true)
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('yield')
        .withHeaderName('GENERAL.YIELD', 1, false, 'GENERAL.UNIT.PICKS')
        .withValueGetter((params: ValueGetterParams) => this.getYieldValue(params.data.yield), true)
        .withTextMultiFilter(getPossibleColumnsValues('yield'))
        .withRightAlignment()
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.WEFT_DENSITY_COL_ID)
        .withHeaderValueGetter((params: HeaderValueGetterParams) =>
          this.getHeaderNameAndSetTooltip('TEXTILE_DATA.MACHINE_QUALITY.WEFTDENSITY', getColumnUnits()[ColumnUnitsId.WEFT_DENSITY_COL_ID].getDisplayUnit(), params)
        )
        .withValueGetter(
          (params: ValueGetterParams) =>
            AgGridUtils.buildAgGridCellTextWithoutUnit(
              params.data.machineQuality.weftDensity,
              Unit.WEFTS_PER_MILLIMETER,
              getColumnUnits()[ColumnUnitsId.WEFT_DENSITY_COL_ID].getDisplayUnit(),
              this.l10nIntlService
            ),
          true
        )
        .withNumericMultiFilter(getPossibleColumnsValues(ColumnUnitsId.WEFT_DENSITY_COL_ID), () => this.getConversionRate(ColumnUnitsId.WEFT_DENSITY_COL_ID, getColumnUnits()), this.l10nIntlService)
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.PICK_DENSITY_COL_ID)
        .withHeaderValueGetter((params: HeaderValueGetterParams) =>
          this.getHeaderNameAndSetTooltip('TEXTILE_DATA.MACHINE_QUALITY.PICKDENSITY', getColumnUnits()[ColumnUnitsId.PICK_DENSITY_COL_ID].getDisplayUnit(), params)
        )
        .withValueGetter(
          (params: ValueGetterParams) =>
            AgGridUtils.buildAgGridCellTextWithoutUnit(
              params.data.machineQuality.pickDensity,
              Unit.PICKS_PER_MILLIMETER,
              getColumnUnits()[ColumnUnitsId.PICK_DENSITY_COL_ID].getDisplayUnit(),
              this.l10nIntlService
            ),
          true
        )
        .withNumericMultiFilter(getPossibleColumnsValues('pickDensityInPicksPerMeter'), () => this.getConversionRate(ColumnUnitsId.PICK_DENSITY_COL_ID, getColumnUnits()), this.l10nIntlService)
        .withRightAlignment()
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.REED_DENSITY_COL_ID)
        .withHeaderValueGetter((params: HeaderValueGetterParams) =>
          this.getHeaderNameAndSetTooltip('TEXTILE_DATA.MACHINE_QUALITY.REEDDENSITY', getColumnUnits()[ColumnUnitsId.REED_DENSITY_COL_ID].getDisplayUnit(), params)
        )
        .withValueGetter(
          (params: ValueGetterParams) =>
            AgGridUtils.buildAgGridCellTextWithoutUnit(
              params.data.machineQuality.reedDensity,
              Unit.DENTS_PER_MILLIMETER,
              getColumnUnits()[ColumnUnitsId.REED_DENSITY_COL_ID].getDisplayUnit(),
              this.l10nIntlService
            ),
          true
        )
        .withNumericMultiFilter(getPossibleColumnsValues('reedDensityInDentsPerMeter'), () => this.getConversionRate(ColumnUnitsId.REED_DENSITY_COL_ID, getColumnUnits()), this.l10nIntlService)
        .withRightAlignment()
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.PRACTICAL_PICK_DENSITY_COL_ID)
        .withHeaderValueGetter((params: HeaderValueGetterParams) =>
          this.getHeaderNameAndSetTooltip('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_PICK_DENSITY', getColumnUnits()[ColumnUnitsId.PICK_DENSITY_COL_ID].getDisplayUnit(), params)
        )
        .withValueGetter(
          (params: ValueGetterParams) =>
            AgGridUtils.buildAgGridCellTextWithoutUnit(
              params.data.practicalPickDensityInPicksPerMM,
              Unit.PICKS_PER_MILLIMETER,
              getColumnUnits()[ColumnUnitsId.PICK_DENSITY_COL_ID].getDisplayUnit(),
              this.l10nIntlService
            ),
          true
        )
        .withNumericMultiFilter(
          getPossibleColumnsValues(ColumnUnitsId.PRACTICAL_PICK_DENSITY_COL_ID),
          () => this.getConversionRate(ColumnUnitsId.PRACTICAL_PICK_DENSITY_COL_ID, getColumnUnits()),
          this.l10nIntlService
        )
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.LENGTH_IN_MM_COL_ID)
        .withHeaderName('GENERAL.DIMENSIONS.LENGTH', 1, false, 'GENERAL.UNIT.METER')
        .withValueGetter(
          (params: ValueGetterParams) => (params.data.lengthInMM ? AgGridUtils.buildAgGridCellTextWithoutUnit(params.data.lengthInMM, Unit.MILLIMETER, Unit.METER, this.l10nIntlService) : ''),
          true
        )
        .withNumericMultiFilter(getPossibleColumnsValues(ColumnUnitsId.LENGTH_IN_MM_COL_ID), this.getConversionRate(ColumnUnitsId.LENGTH_IN_MM_COL_ID, getColumnUnits()), this.l10nIntlService)
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('lengthInPicks')
        .withHeaderName('GENERAL.DIMENSIONS.LENGTH', 1, false, 'GENERAL.UNIT.PICKS')
        .withValueGetter((params: ValueGetterParams) => (params.data.lengthInPicks ? params.data.lengthInPicks : ''), true)
        .withNumericMultiFilter(getPossibleColumnsValues('lengthInPicks'))
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId(ColumnUnitsId.WIDTH_IN_MM_COL_ID)
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH', 1, false, 'GENERAL.UNIT.CENTIMETER')
        .withValueGetter(
          (params: ValueGetterParams) => (params.data.widthInMM ? AgGridUtils.buildAgGridCellTextWithoutUnit(params.data.widthInMM, Unit.MILLIMETER, Unit.CENTIMETER, this.l10nIntlService) : ''),
          true
        )
        .withNumericMultiFilter(getPossibleColumnsValues(ColumnUnitsId.WIDTH_IN_MM_COL_ID), this.getConversionRate(ColumnUnitsId.WIDTH_IN_MM_COL_ID, getColumnUnits()), this.l10nIntlService)
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('widthInDents')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH', 1, false, 'GENERAL.UNIT.DENTS')
        .withValueGetter((params: ValueGetterParams) => (params.data.widthInDents ? params.data.widthInDents : ''), true)
        .withNumericMultiFilter(getPossibleColumnsValues('widthInDents'))
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('buggy1', true)
        .withHeaderName('PLANNING.BUGGY.BUGGY_1')
        .withTextMultiFilter(getPossibleColumnsValues('buggy1'))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('buggy2', true)
        .withHeaderName('PLANNING.BUGGY.BUGGY_2')
        .withTextMultiFilter(getPossibleColumnsValues('buggy2'))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('dateModified')
        .withHeaderName('GENERAL.DATETIME.DATE')
        .withValueGetter((params: ValueGetterParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.data.dateModified), true)
        .withDateMultiFilter(getPossibleColumnsValues('dateModified'))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('dueDate')
        .withHeaderName('GENERAL.DATETIME.DUE_DATE')
        .withValueGetter((params: ValueGetterParams) => (params.data.dueDate ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.data.dueDate) : ''), true)
        .withDateMultiFilter(getPossibleColumnsValues('dueDate'))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('comment', true).withHeaderName('GENERAL.COMMENT').withTextMultiFilter(getPossibleColumnsValues('comment')).withHide().build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('missingDesigns')
        .withHeaderName('PRODUCTION_ORDER.MISSING_DESIGN')
        .withValueGetter((params: ValueGetterParams) => (params.data.missingDesigns ? this.translate.instant(this.booleanTrueTranslation) : this.translate.instant(this.booleanFalseTranslation)), true)
        .withBooleanFilter(this.translate.instant(this.booleanFalseTranslation), this.translate.instant(this.booleanTrueTranslation))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('isSample')
        .withHeaderName('PRODUCTION_ORDER.SAMPLE')
        .withValueGetter((params: ValueGetterParams) => (params.data.isSample ? this.translate.instant(this.booleanTrueTranslation) : this.translate.instant(this.booleanFalseTranslation)), true)
        .withBooleanFilter(this.translate.instant(this.booleanFalseTranslation), this.translate.instant(this.booleanTrueTranslation))
        .withHide()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('wovenStartDate')
        .withHeaderName('GENERAL.DATETIME.START_DATE')
        .withValueGetter((params: ValueGetterParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.data.wovenStartDate), true)
        .withDateMultiFilter(getPossibleColumnsValues('wovenStartDate'))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('wovenEndDate')
        .withHeaderName('GENERAL.DATETIME.END_DATE')
        .withValueGetter((params: ValueGetterParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.data.wovenEndDate), true)
        .withDateMultiFilter(getPossibleColumnsValues('wovenEndDate'))
        .build()
    ];
  }

  public getDefaultColumnUnitsMap(): ColumnUnitsMap {
    return {
      [ColumnUnitsId.PATH_WIDTHS_IN_MM_COL_ID]: {dataFromUnit: Unit.MILLIMETER, dataToUnit: Unit.MILLIMETER, getDisplayUnit: (): Unit => Unit.CENTIMETER},
      [ColumnUnitsId.WEFT_DENSITY_COL_ID]: {dataFromUnit: Unit.WEFTS_PER_MILLIMETER, dataToUnit: Unit.WEFTS_PER_METER, getDisplayUnit: (): Unit => Unit.WEFTS_PER_METER},
      [ColumnUnitsId.PICK_DENSITY_COL_ID]: {dataFromUnit: Unit.PICKS_PER_MILLIMETER, dataToUnit: Unit.PICKS_PER_METER, getDisplayUnit: (): Unit => Unit.PICKS_PER_METER},
      [ColumnUnitsId.REED_DENSITY_COL_ID]: {dataFromUnit: Unit.DENTS_PER_MILLIMETER, dataToUnit: Unit.DENTS_PER_METER, getDisplayUnit: (): Unit => Unit.DENTS_PER_METER},
      [ColumnUnitsId.PRACTICAL_PICK_DENSITY_COL_ID]: {dataFromUnit: Unit.PICKS_PER_MILLIMETER, dataToUnit: Unit.PICKS_PER_METER, getDisplayUnit: (): Unit => Unit.PICKS_PER_METER},
      [ColumnUnitsId.LENGTH_IN_MM_COL_ID]: {dataFromUnit: Unit.MILLIMETER, dataToUnit: Unit.METER, getDisplayUnit: (): Unit => Unit.METER},
      [ColumnUnitsId.WIDTH_IN_MM_COL_ID]: {dataFromUnit: Unit.MILLIMETER, dataToUnit: Unit.MILLIMETER, getDisplayUnit: (): Unit => Unit.CENTIMETER}
    };
  }

  public updateColumnUnitsMap(columnUnitsMap: ColumnUnitsMap, weftDensityUnit: Unit, pickDensityUnit: Unit, reedDensityUnit: Unit): ColumnUnitsMap {
    columnUnitsMap[ColumnUnitsId.WEFT_DENSITY_COL_ID].getDisplayUnit = (): Unit => weftDensityUnit;
    columnUnitsMap[ColumnUnitsId.PICK_DENSITY_COL_ID].getDisplayUnit = (): Unit => pickDensityUnit;
    columnUnitsMap[ColumnUnitsId.PRACTICAL_PICK_DENSITY_COL_ID].getDisplayUnit = (): Unit => pickDensityUnit;
    columnUnitsMap[ColumnUnitsId.REED_DENSITY_COL_ID].getDisplayUnit = (): Unit => reedDensityUnit;

    return columnUnitsMap;
  }

  private getNameCellClass(cellClassParams: CellClassParams): string[] {
    const result = [];

    const productionSchedule: OverviewListProductionSchedule = cellClassParams.data;
    if (AssertionUtils.isEmpty(productionSchedule.name)) {
      result.push('disabled');
    }
    return result;
  }

  private getConversionRate(key: string, columnUnits: {[colId: string]: {dataFromUnit: Unit; dataToUnit: Unit; getDisplayUnit: () => Unit}}): number {
    return convertUnit({
      from: {
        value: 1,
        unit: columnUnits[key].dataFromUnit
      },
      to: columnUnits[key].getDisplayUnit()
    });
  }

  private getYieldValue(productionOrderYield: ProductionOrderYield): string {
    return !AssertionUtils.isNullOrUndefined(productionOrderYield) ? `${productionOrderYield.current}/${productionOrderYield.planned}` : '';
  }

  private getHeaderNameAndSetTooltip(headerName: string, unit: Unit, params: HeaderValueGetterParams): string {
    const headerNameWithUnit = `${this.translate.instant(headerName)} (${this.translate.instant(`GENERAL.UNIT.${Unit.getKeyFromValue(unit)}`)})`;
    params.colDef.headerTooltip = headerNameWithUnit;
    return headerNameWithUnit;
  }
}
