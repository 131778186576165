import {Component} from '@angular/core';
import {isNil} from 'lodash-es';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {animateToast} from './toast.animation';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [animateToast()],
  preserveWhitespaces: false
})
export class ToastComponent extends Toast {
  public titleIcon: string;
  public statusBarClass = '';
  public buttonText = '';
  public action: () => void;

  public constructor(
    toastrService: ToastrService,
    public readonly toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  public dismiss(): void {
    this.remove();
  }

  public canShowActionButton(): boolean {
    return !isNil(this.action);
  }

  public triggerAction(): void {
    this.toastPackage.triggerAction('confirmed');
  }
}
