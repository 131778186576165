import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {IdNameUnitOfMeasurement} from '@domain/texfab/plastic-production-order/id-name-unit-of-measurement';
import {PlasticProductionOrder} from '@domain/texfab/plastic-production-order/plastic-production-order';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {UnitOfMeasurement} from '@domain/textile-data/plastic-product/unit-of-measurement.enum';
import {Tool} from '@domain/utilities/tool/tool';
import {MACHINES, Machines} from '@infrastructure/http/machine/machines.interface';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {HttpPlasticProductionOrdersCustomSettingsService} from '@infrastructure/http/plastic-production-order/http-plastic-production-orders-custom-settings.service';
import {HttpPlasticProductionOrdersService} from '@infrastructure/http/plastic-production-order/http-plastic-production-orders.service';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {HttpToolsService} from '@infrastructure/http/tool/http-tools.service';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {PlasticProductOverviewService} from '@presentation/pages/textile-data/plastic-product/overview/plastic-product-overview.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {ToolOverviewService} from '@presentation/pages/utilities/tool/overview/tool-overview.service';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  ColDefBuilderFactoryService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  DialogBuilderFactoryService,
  DialogType,
  FormValidationHelper,
  GridOptionsBuilder,
  SaveType,
  skeletonViewAnimation,
  TabsMenuItem,
  TranslateService
} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ValueGetterParams} from 'ag-grid-community';
import {finalize, map, Observable, takeUntil} from 'rxjs';
import {PlasticProductionOrderCustomSettingsComponent} from '../custom-settings/plastic-production-order-custom-settings.component';

type CrudOverviewDataConfigType = PlasticProduct | MachineOverviewList | Tool;

@Component({
  templateUrl: './add-plastic-production-order-page.component.html',
  styleUrls: ['./add-plastic-production-order-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .button-skeleton-wrapper')]
})
export class AddPlasticProductionOrderPageComponent extends BaseComponent implements OnInit {
  public addPlasticProductionOrderGeneralForm: UntypedFormGroup;
  public selectedMenuItem: TabsMenuItem = {value: 0, translationKey: 'GENERAL.GENERAL'};

  private productConfig: CrudOverviewDataConfig<CrudOverviewDataConfigType>;
  private machineConfig: CrudOverviewDataConfig<CrudOverviewDataConfigType>;
  private toolConfig: CrudOverviewDataConfig<CrudOverviewDataConfigType>;

  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_PLASTIC_PRODUCTION_ORDER;
  public readonly MIN_DUE_DATE = new Date();

  private plasticProductionOrderId: number;
  protected currentProductionOrder: PlasticProductionOrder;

  private readonly TRANSLATION_KEY = 'PLASTIC_PRODUCTION_ORDER.PLASTIC_PRODUCTION_ORDER';

  public constructor(
    @Inject(MACHINES) private readonly machines: Machines,
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    @Inject(DOCUMENT) private document: Document,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly plasticProducts: HttpPlasticProductsService,
    private readonly plasticProductionOrders: HttpPlasticProductionOrdersService,
    protected readonly plasticProductionOrdersCustomSettings: HttpPlasticProductionOrdersCustomSettingsService,
    private readonly tools: HttpToolsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly translate: TranslateService,
    private readonly plasticProductOverviewService: PlasticProductOverviewService,
    private readonly toolOverviewService: ToolOverviewService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<CrudOverviewDataConfigType>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setGeneralFormFields();
    this.setBuilderConfigForPLasticProduct();
    this.setBuilderConfigForMachine();
    this.setBuilderConfigForTool();

    this.plasticProductionOrderId = !AssertionUtils.isNullOrUndefined(this.activatedRoute.snapshot.params.id) ? Number(this.activatedRoute.snapshot.params.id) : undefined;

    this.addPlasticProductionOrderGeneralForm.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((_: UntypedFormGroup) => {
      this.setCurrentProductionOrder();
    });

    if (this.plasticProductionOrderId) {
      this.plasticProductionOrders
        .getById(this.plasticProductionOrderId)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((plasticProductionOrder: PlasticProductionOrder) => {
          this.setFormValues(plasticProductionOrder);
        });
    }
  }

  public save(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForms([this.addPlasticProductionOrderGeneralForm], this.document);
    if (isValid) {
      const plasticProductionOrderToSave = this.currentProductionOrder;
      this.saving = true;

      const request: Observable<void | number> = this.isEditingPlasticProductionOrder()
        ? this.plasticProductionOrders.update(plasticProductionOrderToSave)
        : this.plasticProductionOrders.save(plasticProductionOrderToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) =>
          this.textileService.navigateAndShowToast(
            saveType,
            TextileDataType.PLASTIC_PRODUCTION_ORDER,
            this.TRANSLATION_KEY,
            this.isEditingPlasticProductionOrder(),
            plasticProductionOrderToSave.name,
            id
          ),
        error: (errorMessage: BackendError) =>
          this.showErrorDialogForBackendError(this.isEditingPlasticProductionOrder() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public isEditingPlasticProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === NavigationUtils.getNavigationData(NavigationId.EDIT_PLASTIC_ORDER).route;
  }

  public duplicatePlasticProductionOrder(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.plastic.duplicateProductionOrder.absolutePath.replace(':id', this.plasticProductionOrderId.toString()));
  }

  public cancel(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.plastic.productionOrder.absolutePath);
  }

  public deletePlasticProductionOrder(): void {
    this.textileService.removeConfirmation(this.currentProductionOrder, TextileDataType.PLASTIC_PRODUCTION_ORDER, false, null, this.plasticProductionOrders);
  }

  public selectProduct(): void {
    this.openObjectSelectionDialog(this.productConfig, 'product');
  }

  public selectMachine(): void {
    this.openObjectSelectionDialog(this.machineConfig, 'machine');
  }

  public selectTool(): void {
    this.openObjectSelectionDialog(this.toolConfig, 'tool');
  }

  public getQuantitySuffixTranslationKey(): string {
    const product: PlasticProduct = this.addPlasticProductionOrderGeneralForm.get('product').value;
    return `TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.${product?.unitOfMeasurement ?? UnitOfMeasurement.PIECES}`;
  }

  protected applyNameSuggestion(suggestion: string): void {
    this.addPlasticProductionOrderGeneralForm.controls.name.patchValue(suggestion);
  }

  protected openCustomSettings(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('large-modal')
      .openDialog(PlasticProductionOrderCustomSettingsComponent)
      .subscribe(() => this.setCurrentProductionOrder());
  }

  private setCurrentProductionOrder(): void {
    const product = this.addPlasticProductionOrderGeneralForm.get('product').value;
    const tool = this.addPlasticProductionOrderGeneralForm.get('tool').value;

    this.currentProductionOrder = new PlasticProductionOrder(
      this.isEditingPlasticProductionOrder() ? this.plasticProductionOrderId : null,
      this.addPlasticProductionOrderGeneralForm.get('name').value,
      ProductionScheduleStatus.TO_PLAN,
      this.addPlasticProductionOrderGeneralForm.get('dueDate').value,
      product ? new IdNameUnitOfMeasurement(product.id, product.name, product.unitOfMeasurement) : null,
      this.addPlasticProductionOrderGeneralForm.get('quantity').value,
      this.addPlasticProductionOrderGeneralForm.get('machine').value,
      tool ? new IdName(tool.id, tool.name) : null
    );
  }

  private setGeneralFormFields(): void {
    this.addPlasticProductionOrderGeneralForm = this.formBuilder.group({
      name: [null, Validators.required, AsyncUniqueValidator.createValidator(this.productionSchedules, null)],
      dueDate: [null],
      product: [null, Validators.required],
      quantity: [{value: null, disabled: true}, [Validators.required, Validators.min(1)]],
      machine: [null],
      tool: [null]
    });

    this.addPlasticProductionOrderGeneralForm
      .get('product')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((product: PlasticProduct) => {
        if (AssertionUtils.isNullOrUndefined(product)) {
          this.addPlasticProductionOrderGeneralForm.get('quantity').setValue(null);
          this.addPlasticProductionOrderGeneralForm.get('quantity').disable();
        } else {
          this.addPlasticProductionOrderGeneralForm.get('quantity').enable();
        }
      });

    this.selectedMenuItem.form = this.addPlasticProductionOrderGeneralForm;
  }

  private setFormValues(plasticProductionOrder: PlasticProductionOrder): void {
    let dueDate = plasticProductionOrder.dueDate;
    if (!AssertionUtils.isNullOrUndefined(dueDate) && dueDate <= this.MIN_DUE_DATE) {
      dueDate = null;
    }

    this.addPlasticProductionOrderGeneralForm.reset({
      name: this.isDuplicatingPlasticProductionOrder() ? null : plasticProductionOrder.name,
      dueDate,
      product: plasticProductionOrder.plasticProduct,
      quantity: plasticProductionOrder.plannedQuantity,
      machine: plasticProductionOrder.machine,
      tool: plasticProductionOrder.tool
    });

    if (this.isEditingPlasticProductionOrder()) {
      this.addPlasticProductionOrderGeneralForm.get('name').setAsyncValidators([AsyncUniqueValidator.createValidator(this.productionSchedules, plasticProductionOrder.name)]);
      this.addPlasticProductionOrderGeneralForm.get('name').updateValueAndValidity();
    }
  }

  private isDuplicatingPlasticProductionOrder(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === NavigationUtils.getNavigationData(NavigationId.DUPLICATE_PLASTIC_ORDER).route;
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {object: this.translate.instant(this.TRANSLATION_KEY)}),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  private getColDefForMachine(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('machineType', true)
        .withHeaderName('SUPPORT.MACHINES_LIST.TABLE_HEADER.TYPE')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`MACHINE.TYPES.${MachineType[params.data.machineType]}`))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('standardSpeed')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_SPEED')
        .withCellClass('right')
        .withCellRenderer((params: ICellRendererParams) => (params.value ? `${params.value} ${this.translate.instant('MACHINE.DETAILS.STANDARD_SPEEDS.SPEED')}` : ''))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('standardEfficiency')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_EFFICIENCY')
        .withCellClass('right')
        .withCellRenderer((params: ICellRendererParams) => (params.value ? `${params.value} %` : ''))
        .build()
    ];
  }

  private setBuilderConfigForPLasticProduct(): void {
    this.productConfig = this.getConfig(
      this.translate.instant('TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT'),
      this.plasticProductOverviewService.getColumnDefs(false),
      GridIdentifier.PLASTIC_PRODUCT_OVERVIEW,
      this.plasticProducts.getAll()
    );
  }

  private setBuilderConfigForMachine(): void {
    this.machineConfig = this.getConfig(
      this.translate.instant('MACHINE.MACHINE', {count: 1}),
      this.getColDefForMachine(),
      GridIdentifier.SELECT_MACHINE,
      this.machines.getAll().pipe(map((machines: MachineOverviewList[]) => machines.filter((machine: MachineOverviewList) => machine.machineType === MachineType.PLASTIC_MACHINE)))
    );
  }

  private setBuilderConfigForTool(): void {
    this.toolConfig = this.getConfig(this.translate.instant('UTILITIES.TOOL.TOOL', {count: 1}), this.toolOverviewService.getColumnDefs(false), GridIdentifier.TOOL_OVERVIEW, this.tools.getAll());
  }

  private getConfig(
    translationKey: string,
    colDefs: ColDef[],
    gridIdentifier: string,
    dataObservable: Observable<CrudOverviewDataConfigType[]>,
    rowData: CrudOverviewDataConfigType[] = null
  ): CrudOverviewDataConfig<CrudOverviewDataConfigType> {
    let builderFactoryService = this.builderFactoryService
      .getBuilder()
      .withHeader(translationKey)
      .withGridOptions(colDefs, gridIdentifier, rowData, (builder: GridOptionsBuilder) => {
        if (gridIdentifier === GridIdentifier.TOOL_OVERVIEW) {
          builder = this.toolOverviewService.getDefaultGridOptionsBuilder(builder);
        }

        if (gridIdentifier === GridIdentifier.PLASTIC_PRODUCT_OVERVIEW) {
          builder = this.plasticProductOverviewService.getDefaultGridOptionsBuilder(builder);
        }

        return builder.withRowSelection(false, false, true, false).build();
      });

    if (dataObservable) {
      builderFactoryService = builderFactoryService.withRowData(dataObservable);
    }

    return builderFactoryService.build();
  }

  private openObjectSelectionDialog(config: CrudOverviewDataConfig<CrudOverviewDataConfigType>, fieldName: string): void {
    const field = this.addPlasticProductionOrderGeneralForm.get(fieldName);
    config.selectedObjects = [field.value];

    this.builderFactoryService.openObjectSelectionDialog(config).subscribe((object: CrudOverviewDataConfigType[]) => field.setValue(AssertionUtils.isEmpty(object) ? field.value : object[0]));
  }
}
