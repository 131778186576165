import {IdNameVersionPosition} from './id-name-version-position';

export class OverviewListSingleWidthWeaveStructure {
  private readonly _weaveStructure: IdNameVersionPosition;

  public constructor(weaveStructure: IdNameVersionPosition) {
    this._weaveStructure = weaveStructure;
  }

  public get weaveStructure(): IdNameVersionPosition {
    return this._weaveStructure;
  }

  public static fromJSON(singleWidthWeaveStructureJSON: any): OverviewListSingleWidthWeaveStructure {
    return new OverviewListSingleWidthWeaveStructure(IdNameVersionPosition.fromJSON(singleWidthWeaveStructureJSON.singleWidth.weaveStructure));
  }

  public static fromOldJSON(singleWidthWeaveStructureJSON: any): OverviewListSingleWidthWeaveStructure {
    return new OverviewListSingleWidthWeaveStructure(IdNameVersionPosition.fromJSON(singleWidthWeaveStructureJSON.weaveStructure));
  }
}
