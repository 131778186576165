import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export const YARN_CONSUMPTION = new InjectionToken<YarnConsumption>('YarnConsumption');

export interface YarnConsumption {
  calculateYarnConsumptionForOrderline(id: number): Observable<void>;
  calculateYarnConsumptionForProductionOrder(id: number | string): Observable<void>;
  calculateYarnConsumptionForDesign(id: number, colorSetId: number, yarnSetId: number, machineQualityId: number, calculateYarnConsumptionPerDent: boolean): Observable<void>;
}
