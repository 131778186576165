import {Pattern} from '@domain/pattern';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {RepeatLabel} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-selvedges/repeat-label.enum';
import {SelvedgeOption} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-selvedges/selvedge-option.enum';
import {map} from 'lodash-es';
import {Label} from './label';

export class Selvedge {
  private _selvedgeOption: SelvedgeOption;
  private _minimumWidth: number;
  private _backgroundImage: Pattern;
  private _repeatLabel: RepeatLabel;
  private _labels: Label[];

  public constructor(selvedgeOption: SelvedgeOption, minimumWidth: number = null, backgroundImage: Pattern = null, repeatLabel: RepeatLabel = null, labels: Label[] = null) {
    this._selvedgeOption = selvedgeOption;
    this._minimumWidth = minimumWidth;
    this._backgroundImage = backgroundImage;
    this._repeatLabel = repeatLabel;
    this._labels = labels;
  }

  public get selvedgeOption(): SelvedgeOption {
    return this._selvedgeOption;
  }

  public get minimumWidth(): number {
    return this._minimumWidth;
  }

  public get backgroundImage(): Pattern {
    return this._backgroundImage;
  }

  public get repeatLabel(): RepeatLabel {
    return this._repeatLabel;
  }

  public get labels(): Label[] {
    return this._labels;
  }

  public static fromJSON(selvedgeJSON: any): Selvedge {
    return new Selvedge(
      selvedgeJSON.selvedgeOption,
      selvedgeJSON.minimumWidth,
      Pattern.fromJSON(selvedgeJSON.backgroundImage),
      selvedgeJSON.repeatLabel,
      selvedgeJSON.labels === null
        ? null
        : map(selvedgeJSON.labels, (labelJSON: any) => {
            return Label.fromJSON(labelJSON);
          })
    );
  }

  public toJSON(): JSON {
    const firstVisualization = this._backgroundImage?.visualisations[0];
    return {
      selvedgeOption: this._selvedgeOption,
      minimumWidth: this._minimumWidth,
      backgroundPatternWeaveStructureIndex: firstVisualization?.isWeavePatternVisualisation() ? this._backgroundImage.id : null,
      backgroundImageId: firstVisualization?.type === PatternVisualisationType.DRAWING ? this._backgroundImage.id : null,
      repeatLabel: this._repeatLabel,
      labels:
        this._labels === null
          ? null
          : map(this._labels, (label: Label) => {
              return label.toJSON();
            })
    } as any as JSON;
  }
}
