import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Pattern} from '@domain/pattern';
import {PropertyValue} from '@domain/property-value';
import {FinishingTemplate} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template';
import {ImageForFinishingImageLabel} from '@domain/textile-data/finishing-and-finishing-template/image-for-finishing-image-label';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {FinishingTemplates} from '@infrastructure/http/finishing-template/finishing-templates';
import {OverviewListFinishingTemplate} from '@presentation/pages/textile-data/finishing-template/overview/overview-list-finishing-template';
import {FinishingTemplateForMachineQuality} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/finishing-template-for-machine-quality';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {each, map} from 'lodash-es';
import {forkJoin, Observable} from 'rxjs';
import {map as rxjsMap, retry} from 'rxjs/operators';

@Injectable()
export class HttpFinishingTemplatesService implements FinishingTemplates {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/${id}`);
  }

  public getAll(): Observable<OverviewListFinishingTemplate[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates`).pipe(
      rxjsMap((finishingTemplatesJSON: any) => {
        return map(finishingTemplatesJSON, (finishingTemplateJSON: any) => {
          return OverviewListFinishingTemplate.fromJSON(finishingTemplateJSON);
        });
      })
    );
  }

  public getById(id: number): Observable<FinishingTemplate> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/${id}`).pipe(
      rxjsMap((finishingTemplateJSON: any) => {
        return FinishingTemplate.fromJSON(finishingTemplateJSON);
      })
    );
  }

  public save(item: FinishingTemplate): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}finishingtemplates`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public update(item: FinishingTemplate): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}finishingtemplates/check-name`, {params}).pipe(rxjsMap((response: any) => CheckName.fromJSON(response)));
  }

  public getFinishingTemplatesForMachineQuality(machineQualityId: number): Observable<FinishingTemplateForMachineQuality[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/machine-quality/${machineQualityId}`).pipe(
      rxjsMap((finishingTemplatesJSON: any) => {
        return map(finishingTemplatesJSON, (finishingTempalateJSON: any) => {
          return FinishingTemplateForMachineQuality.fromJSON(finishingTempalateJSON);
        });
      })
    );
  }

  public getFinishingTemplatesForQuality(weaveStructureId: number, pickDensity: number): Observable<FinishingTemplateForMachineQuality[]> {
    const params = new HttpParams().set('weave-structure-id', weaveStructureId).set('pick-density', pickDensity);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}finishingtemplates`, {params}).pipe(
      rxjsMap((finishingTemplatesJSON: any) => {
        return finishingTemplatesJSON.map((finishingTempalateJSON: any) => {
          return FinishingTemplateForMachineQuality.fromJSON(finishingTempalateJSON);
        });
      })
    );
  }

  public getListOfFinishingTypes(): Observable<PropertyValue> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/finishing-types`).pipe(
      rxjsMap((response: PropertyValue) => {
        return response;
      })
    );
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/custom-settings`).pipe(
      rxjsMap((response: PropertyValue[]) => {
        return response;
      })
    );
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/custom-settings/already-adjusted`).pipe(
      rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    const settingsToSave: PropertyValue[] = [];

    each(chosenSettings, (chosenSetting: PropertyValue) => {
      settingsToSave.push({propertyName: chosenSetting.propertyName, propertyValue: chosenSetting.propertyValue});
    });

    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/custom-settings`, settingsToSave);
  }

  public getCategoriesWithComponents(): Observable<PlaceholderCategory[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/categories-with-components`).pipe(
      rxjsMap((placeholderCategoriesJSON: any) => {
        return map(placeholderCategoriesJSON, (placeholderCategoryJSON: any) => {
          return PlaceholderCategory.fromJSON(placeholderCategoryJSON);
        });
      })
    );
  }

  public getImagesForLabels(): Observable<ImageForFinishingImageLabel[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/label-images`).pipe(
      rxjsMap((imagesForFinishingImageLabelJSON: any) => {
        return map(imagesForFinishingImageLabelJSON, (imageForFinishingImageLabelJSON: any) => {
          return ImageForFinishingImageLabel.fromJSON(imageForFinishingImageLabelJSON);
        });
      })
    );
  }

  public getFoldingTypes(): Observable<Pattern[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/folding-types`).pipe(
      rxjsMap((patternsJSON: any) => {
        return map(patternsJSON, (patternJSON: any) => {
          return Pattern.fromFoldingTypeJSON(patternJSON);
        });
      })
    );
  }

  public uploadImagesForLabels(imagesForFinishingImageLabel: ImageForFinishingImageLabel[]): Observable<ImageForFinishingImageLabel[]> {
    return forkJoin(
      map(imagesForFinishingImageLabel, (imageForFinishingImageLabel: ImageForFinishingImageLabel) => {
        const formDataToUpload = new FormData();
        formDataToUpload.append('file', imageForFinishingImageLabel.file, imageForFinishingImageLabel.file.name);
        return this.httpClient.post(`${this.backendConfiguration.getEndpoint(2)}finishingtemplates/label-images/image`, formDataToUpload).pipe(
          rxjsMap((response: any) => {
            imageForFinishingImageLabel.imageId = response.imageId;
            imageForFinishingImageLabel.id = response.id;
            return imageForFinishingImageLabel;
          })
        );
      })
    );
  }

  public getImageForLabels(imageId: string): Observable<Blob> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/label-images/image?imageId=${imageId}`, {responseType: 'blob'}).pipe(retry(2));
  }

  public deleteImageForLabels(imageId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/label-images/${imageId}`);
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}finishingtemplates/${id}/conflicts`).pipe(
      rxjsMap((conflictsJSON: any) => {
        return map(conflictsJSON, (conflictJSON: any) => Conflict.fromJSON(conflictJSON));
      })
    );
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/custom-settings/name-generation-properties`);
  }

  public generateName(item: FinishingTemplate): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getEndpoint()}finishingtemplates/custom-settings/generate-name`, item.toJSON());
  }
}
