import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {MachineSummary} from '@domain/production-schedule/machine-summary';

export class OverviewListTuftingMachine extends IdName {
  private readonly _machineType: MachineType;

  public constructor(machineType: MachineType, id: number, name: string) {
    super(id, name);
    this._machineType = machineType;
  }

  public get machineType(): MachineType {
    return this._machineType;
  }

  public static fromJSON(overviewListTuftingMachine: any): OverviewListTuftingMachine {
    return new OverviewListTuftingMachine(overviewListTuftingMachine.machineType, overviewListTuftingMachine.id, overviewListTuftingMachine.name);
  }

  public static fromMachineSummary(machine: MachineSummary): OverviewListTuftingMachine {
    return new OverviewListTuftingMachine(machine.type, machine.id, machine.name);
  }
}
