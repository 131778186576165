import {Inject, Injectable} from '@angular/core';
import {ProductionOrders, PRODUCTION_ORDERS} from '@infrastructure/http/production-order/production-orders';
import {OverviewListProductionSchedule} from '@presentation/pages/texfab/production-schedule/overview/overview-list-production-schedule';
import {ReasonForFailureContext} from '@presentation/pages/texfab/production-schedule/overview/reason-for-failure-context';
import {DialogBuilderFactoryService, DialogType, TranslateService} from '@vdw/angular-component-library';
import {isEqual, isNil} from 'lodash-es';

@Injectable()
export class ShowReasonForFailureDialogCommand {
  public constructor(
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public execute(productionSchedule: OverviewListProductionSchedule): void {
    this.productionOrders.getReasonForFailure(Number(productionSchedule.id)).subscribe((reasonForFailureContext: ReasonForFailureContext) => {
      let messageText: string;
      let extraInformationText: string;

      if (isEqual(reasonForFailureContext.errorCode, 'PROCESSING_ERROR')) {
        messageText = this.translate.instant(`PRODUCTION_ORDER.ERRORS.PROCESSING_ERROR.${reasonForFailureContext.reasonForFailure}`, {
          entityName: reasonForFailureContext.entityName
        });
      } else {
        messageText = `PRODUCTION_ORDER.ERRORS.${reasonForFailureContext.errorCode}`;
      }

      if (!isNil(reasonForFailureContext.errorText)) {
        extraInformationText = reasonForFailureContext.errorText;
      }

      this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
        titleText: 'GENERAL.ERRORS.ERROR_DETAILS',
        messageText,
        extraMessageText: extraInformationText,
        type: DialogType.ERROR
      });
    });
  }
}
