import {ColorConstants} from '../common/colors-variables.generated';
import {addAlphaToHexRGB} from '../common/converters/convert-color-representation';

export const MACHINE_GROUP_COLOURS_PALETTE = [
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_ORANGE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_YELLOW, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SKY_BLUE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_BRIGHT_SKY_BLUE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_BABY_BLUE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_BRIGHT_PINK, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_PINK, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_PURPLE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_BLUE, opacity),
  (opacity: number): string => addAlphaToHexRGB(ColorConstants.PLANNING_SOFT_GREY, opacity)
];
