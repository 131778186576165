import {Injectable} from '@angular/core';
import {WindowRef} from './window-ref';

@Injectable()
export class BrowserWindowRef extends WindowRef {
  public constructor() {
    super();
  }

  public get nativeWindow(): Window | Record<string, unknown> {
    return window;
  }
}
