import {Equipment} from '@domain/machine/equipment';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {isNil} from 'lodash-es';
import {BuilderType} from './builder-type.enum';
import {JacquardMachineLoomLayoutZone} from './jacquard-machine-loom-layout-zone';
import {MachineGroup} from './machine-group';
import {MachineManufacturer} from './machine-manufacturer';
import {MachineType} from './machine-type.enum';
import {VelvetMachineDrawingIn} from './velvet-machine-drawing-in';
import {VelvetMachineDrawingInType} from './velvet-machine-drawing-in-type.enum';
import {VelvetMachineGroundDrawingIn} from './velvet-machine-ground-drawing-in';
import {VelvetMachinePileDrawingIn} from './velvet-machine-pile-drawing-in';

export class VelvetMachine extends Equipment {
  private _machineGroup: MachineGroup;
  private _remote: boolean;
  private _manufacturer: MachineManufacturer;
  private _jacquardType: string;
  private _minNumberOfPicks: number;
  private _pickDensityInSchedule: boolean;
  private _possibleQualities: OverviewListMachineQuality[];
  private _possibleCreels: OverviewListCreel[];
  private _numberOfCreelPositions: number;
  private _dentsForFabric: number;
  private _sendReport: number;
  private _builderType: BuilderType;
  private _loomLayoutZones: JacquardMachineLoomLayoutZone[];
  private _groundYarnsPerDent: number;
  private _drawingIn: VelvetMachineDrawingIn[];

  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.VELVET, name, orderId);

    this._machineGroup = null;
    this._remote = false;
    this._loomLayoutZones = [];
    this._drawingIn = [];
  }

  public static fromJSON(velvetMachineJSON: any): VelvetMachine {
    const machineQualities: OverviewListMachineQuality[] =
      velvetMachineJSON.possibleMachineQualities.length > 0
        ? velvetMachineJSON.possibleMachineQualities.map((possibleMachineQuality: any) => {
            return OverviewListMachineQuality.fromJSON(possibleMachineQuality);
          })
        : null;

    const creels: OverviewListCreel[] =
      velvetMachineJSON.possibleCreels.length > 0
        ? velvetMachineJSON.possibleCreels.map((possibleCreel: any) => {
            return OverviewListCreel.fromJSON(possibleCreel);
          })
        : null;

    const machineGroup: MachineGroup = isNil(velvetMachineJSON.machineGroup) ? null : MachineGroup.fromJSON(velvetMachineJSON.machineGroup);

    const velvetMachine = new VelvetMachine(velvetMachineJSON.id, velvetMachineJSON.name, velvetMachineJSON.orderId);
    velvetMachine.manufacturer = isNil(velvetMachineJSON.manufacturerId) ? null : new MachineManufacturer(velvetMachineJSON.manufacturerId);
    velvetMachine.jacquardType = velvetMachineJSON.jacquardType;
    velvetMachine.minNumberOfPicks = velvetMachineJSON.minNumberOfPicks;
    velvetMachine.pickDensityInSchedule = velvetMachineJSON.pickDensityInSchedule;
    velvetMachine.possibleQualities = machineQualities;
    velvetMachine.possibleCreels = creels;
    velvetMachine.numberOfCreelPositions = velvetMachineJSON.numberOfCreelPositions;
    velvetMachine.dentsForFabric = velvetMachineJSON.dentsForFabric;
    velvetMachine.sendReport = velvetMachineJSON.sendReport;
    velvetMachine.builderType = velvetMachineJSON.builderType ? BuilderType[velvetMachineJSON.builderType as string] : null;
    velvetMachine.remote = velvetMachineJSON.remoteMachine;
    velvetMachine.machineGroup = machineGroup;
    velvetMachine.loomLayoutZones = velvetMachineJSON.loomLayoutZones
      ? velvetMachineJSON.loomLayoutZones
          .map(JacquardMachineLoomLayoutZone.fromJSON)
          .sort((firstLoomLayoutZone: JacquardMachineLoomLayoutZone, secondLoomLayoutZone: JacquardMachineLoomLayoutZone) => firstLoomLayoutZone.position - secondLoomLayoutZone.position)
      : [];
    velvetMachine.groundYarnsPerDent = velvetMachineJSON.groundYarnsPerDent;
    velvetMachine.drawingIn =
      velvetMachineJSON.drawingIn
        ?.map((drawingInJSON: any) => {
          return VelvetMachineDrawingInType[`${drawingInJSON.type}`] === VelvetMachineDrawingInType.PILE
            ? VelvetMachinePileDrawingIn.fromJSON(drawingInJSON)
            : VelvetMachineGroundDrawingIn.fromJSON(drawingInJSON);
        })
        .sort((firstDrawingIn: VelvetMachineDrawingIn, secondDrawingIn: VelvetMachineDrawingIn) => firstDrawingIn.position - secondDrawingIn.position) ?? [];

    return velvetMachine;
  }

  public get machineGroup(): MachineGroup {
    return this._machineGroup;
  }

  public set machineGroup(machineGroup: MachineGroup) {
    this._machineGroup = machineGroup;
  }

  public get remote(): boolean {
    return this._remote;
  }

  public set remote(remote: boolean) {
    this._remote = remote;
  }

  public get manufacturer(): MachineManufacturer {
    return this._manufacturer;
  }

  public set manufacturer(manufacturer: MachineManufacturer) {
    this._manufacturer = manufacturer;
  }

  public get jacquardType(): string {
    return this._jacquardType;
  }

  public set jacquardType(jacquardType: string) {
    this._jacquardType = jacquardType;
  }

  public get minNumberOfPicks(): number {
    return this._minNumberOfPicks;
  }

  public set minNumberOfPicks(minNumberOfPicks: number) {
    this._minNumberOfPicks = minNumberOfPicks;
  }

  public get pickDensityInSchedule(): boolean {
    return this._pickDensityInSchedule;
  }

  public set pickDensityInSchedule(pickDensityInSchedule: boolean) {
    this._pickDensityInSchedule = pickDensityInSchedule;
  }

  public get possibleQualities(): OverviewListMachineQuality[] {
    return this._possibleQualities;
  }

  public set possibleQualities(possibleQualities: OverviewListMachineQuality[]) {
    this._possibleQualities = possibleQualities;
  }

  public get possibleCreels(): OverviewListCreel[] {
    return this._possibleCreels;
  }

  public set possibleCreels(possibleCreels: OverviewListCreel[]) {
    this._possibleCreels = possibleCreels;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public set numberOfCreelPositions(numberOfCreelPositions: number) {
    this._numberOfCreelPositions = numberOfCreelPositions;
  }

  public get dentsForFabric(): number {
    return this._dentsForFabric;
  }

  public set dentsForFabric(dentsForFabric: number) {
    this._dentsForFabric = dentsForFabric;
  }

  public get sendReport(): number {
    return this._sendReport;
  }

  public set sendReport(sendReport: number) {
    this._sendReport = sendReport;
  }

  public get builderType(): BuilderType {
    return this._builderType;
  }

  public set builderType(builderType: BuilderType) {
    this._builderType = builderType;
  }

  public get loomLayoutZones(): JacquardMachineLoomLayoutZone[] {
    return this._loomLayoutZones;
  }

  public set loomLayoutZones(loomLayoutZones: JacquardMachineLoomLayoutZone[]) {
    this._loomLayoutZones = loomLayoutZones;
  }

  public get groundYarnsPerDent(): number {
    return this._groundYarnsPerDent;
  }

  public set groundYarnsPerDent(groundYarnsPerDent: number) {
    this._groundYarnsPerDent = groundYarnsPerDent;
  }

  public get drawingIn(): VelvetMachineDrawingIn[] {
    return this._drawingIn;
  }

  public set drawingIn(drawingIn: VelvetMachineDrawingIn[]) {
    this._drawingIn = drawingIn;
  }

  public toJSON(): JSON {
    const velvetMachineJSON = super.toJSON();

    velvetMachineJSON['jacquardType'] = this.jacquardType;
    velvetMachineJSON['manufacturerId'] = this.manufacturer?.id;
    velvetMachineJSON['minNumberOfPicks'] = this.minNumberOfPicks;
    velvetMachineJSON['numberOfCreelPositions'] = this.numberOfCreelPositions;
    velvetMachineJSON['pickDensityInSchedule'] = this.pickDensityInSchedule;
    velvetMachineJSON['possibleCreelIds'] = this.possibleCreels?.map((possibleCreel: OverviewListCreel) => possibleCreel.id) ?? [];
    velvetMachineJSON['possibleMachineQualityIds'] = this.possibleQualities?.map((possibleQuality: OverviewListMachineQuality) => possibleQuality.id) ?? [];
    velvetMachineJSON['dentsForFabric'] = this.dentsForFabric;
    velvetMachineJSON['sendReport'] = this.sendReport;
    velvetMachineJSON['machineGroupId'] = this.machineGroup?.id;
    velvetMachineJSON['machineGroup'] = this.machineGroup?.toJSON();
    velvetMachineJSON['remoteMachine'] = this.remote;
    velvetMachineJSON['builderType'] = BuilderType[this.builderType];
    velvetMachineJSON['loomLayoutZones'] = this._loomLayoutZones.map((loomLayoutZone: JacquardMachineLoomLayoutZone) => loomLayoutZone.toJSON());
    velvetMachineJSON['groundYarnsPerDent'] = this._groundYarnsPerDent;
    velvetMachineJSON['drawingIn'] = this._drawingIn.map((drawingIn: VelvetMachineDrawingIn) => drawingIn.toJSON());

    return velvetMachineJSON;
  }
}
