<mat-form-field [formGroup]="placeholderForm" class="full-width">
  <mat-label>{{ linkFormInputPlaceholder | bmsTranslate }}</mat-label>
  <vdw-object-selection
    formControlName="placeholderLabel"
    [objectName]="linkFormInputPlaceholder | bmsTranslate | lowercase"
    (selectClicked)="showBuilderForPlaceholderParts()"
    (clearClicked)="removePlaceholderParts()"
  ></vdw-object-selection>
  <mat-error>
    {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
  </mat-error>
</mat-form-field>
