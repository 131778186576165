import {Component, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {convertRGBToHexadecimalColorCode} from '../../../common/converters/convert-color-representation';
import {Color} from '../../../common/data/color';
import {MatFormFieldControlHelper} from '../../helper/form-field-control-helper';

@Component({
  selector: 'vdw-select-colors',
  templateUrl: './select-colors.component.html',
  styleUrls: ['./select-colors.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectColorsComponent}]
})
export class SelectColorsComponent extends MatFormFieldControlHelper<Color> implements OnDestroy, OnInit {
  public static nextId = 0;
  @ViewChild(MatSelect, {static: true}) public formControl: MatSelect;
  @Input() public colors: Color[];

  public id = `vdw-select-colors-${SelectColorsComponent.nextId++}`;
  public controlType = 'mat-select';

  public constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }

  public ngOnInit(): void {
    super.init();
  }

  public ngOnDestroy(): void {
    super.destroy();
  }

  public compareColors(colorOption: Color, selectedColor: Color): boolean {
    return colorOption && selectedColor && colorOption.id === selectedColor.id;
  }

  public getPreviewColorCode(color: Color): string {
    if (color?.rgb) {
      return convertRGBToHexadecimalColorCode(color.rgb);
    }
  }
}
