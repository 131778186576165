export enum ProductionScheduleClickEvent {
  SAVE = 'SAVE',
  SAVE_AND_CREATE_NEW = 'SAVE_AND_CREATE_NEW',
  SAVE_AND_GO_TO_PRESELECTION = 'SAVE_AND_GO_TO_PRESELECTION',
  SAVE_AND_QUIT = 'SAVE_AND_QUIT',
  PRESELECTION = 'PRESELECTION',
  NEXT_PHASE = 'NEXT_PHASE',
  VERIFY_AND_CONFIRM = 'VERIFY_AND_CONFIRM',
  COMPLETE = 'COMPLETE',
  DECREATE = 'DECREATE',
  SETTINGS = 'SETTINGS',
  CANCEL = 'CANCEL'
}
