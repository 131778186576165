import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ColDef, GridApi, GridOptions} from 'ag-grid-community';
import {PropertyValue} from '../../common/data/property-value.interface';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {GridModel} from '../../grids/floating-filters/data/grid-model';
import {StatusBar} from './status-bar';
import {StatusBarSize} from './status-bar-size.enum';

@Component({
  selector: 'vdw-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnChanges {
  @Input() public title: string;
  @Input() public statusBar: StatusBar;
  @Input() public selectedRows: number;
  @Input() public gridModel: GridModel;
  @Input() public advancedSearchFilters: PropertyValue[] = [];
  @Input() public gridApi: GridApi;
  @Input() public detailGridOptions: GridOptions;
  @Input() public initialToggleSelected: boolean;
  @Input() public canShowOnlyShowSelectedToggle = false;
  @Input() public canShowBMSTheme = false;
  @Input() public size: StatusBarSize = StatusBarSize.DEFAULT;
  @Output() public updateGrid: EventEmitter<GridModel> = new EventEmitter<GridModel>();
  @Output() public toggleSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (!AssertionUtils.isNullOrUndefined(this.gridApi) && !AssertionUtils.isNullOrUndefined(this.gridModel) && ('gridModel' in changes || 'gridApi' in changes)) {
      this.advancedSearchFilters = [];
      this.getAdvancedSearchFiltersFromGridModel();
    }
  }

  public hasMultipleChips(): boolean {
    return this.advancedSearchFilters.length > 1;
  }

  public canShowOnlySelected(): boolean {
    return AssertionUtils.isNullOrUndefined(this.detailGridOptions) && this.canShowOnlyShowSelectedToggle;
  }

  public totalGroupedRowsIsDefined(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.statusBar?.totalGroupedRows);
  }

  public getAdvancedSearchFiltersFromGridModel(): void {
    const columnsDefs = this.gridApi.getColumnDefs();
    let key: any;
    let value: any;
    for ([key, value] of Object.entries(this.gridModel.filterModel)) {
      const colDef = columnsDefs.filter((columnDef: ColDef) => {
        return columnDef.colId === key;
      });

      if (!AssertionUtils.isNullOrUndefined(colDef[0]?.headerName) && !AssertionUtils.isNullOrUndefined(value?.filterModels)) {
        if (!AssertionUtils.isNullOrUndefined(value.filterModels[0]?.filter)) {
          this.advancedSearchFilters.push({propertyName: key, propertyValue: value.filterModels[0].filter, headerName: colDef[0].headerName, filterType: value.filterModels[0].type});
        }

        if (!AssertionUtils.isNullOrUndefined(value.filterModels[1]?.values)) {
          this.advancedSearchFilters.push({propertyName: key, propertyValue: value.filterModels[1].values, headerName: colDef[0].headerName});
        }
      }
    }
  }

  public getLabelForFilter(chip: PropertyValue): string {
    let symbol = '';
    if (chip?.filterType === 'greaterThan') {
      symbol = ' > ';
    } else if (chip?.filterType === 'lessThan') {
      symbol = ' < ';
    } else if (chip?.filterType === 'greaterThanOrEqual') {
      symbol = ' >= ';
    } else if (chip?.filterType === 'lessThanOrEqual') {
      symbol = ' <= ';
    } else if (chip?.filterType === 'notEqual') {
      symbol = ' != ';
    }

    const label = `${chip.headerName} (${symbol}${chip.propertyValue})`;
    return label.split(',').join(', ');
  }

  public showSelectedRows(checked: boolean): void {
    this.toggleSelected.emit(checked);
  }

  public deleteFilter(propertyValue: PropertyValue | string): void {
    if (typeof propertyValue === 'string') {
      let property: PropertyValue;
      this.advancedSearchFilters.forEach((value: PropertyValue) => {
        const labelValue = this.getLabelForFilter(value);
        if (labelValue === propertyValue) {
          property = value;
        }
      });

      this.advancedSearchFilters = this.advancedSearchFilters.filter((value: PropertyValue) => {
        return value !== property;
      });

      delete this.gridModel.filterModel[`${property.propertyName}`];
    } else {
      this.advancedSearchFilters = this.advancedSearchFilters.filter((value: PropertyValue) => {
        return value !== propertyValue;
      });

      delete this.gridModel.filterModel[`${propertyValue.propertyName}`];
    }

    this.updateGrid.emit(this.gridModel);
  }

  public deleteAllFilters(): void {
    this.gridModel.filterModel = null;

    this.updateGrid.emit(this.gridModel);
  }
}
