import {PlanningItem} from '../planning-item';
import {PlanningItemProperties} from '../planning-item-properties';
import {PlanningItemType} from '../planning-item-type';
import {Tool} from './tool';

export class RunPlastic extends PlanningItem {
  private _tool?: Tool;

  public constructor(itemProperties: PlanningItemProperties, tool?: Tool, ...planningItems: PlanningItem[]) {
    super(itemProperties, PlanningItemType.RUN_PLASTIC, ...planningItems);
    this._tool = tool;
  }

  public get tool(): Tool {
    return this._tool;
  }

  public set tool(tool: Tool) {
    this._tool = tool;
  }

  public override toJSON(clearCompletedItems: boolean = false): JSON {
    return {
      ...super.toJSON(clearCompletedItems),
      toolId: this.tool?.id
    } as any as JSON;
  }
}
