import {BaseMachineType} from '@domain/machine/base-machine-type';
import {EquipmentManufacturer} from './equipment-manufacturer';
import {Technology} from './technology';

export class WeavingMachineType extends BaseMachineType {
  private _width: number;
  private _technology: Technology;

  public constructor(id: number, name: string, width: number, manufacturer: EquipmentManufacturer, model: string, technology: Technology) {
    super(id, name, manufacturer, model);
    this._width = width;
    this._technology = technology;
  }

  public static fromJSON(weavingMachineTypeJSON: any): WeavingMachineType {
    return new WeavingMachineType(
      weavingMachineTypeJSON.id,
      weavingMachineTypeJSON.name,
      weavingMachineTypeJSON.widthInCm,
      new EquipmentManufacturer(weavingMachineTypeJSON.manufacturerId, weavingMachineTypeJSON.manufacturerName, []),
      weavingMachineTypeJSON.model,
      new Technology(weavingMachineTypeJSON.technologyId, weavingMachineTypeJSON.technologyName)
    );
  }

  public toJSON(): JSON {
    const machineTypeJSON = super.toJSON();
    machineTypeJSON['widthInCm'] = this.width;
    machineTypeJSON['technologyId'] = this.technology?.id;
    return machineTypeJSON;
  }

  public get width(): number {
    return this._width;
  }

  public set width(width: number) {
    this._width = width;
  }

  public get technology(): Technology {
    return this._technology;
  }

  public set technology(technology: Technology) {
    this._technology = technology;
  }
}
