import {Component, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {ColorConstants} from '../../common/colors-variables.generated';
import {PlanningRowData} from '../planning-items/planning-item-base/planning-row-data';

@Component({
  selector: 'vdw-planning-row',
  templateUrl: './planning-row.component.html',
  styleUrls: ['./planning-row.component.scss']
})
export class PlanningRowComponent {
  public readonly data = new PlanningRowData();

  @Input()
  public color = 'white';

  @Input()
  public icon = '';

  @Input()
  public indicatorText = 'Indicator';

  @Input()
  public set dataTransfer(value: any) {
    this.data.dataTransfer = value;
  }

  public get dataTransfer(): any {
    return this.data.dataTransfer;
  }

  @Input()
  public set clickable(value: boolean) {
    this.data.clickable = value;
  }

  public get clickable(): boolean {
    return this.data.clickable;
  }

  @Input()
  public rowBackgroundColor = ColorConstants.PRIMARY_COLOR_WHITE;

  @Output()
  public selected = this.data.selected;

  @ViewChild(TemplateRef)
  public template: TemplateRef<any>;
}
