import {Directive, TemplateRef} from '@angular/core';
import {CdkDraggableDropListComponent} from './cdk-draggable-drop-list/cdk-draggable-drop-list.component';

@Directive({
  selector: '[vdwCdkDragPreviewContent]'
})
export class CdkDragPreviewContentDirective {
  public constructor(
    template: TemplateRef<any>,
    private readonly dropList: CdkDraggableDropListComponent
  ) {
    this.dropList.dragPreviewContent = template;
  }
}
