import {AssertionUtils} from '../utils/assertion-utils';

export enum MessageType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  CONFIRMATION = 'confirmation'
}

export namespace MessageType {
  export function getMessageTypeByLabel(label: string): MessageType {
    return AssertionUtils.isNullOrUndefined(label) || AssertionUtils.isNullOrUndefined(MessageType[`${label}`]) ? null : MessageType[`${label}`];
  }
}
