import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Gauge} from '@domain/machine/gauge';
import {TuftProduct} from '@domain/textile-data/tuft-product/tuft-product';
import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {ConflictsRepository} from '../conflicts-repository';
import {ExtendedHttpParams} from '../extended-http-params';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';
import {GetAllTuftProductsParameters} from './tuft-products.interface';

@Injectable({providedIn: 'root'})
export class HttpTuftProductsService implements Repository<TuftProduct, OverviewListTuftProduct>, UniqueIdentifierObjectRepository, ConflictsRepository {
  private readonly VARIABLE_NAMES_TO_HTTP_PARAMS_MAP = new Map<string, string>([['showOnlyLatestVersion', 'only-latest-versions']]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(parameters?: Partial<GetAllTuftProductsParameters>): Observable<OverviewListTuftProduct[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}tuft-products`, {params})
      .pipe(map((tuftProductsJSON: any) => tuftProductsJSON.map((tuftProductJSON: any) => OverviewListTuftProduct.fromJSON(tuftProductJSON))));
  }

  public getAllForMachineId(machineId: number, parameters?: Partial<GetAllTuftProductsParameters>): Observable<OverviewListTuftProduct[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}tuft-products/machine-id/${machineId}/compatible`, {params})
      .pipe(map((tuftProductsJSON: any) => tuftProductsJSON.map((tuftProductJSON: any) => OverviewListTuftProduct.fromJSON(tuftProductJSON))));
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}tuft-products/${id}`);
  }

  public getById(id: string | number): Observable<TuftProduct> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tuft-products/${id}`).pipe(map((tuftProductJSON: any) => TuftProduct.fromJSON(tuftProductJSON)));
  }

  public save(item: TuftProduct): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}tuft-products`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: TuftProduct): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}tuft-products/${item.id}`, item.toJSON());
  }

  public getGauges(): Observable<Gauge[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tuft-products/gauges`).pipe(map((gaugesJSON: any) => gaugesJSON.map((gaugeJSON: any) => Gauge.fromJSON(gaugeJSON))));
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}tuft-products/check-name`, {params}).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getConflicts(id: string | number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}tuft-products/${id}/conflicts`)
      .pipe(map((conflictsJSON: any) => conflictsJSON.map((conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }
}
