import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {PropertyValue} from '@domain/property-value';
import {BaseComponent, DOMUtils, EnumUtils, TranslateService, Unit} from '@vdw/angular-component-library';
import {forEach, isEmpty, isEqual, isNil} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {AdvancedSearchInput} from './advanced-search/advanced-search-input.enum';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent extends BaseComponent implements OnInit, AfterViewInit {
  public static OVERFLOW_BUTTON_WIDTH_IN_PX = 44;
  public static OVERFLOW_BUTTON_PADDING_IN_PX = 16;

  @Input() public searchFilters: PropertyValue[] = [];
  @Input() public commercialUnit: Unit;
  @Input() public pickDensityUnit: Unit;
  @Input() public reedDensityUnit: Unit;

  @HostBinding('class.right-to-left') @Input() public showFiltersFromRightToLeft = false;
  @HostBinding('class.has-filters') public get hasFilters(): boolean {
    return this.showOverflowChip || this.searchFilters?.some((propertyValue: PropertyValue, index: number) => this.canShowFilter(propertyValue, index));
  }

  @Output() public searchFiltersChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() public showAdvancedSearch: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('filterContainer') public filterContainer: ElementRef<HTMLElement>;
  public filterOverflowPositionLeft: number;

  public showOverflowChip = false;

  private readonly filterContainerContentChangedSubject = new Subject<void>();
  private lastNonOverflowFilterIndex: number;

  public constructor(
    private readonly translate: TranslateService,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.checkIfFiltersAreTooBigForContainer();
  }

  public ngOnInit(): void {
    this.showOverflowChip = false;
    this.filterContainerContentChangedSubject
      .asObservable()
      .pipe(debounceTime(100), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.checkIfFiltersAreTooBigForContainer());
  }

  public ngAfterViewInit(): void {
    DOMUtils.observeResize(this.filterContainer.nativeElement)
      .pipe(debounceTime(100), takeUntil(this.unSubscribeOnViewDestroy), distinctUntilChanged())
      .subscribe(() => this.checkIfFiltersAreTooBigForContainer());
  }

  public onFilterOverflowClicked(): void {
    this.showAdvancedSearch.emit();
  }

  public canShowFilter(propertyValue: PropertyValue, index: number): boolean {
    return AdvancedSearchUtils.hasValue(propertyValue) && (isNil(this.lastNonOverflowFilterIndex) || index < this.lastNonOverflowFilterIndex);
  }

  public getFilterDisplayText(propertyValue: PropertyValue): string {
    const propertyName: string = this.translate.instant(`GENERAL.ADVANCED_SEARCH.${EnumUtils.getKeyFromValue(AdvancedSearchInput, propertyValue.propertyName)}`, {count: 1});

    const value: string = this.getDisplayValueForAdvancedSearchFilter(propertyValue);
    let text: string;
    if (
      propertyValue.propertyName === AdvancedSearchInput.LENGTH ||
      propertyValue.propertyName === AdvancedSearchInput.WIDTH ||
      propertyValue.propertyName === AdvancedSearchInput.WIDTH_IN_DENTS ||
      propertyValue.propertyName === AdvancedSearchInput.HEIGHT_IN_PICKS
    ) {
      const unitKey = `GENERAL.UNIT.${Unit.getKeyFromValue(this.commercialUnit)}`;
      text = `${propertyName} (${value} ${this.translate.instant(unitKey)})`;
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.PICK_DENSITY)) {
      const unitKey = this.pickDensityUnit;
      text = `${propertyName} (${value} ${this.translate.instant(unitKey)})`;
    } else if (isEqual(propertyValue.propertyName, AdvancedSearchInput.REED_DENSITY)) {
      const unitKey = this.reedDensityUnit;
      text = `${propertyName} (${value} ${this.translate.instant(unitKey)})`;
    } else if (value.length > 10 && isEmpty(propertyValue.possiblePropertyValues)) {
      text = `${propertyName} (${value.substring(0, 10)}...)`;
    } else if (propertyValue.propertyName === AdvancedSearchInput.NOT_IN_GROUP) {
      text = `${propertyName}`;
    } else {
      text = `${propertyName} (${value})`;
    }

    return text;
  }

  public canShowFilterTooltip(propertyValue: PropertyValue): boolean {
    return (
      propertyValue.propertyName !== AdvancedSearchInput.LENGTH && propertyValue.propertyName !== AdvancedSearchInput.WIDTH && this.getDisplayValueForAdvancedSearchFilter(propertyValue).length > 10
    );
  }

  public getFilterTooltip(propertyValue: PropertyValue): string {
    return this.getDisplayValueForAdvancedSearchFilter(propertyValue);
  }

  public clearValue(propertyValue: PropertyValue): void {
    this.showOverflowChip = false;
    this.lastNonOverflowFilterIndex = undefined;
    AdvancedSearchUtils.clearValue(propertyValue);
    this.filterContainerContentChangedSubject.next();
    this.searchFiltersChanged.emit();
  }

  public getFilterOverflowTooltip(): string {
    return this.searchFilters
      ? this.searchFilters
          .slice(this.lastNonOverflowFilterIndex)
          .filter((propertyValue: PropertyValue, index: number) => this.canShowFilter(propertyValue, index))
          .map((propertyValue: PropertyValue) => this.getFilterDisplayText(propertyValue))
          .join(', ')
      : null;
  }

  public emitCheckIfFiltersAreTooBigForContainerEvent(): void {
    this.filterContainerContentChangedSubject.next();
  }

  private checkIfFiltersAreTooBigForContainer(): void {
    this.setFiltersOpacity(0);
    this.showOverflowChip = false;
    this.lastNonOverflowFilterIndex = undefined;

    this.renderer.removeClass(this.filterContainer.nativeElement, 'overflow');
    const maxWidth = this.filterContainer.nativeElement.clientWidth;

    let widthCount = 0;

    this.changeDetectorRef.detectChanges();

    forEach(this.filterContainer.nativeElement.children, (children: HTMLElement, index: number) => {
      widthCount += children.clientWidth;

      if (widthCount > maxWidth) {
        if (this.showFiltersFromRightToLeft) {
          this.renderer.addClass(this.filterContainer.nativeElement, 'overflow');
        } else {
          widthCount -= children.clientWidth;
          this.setFilterOverflowLeftPosition(maxWidth, widthCount, index);
        }

        this.showOverflowChip = true;
        this.changeDetectorRef.detectChanges();

        this.lastNonOverflowFilterIndex =
          widthCount - children.clientWidth + SearchFiltersComponent.OVERFLOW_BUTTON_WIDTH_IN_PX + SearchFiltersComponent.OVERFLOW_BUTTON_PADDING_IN_PX > maxWidth ? index - 1 : index;

        return false;
      }
    });

    this.setFiltersOpacity(1);
  }

  private setFiltersOpacity(opacity: number): void {
    forEach(this.filterContainer.nativeElement.children, (children: HTMLElement, index: number) => {
      if (index > 0 && isEqual(opacity, 0)) {
        this.renderer.setStyle(children, 'opacity', 0);
      } else {
        this.renderer.setStyle(children, 'opacity', 1);
      }
    });
  }

  private getDisplayValueForAdvancedSearchFilter(propertyValue: PropertyValue): string {
    return AdvancedSearchUtils.getDisplayValueForAdvancedSearchFilter(propertyValue.propertyName, propertyValue.propertyValue, this.translate, this.commercialUnit);
  }

  private setFilterOverflowLeftPosition(maxWidth: number, widthCount: number, currentChildElementIndex: number): void {
    if (widthCount + SearchFiltersComponent.OVERFLOW_BUTTON_WIDTH_IN_PX >= maxWidth) {
      for (let i = currentChildElementIndex - 1; i > 0; i--) {
        if (this.filterContainer.nativeElement.children[i].clientWidth > 0) {
          widthCount -= this.filterContainer.nativeElement.children[i].clientWidth;
          break;
        }
      }
      this.filterOverflowPositionLeft = widthCount;
      this.renderer.addClass(this.filterContainer.nativeElement, 'overflow');
    } else {
      this.filterOverflowPositionLeft = widthCount;
    }
  }
}
