import {DensityUnitConversionError} from '../error/density-unit-conversion-error';
import {Unit} from '../unit.enum';
import {convertToDensityUnitDentsPerCentimeter} from './convert-to-density-unit-dents-per-centimeter';
import {convertToDensityUnitDentsPerMeter} from './convert-to-density-unit-dents-per-meter';
import {convertToDensityUnitDentsPerMillimeter} from './convert-to-density-unit-dents-per-millimeter';
import {convertToDensityUnitPicksPerCentimeter} from './convert-to-density-unit-picks-per-centimeter';
import {convertToDensityUnitPicksPerDecimeter} from './convert-to-density-unit-picks-per-decimeter';
import {convertToDensityUnitPicksPerMeter} from './convert-to-density-unit-picks-per-meter';
import {convertToDensityUnitPicksPerMillimeter} from './convert-to-density-unit-picks-per-millimeter';
import {convertToDensityUnitWeftsPerCentimeter} from './convert-to-density-unit-wefts-per-centimeter';
import {convertToDensityUnitWeftsPerDecimeter} from './convert-to-density-unit-wefts-per-decimeter';
import {convertToDensityUnitWeftsPerMeter} from './convert-to-density-unit-wefts-per-meter';
import {convertToDensityUnitWeftsPerMillimeter} from './convert-to-density-unit-wefts-per-millimeter';
import {UnitConversionParameters} from './unit-conversion-parameters';

export function convertDensityUnit(unitConversionParameters: UnitConversionParameters): number {
  let result: number;

  if (unitConversionParameters.from.value === undefined) {
    return undefined;
  } else if (unitConversionParameters.from.value === null) {
    return null;
  }

  switch (unitConversionParameters.to) {
    case Unit.DENTS_PER_METER:
      result = convertToDensityUnitDentsPerMeter(unitConversionParameters.from);
      break;
    case Unit.DENTS_PER_CENTIMETER:
      result = convertToDensityUnitDentsPerCentimeter(unitConversionParameters.from);
      break;
    case Unit.DENTS_PER_MILLIMETER:
      result = convertToDensityUnitDentsPerMillimeter(unitConversionParameters.from);
      break;
    case Unit.PICKS_PER_METER:
      result = convertToDensityUnitPicksPerMeter(unitConversionParameters.from);
      break;
    case Unit.PICKS_PER_DECIMETER:
      result = convertToDensityUnitPicksPerDecimeter(unitConversionParameters.from);
      break;
    case Unit.PICKS_PER_CENTIMETER:
      result = convertToDensityUnitPicksPerCentimeter(unitConversionParameters.from);
      break;
    case Unit.PICKS_PER_MILLIMETER:
      result = convertToDensityUnitPicksPerMillimeter(unitConversionParameters.from);
      break;
    case Unit.WEFTS_PER_METER:
      result = convertToDensityUnitWeftsPerMeter(unitConversionParameters.from);
      break;
    case Unit.WEFTS_PER_DECIMETER:
      result = convertToDensityUnitWeftsPerDecimeter(unitConversionParameters.from);
      break;
    case Unit.WEFTS_PER_CENTIMETER:
      result = convertToDensityUnitWeftsPerCentimeter(unitConversionParameters.from);
      break;
    case Unit.WEFTS_PER_MILLIMETER:
      result = convertToDensityUnitWeftsPerMillimeter(unitConversionParameters.from);
      break;
    default:
      throw new DensityUnitConversionError(`No conversion found from ${unitConversionParameters.from.unit} to ${unitConversionParameters.to}`);
  }

  return result;
}
