export enum AdvancedSearchObjectInput {
  MACHINE_QUALITY = 'quality',
  COLORED_YARN_SET = 'colored_yarn_set',
  COLOR_SET = 'color_set',
  MAIN_COLOR = 'main_color',
  BORDER_COLOR = 'border_color',
  ARTICLE = 'article',
  CREEL = 'creel',
  MACHINE = 'machine',
  WEAVE_STRUCTURE = 'weave_structure',
  MAIN_DESIGN = 'main_design',
  WEFT_COLORED_YARN_SET = 'weft_colored_yarn_set',
  WEAVE_PRODUCT = 'weave_product',
  TUFT_PRODUCT = 'tuft_product',
  PLASTIC_PRODUCT = 'plastic_product',
  TOOL = 'tool'
}
