import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';

export class WeaveProduct {
  private _id: number;
  private _name: string;
  private _numberOfFeeders: number;
  private _numberOfShafts: number;
  private _widthInCm: number;

  public constructor(id: number, name: string, numberOfFeeders: number, numberOfShafts: number, widthInCm: number) {
    this._id = id;
    this._name = name;
    this._numberOfFeeders = numberOfFeeders;
    this._numberOfShafts = numberOfShafts;
    this._widthInCm = widthInCm;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get numberOfFeeders(): number {
    return this._numberOfFeeders;
  }

  public get numberOfShafts(): number {
    return this._numberOfShafts;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public static fromJSON(json: any): WeaveProduct {
    return new WeaveProduct(json.id, json.name, json.numberOfFeeders, json.numberOfShafts, json.widthInCm);
  }

  public static fromOverviewListWeaveProduct(weaveProduct: OverviewListWeaveProduct): WeaveProduct {
    return new WeaveProduct(weaveProduct.id, weaveProduct.name, weaveProduct.numberOfFeeders, weaveProduct.numberOfShafts, weaveProduct.widthInCm);
  }
}
