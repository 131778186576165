import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';

export class FinishingTemplateRoute extends RouteBranch {
  private _addFinishingTemplate: RouteLeaf;
  private _duplicateFinishingTemplate: RouteLeaf;
  private _editFinishingTemplate: RouteLeaf;

  public get addFinishingTemplate(): RouteLeaf {
    return this._addFinishingTemplate;
  }

  public set addFinishingTemplate(value: RouteLeaf) {
    this.replace(this._addFinishingTemplate, value);
    this._addFinishingTemplate = value;
  }

  public get duplicateFinishingTemplate(): RouteLeaf {
    return this._duplicateFinishingTemplate;
  }

  public set duplicateFinishingTemplate(value: RouteLeaf) {
    this.replace(this._duplicateFinishingTemplate, value);
    this._duplicateFinishingTemplate = value;
  }

  public get editFinishingTemplate(): RouteLeaf {
    return this._editFinishingTemplate;
  }

  public set editFinishingTemplate(value: RouteLeaf) {
    this.replace(this._editFinishingTemplate, value);
    this._editFinishingTemplate = value;
  }
}
