import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';

export class WeaveProduct {
  private _id: number;
  private _name: string;
  private _version: number;
  private _widthInCm: number;
  private _pickDensityInPicksPerCm: number;
  private _standardSpeedInRotationsPerMinute: number;
  private _standardEfficiencyInPercentage: number;
  private _numberOfFeeders: number;
  private _numberOfShafts: number;
  private _description?: string;
  private _used: boolean;

  public constructor(
    id: number,
    name: string,
    version: number,
    widthInCm: number,
    pickDensityInPicksPerCm: number,
    standardSpeedInRotationsPerMinute: number,
    standardEfficiencyInPercentage: number,
    numberOfFeeders: number,
    numberOfShafts: number,
    description: string,
    used: boolean
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._widthInCm = widthInCm;
    this._pickDensityInPicksPerCm = pickDensityInPicksPerCm;
    this._standardSpeedInRotationsPerMinute = standardSpeedInRotationsPerMinute;
    this._standardEfficiencyInPercentage = standardEfficiencyInPercentage;
    this._numberOfFeeders = numberOfFeeders;
    this._numberOfShafts = numberOfShafts;
    this._description = description;
    this._used = used;
  }

  public static fromOverviewListWeaveProduct(overviewListWeaveProduct: OverviewListWeaveProduct): WeaveProduct {
    return new WeaveProduct(
      overviewListWeaveProduct.id,
      overviewListWeaveProduct.name,
      overviewListWeaveProduct.version,
      overviewListWeaveProduct.widthInCm,
      overviewListWeaveProduct.pickDensityInPicksPerCm,
      overviewListWeaveProduct.standardSpeedInRotationsPerMinute,
      overviewListWeaveProduct.standardEfficiencyInPercentage,
      overviewListWeaveProduct.numberOfFeeders,
      overviewListWeaveProduct.numberOfShafts,
      null,
      null
    );
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get widthInCm(): number {
    return this._widthInCm;
  }

  public get pickDensityInPicksPerCm(): number {
    return this._pickDensityInPicksPerCm;
  }

  public get standardSpeedInRotationsPerMinute(): number {
    return this._standardSpeedInRotationsPerMinute;
  }

  public get standardEfficiencyInPercentage(): number {
    return this._standardEfficiencyInPercentage;
  }

  public get numberOfFeeders(): number {
    return this._numberOfFeeders;
  }

  public get numberOfShafts(): number {
    return this._numberOfShafts;
  }

  public get description(): string {
    return this._description;
  }

  public get used(): boolean {
    return this._used;
  }

  public static fromJSON(weaveProductJSON: any): WeaveProduct {
    return new WeaveProduct(
      weaveProductJSON.id,
      weaveProductJSON.name,
      weaveProductJSON.version,
      weaveProductJSON.widthInCm,
      weaveProductJSON.pickDensityInPicksPerCm,
      weaveProductJSON.standardSpeedInRotationsPerMinute,
      weaveProductJSON.standardEfficiencyInPercentage,
      weaveProductJSON.numberOfFeeders,
      weaveProductJSON.numberOfShafts,
      weaveProductJSON.description,
      weaveProductJSON.used
    );
  }

  public static createEmptyWeaveProduct(): WeaveProduct {
    return new WeaveProduct(null, null, null, null, null, null, null, null, null, null, null);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version,
      widthInCm: this._widthInCm,
      pickDensityInPicksPerCm: this._pickDensityInPicksPerCm,
      standardSpeedInRotationsPerMinute: this._standardSpeedInRotationsPerMinute,
      standardEfficiencyInPercentage: this._standardEfficiencyInPercentage,
      numberOfFeeders: this._numberOfFeeders,
      numberOfShafts: this._numberOfShafts,
      description: this._description,
      used: this._used
    } as any as JSON;
  }
}
