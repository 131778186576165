import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {ProfileInfo} from '@domain/profile/profile-info';
import {Versionable} from '@domain/profile/versionable';
import {VersionableType} from '@domain/profile/versionable-type';
import {VERSION} from '@environments/version';
import {Profile} from '@infrastructure/http/profile/profile';
import {each, sortBy} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {createCancelRequestContext} from '../cancel-request-context';
import {CompanySubscriptions} from './company-subscriptions';

@Injectable({
  providedIn: 'root'
})
export class HttpProfileService implements Profile {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public getCreditsAmount(): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getAuthenticationEndpoint()}vdwuser/credits`).pipe(
      rxjsMap((creditsJSON: any) => {
        return creditsJSON.remainingCredits;
      })
    );
  }

  public getProfileInfo(): Observable<ProfileInfo> {
    return this.httpClient.get(`${this.backendConfiguration.getAuthenticationEndpoint()}vdwuser/profile`).pipe(rxjsMap((profileInfoJSON: any) => ProfileInfo.fromJSON(profileInfoJSON)));
  }

  public getSubscriptions(shouldCancelRequest: boolean = true): Observable<CompanySubscriptions> {
    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}vdwuser/companysubscriptions`, {
        context: createCancelRequestContext(shouldCancelRequest)
      })
      .pipe(rxjsMap((companySubscriptionsJSON: any) => CompanySubscriptions.fromJSON(companySubscriptionsJSON)));
  }

  public getVersionables(): Observable<Versionable[]> {
    const versionEndpointUrl = 'version';
    const versionables: Versionable[] = [
      {
        type: VersionableType.CLIENT,
        version: VERSION.version
      }
    ];
    const versionablesSubject = new BehaviorSubject<Versionable[]>(versionables);

    each(
      [
        {
          type: VersionableType.TEXTILE,
          url: `${this.backendConfiguration.getEndpoint()}${versionEndpointUrl}`
        },
        {
          type: VersionableType.MACHINE,
          url: `${this.backendConfiguration.getMachineOverviewEndpoint()}${versionEndpointUrl}`
        },
        {
          type: VersionableType.PRODUCTION,
          url: `${this.backendConfiguration.getProductionOrderEndpoint()}${versionEndpointUrl}`
        },
        {
          type: VersionableType.WORKER,
          url: `${this.backendConfiguration.getWorkerEndpoint()}${versionEndpointUrl}`
        }
      ],
      ({url, type}: {url: string; type: VersionableType}) => {
        this.httpClient.get<{version: string}>(url).subscribe((res: {version: string}) => {
          if (type === VersionableType.WORKER) {
            versionables.push({type: VersionableType.MESSAGING, version: res.version});
          }

          versionables.push({type, version: res.version});
          versionablesSubject.next(sortBy(versionables, 'type'));
        });
      }
    );

    return versionablesSubject;
  }

  public getExternalApplicationKey(): Observable<string> {
    return this.httpClient.get(`${this.backendConfiguration.getAuthenticationEndpoint()}vdwuser/external-application-key`).pipe(
      rxjsMap((externalApplicationKeyJSON: any) => {
        return externalApplicationKeyJSON.applicationKey;
      })
    );
  }

  public signTermsAndConditions(): Observable<void> {
    return this.httpClient.post<void>(`${this.backendConfiguration.getAuthenticationEndpoint()}vdwuser/sign-terms-and-conditions`, {});
  }
}
