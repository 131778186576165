import {PlaceholderComponent} from './placeholder-component';

export class PlaceholderResponse {
  public editedPlaceholderParts: PlaceholderComponent[];
  public filteredEditedPlaceholderParts: PlaceholderComponent[];

  public constructor(editedPlaceholderParts: PlaceholderComponent[], filteredEditedPlaceholderParts: PlaceholderComponent[]) {
    this.editedPlaceholderParts = editedPlaceholderParts;
    this.filteredEditedPlaceholderParts = filteredEditedPlaceholderParts;
  }
}
