import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {ColDefBuilderFactoryService, GridInputComponent, GridOptionsBuilderFactoryService, Message, MessageType, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {CellClickedEvent, ColDef, GridApi, GridOptions, GridReadyEvent, IRowNode, RowHeightParams, RowNode, RowSelectedEvent} from 'ag-grid-community';
import {cloneDeep, each, flatMap, isEmpty, isEqual, isNil, isUndefined, map, remove, some} from 'lodash-es';

@Component({
  selector: 'app-placeholder-parts',
  templateUrl: './placeholder-parts.component.html',
  styleUrls: ['./placeholder-parts.component.scss']
})
export class PlaceholderPartsComponent implements OnInit, OnChanges {
  @ViewChild('placeholderPartsGrid') public placeholderPartsGrid: AgGridAngular;
  @Input() public listOfPlaceholderCategoriesWithComponents: PlaceholderCategory[];
  @Input() public placeholderParts: PlaceholderComponent[] = [];
  @Input() public filteredPlaceholderParts: PlaceholderComponent[] = [];
  @Input() public separatorForTextPlaceholder: string;
  @Input() public headerTitle: string;
  @Input() public specifyTitle: string;
  @Input() public columnTitle: string;
  @Input() public requiredMaxLength: boolean;
  @Input() public filterText: string;
  @Input() public isSerialNumberAllowedFreePosition: boolean;
  @Output() public confirm = new EventEmitter<{placeholderParts: PlaceholderComponent[]; filteredPlaceholderParts: PlaceholderComponent[]}>();
  @Output() public cancelled = new EventEmitter<{placeholderParts: PlaceholderComponent[]; filteredPlaceholderParts: PlaceholderComponent[]}>();
  public customTextValue = '';
  public editedPlaceholderParts: PlaceholderComponent[] = [];
  public gridOptionsForPlaceholderParts: GridOptions;
  public filteredEditedPlaceholderParts: PlaceholderComponent[] = [];
  public labelFromFilteredEditedPlaceholderComponents: string;
  public errorMessage: Message[];
  public serialNumberValueErrorMessage: Message[];

  private rowData: PlaceholderComponent[] = [];
  private placeholderPartsWithError: PlaceholderComponent[] = [];
  private serialNumberTypes = [
    {
      name: 'numeric serial number',
      translationKey: 'GENERAL.PLACEHOLDER.NUMERIC_SERIAL_NUMBER',
      regex: '^\\d+$'
    },
    {
      name: 'alphabetic serial number',
      translationKey: 'GENERAL.PLACEHOLDER.ALPHABETIC_SERIAL_NUMBER',
      regex: '^[A-Z]+$'
    },
    {
      name: 'alphanumeric serial number',
      translationKey: 'GENERAL.PLACEHOLDER.ALPHANUMERIC_SERIAL_NUMBER',
      regex: '^[A-Z0-9]+$'
    }
  ];

  private readonly serialNumberKey = 'serial number';
  private readonly startTranslationKey = 'GENERAL.START';

  public constructor(
    private readonly translate: TranslateService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService
  ) {}

  public readonly placeholderPartLabelGetter: (placeholderPart: PlaceholderComponent) => string = (placeholderPart: PlaceholderComponent): string => placeholderPart.name;
  public readonly placeholderPartErrorPredicate = (placeholderPart: PlaceholderComponent): boolean => {
    const index = this.filteredEditedPlaceholderParts.findIndex((filteredEditedPlaceholderPart: PlaceholderComponent) => filteredEditedPlaceholderPart === placeholderPart);
    return !this.isSerialNumberAllowedFreePosition && placeholderPart.categoryName?.includes(this.serialNumberKey) && index !== 0 && index !== this.filteredEditedPlaceholderParts.length - 1;
  };

  public ngOnInit(): void {
    this.setGridOptionsForPlaceholderParts();
    this.editedPlaceholderParts = !this.placeholderParts ? [] : cloneDeep(this.placeholderParts);
    this.filteredEditedPlaceholderParts = !this.filteredPlaceholderParts ? [] : cloneDeep(this.filteredPlaceholderParts);
    this.errorMessage = [new Message(null, this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_SEQUENCE_ERROR'), MessageType.ERROR, null)];

    this.setLabelFromOrderedPlaceholderComponents();
    this.setFilteredEditedPlaceholderParts();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('filterText' in changes && !changes.filterText.isFirstChange()) {
      this.filterPlaceholderComponents(this.filterText);
    }
  }

  public filterPlaceholderComponents(event: string): void {
    this.placeholderPartsGrid.api.setGridOption('quickFilterText', event);
  }

  public removePlaceholderPart(placeholderComponent: PlaceholderComponent): void {
    remove(this.filteredEditedPlaceholderParts, placeholderComponent);
    remove(this.editedPlaceholderParts, placeholderComponent);

    const rowNodeForPlaceholderPart = this.placeholderPartsGrid.api.getRowNode(`${placeholderComponent.id}`);

    if (!isUndefined(rowNodeForPlaceholderPart)) {
      rowNodeForPlaceholderPart.setSelected(false);
    }
    this.setLabelFromOrderedPlaceholderComponents();
  }

  public canAddCustomText(): boolean {
    return !isEmpty(this.customTextValue);
  }

  public addCustomText(): void {
    const placeholderComponent = new PlaceholderComponent(null, this.customTextValue);
    this.updatePlaceholderParts(placeholderComponent, true);
    this.customTextValue = '';
  }

  public hasPlaceholderPartsSpecified(): boolean {
    return !isEmpty(this.editedPlaceholderParts);
  }

  public confirmSpecifiedPlaceholderParts(): void {
    each(this.listOfPlaceholderCategoriesWithComponents, (placeholderCategory: PlaceholderCategory) => {
      each(placeholderCategory.placeholderComponents, (placeholderComponent: PlaceholderComponent) => {
        const editededPlaceholderPart: PlaceholderComponent = this.editedPlaceholderParts.find((editedPlaceholder: PlaceholderComponent) => editedPlaceholder.id === placeholderComponent.id);
        if (!isNil(editededPlaceholderPart)) {
          placeholderComponent.maxLength = editededPlaceholderPart.maxLength;
        }
      });
    });

    this.confirm.emit({placeholderParts: this.editedPlaceholderParts, filteredPlaceholderParts: this.filteredEditedPlaceholderParts});
  }

  public cancel(): void {
    each(this.listOfPlaceholderCategoriesWithComponents, (placeholderCategory: PlaceholderCategory) => {
      each(placeholderCategory.placeholderComponents, (placeholderComponent: PlaceholderComponent) => {
        const placeholderPart: PlaceholderComponent = this.placeholderParts.find((placeholder: PlaceholderComponent) => placeholder.id === placeholderComponent.id);
        if (!isNil(placeholderPart)) {
          placeholderComponent.maxLength = placeholderPart.maxLength;
        }
      });
    });
    this.cancelled.emit({placeholderParts: this.placeholderParts, filteredPlaceholderParts: this.filteredPlaceholderParts});
  }

  public getFilteredEditedPlaceholderParts(): PlaceholderComponent[] {
    return this.filteredEditedPlaceholderParts;
  }

  public dropPlaceholderComponent(): void {
    const editList: PlaceholderComponent[] = [];
    this.filteredEditedPlaceholderParts.forEach((placeholderComponent: PlaceholderComponent) => {
      if (placeholderComponent.configurableValue) {
        const placeholderWithSameCategoryAndConfigIsTrue: PlaceholderComponent[] = this.editedPlaceholderParts.filter(
          (placeholder: PlaceholderComponent) => placeholder.categoryName === placeholderComponent.categoryName
        );
        editList.push(...placeholderWithSameCategoryAndConfigIsTrue);
      } else {
        editList.push(placeholderComponent);
      }
    });
    this.editedPlaceholderParts = editList;
    this.setLabelFromOrderedPlaceholderComponents();
  }

  public getTitle(): string {
    return !isNil(this.specifyTitle) ? this.specifyTitle : 'GENERAL.PLACEHOLDER.PLACEHOLDER_PARTS_SPECIFY';
  }

  public canShowErrorMessage(): boolean {
    const indexes = this.filteredEditedPlaceholderParts
      .map((filteredEditedPlaceholderPart: PlaceholderComponent, index: number) => {
        if (filteredEditedPlaceholderPart.categoryName?.includes(this.serialNumberKey)) {
          return index;
        }
      })
      .filter((index: number) => index !== undefined);

    return !this.isSerialNumberAllowedFreePosition && indexes.some((index: number) => index !== 0 && index !== this.filteredEditedPlaceholderParts.length - 1);
  }

  public canShowSerialNumberValueErrorMessage(): boolean {
    let result = false;
    this.serialNumberValueErrorMessage = [];
    this.placeholderPartsWithError = [];
    this.serialNumberTypes.forEach((type: any) => {
      const serialNumberParts = this.editedPlaceholderParts.filter((placeholderComponent: PlaceholderComponent) => placeholderComponent.categoryName === type.name);
      if (serialNumberParts.length > 0) {
        const start = serialNumberParts[0];
        const max = serialNumberParts[1];
        const translatedSerialNumberKey = this.translate.instant(type.translationKey).toLowerCase();
        if (!start?.value) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(start);
          this.serialNumberValueErrorMessage.push(
            new Message(
              null,
              this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_REQUIRED_VALUE_ERROR', {value: this.translate.instant(this.startTranslationKey), serialNumberType: translatedSerialNumberKey}),
              MessageType.ERROR,
              null
            )
          );
        }
        if (!max?.value) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(max);
          this.serialNumberValueErrorMessage.push(
            new Message(
              null,
              this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_REQUIRED_VALUE_ERROR', {
                value: this.translate.instant('GENERAL.VALIDATION.MAXIMUM_LENGTH'),
                serialNumberType: translatedSerialNumberKey
              }),
              MessageType.ERROR,
              null
            )
          );
        }
        if (start?.value?.toString().length > Number(max?.value)) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(start);
          this.serialNumberValueErrorMessage.push(
            new Message(null, this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_START_EXCEEDS_MAX_ERROR', {serialNumberType: translatedSerialNumberKey}), MessageType.ERROR, null)
          );
        }
        if (type.name === 'numeric serial number' && Number(start?.value) > BackendLimitsConstants.INT32_MAX) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(start);
          this.serialNumberValueErrorMessage.push(
            new Message(
              null,
              this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_EXCEEDED_INT_MAX_ERROR', {
                value: this.translate.instant(this.startTranslationKey).toLowerCase(),
                serialNumberType: translatedSerialNumberKey,
                maxValue: BackendLimitsConstants.INT32_MAX
              }),
              MessageType.ERROR,
              null
            )
          );
        }
        if (Number(max?.value) > BackendLimitsConstants.INT32_MAX) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(max);
          this.serialNumberValueErrorMessage.push(
            new Message(
              null,
              this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_EXCEEDED_INT_MAX_ERROR', {
                value: this.translate.instant('GENERAL.VALIDATION.MAXIMUM_LENGTH').toLowerCase(),
                serialNumberType: translatedSerialNumberKey,
                maxValue: BackendLimitsConstants.INT32_MAX
              }),
              MessageType.ERROR,
              null
            )
          );
        }
        if (!new RegExp(type.regex).test(start?.value?.toString())) {
          result = true;
          this.addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(start);
          this.serialNumberValueErrorMessage.push(
            new Message(
              null,
              this.translate.instant('GENERAL.PLACEHOLDER.SERIAL_NUMBER_INCORRECT_FORMAT_ERROR', {
                value: this.translate.instant(this.startTranslationKey).toLowerCase(),
                serialNumberType: translatedSerialNumberKey
              }),
              MessageType.ERROR,
              null
            )
          );
        }
      }
    });

    return result;
  }

  private addPlaceholderPartToErrorListIfItDoesNotAlreadyExist(placeholderPart: PlaceholderComponent): void {
    if (!this.verifyIfRowIsHavingAnError(placeholderPart)) {
      this.placeholderPartsWithError.push(placeholderPart);
    }
  }

  private verifyIfRowIsHavingAnError(placeholderPart: PlaceholderComponent): boolean {
    return this.placeholderPartsWithError.filter((component: PlaceholderComponent) => component.categoryName === placeholderPart.categoryName && component.name === placeholderPart.name).length > 0;
  }

  private setLabelFromOrderedPlaceholderComponents(): void {
    this.labelFromFilteredEditedPlaceholderComponents = PlaceholderComponent.getLabelFromPlaceholderComponents(this.filteredEditedPlaceholderParts, this.separatorForTextPlaceholder);
  }

  private setGridOptionsForPlaceholderParts(): void {
    this.gridOptionsForPlaceholderParts = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefs(), GridIdentifier.PLACEHOLDER_PARTS, this.getRowData())
      .withOnGridReady(({api}: GridReadyEvent) => this.onGridReadyEvent(api))
      .withOnRowSelected((event: RowSelectedEvent) => this.onRowSelected(event))
      .withRowSelection(true, false, true)
      .withGroupDisplayType('groupRows')
      .withSuppressRowClickSelection()
      .withAutoGroupColumnDef(this.colDefBuilderFactory.getBuilder().withField('categoryName').withCellRenderer('agGroupCellRenderer', {checkbox: false}).withSuppressMovable().build(), false)
      .withSingleClickEdit()
      .withNoRowsOverlay({
        titleParam: this.headerTitle,
        hideDescription: true
      })
      .withLoadingOverlay()
      .withTabToNextCell()
      .withRowClass('capitalize')
      .withGroupRowRendererParams({suppressCount: true})
      .withoutMovableColumns()
      .withoutSorting()
      .withGetRowHeight((params: RowHeightParams) => (params.node.group ? 32 : 80))
      .build();
  }

  private onGridReadyEvent(gridApi: GridApi): void {
    this.editedPlaceholderParts.forEach((placeholderPart: PlaceholderComponent) => {
      const rowNode: IRowNode<PlaceholderComponent> = gridApi.getRowNode(`${placeholderPart.id}`);
      if (!isUndefined(rowNode)) {
        rowNode.setSelected(true);
        const placeholderComponent = rowNode.data;
        placeholderComponent.maxLength = placeholderPart.maxLength;
        placeholderComponent.value = placeholderPart.value;
      }
    });
  }

  private getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('categoryName').withHide(true).withRowGroup(true).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name', true)
        .withHeaderName(this.columnTitle ? this.columnTitle : 'GENERAL.PLACEHOLDER.PLACEHOLDER_NAME')
        .withOnCellClicked((event: CellClickedEvent) => event.node.setSelected(!event.node.isSelected()))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withCellRenderer(GridInputComponent, (params: any) => {
          return {
            canShowInput: (placeholderComponent: PlaceholderComponent): boolean => {
              return some(this.editedPlaceholderParts, ['id', placeholderComponent.id]);
            },
            canShowRequiredError: (placeholderComponent: PlaceholderComponent, value: number | string): boolean => {
              return (
                (placeholderComponent.configurableValue || this.requiredMaxLength) &&
                some(this.editedPlaceholderParts, ['id', placeholderComponent.id]) &&
                (value === '' || !value || this.verifyIfRowIsHavingAnError(placeholderComponent))
              );
            },
            value: params.data.configurableValue ? params.data.value : params.data.maxLength,
            updateValue: (placeholderComponent: PlaceholderComponent, value: number | string): void => {
              this.updatePlaceholderComponentMaxLengthAndValue(placeholderComponent, value);
            },
            minValue: 1
          };
        })
        .withPinned(false)
        .withMaxWidth(150)
        .withCellClass('cell-no-x-padding')
        .withoutFilter()
        .build()
    ];
  }

  private getRowData(): PlaceholderComponent[] {
    this.rowData = flatMap(this.listOfPlaceholderCategoriesWithComponents, (placeholderCategory: PlaceholderCategory) => {
      return map(placeholderCategory.placeholderComponents, (placeholderComponent: PlaceholderComponent) => {
        placeholderComponent.categoryName = placeholderCategory.name;
        return placeholderComponent;
      });
    });

    return this.rowData;
  }

  private onRowSelected(event: RowSelectedEvent): void {
    const placeholderComponent: PlaceholderComponent = event.data;

    this.updatePlaceholderParts(placeholderComponent, event.node.isSelected());

    if (!event.node.isSelected()) {
      this.placeholderPartsGrid.api.applyTransaction({update: [placeholderComponent]});
    }

    setTimeout(() => this.placeholderPartsGrid.api.sizeColumnsToFit());
  }

  private updatePlaceholderParts(placeholderComponent: PlaceholderComponent, selected: boolean): void {
    if (selected) {
      if (isNil(placeholderComponent.id) || !some(this.editedPlaceholderParts, ['id', placeholderComponent.id])) {
        this.addPlaceholderParts(placeholderComponent);
      }
    } else {
      this.removePlaceholderParts(placeholderComponent);
    }
    this.setLabelFromOrderedPlaceholderComponents();
  }

  private addPlaceholderParts(placeholderComponent: PlaceholderComponent): void {
    if (placeholderComponent.configurableValue) {
      this.setSelectedChildNode(placeholderComponent.categoryName, true);
      this.addAllPlaceholdelderPartsOfCategory(placeholderComponent.categoryName);
    } else {
      this.editedPlaceholderParts.push(placeholderComponent);
      this.filteredEditedPlaceholderParts.push(placeholderComponent);
    }
  }

  private addAllPlaceholdelderPartsOfCategory(category: string): void {
    const placeholderComponentToBeAdded = this.rowData.filter((placeholderComponent: PlaceholderComponent) => placeholderComponent.categoryName === category);
    this.editedPlaceholderParts.push(...placeholderComponentToBeAdded);
    this.filteredEditedPlaceholderParts.push(placeholderComponentToBeAdded[0]);
  }

  private removePlaceholderParts(placeholderComponent: PlaceholderComponent): void {
    if (placeholderComponent.configurableValue && this.editedPlaceholderParts.find((placeholder: PlaceholderComponent) => placeholder.categoryName === placeholderComponent.categoryName)) {
      this.removeAllPlaceholderPartsOfSameCategorie(placeholderComponent.categoryName);
      this.setSelectedChildNode(placeholderComponent.categoryName, false);
    } else {
      remove(this.editedPlaceholderParts, ['id', placeholderComponent.id]);
      remove(this.filteredEditedPlaceholderParts, ['id', placeholderComponent.id]);
    }
  }

  private removeAllPlaceholderPartsOfSameCategorie(categoryName: string): void {
    remove(this.editedPlaceholderParts, ['categoryName', categoryName]);
    remove(this.filteredEditedPlaceholderParts, ['categoryName', categoryName]);
  }

  private setSelectedChildNode(categoryName: string, setSelected: boolean): void {
    this.placeholderPartsGrid.api.forEachNode((node: RowNode) => {
      if (isEqual(node.key, categoryName)) {
        each(node.allLeafChildren, (child: RowNode) => {
          child.setSelected(setSelected);
        });
      }
    });
  }

  private updatePlaceholderComponentMaxLengthAndValue(placeholderComponent: PlaceholderComponent, value: number | string): void {
    this.updateAdjustedPlaceholderPartMaxLengthAndValueFromSpecifiedList(this.editedPlaceholderParts, placeholderComponent, value);
    this.updateAdjustedPlaceholderPartMaxLengthAndValueFromSpecifiedList(this.filteredEditedPlaceholderParts, placeholderComponent, value);

    this.setLabelFromOrderedPlaceholderComponents();
  }

  private updateAdjustedPlaceholderPartMaxLengthAndValueFromSpecifiedList(placeholderParts: PlaceholderComponent[], placeholderComponent: PlaceholderComponent, value: number | string): void {
    const editedPlaceholderComponent: PlaceholderComponent = placeholderParts.find((placeholder: PlaceholderComponent) => placeholder.id === placeholderComponent.id);
    if (editedPlaceholderComponent) {
      if (placeholderComponent.configurableValue) {
        editedPlaceholderComponent.value = value;
        placeholderComponent.value = value;
      } else {
        editedPlaceholderComponent.maxLength = Number(value);
        placeholderComponent.maxLength = Number(value);
      }
    }
  }

  private setFilteredEditedPlaceholderParts(): void {
    if (this.placeholderParts) {
      const filteredPlaceholderParts: PlaceholderComponent[] = [];
      this.placeholderParts.forEach((placeholderComponent: PlaceholderComponent) => {
        const categoryName = placeholderComponent.categoryName;
        if (!filteredPlaceholderParts.find((placeholder: PlaceholderComponent) => placeholder.categoryName === categoryName)) {
          filteredPlaceholderParts.push(placeholderComponent);
        } else {
          if (!placeholderComponent.configurableValue) {
            filteredPlaceholderParts.push(placeholderComponent);
          }
        }
      });
      this.filteredEditedPlaceholderParts = filteredPlaceholderParts;
    }
  }
}
