import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {canActivatePermissionRedirectGuard} from '@application/security/can-activate-permission-redirect.guard';
import {Permission} from '@domain/profile/permission.enum';
import {OrderbookOverviewPageComponent} from '@presentation/pages/texfab/orderbook/overview/orderbook-overview-page.component';
import {OrderbookCustomSettingsComponent} from './settings/orderbook-custom-settings.component';

const shared = {parentId: NavigationId.ORDERBOOK_MODULE};

export const orderbookRoutes = [
  Factory.createPrototypeComponentRoute(OrderbookOverviewPageComponent, {route: '', requiredPermission: null, id: NavigationId.ORDERBOOK, ...shared}),
  {
    path: 'settings',
    component: OrderbookCustomSettingsComponent,
    canActivate: [canActivatePermissionRedirectGuard],
    data: {
      id: NavigationId.ORDERBOOK_SETTINGS,
      ...shared,
      permissionsToCheck: [Permission.TEXFAB_ORDERBOOK, Permission.ACCESS_ALL],
      redirectCandidateRoutes: [
        {
          route: 'texfab/orderbook',
          requiredPermission: Permission.TEXFAB_ORDERBOOK,
          isMobileFriendly: false
        }
      ]
    }
  }
];

export const orderbookDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(orderbookRoutes);
