import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {convertCommercialUnit, Unit} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {isUndefined, round, toNumber} from 'lodash-es';
import {OverviewListYarnType} from '../../yarn-type/overview/overview-list-yarn-type';

@Component({
  templateUrl: './grid-yarn-consumption.component.html',
  styleUrls: ['./grid-yarn-consumption.component.scss']
})
export class GridYarnConsumptionComponent implements ICellRendererAngularComp, AfterViewChecked {
  @ViewChild('name') public name: ElementRef;
  public creelPositionForYarnTypes: OverviewListCreelPosition;
  public showTooltip = false;
  public yarnTypes: OverviewListYarnType[];
  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }

  public agInit(params: any): void {
    this.yarnTypes = params.value.items;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public getYarnConsumptionCommercialLengthInMeters(yarnType: OverviewListYarnType): string {
    return `${round(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: toNumber(yarnType.yarnConsumption.commercialLengthInMM)}, to: Unit.METER}), 3)}m`;
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.name) && this.name.nativeElement.offsetWidth < this.name.nativeElement.scrollWidth;
  }
}
