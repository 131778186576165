import {IdName} from '@domain/id-name';
import {IdNameVersion} from '@domain/id-name-version';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';

export class OverviewListTuftProductionOrder {
  private _id: number;
  private _name: string;
  private _status: ProductionScheduleStatus;
  private _tuftProduct: IdNameVersion;
  private _machine: IdName;
  private _plannedQuantityInMeters: number;
  private _tuftingNotes: string;
  private _dateCreated: Date;
  private _dueDate: Date;
  private _actualStartDate: Date;
  private _actualEndDate: Date;

  public constructor(
    id: number,
    name: string,
    status: ProductionScheduleStatus,
    tuftProduct: IdNameVersion,
    machine: IdName,
    plannedQuantityInMeters: number,
    tuftingNotes: string,
    dateCreated: Date,
    dueDate: Date,
    actualStartDate: Date,
    actualEndDate: Date
  ) {
    this._id = id;
    this._name = name;
    this._status = status;
    this._tuftProduct = tuftProduct;
    this._machine = machine;
    this._plannedQuantityInMeters = plannedQuantityInMeters;
    this._tuftingNotes = tuftingNotes;
    this._dateCreated = dateCreated;
    this._dueDate = dueDate;
    this._actualStartDate = actualStartDate;
    this._actualEndDate = actualEndDate;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get tuftProduct(): IdNameVersion {
    return this._tuftProduct;
  }

  public get machine(): IdName {
    return this._machine;
  }

  public get plannedQuantityInMeters(): number {
    return this._plannedQuantityInMeters;
  }

  public get tuftingNotes(): string {
    return this._tuftingNotes;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public get actualStartDate(): Date {
    return this._actualStartDate;
  }

  public get actualEndDate(): Date {
    return this._actualEndDate;
  }

  public static fromJSON(overviewListTuftProductionOrderJSON: any): OverviewListTuftProductionOrder {
    return new OverviewListTuftProductionOrder(
      overviewListTuftProductionOrderJSON.id,
      overviewListTuftProductionOrderJSON.name,
      overviewListTuftProductionOrderJSON.status,
      IdNameVersion.fromJSON(overviewListTuftProductionOrderJSON.tuftProduct),
      overviewListTuftProductionOrderJSON.machine ? IdName.fromJSON(overviewListTuftProductionOrderJSON.machine) : null,
      overviewListTuftProductionOrderJSON.plannedQuantityInMeters,
      overviewListTuftProductionOrderJSON.tuftingNotes,
      new Date(overviewListTuftProductionOrderJSON.creationDate),
      overviewListTuftProductionOrderJSON.dueDate ? new Date(overviewListTuftProductionOrderJSON.dueDate) : null,
      overviewListTuftProductionOrderJSON.actualStartDate ? new Date(overviewListTuftProductionOrderJSON.actualStartDate) : null,
      overviewListTuftProductionOrderJSON.actualEndDate ? new Date(overviewListTuftProductionOrderJSON.actualEndDate) : null
    );
  }

  public canDuplicate(): boolean {
    return true;
  }
}
