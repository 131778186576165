import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './tooltip-cell-renderer.component.html'
})
export class TooltipCellRendererComponent implements ICellRendererAngularComp {
  public tooltip: string;

  public constructor() {}

  public agInit(params: ICellRendererParams<any, any, any>): void {
    this.tooltip = params.value;
  }

  public refresh(_: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
