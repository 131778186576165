import {Component, Input} from '@angular/core';
import {Moment} from 'moment';

@Component({
  selector: 'vdw-shift',
  template: ``
})
export class ShiftComponent {
  @Input()
  public start: string;

  @Input()
  public end: string;

  @Input()
  public name: string;

  public getStartOnDate(date: Moment): Moment {
    const [startHours, startMinutes] = this.getTimeAsNumeric(this.start);
    return this.getDateWithTime(date, startHours, startMinutes);
  }

  public getEndOnDate(date: Moment): Moment {
    const [endHours, endMinutes] = this.getTimeAsNumeric(this.end);
    return this.getDateWithTime(date, endHours, endMinutes);
  }

  public getTimeAsNumeric(time: string): [number, number] {
    const [hours, minutes] = time.split(':').map((s: string) => Number(s));
    return [hours, minutes];
  }

  private getDateWithTime(date: Moment, hours: number, minutes: number): Moment {
    return date.clone().set('hours', hours).set('minutes', minutes);
  }
}
