export class MachineForOverviewListProductionSchedule {
  private _id: number;
  private _name: string;
  private _remoteMachine: boolean;

  public constructor(id: number, name: string, remoteMachine: boolean) {
    this._id = id;
    this._name = name;
    this._remoteMachine = remoteMachine;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get remoteMachine(): boolean {
    return this._remoteMachine;
  }

  public static fromJSON(machineForOverviewListProductionScheduleJSON: any): MachineForOverviewListProductionSchedule {
    return new MachineForOverviewListProductionSchedule(
      machineForOverviewListProductionScheduleJSON.id,
      machineForOverviewListProductionScheduleJSON.name,
      machineForOverviewListProductionScheduleJSON.remoteMachine
    );
  }
}
