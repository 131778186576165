import {replace, toLower} from 'lodash-es';

export enum OrderLineStatus {
  TO_PLAN = 'TO_PLAN',
  PLANNED = 'PLANNED',
  PRODUCED = 'PRODUCED',
  PARTIALLY_PLANNED = 'PARTIALLY_PLANNED'
}

export namespace OrderLineStatus {
  export function getOrderLineStatusClass(orderLineStatus: OrderLineStatus): string {
    return replace(toLower(`${OrderLineStatus[orderLineStatus]}`), '_', '-');
  }
}
