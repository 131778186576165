import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {filter, pairwise, takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {Point} from '../../common/interfaces/point';
import {PlanningGridComponent} from '../planning-display/planning-grid/planning-grid.component';
import {PlanningGroupComponent} from '../planning-items/planning-group/planning-group.component';
import {PlanningItemDirective} from '../planning-items/planning-item/planning-item.directive';
import {PlanningRowComponent} from '../planning-row/planning-row.component';
import {DragDropData} from './planning-drag-drop-data';
import {PlanningDragDropService} from './planning-drag-drop.service';

@Directive({
  selector: '[vdwPlanningDragDrop]'
})
export class PlanningDragDropDirective extends BaseComponent implements OnInit, OnDestroy {
  private currentDisplayElement: ElementRef<HTMLElement>;
  private isNestedItem = false;

  @Input()
  public set isItemDraggable(isDraggable: boolean) {
    const draggedItem = this.planningItem?.data ?? this.planningGroup?.data;
    draggedItem.draggable = isDraggable;
  }

  @Input()
  public newGroupText: string;

  @Output()
  public readonly dragEnd = new EventEmitter<DragDropData>();

  @Output()
  public readonly dragMove = this.dragDropService.drag.pipe(
    takeUntil(this.unSubscribeOnViewDestroy),
    filter((eventData: DragDropData) => eventData.draggedItem === (this.planningItem?.data ?? this.planningGroup?.data))
  );

  @Output()
  public readonly dragStart = new EventEmitter<DragDropData>();

  public constructor(
    @Optional() private readonly planningItem: PlanningItemDirective,
    @Optional() private readonly planningGroup: PlanningGroupComponent,
    private readonly dragDropService: PlanningDragDropService,
    private readonly planningRow: PlanningRowComponent,
    private readonly planningGrid: PlanningGridComponent
  ) {
    super();
    this.isNestedItem = this.planningItem != null && this.planningGroup != null;
  }

  public ngOnInit(): void {
    const draggedItem = this.planningItem?.data ?? this.planningGroup?.data;
    const changesObservable = draggedItem.displayElementChanges;

    changesObservable.pipe(takeUntil(this.unSubscribeOnViewDestroy), pairwise()).subscribe(([previousDisplayElement, currentDisplayElement]: ElementRef<HTMLElement>[]) => {
      this.removeItemListeners(previousDisplayElement);
      this.addItemListeners(currentDisplayElement);
    });

    this.dragDropService.dragEnd
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        filter((data: DragDropData) => data.draggedItem === (this.planningItem?.data ?? this.planningGroup?.data))
      )
      .subscribe((data: DragDropData) => this.onDragEnd(data));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeItemListeners(this.currentDisplayElement);
  }

  private removeItemListeners(element: ElementRef<HTMLElement>): void {
    if (element?.nativeElement == null) {
      return;
    }

    element.nativeElement.removeEventListener('dragstart', this.onDragStart);
    element.nativeElement.draggable = false;
  }

  private addItemListeners(element: ElementRef<HTMLElement>): void {
    if (element?.nativeElement == null) {
      return;
    }
    element.nativeElement.addEventListener('dragstart', this.onDragStart);
    element.nativeElement.draggable = true;
    this.currentDisplayElement = element;
  }

  private onDragStart = (event: DragEvent): void => {
    const draggedItem = this.planningItem?.data ?? this.planningGroup?.data;

    if (!draggedItem.draggable) {
      event.preventDefault();
      return;
    }

    const indicatorWidthPx = this.currentDisplayElement.nativeElement.clientWidth;
    const startMousePositionInContainer: Point = {
      x: this.currentDisplayElement.nativeElement.offsetLeft + this.planningGrid.ROW_INDICATOR_WIDTH + event.offsetX + 2,
      y: this.currentDisplayElement.nativeElement.offsetTop + event.offsetY
    };

    if (this.isNestedItem) {
      startMousePositionInContainer.x += this.planningGroup.data.displayElement.nativeElement.offsetLeft;
      startMousePositionInContainer.y += this.planningGroup.data.displayElement.nativeElement.offsetTop;

      this.planningGroup.data.displayElement.nativeElement.style.opacity = '0.5';
    } else {
      this.currentDisplayElement.nativeElement.style.opacity = '0';
    }

    const dragData = this.dragDropService.onDragStart(
      event,
      draggedItem,
      this.planningGroup?.data,
      this.planningRow.data,
      indicatorWidthPx,
      startMousePositionInContainer,
      this.newGroupText,
      this.planningGrid
    );

    this.dragStart.emit(dragData);
  };

  private onDragEnd(eventData: DragDropData): void {
    if (!this.isNestedItem) {
      this.currentDisplayElement.nativeElement.style.opacity = '1';
    } else {
      this.planningGroup.data.displayElement.nativeElement.style.opacity = '1';
    }

    this.dragEnd.emit(eventData);
  }
}
