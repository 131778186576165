export enum ProductionScheduleCombinedStatus {
  CREATE_BUSY_DRAFT = 'Create Busy Draft',
  CREATE_BUSY_CREATING = 'Create Busy Creating',
  CREATE_WARNING_VERIFIED_WITH_WARNINGS = 'Create Warning Verified With Warnings',
  CREATE_WARNING_TO_RECONFIRM = 'Create Warning To Reconfirm',
  CREATE_FAILURE_VERIFIED_WITH_ERRORS = 'Create Failure Verified With Errors',
  CREATE_COMPLETE_VERIFIED = 'Create Complete Verified',
  CREATE_COMPLETE_CANCELLED = 'Create Complete Cancelled',
  CREATE_COMPLETE_HIDDEN = 'Create Complete Hidden',

  CONFIRM_BUSY_PROCESSING = 'Confirm Busy Processing',
  CONFIRM_BUSY_PROCESSED = 'Confirm Busy Processed',
  CONFIRM_BUSY_TRANSLATING = 'Confirm Busy Translating',
  CONFIRM_BUSY_CREATING = 'Confirm Busy Creating',

  CONFIRM_COMPLETE_TRANSLATED = 'Confirm Complete Translated',
  CONFIRM_COMPLETE_CREATED = 'Confirm Complete Created',

  CONFIRM_FAILURE_PROCESSING = 'Confirm Failure Processing',
  CONFIRM_FAILURE_TRANSLATING = 'Confirm Failure Translating',
  CONFIRM_FAILURE_CREATED = 'Confirm Failure Created',

  SEND_BUSY_SENDING = 'Send Busy Sending',
  SEND_FAILURE_SENDING = 'Send Failure Sending',
  SEND_COMPLETE_SENT = 'Send Complete Sent',

  PLAN_BUSY_PLANNING = 'Plan Busy Planning',
  PLAN_FAILURE_PLANNING = 'Plan Failure Planning',
  PLAN_COMPLETE_PLANNED = 'Plan Complete Planned',

  REPLAN_BUSY_REPLANNING = 'Replan Busy Replanning',
  REPLAN_COMPLETE_REPLANNED = 'Replan Complete Replanned',
  REPLAN_WARNING_REPLANNED_WITH_WARNINGS = 'Replan Warning Replanned With Warnings',
  REPLAN_FAILURE_REPLANNING = 'Replan Failure Replanning',

  PRODUCE_BUSY_PRODUCING = 'Produce Busy Producing',
  PRODUCE_COMPLETE_PRODUCED_COMPLETELY = 'Produce Complete Produced Completely',
  PRODUCE_WARNING_PRODUCED_PARTIALLY = 'Produce Warning Produced Partially',
  PRODUCE_WARNING_NO_PRODUCTION_INFORMATION_RECEIVED = 'Produce Warning No Production Information Received'
}
