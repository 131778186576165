import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class TuftProductRoute extends RouteBranch {
  private _addTuftProduct: RouteLeaf;
  private _editTuftProduct: RouteLeaf;
  private _duplicateTuftProduct: RouteLeaf;

  public get addTuftProduct(): RouteLeaf {
    return this._addTuftProduct;
  }

  public set addTuftProduct(value: RouteLeaf) {
    this.replace(this._addTuftProduct, value);
    this._addTuftProduct = value;
  }

  public get editTuftProduct(): RouteLeaf {
    return this._editTuftProduct;
  }

  public set editTuftProduct(value: RouteLeaf) {
    this.replace(this._editTuftProduct, value);
    this._editTuftProduct = value;
  }

  public get duplicateTuftProduct(): RouteLeaf {
    return this._duplicateTuftProduct;
  }

  public set duplicateTuftProduct(value: RouteLeaf) {
    this.replace(this._duplicateTuftProduct, value);
    this._duplicateTuftProduct = value;
  }
}
