import {OrderLine} from '@domain/orderbook/order-line';
import {OrderLineAdditionalInfo} from '@domain/orderbook/order-line-additional-info';
import {OrderLineStatus} from '@domain/orderbook/order-line-status';
import {OverviewListArticleForOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-article-for-order-line';
import {OverviewListOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-order-line';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {moment, Priority} from '@vdw/angular-component-library';
import {Drawing} from './drawing';

export class ProductionScheduleOrderLine extends OverviewListOrderLine {
  private _amountInProductionSchedule: number;
  private _heightInProductionScheduleInMM: number;

  public constructor(
    id: number,
    salesOrderReference: string,
    salesOrderLineReference: string,
    customerReference: string,
    desiredDate: Date,
    priority: Priority,
    article: OverviewListArticleForOrderLine,
    amount: number,
    amountLeft: number,
    amountProduced: number,
    amountPlanned: number,
    isSelectable: boolean,
    status: OrderLineStatus,
    orderType: string,
    canBePlanned: boolean,
    missingDesign: boolean,
    additionalInfo: OrderLineAdditionalInfo[],
    commercialLengthInMM?: number
  ) {
    super(
      id,
      salesOrderReference,
      salesOrderLineReference,
      customerReference,
      desiredDate,
      priority,
      article,
      amount,
      amountLeft,
      amountProduced,
      amountPlanned,
      isSelectable,
      status,
      orderType,
      canBePlanned,
      missingDesign,
      additionalInfo,
      commercialLengthInMM
    );

    this._amountInProductionSchedule = 0;
    this._heightInProductionScheduleInMM = 0;
  }

  public get amountInProductionSchedule(): number {
    return this._amountInProductionSchedule;
  }

  public set amountInProductionSchedule(amountInProductionSchedule: number) {
    this._amountInProductionSchedule = amountInProductionSchedule;
  }

  public get heightInProductionScheduleInMM(): number {
    return this._heightInProductionScheduleInMM;
  }

  public set heightInProductionScheduleInMM(heightInProductionScheduleInMM: number) {
    this._heightInProductionScheduleInMM = heightInProductionScheduleInMM;
  }

  public static fromOrderLine(orderLine: OrderLine, coloredYarnSet: OverviewListColoredYarnSet): ProductionScheduleOrderLine {
    return new ProductionScheduleOrderLine(
      orderLine.id,
      orderLine.salesOrderReference,
      orderLine.salesOrderLineReference,
      orderLine.customerReference,
      orderLine.desiredDate,
      orderLine.priority,
      new OverviewListArticleForOrderLine(
        orderLine.article.id,
        orderLine.article.name,
        orderLine.article.machineQuality,
        coloredYarnSet,
        orderLine.article.colorConfiguration.colorSet,
        null,
        null,
        orderLine.article.finishing,
        orderLine.article.commercialWidthInMM,
        orderLine.article.commercialHeightInMM,
        orderLine.article.design as Drawing
      ),
      orderLine.amount,
      orderLine.amount - orderLine.amountProduced,
      orderLine.amountProduced,
      orderLine.amountPlanned,
      true,
      orderLine.status,
      null,
      true,
      false,
      orderLine.additionalInfo,
      orderLine.commercialLengthInMM
    );
  }

  public static fromJSON(orderLineJSON: any): ProductionScheduleOrderLine {
    return new ProductionScheduleOrderLine(
      orderLineJSON.id,
      orderLineJSON.salesOrderReference,
      orderLineJSON.salesOrderLineReference,
      orderLineJSON.customerReference,
      moment(orderLineJSON.desiredDate).add(new Date().getTimezoneOffset(), 'minutes').toDate(),
      Priority[orderLineJSON.priority as string],
      OverviewListArticleForOrderLine.fromJSON(orderLineJSON.article),
      orderLineJSON.amount,
      orderLineJSON.amountLeft,
      orderLineJSON.amountProduced,
      orderLineJSON.amountPlanned,
      true,
      OrderLineStatus[`${orderLineJSON.status}`],
      orderLineJSON.orderType,
      orderLineJSON.canBePlanned ?? true,
      !!orderLineJSON.missingDesign,
      Object.keys(orderLineJSON).reduce((additionalInfo: OrderLineAdditionalInfo[], key: string) => {
        if (key.startsWith('additionalInfo')) {
          additionalInfo.push(new OrderLineAdditionalInfo(key, orderLineJSON[key]));
        }
        return additionalInfo;
      }, []),
      orderLineJSON.commercialLengthInMM
    );
  }

  public static fromOverviewListOrderLine(overviewListOrderLine: OverviewListOrderLine): ProductionScheduleOrderLine {
    return new ProductionScheduleOrderLine(
      overviewListOrderLine.id,
      overviewListOrderLine.salesOrderReference,
      overviewListOrderLine.salesOrderLineReference,
      overviewListOrderLine.customerReference,
      overviewListOrderLine.desiredDate,
      overviewListOrderLine.priority,
      overviewListOrderLine.article,
      overviewListOrderLine.amount,
      overviewListOrderLine.amountLeft,
      overviewListOrderLine.amountProduced,
      overviewListOrderLine.amountPlanned,
      overviewListOrderLine.isSelectable,
      overviewListOrderLine.status,
      overviewListOrderLine.orderType,
      overviewListOrderLine.canBePlanned,
      overviewListOrderLine.missingDesign,
      overviewListOrderLine.additionalInfo,
      overviewListOrderLine.commercialLengthInMM
    );
  }

  public isCompleted(): boolean {
    return this.amountLeft === this._amountInProductionSchedule * 2;
  }

  public isOverplanned(): boolean {
    return this.amountLeft < this._amountInProductionSchedule * 2;
  }

  public getAmountLeftForProductionSchedule(itemMultiplier: number = 2): number {
    return this.amountLeft - this._amountInProductionSchedule * itemMultiplier;
  }

  public getHeightLeftInMMForProductionSchedule(itemMultiplier: number = 1): number {
    return this.commercialLengthInMM - this._heightInProductionScheduleInMM * itemMultiplier;
  }
}
