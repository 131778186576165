import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {ColorStubFactory} from '@vdw/angular-component-library';

export class OverviewListColorSetStubFactory {
  public static getRandomColorSetOverviewListWithId(id: number): OverviewListColorSet {
    return this.getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(id, 5);
  }

  public static getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(id: number, numberOfCreelPositions: number): OverviewListColorSet {
    const result = new OverviewListColorSet(id, `Color_Set_${id}`, []);

    let colorId = 0;

    for (let i = 0; i < numberOfCreelPositions; i++) {
      result.creelPositions.push(
        new OverviewListCreelPosition(
          [
            ColorStubFactory.getRandomColorWithIdAndColorCode(colorId++, '#1aceff'),
            ColorStubFactory.getRandomColorWithIdAndColorCode(colorId++, '#9867bb'),
            ColorStubFactory.getRandomColorWithIdAndColorCode(colorId++, '#ff1c1c')
          ],
          i + 1
        )
      );
    }

    return result;
  }

  public static getRandomOverviewListColorSet(id: number): OverviewListColorSet {
    const result = new OverviewListColorSet(id, `Color_Set_${id}`, []);

    result.creelPositions.push(
      new OverviewListCreelPosition(
        [
          ColorStubFactory.getRandomColorWithIdAndColorCode(1, '#1aceff'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(2, '#9867bb'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(3, '#ff1c1c')
        ],
        0,
        50,
        true
      )
    );

    result.creelPositions.push(
      new OverviewListCreelPosition(
        [
          ColorStubFactory.getRandomColorWithIdAndColorCode(1, '#1aceff'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(2, '#9867bb'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(3, '#ff1c1c')
        ],
        1,
        25,
        false
      )
    );

    result.creelPositions.push(
      new OverviewListCreelPosition(
        [
          ColorStubFactory.getRandomColorWithIdAndColorCode(1, '#1aceff'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(2, '#9867bb'),
          ColorStubFactory.getRandomColorWithIdAndColorCode(3, '#ff1c1c')
        ],
        2,
        25,
        false
      )
    );

    return result;
  }
}
