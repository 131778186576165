import {getTotalRunningTimeInMsForDateRange} from '@application/helper/planning/get-total-running-time-in-milliseconds-for-date-range';
import {DateRange} from '@domain/date-range';
import {UuidUtils} from '@vdw/angular-component-library';

export abstract class CalendarItem {
  protected _id: number;
  protected _startDate: Date;
  protected _endDate: Date;
  protected _uuid: string;

  public constructor(id: number, startDate: Date, endDate: Date) {
    this._id = id;
    this._startDate = startDate;
    this._endDate = endDate;

    this.updateUuid();
  }

  public get id(): number {
    return this._id;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public set startDate(startDate: Date) {
    this._startDate = startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public set endDate(endDate: Date) {
    this._endDate = endDate;
  }

  public get uuid(): string {
    return this._uuid;
  }

  public getTotalRunningTimeInMsForDateRange(dateRange: DateRange): number {
    return getTotalRunningTimeInMsForDateRange<CalendarItem>(this, dateRange);
  }

  protected updateUuid(): void {
    this._uuid = UuidUtils.generateV4Uuid();
  }
}
