import {ControlValueAccessor} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {BaseComponent} from '../../base-component';

export class ControlValueAccessorHelper<T> extends BaseComponent implements ControlValueAccessor {
  public disabled: boolean;
  public stateChanges: Subject<void> = new Subject<void>();
  protected formControl: ControlValueAccessor & MatFormFieldControl<T>;

  protected onChange: (value: T) => void;
  protected onTouched: () => void;

  private _value: T;

  public get value(): T {
    return this._value;
  }

  public set value(v: T) {
    this._value = v;
    this.stateChanges.next();
  }

  public writeValue(value: any): void {
    this._value = value;
    if (this.formControl) {
      this.formControl.writeValue(value);
    }
  }

  public updateValue(v: T): void {
    this.onChange(v);
    this.onTouched();
    this.stateChanges.next();
  }

  public deleteValue(): void {
    this.onChange(null);
    this.onTouched();
    this.stateChanges.next();
  }

  public registerOnChange(fn: any): void {
    if (this.formControl) {
      this.formControl.registerOnChange(fn);
    } else {
      this.onChange = fn;
    }
  }

  public registerOnTouched(fn: any): void {
    if (this.formControl) {
      this.formControl.registerOnChange(fn);
    } else {
      this.onTouched = fn;
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (this.formControl) {
      this.formControl.setDisabledState(isDisabled);
    } else {
      this.disabled = isDisabled;
    }
  }
}
