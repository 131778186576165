import {Injectable} from '@angular/core';
import {ILogger, LogLevel} from '@microsoft/signalr';
import {NGXLogger} from 'ngx-logger';

@Injectable({providedIn: 'root'})
export class SignalrLogger implements ILogger {
  private _logger: NGXLogger;

  public constructor(logger: NGXLogger) {
    this._logger = logger;
  }

  public log(logLevel: LogLevel, message: string): void {
    switch (logLevel) {
      case LogLevel.Critical:
      case LogLevel.Error:
        this._logger.error(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
        break;
      case LogLevel.Warning:
        this._logger.warn(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
        break;
      default:
        break;
    }
  }
}
