import {Equipment} from '@domain/machine/equipment';
import {MachineGroup} from './machine-group';
import {MachineType} from './machine-type.enum';
import {WeavingMachineType} from './weaving-machine-type';

export class WeavingMachine extends Equipment {
  private _machineGroup: MachineGroup;
  private _standardSpeed: number;
  private _standardEfficiency: number;
  private _numberOfFeeders: number;
  private _numberOfShafts: number;
  private _weavingMachineType: WeavingMachineType;

  public constructor(
    id: number,
    name: string,
    orderId: string,
    machineGroup: MachineGroup,
    standardSpeed: number,
    standardEfficiency: number,
    numberOfFeeders: number,
    numberOfShafts: number,
    weavingMachineType: WeavingMachineType
  ) {
    super(id, MachineType.WEAVING_MACHINE, name, orderId);
    this._machineGroup = machineGroup;
    this._standardSpeed = standardSpeed;
    this._standardEfficiency = standardEfficiency;
    this._numberOfFeeders = numberOfFeeders;
    this._numberOfShafts = numberOfShafts;
    this._weavingMachineType = weavingMachineType;
  }

  public static fromJSON(weavingMachineJSON: any): WeavingMachine {
    return new WeavingMachine(
      weavingMachineJSON.id,
      weavingMachineJSON.name,
      weavingMachineJSON.orderId,
      MachineGroup.fromJSON(weavingMachineJSON.machineGroup),
      weavingMachineJSON.standardSpeed,
      weavingMachineJSON.standardEfficiency,
      weavingMachineJSON.numberOfFeeders,
      weavingMachineJSON.numberOfShafts,
      weavingMachineJSON.weavingMachineType === undefined ? null : WeavingMachineType.fromJSON(weavingMachineJSON.weavingMachineType)
    );
  }

  public toJSON(): JSON {
    const weavingMachineJSON = super.toJSON();
    weavingMachineJSON['standardSpeed'] = this.standardSpeed;
    weavingMachineJSON['standardEfficiency'] = this.standardEfficiency;
    weavingMachineJSON['numberOfFeeders'] = this.numberOfFeeders;
    weavingMachineJSON['numberOfShafts'] = this.numberOfShafts;
    weavingMachineJSON['machineGroupId'] = this.machineGroup?.id;
    weavingMachineJSON['machineGroup'] = this.machineGroup?.toJSON();
    weavingMachineJSON['weavingMachineTypeId'] = this.weavingMachineType?.id;

    return weavingMachineJSON;
  }

  public get machineGroup(): MachineGroup {
    return this._machineGroup;
  }

  public set machineGroup(machineGroup: MachineGroup) {
    this._machineGroup = machineGroup;
  }

  public get standardSpeed(): number {
    return this._standardSpeed;
  }

  public set standardSpeed(standardSpeed: number) {
    this._standardSpeed = standardSpeed;
  }

  public get standardEfficiency(): number {
    return this._standardEfficiency;
  }

  public set standardEfficiency(standardEfficiency: number) {
    this._standardEfficiency = standardEfficiency;
  }

  public get numberOfFeeders(): number {
    return this._numberOfFeeders;
  }

  public set numberOfFeeders(numberOfFeeders: number) {
    this._numberOfFeeders = numberOfFeeders;
  }

  public get numberOfShafts(): number {
    return this._numberOfShafts;
  }

  public set numberOfShafts(numberOfShafts: number) {
    this._numberOfShafts = numberOfShafts;
  }

  public get weavingMachineType(): WeavingMachineType {
    return this._weavingMachineType;
  }

  public set weavingMachineType(weavingMachineType: WeavingMachineType) {
    this._weavingMachineType = weavingMachineType;
  }
}
