<mat-sidenav-container class="mat-sidenav-container" [hasBackdrop]="false">
  <mat-sidenav
    class="mat-sidenav"
    mode="over"
    position="end"
    [autoFocus]="false"
    [opened]="planningSidebarManager.opened"
    [ngClass]="['sidebar', planningSidebarManager.size]"
    (openedChange)="planningSideBars.onSidebarOpenedChange(!$event)"
  >
    <app-planning-sidebars [class.onboarding-running]="planningContext.isOnboardingRunning" [sidebarManager]="planningSidebarManager"></app-planning-sidebars>
  </mat-sidenav>
  <mat-sidenav-content class="overflow-hidden">
    <mat-card class="full-height flex-column">
      <vdw-header [title]="'PLANNING.PLANNING' | bmsTranslate" svgIcon="planning-blue">
        <vdw-dialog-button
          *ngIf="showMachineFilterButton()"
          #machineFilterSettingsButton
          (dialogDataChanged)="onMachineFilterChanged($event)"
          [componentType]="machineFilterSettingsComponent"
          [dialogData]="getMachineFilterDialogData()"
        >
          <button mat-icon-button class="mat-icon-action-button-lg-with-border" [class.open]="machineFilterSettingsButton.open" (click)="machineFilterSettingsButton.toggleDialog()">
            <mat-icon svgIcon="filter"></mat-icon>
          </button>
        </vdw-dialog-button>
        <button
          mat-icon-button
          class="mat-icon-action-button-lg-with-border needs-attention"
          [class.onboarding-running]="planningContext.isOnboardingRunning"
          (click)="planningSideBars.openNeedsAttentionOrders()"
          *ngIf="canShowOpenNeedsAttentionOrders()"
        >
          <mat-icon svgIcon="warning"></mat-icon>
        </button>
      </vdw-header>
      <mat-divider></mat-divider>
      <mat-card-content class="overflow-hidden full-height">
        <article *ngIf="loadingMachines" class="full-width full-height flex-column flex-center">
          <vdw-loading-overlay [hideDescription]="false" [scale]="1"> </vdw-loading-overlay>
        </article>
        <article *ngIf="canShowNoDataOverlay()" class="full-width full-height flex-column flex-center">
          <vdw-nodata-overlay titleParam="MACHINE.MACHINE" [scale]="1" [hideDescription]="true"></vdw-nodata-overlay>
        </article>
        <article *ngIf="!loadingMachines" class="full-height full-width">
          <div class="period-selection" [class.onboarding-running]="planningContext.isOnboardingRunning">
            <app-period-selection [selectedDateRange]="selectedDateRange" (selectedDateRangeChanged)="selectedDateRangeChanged($event)"></app-period-selection>
          </div>
          <div class="planning-display-container">
            <app-planning-display-content [selectedDateRange]="selectedDateRange" [fallbackMachineGroupId]="fallbackMachineGroupId" [regimes]="regimes"></app-planning-display-content>
          </div>
        </article>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
