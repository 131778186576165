<div class="status-bar" [ngClass]="statusBarClass" appDataCy="toast"></div>

<div fxLayout="row" [fxLayoutAlign]="canShowActionButton() ? 'space-between top' : 'space-between center'" fxLayoutGap="16px">
  <mat-icon [svgIcon]="titleIcon" class="message-icon"></mat-icon>

  <div class="content" fxFlex>
    <div class="font-weight-semibold" *ngIf="title">{{ title | bmsTranslate }}</div>
    <div class="message" [innerHTML]="message"></div>
    <div class="toolbar" *ngIf="canShowActionButton()">
      <button mat-flat-button (click)="triggerAction()">{{ buttonText | bmsTranslate }}</button>
    </div>
  </div>

  <mat-icon svgIcon="close" class="delete-icon" (click)="dismiss()" appDataCy="toast-delete-button"></mat-icon>
</div>
