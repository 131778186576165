import {DOCUMENT} from '@angular/common';
import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PathLayoutTemplatePath} from '@domain/path-layout-template/path-layout-template-path';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {HttpPathLayoutTemplatesService} from '@infrastructure/http/path-layout-template/http-path-layout-templates.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  convertToCommercialUnitCentimeter,
  DialogBuilderFactoryService,
  DialogType,
  FormValidationHelper,
  SaveType,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {debounceTime, EMPTY, finalize, merge, Observable, switchMap, takeUntil} from 'rxjs';

@Component({
  selector: 'app-add-path-layout-template',
  templateUrl: './add-path-layout-template.component.html',
  styleUrls: ['./add-path-layout-template.component.scss']
})
export class AddPathLayoutTemplateComponent extends BaseComponent implements OnInit {
  @ViewChild('container') public pathWidthContainer: ElementRef<HTMLElement>;

  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_PATH_LAYOUT_TEMPLATE;
  public readonly PATH_WIDTH_MIN_VALUE = 1;
  public readonly PATH_WIDTH_MAX_VALUE = BackendLimitsConstants.INT32_MAX;

  public addPathLayoutTemplateForm: FormGroup<{
    name: FormControl<string>;
    loomGroup: FormControl<string>;
    paths: FormArray<FormGroup<{width: FormControl<number>}>>;
  }>;

  public isLoadingPathLayoutTemplate = false;
  public dragging = false;
  public suggestedPathLayoutTemplateName: string;

  private readonly PATH_LAYOUT_TEMPLATE_TRANSLATION_KEY = 'PATH_LAYOUT_TEMPLATE.PATH_LAYOUT_TEMPLATE';
  private readonly URL_TO_PATH_LAYOUT_TEMPLATE_OVERVIEW = RouteUtils.paths.texFab.pathLayoutTemplate.absolutePath;
  private readonly PATH_TO_EDIT_PATH_LAYOUT_TEMPLATE = RouteUtils.paths.texFab.pathLayoutTemplate.editPathLayoutTemplate.path;
  private readonly PATH_TO_ADD_PATH_LAYOUT_TEMPLATE = RouteUtils.paths.texFab.pathLayoutTemplate.addPathLayoutTemplate.path;

  private saveButtonTouched = false;

  private readonly CLASS_NAME_FOR_DRAGGED_ELEMENT = 'dragging';
  private dragPreviewElement: HTMLElement;
  private dragPreviewElementDOMRect: DOMRect;
  private readonly classNameForDropIndicator = 'drop-indicator';
  private readonly dragPreviewElementWidth = 280;
  private pathWidthItemDropIndicator: HTMLElement;
  private draggedPathWidthIndex: number;
  private classNameForPathWidthItem = 'path-width-item';
  private baseElementForDragPreview: HTMLElement;
  private isOnBottomHalfOfRow: boolean;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly translate: TranslateService,
    private readonly navigationHelperService: NavigationHelperService<PathLayoutTemplate>,
    private readonly formBuilder: FormBuilder,
    private readonly pathLayoutTemplates: HttpPathLayoutTemplatesService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly renderer: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setFormFields();
    this.initialisePathLayoutTemplate();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.saveState(this.getPathLayoutTemplateToSave());
  }

  public getActionText(): string {
    return this.translate.instant(this.isEditingPathLayoutTemplate() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', {
      object: this.translate.instant(this.PATH_LAYOUT_TEMPLATE_TRANSLATION_KEY, {count: 1}).toLowerCase()
    });
  }

  public navigateBack(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.URL_TO_PATH_LAYOUT_TEMPLATE_OVERVIEW);
  }

  public addPathWidth(width: number = null): void {
    this.addPathLayoutTemplateForm.controls.paths.push(
      this.formBuilder.group({
        width: this.formBuilder.control(width, [Validators.required, Validators.min(this.PATH_WIDTH_MIN_VALUE), Validators.max(this.PATH_WIDTH_MAX_VALUE)])
      })
    );
  }

  public removePathWidth(index: number): void {
    this.addPathLayoutTemplateForm.controls.paths.removeAt(index);
  }

  public getPathWidthFormArray(): FormArray<
    FormGroup<{
      width: FormControl<number>;
    }>
  > {
    return this.addPathLayoutTemplateForm.controls.paths;
  }

  public savePathLayoutTemplate(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.addPathLayoutTemplateForm], this.document);

    if (isValid) {
      const pathLayoutTemplateToSave = this.getPathLayoutTemplateToSave();
      this.saving = true;

      const request: Observable<void | number> = this.isEditingPathLayoutTemplate()
        ? this.pathLayoutTemplates.update(pathLayoutTemplateToSave)
        : this.pathLayoutTemplates.save(pathLayoutTemplateToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) =>
          this.textileService.navigateAndShowToast(
            saveType,
            TextileDataType.PATH_LAYOUT_TEMPLATE,
            this.PATH_LAYOUT_TEMPLATE_TRANSLATION_KEY,
            this.isEditingPathLayoutTemplate(),
            pathLayoutTemplateToSave.name,
            id
          ),
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = Object.values(this.addPathLayoutTemplateForm.controls).some((control: FormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public isPathWidthsTitleInvalid(): boolean {
    const pathWidthsForm = this.addPathLayoutTemplateForm.controls.paths;

    if (pathWidthsForm.controls.length > 0) {
      const arePathWidthsFormControlsInvalid = pathWidthsForm.controls.some((formGroup: FormGroup) => formGroup.touched && formGroup.invalid);

      return pathWidthsForm.touched && arePathWidthsFormControlsInvalid;
    }

    return pathWidthsForm.touched;
  }

  public isEditingPathLayoutTemplate(): boolean {
    return this.activatedRoute?.snapshot?.routeConfig?.path === this.PATH_TO_EDIT_PATH_LAYOUT_TEMPLATE;
  }

  public isAddingPathLayoutTemplate(): boolean {
    return this.activatedRoute?.snapshot?.routeConfig?.path === this.PATH_TO_ADD_PATH_LAYOUT_TEMPLATE;
  }

  public duplicatePathLayoutTemplate(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.pathLayoutTemplate.duplicatePathLayoutTemplate.absolutePath.replace(':id', this.activatedRoute.snapshot.params.id));
  }

  public deletePathLayoutTemplate(): void {
    this.textileService.removeConfirmation(this.getPathLayoutTemplateToSave(), TextileDataType.PATH_LAYOUT_TEMPLATE, false, null, this.pathLayoutTemplates);
  }

  public hasEditPermission(): boolean {
    return this.authentication.getCurrentSubscription().hasPermission(LastModifiedCardUtils.getPermissionToModifyItems('pathLayoutTemplate'));
  }

  public onPathWidthDragOver(event: any, pathWidthIndex: number): void {
    event.preventDefault();
    const target: HTMLElement = event.target as HTMLElement;

    const draggedPathWidthItemDifferentFromTargetItem = pathWidthIndex !== this.draggedPathWidthIndex;
    const isMouseOnBottomHalfOfTarget = this.isMouseOnBottomHalfOfRow(target.getBoundingClientRect(), event.y);
    const isMouseOnBottomHalfOfPreviousItem = isMouseOnBottomHalfOfTarget && pathWidthIndex === this.draggedPathWidthIndex - 1;
    const isMouseOnTopHalfOfNextItem = !isMouseOnBottomHalfOfTarget && pathWidthIndex === this.draggedPathWidthIndex + 1;

    if (draggedPathWidthItemDifferentFromTargetItem && !isMouseOnBottomHalfOfPreviousItem && !isMouseOnTopHalfOfNextItem) {
      this.addPathWidthItemDropIndicator(target, event.y);
    }
  }

  public onPathWidthDrag(event: DragEvent): void {
    this.dragging = true;
    if (event.screenX !== 0 && event.screenY !== 0) {
      this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${event.x + 5}px, ${event.y - this.dragPreviewElementDOMRect.height / 2}px, 0)`);
    }
  }

  public onDraggedPathWidthLeavesPathWidthItem(event: any): void {
    event.preventDefault();

    if (AssertionUtils.isNullOrUndefined(event.relatedTarget) || !(event.relatedTarget as HTMLElement).classList.contains(this.classNameForPathWidthItem)) {
      this.removePathWidthItemDropIndicator();
    }
  }

  public onDraggedPathWidthDroppedOnPathWidthItem(event: any, pathWidthIndex: number): void {
    event.preventDefault();

    const currentGroup = this.getPathWidthFormArray().at(this.draggedPathWidthIndex);
    this.getPathWidthFormArray().removeAt(this.draggedPathWidthIndex);

    if (this.draggedPathWidthIndex < pathWidthIndex && !this.isOnBottomHalfOfRow) {
      this.getPathWidthFormArray().insert(pathWidthIndex - 1, currentGroup);
    } else {
      this.getPathWidthFormArray().insert(pathWidthIndex, currentGroup);
    }
  }

  public onDragStart(event: DragEvent, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, pathWidthIndex: number): void {
    this.dragPreviewElementDOMRect = this.createDragPreviewElement(event, baseElementForDragPreview, containerElement, pathWidthIndex);
    this.baseElementForDragPreview = baseElementForDragPreview;

    this.renderer.setStyle(baseElementForDragPreview, 'opacity', 0.5);
    this.renderer.setStyle(this.dragPreviewElement, 'width', `${this.dragPreviewElementWidth}px`);
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.removeStyle(this.dragPreviewElement, 'max-width');
    this.renderer.removeStyle(this.dragPreviewElement, 'min-width');
    this.renderer.appendChild(document.body, this.dragPreviewElement);
    this.draggedPathWidthIndex = pathWidthIndex;

    event.stopPropagation();
  }

  public onDragEnd(): void {
    this.removeDragPreviewElement();
    this.removePathWidthItemDropIndicator();
    this.renderer.setStyle(this.baseElementForDragPreview, 'opacity', 1);
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.dragging = false;
  }

  public canShowSuggestionForPathLayoutTemplateName(): boolean {
    return this.suggestedPathLayoutTemplateName?.length > 0 && this.addPathLayoutTemplateForm.get('name').value !== this.suggestedPathLayoutTemplateName;
  }

  public applySuggestionForPathLayoutTemplateName(): void {
    this.addPathLayoutTemplateForm.get('name').setValue(this.suggestedPathLayoutTemplateName);
  }

  public navigateToCustomSettings(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.pathLayoutTemplate.pathLayoutTemplateSettings.absolutePath);
  }

  private removeDragPreviewElement(): void {
    if (!AssertionUtils.isNullOrUndefined(this.dragPreviewElement)) {
      this.renderer.removeChild(this.dragPreviewElement.parentElement, this.dragPreviewElement);
      this.renderer.removeClass(document.querySelector(`.${this.CLASS_NAME_FOR_DRAGGED_ELEMENT}`), this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
      this.dragPreviewElement = null;
    }
  }

  private createDragPreviewElement(event: any, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, pathWidthIndex: number): DOMRect {
    const dragPreviewElementDOMRect = baseElementForDragPreview.getBoundingClientRect();
    this.dragPreviewElement = baseElementForDragPreview.cloneNode(true) as HTMLElement;

    const dragIcon = this.dragPreviewElement.querySelector('mat-icon');
    this.dragPreviewElement.replaceChildren(dragIcon);

    this.renderer.addClass(this.dragPreviewElement, 'path-width-drag-preview');
    this.renderer.addClass(containerElement, this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
    this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${dragPreviewElementDOMRect.left}px, -${dragPreviewElementDOMRect.height}px, 0)`);

    const dragPreviewTextElement = this.document.createElement('div');

    dragPreviewTextElement.style.paddingLeft = '12px';
    this.renderer.addClass(this.dragPreviewElement, 'b1');
    dragPreviewTextElement.textContent = this.getPathWidthFormArray().controls[pathWidthIndex]?.value?.width?.toString();
    this.dragPreviewElement.appendChild(dragPreviewTextElement);

    const dummyPreview: HTMLElement = document.createElement('div');
    this.renderer.setStyle(dummyPreview, 'display', `none`);
    event.dataTransfer.setDragImage(dummyPreview, 0, 0);

    return dragPreviewElementDOMRect;
  }

  private isMouseOnBottomHalfOfRow(productionScheduleItemElementboundingDOMRect: DOMRect, mouseYPosition: number): boolean {
    return mouseYPosition > productionScheduleItemElementboundingDOMRect.top + productionScheduleItemElementboundingDOMRect.height / 2;
  }

  private removePathWidthItemDropIndicator(): void {
    if (!AssertionUtils.isNullOrUndefined(this.pathWidthItemDropIndicator)) {
      this.renderer.removeChild(document.body, this.pathWidthItemDropIndicator);
      this.pathWidthItemDropIndicator = null;
    }
  }

  private addPathWidthItemDropIndicator(pathWidthItemElement: HTMLElement, eventY: number): void {
    if (AssertionUtils.isNullOrUndefined(this.pathWidthItemDropIndicator)) {
      this.pathWidthItemDropIndicator = this.renderer.createElement('hr');
      this.renderer.addClass(this.pathWidthItemDropIndicator, this.classNameForDropIndicator);
      this.renderer.appendChild(document.body, this.pathWidthItemDropIndicator);
    }

    const pathWidthItemElementboundingDOMRect: DOMRect = pathWidthItemElement.getBoundingClientRect();
    this.isOnBottomHalfOfRow = this.isMouseOnBottomHalfOfRow(pathWidthItemElementboundingDOMRect, eventY);
    this.renderer.setStyle(this.pathWidthItemDropIndicator, 'width', `${pathWidthItemElementboundingDOMRect.width}px`);

    const xPosition: number = pathWidthItemElementboundingDOMRect.left;
    let yPosition: number = pathWidthItemElementboundingDOMRect.bottom;

    if (!this.isOnBottomHalfOfRow) {
      yPosition = pathWidthItemElementboundingDOMRect.top;
    }

    this.renderer.setStyle(this.pathWidthItemDropIndicator, 'transform', `translate3d(${xPosition}px, ${yPosition}px, 0)`);
  }

  private getPathLayoutTemplateToSave(): PathLayoutTemplate {
    const pathLayoutTemplatePaths = this.addPathLayoutTemplateForm?.value?.paths?.map((path: {width: number}, index: number) => new PathLayoutTemplatePath(path.width * 10, index + 1));

    return new PathLayoutTemplate(
      this.isEditingPathLayoutTemplate() ? Number(this.activatedRoute.snapshot.params.id) : null,
      this.addPathLayoutTemplateForm?.value.name,
      this.addPathLayoutTemplateForm?.value.loomGroup,
      pathLayoutTemplatePaths
    );
  }

  private setFormFields(): void {
    this.addPathLayoutTemplateForm = this.formBuilder.group({
      name: this.formBuilder.control(null, Validators.required, AsyncUniqueValidator.createValidator(this.pathLayoutTemplates, null)),
      loomGroup: this.formBuilder.control(null, Validators.required),
      paths: this.formBuilder.array(
        [
          this.formBuilder.group({
            width: this.formBuilder.control(null)
          })
        ],
        Validators.required
      )
    });

    this.addPathLayoutTemplateForm.controls.paths.clear();

    merge(this.addPathLayoutTemplateForm.controls.loomGroup.valueChanges, this.addPathLayoutTemplateForm.controls.paths.valueChanges)
      .pipe(
        debounceTime(500),
        switchMap(() => {
          const isValid = this.addPathLayoutTemplateForm.controls.loomGroup.valid && this.addPathLayoutTemplateForm.controls.paths.valid;
          return this.hasEditPermission() && isValid ? this.pathLayoutTemplates.generateName(this.getPathLayoutTemplateToSave()) : EMPTY;
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((name: string) => {
        this.suggestedPathLayoutTemplateName = name;
      });
  }

  private setFormFieldsValues(pathLayoutTemplate: PathLayoutTemplate, withName?: boolean): void {
    const name = this.isEditingPathLayoutTemplate() || withName ? pathLayoutTemplate.name : null;

    this.addPathLayoutTemplateForm.patchValue({
      name: name,
      loomGroup: pathLayoutTemplate.loomGroup
    });
    pathLayoutTemplate.pathLayoutTemplatePaths.forEach((pathLayoutTemplatePath: PathLayoutTemplatePath) =>
      this.addPathWidth(
        convertToCommercialUnitCentimeter({
          unit: Unit.MILLIMETER,
          value: pathLayoutTemplatePath.widthInMM
        })
      )
    );

    this.addPathLayoutTemplateForm.controls.name.setAsyncValidators(AsyncUniqueValidator.createValidator(this.pathLayoutTemplates, name));
    this.addPathLayoutTemplateForm.controls.name.updateValueAndValidity();

    this.checkPermissionToEdit();
  }

  private checkPermissionToEdit(): void {
    if (!this.hasEditPermission()) {
      this.addPathLayoutTemplateForm.disable();
    }
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant(this.PATH_LAYOUT_TEMPLATE_TRANSLATION_KEY, {
          count: 1
        })
      }),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  private initialisePathLayoutTemplate(): void {
    const pathLayoutTemplateState = this.navigationHelperService.getState();

    if (!AssertionUtils.isNullOrUndefined(pathLayoutTemplateState)) {
      this.setFormFieldsValues(pathLayoutTemplateState, true);
    } else if (!this.isAddingPathLayoutTemplate()) {
      this.isLoadingPathLayoutTemplate = true;

      const pathLayoutTemplateId = Number(this.activatedRoute.snapshot.params.id);

      if (isNaN(pathLayoutTemplateId)) {
        this.navigateBack();
      } else {
        this.pathLayoutTemplates
          .getById(pathLayoutTemplateId)
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe({
            next: (pathLayoutTemplate: PathLayoutTemplate) => {
              this.setFormFieldsValues(pathLayoutTemplate);
              this.isLoadingPathLayoutTemplate = false;
            },
            error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.URL_TO_PATH_LAYOUT_TEMPLATE_OVERVIEW, this.navigationHelperService)
          });
      }
    }
  }
}
