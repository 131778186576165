import {CommercialDimensions} from '@domain/commercial-dimensions';
import {DrawingConfiguration} from '@domain/production-schedule/drawing-configuration';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {Color, ObjectUtils} from '@vdw/angular-component-library';
import {get, isNil, map} from 'lodash-es';
import {CommercialDimensionsCM} from './commercial-dimensions-cm';
import {DrawingConfigurationWeaveQuality} from './drawing-configuration-weave-quality';
import {DrawingImage} from './drawing-image';

export class DrawingConfigurationWithColorAndYarnSet extends DrawingConfiguration {
  private _colorSet: OverviewListColorSet;
  private _yarnSet: OverviewListYarnSet;

  public constructor(
    image: DrawingImage,
    mainColorsCreelPosition: OverviewListCreelPosition,
    borderColorsCreelPosition: OverviewListCreelPosition,
    colorSet: OverviewListColorSet,
    quality: DrawingConfigurationWeaveQuality,
    commercialDimensionsInMm: CommercialDimensions,
    yarnSet: OverviewListYarnSet,
    commercialDimensionsInCm?: CommercialDimensionsCM,
    id?: number
  ) {
    super(image, mainColorsCreelPosition, borderColorsCreelPosition, quality, commercialDimensionsInMm, commercialDimensionsInCm, id);
    this._colorSet = colorSet;
    this._yarnSet = yarnSet;
  }

  public get colorSet(): OverviewListColorSet {
    return this._colorSet;
  }

  public get yarnSet(): OverviewListYarnSet {
    return this._yarnSet;
  }

  public static fromJSON(drawingConfigurationJSON: any): DrawingConfigurationWithColorAndYarnSet {
    let colorSet: OverviewListColorSet = null;
    let yarnSet: OverviewListYarnSet = null;
    let mainColorsCreelPosition: OverviewListCreelPosition = null;
    let borderColorsCreelPosition: OverviewListCreelPosition = null;
    const quality = DrawingConfigurationWeaveQuality.fromJSON(drawingConfigurationJSON.quality);
    const commercialDimensionsInMm = isNil(drawingConfigurationJSON.commercialDimensions)
      ? null
      : {
          widthInMM: drawingConfigurationJSON.commercialDimensions.widthInMM,
          heightInMM: drawingConfigurationJSON.commercialDimensions.heightInMM
        };

    const commercialDimensionsInCm = ObjectUtils.tryGetValue(drawingConfigurationJSON.commercialDimensionsCM, null) as CommercialDimensionsCM;

    if (!isNil(drawingConfigurationJSON.colorSet)) {
      colorSet = OverviewListColorSet.fromJSON(drawingConfigurationJSON.colorSet);
      mainColorsCreelPosition = colorSet.creelPositions.find((creelPosition: OverviewListCreelPosition) => creelPosition.position === drawingConfigurationJSON.mainColorPosition);
      borderColorsCreelPosition = colorSet.creelPositions.find((creelPosition: OverviewListCreelPosition) => creelPosition.position === drawingConfigurationJSON.borderColorPosition);
    } else if (!isNil(drawingConfigurationJSON.mainColors) && !isNil(drawingConfigurationJSON.borderColors)) {
      mainColorsCreelPosition = new OverviewListCreelPosition(map(drawingConfigurationJSON.mainColors.creel, (colorJSON: any) => Color.fromJSON(colorJSON)));
      borderColorsCreelPosition = new OverviewListCreelPosition(map(drawingConfigurationJSON.borderColors.creel, (colorJSON: any) => Color.fromJSON(colorJSON)));
    }

    const image: DrawingImage = {
      id: null,
      data: drawingConfigurationJSON.image ?? null
    };

    if (!isNil(drawingConfigurationJSON.yarnSet)) {
      yarnSet = OverviewListYarnSet.fromJSON(drawingConfigurationJSON.yarnSet);
    }

    return new DrawingConfigurationWithColorAndYarnSet(
      image,
      mainColorsCreelPosition,
      borderColorsCreelPosition,
      colorSet,
      quality,
      commercialDimensionsInMm,
      yarnSet,
      commercialDimensionsInCm,
      drawingConfigurationJSON.id
    );
  }

  public toJSON(): JSON {
    return {
      qualityId: this.quality.id,
      mainColorPosition: this.mainColorsCreelPosition != null ? this.mainColorsCreelPosition.position : null,
      borderColorPosition: this.borderColorsCreelPosition != null ? this.borderColorsCreelPosition.position : null,
      commercialDimensions: {
        widthInMM: this.commercialDimensionsInMm.widthInMM,
        heightInMM: this.commercialDimensionsInMm.heightInMM
      },
      colorSet: !isNil(this.colorSet) ? this.colorSet.toJSON() : null,
      yarnSetId: get(this.yarnSet, 'id', null)
    } as any as JSON;
  }
}
