import {WeftSelectionType} from './weft-selection-type.enum';

export class WeftSelection {
  private _type: WeftSelectionType;
  private _position: number;
  private _numberOfColumns: number;
  private _possibleNumberOfColumns: number[];
  private _defaultPattern: string;

  public constructor(type: WeftSelectionType, position: number, numberOfColumns: number, possibleNumberOfColumns: number[], defaultPattern: string) {
    this._type = type;
    this._position = position;
    this._numberOfColumns = numberOfColumns;
    this._possibleNumberOfColumns = possibleNumberOfColumns;
    this._defaultPattern = defaultPattern;
  }

  public get type(): any {
    return this._type;
  }

  public get position(): number {
    return this._position;
  }

  public get numberOfColumns(): number {
    return this._numberOfColumns;
  }

  public get possibleNumberOfColumns(): number[] {
    return this._possibleNumberOfColumns;
  }

  public get defaultPattern(): string {
    return this._defaultPattern;
  }

  public static fromJSON(weftSelectionJSON: any): WeftSelection {
    return new WeftSelection(
      WeftSelectionType[`${weftSelectionJSON.type}`],
      weftSelectionJSON.position,
      weftSelectionJSON.selectedNrColumns,
      weftSelectionJSON.possibleNrColumns,
      weftSelectionJSON.defaultPattern
    );
  }

  public toJSON(): JSON {
    return {
      type: WeftSelectionType[this._type],
      position: this._position,
      selectedNrColumns: this._numberOfColumns,
      possibleNrColumns: this._possibleNumberOfColumns,
      defaultPattern: this._defaultPattern
    } as any as JSON;
  }
}
