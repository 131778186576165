<div
  class="distance-specifier"
  [style.width.px]="containerWidthInPx"
  [ngClass]="{'distance-specifier--vertical': hasVerticalOrientation(), 'distance-specifier--small': !canShowLabel(), 'visibility-hidden': !canShow()}"
  [matTooltip]="getDistanceSpecifierTooltip()"
>
  <div class="arrow">
    <span class="arrow-top"></span>
    <span class="arrow-bottom"></span>
    <span class="arrow-line"></span>
  </div>

  <span class="label">{{ getDistanceSpecifierLabel() }}</span>

  <div class="arrow arrow--rotate">
    <span class="arrow-top"></span>
    <span class="arrow-bottom"></span>
    <span class="arrow-line"></span>
  </div>
</div>
