import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class PhysicalQuantityRoute extends RouteBranch {
  private _addPhysicalQuantity: RouteLeaf;
  private _editPhysicalQuantity: RouteLeaf;
  private _duplicatePhysicalQuantity: RouteLeaf;

  public get addPhysicalQuantity(): RouteLeaf {
    return this._addPhysicalQuantity;
  }

  public set addPhysicalQuantity(value: RouteLeaf) {
    this.replace(this._addPhysicalQuantity, value);
    this._addPhysicalQuantity = value;
  }

  public get editPhysicalQuantity(): RouteLeaf {
    return this._editPhysicalQuantity;
  }

  public set editPhysicalQuantity(value: RouteLeaf) {
    this.replace(this._editPhysicalQuantity, value);
    this._editPhysicalQuantity = value;
  }

  public get duplicatePhysicalQuantity(): RouteLeaf {
    return this._duplicatePhysicalQuantity;
  }

  public set duplicatePhysicalQuantity(value: RouteLeaf) {
    this.replace(this._duplicatePhysicalQuantity, value);
    this._duplicatePhysicalQuantity = value;
  }
}
