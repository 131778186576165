import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';

@Component({
  selector: 'app-pattern-preview',
  templateUrl: './pattern-preview.component.html',
  styleUrls: ['./pattern-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternPreviewComponent {
  public patternVisualisationType = PatternVisualisationType;

  @Input() public set patternVisualisation(patternVisualisation: PatternVisualisation) {
    if (patternVisualisation) {
      this._patternVisualisation = patternVisualisation;
    }
  }

  public get patternVisualisation(): PatternVisualisation {
    return this._patternVisualisation;
  }

  @Input() public viewMode: 'full' | 'thumbnail';

  private _patternVisualisation: PatternVisualisation;

  public getPatternVisualisationRepresentation(): string {
    if (this.patternVisualisation.type === PatternVisualisationType.COLOR) {
      return `rgb(${this.patternVisualisation['rgb'].red},${this.patternVisualisation['rgb'].green},${this.patternVisualisation['rgb'].blue})`;
    }

    return `url(${this.patternVisualisation['image']})`;
  }

  public isThumbnailViewMode(): boolean {
    return this.viewMode === 'thumbnail';
  }
}
