import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddArticlePageComponent} from '@presentation/pages/texfab/article/add/add-article-page.component';
import {ArticleOverviewPageComponent} from '@presentation/pages/texfab/article/overview/article-overview-page.component';

const shared = {parentId: NavigationId.ARTICLE_MODULE};

export const articleRoutes = [
  Factory.createPrototypeComponentRoute(ArticleOverviewPageComponent, {route: '', id: NavigationId.ARTICLE, ...shared}),
  Factory.createPrototypeComponentRoute(AddArticlePageComponent, {route: 'add/:id', requiredPermission: Permission.TEXFAB_ARTICLE_EDIT, id: NavigationId.DUPLICATE_ARTICLE, ...shared}),
  Factory.createPrototypeComponentRoute(AddArticlePageComponent, {route: 'edit/:id', requiredPermission: Permission.TEXFAB_ARTICLE_VIEW, id: NavigationId.EDIT_ARTICLE, ...shared}),
  Factory.createPrototypeComponentRoute(AddArticlePageComponent, {route: 'add', requiredPermission: Permission.TEXFAB_ARTICLE_EDIT, reuse: true, id: NavigationId.ADD_ARTICLE, ...shared})
];

export const articleDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(articleRoutes);
