import {Injectable} from '@angular/core';
import {ObjectActionType} from '../../../common/object-action-type.enum';
import {TranslateService} from '../../../translation/translate.service';
import {ToastService} from '../service/toast-service';

@Injectable({providedIn: 'root'})
export class ToastHelperService {
  private readonly defaultTimeout = 2000;
  private readonly actionTypeToTranslationKeyMap = new Map<string, string>([
    [ObjectActionType.EDIT, 'ANGULAR_COMPONENT_LIBRARY.TOAST_HELPER.EDITED_OBJECT'],
    [ObjectActionType.CREATE, 'ANGULAR_COMPONENT_LIBRARY.TOAST_HELPER.CREATED_OBJECT'],
    [ObjectActionType.DELETE, 'ANGULAR_COMPONENT_LIBRARY.TOAST_HELPER.DELETED_OBJECT'],
    [ObjectActionType.SAVE, 'ANGULAR_COMPONENT_LIBRARY.TOAST_HELPER.SAVED_OBJECT']
  ]);

  public constructor(
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  public showToastForObjectAction<T extends number | string>(
    actionType: ObjectActionType,
    objectTranslationKey: string,
    identifier: T,
    multiDelete: boolean = false,
    tapToDismiss: boolean = false,
    timeOut: number = this.defaultTimeout
  ): void {
    this.toastService.showInfo({
      tapToDismiss,
      timeOut,
      message: this.translate.instant(this.mapObjectActionTypeToTranslationKey(actionType), {
        object: this.translate.instant(objectTranslationKey, {count: multiDelete ? 2 : 1}),
        name: identifier
      })
    });
  }

  private mapObjectActionTypeToTranslationKey(actionType: string): string {
    return this.actionTypeToTranslationKeyMap.get(actionType);
  }
}
