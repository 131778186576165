import {Equipment} from '@domain/machine/equipment';
import {MachineType} from './machine-type.enum';

export class VelvetNoJacquardMachine extends Equipment {
  public constructor(id: number, name: string, orderId: string) {
    super(id, MachineType.VELVET_NO_JQ, name, orderId);
  }

  public static fromJSON(velvetNoJacquardMachineJSON: any): VelvetNoJacquardMachine {
    return new VelvetNoJacquardMachine(velvetNoJacquardMachineJSON.id, velvetNoJacquardMachineJSON.name, velvetNoJacquardMachineJSON.orderId);
  }
}
