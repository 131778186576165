import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {MatFormFieldControlHelper} from '../helper/form-field-control-helper';

@Component({
  selector: 'vdw-filter-chips',
  styleUrls: ['./filter-chips.component.scss'],
  templateUrl: './filter-chips.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: FilterChipsComponent}]
})
export class FilterChipsComponent extends MatFormFieldControlHelper<any> implements OnInit, AfterViewInit, OnDestroy, OnChanges, DoCheck {
  public overflowAmount = 0;
  public overFlowChipLabels: string[];
  public overflowGap = 0;
  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;
  private previousChips: any[];
  private readonly INPUT_FIELD_GAP_WIDTH = 8;
  private readonly INPUT_FIELD_OVERFLOW_BUTTON_WIDTH = 30;
  private readonly INPUT_CHIP_OVERFLOW_MIN_WIDTH = 200;

  @ViewChildren('visibleChips') public visibleChipComponents: QueryList<any>;
  @ViewChild('chipsContainer', {read: ElementRef}) public chipsContainer: ElementRef;
  @ViewChild('overflowChipTrigger') public overflowChipTrigger: MatMenuTrigger;
  @ViewChild('overflowChips') public overflowChipsMenu: ElementRef<MatMenu>;
  @Output() public deletedEvent = new EventEmitter<any>();
  @Output() public droppedEvent = new EventEmitter<any>();
  @Input() public chips: any[];
  @Input() public canDeleteChips = true;
  @Input() public canMoveChips = true;
  @Input() public disabled = false;
  @Input() public compact = false;
  @Input() public errorPredicate: (chip: any) => boolean = (): boolean => false;
  @Input() public labelGetter: (chip: any) => string = (chip: string): string => chip;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public constructor(
    @Optional() @Self() public readonly ngControl: NgControl,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    public elementRef: ElementRef
  ) {
    super(ngControl);
  }

  public ngOnInit(): void {
    super.init();

    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe((evt: any) => {
      this.setVisibleChips();
    });
    setTimeout(() => this.setVisibleChips());
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.setVisibleChips());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('chips' in changes) {
      setTimeout(() => this.setVisibleChips());
    }
  }

  public ngDoCheck(): void {
    if (!this.canMoveChips && this.chips?.length !== this.previousChips?.length) {
      setTimeout(() => this.setVisibleChips());
      this.previousChips = this.chips.slice();
    }
  }

  public ngOnDestroy(): void {
    super.destroy();
    this.resizeSubscription.unsubscribe();
  }

  public delete(deletedObject: any): void {
    this.deletedEvent.emit(deletedObject);
  }

  public drop(): void {
    this.droppedEvent.emit();
  }

  public out(): void {
    const dragPreview = this.getDragPreview();

    if (dragPreview) {
      this.renderer.setStyle(dragPreview, 'cursor', 'not-allowed', 1);
    }
  }

  public over(): void {
    const dragPreview = this.getDragPreview();

    if (dragPreview) {
      this.renderer.removeStyle(dragPreview, 'cursor');
    }
  }

  private getDragPreview(): HTMLElement {
    return this.document.querySelector('.gu-mirror');
  }

  public setVisibleChips(): void {
    if (this.canMoveChips) {
      return;
    }

    const availableWidthChips = (this.chipsContainer.nativeElement as HTMLElement).parentElement.offsetWidth;
    this.overFlowChipLabels = [];
    this.overflowAmount = 0;
    let usedWidthByInputChips = 0;
    let index = 0;

    for (let chip of this.visibleChipComponents) {
      const htmlComponent = chip.nativeElement as HTMLElement;
      htmlComponent.classList.remove('hidden');

      if (AssertionUtils.isNullOrUndefined(htmlComponent)) {
        continue;
      }

      const chipWidth = htmlComponent.offsetWidth + 1;
      const overflowButtonWidth = index === this.visibleChipComponents.length - 1 ? 0 : this.INPUT_FIELD_OVERFLOW_BUTTON_WIDTH + this.INPUT_FIELD_GAP_WIDTH;
      const availableWidth = availableWidthChips - usedWidthByInputChips - overflowButtonWidth;

      if (availableWidth > chipWidth) {
        htmlComponent.classList.remove('hidden');
        usedWidthByInputChips += chipWidth + this.INPUT_FIELD_GAP_WIDTH;
      } else if (availableWidth > this.INPUT_CHIP_OVERFLOW_MIN_WIDTH || this.chips.length === 1) {
        htmlComponent.style.width = this.chips.length === 1 ? '100%' : `${this.INPUT_CHIP_OVERFLOW_MIN_WIDTH}px`;
        usedWidthByInputChips += this.INPUT_CHIP_OVERFLOW_MIN_WIDTH + this.INPUT_FIELD_GAP_WIDTH;
      } else if (availableWidth < chipWidth) {
        htmlComponent.classList.add('hidden');
        const elementLabel = htmlComponent.textContent.trim();
        this.overFlowChipLabels.push(elementLabel);
        usedWidthByInputChips += chipWidth + this.INPUT_FIELD_GAP_WIDTH;
        this.overflowAmount += 1;
      }

      index = index + 1;
      this.overflowGap = this.INPUT_FIELD_GAP_WIDTH * this.overFlowChipLabels.length;
    }
  }

  public canShowErrorQuantity(): boolean {
    const overFlowChips = this.chips.filter((chip: any) => this.overFlowChipLabels.includes(this.labelGetter(chip)));
    const hasErrorOverFlowChips = overFlowChips.filter((chip: any) => this.errorPredicate(chip) === true);
    return hasErrorOverFlowChips.length > 0;
  }
}
