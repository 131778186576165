import {Equipment} from '@domain/machine/equipment';
import {AssertionUtils} from '@vdw/angular-component-library';
import {DyeingMachineType} from './dyeing-machine-type';
import {MachineType} from './machine-type.enum';

export class DyeingMachine extends Equipment {
  private _dyeingMachineType: DyeingMachineType;

  public constructor(id: number, name: string, orderId: string, dyeingMachineType: DyeingMachineType) {
    super(id, MachineType.DYEING, name, orderId);
    this._dyeingMachineType = dyeingMachineType;
  }

  public static fromJSON(dyeingMachineJSON: any): DyeingMachine {
    return new DyeingMachine(
      dyeingMachineJSON.id,
      dyeingMachineJSON.name,
      dyeingMachineJSON.orderId,
      AssertionUtils.isNullOrUndefined(dyeingMachineJSON.dyeingMachineType) ? null : DyeingMachineType.fromJSON(dyeingMachineJSON.dyeingMachineType)
    );
  }

  public toJSON(): JSON {
    const dyeingMachineJSON = super.toJSON();
    dyeingMachineJSON['dyeingMachineTypeId'] = this.dyeingMachineType?.id;

    return dyeingMachineJSON;
  }

  public get dyeingMachineType(): DyeingMachineType {
    return this._dyeingMachineType;
  }

  public set dyeingMachineType(value: DyeingMachineType) {
    this._dyeingMachineType = value;
  }
}
