import {UnitOfMeasurement} from '@domain/textile-data/plastic-product/unit-of-measurement.enum';

export class PlasticProduct {
  private _id: number;
  private _name: string;
  private _unitOfMeasurement: UnitOfMeasurement;

  public constructor(id: number, name: string, unitOfMeasurement: UnitOfMeasurement) {
    this._id = id;
    this._name = name;
    this._unitOfMeasurement = unitOfMeasurement;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get unitOfMeasurement(): UnitOfMeasurement {
    return this._unitOfMeasurement;
  }

  public static fromJSON(json: any): PlasticProduct {
    return new PlasticProduct(json.id, json.name, json.unitOfMeasurement);
  }
}
