import {ColorSetStubFactory} from '@application/helper/textile-data/color-set/color-set-stub-factory';
import {YarnSetStubFactory} from '@application/helper/textile-data/yarn-set/yarn-set-stub-factory';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';

export class ColoredYarnSetStubFactory {
  public static getRandomColoredYarnSet(): ColoredYarnSet {
    return ColoredYarnSetStubFactory.getRandomColoredYarnSetWithId(1);
  }

  public static getRandomColoredYarnSetWithId(id: number): ColoredYarnSet {
    return this.getRandomColoredYarnSetWithIdAndName(id, `ColoredYarnSet ${id}`);
  }

  public static getRandomColoredYarnSetWithIdAndName(id: number, name: string): ColoredYarnSet {
    return new ColoredYarnSet(id, name, ColorSetStubFactory.getRandomColorSetWithId(1), YarnSetStubFactory.getRandomYarnSetWithId(1), false, null, []);
  }

  public static getRandomColoredYarnSetWithIdAndNameAndNumberOfCreelPositions(id: number, name: string, numberOfCreelPositions: number): ColoredYarnSet {
    return new ColoredYarnSet(
      id,
      name,
      ColorSetStubFactory.getRandomColorSetOverviewListWithIdAndNumberOfCreelPositions(id, numberOfCreelPositions),
      YarnSetStubFactory.getRandomYarnSetWithId(1),
      false,
      null,
      []
    );
  }
}
