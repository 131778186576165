import {MappingForDesign} from '@domain/production-schedule/mapping-for-design';
import {AssertionUtils} from '@vdw/angular-component-library';

export class UploadDrawingInfo {
  private _id: string;
  private _threadByThread?: boolean;
  private _nrColumnsForWeftSelection?: number;
  private _designMap: MappingForDesign[];

  public constructor(id: string, threadByThread?: boolean, nrColumnsForWeftSelection?: number, designMap: MappingForDesign[] = []) {
    this._id = id;
    this._threadByThread = threadByThread;
    this._nrColumnsForWeftSelection = nrColumnsForWeftSelection;
    this._designMap = designMap;
  }

  public get id(): string {
    return this._id;
  }

  public get threadByThread(): boolean {
    return this._threadByThread;
  }

  public get nrColumnsForWeftSelection(): number {
    return this._nrColumnsForWeftSelection;
  }

  public get designMap(): MappingForDesign[] {
    return this._designMap;
  }

  public static fromJSON(uploadDrawingJSON: any): UploadDrawingInfo {
    let designMap: MappingForDesign[] = [];

    if (AssertionUtils.isNullOrUndefined(uploadDrawingJSON.designMap)) {
      designMap = uploadDrawingJSON.designMap.map((designMapJSON: any) => MappingForDesign.fromJSON(designMapJSON));
    }

    return new UploadDrawingInfo(uploadDrawingJSON.id, uploadDrawingJSON.threadByThread, uploadDrawingJSON.nrColumnsForWeftSelection, designMap);
  }
}
