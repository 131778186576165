import {isNil} from 'lodash-es';

export class FinishingTemplateCutbarProperties {
  private _commercialLengthInMM: number;

  public constructor(commercialLengthInMM: number) {
    this._commercialLengthInMM = commercialLengthInMM;
  }

  public get commercialLengthInMM(): number {
    return this._commercialLengthInMM;
  }

  public static fromJSON(cutbarPropertiesJSON: any): FinishingTemplateCutbarProperties {
    return isNil(cutbarPropertiesJSON) ? null : new FinishingTemplateCutbarProperties(cutbarPropertiesJSON.commercialLengthInMM);
  }

  public toJSON(): JSON {
    return {commercialLengthInMM: this._commercialLengthInMM} as any as JSON;
  }
}
