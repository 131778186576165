import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  templateUrl: './grid-checked-flag.component.html'
})
export class GridCheckedFlagComponent implements ICellRendererAngularComp {
  private isDefault: boolean;

  public agInit(params: ICellRendererParams): void {
    this.isDefault = params.value;
  }

  public canShowIcon(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.isDefault);
  }

  public getIconName(): string {
    return this.isDefault ? 'checkmark' : 'close';
  }

  public refresh(params: any): boolean {
    return false;
  }
}
