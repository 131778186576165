import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';
import {BreakpointResponsivenessViewModeService} from '../../common/responsiveness/breakpoint-responsiveness-view-mode.service';

@Directive({
  selector: '[vdwAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public autoSelect: boolean = false;

  public constructor(
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly responsivenessViewMode: BreakpointResponsivenessViewModeService
  ) {}

  public ngAfterContentInit(): void {
    if (this.responsivenessViewMode.isDesktop) {
      this.setFocus();
    }
  }

  private setFocus(): void {
    const delay = this.autoSelect ? 0 : 500;
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
      if (this.autoSelect) {
        this.elementRef.nativeElement.select();
      }
    }, delay);
  }
}
