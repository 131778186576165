import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {RestZonePosition} from '@domain/production-schedule/rest-zone/rest-zone-position.enum';
import {UserDefinedRest} from '@domain/production-schedule/rest-zone/user-defined-rest';
import {isEqual, isNil} from 'lodash-es';

export class InitialRestZone {
  private readonly _position: RestZonePosition;
  private readonly _commercialWidthInMM: number;
  private readonly _technicalWidthInDents: number;
  private readonly _pattern: Pattern;
  private readonly _userDefinedRest: UserDefinedRest;

  public constructor(position: RestZonePosition, commercialWidthInMM: number, technicalWidthInNumberOfDents: number, pattern: Pattern, userDefinedRest: UserDefinedRest) {
    this._position = position;
    this._commercialWidthInMM = commercialWidthInMM;
    this._technicalWidthInDents = technicalWidthInNumberOfDents;
    this._pattern = pattern;
    this._userDefinedRest = userDefinedRest;

    if (this._pattern?.visualisations?.some((x: PatternVisualisation) => x.isWeavePatternVisualisation())) {
      this._pattern.clearName();
    }
  }

  public get position(): RestZonePosition {
    return this._position;
  }

  public get commercialWidthInMM(): number {
    return this._commercialWidthInMM;
  }

  public get technicalWidthInDents(): number {
    return this._technicalWidthInDents;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public get userDefinedRest(): UserDefinedRest {
    return this._userDefinedRest;
  }

  public static fromJSON(initialRestZoneJSON: any): InitialRestZone {
    return new InitialRestZone(
      RestZonePosition[`${initialRestZoneJSON.position}`],
      initialRestZoneJSON.commercialWidthInMM,
      initialRestZoneJSON.technicalWidthInDents,
      Pattern.fromJSON(initialRestZoneJSON.pattern),
      isNil(initialRestZoneJSON.userDefinedRest)
        ? null
        : {
            position: RestZonePosition[`${initialRestZoneJSON.userDefinedRest.position}`],
            technicalWidthInDents: initialRestZoneJSON.userDefinedRest.technicalWidthInDents
          }
    );
  }

  public toJSON(): JSON {
    const result = {
      position: RestZonePosition[this._position],
      pattern: this._pattern.toJSON()
    };

    if (isEqual(this._position, RestZonePosition.USER_DEFINED)) {
      result['userDefinedRest'] = {
        position: RestZonePosition[this._userDefinedRest.position],
        technicalWidthInDents: this._userDefinedRest.technicalWidthInDents
      };
    }

    return result as any as JSON;
  }

  public isValid(isConfigurable: boolean): boolean {
    let isValid = !isNil(this.pattern);

    if (isValid && isConfigurable) {
      isValid = !isNil(this.position);
      if (isValid && isEqual(this._position, RestZonePosition.USER_DEFINED)) {
        isValid = !isNil(this.userDefinedRest.technicalWidthInDents);
      }
    }

    return isValid;
  }
}
