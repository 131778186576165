import {RouteBranch} from '@application/helper/routing/route-branch';
import {TexBotMachineDocumentationRoute} from './tex-bot-machine-documentation-route';
import {TexBotTrainingRoute} from './tex-bot-training-route';

export class TexBotRoute extends RouteBranch {
  private _machineDocumentation: TexBotMachineDocumentationRoute;
  private _training: TexBotTrainingRoute;

  public get machineDocumentation(): TexBotMachineDocumentationRoute {
    return this._machineDocumentation;
  }

  public set machineDocumentation(value: TexBotMachineDocumentationRoute) {
    this.replace(this._machineDocumentation, value);
    this._machineDocumentation = value;
  }

  public get training(): TexBotTrainingRoute {
    return this._training;
  }

  public set training(value: TexBotTrainingRoute) {
    this.replace(this._training, value);
    this._training = value;
  }
}
