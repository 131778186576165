import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddTuftProductionOrderPageComponent} from './add/add-tuft-production-order-page.component';
import {TuftProductionOrderOverviewPageComponent} from './overview/tuft-production-order-overview-page.component';
import {TuftProductionOrderSettingsComponent} from './settings/tuft-production-order-settings.component';

const shared = {parentId: NavigationId.TUFT_PRODUCTION_ORDER_MODULE};

export const tuftProductionOrderRoutes = [
  Factory.createPrototypeComponentRoute(TuftProductionOrderOverviewPageComponent, {route: '', requiredPermission: Permission.TEXFAB_TUFT_ORDER_VIEW, id: NavigationId.TUFT_ORDER, ...shared}),
  Factory.createPrototypeComponentRoute(AddTuftProductionOrderPageComponent, {
    route: 'add',
    requiredPermission: Permission.TEXFAB_TUFT_ORDER_EDIT,
    reuse: true,
    id: NavigationId.ADD_TUFT_ORDER,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddTuftProductionOrderPageComponent, {route: 'add/run', requiredPermission: Permission.TEXFAB_TUFT_QUEUE, id: NavigationId.ADD_TUFT_ORDER_RUN, ...shared}),
  Factory.createPrototypeComponentRoute(AddTuftProductionOrderPageComponent, {
    route: 'add/machine/:id',
    requiredPermission: Permission.TEXFAB_TUFT_ORDER_EDIT,
    id: NavigationId.ADD_TUFT_ORDER_MACHINE,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(AddTuftProductionOrderPageComponent, {route: 'edit/:id', requiredPermission: Permission.TEXFAB_TUFT_ORDER_VIEW, id: NavigationId.EDIT_TUFT_ORDER, ...shared}),
  Factory.createPrototypeComponentRoute(AddTuftProductionOrderPageComponent, {
    route: 'add/:id',
    requiredPermission: Permission.TEXFAB_TUFT_ORDER_EDIT,
    id: NavigationId.DUPLICATE_TUFT_ORDER,
    ...shared
  }),
  Factory.createPrototypeComponentRoute(TuftProductionOrderSettingsComponent, {
    route: 'settings',
    requiredPermission: Permission.TEXFAB_TUFT_ORDER_VIEW,
    id: NavigationId.TUFT_ORDER_SETTINGS,
    ...shared
  })
];

export const tuftProductionOrderRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(tuftProductionOrderRoutes);
