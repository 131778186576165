import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'vdw-multi-line-drag',
  templateUrl: './multi-line-drag.component.html'
})
export class MultiLineDragComponent {
  @Input() public items: any[] = [];
  @Output() public itemsChange = new EventEmitter<any[]>();
  @Input() public draggable = true;
  @ContentChild(TemplateRef) public customTemplateRef: TemplateRef<any>;

  private _draggedElement: any;

  public startDrag(item: HTMLElement): void {
    this._draggedElement = item;
  }

  public endDrag(event: DragEvent, item: HTMLElement): void {
    event.preventDefault();

    let sourceIndex = this.items.indexOf(this._draggedElement);
    let targetIndex = this.items.indexOf(item);

    if (sourceIndex < 0 || targetIndex < 0) {
      return;
    }

    const [removed] = this.items.splice(sourceIndex, 1);
    this.items.splice(targetIndex, 0, removed);
    this.itemsChange.emit(this.items);
  }

  public allowDrop(event: DragEvent): void {
    event.preventDefault();
  }
}
