export class PlanningFilter {
  private readonly _equipmentGroupIds: number[];

  public constructor(equipmentGroupIds: number[]) {
    this._equipmentGroupIds = equipmentGroupIds ?? [];
  }

  public get equipmentGroupIds(): number[] {
    return this._equipmentGroupIds;
  }

  public static fromJSON(json: any): PlanningFilter {
    return new PlanningFilter(json.equipmentGroupIds);
  }

  public toJSON(): any {
    return {
      equipmentGroupIds: this.equipmentGroupIds
    };
  }
}
