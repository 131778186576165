import {Injectable} from '@angular/core';
import {AsyncValidatorFn} from '@angular/forms';
import {Observable} from 'rxjs';
import {Color} from '../../common/data/color';
import {DialogBuilderFactoryService} from '../../dialogs/dialog-builder/dialog-builder-factory.service';
import {ColorPickerPopupComponent} from './popup-component/color-picker-popup.component';

@Injectable({providedIn: 'root'})
export class ColorPickerPopupService {
  public constructor(private readonly dialogBuilderFactoryService: DialogBuilderFactoryService) {}

  public showColorPickerPopup(validatorFunction?: () => AsyncValidatorFn, canEdit?: boolean, okButtonText?: string, cancelButtonText?: string, color?: Color): Observable<Color> {
    return this.dialogBuilderFactoryService.getBuilder().withClass('select-color-dialog').openDialog(ColorPickerPopupComponent, {
      validatorFunction,
      canEdit,
      color,
      okButtonText,
      cancelButtonText
    });
  }
}
